import { useCurrentUser } from "@accurx/auth";
import { useUserScheduledConversationGroupQuery } from "@accurx/concierge/hooks/queries/useUserScheduledConversationGroupQuery";
import { getMostRecentItem } from "@accurx/concierge/util/getMostRecentItem";
import { getNextScheduledItem } from "@accurx/concierge/util/getNextScheduledItem";
import * as UI from "@accurx/design";
import {
    ConversationFeed,
    ConversationListHeader,
    ConversationTabItem,
    ConversationTabs,
} from "domains/inbox/components/ConversationList/components";
import { ConversationPreview } from "domains/inbox/components/ConversationPreview/ConversationPreview";
import { useInboxTitle } from "domains/inbox/hooks/util";
import { useConversationListAnalytics } from "domains/inbox/hooks/util/useConversationListAnalytics";
import { useInboxLink } from "domains/inbox/hooks/util/useInboxLink";

import { StyledAccessibleHeader, StyledConversationList } from "../styles";

export const MyInboxScheduled = () => {
    useInboxTitle("My inbox scheduled conversations");

    const { user } = useCurrentUser();
    const link = useInboxLink();

    const groupQuery = useUserScheduledConversationGroupQuery({
        userId: user.accuRxUserId,
    });

    const { trackTabClick } = useConversationListAnalytics();

    return (
        <>
            <StyledConversationList rows="auto 1fr">
                <UI.Cell>
                    <ConversationListHeader
                        heading={
                            <>
                                Sent & scheduled
                                <StyledAccessibleHeader>
                                    showing scheduled messages
                                </StyledAccessibleHeader>
                            </>
                        }
                    />
                    <ConversationTabs>
                        <ConversationTabItem
                            active={false}
                            link={link.to("MyInboxSent")}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Scheduled",
                                    newTab: "Sent",
                                    conversationParticipant: "WithPatient",
                                })
                            }
                        >
                            Sent
                        </ConversationTabItem>

                        <ConversationTabItem
                            active={true}
                            link={link.to("MyInboxScheduled")}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Sent",
                                    newTab: "Scheduled",
                                    conversationParticipant: "WithPatient",
                                })
                            }
                        >
                            Scheduled
                        </ConversationTabItem>
                    </ConversationTabs>
                </UI.Cell>

                <ConversationFeed
                    key={groupQuery.data?.id}
                    isLoading={groupQuery.status === "loading"}
                    isError={groupQuery.status === "error"}
                    refetch={groupQuery.refetch}
                    fetchMore={groupQuery.fetchMore}
                    isFetchingMore={groupQuery.isFetchingMore}
                    isFullyLoaded={groupQuery.data?.isFullyLoaded}
                    members={groupQuery.data?.members}
                    filters={groupQuery.data?.filters}
                    renderItem={(conversation, state) => (
                        <ConversationPreview
                            conversation={conversation}
                            displayAssigneeBadge
                            item={
                                getNextScheduledItem(
                                    conversation,
                                    user.accuRxUserId,
                                ) ?? getMostRecentItem(conversation)
                            }
                            state={state}
                            hideConversationActions
                        />
                    )}
                />
            </StyledConversationList>
        </>
    );
};
