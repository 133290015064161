import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledFeedbackContent = styled.div`
    padding-top: ${UI.Tokens.SIZES[1]};
`;

export const StyledPatientDetails = styled(UI.Flex)`
    padding-top: ${UI.Tokens.SIZES[1]};
    flex-wrap: wrap;
    flex-direction: column;
`;

export const StyledFeedback = styled(UI.Feedback)`
    flex: 1;
    padding: ${UI.Tokens.SIZES[1]};

    * > * {
        font-size: ${UI.Tokens.TYPOGRAPHY.preview.desktop.fontSize};
    }
`;
