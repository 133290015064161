import { Ds } from "@accurx/design";

import { Voip } from "app/practices/voip/Voip";

export const VoipPage = () => {
    return (
        <Ds.Box m={"3"}>
            <Voip />
        </Ds.Box>
    );
};
