import { Button, Ds, Flex, Text } from "@accurx/design";

type FailedAttachmentWarningModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onClickSendMessageWithoutAttachment: () => void;
};

export const FailedAttachmentWarningModal = ({
    isOpen,
    onClose,
    onClickSendMessageWithoutAttachment: onSendMessage,
}: FailedAttachmentWarningModalProps) => {
    return (
        <Ds.Modal open={isOpen} onOpenChange={onClose}>
            <Ds.Modal.Section>
                <Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>
                        Would you like to send your message without the failed
                        attachments?
                    </Ds.Modal.Title>
                    <Text>At least one attachment has failed to upload.</Text>
                    <Flex justifyContent="flex-end" gap="2">
                        <Button
                            onClick={onClose}
                            type="button"
                            text="Cancel"
                            theme="secondary"
                        />
                        <Button
                            onClick={onSendMessage}
                            type="button"
                            text="Send without failed attachments"
                        />
                    </Flex>
                </Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
