import { Ds } from "@accurx/design";
import { useMedicalRecordConnection } from "@accurx/native";
import { ContactDetail } from "domains/message-component/reducer.types";
import { PatientExternalId } from "domains/message-component/types";

import { useCommunicationConsent } from "./CommunicationConsent/ConsentAggregate/useCommunicationConsent";
import {
    StyledContactButton,
    StyledFullWidthFlex,
    StyledTextContainer,
} from "./ContactButton.styles";
import { RecipientSelectorButtonConsentIcon } from "./RecipientSelectorButtonConsentIcon";
import { mapOriginToDisplay } from "./mapOriginToDisplay";

export const ContactButton = ({
    contactDetail,
    patientExternalIds,
    disabled,
    onClick,
}: {
    contactDetail: ContactDetail;
    patientExternalIds: PatientExternalId[];
    disabled: boolean;
    onClick: () => void;
}) => {
    const medicalRecordConnection = useMedicalRecordConnection();
    const communicationConsentQuery = useCommunicationConsent({
        patientExternalIds,
    });

    const isMobile = contactDetail.method === "Mobile";

    return (
        <StyledContactButton
            fullwidth
            disabled={disabled}
            appearance={"tertiary"}
            onClick={onClick}
            size="small"
        >
            <StyledFullWidthFlex
                flexDirection={"column"}
                gap={"0.5"}
                alignItems={"start"}
            >
                <StyledFullWidthFlex justifyContent={"space-between"}>
                    <StyledTextContainer>
                        <Ds.Text
                            as="span"
                            size="small"
                            color={disabled ? "inherit" : "zinc"}
                        >
                            {contactDetail.value}
                        </Ds.Text>
                        <Ds.Text
                            as="span"
                            size="small"
                            color={disabled ? "inherit" : "zinc"}
                        >
                            {mapOriginToDisplay(
                                contactDetail.origin,
                                medicalRecordConnection.system,
                            )}
                        </Ds.Text>
                    </StyledTextContainer>
                    <RecipientSelectorButtonConsentIcon
                        patientExternalIds={patientExternalIds}
                        contactMethod={contactDetail.method}
                    />
                </StyledFullWidthFlex>
                {communicationConsentQuery !== null &&
                    communicationConsentQuery.status === "success" &&
                    (isMobile
                        ? communicationConsentQuery.data.smsConsent
                              .consentAggregate === "Ambiguous"
                        : communicationConsentQuery.data.emailConsent
                              .consentAggregate === "Ambiguous") && (
                        <Ds.Text
                            as="span"
                            size="small"
                            color={disabled ? "inherit" : "metal"}
                        >
                            {`Check patient record for ${
                                isMobile ? "text message" : "email"
                            } consent`}
                        </Ds.Text>
                    )}
            </StyledFullWidthFlex>
        </StyledContactButton>
    );
};
