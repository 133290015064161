import { useMemo } from "react";

import {
    ConversationItem,
    NhsAppMessageItem,
} from "@accurx/concierge/schemas/ConversationItemSchema";
import { FallbackFailedLinkItem } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import {
    ConversationItemContainer,
    ConversationItemContent,
    ConversationItemFooter,
    ConversationItemHeader,
    ConversationThreadListItem,
} from "domains/inbox/components/ConversationItem/ConversationItem.styles";
import { AccurxLogoAvatar } from "domains/inbox/components/ConversationItem/components/AccurxLogoAvatar/AccurxLogoAvatar";
import { CreatedAtTime } from "domains/inbox/components/ConversationItem/components/CreatedAtTime/CreatedAtTime";
import { MessageNameTag } from "domains/inbox/components/ConversationItem/components/MessageNameTag/MessageNameTag";
import { ReadByTooltip } from "domains/inbox/components/ConversationItem/components/ReadByTooltip/ReadByTooltip";
import { ConversationItemComponent } from "domains/inbox/components/ConversationItem/types";
import { getItemAlignment } from "domains/inbox/components/ConversationView/components/ConversationThread/conversationThread.utils";
import { userflowIds } from "domains/inbox/util";

import { StyledMessage } from "./FallbackFailedLinkNote.styles";

const isSupported = (item?: ConversationItem): item is NhsAppMessageItem => {
    return item?.contentType === "NhsAppMessage";
};

export const FallbackFailedLinkNote = ({
    item,
    conversation,
}: ConversationItemComponent<FallbackFailedLinkItem>) => {
    const linkedItem = useMemo(
        () => conversation.items.find((i) => i.id === item.linkedItemId),
        [item, conversation],
    );

    return (
        <ConversationThreadListItem
            $align={getItemAlignment({
                item,
            })}
        >
            <ConversationItemContainer
                data-testid={`conversation-item-${item.id}`}
                $color="red"
            >
                <ConversationItemHeader>
                    <MessageNameTag
                        avatar={<AccurxLogoAvatar />}
                        name="Accurx"
                    />
                    <div
                        data-userflow-id={
                            userflowIds.conversationView.messageHeaderLabel
                        }
                    >
                        <UI.Ds.Badge color="red" size="small">
                            System
                        </UI.Ds.Badge>
                    </div>
                </ConversationItemHeader>
                <ConversationItemContent>
                    {isSupported(linkedItem) ? (
                        <StyledMessage>
                            Message delivered to NHS App but could not deliver
                            SMS fallback.
                        </StyledMessage>
                    ) : (
                        <StyledMessage>
                            Could not deliver fallback message.
                        </StyledMessage>
                    )}
                </ConversationItemContent>
                <ConversationItemFooter>
                    <ReadByTooltip readDataByUserId={item.readDataByUserId} />
                    <CreatedAtTime time={item.createdAt} />
                </ConversationItemFooter>
            </ConversationItemContainer>
        </ConversationThreadListItem>
    );
};
