import { useState } from "react";

import {
    FeatureName,
    Organisation,
    isFeatureEnabledForWorkspace,
    useCurrentUser,
    useCurrentWorkspace,
    useFeatureFlag,
} from "@accurx/auth";
import { Ds, Popover, PopoverTrigger } from "@accurx/design";
import {
    NavTooltip,
    PopoverItemLink,
    StyledDivider,
    StyledListHeader,
    StyledPopoverContent,
    StyledPopoverItem,
} from "@accurx/navigation";
import {
    ArchivedWorkspaceWarningModal,
    MyWorkspaceWarningModal,
    SelectNhsPharmacyModal,
    isArchivedMoreThanMonthAgo,
    isArchivedWorkspace,
    isMyWorkspace,
    isNhsPharmacy,
    isPharmacy,
    isUserOnlyInDefaultWorkspaceWithinOrg,
} from "@accurx/workspace-management";
import { userflowIds } from "layouts/constants";
import { generatePath, useHistory } from "react-router-dom";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";
import { useAnalytics } from "reduxQuarantine/useAnalytics";

import { ROUTES_BASE } from "shared/Routes";

import { StyledTriggerButton } from "../../UnifiedLayout.styles";
import { getNavigationVersion } from "../../helpers";
import { StyledArchivedBadge } from "./WorkspaceSwitcher.styles";

export const WorkspaceSwitcher = () => {
    const [popoverOpen, setPopoverOpen] = useState(false);

    const history = useHistory();
    const track = useAnalytics();

    return (
        <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
            <NavTooltip text="Switch workspace">
                <PopoverTrigger asChild>
                    <StyledTriggerButton
                        onClick={() => {
                            if (!popoverOpen) {
                                track("NavigationMenu Button Click", {
                                    origin: history.location.pathname,
                                    navigationOptionSelected:
                                        "WorkspaceSwitcher",
                                    navigationVersion: getNavigationVersion(),
                                    eventVersion: 2,
                                });
                            }
                        }}
                        data-userflow-id={
                            userflowIds.navigation.primary.workspaceSwitcher
                        }
                        data-testid="primary-nav-workspace-switcher-button"
                        aria-label="Switch workspace"
                    >
                        <Ds.Icon
                            name="preview-Swap"
                            size="small"
                            color="white"
                        />
                    </StyledTriggerButton>
                </PopoverTrigger>
            </NavTooltip>
            <WorkspaceSwitcherContent onClose={() => setPopoverOpen(false)} />
        </Popover>
    );
};

const sortWorkspacesAlphabetically = (a: Organisation, b: Organisation) => {
    return a.organisationName.toLowerCase() > b.organisationName.toLowerCase()
        ? 1
        : -1;
};

export const WorkspaceSwitcherContent = ({
    onClose,
}: {
    onClose: () => void;
}) => {
    const history = useHistory();
    const track = useAnalytics();

    const { user } = useCurrentUser();
    const currentWorkspace = useCurrentWorkspace();
    const { setSelectedOrgId } = useSelectedOrganisation();
    const { ref, handleFocusRef } = Ds.useModalAutoFocus<HTMLButtonElement>();

    const allWorkspaces = [...user.organisations];
    const availableWorkspaces = allWorkspaces
        .filter(
            (workspace) =>
                workspace.orgId !== currentWorkspace.orgId &&
                !isArchivedMoreThanMonthAgo(workspace),
        )
        .sort(sortWorkspacesAlphabetically);

    const [showPharmacyModal, setShowPharmacyModal] = useState(false);
    const [selectedMyWorkspace, setSelectedMyWorkspace] =
        useState<Organisation | null>(null);
    const [selectedArchivedWorkspace, setSelectedArchivedWorkspace] =
        useState<Organisation | null>(null);

    const isFlexibleWorkspaceEnabled = useFeatureFlag(
        FeatureName.FlexibleWorkspace,
    );

    const showYourWorkspacesItem = allWorkspaces.some((w) =>
        isArchivedMoreThanMonthAgo(w),
    );

    const trackMenuItemClick = (
        optionSelected:
            | "JoinAnOrganisation"
            | "SwitchPractice"
            | "YourWorkspaces",
    ) => {
        track("NavigationOrganisation MenuItem Click", {
            eventVersion: 2,
            navigationVersion: getNavigationVersion(),
            origin: history.location.pathname,
            optionSelected,
        });
    };

    const navigateToJoinOrganisation = () => {
        trackMenuItemClick("JoinAnOrganisation");
        history.push("/join-organisation", { showBackButton: true });
    };

    const navigateToJoinWorkspace = () => {
        trackMenuItemClick("JoinAnOrganisation");
        history.push(
            generatePath(
                "/w/:workspaceId/workspace-onboarding/select-organisation",
                { workspaceId: currentWorkspace.orgId },
            ),
        );
    };

    const joinWorkspace = () => {
        isFlexibleWorkspaceEnabled
            ? navigateToJoinWorkspace()
            : navigateToJoinOrganisation();
        track("NavigationOrganisation MenuItem Click", {
            origin: history.location.pathname,
            optionSelected: "JoinAnOrganisation",
        });
        onClose();
    };

    const handleWorkspaceClick = (workspace: Organisation) => {
        trackMenuItemClick("SwitchPractice");
        if (
            isNhsPharmacy(workspace) &&
            user.organisations.filter(isPharmacy).length > 0
        ) {
            setShowPharmacyModal(true);
        } else if (
            isFeatureEnabledForWorkspace(
                FeatureName.DissuadeFromUsingMyWorkspace,
                workspace,
            ) &&
            isMyWorkspace(workspace) &&
            !isUserOnlyInDefaultWorkspaceWithinOrg({
                user,
                organisation: workspace,
            })
        ) {
            setSelectedMyWorkspace(workspace);
        } else if (isArchivedWorkspace(workspace)) {
            setSelectedArchivedWorkspace(workspace);
        } else {
            selectWorkspace(workspace);
        }
        onClose();
    };

    const selectWorkspace = (workspace: Organisation) => {
        setSelectedOrgId(workspace.orgId);
        history.push("/");
    };

    return (
        <>
            <StyledPopoverContent
                side="bottom"
                align="end"
                onOpenAutoFocus={(e) => setTimeout(() => handleFocusRef(e), 0)}
                data-testid="primary-nav-workspace-switcher-content"
            >
                <StyledPopoverItem
                    ref={ref}
                    onClick={joinWorkspace}
                    data-userflow-id={
                        userflowIds.navigation.primary
                            .workspaceSwitcherJoinOrganisation
                    }
                >
                    <Ds.Icon name="Plus" size="small" color="stone" />
                    <Ds.Text weight="bold" color="zinc">
                        {isFlexibleWorkspaceEnabled
                            ? "Join a workspace"
                            : "Join an organisation"}
                    </Ds.Text>
                </StyledPopoverItem>

                {availableWorkspaces.length > 0 && (
                    <>
                        <StyledDivider />

                        <StyledListHeader>Switch workspace</StyledListHeader>
                        {availableWorkspaces.map((workspace) => (
                            <WorkspaceItem
                                key={workspace.orgId}
                                workspace={workspace}
                                onClick={handleWorkspaceClick}
                            />
                        ))}
                        {showYourWorkspacesItem && (
                            <PopoverItemLink
                                to={ROUTES_BASE.accountUserWorkspaceAccess}
                                onClick={() =>
                                    trackMenuItemClick("YourWorkspaces")
                                }
                            >
                                <Ds.Icon
                                    name="List"
                                    size="small"
                                    color="stone"
                                />
                                <Ds.Flex
                                    flexDirection="column"
                                    alignItems="flex-start"
                                >
                                    <Ds.Text weight="bold" color="zinc">
                                        Your workspaces
                                    </Ds.Text>
                                </Ds.Flex>
                            </PopoverItemLink>
                        )}
                    </>
                )}
            </StyledPopoverContent>

            {showPharmacyModal && (
                <SelectNhsPharmacyModal
                    handleClose={() => setShowPharmacyModal(false)}
                    handleSelectWorkspace={selectWorkspace}
                />
            )}

            {selectedMyWorkspace && (
                <MyWorkspaceWarningModal
                    handleClose={() => setSelectedMyWorkspace(null)}
                    handleSelectMyWorkspace={() => {
                        selectWorkspace(selectedMyWorkspace);
                        setSelectedMyWorkspace(null);
                    }}
                />
            )}
            {selectedArchivedWorkspace && (
                <ArchivedWorkspaceWarningModal
                    handleClose={() => setSelectedArchivedWorkspace(null)}
                    handleSelectArchivedWorkspace={() => {
                        selectedArchivedWorkspace &&
                            selectWorkspace(selectedArchivedWorkspace);
                        setSelectedArchivedWorkspace(null);
                    }}
                />
            )}
        </>
    );
};

const WorkspaceItem = ({
    workspace,
    onClick,
}: {
    workspace: Organisation;
    onClick: (workspace: Organisation) => void;
}) => {
    const showArchivedBadge =
        isNhsPharmacy(workspace) ||
        (isMyWorkspace(workspace) &&
            isFeatureEnabledForWorkspace(
                FeatureName.DissuadeFromUsingMyWorkspace,
                workspace,
            )) ||
        isArchivedWorkspace(workspace);

    return (
        <StyledPopoverItem
            key={workspace.orgId}
            onClick={() => onClick(workspace)}
            data-testid={`inactive-${workspace.orgId}`}
        >
            <Ds.Icon name="Carehome" size="small" color="stone" />
            <Ds.Flex flexDirection="column" alignItems="flex-start">
                <Ds.Text weight="bold" color="zinc">
                    {isMyWorkspace(workspace)
                        ? "My Workspace"
                        : workspace.organisationName}
                </Ds.Text>
                {isFeatureEnabledForWorkspace(
                    FeatureName.FlexibleWorkspace,
                    workspace,
                ) && (
                    <Ds.Text color="zinc">
                        {workspace.organisationNodeName}
                    </Ds.Text>
                )}
                {showArchivedBadge && (
                    <StyledArchivedBadge color="greyscale">
                        Archived
                    </StyledArchivedBadge>
                )}
            </Ds.Flex>
        </StyledPopoverItem>
    );
};
