import { Link, LinkProps, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import { usePrimaryNavAnalytics } from "../hooks/usePrimaryNavAnalytics";
import { mobileNavItemStyles } from "./MobileNavItem.styles";

export const MobileNavButton = styled.button`
    ${mobileNavItemStyles};
    background: none;
`;

const StyledMobileNavLink = styled(Link)`
    ${mobileNavItemStyles}
`;

export const MobileNavLink = ({
    activePaths,
    to,
    children,
    navigationOptionSelected,
    ...props
}: {
    activePaths: string[];
    navigationOptionSelected: string;
} & LinkProps) => {
    const { track } = usePrimaryNavAnalytics({ navigationOptionSelected });
    const routeMatch = useRouteMatch(activePaths);
    const isActiveRoute = Boolean(routeMatch);

    return (
        <StyledMobileNavLink
            to={to}
            data-active={isActiveRoute}
            onClick={track}
            {...props}
        >
            {children}
        </StyledMobileNavLink>
    );
};
