import { RefObject, useEffect, useState } from "react";

import { useContainerHeight } from "domains/message-component/components/TrackedDimensionContainer/TrackedDimensionContainer";
import { useCompose } from "domains/message-component/context";

/**
 * Ideally the dropdown would not be less than a minimum value
 */
const MIN_DROPDOWN_HEIGHT = 250;

export const useTemplateSelectorDropdown = ({
    comboboxRef,
    templatesCount,
}: {
    comboboxRef: RefObject<HTMLDivElement>;
    templatesCount: number;
}) => {
    // Make dropdown extend to the end of compose area
    const parentContainerHeight = useContainerHeight();
    const [calculatedDropdownHeight, setCalculatedDropdownHeight] =
        useState(MIN_DROPDOWN_HEIGHT);
    const [openSide, setOpenSide] = useState("bottom");
    const { templatesComboboxMustOpenDownwards } = useCompose();

    // Calculate what the dropdown height should be
    // to extend to the end of reply area max
    useEffect(() => {
        if (parentContainerHeight && comboboxRef.current) {
            // combobox does not include the dropdown itself
            const { height: comboboxHeight, top: comboboxTopPosition } =
                comboboxRef.current.getBoundingClientRect();
            const comboboxOffsetTop =
                comboboxRef.current.offsetTop + comboboxHeight;

            const distanceFromWindowTop = comboboxTopPosition;
            const distanceToWindowBottom =
                parentContainerHeight - comboboxOffsetTop;

            // When compose is shown in a container that's restricted in
            // height, we want to make sure enough templates are visible
            // in the dropdown, in that case we open the dropdown upwards
            const shouldOpenTop =
                // If there are "enough" templates
                templatesCount > 2 &&
                // And there is not enough space to the bottom
                distanceToWindowBottom < MIN_DROPDOWN_HEIGHT &&
                // And there's enough space to the top
                distanceFromWindowTop > MIN_DROPDOWN_HEIGHT &&
                // Usages of the component can disable it opening upwards
                !templatesComboboxMustOpenDownwards;

            const calculatedDropdownHeight = shouldOpenTop
                ? distanceFromWindowTop
                : distanceToWindowBottom;

            setCalculatedDropdownHeight(calculatedDropdownHeight);
            setOpenSide(shouldOpenTop ? "top" : "bottom");
        }
    }, [
        comboboxRef,
        parentContainerHeight,
        templatesCount,
        templatesComboboxMustOpenDownwards,
    ]);

    return {
        calculatedDropdownHeight,
        openSide,
    };
};
