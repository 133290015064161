import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledPage = styled.div`
    background-color: ${Tokens.COLOURS.greyscale.white};
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
`;

export const StyledHeader = styled.header`
    align-items: center;
    background-color: ${Tokens.COLOURS.greyscale.white};
    border-bottom: ${Tokens.BORDERS.normal.silver};
    display: flex;
    padding: ${Tokens.SIZES[1]};
`;

export const StyledDivider = styled.div`
    height: 24px;
    background-color: ${Tokens.COLOURS.greyscale.silver};
    margin: 0 ${Tokens.SIZES[2]} 0 ${Tokens.SIZES[1]};
    width: 1px;
`;

export const StyledFooter = styled.footer`
    border-top: ${Tokens.BORDERS.normal.silver};
    padding: ${Tokens.SIZES[1.5]} 0;
`;

export const StyledBody = styled.div`
    flex-grow: 1;
    overflow-y: auto;
    padding: ${Tokens.SIZES[3]} 0;
`;

export const StyledContainer = styled.div`
    margin: 0 auto;
    width: 752px;
    max-width: 752px; // 720px + 2x16px gutters
    padding: 0 ${Tokens.SIZES[2]};
`;
