import { Ds } from "@accurx/design";
import { useIsComposing } from "@accurx/message-component";
import { useMutateCloseWindow } from "@accurx/native";

import { useActiveCloseAttempt } from "../../hooks/useActiveCloseAttempt";

export const DiscardChangesModal = ({ onClose }: { onClose: () => void }) => {
    const isComposing = useIsComposing();
    const { mutate: closeWindow } = useMutateCloseWindow();

    const { activeCloseAttempt, onCloseAttemptActioned } =
        useActiveCloseAttempt();

    if (activeCloseAttempt && !isComposing) {
        /* When the user has closed the window and there's no message being
         * composed we can immediately close the window without prompt */
        closeWindow();
        onCloseAttemptActioned();
    }

    return (
        <Ds.Modal
            open={activeCloseAttempt && isComposing}
            onOpenChange={(open) => {
                if (!open) {
                    onCloseAttemptActioned();
                }
            }}
        >
            <Ds.Modal.Section>
                <Ds.Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>Discard this message?</Ds.Modal.Title>
                    <Ds.Text>
                        A message has been started but is not sent yet.
                    </Ds.Text>
                    <Ds.Flex justifyContent="flex-end" gap="2">
                        <Ds.Button
                            appearance="secondary"
                            type="button"
                            onClick={() => onCloseAttemptActioned()}
                        >
                            Cancel
                        </Ds.Button>
                        <Ds.Button
                            appearance="primary"
                            type="button"
                            onClick={() => {
                                closeWindow();
                                onCloseAttemptActioned();
                                onClose();
                            }}
                        >
                            Discard
                        </Ds.Button>
                    </Ds.Flex>
                </Ds.Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
