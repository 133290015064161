import { AppOrigin } from "@accurx/message-component";
import { PatientDemographics } from "@accurx/native";

import {
    ComposePatientRouterState,
    createSearchedPatient,
    createSearchedPatientFromDemographics,
} from "../utils/createSearchedPatient";
import { RouterState } from "./Routes.types";

export const createRouterState = ({
    appOrigin,
    ...patientInformation
}:
    | {
          patient: ComposePatientRouterState | null;
          patientToken?: string | null;
          appOrigin?: keyof typeof AppOrigin;
      }
    | {
          patientDemographics: PatientDemographics;
          appOrigin?: keyof typeof AppOrigin;
      }): RouterState => {
    if ("patient" in patientInformation) {
        const { patient, patientToken = null } = patientInformation;
        const finalPatientToken = patient?.patientToken ?? patientToken;

        if (!finalPatientToken) {
            return undefined;
        }

        const searchedPatient = createSearchedPatient({
            patient,
            patientToken: finalPatientToken,
        });
        return searchedPatient === null
            ? undefined
            : {
                  searchedPatient,
                  appOrigin,
              };
    }

    return {
        appOrigin,
        searchedPatient: createSearchedPatientFromDemographics(
            patientInformation.patientDemographics,
        ),
    };
};
