import React from "react";

import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { SenderId } from "app/practices/senderId/SenderId";

export const SmsSettingsPage = () => {
    return (
        <StyledContainer>
            <PageHeader title="SMS sender ID" type={PageHeaderType.ListPage} />
            <SenderId noBreadcrumbs noHeader />
        </StyledContainer>
    );
};
