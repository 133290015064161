import { AppSettings, HttpError, Log, LogSinks } from "@accurx/shared";
import { createBrowserHistory } from "history";

/**
 * Setup Logging
 *
 * This is where we configure where our Log statements will go. If you wish to
 * consume Log data and send it to a new destination, create yourself a LogSink,
 * initialise it here and add include it via Log.addSink(new MySink())
 */
export const setupLoggingProviders = (
    history: ReturnType<typeof createBrowserHistory>,
) => {
    // only log to console if we're not on production
    if (AppSettings.accurxEnvironment !== "production") {
        Log.addSink(new LogSinks.Console());
    }

    const isSentryEnabled = AppSettings.getWebClientSentrySettingsIsEnabled();
    const environment =
        AppSettings.getWebClientSentrySettingsEnvironment() ?? "unknown";
    const sentryDSN = AppSettings.getWebClientSentrySettingsDSN();

    // Enable Sentry
    if (isSentryEnabled === true && sentryDSN) {
        const sentryLogSink = new LogSinks.Sentry(
            { minimumLevel: "warn" },
            {
                browserHistory: history,
                dsn: sentryDSN,
                environment,
                tracesSamplingRate: 0.01,
                errorsToIgnore: [
                    "Event `CustomEvent` (type=error) captured as exception",
                ],
            },
        );

        Log.addSink(sentryLogSink);
    } else {
        // Sentry is not enabled so Log and return undefined
        Log.info("Sentry is not enabled", {
            tags: { environment: environment },
        });
    }

    // now do AppInsights
    const appInsightsKey = AppSettings.getAppInsightsLoggingSinkKey();
    if (appInsightsKey) {
        Log.addSink(
            new LogSinks.AppInsights(
                { minimumLevel: "info" },
                { instrumentationKey: appInsightsKey, browserHistory: history },
            ),
        );

        Log.addSink(
            new LogSinks.AppInsights(
                { maximumLevel: "debug" },
                { instrumentationKey: appInsightsKey, debugSamplingRate: 1 },
            ),
        );
    }
};

export const getRequestLoggingTags = ({
    request,
    path,
    params,
}: {
    request: Request;
    path: string;
    params?: Record<string, string | number | boolean | undefined>;
}) => {
    const url = new URL(request.url, window.location.origin);
    const correlationId = request.headers.get("AccurxCorrelationId") ?? "";

    const tags: Record<string, string> = {
        logger: "httpClient2",
        "api.correlationId": correlationId,
        "api.endpoint": path,
        "api.method": request.method,
    };

    if (url.search) {
        tags["api.query"] = url.search;
    }

    if (params) {
        for (const param in params) {
            const value = params[param];
            if (value) {
                tags[`api.params.${param}`] = value.toString();
            }
        }
    }

    return tags;
};

export const getHttpErrorResponseMessage = async (
    error: HttpError,
): Promise<string> => {
    try {
        const json = await error.response.clone().json();

        if (typeof json === "string") {
            return json.replace(/\s\s+/g, " ").trim();
        } else if (json.message) {
            return json.message.replace(/\s\s+/g, " ").trim();
        }
    } catch (e) {
        // We couldn't get the error message from the JSON response so carry on
        // and try just getting the response as text...
    }

    try {
        const text = await error.response.clone().text();
        return text.replace(/\s\s+/g, " ").trim();
    } catch (e) {
        // Getting the response as text didn't work either... sad. Admit defeat
        // and return a fallback error message.
    }

    return "no message returned";
};
