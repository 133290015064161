import { ReactNode, useRef, useState } from "react";

import { Tokens } from "@accurx/design";
import styled from "styled-components";

const StyledBody = styled.main<{ hasBorder: boolean }>`
    overflow: auto;
    height: 100%;
    transition: border 0.15s ease-out;
    border-top: ${(props) =>
        props.hasBorder
            ? Tokens.BORDERS.normal.silver
            : Tokens.BORDERS.normal.transparent};
`;

export const Body = ({ children }: { children: ReactNode }) => {
    const ref = useRef<HTMLDivElement | null>(null);

    const [scrollTop, setScrollTop] = useState(0);

    const handleScroll = () => {
        ref.current && setScrollTop(ref.current.scrollTop);
    };

    const hasBorder =
        ref.current?.previousSibling?.nodeName === "HEADER" && scrollTop > 0;

    return (
        <StyledBody ref={ref} onScroll={handleScroll} hasBorder={hasBorder}>
            {children}
        </StyledBody>
    );
};

Body.displayName = "QuickViewBody";
