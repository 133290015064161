import * as UI from "@accurx/design";

import { PatientHeader } from "..";
import { SkeletonLoader } from "../../SkeletonLoader/SkeletonLoader";
import { StyledAdditionalDetails } from "../PatientHeader.styles";

const LoadingPatientName = () => <SkeletonLoader height="20px" width="64%" />;

const LoadingAdditionalDetails = () => (
    <StyledAdditionalDetails>
        <SkeletonLoader height={UI.Tokens.SIZES[2]} width="22%" />
        <SkeletonLoader height={UI.Tokens.SIZES[2]} width="22%" />
    </StyledAdditionalDetails>
);

export const LoadingState = () => (
    <PatientHeader>
        <UI.Ds.Flex.Item flex="1">
            <PatientHeader.Details>
                <LoadingPatientName />
                <LoadingAdditionalDetails />
            </PatientHeader.Details>
        </UI.Ds.Flex.Item>
    </PatientHeader>
);
