import { TeamSummary } from "domains/concierge/types";

import { useTeamsByType } from "./useTeamsByType";

export const useUnassignedTeamPatient = (): TeamSummary | undefined => {
    return useTeamsByType({ type: "ToAssignPatientMessaging" })[0];
};

export const useUnassignedTeamClinician = (): TeamSummary | undefined => {
    return useTeamsByType({ type: "ToAssignClinicianMessaging" })[0];
};
