import { Ds, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledDivider = styled.hr`
    margin: ${Tokens.SIZES[0.5]} 0;
`;

export const StyledContactDetailsList = styled.ul`
    list-style: none;
    padding: 0;
`;

export const StyledMinWidthFlex = styled(Ds.Flex)`
    min-width: 0;
`;

export const StyledCustomContactDetailForm = styled(Ds.Flex)`
    padding: ${Tokens.SIZES[1]};
`;
