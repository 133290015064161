import React, { useEffect } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useCurrentWorkspace } from "@accurx/auth";
import { Ds, Feedback } from "@accurx/design";
import { useAccurxWebTitle, useNavigationOrigin } from "@accurx/navigation";

import { RequestsListTable } from "app/practices/recordViewRequests/RequestsListTable";
import { RequestsStatusAccordion } from "app/practices/recordViewRequests/RequestsStatusAccordion";

import { useGpRecordRequestsQuery } from "../hooks/useGpRecordRequestsQuery";

export const OrganisationRecordRequestsPage = () => {
    useAccurxWebTitle("View medical record requests");

    const { orgId: workspaceId } = useCurrentWorkspace();
    const { data, isLoading, isError } = useGpRecordRequestsQuery({
        workspaceId,
    });
    const track = useAnalytics();
    const navigationOrigin = useNavigationOrigin();

    useEffect(() => {
        track("RecordViewRequest Page View", { navigationOrigin });
    }, [track, navigationOrigin]);

    return (
        <Ds.Flex flexDirection="column" gap="2">
            <Ds.Text>
                Here you can see who has requested Record View for a patient
            </Ds.Text>
            <RequestsStatusAccordion />

            {isError ? (
                <Feedback
                    title="Something went wrong loading your information. Please refresh the page"
                    colour="error"
                />
            ) : isLoading ? (
                <Ds.Flex justifyContent="center">
                    <Ds.Spinner />
                </Ds.Flex>
            ) : (
                <RequestsListTable requests={data?.recordAccessRequests} />
            )}
        </Ds.Flex>
    );
};
