import findLast from "lodash/findLast";

import { Conversation, ConversationItem } from "../types";

export const getMostRecentItemOfTypes = <
    T extends ConversationItem["contentType"],
>(
    conversation: Conversation,
    types: T[],
): Extract<ConversationItem, { contentType: T }> | undefined => {
    const match = findLast(
        conversation.items,
        (item): item is Extract<ConversationItem, { contentType: T }> =>
            types.includes(item.contentType as T),
    );

    return match;
};
