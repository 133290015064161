import { Conversation } from "@accurx/concierge/types";
import { ContactDetail } from "@accurx/message-component";

import { getDefaultContactDetail } from "./getDefaultContactDetail";
import { getUniqueValidContactDetailsFromConversation } from "./getUniqueValidContactDetailsFromConversation";

export const getPatientContactsWithDefault = ({
    conversation,
    demographicsData,
}: {
    conversation: Conversation;
    demographicsData?: ContactDetail[];
}) => {
    // Unique contacts from conversation sorted by date
    const uniqueContactDetailsFromConversation =
        getUniqueValidContactDetailsFromConversation(conversation);

    // Put together fetched demographics data & conversation data
    const data = [
        ...uniqueContactDetailsFromConversation,
        ...(demographicsData ?? []),
    ];

    // Work out which contact should be pre-selected
    const defaultContact = getDefaultContactDetail(data);

    return {
        contactDetails: data,
        default: defaultContact,
    };
};
