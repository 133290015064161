import * as UI from "@accurx/design";
import styled from "styled-components";

const { SIZES } = UI.Tokens;

export const StyledCustomDateContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: ${SIZES[2]};
    height: 100%;
`;

export const StyledCustomDateFooter = styled(UI.Flex).attrs({
    justifyContent: "flex-end",
})`
    padding-top: ${SIZES[1.5]};
`;

export const StyledCustomDateBody = styled(UI.Flex).attrs({
    gap: "2",
    flexDirection: "column",
    alignItems: "flex-start",
})`
    height: 100%;
`;

export const StyledSubtitle = styled(UI.Text).attrs({
    colour: "metal",
    variant: "preview",
})`
    margin: 0;
`;

export const StyledOptionLabel = styled(UI.Text).attrs({
    colour: "night",
    variant: "label",
})`
    margin: 0;
    font-size: 14px;
`;

// Contain size of the select field
export const StyledSelectContainer = styled.div<{ width?: string }>`
    width: ${(props) => props.width ?? "126px"};
`;

export const StyledInputContainer = styled.div`
    width: 63px;
`;

export const StyledLabelContainer = styled(UI.Flex).attrs({
    justifyContent: "flex-end",
})`
    width: 28px;
`;
export const StyledDateInputContainer = styled.div`
    width: 164px;
`;
