import React from "react";

import {
    ContentClassification,
    ContentClassificationGroup,
} from "@accurx/api/florey-builder";
import {
    Button,
    Ds,
    FormFieldWrapper,
    Option,
    SearchSelect,
    Text,
} from "@accurx/design";
import { Log } from "@accurx/shared";
import cloneDeep from "lodash/cloneDeep";

import { StyledClickableAreaButton } from "../components/AddQuestionButton";

type ContentTagSelectProps = {
    index: number;
    allContentClassifications: ContentClassification[];
    selectedContentClassificationGroups: ContentClassificationGroup;
    onChange: (tags: ContentClassification[]) => void;
    onRemove: () => void;
};

type ContentTagsProps = {
    allContentClassifications: ContentClassification[];
    contentClassificationGroups: ContentClassificationGroup[];
    onChange: (groups: ContentClassificationGroup[]) => void;
};

function getTagGroupKey(tagGroup: ContentClassificationGroup) {
    return `${tagGroup.contentClassifications
        .map(
            (contentClassification) =>
                `${contentClassification.type}-${contentClassification.name}`,
        )
        .join(",")}`;
}

const EMPTY_SELECTOR = {
    label: "None (Deselect)",
    value: "none",
    grouping: "none",
    shouldHideValue: true,
};

const createClassificationOptions = (
    items: ContentClassification[],
): Option[] =>
    items.map((classification) => ({
        label: classification.name,
        value: classification.name,
        grouping: classification.type,
    }));

export const ContentTags = ({
    allContentClassifications,
    contentClassificationGroups,
    onChange,
}: ContentTagsProps) => {
    return (
        <div>
            <Text variant="label">Select tags:</Text>
            <Text skinny>
                Tags are used in groups to determine which workspaces see the
                content.
            </Text>
            <Text skinny>
                Select multiple tags in one group to show it to workspaces with
                all selected.
            </Text>
            {contentClassificationGroups.map((group, i) => (
                <ContentTagSelect
                    key={getTagGroupKey(group)}
                    index={i}
                    allContentClassifications={allContentClassifications}
                    selectedContentClassificationGroups={group}
                    onRemove={() => {
                        const clonedGroups = cloneDeep(
                            contentClassificationGroups,
                        );
                        clonedGroups.splice(i, 1);
                        onChange(clonedGroups);
                    }}
                    onChange={(contentClassifications) => {
                        const clonedGroups = cloneDeep(
                            contentClassificationGroups,
                        );
                        clonedGroups[i] = {
                            contentClassifications: contentClassifications,
                        };
                        onChange(clonedGroups);
                    }}
                />
            ))}
            <StyledClickableAreaButton
                onClick={() => {
                    const clonedGroups = cloneDeep(contentClassificationGroups);
                    clonedGroups.push({ contentClassifications: [] });
                    onChange(clonedGroups);
                }}
            >
                <Ds.Halo color="blue" luminosity="high">
                    <Ds.Icon name="Plus" />
                </Ds.Halo>
                <Text variant="label">Add tag group</Text>
            </StyledClickableAreaButton>
        </div>
    );
};

const ContentTagSelect = ({
    index,
    allContentClassifications,
    selectedContentClassificationGroups,
    onChange,
    onRemove,
}: ContentTagSelectProps) => {
    const selectedClassification =
        selectedContentClassificationGroups.contentClassifications;

    const classificationGroups = {
        Market: allContentClassifications.filter(
            (classification) => classification.type === "Market",
        ),
        Region: allContentClassifications.filter(
            (classification) => classification.type === "Region",
        ),
        Pilot: allContentClassifications.filter(
            (classification) => classification.type === "Pilot",
        ),
        Specialty: allContentClassifications.filter(
            (classification) => classification.type === "Specialty",
        ),
    };

    const getInitialSelectedValues = (type: string) => {
        const classificationOptions = createClassificationOptions(
            selectedClassification.filter((selected) => selected.type === type),
        );

        return type === "Pilot" || type === "Specialty"
            ? classificationOptions
            : classificationOptions[0] || EMPTY_SELECTOR;
    };

    const handleChange = (selected: Option | Option[], type: string): void => {
        const newlySelectedContentClassifications = (
            Array.isArray(selected) ? selected : [selected]
        ).map((option) => {
            if (!option.grouping) {
                Log.warn("Missing grouping for content classification", {
                    tags: { classificationName: option.label },
                });
            }

            return {
                name: option.value,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                type: option.grouping!,
            };
        });

        const filteredNewlySelectedContentClassifications =
            newlySelectedContentClassifications.filter(
                (selectedTag) => selectedTag.name !== "none",
            );

        const updatedTags = selectedClassification
            .filter((tag) => tag.type !== type)
            .concat(filteredNewlySelectedContentClassifications);

        onChange(updatedTags);
    };

    return (
        <Ds.Flex gap="0.5" flexDirection="column">
            <Text variant="label">Tag Group {`#${index + 1}`}</Text>
            <Text>
                Workspaces need to have all tags in the list in order to match
                this group.
            </Text>
            {/* <StyledFlexContainer flexDirection="column"> */}
            <FormFieldWrapper
                labelProps={{
                    htmlFor: `tag-group-market-select-${index + 1}`,
                }}
                label={"Market classification"}
            >
                <SearchSelect
                    options={[
                        EMPTY_SELECTOR,
                        ...createClassificationOptions(
                            classificationGroups.Market,
                        ),
                    ]}
                    id={`tag-group-market-select-${index + 1}`}
                    onChangeHandler={(selected) =>
                        handleChange(selected, "Market")
                    }
                    initSelectedValues={getInitialSelectedValues("Market")}
                    zIndex={4}
                />
            </FormFieldWrapper>

            <FormFieldWrapper
                labelProps={{
                    htmlFor: `tag-group-region-select-${index + 1}`,
                }}
                label={"Region classification"}
            >
                <SearchSelect
                    options={[
                        EMPTY_SELECTOR,
                        ...createClassificationOptions(
                            classificationGroups.Region,
                        ),
                    ]}
                    id={`tag-group-region-select-${index + 1}`}
                    onChangeHandler={(selected) =>
                        handleChange(selected, "Region")
                    }
                    initSelectedValues={getInitialSelectedValues("Region")}
                    zIndex={3}
                />
            </FormFieldWrapper>

            <FormFieldWrapper
                labelProps={{
                    htmlFor: `tag-group-pilot-select-${index + 1}`,
                }}
                label={"Pilot classification"}
            >
                <SearchSelect
                    options={createClassificationOptions(
                        classificationGroups.Pilot,
                    )}
                    id={`tag-group-pilot-select-${index + 1}`}
                    onChangeHandler={(selected) =>
                        handleChange(selected, "Pilot")
                    }
                    theme="MULTISELECT"
                    initSelectedValues={getInitialSelectedValues("Pilot")}
                    zIndex={2}
                />
            </FormFieldWrapper>

            <FormFieldWrapper
                labelProps={{
                    htmlFor: `tag-group-specialty-select-${index + 1}`,
                }}
                label={" Specialty classification"}
            >
                <SearchSelect
                    options={createClassificationOptions(
                        classificationGroups.Specialty,
                    )}
                    id={`tag-group-specialty-select-${index + 1}`}
                    onChangeHandler={(selected) =>
                        handleChange(selected, "Specialty")
                    }
                    theme="MULTISELECT"
                    initSelectedValues={getInitialSelectedValues("Specialty")}
                    zIndex={1}
                />
            </FormFieldWrapper>
            <Ds.Flex justifyContent="flex-end">
                <Button
                    text="Remove Group"
                    dimension="small"
                    theme="danger"
                    icon={{ name: "Bin" }}
                    onClick={onRemove}
                />
            </Ds.Flex>
            <hr />
        </Ds.Flex>
    );
};
