import { mapPatientDemographicsContactsToContactDetails } from "@accurx/message-component";
import { PatientDemographics } from "@accurx/native";
import { mapPatientDemographicsToComposePatient } from "domains/compose/utils/mapPatientDemographicsToComposePatient";

import { CurrentPatient } from "../pages/compose/context/CurrentPatientProvider.types";

export const mapDemographicsToCurrentPatient = (
    patientDemographics: PatientDemographics | null,
): CurrentPatient | null =>
    patientDemographics
        ? {
              patient:
                  mapPatientDemographicsToComposePatient(patientDemographics),
              contactDetails: mapPatientDemographicsContactsToContactDetails(
                  patientDemographics.contact,
              ),
          }
        : null;
