import { useEffect, useState } from "react";

import { useCurrentUser, useFeatureFlag } from "@accurx/auth";
import { useAllAssignees } from "@accurx/concierge/hooks/data/useAllAssignees";
import { useAllUsers } from "@accurx/concierge/hooks/data/useAllUsers";
import { useTeamsByType } from "@accurx/concierge/hooks/data/useTeamsByType";
import { useUser } from "@accurx/concierge/hooks/data/useUser";
import { useConversationQuery } from "@accurx/concierge/hooks/queries/useConversationQuery";
import { AssigneeSummary, Conversation } from "@accurx/concierge/types";
import { getIsConversationTriageRequest } from "@accurx/concierge/util/getIsConversationTriageRequest";
import * as UI from "@accurx/design";
import { QuickViewPortal } from "@accurx/quick-view";
import { ArchivedWorkspaceHiddenItemWrapper } from "@accurx/workspace-management";
import { useClinicianMessagingWorkspaceAvailabilityQuery } from "domains/inbox/hooks/useClinicianMessagingWorkspaceAvailabilityQuery";
import { useSendUserIsViewing } from "domains/inbox/hooks/useSendUserIsViewing";
import { useViewportSize } from "domains/inbox/hooks/util";
import { useHasClinicianMessagingInbox } from "domains/inbox/hooks/util/useHasClinicianMessagingInbox";
import { useIsCollaborativeInboxEnabled } from "domains/inbox/hooks/util/useIsCollaborativeInboxEnabled";
import { usePatientSummaryBackgroundFixer } from "domains/inbox/hooks/util/usePatientSummaryBackgroundFixer";
import { useStalePatientSummaryBackgroundFixer } from "domains/inbox/hooks/util/useStalePatientSummaryBackgroundFixer";
import { getLastConversationLabelTagItem } from "domains/inbox/util/getLastConversationLabelTag";

import { GenericErrorState } from "../GenericErrorState/GenericErrorState";
import { ComposeAreaVisibilityProvider } from "./ComposeAreaVisibilityContext/ComposeAreaVisibilityProvider";
import { useComposeAreaVisibility } from "./ComposeAreaVisibilityContext/useComposeAreaVisibility";
import {
    PatientParticipantContainer,
    StyledConversationViewGrid,
    StyledGridItemConversationThread,
    StyledLiveUserActivityBadgeContainer,
    StyledReplyContainer,
} from "./ConversationView.styles";
import { ReplyState } from "./ConversationView.types";
import {
    ConversationActions,
    ConversationActionsLoadingState,
    ConversationThread,
    ConversationThreadLoadingState,
    ReadOnlyAssigneeBadge,
} from "./components";
import { ClinicianConversationActionsFeedback } from "./components/ConversationActionsFeedback/ClinicianConversationActionsFeedback";
import { PatientConversationActionsFeedback } from "./components/ConversationActionsFeedback/PatientConversationActionsFeedback";
import { ConversationViewHeader } from "./components/ConversationViewHeader/ConversationViewHeader";
import { ConversationViewHeaderLoading } from "./components/ConversationViewHeader/ConversationViewLoading";
import { LiveUserActivityBadge } from "./components/LiveUserActivityBadge/LiveUserActivityBadge";
import { ClinicianMessageActions } from "./components/MessageActions/ClinicianMessageActions";
import { MessageActions } from "./components/MessageActions/MessageActions";
import { MessageActionsLoading } from "./components/MessageActions/components/Loading/MessageActionsLoading";
import { PatientMatch } from "./components/PatientMatch/PatientMatch";

/**
 * <PatientMessagingConversation />
 *
 * Renders a Patient Messaging conversation.
 */
const PatientMessagingConversation = ({
    conversation,
    onClickBack,
    onMarkAsUnreadSuccess,
    onAssignedSuccess,
    hideConversationActions,
}: {
    conversation: Conversation;
    onClickBack: (() => void) | undefined;
    onMarkAsUnreadSuccess: (() => void) | undefined;
    onAssignedSuccess:
        | ((payload: {
              newAssignee: AssigneeSummary;
              previousAssignee: AssigneeSummary;
          }) => void)
        | undefined;
    hideConversationActions: boolean;
}) => {
    const [isPatientMatchingOpen, setIsPatientMatchingOpen] = useState(false);
    const [sentNoteStatus, setSentNoteStatus] = useState<string | null>(null);
    const [isFullHeightMessageContainer, setIsFullHeightMessageContainer] =
        useState(false);
    const viewPort = useViewportSize();
    const isMobileSize = viewPort === "xs" || viewPort === "sm";
    const isMessageContainerExpanded =
        isFullHeightMessageContainer || isMobileSize;

    const { user: currentUser } = useCurrentUser();
    const currentUserSummary = useUser({ userId: currentUser.accuRxUserId });
    const isCurrentUserApproved = currentUserSummary?.status === "Approved";
    const isCollaborativeInboxEnabled = useIsCollaborativeInboxEnabled();
    const hasClinicianMessagingInbox = useHasClinicianMessagingInbox();

    const { isPatientSummaryFixing } = usePatientSummaryBackgroundFixer(
        conversation.regardingPatientId,
    );

    useSendUserIsViewing({
        conversationId: conversation.id,
        patientId: conversation.regardingPatientId,
    });

    useStalePatientSummaryBackgroundFixer(conversation.regardingPatientId);

    const onExpandMessageContainerClick = () => {
        setIsFullHeightMessageContainer((prev) => !prev);
    };

    const { isAnyComposeAreaOpen } = useComposeAreaVisibility();

    const allUsers = useAllUsers();
    const allAssignees = useAllAssignees();
    const teams = allAssignees.teams
        .filter((team) => team.type !== "ToAssignClinicianMessaging")
        .filter((t) => t.canBeAssignedTo);

    const isPatientTriageConversation =
        getIsConversationTriageRequest(conversation);
    const hasOutcomeRecordingFlag = useFeatureFlag("OutcomeRecording");
    const lastRecordedOutcome = getLastConversationLabelTagItem(conversation);
    const isOutcomeRecordingEnabledForConversation =
        conversation.status === "Done" &&
        hasOutcomeRecordingFlag &&
        !lastRecordedOutcome;

    const [shouldShowOutcomeRecording, setShouldShowOutcomeRecording] =
        useState(false);

    const isOutcomeRecordingOpenByDefault =
        isOutcomeRecordingEnabledForConversation
            ? isPatientTriageConversation
            : false;

    useEffect(() => {
        setShouldShowOutcomeRecording(isOutcomeRecordingOpenByDefault);
    }, [isOutcomeRecordingOpenByDefault]);

    return (
        <StyledConversationViewGrid
            rows={
                isAnyComposeAreaOpen && isMessageContainerExpanded
                    ? "auto auto 0 1fr"
                    : "auto auto 1fr auto"
            }
            columns="minmax(0, 1fr)"
        >
            <UI.Cell>
                {isPatientSummaryFixing ? (
                    <ConversationViewHeaderLoading />
                ) : (
                    <UI.Cell>
                        <ConversationViewHeader
                            conversation={conversation}
                            searchForPatientButtonProps={{
                                disabled: isPatientMatchingOpen,
                                onClick: () => setIsPatientMatchingOpen(true),
                            }}
                            onClickBack={onClickBack}
                            conversationFeedback={
                                <PatientConversationActionsFeedback
                                    conversation={conversation}
                                    searchForPatientButtonProps={{
                                        disabled: isPatientMatchingOpen,
                                        onClick: () =>
                                            setIsPatientMatchingOpen(true),
                                    }}
                                />
                            }
                        />
                    </UI.Cell>
                )}
            </UI.Cell>
            <UI.Cell>
                {!hideConversationActions && (
                    <ArchivedWorkspaceHiddenItemWrapper>
                        <ConversationActions.Container>
                            {hasClinicianMessagingInbox && (
                                <PatientParticipantContainer>
                                    <ConversationActions.PatientParticipant />
                                </PatientParticipantContainer>
                            )}
                            {isCollaborativeInboxEnabled &&
                            isCurrentUserApproved ? (
                                <ConversationActions.Item $collapsible>
                                    <ConversationActions.Assign
                                        conversation={conversation}
                                        users={allUsers}
                                        teams={teams}
                                        onAssignedSuccess={onAssignedSuccess}
                                    />
                                </ConversationActions.Item>
                            ) : (
                                <ConversationActions.Item $collapsible>
                                    <ReadOnlyAssigneeBadge
                                        assignee={conversation.assignee}
                                    />
                                </ConversationActions.Item>
                            )}
                            <ConversationActions.Item>
                                <ConversationActions.ToggleStatus
                                    conversation={conversation}
                                />
                            </ConversationActions.Item>
                            <ConversationActions.Item>
                                <ConversationActions.ToggleUrgent
                                    conversation={conversation}
                                />
                            </ConversationActions.Item>
                            <ConversationActions.Item>
                                <ConversationActions.MarkAsUnread
                                    conversation={conversation}
                                    onMarkAsUnreadSuccess={
                                        onMarkAsUnreadSuccess
                                    }
                                />
                            </ConversationActions.Item>
                        </ConversationActions.Container>
                    </ArchivedWorkspaceHiddenItemWrapper>
                )}
            </UI.Cell>

            <StyledGridItemConversationThread>
                {!conversation.isFullyLoaded ? (
                    <ConversationThreadLoadingState />
                ) : (
                    <ConversationThread
                        conversation={conversation}
                        {...(shouldShowOutcomeRecording
                            ? {
                                  shouldShowInitialOutcomeRecording: true,
                                  onCloseOutcomeRecording: () =>
                                      setShouldShowOutcomeRecording(false),
                              }
                            : {
                                  shouldShowInitialOutcomeRecording: false,
                              })}
                        hasFloatingBottomElements={!isAnyComposeAreaOpen}
                    />
                )}
            </StyledGridItemConversationThread>
            <StyledReplyContainer>
                <StyledLiveUserActivityBadgeContainer
                    $composeAreaOpen={isAnyComposeAreaOpen}
                    $isFullHeightMessageContainer={isMessageContainerExpanded}
                    aria-live="polite"
                >
                    <LiveUserActivityBadge conversationId={conversation.id} />
                </StyledLiveUserActivityBadgeContainer>
                <MessageActions
                    conversation={conversation}
                    isExpanded={isMessageContainerExpanded}
                    onExpandClick={onExpandMessageContainerClick}
                    setSentNoteStatus={setSentNoteStatus}
                    {...(isOutcomeRecordingEnabledForConversation
                        ? {
                              onClickAddOutcome: () =>
                                  setShouldShowOutcomeRecording(true),
                          }
                        : {})}
                />
            </StyledReplyContainer>
            {conversation.status !== "Done" && (
                <QuickViewPortal
                    isOpen={isPatientMatchingOpen}
                    onClose={() => setIsPatientMatchingOpen(false)}
                    label="Search and match patient to conversation"
                >
                    <PatientMatch conversation={conversation} />
                </QuickViewPortal>
            )}
            <UI.VisuallyHidden>
                <div aria-live="assertive" aria-atomic="true">
                    {sentNoteStatus}
                </div>
            </UI.VisuallyHidden>
        </StyledConversationViewGrid>
    );
};

/**
 * <ClinicianMessagingConversation />
 *
 * Renders a Clinician Messaging conversation. The eventual aim is possibly to
 * have a single view for all conversations, but currently Clinician Messaging
 * conversations don't have some of the features of Patient Messaging
 * (re-assign, mark as done, mark as urgent) so it makes sense to have different
 * views for them.
 */
const ClinicianMessagingConversation = ({
    conversation,
    onClickBack,
    onAssignedSuccess,
}: {
    conversation: Conversation;
    onClickBack: (() => void) | undefined;
    onAssignedSuccess:
        | ((payload: {
              newAssignee: AssigneeSummary;
              previousAssignee: AssigneeSummary;
          }) => void)
        | undefined;
}) => {
    const isCollaborativeInboxEnabled = useIsCollaborativeInboxEnabled();
    const [isFullHeightMessageContainer, setIsFullHeightMessageContainer] =
        useState(false);
    const [replyState, setReplyState] = useState<ReplyState>("Closed");

    const onExpandMessageContainerClick = () => {
        setIsFullHeightMessageContainer((prev) => !prev);
    };

    const composeAreaOpen = replyState === "Open";

    const workspaceAvailability =
        useClinicianMessagingWorkspaceAvailabilityQuery({
            conversation,
        });

    const replyEnabled =
        !workspaceAvailability.isLoading &&
        !workspaceAvailability.areAllWorkspacesUnavailable;

    const allUsers = useAllUsers();
    const [toAssignTeam] = useTeamsByType({
        type: "ToAssignClinicianMessaging",
    });

    return (
        <StyledConversationViewGrid
            rows={
                composeAreaOpen && isFullHeightMessageContainer
                    ? "auto auto 0 1fr"
                    : "auto auto 1fr auto"
            }
            columns="minmax(0, 1fr)"
        >
            <UI.Cell>
                <ConversationViewHeader
                    conversation={conversation}
                    onClickBack={onClickBack}
                    conversationFeedback={
                        workspaceAvailability.unavailableWorkspaces.length >
                        0 ? (
                            <ClinicianConversationActionsFeedback
                                workspaces={
                                    workspaceAvailability.unavailableWorkspaces
                                }
                            />
                        ) : undefined
                    }
                />
            </UI.Cell>
            {!conversation.isFullyLoaded && (
                <>
                    <UI.Grid>
                        <UI.Cell>
                            <ConversationActions.LoadingState />
                        </UI.Cell>
                        <StyledGridItemConversationThread>
                            <UI.Cell>
                                <ConversationThreadLoadingState />
                            </UI.Cell>
                        </StyledGridItemConversationThread>
                    </UI.Grid>
                </>
            )}
            {conversation.isFullyLoaded && (
                <>
                    <UI.Cell>
                        <ConversationActions.Container>
                            <ConversationActions.Item $collapsible>
                                <ConversationActions.Participants
                                    participants={conversation.participants}
                                />
                            </ConversationActions.Item>
                            <ArchivedWorkspaceHiddenItemWrapper>
                                {isCollaborativeInboxEnabled && (
                                    <ConversationActions.Item $collapsible>
                                        <ConversationActions.Assign
                                            conversation={conversation}
                                            users={allUsers}
                                            toAssignTeam={toAssignTeam}
                                            onAssignedSuccess={
                                                onAssignedSuccess
                                            }
                                        />
                                    </ConversationActions.Item>
                                )}
                                <ConversationActions.Item>
                                    <ConversationActions.ToggleStatus
                                        conversation={conversation}
                                    />
                                </ConversationActions.Item>
                            </ArchivedWorkspaceHiddenItemWrapper>
                        </ConversationActions.Container>
                    </UI.Cell>
                    <StyledGridItemConversationThread>
                        <ConversationThread
                            conversation={conversation}
                            hasFloatingBottomElements={
                                replyEnabled && !composeAreaOpen
                            }
                        />
                    </StyledGridItemConversationThread>
                </>
            )}
            <ArchivedWorkspaceHiddenItemWrapper>
                {replyEnabled && (
                    <StyledReplyContainer>
                        <ClinicianMessageActions
                            conversation={conversation}
                            replyState={replyState}
                            setReplyState={setReplyState}
                            isExpanded={isFullHeightMessageContainer}
                            onExpandClick={onExpandMessageContainerClick}
                        />
                    </StyledReplyContainer>
                )}
            </ArchivedWorkspaceHiddenItemWrapper>
        </StyledConversationViewGrid>
    );
};

/**
 * <ConversationLoadingState />
 *
 * This is a skeleton style loading state.
 */
const ConversationLoadingState = () => {
    return (
        <StyledConversationViewGrid
            rows="auto auto 1fr auto"
            columns="minmax(0, 1fr)"
        >
            <UI.Cell>
                <ConversationViewHeaderLoading />
            </UI.Cell>
            <UI.Cell>
                <ConversationActionsLoadingState />
            </UI.Cell>
            <StyledGridItemConversationThread>
                <ConversationThreadLoadingState />
            </StyledGridItemConversationThread>

            <MessageActionsLoading />
        </StyledConversationViewGrid>
    );
};

/**
 * <ConversationLoader />
 *
 * Given a conversation ID this loads the conversation from the Concierge Layer.
 * Once the conversation is being fetched it defers responsibility for rendering
 * the conversation to its child render function.
 *
 * While the conversation is loading it renders a loading state. If the
 * conversation fails to fetch it renders an error state.
 */
const ConversationLoader = (props: {
    conversationId: Conversation["id"];
    children: (arg: { conversation: Conversation }) => JSX.Element;
}): JSX.Element => {
    const query = useConversationQuery({
        conversationId: props.conversationId,
    });

    if (query.status === "error") {
        return <GenericErrorState />;
    }

    if (query.status === "loading") {
        return <ConversationLoadingState />;
    }

    return props.children({ conversation: query.data });
};

export const ConversationView = ({
    conversationId,
    onClickBack,
    onMarkAsUnreadSuccess,
    onAssignedSuccess,
    hidePatientConversationActions = false,
}: {
    conversationId: string;
    /**
     * Add this callback so that
     * the patient header will render
     * a back arrow button on mobile devices.
     */
    onClickBack: (() => void) | undefined;
    /**
     * Add this callback to optionally navigate
     * users to a different page after they mark the
     * conversation as unread
     */
    onMarkAsUnreadSuccess: (() => void) | undefined;
    onAssignedSuccess?:
        | ((payload: {
              newAssignee: AssigneeSummary;
              previousAssignee: AssigneeSummary;
          }) => void)
        | undefined;
    hidePatientConversationActions?: boolean;
}) => {
    return (
        <ComposeAreaVisibilityProvider
            // Passing the conversation ID as a key here ensures that the
            // conversation view gets re-mounted when the conversaton
            // changes, forcing the reply state to be reset.
            key={conversationId}
        >
            <ConversationLoader conversationId={conversationId}>
                {({ conversation }) => {
                    switch (conversation.source.system) {
                        case "Ticket":
                            return (
                                <PatientMessagingConversation
                                    conversation={conversation}
                                    onClickBack={onClickBack}
                                    onMarkAsUnreadSuccess={
                                        onMarkAsUnreadSuccess
                                    }
                                    onAssignedSuccess={onAssignedSuccess}
                                    hideConversationActions={
                                        hidePatientConversationActions
                                    }
                                />
                            );

                        case "ClinicianMessaging":
                            return (
                                <ClinicianMessagingConversation
                                    conversation={conversation}
                                    onClickBack={onClickBack}
                                    onAssignedSuccess={onAssignedSuccess}
                                />
                            );
                    }
                }}
            </ConversationLoader>
        </ComposeAreaVisibilityProvider>
    );
};
