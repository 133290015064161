import {
    ChangeEvent,
    ClipboardEvent,
    FormEvent,
    forwardRef,
    useEffect,
    useState,
} from "react";

import { Ds, Input } from "@accurx/design";
import { useBasicTwoFactoredPatientSearch } from "domains/navigation/hooks/mutations/useBasicTwoFactoredPatientSearch";

import { PatientSearchResult } from "../../../../types";
import {
    BasicTwoFactoredSearchState,
    FIELDS_ID,
    FORM_ACTION,
    FORM_ID,
    FORM_LABELS,
    FormAction,
    INIT_STATE,
    TEST_ID,
    sanitise,
} from "../PatientSearchForm.helpers";
import {
    StyledClearButton,
    StyledFormFieldWrapper,
} from "../PatientSearchForm.styles";
import {
    BasicTwoFactoredSearchError,
    BasicTwoFactoredSearchSchema,
    formatErrors,
} from "../PatientSearchFormSchema";

export type BasicTwoFactoredSearchProps = {
    formData: BasicTwoFactoredSearchState;
    formAction: React.Dispatch<FormAction<BasicTwoFactoredSearchState>>;
    onSubmit: (data: PatientSearchResult[]) => void;
    onError: (error: Error) => void;
    onValidationError: (errorReason: string[]) => void;
    onClear: () => void;
    isFormDisabled?: boolean;
    onLoading?: (isLoading: boolean) => void;
    children?: React.ReactChild;
};

export const BasicTwoFactoredSearch = forwardRef<
    HTMLFormElement,
    BasicTwoFactoredSearchProps
>(
    (
        {
            formData,
            formAction,
            onSubmit,
            onError,
            onValidationError,
            onClear,
            onLoading,
            isFormDisabled,
            children,
        },
        forwardedRef,
    ) => {
        const { mutate, isLoading, reset } = useBasicTwoFactoredPatientSearch({
            onError: (error) => onError(error),
            onSuccess: (data) => onSubmit(data),
        });
        const [fieldErrors, setFieldErrors] =
            useState<BasicTwoFactoredSearchError | null>(null);

        useEffect(() => {
            onLoading?.(isLoading);
        }, [isLoading, onLoading]);

        const handleNhsNumberChange = (
            e: ChangeEvent<HTMLInputElement>,
        ): void => {
            const nhsNumber = sanitise(e.target.value);
            formAction({ type: FORM_ACTION.UPDATE, payload: { nhsNumber } });
            checkFormIsCleared(e.target.value);
        };

        const checkFormIsCleared = (nextValue: string) => {
            if (!nextValue) {
                onClear();
                reset();
            }
        };

        // Dedicated paste handler for NHS numbers formatted with spaces
        const handleNhsNumberPaste = (
            e: ClipboardEvent<HTMLInputElement>,
        ): void => {
            e.preventDefault();
            const nhsNumber = sanitise(e.clipboardData.getData("Text"));
            formAction({ type: FORM_ACTION.UPDATE, payload: { nhsNumber } });
        };

        const handleSubmit = (e: FormEvent) => {
            e.preventDefault();

            const result = BasicTwoFactoredSearchSchema.safeParse(formData);
            if (result.success) {
                setFieldErrors(null);
                mutate(formData);
            } else {
                const submitErrors = formatErrors<BasicTwoFactoredSearchError>(
                    result.error,
                );
                setFieldErrors(submitErrors);
                onValidationError(
                    [submitErrors.nhsNumber].filter(
                        (errorReason) => errorReason !== undefined,
                    ) as string[], // Despite filtering TS still consider this to be Array<string | undefined>
                );
            }
        };

        const handleClear = () => {
            setFieldErrors(null);
            formAction({
                type: FORM_ACTION.UPDATE,
                payload: INIT_STATE.BASIC_TWO_FACTORED,
            });
            onClear();
        };

        return (
            <form
                ref={forwardedRef}
                onSubmit={handleSubmit}
                id={FORM_ID.BASIC_TWO_FACTORED}
                data-testid={TEST_ID.BASIC_TWO_FACTORED}
                noValidate
            >
                <Ds.Flex gap="1.5" flexDirection="column">
                    <Ds.Flex
                        gap="1"
                        alignItems={fieldErrors ? "center" : "flex-end"}
                    >
                        <StyledFormFieldWrapper
                            label={FORM_LABELS.nhsNumber}
                            labelProps={{
                                htmlFor: `${FORM_ID.BASIC_TWO_FACTORED}-input-${FIELDS_ID.NHS_NUMBER}`,
                            }}
                            errors={[fieldErrors?.nhsNumber ?? null]}
                        >
                            <Input
                                autoFocus
                                id={`${FORM_ID.BASIC_TWO_FACTORED}-input-${FIELDS_ID.NHS_NUMBER}`}
                                value={formData.nhsNumber}
                                type="text"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                minLength={10}
                                maxLength={10}
                                required
                                onChange={handleNhsNumberChange}
                                onPaste={handleNhsNumberPaste}
                                disabled={isFormDisabled}
                                data-userflow-id="patient-search-nhs-number-input"
                                hasErrors={Boolean(fieldErrors?.nhsNumber)}
                            />
                            {formData.nhsNumber && (
                                <StyledClearButton
                                    type="button"
                                    onClick={handleClear}
                                >
                                    <Ds.Text weight="bold">Clear</Ds.Text>
                                </StyledClearButton>
                            )}
                        </StyledFormFieldWrapper>
                        <Ds.Button
                            type="submit"
                            aria-label="Search for a patient"
                            disabled={isFormDisabled}
                        >
                            <Ds.Button.Icon
                                name="Search"
                                aria-label="Search for a patient"
                                id="search-btn"
                            />
                        </Ds.Button>
                    </Ds.Flex>
                    <Ds.Flex justifyContent="flex-start">{children}</Ds.Flex>
                </Ds.Flex>
            </form>
        );
    },
);
