import { ComponentProps, useRef } from "react";

import * as UI from "@accurx/design";
import { WithTooltip } from "domains/navigation/components/Tooltip/WithTooltip";
import { useIsEllipsisActive } from "domains/navigation/hooks/useIsEllipsisActive";
import { formatAdditionalPatientDemographics } from "domains/navigation/util/format/patientDisplayInfo";

import {
    StyledContainer,
    StyledFlex,
    StyledName,
    StyledNameText,
} from "./PatientName.styles";

type TextProps = Omit<ComponentProps<typeof UI.Ds.Text>, "children">;

export type PatientNameProps = {
    name: string;
    ageYear?: number;
    gender: string;
    textProps?: TextProps;
};

export const PatientName = ({
    name,
    ageYear,
    gender,
    textProps,
}: PatientNameProps) => {
    const nameRef = useRef<HTMLElement>(null);
    const isEllipsisActive = useIsEllipsisActive(nameRef.current);

    const demographicDetails = formatAdditionalPatientDemographics({
        gender,
        ageYear,
    });

    return (
        <StyledContainer>
            <StyledFlex gap="0.5">
                <UI.VisuallyHidden as="span">
                    Patient: {name},{" "}
                </UI.VisuallyHidden>
                <WithTooltip
                    isEnabled={isEllipsisActive}
                    content={
                        <UI.Text skinny variant="note" colour="white">
                            {name}
                        </UI.Text>
                    }
                    props={{
                        root: { $withEllipsis: true },
                        content: {
                            align: "start",
                            side: "bottom",
                        },
                    }}
                >
                    {/* This <span> allows the Tooltip to pass a ref to its trigger */}
                    <StyledName>
                        <StyledNameText
                            size="small"
                            weight="bold"
                            aria-hidden="true"
                            ref={nameRef}
                            mb="0px"
                            {...textProps}
                        >
                            {name}
                        </StyledNameText>
                    </StyledName>
                </WithTooltip>
                <UI.VisuallyHidden as="span">
                    {`${ageYear ?? ""}${
                        gender !== "NotKnown" && gender !== "Unknown"
                            ? `,  ` + gender.toLowerCase()
                            : ", "
                    },`}
                </UI.VisuallyHidden>
                <UI.Ds.Text
                    size="small"
                    weight="bold"
                    mb="0px"
                    aria-hidden="true"
                    {...textProps}
                >
                    {demographicDetails}
                </UI.Ds.Text>
            </StyledFlex>
        </StyledContainer>
    );
};
