import { Ds, Link, Popover, PopoverTrigger } from "@accurx/design";
import { useToggle } from "@accurx/navigation";
import noop from "lodash/noop";

import {
    StyledDeleteButton,
    StyledListItem,
    StyledPopoverContent,
    StyledPopoverHeader,
    StyledPopoverItemButton,
} from "./ItemAttachment.styles";

type ItemAttachmentProps = {
    fileName: string;
    href: string;
    allowDelete?: boolean;
    onPreview?: () => void;
    onDeleteClick?: () => void;
    onDeleteConfirm?: (args: { reason: string }) => void;
};

const reasons: string[] = [
    "Sensitive information in attachment",
    "Patient request to delete",
    "Attachment no longer necessary",
    "Attachment sent in error",
    "Other",
];

export const ItemAttachment = ({
    href,
    fileName,
    allowDelete,
    onPreview = noop,
    onDeleteClick = noop,
    onDeleteConfirm = noop,
}: ItemAttachmentProps) => {
    const popover = useToggle();

    return (
        <StyledListItem>
            <Link href={href} openInNewTab onClick={onPreview}>
                <Link.Text text={fileName} />
                <Link.Icon />
            </Link>
            {allowDelete && (
                <Popover open={popover.isOpen} onOpenChange={popover.setIsOpen}>
                    <PopoverTrigger asChild={true}>
                        <StyledDeleteButton
                            aria-label={`Delete ${fileName}`}
                            appearance="secondary"
                            size="small"
                            onClick={() => {
                                onDeleteClick();
                                popover.setIsOpen(true);
                            }}
                        >
                            <Ds.Button.Icon name="Bin" id="secondary-delete" />
                            Delete
                        </StyledDeleteButton>
                    </PopoverTrigger>
                    <StyledPopoverContent
                        aria-label="Reason for deleting attachment"
                        align="start"
                    >
                        <StyledPopoverHeader aria-hidden={true}>
                            <b>Reason for deleting attachment</b>
                        </StyledPopoverHeader>

                        {reasons.map((reason) => (
                            <StyledPopoverItemButton
                                key={reason}
                                onClick={() => {
                                    onDeleteConfirm({ reason });
                                }}
                            >
                                {reason}
                            </StyledPopoverItemButton>
                        ))}
                    </StyledPopoverContent>
                </Popover>
            )}
        </StyledListItem>
    );
};
