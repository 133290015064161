import {
    ChangeEvent,
    ClipboardEvent,
    FormEvent,
    useEffect,
    useRef,
    useState,
} from "react";

import { Button, Ds, Input, Option, Select, Tokens } from "@accurx/design";
import { useAdvancedPatientSearch } from "domains/navigation/hooks/mutations/useAdvancedPatientSearch";
import styled from "styled-components";

import { PatientSearchResult } from "../../../../types";
import { DateInput, DateInputValue } from "../DateInput/DateInput";
import {
    AdvancedSearchState,
    FIELDS_ID,
    FORM_ACTION,
    FORM_ID,
    FORM_LABELS,
    FormAction,
    INIT_STATE,
    TEST_ID,
} from "../PatientSearchForm.helpers";
import { StyledFormFieldWrapper } from "../PatientSearchForm.styles";
import {
    AdvancedSearchError,
    AdvancedSearchSchema,
    formatErrors,
} from "../PatientSearchFormSchema";

export type AdvancedSearchProps = {
    formData: AdvancedSearchState;
    formAction: React.Dispatch<FormAction<AdvancedSearchState>>;
    onSubmit: (data: PatientSearchResult[]) => void;
    onError: (error: Error) => void;
    onValidationError: (errorReason: string[]) => void;
    onClear: () => void;
    isFormDisabled?: boolean;
    onLoading?: (isLoading: boolean) => void;
    children?: React.ReactChild;
};

const genderOptions = [
    { value: "Female", label: "Female" },
    { value: "Male", label: "Male" },
    { value: "Not Known", label: "Not known" },
    {
        value: "Not Specified",
        label: "Not specified",
    },
];

export const AdvancedSearch = ({
    formData,
    formAction,
    onSubmit,
    onError,
    onValidationError,
    onClear,
    onLoading,
    isFormDisabled,
    children,
}: AdvancedSearchProps) => {
    const dateRef = useRef<HTMLInputElement>(null);

    const { mutate, isLoading } = useAdvancedPatientSearch({
        onError: (error) => onError(error),
        onSuccess: (data) => onSubmit(data),
    });

    const [fieldErrors, setFieldErrors] = useState<AdvancedSearchError | null>(
        null,
    );

    const isDirty =
        formData.firstName ||
        formData.lastName ||
        formData.postcode ||
        formData.dateOfBirth.day ||
        formData.dateOfBirth.month ||
        formData.dateOfBirth.year;

    useEffect(() => {
        onLoading?.(isLoading);
    }, [isLoading, onLoading]);

    const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const firstName = e.target.value;
        formAction({ type: FORM_ACTION.UPDATE, payload: { firstName } });
    };

    const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const lastName = e.target.value;
        formAction({ type: FORM_ACTION.UPDATE, payload: { lastName } });
    };

    const handlePostcodeChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const postcode = e.target.value;
        formAction({ type: FORM_ACTION.UPDATE, payload: { postcode } });
    };

    const handleGenderChange = (selected: Option | Option[]): void => {
        if (!Array.isArray(selected)) {
            formAction({
                type: FORM_ACTION.UPDATE,
                payload: { gender: selected.value },
            });
            // Currently the 'Select' listbox loses focus after selection, so we set manual focus on next field
            if (dateRef.current) {
                dateRef.current.focus();
            }
        }
    };

    const handleDateChange = (
        e: ChangeEvent<HTMLInputElement> | ClipboardEvent<HTMLInputElement>,
        dateOfBirth: DateInputValue,
    ) => {
        formAction({
            type: FORM_ACTION.UPDATE,
            payload: { dateOfBirth },
        });
    };

    const onSearch = (e: FormEvent) => {
        e.preventDefault();
        const result = AdvancedSearchSchema.safeParse(formData);
        if (result.success) {
            setFieldErrors(null);
            mutate(formData);
        } else {
            const submitErrors = formatErrors<AdvancedSearchError>(
                result.error,
            );
            setFieldErrors(submitErrors);
            onValidationError(
                [
                    submitErrors.firstName,
                    submitErrors.lastName,
                    submitErrors.postcode,
                    submitErrors.gender,
                    submitErrors.dateOfBirth,
                ].filter(
                    (errorReason) => errorReason !== undefined,
                ) as string[], // Despite filtering TS still consider this to be Array<string | undefined>
            );
        }
    };

    const handleClear = () => {
        setFieldErrors(null);
        formAction({
            type: FORM_ACTION.UPDATE,
            payload: { ...INIT_STATE.ADVANCED, gender: formData.gender },
        });
        onClear();
    };

    return (
        <form
            onSubmit={(e: FormEvent) => onSearch(e)}
            id={FORM_ID.ADVANCED}
            data-testid={TEST_ID.ADVANCED}
            noValidate
        >
            <Ds.Flex flexDirection="column" gap="1.5">
                <Ds.Grid columns="1fr 1fr" gap="1.5">
                    <StyledFormFieldWrapper
                        label={FORM_LABELS.firstName}
                        labelProps={{
                            htmlFor: `${FORM_ID.ADVANCED}-input-${FIELDS_ID.FIRST_NAME}`,
                        }}
                        errors={[fieldErrors?.firstName ?? null]}
                    >
                        <Input
                            autoFocus
                            id={`${FORM_ID.ADVANCED}-input-${FIELDS_ID.FIRST_NAME}`}
                            value={formData.firstName}
                            onChange={handleFirstNameChange}
                            disabled={isFormDisabled}
                            hasErrors={Boolean(fieldErrors?.firstName)}
                        />
                    </StyledFormFieldWrapper>
                    <StyledFormFieldWrapper
                        label={FORM_LABELS.lastName}
                        labelProps={{
                            htmlFor: `${FORM_ID.ADVANCED}-input-${FIELDS_ID.LAST_NAME}`,
                        }}
                        errors={[fieldErrors?.lastName ?? null]}
                    >
                        <Input
                            id={`${FORM_ID.ADVANCED}-input-${FIELDS_ID.LAST_NAME}`}
                            value={formData.lastName}
                            onChange={handleLastNameChange}
                            disabled={isFormDisabled}
                            hasErrors={Boolean(fieldErrors?.firstName)}
                        />
                    </StyledFormFieldWrapper>
                    <StyledFormFieldWrapper
                        label={FORM_LABELS.postcode}
                        labelProps={{
                            htmlFor: `${FORM_ID.ADVANCED}-input-${FIELDS_ID.POSTCODE}`,
                        }}
                        errors={[fieldErrors?.postcode ?? null]}
                    >
                        <Input
                            id={`${FORM_ID.ADVANCED}-input-${FIELDS_ID.POSTCODE}`}
                            value={formData.postcode}
                            onChange={handlePostcodeChange}
                            disabled={isFormDisabled}
                            hasErrors={Boolean(fieldErrors?.postcode)}
                        />
                    </StyledFormFieldWrapper>
                    <StyledFormFieldWrapper
                        label={FORM_LABELS.gender}
                        labelProps={{
                            htmlFor: `${FORM_ID.ADVANCED}-select-${FIELDS_ID.GENDER}`,
                        }}
                        errors={[fieldErrors?.gender ?? null]}
                    >
                        <StyledSelectContainer>
                            <Select
                                defaultCopy={{ placeholder: "Select gender" }}
                                options={genderOptions}
                                id={`${FORM_ID.ADVANCED}-select-${FIELDS_ID.GENDER}`}
                                onChangeHandler={handleGenderChange}
                                disabled={isFormDisabled}
                                error={Boolean(fieldErrors?.gender)}
                            />
                        </StyledSelectContainer>
                    </StyledFormFieldWrapper>
                </Ds.Grid>
                <DateInput
                    ref={dateRef}
                    label={FORM_LABELS.date}
                    onChange={handleDateChange}
                    value={formData.dateOfBirth}
                    disabled={isFormDisabled}
                    error={fieldErrors?.dateOfBirth}
                />
                <Ds.Flex
                    justifyContent={children ? "space-between" : "flex-end"}
                >
                    {children}
                    <Ds.Flex justifyContent="flex-end">
                        <Ds.Flex gap="1">
                            {isDirty && (
                                <Button
                                    type="button"
                                    text="Clear"
                                    theme="transparent"
                                    onClick={handleClear}
                                />
                            )}
                            <Button
                                type="submit"
                                aria-label="Search for a patient"
                                icon={{
                                    name: "Search",
                                    title: "Search for a patient",
                                    id: "search-btn",
                                }}
                                disabled={isFormDisabled}
                            />
                        </Ds.Flex>
                    </Ds.Flex>
                </Ds.Flex>
            </Ds.Flex>
        </form>
    );
};

// The deprecated Select component needed to be tweaked to align with designs
// Once the migrated select component is available this overwrite can be removed.
const StyledSelectContainer = styled.div`
    & > div > div {
        border: 1px solid ${Tokens.COLOURS.greyscale.stone};

        > div > div {
            padding: 8px;
        }

        &[aria-expanded="false"] {
            height: 40px;

            & span {
                margin-top: -4px;
            }
        }
    }
`;
