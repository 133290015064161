import { Ds } from "@accurx/design";

import { StyledButton } from "./BackButton.styles";

export const BackButton = ({ onClickBack }: { onClickBack: () => void }) => {
    return (
        <nav>
            <StyledButton
                appearance="tertiary"
                title="Close conversation"
                onClick={onClickBack}
            >
                <Ds.Button.Icon
                    name="Arrow"
                    id="close-convsersation"
                    rotate="270"
                />
            </StyledButton>
        </nav>
    );
};
