import {
    IdType,
    SmsSuccessPredictionRequest,
    SmsSuccessPredictionResponse,
} from "@accurx/api/patient-messaging";
import { useCurrentWorkspace } from "@accurx/auth";
import { PatientExternalId } from "@accurx/concierge/types";
import { getApiEndpoint, httpClient, returnDataOrThrow } from "@accurx/shared";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";

type SmsDeliveryPredictionVariables = {
    patientExternalIds: PatientExternalId[];
    mobileNumber: SmsSuccessPredictionRequest["mobileNumber"];
};

export const useSmsDeliveryPredictionQuery = ({
    mobileNumber,
    patientExternalIds,
    ...options
}: SmsDeliveryPredictionVariables &
    UseQueryOptions<SmsSuccessPredictionResponse, Error>) => {
    const workspace = useCurrentWorkspace();
    return useQuery({
        queryKey: [
            "SmsPrediction",
            workspace.orgId,
            mobileNumber,
            patientExternalIds,
        ],
        queryFn: async () => {
            const requestDto: SmsSuccessPredictionRequest = {
                organisationId: workspace.orgId,
                patientExternalIds: patientExternalIds.map(
                    ({ type, value }) => ({
                        value,
                        type: IdType[type],
                    }),
                ),
                mobileNumber,
            };

            const response = await httpClient.postJsonReturnJsonSafeAsync(
                getApiEndpoint({
                    path: "/api/patientmessaging/smssuccessprediction",
                }),
                requestDto,
            );

            return returnDataOrThrow<SmsSuccessPredictionResponse>(response);
        },
        ...options,
    });
};
