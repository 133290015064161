import React from "react";

import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { TwoThirdsContainer } from "app/layout/TwoThirdsContainer/TwoThirdsContainer";
import { SettingsPassword } from "app/practices/userSettings/SettingsPassword";

export const PasswordPage = () => {
    return (
        <TwoThirdsContainer>
            <PageHeader title="Password" type={PageHeaderType.ListPage} />
            <SettingsPassword />
        </TwoThirdsContainer>
    );
};
