import { Tokens } from "@accurx/design";
import styled from "styled-components";

// Contain size of the select field
export const StyledSelectContainer = styled.div`
    flex: 2;
`;

export const StyledInputContainer = styled.div`
    flex: 1;
`;

export const StyledHeader = styled.div`
    display: flex;
`;

export const StyledTextComponent = styled.div`
    padding-top: ${Tokens.SIZES[0.5]};
`;

export const SubTitleText = styled.span`
    display: inline-block;
    max-width: 100%;
    overflow-wrap: break-word;
    white-space: normal;
`;
