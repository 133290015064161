import { useCallback, useEffect, useState } from "react";

import { useMeaningfulActionAnalyticsProps } from "@accurx/analytics";
import { FeatureName } from "@accurx/auth";
import { Button, Feedback, Flex, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { QuickViewPortal } from "@accurx/quick-view";
import { EmailAddressHelper, Log, MobileNumberHelper } from "@accurx/shared";
import { ArchivedWorkspaceHiddenItemWrapper } from "@accurx/workspace-management";
import { shallowEqual } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

import { UserflowEvent, trackUserflowEvent } from "app/account/Userflow";
import { FlemingAnalyticsTracker } from "app/analytics";
import {
    getAnalyticsConversationInitiatedBy,
    getAnalyticsConversationMatchType,
    getAnalyticsConversationsAssigneeType,
    getAnalyticsMessageContainsPIFULink,
} from "app/analytics/ConversationsAnalyticsMapper";
import {
    SentPatientMessageRudderStackAnalyticsProps,
    getMessagePracticeStatus,
} from "app/analytics/FlemingAnalytics";
import { useCanSelectedPatientReceiveMessageGp } from "app/patients/hooks";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import {
    LoadingPatientPill,
    PatientPill,
} from "app/sharedComponents/PatientPill";
import { getPatientNhsNumber } from "app/workspaceConversations/utils/patient.utils";
import { generateSignature } from "shared/MessageHelper";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { PatientHelper } from "shared/PatientHelper";
import { useWorkspaceId } from "shared/concierge/conversations/hooks";
import { formatPatientDisplayName } from "shared/formatters/formatPatientDisplayName";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

import { Template } from "../MessageTemplates/MessageTemplates.types";
import { ComposeArea } from "./ComposeArea/ComposeArea";
import {
    StyledCollapseButton,
    StyledTopButtonRow,
} from "./ComposeArea/ComposeArea.styles";
import { HandleSendMessageParams } from "./ComposeArea/ComposeArea.types";
import {
    MessageType,
    generateEmailConfirmation,
    generateGreeting,
    getPatientMatchingInformation,
    sendMessage,
} from "./Conversation.helpers";
import { useFetchPatientData } from "./Conversation.hooks";
import {
    StyledBackButtonWrapper,
    StyledCompose,
    StyledPageWrapper,
    StyledThreadContainer,
} from "./Conversation.styles";
import { ConversationProps } from "./Conversation.types";
import { ConversationHeader } from "./ConversationHeader/ConversationHeader";
import { ConversationThread } from "./ConversationThread/ConversationThread";
import { QuestionnaireButton } from "./FloreyQuestionnaire/QuestionnaireButton";
import { SinglePatientQuestionnairesList } from "./FloreyQuestionnaire/SinglePatientQuestionnaireList";
import { DisplayFloreyMessageTemplate } from "./FloreyQuestionnaire/types";
import { PatientMatch } from "./PatientMatch/PatientMatch";
import { SendVia } from "./SendVia/SendVia";
import { SendViaOption, SendViaOptionsType } from "./SendVia/SendVia.types";

export const Conversation = ({
    conversation,
    conversationActions,
    patient: patientData,
    currentUserId,
    composeAreaDefaultOpen,
    conversationHeaderSlot,
}: ConversationProps): JSX.Element => {
    const history = useHistory<{ appOrigin?: string }>();
    const patient = patientData?.data;
    const [showQuestionnairesList, setShowQuestionnairesList] = useState(false);

    const [isComposeAreaOpen, setIsComposeAreaOpen] = useState(
        !!composeAreaDefaultOpen,
    );

    const [sendViaOptions, setSendViaOptions] = useState<SendViaOptionsType>({
        id: "MobilePds",
        value: null,
        error: null,
    });
    const isSingleSendFloreysEnabled = useIsFeatureEnabled(
        FeatureName.WebSingleSendFloreys,
    );
    const isCollaborativeWebInboxEnabled = useIsFeatureEnabled(
        FeatureName.CollaborativeWebInbox,
    );
    // Note: Once we have rolled out email to all of Web this feature flag will be retired
    const hasWebEmail = useIsFeatureEnabled(
        FeatureName.WebPatientMessageEmails,
    );
    const trySendViaNhsApp = useIsFeatureEnabled(FeatureName.SendSmsViaNhsApp);

    const canUseQuestionnaires =
        isSingleSendFloreysEnabled && isCollaborativeWebInboxEnabled;

    const greeting = generateGreeting(patient);

    const signature = useAppSelector((state) =>
        generateSignature(state.account.user),
    );

    const [messageParts, setMessageParts] = useState({
        greeting,
        body: "",
        signature,
    });

    // Set the initial message when the greeting loads (i.e. when the patient has loaded)
    useEffect(() => {
        setMessageParts({
            greeting,
            body: "",
            signature,
        });
    }, [greeting, signature]);

    const workspaceId = useWorkspaceId();

    const conversationIsDone = conversation?.status === "Done";

    const patientMatchingInfo = getPatientMatchingInformation(conversation);

    const [selectedQuestionnaire, setSelectedQuestionnaire] =
        useState<DisplayFloreyMessageTemplate | null>(null);

    // Fetch patient mobile number and patient token via PDS search.
    const {
        result: { mobileNumber: mobileNumberFromPDS, patientToken },
        success: fetchPatientDetailsSuccess,
        isLoading: isLoadingPatientDetails,
    } = useFetchPatientData({ patient, workspaceId });

    useEffect(() => {
        if (!isLoadingPatientDetails && !fetchPatientDetailsSuccess) {
            setSendViaOptions((prevSendViaOptions) => ({
                ...prevSendViaOptions,
                id: "MobileManual",
                error: "Could not load patient's mobile number.",
            }));
        } else {
            setSendViaOptions((prevSendViaOptions) => ({
                ...prevSendViaOptions,
                value: mobileNumberFromPDS,
                id: mobileNumberFromPDS ? "MobilePds" : "MobileManual",
            }));
        }
    }, [
        fetchPatientDetailsSuccess,
        isLoadingPatientDetails,
        mobileNumberFromPDS,
    ]);

    useEffect(() => {
        if (!conversation?.isFullyLoaded || patientData.isLoading) {
            return;
        }

        const patientNhsNumber = getPatientNhsNumber(
            patient?.externalIds || [],
        );
        const isTestPatient = PatientHelper.isDemoPatient(
            patientNhsNumber || "",
        );

        FlemingAnalyticsTracker.trackPatientConversationPageLoad({
            ...loggedInProps,
            conversationId: conversation.id,
            conversationInitiatedBy:
                getAnalyticsConversationInitiatedBy(conversation),
            isDone: conversation.status === "Done",
            conversationMessageCount: conversation.items.length,
            isTestPatient,
            assigneeType: getAnalyticsConversationsAssigneeType(
                conversation.assignee,
                currentUserId,
            ),
            matchStatus: getAnalyticsConversationMatchType(
                patientMatchingInfo.matchType,
            ),
            isPatientInitiatedFollowUpUser: isPifuEnabled,
        });

        // not including all dependencies as we only want this event to fire once when the conversation and patient have loaded
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversation?.id, conversation?.isFullyLoaded, patientData.isLoading]);

    const {
        matchPatientToConversation,
        onSendMessageSuccess,
        markAsOpen,
        markAsDone,
        markItemAsRead: markAsRead,
        assign,
    } = conversationActions;

    const markItemAsRead = useCallback(
        async (itemId: string) => {
            const patientExternalId = patient ? patient.externalIds[0] : null;
            try {
                await markAsRead(itemId, patientExternalId);
            } catch (error) {
                Log.error(
                    `Mark conversation item as read action failed.${
                        error && ` ${error}`
                    }`,
                );
            }
        },
        [patient, markAsRead],
    );

    const patientDisplayName = formatPatientDisplayName({
        firstName: patient?.firstName || null,
        familyName: patient?.familyName || null,
    });

    const userFullName = useAppSelector(
        ({ account }) => account.user?.fullName,
    );

    const loggedInProps = useFlemingLoggedInAnalytics();
    const meaningfulActionProps = useMeaningfulActionAnalyticsProps();
    const patientOrigin = useAppSelector(
        (state) => state.selectProduct.searchPatientOrigin,
        shallowEqual,
    );
    const nationalCode = useAppSelector(
        ({ account }) =>
            OrganisationHelper.getOrganisation(account)?.nationalCode,
    );
    const isPifuEnabled = useIsFeatureEnabled(
        FeatureName.WebPatientLedFollowUp,
    );
    const isMessageGpEnabledForCurrentUser = useIsFeatureEnabled(
        FeatureName.CaregiverInitiated,
    );
    const isMessageGpEnabledForPatient =
        useCanSelectedPatientReceiveMessageGp();

    useAccurxWebTitle("View conversation with patient");

    const getMessageType = (): MessageType =>
        sendViaOptions.id === "EmailManual" ? "Email" : "Sms";

    const getSendMessageAnalyticsProps = ({
        message,
        withAttachment,
        template,
        templateAttachmentCount,
        patientNhsNumber,
        enablePatientResponse,
        questionnaire,
        messageLength,
        isMessageGpEnabledForCurrentUser,
        isMessageGpEnabledForPatient,
    }: {
        message: string;
        withAttachment: boolean;
        template: Template | null;
        templateAttachmentCount: number;
        patientNhsNumber: string | undefined;
        enablePatientResponse: boolean;
        questionnaire: DisplayFloreyMessageTemplate | null;
        messageLength: number;
        isMessageGpEnabledForCurrentUser: boolean;
        isMessageGpEnabledForPatient: boolean;
    }): SentPatientMessageRudderStackAnalyticsProps => {
        const isTestPatient = PatientHelper.isDemoPatient(
            patientNhsNumber || "",
        );
        const conversationInitiatedBy =
            getAnalyticsConversationInitiatedBy(conversation);
        const messageContainsPIFULink = getAnalyticsMessageContainsPIFULink(
            isPifuEnabled,
            nationalCode,
            message,
        );
        const isTemplateUsed = template !== null;

        const selectedTemplateGroup = (): string => {
            if (template === null) return "";

            return template.isPreset ? template.group || "" : "Custom";
        };

        return {
            ...loggedInProps,
            ...meaningfulActionProps,
            isTestPatient: isTestPatient,
            origin: patientOrigin,
            withTemplate: isTemplateUsed,
            withAttachment,
            countAttachmentFromTemplate: templateAttachmentCount,
            withPatientResponse: enablePatientResponse,
            templateName: template?.title || null,
            templateGroup: selectedTemplateGroup(),
            isPresetTemplate: template?.isPreset || false,
            conversationId: conversation?.id || "",
            conversationInitiatedBy: conversationInitiatedBy,
            withPatientInitiatedFollowUpLink: messageContainsPIFULink,
            isPatientInitiatedFollowUpUser: isPifuEnabled,
            isMobileNumberOverridden: sendViaOptions.id !== "MobilePds",
            messageType: getMessageType(),
            messageLength,
            inviteType: "Anytime", // Currently the new conversation screen doesn't support patient list appointments.
            isReply: conversation !== null,
            withFlorey: questionnaire !== null,
            floreyName: questionnaire?.title || null,
            messagePracticeStatus: getMessagePracticeStatus({
                isMessageGpEnabledForCurrentUser,
                isMessageGpEnabledForPatient,
            }),
        };
    };

    const getIsValidMobileNumber = (mobileNumber: string) => {
        const isMobileNumberValid = MobileNumberHelper.validatePhoneNumber(
            mobileNumber,
            true,
            mobileNumberFromPDS ?? "",
        );

        if (!isMobileNumberValid) {
            setSendViaOptions({
                ...sendViaOptions,
                error: "Please enter a valid UK mobile number",
                id: "MobileManual",
            });
            return false;
        }

        return true;
    };

    const getIsValidEmailAddress = (emailAddress: string) => {
        const isValidEmail =
            EmailAddressHelper.isValidEmailAddress(emailAddress);

        if (isValidEmail === false) {
            setSendViaOptions({
                ...sendViaOptions,
                error: "Please enter a valid email address",
                id: "EmailManual",
            });
            return false;
        }

        return true;
    };

    const handleSendMessage = async (params: HandleSendMessageParams) => {
        if (!params.isValidMessage) {
            return { success: false };
        }

        const sendViaEmail = getMessageType() === "Email";
        const mobileNumber = sendViaEmail ? null : sendViaOptions.value;
        const emailAddress = sendViaEmail ? sendViaOptions.value : null;

        if (sendViaEmail) {
            const isValidEmail = getIsValidEmailAddress(emailAddress ?? "");
            if (!isValidEmail) {
                return { success: false };
            }
        } else {
            const isValidMobileNumber = getIsValidMobileNumber(
                mobileNumber ?? "",
            );
            if (!isValidMobileNumber) {
                return { success: false };
            }
        }

        const {
            message,
            template,
            uploadedFileIds,
            questionnaire,
            enablePatientResponse,
        } = params;

        const useMobileNumberFromPDS = sendViaOptions.id === "MobilePds";

        const patientNhsNumber = getPatientNhsNumber(
            patient?.externalIds || [],
        );

        const confirmationEmail = generateEmailConfirmation({
            patientName: patientDisplayName,
            sentByUserName: userFullName,
            nhsNumber: patientNhsNumber,
            mobileNumber,
        });

        const templateAttachmentCount =
            template?.attachedDocuments?.length || 0;

        const sendMessageAnalyticsProps = getSendMessageAnalyticsProps({
            message,
            withAttachment:
                templateAttachmentCount + uploadedFileIds.length > 0,
            template,
            templateAttachmentCount,
            patientNhsNumber,
            enablePatientResponse,
            questionnaire,
            messageLength: params.messageLength,
            isMessageGpEnabledForCurrentUser,
            isMessageGpEnabledForPatient,
        });

        if (history.location.state?.appOrigin) {
            sendMessageAnalyticsProps.appOrigin =
                history.location.state.appOrigin;
        }

        if (!patientNhsNumber) {
            FlemingAnalyticsTracker.trackPatientMessageSendButtonClickError({
                ...sendMessageAnalyticsProps,
                withQuestionnaire: sendMessageAnalyticsProps.withFlorey,
                withBookingLink: false,
            });

            toast(
                <Feedback title="Unable to send message" colour="error">
                    <Text>Patient has no NHS number</Text>
                </Feedback>,
            );

            return { success: false };
        }

        FlemingAnalyticsTracker.trackPatientMessageSendButtonClickSuccess({
            ...sendMessageAnalyticsProps,
            withQuestionnaire: sendMessageAnalyticsProps.withFlorey,
            withBookingLink: false,
        });

        const { result, success, error } = await sendMessage({
            conversationId: conversation?.id || null,
            messageOptions: {
                questionnaire,
                message,
                patientExternalIds: patient?.externalIds || [],
                mobileNumber,
                emailAddress,
                patientToken,
                workspaceId,
                confirmationEmail,
                useMobileNumberFromPDS,
                enablePatientResponse,
                trySendViaNhsApp,
                attachedDocumentIds: uploadedFileIds,
                messageTemplate: {
                    id: template?.id || 0,
                    attachmentIds: (template?.attachedDocuments || []).map(
                        ({ documentId }) => parseInt(documentId, 10),
                    ),
                    isPreset: template?.isPreset,
                    group: template?.group,
                    name: template?.title,
                    level: template?.owner,
                },
            },
        });

        if (!success || !result) {
            FlemingAnalyticsTracker.trackSentPatientMessageFailure({
                ...sendMessageAnalyticsProps,
                withQuestionnaire: sendMessageAnalyticsProps.withFlorey,
                withBookingLink: false,
            });

            toast(
                <Feedback title="Unable to send message" colour="error">
                    {error && <Text>{error}</Text>}
                </Feedback>,
            );

            return { success: false };
        }

        FlemingAnalyticsTracker.trackSentPatientMessageSuccess({
            ...sendMessageAnalyticsProps,
            withQuestionnaire: sendMessageAnalyticsProps.withFlorey,
            withBookingLink: false,
        });

        trackUserflowEvent(UserflowEvent.PATIENT_MESSAGE_SENT);

        const allMessages = (result.messages ?? [])
            .concat(result.emailMessages ?? [])
            .concat(result.nhsAppMessages ?? []);

        onSendMessageSuccess(allMessages);

        setIsComposeAreaOpen(false);
        return { success: true };
    };

    const displayConversationHeader = () => {
        return (
            <ConversationHeader
                conversation={conversation}
                markAsDone={markAsDone}
                assign={assign}
                markAsOpen={markAsOpen}
                patient={patient}
                suggestedMatch={patientMatchingInfo.suggestedMatch}
                matchType={patientMatchingInfo.matchType}
                currentUserId={currentUserId}
                isLoading={patientData.isLoading}
            >
                {conversationHeaderSlot}
            </ConversationHeader>
        );
    };

    // If a conversation is marked as 'done' and the user has a way to re-open the conversation
    const isEndOfConversation =
        !!conversationActions.markAsOpen && conversationIsDone;

    // If it's not the end of a conversation (ie it's not 'Done' or they don't have a way to re-open it)
    // then a user should be able to send a message
    const canComposeMessage = !!patient && !isEndOfConversation;

    // Either the compose area has been collapsed, or the user cannot compose a message
    const shouldDisplayConversation = !isComposeAreaOpen || !canComposeMessage;

    const sendViaOptionChange = (option: SendViaOption) => {
        const value = option.id === "MobilePds" ? mobileNumberFromPDS : "";

        setSendViaOptions({
            ...sendViaOptions,
            id: option.id,
            value: value ?? "",
            error: "", // Reset error on change
        });
    };

    const sendViaOnInputChange = (value: string) => {
        setSendViaOptions({ ...sendViaOptions, error: null, value });
    };

    const onSeeQuestionnairesClick = () => setShowQuestionnairesList(true);

    const onRemoveQuestionnaireClick = () => {
        setSelectedQuestionnaire(null);
        setMessageParts({
            greeting,
            body: "",
            signature,
        });
        toast(
            <Feedback colour="success" title="Questionnaire removed">
                <Text skinny>{selectedQuestionnaire?.title}</Text>
            </Feedback>,
        );
    };

    const renderComposeArea = () => {
        return (
            canComposeMessage && (
                <StyledCompose isOpen={isComposeAreaOpen}>
                    {isComposeAreaOpen && (
                        <>
                            <StyledTopButtonRow data-testid="top-of-compose-area">
                                <Flex gap="1.5" alignItems="center">
                                    <Text variant="label" skinny>
                                        To:
                                    </Text>{" "}
                                    {isLoadingPatientDetails ? (
                                        <LoadingPatientPill verbose />
                                    ) : (
                                        <PatientPill
                                            patient={patient}
                                            verbose
                                        />
                                    )}
                                </Flex>
                                <StyledCollapseButton
                                    theme="secondary"
                                    icon={{
                                        name: "Slide",
                                        rotation: "left",
                                    }}
                                    text="Collapse"
                                    onClick={() => {
                                        setIsComposeAreaOpen(false);
                                        setShowQuestionnairesList(false);
                                    }}
                                />
                            </StyledTopButtonRow>
                            <StyledTopButtonRow>
                                <SendVia
                                    defaultMobileNumber={MobileNumberHelper.obfuscate(
                                        mobileNumberFromPDS,
                                    )}
                                    currentValue={sendViaOptions.value ?? ""}
                                    onInputChange={sendViaOnInputChange}
                                    error={sendViaOptions.error}
                                    isLoading={isLoadingPatientDetails}
                                    onOptionChange={sendViaOptionChange}
                                    activeOption={sendViaOptions.id}
                                    hasEmailEnabled={hasWebEmail}
                                />
                            </StyledTopButtonRow>
                        </>
                    )}
                    <ComposeArea
                        workspaceId={workspaceId}
                        handleSendMessage={handleSendMessage}
                        messageParts={messageParts}
                        isLoading={isLoadingPatientDetails}
                        isOpen={isComposeAreaOpen}
                        setIsOpen={setIsComposeAreaOpen}
                        canTogglePatientResponse={
                            selectedQuestionnaire === null
                        }
                        canUseAttachFiles={selectedQuestionnaire === null}
                        {...(canUseQuestionnaires
                            ? {
                                  canUseQuestionnaires: true,
                                  selectedQuestionnaire,
                                  unSelectQuestionnaire: () =>
                                      setSelectedQuestionnaire(null),
                                  questionnaireButton: (
                                      <QuestionnaireButton
                                          hasQuestionnaireSelected={
                                              !!selectedQuestionnaire
                                          }
                                          onRemoveQuestionnaireClick={
                                              onRemoveQuestionnaireClick
                                          }
                                          onSeeQuestionnairesClick={
                                              onSeeQuestionnairesClick
                                          }
                                      />
                                  ),
                                  displayQuestionnaireResponseBanner:
                                      conversation === null, // Questionnaire responses are only assigned to default team if there is not already an existing conversation, so we only show the banner if a response will lead to a conversation being created with the assignee
                              }
                            : { canUseQuestionnaires: false })}
                    />
                </StyledCompose>
            )
        );
    };

    return (
        <>
            {shouldDisplayConversation && (
                <StyledPageWrapper data-testid="conversation">
                    <StyledBackButtonWrapper>
                        <Button
                            onClick={history.goBack}
                            text="Back"
                            icon={{
                                name: "ArrowTail",
                                colour: "metal",
                                rotation: "left",
                                style: "Line",
                            }}
                            theme="transparent"
                        />
                    </StyledBackButtonWrapper>
                    {matchPatientToConversation ? (
                        <PatientMatch
                            matchType={patientMatchingInfo.matchType}
                            suggestedMatch={patientMatchingInfo.suggestedMatch}
                            matchPatientToConversation={async (
                                patientToken: string,
                            ) => {
                                return await matchPatientToConversation(
                                    patientToken,
                                );
                            }}
                            conversationIsDone={conversationIsDone}
                        >
                            {displayConversationHeader()}
                        </PatientMatch>
                    ) : (
                        displayConversationHeader()
                    )}
                    <StyledThreadContainer>
                        <ConversationThread
                            conversationItems={conversation?.items || []}
                            patient={patient}
                            isEndOfConversation={isEndOfConversation}
                            markItemAsRead={markItemAsRead}
                            matchType={patientMatchingInfo.matchType}
                            currentUserId={currentUserId}
                        />
                    </StyledThreadContainer>
                </StyledPageWrapper>
            )}
            {conversation?.items ? (
                <ArchivedWorkspaceHiddenItemWrapper>
                    {renderComposeArea()}
                </ArchivedWorkspaceHiddenItemWrapper>
            ) : (
                renderComposeArea()
            )}
            <QuickViewPortal
                isOpen={showQuestionnairesList}
                onClose={() => setShowQuestionnairesList(false)}
            >
                <QuickViewPortal.Header>
                    <Button
                        onClick={() => setShowQuestionnairesList(false)}
                        text="Close"
                        icon={{
                            name: "Cross",
                            colour: "metal",
                            style: "Line",
                        }}
                        theme="transparent"
                    />
                </QuickViewPortal.Header>
                <QuickViewPortal.Body>
                    <SinglePatientQuestionnairesList
                        workspaceId={workspaceId}
                        patient={patient}
                        onUseQuestionnaireClick={(
                            template: DisplayFloreyMessageTemplate,
                        ) => {
                            setSelectedQuestionnaire(template);
                            setMessageParts({
                                greeting,
                                body: template.body,
                                signature,
                            });
                            setShowQuestionnairesList(false);

                            toast(
                                <Feedback
                                    colour="success"
                                    title="Questionnaire selected"
                                >
                                    <Text skinny>{template.title}</Text>
                                </Feedback>,
                            );
                        }}
                    />
                </QuickViewPortal.Body>
            </QuickViewPortal>
        </>
    );
};
