import { LinksResponse } from "@accurx/api/patient-initiated";
import { getApiEndpoint, httpClient, returnDataOrThrow } from "@accurx/shared";
import { useQuery } from "@tanstack/react-query";

export const usePatientInitiatedLinksQuery = ({
    nationalCode,
}: {
    nationalCode: string;
}) =>
    useQuery<LinksResponse>({
        queryKey: ["PatientInitiated", "Links", { nationalCode }],
        queryFn: async () => {
            const response = await httpClient.getReturnJsonSafeAsync(
                getApiEndpoint({
                    path: "/api/patientInitiated/workspaceSettings/:nationalCode/links",
                    params: { nationalCode },
                }),
            );
            return returnDataOrThrow(response);
        },
    });
