import { PatientThreadContentType } from "@accurx/api/ticket";
import { EventPayload, SignalRSubscriptionEvent } from "@accurx/realtime";
import { Log } from "@accurx/shared";
import { mapPatientThreadUserGroupToTeamSummary } from "domains/concierge/internal/api/shared/mappers/mapPatientThreadUserGroupToTeamSummary";
import { mapPatientThreadPatientDetailsToPatientSummary } from "domains/concierge/internal/api/shared/mappers/patients/mapPatientThreadPatientDetailsToPatientSummary";
import { Subscription } from "domains/concierge/internal/hooks/useSignalRSubscription";
import { actions } from "domains/concierge/internal/store";
import { ConciergeUpdates } from "domains/concierge/internal/types/ConciergeUpdates";
import { isInstance } from "domains/concierge/internal/util/isInstance";
import isNil from "lodash/isNil";

import { mapPatientThreadUserToUserSummary } from "../mappers/mapPatientThreadUserToUserSummary";
import { mapTicketItemToConversationItem } from "../mappers/mapTicketItemToConversationItem";
import { mapTicketItemUpdateToConversation } from "../mappers/mapTicketItemUpdateToConversation";

export const isForWorkspace =
    (workspaceId: number) =>
    (
        payload: EventPayload<SignalRSubscriptionEvent.OnPatientThreadItemChanged>,
    ) => {
        if (isNil(payload)) {
            Log.error(
                "Unable to process undefined/null event from SignalR for 'OnPatientThreadItemChanged'",
                { tags: { product: "Inbox" } },
            );
            return false;
        }
        return payload?.organisationId === workspaceId;
    };

export const mapToConciergeUpdates = (
    payload: EventPayload<SignalRSubscriptionEvent.OnPatientThreadItemChanged>,
): ConciergeUpdates => {
    const conversation = mapTicketItemUpdateToConversation(payload);
    const conversations = conversation ? [conversation] : [];

    const patients =
        payload.referencedPatients
            ?.map(mapPatientThreadPatientDetailsToPatientSummary)
            .filter(isInstance) ?? [];

    const users =
        payload.users
            ?.map(mapPatientThreadUserToUserSummary)
            .filter(isInstance) ?? [];

    const teams =
        payload.userGroups
            ?.map(mapPatientThreadUserGroupToTeamSummary)
            .filter(isInstance) ?? [];

    return {
        conversations,
        patients,
        users,
        teams,
    };
};

export const OnPatientThreadItemChanged: Subscription = ({
    subscribe,
    dispatch,
    workspaceId,
    currentUserId,
}) => {
    /**
     * Add logging so that we can measure the latency between a note being
     * created and the inbox receiving the update
     */
    let logsToPush: unknown[] = [];
    setInterval(() => {
        if (logsToPush.length === 0) return;

        Log.info("Unified Inbox: Patient thread update latencies", {
            tags: {
                logs: JSON.stringify(logsToPush),
                numberOfLogsSent: logsToPush.length,
                isWebView: !!window.chrome?.webview,
            },
        });

        logsToPush = [];
    }, 1_000 * 60 * 5);
    const subscription = subscribe({
        type: SignalRSubscriptionEvent.OnPatientThreadItemChanged,
        onEvent: (payload) => {
            if (!payload) {
                Log.error(
                    "Unable to process undefined/null event from SignalR for 'OnPatientThreadItemChanged'",
                    { tags: { product: "Inbox" } },
                );
                return;
            }
            if (
                [
                    PatientThreadContentType.FloreyResponseNote,
                    PatientThreadContentType.PatientTriageRequestNote,
                    PatientThreadContentType.StateChange,
                ].includes(payload.type)
            ) {
                const item = mapTicketItemToConversationItem(payload);
                logsToPush.push({
                    LogCreatedTime: new Date().toISOString(),
                    NoteCreatedTime: item?.createdAt,
                    Type: item?.contentType,
                    NoteId: item?.serverId,
                    UserId: currentUserId,
                    ConciergeWorkspaceId: workspaceId,
                    EventWorkspaceId: payload?.organisationId,
                    UpdateCreatedTime: payload.updateCreatedTime,
                    TimeDeltaBetweenClientTimeAndPayloadCreated:
                        new Date().getTime() -
                        new Date(payload.updateCreatedTime).getTime(),
                });
            }
            if (!isForWorkspace(workspaceId)(payload)) return;

            const mappedPayload = mapToConciergeUpdates(payload);

            if (mappedPayload.conversations.length === 0) {
                Log.info(
                    "Conversation empty after mapping to concierge update",
                    {
                        tags: {
                            isWebView: !!window.chrome?.webview,
                            UserId: currentUserId,
                            EventWorkspaceId: payload?.organisationId,
                            ticketModel: JSON.stringify(payload),
                        },
                    },
                );
            }

            if (mappedPayload.conversations[0]?.items.length === 0) {
                Log.info(
                    "Conversation items not present after mapping to concierge update",
                    {
                        tags: {
                            isWebView: !!window.chrome?.webview,
                            UserId: currentUserId,
                            EventWorkspaceId: payload?.organisationId,
                            ticketModel: JSON.stringify(payload),
                        },
                    },
                );
            }

            dispatch(
                actions.processUpdates({
                    source: "SignalR:OnPatientThreadItemChanged",
                    ...mappedPayload,
                }),
            );
        },
    });

    return subscription.unsubscribe.bind(subscription);
};
