import { useEffect, useRef, useState } from "react";

import { conversationIdMapper } from "@accurx/concierge";
import {
    NoteTypingReceive,
    SignalRSubscriptionEvent,
    useSubscribeSignalREvent,
} from "@accurx/realtime";
import { Log } from "@accurx/shared";
import { TYPING_SEND_THROTTLE_MS } from "domains/inbox/util";
import uniq from "lodash/uniq";
import { v4 as uuid } from "uuid";

type UserDisplayName = string;
type UserIsTypingEventId = string;

const mapUserIsTypingTicketIdToConversationId = (
    payload: NoteTypingReceive,
) => {
    if (!payload.ticketIdentity?.type || !payload.ticketIdentity.id) {
        Log.error(
            "Received an event for OnPatientThreadNoteTyping signalR without a valid ticketIdentity - ignoring the event",
            {
                tags: {
                    product: "Inbox",
                    itemType: payload.ticketIdentity?.type,
                    itemId: payload.ticketIdentity?.id,
                },
            },
        );

        return;
    }
    const processedTicketIdentity = {
        type: payload.ticketIdentity.type,
        id: payload.ticketIdentity.id,
    };
    return conversationIdMapper.ticket.fromSource(processedTicketIdentity);
};

export const useCurrentlyTypingUsers = (conversationId: string) => {
    const userIsTypingEventTracker = useRef<
        Record<UserIsTypingEventId, UserDisplayName>
    >({});
    const eventTrackerClearingTimeouts = useRef<
        Array<ReturnType<typeof setTimeout>>
    >([]);

    const [conversationUsersTyping, setConversationUsersTyping] = useState<
        string[]
    >([]);

    useEffect(() => {
        // Reset event tracking when conversation changes
        userIsTypingEventTracker.current = {};
        eventTrackerClearingTimeouts.current = [];

        // Clean up outstanding timeouts when hook unmounts
        const timeouts = eventTrackerClearingTimeouts.current;
        return () => {
            timeouts.forEach((timeout) => clearTimeout(timeout));
        };
    }, [conversationId]);

    useSubscribeSignalREvent({
        type: SignalRSubscriptionEvent.OnPatientThreadNoteTyping,
        onEvent: (payload) => {
            const notificationConversationId =
                mapUserIsTypingTicketIdToConversationId(payload);

            if (notificationConversationId === conversationId) {
                const userIsTypingEventId = uuid();
                const userDisplayName =
                    payload.sender?.name ?? payload.sender?.email ?? "A user";
                // Keep track every time we receive an event for a user typing on the conversation
                userIsTypingEventTracker.current[userIsTypingEventId] =
                    userDisplayName;

                // Set the list of unique users typing to display in UI
                setConversationUsersTyping(
                    uniq(Object.values(userIsTypingEventTracker.current)),
                );
                // After 2.5s that the event comes in, make sure to clear
                // it from the event tracker
                eventTrackerClearingTimeouts.current.push(
                    setTimeout(() => {
                        delete userIsTypingEventTracker.current[
                            userIsTypingEventId
                        ];
                        const newUsersTypingArr = uniq(
                            Object.values(userIsTypingEventTracker.current),
                        );
                        setConversationUsersTyping(newUsersTypingArr);
                    }, TYPING_SEND_THROTTLE_MS + 500),
                );
            }
        },
    });

    return conversationUsersTyping;
};
