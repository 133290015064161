import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledCenteredText = styled(UI.Ds.Text)`
    text-align: center;
    // Compensate for image so that the text appears centered on the screen
    padding-bottom: calc(80px + ${UI.Tokens.SIZES[2]});
`;

export const FullPageFlex = styled(UI.Ds.Flex)`
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: ${UI.Tokens.COLOURS.greyscale.white};
`;
