import { Ds } from "@accurx/design";

type ConsentStillLoadingModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSendMessage: () => void;
};

export const ConsentStillLoadingModal = ({
    isOpen,
    onClose,
    onSendMessage,
}: ConsentStillLoadingModalProps) => {
    return (
        <Ds.Modal open={isOpen} onOpenChange={onClose}>
            <Ds.Modal.Section>
                <Ds.Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>
                        Patient consent is loading. Send anyway?
                    </Ds.Modal.Title>
                    <Ds.Text>
                        Information about this patient's consent to receive
                        messages is still loading from the clinical system.
                    </Ds.Text>
                    <Ds.Flex justifyContent="flex-end" gap="2">
                        <Ds.Button
                            onClick={onClose}
                            type="button"
                            appearance={"secondary"}
                        >
                            Cancel
                        </Ds.Button>
                        <Ds.Button
                            onClick={onSendMessage}
                            type="button"
                            appearance="primary"
                        >
                            Send
                        </Ds.Button>
                    </Ds.Flex>
                </Ds.Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
