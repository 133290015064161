import {
    FloreyEnrolmentCanBeEnrolledRequest,
    FloreyEnrolmentCanBeEnrolledResponse,
    IdType,
} from "@accurx/api/patient-messaging";
import {
    Log,
    getApiEndpoint,
    httpClient,
    returnDataOrThrow,
} from "@accurx/shared";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { PatientExternalId } from "domains/message-component/types";

export type EnrolmentStatus = {
    hasEnrolled: boolean;
    schedule: {
        startDate: string;
        endDate: string;
    } | null;
};

export const useQuestionnaireEnrolmentStatusQuery = (
    {
        workspaceId,
        questionnaireId,
        patientExternalIds,
    }: {
        workspaceId: number;
        questionnaireId: string | undefined;
        patientExternalIds: PatientExternalId[];
    },
    options?: UseQueryOptions<EnrolmentStatus, Error>,
): UseQueryResult<EnrolmentStatus, Error> => {
    return useQuery<EnrolmentStatus, Error>({
        queryKey: [
            "QuestionnaireEnrolmentValidation",
            { workspaceId, id: questionnaireId, patientExternalIds },
        ],
        queryFn: async () => {
            if (!questionnaireId) {
                throw new Error(
                    "Questionnaire id must be provided to query enrolment",
                );
            }

            const response = await httpClient.postJsonReturnJsonSafeAsync(
                getApiEndpoint({
                    path: "/api/patientmessaging/floreyenrolmentschedulevalidation",
                }),
                {
                    accuRxOrgId: workspaceId,
                    conditionId: questionnaireId,
                    patientExternalIds: patientExternalIds.map(
                        ({ type, value }) => ({
                            value,
                            type: IdType[type],
                        }),
                    ),
                } as FloreyEnrolmentCanBeEnrolledRequest,
            );

            const { enrolmentSchedules, canBeEnrolled, enrolmentResultReason } =
                returnDataOrThrow<FloreyEnrolmentCanBeEnrolledResponse>(
                    response,
                );

            if (!canBeEnrolled) {
                throw new Error(`${enrolmentResultReason}`);
            }

            if (enrolmentSchedules.length === 0) {
                return {
                    hasEnrolled: false,
                    schedule: null,
                };
            }

            const { startDate, endDate } = enrolmentSchedules[0];
            if (!startDate || !endDate) {
                throw new Error("No schedule start or end date");
            }

            return {
                hasEnrolled: true,
                schedule: { startDate, endDate },
            };
        },
        onError: () => {
            const error = new Error("Questionnaire enrolment data missing");
            Log.error(error, {
                tags: { questionnaireId },
            });
        },
        refetchOnMount: "always",
        ...options,
    });
};
