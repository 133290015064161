import { useMedicalRecordConnection } from "@accurx/native";
import { ContactDetail } from "domains/message-component/reducer.types";

const mapSystemToDisplay = (
    system: ReturnType<typeof useMedicalRecordConnection>["system"],
) => {
    switch (system) {
        case "Emis":
            return "EMIS";
        case "SystmOne":
            return "SystmOne";
        case "Vision":
            return "Vision";
        case "Unknown":
        case "Server":
        case null:
            return "Medical record";
        default: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustive: never = system;
            return "";
        }
    }
};
export const mapOriginToDisplay = (
    origin: ContactDetail["origin"],
    system: ReturnType<typeof useMedicalRecordConnection>["system"],
): string => {
    switch (origin) {
        case "PDS":
            return "Personal Demographics Service";
        case "EMR":
            return mapSystemToDisplay(system);
        case "Conversation":
            return "This conversation";
        case "UserInput":
            return "Manually entered";
        case "SubmittedByPatient":
            return "Patient triage form";
        case "SubmittedByPatientProxy":
            return "Patient triage form (proxy)";
        default: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustive: never = origin;
            return "";
        }
    }
};
