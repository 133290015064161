import { SearchForPatientByNhsNumberAndDobRequest } from "@accurx/api/portal";
import { useCurrentWorkspace } from "@accurx/auth";
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { isTestPatient } from "domains/navigation/components/PatientSearch/PatientSearchForm/PatientSearchForm.helpers";
import {
    SearchForPatientSuccess,
    SearchForPatientSuccessSchema,
} from "domains/navigation/schemas/SearchForPatientSchema";
import { PatientSearchResult } from "domains/navigation/types";
import { mapPatientSearchResponse } from "domains/navigation/util/format/mappers";

import { useAddPatientToRecentSearches } from "./useAddPatientToRecentSearches";

export const searchForPatientError = {
    generic: "Search for patient via /api/portal/SearchForPatient failed",
};

export const SearchForPatientKey = "SearchForPatient";

type MutationVariables = {
    nhsNumber: string;
    dateOfBirth: { day: string; month: string; year: string };
    isUserAction?: boolean;
};

const searchForPatient = async (
    payload: SearchForPatientByNhsNumberAndDobRequest,
): Promise<IWrappedResult<SearchForPatientSuccess>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/portal/SearchForPatient",
        }),
        payload,
    );

export const useBasicPatientSearch = (
    options: UseMutationOptions<
        PatientSearchResult[],
        Error,
        MutationVariables
    >,
) => {
    const { mutate: saveToRecentSearches } = useAddPatientToRecentSearches();
    const workspaceId = useCurrentWorkspace().orgId;

    return useMutation(
        options.mutationKey ?? [SearchForPatientKey],
        async (variables: MutationVariables) => {
            const { day, month, year } = variables.dateOfBirth;

            const payload = {
                nhsNumber: variables.nhsNumber,
                dateOfBirthYear: Number(year),
                dateOfBirthMonth: Number(month),
                dateOfBirthDay: Number(day),
                organisationId: workspaceId,
                isTestPatient: isTestPatient(variables.nhsNumber),
                isUserAction: variables.isUserAction,
            };

            const response = await searchForPatient(payload);
            const result = SearchForPatientSuccessSchema.safeParse(
                response.result,
            );

            if (result.success) {
                return mapPatientSearchResponse(result.data);
            }

            throw new Error(searchForPatientError.generic);
        },
        {
            ...options,
            onSuccess: (data, variables, context) => {
                const patient = data.length ? data[0] : null;
                if (patient?.nhsNumber && !isTestPatient(patient.nhsNumber)) {
                    void saveToRecentSearches({
                        organisationId: workspaceId,
                        patientToken: patient.patientToken,
                    });
                }
                options.onSuccess?.(data, variables, context);
            },
        },
    );
};
