import { useMemo } from "react";

import { useSignalRClientOn, useSignalRInvoke } from "@accurx/native";
import { SignalRTransport } from "domains/realtime/types";

import { getEventSubscription } from "./useOnNativeSignalRSubscriptionEvent";

// A hook to encapsulate the logic for sending and subscribing to SignalR events
// via the native bridge. The hook is composed of being able to send SignalR events and
// setting up and subscribing events, which is abstracted under a single subscribe function

export const useNativeSignalRTransport = (): SignalRTransport => {
    const nativeSignalRInvoke = useSignalRInvoke();
    const nativeSignalRClientOn = useSignalRClientOn();

    return useMemo(
        () => ({
            send: ({ type, payload }) => {
                void nativeSignalRInvoke({ methodName: type, payload });
            },
            subscribe: ({ type, eventFilter = () => true, onEvent }) => {
                const subscription = getEventSubscription({
                    type,
                    onNewSubscription: () =>
                        void nativeSignalRClientOn({ methodName: type }),
                }).subscribe((payload) => {
                    // Call eventFiler on every event instead of piping the subscription subject in case of a function reference issue
                    if (eventFilter(payload)) {
                        onEvent(payload);
                    }
                });

                return {
                    unsubscribe: () => subscription.unsubscribe(),
                };
            },
        }),
        [nativeSignalRInvoke, nativeSignalRClientOn],
    );
};
