import { Tokens } from "@accurx/design";
import { css } from "styled-components";

export const customScrollbar = css`
    &::-webkit-scrollbar {
        background-color: transparent;
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${Tokens.COLOURS.primary.green[100]};
        border-radius: 50px;
        min-height: 40px;

        &:hover {
            background-color: ${Tokens.COLOURS.greyscale.white};
        }
    }

    // For Firefox fallback to scrollbar-color which looks almost as good but
    // doesn't have the hover effect on the scrollbar thumb
    @supports not selector(::-webkit-scrollbar) {
        scrollbar-color: ${Tokens.COLOURS.primary.green[100]} transparent;
    }
`;
