import { useAnalytics } from "@accurx/analytics";
import { useHistory } from "react-router-dom";

import { getNavigationVersion } from "../../helpers";

type UsePrimaryNavAnalyticsProps = {
    navigationOptionSelected: string;
};

export const usePrimaryNavAnalytics = ({
    navigationOptionSelected,
}: UsePrimaryNavAnalyticsProps) => {
    const track = useAnalytics();
    const history = useHistory();

    return {
        track: () => {
            track("NavigationMenu Button Click", {
                origin: history.location.pathname,
                navigationOptionSelected,
                navigationVersion: getNavigationVersion(),
                eventVersion: 2,
            });
        },
    };
};
