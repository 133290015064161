import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { Ds, Feedback, Text, VisuallyHidden } from "@accurx/design";
import { Checkbox } from "@accurx/inbox-design-library";
import {
    PatientDemographics,
    useMedicalRecordConnection,
    useNativeTrackingFields,
} from "@accurx/native";
import { PatientSearchResult } from "domains/inbox/components/PatientSearch/types";

import { PatientCard } from "../PatientCard";
import { StyledPanel } from "../PatientSearchPanel.styles";
import { PatientSearchConfirmRadioButton } from "../forms/PatientSearchConfirmRadioButton";

const NO_PATIENT_SELECTED_ERROR_ID = "no-patient-selected";

export type PatientSearchResultsForRadio = (PatientSearchResult & {
    demographics: PatientDemographics;
    uniqueId: string;
})[];

export const PatientSearchConfirmForm = ({
    results,
    onCancel,
    onSelectPatient,
}: {
    results: PatientSearchResultsForRadio;
    onCancel: () => void;
    onSelectPatient: (
        patient: PatientDemographics,
        shouldOpenInSystem: boolean,
    ) => void;
}) => {
    const track = useAnalytics();
    const { accessType, medicalRecordSystem } = useNativeTrackingFields();
    const medicalRecordConnection = useMedicalRecordConnection();
    const [showNoPatientSelectedError, setShowNoPatientSelectedError] =
        useState(false);
    const [selectedPatient, setSelectedPatient] = useState(
        // autoselect if there is only one option
        results.length === 1 ? results[0] : null,
    );

    const [shouldOpenInSystem, setShouldOpenInSystem] = useState(false);

    const trackSearchConfirmClickEvent = ({
        hasError,
    }: {
        hasError: boolean;
    }) => {
        track("PatientCardSearchConfirm Button Click", {
            accessType,
            medicalRecordSystem,
            hasError,
            openInSystem: shouldOpenInSystem,
        });
    };

    return (
        <>
            <StyledPanel.Body>
                <Ds.Flex flexDirection={"column"} gap="1">
                    {showNoPatientSelectedError && (
                        <Feedback
                            props={{
                                id: NO_PATIENT_SELECTED_ERROR_ID,
                                "aria-live": "polite",
                            }}
                            colour="error"
                            title="Please select a patient"
                        />
                    )}
                    <form
                        aria-label="Select a patient"
                        id="select-searched-patient"
                    >
                        <fieldset
                            aria-describedby={
                                showNoPatientSelectedError
                                    ? NO_PATIENT_SELECTED_ERROR_ID
                                    : undefined
                            }
                        >
                            <VisuallyHidden as="legend">
                                <Text skinny variant="label" colour="night">
                                    Select a patient
                                </Text>
                            </VisuallyHidden>
                            <Ds.Flex flexDirection={"column"} gap="1">
                                {results.map(({ uniqueId, ...patient }) => (
                                    <PatientSearchConfirmRadioButton
                                        key={`${uniqueId}`}
                                        value={uniqueId}
                                        label={<PatientCard {...patient} />}
                                        checkedValue={
                                            selectedPatient?.uniqueId ?? ""
                                        }
                                        onChange={() =>
                                            setSelectedPatient({
                                                ...patient,
                                                uniqueId,
                                            })
                                        }
                                    />
                                ))}
                            </Ds.Flex>
                        </fieldset>
                    </form>
                </Ds.Flex>
            </StyledPanel.Body>
            <StyledPanel.Footer>
                <Ds.Button
                    appearance="secondary"
                    size="small"
                    onClick={onCancel}
                >
                    <Ds.Button.Icon name="Arrow" rotate="270" />
                    Back
                </Ds.Button>

                <Ds.Flex gap="1">
                    {!!medicalRecordConnection.capabilities?.openInEMR && (
                        <Checkbox
                            id="open-in-system"
                            checked={shouldOpenInSystem}
                            onCheckedChange={(checked) =>
                                setShouldOpenInSystem(checked)
                            }
                        >
                            <Checkbox.Indicator />
                            <Text skinny variant="preview">
                                Open patient in {medicalRecordConnection.system}
                            </Text>
                        </Checkbox>
                    )}
                    <Ds.Button
                        type="submit"
                        form="select-patient-form"
                        size="small"
                        loading={false}
                        onClick={(e) => {
                            e.preventDefault();
                            if (!selectedPatient) {
                                setShowNoPatientSelectedError(true);
                                trackSearchConfirmClickEvent({
                                    hasError: true,
                                });
                                return;
                            }

                            trackSearchConfirmClickEvent({ hasError: false });

                            onSelectPatient(
                                selectedPatient.demographics,
                                shouldOpenInSystem,
                            );
                        }}
                    >
                        Select patient
                    </Ds.Button>
                </Ds.Flex>
            </StyledPanel.Footer>
        </>
    );
};
