import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const PageContainer = styled.div`
    height: 100%;
    position: relative;
    padding-bottom: ${Tokens.SIZES[8]};
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
`;

export const MainContentsContainer = styled.main`
    padding: ${Tokens.SIZES[3]} ${Tokens.SIZES[2]};
    overflow-y: auto;
    flex: 1;
`;

export const MainContentsText = styled.div`
    padding-bottom: 1.25rem;
`;

export const FooterContainer = styled.footer`
    display: flex;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    bottom: 0;
    height: ${Tokens.SIZES[8]};
    padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[2]};
    background-color: ${Tokens.COLOURS.greyscale.white};
    border-top: 1px solid ${Tokens.COLOURS.greyscale.silver};
`;

export const StyledTextWithHalo = styled.div`
    background-color: ${Tokens.COLOURS.primary.green[25]};

    padding: ${Tokens.SIZES[0.5]} ${Tokens.SIZES[1]};
    border-radius: 99px;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    min-width: 2.5rem;
    margin-inline: 0.5rem;
`;

export const StyledReviewUploadAppointmentsText = styled.div`
    margin-inline-end: auto;
`;

export const StyledTruncatedTableData = styled.div`
    overflow-x: hidden;
    text-overflow: ellipsis;
`;
