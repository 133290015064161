import { useConciergeSelector } from "domains/concierge/internal/context";
import { UserSummary } from "domains/concierge/types";
import orderBy from "lodash/orderBy";

type UseAllUsersOptions = {
    excludeCurrentUser?: boolean;
};

export const useAllUsers = (options?: UseAllUsersOptions): UserSummary[] => {
    return useConciergeSelector((state) => {
        const filtered = options?.excludeCurrentUser
            ? Object.values(state.users.items).filter((u) => !u.isCurrentUser)
            : Object.values(state.users.items);
        return orderBy(filtered, [(t) => t.displayName.toLowerCase()], ["asc"]);
    });
};
