import { useFullScreen } from "layouts/UnifiedLayout/UnifiedLayoutContext";

import { StyledContainer } from "app/layout/Container";
import { VaccineDeliverySelectSecond } from "app/practices/vaccine/vaccineInvitesUploadAndCompose/VaccineDeliverySelectSecond";

export const InvitePatientsSelectPage = () => {
    useFullScreen();

    return (
        <StyledContainer>
            <VaccineDeliverySelectSecond />
        </StyledContainer>
    );
};
