import { getEmbedMode } from "@accurx/native";
import {
    JsonParseError,
    JsonStringifyError,
    Log,
    httpClient2, // eslint-disable-line no-restricted-imports
} from "@accurx/shared";
import { reloadApp, restartApp } from "appLifecycle";
import { getHttpErrorResponseMessage, getRequestLoggingTags } from "logging";

/**
 * For all API calls, when we receive a 401 response, we take that as a sign
 * that the user's session has expired and should be kicked back to the login
 * page. The only exceptions are these login endpoints which return a 401 when
 * you pass invalid credentials.
 */
const isExpected401Response = (method: string, path: string) => {
    return [
        ["POST", "/api/account/login"],
        ["POST", "/api/account/weblogin"],
        ["POST", "/api/account/ConfirmEmail/Index"],
    ].some((r) => {
        return r[0] === method && r[1] === path;
    });
};

const on401Response = () => {
    const { embedMode } = getEmbedMode();

    if (embedMode === "Desktop") {
        Log.error("User unauthorised in WebView2");
        window.alert(
            "Something went wrong, we'll need to reload the Inbox. \n\nPlease contact support if this problem continues.",
        );
        reloadApp();
    } else {
        window.alert(
            "You are about to be logged off as you haven't used Accurx for a while.\n\nPlease log in to continue.",
        );
        restartApp();
    }
};

/**
 * I've taken this logic from how we're doing it with HttpClient. We only call
 * Log.warn() for some Http status codes and call Log.info() for the rest. Some
 * if this logic makes clear sense, e.g. 401 responses are a known response
 * caused by the user's session token expiring. But some make less sense e.g.
 * why don't we Log.warn() 5xx errors...
 */
const doesStatusCodeWarrentErrorLog = (statusCode: number): boolean => {
    // 401 responses are expected if the user's session expires and we handle
    // this by kicking them back to login
    if (statusCode === 401) return false;

    // It's unclear why 404 responses aren't considered worthy of an error log
    if (statusCode === 404) return false;

    // It's unclear why 5xx responses aren't considered worthy of an error log
    if (statusCode >= 500) return false;

    // Anything lower than 400 will be a 3xx redirect.
    if (statusCode < 400) return false;

    return true;
};

export const configureHttpClient2 = () => {
    httpClient2.configure({
        // Setup a global handler. Anytime we receive a 401 response from an API
        // (with one or two exceptions) we take that as a signal that the user
        // is no longer logged in and we reload the app to boot them back to the
        // login page.
        onResponse: ({ response, request, init }) => {
            if (response.status !== 401) return;

            if (!isExpected401Response(request.method, init.path)) {
                on401Response();
            }
        },
        logger: {
            onRequestStart: ({ init, request }) => {
                const { path, params } = init;

                Log.info(`HttpClient request starting`, {
                    tags: getRequestLoggingTags({ request, path, params }),
                });
            },
            onSuccessResponse: ({ init, request, response }) => {
                const { path, params } = init;

                Log.info(`HttpClient request succeeded`, {
                    tags: {
                        ...getRequestLoggingTags({ request, path, params }),
                        "api.statusCode": response.status,
                    },
                });
            },
            onNetworkError: ({ init, error }) => {
                const { path, params } = init;
                const request = error.request;
                const message = `[HttpClient] Failed to fetch. Method: ${request.method}. Request: ${path}. Error: ${error.originalError}.`;

                const logOptions = {
                    originalException: error.originalError,
                    tags: getRequestLoggingTags({ request, path, params }),
                    context: [
                        {
                            name: "Api Request",
                            data: {
                                url: request.url,
                                method: request.method,
                                headers: init.headers,
                                referrer: request.referrer ?? undefined,
                            },
                        },
                    ],
                };

                if (window.navigator.onLine) {
                    Log.warn(message, logOptions);
                } else {
                    Log.info(message, logOptions);
                }
            },
            onFetchRetry: ({ init, request, error, attempt }) => {
                const { path, params } = init;

                Log.info("HttpClient fetch attempt failed", {
                    originalException: error,
                    tags: {
                        ...getRequestLoggingTags({ request, path, params }),
                        attempt: attempt + 1,
                        reason: `${error}` ?? "unknown",
                    },
                });
            },
            onHttpError: async ({ init, error }) => {
                const { path, params } = init;
                const request = error.request;
                const message = `[HttpClient] failure. Method: ${request.method}. Request: ${path}. Code: ${error.statusCode}.`;
                const responseBody = await getHttpErrorResponseMessage(error);
                const logOptions = {
                    tags: {
                        ...getRequestLoggingTags({ request, path, params }),
                        "api.statusCode": error.statusCode,
                        "api.accurxErrorMessage": responseBody,
                    },
                    context: [
                        {
                            name: "Api Request",
                            data: {
                                url: request.url,
                                method: request.method,
                                headers: init.headers,
                                referrer: request.referrer ?? undefined,
                            },
                        },
                        {
                            name: "Api Response",
                            data: {
                                code: error.statusCode,
                                headers: init.headers,
                            },
                        },
                    ],
                };

                if (doesStatusCodeWarrentErrorLog(error.statusCode)) {
                    Log.error(message, logOptions);
                } else {
                    Log.info(message, logOptions);
                }
            },
            onClientError: ({ init, error }) => {
                const { path, params } = init;

                if (error instanceof JsonParseError) {
                    // JsonParseError means that we received a non-JSON response and
                    // tried to parse it to JSON. This is likely a sign that an
                    // unexpected API response occured.
                    Log.error(`response.json() called on a non-JSON response`, {
                        tags: getRequestLoggingTags({
                            request: error.request,
                            path,
                            params,
                        }),
                    });
                } else if (error instanceof JsonStringifyError) {
                    // JsonStringifyError means we passed non-serializable data
                    // as the request body.
                    Log.error(`Request body couldn't be serialized to JSON`, {
                        tags: getRequestLoggingTags({
                            request: error.request,
                            path,
                            params,
                        }),
                    });
                } else if (error instanceof Error) {
                    // We could get here if any other runtime error occurs. e.g.
                    // one of the handlers in this file throws. We can just log
                    // the error directly.
                    Log.error(error, { tags: { path: init.path } });
                } else {
                    // We shouldn't get here but log an error just in case
                    Log.error("Unknown client error detected in HttpClient", {
                        tags: {
                            path: init.path,
                            error: `${error}`,
                        },
                    });
                }
            },
        },
    });
};
