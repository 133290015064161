import React, { useEffect, useMemo } from "react";

import { Owner, Recipient } from "@accurx/api/content";
import { Button, Ds, Feedback, Table, useTable } from "@accurx/design";
import { useMessageTemplates } from "@accurx/message-templates";
import { ArchivedWorkspaceHiddenItemWrapper } from "@accurx/workspace-management";
import { useHistory } from "react-router-dom";

import Linkify from "app/sharedComponents/linkify/Linkify";
import { useIsUserApproved } from "store/hooks";

import { trackManageTemplatePageView } from "../../analytics/FlemingAnalytics";
import { useFlemingLoggedInAnalytics } from "../../sessionAnalytics/useFlemingLoggedInAnalytics";
import { CategoriesFilter } from "./CategoriesFilter";
import { StyledButton, StyledTableBody } from "./ManageTemplates.styles";
import {
    FEEDBACK_SEARCH_TEXT,
    NO_TEMPLATES_TEXT,
    shouldDisplayCategoryHeader,
} from "./ManageTemplates.utils";
import { TabIds } from "./ManageTemplatesOverview";
import SnomedTag from "./SnomedTag";
import DeleteTemplateButton from "./components/DeleteTemplateButton";
import {
    routeManageTemplatesCopyToPractice,
    routeManageTemplatesCreate,
    routeManageTemplatesEdit,
    routeToCopyWebTemplates,
    routeToCreateWebTemplate,
    routeToEditWebTemplate,
} from "./routeHelpers";
import { getDistinctCategories } from "./utils";

type UserTemplatesPanelProps = {
    orgId: number;
    orgName: string;
    isDesktopPage?: boolean;
};

export const UserTemplatesPanel = ({
    orgId,
    orgName,
    isDesktopPage = true,
}: UserTemplatesPanelProps): JSX.Element => {
    const history = useHistory();
    const { data, refetch, status } = useMessageTemplates(orgId, {
        owner: Owner.User,
        recipient: Recipient.Patient,
    });

    const sortedTemplates = data?.templates
        .sort((a, b) => a.title.localeCompare(b.title))
        .sort((a, b) => a.category.localeCompare(b.category));

    const table = useTable({
        data: sortedTemplates ?? [],
        columnDefs: [
            {
                header: "Template name",
                id: "title",
            },
            {
                header: "Details",
                id: "body",
            },
            {
                header: "Category",
                id: "category",
            },
            {
                header: "Actions",
                id: "actions",
            },
        ],
    });

    const isUserApproved: boolean = useIsUserApproved();
    const flemingLoggedInProperties = useFlemingLoggedInAnalytics();

    const distinctCategories = useMemo(() => {
        return getDistinctCategories(table.rows);
    }, [table.rows]);
    const countTemplateCategories = distinctCategories.length;

    useEffect(() => {
        trackManageTemplatePageView({
            ...flemingLoggedInProperties,
            countTemplateCategories,
            currentTab: TabIds.UserTemplates,
            productOrigin: "PatientMessage",
        });
    }, [flemingLoggedInProperties, countTemplateCategories]);
    return (
        <Ds.Flex gap="3" flexDirection="column">
            <Ds.Text>
                Here you can create your own templates and they will appear in
                the software for you to use. Each message will still have a
                greeting (e.g. Dear Miss Flynn) and a signature (e.g. {orgName}
                ), so you won't need to create these!
            </Ds.Text>
            <ArchivedWorkspaceHiddenItemWrapper>
                <StyledButton
                    onClick={() => {
                        history.push(
                            isDesktopPage
                                ? routeManageTemplatesCreate({
                                      orgId,
                                      isOrgTemplate: false,
                                  })
                                : routeToCreateWebTemplate({
                                      orgId,
                                      isOrgTemplate: false,
                                  }),
                        );
                    }}
                    text="Create New Template"
                />
            </ArchivedWorkspaceHiddenItemWrapper>
            {status === "success" && (
                <Table>
                    <Table.Head>
                        <Table.Row>
                            <Table.Header colSpan={5}>
                                <Ds.Grid
                                    columns={{ xs: "1fr", sm: "1fr 1fr" }}
                                    gap="1"
                                >
                                    <Ds.Flex flexDirection="column">
                                        <Ds.Text weight="bold">
                                            My templates
                                        </Ds.Text>
                                        {table.rows.length !== 0 && (
                                            <Ds.Text>
                                                {FEEDBACK_SEARCH_TEXT}
                                            </Ds.Text>
                                        )}
                                    </Ds.Flex>

                                    {table.rows.length && (
                                        <CategoriesFilter
                                            distinctCategories={
                                                distinctCategories
                                            }
                                            onFilterSelect={(filterTemplates) =>
                                                table.actions.filter((row) =>
                                                    filterTemplates(
                                                        row.category,
                                                    ),
                                                )
                                            }
                                            currentTable={TabIds.UserTemplates}
                                        />
                                    )}
                                </Ds.Grid>
                            </Table.Header>
                        </Table.Row>
                        <Table.Row>
                            {table.columns.map((column) => (
                                <Table.Header
                                    scope={"col"}
                                    key={column.id}
                                    colour="greyscale"
                                >
                                    {column.header}
                                </Table.Header>
                            ))}
                        </Table.Row>
                    </Table.Head>
                    <StyledTableBody>
                        {table.rows.map((row, index) => (
                            <React.Fragment key={row.id}>
                                {shouldDisplayCategoryHeader({
                                    currentRowCategoryHeading: row.category,
                                    previousRowCategoryHeading:
                                        table.rows[index - 1]?.category,
                                    currentIndex: index,
                                }) && (
                                    <Table.Row>
                                        <Table.Header
                                            colSpan={5}
                                            scope="colgroup"
                                            colour="greyscale"
                                        >
                                            <Ds.Text weight="bold" as="span">
                                                {row.category}
                                            </Ds.Text>
                                        </Table.Header>
                                    </Table.Row>
                                )}
                                <Table.Row>
                                    <Table.Header scope="row">
                                        {row.title}
                                    </Table.Header>
                                    <Table.DataCell>
                                        <Ds.Flex flexDirection="column">
                                            <Linkify>{row.body}</Linkify>
                                            {row.snomedCodes.map(
                                                (snomedCode, index) => (
                                                    <SnomedTag
                                                        key={`${snomedCode.conceptId}-${index}`}
                                                        text={snomedCode.term}
                                                        code={
                                                            snomedCode.conceptId
                                                        }
                                                    />
                                                ),
                                            )}
                                        </Ds.Flex>
                                    </Table.DataCell>
                                    <Table.DataCell>
                                        {row.category}
                                    </Table.DataCell>
                                    <Table.DataCell>
                                        <ArchivedWorkspaceHiddenItemWrapper>
                                            <Ds.Flex
                                                flexDirection="column"
                                                alignItems="flex-start"
                                                gap="1"
                                            >
                                                {isUserApproved &&
                                                    !row.isVideo && (
                                                        <Button
                                                            onClick={() => {
                                                                history.push(
                                                                    isDesktopPage
                                                                        ? routeManageTemplatesCopyToPractice(
                                                                              {
                                                                                  orgId: orgId,
                                                                                  templateId:
                                                                                      row.id,
                                                                              },
                                                                          )
                                                                        : routeToCopyWebTemplates(
                                                                              {
                                                                                  orgId,
                                                                                  templateId:
                                                                                      row.id,
                                                                              },
                                                                          ),
                                                                );
                                                            }}
                                                            text="Copy to workspace"
                                                            theme="primary"
                                                            style={{
                                                                whiteSpace:
                                                                    "nowrap",
                                                            }}
                                                        />
                                                    )}

                                                {!row.isVideo && (
                                                    <Button
                                                        onClick={() => {
                                                            history.push(
                                                                isDesktopPage
                                                                    ? routeManageTemplatesEdit(
                                                                          {
                                                                              orgId,
                                                                              templateId:
                                                                                  row.id,
                                                                              isOrgTemplate:
                                                                                  false,
                                                                          },
                                                                      )
                                                                    : routeToEditWebTemplate(
                                                                          {
                                                                              orgId,
                                                                              templateId:
                                                                                  row.id,
                                                                              isOrgTemplate:
                                                                                  false,
                                                                          },
                                                                      ),
                                                            );
                                                        }}
                                                        text="Edit"
                                                        theme="secondary"
                                                    />
                                                )}
                                                <DeleteTemplateButton
                                                    templateId={parseInt(
                                                        row.id,
                                                        10,
                                                    )}
                                                    templateName={row.title}
                                                    templateAttachmentCount={
                                                        row.attachments.length
                                                    }
                                                    isWorkspaceTemplate={false}
                                                    workspaceId={orgId}
                                                    workspaceName={orgName}
                                                    onSuccess={() => {
                                                        refetch();
                                                    }}
                                                />
                                            </Ds.Flex>
                                        </ArchivedWorkspaceHiddenItemWrapper>
                                    </Table.DataCell>
                                </Table.Row>
                            </React.Fragment>
                        ))}
                    </StyledTableBody>
                    <Table.Foot>
                        <Table.Row>
                            <Table.DataCell colSpan={5}>
                                {table.rows.length > 0 ? (
                                    <Ds.Flex justifyContent={"center"}>
                                        <Ds.Flex.Item>
                                            Showing {table.rows.length}
                                        </Ds.Flex.Item>
                                    </Ds.Flex>
                                ) : (
                                    NO_TEMPLATES_TEXT
                                )}
                            </Table.DataCell>
                        </Table.Row>
                    </Table.Foot>
                </Table>
            )}
            {status === "error" && (
                <Feedback
                    colour="error"
                    title="There was a problem loading your templates"
                >
                    <Ds.Text>Reload this page to try again</Ds.Text>
                </Feedback>
            )}
            {status === "loading" && <Ds.Spinner />}
        </Ds.Flex>
    );
};
