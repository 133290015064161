import { AtLeastOneAttachmentError, AttachmentError } from "./upload.types";

type MaxAllowedFileSize = {
    size: number /* size in bytes */;
    humanReadable: string;
};

type AllowedFileExtensions = string[];

type ValidateFileProps = {
    file: File; // Due to File type meaning that File.name is not defined as a string
    maxAllowedFileSize: MaxAllowedFileSize;
    allowedFileExtensions: AllowedFileExtensions;
};

const hasErrors = (
    errors: AttachmentError[],
): errors is AtLeastOneAttachmentError => errors.length !== 0;

export const validateFile = ({
    file,
    maxAllowedFileSize,
    allowedFileExtensions,
}: ValidateFileProps):
    | { isValid: false; errors: AtLeastOneAttachmentError }
    | { isValid: true } => {
    const errors: AttachmentError[] = [];
    try {
        if (file.size === 0) {
            errors.push({
                type: "emptyFile",
                message: "Cannot upload an empty file",
            });
        }

        if (file.size > maxAllowedFileSize.size) {
            errors.push({
                type: "tooBig",
                message:
                    "File too big, max file size: " +
                    maxAllowedFileSize.humanReadable,
            });
        }

        const filename = file.name;

        if (filename === "") {
            errors.push({
                type: "badFileName",
                message: "Error with loading filename",
            });
        }

        const fileExtension = filename.split(".").pop()?.toLowerCase();

        if (
            fileExtension &&
            allowedFileExtensions.indexOf(fileExtension) === -1
        ) {
            errors.push({
                type: "badExtension",
                message: `Unsupported file extension: File with the extension .${fileExtension} was uploaded, but files can only be one of the supported extensions (${allowedFileExtensions.join(
                    ", ",
                )})`,
            });
        }

        return hasErrors(errors)
            ? {
                  isValid: false,
                  errors,
              }
            : { isValid: true };
    } catch (e) {
        return {
            isValid: false,
            errors: [
                { type: "generalError", message: "Error validating file" },
                ...errors,
            ],
        };
    }
};
