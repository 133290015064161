import { User } from "@accurx/auth";
import {
    Conversation,
    ConversationGroupFilters,
    TeamSummary,
} from "@accurx/concierge/types";
import { Reason } from "domains/inbox/hooks/util/useUpdatingFeed";
import isEqual from "lodash/isEqual";

export const getReasonForLeaving = ({
    conversation,
    filters,
    currentUser,
    assignedTeam,
}: {
    conversation: Conversation;
    filters: ConversationGroupFilters;
    currentUser: User;
    assignedTeam?: TeamSummary;
}): Reason => {
    for (const filter of filters) {
        switch (filter.type) {
            case "AssignedTo":
                if (!isEqual(conversation.assignee, filter.value)) {
                    if (conversation.assignee.type === "Team") {
                        return assignedTeam?.type ===
                            "ToAssignClinicianMessaging" ||
                            assignedTeam?.type === "ToAssignPatientMessaging"
                            ? "Unassigned"
                            : "TeamAssigned";
                    }
                    if (conversation.assignee.type === "User") {
                        return conversation.assignee.id ===
                            currentUser.accuRxUserId
                            ? "SelfAssigned"
                            : "ColleagueAssigned";
                    }
                }
                break;
            case "Status":
                if (conversation.status !== filter.value) {
                    return filter.value === "Done"
                        ? "MarkedOpen"
                        : "MarkedDone";
                }
                break;
        }
    }
    return "Unknown";
};
