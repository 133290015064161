import { GlobalConversationManagerProvider } from "shared/concierge/conversations/context/GlobalConversationManagerContext";
import { GlobalPatientManagerProvider } from "shared/concierge/patients/context/GlobalPatientManagerContext";
import { GlobalUsersAndTeamsManagerProvider } from "shared/concierge/usersAndTeams/context/GlobalUsersAndTeamsManagerContext";

import { SelectedGlobalOrganisationConversationManagerProvider } from "./workspaceConversations/context/SelectedGlobalOrganisationConversationManagerContext";

type TrustWebInboxProvidersProps = {
    is2FAed: boolean;
    workspaceId: number | null;
    currentUserId: string | undefined;
};

/* Root level providers for our app
 */
export const TrustWebInboxProviders = ({
    is2FAed,
    workspaceId,
    currentUserId,
    children,
}: React.PropsWithChildren<TrustWebInboxProvidersProps>) => {
    /**
     * This solves a redirect-to-login-page loop that happens when the user is not logged in because down the tree in one of the "Global..." providers,
     * we ask for the current workspace but when a user isn't logged in, there isn't a workspace... so it redirects the login page.
     *
     * Each of the "Global..." providers below will not do anything and only render the children if currentUserId is undefined.
     * So essentially, this early short-circuit is doing the same thing, but earlier!
     *
     * We'd rather not touch the providers as they are technically legacy code but not ready to be completely removed yet.
     */
    if (!currentUserId) {
        return <>{children}</>;
    }

    return (
        <GlobalUsersAndTeamsManagerProvider currentUserId={currentUserId}>
            <GlobalPatientManagerProvider currentUserId={currentUserId}>
                <GlobalConversationManagerProvider
                    currentUserId={currentUserId}
                >
                    <SelectedGlobalOrganisationConversationManagerProvider
                        is2FAed={is2FAed}
                        workspaceId={workspaceId}
                    >
                        {children}
                    </SelectedGlobalOrganisationConversationManagerProvider>
                </GlobalConversationManagerProvider>
            </GlobalPatientManagerProvider>
        </GlobalUsersAndTeamsManagerProvider>
    );
};
