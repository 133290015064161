import * as UI from "@accurx/design";
import { Reason } from "domains/inbox/hooks/util/useUpdatingFeed";

import { StyledContainer, StyledOverlay } from "./StateChangeOverlay.styles";

export type StateChangeOverlayProps = {
    reason: Reason;
};

const REASON_FOR_LEAVING_TEXT = {
    MarkedDone: "Conversation marked done",
    MarkedOpen: "Conversation reopened",
    ColleagueAssigned: "Conversation assigned to colleague",
    TeamAssigned: "Conversation assigned to team",
    SelfAssigned: "Conversation assigned to you",
    Unassigned: "Conversation unassigned",
} as const;

export const StateChangeOverlay = ({ reason }: StateChangeOverlayProps) => {
    if (reason === "Unknown") return null;

    return (
        <StyledContainer>
            <StyledOverlay justifyContent="center" alignItems="center">
                <UI.Text variant="note">
                    {REASON_FOR_LEAVING_TEXT[reason]}
                </UI.Text>
            </StyledOverlay>
        </StyledContainer>
    );
};
