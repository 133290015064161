import { Link as AccurxLink, Ds, Tokens } from "@accurx/design";
import { BackgroundColourVariants } from "@accurx/design/dist/styles/types";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const StyledHoveredIconWrapper = styled.div`
    display: none;
`;
export const StyledUnhoveredIconWrapper = styled.div`
    display: inline-block;
`;

const StyledCardLinkBackground = css<{
    bgColor?: BackgroundColourVariants;
}>`
    position: relative;
    padding: ${Tokens.SIZES[2]};
    background-color: ${(props) =>
        props.bgColor || Tokens.COLOURS.greyscale.white};
    border-radius: ${Tokens.SIZES[0.5]};
    border: 1px solid ${Tokens.COLOURS.greyscale.silver};
`;
/**
 * StyledCardLinkBackgroundDiv is used in cases where you want a disabled
 * version of the StyledCardLink that has no hover/focus styles to be used alongside
 * the enabled link that has hover/focus styles
 */
export const StyledCardLinkBackgroundDiv = styled.div`
    ${StyledCardLinkBackground}
`;

export const StyledFauxBlockContainer = styled.li<{
    bgColor?: BackgroundColourVariants;
}>`
    margin-bottom: ${Tokens.SIZES[2]};
    ${StyledCardLinkBackground}
    &:hover {
        background-color: ${Tokens.COLOURS.primary.blue[5]};
        cursor: pointer;
        border: 1px solid ${Tokens.COLOURS.primary.blue[100]};
    }

    &:hover
        ${StyledHoveredIconWrapper},
        &:focus-within
        ${StyledHoveredIconWrapper} {
        display: inline-block;
    }

    &:hover
        ${StyledUnhoveredIconWrapper},
        &:focus-within
        ${StyledUnhoveredIconWrapper} {
        display: none;
    }

    &:focus-within {
        background-color: ${Tokens.COLOURS.primary.blue[5]};
        cursor: pointer;
        border: 1px solid ${Tokens.COLOURS.primary.blue[100]};
    }

    h2 a[href]:not([tabindex="-1"]):focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
`;

export const StyledFauxBlockOverlayLink = styled(Link)`
    position: static;

    &:before {
        bottom: 0;
        content: "";
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        white-space: nowrap;
        z-index: 1;
    }

    &:hover {
        cursor: pointer;
        text-decoration: none;
    }
`;

export const StyledFauxBlockOverlayAnchor = styled.a`
    position: static;

    &:before {
        bottom: 0;
        content: "";
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        white-space: nowrap;
        z-index: 1;
    }

    &:hover {
        cursor: pointer;
        text-decoration: none;
    }
`;

export const StyledBlockNestedLink = styled(AccurxLink)`
    position: relative;
    align-items: center;
    z-index: 2;
    vertical-align: middle;
`;

export const StyledTextBody = styled(Ds.Text)`
    display: flex;
    flex-direction: column;
`;
