import { useEffect } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { getEmbedMode } from "@accurx/native";
import { Log } from "@accurx/shared";
import {
    Redirect,
    Route,
    Switch,
    generatePath,
    matchPath,
    useHistory,
} from "react-router";
import { useLocation } from "react-router-dom";

import {
    DEPRECATED_BACKGROUND_SEARCH,
    DEPRECATED_CLINICIAN_COMPOSE_ROUTE,
    DEPRECATED_COMPOSE_ROUTE,
    DEPRECATED_CONVERSATION_ROUTE,
    ROUTES_COMPOSE,
} from "./Routes.constants";
import { RouterState } from "./Routes.types";
import { Page } from "./components/Page";
import { ComposePage } from "./compose/ComposePage";
import { FullScreenPageWrapper } from "./compose/FullScreenPageWrapper";
import {
    CurrentPatientProvider,
    LocationStatePatientProvider,
} from "./compose/context/CurrentPatientProvider";
import { useAllowWindowClose } from "./compose/hooks/useAllowWindowClose";
import { useOnWindowReset } from "./compose/hooks/useOnWindowReset";
import { NativeSubscriptionsProvider } from "./compose/native/NativeSubscriptionsProvider";
import { ConversationPage } from "./conversation/ConversationPage";
import { ComposeBackgroundSearchPage } from "./search/ComposeBackgroundSearchPage";
import { ComposeSearchPage } from "./search/ComposeSearchPage";

const RedirectedNotFoundRoutes = () => {
    useEffect(() => {
        Log.error(
            "ComposeRoutes: attempt to visit a page that does not exist. Redirecting away",
            {
                tags: {
                    product: "Compose",
                },
            },
        );
    }, []);

    return <Redirect to={ROUTES_COMPOSE.compose} />;
};

const getPatientKey = (state: RouterState) => {
    if (!state?.searchedPatient) {
        return undefined;
    }

    return state.searchedPatient.patientToken
        ? state.searchedPatient.patientToken
        : btoa(state.searchedPatient.externalIds.map((x) => x.value).join());
};

export const ComposeRoutes = () => {
    const { embedMode } = getEmbedMode();
    const { state, pathname } = useLocation<RouterState>();

    const isComposePage = matchPath(pathname, {
        path: ROUTES_COMPOSE.compose,
        exact: true,
    });

    const history = useHistory();
    const { orgId: workspaceId } = useCurrentWorkspace();

    const resetCompose = () => {
        history.replace(
            generatePath(ROUTES_COMPOSE.compose, {
                workspaceId,
            }),
        );
    };

    useAllowWindowClose({
        enabled: !isComposePage,
        onClose: resetCompose,
    });

    useOnWindowReset({
        enabled: !isComposePage,
        onReset: resetCompose,
    });

    // Enables page re-renders for when a different patient is searched whilst on a compose route
    const patientKey = getPatientKey(state);

    return (
        <NativeSubscriptionsProvider>
            <Switch>
                <Route
                    path={ROUTES_COMPOSE.clinicianCompose}
                    key={patientKey}
                    exact
                >
                    {/* Accurx Web controls the layout of quick view. 
                        We only handle it in compose when used in the toolbar.
                    */}
                    <Page withQuickViewContainer={embedMode === "Desktop"}>
                        <CurrentPatientProvider>
                            <ComposePage composeVariant="Clinician" />
                        </CurrentPatientProvider>
                    </Page>
                </Route>
                <Route key={patientKey} path={ROUTES_COMPOSE.compose} exact>
                    {/* Accurx Web controls the layout of quick view. 
                        We only handle it in compose when used in the toolbar.
                    */}
                    <Page withQuickViewContainer={embedMode === "Desktop"}>
                        <CurrentPatientProvider>
                            <ComposePage composeVariant="Patient" />
                        </CurrentPatientProvider>
                    </Page>
                </Route>
                <Route
                    key={patientKey}
                    path={ROUTES_COMPOSE.inboxCompose}
                    exact
                >
                    {/* Accurx Web controls the layout of quick view. 
                        We only handle it in compose when used in the toolbar.
                    */}
                    <Page withQuickViewContainer={embedMode === "Desktop"}>
                        <FullScreenPageWrapper>
                            <LocationStatePatientProvider>
                                <ComposePage composeVariant="Patient" />
                            </LocationStatePatientProvider>
                        </FullScreenPageWrapper>
                    </Page>
                </Route>

                <Route path={ROUTES_COMPOSE.search} exact>
                    <ComposeSearchPage />
                </Route>

                <Route path={ROUTES_COMPOSE.backgroundSearch} exact>
                    <ComposeBackgroundSearchPage />
                </Route>
                <Route path={ROUTES_COMPOSE.conversation} exact>
                    <ConversationPage />
                </Route>

                <Redirect
                    from={DEPRECATED_CLINICIAN_COMPOSE_ROUTE}
                    to={ROUTES_COMPOSE.clinicianCompose}
                    exact
                />
                <Redirect
                    from={DEPRECATED_BACKGROUND_SEARCH}
                    to={ROUTES_COMPOSE.backgroundSearch}
                    exact
                />
                <Redirect
                    from={DEPRECATED_CONVERSATION_ROUTE}
                    to={ROUTES_COMPOSE.conversation}
                    exact
                />
                <Redirect
                    from={DEPRECATED_COMPOSE_ROUTE}
                    to={ROUTES_COMPOSE.compose}
                />

                {/* redirect away from paths that don't match the route */}
                <Route path="*">
                    <RedirectedNotFoundRoutes />
                </Route>
            </Switch>
        </NativeSubscriptionsProvider>
    );
};
