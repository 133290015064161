import React from "react";

import { Ds, Tokens } from "@accurx/design";

export const PageTitle = ({
    pageNumber,
    pageTitle,
    isDesktopPage,
}: {
    pageNumber: string;
    pageTitle: string;
    isDesktopPage: boolean;
}): JSX.Element => (
    <>
        <Ds.Flex gap="3" justifyContent="center">
            <Ds.Text color={"zinc"} children={`Step ${pageNumber} of 4`} />
        </Ds.Flex>
        <Ds.Flex gap="2" justifyContent="center">
            <Ds.Text
                as="h1"
                weight="bold"
                size={isDesktopPage ? "xxlarge" : "large"}
                mt={isDesktopPage ? "0" : Tokens.SIZES[3]}
                children={pageTitle}
            />
        </Ds.Flex>
    </>
);
