import { Ds, Tokens, focusBorderStyles } from "@accurx/design";
import styled from "styled-components";

export const SelectRadioCard = ({
    children,
    ...props
}: Ds.RadioGroupItemProps) => {
    return (
        <StyledSelectRadioCard {...props}>
            <Ds.RadioGroup.Label>{children}</Ds.RadioGroup.Label>
        </StyledSelectRadioCard>
    );
};

const StyledSelectRadioCard = styled(Ds.RadioGroup.Item)`
    // Higher specificity hack
    && {
        border: ${Tokens.BORDERS.normal.transparent};
        padding: ${Tokens.SIZES[1]};
        width: auto;

        &[data-state="checked"] {
            border: ${Tokens.BORDERS.normal.blue};

            label {
                p {
                    color: ${Tokens.COLOURS.primary.blue["130"]};
                }
                svg {
                    fill: ${Tokens.COLOURS.primary.blue["130"]};
                }
            }
        }

        &&:hover:not([data-state="checked"]) {
            background-color: ${Tokens.COLOURS.greyscale.cloud};
            border: ${Tokens.BORDERS.normal.transparent};

            label {
                p {
                    color: ${Tokens.COLOURS.greyscale.night};
                }
                svg {
                    fill: ${Tokens.COLOURS.greyscale.night};
                }
            }
        }

        // Higher specificity to override GlobalStyle
        &:focus&:focus,
        &:focus-visible {
            ${focusBorderStyles};
        }
    }
`;
