import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledInnerContainer = styled.div`
    padding: ${UI.Tokens.SIZES[1.5]};
`;

export const StyledList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

export const StyledListItem = styled.li`
    padding: ${UI.Tokens.SIZES[0.5]};
    border-bottom: ${UI.Tokens.BORDERS.normal.silver};
`;

export const StyledItemButton = styled.button`
    appearance: none;
    border-radius: ${UI.Tokens.BORDERS.radius};
    background: ${UI.Tokens.COLOURS.greyscale.white};
    width: 100%;
    border: none;
    padding: ${UI.Tokens.SIZES[1]};
    font-size: 0.875rem;
    color: ${UI.Tokens.COLOURS.greyscale.night};
    &:hover {
        background: ${UI.Tokens.COLOURS.primary.blue[10]};
    }
    &:focus {
        box-shadow: 0px 0px 0px 2px ${UI.Tokens.COLOURS.greyscale.white},
            0px 0px 0px 4px ${UI.Tokens.COLOURS.primary.blue[100]};
    }
`;

export const StyledItemName = styled.span`
    color: ${UI.Tokens.COLOURS.greyscale.night};
    text-align: left;
    display: block;
    word-break: break-all;
`;

export const StyledItemDate = styled.span`
    color: ${UI.Tokens.COLOURS.greyscale.metal};
    white-space: nowrap;
    text-align: left;
    display: block;
`;

export const StyledEmptyMessage = styled.p`
    font-size: 0.875rem;
    padding-top: ${UI.Tokens.SIZES[8]};
    text-align: center;
    max-width: 200px;
    margin: 0 auto;
`;
