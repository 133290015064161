import { Button, Ds, Tokens } from "@accurx/design";
import styled, { css } from "styled-components";

export const StyledLayout = styled.div`
    display: flex;
    flex-direction: column;
    height: 100dvh;
    overflow: hidden;
`;

export const StyledWrapper = styled.div<{
    $isBordered?: boolean;
    $includeTopBorder?: boolean;
}>`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        flex-wrap: nowrap;
        flex-direction: row;
    }
    ${({ $isBordered, $includeTopBorder }) =>
        $isBordered &&
        css`
            @media (min-width: ${Tokens.BREAKPOINTS.md}) {
                background-color: ${Tokens.COLOURS.primary.green[100]};
                padding: ${$includeTopBorder ? Tokens.SIZES[1] : 0}
                    ${Tokens.SIZES[1]} ${Tokens.SIZES[1]};
            }
        `}
`;

export const StyledMainContent = styled.main`
    background-color: ${Tokens.COLOURS.greyscale.cloud};
    border-radius: ${Tokens.BORDERS.radius};
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`;

export const QuickViewContainer = styled.div``;
export const PageContainer = styled.div``;

export const StyledQuickViewWrapper = styled(Ds.Grid)<{
    $quickViewIsOpen: boolean;
}>`
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    width: 100%;

    ${QuickViewContainer} {
        border-left: ${Tokens.BORDERS.normal.silver};
        width: 100%;
        position: relative;
        @media (min-width: ${Tokens.BREAKPOINTS.md}) {
            min-width: 280px;
            flex: 0.5;
        }
        &:empty {
            min-width: 0;
            flex: 0;
        }
    }
    ${PageContainer} {
        min-width: 0;
        flex: 1;
        overflow: auto;
        max-height: 100%;
        display: flex;
        flex-direction: column;
    }
`;

export const primaryNavFocusState = css`
    // Higher specificity to override GlobalStyle
    &:focus&:focus,
    &:focus-visible {
        box-shadow: inset 0px -1px 0px #0f7070,
            0 0 0 2px ${Tokens.COLOURS.greyscale.white},
            0 0 0 4px ${Tokens.COLOURS.primary.blue[100]};
    }
`;

export const StyledTriggerButton = styled(Ds.Button).attrs({
    appearance: "primary",
})`
    background-color: #128484;
    border: 1px solid #0f7070;
    box-shadow: inset 0px -1px 0px #0f7070;
    ${primaryNavFocusState};

    &:hover {
        background-color: #037070;
        border-color: #035f5f;
        box-shadow: inset 0px -1px 0px #035f5f;
    }
    // Overriding button background in a focus state
    // because <Ds.Button /> doesn't have green colour variant
    &:focus&:focus {
        background-color: inherit;

        &:hover&:hover {
            background-color: #037070;
        }
    }

    &[data-state="open"],
    &:active {
        background-color: #026363;
        border: 1px solid #025454;
        box-shadow: none;
    }

    & span {
        overflow: hidden;
        text-overflow: ellipsis;

        // Add extra padding to text to match icons' internal paddings
        &:last-of-type {
            margin-right: ${Tokens.SIZES[0.5]};
        }
    }

    // Prevent icons withing buttons to shrink on smaller screen sizes
    svg {
        flex-shrink: 0;
    }
`;

export const StyledMessageButton = styled(Button)`
    display: block;
    background-color: #e9faf7;
    border: 1px solid #0f7070;
    box-shadow: inset 0px -1px 0px #0f7070;
    color: #014849;
    padding: ${Tokens.SIZES[1]};
    white-space: nowrap;
    ${primaryNavFocusState};

    // Add extra padding to text to match icons' internal padding
    & > span:last-of-type {
        margin-right: ${Tokens.SIZES[0.5]};
    }

    // Supports legacy and migrated icon components
    & path,
    & svg {
        fill: currentColor;
    }

    &:hover {
        background-color: ${Tokens.COLOURS.primary.green["25"]};
        color: #014849;
    }

    &:focus {
        background-color: ${Tokens.COLOURS.primary.green["25"]};
        outline: none;
    }

    &[data-state="open"] {
        background-color: ${Tokens.COLOURS.primary.green["50"]};
        box-shadow: none;
    }
`;
