import {
    Conversation,
    ConversationItem,
    PatientTriageRequestItem,
} from "@accurx/concierge/types";
import { ContactDetail } from "@accurx/message-component";
import { EmailAddressHelper, MobileNumberHelper } from "@accurx/shared";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";

import {
    TriageContactNumber,
    findValidNumbersFromTriageRequest,
} from "./findValidNumbersFromTriageRequest";
import { getDefaultContactDetail } from "./getDefaultContactDetail";

const mapConversationItemToContactDetail = (
    item: ConversationItem,
): ContactDetail[] => {
    if (
        item.contentType === "PatientEmail" &&
        EmailAddressHelper.isValidEmailAddress(item.emailAddress)
    ) {
        return [
            {
                method: "Email",
                value: item.emailAddress,
                origin: "Conversation",
            },
        ];
    }
    if (
        item.contentType === "PatientSms" &&
        MobileNumberHelper.isValidMobileNumber(item.mobileNumber)
    ) {
        return [
            {
                method: "Mobile",
                value: item.mobileNumber,
                origin: "Conversation",
            },
        ];
    }
    return [];
};

const getUniqueConversationOutboundContacts = (conversation: Conversation) => {
    const itemsOrderedByCreatedAt = orderBy(
        conversation.items,
        "createdAt",
        "desc",
    );
    const contactDetailsInConversation = itemsOrderedByCreatedAt
        .map(mapConversationItemToContactDetail)
        .flat();

    return uniqBy(contactDetailsInConversation, "value");
};

const triageTypeToContactOrigin = (
    triageType: TriageContactNumber["type"],
): ContactDetail["origin"] => {
    switch (triageType) {
        case "proxy":
            return "SubmittedByPatientProxy";
        case "patient":
        case "patientPreferred":
        default:
            return "SubmittedByPatient";
    }
};

/**
 * Returns
 * - The patient's preference to select who should be contacted:
 *   This is an explicit preference if proxy submits
 *   or the patient's number if different from EMR
 *   or null
 * - The unique contacts which don't match EMR number.
 */
const getTriageItemsWithPreference = (
    conversation: Conversation,
    demographicsNumber?: string,
): {
    contactDetails: ContactDetail[];
    preferred: string | null;
} => {
    const triageItem = conversation.items.find(
        (item): item is PatientTriageRequestItem =>
            item.contentType === "PatientTriageRequestNote",
    );

    if (triageItem) {
        const foundNumbers = findValidNumbersFromTriageRequest(triageItem);

        // If the numbers are same as EMR, we don't need them
        // because EMR wins in order of importance
        const numbersDifferentFromDemographics = foundNumbers.filter(
            (item) => item.value !== demographicsNumber,
        );
        if (numbersDifferentFromDemographics.length) {
            const uniqueTriageNumbers = uniqBy(
                numbersDifferentFromDemographics,
                "value",
            );

            const specifiedPreferredNumber = foundNumbers.find(
                ({ type }) => type === "patientPreferred",
            )?.value;
            const patientNumber = foundNumbers.find(
                ({ type }) => type === "patient",
            )?.value;

            // Preferred is either the one specified or the first one in the patient triage form
            const preferred = specifiedPreferredNumber ?? patientNumber ?? null;
            const contactDetails = uniqueTriageNumbers.map(
                ({ type, value }) => ({
                    method: "Mobile" as const,
                    value,
                    origin: triageTypeToContactOrigin(type),
                }),
            );

            return {
                contactDetails,
                preferred,
            };
        }
    }

    return { contactDetails: [], preferred: null };
};

export const getPatientContactsWithDefaultNew = ({
    conversation,
    demographicsData,
}: {
    conversation: Conversation;
    demographicsData?: ContactDetail[];
}) => {
    // Unique contacts from conversation sorted by date
    const uniqueContactDetailsFromConversation =
        getUniqueConversationOutboundContacts(conversation);
    const triageItemsToDisplay = getTriageItemsWithPreference(
        conversation,
        demographicsData?.find((item) => item.method === "Mobile")?.value,
    );

    // Put together fetched demographics data & conversation data
    const contactDetails = [
        ...uniqueContactDetailsFromConversation,
        ...triageItemsToDisplay.contactDetails,
        ...(demographicsData ?? []),
    ];

    // Work out which contact should be pre-selected
    const defaultContact = getDefaultContactDetail(
        contactDetails,
        triageItemsToDisplay.preferred,
    );

    return {
        contactDetails,
        default: defaultContact,
    };
};
