import { addDays, endOfDay } from "date-fns";

import { AppointmentAvailabilityPeriod } from "./types";

export const appointmentAvailabilityPeriodCalculation = (
    sendTimeAsDateTime: Date,
    appointmentAvailabilityPeriod?: AppointmentAvailabilityPeriod,
): Date => {
    let calculatedFutureDate: Date = sendTimeAsDateTime;
    if (appointmentAvailabilityPeriod) {
        const { units, value } = appointmentAvailabilityPeriod;
        const daysToAdd = units === "weeks" ? value * 7 : value;
        calculatedFutureDate = addDays(calculatedFutureDate, daysToAdd - 1);
    }

    return endOfDay(calculatedFutureDate);
};
