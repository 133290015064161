import { ChangeEvent, useCallback, useEffect, useState } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import {
    AttachmentStatus,
    Ds,
    Feedback,
    FileUploadRequest,
    Option,
    useErrorSummary,
} from "@accurx/design";
import { validateFile } from "@accurx/design/dist/components/Attachment/Attachment.hooks";
import {
    useCreateTemplateMutation,
    useEditMessageTemplateMutation,
} from "@accurx/message-templates";
import { Log } from "@accurx/shared";
import every from "lodash/every";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import last from "lodash/last";
import { useDispatch } from "react-redux";
import {
    generatePath,
    useHistory,
    useLocation,
    useParams,
} from "react-router-dom";
import { toast } from "react-toastify";

import FlemingApiClient from "api/FlemingApiClient";
import { MessageTemplateOwner, MessageTemplateType } from "api/FlemingDtos";
import { FlemingAnalyticsTracker } from "app/analytics";
import { TemplateSaveButtonProps } from "app/analytics/FlemingAnalytics";
import { BatchSnomedCode } from "app/batchMessage/gp/BatchMessage.types";
import { useMessageTemplateCategoriesQuery } from "app/hooks/queries";
import { createPreviewUrl } from "app/messageTemplates/MessageTemplatesHelper";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { LoadingStatus } from "shared/LoadingStatus";
import { ROUTES_CHAIN, ROUTES_WORKSPACE } from "shared/Routes";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

import { TabIds } from "../manageTemplates";
import { getMessageTemplates } from "../manageTemplates/ManageTemplates.actions";
import { Template } from "../manageTemplates/ManageTemplates.types";
import * as helpers from "./ManageTemplatesFormPage.helpers";
import {
    AddCategoryFormState,
    FormState,
    FormTemplateAttachment,
    ManageTemplatesFormHook,
    ObjectUrl,
    Params,
} from "./ManageTemplatesFormPage.types";

const initialState: Omit<FormState, "owner"> = {
    id: null,
    templateName: "",
    body: "",
    allowReplyByDefault: false,
    allowAsBatch: false,
    allowAsSms: true,
    category: "",
    categorySelect: helpers.CATEGORIES_EMPTY_OPTION,
    snomedCode: null,
    // always false as video not supported from this page
    isDefault: false,
    // always "sms" as video not supported from this page
    type: MessageTemplateType.Sms,
    attachments: [],
};

const useDefaultOwner = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("isWorkspaceTemplate") === "true"
        ? MessageTemplateOwner.Organisation
        : MessageTemplateOwner.User;
};

export const useIsOrgTemplate = (
    templateOwner?: Template["owner"],
): boolean => {
    const owner = useDefaultOwner();

    // uses state rather than the query param when the owner is known, ensures changing/removing the
    // query param by accident doesn't result in incorrect information when editing an existing template
    return templateOwner
        ? templateOwner === "Organisation"
        : owner === MessageTemplateOwner.Organisation;
};

/*
 * If something we expect to always be true isn't true,
 * we should log the error, show the user an error message, and bounce
 * them back to the overview page
 */
const useAssertion = (isDesktopPage: boolean, workspaceId: number) => {
    const history = useHistory();
    return useCallback(
        (assertion: string, value: boolean) => {
            if (!value) {
                Log.error(new Error(`Assertion error: ${assertion}`));
                toast(
                    Feedback({
                        colour: "error",
                        title: "Unexpected error",
                        children:
                            "Something went wrong. Please contact support",
                    }),
                );
                history.replace(
                    isDesktopPage
                        ? generatePath(ROUTES_CHAIN.practicesManageTemplates, {
                              orgId: workspaceId,
                          })
                        : generatePath(
                              ROUTES_WORKSPACE.workspaceTemplatesOverview,
                              { workspaceId },
                          ),
                );
            }
        },
        [history, isDesktopPage, workspaceId],
    );
};

const toastFailure = (action: string) => {
    toast(
        Feedback({
            colour: "error",
            title: `Failed to ${action} template`,
            children: "Something went wrong, please try again",
        }),
    );
};

const trackSubmitButtonClick = {
    create: FlemingAnalyticsTracker.trackTemplateCreateConfirmButtonClick,
    copy: FlemingAnalyticsTracker.trackTemplateCopyConfirmButtonClick,
    edit: FlemingAnalyticsTracker.trackTemplateEditConfirmButtonClick,
};

export const useManageTemplatesForm = (
    template?: Optional<Template>,
    isDesktopPage?: boolean,
): ManageTemplatesFormHook => {
    const history = useHistory();

    const { mutateAsync: createTemplate } = useCreateTemplateMutation();
    const { mutateAsync: editTemplate } = useEditMessageTemplateMutation();

    const { orgId, latestIntegratedSystem } = useCurrentWorkspace();
    const owner = useDefaultOwner();
    const isOrgTemplate = useIsOrgTemplate();
    const assertion = useAssertion(isDesktopPage ?? false, orgId);
    const { action } = useParams<Params>();
    const { errors, addError, clearAllErrors, errorsByKey } = useErrorSummary();
    const [state, setState] = useState<FormState>({ ...initialState, owner });
    const [characterCounts, setCharacterCounts] = useState<
        ManageTemplatesFormHook["characterCounts"]
    >({
        templateName: 0,
        body: 0,
    });
    const [categories, setCategories] = useState<
        ManageTemplatesFormHook["categories"]
    >([]);
    const [addCategoryFormValues, setAddCategoryFormValues] = useState<
        ManageTemplatesFormHook["addCategoryFormValues"]
    >({ newCategoryName: "" });
    const [addCategoryFormErrors, setAddCategoryFormErrors] = useState<
        ManageTemplatesFormHook["addCategoryFormErrors"]
    >({ newCategoryName: [""] });
    const [addCategoryIsModalOpen, setAddCategoryIsModalOpen] =
        useState<ManageTemplatesFormHook["addCategoryIsModalOpen"]>(false);
    const [addCategorySuccessStatus, setAddCategorySuccessStatus] =
        useState<ManageTemplatesFormHook["addCategorySuccessStatus"]>("");

    const shouldShowAttachmentsSection = useIsFeatureEnabled(
        "GPTemplateAttachments",
    );

    const {
        data: categoriesData,
        status: categoriesStatus,
        error: categoriesError,
    } = useMessageTemplateCategoriesQuery({
        organisationId: orgId.toString(),
        owner: isOrgTemplate ? "organisation" : "user",
    });

    useEffect(() => {
        if (template) {
            // we're making some assumptions, so if at some point
            // those assumptions become wrong, we want to barf
            // rather than silently strip any unhandled data
            assertion(
                "Only one Snomed code is supported",
                (template.snomedCodes ?? []).length <= 1,
            );

            setState({
                // if we're copying the template, we don't add its `id` to
                // the form state, as we want to create a new one
                id: action === "copy" ? null : template.id,
                // if we're copying the template, it's often because we're
                // moving it from being a personal template to an organisation
                // template - so we want to overwrite the `owner`.
                owner,
                templateName: template.title,
                body: template.body,
                allowReplyByDefault: template.allowReplyByDefault,
                allowAsBatch: template.isAllowedAsBatch,
                allowAsSms: template.isAllowedAsSms,
                category: helpers.generateCategoryFromTemplatePath(
                    template.path,
                ),
                categorySelect:
                    helpers.generateCategoryDropdownFromTemplatePath(
                        template.path,
                    ),
                snomedCode: first(template.snomedCodes) as BatchSnomedCode,
                isDefault: template.isDefault,
                type: template.type as unknown as MessageTemplateType,
                attachments: template.attachments?.map((attachment) => ({
                    id: attachment.id,
                    fileSize: attachment.fileSize,
                    fileName: attachment.fileName,
                    fileType: last(attachment.fileName?.split(".")) ?? "", // we don't store mimetype on server so extract out file ending
                    // When loading in edit mode, generate a previewUrl that links to a path that displays the image
                    previewUrl: createPreviewUrl({
                        orgId,
                        documentId: attachment.id?.toString(),
                        templateId: template.id
                            ? parseInt(template.id, 10)
                            : null,
                    }),
                    errors: [],
                    status: AttachmentStatus.Success,
                })),
            });
            setCharacterCounts({
                templateName: template.title.length,
                body: template.body.length,
            });
        }
    }, [action, template, owner, assertion, orgId]);

    useEffect(() => {
        if (
            categoriesStatus === "success" &&
            categoriesData &&
            categoriesData.categories
        ) {
            setCategories([
                helpers.CATEGORIES_EMPTY_OPTION,
                ...helpers.toOptions(categoriesData.categories),
            ]);
        }
    }, [categoriesStatus, categoriesData]);
    const [submitting, setSubmitting] = useState(false);

    const validate = () => {
        const templateNameErrors = validateTemplateName(state.templateName);
        const messageBodyErrors = validateMessageBody(state.body);

        return {
            isValid:
                templateNameErrors.length === 0 &&
                messageBodyErrors.length === 0,
            errors: [...templateNameErrors, ...messageBodyErrors],
        };
    };
    const validateTemplateName = (templateName: string) => {
        const errors = [];

        if (templateName.length === 0) {
            const errorMessage = "Template name can't be empty";
            addError({
                id: "templateName",
                body: errorMessage,
            });
            errors.push(errorMessage);
        } else if (templateName.length > 50) {
            const errorMessage =
                "Template name can't be more than 50 characters long";
            addError({
                id: "templateName",
                body: errorMessage,
            });
            errors.push(errorMessage);
        }

        return errors;
    };

    const validateMessageBody = (body: string) => {
        const errors = [];

        if (body.length === 0) {
            const errorMessage = "Message can't be empty";
            addError({ id: "message", body: errorMessage });
            errors.push(errorMessage);
        } else if (body.length > helpers.MAX_BODY_LENGTH) {
            const errorMessage =
                "Message including intro and signature can't be more than 612 characters long";
            addError({
                id: "message",
                body: errorMessage,
            });
            errors.push(errorMessage);
        }

        return errors;
    };

    /*
     * Change handlers made as generic as possible, but we need different
     * ones for different types of input.
     */
    const onInputChange =
        (
            key: keyof FormState,
        ): React.FormEventHandler<HTMLInputElement | HTMLTextAreaElement> =>
        (e) => {
            setCharacterCounts({
                ...characterCounts,
                [key]: e.currentTarget.value.length,
            });
            setState({ ...state, [key]: e.currentTarget.value });
        };

    const onMultiCheckboxChange =
        (key1: keyof FormState, key2: keyof FormState) =>
        (checkboxState1: boolean, checkboxState2: boolean) => {
            setState({
                ...state,
                [key1]: !checkboxState1,
                [key2]: !checkboxState2,
            });
        };

    const onCheckboxChange =
        (key: keyof FormState) => (checkboxState: boolean) => {
            setState({ ...state, [key]: !checkboxState });
        };

    const onSnomedCodeChange = (snomedCode: Optional<BatchSnomedCode>) => {
        setState({ ...state, snomedCode });
    };

    const onCategorySelectChange = (selected: Option | Option[]) => {
        const option = Array.isArray(selected) ? selected[0] : selected;
        setState({ ...state, categorySelect: option });
    };

    const onAttachmentsChange = (attachments: FormState["attachments"]) => {
        setState((prevState) => ({ ...prevState, attachments }));
    };

    const onCategoryFormInputChange =
        (
            key: keyof AddCategoryFormState,
        ): React.FormEventHandler<HTMLInputElement | HTMLTextAreaElement> =>
        (e) => {
            setAddCategoryFormErrors({ ...addCategoryFormErrors, [key]: [] });
            setAddCategoryFormValues({
                ...addCategoryFormValues,
                [key]: e.currentTarget.value,
            });
        };

    const onRemoveAttachment = (id: FormTemplateAttachment["id"]) => {
        setState((prevState) => ({
            ...prevState,
            attachments: [
                ...prevState.attachments.filter(
                    (attachment) => attachment.id !== id,
                ),
            ],
        }));
    };

    const uploadFileRequest = (request: FileUploadRequest) =>
        FlemingApiClient.uploadTemplateAttachment({
            file: request.file,
            organisationId: orgId.toString(),
        });

    const onAddAttachment = async (
        e: ChangeEvent<HTMLInputElement>,
    ): Promise<void> => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.files === null) return;

        // Only allow uploading a single file at once
        const file = e.target.files[0];
        const { isValid, errors } = validateFile({
            file,
            allowedFileExtensions: helpers.ALLOWED_FILE_EXTENSIONS,
            maxAllowedFileSize: helpers.ALLOWED_FILE_SIZE,
        });

        onAttachmentsChange([
            helpers.mapDataToAttachment({
                attachmentId: helpers.generateClientsideAttachmentId(),
                file,
                errors: [...errors],
                status: isValid
                    ? AttachmentStatus.Loading
                    : AttachmentStatus.Error,
            }),
        ]);

        // when there are clientside errors, do not upload file
        if (!isValid) {
            return;
        }

        try {
            const { result, error } = await uploadFileRequest({ file });
            if (error) {
                // handle serverside error
                Log.warn("Unable to upload user file in template creation", {
                    originalException: error,
                    tags: {
                        logger: "ManageTemplatesForm",
                        fileType: file.type,
                    },
                });
                onAttachmentsChange([
                    helpers.mapDataToAttachment({
                        attachmentId: helpers.generateClientsideAttachmentId(),
                        file,
                        errors: ["Unable to upload file"],
                        status: AttachmentStatus.Error,
                    }),
                ]);
            }
            if (result?.id) {
                // if uploading file is successful, add to attachments list
                const dataUrl = window.URL.createObjectURL(file) as ObjectUrl;
                onAttachmentsChange([
                    helpers.mapDataToAttachment({
                        file,
                        attachmentId: result.id,
                        dataUrl: dataUrl,
                        errors: [],
                        status: AttachmentStatus.Success,
                    }),
                ]);
            }
        } catch (error) {
            // if not successful, show error
            Log.warn("Unable to upload user file in template creation", {
                originalException: error,
                tags: { logger: "ManageTemplatesForm", fileType: file.type },
            });
            onAttachmentsChange([
                helpers.mapDataToAttachment({
                    attachmentId: helpers.generateClientsideAttachmentId(),
                    file,
                    errors: ["Unable to upload file"],
                    status: AttachmentStatus.Error,
                }),
            ]);
        }
    };

    const overviewPath = generatePath(
        `${ROUTES_CHAIN.practicesManageTemplates}?tab=${
            owner === MessageTemplateOwner.User
                ? TabIds.UserTemplates
                : TabIds.OrganisationTemplates
        }`,
        {
            orgId,
        },
    );

    const webOverviewPath = generatePath(
        `${ROUTES_WORKSPACE.workspaceTemplatesOverview}?tab=${
            owner === MessageTemplateOwner.User
                ? TabIds.UserTemplates
                : TabIds.OrganisationTemplates
        }`,
        {
            workspaceId: orgId,
        },
    );

    const logSnomedInWorkspaceWithoutEPRConnection = (): void => {
        if (
            latestIntegratedSystem === undefined &&
            template &&
            template.snomedCodes.length > 0
        ) {
            Log.error(
                `Template ${template.title} belongs to a workspace withoue EPR connection`,
                {
                    tags: { logger: "ManageTemplatesForm" },
                },
            );
        }
    };

    const flemingLoggedInProps = useFlemingLoggedInAnalytics();

    const onSuccess = () => {
        // replace rather than push so the user can't go back
        history.replace(isDesktopPage ? overviewPath : webOverviewPath);
        const action = state.id ? "updated" : "created";

        toast(
            <Feedback colour="success" title={`Template ${action}`}>
                <Ds.Text>
                    Template "{state.templateName}" {action},
                </Ds.Text>
            </Feedback>,
        );
    };

    const onSubmit: React.FormEventHandler = (e) => {
        e.preventDefault();
        clearAllErrors();
        const { isValid, errors: validationErrors } = validate();

        const analyticsProps: TemplateSaveButtonProps = {
            ...flemingLoggedInProps,
            ...(isValid
                ? { hasError: false }
                : {
                      hasError: true,
                      errorReason: validationErrors,
                  }),
            productOrigin: "PatientMessage",
            templateLevel: isOrgTemplate ? "Organisation" : "User",
            pageOrigin: "ManageTemplatePage",
            templateType: "sms",
            templateName: state.templateName,
            countAttachment: state.attachments.length,
            countSnomedCode: state.snomedCode?.conceptId ? 1 : 0,
            countPatientDetail: 0,
        };

        const mode = action ? action : "create";

        trackSubmitButtonClick[mode](analyticsProps);

        if (isValid) {
            setSubmitting(true);
            logSnomedInWorkspaceWithoutEPRConnection();

            const template = helpers.toRequestBody(state);

            if (mode === "create" || mode === "copy") {
                createTemplate({
                    workspaceId: orgId,
                    template,
                })
                    .then(() => {
                        onSuccess();
                    })
                    .catch(() => {
                        setSubmitting(false);
                        toastFailure("save");
                    });
            } else {
                editTemplate({
                    workspaceId: orgId,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    templateId: state.id!,
                    template,
                })
                    .then(() => {
                        onSuccess();
                    })
                    .catch(() => {
                        setSubmitting(false);
                        toastFailure("save");
                    });
            }
        }
    };

    const onCancel = () => {
        history.push(isDesktopPage ? overviewPath : webOverviewPath);
    };

    const validateNewCategoryName = (body: string): string[] => {
        if (body.replace(/\//g, "").trim() === "") {
            return ["Please enter a category"];
        }
        if (body.length > 150) {
            return ["Please enter a category length less than 150 characters"];
        }
        if (body.trim().toLowerCase() === helpers.CATEGORIES_RESERVED_VALUE) {
            return [
                'The category name "No category" is reserved. Either add a new category name or select "No category" from the existing list.',
            ];
        }
        if (body.includes(">")) {
            return [
                'To create sub-folders, please use a forward slash "/" instead of ">"',
            ];
        }
        return [];
    };
    const validateNewCategoryForm = () => {
        const newErrors = {
            newCategoryName: validateNewCategoryName(
                addCategoryFormValues.newCategoryName,
            ),
        };
        setAddCategoryFormErrors({ ...addCategoryFormErrors, ...newErrors });
        return newErrors;
    };
    const onAddCategoryClose = () => {
        setAddCategoryIsModalOpen(false);
        // clear modal form data
        setAddCategoryFormValues({ newCategoryName: "" });
        setAddCategoryFormErrors({ newCategoryName: [""] });
    };
    const onAddCategoryOpen = () => {
        setAddCategoryIsModalOpen(true);
    };

    const onAddCategorySubmit: React.FormEventHandler = (e) => {
        e.preventDefault();
        e.stopPropagation(); // Don't bubble up to the page-level form
        // Validate - if category already exists in the list, show an error
        const errors = validateNewCategoryForm();
        // If no errors
        if (every(errors, isEmpty)) {
            const newCategory = helpers.createNewOption(
                addCategoryFormValues.newCategoryName,
            );
            onCategorySelectChange(newCategory);
            if (helpers.hasDuplicateInList(categories, newCategory.value)) {
                setAddCategorySuccessStatus("The category already exists");
            } else {
                setCategories((prevCategories) => [
                    newCategory,
                    ...prevCategories,
                ]);
                setAddCategorySuccessStatus("Category successfully added");
            }
            onAddCategoryClose();
        }
    };
    // On page submit, ensure form category is submitted on server.

    return {
        values: state,
        onChange: {
            templateName: onInputChange("templateName"),
            body: onInputChange("body"),
            allowReplyByDefault: onCheckboxChange("allowReplyByDefault"),
            allowAsBatch: onCheckboxChange("allowAsBatch"),
            allowAsSms: onCheckboxChange("allowAsSms"),
            disallowAsSms: onMultiCheckboxChange(
                "allowAsSms",
                "allowReplyByDefault",
            ),
            category: onInputChange("category"),
            categorySelect: onCategorySelectChange,
            snomedCode: onSnomedCodeChange,
            attachments: onAttachmentsChange,
        },
        errorsCount: errors.length,
        errorsByKey,
        characterCounts,
        submitting,
        categories,
        categoriesError,
        categoriesStatus,
        addCategoryFormValues,
        addCategoryFormErrors,
        addCategoryFormOnChange: {
            newCategoryName: onCategoryFormInputChange("newCategoryName"),
        },
        addCategorySuccessStatus,
        addCategoryIsModalOpen,
        onSubmit,
        onCancel,
        onAddCategorySubmit,
        onAddCategoryClose,
        onAddCategoryOpen,
        onAddAttachment,
        onRemoveAttachment,
        shouldShowAttachmentsSection,
    };
};

/*
 * Because this component is used from both the /create and /:templateId/:action
 * pages, we _may_ have a templateId that we want to fetch the template
 * for, or we may not. This hook checks the url params for a template id
 * and fetches it from the redux state if one is found. It also fetches
 * the template data from the server (if it hasn't already been fetched).
 */
export const useMessageTemplate = (): {
    template: Optional<Template>;
    loadingStatus: LoadingStatus;
} => {
    const { templateId } = useParams<Params>();
    const dispatch = useDispatch();
    const state = useAppSelector(({ manageTemplates }) => manageTemplates);
    const { orgId } = useCurrentWorkspace();
    const {
        messageTemplates: templates,
        messageTemplatesLoadingStatus: loadingStatus,
    } = state;

    useEffect(() => {
        // we only fetch the message templates here if they haven't
        // already been fetched, as they probably have been - we've most
        // likely arrived at this page from one of the overview page tabs,
        // which will have already loaded the templates. but we make sure
        // here they are loaded in case we directly link to this page
        if (!!orgId && templateId && loadingStatus === LoadingStatus.Initial) {
            dispatch(
                getMessageTemplates({
                    organisationId: orgId,
                    includeVideo: false,
                }),
            );
        }
    }, [dispatch, orgId, templateId, loadingStatus]);

    if (!templateId) {
        return { loadingStatus: LoadingStatus.Loaded, template: null };
    }

    const template = templates.find((template) => template.id === templateId);

    return {
        loadingStatus,
        template,
    };
};
