import { useParams } from "react-router";

import { Page } from "../components/Page";
import { InboxConversationView } from "./components/InboxConversationView";

export const ConversationPage = () => {
    const { conversationId } = useParams<{
        conversationId: string;
    }>();

    return (
        <Page>
            <InboxConversationView conversationId={conversationId} />
        </Page>
    );
};
