import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import { IOrganisation } from "api/FlemingDtos";
import { actionCreators as accountActions } from "app/account/AccountActions";
import { useAppSelector } from "store/hooks";

import { useCurrentUser } from "./";

/**
 * @deprecated
 * Beware this hook might not work in routes outside of Manage Org, so use `useCurrentWorkspace` instead.
 */
export const useCurrentOrganisation = (): Optional<IOrganisation> => {
    const dispatch = useDispatch();
    const user = useCurrentUser();
    const { orgId } = useParams<{ orgId: string }>();
    const urlOrgId = orgId && parseInt(orgId, 10);
    const currentOrgId = useAppSelector(
        ({ account }) => account.selectedOrganisation,
    );

    useEffect(() => {
        if (!urlOrgId) {
            // if there's no orgId in the url, we should clear
            // the selected org in the redux state, too
            dispatch(accountActions.clearOrganisation());
        } else if (
            user?.settings?.accuRxUserId &&
            urlOrgId &&
            currentOrgId !== urlOrgId
        ) {
            // if the selectedOrganisation in the redux state doesn't match
            // what's in the url, we should dispatch an action to update it
            dispatch(
                accountActions.selectOrganisation(
                    user?.settings.accuRxUserId,
                    urlOrgId,
                ),
            );
        }
    }, [dispatch, currentOrgId, urlOrgId, user?.settings?.accuRxUserId]);

    return useAppSelector(({ account }) =>
        account?.user?.organisations.find(
            // this is a bit of an annoying hack, but lots of places (including tests)
            // rely on the org being in the state even if it's not in the url, so
            // we need to check the url orgId here first, and default to what's in the state
            // if the orgId isn't set in the url. as we move more towards keeping state
            // in the url, we may get to the point where we can remove this
            (org) => org.orgId === (urlOrgId || currentOrgId),
        ),
    );
};
