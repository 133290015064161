import { filters } from "domains/concierge/internal/util/conversationGroupFilters";
import { sortOptions } from "domains/concierge/internal/util/conversationGroupSortOptions";

import { useConversationCount } from "./useConversationCount";

export const useTeamConversationCount = (teamId: string) => {
    const status = "Open";
    const sort = "Newest";

    return useConversationCount(
        filters.team({ teamId, status }),
        sortOptions.common({ status, sort }),
    );
};
