import React from "react";

import { StyledContainer } from "./ConversationFeedOptionsWrapper.styles";

export type ConversationFeedOptionsWrapperProps = {
    children: React.ReactNode;
};

export const ConversationFeedOptionsWrapper = ({
    children,
}: ConversationFeedOptionsWrapperProps) => {
    return (
        <StyledContainer alignItems="center" gap="1">
            {children}
        </StyledContainer>
    );
};
