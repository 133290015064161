import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import styled from "styled-components";

export const StyledPillButton = styled(Pill.Button)<{ isError: boolean }>`
    ${({ isError }) =>
        isError
            ? `
        &:enabled{
            box-shadow: inset 0 -2px 0 ${UI.Tokens.COLOURS.primary.red[100]},
                inset 0 0 0 1px ${UI.Tokens.COLOURS.primary.red[100]};
        }
    `
            : null};

    width: auto;

    > span {
        overflow: hidden;
    }
`;
