import {
    ConversationSort,
    GetConversationsResponse,
} from "@accurx/api/clinician-messaging";
import { mapGetConversationsResponseToConciergeUpdate } from "domains/concierge/internal/api/clinicianMessaging/mappers/mapGetConversationsResponseToConciergeUpdate";
import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";

import * as ClinicianMessagingApiClient from "../ClinicianMessagingApiClient";

type GetConversationsRequest = {
    workspaceId: number;
    isArchived?: boolean;
    assigneeUserId?: string;
    assigneeGroupId?: string;
    updatedBeforeDate?: string;
    limit?: number;
    sort: ConversationSort;
};

export const getConversations: ConciergeFetcher<
    GetConversationsRequest,
    GetConversationsResponse
> = async (arg) => {
    const response = await ClinicianMessagingApiClient.fetchConversations(arg);

    return {
        updates: mapGetConversationsResponseToConciergeUpdate(response),
        response,
    };
};
