import { useCurrentUser } from "@accurx/auth";
import { useAllConversationsConversationCount } from "@accurx/concierge/hooks/data/conversationGroupConversationCount/useAllConversationsConversationCount";
import { useAllConversationsFailedMessagesConversationCount } from "@accurx/concierge/hooks/data/conversationGroupConversationCount/useAllConversationsFailedMessages";
import { useAllConversationsPatientResponsesConversationCount } from "@accurx/concierge/hooks/data/conversationGroupConversationCount/useAllConversationsPatientResponsesConversationCount";
import { useAllConversationsPatientTriagesConversationCount } from "@accurx/concierge/hooks/data/conversationGroupConversationCount/useAllConversationsPatientTriagesConversationCount";
import { useClinicianAllConversationsConversationCount } from "@accurx/concierge/hooks/data/conversationGroupConversationCount/useClinicianAllConversationsConversationCount";
import { useClinicianTeamConversationCount } from "@accurx/concierge/hooks/data/conversationGroupConversationCount/useClinicianTeamConversationCount";
import { useClinicianUserConversationCount } from "@accurx/concierge/hooks/data/conversationGroupConversationCount/useClinicianUserConversationCount";
import { usePatientConversationCount } from "@accurx/concierge/hooks/data/conversationGroupConversationCount/usePatientConversationCount";
import { useTeamConversationCount } from "@accurx/concierge/hooks/data/conversationGroupConversationCount/useTeamConversationCount";
import { useUnassignedTriageConversationCount } from "@accurx/concierge/hooks/data/conversationGroupConversationCount/useUnassignedTriageConversationCount";
import { useUserConversationCount } from "@accurx/concierge/hooks/data/conversationGroupConversationCount/useUserConversationCount";
import {
    useUnassignedTeamClinician,
    useUnassignedTeamPatient,
} from "@accurx/concierge/hooks/data/useUnassignedTeam";

import { ConversationCountData } from "../ConversationCountTooltip";

type Props = {
    children: (arg: ConversationCountData) => JSX.Element;
};

const toRenderProps = (
    props: Props,
    data: { count: number; isFullyLoaded: boolean } | undefined,
) => {
    if (!data) {
        return props.children({ status: "Loading" });
    }

    return props.children({
        status: "Success",
        count: data.count,
        isFullyLoaded: data.isFullyLoaded,
    });
};

const MyInbox = (props: Props) => {
    const currentUserId = useCurrentUser().user.accuRxUserId;
    const data = useUserConversationCount(currentUserId);
    return toRenderProps(props, data);
};

const Team = (props: Props & { teamId: string }) => {
    const data = useTeamConversationCount(props.teamId);
    return toRenderProps(props, data);
};

const Colleague = (props: Props & { userId: string }) => {
    const data = useUserConversationCount(props.userId);
    return toRenderProps(props, data);
};

const Unassigned = (props: Props) => {
    const unassignedTeamId = useUnassignedTeamPatient()?.id ?? "";
    const data = useTeamConversationCount(unassignedTeamId);
    return toRenderProps(props, data);
};

const UnassignedAdmin = (props: Props) => {
    const unassignedTeamId = useUnassignedTeamPatient()?.id ?? "";
    const data = useUnassignedTriageConversationCount({
        triageType: "Admin",
        teamId: unassignedTeamId,
        status: "Open",
    });

    return toRenderProps(props, data);
};

const UnassignedMedical = (props: Props) => {
    const unassignedTeamId = useUnassignedTeamPatient()?.id ?? "";
    const data = useUnassignedTriageConversationCount({
        triageType: "Medical",
        teamId: unassignedTeamId,
        status: "Open",
    });

    return toRenderProps(props, data);
};

const AllConversations = (props: Props) => {
    const data = useAllConversationsConversationCount();
    return toRenderProps(props, data);
};

const AllConversationsPatientResponses = (props: Props) => {
    const data = useAllConversationsPatientResponsesConversationCount();
    return toRenderProps(props, data);
};

const AllConversationsPatientTriages = (props: Props) => {
    const data = useAllConversationsPatientTriagesConversationCount();
    return toRenderProps(props, data);
};

const AllConversationsFailedMessages = (props: Props) => {
    const data = useAllConversationsFailedMessagesConversationCount();
    return toRenderProps(props, data);
};

const WithCareProvidersMyInbox = (props: Props) => {
    const currentUserId = useCurrentUser().user.accuRxUserId;
    const data = useClinicianUserConversationCount(currentUserId);
    return toRenderProps(props, data);
};

const WithCareProvidersUnassigned = (props: Props) => {
    const unassignedTeamId = useUnassignedTeamClinician()?.id ?? "";
    const data = useClinicianTeamConversationCount(unassignedTeamId);
    return toRenderProps(props, data);
};

const WithCareProvidersAllConversations = (props: Props) => {
    const data = useClinicianAllConversationsConversationCount();
    return toRenderProps(props, data);
};

const Patient = (props: Props & { patientInternalId: string }) => {
    const data = usePatientConversationCount(props.patientInternalId);
    return toRenderProps(props, data);
};

export const TotalCountProvider = {
    MyInbox,
    Team,
    Colleague,
    Unassigned,
    UnassignedAdmin,
    UnassignedMedical,
    AllConversations,
    AllConversationsPatientResponses,
    AllConversationsPatientTriages,
    AllConversationsFailedMessages,
    WithCareProvidersMyInbox,
    WithCareProvidersUnassigned,
    WithCareProvidersAllConversations,
    Patient,
};
