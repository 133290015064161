import { EventPayload, SignalRSubscriptionEvent } from "@accurx/realtime";
import { mapUserGroupToTeamSummary } from "domains/concierge/internal/api/shared/mappers/mapUserGroupToTeamSummary";
import { SubscriptionParams } from "domains/concierge/internal/hooks/useSignalRSubscription";
import { TeamSummary } from "domains/concierge/types";
import isNil from "lodash/isNil";

const mapToTeamSummary = (
    payload: EventPayload<SignalRSubscriptionEvent.OnUserGroupChanged>,
    currentUserId: string,
): TeamSummary | undefined => {
    if (isNil(payload.organisationId)) {
        return undefined;
    }

    if (isNil(payload.userMembership)) {
        return undefined;
    }

    const isMember = !!payload.userMembership.find(
        (membership) => membership.userId === currentUserId,
    );

    return mapUserGroupToTeamSummary({
        id: payload.userGroup?.id,
        name: payload.userGroup?.name,
        type: payload.userGroup?.groupType,
        isMember,
    });
};

export const OnUserGroupChanged = ({
    subscribe,
    workspaceId,
    currentUserId,
    onUserGroupChanged,
}: Pick<SubscriptionParams, "subscribe" | "workspaceId" | "currentUserId"> & {
    onUserGroupChanged: (teamUpdate: TeamSummary) => void;
}) => {
    const subscription = subscribe({
        type: SignalRSubscriptionEvent.OnUserGroupChanged,
        eventFilter: (payload) => payload.organisationId === workspaceId,
        onEvent: (payload) => {
            const teamUpdate = mapToTeamSummary(payload, currentUserId);
            if (!teamUpdate) return;

            onUserGroupChanged(teamUpdate);
        },
    });

    return subscription.unsubscribe.bind(subscription);
};
