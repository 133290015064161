import { useCurrentUser, useCurrentWorkspace } from "@accurx/auth";
import { useSendMutation } from "@accurx/concierge/hooks/mutations/useSendMutation";
import { patientNhsNumber } from "@accurx/concierge/util/patientNhsNumber";
import * as UI from "@accurx/design";
import {
    ClinicianCompose,
    ComposePatient,
    ComposeProvider,
    OnClinicianMessageSendFn,
    generateClinicianGreeting,
    generateSignature,
    useClinicianMessageSendAnalytics,
    useMaxAttachmentCount,
} from "@accurx/message-component";
import { Log } from "@accurx/shared";
import { isTestPatient } from "domains/compose/utils/isTestPatient";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { useAppOrigin } from "../compose/hooks/useAppOrigin";

type ClinicianComposeNewMessageProps = {
    patient: ComposePatient;
};

export const ClinicianComposeNewMessage = ({
    patient,
}: ClinicianComposeNewMessageProps) => {
    const sendMessageMutation = useSendMutation();
    const workspace = useCurrentWorkspace();
    const history = useHistory();
    const { user } = useCurrentUser();
    const { trackClinicianMessageSendClick } =
        useClinicianMessageSendAnalytics();
    const analyticsAppOrigin = useAppOrigin();
    const maxAttachmentCount = useMaxAttachmentCount("ClinicianMessaging");

    const onMessageSend: OnClinicianMessageSendFn = ({
        errors,
        warnings,
        data,
    }) => {
        if (!patient.patientToken) {
            Log.error(
                "Trying to send a message to a clinician without a patient token",
                {
                    tags: {
                        product: "Compose",
                    },
                },
            );
            return;
        }

        // Always track the click
        trackClinicianMessageSendClick({
            isReply: false,
            origin: "/compose",
            conversationId: null,
            isTestPatient: isTestPatient(patientNhsNumber(patient.externalIds)),
            // The conversation doesn't exist yet, so there is only 1 participant.
            // In the future we may add the ability to add multiple participants
            // when sending the first message in the conversation. But for now,
            // this is why it is hard-coded to 1.
            countParticipant: 1,
            hasError: errors.length > 0,
            appOrigin: analyticsAppOrigin,
            ...data,
        });

        const isValid = errors.length === 0 && warnings.length === 0;

        // If it's valid, send the message
        if (isValid) {
            const sendMessageVariables = {
                workspaceId: workspace.orgId.toString(),
                patientToken: patient.patientToken,
                message: `${data.messageBody}${
                    data.messageSignature ? `\n${data.messageSignature}` : ""
                }`,
                attachmentIds: data.attachments.map((att) => att.id),
                isPresetTemplate: !!data.template.value,
                isPostPatientMessage: false,
                templateGroup: data.template.value?.heading ?? null,
                templateName: data.template.value?.title ?? null,
            };
            sendMessageMutation.mutate(sendMessageVariables, {
                onSuccess: (data) => {
                    history.push(
                        `/w/${workspace.orgId}/compose/conversation/${data.id}`,
                    );
                },
                onError: () => {
                    toast(
                        <UI.Feedback
                            title="Something went wrong while trying to send your message"
                            colour="error"
                        />,
                    );
                },
            });
        }
    };

    // When using message GP it is not technically possible for practiceCode not to
    // be there, but since in the DTO it is, we need to handle it
    const recipientName = patient.practiceName
        ? patient.practiceName
        : patient.practiceCode
        ? `Organisation with ODS code ${patient.practiceCode}`
        : "This patient's organisation";

    const greeting = generateClinicianGreeting(patient);
    const userSignature = generateSignature(user);
    const signature = `${userSignature}\n${workspace.organisationName}`;

    const isMessageSending = sendMessageMutation.isLoading;

    return (
        <ComposeProvider
            settings={{
                greeting,
                editableBody: "\n",
                editableSignature: signature,
                maxAttachmentCount,
                canUseQuestionnaires: false,
                conversationParticipant: "WithHcp",
                templatesComboboxMustOpenDownwards: true,
            }}
        >
            <ClinicianCompose
                recipient={{
                    name: recipientName,
                    practiceCode: patient.practiceCode ?? undefined,
                }}
                isMessageSending={isMessageSending}
                onMessageSend={onMessageSend}
            />
        </ComposeProvider>
    );
};
