import { MarketClassificationValue } from "@accurx/api/portal";
import { Ds } from "@accurx/design";
import { useWorkspaceClassifications } from "@accurx/workspace-management";
import { userflowIds } from "layouts/constants";

import { PopoverExternalLink } from "./PopoverExternalLink";

export const WhatIsNextLink = ({
    workspaceId,
    onClick,
}: {
    workspaceId: number;
    onClick: () => void;
}) => {
    const { data: { market } = {} } = useWorkspaceClassifications({
        workspaceId,
    });

    const isGeneralPractice =
        market === MarketClassificationValue.GP ||
        market === MarketClassificationValue.PCN;

    if (!isGeneralPractice) {
        return null;
    }

    return (
        <PopoverExternalLink
            href="https://accurx.notion.site/2025-gp-roadmap"
            onClick={onClick}
            data-userflow-id={userflowIds.navigation.primary.helpWhatIsNext}
        >
            <Ds.Icon name="Celebrate" size="small" color="stone" />
            <Ds.Text weight="bold">What's next</Ds.Text>
        </PopoverExternalLink>
    );
};
