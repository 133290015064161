import * as AppReducer from "app/AppReducer";
import * as Account from "app/account/AccountReducer";
import * as DeliveryReceipts from "app/account/DeliveryReceipts/DeliveryReceiptsReducer";
import * as BatchMessage from "app/batchMessage/gp/BatchMessage.reducer";
import * as FileUpload from "app/fileUpload/FileUploadReducer";
import * as MessagePractice from "app/messagePractice/MessagePracticeReducer";
import * as MessageTemplates from "app/messageTemplates/MessageTemplatesReducer";
import * as PatientLists from "app/patientLists/PatientListsReducer";
import * as Practice from "app/practices/Practices.reducer";
import * as AppointmentReminder from "app/practices/appointmentReminder/AppointmentReminder.reducer";
import * as Beast from "app/practices/beast/Beast.reducer";
import * as DelayedMessages from "app/practices/delayedMessages/DelayedMessages.reducer";
import * as DeliveryReceiptsChain from "app/practices/deliveryReceipts/DeliveryReceipts.reducer";
import * as ManageTemplates from "app/practices/manageTemplates/ManageTemplates.reducer";
import * as PatientTriage from "app/practices/patientTriage/PatientTriage.reducer";
import * as PracticeUsers from "app/practices/practiceUsers/PracticeUsers.reducer";
import * as TeamNotification from "app/practices/teamNotifications/TeamNotifications.reducer";
import * as VaccineApprovalRequests from "app/practices/vaccine/approvalRequests/ApprovalRequests.reducer";
import * as VaccineSiteSetup from "app/practices/vaccine/siteSetup/SiteSetup.reducer";
import * as VaccineAllPatientsInvited from "app/practices/vaccine/vaccineAllPatientsInvited/VaccineAllPatientsInvited.reducer";
import * as VaccineCancel from "app/practices/vaccine/vaccineCancel/VaccineCancel.reducer";
import * as VaccineDelivery from "app/practices/vaccine/vaccineInvitesOldPage/vaccineDelivery.reducer";
import * as VaccineRecording from "app/practices/vaccine/vaccineRecording/VaccineRecording.reducer";
import * as RecordView from "app/recordView/RecordViewReducer";
import * as SearchForPatient from "app/searchForPatient/SearchForPatientReducer";
import * as SelectProduct from "app/selectProduct/SelectProductReducer";
import * as VideoCompatibility from "app/selectProduct/VideoCompatibilityReducer";
import * as MessagePatient from "app/sendMessage/SendMessageReducer";
import * as TwoFactor from "app/twoFactorAuth/TwoFactorReducer";
import * as VideoLobby from "app/videoLobby/VideoLobbyReducer";

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    app: AppReducer.reducer,
    appointmentReminders: AppointmentReminder.reducer,
    batchMessage: BatchMessage.reducer,
    beast: Beast.reducer,
    selectProduct: SelectProduct.reducer,
    searchForPatient: SearchForPatient.reducer,
    messagePatient: MessagePatient.reducer,
    messagePractice: MessagePractice.reducer,
    recordView: RecordView.reducer,
    account: Account.reducer,
    delayedMessages: DelayedMessages.reducer,
    deliveryReceipts: DeliveryReceipts.reducer,
    deliveryReceiptsChain: DeliveryReceiptsChain.reducer,
    videoCompatibility: VideoCompatibility.reducer,
    videoConfirmation: VideoLobby.reducer,
    fileUpload: FileUpload.reducer,
    manageTemplates: ManageTemplates.reducer,
    messageTemplates: MessageTemplates.reducer,
    patientLists: PatientLists.reducer,
    patientTriage: PatientTriage.reducer,
    twoFactor: TwoFactor.reducer,
    practices: Practice.reducer,
    practiceUsers: PracticeUsers.reducer,
    teams: TeamNotification.reducer,
    vaccineAllPatientsInvited: VaccineAllPatientsInvited.reducer,
    vaccineApprovalRequests: VaccineApprovalRequests.reducer,
    vaccineCancel: VaccineCancel.reducer,
    vaccineDelivery: VaccineDelivery.reducer,
    vaccineRecording: VaccineRecording.reducer,
    vaccineSiteSetup: VaccineSiteSetup.reducer,
};

export const storeInitialState: ApplicationState = {
    app: AppReducer.initialState,
    appointmentReminders: AppointmentReminder.initialState,
    batchMessage: BatchMessage.initialState,
    beast: Beast.initialState,
    selectProduct: SelectProduct.initialState,
    searchForPatient: SearchForPatient.initialState,
    messagePatient: MessagePatient.initialState,
    messagePractice: MessagePractice.initialState,
    recordView: RecordView.initialState,
    account: Account.initialState,
    delayedMessages: DelayedMessages.initialState,
    deliveryReceipts: DeliveryReceipts.initialState,
    deliveryReceiptsChain: DeliveryReceiptsChain.initialState,
    videoCompatibility: VideoCompatibility.initialState,
    videoConfirmation: VideoLobby.initialState,
    fileUpload: FileUpload.initialState,
    manageTemplates: ManageTemplates.initialState,
    messageTemplates: MessageTemplates.initialState,
    patientLists: PatientLists.initialState,
    patientTriage: PatientTriage.initialState,
    twoFactor: TwoFactor.initialState,
    practices: Practice.initialState,
    practiceUsers: PracticeUsers.initialState,
    teams: TeamNotification.initialState,
    vaccineAllPatientsInvited: VaccineAllPatientsInvited.initialState,
    vaccineApprovalRequests: VaccineApprovalRequests.initialState,
    vaccineCancel: VaccineCancel.initialState,
    vaccineDelivery: VaccineDelivery.initialState,
    vaccineRecording: VaccineRecording.initialState,
    vaccineSiteSetup: VaccineSiteSetup.initialState,
};
