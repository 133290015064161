import { useAnalytics } from "@accurx/analytics";
import { useNativeTrackingFields } from "@accurx/native";
import { useAppointmentsConfigurationQuery } from "@accurx/self-book";
import { addDays, isAfter, parseISO } from "date-fns";
import { useCompose } from "domains/message-component/context";
import { userflowIds } from "domains/message-component/userflow";

import { BookingInviteButton } from "./BookingInviteButton";

type BookingInviteProps = {
    onClick: () => void;
};

export const BookingInvite = ({ onClick }: BookingInviteProps) => {
    const { state } = useCompose();
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();

    const { data, status } = useAppointmentsConfigurationQuery();

    const isCustomDateAndTime =
        state.sendAt?.type === "calendar" || state.sendAt?.type === "frame";

    const selectedSendDateAndTime =
        state.sendAt?.sendAtDateTime && parseISO(state.sendAt.sendAtDateTime);

    const maxDateForScheduling =
        status === "success" &&
        data.selfBookSchedulingWeeksIntoFuture &&
        addDays(new Date(), data.selfBookSchedulingWeeksIntoFuture * 7); // n*7 equivalent to n days
    const selectedTimeIsAfterAllowedSchedulingTimeFrame =
        selectedSendDateAndTime &&
        maxDateForScheduling &&
        isAfter(selectedSendDateAndTime, maxDateForScheduling);

    // Pre-conditions necessary for a user to set up self book link
    const canAddSelfBook =
        state.nhsAdviceLink === null &&
        state.template.type !== "QuestionnaireTemplate" &&
        state.template.type !== "PendingQuestionnaireTemplate" &&
        state.selfBookLink === null &&
        state.attachments.length === 0 &&
        state.isPatientResponseEnabled === false &&
        state.contactDetails.method !== "Email";

    const isBookingInviteDisabled =
        !canAddSelfBook ||
        (isCustomDateAndTime &&
            selectedTimeIsAfterAllowedSchedulingTimeFrame === true);

    return (
        <BookingInviteButton
            data-testid={`booking-invite-configuration-${status}`}
            onClick={() => {
                onClick();
                track("ConversationBookingAddStart Button Click", {
                    accessType: nativeTrackingFields.accessType,
                    medicalRecordSystem:
                        nativeTrackingFields.medicalRecordSystem,
                    appOrigin: "QuickReply",
                });
            }}
            userflowId={userflowIds.bookingLinkButton}
            disabled={isBookingInviteDisabled}
        />
    );
};
