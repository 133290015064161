import { Dispatch } from "redux";

import {
    TemplatesViewRequest,
    getTemplatesManagementView,
} from "api/PatientMessagingServer/PatientMessagingServerApi";

import { DisplayTemplate, Template } from "./ManageTemplates.types";

export enum Actions {
    GET_MESSAGE_TEMPLATES = "GET_MESSAGE_TEMPLATES",
    GET_MESSAGE_TEMPLATES_SUCCESS = "GET_MESSAGE_TEMPLATES_SUCCESS",
    GET_MESSAGE_TEMPLATES_FAILURE = "GET_MESSAGE_TEMPLATES_FAILURE",
    UPDATE_MESSAGE_TEMPLATE_SENDTYPE = "UPDATE_MESSAGE_TEMPLATE_SENDTYPE",
    UPDATE_MESSAGE_TEMPLATE_SENDTYPE_SUCCESS = "UPDATE_MESSAGE_TEMPLATE_SENDTYPE_SUCCESS",
    UPDATE_MESSAGE_TEMPLATE_SENDTYPE_FAILURE = "UPDATE_MESSAGE_TEMPLATE_SENDTYPE_FAILURE",
}

type DefaultActions = BaseActions<typeof Actions>;

export type ExtendedActions = DecoratedActions<
    DefaultActions,
    {
        [Actions.GET_MESSAGE_TEMPLATES_SUCCESS]: {
            messageTemplates: Template[];
        };
        [Actions.GET_MESSAGE_TEMPLATES_FAILURE]: { error: string };
        [Actions.UPDATE_MESSAGE_TEMPLATE_SENDTYPE_FAILURE]: {
            templateId: string;
            originalTemplate: DisplayTemplate | undefined;
            errorText: string;
        };
    }
>;

export type KnownAction = ExtendedActions[keyof ExtendedActions];

export const getMessageTemplates =
    ({ organisationId, includeVideo }: TemplatesViewRequest) =>
    async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: Actions.GET_MESSAGE_TEMPLATES });
        const response = await getTemplatesManagementView({
            organisationId,
            includeVideo,
        });
        if (response.success && response.result && response.result.templates) {
            const templatesWithIds = response.result.templates as Template[];
            return dispatch({
                type: Actions.GET_MESSAGE_TEMPLATES_SUCCESS,
                messageTemplates: templatesWithIds,
            });
        }

        return dispatch({
            type: Actions.GET_MESSAGE_TEMPLATES_FAILURE,
            error:
                response.error ||
                "There was a problem fetching custom templates. Please refresh to try again.",
        });
    };
