import { Ds, Tokens } from "@accurx/design";
import { PracticeUserAvailability } from "@accurx/navigation";
import { DateHelpers } from "@accurx/shared";
import ContentLoader from "react-content-loader";

import { FlemingAnalyticsTracker } from "app/analytics";
import { FlemingLoggedInCustomProperties } from "app/analytics/FlemingAnalytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

// TODO: This placeholder should be replaced with the shared component version when it is available and the associated module should be removed.
const BadgePlaceholder = () => (
    <ContentLoader
        speed={2}
        width={200}
        height={24}
        viewBox="0 0 200 24"
        backgroundColor={Tokens.COLOURS.greyscale.cloud}
        foregroundColor={Tokens.COLOURS.greyscale.white}
    >
        <rect x="0" y="0" rx="3" ry="3" width="200" height="24" />
    </ContentLoader>
);

export type TrackingProperties = {
    practiceUserAvailabilityDaysAgo?: string;
    practiceUserAvailabilityCount?: number;
    practiceUserAvailabilityError?: string;
};

export type PracticeUserAvailabilityBadgeProps =
    | {
          data: PracticeUserAvailability;
          status: "success";
      }
    | { status: "loading" | "error"; data?: PracticeUserAvailability };

const trackUserAvailability = (
    practiceUserAvailability: TrackingProperties,
    loggedInCustomProperties: FlemingLoggedInCustomProperties,
) => {
    FlemingAnalyticsTracker.trackMessagePracticeStaffAvailability({
        practiceStaffOnlineCount:
            practiceUserAvailability.practiceUserAvailabilityCount,
        practiceStaffOnlineDate:
            practiceUserAvailability.practiceUserAvailabilityDaysAgo,
        practiceStaffOnlineError:
            practiceUserAvailability.practiceUserAvailabilityError,
        ...loggedInCustomProperties,
    });
};

type GetBadgeProps = {
    text: string;
    colour: Ds.BadgeProps["color"];
};

export const PracticeOnlineStatus = ({
    status,
    data,
}: PracticeUserAvailabilityBadgeProps) => {
    const loggedInProps = useFlemingLoggedInAnalytics();
    if (status === "loading") {
        return (
            <div className="mt-1">
                <BadgePlaceholder />
            </div>
        );
    }

    function getBadgeProps(): GetBadgeProps | null {
        if (status === "error" || !data) {
            trackUserAvailability(
                {
                    practiceUserAvailabilityError: "ErrorOnRequest",
                },
                loggedInProps,
            );
            return {
                text: "Status unknown",
                colour: "greyscale",
            };
        }

        if (!data.isAvailable) {
            trackUserAvailability(
                {
                    practiceUserAvailabilityError: "FeatureFlagDisabled",
                },
                loggedInProps,
            );

            return {
                text: "We are unable to reach this workspace",
                colour: "red",
            };
        }

        if (!data.activity) {
            trackUserAvailability(
                {
                    practiceUserAvailabilityError: "NoStaffOnlineEver",
                },
                loggedInProps,
            );
            return null;
        }

        const { lastOnlineDaysAgo, numberOfUsers } = data.activity;
        const daysAgoText = DateHelpers.getDaysAgoText(lastOnlineDaysAgo);

        trackUserAvailability(
            {
                practiceUserAvailabilityCount: numberOfUsers,
                practiceUserAvailabilityDaysAgo: daysAgoText,
            },
            loggedInProps,
        );

        return {
            text: `${numberOfUsers} practice staff ${
                numberOfUsers === 1 ? "member " : ""
            }online ${daysAgoText}`,
            colour: "green",
        };
    }

    const badgeProps = getBadgeProps();

    return (
        <div className="mt-1">
            {badgeProps && (
                <Ds.Badge
                    color={badgeProps.colour}
                    data-testid="practice-activity"
                >
                    {badgeProps.text}
                </Ds.Badge>
            )}
        </div>
    );
};
