import {
    ChangeEvent,
    ClipboardEvent,
    FormEvent,
    forwardRef,
    useEffect,
    useState,
} from "react";

import { Ds, Input } from "@accurx/design";
import { useEmrPatientSearch } from "domains/navigation/hooks/mutations/useEmrPatientSearch";
import { PatientSearchResult } from "domains/navigation/types";

import {
    EmrSearchState,
    FIELDS_ID,
    FORM_ACTION,
    FORM_ID,
    FORM_LABELS,
    FormAction,
    INIT_STATE,
    TEST_ID,
    sanitise,
} from "../PatientSearchForm.helpers";
import { StyledFormFieldWrapper } from "../PatientSearchForm.styles";
import {
    EmrSearchError,
    EmrSearchSchema,
    formatErrors,
} from "../PatientSearchFormSchema";

export type EmrSearchProps = {
    formData: EmrSearchState;
    formAction: React.Dispatch<FormAction<EmrSearchState>>;
    onSubmit: (data: PatientSearchResult[]) => void;
    onError: (error: Error) => void;
    onValidationError: (errorReason: string[]) => void;
    onClear?: () => void;
    isFormDisabled?: boolean;
    onLoading?: (isLoading: boolean) => void;
};

export const EmrSearch = forwardRef<HTMLFormElement, EmrSearchProps>(
    (
        {
            formData,
            formAction,
            onSubmit,
            onError,
            onValidationError,
            onClear,
            onLoading,
            isFormDisabled,
        },
        forwardedRef,
    ) => {
        const { mutate, isLoading } = useEmrPatientSearch({
            onError: (error) => onError(error),
            onSuccess: (data) => onSubmit(data),
        });
        const [fieldErrors, setFieldErrors] = useState<EmrSearchError | null>(
            null,
        );

        useEffect(() => {
            onLoading?.(isLoading);
        }, [isLoading, onLoading]);

        const handleFirstNameChange = (
            e: ChangeEvent<HTMLInputElement>,
        ): void => {
            formAction({
                type: FORM_ACTION.UPDATE,
                payload: { firstName: e.target.value },
            });
            checkFormIsCleared({
                ...formData,
                firstName: e.target.value,
            });
        };

        const handleLastNameChange = (
            e: ChangeEvent<HTMLInputElement>,
        ): void => {
            formAction({
                type: FORM_ACTION.UPDATE,
                payload: { lastName: e.target.value },
            });
            checkFormIsCleared({
                ...formData,
                lastName: e.target.value,
            });
        };

        const handleNhsNumberChange = (
            e: ChangeEvent<HTMLInputElement>,
        ): void => {
            const nhsNumber = sanitise(e.target.value);
            formAction({ type: FORM_ACTION.UPDATE, payload: { nhsNumber } });
            checkFormIsCleared({
                ...formData,
                nhsNumber: nhsNumber,
            });
        };

        // Dedicated paste handler for NHS numbers formatted with spaces
        const handleNhsNumberPaste = (
            e: ClipboardEvent<HTMLInputElement>,
        ): void => {
            e.preventDefault();
            const nhsNumber = sanitise(e.clipboardData.getData("Text"));
            formAction({ type: FORM_ACTION.UPDATE, payload: { nhsNumber } });
        };

        const checkFormIsCleared = (formData: EmrSearchState) => {
            if (JSON.stringify(formData) === JSON.stringify(INIT_STATE.EMR)) {
                onClear?.();
            }
        };

        const handleSubmit = (e: FormEvent) => {
            e.preventDefault();

            const result = EmrSearchSchema.safeParse(formData);
            if (result.success) {
                setFieldErrors(null);
                mutate(formData);
            } else {
                const submitErrors = formatErrors<EmrSearchError>(result.error);
                setFieldErrors(submitErrors);
                if (submitErrors.nhsNumber) {
                    onValidationError([submitErrors.nhsNumber]);
                }
            }
        };

        return (
            <form
                ref={forwardedRef}
                onSubmit={handleSubmit}
                id={FORM_ID.EMR}
                data-testid={TEST_ID.EMR}
                noValidate
            >
                <Ds.Flex gap="1.5" flexDirection="column">
                    <Ds.Flex gap="1">
                        <StyledFormFieldWrapper
                            label={FORM_LABELS.firstName}
                            labelProps={{
                                htmlFor: `${FORM_ID.EMR}-input-${FIELDS_ID.FIRST_NAME}`,
                            }}
                            errors={[fieldErrors?.firstName ?? null]}
                        >
                            <Input
                                autoFocus
                                id={`${FORM_ID.EMR}-input-${FIELDS_ID.FIRST_NAME}`}
                                value={formData.firstName}
                                type="text"
                                onChange={handleFirstNameChange}
                                disabled={isFormDisabled}
                                data-userflow-id="patient-search-first-name-input"
                                hasErrors={Boolean(fieldErrors?.firstName)}
                            />
                        </StyledFormFieldWrapper>
                        <StyledFormFieldWrapper
                            label={FORM_LABELS.lastName}
                            labelProps={{
                                htmlFor: `${FORM_ID.EMR}-input-${FIELDS_ID.LAST_NAME}`,
                            }}
                            errors={[fieldErrors?.lastName ?? null]}
                        >
                            <Input
                                id={`${FORM_ID.EMR}-input-${FIELDS_ID.LAST_NAME}`}
                                value={formData.lastName}
                                type="text"
                                onChange={handleLastNameChange}
                                disabled={isFormDisabled}
                                data-userflow-id="patient-search-last-name-input"
                                hasErrors={Boolean(fieldErrors?.lastName)}
                            />
                        </StyledFormFieldWrapper>
                    </Ds.Flex>
                    <Ds.Flex
                        gap="1"
                        alignItems={
                            fieldErrors?.nhsNumber ? "center" : "flex-end"
                        }
                    >
                        <StyledFormFieldWrapper
                            label={FORM_LABELS.nhsNumber}
                            labelProps={{
                                htmlFor: `${FORM_ID.EMIS}-input-${FIELDS_ID.NHS_NUMBER}`,
                            }}
                            errors={[fieldErrors?.nhsNumber ?? null]}
                        >
                            <Input
                                id={`${FORM_ID.EMIS}-input-${FIELDS_ID.NHS_NUMBER}`}
                                value={formData.nhsNumber}
                                type="text"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                minLength={10}
                                maxLength={10}
                                onChange={handleNhsNumberChange}
                                onPaste={handleNhsNumberPaste}
                                disabled={isFormDisabled}
                                data-userflow-id="patient-search-nhs-number-input"
                                hasErrors={Boolean(fieldErrors?.nhsNumber)}
                            />
                        </StyledFormFieldWrapper>
                        <Ds.Button type="submit" disabled={isFormDisabled}>
                            <Ds.Button.Icon
                                name="Search"
                                aria-label="Search for a patient"
                            />
                        </Ds.Button>
                    </Ds.Flex>
                </Ds.Flex>
            </form>
        );
    },
);
