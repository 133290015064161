import { ComponentPropsWithoutRef, ReactNode } from "react";

import { Ds, Item, Tokens } from "@accurx/design";
import { Slot } from "@radix-ui/react-slot";
import {
    applyFocusStyle,
    applyHoverStyle,
} from "domains/navigation/components/Sidenav/Sidenav.styles";
import styled, { css } from "styled-components";

const StyledLink = styled.a<{
    $size: "large" | "small";
    $isActive: boolean;
    $color: string;
}>`
    flex: 1;
    text-decoration: none !important;
    color: ${(props) => props.$color} !important;

    border-radius: 8px;
    display: flex;
    gap: ${Tokens.SIZES[0.5]};
    padding: 0 ${Tokens.SIZES[1]} 0 ${Tokens.SIZES[0.5]};
    align-items: center;
    border: 1px solid transparent;
    min-height: 36px; // Use min-height so it works inside a flex container AND can have long multi-line text content

    font-size: ${(props) => (props.$size === "large" ? "1rem" : "0.875rem")};

    // Higher specificity to override GlobalStyle
    &:focus&:focus {
        ${applyFocusStyle}
    }

    &:hover {
        ${applyHoverStyle}
    }

    ${({ $isActive }) =>
        $isActive &&
        css`
            border: 1px solid ${Tokens.COLOURS.primary.green[50]} !important;
            background: ${Tokens.COLOURS.primary.green[100]} !important;
        `}
`;

const Text = styled(Item).attrs({
    flex: "1",
})`
    align-items: center;
    display: flex;
    gap: ${Tokens.SIZES[0.5]};
    padding: 0 ${Tokens.SIZES[0.5]};
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Icon = ({
    name,
    appearance,
    rotate,
}: Pick<Ds.IconProps, "name" | "appearance" | "rotate">) => (
    <Item>
        <Ds.Icon
            name={name}
            appearance={appearance}
            rotate={rotate}
            size="small"
            color="white"
        />
    </Item>
);

type NavLinkProps = {
    asChild?: boolean;
    size?: "large" | "small";
    isActive?: boolean;
    color?: string;
    children: ReactNode;
} & ComponentPropsWithoutRef<"a">;

const NavLinkRoot = ({
    asChild = false,
    size = "large",
    isActive = false,
    color = Tokens.COLOURS.greyscale.white,
    children,
    ...props
}: NavLinkProps) => {
    return (
        <StyledLink
            as={asChild ? Slot : "a"}
            $size={size}
            $isActive={Boolean(isActive)}
            $color={color}
            aria-current={isActive ? "page" : false}
            {...props}
        >
            {children}
        </StyledLink>
    );
};

export const NavLink = Object.assign(NavLinkRoot, { Text, Icon });
