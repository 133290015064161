import React from "react";

import { Feedback, FeedbackColourVariants, Text } from "@accurx/design";
import { ArchivedWorkspaceHiddenItemWrapper } from "@accurx/workspace-management";

type PatientNameProp = { patientName: string | null };

const NORMAL_REOPEN_TEXT = "Re-open the conversation to send a message.";
const PATIENT_MATCHING_REOPEN_TEXT =
    "Re-open the conversation to search for and/or match the patient.";

const FeedbackItem = ({
    children,
    colour,
}: {
    children: React.ReactNode;
    colour: FeedbackColourVariants;
}) => {
    return (
        <Feedback
            title="All conversations are securely stored"
            iconName="LockLocked"
            colour={colour}
        >
            <Text colour="night" skinny>
                {children}
            </Text>
        </Feedback>
    );
};

const PatientNameLabel = ({ patientName }: PatientNameProp) => {
    return (
        <>
            <Text variant="label" as="span">
                {patientName || "this patient"}
            </Text>
        </>
    );
};

export const EndOfConversationItem = ({
    patientName,
    hasNotMatchedPatient,
}: PatientNameProp & {
    hasNotMatchedPatient: boolean;
}) => {
    return (
        <FeedbackItem colour="success">
            This is the end of this conversation about{" "}
            <PatientNameLabel patientName={patientName} />.
            <br />
            <ArchivedWorkspaceHiddenItemWrapper>
                {hasNotMatchedPatient
                    ? PATIENT_MATCHING_REOPEN_TEXT
                    : NORMAL_REOPEN_TEXT}
            </ArchivedWorkspaceHiddenItemWrapper>
        </FeedbackItem>
    );
};

export const NewConversationItem = ({ patientName }: PatientNameProp) => {
    return (
        //  We want the colour to be "information", but at the moment there's an issue with showing 'Fill' icons on an information feedback message
        // (it always uses 'Line' and the 'Lock' is a fill icon). Once we've fixed it, using 'information' as the colour will be an easy change to make
        <FeedbackItem colour="success">
            This is the start of a new conversation with{" "}
            <PatientNameLabel patientName={patientName} />.
        </FeedbackItem>
    );
};
