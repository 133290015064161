import { Flex, Text } from "@accurx/design";

import { TwoFactorContext } from "../TwoFactor";
import { StyledFooterLink } from "../TwoFactor.styles";
import {
    TwoFactorContextProps,
    TwoFactorMethod,
    TwoFactorRequestStepProps,
    TwoFactorStepProps,
} from "../TwoFactorModels";
import { NeedAssistance } from "../shared/NeedAssistance";
import { SendCodeCard } from "../shared/SendCodeCard";
import { StyledText } from "./TwoFactorSendCode.styles";
import { getMainContentSuffix } from "./TwoFactorStepsHelper";

export type TwoFactorSendCodeProps = TwoFactorRequestStepProps &
    TwoFactorStepProps &
    TwoFactorContextProps;

export const TwoFactorSendCode = ({
    prevStep,
    nextStep,
    twoFactorMethod: { chosenMethod, obfuscatedMethod },
    error,
    requestLoading,
    context,
}: TwoFactorSendCodeProps): JSX.Element => {
    const handleSendSms = (): void => {
        nextStep && nextStep();
    };

    const useMobile = chosenMethod === TwoFactorMethod.Mobile;
    const mobileOrEmailText = useMobile ? "mobile phone" : "email";

    const MainText = (
        <>
            <Text variant="body">
                Send a verification code to your {mobileOrEmailText} and use it
                to gain secure access to {getMainContentSuffix(context)};{" "}
                {`${
                    context === TwoFactorContext.Workspaces
                        ? "you will be able to access workspaces after verifying your identity."
                        : "you won't be able to use all features without completing this step."
                }`}
            </Text>
            <StyledText variant="label">
                {useMobile ? "Phone number" : "Email address"}:{" "}
                {obfuscatedMethod}
            </StyledText>
        </>
    );

    return (
        <>
            <Flex flexDirection="column" alignItems="center" gap="2">
                <SendCodeCard
                    onSubmit={handleSendSms}
                    isLoading={requestLoading}
                    error={error}
                    text={{
                        mainText: MainText,
                        submitButtonText: useMobile ? "Send SMS" : "Send email",
                    }}
                />
            </Flex>
            <StyledFooterLink>
                <NeedAssistance />
            </StyledFooterLink>
        </>
    );
};
