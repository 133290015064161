import React, { FormEventHandler } from "react";

import {
    Button,
    Flex,
    FormFieldWrapper,
    Input,
    Item,
    Text,
} from "@accurx/design";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { ManageTemplatesFormHook } from "../../ManageTemplatesFormPage.types";
import { StyledCloseButton } from "./CategoryModal.styles";

export type CategoryModalProps = {
    handleCloseModal: () => void;
    handleAddCategory: FormEventHandler;
    isModalOpen: boolean;
    formValues: ManageTemplatesFormHook["addCategoryFormValues"];
    onChange: ManageTemplatesFormHook["addCategoryFormOnChange"];
    errors: ManageTemplatesFormHook["addCategoryFormErrors"];
};

export const CategoryModal = ({
    handleCloseModal,
    handleAddCategory,
    isModalOpen,
    formValues,
    onChange,
    errors,
}: CategoryModalProps): JSX.Element => {
    return (
        <Modal isOpen={isModalOpen}>
            <form onSubmit={handleAddCategory}>
                <StyledCloseButton
                    icon={{
                        name: "Cross",
                        title: "Close",
                        id: "close-add-category",
                    }}
                    aria-label="Close"
                    theme="transparent"
                    onClick={handleCloseModal}
                    type="button"
                />
                <ModalHeader tag="div">
                    <Text variant="subtitle" skinny as="h2">
                        Add category
                    </Text>
                </ModalHeader>
                <ModalBody>
                    <FormFieldWrapper
                        label="New category name"
                        subLabel={`This will organise templates in the dropdown. Create subfolders by separating category names with a forward slash "/"`}
                        labelProps={{ htmlFor: "new-category-input" }}
                        errors={errors?.newCategoryName}
                    >
                        <Input
                            id="new-category-input"
                            placeholder="Write new category"
                            value={formValues.newCategoryName}
                            onChange={onChange.newCategoryName}
                        />
                    </FormFieldWrapper>
                </ModalBody>
                <ModalFooter>
                    <Flex justifyContent="flex-end" gap="1">
                        <Item>
                            <Button
                                text="Cancel"
                                theme="secondary"
                                onClick={handleCloseModal}
                                type="button"
                            />
                        </Item>
                        <Item>
                            <Button
                                text="Save"
                                theme="primary"
                                type="submit"
                                aria-label="Save category"
                            />
                        </Item>
                    </Flex>
                </ModalFooter>
            </form>
        </Modal>
    );
};
