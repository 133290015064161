import { ChangeEvent, TextareaHTMLAttributes, forwardRef } from "react";

import { useCompose } from "../../../../context";
import { ComposeActionsTypes } from "../../../../reducer.types";
import { StyledInnerTextarea } from "../InnerTextarea.styles";

type MessageBodyProps = Pick<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    "onKeyDown" | "onChange" | "onKeyUp"
>;

export const MessageBody = forwardRef<HTMLTextAreaElement, MessageBodyProps>(
    function Body({ onChange, ...props }, ref) {
        const { state, dispatch } = useCompose();

        const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
            dispatch({
                type: ComposeActionsTypes.UpdateMessageBody,
                payload: {
                    text: e.currentTarget.value,
                },
            });
            onChange?.(e);
        };

        return (
            <StyledInnerTextarea
                ref={ref}
                minRows={1}
                aria-label="Message body"
                name="compose-message"
                onChange={handleChange}
                value={state.messageBody}
                {...props}
            />
        );
    },
);
