import { TemplatePath } from "./ManageTemplates.types";

export const getCategoryHeading = (path: TemplatePath): string => {
    return path.folders
        .map((folder) => (folder.name ? folder.name.trim() : ""))
        .join(" > ");
};

/* Display category heading when
 * current row's categoryHeading is non empty
 * and it's the first row or this row's categoryHeading differs from previous row's categoryHeading
 * */
export const shouldDisplayCategoryHeader = ({
    currentRowCategoryHeading,
    previousRowCategoryHeading = "",
    currentIndex,
}: {
    currentRowCategoryHeading: string;
    previousRowCategoryHeading: string;
    currentIndex: number;
}): boolean =>
    currentRowCategoryHeading !== "" &&
    (currentIndex === 0 ||
        (currentIndex > 0 &&
            currentRowCategoryHeading !== previousRowCategoryHeading));

export const FEEDBACK_SEARCH_TEXT =
    "Tip: Use the keyboard shortcut CTRL-F to search for a specific template";
export const NO_TEMPLATES_TEXT = "No templates created";
export const FEEDBACK_UNAPPROVED_USER_TITLE_TEXT =
    "You are currently an unapproved user.";
export const FEEDBACK_UNAPPROVED_USER_BODY_TEXT =
    "This means you will not have access to certain Accurx features, such as copying personal templates to your organisation, updating availability of templates, or creating an organisation template. Please ask your Admin to approve you.";
