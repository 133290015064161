import { ChangeEvent, useEffect, useMemo, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import * as UI from "@accurx/design";
import { Feedback, Pill } from "@accurx/inbox-design-library";
import { useNativeTrackingFields } from "@accurx/native";
import { QuickViewPortal } from "@accurx/quick-view";
import { useAppointmentsConfigurationQuery } from "@accurx/self-book";
import { addDays, isAfter } from "date-fns";
import { useCompose } from "domains/message-component/context";
import { useDayDetails } from "domains/message-component/hooks/useDayDetails";
import { ComposeActionsTypes } from "domains/message-component/reducer.types";

import { TimeFrameType } from "../../constants/constants";
import { CustomDateType, ScheduleSendAt } from "../../types/ScheduleSend.types";
import { AfterTimeFrame } from "./AfterTimeFrame";
import {
    StyledCustomDateBody,
    StyledCustomDateContainer,
    StyledCustomDateFooter,
    StyledOptionLabel,
    StyledSubtitle,
} from "./ScheduleSendCustomDate.styles";
import { SpecificDate } from "./SpecificDate";
import { getCalendarOrFrameFromScheduleSendAt } from "./getCalendarOrFrameFromScheduleSendAt";

type ScheduleSendCustomDateProps = {
    onConfirm: (payload: ScheduleSendAt) => void;
    sendAt?: ScheduleSendAt;
};

// equivalent to one year
const DEFAULT_SCHEDULING_WINDOW_IN_DAYS = 365;

export const ScheduleSendCustomDate = ({
    onConfirm,
    sendAt,
}: ScheduleSendCustomDateProps) => {
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();

    const { state, dispatch } = useCompose();
    const { data: appointmentConfiguration } =
        useAppointmentsConfigurationQuery({
            enabled: !!state.selfBookLink,
        });

    useEffect(() => {
        if (!state.isScheduling) {
            dispatch({
                type: ComposeActionsTypes.SetIsScheduling,
                payload: { isScheduling: true },
            });
        }
        return () => {
            if (state.isScheduling) {
                dispatch({
                    type: ComposeActionsTypes.SetIsScheduling,
                    payload: { isScheduling: false },
                });
            }
        };
    }, [state, dispatch]);

    const [overDaysInAdvanceError, setOverDaysInAdvanceError] = useState<
        string | undefined
    >(undefined);

    const [showEmptyError, setShowEmptyError] = useState(false);

    const initialValue = getCalendarOrFrameFromScheduleSendAt(sendAt ?? null);

    const [selectedOption, setSelectedOption] = useState<CustomDateType>(
        initialValue?.type ?? "frame",
    );
    const [selectedValue, setSelectedValue] =
        useState<ScheduleSendAt>(initialValue);

    const dayDetails = useDayDetails({
        selectedDate: selectedValue?.sendAtDateTime || null,
    });

    const availableScheduleTimeFrameText =
        state.selfBookLink &&
        appointmentConfiguration?.selfBookSchedulingWeeksIntoFuture
            ? `${appointmentConfiguration.selfBookSchedulingWeeksIntoFuture} weeks`
            : "1 year";

    /*
        When a self book link has been added,
        we allow to set a scheduling window of n weeks in advance, which is set per org.
        Otherwise, the default scheduling window is 1 year in advance.
    */
    const schedulingWindowInDays =
        state.selfBookLink &&
        appointmentConfiguration?.selfBookSchedulingWeeksIntoFuture
            ? appointmentConfiguration.selfBookSchedulingWeeksIntoFuture * 7 // n*7 equivalent to n days
            : DEFAULT_SCHEDULING_WINDOW_IN_DAYS;

    const onConfirmButtonClick = () => {
        const scheduledTimestampUtc = selectedValue?.sendAtDateTime
            ? new Date(selectedValue.sendAtDateTime).toUTCString()
            : undefined;

        track("ConversationScheduleConfirm Button Click", {
            ...nativeTrackingFields,
            indexMessage: 0,
            templateName: state.template.value?.title,
            scheduleType: selectedValue ? "Custom" : "Now",
            scheduledTimestampUtc,
        });

        if (dateOverDaysInAdvanceError) {
            setOverDaysInAdvanceError(
                `You can only schedule a message ${availableScheduleTimeFrameText} in advance`,
            );
            return;
        }

        if (!selectedValue) {
            setShowEmptyError(true);
            return;
        }

        onConfirm(selectedValue);
    };

    const onSelectOption = (e: ChangeEvent<HTMLInputElement>) => {
        setOverDaysInAdvanceError(undefined);
        setSelectedOption(e.target.value as CustomDateType);
    };

    const getTimeFrameValueLengthDay = (timeFrame: {
        type: "weeks" | "days" | "months";
        value: number;
    }) => {
        switch (timeFrame.type) {
            case "months":
                return timeFrame.value * 30; //changing month from 1 to 2 takes user from 30 to 60 days +-1 day
            case "weeks":
                return timeFrame.value * 7;
            default:
                return timeFrame.value; //default return number of days as received
        }
    };

    const dateOverDaysInAdvanceError = useMemo(() => {
        setOverDaysInAdvanceError(undefined);

        if (!selectedValue?.sendAtDateTime) return;

        const daysInAdvanceErrorText = `You can only schedule ${availableScheduleTimeFrameText} in advance`;
        const daysFromToday = addDays(new Date(), schedulingWindowInDays);

        const daysOverInAdvance: boolean = isAfter(
            new Date(selectedValue.sendAtDateTime),
            daysFromToday,
        );

        // track event only when user selects "After time frame" option in scheduling
        if (daysOverInAdvance && selectedValue.type === "frame") {
            track("ConversationScheduleError Component Load", {
                scheduleLengthDay: schedulingWindowInDays,
                scheduleType: "Custom",
                timeFrameType: TimeFrameType[selectedValue.timeFrame.type],
                timeFrameValueLengthDay: getTimeFrameValueLengthDay(
                    selectedValue.timeFrame,
                ),
            });
        }

        return daysOverInAdvance ? daysInAdvanceErrorText : undefined;
    }, [
        selectedValue,
        availableScheduleTimeFrameText,
        schedulingWindowInDays,
        track,
    ]);

    const isUserBasedAbroad = () =>
        Intl.DateTimeFormat().resolvedOptions().timeZone !== "Europe/London";

    return (
        <>
            <QuickViewPortal.Header text="Schedule message" />
            <StyledCustomDateContainer>
                <StyledCustomDateBody>
                    <div>
                        <StyledSubtitle>
                            When would you like to send this message?
                        </StyledSubtitle>
                    </div>
                    <UI.Flex gap="1" flexDirection="column">
                        <UI.Radio
                            value="frame"
                            id="frame"
                            label={
                                <StyledOptionLabel>
                                    After a time frame
                                </StyledOptionLabel>
                            }
                            onChange={onSelectOption}
                            name="frame"
                            checkedValue={selectedOption}
                        />
                        {selectedOption === "frame" && (
                            <>
                                <AfterTimeFrame
                                    initialValue={
                                        sendAt?.type === "frame"
                                            ? {
                                                  timeFrame: {
                                                      ...sendAt.timeFrame,
                                                      value: sendAt.timeFrame.value.toString(),
                                                  },
                                                  sendAtDateTime:
                                                      sendAt.sendAtDateTime,
                                              }
                                            : undefined
                                    }
                                    dateOverDaysInAdvanceError={
                                        dateOverDaysInAdvanceError
                                    }
                                    onChangeValue={setSelectedValue}
                                    setShowEmptyError={setShowEmptyError}
                                    showEmptyError={showEmptyError}
                                />
                                {!!overDaysInAdvanceError && (
                                    <Feedback colour="error" iconName="Failed">
                                        <UI.Text variant="label" skinny>
                                            {overDaysInAdvanceError}
                                        </UI.Text>
                                    </Feedback>
                                )}

                                <UI.VisuallyHidden aria-live="assertive">
                                    {!!overDaysInAdvanceError && (
                                        <>{overDaysInAdvanceError}</>
                                    )}
                                </UI.VisuallyHidden>
                            </>
                        )}
                    </UI.Flex>
                    <UI.Flex gap="1" flexDirection="column">
                        <UI.Radio
                            value="calendar"
                            id="calendar"
                            label={
                                <StyledOptionLabel>
                                    On a specific date
                                </StyledOptionLabel>
                            }
                            onChange={onSelectOption}
                            name="calendar"
                            checkedValue={selectedOption}
                        />
                        {selectedOption === "calendar" && (
                            <>
                                <SpecificDate
                                    initialValue={initialValue?.sendAtDateTime}
                                    onChangeValue={setSelectedValue}
                                    dateOverDaysInAdvanceError={
                                        dateOverDaysInAdvanceError
                                    }
                                    daysInAdvance={schedulingWindowInDays}
                                />
                                {!!overDaysInAdvanceError && (
                                    <Feedback colour="error" iconName="Failed">
                                        <UI.Text variant="label" skinny>
                                            {overDaysInAdvanceError}
                                        </UI.Text>
                                    </Feedback>
                                )}

                                <UI.VisuallyHidden aria-live="assertive">
                                    {!!overDaysInAdvanceError && (
                                        <>{overDaysInAdvanceError}</>
                                    )}
                                </UI.VisuallyHidden>
                            </>
                        )}
                    </UI.Flex>
                </StyledCustomDateBody>

                {dayDetails && !overDaysInAdvanceError && (
                    <Feedback
                        colour={
                            dayDetails.type === "workingDay"
                                ? "information"
                                : "warning"
                        }
                        iconName={
                            dayDetails.type === "workingDay"
                                ? "Clock"
                                : "Warning"
                        }
                    >
                        {dayDetails.type === "bankHoliday" && (
                            <UI.Text skinny colour="night">
                                <b>This date is a bank holiday</b>
                            </UI.Text>
                        )}
                        {dayDetails.type === "weekend" && (
                            <UI.Text skinny colour="night">
                                <b>This date is a weekend</b>
                            </UI.Text>
                        )}
                        <UI.Text skinny>
                            This message will send on{" "}
                            <b>
                                {dayDetails.date}
                                {isUserBasedAbroad() && " (UK Standard Time)"}
                            </b>
                        </UI.Text>
                    </Feedback>
                )}

                <StyledCustomDateFooter>
                    <Pill.PrimaryButton
                        onClick={onConfirmButtonClick}
                        dimension="small"
                    >
                        <Pill.Text>Confirm date and time</Pill.Text>
                    </Pill.PrimaryButton>
                </StyledCustomDateFooter>
            </StyledCustomDateContainer>
        </>
    );
};
