import { FeatureName, useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import { Redirect, Route, Switch, generatePath } from "react-router";

import {
    AppointmentChangesPage,
    CreateReminderPage,
    EditReminderPage,
    ManageRemindersPage,
    OverviewPage,
    ReviewAppointmentsPage,
    SentRemindersPage,
    UploadAppointmentsPage,
    UploadedAppointmentsPage,
} from "./pages";

// Appointment Reminders flow base bath and routes
const basePathForAppointmentReminders =
    "/w/:workspaceId/settings/appointment-reminders";

const createPagePath = `${basePathForAppointmentReminders}/create`;
const editPagePath = `${basePathForAppointmentReminders}/manage/:reminderId`;
const managePagePath = `${basePathForAppointmentReminders}/manage`;
const sentPagePath = `${basePathForAppointmentReminders}/sent`;
const changesPagePath = `${basePathForAppointmentReminders}/changes`;

// Appointemnt upload flow base path and routes
const basePathForUploadAppointments = "/w/:workspaceId/settings/appointments";

const uploadedAppointmentsPagePath = `${basePathForUploadAppointments}/uploadappointments`;
const uploadAppointmentsPagePath = `${basePathForUploadAppointments}/uploadappointments/upload`;
const reviewAppointmentsPagePath = `${basePathForUploadAppointments}/uploadappointments/:uploadId/review`;

export function AppointmentRemindersRoutes() {
    const workspace = useCurrentWorkspace();
    const { orgId: workspaceId } = workspace;
    const isUnifiedNavigationEnabled = useFeatureFlag(
        FeatureName.UnifiedNavigation,
    );

    return (
        <Switch>
            <Route path={createPagePath} exact>
                <CreateReminderPage />
            </Route>
            <Route path={editPagePath} exact>
                <EditReminderPage />
            </Route>
            {isUnifiedNavigationEnabled ? (
                <>
                    <Route path={basePathForAppointmentReminders} exact>
                        <Redirect
                            to={generatePath(managePagePath, { workspaceId })}
                        />
                    </Route>
                    <Route path={managePagePath} exact>
                        <ManageRemindersPage />
                    </Route>
                    <Route path={sentPagePath} exact>
                        <SentRemindersPage />
                    </Route>
                    <Route path={changesPagePath} exact>
                        <AppointmentChangesPage />
                    </Route>
                    <Route path={uploadedAppointmentsPagePath} exact>
                        <UploadedAppointmentsPage />
                    </Route>
                    <Route path={uploadAppointmentsPagePath} exact>
                        <UploadAppointmentsPage />
                    </Route>
                    <Route path={reviewAppointmentsPagePath} exact>
                        <ReviewAppointmentsPage />
                    </Route>
                </>
            ) : (
                <Route path={basePathForAppointmentReminders} exact>
                    <OverviewPage />
                </Route>
            )}
            <Route
                exact
                path={`${basePathForAppointmentReminders}/:reminderId`}
                render={({ match }) => (
                    <Redirect
                        to={generatePath(editPagePath, {
                            workspaceId,
                            reminderId: match.params.reminderId,
                        })}
                    />
                )}
            />
        </Switch>
    );
}

export const getOverviewPagePath = (workspaceId: number | string) => {
    return generatePath(basePathForAppointmentReminders, { workspaceId });
};

export const getCreatePagePath = (workspaceId: number | string) => {
    return generatePath(createPagePath, { workspaceId });
};

export const getEditPagePath = (
    workspaceId: number | string,
    reminderId: number,
) => {
    return generatePath(editPagePath, { workspaceId, reminderId });
};

export const getUploadPagePath = (workspaceId: number | string) => {
    return generatePath(uploadAppointmentsPagePath, { workspaceId });
};

export const getUploadedPagePath = (workspaceId: number | string) => {
    return generatePath(uploadedAppointmentsPagePath, { workspaceId });
};

export const getAppointmentReviewPagePath = (
    workspaceId: number | string,
    uploadId: string,
) => {
    return generatePath(reviewAppointmentsPagePath, { workspaceId, uploadId });
};
