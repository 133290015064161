import sortBy from "lodash/sortBy";

import {
    AtLeastOneAttachmentError,
    AttachmentError,
} from "../components/Compose/components/Attach/upload.types";

// Lowest ranking is most significant
const RANKINGS: { [k in AttachmentError["type"]]: number } = {
    badExtension: 0,
    tooBig: 1,
    emptyFile: 2,
    badFileName: 3,
    generalError: 4,
    uploadFailed: 5,
} as const;

export const getMostSignificantAttachmentUploadErrorMessage = (
    errors: AtLeastOneAttachmentError,
): string => {
    if (errors.length === 1) {
        return errors[0].message;
    }

    return sortBy(errors, ({ type }) => RANKINGS[type])[0].message;
};
