import React, { MouseEvent, useCallback, useState } from "react";

import { PatientListType } from "@accurx/api/patient-messaging";
import { Organisation } from "@accurx/auth";
import { Button, Card, Feedback, Icon, Text } from "@accurx/design";
import { DateHelpers } from "@accurx/shared";
import { isArchivedWorkspace } from "@accurx/workspace-management";
import { shallowEqual, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import FlemingApi from "api/FlemingApiClient";
import {
    PatientListAppointment,
    PatientListAppointmentDay,
} from "api/FlemingDtos";
import { AnalyticsMapper, FlemingAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import ButtonDropdown from "app/sharedComponents/dropdown/ButtonDropdown.component";
import DropdownMenuOption from "app/sharedComponents/dropdown/DropdownMenuOption.component";
import { ReactComponent as PatientListEmptyIllustration } from "shared/images/CardIllustration.svg";
import { useAppSelector } from "store/hooks";

import { OrganisationHelper } from "../../shared/OrganisationHelper";
import { AddPatientsToList } from "./AddPatientsToList";
import { getPatientListIsBulkAdding } from "./BulkUpload/PatientListBulkUpload.helper";
import { PatientListBulkUploadDuplicates } from "./BulkUpload/PatientListBulkUploadDuplicates";
import PatientListBulkAddPatientErrorsCard from "./BulkUpload/PatientListBulkUploadPatientErrorsCardComponent";
import { ClinicDayFilter } from "./ClinicDayFilter";
import { ConfirmDeleteAppointments } from "./ConfirmDelete/ConfirmDeleteAppointmentsModal";
import CreateOrEditPatientListModalComponent from "./CreateOrEditPatientListModalComponent";
import { PatientListAppointments } from "./PatientListAppointments";
import {
    StyledButtonWrapper,
    StyledColumns,
    StyledEmptyListDiv,
    StyledRow,
    StyledSplitRow,
} from "./PatientListPage.styles";
import ListSharingModal from "./PatientListSharingModalComponent";
import ListWorkspaceSharingModal from "./PatientListWorkspaceSharingModalComponent";
import { actionCreators } from "./PatientListsActions";
import { SortByFilter } from "./SortByFilter";

const isASectionSelected = (
    section: PatientListAppointmentDay | null,
): section is PatientListAppointmentDay => {
    return section !== null;
};
const isSelectedSectionADate = (
    section: PatientListAppointmentDay | null,
): section is PatientListAppointmentDay => {
    return isASectionSelected(section) && section.date !== "";
};
const isSelectedSectionAFutureDate = (
    section: PatientListAppointmentDay | null,
): section is PatientListAppointmentDay => {
    if (isSelectedSectionADate(section)) {
        const isFutureDate = DateHelpers.isBeforeToday(section.date) === false;
        return isFutureDate;
    }

    return false;
};

export const PatientListEditable = (): JSX.Element | null => {
    const dispatch = useDispatch();

    // If organisationId is null, the GuardedRoute will automatically redirect to the homepage
    const organisationId = useAppSelector(
        (state) => state.account.selectedOrganisation,
    );

    const orgName = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationName(account),
    );

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const analyticsPatientListProps = useAppSelector((state) => {
        const listId = state.patientLists.currentList?.patientListId;
        if (!listId) return undefined;
        return AnalyticsMapper.getPatientListAnalyticsProps(state, listId);
    }, shallowEqual);

    const currentList = useAppSelector(
        ({ patientLists }) => patientLists.currentList,
        shallowEqual,
    );
    const currentListId = currentList?.patientListId;
    const currentListDays = currentList?.appointmentDays;
    const currentListPatientDetails = currentList?.patientDetails;

    const currentListPatientDuplicates = useAppSelector(
        ({ patientLists }) => patientLists.currentListPatientDuplicates,
        shallowEqual,
    );
    const currentListBulkAddErrors = useAppSelector(
        ({ patientLists }) => patientLists.currentListBulkAddErrors,
        shallowEqual,
    );

    /* Action statuses i.e. loading / failures */
    const currentListPatientAdding = useAppSelector(
        ({ patientLists }) => patientLists.currentListPatientAdding,
    );
    const currentListPatientsBulkAdding = useAppSelector(({ patientLists }) =>
        getPatientListIsBulkAdding(patientLists),
    );
    const currentListPatientAddLastFailed = useAppSelector(
        ({ patientLists }) => patientLists.currentListPatientAddLastFailed,
    );
    const currentListPatientRemoving = useAppSelector(
        ({ patientLists }) => patientLists.currentListPatientRemoving,
    );
    const currentListPatientsBatchRemoving = useAppSelector(
        ({ patientLists }) => patientLists.currentListPatientsBatchRemoving,
    );
    const isSearching = useAppSelector(
        ({ searchForPatient }) => searchForPatient.isSearching,
    );

    const organisation = useAppSelector(
        ({ account }) => OrganisationHelper.getOrganisation(account),
        shallowEqual,
    );

    const isArchived = organisation
        ? isArchivedWorkspace(organisation as Organisation)
        : false;

    const [daySettingsDropdownOpen, setDaySettingsDropdownOpen] =
        useState(false);
    const [isAddingPatients, setIsAddingPatients] = useState(false);

    const [
        isConfirmDeletePatientsModalOpen,
        setIsConfirmDeletePatientsModalOpen,
    ] = useState(false);
    const [scheduledVideoConsults, setScheduledVideoConsults] = useState<
        PatientListAppointment[]
    >([]);

    const [selectedDateIndex, setSelectedDateIndex] = useState(0);
    const currentDaySelected =
        currentListDays && currentListDays.length > selectedDateIndex
            ? currentListDays[selectedDateIndex]
            : null;

    //#region Actions

    const toggleIsAddingPatients = (e: MouseEvent<HTMLButtonElement>): void => {
        if (isArchived) {
            return;
        }
        e.preventDefault();

        !isAddingPatients &&
            analyticsLoggedInProps &&
            FlemingAnalyticsTracker.trackPatientListStartAdding(
                analyticsLoggedInProps,
            );

        setIsAddingPatients(!isAddingPatients);
        if (!currentListPatientAdding && currentListPatientAddLastFailed) {
            dispatch(actionCreators.resetCurrentListErrors());
        }
    };

    const toggleDaySettingsDropdown = (): void => {
        setDaySettingsDropdownOpen((prevState) => !prevState);
    };

    const handleDaySelected = setSelectedDateIndex;

    const handleRemoveFromDuplicates = (
        e: MouseEvent,
        appointment: PatientListAppointment,
    ): void => {
        e.preventDefault();

        dispatch(actionCreators.removeCurrentListDuplicate(appointment));
    };

    const handleRemoveDay = async (): Promise<void> => {
        if (currentListId && organisationId) {
            if (isSelectedSectionAFutureDate(currentDaySelected)) {
                const { success, result } =
                    await FlemingApi.checkVideoConsultCancellations({
                        organisationId,
                        patientListId: currentListId,
                        patientListEntryIds:
                            currentDaySelected.appointments.map(
                                (appt) => appt.id,
                            ),
                    });

                if (success && result !== null) {
                    if (result.scheduledVideoConsults.length > 0) {
                        setScheduledVideoConsults(
                            result.scheduledVideoConsults,
                        );
                    }
                } else {
                    toast(
                        Feedback({
                            colour: "error",
                            title: "Sorry, something went wrong",
                            content:
                                "We were unable to remove these appointments. Please refresh the page and try again.",
                        }),
                    );

                    return;
                }
            }

            // we always want to show a modal for this use case. The logic in the
            // conditional above is for figuring out if the modal should show a
            // video consult warning
            setIsConfirmDeletePatientsModalOpen(true);
        }
    };

    // confirm delete actions
    const handleConfirmDelete = useCallback(() => {
        analyticsPatientListProps &&
            FlemingAnalyticsTracker.trackPatientListBulkRemovePatientClicked(
                analyticsPatientListProps,
            );

        if (currentListId && organisationId && currentDaySelected) {
            dispatch(
                actionCreators.removePatientsFromList({
                    organisationId,
                    patientListId: currentListId,
                    patientListEntries: currentDaySelected.appointments,
                }),
            );
        }
        setIsConfirmDeletePatientsModalOpen(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentListId, organisationId, currentDaySelected, dispatch]);

    const handleCancelDelete = useCallback(() => {
        setIsConfirmDeletePatientsModalOpen(false);
    }, []);
    // end confirm delete actions

    //#endregion Actions

    //#region Render

    if (!organisationId) return null;

    const hasEntries = currentListPatientDetails
        ? Object.keys(currentListPatientDetails).length > 0
        : false;

    const actionInProgress =
        currentListPatientsBatchRemoving ||
        currentListPatientRemoving ||
        isSearching ||
        currentListPatientAdding ||
        currentListPatientsBulkAdding;

    return (
        <>
            <ListSharingModal />
            <ListWorkspaceSharingModal />
            {currentList?.listType === PatientListType.WorkspaceManaged && (
                <Feedback colour="information" iconName="None">
                    <Text skinny>
                        This list is shared across{" "}
                        <Text variant="label" as="span">
                            {orgName}
                        </Text>
                        . Any changes you make will be visible to everyone in{" "}
                        <Text variant="label" as="span">
                            {orgName}.
                        </Text>
                    </Text>
                </Feedback>
            )}
            {currentList && isConfirmDeletePatientsModalOpen && (
                <ConfirmDeleteAppointments
                    affectedDates={
                        currentDaySelected?.date !== undefined
                            ? [currentDaySelected.date]
                            : null
                    }
                    appointmentsWithVideoConsultsToDelete={
                        scheduledVideoConsults
                    }
                    listDetails={{
                        listName: currentList.name,
                        isCurrentUserListOwner:
                            currentList.isCurrentUserListOwner,
                        listType: currentList.listType,
                    }}
                    handleConfirm={handleConfirmDelete}
                    handleCancel={handleCancelDelete}
                />
            )}
            {!isAddingPatients &&
                !hasEntries && ( // We have no entries and we are not currently adding
                    <Card variant="dashed">
                        <StyledEmptyListDiv>
                            <PatientListEmptyIllustration className="mw-100" />
                            <Text
                                variant="subtitle"
                                as="h2"
                                props={{
                                    className: "mb-3",
                                }}
                            >
                                Your patient list is empty
                            </Text>
                            {!isArchived && (
                                <>
                                    <Text
                                        variant="body"
                                        props={{
                                            className: "mb-3",
                                        }}
                                    >
                                        Help manage your Video Consults, SMS
                                        messages and everything else by creating
                                        a list of patients.
                                    </Text>
                                    <Button
                                        icon={{ name: "Plus" }}
                                        text="Start adding patients"
                                        className="mx-auto"
                                        onClick={toggleIsAddingPatients}
                                    />
                                </>
                            )}
                        </StyledEmptyListDiv>
                    </Card>
                )}
            {isAddingPatients && (
                <>
                    <div className="d-flex justify-content-end">
                        <Text as="a" colour="metal" skinny>
                            <Button
                                icon={{
                                    name: "Cross",
                                    colour: "blue",
                                }}
                                text="Close"
                                theme="secondary"
                                dimension="medium"
                                onClick={toggleIsAddingPatients}
                                className="text-nowrap"
                            />
                        </Text>
                    </div>
                    <AddPatientsToList />
                </>
            )}
            {currentListBulkAddErrors.length > 0 && (
                <PatientListBulkAddPatientErrorsCard />
            )}
            {currentListPatientDuplicates.length > 0 &&
                currentListPatientDetails != null && (
                    <PatientListBulkUploadDuplicates
                        currentListPatientDuplicates={
                            currentListPatientDuplicates
                        }
                        currentListPatientDetails={currentListPatientDetails}
                        onRemoveFromDuplicates={handleRemoveFromDuplicates}
                    />
                )}
            {currentListDays && currentListDays?.length > 0 && (
                <>
                    <StyledSplitRow>
                        <StyledColumns>
                            <ClinicDayFilter
                                selectedDateIndex={selectedDateIndex}
                                onDaySelected={handleDaySelected}
                            />
                        </StyledColumns>
                        {currentDaySelected && (
                            <StyledColumns>
                                <StyledRow>
                                    <div className="flex-grow-1">
                                        <SortByFilter
                                            currentDaySelected={
                                                currentDaySelected
                                            }
                                        />
                                    </div>
                                    {!isArchived && (
                                        <div
                                            className="align-self-end"
                                            data-testid="toggle-more-button"
                                        >
                                            <ButtonDropdown
                                                isOpen={daySettingsDropdownOpen}
                                                onToggle={
                                                    toggleDaySettingsDropdown
                                                }
                                                customIcon={{
                                                    name: "More",
                                                    colour: "zinc",
                                                    style: "Fill",
                                                }}
                                                opensRight
                                            >
                                                <DropdownMenuOption
                                                    key="remove-all-day-patients"
                                                    onClick={handleRemoveDay}
                                                >
                                                    <span className="d-flex align-items-center">
                                                        <Icon
                                                            name="Bin"
                                                            colour="red"
                                                            size={3}
                                                            props={{
                                                                className:
                                                                    "mr-1",
                                                            }}
                                                        />
                                                        <strong>
                                                            Remove{" "}
                                                            {currentDaySelected.date
                                                                ? "day"
                                                                : "all"}
                                                        </strong>
                                                    </span>
                                                </DropdownMenuOption>
                                            </ButtonDropdown>
                                        </div>
                                    )}
                                    {!isArchived && (
                                        <StyledButtonWrapper
                                            isVisible={!isAddingPatients}
                                        >
                                            <Button
                                                icon={{
                                                    name: "Plus",
                                                    colour: "blue",
                                                }}
                                                text="Add patients"
                                                theme="secondary"
                                                className="text-nowrap "
                                                onClick={toggleIsAddingPatients}
                                                disabled={actionInProgress}
                                            />
                                        </StyledButtonWrapper>
                                    )}
                                </StyledRow>
                            </StyledColumns>
                        )}
                    </StyledSplitRow>
                    {currentDaySelected?.appointments &&
                        currentListPatientDetails && (
                            <PatientListAppointments
                                isReadonly={false}
                                appointments={currentDaySelected?.appointments}
                                patientDetails={currentListPatientDetails}
                            />
                        )}
                </>
            )}
            <CreateOrEditPatientListModalComponent />
        </>
    );

    //#endregion Render
};
