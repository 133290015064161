import { useCurrentWorkspace } from "@accurx/auth";
import * as UI from "@accurx/design";
import {
    useMedicalRecordConnection,
    useNativeSubscription,
} from "@accurx/native";
import { Redirect, generatePath } from "react-router-dom";

import { ROUTES_COMPOSE } from "../Routes.constants";
import { ComposeVariant } from "../Routes.types";
import { ClinicianComposeNewMessage } from "../clinicianCompose/ClinicianComposeNewMessage";
import { StyledPatientHeaderWrapper } from "../components/Page.styles";
import { ComposeHeader } from "../components/PatientHeader/ComposeHeader";
import { RestrictedWidthContainer } from "../components/RestrictedWidthContainer";
import { ComposeNewMessage } from "./ComposeNewMessage";
import { useCurrentPatient } from "./context/CurrentPatientProvider";

export const ComposePage = ({
    composeVariant,
}: {
    composeVariant: ComposeVariant;
}) => {
    const medicalRecordConnection = useMedicalRecordConnection();
    const workspace = useCurrentWorkspace();

    const { currentPatient } = useCurrentPatient();
    const { status: medicalRecordPatientFeed } = useNativeSubscription(
        "SubscribeCurrentPatient",
    );

    if (currentPatient === null) {
        return (
            <Redirect
                to={generatePath(
                    ROUTES_COMPOSE.search + `?composeVariant=${composeVariant}`,
                    {
                        workspaceId: workspace.orgId,
                    },
                )}
            />
        );
    }

    const { patient, contactDetails } = currentPatient;

    return (
        <>
            {medicalRecordConnection.status === "Disconnected" && (
                <UI.Ds.Box p="2" backgroundColor="white" pb="0">
                    <UI.Feedback
                        colour="warning"
                        title="Unable to connect to the medical system"
                    >
                        Some features including save to record are unavailable
                    </UI.Feedback>
                </UI.Ds.Box>
            )}

            <StyledPatientHeaderWrapper>
                <ComposeHeader patient={patient} />
            </StyledPatientHeaderWrapper>

            {medicalRecordConnection.status === "Connected" &&
                medicalRecordPatientFeed === "error" && (
                    <UI.Feedback colour="warning">
                        There was a problem fetching the patient form the EMR,
                        the current patient may not match the one selected in
                        the EMR.
                    </UI.Feedback>
                )}
            <RestrictedWidthContainer>
                {composeVariant === "Clinician" ? (
                    <ClinicianComposeNewMessage patient={patient} />
                ) : (
                    <ComposeNewMessage
                        patient={patient}
                        contactDetails={contactDetails}
                    />
                )}
            </RestrictedWidthContainer>
        </>
    );
};
