import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import { formatNhsNumber } from "@accurx/shared/dist/NhsNumberHelper";

import { PatientExternalId } from "../types";

export const generatePatientGreeting = ({
    ageYear,
    prefixName,
    familyName,
    firstName,
    nickname,
}: {
    ageYear: number | null;
    prefixName: string | null;
    familyName: string | null;
    firstName: string | null;
    nickname: string | null;
}): string => {
    const preferredFirstName = nickname ?? firstName;

    if (ageYear !== null && ageYear <= 7) {
        return preferredFirstName
            ? `Dear parent/guardian of ${preferredFirstName},`
            : "Dear parent/guardian,";
    }

    // Informal greeting
    if (ageYear !== null && ageYear < 30) {
        if (preferredFirstName) {
            return `Hi ${preferredFirstName},`;
        }
        if (prefixName && familyName) {
            return `Hi ${prefixName} ${familyName},`;
        }
        return "Hi,";
    }

    // Formal greeting
    if (prefixName && familyName) {
        return `Dear ${prefixName} ${familyName},`;
    }
    if (preferredFirstName) {
        return `Dear ${preferredFirstName},`;
    }

    // Fallback greeting
    return "Dear patient,";
};

const getPatientNameForClinicianGreeting = ({
    firstName,
    familyName,
    prefixName,
}: {
    prefixName: string | null;
    familyName: string | null;
    firstName: string | null;
}) => {
    if (firstName && familyName) {
        return `${firstName} ${familyName}`;
    }

    if (familyName && prefixName) {
        return `${prefixName} ${familyName}`;
    }

    return "Patient";
};

export const generateClinicianGreeting = (patient?: {
    prefixName: string | null;
    familyName: string | null;
    firstName: string | null;
    dateOfBirth: string | null;
    gender: string;
    externalIds: PatientExternalId[];
    nhsNumber?: string;
}): string => {
    if (!patient) {
        return "Dear Colleague,";
    }

    const patientName = getPatientNameForClinicianGreeting(patient);

    const dateOfBirth = patient.dateOfBirth
        ? DateHelpers.formatDate(
              patient.dateOfBirth,
              DateFormatOptions.DATE_SHORT_WITH_HYPHENS,
          )
        : undefined;

    const nhsNumberExternalId = patient.externalIds.find(
        (externalId) => externalId.type === "NhsNumber",
    );
    const nhsNumber = nhsNumberExternalId
        ? formatNhsNumber(nhsNumberExternalId.value)
        : undefined;

    const patientDetails = [patientName, dateOfBirth, nhsNumber, patient.gender]
        .filter((v) => !!v) // Remove missing patient details e.g. empty DOB
        .join(", ");

    return `Dear Colleague,\nRE: ${patientDetails}`;
};
