import React from "react";

import { Ds } from "@accurx/design";

export const RequestsStatusAccordion = () => {
    return (
        <Ds.Accordion>
            <Ds.Accordion.Item value="status-explanation">
                <Ds.Accordion.Trigger>
                    Table status explained
                </Ds.Accordion.Trigger>
                <Ds.Accordion.Content>
                    <Ds.Text>
                        <Ds.Text as="span" size="medium" weight="bold">
                            Request failed:{" "}
                        </Ds.Text>
                        We were unable to retrieve this patient's record.
                    </Ds.Text>
                    <Ds.Text>
                        <Ds.Text as="span" size="medium" weight="bold">
                            Expired:{" "}
                        </Ds.Text>
                        The record display expired after 24 hours and is no
                        longer available.
                    </Ds.Text>
                    <Ds.Text>
                        <Ds.Text as="span" size="medium" weight="bold">
                            Patient authorisation required:{" "}
                        </Ds.Text>
                        The patient needs to share their 6-digit access code
                        with the requester to authorise record view.
                    </Ds.Text>
                    <Ds.Text>
                        <Ds.Text as="span" size="medium" weight="bold">
                            Authorisation failed:{" "}
                        </Ds.Text>
                        We are unable to display the record as there were 15
                        failed attempts to enter the patient's 6 digit access
                        code into Accurx Web.
                    </Ds.Text>
                    <Ds.Text>
                        <Ds.Text as="span" size="medium" weight="bold">
                            Currently requesting:{" "}
                        </Ds.Text>
                        Record View has been authorised and the display is
                        pending.
                    </Ds.Text>
                </Ds.Accordion.Content>
            </Ds.Accordion.Item>
        </Ds.Accordion>
    );
};
