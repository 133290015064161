export const PAGE_CONTENT_ID = "page-content";
export const PORTAL_ID_APP_HEADER = "app-header-root";
export const PORTAL_ID_APP_SUBHEADER = "app-subheader-root";

export const userflowIds = {
    navigation: {
        primary: {
            newMessage: "navigation-new-message-dropdown",
            newMessagePatient: "navigation-new-message-message-patient",
            newMessageGp: "navigation-new-message-message-gp",
            newMessageVideoConsult: "navigation-new-message-video-consult",
            newMessageBatch: "navigation-new-message-batch-message",
            messagePatient: "navigation-message-patient",

            searchPatient: "navigation-search-patient",
            directory: "navigation-directory",

            inbox: "navigation-inbox-link",
            patientLists: "navigation-patient-lists-link",

            workspaceMenu: "navigation-workspace-menu-dropdown",
            workspaceMenuSettings: "navigation-workspace-menu-settings",
            workspaceMenuUsers: "navigation-workspace-menu-users",
            workspaceMenuReporting: "navigation-workspace-menu-reporting",
            workspaceMenuHelp: "navigation-workspace-menu-help-dropdown", // Mobile only
            workspaceMenuWorkspaceSwitcher:
                "navigation-workspace-menu-workspace-switcher", // Mobile only
            workspaceMenuLogout: "navigation-workspace-menu-logout",

            workspaceSwitcher: "navigation-workspace-switcher",
            workspaceSwitcherJoinOrganisation:
                "navigation-workspace-switcher-join-organisation",

            help: "navigation-help-dropdown",
            helpCentre: "navigation-help-help-centre-link",
            helpContactSupport: "navigation-help-contact-support",
            helpSendFeedback: "navigation-help-send-feedback-link",
            helpWhatIsNext: "navigation-help-what-is-next",
        },
        secondary: {
            workspaceSettings: "navigation-workspace-settings-accordion",
            workspaceSettingsName:
                "navigation-workspace-settings-workspace-name-link",
            workspaceSettingsUsers: "navigation-workspace-settings-users-link",
            workspaceSettingsAvailableFeatures:
                "navigation-workspace-settings-available-features-link",
            workspaceSettingsInstalledComputers:
                "navigation-workspace-settings-installed-computers-link",
            workspaceSettingsTeamNotifications:
                "navigation-workspace-settings-team-notifications-link",
            workspaceSettingsSmsSenderId:
                "navigation-workspace-settings-sms-sender-id-link",
            workspaceSettingsResources:
                "navigation-workspace-settings-resources-link",

            accountSettings: "navigation-account-settings-accordion",
            accountSettingsNameAndEmail:
                "navigation-account-settings-name-and-email-link",
            accountSettingsYourWorkspaces:
                "navigation-account-settings-your-workspaces-link",
            accountSettingsMessageSignature:
                "navigation-account-settings-message-signature-link",
            accountSettingsCookies: "navigation-account-settings-cookies-link",
            accountSettingsSecurity:
                "navigation-account-settings-security-link",
            accountSettingsTermsAndConditions:
                "navigation-account-settings-terms-and-conditions-link",

            templates: "navigation-templates-link",
            recordRequests: "navigation-record-requests-link",
            smsStatus: "navigation-sms-status-link",

            reminders: "navigation-reminders-accordion",
            remindersManage: "navigation-reminders-manage-reminders-link",
            remindersSent: "navigation-reminders-sent-reminders-link",
            remindersAppointmentChanges:
                "navigation-reminders-appointment-changes-link",
            appointmentsUpload: "navigation-appointments-upload-link",

            patientTriage: "navigation-patient-triage-accordion",
            patientTriageWhatIs:
                "navigation-patient-triage-what-is-patient-triage-link",
            patientTriageSettings: "navigation-patient-triage-settings-link",
            patientTriageSetup: "navigation-patient-triage-setup-link",

            reporting: "navigation-reporting-accordion",
            reportingOverview: "navigation-reporting-overview-link",
            reportingTeamUsage: "navigation-reporting-team-usage-link",
            reportingPatientTriage: "navigation-reporting-patient-triage-link",
            reportingResponseRates: "navigation-reporting-response-rates-link",
            reportingFragments: "navigation-reporting-fragments-link",

            patientLists: "navigation-patient-lists-accordion",
            clinics: "navigation-clinics-link",
            batchMessages: "navigation-batch-messages-link",
        },
        patientSearch: {
            searchRecents: "navigation-patient-search-search-recents",
        },
    },
};
