import { useMemo } from "react";

import { Log } from "@accurx/shared";
import { OptionalHubClient } from "domains/realtime/hooks/useOptionalHubClient";
import { SignalRTransport } from "domains/realtime/types";
import { filter } from "rxjs/operators";

// A hook to encapsulate the logic for sending and subscribing to SignalR events
// via hubClient. The hook is composed of being able to send SignalR events and
// setting up and subscribing events, which is abstracted under a single subscribe function
export const useHubClientTransport = (
    hubClient: OptionalHubClient,
): SignalRTransport => {
    return useMemo(
        () => ({
            send: ({ type, payload }) => {
                if (!hubClient) {
                    Log.error(
                        `HubClientTransport: Tried to send an event with no HubClient`,
                        {
                            tags: {
                                type,
                            },
                        },
                    );
                    throw new Error(
                        `No HubClient provided - Unable to send ${type} event`,
                    );
                }

                void hubClient.send(type, payload);
            },
            subscribe: ({ type, onEvent, eventFilter = () => true }) => {
                if (!hubClient) {
                    Log.error(
                        `HubClientTransport: Tried to subscribe to an event with no HubClient`,
                        {
                            tags: {
                                type,
                            },
                        },
                    );
                    throw new Error(
                        `No HubClient provided - Unable to subscribe to ${type} event`,
                    );
                }

                const subscription = hubClient
                    .getSubscription(type)
                    .pipe(filter((event) => eventFilter(event.payload)))
                    .subscribe((event) => onEvent(event.payload));
                return { unsubscribe: () => subscription.unsubscribe() };
            },
        }),
        [hubClient],
    );
};
