/**
 * Types suffixed with 'Legacy' are a holdover from when we serialised
 * SignalR payloads using PascalCase. They allow backwards compatibility
 * but will be removed in future.
 *
 * https://github.com/Accurx/rosemary/pull/18433
 */
import { BasicUserStatus } from "@accurx/api/account";
import { TicketIdentityType, UserGroupType } from "@accurx/api/ticket";

export { type PatientThreadItemUpdate } from "@accurx/api/ticket";

export type PatientThreadUserGroupChanged = {
    organisationId: Optional<number>;
    userGroup: Optional<{
        id: Optional<string>;
        name: Optional<string>;
        groupType: Optional<UserGroupType>;
    }>;
    userMembership: Optional<
        {
            userId: Optional<string>;
            userHasConfirmedMemberShip: Optional<boolean>;
        }[]
    >;
};

/**
 * @deprecated use PatientThreadUserGroupChanged
 */
export type PatientThreadUserGroupChangedLegacy = {
    OrganisationId: Optional<number>;
    UserGroup: Optional<{
        Id: Optional<string>;
        Name: Optional<string>;
        GroupType: Optional<UserGroupType>;
    }>;
    UserMembership: Optional<
        {
            UserId: Optional<string>;
            UserHasConfirmedMemberShip: Optional<boolean>;
        }[]
    >;
};

export type VideoConsultationStatusChanged = {
    consultationId: string;
    patientListId: number;
};

/**
 * @deprecated use VideoConsultationStatusChanged
 */
export type VideoConsultationStatusChangedLegacy = {
    ConsultationId: string;
    PatientListId: number;
};

export type VaccineInviteStatusPushUpdate = {
    organisationId: number;
    inviteId: string;
    currentInviteStatus: string;
};

/**
 * @deprecated use VaccineInviteStatusPushUpdate
 */
export type VaccineInviteStatusPushUpdateLegacy = {
    InviteId: string;
    CurrentInviteStatus: string;
};

export type NoteTypingSend = {
    organisationId: Optional<number>;
    patient: Optional<{ accuRxId: Optional<string> }>;
    ticketIdentity: Optional<{
        type: Optional<TicketIdentityType>;
        id: Optional<string>;
    }>;
};

export type NoteTypingReceive = {
    organisationId: Optional<number>;
    patientId: Optional<string>;
    sender: Optional<{
        accuRxId: Optional<string>;
        email: Optional<string>;
        name: Optional<string>;
    }>;
    ticketIdentity: Optional<{
        type: Optional<TicketIdentityType>;
        id: Optional<string>;
    }>;
};

export type ThreadActiveSend = {
    organisationId: Optional<number>;
    patient: Optional<{ accuRxId: Optional<string> }>;
    ticketIdentity: Optional<{
        type: Optional<TicketIdentityType>;
        id: Optional<string>;
    }>;
};

export type ThreadActiveReceive = {
    organisationId: Optional<number>;
    patientId: Optional<string>;
    sender: {
        accuRxId: Optional<string>;
        email: Optional<string>;
        name: Optional<string>;
    };
    ticketIdentity: Optional<{
        type: Optional<TicketIdentityType>;
        id: Optional<string>;
    }>;
};

export enum MachineChangeStatusReceiveStatus {
    None = 0,
    OnConnected = 1,
    OnDisconnected = 2,
    StatusUpdateActive = 3,
    StatusUpdateIdle = 4,
}

export type MachineChangeStatusReceive = {
    status: MachineChangeStatusReceiveStatus;
    organisationId: Optional<number>;
    fromUser: Optional<{
        accuRxId: string;
        email: string;
        name: string;
    }>;
};

export enum StatusType {
    None = 0,
    StatusUpdateActive = 1,
    StatusUpdateIdle = 2,
}

export type ConnectedStatus = {
    status: StatusType;
    organisationId: Optional<number>;
    notifySingleUserId: Optional<string>;
    machineIdentifier: Optional<string>;
};

export type OnConnectedInfo = {
    organisationId: Optional<number>;
    machineIdentifier: Optional<string>;
};

export type ReconnectPushRequest = {
    workspaceId: Optional<number>;
};

export type HubReconnectDataInbox = {
    unreadPatientThreadNoteIds: Optional<string[]>;
    patientThreadUserUserGroupIds: Optional<string[]>;
    organisationId: Optional<number>;
};

export type UserApprovalChange = {
    organisationId: number;
    userId: number;
    userStatus: BasicUserStatus;
};

export enum OrganisationEntityChangedType {
    None = 0,
    Settings = 1,
    Content = 2,
    NoticeBoard = 3,
    Templates = 4,
    UserStatusChange = 5,
    FloreyTemplate = 6,
}

export type OrganisationEntityChanged = {
    organisationId: Optional<number>;
    type: Optional<OrganisationEntityChangedType>;
};
