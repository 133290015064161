import { Button } from "@accurx/design";

import { useUnifiedLayout } from "../UnifiedLayoutContext";
import { StyledMobileHeader } from "./MobileHeader.styles";

export const MobileHeader = () => {
    const { secondaryNav } = useUnifiedLayout();

    return (
        <StyledMobileHeader>
            <Button
                theme="transparent"
                dimension="medium"
                icon={{ name: "Menu", title: "Menu" }}
                onClick={secondaryNav.toggle}
            />
        </StyledMobileHeader>
    );
};
