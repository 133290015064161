import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledExpandableContainer = styled(UI.Flex).attrs({ gap: "1" })<{
    $isExpanded: boolean;
    $isFloating: boolean;
    $isMobile: boolean;
}>`
    ${(props) => props.$isExpanded && "flex: 1;"}
    margin: ${(props) => {
        if (props.$isFloating) {
            return UI.Tokens.SIZES[3];
        }

        const topMargin = props.$isExpanded ? UI.Tokens.SIZES[2] : "0";
        const horizontalMargin = props.$isMobile
            ? UI.Tokens.SIZES[0.25]
            : UI.Tokens.SIZES[2];

        return `${topMargin} ${horizontalMargin} ${UI.Tokens.SIZES[1]}`;
    }};
    max-width: ${UI.Tokens.BREAKPOINTS.md};
    flex: 1;
    position: ${(props) => (props.$isFloating ? "absolute" : "inherit")};
    bottom: 0;
    left: 0;

    width: fill-available;
`;

export const StyledReplyAndNote = styled(UI.Ds.Flex)`
    width: 100%;
`;
