import { ReactNode, useLayoutEffect, useRef } from "react";

import { Level2AccordionHeaderHeight } from "domains/navigation/styles/accordionHeight";
import { customScrollbar } from "domains/navigation/styles/scrollbar";
import styled from "styled-components";

import { scrollChildToCenter } from "./scrollChildToCenter";
import { useElementSize } from "./useElementSize";

type MagicScrollableAreaProps = {
    children: ReactNode;
};

const Container = styled.div`
    overflow-y: hidden;
    height: 100%;
`;

const HeightPlaceholder = styled.div`
    visibility: hidden;
`;

const Scrollable = styled.div`
    position: absolute;
    top: ${Level2AccordionHeaderHeight};
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: scroll;
    ${customScrollbar}
`;

/**
 * The height of a scrollable element is not controlled by the height of its
 * content. In the sidebar accordion we have sections which should expand
 * vertically to fill the available space, but once the available space is
 * filled it should become scrollable.
 *
 * In order to support that behaviour we need two elements:
 * 1. A placeholder div that is not scrollable and is given the height of the
 *    content. The role of this element is to take up vertical space.
 * 2. A scrollable element which is absolutely positioned to fill the available
 *    space and contains the content.
 */
export const ExpandingScrollableArea = (props: MagicScrollableAreaProps) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const scrollableRef = useRef<HTMLDivElement>(null);
    const { height } = useElementSize(contentRef);

    useLayoutEffect(() => {
        let timeout: NodeJS.Timeout;
        const scrollable = scrollableRef.current;
        if (!scrollable) return;

        const active = scrollable.querySelector("[data-autoscroll='true']");

        if (active) {
            // Wait 30ms for the accordion animation and then scroll to center
            timeout = setTimeout(() => {
                scrollChildToCenter({
                    child: active as HTMLElement,
                    parent: scrollable,
                });
            }, 300);

            return () => clearTimeout(timeout);
        }
    }, []);

    return (
        <Container>
            <HeightPlaceholder style={{ height }} />
            <Scrollable ref={scrollableRef}>
                <div ref={contentRef}>{props.children}</div>
            </Scrollable>
        </Container>
    );
};
