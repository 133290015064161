import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useFeatureFlag } from "@accurx/auth";
import * as UI from "@accurx/design";
import { Feedback } from "@accurx/inbox-design-library";
import { useNativeTrackingFields } from "@accurx/native";
import { getIsFileLimitReached } from "domains/message-component/components/Compose/components/Attach/getIsFileLimitReached";
import { getValidPatientNhsNumber } from "domains/message-component/utils";

import { useCompose } from "../../../../context";
import { MessageActionsControlBarProps } from "../../types/Compose.types";
import { AttachSelector } from "../Attach/components/AttachSelector/AttachSelector";
import { AttachFileFromDesktopButtonBySourceType } from "../Attach/components/AttachSelector/components/AttachFileFromDesktopButton";
import { AttachFileFromPatientRecordButton } from "../Attach/components/AttachSelector/components/AttachFileFromPatientRecordButton";
import { AttachNHSAdviceButton } from "../Attach/components/AttachSelector/components/AttachNHSAdviceButton";
import { BookingInvite } from "../BookingInvite/BookingInvite";
import { AllowReplyCheckbox } from "../PatientResponseCheckbox/AllowReplyCheckbox";
import { SaveToRecordCheckbox } from "../SaveToRecordCheckbox/SaveToRecordCheckbox";

export const MessageActionsControlBar = ({
    showSelfbookConfiguration,
    setAssignee,
    attachSelectorButtonRef,
    setShowPatientRecordAttachments,
    patient,
    setShowNhsAdviceSelector,
    setShowSelfbookConfiguration,
    canSaveToRecord,
}: MessageActionsControlBarProps) => {
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();
    const { state, dispatch } = useCompose();

    const [isAttachSelectorOpen, setIsAttachSelectorOpen] = useState(false);

    const isBookingInviteEnabled = useFeatureFlag(
        "SelfBookFromComposeSendLink",
    );

    const { size: viewPort } = UI.Hooks.useViewportSize();
    const isMobileSize = viewPort === "xs" || viewPort === "sm";

    const hasReachedAttachmentLimit = getIsFileLimitReached(state);
    const hasQuestionnaire = state.template.type === "QuestionnaireTemplate";

    const hasReachedUploadLimit =
        !hasQuestionnaire && hasReachedAttachmentLimit;
    const hasReachedNHSAdviceLinkLimit = !!state.nhsAdviceLink;
    const selfBookLinkSelected = state.selfBookLink !== null;

    const shouldDisableNHSAdvice =
        hasQuestionnaire ||
        hasReachedNHSAdviceLinkLimit ||
        selfBookLinkSelected ||
        showSelfbookConfiguration;
    const shouldDisableAttachments =
        hasQuestionnaire ||
        hasReachedUploadLimit ||
        selfBookLinkSelected ||
        showSelfbookConfiguration;
    const isPatientResponseCheckboxEnabled =
        !hasQuestionnaire && !selfBookLinkSelected;

    const patientResposeCheckboxOnChange = () => {
        track("PatientMessageResponse Option Select", {
            ...nativeTrackingFields,
            oldValue: state.isPatientResponseEnabled,
            newValue: !state.isPatientResponseEnabled,
        });

        // Reset assignee if patient response is being turned off
        if (state.isPatientResponseEnabled) {
            setAssignee?.(null);
        } else {
            setAssignee?.("DEFAULT");
        }

        dispatch({
            type: "UPDATE_IS_PATIENT_RESPONSE_ENABLED",
            payload: {
                isPatientResponseEnabled: !state.isPatientResponseEnabled,
            },
        });
    };

    return (
        <UI.Flex
            gap="1"
            justifyContent="space-between"
            alignItems={isMobileSize ? "flex-end" : "flex-start"}
        >
            <UI.Item>
                <UI.Flex
                    justifyContent="flex-start"
                    flexDirection={isMobileSize ? "column-reverse" : "row"}
                    gap={isMobileSize ? "1" : "0.5"}
                >
                    <UI.Item>
                        <AllowReplyCheckbox
                            checked={state.isPatientResponseEnabled}
                            onChange={patientResposeCheckboxOnChange}
                            disabled={
                                !isPatientResponseCheckboxEnabled ||
                                showSelfbookConfiguration ||
                                !!state.selfBookLink ||
                                state.template.type ===
                                    "PendingQuestionnaireTemplate"
                            }
                        />
                    </UI.Item>

                    <UI.Flex gap="0.5">
                        <UI.Item>
                            <AttachSelector
                                ref={attachSelectorButtonRef}
                                isOpen={isAttachSelectorOpen}
                                toggle={() => {
                                    // only track when its being opened
                                    if (!isAttachSelectorOpen) {
                                        track("Attachment Button Click", {
                                            ...nativeTrackingFields,
                                            conversationParticipant:
                                                state.conversationParticipant,
                                            eventVersion: 2,
                                        });
                                    }

                                    setIsAttachSelectorOpen((prev) => !prev);
                                }}
                                iconOnlyButton={isBookingInviteEnabled}
                                disabled={
                                    showSelfbookConfiguration ||
                                    selfBookLinkSelected ||
                                    state.template.type ===
                                        "PendingQuestionnaireTemplate" ||
                                    hasQuestionnaire ||
                                    (shouldDisableAttachments &&
                                        shouldDisableNHSAdvice)
                                }
                            >
                                <UI.Flex flexDirection="column" gap="1">
                                    {state.maxAttachmentCount === 1 &&
                                        hasReachedUploadLimit &&
                                        !hasReachedNHSAdviceLinkLimit && (
                                            <Feedback
                                                colour="warning"
                                                title="File attachment limit reached"
                                            />
                                        )}
                                    <AttachFileFromDesktopButtonBySourceType
                                        source="PatientMessaging"
                                        isDisabled={shouldDisableAttachments}
                                        onClick={() => {
                                            track("Attachment MenuItem Click", {
                                                eventVersion: 2,
                                                conversationParticipant:
                                                    state.conversationParticipant,
                                                attachmentType: "LocalFile",
                                                ...nativeTrackingFields,
                                            });
                                        }}
                                        onChange={() =>
                                            setIsAttachSelectorOpen(false)
                                        }
                                    />
                                    <AttachFileFromPatientRecordButton
                                        isDisabled={shouldDisableAttachments}
                                        patientNhsNumber={getValidPatientNhsNumber(
                                            patient.externalIds,
                                        )}
                                        onClick={() => {
                                            track("Attachment MenuItem Click", {
                                                conversationParticipant:
                                                    state.conversationParticipant,
                                                attachmentType: "MedicalRecord",
                                                ...nativeTrackingFields,
                                            });

                                            setShowPatientRecordAttachments(
                                                true,
                                            );
                                            setIsAttachSelectorOpen(false);
                                        }}
                                    />

                                    <AttachNHSAdviceButton
                                        onClick={() => {
                                            track("Attachment MenuItem Click", {
                                                conversationParticipant:
                                                    state.conversationParticipant,
                                                attachmentType: "NHSAdvice",
                                                ...nativeTrackingFields,
                                            });

                                            setShowNhsAdviceSelector(true);
                                        }}
                                        isDisabled={shouldDisableNHSAdvice}
                                        showWarning={
                                            hasReachedNHSAdviceLinkLimit &&
                                            !hasReachedUploadLimit
                                        }
                                    />
                                </UI.Flex>
                            </AttachSelector>
                        </UI.Item>

                        {isBookingInviteEnabled && (
                            <UI.Item>
                                <BookingInvite
                                    onClick={() =>
                                        setShowSelfbookConfiguration(true)
                                    }
                                />
                            </UI.Item>
                        )}
                    </UI.Flex>
                </UI.Flex>
            </UI.Item>

            {canSaveToRecord && (
                <UI.Item>
                    <SaveToRecordCheckbox />
                </UI.Item>
            )}
        </UI.Flex>
    );
};
