import React from "react";

import { ColourVariants, Icon, IconNameVariants } from "@accurx/design";
import { IconRotationVariants } from "@accurx/design/dist/styles/types";

import { ConversationItem } from "shared/concierge/conversations/types/item.types";
import { useCurrentUserId } from "store/hooks";

import {
    StyledPatientPillAvatarContainer,
    StyledPatientPillContainer,
    StyledPatientPillInnerContainer,
    StyledText,
} from "./ItemIndicatorPill.styles";

type ItemIndicatorPillProps = {
    conversationItem: ConversationItem;
    hasUnread: boolean;
};

export const ItemIndicatorPill = ({
    conversationItem,
    hasUnread,
}: ItemIndicatorPillProps): JSX.Element => {
    const currentUserId = useCurrentUserId();
    const data = itemIndicatorData(conversationItem, currentUserId);

    if (!data) {
        return <></>;
    }

    return (
        <StyledPatientPillContainer>
            <StyledPatientPillInnerContainer>
                <StyledPatientPillAvatarContainer>
                    <Icon
                        name={data.name}
                        size={3}
                        rotation={data.rotation}
                        halo={{
                            colour: data.colour,
                            luminosity: "high",
                        }}
                        props={{ "aria-hidden": true, focusable: false }}
                    />
                </StyledPatientPillAvatarContainer>
                <StyledText variant={hasUnread ? "label" : "body"}>
                    {data.label}
                </StyledText>
            </StyledPatientPillInnerContainer>
        </StyledPatientPillContainer>
    );
};

export const itemIndicatorData = (
    conversationItem: ConversationItem,
    currentUserId: string | undefined,
):
    | {
          rotation: IconRotationVariants;
          name: IconNameVariants;
          colour: ColourVariants;
          label: string;
      }
    | undefined => {
    switch (conversationItem.contentType) {
        case "PatientSms":
        case "PatientEmail":
        case "NhsAppMessage":
        case "VideoConsultLink":
            return {
                colour: "greyscale",
                rotation: "right",
                name: "ArrowTail",
                label: "To patient",
            };
        case "FloreyResponseNote":
        case "PatientSingleResponse":
        case "PatientTriageRequestNote":
        case "PatientAppointmentRequestNote":
        case "GenericNote":
            return {
                colour: "green",
                rotation: "left",
                name: "ArrowTail",
                label: "From patient",
            };
        case "FallbackFailedLink":
        case "FailedDeliveryReceiptLink":
            return {
                colour: "red",
                rotation: "down",
                name: "Warning",
                label: "Not delivered",
            };
        case "Note":
            return currentUserId &&
                conversationItem.createdBy.type === "User" &&
                currentUserId === conversationItem.createdBy.id
                ? {
                      colour: "greyscale",
                      rotation: "down",
                      name: "Document",
                      label: "Note sent",
                  }
                : {
                      colour: "green",
                      rotation: "down",
                      name: "Document",
                      label: "Note received",
                  };
        case "StateChange":
        case "LabelTag":
        case "Unknown":
            return undefined;
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = conversationItem;
            return undefined;
    }
};
