import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledConversationList = styled(UI.Grid)`
    height: 100%;
    padding: 0;
`;

export const StyledConversationFeedContainer = styled.div`
    position: relative;
    background-color: ${UI.Tokens.COLOURS.greyscale.cloud};
    display: flex;
    flex-direction: column;
`;

export const StyledAccessibleHeader = styled.span`
    position: absolute;
    left: -99999px;
`;

export const StyledHeaderTitle = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
`;

export const StyledHeaderWrap = styled(UI.Cell)`
    min-width: 0;
`;
