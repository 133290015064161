import { useEffect } from "react";

import { PAGE_CONTENT_ID } from "layouts/constants";
import { useHistory } from "react-router-dom";

export const useRestoreScrollPositionAfterCompose = () => {
    const history = useHistory<{ originScrollTop?: number } | undefined>();

    useEffect(() => {
        const routerState = history.location.state;
        const pageNode = document.getElementById(PAGE_CONTENT_ID);

        if (routerState?.originScrollTop && pageNode) {
            pageNode.scrollTo({ top: routerState.originScrollTop });
            history.replace({ state: {} });
        }
    }, [history]);
};
