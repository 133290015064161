import { useCallback, useState } from "react";

import { useCurrentUser, useCurrentWorkspace } from "@accurx/auth";
import { getEmbedMode } from "@accurx/native";
import {
    SignalRSendEvent,
    SignalRSubscriptionEvent,
    StatusType,
    MachineChangeStatusReceiveStatus as UserStatus,
    useSendSignalREvent,
    useSubscribeSignalREvent,
} from "@accurx/realtime";
import { Log } from "@accurx/shared";

type UserIdsOnlineSet = Set<string>;

export const useUserOnline = (): Set<string> => {
    const { orgId } = useCurrentWorkspace();
    const { user } = useCurrentUser();
    const { embedMode } = getEmbedMode();
    const isWeb = embedMode === "Web";

    const sendUserStatusUpdate = useSendSignalREvent(
        SignalRSendEvent.ConnectedStatus,
    );

    const [usersOnlineSet, setUsersOnlineSet] = useState<UserIdsOnlineSet>(
        new Set([]),
    );

    const addUser = (userId: string) => {
        setUsersOnlineSet((prevSet) => {
            const newSet = new Set(prevSet);
            newSet.add(userId);
            return newSet;
        });
    };

    const removeUser = (userId: string) => {
        setUsersOnlineSet((prevSet) => {
            const newSet = new Set(prevSet);
            newSet.delete(userId);
            return newSet;
        });
    };

    const broadcastStatus = useCallback(
        (
            statusType: UserStatus.OnConnected | UserStatus.OnDisconnected,
            userId: string,
        ) => {
            const isCurrentUserDisconnected =
                statusType === UserStatus.OnDisconnected;

            const payload = {
                organisationId: orgId,
                status: isCurrentUserDisconnected
                    ? StatusType.StatusUpdateIdle
                    : StatusType.StatusUpdateActive,
                notifySingleUserId: isCurrentUserDisconnected ? null : userId,
                machineIdentifier: null,
            };

            sendUserStatusUpdate(payload);
        },
        [sendUserStatusUpdate, orgId],
    );

    const updateUserStatus = useCallback(
        (statusType: UserStatus, userId: string) => {
            switch (statusType) {
                case UserStatus.OnConnected: {
                    addUser(userId);

                    if (isWeb && userId !== user.accuRxUserId) {
                        broadcastStatus(statusType, userId);
                    }
                    break;
                }

                case UserStatus.OnDisconnected: {
                    removeUser(userId);

                    if (isWeb && userId === user.accuRxUserId) {
                        broadcastStatus(statusType, userId);
                    }
                    break;
                }

                case UserStatus.StatusUpdateActive: {
                    addUser(userId);
                    break;
                }

                case UserStatus.StatusUpdateIdle: {
                    removeUser(userId);
                    break;
                }

                case UserStatus.None:
                    break;
                default:
                    Log.warn(
                        "Unknown OnMachineChangeStatus status type",
                        statusType,
                    );
            }
        },
        [broadcastStatus, isWeb, user.accuRxUserId],
    );

    useSubscribeSignalREvent({
        type: SignalRSubscriptionEvent.OnMachineChangeStatus,
        eventFilter: ({ organisationId }) => organisationId === orgId,
        onEvent: ({ status, fromUser }) => {
            if (!fromUser) return;
            updateUserStatus(status, fromUser.accuRxId);
        },
    });

    return usersOnlineSet;
};
