import { Ds, Tokens } from "@accurx/design";
import { SplitButtonGroup } from "@accurx/navigation";
import styled from "styled-components";

export const StyledPrimaryNavigation = styled.nav`
    background-color: ${Tokens.COLOURS.primary.green[100]};
    color: ${Tokens.COLOURS.greyscale.white};
    padding: ${Tokens.SIZES[1]};
`;

export const StyledThreeColumnGrid = styled.div`
    gap: ${Tokens.SIZES[1]};

    @media (max-width: ${Tokens.BREAKPOINTS.lg}) {
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
    }

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
`;
export const StyledColumn = styled(Ds.Flex).attrs({
    alignItems: "center",
    gap: "1",
})``;
export const StyledColOne = styled(StyledColumn)``;

export const StyledColTwo = styled(StyledColumn).attrs({
    justifyContent: "center",
})``;

export const StyledColThree = styled(StyledColumn).attrs({
    justifyContent: "flex-end",
})`
    min-width: 0;
`;

export const StyledNavLinkList = styled.ul`
    align-items: center;
    display: flex;
    gap: ${Tokens.SIZES[0.5]};
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
`;

export const StyledSplitWorkspaceButton = styled(SplitButtonGroup)`
    white-space: nowrap;
    min-width: 0;
    max-width: 100%;
`;
