import { useEffect, useMemo } from "react";

import {
    Conversation,
    PatientTriageRequestItem,
} from "@accurx/concierge/types";
import { Ds, Feedback, VisuallyHidden } from "@accurx/design";
import { SkeletonLoader } from "domains/inbox/components/SkeletonLoader/SkeletonLoader";
import { useSearchForPatientInEmrMutation } from "domains/inbox/hooks/mutations/useSearchForPatientInEmrMutation";
import find from "lodash/find";

import { SuggestedMatchedPatientList } from "./SuggestedMatchedPatientList";
import { StyledCard } from "./SuggestedPatientMatches.styles";

export const MUTATION_KEY = ["SearchForSuggestedPatientsInEmr"];

type Props = {
    conversation: Conversation;
    onMatchConfirmSuccess?: () => void;
    /**
     * Supported search modes for certain EMRs:
     * EMIS - patient date of birth
     * SystmOne/Vision - patient first and last name
     */
    searchMode: "dateOfBirth" | "patientName";
};

export const SuggestedPatientMatches = ({
    conversation,
    onMatchConfirmSuccess,
    searchMode,
}: Props) => {
    const { mutate, status, data } = useSearchForPatientInEmrMutation({
        mutationKey: MUTATION_KEY,
    });
    const patientTriageRequestItem = conversation.items.find(
        (item): item is PatientTriageRequestItem =>
            item.contentType === "PatientTriageRequestNote",
    );

    const searchDetails = useMemo(() => {
        if (!patientTriageRequestItem) return;

        const triageRequestResponses =
            patientTriageRequestItem.sections.flatMap(
                (section) => section.responses,
            );

        if (searchMode === "dateOfBirth") {
            const searchTerm = find(
                triageRequestResponses,
                (response) => response.question === "DOB",
            )?.answer;

            return { searchTerm };
        } else {
            const name = find(
                triageRequestResponses,
                (response) => response.question === "Name",
            )?.answer;

            if (name) {
                const [lastName, firstName] = name.split(", ");
                return { firstName, lastName };
            }
        }
    }, [patientTriageRequestItem, searchMode]);

    useEffect(() => {
        if (searchDetails) mutate(searchDetails);
    }, [mutate, searchDetails]);

    const headerText =
        data?.results.length === 1 ? "Suggested patient" : "Suggested patients";

    const patients = data?.results ?? [];

    if (!patientTriageRequestItem) return null;
    return (
        <>
            <Ds.Box as="span" mb="1">
                <Ds.Text
                    id="suggested-patients-list"
                    as="h3"
                    size="small"
                    weight="bold"
                    mb="0"
                >
                    {headerText}
                </Ds.Text>
            </Ds.Box>
            {status === "loading" && <LoadingCard />}
            {status === "error" && (
                <Feedback colour="secondary">
                    <Ds.Text mb="0" size="small">
                        Couldn't load suggestions. Try searching for a patient.
                    </Ds.Text>
                </Feedback>
            )}
            {status === "success" &&
                (patients.length === 0 ? (
                    <NoResults searchMode={searchMode} />
                ) : (
                    <SuggestedMatchedPatientList
                        conversation={conversation}
                        patients={patients}
                        props={{
                            "aria-labelledby": "suggested-patients-list",
                        }}
                        onMatchConfirmSuccess={onMatchConfirmSuccess}
                    />
                ))}
        </>
    );
};

function NoResults({ searchMode }: Pick<Props, "searchMode">) {
    const getText = (): string | null => {
        switch (searchMode) {
            case "dateOfBirth":
                return "No date of birth matches. Try searching by name or NHS number.";
            case "patientName":
                return "No name matches. Try searching by NHS number.";
            default:
                return null;
        }
    };
    const text = getText();

    if (!text) return null;
    return (
        <Feedback colour="secondary">
            <Ds.Text mb="0" size="small">
                {text}
            </Ds.Text>
        </Feedback>
    );
}

function LoadingCard() {
    return (
        <StyledCard p="1.5">
            <VisuallyHidden>Loading suggestions...</VisuallyHidden>
            <Ds.Flex flexDirection="column" gap="0.25">
                <Ds.Box py="6px">
                    <SkeletonLoader height="12px" width="75%" />
                </Ds.Box>
                <Ds.Box py="6px">
                    <SkeletonLoader height="12px" width="100%" />
                </Ds.Box>
            </Ds.Flex>
        </StyledCard>
    );
}
