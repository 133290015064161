import { Template } from "@accurx/message-templates";
import { TemplateItem } from "domains/message-component/types";
import filter from "lodash/filter";
import lowerCase from "lodash/lowerCase";
import sortBy from "lodash/sortBy";

import { TemplatesGroupedByOwner } from "../types/TemplateItem";

/**
 * Sort templates by their type first (Message > Questionnaires) and then alphabetically by title
 */
const sortTemplateItems = (items: TemplateItem[]) => {
    const messageTemplates = filter(
        items,
        (item) => item.type === "MessageTemplate",
    );
    const questionnaireTemplates = filter(
        items,
        (item) => item.type === "QuestionnaireTemplate",
    );

    return [
        ...sortBy(messageTemplates, (item) => lowerCase(item.value.title)),
        ...sortBy(questionnaireTemplates, (item) =>
            lowerCase(item.value.title),
        ),
    ];
};

/**
 * When displaying templates they are grouped with two levels of nested
 * categories. Firstly all templates are categorised by owner. The owner can be
 * us (Accurx), the user personally or the user's organisation. Each of those
 * has a top level group. Within the owner categories we group templates once
 * more by their heading.
 *
 * E.g.
 * - User
 *   - Admin
 *     - Template 1
 *     - Template 2
 *   - Custom
 *     - Template 3
 */
export const groupTemplatesByOwnerAndHeading = (
    items: TemplateItem[],
): TemplatesGroupedByOwner => {
    // Create a record to store the categories and templates per owner
    const owners: Record<Template["owner"], Record<string, TemplateItem[]>> = {
        User: {},
        Accurx: {},
        Organisation: {},
    };

    // For each template add it to the correct owner and correct category
    items.forEach((item) => {
        // No category is represented by an empty string
        const category = item.value.heading ?? "";

        // Id the cateogry doesn't aready exist add it to the owner
        if (!(category in owners[item.value.owner])) {
            owners[item.value.owner][category] = [];
        }

        // Push the template into its category
        owners[item.value.owner][category].push(item);
    });

    // Make sure that categories are sorted alphabetically within the owner
    const userCategories = sortBy(Object.keys(owners.User), lowerCase);
    const accurxCategories = sortBy(Object.keys(owners.Accurx), lowerCase);
    const organisationCategories = sortBy(
        Object.keys(owners.Organisation),
        lowerCase,
    );

    return {
        User: userCategories.map((category) => ({
            title: category,
            items: sortTemplateItems(owners.User[category]),
        })),
        Accurx: accurxCategories.map((category) => ({
            title: category,
            items: sortTemplateItems(owners.Accurx[category]),
        })),
        Organisation: organisationCategories.map((category) => ({
            title: category,
            items: sortTemplateItems(owners.Organisation[category]),
        })),
    };
};
