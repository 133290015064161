import { useCallback, useEffect, useState } from "react";

import { getEmbedMode, useSignalRClientOn } from "@accurx/native";

import { ConnectionState } from "../types";
import { getSubscriptionNames } from "./useOnNativeSignalRSubscriptionEvent";

/**
 * When the desktop toolbar disconnects from SignalR, it clears the registered subscriptions
 * for SignalR messages via the native bridge. In order to continue to receiving the
 * subscription data, we must re-register the subscriptions we had previously set up.
 */
export const useResubscribeToNativeSubscriptions = (
    connectionState: ConnectionState,
): void => {
    const { embedMode } = getEmbedMode();
    const nativeSignalRClientOn = useSignalRClientOn();

    /**
     * We don't want to resubscribe to SignalR when we're first connecting to SignalR. This is
     * because registering the subscriptions happens when we define our subscription events in
     * their respective usages.
     */
    const [isFirstConnection, setIsFirstConnection] = useState(true);

    const resubscribeToSubscriptions = useCallback(() => {
        const subscriptions = getSubscriptionNames();
        subscriptions.forEach((methodName) => {
            void nativeSignalRClientOn({ methodName });
        });
    }, [nativeSignalRClientOn]);

    useEffect(() => {
        if (embedMode !== "Desktop" || connectionState !== "Connected") return;

        if (isFirstConnection) {
            setIsFirstConnection(false);
            return;
        }

        resubscribeToSubscriptions();
        // we don't want isFirstConnection to be a dependency, because it will
        // cause this useEffect to re-run and re-subscribe to the events on first
        // connection
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connectionState, resubscribeToSubscriptions]);
};
