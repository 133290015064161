import { ComponentPropsWithoutRef } from "react";

import * as UI from "@accurx/design";

import { StyledSkeleton } from "./SkeletonLoader.styles";

export type SkeletonLoaderProps = {
    width?: string;
    height?: string;
    backgroundColor?: string;
    shimmerRGBColor?: [number, number, number];
};

export const SkeletonLoader = ({
    backgroundColor = UI.Tokens.COLOURS.greyscale.cloud,
    shimmerRGBColor = [0, 0, 0],
    height = "14px",
    width = "100%",
    ...props
}: SkeletonLoaderProps & ComponentPropsWithoutRef<"div">) => {
    return (
        <StyledSkeleton
            $width={width}
            $height={height}
            $backgroundColor={backgroundColor}
            $shimmerRGBColor={shimmerRGBColor}
            {...props}
        />
    );
};
