import { useEffect, useState } from "react";

import { useConversation } from "@accurx/concierge/hooks/data/useConversation";
import { AssigneeSummary } from "@accurx/concierge/types";
import {
    useInboxLink,
    useSearchParams,
    useViewportSize,
} from "domains/inbox/hooks/util";
import { useHistory, useLocation } from "react-router";

import { useLayout } from "../Layout/context";
import { ConversationView } from "./ConversationView";
import {
    ConversationAssignedOverlay,
    ConversationViewEmptyState,
} from "./components";
import { isDisabledForPath } from "./components/ConversationAssignedOverlay/ConversationAssignedOverlay.utils";

type Assignees = {
    newAssignee: AssigneeSummary;
    previousAssignee: AssigneeSummary;
};

/**
 * <ConversationViewPage />
 *
 * This is the public component. It loads the current conversation and renders
 * it. Most of that it defers resonsibility for to its children. The things it
 * takes personal responsibility for are:
 * - Knowing what the current conversation ID is based on the URL
 * - If there's no current conversation rendering an empty state
 * - Making sure the layout looks correct at different breakpoints
 * - Making sure the correct conversation component is used
 */
export const ConversationViewPage = ({
    showEmptyState = true,
}: {
    showEmptyState?: boolean;
}) => {
    const { conversationId = "" } = useSearchParams();
    const conversation = useConversation({ conversationId });
    const { rightPane } = useLayout();
    const viewportSize = useViewportSize();
    const isMobile = viewportSize === "xs" || viewportSize === "sm";
    const history = useHistory();
    const location = useLocation();
    const link = useInboxLink();

    const [showOverride, setShowOverride] = useState(false);
    const [assignees, setAssignees] = useState<Assignees | null>(null);

    const resetOverlay = () => {
        setShowOverride(false);
        setAssignees(null);
    };

    useEffect(() => {
        rightPane.setIsOpen(Boolean(conversationId));
    }, [conversationId, rightPane]);

    useEffect(() => {
        resetOverlay();
    }, [location]);

    if (!conversationId) {
        return !isMobile && showEmptyState ? (
            <ConversationViewEmptyState />
        ) : (
            <></>
        );
    }

    const handleCloseConversation = () => {
        history.push(
            link.update({
                conversationId: null,
            }),
        );
    };

    const handleAssignedSuccess = ({
        newAssignee,
        previousAssignee,
    }: Assignees): void => {
        if (isDisabledForPath(history.location.pathname)) {
            return;
        }

        const getAssigneeId = (assignee: AssigneeSummary): string | null =>
            assignee.type === "None" ? null : assignee.id;

        if (getAssigneeId(newAssignee) === getAssigneeId(previousAssignee)) {
            return;
        }

        setShowOverride(true);
        setAssignees({ newAssignee, previousAssignee });
    };

    return showOverride && conversation && assignees ? (
        <ConversationAssignedOverlay
            {...assignees}
            conversation={conversation}
            onUndoSuccess={resetOverlay}
        />
    ) : (
        <ConversationView
            conversationId={conversationId}
            onClickBack={handleCloseConversation}
            onMarkAsUnreadSuccess={handleCloseConversation}
            onAssignedSuccess={handleAssignedSuccess}
        />
    );
};
