import { useEffect, useState } from "react";

import { PatientExternalId } from "@accurx/concierge/types";
import { PatientDemographics, useNativeSubscription } from "@accurx/native";
import { mapDemographicsToCurrentPatient } from "domains/compose/utils/mapDemographicsToCurrentPatient";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";

import { CurrentPatient } from "../../context/CurrentPatientProvider.types";
import { ChangePatientModal } from "../ChangePatientModal/ChangePatientModal";

// TODO: this is copy/pasted from inbox's useGetCurrentPatient, we might want to
// export this util tool dfrom native domain potentially
const isSamePatient = (
    patientAExternalIds?: PatientExternalId[],
    patientBExternalIds?: PatientExternalId[],
): boolean => {
    if (!patientAExternalIds || !patientBExternalIds) return false;

    /**
     * We compare external ids arrays here because:
     * 1. we don't have internal ids in PatientDemographics,
     *    we'd have to fetch them via an API call if we needed those
     * 2. both patients come from the medical record integration, so data should
     *    be consistent/the same
     */
    return isEqual(
        sortBy(patientAExternalIds, ["type", "value"]),
        sortBy(patientBExternalIds, ["type", "value"]),
    );
};

/**
 * Component that checks for medical record patient changes
 * and either updates the compose patient in the background,
 * or shows a modal to get confirmation from the user
 */
export const MedicalRecordPatientUpdater = ({
    currentPatient,
    patientFeedPatient,
    medicalRecordSystem,
    setCurrentPatient,
    medicalRecordSystemStatus,
}: {
    currentPatient: CurrentPatient | null;
    setCurrentPatient: (patient: CurrentPatient | null) => void;
    patientFeedPatient: PatientDemographics | null;
    medicalRecordSystem: string;
    medicalRecordSystemStatus: "Connected" | "Disconnected";
}) => {
    const { data: windowVisibility } = useNativeSubscription(
        "SubscribeWindowOpenStatus",
    );
    const isComposeWindowClosed = windowVisibility?.open === false;

    const hasCurrentPatientBeenSet = !!currentPatient;
    const isCurrentPatientSameAsPatientFeedPatient = isSamePatient(
        currentPatient?.patient.externalIds,
        patientFeedPatient?.patientExternalIdentityDto.patientExternalIds,
    );

    const [showConfirmModal, setShowConfirmModal] = useState(false);

    useEffect(() => {
        // Update in the background when window is not visible
        if (isComposeWindowClosed || !hasCurrentPatientBeenSet) {
            setCurrentPatient(
                mapDemographicsToCurrentPatient(patientFeedPatient),
            );
            // Otherwise, ask user for confirmation before updating current patient
            // (only if there is a patient change!)
        } else if (
            !isCurrentPatientSameAsPatientFeedPatient &&
            !!patientFeedPatient
        ) {
            setShowConfirmModal(true);
        }
    }, [
        patientFeedPatient,
        setCurrentPatient,
        isComposeWindowClosed,
        isCurrentPatientSameAsPatientFeedPatient,
        hasCurrentPatientBeenSet,
        medicalRecordSystemStatus,
    ]);

    return (
        <ChangePatientModal
            isOpen={showConfirmModal}
            onClose={() => setShowConfirmModal(false)}
            onConfirm={() => {
                if (patientFeedPatient) {
                    setCurrentPatient(
                        mapDemographicsToCurrentPatient(patientFeedPatient),
                    );
                }
                setShowConfirmModal(false);
            }}
            emrName={medicalRecordSystem}
        />
    );
};
