import React from "react";

import { useCurrentUser } from "@accurx/auth";
import { ButtonLink, Ds } from "@accurx/design";
import { useGenerateTwoFactorToken } from "@accurx/workspace-management";
import styled from "styled-components";

import { InnerStackPanel, OuterStackPanel } from "../LogIn/LoginStackPanel";

const StyledCenteredButtonLink = styled(ButtonLink)`
    width: 100%;
`;

const StyledContainer = styled(Ds.Flex).attrs({
    alignItems: "center",
    justifyContent: "center",
})`
    width: 100%;
    height: 100%;
`;

const StyledContent = styled(Ds.Flex).attrs({
    flexDirection: "column",
    alignItems: "center",
    gap: "1",
})`
    text-align: center;
`;

export const DesktopLaunch = (): JSX.Element => {
    const { user } = useCurrentUser();

    const { status, data } = useGenerateTwoFactorToken({ email: user.email });

    return (
        <OuterStackPanel>
            <StyledContainer>
                <StyledContent>
                    {status === "success" && (
                        <>
                            <Ds.Text size="medium" as="h1" weight="bold">
                                You are now logged in
                            </Ds.Text>
                            <InnerStackPanel horizontalContentAlignment="stretch">
                                <StyledCenteredButtonLink
                                    href={data.launchUrl}
                                    text="Open Accurx desktop toolbar"
                                />
                            </InnerStackPanel>
                        </>
                    )}

                    {status === "error" && (
                        <InnerStackPanel horizontalContentAlignment="stretch">
                            <Ds.Text size="medium" weight="bold" as="h1">
                                Something went wrong
                            </Ds.Text>
                        </InnerStackPanel>
                    )}

                    {status === "loading" && <Ds.Spinner />}
                </StyledContent>
            </StyledContainer>
        </OuterStackPanel>
    );
};
