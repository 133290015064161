import { Checkbox, Ds, Text } from "@accurx/design";
import { SharedUrls } from "@accurx/shared";

/** Keep in sync with the content of the children */
export const TERMS_AND_CONDITIONS_TEXT =
    "The use of Accurx is subject to our Terms and Conditions. I have read and agree to these Terms and Conditions.";

type TermsAndConditionsCheckboxProps = {
    id?: string;
    checked: boolean;
    onCheckChange: (checked: boolean) => void;
};

export const TermsAndConditionsCheckbox = ({
    checked,
    id,
    onCheckChange,
}: TermsAndConditionsCheckboxProps) => {
    return (
        <Checkbox
            id={id ?? "terms-and-conditions"}
            theme="bordered"
            checked={checked}
            onCheckChange={onCheckChange}
            text={TERMS_AND_CONDITIONS_TEXT}
        >
            <Text skinny as="span">
                {"The use of Accurx is subject to our "}
                <Ds.Link href={SharedUrls.TermsAndConditions} target="_blank">
                    Terms and Conditions
                </Ds.Link>
                .
                <br />
                {"I have read and agree to these Terms and Conditions."}
            </Text>
        </Checkbox>
    );
};
