import { PopoverContent, PopoverItemButton, Tokens } from "@accurx/design";
import { StyledItemLink } from "@accurx/design/dist/components/Popover/Popover.styles";
import styled, { css } from "styled-components";

export const StyledPopoverContent = styled(PopoverContent)`
    max-height: 500px;
    width: 248px;
    border-radius: ${Tokens.BORDERS.radius};
`;

const popoverItemOverrides = css`
    gap: ${Tokens.SIZES[1]};
    margin: 0;
    text-align: left;

    & p {
        word-break: break-word;
    }

    & svg {
        flex-shrink: 0;
    }

    // Cancelling PopoverItemButton style for this selector because it applies to nested elements
    & * + * {
        margin-left: 0;
    }
`;

export const StyledPopoverItem = styled(PopoverItemButton)`
    ${popoverItemOverrides}
`;

export const StyledPopoverItemLink = styled(StyledItemLink)`
    ${popoverItemOverrides}

    &:hover {
        text-decoration: none;
    }
`;

export const StyledPopoverItemWithSpaceBetween = styled(StyledPopoverItem)`
    justify-content: space-between;
`;

export const StyledDivider = styled.hr`
    margin: ${Tokens.SIZES[1]} -${Tokens.SIZES[1]};
`;

export const StyledListHeader = styled.h2`
    padding: ${Tokens.SIZES[1]};
    color: ${Tokens.COLOURS.greyscale.metal};
    font-size: 1rem;
`;
