import React, { useState } from "react";

import {
    FeatureName,
    Organisation,
    isFeatureEnabledForWorkspace,
    useCurrentUser,
} from "@accurx/auth";
import {
    Ds,
    Popover,
    PopoverContent,
    PopoverItem,
    PopoverTrigger,
    Text,
} from "@accurx/design";
import {
    ArchivedWorkspaceWarningModal,
    MyWorkspaceWarningModal,
    SelectNhsPharmacyModal,
    isArchivedWorkspace,
    isMyWorkspace,
    isNhsPharmacy,
    isPharmacy,
    isUserOnlyInDefaultWorkspaceWithinOrg,
    supportsMultipleWorkspaces,
} from "@accurx/workspace-management";
import { Link, generatePath, useHistory } from "react-router-dom";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { ROUTES, ROUTES_WORKSPACE } from "shared/Routes";

import { WorkspaceCard } from "../../WorkspaceCard/WorkspaceCard";

export const WorkspaceMemberCard = ({
    workspace,
}: {
    workspace: Organisation;
}) => {
    const history = useHistory();

    const { user } = useCurrentUser();

    const { selectedOrgId, setSelectedOrgId } = useSelectedOrganisation();

    const [showPharmacyModal, setShowPharmacyModal] = useState(false);
    const [showMyWorkspaceModal, setShowMyWorkspaceModal] = useState(false);
    const [showArchivedWorkspaceModal, setShowArchivedWorkspaceModal] =
        useState(false);

    const defaultWorkspaceName = supportsMultipleWorkspaces(workspace)
        ? workspace.organisationNodeName
        : undefined;

    const userWorkspaceRole = workspace.settings.isAdminUser
        ? "Admin"
        : "Member";

    const isCurrentWorkspace = (orgId: number) => {
        return orgId === selectedOrgId;
    };

    const workspaceName = isMyWorkspace(workspace)
        ? "My Workspace"
        : workspace.organisationName;

    const selectWorkspace = (selectedWorkspace: Organisation) => {
        setSelectedOrgId(selectedWorkspace.orgId);
        history.push(ROUTES.home);
    };

    const handleSwitchWorkspace = (
        event:
            | React.MouseEvent<HTMLAnchorElement>
            | React.MouseEvent<HTMLButtonElement>,
        selectedWorkspace: Organisation,
    ) => {
        event.preventDefault();
        if (
            isNhsPharmacy(workspace) &&
            user.organisations.filter(isPharmacy).length > 0
        ) {
            setShowPharmacyModal(true);
        } else if (
            isFeatureEnabledForWorkspace(
                FeatureName.DissuadeFromUsingMyWorkspace,
                workspace,
            ) &&
            isMyWorkspace(workspace) &&
            !isUserOnlyInDefaultWorkspaceWithinOrg({
                user,
                organisation: workspace,
            })
        ) {
            setShowMyWorkspaceModal(true);
        } else if (isArchivedWorkspace(workspace)) {
            setShowArchivedWorkspaceModal(true);
        } else {
            selectWorkspace(selectedWorkspace);
        }
    };

    return (
        <>
            <WorkspaceCard
                workspaceName={workspaceName}
                defaultWorkspaceName={defaultWorkspaceName}
            >
                {!isArchivedWorkspace(workspace) && (
                    <>
                        <Text skinny>{userWorkspaceRole}</Text>

                        <Popover>
                            <PopoverTrigger asChild>
                                <Ds.Button
                                    aria-label="Actions"
                                    appearance="secondary"
                                    data-testid={`actions-workspace-${workspace.orgId}`}
                                >
                                    <Ds.Icon
                                        name="More"
                                        size="small"
                                        color="blue"
                                        appearance="solid"
                                    />
                                </Ds.Button>
                            </PopoverTrigger>
                            <PopoverContent align="center" side="bottom">
                                {!isCurrentWorkspace(workspace.orgId) && (
                                    <PopoverItem>
                                        <Link
                                            to={ROUTES.home}
                                            onClick={(e) =>
                                                handleSwitchWorkspace(
                                                    e,
                                                    workspace,
                                                )
                                            }
                                        >
                                            <Text skinny>
                                                Switch to workspace
                                            </Text>
                                        </Link>
                                    </PopoverItem>
                                )}
                                <PopoverItem>
                                    <Link
                                        to={generatePath(
                                            ROUTES_WORKSPACE.workspaceGeneralSettings,
                                            {
                                                workspaceId: workspace.orgId,
                                            },
                                        )}
                                    >
                                        <Text skinny>Workspace settings</Text>
                                    </Link>
                                </PopoverItem>
                            </PopoverContent>
                        </Popover>
                    </>
                )}
                {isArchivedWorkspace(workspace) &&
                    !isCurrentWorkspace(workspace.orgId) && (
                        <Ds.Button
                            appearance="secondary"
                            onClick={(e) => {
                                handleSwitchWorkspace(e, workspace);
                            }}
                            aria-label={`Switch to ${workspace.organisationName} workspace`}
                            data-testid={`switch-to-workspace-${workspace.orgId}`}
                        >
                            Switch to workspace
                        </Ds.Button>
                    )}
            </WorkspaceCard>
            {showPharmacyModal && (
                <SelectNhsPharmacyModal
                    handleClose={() => setShowPharmacyModal(false)}
                    handleSelectWorkspace={selectWorkspace}
                />
            )}
            {showMyWorkspaceModal && (
                <MyWorkspaceWarningModal
                    handleClose={() => setShowMyWorkspaceModal(false)}
                    handleSelectMyWorkspace={() => {
                        setShowMyWorkspaceModal(false);
                        selectWorkspace(workspace);
                    }}
                />
            )}
            {showArchivedWorkspaceModal && (
                <ArchivedWorkspaceWarningModal
                    handleClose={() => setShowArchivedWorkspaceModal(false)}
                    handleSelectArchivedWorkspace={() => {
                        setShowArchivedWorkspaceModal(false);
                        selectWorkspace(workspace);
                    }}
                />
            )}
        </>
    );
};
