import React, { Fragment, useState } from "react";

import { QuestionType } from "@accurx/api/florey-builder";
import {
    Button,
    FormFieldWrapper,
    Option as SelectOption,
    StackPanel,
    Text,
} from "@accurx/design";
import { Editor, EditorProvider } from "@accurx/markdown-editor";

import { FlemingAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { StyledCardLineBreak } from "app/sharedComponents/cardWithTitle/CardWithTitle.styles";
import { StyledTextareaAutosize } from "app/sharedComponents/textAreaAutosize/TextAreaAutosize.styles";

import { useFloreyBuilder } from "../FloreyBuilder.context";
import { useQuestionFeatures } from "../FloreyBuilder.hooks";
import {
    getAnswersList,
    mapQuestionsToList,
    mapSelectedAnswer,
    mapSelectedQuestion,
} from "../FloreyBuilder.utils";
import { Actions } from "../constants";
import { Action } from "../types";
import { ClientSideQuestion } from "../types/context.types";
import {
    BranchPosition,
    ErrorIds,
    Errors,
    QuestionLevel,
    QuestionnaireId,
} from "../types/data.types";
import { StyledGreyBoxWithIndent } from "./PageContainer.styles";
import { StyledSpacing } from "./Question.styles";
import {
    BranchingInformation,
    MULTIPLE_CHOICE_MANY_ANSWERS_MAX_RECOMMENDED_OPTIONS,
    MultipleChoiceManyAnswersRecommendedLimit,
} from "./QuestionFeedbacks";
import { QuestionImageUploader } from "./QuestionImageUploader/QuestionImageUploader";
import { QuestionMultipleChoiceSwitch } from "./QuestionMultipleChoiceSwitch";
import QuestionOptionEdit from "./QuestionOptionEdit";
import { QuestionSelectFormField } from "./QuestionSelectFormField/QuestionSelectFormField";

type QuestionEditProps = {
    question: ClientSideQuestion;
    questionIndex: number;
    questionnaireId: QuestionnaireId;
    errors: Errors;
    questionLevel: QuestionLevel;
    branchPosition?: BranchPosition;
};

const QuestionHelperExplanatoryText =
    "Patients will see this additional explanation but it won't be saved to their record.";
const QuestionTitleExplanatoryText =
    "Patients will see each question on a separate page of the questionnaire as they are completing it.";
const RecordTextExplanatoryText =
    "Clinicians will see this question displayed in the inbox as the value specified here. If not specified, they will see the question title.";
const InfoExplanatoryText =
    "Patients will see this information heading and content but it won't be saved to their record.";

const handleOnChangeQuestionText = (
    text: string,
    questionIndex: number,
    fieldKey: "questionTitle" | "helperText" | "recordText",
    fieldSetter: React.Dispatch<React.SetStateAction<string>>,
    dispatch: React.Dispatch<Action>,
    questionLevel: QuestionLevel,
    branchPosition?: BranchPosition,
) => {
    fieldSetter(text);
    dispatch({
        type: Actions.UPDATE_QUESTION_FIELD,
        payload: {
            questionIndex,
            fieldKey,
            fieldValue: text,
            questionLevel,
            branchPosition,
        },
    });
};

const QuestionEditMultipleChoice = ({
    question,
    questionIndex,
    questionnaireId,
    errors,
    questionLevel,
    branchPosition,
}: QuestionEditProps): JSX.Element => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const { state, dispatch } = useFloreyBuilder();
    const { isQuestionImageEnabled, isRecordTextEnabled } =
        useQuestionFeatures();
    const [questionTitle, setQuestionTitle] = useState(
        question.questionTitle ?? "",
    );
    const [recordText, setRecordText] = useState(question.recordText ?? "");
    const [helperText, setHelperText] = useState(question.helperText ?? "");
    const options = question.options;

    const handleAddOption = () => {
        FlemingAnalyticsTracker.trackQuestionnaireAnswerAdd({
            ...analyticsLoggedInProps,
            questionnaireId: questionnaireId ?? null,
            questionType: "MultipleChoice",
        });
        dispatch({
            type: Actions.ADD_QUESTION_MULTIPLE_CHOICE_OPTION,
            payload: { questionIndex, questionLevel, branchPosition },
        });
    };

    const handleRemoveOption = (
        selectedOptionClientId: string | undefined,
        questionIndex: number,
    ) => {
        FlemingAnalyticsTracker.trackQuestionnaireAnswerRemove({
            ...analyticsLoggedInProps,
            questionnaireId: questionnaireId ?? null,
            questionType: "MultipleChoice",
        });
        dispatch({
            type: Actions.REMOVE_QUESTION_MULTIPLE_CHOICE_OPTION,
            payload: {
                questionIndex,
                selectedOptionClientId,
                questionLevel,
                branchPosition,
            },
        });
    };

    const handleSelectSnomed = (
        selected: SelectOption,
        questionIndex: number,
        optionIndex: number,
    ): void => {
        FlemingAnalyticsTracker.trackQuestionnaireSnomedOption({
            ...analyticsLoggedInProps,
            pageOrigin: "QuestionnaireNameAndQuestions",
            questionnaireId: questionnaireId ?? null,
        });
        dispatch({
            type: Actions.UPDATE_QUESTION_MULTIPLE_CHOICE_OPTION_CODE,
            payload: {
                answerCode: { conceptId: selected.value, term: selected.label },
                questionIndex,
                optionIndex,
                questionLevel,
                branchPosition,
            },
        });
    };

    const handleOnChangeOptionTextArea = (
        e: React.ChangeEvent<HTMLTextAreaElement>,
        questionIndex: number,
        optionIndex: number,
        fieldKey: "text", // allows support for extending to additional field keys
        fieldSetter: React.Dispatch<string | undefined>,
        questionLevel: QuestionLevel,
        branchPosition?: BranchPosition,
    ) => {
        fieldSetter(e.target.value);
        dispatch({
            type: Actions.UPDATE_QUESTION_MULTIPLE_CHOICE_OPTION_FIELD,
            payload: {
                fieldKey,
                fieldValue: e.target.value,
                questionIndex,
                optionIndex,
                questionLevel,
                branchPosition,
            },
        });
    };

    return (
        <>
            <FormFieldWrapper
                label="Question"
                labelProps={{ htmlFor: ErrorIds.questionTitleError }}
                errors={
                    errors?.questionTitle?.errorId
                        ? [errors?.questionTitle?.errorMessage]
                        : []
                }
            >
                <Text skinny>{QuestionTitleExplanatoryText}</Text>
                <StyledTextareaAutosize
                    id={ErrorIds.questionTitleError}
                    disabled={false}
                    value={questionTitle}
                    onChange={(e) =>
                        handleOnChangeQuestionText(
                            e.currentTarget.value,
                            questionIndex,
                            "questionTitle",
                            setQuestionTitle,
                            dispatch,
                            questionLevel,
                            branchPosition,
                        )
                    }
                />
            </FormFieldWrapper>
            <FormFieldWrapper
                label="Description (optional)"
                labelProps={{ htmlFor: ErrorIds.questionDescriptionError }}
                errors={
                    errors?.helperText?.errorId
                        ? [errors?.helperText?.errorMessage]
                        : []
                }
            >
                <Text skinny>{QuestionHelperExplanatoryText}</Text>
                <StyledTextareaAutosize
                    id={ErrorIds.questionDescriptionError}
                    disabled={false}
                    value={helperText}
                    onChange={(e) =>
                        handleOnChangeQuestionText(
                            e.currentTarget.value,
                            questionIndex,
                            "helperText",
                            setHelperText,
                            dispatch,
                            questionLevel,
                            branchPosition,
                        )
                    }
                />
            </FormFieldWrapper>
            {isRecordTextEnabled && (
                <FormFieldWrapper
                    label="Display in inbox as (optional)"
                    labelProps={{ htmlFor: "recordText" }}
                >
                    <Text skinny>{RecordTextExplanatoryText}</Text>
                    <StyledTextareaAutosize
                        id={"recordText"}
                        disabled={false}
                        value={recordText}
                        onChange={(e) =>
                            handleOnChangeQuestionText(
                                e.currentTarget.value,
                                questionIndex,
                                "recordText",
                                setRecordText,
                                dispatch,
                                questionLevel,
                                branchPosition,
                            )
                        }
                    />
                </FormFieldWrapper>
            )}
            <StyledSpacing />
            {isQuestionImageEnabled && (
                <>
                    <QuestionImageUploader
                        question={question}
                        questionIndex={questionIndex}
                        questionLevel={questionLevel}
                        branchPosition={branchPosition}
                        errors={errors}
                    />
                    <StyledSpacing />
                </>
            )}
            <StyledCardLineBreak />
            <QuestionMultipleChoiceSwitch
                allowMultipleAnswers={question.allowMultipleAnswers ?? false}
                questionIndex={questionIndex}
                questionLevel={questionLevel}
                branchPosition={branchPosition}
            />
            {options &&
                options.map(({ answerCode, text, clientId }, index) => {
                    const previouslySelectedCode =
                        answerCode && answerCode.term && answerCode.conceptId
                            ? {
                                  label: answerCode.term,
                                  value: answerCode.conceptId,
                              }
                            : undefined;

                    const showRemoveOptionButton = options.length > 1;
                    return (
                        <Fragment key={clientId}>
                            <StyledCardLineBreak />
                            <QuestionOptionEdit
                                questionnaireId={questionnaireId}
                                snomedOptions={state.snomedCodes}
                                questionIndex={questionIndex}
                                optionIndex={index}
                                handleOptionFieldUpdate={
                                    handleOnChangeOptionTextArea
                                }
                                key={clientId}
                                allowMultipleAnswers={
                                    question.allowMultipleAnswers ?? false
                                }
                                optionText={text}
                                optionTextError={null} // TODO: add optionTextError on saving
                                selectedSnomed={previouslySelectedCode}
                                handleSelectSnomed={(
                                    selected: SelectOption | SelectOption[],
                                ) =>
                                    handleSelectSnomed(
                                        (selected ||
                                            previouslySelectedCode) as SelectOption,
                                        questionIndex,
                                        index,
                                    )
                                }
                                questionLevel={questionLevel}
                                branchPosition={branchPosition}
                            />
                            {showRemoveOptionButton && (
                                <Button
                                    theme="transparent"
                                    icon={{
                                        name: "Bin",
                                        colour: "red",
                                        title: "Remove",
                                        id: `remove-option-${clientId}`,
                                    }}
                                    text="Remove option"
                                    onClick={() =>
                                        handleRemoveOption(
                                            clientId,
                                            questionIndex,
                                        )
                                    }
                                />
                            )}
                        </Fragment>
                    );
                })}
            <StyledSpacing />
            {options &&
                options.length >
                    MULTIPLE_CHOICE_MANY_ANSWERS_MAX_RECOMMENDED_OPTIONS &&
                question.allowMultipleAnswers && (
                    <>
                        <MultipleChoiceManyAnswersRecommendedLimit />
                        <StyledSpacing />
                    </>
                )}
            <Button
                theme="transparent"
                icon={{
                    name: "Plus",
                    colour: "blue",
                    title: "Add",
                    id: "add-option",
                }}
                text="Add an option"
                onClick={handleAddOption}
            />
        </>
    );
};

const QuestionEditText = ({
    question,
    questionIndex,
    errors,
    questionLevel,
    branchPosition,
}: QuestionEditProps): JSX.Element => {
    const { dispatch } = useFloreyBuilder();
    const { isQuestionImageEnabled, isRecordTextEnabled } =
        useQuestionFeatures();
    const [questionTitle, setQuestionTitle] = useState(
        question.questionTitle ?? "",
    );
    const [recordText, setRecordText] = useState(question.recordText ?? "");
    const [helperText, setHelperText] = useState(question.helperText ?? "");

    return (
        <>
            <FormFieldWrapper
                label="Question"
                labelProps={{ htmlFor: ErrorIds.questionTitleError }}
                errors={
                    errors?.questionTitle?.errorId
                        ? [errors?.questionTitle?.errorMessage]
                        : []
                }
            >
                <Text skinny>{QuestionTitleExplanatoryText}</Text>
                <StyledTextareaAutosize
                    id={ErrorIds.questionTitleError}
                    disabled={false}
                    value={questionTitle}
                    onChange={(e) =>
                        handleOnChangeQuestionText(
                            e.currentTarget.value,
                            questionIndex,
                            "questionTitle",
                            setQuestionTitle,
                            dispatch,
                            questionLevel,
                            branchPosition,
                        )
                    }
                />
            </FormFieldWrapper>
            <FormFieldWrapper
                label="Description (optional)"
                labelProps={{ htmlFor: ErrorIds.questionDescriptionError }}
                errors={
                    errors?.helperText?.errorId
                        ? [errors?.helperText?.errorMessage]
                        : []
                }
            >
                <Text skinny>{QuestionHelperExplanatoryText}</Text>
                <StyledTextareaAutosize
                    id={ErrorIds.questionDescriptionError}
                    disabled={false}
                    value={helperText}
                    onChange={(e) =>
                        handleOnChangeQuestionText(
                            e.currentTarget.value,
                            questionIndex,
                            "helperText",
                            setHelperText,
                            dispatch,
                            questionLevel,
                            branchPosition,
                        )
                    }
                />
            </FormFieldWrapper>
            {isRecordTextEnabled && (
                <FormFieldWrapper
                    label="Display in inbox as (optional)"
                    labelProps={{ htmlFor: "recordText" }}
                >
                    <Text skinny>{RecordTextExplanatoryText}</Text>
                    <StyledTextareaAutosize
                        id={"recordText"}
                        disabled={false}
                        value={recordText}
                        onChange={(e) =>
                            handleOnChangeQuestionText(
                                e.currentTarget.value,
                                questionIndex,
                                "recordText",
                                setRecordText,
                                dispatch,
                                questionLevel,
                                branchPosition,
                            )
                        }
                    />
                </FormFieldWrapper>
            )}
            <StyledSpacing />
            {isQuestionImageEnabled && (
                <>
                    <QuestionImageUploader
                        question={question}
                        questionIndex={questionIndex}
                        questionLevel={questionLevel}
                        branchPosition={branchPosition}
                        errors={errors}
                    />
                    <StyledSpacing />
                </>
            )}
            <StyledCardLineBreak />
            <StackPanel gutter={2}>
                <Text variant={"caption"}>Answer</Text>
                <StyledGreyBoxWithIndent>
                    <Text skinny>
                        Patients will be able to provide an answer up to 600
                        characters long
                    </Text>
                </StyledGreyBoxWithIndent>
            </StackPanel>
        </>
    );
};

const QuestionEditInformation = ({
    question,
    questionIndex,
    errors,
    questionLevel,
    branchPosition,
}: QuestionEditProps): JSX.Element => {
    const { dispatch } = useFloreyBuilder();
    const { isQuestionImageEnabled } = useQuestionFeatures();
    const [questionTitle, setQuestionTitle] = useState(
        question.questionTitle ?? "",
    );
    const [helperText, setHelperText] = useState(question.helperText ?? "");

    /**
     * TODO: Investigate re-renders in CONT-604
     * due to the call to update page-level state
     * the whole tree re-renders and causes this function
     * to be a different value each time, leading to the
     * the "too many re-renders" error.
     * As seen from the dependency array, none of the constituents
     * of this method change too often despite the re-renders
     */
    const onChangeHelperText = React.useCallback(
        (text: string) => {
            handleOnChangeQuestionText(
                text,
                questionIndex,
                "helperText",
                setHelperText,
                dispatch,
                questionLevel,
                branchPosition,
            );
        },
        [branchPosition, dispatch, questionIndex, questionLevel],
    );

    return (
        <>
            <FormFieldWrapper
                label="Heading"
                labelProps={{ htmlFor: ErrorIds.questionTitleError }}
                errors={
                    errors?.questionTitle?.errorId
                        ? [errors?.questionTitle?.errorMessage]
                        : []
                }
            >
                <Text skinny>{InfoExplanatoryText}</Text>
                <StyledTextareaAutosize
                    id={ErrorIds.questionTitleError}
                    disabled={false}
                    value={questionTitle}
                    onChange={(e) =>
                        handleOnChangeQuestionText(
                            e.currentTarget.value,
                            questionIndex,
                            "questionTitle",
                            setQuestionTitle,
                            dispatch,
                            questionLevel,
                            branchPosition,
                        )
                    }
                />
            </FormFieldWrapper>
            <FormFieldWrapper
                label="Content (optional)"
                labelProps={{ htmlFor: ErrorIds.questionDescriptionError }}
                errors={
                    errors?.helperText?.errorId
                        ? [errors?.helperText?.errorMessage]
                        : []
                }
            >
                <EditorProvider content={helperText} label="Content (optional)">
                    <Editor onChange={onChangeHelperText} />
                </EditorProvider>
            </FormFieldWrapper>
            <StyledSpacing />
            {isQuestionImageEnabled && (
                <QuestionImageUploader
                    question={question}
                    questionIndex={questionIndex}
                    questionLevel={questionLevel}
                    branchPosition={branchPosition}
                    errors={errors}
                />
            )}
        </>
    );
};

const QuestionEditBranch = ({
    question,
    questionIndex, // used for z-index
    errors,
    questionLevel,
    branchPosition,
}: QuestionEditProps): JSX.Element => {
    const { dispatch, state } = useFloreyBuilder();

    const questions = state.selectedQuestionnaire?.questions ?? [];

    /**
     * handleSelectBranchConditionQuestion - on change, update state question to use the correct BranchData Condition field
     * @param selectedOption
     * @param clientId
     */
    const handleSelectConditionQuestion = (
        selectedOption: SelectOption,
        clientId: string,
    ): void => {
        dispatch({
            type: Actions.UPDATE_QUESTION_BRANCH_CONDITION_QUESTION,
            payload: {
                clientId,
                fieldKey: "conditionQuestion",
                fieldValue: {
                    answerText: "", // clear selected answer when we change question
                    questionId: parseInt(selectedOption.value, 10),
                    questionText: selectedOption.label,
                },
                questionLevel,
                branchPosition,
            },
        });
    };

    const handleSelectConditionAnswer = (
        selectedOption: SelectOption,
        clientId: string,
        questionText: string,
    ): void => {
        dispatch({
            type: Actions.UPDATE_QUESTION_BRANCH_CONDITION_QUESTION,
            payload: {
                clientId,
                fieldKey: "conditionQuestion",
                fieldValue: {
                    answerText: selectedOption.label,
                    questionId: parseInt(selectedOption.value, 10),
                    questionText: questionText,
                },
                questionLevel,
                branchPosition,
            },
        });
    };

    const selectedConditionQuestion =
        question.branchData?.condition.conditionQuestion;

    const questionsList = mapQuestionsToList(questions);
    const initialError =
        questionsList.length > 0
            ? []
            : [
                  "You need to add a multiple choice question before you can add a branch",
              ];
    const questionError = errors?.conditionQuestion?.errorId
        ? [errors?.conditionQuestion?.errorMessage]
        : [];
    return (
        <>
            <BranchingInformation />
            <QuestionSelectFormField
                label="Question"
                subLabel="Select an existing question of type multiple choice"
                id={ErrorIds.branchConditionQuestion}
                selectedOption={mapSelectedQuestion(selectedConditionQuestion)}
                options={mapQuestionsToList(questions)}
                onChange={(option) =>
                    handleSelectConditionQuestion(option, question.clientId)
                }
                formFieldProps={{
                    errors: [...initialError, ...questionError],
                }}
                selectProps={{
                    zIndex: 1000 - questionIndex, // to ensure that first rendered one has a higher z-index than the next one so there won't be overlapping
                }}
            />
            {selectedConditionQuestion?.questionText && (
                <QuestionSelectFormField
                    id={ErrorIds.branchConditionAnswer}
                    label="Matching answer"
                    subLabel="Select the answer in order to show this branch of questions"
                    selectedOption={mapSelectedAnswer(
                        selectedConditionQuestion,
                    )}
                    options={getAnswersList({
                        questions,
                        selectedConditionQuestion,
                    })}
                    onChange={(option) =>
                        handleSelectConditionAnswer(
                            option,
                            question.clientId,
                            selectedConditionQuestion.questionText,
                        )
                    }
                    formFieldProps={{
                        errors: errors?.conditionAnswer?.errorId
                            ? [errors?.conditionAnswer?.errorMessage]
                            : [],
                    }}
                    selectProps={{
                        zIndex: 1000 - questionIndex, // to ensure that first rendered one has a higher z-index than the next one so there won't be overlapping
                    }}
                />
            )}
        </>
    );
};

const QuestionEdit = ({
    question,
    questionIndex,
    questionnaireId,
    errors,
    questionLevel,
    branchPosition,
}: QuestionEditProps): JSX.Element => {
    switch (question.questionType) {
        case QuestionType.MultipleChoice:
            return (
                <QuestionEditMultipleChoice
                    question={question}
                    questionIndex={questionIndex}
                    questionnaireId={questionnaireId}
                    errors={errors}
                    questionLevel={questionLevel}
                    branchPosition={branchPosition}
                />
            );
        case QuestionType.Text:
            return (
                <QuestionEditText
                    question={question}
                    questionIndex={questionIndex}
                    questionnaireId={questionnaireId}
                    errors={errors}
                    questionLevel={questionLevel}
                    branchPosition={branchPosition}
                />
            );
        case QuestionType.Information:
            return (
                <QuestionEditInformation
                    question={question}
                    questionIndex={questionIndex}
                    questionnaireId={questionnaireId}
                    errors={errors}
                    questionLevel={questionLevel}
                    branchPosition={branchPosition}
                />
            );
        case QuestionType.Branching:
            return (
                <QuestionEditBranch
                    question={question}
                    questionIndex={questionIndex}
                    questionnaireId={questionnaireId}
                    errors={errors}
                    questionLevel={questionLevel}
                    branchPosition={branchPosition}
                />
            );
        default:
            return <></>; // Will later have support for future question types
    }
};

export default QuestionEdit;
