import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledSpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${UI.Tokens.SIZES[3]};
    height: ${UI.Tokens.SIZES[3]};
`;
