import React, { useState } from "react";

import { OrganisationSetupUserViewModel, UserStatus } from "@accurx/api/portal";
import { useCurrentWorkspace } from "@accurx/auth";
import { Button } from "@accurx/design";
import {
    isArchivedWorkspace,
    useUpdateUserStatusMutation,
    useWorkspaceUsersQuery,
} from "@accurx/workspace-management";

import { trackUserManagementAction } from "app/analytics/FlemingAnalytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { ArchiveModal } from "../ConfirmationModal/ArchiveModal";
import { UserCard } from "../UserCard/UserCard";
import { isStatusChangeAllowed } from "../utils/permissions";
import { showErrorToast, showSuccessToast } from "../utils/toasts";

export const ApprovalRequestCard = ({
    user,
}: {
    user: OrganisationSetupUserViewModel;
}) => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const { name, email, userId } = user;

    const workspace = useCurrentWorkspace();

    const { orgId: workspaceId } = workspace;

    const { data: { hasApprovalRights = false } = {}, refetch: refetchUsers } =
        useWorkspaceUsersQuery({ workspaceId });

    const { mutate: mutateStatus } = useUpdateUserStatusMutation({
        onSuccess: async () => {
            showSuccessToast(`${name || email || ""} is now a member`);
            trackUserManagementAction({
                ...analyticsLoggedInProps,
                targetUserId: userId,
                actionSelected: "Approve",
            });
            await refetchUsers();
        },
        onError: () => showErrorToast(),
    });

    const [confirmationModal, setConfirmationModal] =
        useState<React.ReactNode>(null);

    const handleApprove = () => {
        mutateStatus({ workspaceId, userId, status: UserStatus.Approved });
    };

    const handleArchive = () => {
        setConfirmationModal(
            <ArchiveModal
                name={name || email || ""}
                userId={userId}
                onCloseModal={() => setConfirmationModal(null)}
            />,
        );
    };

    return (
        <UserCard name={name} email={email || ""}>
            {hasApprovalRights && !isArchivedWorkspace(workspace) && (
                <>
                    {isStatusChangeAllowed(user, UserStatus.Approved) && (
                        <Button text="Approve" onClick={handleApprove} />
                    )}
                    {isStatusChangeAllowed(user, UserStatus.Archived) && (
                        <Button
                            text="Archive"
                            theme="secondary"
                            onClick={handleArchive}
                        />
                    )}
                    {confirmationModal}
                </>
            )}
        </UserCard>
    );
};
