import { Ds, Tokens } from "@accurx/design";
import styled from "styled-components";

const { COLOURS, SIZES } = Tokens;

export const StyledArchivedWorkspaceBanner = styled(Ds.Flex).attrs({
    gap: "1",
    alignItems: "center",
})`
    background: ${COLOURS.primary.blue[10]};
    border: 1px solid ${COLOURS.primary.blue[25]};
    padding: ${SIZES[1.5]} ${SIZES[2]};
    width: 100%;
`;

export const StyledIcon = styled(Ds.Icon)`
    flex-shrink: 0;
`;
