import { Ds, Tokens } from "@accurx/design";
import styled from "styled-components";

export const SplitButtonGroup = styled(Ds.Flex)`
    & > * {
        border-radius: 0;

        &:first-child {
            border-radius: ${Tokens.BORDERS.radius} 0 0 ${Tokens.BORDERS.radius};
        }
        &:last-child {
            border-radius: 0 ${Tokens.BORDERS.radius} ${Tokens.BORDERS.radius} 0;
        }
        &:not(:last-child) {
            border-right: 0;
        }

        // When it is the only button in the group we need border radius on all corners
        &:first-child:last-child {
            border-radius: ${Tokens.BORDERS.radius};
        }

        &:focus,
        &:focus-within {
            z-index: 9999;
        }
    }

    // Duplicating styles for buttons with tooltip span wrapper
    & > span {
        &:first-child button,
        &:first-child a {
            border-radius: ${Tokens.BORDERS.radius} 0 0 ${Tokens.BORDERS.radius};
        }
        &:last-child button,
        &:last-child a {
            border-radius: 0 ${Tokens.BORDERS.radius} ${Tokens.BORDERS.radius} 0;
        }
        &:not(:last-child) button,
        &:not(:last-child) a {
            border-right: 0;
        }

        // When it is the only button in the group we need border radius on all corners
        &:first-child:last-child button,
        &:first-child:last-child a {
            border-radius: ${Tokens.BORDERS.radius};
        }
    }
`;
