import { useEffect, useRef, useState } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { Ds, Feedback, Hooks, Tokens } from "@accurx/design";
import {
    BasicSearchState,
    INIT_STATE,
    MobileModalHeader,
    ModalHeader,
    PatientDetailsPanel,
    PatientResults,
    PatientSearchForm,
    PatientSearchFormHandle,
    PatientSearchResult,
    ToastContainer,
    ToastContainerIds,
    isTestPatient,
    useLatestPatientSearchQuery,
    usePatientSearchViewAnalytics,
} from "@accurx/navigation";
import { useIsMutating } from "@tanstack/react-query";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import { useAnalytics } from "reduxQuarantine/useAnalytics";
import styled from "styled-components";

import { PatientDetails } from "./PatientDetails/PatientDetails";

export type PatientSearchModalProps = {
    modalOpen: boolean;
    setModalOpen: (open: boolean) => void;
    initialData?: BasicSearchState;
};

export const NewPatientSearchModal = ({
    modalOpen,
    setModalOpen,
    initialData = INIT_STATE.BASIC,
}: PatientSearchModalProps) => {
    const { orgId } = useCurrentWorkspace();
    const { size } = Hooks.useViewportSize();
    const track = useAnalytics();
    const location = useLocation();
    const isFetchingPatient = useIsMutating({
        mutationKey: ["SearchForLatestPatient"],
    });

    const formRef = useRef<PatientSearchFormHandle>(null);

    const [originLocation] = useState(location);
    const [isSecondPanel, setIsSecondPanel] = useState(false);
    const [selectedPatient, setSelectedPatient] =
        useState<PatientSearchResult | null>(null);
    const [searchResults, setSearchResults] = useState<
        PatientSearchResult[] | null
    >(null);
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);

    const isMobileSize = ["xs", "sm"].includes(size);

    const { data: latestPatientSearch } = useLatestPatientSearchQuery(
        { organisationId: orgId, search: "" },
        { enabled: false },
    );
    const isFirstExperience = latestPatientSearch?.patients.length === 0;
    const showEmptyPanel = isFirstExperience && !selectedPatient;

    usePatientSearchViewAnalytics({
        isDetailPrePopulated: Boolean(initialData.nhsNumber),
        searchPatientOrigin: initialData.nhsNumber ? "Email" : "PrimaryNav",
    });

    useEffect(() => {
        if (location !== originLocation) {
            setModalOpen(false);
        }
    }, [location, originLocation, setModalOpen]);

    useEffect(() => {
        // Temporary workaround: Reverts Radix's "modal" prop behaviour on popovers
        // within a modal, because it blocks all interactive elements
        if (modalOpen) {
            const timer = setTimeout(() => {
                document.body.style.pointerEvents = "";
            }, 0);
            return () => clearTimeout(timer);
        } else {
            document.body.style.pointerEvents = "auto";
        }
    }, [modalOpen]);

    const onTestPatient = () => {
        formRef.current?.useTestPatient();
        track("PatientSearchTestPatient Button Click", {
            navigationVersion: "Unified",
            productOrigin: "PatientSearch",
        });
    };

    const showErrorToast = () => {
        toast(
            <Feedback
                colour="error"
                title="Couldn't load patient details. Please try again."
            />,
            { containerId: "toast-container-patient-search" },
        );
    };

    const updateCurrentPatient = (searchResult: PatientSearchResult) => {
        setSelectedPatient(searchResult);
        if (searchResult) {
            setIsSecondPanel(true);
        }
    };

    const onPatientSelect = (patient: PatientSearchResult | null) => {
        if (!patient) {
            showErrorToast();
            setSearchResults(null);
            track("PatientSearchResult Button Click", {
                hasError: true,
                isTestPatient: false,
                navigationVersion: "Unified",
                productOrigin: "PatientSearch",
            });
            return;
        }

        updateCurrentPatient(patient);

        track("PatientSearchResult Button Click", {
            hasError: false,
            isTestPatient: isTestPatient(String(patient.nhsNumber)),
            navigationVersion: "Unified",
            productOrigin: "PatientSearch",
        });
    };

    const handleSearchResult = (results: PatientSearchResult[] | null) => {
        setSearchResults(results);

        // Cannot preselect patient on mobile because it would skip the patient results view
        if (!isMobileSize && results?.length === 1) {
            updateCurrentPatient(results[0]);
        }
    };

    const onToggleFilter = (state: boolean) => {
        setIsFormDisabled(state);
    };

    return (
        <StyledModal
            size="large"
            open={modalOpen}
            onOpenChange={setModalOpen}
            fullscreen="lg"
        >
            {isMobileSize ? (
                <Ds.Flex flexDirection="column">
                    <MobileModalHeader
                        onClickTestPatient={
                            isFirstExperience || isFormDisabled
                                ? undefined
                                : onTestPatient
                        }
                        onBack={() => setIsSecondPanel(false)}
                        isSecondPanel={isSecondPanel}
                    />
                    <Ds.Modal.Divider />
                    <StyledModalBody>
                        <StyledBaseColumn>
                            {!isSecondPanel ? (
                                <Ds.Flex flexDirection="column" gap="1.5">
                                    <PatientSearchForm
                                        ref={formRef}
                                        searchMethods={["BASIC", "ADVANCED"]}
                                        onSearchResult={handleSearchResult}
                                        onSearchError={showErrorToast}
                                        onLoading={setIsSearching}
                                        initialData={initialData}
                                        isFormDisabled={isFormDisabled}
                                    />
                                    <PatientResults
                                        onSelect={onPatientSelect}
                                        onToggleFilter={onToggleFilter}
                                        onClickTestPatient={onTestPatient}
                                        results={searchResults}
                                        isLoading={isSearching}
                                    />
                                </Ds.Flex>
                            ) : (
                                <PatientDetailsPanel
                                    patient={selectedPatient}
                                    isLoading={Boolean(isFetchingPatient)}
                                    isEmpty={showEmptyPanel}
                                >
                                    {selectedPatient && (
                                        <PatientDetails
                                            patient={selectedPatient}
                                        />
                                    )}
                                </PatientDetailsPanel>
                            )}
                        </StyledBaseColumn>
                    </StyledModalBody>
                </Ds.Flex>
            ) : (
                <>
                    <ModalHeader
                        onClickTestPatient={
                            isFirstExperience || isFormDisabled
                                ? undefined
                                : onTestPatient
                        }
                    />
                    <Ds.Modal.Divider />
                    <StyledModalBody>
                        <StyledBaseColumn>
                            <Ds.Flex
                                flexDirection="column"
                                gap="1.5"
                                style={{ overflow: "hidden", flex: 1 }}
                            >
                                <PatientSearchForm
                                    ref={formRef}
                                    searchMethods={["BASIC", "ADVANCED"]}
                                    onSearchResult={handleSearchResult}
                                    onSearchError={showErrorToast}
                                    onLoading={setIsSearching}
                                    initialData={initialData}
                                    isFormDisabled={isFormDisabled}
                                />
                                <PatientResults
                                    onSelect={onPatientSelect}
                                    onToggleFilter={onToggleFilter}
                                    onClickTestPatient={onTestPatient}
                                    results={searchResults}
                                    isLoading={isSearching}
                                    cardRole="radio"
                                />
                            </Ds.Flex>
                        </StyledBaseColumn>
                        <PatientDetailsPanel
                            patient={selectedPatient}
                            isLoading={Boolean(isFetchingPatient)}
                            isEmpty={showEmptyPanel}
                        >
                            {selectedPatient && (
                                <PatientDetails patient={selectedPatient} />
                            )}
                        </PatientDetailsPanel>
                    </StyledModalBody>
                </>
            )}
            <ToastContainer containerId={ToastContainerIds.patientSearch} />
        </StyledModal>
    );
};

const StyledModal = styled(Ds.Modal)`
    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        display: flex;
        flex-direction: column;
        min-height: 700px;
        max-height: 90%;
        overflow: hidden;
        width: 900px;
    }
`;

const StyledModalBody = styled(Ds.Flex)`
    flex: 1;
    height: 100%;
    overflow: hidden;
`;

const StyledBaseColumn = styled(Ds.Flex).attrs({
    flexDirection: "column",
})`
    flex: 1;
    overflow: hidden;
    padding: ${Tokens.SIZES[2]} ${Tokens.SIZES[1.5]};
`;
