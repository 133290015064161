import { useTeam } from "@accurx/concierge/hooks/data/useTeam";
import { useTeamTypeUnreadCount } from "@accurx/concierge/hooks/data/useTeamTypeUnreadCount";
import { useTeamUnreadCount } from "@accurx/concierge/hooks/data/useTeamUnreadCount";
import { useUserUnreadCount } from "@accurx/concierge/hooks/data/useUserUnreadCount";
import { useWithCareProvidersUnassignedUnreadCount } from "@accurx/concierge/hooks/data/useWithCareProvidersUnassignedUnreadCount";
import { useWithCareProvidersUserUnreadCount } from "@accurx/concierge/hooks/data/useWithCareProvidersUserUnreadCount";
import { useWithCareProvidersWorkspaceUnreadCount } from "@accurx/concierge/hooks/data/useWithCareProvidersWorkspaceUnreadCount";
import { useWithPatientsWorkspaceUnreadCount } from "@accurx/concierge/hooks/data/useWithPatientsWorkspaceUnreadCount";
import { TeamSummary } from "@accurx/concierge/types";

type Props = {
    children: (unreadCount: number | undefined) => JSX.Element;
};

const WithPatients = (props: Props) => {
    const { unreadCount } = useWithPatientsWorkspaceUnreadCount();
    return props.children(unreadCount);
};

const MyInbox = (props: Props) => {
    const unreadCount = useUserUnreadCount();
    return props.children(unreadCount);
};

const Team = (props: Props & { teamId: string }) => {
    const unreadCount = useTeamUnreadCount({ teamId: props.teamId });
    const team = useTeam({ teamId: props.teamId });
    return props.children(team?.isMember ? unreadCount : undefined);
};

const TeamType = (props: Props & { type: TeamSummary["type"] }) => {
    const unreadCount = useTeamTypeUnreadCount({ type: props.type });
    return props.children(unreadCount);
};

const WithCareProviders = (props: Props) => {
    const { unreadCount } = useWithCareProvidersWorkspaceUnreadCount();
    return props.children(unreadCount);
};

const WithCareProvidersMyInbox = (props: Props) => {
    const unreadCount = useWithCareProvidersUserUnreadCount();
    return props.children(unreadCount);
};

const WithCareProvidersUnassigned = (props: Props) => {
    const unreadCount = useWithCareProvidersUnassignedUnreadCount();
    return props.children(unreadCount);
};

export const UnreadCountProvider = {
    WithPatients,
    MyInbox,
    Team,
    TeamType,
    WithCareProviders,
    WithCareProvidersMyInbox,
    WithCareProvidersUnassigned,
};
