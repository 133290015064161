import { FormEvent, ReactNode } from "react";

import { useAnalytics } from "@accurx/analytics";
import { FeatureName, useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import { TeamSummary, UserSummary } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { useNativeTrackingFields } from "@accurx/native";
import { useConversationParticipant } from "domains/inbox/hooks/useConversationParticipant";

import { AssigneeLabel } from "./AssigneeLabel";
import {
    LinkWrapper,
    StyledRadioGroupItem,
} from "./AssigneeListRadioGroupForm.styles";
import { AssigneeRadioGroupLabel } from "./AssigneeRadioGroupLabel";
import { StyledAssigneeListWrapper } from "./AssigneeSelectorForm.styles";
import { ValidAssigneeSummary } from "./AssigneeSelectorForm.types";

export const ASSIGNEE_SELECTOR_FORM_ID = "assignee-selector";

const { Ds } = UI;

type AssigneeListRadioGroupFormProps = {
    onClickAssignButton: () => void;
    onCheckedChange: (userId: string, userType: "User" | "Team") => void;
    showNoAssigneeSelectedError: boolean;
    teams: TeamSummary[];
    unassignedTeams: TeamSummary[];
    users: UserSummary[];
    selectedAssignee?: ValidAssigneeSummary;
    currentAssignee?: ValidAssigneeSummary;
};

export const AssigneeListRadioGroupForm = ({
    onClickAssignButton,
    onCheckedChange,
    showNoAssigneeSelectedError,
    unassignedTeams,
    teams,
    users,
    selectedAssignee,
    currentAssignee,
}: AssigneeListRadioGroupFormProps) => {
    const showPendingUsers = useFeatureFlag(
        FeatureName.UnifiedInboxPendingUsers,
    );

    const approvedUsers = users.filter((u) => u.status === "Approved");
    const pendingUsers = users.filter((u) => u.status === "Unapproved");

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onClickAssignButton();
    };

    const handleChangeTeam = (teamId: string) => {
        onCheckedChange(teamId, "Team");
    };

    const handleChangeUser = (userId: string) => {
        onCheckedChange(userId, "User");
    };

    const ariaDescribedBy = showNoAssigneeSelectedError
        ? "no-assignee-error"
        : undefined;

    const teamProps = {
        name: "team-assignee",
        onChange: handleChangeTeam,
        ariaDescribedBy: ariaDescribedBy,
        value:
            selectedAssignee?.type === "Team" ? selectedAssignee.id : undefined,
    };

    const userProps = {
        name: "user-assignee",
        onChange: handleChangeUser,
        ariaDescribedBy: ariaDescribedBy,
        value:
            selectedAssignee?.type === "User" ? selectedAssignee.id : undefined,
    };

    return (
        <form id={ASSIGNEE_SELECTOR_FORM_ID} onSubmit={handleSubmit}>
            {unassignedTeams.length > 0 && (
                <AssigneeRadioGroup
                    {...teamProps}
                    label={<AssigneeRadioGroupLabel labelText="" />}
                >
                    {unassignedTeams.map((team) => (
                        <Ds.RadioGroup.Item
                            id={`team-${team.id}`}
                            key={team.id}
                            value={team.id}
                            aria-label={team.displayName}
                        >
                            <Ds.RadioGroup.Label>
                                <AssigneeLabel
                                    assignee={team}
                                    assigneeType="Team"
                                    isCurrentAssignee={
                                        currentAssignee?.type === "Team" &&
                                        currentAssignee.id === team.id
                                    }
                                />
                            </Ds.RadioGroup.Label>
                            <Ds.RadioGroup.Indicator />
                        </Ds.RadioGroup.Item>
                    ))}
                </AssigneeRadioGroup>
            )}

            {teams.length > 0 && (
                <AssigneeRadioGroup
                    {...teamProps}
                    label={<AssigneeRadioGroupLabel labelText="Teams" />}
                >
                    {teams.map((team) => (
                        <Ds.RadioGroup.Item
                            id={`team-${team.id}`}
                            key={team.id}
                            value={team.id}
                            aria-label={team.displayName}
                        >
                            <Ds.RadioGroup.Label>
                                <AssigneeLabel
                                    assignee={team}
                                    assigneeType="Team"
                                    isCurrentAssignee={
                                        currentAssignee?.type === "Team" &&
                                        currentAssignee.id === team.id
                                    }
                                />
                            </Ds.RadioGroup.Label>
                            <Ds.RadioGroup.Indicator />
                        </Ds.RadioGroup.Item>
                    ))}
                </AssigneeRadioGroup>
            )}

            {approvedUsers.length > 0 && (
                <AssigneeRadioGroup
                    {...userProps}
                    label={<AssigneeRadioGroupLabel labelText="Colleagues" />}
                >
                    {approvedUsers.map((user) => (
                        <Ds.RadioGroup.Item
                            id={`user-${user.id}`}
                            key={user.id}
                            value={user.id}
                            aria-label={
                                user.isCurrentUser
                                    ? `${user.displayName} (You)`
                                    : user.displayName
                            }
                        >
                            <Ds.RadioGroup.Label>
                                <AssigneeLabel
                                    assignee={user}
                                    assigneeType="User"
                                    isCurrentAssignee={
                                        currentAssignee?.type === "User" &&
                                        currentAssignee.id === user.id
                                    }
                                />
                            </Ds.RadioGroup.Label>
                            <Ds.RadioGroup.Indicator />
                        </Ds.RadioGroup.Item>
                    ))}
                </AssigneeRadioGroup>
            )}

            {showPendingUsers && pendingUsers.length > 0 && (
                <AssigneeRadioGroup
                    {...userProps}
                    label={<PendingUsersLegend />}
                    disabled
                >
                    {pendingUsers.map((user) => (
                        <StyledRadioGroupItem
                            id={`user-${user.id}`}
                            key={user.id}
                            value={user.id}
                            aria-label={
                                user.isCurrentUser
                                    ? `${user.displayName} (You)`
                                    : user.displayName
                            }
                        >
                            <Ds.RadioGroup.Label>
                                <AssigneeLabel
                                    assignee={user}
                                    assigneeType="User"
                                    isPending
                                    isCurrentAssignee={
                                        currentAssignee?.type === "User" &&
                                        currentAssignee.id === user.id
                                    }
                                />
                            </Ds.RadioGroup.Label>
                        </StyledRadioGroupItem>
                    ))}
                </AssigneeRadioGroup>
            )}
        </form>
    );
};

type AssigneeRadioGroupProps = {
    name: string;
    label: string | JSX.Element;
    value: string | undefined;
    onChange: (assigneeId: string) => void;
    children: ReactNode;
    disabled?: boolean;
    ariaDescribedBy?: string;
};

const AssigneeRadioGroup = ({
    name,
    label,
    value,
    onChange,
    disabled,
    children,
    ariaDescribedBy,
}: AssigneeRadioGroupProps) => {
    return (
        <StyledAssigneeListWrapper>
            <UI.FormFieldWrapper
                as="fieldset"
                label={label}
                aria-describedby={ariaDescribedBy}
            >
                <Ds.Box px="1" pb="1">
                    <Ds.RadioGroup
                        name={name}
                        value={value}
                        onValueChange={onChange}
                        disabled={disabled}
                    >
                        <Ds.Flex flexDirection="column" gap="0.5">
                            {children}
                        </Ds.Flex>
                    </Ds.RadioGroup>
                </Ds.Box>
            </UI.FormFieldWrapper>
        </StyledAssigneeListWrapper>
    );
};

export const PendingUsersLegend = () => {
    const workspace = useCurrentWorkspace();
    const { isAdminUser } = workspace.settings;
    const href = `/w/${workspace.orgId}/settings/users?tab=pending`;

    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();
    const conversationParticipant = useConversationParticipant();

    const handleClickLink = () => {
        track("ConversationAssignUserManagementPage Link Click", {
            ...nativeTrackingFields,
            conversationParticipant,
        });
    };

    return (
        <>
            <AssigneeRadioGroupLabel labelText="Pending approval" />
            <Ds.Box px="2" pb="1">
                <Ds.Text size="small" as="div">
                    {isAdminUser ? (
                        <>
                            These colleagues need to be approved before you can
                            assign conversations to them.
                            <LinkWrapper>
                                <Ds.Link
                                    to={href}
                                    target="_blank"
                                    onClick={handleClickLink}
                                >
                                    View approval requests
                                </Ds.Link>
                            </LinkWrapper>
                        </>
                    ) : (
                        <>
                            A workspace admin needs to approve these colleagues
                            before conversations can be assigned to them.
                        </>
                    )}
                </Ds.Text>
            </Ds.Box>
        </>
    );
};
