import { Ds } from "@accurx/design";
import { AutoFocusHeading } from "@accurx/navigation";
import { useLayout } from "domains/inbox/components/Layout/context";
import { useViewportSize } from "domains/inbox/hooks/util";

import {
    StyledContainer,
    StyledHeadingWrap,
    StyledTitledContainer,
    StyledTitledContainerInner,
} from "./ConversationListHeader.styles";

export type ConversationListHeaderProps = {
    heading: JSX.Element;
    notifications?: JSX.Element;
    paddingBottom?: number;
};

export const ConversationListHeader = ({
    heading,
    notifications,
    paddingBottom = 0,
}: ConversationListHeaderProps) => {
    const size = useViewportSize();
    const shouldShowMenuButton = size !== "lg" && size !== "xl";
    const { leftPane } = useLayout();

    return (
        <StyledContainer pb={paddingBottom}>
            <Ds.Flex justifyContent="flex-start" alignItems="center">
                {shouldShowMenuButton && (
                    <Ds.Flex.Item>
                        <nav>
                            <Ds.Button
                                appearance="tertiary"
                                onClick={leftPane.toggle}
                                title="Open Sidebar navigation"
                                id="menu-nav"
                            >
                                <Ds.Button.Icon
                                    name="Menu"
                                    aria-label="Open Sidebar navigation"
                                />
                            </Ds.Button>
                        </nav>
                    </Ds.Flex.Item>
                )}
                <StyledTitledContainer>
                    <StyledTitledContainerInner>
                        <StyledHeadingWrap>
                            <AutoFocusHeading>{heading}</AutoFocusHeading>
                        </StyledHeadingWrap>
                        {notifications && (
                            <Ds.Flex.Item>{notifications}</Ds.Flex.Item>
                        )}
                    </StyledTitledContainerInner>
                </StyledTitledContainer>
            </Ds.Flex>
        </StyledContainer>
    );
};
