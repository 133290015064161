import { ReactNode, useState } from "react";

import {
    Icon,
    Text,
    Tooltip,
    TooltipArrow,
    TooltipContent,
    TooltipTrigger,
} from "@accurx/design";
import { IconNameVariants } from "@accurx/design/dist/styles/types";
import { useHistory } from "react-router-dom";
import { useAnalytics } from "reduxQuarantine/useAnalytics";

import { FlemingAnalyticsTracker } from "app/analytics";
import { pascalCase } from "shared/formatters/String";
import { useRouteMatchOrTwoFactorPrompt } from "shared/useRouteMatchOrTwoFactorPrompt";

import { IsCollapsed } from "../NavigationMenu.styles";
import {
    StyledIconWrapper,
    StyledPrimaryNavLink,
    StyledPrimaryNavLinkBadge,
    StyledPrimaryNavLinkText,
    StyledSecondaryLinkContentWrapper,
    StyledSecondaryNavLink,
    StyledSecondaryNavLinkText,
} from "./NavLink.styles";
import {
    ActiveState,
    COLOURS,
    LinkVariant,
    TEXT_VARIANTS,
} from "./NavLink.types";

export type RenderBadgeNavLinkArgs = {
    isActive: boolean;
};

type BaseNavLinkProps = {
    to: string;
    icon?: IconNameVariants;
    text: string;
    onClick?: () => void;
    /**
     * Ideally <Ds.Badge /> or <Icon /> component
     * that will display on the right hand side of the text.
     */
    renderBadge?: (args: RenderBadgeNavLinkArgs) => JSX.Element | null;
    /**
     * Override default analytics props that will be sent
     */
    customAnalyticsProps?: Partial<
        Pick<
            FlemingAnalyticsTracker.NavigationMenuAnalyticsProps,
            "navigationOptionSelected" | "notificationCount"
        >
    >;
    toQueryParams?: string;
    openInNewTab?: boolean;
    variant: LinkVariant;
    userflowId?: string;
};

/**
 * Primary nav link is a collapsible link
 * it must have icon & text
 *
 * It can display a notification badge
 */
type PrimaryNavLinkProps = BaseNavLinkProps & {
    variant: LinkVariant.Primary;
    icon: IconNameVariants;
    isCollapsed: IsCollapsed;
};

/**
 * Secondary nav links can have icon + text + badge.
 *
 * The text is not collapsible, although it will
 * truncate if the content doesn't quite fit
 */
type SecondaryNavLinkProps = BaseNavLinkProps & {
    variant: LinkVariant.Secondary | LinkVariant.Tertiary;
};

export type NavLinkProps = PrimaryNavLinkProps | SecondaryNavLinkProps;

const OptionalToolTip = ({
    tooltipText,
    children,
}: {
    tooltipText?: string;
    children: ReactNode;
}) => {
    return tooltipText ? (
        <Tooltip>
            <TooltipTrigger tabIndex={-1} asChild={true}>
                {children}
            </TooltipTrigger>
            <TooltipContent side="right">
                <TooltipArrow />
                {tooltipText}
            </TooltipContent>
        </Tooltip>
    ) : (
        <>{children}</>
    );
};

const NavLink = ({
    to,
    text,
    onClick,
    renderBadge,
    toQueryParams,
    openInNewTab,
    customAnalyticsProps,
    ...props
}: NavLinkProps): JSX.Element => {
    const history = useHistory();

    const [isOverNavLink, setIsOverNavLink] = useState(false);

    const isActiveRoute = useRouteMatchOrTwoFactorPrompt({ path: to });
    const variant = props.variant;
    const activeState = isActiveRoute
        ? ActiveState.Active
        : ActiveState.Inactive;
    const colour = COLOURS[variant][activeState];
    const textVariant = TEXT_VARIANTS[variant][activeState];
    const fullToPath = to + (toQueryParams ?? "");
    const track = useAnalytics();

    // Only primary nav can be collapsed/minimised
    const isCollapsed =
        variant === LinkVariant.Primary ? props.isCollapsed : false;

    const analyticsProps = {
        origin: history.location.pathname,
        navigationOptionSelected:
            customAnalyticsProps?.navigationOptionSelected || pascalCase(text),
        notificationCount: customAnalyticsProps?.notificationCount,
    };

    const handleLinkClick = (e: React.MouseEvent) => {
        switch (variant) {
            case LinkVariant.Primary:
                track("NavigationMenu Button Click", {
                    ...analyticsProps,
                    eventVersion: 2,
                });
                break;
            case LinkVariant.Secondary:
            case LinkVariant.Tertiary:
                track("NavigationSubMenu Button Click", analyticsProps);
                break;
            default:
                break;
        }

        // move focus only if link was "clicked" via the keyboard
        if (e.clientX === 0 && e.clientY === 0) {
            document.getElementById("skipToContent")?.focus();
        }

        onClick?.();
    };

    const sharedNavLinkProps = {
        to: fullToPath,
        target: openInNewTab ? "_blank" : undefined,
        rel: openInNewTab ? "noreferrer noopener" : undefined,
        activestate: activeState,
        onClick: handleLinkClick,
        "data-userflow-id": props.userflowId,
    };

    return (
        <OptionalToolTip
            tooltipText={
                variant === LinkVariant.Primary && isCollapsed && isOverNavLink
                    ? text
                    : undefined
            }
        >
            <Text as="span" variant={textVariant} colour={colour}>
                {variant === LinkVariant.Primary ? (
                    <StyledPrimaryNavLink
                        {...sharedNavLinkProps}
                        tabIndex={0}
                        variant={LinkVariant.Primary}
                        onMouseEnter={() => setIsOverNavLink(true)}
                        onMouseLeave={() => setIsOverNavLink(false)}
                    >
                        <StyledIconWrapper>
                            <Icon
                                name={props.icon}
                                theme="Line"
                                colour={colour}
                                size={4}
                            />
                        </StyledIconWrapper>

                        <StyledPrimaryNavLinkText isCollapsed={isCollapsed}>
                            {text}
                        </StyledPrimaryNavLinkText>

                        {renderBadge && (
                            <StyledPrimaryNavLinkBadge>
                                {renderBadge({
                                    isActive:
                                        activeState === ActiveState.Active,
                                })}
                            </StyledPrimaryNavLinkBadge>
                        )}
                    </StyledPrimaryNavLink>
                ) : (
                    <StyledSecondaryNavLink
                        {...sharedNavLinkProps}
                        aria-current={isActiveRoute ? "page" : undefined}
                        variant={variant}
                    >
                        <StyledSecondaryLinkContentWrapper>
                            {props.icon && (
                                <StyledIconWrapper>
                                    <Icon
                                        name={props.icon}
                                        theme="Line"
                                        colour={colour}
                                        size={3}
                                    />
                                </StyledIconWrapper>
                            )}
                            <StyledSecondaryNavLinkText>
                                {text}
                            </StyledSecondaryNavLinkText>
                        </StyledSecondaryLinkContentWrapper>
                        {renderBadge &&
                            renderBadge({
                                isActive: activeState === ActiveState.Active,
                            })}
                    </StyledSecondaryNavLink>
                )}
            </Text>
        </OptionalToolTip>
    );
};

export { NavLink };
