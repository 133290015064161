import { Reducer } from "@reduxjs/toolkit";
import {
    NativeFetchError,
    NativeFetchUnexpectedError,
    convertNativeErrorMessageToError,
} from "domains/native/errors";
import {
    SubscribeWindowOpenStatusSchema,
    SubscribeWindowOpenStatusWithResetCount,
} from "domains/native/schemas/SubscribeWindowOpenStatusSchema";
import { Action, QueryResult } from "domains/native/types";

import { initialState } from "../store";

const subscriptionName = "SubscribeWindowOpenStatus";

const getResetCount = (
    currentResetCount: number,
    previouslyOpen: boolean,
    nowOpen: boolean,
) => {
    if (!nowOpen || !previouslyOpen) {
        return 0;
    }

    return currentResetCount + 1;
};

export const reducer: Reducer<
    QueryResult<SubscribeWindowOpenStatusWithResetCount, NativeFetchError>,
    Action
> = (state = initialState[subscriptionName], action: Action) => {
    if (action.type !== subscriptionName) {
        return state;
    }

    if (!action.payload.success) {
        return {
            status: "error",
            error: convertNativeErrorMessageToError(action.payload.error),
        };
    }

    const parseResult = SubscribeWindowOpenStatusSchema.safeParse(
        action.payload.data,
    );

    if (!parseResult.success) {
        return {
            status: "error",
            error: new NativeFetchUnexpectedError(
                "ClientZodParseFailure",
                `Invalid payload for ${subscriptionName}`,
                parseResult.error,
            ),
        };
    }

    const { open } = parseResult.data;

    const resetCount = getResetCount(
        state.data?.resetCount ?? 0,
        state.data?.open === true,
        open,
    );

    return {
        status: "success",
        data: { open, resetCount },
    };
};
