import { useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import * as UI from "@accurx/design";
import { QuestionnaireTemplate } from "@accurx/message-templates";
import { useBrowserEnvironment } from "@accurx/native";
import { SkeletonLoader } from "domains/message-component/ILLEGAL_IMPORTS_DO_NOT_USE";
import { PatientExternalId } from "domains/message-component/types";

import { StyledFetchingError, StyledReloadButton } from "./Templates.styles";
import { TemplatesBrowser } from "./components/TemplatesBrowser/TemplatesBrowser";
import { combineTemplates } from "./components/TemplatesCombobox/combineTemplates";
import { useAllTemplates } from "./hooks/useAllTemplates";

type TemplatesProps = {
    patientExternalIds: PatientExternalId[];
    disabled?: boolean;
    onQuestionnaireSelected?: (template: QuestionnaireTemplate) => void;
    onQuestionnaireRemoved?: () => void;
};

export const Templates = ({
    patientExternalIds,
    disabled,
    onQuestionnaireSelected,
    onQuestionnaireRemoved,
}: TemplatesProps) => {
    const workspace = useCurrentWorkspace();
    const isUnifiedNavigationEnabled = useFeatureFlag("UnifiedNavigation");
    const env = useBrowserEnvironment();

    const templatesResult = useAllTemplates({ patientExternalIds });

    if (templatesResult.status === "loading") {
        return <SkeletonLoader width="100%" height="20px" />;
    }

    if (templatesResult.status === "error") {
        return (
            <StyledFetchingError>
                <UI.Ds.Flex alignItems={"center"}>
                    <UI.Ds.Icon size="xsmall" name="Error" appearance="solid" />
                </UI.Ds.Flex>
                <UI.Text skinny variant="preview" colour="zinc">
                    Templates failed to load.
                </UI.Text>
                <StyledReloadButton onClick={templatesResult.refetch}>
                    Reload templates
                </StyledReloadButton>
            </StyledFetchingError>
        );
    }

    const combinedTemplates = combineTemplates(templatesResult.data);

    const manageTemplatesUrl =
        env === "WebView"
            ? `/practices/${workspace.orgId}/manage-templates?tab=UserTemplates`
            : isUnifiedNavigationEnabled
            ? `/w/${workspace.orgId}/settings/templates`
            : `/w/${workspace.orgId}/settings/personal-templates`;

    return (
        <TemplatesBrowser
            templates={combinedTemplates}
            patientExternalIds={patientExternalIds}
            disabled={disabled}
            manageTemplatesUrl={manageTemplatesUrl}
            onQuestionnaireSelected={onQuestionnaireSelected}
            onQuestionnaireRemoved={onQuestionnaireRemoved}
        />
    );
};
