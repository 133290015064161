import {
    getEmbedMode,
    useIsNativeSignalRConnectionAvailable,
} from "@accurx/native";
import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

/**
 * SignalR can be used via the Native Bridge when all of the following are true:
 * 1. UnifiedInboxSingleSignalRConnection feature is turned ON
 * 2. Embedded in Desktop
 * 3. SignalR via Native Bridge is possible (supported desktop version)
 */
export const useCanUseSignalRViaNativeBridge = (): boolean => {
    const isFeatureFlagEnabled = useFeatureFlag(
        "UnifiedInboxSingleSignalRConnection",
    );

    const { embedMode } = getEmbedMode();
    const isNativeSignalRConnectionAvailable =
        useIsNativeSignalRConnectionAvailable();

    return (
        isFeatureFlagEnabled &&
        embedMode === "Desktop" &&
        isNativeSignalRConnectionAvailable
    );
};
