import { createContext, useContext } from "react";

import { PatientDemographics } from "@accurx/native";

export type ComposeNewMessageContextType = {
    onNavigateToCompose: (args: {
        patientDemographics: PatientDemographics;
        workspaceId: number;
    }) => void;
};

export const ComposeNewMessageContext =
    createContext<ComposeNewMessageContextType | null>(null);

export const useComposeNewMessageContext = () => {
    const context = useContext(ComposeNewMessageContext);
    if (context === null) {
        throw new Error(
            "useComposeNewMessageContext must be used inside of a ComposeNewMessageContext provider",
        );
    }

    return context;
};
