import React from "react";

import * as UI from "@accurx/design";

/**
 * TODO: Remove import from ILLEGAL_IMPORTS_DO_NOT_USE
 */
import { Pill } from "@accurx/inbox-design-library";
import { userflowIds } from "domains/message-component/userflow";

import { StyledPillButton } from "./ContactDetailButton.styles";

type ContactDetailProps = {
    text: string;
    toggle: () => void;
    isError: boolean;
};

type Ref = HTMLButtonElement;

export const ContactDetailButton = React.forwardRef<Ref, ContactDetailProps>(
    ({ text, isError, toggle, ...props }, ref) => {
        return (
            <StyledPillButton
                data-testid="contact-detail-selector-button"
                radius="full"
                onClick={toggle}
                isError={isError}
                ref={ref}
                {...props}
                aria-label="Edit contact details"
                data-userflow-id={userflowIds.contactDetailsButton}
            >
                <UI.Text
                    skinny
                    variant="preview"
                    colour="zinc"
                    props={{ style: { paddingLeft: UI.Tokens.SIZES[0.5] } }}
                >
                    {text}
                </UI.Text>
                <Pill.Icon
                    name={"Pencil"}
                    colour="blue"
                    theme="Line"
                    props={{
                        "aria-hidden": true,
                    }}
                />
            </StyledPillButton>
        );
    },
);
