import { FormEvent, useEffect, useState } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import {
    Button,
    Card,
    Ds,
    Feedback,
    FormFieldWrapper,
    Input,
    Text,
} from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";

import { getSenderId, postSenderId } from "api/PracticesApi";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";

import { FeedbackMsg } from "./SenderId.types";
import { validateSenderId } from "./SenderId.utils";

type SenderIdProps = {
    noHeader?: boolean;
    noBreadcrumbs?: boolean;
};

export const SenderId = ({
    noBreadcrumbs = false,
    noHeader = false,
}: SenderIdProps) => {
    useAccurxWebTitle("Manage SMS sender ID");

    const workspace = useCurrentWorkspace();
    const practiceId = String(workspace.orgId);

    const [senderId, setSenderId] = useState("");
    const [senderIdError, setSenderIdError] = useState("");
    const [feedbackMsg, setFeedbackMsg] = useState<FeedbackMsg>({
        colour: null,
        msg: "",
    });

    useEffect(() => {
        async function loadData(): Promise<void> {
            setFeedbackMsg({
                colour: null,
                msg: "",
            });
            const res = await getSenderId(practiceId);
            if (!res.success || !res.result) {
                setFeedbackMsg({
                    colour: "error",
                    msg:
                        res.error ||
                        "Something went wrong loading your details. Please refresh the page.",
                });
                return;
            }
            setSenderId(res.result.senderId);
        }
        if (practiceId) {
            loadData();
        }
    }, [practiceId]);

    const handleSave = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();
        setSenderIdError("");
        setFeedbackMsg({
            colour: null,
            msg: "",
        });

        const formError = validateSenderId(senderId);
        if (formError) {
            setSenderIdError(formError);
            return;
        }

        const res = await postSenderId(practiceId, { senderId });
        if (!res.success) {
            setFeedbackMsg({
                colour: "error",
                msg:
                    res.error ||
                    "Something went wrong saving your details. Please try again.",
            });
            return;
        }
        setFeedbackMsg({
            colour: "success",
            msg: "Successfully updated. Send yourself a text message for a dummy patient to see what this looks like.",
        });
    };

    return (
        <>
            {!noBreadcrumbs && <Breadcrumb title="SMS Settings" />}
            <form className="mb-4" onSubmit={handleSave}>
                <Card
                    header={
                        !noHeader ? (
                            <Text variant="subtitle" as="h2" skinny>
                                Sender ID
                            </Text>
                        ) : undefined
                    }
                    footer={
                        workspace.settings.isAdminUser ? (
                            <div className="d-flex justify-content-end">
                                <Button type="submit" text="Update" />
                            </div>
                        ) : undefined
                    }
                >
                    <Ds.Flex flexDirection="column" gap="2">
                        {!workspace.settings.isAdminUser && (
                            <Feedback colour="information">
                                Please contact your workspace admin to change
                                this
                            </Feedback>
                        )}
                        <Text skinny>
                            This is the sender name shown to a patient when they
                            receive a message sent by your team. There is a
                            network provider restriction that this must be
                            between 3 and 11 characters and consist of only
                            letters or numbers with no spaces.
                        </Text>
                        <FormFieldWrapper
                            label="Sender Name"
                            labelProps={{ htmlFor: "sender-id" }}
                            className="mb-2"
                            errors={[senderIdError]}
                        >
                            <Input
                                id="sender-id"
                                value={senderId}
                                type="text"
                                onChange={(e): void =>
                                    setSenderId(e.target.value)
                                }
                                maxLength={11}
                                disabled={!workspace.settings.isAdminUser}
                            />
                        </FormFieldWrapper>
                        {feedbackMsg.colour && feedbackMsg.msg && (
                            <Feedback
                                title={feedbackMsg.msg}
                                colour={feedbackMsg.colour}
                                props={{ className: "mb-3" }}
                            />
                        )}
                    </Ds.Flex>
                </Card>
            </form>
        </>
    );
};
