import { useState } from "react";

import {
    Button,
    Ds,
    Popover,
    PopoverContent,
    PopoverItemButton,
    PopoverTrigger,
} from "@accurx/design";
import { Icon } from "@accurx/design/dist/ds";
import { ArchivedWorkspaceHiddenItemWrapper } from "@accurx/workspace-management";
import {
    StyledModalCloseButton,
    StyledModalHeader,
    StyledModalHeaderDivider,
} from "domains/navigation/styles/Modal.styles";
import styled from "styled-components";

export type ModalHeaderProps = {
    title?: string;
    onClickTestPatient?: (state: boolean) => void;
    onBack?: () => void;
    isSecondPanel?: boolean;
};

export const ModalHeader = ({
    title,
    onClickTestPatient,
}: ModalHeaderProps) => (
    <StyledModalHeader>
        <Ds.Modal.Title>{title || "Search patients"}</Ds.Modal.Title>

        <Ds.Flex justifyContent="flex-end" gap="0.5" alignItems="center">
            <ArchivedWorkspaceHiddenItemWrapper>
                {onClickTestPatient && (
                    <>
                        <Button
                            text="Use test patient"
                            onClick={() => onClickTestPatient(true)}
                            theme="transparent"
                        />
                        <StyledModalHeaderDivider />
                    </>
                )}
            </ArchivedWorkspaceHiddenItemWrapper>
            <StyledModalCloseButton />
        </Ds.Flex>
    </StyledModalHeader>
);

export const MobileModalHeader = ({
    onClickTestPatient,
    onBack,
    title,
    isSecondPanel = false,
}: ModalHeaderProps) => {
    const [open, setOpen] = useState(false);

    const BackButton = () => (
        <Button
            theme="transparent"
            onClick={onBack}
            icon={{
                name: "ArrowTail",
                rotation: "left",
                title: "Back",
            }}
        />
    );

    const handleClickTestPatient = () => {
        onClickTestPatient?.(true);
        setOpen(false);
    };

    return (
        <StyledModalHeader>
            {isSecondPanel ? <BackButton /> : <StyledModalCloseButton />}
            <Ds.Modal.Title>{title || "Search patients"}</Ds.Modal.Title>
            {!isSecondPanel && onClickTestPatient ? (
                <Popover open={open} onOpenChange={setOpen} modal>
                    <PopoverTrigger>
                        <Button
                            icon={{
                                name: "More",
                                title: "More",
                                style: "Fill",
                            }}
                            theme="transparent"
                        />
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverItemButton onClick={handleClickTestPatient}>
                            <Icon name="RecordPerson" size="small" />
                            <Ds.Text weight="bold">Use test patient</Ds.Text>
                        </PopoverItemButton>
                    </PopoverContent>
                </Popover>
            ) : (
                <ButtonPlaceholder />
            )}
        </StyledModalHeader>
    );
};

const ButtonPlaceholder = styled.div`
    height: 42px;
    width: 42px;
`;
