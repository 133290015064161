import { returnDataOrThrow } from "@accurx/shared";
import { useQuery } from "@tanstack/react-query";

import { getRecordViewRequestsForGp } from "api/RecordViewApi";
import { RecordViewRequestDto } from "app/practices/recordViewRequests/RequestsList.types";

export const useGpRecordRequestsQuery = ({
    workspaceId,
}: {
    workspaceId: number;
}) => {
    return useQuery<RecordViewRequestDto, Error, RecordViewRequestDto>({
        queryKey: ["GpRecordViewRequests", { workspaceId }],
        queryFn: async () => {
            const response = await getRecordViewRequestsForGp(
                String(workspaceId),
            );
            return returnDataOrThrow(response);
        },
        refetchOnMount: true,
    });
};
