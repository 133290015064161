import * as UI from "@accurx/design";
import styled from "styled-components";

export const Inner = styled.div<{ $withBorder: boolean }>`
    display: flex;
    flex-direction: column;
    flex: 1;

    width: 100%;
    max-width: ${UI.Tokens.BREAKPOINTS.md};

    background-color: ${UI.Tokens.COLOURS.greyscale.white};
    margin: ${UI.Tokens.SIZES[2]};

    ${({ $withBorder }) =>
        $withBorder
            ? `
        border-radius: ${UI.Tokens.BORDERS.radius};
        border: ${UI.Tokens.BORDERS.normal.silver};
        box-shadow: 0px ${UI.Tokens.SIZES[0.5]} ${UI.Tokens.SIZES[2]}
        rgba(67.83, 72.3, 125.97, 0.32);
        `
            : "border: 1px solid transparent;"}
`;

export const Outer = styled.div`
    display: flex;
    justify-content: center;

    flex: 1;
    width: 100%;
`;

export const StyledRestrictedWidthContainer = { Inner, Outer };
