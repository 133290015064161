import { ReactNode } from "react";

import { StyledRestrictedWidthContainer } from "./RestrictedWidthContainer.styles";

export const RestrictedWidthContainer = ({
    withBorder = true,
    children,
}: {
    withBorder?: boolean;
    children: ReactNode;
}) => {
    return (
        <StyledRestrictedWidthContainer.Outer>
            <StyledRestrictedWidthContainer.Inner $withBorder={withBorder}>
                {children}
            </StyledRestrictedWidthContainer.Inner>
        </StyledRestrictedWidthContainer.Outer>
    );
};
