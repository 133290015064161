import { mapPatientDemographicsNamesToPatientSummaryParts } from "@accurx/concierge/util/mapPatientDemographicsToPatientSummary";
import { ComposePatient } from "@accurx/message-component";
import { PatientDemographics } from "@accurx/native";

export const mapPatientDemographicsToComposePatient = (
    patient: PatientDemographics,
): ComposePatient => {
    const names = mapPatientDemographicsNamesToPatientSummaryParts(
        patient.patientName,
    );

    return {
        ...names,
        externalIds: patient.patientExternalIdentityDto.patientExternalIds,
        gender: patient.gender,
        dateOfBirth: patient.dateOfBirth,
    };
};
