import { useState } from "react";

import { BulkAppointmentImportStatus } from "@accurx/api/appointment";
import { useCurrentWorkspace } from "@accurx/auth";
import { Ds, Feedback } from "@accurx/design";
import { Button } from "@accurx/design/dist/ds/components/Button";
import { getUploadPagePath } from "domains/appointment-reminders/Routes";
import { useBulkAppointmentImportsForWorkspaceQuery } from "domains/appointment-reminders/hooks";
import { useHistory } from "react-router-dom";

import { PageContainer } from "../OverviewPage/components/PageContainer.styles";
import { UploadButtonWrapper } from "./components/UploadedAppointmentsPage.styles";
import { UploadedAppointmentsTable } from "./components/UploadedAppointmentsTable";

/**
 * This page is purposely designed for the new unified navigation
 */

const importFilters = {
    statuses: [
        BulkAppointmentImportStatus.Processing,
        BulkAppointmentImportStatus.Completed,
        BulkAppointmentImportStatus.Failed,
    ],
};

export const UploadedAppointmentsPage = () => {
    const {
        orgId: workspaceId,
        settings: { isAdminUser },
    } = useCurrentWorkspace();

    const { data, status } = useBulkAppointmentImportsForWorkspaceQuery(
        workspaceId.toString(),
        importFilters,
    );
    const [showProcessingError, setShowProcessingError] =
        useState<boolean>(false);

    const imports = status === "success" ? data : [];

    const history = useHistory();

    const handleUploadNewListClick = () => {
        if (
            imports.some(
                (x) => x.status === BulkAppointmentImportStatus.Processing,
            )
        ) {
            setShowProcessingError(true);
        } else {
            history.push(getUploadPagePath(workspaceId));
        }
    };

    return (
        <PageContainer>
            <Ds.Text as="h1" weight="bold" size={"large"}>
                Uploaded appointments
            </Ds.Text>
            {status === "loading" ? (
                <Ds.Spinner />
            ) : status === "error" ? (
                <Feedback
                    colour="error"
                    iconName="Failed"
                    title="There was an issue retrieving data"
                />
            ) : (
                <>
                    {showProcessingError && (
                        <Feedback
                            colour="warning"
                            iconName="Warning"
                            title="Please wait for upload processing to complete"
                        />
                    )}
                    {isAdminUser && (
                        <UploadButtonWrapper>
                            <Button
                                appearance="primary"
                                size="medium"
                                onClick={handleUploadNewListClick}
                            >
                                <Button.Icon name="Upload" />
                                Upload new list
                            </Button>
                        </UploadButtonWrapper>
                    )}
                    <UploadedAppointmentsTable imports={imports} />
                </>
            )}
        </PageContainer>
    );
};
