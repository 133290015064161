import { useFeatureFlag } from "@accurx/auth";
import { useAllPatientTriagesConversationGroupQuery } from "@accurx/concierge/hooks/queries/useAllPatientTriagesConversationGroupQuery";
import { getMostRecentItem } from "@accurx/concierge/util/getMostRecentItem";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import {
    ConversationFeed,
    ConversationFeedOptionsWrapper,
    ConversationListHeader,
    ConversationTabItem,
    ConversationTabs,
} from "domains/inbox/components/ConversationList/components";
import { ConversationPreview } from "domains/inbox/components/ConversationPreview/ConversationPreview";
import { Dropdown } from "domains/inbox/components/Dropdown/Dropdown";
import { DropdownItem } from "domains/inbox/components/Dropdown/DropdownItem";
import {
    useInboxLink,
    useInboxParams,
    useInboxTitle,
} from "domains/inbox/hooks/util";
import { useConversationFilterAnalytics } from "domains/inbox/hooks/util/useConversationFilterAnalytics";
import { useConversationListAnalytics } from "domains/inbox/hooks/util/useConversationListAnalytics";
import { userflowIds } from "domains/inbox/util";

import { ConversationSortOrder } from "../../components/ConversationSortOrder/ConversationSortOrder";
import {
    StyledAccessibleHeader,
    StyledConversationFeedContainer,
    StyledConversationList,
} from "../styles";

export const AllPatientTriages = () => {
    const { status, sort } = useInboxParams(["status", "sort"]);
    const link = useInboxLink();

    useInboxTitle(`All  patient triage reponses`);
    const groupQuery = useAllPatientTriagesConversationGroupQuery({
        status,
        sort,
    });

    const { trackTabClick } = useConversationListAnalytics();
    const { trackFilterButtonClick, trackFilterMenuItemClick } =
        useConversationFilterAnalytics();

    const canUseSorting = useFeatureFlag("UnifiedInboxSortingBuild");

    return (
        <>
            <StyledConversationList rows="auto 1fr">
                <UI.Cell>
                    <ConversationListHeader
                        heading={
                            <>
                                All conversations
                                <StyledAccessibleHeader>
                                    showing with patient triage filter applied
                                </StyledAccessibleHeader>
                            </>
                        }
                    />

                    <ConversationTabs>
                        <ConversationTabItem
                            active={status === "Open"}
                            link={link.update({
                                status: "Open",
                                sort: null,
                                conversationId: null,
                            })}
                            userflowId={userflowIds.conversationList.openTab}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Done",
                                    newTab: "Open",
                                    conversationParticipant: "WithPatient",
                                })
                            }
                        >
                            Open
                        </ConversationTabItem>

                        <ConversationTabItem
                            active={status === "Done"}
                            link={link.update({
                                status: "Done",
                                sort: null,
                                conversationId: null,
                            })}
                            userflowId={userflowIds.conversationList.doneTab}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Open",
                                    newTab: "Done",
                                    conversationParticipant: "WithPatient",
                                })
                            }
                        >
                            Done
                        </ConversationTabItem>
                    </ConversationTabs>
                </UI.Cell>

                <StyledConversationFeedContainer>
                    <ConversationFeedOptionsWrapper>
                        <UI.Ds.Flex.Item>
                            <Dropdown
                                icon={<Pill.Icon name="Tag" colour="blue" />}
                                dropDownDisplayName="Patient triages"
                                contentHeading="Filter"
                                onClick={(_, isOpen) => {
                                    if (!isOpen) {
                                        trackFilterButtonClick({
                                            filterName: "Patient triages",
                                            appOrigin: "ConversationList",
                                        });
                                    }
                                }}
                            >
                                <DropdownItem.ListItem>
                                    <DropdownItem.Link
                                        link={link.to("All", {
                                            status,
                                        })}
                                        onClick={() =>
                                            trackFilterMenuItemClick({
                                                filterName: "Show all",
                                                appOrigin: "ConversationList",
                                            })
                                        }
                                    >
                                        <DropdownItem.Icon name="Tag" />
                                        <DropdownItem.Text>
                                            Show all
                                        </DropdownItem.Text>
                                    </DropdownItem.Link>
                                </DropdownItem.ListItem>

                                <DropdownItem.ListItem>
                                    <DropdownItem.Divider fullWidth />
                                </DropdownItem.ListItem>

                                <DropdownItem.ListItem $mb={1}>
                                    <DropdownItem.Link
                                        link={link.to("AllPatientResponses", {
                                            status,
                                        })}
                                        onClick={() =>
                                            trackFilterMenuItemClick({
                                                filterName: "Patient responses",
                                                appOrigin: "ConversationList",
                                            })
                                        }
                                    >
                                        <DropdownItem.Icon name="Tag" />
                                        <DropdownItem.Text>
                                            Patient responses
                                        </DropdownItem.Text>
                                    </DropdownItem.Link>
                                </DropdownItem.ListItem>

                                <DropdownItem.ListItem>
                                    <DropdownItem.Link
                                        link={link.to("AllPatientTriages", {
                                            status,
                                        })}
                                        selected={true}
                                        onClick={() =>
                                            trackFilterMenuItemClick({
                                                filterName: "Patient triages",
                                                appOrigin: "ConversationList",
                                            })
                                        }
                                    >
                                        <DropdownItem.Icon name="Tag" />
                                        <DropdownItem.Text>
                                            Patient triages
                                        </DropdownItem.Text>
                                    </DropdownItem.Link>
                                </DropdownItem.ListItem>
                            </Dropdown>
                        </UI.Ds.Flex.Item>
                        {canUseSorting && (
                            <UI.Ds.Flex.Item>
                                <ConversationSortOrder minimise />
                            </UI.Ds.Flex.Item>
                        )}
                    </ConversationFeedOptionsWrapper>

                    <ConversationFeed
                        key={groupQuery.data?.id}
                        isLoading={groupQuery.status === "loading"}
                        isError={groupQuery.status === "error"}
                        refetch={groupQuery.refetch}
                        fetchMore={groupQuery.fetchMore}
                        isFetchingMore={groupQuery.isFetchingMore}
                        isFullyLoaded={groupQuery.data?.isFullyLoaded}
                        members={groupQuery.data?.members}
                        filters={groupQuery.data?.filters}
                        renderItem={(conversation, state) => (
                            <ConversationPreview
                                conversation={conversation}
                                item={getMostRecentItem(conversation)}
                                state={state}
                                displayAssigneeBadge
                            />
                        )}
                    />
                </StyledConversationFeedContainer>
            </StyledConversationList>
        </>
    );
};
