import { NativeApi } from "domains/native/api/NativeApi";
import { createEndpoint } from "domains/native/api/api.utils";

export type MutateForwardUnreadItemsPayload = {
    tickets: {
        ticketId: string;
        itemIds: string[];
    }[];
    isConnectedToSignalR: boolean;
};

export const mutateForwardUnreadItems = createEndpoint<
    void,
    MutateForwardUnreadItemsPayload
>("mutateForwardUnreadItems", async (context, payload) => {
    await NativeApi.request({
        transport: context.transport,
        request: {
            type: "MutateForwardUnreadItems",
            workspaceId: context.workspaceId,
            payload,
        },
    });
});
