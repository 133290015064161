import { Conversation } from "@accurx/concierge/types";
import { Ds } from "@accurx/design";

import { StyledOutcomeRecording } from "./OutcomeRecording.styles";
import { Header } from "./components/Header";
import { OutcomeLabels } from "./components/OutcomeLabels";

type OutcomeRecordingProps = {
    onClose: () => void;
    conversation: Conversation;
    title: string;
};

export const OutcomeRecording = ({
    conversation,
    onClose,
    title,
}: OutcomeRecordingProps) => {
    return (
        <StyledOutcomeRecording.Wrapper aria-live="polite">
            <Header text={title}>
                <Ds.Button
                    appearance="secondary"
                    size="small"
                    title="Close"
                    onClick={onClose}
                    autoFocus
                >
                    <Ds.Button.Icon name="Cross" aria-label="Close" />
                </Ds.Button>
            </Header>
            <StyledOutcomeRecording.Body>
                <OutcomeLabels
                    conversation={conversation}
                    onSelectOutcome={onClose}
                />
            </StyledOutcomeRecording.Body>
        </StyledOutcomeRecording.Wrapper>
    );
};
