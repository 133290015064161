import { useCallback, useEffect, useRef } from "react";

import { useCurrentUser, useCurrentWorkspace } from "@accurx/auth";
import { useBrowserEnvironment } from "@accurx/native";
import { Log } from "@accurx/shared";

/**
 * Monitoring the page lifcycle states via logs, with logic lifted from:
 * https://developer.chrome.com/docs/web-platform/page-lifecycle-api#how_to_observe_page_lifecycle_states_in_code
 */

type PageLifecycleState =
    | "hidden" // page not visible but tasks running
    | "active" // page visibile and focussed
    | "passive" // page visible but not focussed
    | "frozen" // page functionality suspended until unfrozen
    | "terminated"; // page inactive, no new tasks will run

const getCurrentState = (): PageLifecycleState => {
    if (document.visibilityState === "hidden") {
        return "hidden";
    }
    if (document.hasFocus()) {
        return "active";
    }
    return "passive";
};

const LOG_CURRENT_STATE_EVENTS = [
    "pageshow",
    "focus",
    "blur",
    "visibilitychange",
    "resume",
];

export const usePageLifecycle = () => {
    const { user } = useCurrentUser();
    const { orgId } = useCurrentWorkspace();
    const env = useBrowserEnvironment();

    const currentStateRef = useRef<PageLifecycleState>(getCurrentState());

    const logStateChange = useCallback(
        (nextState: PageLifecycleState) => {
            if (nextState === currentStateRef.current) return;
            if (
                [nextState, currentStateRef.current].every(
                    (state) => state === "active" || state === "passive",
                )
            ) {
                return; // switching between active and passive states maintains full inbox functioning
            }

            Log.info(
                `Page lifecycle state change: ${currentStateRef.current} -> ${nextState}`,
                {
                    tags: {
                        userId: user.accuRxUserId,
                        organisationId: orgId,
                        isWebView: env === "WebView",
                        previousPageLifecycleState: currentStateRef.current,
                        nextPageLifecycleState: nextState,
                        logCreatedTime: new Date().toISOString(),
                        wasDiscarded:
                            "wasDiscarded" in document
                                ? !!document.wasDiscarded
                                : null, // document.wasDiscarded may not be defined, so this makes its presence explicit
                    },
                },
            );
            currentStateRef.current = nextState;
        },
        [env, orgId, user.accuRxUserId],
    );

    const logCurrentStateChange = useCallback(() => {
        logStateChange(getCurrentState());
    }, [logStateChange]);

    const handlePageFreeze = useCallback(() => {
        logStateChange("frozen");
    }, [logStateChange]);

    const handlePageHide = useCallback(
        (event: Event) => {
            logStateChange(
                "persisted" in event && Boolean(event.persisted)
                    ? "frozen"
                    : "terminated",
            );
        },
        [logStateChange],
    );

    useEffect(() => {
        if (env !== "WebView") return;

        LOG_CURRENT_STATE_EVENTS.forEach((type) => {
            window.addEventListener(type, logCurrentStateChange);
        });

        window.addEventListener("freeze", handlePageFreeze);
        window.addEventListener("pageHide", handlePageHide);

        return () => {
            LOG_CURRENT_STATE_EVENTS.forEach((type) => {
                window.removeEventListener(type, logCurrentStateChange);
            });

            window.removeEventListener("freeze", handlePageFreeze);
            window.removeEventListener("pageHide", handlePageHide);
        };
    }, [logCurrentStateChange, handlePageFreeze, handlePageHide, env]);
};
