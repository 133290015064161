import { Conversation, PatientSummary } from "@accurx/concierge/types";
import { useComposeAreaVisibility } from "domains/inbox/components/ConversationView/ComposeAreaVisibilityContext/useComposeAreaVisibility";

import { useButtonClickAnalytics } from "../../useButtonClickAnalytics";
import { NoteContents } from "../Contents/NoteContents";
import { MessageActionButton } from "../MessageActionButton/MessageActionButton";

type NoteProps = {
    patient?: PatientSummary;
    conversation: Conversation;
    setSentNoteStatus: (status: string | null) => void;
    isExpanded: boolean;
    onExpandClick: () => void;
};

export const Note = ({
    patient,
    conversation,
    setSentNoteStatus,
    isExpanded,
    onExpandClick,
}: NoteProps) => {
    const { noteState, setNoteState } = useComposeAreaVisibility();
    const { trackNoteButtonClick } = useButtonClickAnalytics(conversation);

    if (noteState === "Closed") {
        return (
            <MessageActionButton
                onClick={() => {
                    setNoteState("Open");
                    trackNoteButtonClick();
                }}
                text="Add note"
                theme="secondary"
                icon="Document"
            />
        );
    }

    return (
        <NoteContents
            conversation={conversation}
            patient={patient}
            isExpanded={isExpanded}
            onExpandClick={onExpandClick}
            setSentNoteStatus={setSentNoteStatus}
        />
    );
};
