import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledContainer = styled(UI.Ds.Flex).attrs({ gap: "1" })<{
    $reducedPadding?: boolean;
}>`
    background: ${UI.Tokens.COLOURS.greyscale.white};
    border-bottom: 1px solid ${UI.Tokens.COLOURS.greyscale.dishwater};

    padding: ${UI.Tokens.SIZES[2]} ${UI.Tokens.SIZES[2]};
    padding-left: ${({ $reducedPadding }) =>
        $reducedPadding && UI.Tokens.SIZES[1]};
`;
