import { useMemo } from "react";

import { useFeatureFlag } from "@accurx/auth";
import { FloreyResponseItem } from "@accurx/concierge/types";
import { itemHasAttachments } from "@accurx/concierge/util/itemHasAttachments";
import { Ds } from "@accurx/design";
import { useMedicalRecordConnection } from "@accurx/native";

import { DownloadAsPDFButton } from "../../components/DownloadAsPDFButton/DownloadAsPDFButton";
import { SaveFloreyPdfToRecordButton } from "../../components/SaveItemAction/SaveFloreyPdfToRecordButton";
import { SaveItemAction } from "../../components/SaveItemAction/SaveItemAction";
import { ConversationItemComponent } from "../../types";

/**
 * See PR https://github.com/Accurx/rosemary/pull/34102
 * for a visual matrix of which buttons should show when
 */
export const FloreyResponseNoteActionButtons = ({
    item,
    conversation,
    copyInfo,
    downloadAsPdfUrl,
}: {
    downloadAsPdfUrl: string;
    copyInfo: () => string;
} & ConversationItemComponent<FloreyResponseItem>) => {
    const hasAttachments = useMemo(() => itemHasAttachments(item), [item]);
    const medicalRecordConnection = useMedicalRecordConnection();
    const canSaveToRecord =
        medicalRecordConnection.capabilities?.saveToRecord === true;
    const hasBeenSavedAlready = item.saveToRecordState.status === "Saved";

    /**
     * UHL integration
     * When this feature flag is enabled, the user
     * might be able to save a PDF version of the Florey
     * to their medical system.
     * When enabled, the user also needs
     * - to not have a native integration available
     * - the item must not have been previously saved to a
     * medical record
     */
    const isSavePdfToRecordEnabled = useFeatureFlag(
        "CitoDocumentStoreIntegration",
    );

    return (
        <Ds.Flex
            gap="1"
            flexDirection="column"
            data-testid="save-questionnaire-actions"
        >
            {canSaveToRecord ? (
                <SaveItemAction
                    conversation={conversation}
                    item={item}
                    getTextToCopy={copyInfo}
                    hasAttachments={hasAttachments}
                />
            ) : (
                <>
                    {(!isSavePdfToRecordEnabled || hasBeenSavedAlready) && (
                        <SaveItemAction
                            conversation={conversation}
                            item={item}
                            getTextToCopy={copyInfo}
                            hasAttachments={hasAttachments}
                        />
                    )}
                    {isSavePdfToRecordEnabled && !hasBeenSavedAlready && (
                        <SaveFloreyPdfToRecordButton item={item} />
                    )}
                    {/* Show the save as PDF button if save to record capability is missing
                    i.e. disconnected from medical record, non integrated or connected but
                    system does not support save to record
                */}
                    <Ds.Flex.Item>
                        <DownloadAsPDFButton downloadUrl={downloadAsPdfUrl} />
                    </Ds.Flex.Item>
                </>
            )}
        </Ds.Flex>
    );
};
