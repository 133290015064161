import { useCallback, useState } from "react";

import { FeatureName, useAuth } from "@accurx/auth";
import { Button, Ds, Flex, Item, Spinner, Text } from "@accurx/design";
import { Log } from "@accurx/shared";
import { isArchivedWorkspace } from "@accurx/workspace-management";
import { useDispatch } from "react-redux";
import { Modal } from "reactstrap";

import { TemplateInList } from "app/workspaceConversations/components/MessageTemplates/MessageTemplates.types";
import { useCurrentOrgId, useIsFeatureEnabled } from "store/hooks";

import { actionCreators } from "./MessageTemplatesActions";
import {
    ManageMessageTemplate,
    ManageTemplateActionType,
} from "./messageTemplatesEditCreate/ManageMessageTemplate";

export interface MessageTemplateCardProps {
    template: TemplateInList;
    showAvailabilitySwitches?: boolean;
}

const MessageTemplateCard = ({
    template,
    showAvailabilitySwitches,
}: MessageTemplateCardProps): JSX.Element => {
    const dispatch = useDispatch();

    const organisationId = useCurrentOrgId();

    const { user } = useAuth();

    const workspace = user.isLoggedIn
        ? user.organisations.find((w) => w.orgId === organisationId)
        : null;

    const isArchived = workspace && isArchivedWorkspace(workspace);

    const [currentPendingAction, setCurrentPendingAction] = useState<
        ManageTemplateActionType.Edit | ManageTemplateActionType.Delete | null
    >(ManageTemplateActionType.Edit);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Feature flags
    const webBatchEnabled = useIsFeatureEnabled(FeatureName.WebBatchMessaging);

    const handleModalClose = () => {
        setCurrentPendingAction(null);
        setIsModalOpen(false);
    };

    const toggleAvailability = useCallback(
        (type: "Sms" | "Batch") => {
            if (organisationId === null || template.id === null) {
                Log.error(
                    "Attempted to update template availability but required info is missing",
                );
                return;
            }

            dispatch(
                actionCreators.requestTemplateAvailabilityUpdate(
                    organisationId,
                    template.id,
                    type,
                ),
            );
        },
        [organisationId, template, dispatch],
    );

    const handleToggleIndividualAvailability = useCallback(() => {
        toggleAvailability("Sms");
    }, [toggleAvailability]);

    const handleToggleBatchAvailability = useCallback(() => {
        toggleAvailability("Batch");
    }, [toggleAvailability]);

    const handleEditTemplate = (): void => {
        setCurrentPendingAction(ManageTemplateActionType.Edit);
        setIsModalOpen(true);
    };

    const handleDeleteTemplate = (): void => {
        setCurrentPendingAction(ManageTemplateActionType.Delete);
        setIsModalOpen(true);
    };

    const handleModalSuccess = useCallback((): void => {
        // Delete success will result in this component disappearing, so we just return
        if (currentPendingAction === ManageTemplateActionType.Delete) {
            return;
        } else {
            handleModalClose();
        }
    }, [currentPendingAction]);

    return (
        <>
            <div className="message-template-card d-md-flex d-block justify-content-between align-items-center">
                <div className="mb-2 mb-md-0">
                    <Text
                        as="h4"
                        skinny
                        props={{ className: "d-flex align-items-center" }}
                    >
                        <Text as="span" variant="label" skinny>
                            {template.title}
                        </Text>
                        {template.isDefault && (
                            <Ds.Badge
                                color="greyscale"
                                luminosity="low"
                                className="ml-2"
                            >
                                Current default
                            </Ds.Badge>
                        )}
                    </Text>
                    <Text skinny>{template.body}</Text>
                </div>
                <Item flex="0 0 360px">
                    <Flex gap="2" flexWrap="wrap" justifyContent="flex-end">
                        {webBatchEnabled &&
                            showAvailabilitySwitches &&
                            !isArchived && (
                                <Flex
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    gap="2"
                                >
                                    <Ds.Switch
                                        checked={
                                            template.isAllowedAsSms || false
                                        }
                                        onCheckedChange={
                                            handleToggleIndividualAvailability
                                        }
                                    >
                                        <Ds.Switch.Label>
                                            Individual
                                        </Ds.Switch.Label>
                                        <Ds.Switch.Toggle />
                                    </Ds.Switch>
                                    <Ds.Switch
                                        checked={
                                            template.isAllowedAsBatch || false
                                        }
                                        onCheckedChange={
                                            handleToggleBatchAvailability
                                        }
                                    >
                                        <Ds.Switch.Label>Batch</Ds.Switch.Label>
                                        <Ds.Switch.Toggle />
                                    </Ds.Switch>
                                </Flex>
                            )}
                        {!isArchived && (
                            <Flex
                                justifyContent="flex-end"
                                alignItems="center"
                                gap="1"
                            >
                                <Button
                                    text="Edit"
                                    onClick={handleEditTemplate}
                                />
                                <Button
                                    theme="secondary"
                                    text="Delete"
                                    className="text-nowrap"
                                    onClick={handleDeleteTemplate}
                                />
                            </Flex>
                        )}
                    </Flex>
                </Item>
            </div>
            {isModalOpen && (
                <Modal
                    isOpen={isModalOpen}
                    toggle={handleModalClose}
                    unmountOnClose
                    backdrop="static"
                >
                    {currentPendingAction === null && <Spinner />}
                    {currentPendingAction !== null && (
                        <ManageMessageTemplate
                            actionType={currentPendingAction}
                            template={template}
                            onCancelAction={handleModalClose}
                            onSuccessAction={handleModalSuccess}
                        />
                    )}
                </Modal>
            )}
        </>
    );
};

export default MessageTemplateCard;
