import { ReactNode, useState } from "react";

import {
    FeatureName,
    useAuth,
    useCurrentUser,
    useCurrentWorkspace,
    useFeatureFlag,
} from "@accurx/auth";
import {
    Ds,
    Popover,
    PopoverTrigger,
    Tooltip,
    TooltipContent,
    TooltipPortal,
    TooltipTrigger,
} from "@accurx/design";
import {
    StyledDivider,
    StyledPopoverContent,
    StyledPopoverItem,
    StyledPopoverItemWithSpaceBetween,
} from "@accurx/navigation";
import {
    isMyWorkspace,
    usePendingApproval,
} from "@accurx/workspace-management";
import { cleanUpSession } from "appLifecycle";
import { userflowIds } from "layouts/constants";
import { generatePath, useHistory } from "react-router-dom";
import { useAnalytics } from "reduxQuarantine/useAnalytics";

import { ROUTES_WORKSPACE } from "shared/Routes";

import { getNavigationVersion } from "../../helpers";
import { HelpPopoverContent } from "../HelpPopover/HelpPopover";
import { UnapprovedUsersBadge } from "../UnapprovedUsersBadge/UnapprovedUsersBadge";
import { WorkspaceSwitcherContent } from "../WorkspaceSwitcher/WorkspaceSwitcher";
import { PopoverSubMenu } from "./PopoverSubMenu";
import {
    StyledTooltipTriggerWrapper,
    StyledWorkspaceMenuButton,
} from "./WorkspaceMenu.styles";
import { WorkspaceMenuHeader } from "./WorkspaceMenuHeader";

const WorkspaceMenuTooltip = ({ children }: { children: ReactNode }) => {
    const workspace = useCurrentWorkspace();
    const { organisationName: workspaceName, organisationNodeName } = workspace;
    const { user } = useCurrentUser();
    const isFlexibleWorkspaceEnabled = useFeatureFlag(
        FeatureName.FlexibleWorkspace,
    );

    return (
        <Tooltip>
            <StyledTooltipTriggerWrapper>
                <TooltipTrigger tabIndex={-1} asChild>
                    {children}
                </TooltipTrigger>
            </StyledTooltipTriggerWrapper>
            <TooltipPortal>
                <TooltipContent side="bottom" align="center">
                    <Ds.Flex flexDirection="column" alignItems="center">
                        <Ds.Text weight="bold">
                            {isMyWorkspace(workspace)
                                ? "My Workspace"
                                : workspaceName}
                        </Ds.Text>
                        {isFlexibleWorkspaceEnabled && (
                            <Ds.Text weight="bold">
                                {organisationNodeName}
                            </Ds.Text>
                        )}
                        <Ds.Text>{user.fullName}</Ds.Text>
                    </Ds.Flex>
                </TooltipContent>
            </TooltipPortal>
        </Tooltip>
    );
};

export const WorkspaceMenu = ({
    children,
    isMobile = false,
}: {
    children?: ReactNode;
    isMobile?: boolean;
}) => {
    const workspace = useCurrentWorkspace();
    const { orgId: workspaceId, organisationName: workspaceName } = workspace;
    const { user } = useCurrentUser();
    const { logOut } = useAuth();
    const { isPendingApproval } = usePendingApproval(workspaceId);

    const track = useAnalytics();
    const history = useHistory();

    const [popoverOpen, setPopoverOpen] = useState(false);

    // Mobile-specific
    const [workspaceSwitcherOpen, setWorkspaceSwitcherOpen] = useState(false);
    const [helpMenuOpen, setHelpMenuOpen] = useState(false);

    const trackMenuItemClick = (
        optionSelected:
            | "Settings"
            | "Users"
            | "Reporting"
            | "Logout"
            | "Help"
            | "WorkspaceSwitcher",
    ) => {
        track("NavigationOrganisation MenuItem Click", {
            eventVersion: 2,
            navigationVersion: getNavigationVersion(),
            origin: history.location.pathname,
            optionSelected,
        });
    };

    const handleOpenChange = (open: boolean) => {
        if (open) {
            setWorkspaceSwitcherOpen(false);
            setHelpMenuOpen(false);
            setPopoverOpen(true);
            track("NavigationMenu Button Click", {
                origin: history.location.pathname,
                navigationOptionSelected: "WorkspaceMenu",
                navigationVersion: getNavigationVersion(),
                eventVersion: 2,
            });
        } else {
            setPopoverOpen(false);
        }
    };

    const goToManageTemplates = () => {
        trackMenuItemClick("Settings");
        history.push(
            generatePath("/w/:workspaceId/settings/templates", {
                workspaceId,
            }),
        );
        setPopoverOpen(false);
    };

    const goToUsers = () => {
        trackMenuItemClick("Users");
        history.push(
            generatePath("/w/:workspaceId/settings/users", {
                workspaceId,
            }),
        );
        setPopoverOpen(false);
    };

    const goToReporting = () => {
        trackMenuItemClick("Reporting");
        history.push(
            generatePath(ROUTES_WORKSPACE.reporting, {
                workspaceId,
            }),
        );
        setPopoverOpen(false);
    };

    const handleLogOut = () => {
        trackMenuItemClick("Logout");
        setPopoverOpen(false);
        logOut({ cleanUpFn: cleanUpSession });
    };

    const handleHelpMenuClick = () => {
        setHelpMenuOpen(true);
        trackMenuItemClick("Help");
    };

    const handleWorkspaceSwitcherMenuClick = () => {
        setWorkspaceSwitcherOpen(true);
        trackMenuItemClick("WorkspaceSwitcher");
    };

    return (
        <Popover open={popoverOpen} onOpenChange={handleOpenChange}>
            {isMobile ? (
                <PopoverTrigger asChild>
                    {children || (
                        <StyledWorkspaceMenuButton
                            data-userflow-id={
                                userflowIds.navigation.primary.workspaceMenu
                            }
                            aria-label={`${workspaceName} workspace menu`}
                        >
                            <Ds.Icon name="Cog" size="small" color="white" />
                        </StyledWorkspaceMenuButton>
                    )}
                </PopoverTrigger>
            ) : (
                <WorkspaceMenuTooltip>
                    <PopoverTrigger asChild>
                        {children || (
                            <StyledWorkspaceMenuButton
                                data-userflow-id={
                                    userflowIds.navigation.primary.workspaceMenu
                                }
                                aria-label={`${workspaceName} workspace menu`}
                            >
                                <Ds.Icon
                                    name="Cog"
                                    size="small"
                                    color="white"
                                />
                                {workspaceName}
                                {isPendingApproval && (
                                    <Ds.Icon
                                        name="LockLocked"
                                        appearance="solid"
                                        size="xsmall"
                                        color="white"
                                    />
                                )}
                            </StyledWorkspaceMenuButton>
                        )}
                    </PopoverTrigger>
                </WorkspaceMenuTooltip>
            )}
            {helpMenuOpen ? (
                <HelpPopoverContent
                    onClose={() => setPopoverOpen(false)}
                    workspaceId={workspaceId}
                />
            ) : workspaceSwitcherOpen ? (
                <WorkspaceSwitcherContent
                    onClose={() => setPopoverOpen(false)}
                />
            ) : (
                <StyledPopoverContent side="bottom" align="end">
                    <Ds.Box m="1">
                        <WorkspaceMenuHeader
                            workspace={workspace}
                            user={user}
                        />
                    </Ds.Box>

                    {isPendingApproval && (
                        <Ds.Box mx="1" mb="1.5">
                            <Ds.Badge color="greyscale">
                                <Ds.Icon
                                    name="LockLocked"
                                    appearance="solid"
                                    size="xsmall"
                                    color="night"
                                />
                                Pending approval
                            </Ds.Badge>
                        </Ds.Box>
                    )}

                    <StyledPopoverItem
                        onClick={goToManageTemplates}
                        data-userflow-id={
                            userflowIds.navigation.primary.workspaceMenuSettings
                        }
                    >
                        <Ds.Icon name="Cog" size="small" color="stone" />
                        <Ds.Text weight="bold" color="zinc">
                            Settings
                        </Ds.Text>
                    </StyledPopoverItem>

                    {workspace.settings.isApprovedUser && (
                        <StyledPopoverItemWithSpaceBetween
                            onClick={goToUsers}
                            data-userflow-id={
                                userflowIds.navigation.primary
                                    .workspaceMenuUsers
                            }
                        >
                            <Ds.Flex gap="1">
                                <Ds.Icon
                                    name="Team"
                                    size="small"
                                    color="stone"
                                />
                                <Ds.Text weight="bold" color="zinc">
                                    Users
                                </Ds.Text>
                            </Ds.Flex>
                            <UnapprovedUsersBadge />
                        </StyledPopoverItemWithSpaceBetween>
                    )}

                    {workspace.settings.isApprovedUser && (
                        <StyledPopoverItem
                            onClick={goToReporting}
                            data-userflow-id={
                                userflowIds.navigation.primary
                                    .workspaceMenuReporting
                            }
                        >
                            <Ds.Icon name="Chart" size="small" color="stone" />
                            <Ds.Text weight="bold" color="zinc">
                                Reporting
                            </Ds.Text>
                        </StyledPopoverItem>
                    )}

                    <StyledDivider />

                    {isMobile && (
                        <>
                            <PopoverSubMenu
                                onClick={handleHelpMenuClick}
                                data-userflow-id={
                                    userflowIds.navigation.primary
                                        .workspaceMenuHelp
                                }
                            >
                                <Ds.Icon
                                    name="Question"
                                    appearance="outline"
                                    size="small"
                                    color="stone"
                                />
                                <Ds.Text weight="bold" color="zinc">
                                    Help
                                </Ds.Text>
                            </PopoverSubMenu>

                            <StyledDivider />

                            <PopoverSubMenu
                                onClick={handleWorkspaceSwitcherMenuClick}
                                data-userflow-id={
                                    userflowIds.navigation.primary
                                        .workspaceMenuWorkspaceSwitcher
                                }
                                data-testid="primary-nav-workspace-switcher-button"
                            >
                                <Ds.Icon
                                    name="ArrowTwoWay"
                                    appearance="solid"
                                    size="small"
                                    color="stone"
                                    rotate="90"
                                />
                                <Ds.Text weight="bold" color="zinc">
                                    Switch workspace
                                </Ds.Text>
                            </PopoverSubMenu>
                        </>
                    )}

                    <StyledPopoverItem
                        onClick={handleLogOut}
                        data-userflow-id={
                            userflowIds.navigation.primary.workspaceMenuLogout
                        }
                    >
                        <Ds.Icon
                            name="preview-Logout"
                            size="small"
                            color="stone"
                        />
                        <Ds.Text weight="bold" color="zinc">
                            Log out
                        </Ds.Text>
                    </StyledPopoverItem>
                </StyledPopoverContent>
            )}
        </Popover>
    );
};
