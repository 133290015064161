import { useCompose } from "domains/message-component/context";

import { MessageContentPlaceholder } from "../../MessageContentPlaceholder/MessageContentPlaceholder";

export const ComposeMessageContentPlaceholder = () => {
    const { state } = useCompose();

    return (
        <MessageContentPlaceholder
            nhsAdviceLink={state.nhsAdviceLink}
            isPatientResponseEnabled={state.isPatientResponseEnabled}
            attachmentsCount={state.attachments.length}
            isQuestionnaire={state.template.type === "QuestionnaireTemplate"}
            hasSelfBookLink={state.selfBookLink !== null}
            selfBookTimeoutDays={state.selfBookLink?.timeoutDays}
        />
    );
};
