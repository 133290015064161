import {
    AssigneeType,
    MessagePatientRequestBase,
} from "@accurx/api/patient-messaging";
import { AssignOnSendAssignee } from "domains/message-component/hooks/mutations/useSendMessageMutation";

export const mapAssigneeToAsigneeDto = (
    composeAssignee: AssignOnSendAssignee | null,
): Exclude<MessagePatientRequestBase["assignee"], undefined> => {
    if (composeAssignee === null) {
        return null;
    }

    switch (composeAssignee.type) {
        case "User":
            return {
                type: AssigneeType.User,
                userId: composeAssignee.id,
                userGroupId: null,
            };
        case "Team":
            return {
                type: AssigneeType.UserGroup,
                userGroupId: composeAssignee.id,
                userId: null,
            };
    }
};
