import { Ds } from "@accurx/design";
import styled from "styled-components";

export const SetupPatientTriageContainer = styled.div`
    padding-left: 1.5rem;
    padding-right: 1.5rem;
`;

export const SetupPatientTriageArticleWrapper = styled(Ds.Flex).attrs(() => ({
    flexDirection: "column",
    gap: "1",
}))`
    button {
        box-shadow: none;
    }
`;
