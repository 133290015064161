import { ReactNode } from "react";

import { createRouterState as createRouterStatePatientForCompose } from "@accurx/compose";
import { ComposeNewMessageContext } from "@accurx/inbox";
import { PatientDemographics } from "@accurx/native";
import { useFullScreenNavigate } from "@accurx/navigation";

export const ComposeNewMessageProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const fullScreenNavigate = useFullScreenNavigate();

    const onNavigateToCompose = ({
        patientDemographics,
        workspaceId,
    }: {
        patientDemographics: PatientDemographics;
        workspaceId: number;
    }) =>
        fullScreenNavigate(
            `/w/${workspaceId}/compose/inbox-compose`,
            createRouterStatePatientForCompose({
                patientDemographics,
                appOrigin: "Inbox",
            }),
        );

    return (
        <ComposeNewMessageContext.Provider
            value={{
                onNavigateToCompose,
            }}
        >
            {children}
        </ComposeNewMessageContext.Provider>
    );
};
