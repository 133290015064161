import { returnDataOrThrow } from "@accurx/api/QueryClient";
import {
    GetTemplatesResponse,
    Owner,
    Recipient,
    SendAvailability,
} from "@accurx/api/content";
import {
    IWrappedResult,
    Log,
    getApiEndpoint,
    httpClient,
} from "@accurx/shared";
import { useQuery } from "@tanstack/react-query";

type Filters = {
    owner?: Owner;
    recipient?: Recipient;
    sendAvailability?: SendAvailability;
};

export const getMessageTemplates = ({
    workspaceId,
    filters,
}: {
    workspaceId: number;
    filters: Filters;
}): Promise<IWrappedResult<GetTemplatesResponse>> => {
    const filterQuery = new URLSearchParams(filters);

    return httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/content/workspace/:workspaceId/templates",
            params: {
                workspaceId: workspaceId.toString(),
            },
            query: `?${filterQuery.toString()}`,
        }),
    );
};

export const useMessageTemplates = (
    workspaceId: number,
    filters: Filters = {},
) =>
    useQuery({
        queryKey: ["GetMessageTemplates", { workspaceId, ...filters }],
        queryFn: async () => {
            if (!workspaceId) {
                Log.error("Could not fetch templates: missing workspace id");
                throw new Error(
                    "Could not fetch templates: missing workspace id",
                );
            }

            return getMessageTemplates({ workspaceId, filters }).then(
                returnDataOrThrow,
            );
        },
        refetchOnMount: true,
    });
