import { useFeatureFlag } from "@accurx/auth";
import { useQueryStatus } from "@accurx/concierge/hooks/data/useQueryStatus";
import { useUnassignedTeamClinician } from "@accurx/concierge/hooks/data/useUnassignedTeam";
import { useTeamClinicianConversationGroupQuery } from "@accurx/concierge/hooks/queries/useTeamClinicianConversationGroupQuery";
import { TeamSummary } from "@accurx/concierge/types";
import { getMostRecentItem } from "@accurx/concierge/util/getMostRecentItem";
import * as UI from "@accurx/design";
import {
    ConversationFeed,
    ConversationFeedOptionsWrapper,
    ConversationListHeader,
    ConversationTabItem,
    ConversationTabs,
} from "domains/inbox/components/ConversationList/components";
import { ConversationFeedErrorState } from "domains/inbox/components/ConversationList/components/ConversationFeed/ConversationFeedErrorState";
import { ConversationFeedLoadingSkeleton } from "domains/inbox/components/ConversationList/components/ConversationFeed/ConversationFeedLoadingSkeleton";
import { ClinicianConversationPreview } from "domains/inbox/components/ConversationPreview/ConversationPreview";
import { EXTENDED_CONVERSATION_PREVIEW_HEIGHT } from "domains/inbox/components/ConversationPreview/ConversationPreview.styles";
import { NotificationsDropdown } from "domains/inbox/components/NotificationsDropdown/NotificationsDropdown";
import {
    useInboxLink,
    useInboxParams,
    useInboxTitle,
} from "domains/inbox/hooks/util";
import { useConversationListAnalytics } from "domains/inbox/hooks/util/useConversationListAnalytics";
import { userflowIds } from "domains/inbox/util";

import { ConversationSortOrder } from "../../components/ConversationSortOrder/ConversationSortOrder";
import { getEmptyStateVariant } from "../../components/EmptyState/EmptyState";
import {
    StyledAccessibleHeader,
    StyledConversationFeedContainer,
    StyledConversationList,
} from "../styles";

const UnassignedTeamLoader = (props: {
    children: (arg: {
        team: TeamSummary | undefined;
        queryStatus: "loading" | "success" | "error";
    }) => JSX.Element;
}) => {
    const { status } = useQueryStatus("clinicianMessagingTeams");
    const unassignedTeam = useUnassignedTeamClinician();

    return props.children({ team: unassignedTeam, queryStatus: status });
};

const EmptyConversationFeed = () => {
    return (
        <ConversationFeed
            isFullyLoaded
            members={[]}
            filters={[]}
            renderItem={(conversation, state) => (
                <ClinicianConversationPreview
                    conversation={conversation}
                    item={getMostRecentItem(conversation)}
                    state={state}
                />
            )}
            itemHeight={EXTENDED_CONVERSATION_PREVIEW_HEIGHT}
        />
    );
};

const UnassignedConversationFeed = ({ team }: { team: TeamSummary }) => {
    const { status, sort } = useInboxParams(["status", "sort"]);
    const groupQuery = useTeamClinicianConversationGroupQuery({
        teamId: team.id,
        status,
        sort,
    });
    const emptyStateVariant = getEmptyStateVariant(status);
    const canUseSorting = useFeatureFlag("UnifiedInboxSortingBuild");

    return (
        <StyledConversationFeedContainer>
            {canUseSorting && (
                <ConversationFeedOptionsWrapper>
                    <UI.Ds.Flex.Item>
                        <ConversationSortOrder />
                    </UI.Ds.Flex.Item>
                </ConversationFeedOptionsWrapper>
            )}
            <ConversationFeed
                key={groupQuery.data?.id}
                isLoading={groupQuery.status === "loading"}
                isError={groupQuery.status === "error"}
                refetch={groupQuery.refetch}
                fetchMore={groupQuery.fetchMore}
                isFetchingMore={groupQuery.isFetchingMore}
                isFullyLoaded={groupQuery.data?.isFullyLoaded}
                members={groupQuery.data?.members}
                filters={groupQuery.data?.filters}
                emptyStateVariant={emptyStateVariant}
                renderItem={(conversation, state) => (
                    <ClinicianConversationPreview
                        conversation={conversation}
                        item={getMostRecentItem(conversation)}
                        state={state}
                    />
                )}
                itemHeight={EXTENDED_CONVERSATION_PREVIEW_HEIGHT}
            />
        </StyledConversationFeedContainer>
    );
};

export const ClinicianUnassigned = () => {
    const { status } = useInboxParams(["status"]);
    const link = useInboxLink();

    const { trackTabClick } = useConversationListAnalytics();

    useInboxTitle(
        `Unassigned ${status.toLowerCase()} conversations with healthcare providers`,
    );

    return (
        <UnassignedTeamLoader>
            {({ team, queryStatus }) => (
                <StyledConversationList rows="auto 1fr">
                    <UI.Cell>
                        <ConversationListHeader
                            heading={
                                <>
                                    Unassigned
                                    <StyledAccessibleHeader>
                                        showing {status.toLowerCase()} messages
                                    </StyledAccessibleHeader>
                                </>
                            }
                            notifications={
                                <NotificationsDropdown team={team} />
                            }
                        />
                        <ConversationTabs>
                            <ConversationTabItem
                                active={status === "Open"}
                                link={link.update({
                                    status: "Open",
                                    sort: null,
                                    conversationId: null,
                                })}
                                userflowId={
                                    userflowIds.conversationList.openTab
                                }
                                onClick={() =>
                                    trackTabClick({
                                        currentTab: "Done",
                                        newTab: "Open",
                                        conversationParticipant: "WithHcp",
                                    })
                                }
                            >
                                Open
                            </ConversationTabItem>

                            <ConversationTabItem
                                active={status === "Done"}
                                link={link.update({
                                    status: "Done",
                                    sort: null,
                                    conversationId: null,
                                })}
                                userflowId={
                                    userflowIds.conversationList.doneTab
                                }
                                onClick={() =>
                                    trackTabClick({
                                        currentTab: "Open",
                                        newTab: "Done",
                                        conversationParticipant: "WithHcp",
                                    })
                                }
                            >
                                Done
                            </ConversationTabItem>
                        </ConversationTabs>
                    </UI.Cell>
                    {queryStatus === "error" && (
                        <ConversationFeedErrorState
                            onClick={() => {
                                window.location.reload();
                            }}
                        />
                    )}
                    {queryStatus === "loading" && (
                        <ConversationFeedLoadingSkeleton />
                    )}
                    {queryStatus === "success" && !!team && (
                        <UnassignedConversationFeed team={team} />
                    )}
                    {queryStatus === "success" && !team && (
                        <EmptyConversationFeed />
                    )}
                </StyledConversationList>
            )}
        </UnassignedTeamLoader>
    );
};
