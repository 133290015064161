import { useFullScreen } from "layouts/UnifiedLayout/UnifiedLayoutContext";

import { StyledContainer } from "app/layout/Container";
import { VaccineDeliveryUploadAndReview } from "app/practices/vaccine/vaccineInvitesUploadAndCompose/VaccineDeliveryUploadAndReview";

export const InvitePatientsUploadPage = () => {
    useFullScreen();

    return (
        <StyledContainer>
            <VaccineDeliveryUploadAndReview />
        </StyledContainer>
    );
};
