import { Conversation } from "@accurx/concierge/types";
import { useViewportSize } from "domains/inbox/hooks/util";

import { ReplyState } from "../../ConversationView.types";
import { StyledExpandableContainer } from "./MessageActions.styles";
import { ClinicianReply } from "./components/Actions/ClinicianReply";

export const ClinicianMessageActions = ({
    conversation,
    onExpandClick,
    isExpanded,
    replyState,
    setReplyState,
}: {
    conversation: Conversation;
    onExpandClick: () => void;
    isExpanded: boolean;
    replyState: ReplyState;
    setReplyState: (state: ReplyState) => void;
}) => {
    const viewPort = useViewportSize();

    // No message actions are available when the conversation is Done
    if (conversation.status === "Done") {
        return null;
    }

    return (
        <StyledExpandableContainer
            $isExpanded={isExpanded}
            $isFloating={replyState !== "Open"}
            $isMobile={viewPort === "xs" || viewPort === "sm"}
        >
            <ClinicianReply
                conversation={conversation}
                replyState={replyState}
                setReplyState={setReplyState}
                isExpanded={isExpanded}
                onExpandClick={onExpandClick}
            />
        </StyledExpandableContainer>
    );
};
