import { ReactNode, createContext, useContext, useState } from "react";

import * as UI from "@accurx/design";
import { Ds } from "@accurx/design";
import {
    PatientDemographics,
    useBrowserEnvironment,
    useMedicalRecordConnection,
    useNativeSubscription,
} from "@accurx/native";
import { useLocation } from "react-router-dom";

import { mapDemographicsToCurrentPatient } from "../../../utils/mapDemographicsToCurrentPatient";
import { mapRouterStateToCurrentPatient } from "../../../utils/mapRouterStateToCurrentPatient";
import { RouterState } from "../../Routes.types";
import { DisconnectedEmrErrorPage } from "../../components/DisconnectedEmrErrorPage/DisconnectedEmrErrorPage";
import { MedicalRecordPatientUpdater } from "../components/MedicalRecordPatientUpdater.tsx/MedicalRecordPatientUpdater";
import { StyledSpinnerWrapper } from "./CurrentPatientProvider.styles";
import { CurrentPatient } from "./CurrentPatientProvider.types";

type CurrentPatientStatusSuccess = {
    currentPatient: CurrentPatient | null;
};

type CurrentPatientContextType = CurrentPatientStatusSuccess;

const CurrentPatientContext = createContext<CurrentPatientContextType | null>(
    null,
);

export const LocationStatePatientProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const { state } = useLocation<RouterState>();
    const currentPatientFromState = mapRouterStateToCurrentPatient(state);

    return (
        <CurrentPatientContext.Provider
            value={{
                currentPatient: currentPatientFromState,
            }}
        >
            {children}
        </CurrentPatientContext.Provider>
    );
};

const CurrentPatientLoader = ({
    children,
    nativePatientSubscriptionResult,
    nativePatientSubscriptionStatus,
}: {
    children: ReactNode;
    nativePatientSubscriptionStatus: "success" | "error" | null;
    nativePatientSubscriptionResult: PatientDemographics | null;
}) => {
    const medicalRecordConnection = useMedicalRecordConnection();

    const location = useLocation<RouterState>();

    const browserEnv = useBrowserEnvironment();
    const [currentPatient, setCurrentPatient] = useState<CurrentPatient | null>(
        browserEnv === "WebView" && nativePatientSubscriptionResult
            ? mapDemographicsToCurrentPatient(nativePatientSubscriptionResult)
            : mapRouterStateToCurrentPatient(location.state),
    );

    if (
        medicalRecordConnection.status === "Connected" &&
        !currentPatient &&
        nativePatientSubscriptionStatus === "error"
    ) {
        return (
            <UI.Feedback colour="error">
                There was an error fetching the current patient from the EMR.
            </UI.Feedback>
        );
    } else if (
        !currentPatient &&
        medicalRecordConnection.status === "Disconnected"
    ) {
        return <DisconnectedEmrErrorPage />;
    }

    return (
        <CurrentPatientContext.Provider
            value={{
                currentPatient,
            }}
        >
            {browserEnv === "WebView"
                ? medicalRecordConnection.status !== null && (
                      <MedicalRecordPatientUpdater
                          currentPatient={currentPatient}
                          setCurrentPatient={setCurrentPatient}
                          patientFeedPatient={
                              nativePatientSubscriptionResult ?? null
                          }
                          medicalRecordSystem={medicalRecordConnection.system}
                          medicalRecordSystemStatus={
                              medicalRecordConnection.status
                          }
                      />
                  )
                : null}

            {children}
        </CurrentPatientContext.Provider>
    );
};

/**
 * This provider is in charge of managing the patient
 * that should be displayed on the Compose page
 */
export const CurrentPatientProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const browserEnv = useBrowserEnvironment();

    // Gets updated in the background, only works when connected to a medical record
    const medicalRecordPatientFeed = useNativeSubscription(
        "SubscribeCurrentPatient",
    );

    if (browserEnv !== "WebView") {
        return (
            <CurrentPatientLoader
                nativePatientSubscriptionResult={null}
                nativePatientSubscriptionStatus={null}
            >
                {children}
            </CurrentPatientLoader>
        );
    }

    if (medicalRecordPatientFeed.status === "loading") {
        return (
            <StyledSpinnerWrapper>
                <Ds.Spinner />
            </StyledSpinnerWrapper>
        );
    }

    return (
        <CurrentPatientLoader
            nativePatientSubscriptionResult={
                medicalRecordPatientFeed.data ?? null
            }
            nativePatientSubscriptionStatus={
                medicalRecordPatientFeed.status === "error"
                    ? "error"
                    : "success"
            }
        >
            {children}
        </CurrentPatientLoader>
    );
};

export const useCurrentPatient = () => {
    const context = useContext(CurrentPatientContext);

    if (context === null) {
        throw new Error(
            "useCurrentPatient can only be used by a child of a CurrentPatientProvider.",
        );
    }

    return context;
};
