import { returnDataOrThrow } from "@accurx/api/QueryClient";
import { Feedback, Text } from "@accurx/design";
import {
    IWrappedResult,
    Log,
    getApiEndpoint,
    httpClient,
} from "@accurx/shared";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

export const deleteMessageTemplate = ({
    workspaceId,
    templateId,
}: {
    workspaceId: number;
    templateId: number;
}): Promise<IWrappedResult<void>> =>
    httpClient.deleteReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/content/workspace/:organisationId/templates/:templateId",
            params: {
                organisationId: workspaceId.toString(),
                templateId: templateId.toString(),
            },
        }),
    );

export const useDeleteTemplateMutation = () => {
    return useMutation({
        mutationKey: ["Delete Template"],
        mutationFn: async ({
            workspaceId,
            templateId,
            templateName,
        }: {
            workspaceId: number;
            templateId: number;
            templateName: string;
        }) => {
            if (!workspaceId) {
                Log.error("Could not delete template: missing workspace id");
                throw new Error(
                    "Could not delete template: missing workspace id",
                );
            }

            if (!templateId) {
                Log.error("Could not delete template: missing template id");
                throw new Error(
                    "Could not delete template: missing template id",
                );
            }

            return deleteMessageTemplate({ workspaceId, templateId }).then(
                returnDataOrThrow,
            );
        },
        onError: () => {
            toast(
                <Feedback title="Failed to delete template" colour="error">
                    <Text skinny>Something went wrong, please try again</Text>
                </Feedback>,
            );
        },
        onSuccess: (data, { templateName }) => {
            toast(
                <Feedback title="Template deleted" colour="success">
                    <Text skinny>{templateName} is no longer available</Text>
                </Feedback>,
            );
        },
    });
};
