import { useMemo } from "react";

import { useTeamsByType } from "@accurx/concierge/hooks/data/useTeamsByType";
import { useFuzzyFilter } from "@accurx/hooks";
import { FocusRingSpacing } from "@accurx/navigation";
import { UnreadCountProvider } from "domains/inbox/components/Sidebar/components/ConversationCount/UnreadCountProvider";
import { FlexList } from "domains/inbox/components/Sidebar/components/FlexList";
import { FolderLink } from "domains/inbox/components/Sidebar/components/FolderLink";
import { FolderListLabel } from "domains/inbox/components/Sidebar/components/FolderListLabel";
import { NoResults } from "domains/inbox/components/Sidebar/components/NoResults";
import { useInboxLink } from "domains/inbox/hooks/util";
import { useSidebarFolderClickAnalytics } from "domains/inbox/hooks/util/useSidebarFolderClickAnalytics";
import { ROUTES_INBOX } from "domains/inbox/routes";
import { zinc } from "domains/inbox/theme/colors";
import partition from "lodash/partition";
import sortBy from "lodash/sortBy";
import { useRouteMatch } from "react-router-dom";

import { TotalCountProvider } from "../ConversationCount/TotalCountProvider";
import { ConversationCountTooltip } from "../ConversationCountTooltip";

type QuestionnaireTeamsListProps = {
    searchTerm?: string;
};

const useActiveTeamId = () => {
    const match = useRouteMatch<{ teamId: string }>(
        ROUTES_INBOX.Questionnaires,
    );
    return match?.params.teamId;
};

export const QuestionnaireTeamsList = (props: QuestionnaireTeamsListProps) => {
    const teams = useTeamsByType({ type: "Florey" });
    const link = useInboxLink();
    const activeTeamId = useActiveTeamId();
    const trackFolderClick = useSidebarFolderClickAnalytics();

    const ungroupedSearchResults = useFuzzyFilter(
        sortBy(teams, (t) => t.displayName.toLowerCase()),
        props.searchTerm ?? "",
        {
            keys: ["displayName"],
            threshold: 0.4,
        },
    );

    const [memberTeams, nonMemberTeams] = useMemo(() => {
        return partition(ungroupedSearchResults, (item) => item.isMember);
    }, [ungroupedSearchResults]);

    return (
        <div>
            {!!memberTeams.length && (
                <FocusRingSpacing>
                    <FlexList
                        gap="0.5"
                        aria-label="Teams with notifications on"
                    >
                        {memberTeams.map((team) => (
                            <li
                                key={team.id}
                                data-autoscroll={
                                    team.id === activeTeamId ? "true" : "false"
                                }
                            >
                                <TotalCountProvider.Team teamId={team.id}>
                                    {(total) => (
                                        <UnreadCountProvider.Team
                                            teamId={team.id}
                                        >
                                            {(unreadCount) => (
                                                <ConversationCountTooltip
                                                    unread={unreadCount}
                                                    total={total}
                                                >
                                                    <FolderLink
                                                        text={team.displayName}
                                                        to={link.to(
                                                            "Questionnaires",
                                                            {
                                                                teamId: team.id,
                                                            },
                                                        )}
                                                        isActive={
                                                            team.id ===
                                                            activeTeamId
                                                        }
                                                        unreadCount={
                                                            unreadCount
                                                        }
                                                        onClick={() => {
                                                            trackFolderClick({
                                                                folderName:
                                                                    "Questionnaires",
                                                                teamId: team.id,
                                                                teamName:
                                                                    team.displayName,
                                                                unreadCount,
                                                                isUserSubscribedToGroupNotifications:
                                                                    true,
                                                                conversationParticipant:
                                                                    "WithPatient",
                                                            });
                                                        }}
                                                    />
                                                </ConversationCountTooltip>
                                            )}
                                        </UnreadCountProvider.Team>
                                    )}
                                </TotalCountProvider.Team>
                            </li>
                        ))}
                    </FlexList>
                </FocusRingSpacing>
            )}
            {!!nonMemberTeams.length && (
                <>
                    <FolderListLabel>Notifications off</FolderListLabel>
                    <FocusRingSpacing>
                        <FlexList
                            gap="0.5"
                            aria-label="Teams with notifications off"
                        >
                            {nonMemberTeams.map((team) => (
                                <li
                                    key={team.id}
                                    data-autoscroll={
                                        team.id === activeTeamId
                                            ? "true"
                                            : undefined
                                    }
                                >
                                    <TotalCountProvider.Team teamId={team.id}>
                                        {(total) => (
                                            <ConversationCountTooltip
                                                total={total}
                                            >
                                                <FolderLink
                                                    text={team.displayName}
                                                    to={link.to(
                                                        "Questionnaires",
                                                        {
                                                            teamId: team.id,
                                                        },
                                                    )}
                                                    color={
                                                        team.id === activeTeamId
                                                            ? "white"
                                                            : zinc.tint70
                                                    }
                                                    icon={{
                                                        name: "BellMute",
                                                        size: 3,
                                                        colour: "currentColor",
                                                    }}
                                                    isActive={
                                                        team.id === activeTeamId
                                                    }
                                                    onClick={() => {
                                                        trackFolderClick({
                                                            folderName:
                                                                "Questionnaires",
                                                            teamId: team.id,
                                                            teamName:
                                                                team.displayName,
                                                            isUserSubscribedToGroupNotifications:
                                                                false,
                                                            conversationParticipant:
                                                                "WithPatient",
                                                        });
                                                    }}
                                                />
                                            </ConversationCountTooltip>
                                        )}
                                    </TotalCountProvider.Team>
                                </li>
                            ))}
                        </FlexList>
                    </FocusRingSpacing>
                </>
            )}
            {!nonMemberTeams.length && !memberTeams.length && (
                <FocusRingSpacing>
                    <NoResults aria-live="assertive">
                        No results found
                    </NoResults>
                </FocusRingSpacing>
            )}
        </div>
    );
};
