import { useMemo } from "react";

import { FeatureName, useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import { createRouterState as createRouterStatePatientForCompose } from "@accurx/compose";
import {
    Button,
    Ds,
    Feedback,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Tokens,
} from "@accurx/design";
import {
    PatientSearchResult,
    SkeletonLoader,
    TEST_PATIENT_DATA,
    canMessagePatientGp,
    usePracticeUserAvailablityQuery,
} from "@accurx/navigation";
import { DateHelpers } from "@accurx/shared";
import { isArchivedWorkspace } from "@accurx/workspace-management";
import { Link, useLocation } from "react-router-dom";
import { useAnalytics } from "reduxQuarantine/useAnalytics";
import styled from "styled-components";

import { PatientSearchTooltip } from "../PatientSearchTooltip/PatientSearchTooltip";

export type PatientGpDetailsProps = {
    patient: PatientSearchResult;
};

export const PatientGpDetails = ({ patient }: PatientGpDetailsProps) => {
    const track = useAnalytics();

    const { orgId } = useCurrentWorkspace();
    const location = useLocation();
    const canMessageGp = canMessagePatientGp(patient.featuresAvailable);
    const isMessageGpEnabled = useFeatureFlag(FeatureName.CaregiverInitiated);

    const workspace = useCurrentWorkspace();

    const isTestPatient = patient.nhsNumber === TEST_PATIENT_DATA.nhsNumber;
    const composePath = {
        pathname: `/w/${orgId}/compose/clinician-compose`,
        state: {
            ...createRouterStatePatientForCompose({ patient }),
            originLocation: location,
        },
    };

    const practiceName = useMemo(() => {
        if (patient.practiceName) {
            return patient.practiceName;
        } else if (patient.practiceCode) {
            return `Organisation with ODS code ${patient.practiceCode}`;
        } else if (isTestPatient) {
            return "Test GP Practice";
        } else {
            return "Practice unknown";
        }
    }, [patient, isTestPatient]);

    const handleMessageGpClick = () => {
        track("PatientContact MenuItem Click", {
            optionSelected: "MessagePractice",
            productOrigin: "PatientSearch",
            navigationVersion: "Unified",
            eventVersion: 3,
        });
    };

    return (
        <Ds.Flex justifyContent="space-between" alignItems="center" gap="2">
            <div>
                {!isMessageGpEnabled && (
                    <Ds.Box mb="1">
                        <Feedback
                            colour="secondary"
                            title="Message GP isn’t enabled for your workspace"
                        />
                    </Ds.Box>
                )}
                <Ds.Box py="1">
                    {(patient.practiceCode || isTestPatient) && (
                        <Ds.Box mb="1">
                            <Ds.Badge
                                color="greyscale"
                                style={{ whiteSpace: "nowrap" }}
                            >
                                GP practice
                            </Ds.Badge>
                        </Ds.Box>
                    )}
                    <Ds.Text as="h4" weight="bold" mb="0.5">
                        {practiceName}
                    </Ds.Text>
                    {/* All three cases below are only relevant is feature is enabled for the user */}
                    {isMessageGpEnabled &&
                        (canMessageGp && patient.practiceCode ? (
                            <PracticeOnlineStatus
                                practiceCode={patient.practiceCode}
                            />
                        ) : patient.practiceCode ? (
                            <Ds.Text>This practice does not use Accurx</Ds.Text>
                        ) : (
                            <Ds.Text>
                                This patient doesn't have a registered practice
                            </Ds.Text>
                        ))}
                </Ds.Box>
            </div>
            {isMessageGpEnabled &&
            canMessageGp &&
            !isArchivedWorkspace(workspace) ? (
                <PatientSearchTooltip text="Message" align="end">
                    <Link to={composePath} onClick={handleMessageGpClick}>
                        <Button
                            as="span"
                            theme="secondary"
                            icon={{
                                name: "preview-MessageHealthProfessional",
                                colour: "blue",
                                title: "Message",
                            }}
                        />
                    </Link>
                </PatientSearchTooltip>
            ) : patient.practiceAddress || patient.practicePhoneNumber ? (
                <Popover modal>
                    <PatientSearchTooltip text="Contact details" align="end">
                        <PopoverTrigger>
                            <Button
                                theme="secondary"
                                icon={{
                                    name: "Info",
                                    colour: "blue",
                                    title: "Contact details",
                                }}
                            />
                        </PopoverTrigger>
                    </PatientSearchTooltip>
                    <StyledPopoverContent>
                        {patient.practiceAddress && (
                            <Ds.Box>
                                <Ds.Text weight="bold" color="metal">
                                    Address
                                </Ds.Text>
                                <Ds.Text>{patient.practiceAddress}</Ds.Text>
                            </Ds.Box>
                        )}
                        {patient.practicePhoneNumber && (
                            <Ds.Box mt="2">
                                <Ds.Text weight="bold" color="metal">
                                    Telephone number
                                </Ds.Text>
                                <Ds.Text>{patient.practicePhoneNumber}</Ds.Text>
                            </Ds.Box>
                        )}
                        {patient.practiceCode && (
                            <Ds.Box mt="2">
                                <Ds.Text weight="bold" color="metal">
                                    Code
                                </Ds.Text>
                                <Ds.Text>{patient.practiceCode}</Ds.Text>
                            </Ds.Box>
                        )}
                    </StyledPopoverContent>
                </Popover>
            ) : null}
        </Ds.Flex>
    );
};

const PracticeOnlineStatus = ({ practiceCode }: { practiceCode: string }) => {
    const { orgId } = useCurrentWorkspace();
    const { status, data } = usePracticeUserAvailablityQuery({
        practiceCodeToQuery: practiceCode,
        organisationId: orgId,
    });

    if (status === "loading") {
        return (
            <Ds.Box mt="1">
                <SkeletonLoader height="20px" />
            </Ds.Box>
        );
    }

    if (!data || !data.isAvailable) {
        return (
            <Ds.Flex alignItems="center" gap="1">
                <Ds.Halo color="greyscale" luminosity="low" />
                <Ds.Text>Online status unknown</Ds.Text>
            </Ds.Flex>
        );
    }

    if (!data.activity) {
        return (
            <Ds.Flex alignItems="center" gap="1">
                <Ds.Halo color="yellow" luminosity="low" />
                <Ds.Text>Staff offline</Ds.Text>
            </Ds.Flex>
        );
    }

    const { lastOnlineDaysAgo, numberOfUsers } = data.activity;
    const daysAgoText = DateHelpers.getDaysAgoText(lastOnlineDaysAgo);

    return (
        <Ds.Flex alignItems="center" gap="1">
            <Ds.Halo style={{ backgroundColor: "#63DBC0" }} />
            <Ds.Text color="metal">
                {numberOfUsers} staff {numberOfUsers === 1 ? "member " : ""}
                online {daysAgoText}
            </Ds.Text>
        </Ds.Flex>
    );
};

const StyledPopoverContent = styled(PopoverContent)`
    padding: ${Tokens.SIZES[2]};
`;
