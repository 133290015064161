import { ConversationView, OnlineUsersContextProvider } from "@accurx/inbox";

// Wrapping the ConversationView component around another one so we can easily mock it in tests
export const InboxConversationView = ({
    conversationId,
}: {
    conversationId: string;
}) => {
    return (
        <OnlineUsersContextProvider>
            <ConversationView
                conversationId={conversationId}
                onClickBack={undefined}
                onMarkAsUnreadSuccess={undefined}
                hidePatientConversationActions
            />
        </OnlineUsersContextProvider>
    );
};
