import React, { ReactNode, useMemo, useState } from "react";

import { useMeaningfulActionAnalyticsProps } from "@accurx/analytics";
import { FeatureName } from "@accurx/auth";
import { Ds, Feedback, Icon, Text } from "@accurx/design";
import { ArchivedWorkspaceHiddenItemWrapper } from "@accurx/workspace-management";
import sortBy from "lodash/sortBy";
import { toast } from "react-toastify";

import { FlemingAnalyticsTracker } from "app/analytics";
import {
    getAnalyticsConversationInitiatedBy,
    getAnalyticsConversationMessageCount,
    getAnalyticsConversationsAssigneeType,
} from "app/analytics/ConversationsAnalyticsMapper";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import {
    LoadingPatientPill,
    PatientPill,
} from "app/sharedComponents/PatientPill";
import { SkeletonSubtitle } from "app/sharedComponents/loadingSkeleton/SkeletonText";
import {
    Assignee,
    TeamAssignee,
    UserAssignee,
} from "app/workspaceConversations/workspaceConversations.types";
import { AssigneeSummary } from "shared/concierge/conversations/types/assignee.types";
import {
    PatientMatchState,
    PatientTriageSuggestedPatient,
} from "shared/concierge/conversations/types/item.types";
import { useSingleUserOrTeamQuery } from "shared/concierge/usersAndTeams/hooks";
import {
    UserOrTeamRequest,
    useAllUsersAndTeamsQuery,
} from "shared/concierge/usersAndTeams/hooks/usersAndTeamsQueryHooks";
import { UsersAndTeamsSummaries } from "shared/concierge/usersAndTeams/types/usersAndTeams.types";
import { formatPatientDisplayName } from "shared/formatters/formatPatientDisplayName";
import { useMountedState } from "shared/useMountedState";
import { useIsFeatureEnabled } from "store/hooks";

import { Assign } from "../Assignment/Assign";
import {
    createAssignee,
    getAssigneeDisplayName,
} from "../Assignment/AssignHelper";
import {
    getPatientData,
    getPatientNotFoundBadgeInfo,
} from "../Conversation.helpers";
import {
    Assign as AssignType,
    ConversationSummary,
    MarkAsDone,
    MarkAsOpen,
    PatientType,
} from "../Conversation.types";
import {
    StyledConversationHeader,
    StyledHeaderContainer,
    StyledMarkAsDoneButton,
    StyledSubHeaderContainer,
} from "./ConversationHeader.styles";

export interface ConversationHeaderProps {
    conversation: ConversationSummary | null;
    markAsDone?: MarkAsDone;
    markAsOpen?: MarkAsOpen;
    assign?: AssignType;
    patient: PatientType;
    matchType: PatientMatchState | null;
    suggestedMatch: PatientTriageSuggestedPatient | null;
    currentUserId: string;
    isLoading?: boolean;
    children?: ReactNode;
}

export const ConversationHeader = ({
    conversation,
    children,
    markAsDone,
    markAsOpen,
    assign,
    patient,
    matchType,
    suggestedMatch,
    currentUserId,
    isLoading,
}: ConversationHeaderProps): JSX.Element => {
    const assignee: AssigneeSummary = conversation?.assignee || {
        type: "None",
    };
    const conversationId = conversation?.id || "";
    const [markAsDoneButtonDisabled, setMarkAsDoneButtonDisabled] =
        useState(false);
    const hasUnknownPatient =
        matchType === "NoMatch" || matchType === "Suggested";
    const loggedInProps = useFlemingLoggedInAnalytics();
    const meaningfulActionProps = useMeaningfulActionAnalyticsProps();
    const isComponentMounted = useMountedState();
    const allUsersAndTeamsQuery = useAllUsersAndTeamsQuery();
    const currentAssigneeQuery = useSingleUserOrTeamQuery(
        mapAssigneeToUserOrTeamRequest(assignee),
    );
    const collaborativeInboxEnabled = useIsFeatureEnabled(
        FeatureName.CollaborativeWebInbox,
    );
    const conversationInitiatedBy =
        getAnalyticsConversationInitiatedBy(conversation);
    const assigneeType = getAnalyticsConversationsAssigneeType(
        assignee,
        currentUserId,
    );
    const markAsDoneAnalytics = {
        ...loggedInProps,
        ...meaningfulActionProps,
        assigneeType,
        conversationId: conversationId,
        conversationInitiatedBy,
        conversationMessageCount: getAnalyticsConversationMessageCount(
            conversation?.items || [],
        ),
        conversationStartTimestampUTC: conversation
            ? conversation.items[0].createdAt
            : "",
    };

    const handleOnAssigneeChange = async (
        newAssignee: Assignee,
        hasSearched: boolean,
        assign: AssignType,
    ) => {
        const newAssigneeSummary = mapAssigneeToAssigneeSummary(newAssignee);
        const newAssigneeType = getAnalyticsConversationsAssigneeType(
            newAssigneeSummary,
            currentUserId,
        );
        const patientConversationAssignSelectMenuItemProps = {
            ...loggedInProps,
            ...meaningfulActionProps,
            hasSearched,
            assigneeType: newAssigneeType,
            conversationId: conversationId,
        };

        FlemingAnalyticsTracker.trackPatientConversationAssignSelectMenuItemClick(
            {
                ...patientConversationAssignSelectMenuItemProps,
                hasError: false,
            },
        );
        let assignmentHasFailed = false;
        try {
            await assign(newAssigneeSummary);
        } catch {
            assignmentHasFailed = true;

            toast(
                <Feedback colour="error" iconName="Error">
                    <Text skinny>
                        {`Unable to assign ${getAssigneeDisplayName(
                            newAssignee,
                        )} to the conversation`}
                    </Text>
                </Feedback>,
            );
        } finally {
            FlemingAnalyticsTracker.trackPatientConversationAssignSelectMenuItemResponse(
                {
                    ...patientConversationAssignSelectMenuItemProps,
                    hasError: assignmentHasFailed,
                },
            );
        }
    };

    const handleMarkAsDone = async (markAsDone: MarkAsDone) => {
        setMarkAsDoneButtonDisabled(true);

        FlemingAnalyticsTracker.trackPatientConversationMarkAsDoneButtonClick({
            ...markAsDoneAnalytics,
            hasError: false,
        });

        let hasError = false;

        try {
            await markAsDone();
        } catch {
            hasError = true;

            toast(
                Feedback({
                    colour: "error",
                    title: "Unable to mark conversation as done.",
                }),
            );
        } finally {
            FlemingAnalyticsTracker.trackPatientConversationMarkAsDoneButtonResponse(
                {
                    ...markAsDoneAnalytics,
                    hasError,
                },
            );

            if (isComponentMounted()) {
                setMarkAsDoneButtonDisabled(false);
            }
        }
    };

    const handleMarkAsOpen = async (markAsOpen: MarkAsOpen) => {
        setMarkAsDoneButtonDisabled(true);

        FlemingAnalyticsTracker.trackPatientConversationReOpenButtonClick({
            ...loggedInProps,
            ...meaningfulActionProps,
            conversationId: conversationId,
            conversationInitiatedBy,
            assigneeType,
            hasError: false,
        });

        let hasError = false;

        try {
            await markAsOpen();
        } catch {
            hasError = true;

            toast(
                Feedback({
                    colour: "error",
                    title: "Unable to mark conversation as open.",
                }),
            );
        } finally {
            FlemingAnalyticsTracker.trackPatientConversationReOpenButtonResponse(
                {
                    ...loggedInProps,
                    ...meaningfulActionProps,
                    conversationId: conversationId,
                    conversationInitiatedBy,
                    assigneeType,
                    hasError,
                },
            );

            if (isComponentMounted()) {
                setMarkAsDoneButtonDisabled(false);
            }
        }
    };

    const displayPatientDetails = getPatientData(patient, suggestedMatch);

    const getConversationTitle = () => {
        if (displayPatientDetails) {
            return `Conversation with ${formatPatientDisplayName({
                firstName: displayPatientDetails.firstName,
                familyName: displayPatientDetails.familyName,
            })}`;
        }

        return "Conversation";
    };

    const sortedAssignees = useSortUsersAndTeams(allUsersAndTeamsQuery.data);
    const patientBadgeInfo = getPatientNotFoundBadgeInfo(matchType);

    return (
        <>
            <StyledHeaderContainer>
                <StyledConversationHeader>
                    {isLoading ? (
                        <SkeletonSubtitle charCount={40} />
                    ) : (
                        <Text as="h1" variant="subtitle" skinny>
                            {getConversationTitle()}
                        </Text>
                    )}
                    {patientBadgeInfo.showBadge && (
                        <Ds.Badge color="yellow">
                            <Icon name="Warning" />
                            {patientBadgeInfo.badgeText}
                        </Ds.Badge>
                    )}
                    {conversation?.status === "Done" && (
                        <Ds.Badge color="green">
                            <Icon name="LockLocked" theme="Fill" />
                            Done
                        </Ds.Badge>
                    )}
                </StyledConversationHeader>

                {!!markAsDone && conversation?.status === "Open" && (
                    <ArchivedWorkspaceHiddenItemWrapper>
                        <StyledMarkAsDoneButton
                            icon={{
                                name: "Done",
                                colour: "blue",
                                style: "Line",
                            }}
                            text="Mark as done"
                            theme="secondary"
                            dimension="medium"
                            disabled={markAsDoneButtonDisabled}
                            onClick={() => handleMarkAsDone(markAsDone)}
                        />
                    </ArchivedWorkspaceHiddenItemWrapper>
                )}
                {!!markAsOpen && conversation?.status === "Done" && (
                    <ArchivedWorkspaceHiddenItemWrapper>
                        <StyledMarkAsDoneButton
                            text="Re-open"
                            theme="secondary"
                            dimension="medium"
                            disabled={markAsDoneButtonDisabled}
                            onClick={() => handleMarkAsOpen(markAsOpen)}
                        />
                    </ArchivedWorkspaceHiddenItemWrapper>
                )}
            </StyledHeaderContainer>
            <StyledSubHeaderContainer hasUnknownPatient={hasUnknownPatient}>
                {isLoading ? (
                    <LoadingPatientPill verbose />
                ) : (
                    <PatientPill patient={patient || undefined} verbose />
                )}
                {!!assign && collaborativeInboxEnabled && (
                    <Assign
                        isLoaded={allUsersAndTeamsQuery.status !== "loading"}
                        error={allUsersAndTeamsQuery.status === "error"}
                        assignmentCollection={sortedAssignees}
                        loggedInUserId={currentUserId}
                        assignee={createAssignee(
                            assignee.type,
                            currentAssigneeQuery.data,
                        )}
                        isDisabled={conversation?.status === "Done"}
                        handleOnAssigneeChange={(newAssignee, hasSearched) =>
                            handleOnAssigneeChange(
                                newAssignee,
                                hasSearched,
                                assign,
                            )
                        }
                        conversationId={conversationId}
                    />
                )}
                {children}
            </StyledSubHeaderContainer>
        </>
    );
};

const useSortUsersAndTeams = (
    usersAndTeams: UsersAndTeamsSummaries | undefined,
) => {
    return useMemo<Assignee[]>(() => {
        const users: UserAssignee[] = sortBy(usersAndTeams?.users, (item) =>
            item.displayName.toLocaleLowerCase(),
        ).map((item) => ({
            type: "User",
            value: item,
        }));
        const teams: TeamAssignee[] = sortBy(usersAndTeams?.teams, (item) =>
            item.displayName.toLocaleLowerCase(),
        ).map((item) => ({
            type: "Team",
            value: item,
        }));

        return [...users, ...teams];
    }, [usersAndTeams]);
};

const mapAssigneeToUserOrTeamRequest = (
    assignee: AssigneeSummary,
): UserOrTeamRequest | undefined => {
    return assignee.type === "None" ? undefined : assignee;
};

const mapAssigneeToAssigneeSummary = (assignee: Assignee): AssigneeSummary => {
    if (assignee.type === "None") {
        return { type: "None" };
    }

    return {
        type: assignee.type,
        id: assignee.value.id,
    };
};
