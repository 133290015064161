import { AppointmentConfiguration } from "@accurx/api/appointment";
import { useCurrentWorkspace } from "@accurx/auth";
import { getApiEndpoint, httpClient, returnDataOrThrow } from "@accurx/shared";
import {
    UseQueryOptions,
    UseQueryResult,
    useQuery,
} from "@tanstack/react-query";

export type AppointmentConfigurationDTO = Omit<
    AppointmentConfiguration,
    "selfBookSchedulingIntoFutureEnabled"
>;

export const useAppointmentsConfigurationQuery = (
    options?: UseQueryOptions<AppointmentConfigurationDTO, Error>,
): UseQueryResult<AppointmentConfigurationDTO, Error> => {
    const { orgId } = useCurrentWorkspace();

    return useQuery({
        queryKey: ["AppointmentsConfiguration", { orgId }],
        queryFn: async () => {
            const response = await httpClient.getReturnJsonSafeAsync(
                getApiEndpoint({
                    path: `/api/appointments/${orgId}/configuration`,
                }),
            );

            return returnDataOrThrow<AppointmentConfigurationDTO>(response);
        },
        ...options,
    });
};
