import * as UI from "@accurx/design";
import { Ds } from "@accurx/design";
import styled from "styled-components";

export const Chevron = styled(Ds.Icon).attrs({
    color: "blue",
    size: "xsmall",
    name: "Arrow",
    rotate: "90",
})``;

export const StyledListItem = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: ${UI.Tokens.BORDERS.normal.silver};

    ${Chevron} {
        visibility: hidden;
    }

    &:last-child {
        border-bottom: none;
    }

    &:hover,
    &:focus-within {
        background: ${UI.Tokens.COLOURS.primary.blue[5]};
    }

    &:hover ${Chevron}, &:focus-within ${Chevron} {
        visibility: visible;
    }

    &:focus-within {
        outline: 2px solid ${UI.Tokens.COLOURS.primary.blue[100]};
        outline-offset: -4px;
    }
`;

export const StyledButton = styled.button`
    background: inherit;
    padding: ${UI.Tokens.SIZES[1]} ${UI.Tokens.SIZES[2]};
    padding-right: ${UI.Tokens.SIZES[1]};

    border: none;
    flex: 1;
    height: 100%;
    min-width: 0;
`;

export const StyledTitleText = styled(UI.Ds.Text)`
    white-space: pre-line;
    word-break: break-word;
    hyphens: auto;
    text-align: left;
    flex: 1;
`;

export const StyledLoadingSpinner = styled(UI.Spinner)`
    // to match the size of an Icon with halo (24px)
    padding: 6px;
`;
