import { ReactNode } from "react";

import {
    QUICK_VIEW_CONTAINER_ID,
    useQuickViewContext,
} from "@accurx/quick-view";
import {
    GlobalArchivedWorkspaceBanner,
    useShowGlobalArchivedWorkspaceBanner,
} from "layouts/Global/GlobalArchivedWorkspaceBanner/GlobalArchivedWorkspaceBanner";

import { PORTAL_ID_NAV_SUBMENU } from "app/navbar/NavSubMenuComponent";

import { MAIN_CONTENT_ID } from "./Layout.constants";
import {
    PageContainer,
    QuickViewContainer,
    StyledLayout,
    StyledMainContent,
    StyledQuickViewWrapper,
    StyledSidebar,
    StyledWrapper,
} from "./Layout.styles";
import { NavigationMenu } from "./navigationMenu";
import { SkipToContentLink } from "./skipToContentLink";

type LayoutProps = {
    children: ReactNode;
};

export const Layout = ({ children }: LayoutProps) => {
    const quickView = useQuickViewContext();

    const showGlobalArchivedWorkspaceBanner =
        useShowGlobalArchivedWorkspaceBanner();

    return (
        <StyledLayout>
            {showGlobalArchivedWorkspaceBanner && (
                <GlobalArchivedWorkspaceBanner />
            )}
            <StyledWrapper>
                <SkipToContentLink />
                <StyledSidebar>
                    <NavigationMenu />
                </StyledSidebar>

                <StyledMainContent id={MAIN_CONTENT_ID}>
                    <StyledQuickViewWrapper $quickViewIsOpen={quickView.isOpen}>
                        {/* Submenu root */}
                        <PageContainer>
                            <div id={PORTAL_ID_NAV_SUBMENU}></div>
                            {children}
                        </PageContainer>
                        <QuickViewContainer id={QUICK_VIEW_CONTAINER_ID} />
                    </StyledQuickViewWrapper>
                </StyledMainContent>
            </StyledWrapper>
        </StyledLayout>
    );
};
