import { Ds, Tokens } from "@accurx/design";
import styled, { css } from "styled-components";

import { StyledStickyHeader } from "./AssigneeSelectorForm.styles";

export const StyledList = styled.ul`
    list-style: none;
    padding: 0;
`;

export const StyledListItem = styled.li`
    padding: 0;
`;

const INDICATOR_WIDTH = "3px";

const focusStateStyles = css`
    position: relative;
    margin-left: ${INDICATOR_WIDTH};
    width: calc(100% - ${INDICATOR_WIDTH});
    background: ${Tokens.COLOURS.primary.blue[5]};

    & > * {
        margin-left: -${INDICATOR_WIDTH};
    }

    &:before {
        content: "";

        position: absolute;
        left: -${INDICATOR_WIDTH};
        top: 0;
        height: 100%;
        width: ${INDICATOR_WIDTH};

        background-color: ${Tokens.COLOURS.primary.blue[100]};
    }
`;

export const StyledListItemButton = styled.button`
    width: 100%;
    border: none;
    background: none;
    padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[1.5]};
    box-shadow: 0px -1px 0px 0px ${Tokens.COLOURS.greyscale.silver} inset;

    &[data-selected="true"] {
        ${focusStateStyles}
    }

    &:hover:enabled,
    &:focus:enabled {
        background: ${Tokens.COLOURS.primary.blue[5]};
    }

    &:focus:enabled {
        box-shadow: 0px -1px 0px 0px ${Tokens.COLOURS.greyscale.silver} inset,
            inset 0 0 0 2px ${Tokens.COLOURS.greyscale.white},
            inset 0 0 0 4px ${Tokens.COLOURS.primary.blue[100]};
    }

    &:disabled {
        cursor: not-allowed;
    }
`;

export const StyledHeaderText = styled(Ds.Text)`
    padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[1.5]};
    box-shadow: 0px -1px 0px 0px ${Tokens.COLOURS.greyscale.silver} inset;
`;

export const StyledPendingUsersStickyHeader = styled(StyledStickyHeader)`
    box-shadow: 0px -1px 0px 0px ${Tokens.COLOURS.greyscale.silver} inset;
`;
