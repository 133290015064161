import { useCurrentWorkspace } from "@accurx/auth";
import { Ds, Tokens } from "@accurx/design";
import {
    UsePracticeUserAvailablityQueryParams,
    usePracticeUserAvailablityQuery,
} from "@accurx/navigation";
import { DateHelpers } from "@accurx/shared";
import { SkeletonLoader } from "domains/message-component/ILLEGAL_IMPORTS_DO_NOT_USE";

import { StyledBadge } from "./ClinicianRecipientDisplay.styles";

type ClinicianRecipientDisplayProps = {
    practiceCode?: string;
    workspaceId?: number;
    name: string;
};

export const ClinicianRecipientDisplay = ({
    name,
    workspaceId,
    practiceCode,
}: ClinicianRecipientDisplayProps) => {
    return (
        <StyledBadge radius="round">
            <Ds.Flex gap="0.5" alignItems="center">
                <Ds.Text weight="bold" size="small">
                    {name}
                </Ds.Text>
                <AvailablityDisplay
                    workspaceIdToQuery={workspaceId}
                    practiceCodeToQuery={practiceCode}
                />
            </Ds.Flex>
        </StyledBadge>
    );
};

const AvailablityDisplay = ({
    workspaceIdToQuery,
    practiceCodeToQuery,
}: Omit<UsePracticeUserAvailablityQueryParams, "organisationId">) => {
    const { orgId: organisationId } = useCurrentWorkspace();

    const { status, data, isFetching } = usePracticeUserAvailablityQuery({
        organisationId,
        ...(workspaceIdToQuery
            ? { workspaceIdToQuery }
            : { practiceCodeToQuery }),
    });

    if (status === "loading" && !isFetching) {
        // Query is disabled due neither practice identifier being specified
        return null;
    }

    if (status === "loading") {
        return (
            <SkeletonLoader
                backgroundColor={Tokens.COLOURS.greyscale.silver}
                width="65px"
                height="12px"
            />
        );
    }

    if (
        status === "error" ||
        !data.isAvailable ||
        data.activity?.lastOnlineDaysAgo === undefined
    ) {
        return null;
    }

    const { lastOnlineDaysAgo } = data.activity;
    const daysAgoText = DateHelpers.getDaysAgoText(lastOnlineDaysAgo);

    return <Ds.Text size="small">(Online {daysAgoText})</Ds.Text>;
};
