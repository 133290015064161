import { ButtonLink, Ds, Link, Text } from "@accurx/design";
import styled from "styled-components";

import { InnerStackPanel } from "app/account/LogIn/LoginStackPanel";
import { ROUTES } from "shared/Routes";

const StyledCenteredButtonLink = styled(ButtonLink)`
    > span {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    width: 100%;
`;

const StyledContainer = styled(Ds.Flex).attrs({
    alignItems: "center",
    justifyContent: "center",
})`
    width: 100%;
    height: 100%;
`;

const StyledContent = styled(Ds.Flex).attrs({
    flexDirection: "column",
    alignItems: "center",
    gap: "1",
})`
    text-align: center;
`;

const DESKTOP_LINK = "accurxchain://ResetScreenPosition";

export const JoinWorkspaceSucceed = () => {
    return (
        <StyledContainer>
            <StyledContent>
                <Text variant="subtitle" as="h1" skinny>
                    You have successfully joined!
                </Text>
                <InnerStackPanel horizontalContentAlignment="stretch">
                    <Text skinny>You can now open the toolbar.</Text>
                    <StyledCenteredButtonLink
                        href={DESKTOP_LINK}
                        text="Open Accurx Desktop toolbar"
                    />
                    <Text variant="label">
                        {"Haven't installed the Accurx Desktop toolbar? "}
                        <Link
                            href={ROUTES.downloadDesktop}
                            text="Install now"
                        />
                    </Text>
                </InnerStackPanel>
            </StyledContent>
        </StyledContainer>
    );
};
