import React, { ChangeEvent, MouseEvent } from "react";

import { Attachment, Ds, Feedback, FormFieldWrapper } from "@accurx/design";

import * as helpers from "../../ManageTemplatesFormPage.helpers";
import { StyledAttachmentButton } from "../../ManageTemplatesFormPage.styles";
import { FormTemplateAttachment } from "../../ManageTemplatesFormPage.types";

export type AttachmentsProps = {
    attachments: FormTemplateAttachment[];
    onAddAttachment: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
    onRemoveAttachment: (id: FormTemplateAttachment["id"]) => void;
};
export const Attachments = ({
    attachments,
    onAddAttachment,
    onRemoveAttachment,
}: AttachmentsProps): JSX.Element => {
    const hasAttachment = attachments.length > 0;
    const hasMaxAllowedAttachments =
        attachments.length === helpers.ALLOWED_NUMBER_OF_FILES;

    return (
        <FormFieldWrapper
            label="Attachments"
            subLabel={`You can attach ${
                helpers.ALLOWED_NUMBER_OF_FILES
            } file, max size ${
                helpers.ALLOWED_FILE_SIZE.humanReadable
            }. Files accepted: ${helpers.ALLOWED_FILE_EXTENSIONS.join(", ")}.`}
        >
            <Ds.Flex flexDirection="column" alignItems="flex-start" gap="2">
                <StyledAttachmentButton
                    type="button"
                    icon={{
                        name: "Paperclip",
                    }}
                    text="Attach file"
                    theme="secondary"
                    onClick={() => {
                        document.getElementById("fileUpload")?.click();
                    }}
                    disabled={hasMaxAllowedAttachments}
                />
                <input
                    id="fileUpload"
                    type="file"
                    accept={`.${helpers.ALLOWED_FILE_EXTENSIONS.join(",.")}`}
                    style={{
                        display: "none",
                    }}
                    // Need to reset this on click in case we want to upload the same file twice
                    onClick={(event: MouseEvent<HTMLInputElement>) => {
                        event.currentTarget.value = "";
                    }}
                    onChange={onAddAttachment}
                    data-testid="fileUpload"
                />
                {attachments.map((attachment, i) => (
                    <Attachment
                        key={attachment.id}
                        index={i + 1}
                        status={attachment.status}
                        onRemove={() => onRemoveAttachment(attachment.id)}
                        attachment={{
                            fileName:
                                // this shouldn't ever be null, but our generated API types tell us that it can,
                                // so we have to handle it by using a non-null assertion
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                attachment.fileName!,
                            fileSize: attachment.fileSize,
                            fileType: attachment.fileType,
                            attachmentId: attachment.id?.toString(),
                            previewUrl: attachment.previewUrl,
                            errors: attachment.errors,
                        }}
                    />
                ))}
                {hasAttachment && (
                    <Feedback
                        colour={"secondary"}
                        title={"Check you've got the right attachment"}
                    >
                        This message could be sent to multiple patients, so
                        please do not include information relating to individual
                        patients.
                    </Feedback>
                )}
            </Ds.Flex>
        </FormFieldWrapper>
    );
};
