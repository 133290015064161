import React, { KeyboardEvent, useCallback, useEffect, useState } from "react";

import {
    Button,
    SelectGroup,
    SelectGroupLabel,
    SelectItem,
    SingleSelect,
} from "@accurx/design";
import { SharedConstants } from "@accurx/shared";
import Bowser from "bowser";
import debounce from "lodash/debounce";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";

import {
    useAppSelector,
    useCurrentOrgName,
    useCurrentUserFullName,
} from "store/hooks";

import { actionCreators as fileUploadActionCreators } from "../../fileUpload/FileUploadActions";
import FileUpload from "../../fileUpload/FileUploadComponent";
import { useIsMessageGpTestFlow } from "../hooks/useIsMessageGpTestFlow";
import { PatientDetailsWithDisplayNames, onSendFunction } from "../types";
import {
    ButtonRow,
    StyledFormFieldFeedback,
    StyledOverFlowContent,
    StyledTextareaAutosize,
} from "./Conversation.styles";
import { UnsavedChangesPrompt } from "./UnsavedChangesPrompt";
import {
    GENERAL_TEMPLATES,
    MessageTemplateGroup,
    NO_TEMPLATE,
    RECORD_VIEW_TEMPLATES,
} from "./templates.helper";

const MAX_MESSAGE_LENGTH = 16 * 1024; // 16KB.

type WithoutTemplate = {
    selectedTemplateValue?: undefined | (typeof NO_TEMPLATE)["value"];
};
type WithTemplate = {
    selectedTemplateValue: string;
    selectedTemplateTitle: string;
    selectedTemplateHeading: string;
};

type Message<T> = {
    body: string;
} & T;

function hasTemplate(
    m: Message<WithTemplate | WithoutTemplate>,
): m is Message<WithTemplate> {
    return (
        m.selectedTemplateValue !== undefined &&
        m.selectedTemplateValue !== NO_TEMPLATE.value
    );
}

type ClinicianConversationComposeProps = {
    onClickSend: onSendFunction;
    organisationId: number;
    patient: PatientDetailsWithDisplayNames | null;
    isFirstMessage: boolean;
    userAgent: string;
};

const getSelectedTemplateGroup = ({
    selectedTemplateGroupName,
    templates,
}: {
    selectedTemplateGroupName: string;
    templates: MessageTemplateGroup[];
}) => {
    const group = templates.find(
        (templateGroup) => templateGroup.Category === selectedTemplateGroupName,
    );
    return {
        templates: group?.TemplateList || [],
        heading: group?.SectionHeading,
    };
};

const getSelectedTemplate = ({
    templates,
    selectedValue,
}: {
    templates: MessageTemplateGroup[];
    selectedValue: string;
}): {
    templateBody: string;
    templateTitle: string;
    templateHeading: string;
} => {
    // splits the template value to retrieve the group and the name of the template
    const [templateGroupName, templateName] = selectedValue.split("--");

    const selectedGroup = getSelectedTemplateGroup({
        selectedTemplateGroupName: templateGroupName,
        templates: templates,
    });

    const selectedTemplate = selectedGroup.templates.find(
        ({ Name }) => Name === templateName,
    );

    // We should always be able to find the template, but because of the types we need a fallback of an empty string
    return {
        templateBody: selectedTemplate?.Body || "",
        templateTitle: selectedTemplate?.Title || "",
        templateHeading: selectedGroup?.heading || "",
    };
};

const isValid = (messageBody: string) => {
    return !messageBody.includes("*****");
};

const INCOMPLETE_TEMPLATE = "Please complete the template message";

export const ClinicianConversationCompose = ({
    onClickSend,
    organisationId,
    patient,
    isFirstMessage,
    userAgent,
}: ClinicianConversationComposeProps) => {
    const isTestFlow = useIsMessageGpTestFlow();
    const [isComposing, setIsComposing] = useState(false);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const templateParam = params.get("template");
    const categoryParam = params.get("category");
    const isPostPatientMessageParam = params.get("isPostPatientMessage");
    const isPostPatientMessage = isPostPatientMessageParam === "1";
    const isRecordViewRequestMessage =
        categoryParam === "record-view" && templateParam === "opt-in";
    const templates = isRecordViewRequestMessage
        ? RECORD_VIEW_TEMPLATES
        : GENERAL_TEMPLATES;

    const isMac =
        Bowser.getParser(userAgent).getOSName(true).indexOf("mac") >= 0;

    const userFullName = useCurrentUserFullName();
    const orgName = useCurrentOrgName();
    const getMessageBody = (templateText = "") => {
        const messageHeader =
            `Hi ${patient?.practiceName || patient?.practiceCode} - ` +
            `this is regarding ${patient?.displayNameWithNHSNo} who is registered at your practice.\n\n`;

        const messageFooter = `\n\nThanks,\n${userFullName}\n${orgName}`;

        return isFirstMessage
            ? messageHeader + templateText + messageFooter
            : templateText;
    };
    const [message, setMessage] = useState<
        Message<WithTemplate | WithoutTemplate>
    >({
        body: getMessageBody(),
    });
    const [errorText, setErrorText] = useState<string | null>(null);

    const dispatch = useDispatch();
    const fileUploadState = useAppSelector((state) => state.fileUpload);

    useEffect(() => {
        return () => {
            dispatch(fileUploadActionCreators.resetFileUpload());
        };
    }, [dispatch]);

    const handleSelectTemplate = (selectedOption: string) => {
        setErrorText(null);
        if (selectedOption === NO_TEMPLATE.value) {
            setMessage({
                body: getMessageBody(),
                selectedTemplateValue: NO_TEMPLATE.value,
            });
        } else {
            const { templateBody, templateTitle, templateHeading } =
                getSelectedTemplate({
                    selectedValue: selectedOption,
                    templates,
                });

            if (templateBody !== message.selectedTemplateValue) {
                setMessage({
                    body: getMessageBody(templateBody),
                    selectedTemplateValue: selectedOption,
                    selectedTemplateTitle: templateTitle,
                    selectedTemplateHeading: templateHeading,
                });
            }
        }
    };

    // Pre-selects template based on URL search parameters if provided
    useEffect(() => {
        if (templateParam && categoryParam) {
            handleSelectTemplate(`${categoryParam}--${templateParam}`);
        }
        // this useEffect should only run on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // This function checks whether the user has completed the template, as they type - it is debounced to prevent us from validating the message body too frequently
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we have no dependencies, but TS doesn't know this because we're using `debounce`
    const debouncedSetErrorText = useCallback(
        debounce((messageBody) => {
            if (isValid(messageBody)) {
                setErrorText(null);
            } else {
                setErrorText(INCOMPLETE_TEMPLATE);
            }
        }, 500),
        [],
    );

    const canSend =
        message.body.trim().length > 0 &&
        !fileUploadState.isUploading &&
        !errorText;

    const handleSendMessage = (usedKeyboardShortcut = false) => {
        const messageBody = message.body;
        const template = hasTemplate(message)
            ? {
                  title: message.selectedTemplateTitle,
                  heading: message.selectedTemplateHeading,
              }
            : undefined;

        if (isValid(messageBody)) {
            setMessage({ body: "", selectedTemplateValue: undefined });
            setIsComposing(false);

            onClickSend(
                messageBody,
                fileUploadState,
                usedKeyboardShortcut,
                isPostPatientMessage,
                template,
            );
        } else {
            setErrorText(INCOMPLETE_TEMPLATE);
        }
    };

    const handleMessageChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
        const newBody = e.currentTarget.value;
        if (isComposing && newBody === "") {
            setIsComposing(false);
        }

        if (!isComposing && newBody !== "") {
            setIsComposing(true);
        }

        debouncedSetErrorText(newBody);
        setMessage({ ...message, body: newBody });
    };

    const handleMessageKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        const modifierKey = isMac ? e.metaKey : e.ctrlKey; // metaKey is CMD for Mac
        if (canSend && modifierKey && e.key === "Enter") {
            handleSendMessage(true);
        }
    };

    return (
        <>
            <UnsavedChangesPrompt blockExit={isComposing} />
            <SingleSelect
                onValueChange={handleSelectTemplate}
                id={"template"}
                key={message.selectedTemplateValue}
                placeholder={"Select a template (optional)"}
                dimension={"medium"}
                displayOptionsAboveTrigger
                value={message.selectedTemplateValue}
                disabled={isRecordViewRequestMessage}
            >
                <StyledOverFlowContent>
                    {!isRecordViewRequestMessage ? (
                        <SelectItem value={NO_TEMPLATE.value}>
                            {NO_TEMPLATE.label}
                        </SelectItem>
                    ) : null}
                    {templates.map((templateGroup) => {
                        return (
                            <SelectGroup
                                key={`${templateGroup.SectionHeading}`}
                            >
                                <SelectGroupLabel>
                                    {templateGroup.SectionHeading}
                                </SelectGroupLabel>
                                {templateGroup.TemplateList.map(
                                    ({ Title, Name }) => (
                                        <SelectItem
                                            key={Name}
                                            value={`${templateGroup.Category}--${Name}`}
                                        >
                                            {Title}
                                        </SelectItem>
                                    ),
                                )}
                            </SelectGroup>
                        );
                    })}
                </StyledOverFlowContent>
            </SingleSelect>
            <StyledTextareaAutosize
                required
                autoFocus
                className="form-control"
                value={message.body}
                onChange={handleMessageChange}
                placeholder={"Please enter a message"}
                data-testid="message-body"
                minRows={3}
                maxRows={8}
                maxLength={MAX_MESSAGE_LENGTH}
                onKeyDown={handleMessageKeyDown}
            />
            {errorText ? (
                <StyledFormFieldFeedback variant="error" text={errorText} />
            ) : null}
            <ButtonRow
                orientation="horizontal"
                horizontalContentAlignment="space-between"
                gutter={2}
                verticalContentAlignment="top"
                className="mt-2"
            >
                <FileUpload
                    maxFiles={4}
                    maxSize={3}
                    accept={SharedConstants.FileUploadTypes}
                    noDrag={true}
                    organisationId={organisationId}
                    onUploadFile={(request) =>
                        dispatch(
                            fileUploadActionCreators.uploadEmailAttachment(
                                request,
                            ),
                        )
                    }
                    onFileRemove={(fileId) =>
                        dispatch(fileUploadActionCreators.removeFile(fileId))
                    }
                />
                <Button
                    icon={{ name: "Send", style: "Line" }}
                    text={isTestFlow ? "Send test message" : "Send"}
                    onClick={() => handleSendMessage()}
                    disabled={!canSend}
                    data-testid="send-reply-button"
                />
            </ButtonRow>
        </>
    );
};
