import React, { useState } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import {
    Button,
    Card,
    Ds,
    Feedback,
    Icon,
    Popover,
    PopoverContent,
    PopoverItem,
    PopoverTrigger,
    StackPanel,
    Text,
} from "@accurx/design";
import { ArchivedWorkspaceHiddenItemWrapper } from "@accurx/workspace-management";
import { toast } from "react-toastify";

import FlemingApi from "api/FlemingApiClient";
import { PatientHelper } from "shared/PatientHelper";

import { StyledCardContent } from "../account/OrganisationResources/DocumentEntryCard.styles";
import { RecallAttachmentModal } from "./RecallModel";

export type PatientProfileCardProps = {
    fileName: string;
    description: string;
    sentAt: string;
    downloadUrl: string;
    documentUrlId: string;
    deletedAt: string | null;
};

export const PatientAttachmentCard = ({
    fileName,
    description,
    sentAt,
    downloadUrl,
    documentUrlId,
    deletedAt,
}: PatientProfileCardProps): JSX.Element | null => {
    const [showRecallModal, setShowRecallModal] = useState(false);

    const [isDeleted, setIsDeleted] = useState(!!deletedAt);

    const toggleRecallModal = () => {
        setShowRecallModal(!showRecallModal);
    };

    const { orgId: organisationId } = useCurrentWorkspace();

    const patientToken = PatientHelper.getPatientTokenWithStorageFallback(null);

    const handleDeleteClick = async (): Promise<void> => {
        if (organisationId == null) {
            toast(
                Feedback({
                    colour: "error",
                    title: "There was a problem deleting this attachment. Please refresh the page and try again.",
                }),
            );
            return;
        }

        const { success } = await FlemingApi.deletePatientAttachments({
            organisationId,
            patientToken,
            documentUrlId,
        });

        if (success) {
            setIsDeleted(true);
            toggleRecallModal();
        } else {
            toast(
                Feedback({
                    colour: "error",
                    title: "There was a problem recalling this document. Please try again.",
                }),
            );
        }
    };

    return (
        <>
            <ArchivedWorkspaceHiddenItemWrapper>
                <RecallAttachmentModal
                    isOpen={showRecallModal}
                    toggleRecallModal={toggleRecallModal}
                    fileName={fileName}
                    handleDelete={handleDeleteClick}
                />
            </ArchivedWorkspaceHiddenItemWrapper>
            <Card spacing={2}>
                <StyledCardContent>
                    <StackPanel orientation="horizontal" gutter={2}>
                        <Icon
                            name="Document"
                            theme="Line"
                            colour="night"
                            size={6}
                            halo={{
                                colour: "greyscale",
                            }}
                        />
                        <div>
                            <StackPanel
                                orientation="horizontal"
                                verticalContentAlignment="center"
                                gutter={1}
                            >
                                <Text variant="label" skinny>
                                    {fileName}
                                </Text>
                                {isDeleted && (
                                    <Ds.Badge className="ml-2">
                                        Recalled from patient
                                    </Ds.Badge>
                                )}
                            </StackPanel>
                            <StackPanel orientation="horizontal" gutter={2}>
                                <Text variant="preview">{description}</Text>
                                {sentAt && (
                                    <Text variant="preview">{sentAt}</Text>
                                )}
                            </StackPanel>
                        </div>
                    </StackPanel>
                    {downloadUrl && (
                        <StackPanel
                            orientation="horizontal"
                            gutter={1.5}
                            verticalContentAlignment="center"
                        >
                            <ArchivedWorkspaceHiddenItemWrapper>
                                <Popover>
                                    <PopoverTrigger asChild>
                                        {!isDeleted && (
                                            <Button
                                                theme="secondary"
                                                icon={{
                                                    name: "More",
                                                    style: "Fill",
                                                    title: "More",
                                                    id: "more-btn",
                                                }}
                                            />
                                        )}
                                    </PopoverTrigger>
                                    <PopoverContent>
                                        <PopoverItem
                                            onClick={toggleRecallModal}
                                        >
                                            <StackPanel
                                                gutter={0.5}
                                                orientation="horizontal"
                                                verticalContentAlignment="center"
                                            >
                                                <Button
                                                    theme="transparent"
                                                    icon={{
                                                        name: "Bin",
                                                        colour: "red",
                                                    }}
                                                    text={"Recall"}
                                                    className="text-nowrap w-100"
                                                />
                                            </StackPanel>
                                        </PopoverItem>
                                    </PopoverContent>
                                </Popover>
                            </ArchivedWorkspaceHiddenItemWrapper>
                            <a href={downloadUrl} download={fileName}>
                                <Button
                                    as="span"
                                    theme="secondary"
                                    icon={{
                                        name: "Save",
                                        style: "Line",
                                        colour: "blue",
                                    }}
                                    text="Download"
                                />
                            </a>
                        </StackPanel>
                    )}
                </StyledCardContent>
            </Card>
        </>
    );
};
