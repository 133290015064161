import { useEffect, useMemo, useRef, useState } from "react";

import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { useMedicalRecordConnection } from "@accurx/native";
import { QuickViewPortal } from "@accurx/quick-view";
import { useCompose } from "domains/message-component/context";
import {
    PatientExternalId,
    TemplateItem,
} from "domains/message-component/types";

import { LiveSearchInput } from "../../../LiveSearchInput/LiveSearchInput";
import { useTemplatesAnalytics } from "../../useTemplatesAnalytics";
import { useTemplateSearchDebounced } from "../TemplatesCombobox/useTemplateSearchDebounced";
import {
    StyledFilterContainer,
    StyledPaddedBody,
    StyledTabContainer,
    StyledTemplateSearchContainer,
    StyledTemplatesScroller,
} from "./MessageTemplateSelector.styles";
import { QuestionnaireTemplatePreview } from "./components/QuestionnaireTemplatePreview";
import { SelectedTemplate } from "./components/SelectedTemplate/SelectedTemplate";
import {
    TemplateFilterSelector,
    TemplatesFilter,
} from "./components/TemplateFilterSelector";
import { TemplateList } from "./components/TemplateList";
import { TemplatePreview } from "./components/TemplatePreview";
import { groupTemplatesByOwnerAndHeading } from "./utils/groupTemplatesByOwnerAndHeading";

type MessageTemplateSelectorProps = {
    onClickTemplate: (value: TemplateItem) => void;
    onClose: () => void;
    patientExternalIds: PatientExternalId[];
    manageTemplatesLink?: string;
    templates: TemplateItem[];
};

export const MessageTemplateSelector = ({
    onClickTemplate,
    onClose,
    patientExternalIds,
    templates,
    manageTemplatesLink,
}: MessageTemplateSelectorProps) => {
    const events = useTemplatesAnalytics();
    const connection = useMedicalRecordConnection();
    const canSaveToRecord =
        connection.status === "Connected" &&
        connection.capabilities.saveToRecord;

    const scrollerRef = useRef<HTMLElement>(null);
    const [filter, setFilter] = useState<TemplatesFilter>("All");

    const [selectedTemplate, setSelectedTemplate] =
        useState<TemplateItem | null>(null);
    const [
        isLoadingQuestionnaireEnrolment,
        setIsLoadingQuestionnaireEnrolment,
    ] = useState(false);

    const [templatePreview, setTemplatePreview] = useState<TemplateItem | null>(
        null,
    );

    const searchTermRef = useRef("");

    const { state } = useCompose();

    const hasQuestionnaireTemplates = templates.some(
        ({ type }) => type === "QuestionnaireTemplate",
    );

    const [searchedTemplates, setSearchedTemplates] =
        useState<TemplateItem[]>(templates);

    const searchDebounced = useTemplateSearchDebounced(
        templates,
        setSearchedTemplates,
    );

    const filteredTemplates = useMemo(
        () =>
            searchedTemplates.filter(
                ({ type }) =>
                    filter === "All" ||
                    (filter === "Questionnaires" &&
                        type === "QuestionnaireTemplate") ||
                    (filter === "Templates" && type === "MessageTemplate"),
            ),
        [searchedTemplates, filter],
    );

    const templateGroups = groupTemplatesByOwnerAndHeading(filteredTemplates);
    const templatesCount = filteredTemplates.length;

    const onSelectTemplate = (value: TemplateItem) => {
        setSelectedTemplate(value);
    };

    const onClickPreview = (value: TemplateItem) => {
        setTemplatePreview(value);
        events.trackTemplatePreviewButtonClick(value);
    };

    const onFilterChange = (value: TemplatesFilter) => {
        events.trackConversationFilterMenuItemClick(value);
        setFilter(value);
    };

    const onFilterClick = () => {
        events.trackConversationFilterButtonClick(filter);
    };

    // When you search or filter the list we scroll it to the top
    useEffect(() => {
        if (scrollerRef.current) {
            scrollerRef.current.scrollTop = 0;
        }
    }, [filter]);

    const resetSelectedTemplate = () => {
        setSelectedTemplate(null);
    };

    return (
        <>
            <QuickViewPortal.Header text="Preview templates" />
            <QuickViewPortal.Body>
                {templatePreview?.type === "MessageTemplate" ? (
                    <TemplatePreview
                        title={templatePreview.value.title}
                        body={templatePreview.value.body}
                        greeting={state.originalGreeting}
                        signature={state.messageSignature}
                        snomedCodes={
                            canSaveToRecord
                                ? templatePreview.value.snomedCodes
                                : []
                        }
                        attachments={templatePreview.value.attachments}
                        allowReplyByDefault={
                            templatePreview.value.allowReplyByDefault
                        }
                    />
                ) : templatePreview?.type === "QuestionnaireTemplate" ? (
                    <QuestionnaireTemplatePreview
                        template={templatePreview.value}
                        greeting={state.originalGreeting}
                        signature={state.messageSignature}
                    />
                ) : (
                    <StyledTabContainer>
                        <StyledTemplateSearchContainer>
                            <LiveSearchInput
                                autoFocus
                                placeholder={"Search"}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    searchTermRef.current = value;
                                    if (value === "") {
                                        setSearchedTemplates(templates);
                                        // Cancel any pending invocations to prevent overriding results
                                        searchDebounced.cancel();
                                    } else {
                                        searchDebounced(value);
                                    }

                                    events.trackTemplateSearchDebounced({
                                        term: value,
                                        appOrigin: "TemplatesQuickview",
                                    });
                                }}
                                aria-label={
                                    hasQuestionnaireTemplates
                                        ? "Search for template or questionnaire"
                                        : "Search for template"
                                }
                            />
                        </StyledTemplateSearchContainer>
                        {hasQuestionnaireTemplates && (
                            <StyledFilterContainer>
                                <TemplateFilterSelector
                                    value={filter}
                                    onChange={onFilterChange}
                                    onClick={onFilterClick}
                                />
                            </StyledFilterContainer>
                        )}
                        <StyledTemplatesScroller ref={scrollerRef}>
                            {templatesCount > 0 ? (
                                <TemplateList
                                    templates={templateGroups}
                                    onClickTemplate={(template) => {
                                        events.trackTemplateSelectButtonClick({
                                            template,
                                            appOrigin: "TemplatesQuickview",
                                        });
                                        onSelectTemplate(template);
                                    }}
                                    onClickPreview={onClickPreview}
                                    loadingTemplate={
                                        selectedTemplate &&
                                        isLoadingQuestionnaireEnrolment
                                            ? selectedTemplate
                                            : undefined
                                    }
                                />
                            ) : (
                                // aria-live=assertive is recommended here so screen reader users
                                // do not continue typing when we've found no results.
                                <StyledPaddedBody aria-live="assertive">
                                    <UI.Text skinny variant="body">
                                        {searchTermRef.current === ""
                                            ? "No templates found."
                                            : `No results found for '${searchTermRef.current}'.`}
                                    </UI.Text>
                                </StyledPaddedBody>
                            )}
                        </StyledTemplatesScroller>
                    </StyledTabContainer>
                )}
            </QuickViewPortal.Body>
            {(templatePreview || manageTemplatesLink) && (
                <QuickViewPortal.Footer>
                    {templatePreview && (
                        <UI.Flex justifyContent={"flex-end"} gap="1">
                            <UI.Button
                                dimension="small"
                                onClick={() => {
                                    events.trackTemplatePreviewBackButtonClick(
                                        templatePreview,
                                    );
                                    setTemplatePreview(null);
                                }}
                                icon={{
                                    name: "ArrowTail",
                                    rotation: "left",
                                    style: "Line",
                                }}
                                theme="transparent"
                                text="Back"
                            />
                            <Pill.PrimaryButton
                                dimension="small"
                                onClick={() => {
                                    events.trackTemplateSelectButtonClick({
                                        template: templatePreview,
                                        appOrigin: "TemplatePreview",
                                    });
                                    onSelectTemplate(templatePreview);
                                }}
                                aria-label={`Use ${templatePreview.value.title}`}
                                isLoading={isLoadingQuestionnaireEnrolment}
                            >
                                <Pill.Text>
                                    {templatePreview.type ===
                                    "QuestionnaireTemplate"
                                        ? "Use questionnaire"
                                        : "Use template"}
                                </Pill.Text>
                            </Pill.PrimaryButton>
                        </UI.Flex>
                    )}
                    {!templatePreview && manageTemplatesLink && (
                        <UI.Link
                            href={manageTemplatesLink}
                            openInNewTab
                            onClick={() => {
                                events.trackTemplateBrowseManageLinkClick();
                            }}
                        >
                            Create and manage templates
                            <UI.Link.Icon />
                        </UI.Link>
                    )}
                </QuickViewPortal.Footer>
            )}

            {selectedTemplate && (
                <SelectedTemplate
                    onClickTemplate={onClickTemplate}
                    resetSelectedTemplate={resetSelectedTemplate}
                    template={selectedTemplate}
                    patientExternalIds={patientExternalIds}
                    onIsLoading={setIsLoadingQuestionnaireEnrolment}
                />
            )}
        </>
    );
};
