import { Ds } from "@accurx/design";

import { ReadOnlyInput } from "./ReadOnlyInput";

interface CopyableLinkProps {
    id: string;
    ariaLabel?: string;
    onCopyClick: React.MouseEventHandler<HTMLButtonElement>;
    url: string;
}

export function CopyableLink({
    id,
    ariaLabel,
    onCopyClick,
    url,
}: CopyableLinkProps) {
    return (
        <Ds.Flex gap="1.5" justifyContent="space-between">
            <ReadOnlyInput id={id} type="text" value={url} />
            <Ds.Button
                appearance="secondary"
                onClick={onCopyClick}
                aria-label={ariaLabel}
                aria-controls={id}
            >
                <Ds.Button.Icon name="Link" />
                Copy link
            </Ds.Button>
        </Ds.Flex>
    );
}
