import React, { KeyboardEvent, useMemo, useState } from "react";

import { PatientListType } from "@accurx/api/patient-messaging";
import { FeatureName, Organisation } from "@accurx/auth";
import { Button, Feedback } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import { isArchivedWorkspace } from "@accurx/workspace-management";
import { useQueryClient } from "@tanstack/react-query";
import { shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { useAnalytics } from "reduxQuarantine/useAnalytics";
import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

import FlemingApi from "api/FlemingApiClient";
import {
    PatientListAppointment,
    PatientListPatientDetail,
} from "api/FlemingDtos/PatientListDtos";
import { FlemingAnalyticsTracker } from "app/analytics";
import { PATIENT_LIST_SUMMARY_KEY } from "app/hooks/queries/usePatientListSummariesQuery";
import { ConfirmDeleteAppointments } from "app/patientLists/ConfirmDelete/ConfirmDeleteAppointmentsModal";
import { actionCreators as patientListsActionCreators } from "app/patientLists/PatientListsActions";
import { PatientListSubMenu } from "app/patientLists/submenu/PatientListSubMenu";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { ROUTES_BASE } from "shared/Routes";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";
import { encodeFieldForCSV } from "utils/encodeFieldForCSV";

import { DropdownButton } from "./PatientListEditableSubMenu.styles";

const DEFAULT_MAXIMUM_PATIENT_LIST_LENGTH = 1000;

const {
    PatientListActionOrigin: { NavSubMenuContextMenu, NavSubMenuInlineIcon },
} = FlemingAnalyticsTracker;

export const PatientListEditableSubMenu = ({
    shouldActionButtonsBeDisabled,
    maximumPatientListLengthToDownload = DEFAULT_MAXIMUM_PATIENT_LIST_LENGTH,
}: {
    shouldActionButtonsBeDisabled?: boolean;
    maximumPatientListLengthToDownload?: number;
}): JSX.Element => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const history = useHistory();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const track = useAnalytics();

    const isFlexibleWorkspacesEnabled = useFeatureFlag(
        FeatureName.FlexibleWorkspace,
    );

    const currentList = useAppSelector(
        ({ patientLists }) => patientLists.currentList,
        shallowEqual,
    );

    const currentListDates = useAppSelector(({ patientLists }) => {
        if (!patientLists.currentList) {
            return [];
        }

        return patientLists.currentList.appointmentDays.map((day) => day.date);
    });

    const organisationId = useAppSelector(
        ({ account }) => account.selectedOrganisation,
    );

    const organisation = useAppSelector(
        ({ account }) => OrganisationHelper.getOrganisation(account),
        shallowEqual,
    );

    const isArchived = organisation
        ? isArchivedWorkspace(organisation as Organisation)
        : false;

    const [isMoreOpen, setIsMoreOpen] = useState(false);
    const [isShareDropdownOpen, setIsShareDropdownOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [scheduledVideoConsults, setScheduledVideoConsults] = useState<
        PatientListAppointment[]
    >([]);

    const handleEditListClick = (
        editOrigin: FlemingAnalyticsTracker.PatientListActionOrigin,
    ): void => {
        if (isArchived) {
            return;
        }
        dispatch(
            patientListsActionCreators.initCreateOrEditList(
                false,
                true,
                editOrigin,
            ),
        );
        if (isMoreOpen) {
            handleToggleMore();
        }
    };

    const handleDeleteClick = async (): Promise<void> => {
        if (!currentList) {
            return;
        }

        if (isArchived) {
            return;
        }

        const allScheduledAppointments: PatientListAppointment[] =
            currentList.appointmentDays
                .filter((day) => day.date !== "")
                .map((day) => day.appointments)
                .reduce((allAppts, appt) => allAppts.concat(appt), []);

        const { success, result } =
            await FlemingApi.checkVideoConsultCancellations({
                organisationId: organisationId,
                patientListId: currentList.patientListId,
                patientListEntryIds: allScheduledAppointments.map(
                    (appt) => appt.id,
                ),
            });

        if (success === false || result === null) {
            toast(
                Feedback({
                    colour: "error",
                    title: "Sorry, something went wrong",
                    content:
                        "We were unable to remove this appointment. Please refresh the page and try again.",
                }),
            );

            return;
        }

        if (result.scheduledVideoConsults.length > 0) {
            setScheduledVideoConsults(result.scheduledVideoConsults);
        }

        handleDeleteModalToggle();

        if (isMoreOpen) {
            handleToggleMore();
        }
    };

    const handleConfirmDeleteList = () => {
        FlemingAnalyticsTracker.trackPatientListDeleteButtonClick({
            ...analyticsLoggedInProps,
            origin: history.location.pathname,
            pageOrigin:
                FlemingAnalyticsTracker.PatientListActionOrigin
                    .PatientListEditor,
        });

        if (!currentList) return;

        dispatch(
            patientListsActionCreators.deletePatientList(
                {
                    organisationId,
                    patientListId: currentList.patientListId,
                },
                NavSubMenuContextMenu,
                handleSuccessDelete,
            ),
        );
    };

    const allPatients: PatientListPatientDetail[] | undefined | null = useMemo(
        () =>
            currentList?.patientDetails
                ? Object.values(currentList.patientDetails)
                : null,
        [currentList?.patientDetails],
    );

    const hasEntries = allPatients && allPatients.length > 0;

    const handleDownloadListClick = () => {
        if (!currentList || !hasEntries) return;

        track("PatientListDownload Button Click", {});

        if (allPatients.length > maximumPatientListLengthToDownload) {
            if (isMoreOpen) {
                handleToggleMore();
            }

            toast(
                Feedback({
                    colour: "error",
                    title: "There is a problem",
                    content: `List must contain ${maximumPatientListLengthToDownload} patients or less to download`,
                }),
            );
            track("PatientListDownload Button Response", { hasError: true });
            return;
        }

        const csvContent = allPatients.reduce(
            (acc, { nhsNumber, dateOfBirth }) => {
                const formattedDOB = dateOfBirth
                    ? DateHelpers.formatDate(
                          dateOfBirth,
                          DateFormatOptions.DATE_SHORT_WITH_SLASH,
                      )
                    : null;
                // not to add an empty row
                if (formattedDOB || nhsNumber) {
                    acc +=
                        [nhsNumber, formattedDOB]
                            .map(encodeFieldForCSV)
                            .join(",") + "\n";
                }
                return acc;
            },
            "NhsNumber,Date of Birth\n",
        );

        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8,",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        const name = currentList.name?.trim().replaceAll(" ", "_");

        link.setAttribute("href", url);
        link.setAttribute(
            "download",
            `patients_list${name ? `_${name}` : ""}.csv`,
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(link);

        track("PatientListDownload Button Response", { hasError: false });
    };

    const handleCancelDeleteList = () => {
        setIsDeleteModalOpen(false);
    };

    const handleSuccessDelete = (): void => {
        queryClient.invalidateQueries([
            PATIENT_LIST_SUMMARY_KEY,
            organisationId,
        ]);
        history.push(ROUTES_BASE.patientsListsAll);
    };

    const handleCreateNewListClick = (
        editOrigin: FlemingAnalyticsTracker.PatientListActionOrigin,
    ): void => {
        if (isArchived) {
            return;
        }
        dispatch(
            patientListsActionCreators.initCreateOrEditList(
                true,
                true,
                editOrigin,
            ),
        );
    };

    const handleShareListWithColleagueClick = (): void => {
        if (isArchived) {
            return;
        }
        dispatch(patientListsActionCreators.initPatientListSharing());
        isShareDropdownOpen && handleToggleShare();
    };

    const handleShareListWithWorkspaceClick = (): void => {
        dispatch(
            patientListsActionCreators.openPatientListWorkspaceSharingModal(),
        );
        isShareDropdownOpen && handleToggleShare();
    };

    // Options dropdown
    const handleToggleMore = (): void => {
        setIsMoreOpen((prevState) => !prevState);
    };

    const handleToggleShare = (): void => {
        setIsShareDropdownOpen((prevState) => !prevState);
    };

    const handleMoreKeyUp = (e: KeyboardEvent<HTMLButtonElement>): void => {
        if (e.key === "Enter") handleToggleMore();
    };

    const handleShareKeyUp = (e: KeyboardEvent<HTMLButtonElement>): void => {
        if (e.key === "Enter") handleToggleShare();
    };
    const handleEditKeyUpFromContextMenu = (
        e: KeyboardEvent<HTMLButtonElement>,
    ): void => {
        if (e.key === "Enter") handleEditListClick(NavSubMenuContextMenu);
    };

    const handleCreateKeyUpFromContextMenu = (
        e: KeyboardEvent<HTMLButtonElement>,
    ): void => {
        if (e.key === "Enter") handleCreateNewListClick(NavSubMenuContextMenu);
    };

    const handleShareWithColleagueKeyUp = (
        e: KeyboardEvent<HTMLButtonElement>,
    ): void => {
        if (e.key === "Enter") handleShareListWithColleagueClick();
    };

    const handleShareWithWorkspaceKeyUp = (
        e: KeyboardEvent<HTMLButtonElement>,
    ): void => {
        if (e.key === "Enter") handleShareListWithWorkspaceClick();
    };

    const handleDeleteKeyUp = (e: KeyboardEvent<HTMLButtonElement>): void => {
        if (e.key === "Enter") handleDeleteClick();
    };

    const handleDownloadKeyUpFromContextMenu = (
        e: KeyboardEvent<HTMLButtonElement>,
    ): void => {
        if (e.key === "Enter") handleDownloadListClick();
    };

    // Delete modal
    const handleDeleteModalToggle = (): void => {
        setIsDeleteModalOpen((prevState) => !prevState);
    };

    const patientListShareEnabled = useIsFeatureEnabled(
        FeatureName.PatientListShare,
    );

    const shouldRenderDeleteOrRemoveButton =
        currentList &&
        (currentList.isCurrentUserListOwner ||
            currentList.listType === PatientListType.UserManaged) &&
        !isArchived;

    const userCanDelete =
        currentList &&
        (currentList.isCurrentUserListOwner ||
            currentList.listType === PatientListType.WorkspaceManaged);

    const renderMoreButton = (): JSX.Element => {
        return (
            <Dropdown isOpen={isMoreOpen} toggle={handleToggleMore}>
                <DropdownToggle
                    tag="div"
                    data-toggle="dropdown"
                    aria-expanded={isMoreOpen}
                >
                    <Button
                        icon={{ name: "More", style: "Fill" }}
                        theme="secondary"
                        disabled={shouldActionButtonsBeDisabled}
                        onKeyUp={handleMoreKeyUp}
                        text="More"
                    />
                </DropdownToggle>
                <DropdownMenu right={true}>
                    {!isArchived && (
                        <DropdownButton
                            theme="transparent"
                            icon={{ name: "Pencil" }}
                            text="Edit list"
                            onClick={(): void =>
                                handleEditListClick(NavSubMenuContextMenu)
                            }
                            onKeyUp={handleEditKeyUpFromContextMenu}
                            disabled={shouldActionButtonsBeDisabled}
                        />
                    )}
                    {hasEntries && (
                        <DropdownButton
                            theme="transparent"
                            icon={{ name: "Save" }}
                            text="Download list"
                            onClick={handleDownloadListClick}
                            onKeyUp={handleDownloadKeyUpFromContextMenu}
                            disabled={shouldActionButtonsBeDisabled}
                        />
                    )}
                    {shouldRenderDeleteOrRemoveButton && (
                        <DropdownButton
                            theme="transparent"
                            icon={{ name: "Bin", colour: "red" }}
                            text={
                                userCanDelete
                                    ? "Delete list"
                                    : "Remove me from list"
                            }
                            onClick={handleDeleteClick}
                            onKeyUp={handleDeleteKeyUp}
                            disabled={shouldActionButtonsBeDisabled}
                        />
                    )}
                    {!isArchived && (
                        <DropdownButton
                            theme="transparent"
                            icon={{ name: "Plus", colour: "blue" }}
                            text="Create a new list"
                            onClick={(): void =>
                                handleCreateNewListClick(NavSubMenuContextMenu)
                            }
                            onKeyUp={handleCreateKeyUpFromContextMenu}
                            disabled={shouldActionButtonsBeDisabled}
                        />
                    )}
                </DropdownMenu>
            </Dropdown>
        );
    };

    const renderShareButton = (): JSX.Element | undefined => {
        if (!patientListShareEnabled) {
            return;
        }
        if (isArchived) {
            return;
        }
        return (
            <Dropdown isOpen={isShareDropdownOpen} toggle={handleToggleShare}>
                <DropdownToggle
                    tag="div"
                    data-toggle="dropdown"
                    aria-expanded={isShareDropdownOpen}
                >
                    <Button
                        theme="secondary"
                        onKeyUp={handleShareKeyUp}
                        text="Share"
                    />
                </DropdownToggle>
                <DropdownMenu right={true}>
                    {patientListShareEnabled && (
                        <DropdownButton
                            theme="transparent"
                            icon={{ name: "AddPerson", style: "Fill" }}
                            text="With colleague"
                            onClick={handleShareListWithColleagueClick}
                            onKeyUp={handleShareWithColleagueKeyUp}
                            disabled={shouldActionButtonsBeDisabled}
                        />
                    )}
                    <DropdownButton
                        theme="transparent"
                        icon={{ name: "AddPerson", style: "Fill" }}
                        text={`With ${
                            isFlexibleWorkspacesEnabled
                                ? "workspace"
                                : "organisation"
                        }`}
                        onClick={handleShareListWithWorkspaceClick}
                        onKeyUp={handleShareWithWorkspaceKeyUp}
                        disabled={shouldActionButtonsBeDisabled}
                    />
                </DropdownMenu>
            </Dropdown>
        );
    };

    return (
        <>
            {currentList && isDeleteModalOpen && (
                <ConfirmDeleteAppointments
                    listDetails={{
                        listName: currentList.name,
                        isCurrentUserListOwner:
                            currentList.isCurrentUserListOwner,
                        listType: currentList.listType,
                    }}
                    affectedDates={currentListDates}
                    appointmentsWithVideoConsultsToDelete={
                        scheduledVideoConsults
                    }
                    handleConfirm={handleConfirmDeleteList}
                    handleCancel={handleCancelDeleteList}
                />
            )}
            <PatientListSubMenu
                patientList={currentList}
                actionButtons={renderMoreButton()}
                shareButtons={renderShareButton()}
                editNameButton={
                    !isArchived ? (
                        <Button
                            icon={{
                                name: "Pencil",
                                title: "Edit",
                                id: "edit-icon-btn",
                            }}
                            theme="transparent"
                            onClick={(): void =>
                                handleEditListClick(NavSubMenuInlineIcon)
                            }
                            data-testid="edit-icon-btn"
                            disabled={shouldActionButtonsBeDisabled}
                            className="ml-2"
                        />
                    ) : undefined
                }
            />
        </>
    );
};
