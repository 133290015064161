import * as UI from "@accurx/design";

import { ContactDetail } from "../../types";
import { ConsentAggregate } from "./CommunicationConsent/ConsentAggregate/types";

export const VisuallyHiddenConsentText = ({
    consentAggregate,
    contactMethod,
    id,
}: {
    contactMethod: ContactDetail["method"];
    consentAggregate: ConsentAggregate;
    id: string;
}) => {
    /* If consentAggregate is Empty, it means there are no recorded
            objections and we display nothing to the user. Also display
            nothing if consentAggregate is Ambiguous. This is because
            we cannot confidently display the consent aggregate. */
    if (consentAggregate === "Empty" || consentAggregate === "Ambiguous") {
        return null;
    }

    return (
        <UI.VisuallyHidden id={id}>
            {getVisuallyHiddenText(consentAggregate, contactMethod)}
        </UI.VisuallyHidden>
    );
};

const getVisuallyHiddenText = (
    consentAggregate: Exclude<ConsentAggregate, "Empty" | "Ambiguous">,
    contactMethod: ContactDetail["method"],
) => {
    const contactMethodText = contactMethod === "Mobile" ? "mobile" : "email";

    switch (consentAggregate) {
        case "Mixed":
            return `Mixed consent given for communication by ${contactMethodText}`;
        case "AllConsented":
            return `Consent given for communication by ${contactMethodText}`;
        case "AllDeclined":
            return `Consent declined for communication by ${contactMethodText}`;
        default: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustive: never = consentAggregate;
            return null;
        }
    }
};
