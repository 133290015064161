import { useLocation } from "react-router-dom";

import { ComposeVariant } from "../../Routes.types";

const COMPOSE_VARIANT_SEARCH_PARAM_KEY = "composeVariant";

/** Used to determine which variant of the compose page to display */
export const useComposeVariant = (): ComposeVariant => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const composeVariantSearchParam = params.get(
        COMPOSE_VARIANT_SEARCH_PARAM_KEY,
    );

    if (composeVariantSearchParam === "Clinician") {
        return "Clinician";
    }

    if (composeVariantSearchParam === "Patient") {
        return "Patient";
    }

    return "Patient";
};
