import { Ds } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import { formatNhsNumber } from "@accurx/shared/dist/NhsNumberHelper";

import { StyledAdditionalDetails } from "../PatientHeader.styles";

type PatientDetailsProps = {
    nhsNumber?: string;
    dateOfBirth?: string;
};

export const AdditionalDetails = ({
    nhsNumber,
    dateOfBirth,
}: PatientDetailsProps) => {
    return (
        <StyledAdditionalDetails
            justifyContent="flex-start"
            flexDirection="row"
        >
            {nhsNumber && (
                <Ds.Text color="metal">
                    {`NHS: ${formatNhsNumber(nhsNumber)}`}
                </Ds.Text>
            )}
            <Ds.Text color="metal">
                {`DOB: ${
                    dateOfBirth
                        ? DateHelpers.formatDate(
                              dateOfBirth,
                              DateFormatOptions.DATE_SHORT_WITH_HYPHENS,
                          )
                        : "Not provided"
                }`}
            </Ds.Text>
        </StyledAdditionalDetails>
    );
};
