import { useEffect } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useCurrentWorkspace } from "@accurx/auth";
import { Button, Ds, Flex, Text } from "@accurx/design";

export const ArchivedWorkspaceWarningModal = ({
    handleClose,
    handleSelectArchivedWorkspace,
}: {
    handleClose: () => void;
    handleSelectArchivedWorkspace: () => void;
}) => {
    const currentWorkspace = useCurrentWorkspace();

    const track = useAnalytics();

    useEffect(() => {
        track("WorkspaceArchive Component View", {
            modalView: "continueToArchivedWorkspace",
        });
        // Only track event on page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleContinueToArchivedWorkspace = () => {
        track("WorkspaceArchiveContinue Button Click", {
            modalView: "continueToArchivedWorkspace",
        });
        handleSelectArchivedWorkspace();
        handleClose();
    };

    const handleStayInCurrentWorkspace = () => {
        track("WorkspaceRemain Button Click", {
            workspaceStatus: "Archived",
            eventVersion: 2,
        });
        handleClose();
    };

    return (
        <Ds.Modal open={true} onOpenChange={handleClose} dismissible={false}>
            <Ds.Modal.Section>
                <Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>
                        Continue to archived workspace?
                    </Ds.Modal.Title>
                    <Text skinny>
                        You can only view or download information for your
                        records. To send messages, you'll need to use another
                        workspace.
                    </Text>
                    <Flex justifyContent="flex-end" gap="2" flexWrap="wrap">
                        <Button
                            theme="secondary"
                            text={`Stay in ${currentWorkspace.organisationName}`}
                            onClick={handleStayInCurrentWorkspace}
                        />
                        <Button
                            text="Continue"
                            onClick={handleContinueToArchivedWorkspace}
                        />
                    </Flex>
                </Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
