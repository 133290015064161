import { useEffect, useState } from "react";

import {
    Flex,
    FormFieldWrapper,
    Icon,
    Input,
    Spinner,
    Text,
} from "@accurx/design";
import debounce from "lodash/debounce";

import { trackSearchWithinRecentSearches } from "app/analytics/FlemingAnalytics";
import { useSearchWithinRecentSearchesQuery } from "app/hooks/queries";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import {
    useCurrentOrgId,
    useCurrentOrgName,
    useIsUserApproved,
} from "store/hooks";

import EmptyState from "./EmptyState";
import ErrorState from "./ErrorState";
import { RecentSearchesHeader } from "./RecentSearchesHeader";
import RecentSearchesList from "./RecentSearchesList";

const USER_INPUT_TO_API_REQUEST_DELAY = 500;

export const RecentSearchesWithSearchBar = () => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const [userInput, setUserInput] = useState("");
    const noUserInput = userInput === "";

    const orgId = useCurrentOrgId();
    const orgName = useCurrentOrgName();
    const isUserApproved = useIsUserApproved();

    const { data, status } = useSearchWithinRecentSearchesQuery({
        workspaceId: orgId,
        queryText: userInput,
    });

    useEffect(() => {
        if (data && !noUserInput) {
            trackSearchWithinRecentSearches({
                ...analyticsLoggedInProps,
                countPatientInFilter: data.patients.length,
                countPatientsTotal: data.totalCount,
                searchStringLength: userInput.length,
            });
        }
        // eslint-disable-next-line
    }, [data]);

    const handleUserInput = debounce(({ target: { value } }) => {
        setUserInput(value);
    }, USER_INPUT_TO_API_REQUEST_DELAY);

    return (
        <Flex flexDirection="column" gap="1.5">
            <Flex alignItems={"center"} gap="1">
                <Icon
                    name="Person"
                    size={4}
                    halo={{ colour: "blue", luminosity: "high" }}
                />
                <Text skinny variant="label" as="h2">
                    {isUserApproved
                        ? `${orgName} patients`
                        : "Your recent patients"}
                </Text>
            </Flex>
            <FormFieldWrapper
                label="Search by name"
                labelProps={{ htmlFor: "search-input" }}
            >
                <Input
                    id="search-input"
                    isSearchInput
                    placeholder={
                        data?.totalCount
                            ? `Search from ${data.totalCount} recent patients`
                            : "Search from recent patients"
                    }
                    onChange={handleUserInput}
                />
            </FormFieldWrapper>

            {status === "loading" && <Spinner />}

            {status === "error" && <ErrorState />}

            {status === "success" && (
                <>
                    <RecentSearchesHeader
                        iconName={noUserInput ? "Clock" : "Search"}
                        title={
                            noUserInput
                                ? "Most relevant for you"
                                : "Top results"
                        }
                        counter={data.patients.length}
                    />
                    {data.patients.length ? (
                        <RecentSearchesList recentSearches={data.patients} />
                    ) : (
                        <EmptyState searchQuery={userInput} />
                    )}
                </>
            )}
        </Flex>
    );
};
