import {
    EventFields,
    useAnalytics,
    useMeaningfulActionAnalyticsProps,
} from "@accurx/analytics";

import {
    AppOrigin,
    OnClinicianMessageSendFnComposeData,
} from "../components/Compose/types/Compose.types";

export type ClinicianMessageAnalyticsData = {
    isReply: boolean;
    origin: string;
    conversationId: string | null;
    hasError: boolean;
    isTestPatient: boolean;
    countParticipant: number;
    appOrigin?: keyof typeof AppOrigin | null;
} & OnClinicianMessageSendFnComposeData;

// List of the custom dimensions for analytics provider
type ClinicianMessageSendCustomDimensions =
    EventFields<"ClinicianMessageSend Button Click">;

const getTemplateTrackingFields = (
    args: Pick<OnClinicianMessageSendFnComposeData, "attachments" | "template">,
): Pick<
    ClinicianMessageSendCustomDimensions,
    "templateGroup" | "withTemplate" | "templateName"
> => {
    return args.template.type === "MessageTemplate"
        ? {
              withTemplate: true,
              templateName: args.template.value.title,
              templateGroup: args.template.value.heading,
          }
        : {
              withTemplate: false,
          };
};

export const useClinicianMessageSendAnalytics = () => {
    const track = useAnalytics();

    const { isMultiFactorAuthActive, isMultiFactorAuthEnabled } =
        useMeaningfulActionAnalyticsProps();

    const trackClinicianMessageSendClick = (
        args: ClinicianMessageAnalyticsData,
    ) => {
        const templateTrackingFields = getTemplateTrackingFields(args);

        track("ClinicianMessageSend Button Click", {
            isMultiFactorAuthActive,
            isMultiFactorAuthEnabled,
            isReply: args.isReply,
            origin: args.origin,
            conversationId: args.conversationId,
            isTestPatient: args.isTestPatient,
            withAttachment: !!args.attachments.length,
            countAttachment: args.attachments.length,
            hasError: args.hasError,
            countParticipant: args.countParticipant,
            appOrigin: args.appOrigin,
            ...templateTrackingFields,
            eventVersion: 9,
        });
    };

    return {
        trackClinicianMessageSendClick,
    };
};
