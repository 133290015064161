import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import * as UI from "@accurx/design";
import { useNativeTrackingFields } from "@accurx/native";
import { clinicianMessageTemplates } from "domains/message-component/components/Compose/components/MessageTemplates/constants/clinicianMessagingTemplates";
import { useCompose } from "domains/message-component/context";

import { TextArea } from "../Textarea";
import {
    StyledContainer,
    StyledFlexGrowContainer,
    StyledScrollableFixedHeightContainer,
} from "./Compose.styles";
import { ComposeActionStack } from "./ComposeActionStack";
import { AttachSelector } from "./components/Attach/components/AttachSelector/AttachSelector";
import { AttachFileFromDesktopButtonBySourceType } from "./components/Attach/components/AttachSelector/components/AttachFileFromDesktopButton";
import { getIsFileLimitReached } from "./components/Attach/getIsFileLimitReached";
import { ClinicianRecipientDisplay } from "./components/ClinicianRecipientDisplay/ClinicianRecipientDisplay";
import { ExpandMinimiseBar } from "./components/ExpandMinimiseBar/ExpandMinimiseBar";
import { TemplatesBrowser } from "./components/MessageTemplates/components/TemplatesBrowser/TemplatesBrowser";
import { ClinicianSendMessage } from "./components/SendMessage/ClinicianSendMessage";
import { StillLoadingErrors } from "./components/StillLoadingErrors/StillLoadingErrors";
import { ClinicianComposeProps } from "./types/Compose.types";

export const ClinicianCompose = ({
    onMessageSend,
    isMessageSending,
    onMinimiseClick,
    isHeightRestricted,
    onExpandClick,
    recipient,
}: ClinicianComposeProps) => {
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();
    const { state } = useCompose();

    const [isAttachSelectorOpen, setIsAttachSelectorOpen] = useState(false);

    const hasReachedUploadLimit = getIsFileLimitReached(state);

    return (
        <StyledContainer>
            <ExpandMinimiseBar
                onMinimiseClick={onMinimiseClick}
                onExpandClick={onExpandClick}
                isHeightRestricted={isHeightRestricted ?? false}
            >
                <UI.Ds.Flex alignItems={"center"} gap={"0.5"}>
                    <UI.Ds.Text size="small">To:</UI.Ds.Text>
                    <ClinicianRecipientDisplay {...recipient} />
                </UI.Ds.Flex>
            </ExpandMinimiseBar>
            <StyledFlexGrowContainer>
                <UI.Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap={"1"}
                >
                    <TemplatesBrowser
                        templates={clinicianMessageTemplates}
                        patientExternalIds={[]}
                    />
                </UI.Flex>
                <StillLoadingErrors />
                <StyledScrollableFixedHeightContainer
                    $isHeightRestricted={isHeightRestricted || false}
                >
                    <TextArea.Container>
                        <TextArea.EditableMessage />
                    </TextArea.Container>
                    <ComposeActionStack canSaveToRecord={false} />
                </StyledScrollableFixedHeightContainer>
                <UI.Flex flexDirection={"column"} gap="0.5">
                    <UI.Flex gap="1" justifyContent="space-between">
                        <AttachSelector
                            isOpen={isAttachSelectorOpen}
                            iconOnlyButton
                            toggle={() => {
                                // only track when its being opened
                                if (!isAttachSelectorOpen) {
                                    track("Attachment Button Click", {
                                        ...nativeTrackingFields,
                                        conversationParticipant:
                                            state.conversationParticipant,
                                        eventVersion: 2,
                                    });
                                }

                                setIsAttachSelectorOpen((prev) => !prev);
                            }}
                            disabled={hasReachedUploadLimit}
                        >
                            <AttachFileFromDesktopButtonBySourceType
                                onClick={() => {
                                    track("Attachment MenuItem Click", {
                                        eventVersion: 2,
                                        attachmentType: "LocalFile",
                                        conversationParticipant:
                                            state.conversationParticipant,
                                        ...nativeTrackingFields,
                                    });
                                }}
                                onChange={() => setIsAttachSelectorOpen(false)}
                                source="ClinicianMessaging"
                            />
                        </AttachSelector>

                        <ClinicianSendMessage
                            isLoading={isMessageSending}
                            onMessageSend={onMessageSend}
                        />
                    </UI.Flex>
                </UI.Flex>
            </StyledFlexGrowContainer>
        </StyledContainer>
    );
};
