import { useCurrentWorkspace } from "@accurx/auth";
import { Flex, Tabs, Text } from "@accurx/design";
import {
    getCreatePagePath,
    getEditPagePath,
} from "domains/appointment-reminders/Routes";
import { useHistory } from "react-router";

import { OrganisationHelper } from "../../../../shared/OrganisationHelper";
import {
    ChangeRequests,
    DeliveryReceipts,
    RemindersList,
} from "../../components";
import { PageContainer, TabsWrapper } from "./components";
import { CreateReminderButton } from "./components/CreateReminderButton";

export const OverviewPage = () => {
    const workspace = useCurrentWorkspace();
    const { orgId: workspaceId, organisationName } = workspace;
    const history = useHistory();
    const isAdminUser = OrganisationHelper.getIsAdminOrgUser(workspace);

    return (
        <PageContainer>
            <Flex justifyContent="space-between" alignItems="center" gap="2">
                <Text as="h1" variant="subtitle" skinny>
                    Appointment reminders
                </Text>
                {isAdminUser && (
                    <CreateReminderButton
                        onClick={() => {
                            history.push(getCreatePagePath(workspaceId));
                        }}
                    />
                )}
            </Flex>

            <Tabs defaultSelected="manage">
                <TabsWrapper>
                    <Tabs.TabList
                        tabs={[
                            { tabId: "manage", text: "Manage reminders" },
                            { tabId: "sent", text: "Sent reminders" },
                            {
                                tabId: "change-requests",
                                text: "Appointment changes",
                            },
                        ]}
                    />
                </TabsWrapper>
                <Tabs.Panel tabId="manage">
                    <RemindersList
                        onCreate={() => {
                            history.push(getCreatePagePath(workspaceId));
                        }}
                        onEdit={(reminder) => {
                            history.push(
                                getEditPagePath(workspaceId, reminder.id),
                            );
                        }}
                        workspaceId={workspaceId}
                        workspaceName={organisationName}
                    />
                </Tabs.Panel>
                <Tabs.Panel tabId="sent">
                    <DeliveryReceipts workspaceId={workspaceId.toString()} />
                </Tabs.Panel>
                <Tabs.Panel tabId="change-requests">
                    <ChangeRequests workspaceId={workspaceId.toString()} />
                </Tabs.Panel>
            </Tabs>
        </PageContainer>
    );
};
