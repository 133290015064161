import { useAnalytics } from "@accurx/analytics";
import * as UI from "@accurx/design";
import { useNativeTrackingFields } from "@accurx/native";
import { useCompose } from "domains/message-component/context";

import { StyledIconButton } from "./ExpandMinimiseBar.styles";

export const ExpandMinimiseBar = ({
    children,
    onExpandClick,
    isHeightRestricted,
    onMinimiseClick,
}: {
    children: JSX.Element;
    onExpandClick?: () => void;
    onMinimiseClick?: () => void;
    isHeightRestricted: boolean;
}) => {
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();
    const { state } = useCompose();

    return (
        <UI.Flex justifyContent="space-between" alignItems="center" gap="1">
            {children}
            <UI.Flex gap="1">
                {onExpandClick && (
                    <StyledIconButton
                        aria-label={isHeightRestricted ? "Expand" : "Collapse"}
                        icon={{
                            name: isHeightRestricted ? "Expand" : "Collapse",
                            title: isHeightRestricted ? "Expand" : "Collapse",
                            colour: "blue",
                        }}
                        onClick={() => {
                            track("Maximise Button Click", {
                                ...nativeTrackingFields,
                                conversationParticipant:
                                    state.conversationParticipant,
                                isExpanded: !isHeightRestricted,
                            });
                            onExpandClick();
                        }}
                    />
                )}
                {onMinimiseClick && (
                    <StyledIconButton
                        aria-label="Minimise"
                        icon={{
                            name: "Minimise",
                            title: "Minimise",
                            colour: "blue",
                        }}
                        onClick={() => {
                            track("Minimise Button Click", {
                                ...nativeTrackingFields,
                                conversationParticipant:
                                    state.conversationParticipant,
                                isExpanded: !isHeightRestricted,
                            });
                            onMinimiseClick();
                        }}
                    />
                )}
            </UI.Flex>
        </UI.Flex>
    );
};
