import * as UI from "@accurx/design";

import { ConsentAggregate } from "./ConsentAggregate/types";

type ConsentAggregateIconProps = { consentAggregate: ConsentAggregate };

export const ConsentAggregateIcon = ({
    consentAggregate,
}: ConsentAggregateIconProps) => {
    if (consentAggregate === "Empty") {
        return null;
    }

    if (consentAggregate === "AllConsented") {
        return (
            <UI.Ds.Icon
                size="small"
                name={"Success"}
                color={"green"}
                appearance="solid"
            />
        );
    }

    if (consentAggregate === "Mixed" || consentAggregate === "Ambiguous") {
        return (
            <UI.Ds.Icon
                size="small"
                name={"Question"}
                color={"zinc"}
                appearance="solid"
            />
        );
    }

    return (
        <UI.Ds.Icon
            size="small"
            name={"Error"}
            color={"red"}
            appearance="solid"
        />
    );
};
