import { useEffect, useRef } from "react";

import {
    HubClient,
    createHubClient,
} from "domains/realtime/hubClient/HubClient";

import { useCanUseSignalRViaNativeBridge } from "./utils/useCanUseSignalRViaNativeBridge";

export type OptionalHubClient = HubClient | null;

type Props = {
    currentUserId: string | null;
};

export const useOptionalHubClient = ({
    currentUserId,
}: Props): OptionalHubClient => {
    const canUseSignalRViaNativeBridge = useCanUseSignalRViaNativeBridge();

    // Don't create our own SignalR connection if we want to use it via the Native Bridge.
    const hubClient = useRef<OptionalHubClient>(
        canUseSignalRViaNativeBridge ? null : createHubClient(),
    );

    useEffect(() => {
        const hubClientInstance = hubClient.current;

        // Reconnect to HubClient when the user changes
        if (currentUserId && hubClientInstance) {
            void hubClientInstance.connect();

            return () => {
                void hubClientInstance.disconnect();
            };
        }
    }, [currentUserId]);

    return hubClient.current;
};
