import {
    FeatureName,
    WorkspaceFeatureGuard,
    useCurrentWorkspace,
} from "@accurx/auth";
import { Ds, Feedback, Tokens } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { isArchivedWorkspace } from "@accurx/workspace-management";
import NotFound from "NotFound";
import { Redirect, Switch, generatePath, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import { AppRoute, SimpleRoute } from "app/AppRoute";
import { GuardedRoute } from "app/guardedRoutes/GuardedRoute";
import { useBatchDetailsQuery } from "app/hooks/queries/useBatchDetailsQuery";
import {
    ROUTES,
    ROUTES_BASE,
    ROUTES_PRIMARY,
    ROUTES_WORKSPACE,
    SLUGS_GP_BATCH,
} from "shared/Routes";
import { useWorkspaceId } from "shared/concierge/conversations/hooks";
import { useAppSelector, useCurrentUserId } from "store/hooks";

import { useLoadPractices } from "../../organisations/hooks";
import { BatchType } from "../gp/BatchMessage.types";
import { BatchMessageChoose } from "../gp/pages/BatchMessageChoose";
import { BatchMessageReview } from "../gp/pages/BatchMessageReview";
import { BatchMessageSchedule } from "../gp/pages/BatchMessageSchedule";
import { BatchMessageUpload } from "../gp/pages/BatchMessageUpload";
import { ComposeCopd } from "../gp/pages/compose/ComposeCopd";
import { ComposeFlorey } from "../gp/pages/compose/ComposeFlorey";
import { ComposeInhaler } from "../gp/pages/compose/ComposeInhaler";
import { ComposeSelfbook } from "../gp/pages/compose/ComposeSelfbook";
import { ComposeSms } from "../gp/pages/compose/ComposeSms";
import { BatchMessageOverviewPage } from "../shared/BatchMessageOverviewPage";
import { AddPatientsFromPatientListPage } from "./pages/AddPatientsFromPatientList";
import { AddPatientsPage } from "./pages/AddPatientsPage";
import { AddPatientsStatusPage } from "./pages/AddPatientsStatusPage";
import { AddPatientsViaCopyPastePage } from "./pages/AddPatientsViaCopyPaste";
import { AddPatientsViaFileUploadPage } from "./pages/AddPatientsViaFileUpload";
import { ComposePage } from "./pages/ComposePage";
import { ConfirmPage } from "./pages/ConfirmPage";

/**
 * Includes the routes to send a batch after it has been uploaded
 *
 * For each of these routes we are doing some extra guarding i.e.
 * prevent user from accessing data from another user's uploaded list
 */
const BatchMessageSendWithBatchIdSection = () => {
    const { batchId, workspaceId } = useParams<{
        batchId: string;
        workspaceId: string;
    }>();

    const currentUserId = useCurrentUserId();
    const batchDetailsQuery = useBatchDetailsQuery({
        workspaceId,
        batchId: batchId || "",
        enabled: !!batchId,
    });

    // If a user is trying to view a batch upload made by another user,
    // redirect them away.
    if (
        batchDetailsQuery.status === "success" &&
        batchDetailsQuery.data.sender.id !== currentUserId
    ) {
        toast(<Feedback title="Unable to view batch upload" colour="error" />);

        return <Redirect to={ROUTES.home} />;
    }

    return (
        <Switch>
            <AppRoute path={ROUTES_WORKSPACE.batchMessageTrustReview} exact>
                <AddPatientsStatusPage />
            </AppRoute>
            <AppRoute path={ROUTES_WORKSPACE.batchMessageTrustCompose} exact>
                <ComposePage />
            </AppRoute>
            <AppRoute path={ROUTES_WORKSPACE.batchMessageTrustConfirm} exact>
                <ConfirmPage />
            </AppRoute>
            <AppRoute path="*">
                <NotFound />
            </AppRoute>
        </Switch>
    );
};

// TODO: Remove temporary solution to accomodate Manage Org pages
const StyledManageOrgContainer = styled.div.attrs({ className: "container" })`
    margin-top: ${Tokens.SIZES[4]};
`;

/**
 * The GP Batch flow has been migrated from Manage Org, so it's not optimised for Accurx Web. There's a dependency on
 * `practices` state in Redux that is required for GP Batch pages to load, which is why we trigger the `loadPractices`
 * action when a user initialises this flow.
 */
const GpBatchSection = () => {
    useLoadPractices();
    const workspaceId = useWorkspaceId();

    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );

    // batchType is set on first page, so any following steps need redirected to initial page
    // so correct state can be set.
    const batchType = useAppSelector(
        ({ batchMessage }) => batchMessage.batchType,
    );
    const hasChooseBatchType = batchType !== BatchType.NONE;

    const getGpBatchRoute = (slug: string) =>
        `${ROUTES_PRIMARY.batchMessageTrust}/${slug}`;

    const chooseGpBatchPath = generatePath(
        getGpBatchRoute(SLUGS_GP_BATCH.choose),
        { workspaceId },
    );

    if (!practiceId) {
        return (
            <StyledManageOrgContainer>
                <Ds.Flex justifyContent="center">
                    <Ds.Spinner />
                </Ds.Flex>
            </StyledManageOrgContainer>
        );
    }

    return (
        <StyledManageOrgContainer>
            <Switch>
                <SimpleRoute
                    path={getGpBatchRoute(SLUGS_GP_BATCH.choose)}
                    exact
                >
                    <BatchMessageChoose />
                </SimpleRoute>
                <AppRoute
                    path={getGpBatchRoute(SLUGS_GP_BATCH.composeCopd)}
                    exact
                    allowRoute={{
                        isAllowed: hasChooseBatchType,
                        redirectTo: chooseGpBatchPath,
                    }}
                >
                    <ComposeCopd />
                </AppRoute>
                <AppRoute
                    path={getGpBatchRoute(SLUGS_GP_BATCH.composeSelfbook)}
                    exact
                    allowRoute={{
                        isAllowed: hasChooseBatchType,
                        redirectTo: chooseGpBatchPath,
                    }}
                >
                    <ComposeSelfbook />
                </AppRoute>
                <AppRoute
                    path={getGpBatchRoute(SLUGS_GP_BATCH.composeFlorey)}
                    exact
                    allowRoute={{
                        isAllowed: hasChooseBatchType,
                        redirectTo: chooseGpBatchPath,
                    }}
                >
                    <ComposeFlorey />
                </AppRoute>
                <AppRoute
                    path={getGpBatchRoute(SLUGS_GP_BATCH.composeInhaler)}
                    exact
                    allowRoute={{
                        isAllowed: hasChooseBatchType,
                        redirectTo: chooseGpBatchPath,
                    }}
                >
                    <ComposeInhaler />
                </AppRoute>
                <AppRoute
                    path={getGpBatchRoute(SLUGS_GP_BATCH.composeSms)}
                    exact
                    allowRoute={{
                        isAllowed: hasChooseBatchType,
                        redirectTo: chooseGpBatchPath,
                    }}
                >
                    <ComposeSms />
                </AppRoute>
                <AppRoute
                    path={getGpBatchRoute(SLUGS_GP_BATCH.upload)}
                    exact
                    allowRoute={{
                        isAllowed: hasChooseBatchType,
                        redirectTo: chooseGpBatchPath,
                    }}
                >
                    <BatchMessageUpload />
                </AppRoute>
                <AppRoute
                    path={getGpBatchRoute(SLUGS_GP_BATCH.schedule)}
                    exact
                    allowRoute={{
                        isAllowed: hasChooseBatchType,
                        redirectTo: chooseGpBatchPath,
                    }}
                >
                    <BatchMessageSchedule />
                </AppRoute>
                <AppRoute
                    path={getGpBatchRoute(SLUGS_GP_BATCH.review)}
                    exact
                    allowRoute={{
                        isAllowed: hasChooseBatchType,
                        redirectTo: chooseGpBatchPath,
                    }}
                >
                    <BatchMessageReview />
                </AppRoute>
            </Switch>
        </StyledManageOrgContainer>
    );
};

/**
 * Routes that are under the /w/:workspaceId/batch-messages umbrella
 */
export const BatchMessageTrustSection = (): JSX.Element => {
    useAccurxWebTitle("Batch messaging");
    const workspaceId = useWorkspaceId();

    const workspace = useCurrentWorkspace();

    const isArchived = isArchivedWorkspace(workspace);

    return (
        <Switch>
            {/* Redirects */}
            <AppRoute
                path={[
                    ROUTES_PRIMARY.batchMessageTrust,
                    ROUTES_BASE.batchMessageTrustSend,
                ]}
                exact
            >
                <Redirect
                    to={
                        isArchived
                            ? generatePath(
                                  ROUTES_WORKSPACE.batchMessageTrustSentMessages,
                                  {
                                      workspaceId,
                                  },
                              )
                            : generatePath(
                                  ROUTES_WORKSPACE.batchMessageTrustUpload,
                                  {
                                      workspaceId,
                                  },
                              )
                    }
                />
            </AppRoute>

            <SimpleRoute
                path={ROUTES_WORKSPACE.batchMessageTrustSentMessages}
                exact
            >
                <BatchMessageOverviewPage />
            </SimpleRoute>

            <SimpleRoute
                path={Object.values(SLUGS_GP_BATCH).map(
                    (route) => `${ROUTES_PRIMARY.batchMessageTrust}/${route}`,
                )}
            >
                <WorkspaceFeatureGuard all={[FeatureName.UnifiedNavigation]}>
                    <GpBatchSection />
                </WorkspaceFeatureGuard>
            </SimpleRoute>

            {/* Upload list routes */}
            {!isArchived && (
                <AppRoute path={ROUTES_WORKSPACE.batchMessageTrustUpload} exact>
                    <AddPatientsPage />
                </AppRoute>
            )}
            {!isArchived && (
                <SimpleRoute
                    path={ROUTES_WORKSPACE.batchMessageTrustUploadViaFile}
                    exact
                >
                    <AddPatientsViaFileUploadPage />
                </SimpleRoute>
            )}

            {!isArchived && (
                <GuardedRoute
                    path={
                        ROUTES_WORKSPACE.batchMessageTrustUploadImportPatientList
                    }
                    requires={{
                        authenticationStatus: "LoggedInWith2FA",
                        onboarded: true,
                    }}
                    exact
                >
                    <AddPatientsFromPatientListPage />
                </GuardedRoute>
            )}

            {!isArchived && (
                <SimpleRoute
                    path={ROUTES_WORKSPACE.batchMessageTrustUploadViaCopyPaste}
                    exact
                >
                    <AddPatientsViaCopyPastePage />
                </SimpleRoute>
            )}

            {/* Send routes */}
            {!isArchived && (
                <AppRoute
                    path={[
                        ROUTES_WORKSPACE.batchMessageTrustReview,
                        ROUTES_WORKSPACE.batchMessageTrustCompose,
                        ROUTES_WORKSPACE.batchMessageTrustConfirm,
                    ]}
                >
                    <BatchMessageSendWithBatchIdSection />
                </AppRoute>
            )}
            <AppRoute path="*">
                <NotFound />
            </AppRoute>
        </Switch>
    );
};
