import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import styled from "styled-components";

const StyledContainer = styled(UI.Flex).attrs({
    flexDirection: "column",
    gap: "1",
})`
    background: ${UI.Tokens.COLOURS.greyscale.white};
    padding: ${UI.Tokens.SIZES[1]};

    border-radius: ${UI.Tokens.SIZES[0.5]};
    border: ${UI.Tokens.BORDERS.normal.silver};
`;

const StyledSection = styled(UI.Flex).attrs({
    flexDirection: "column",
    gap: "1",
})``;

const StyledRow = styled(UI.Flex).attrs({
    alignItems: "center",
})`
    min-height: ${UI.Tokens.SIZES[4]};
`;

const StyledHorizontalBorder = styled.hr`
    margin: 0;
    border-color: ${UI.Tokens.COLOURS.greyscale.silver};
`;

const SquareIconButton = styled(Pill.Button)`
    padding: ${UI.Tokens.SIZES[0.5]};
`;

const StyledIcon = styled(UI.Ds.Icon).attrs({
    size: "xsmall",
})<{ $warning?: boolean }>`
    ${({ $warning }) =>
        // We add this as the Ds component does not currently support the colour orange
        $warning ? `fill: ${UI.Tokens.COLOURS.primary.orange[100]}}` : ""};
    flex-shrink: 0;
`;

const StyledSpinner = styled(UI.Ds.Spinner).attrs({
    size: "xsmall",
    "aria-hidden": "true",
})`
    height: 12.5px;
    width: 12.5px;
    margin: 3.75px;
`;

const StyledLink = styled(UI.Link)`
    padding: 0;

    // In UI.Link we force the container to span the full width of
    // the content, but this means that reply will overflow if window
    // is smaller that the link text.
    // Do not force the width of the link to be the max content
    // otherwise it doesn't go on multiple lines
    width: initial;

    font-size: ${UI.Tokens.TYPOGRAPHY.preview.desktop.fontSize};
    line-height: ${UI.Tokens.TYPOGRAPHY.preview.desktop.lineHeight};

    // If there is an icon, make it 20px
    svg {
        width: 20px;
        height: 20px;
    }
`;

const StyledText = styled(UI.Text).attrs(({ colour }) => ({
    variant: "preview",
    skinny: true,
    colour: colour ?? "night",
}))<{ $warning?: boolean; $noWrap?: boolean }>`
    ${({ $warning }) =>
        // We add this as the Ds component does not currently support the colour orange
        $warning ? `color: ${UI.Tokens.COLOURS.primary.orange[100]}};` : ""}

    ${({ $noWrap }) => ($noWrap ? `white-space: nowrap;` : "")}
`;

export const StyledActionStack = {
    Container: StyledContainer,
    Section: StyledSection,
    Row: StyledRow,
    Divider: StyledHorizontalBorder,
    SquareIconButton,
    RowIcon: StyledIcon,
    RowSpinner: StyledSpinner,
    RowLink: StyledLink,
    RowText: StyledText,
};
