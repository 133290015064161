import React from "react";

import { Icon, StackPanel, Text } from "@accurx/design";
import { useDispatch } from "react-redux";
import { generatePath, useHistory, useParams } from "react-router";

import { useAnalytics } from "app/analytics/hooks";
import { useCurrentUser } from "app/organisations/hooks";
import { useLoadAppointmentReminders } from "app/organisations/hooks/useLoadAppointmentReminders";
import { resetAppointmentComposeState } from "app/practices/appointmentReminder/AppointmentReminder.actions";
import { ROUTES_CHAIN, ROUTES_ORGS } from "shared/Routes";

import { SectionCardLink } from "../";
import { SECTIONS } from "../../HomePage.constants";
import {
    StyledCTAButton,
    StyledCTAStackPanel,
    StyledCard,
    StyledCardContent,
    StyledCardHeading,
    StyledList,
} from "../../styles/Cards.styles";

export const AppointmentRemindersProductCard = (): JSX.Element => {
    const { track } = useAnalytics();
    useLoadAppointmentReminders();

    const history = useHistory();
    const { orgId } = useParams<{ orgId: string }>();
    const dispatch = useDispatch();

    const user = useCurrentUser();
    const isAdminUser = user?.isAdmin;

    const newReminderClickHandler = () => {
        track("HomepageAppointmentReminderCreateStartButtonClick", {
            pageOrigin: "Homepage",
        });
        dispatch(resetAppointmentComposeState());
        history.push(
            generatePath(ROUTES_CHAIN.practicesAppointmentReminderCompose, {
                orgId,
            }),
            {
                from: history.location.pathname,
            },
        );
    };

    return (
        <StyledCard>
            <StyledCardHeading
                as="header"
                orientation="horizontal"
                horizontalContentAlignment="space-between"
                gutter={4}
            >
                <StackPanel
                    gutter={1.5}
                    orientation="horizontal"
                    verticalContentAlignment="center"
                    horizontalContentAlignment="left"
                >
                    <Icon
                        name="Bell"
                        size={4}
                        props={{
                            "aria-hidden": true,
                            focusable: false,
                        }}
                    />
                    <Text as="h2" skinny variant="subtitle">
                        {SECTIONS.APPOINTMENT_REMINDERS}
                    </Text>
                </StackPanel>
                {isAdminUser && (
                    <StyledCTAStackPanel
                        gutter={2}
                        orientation="horizontal"
                        verticalContentAlignment="center"
                        horizontalContentAlignment="left"
                    >
                        <StyledCTAButton
                            theme="secondary"
                            text="Create a reminder"
                            icon={{ name: "Plus", colour: "blue" }}
                            onClick={newReminderClickHandler}
                        />
                    </StyledCTAStackPanel>
                )}
            </StyledCardHeading>

            <StyledCardContent>
                <StyledList>
                    <li>
                        <SectionCardLink
                            section={SECTIONS.APPOINTMENT_REMINDERS}
                            text="Manage reminders"
                            path={generatePath(
                                ROUTES_ORGS.appointmentReminders,
                                {
                                    orgId,
                                    tab: "manage",
                                },
                            )}
                        />
                    </li>
                    <li>
                        <SectionCardLink
                            section={SECTIONS.APPOINTMENT_REMINDERS}
                            text="All sent reminders"
                            path={generatePath(
                                ROUTES_ORGS.appointmentReminders,
                                {
                                    orgId,
                                    tab: "sent",
                                },
                            )}
                        />
                    </li>
                    <li>
                        <SectionCardLink
                            section={SECTIONS.APPOINTMENT_REMINDERS}
                            text="View cancelled appointments"
                            path={generatePath(
                                ROUTES_ORGS.appointmentReminders,
                                {
                                    orgId,
                                    tab: "cancelled",
                                },
                            )}
                        />
                    </li>
                </StyledList>
            </StyledCardContent>
        </StyledCard>
    );
};
