import {
    ContainsType,
    GetFilteredTicketViewRequest,
    MarkTicketUnreadRequest,
    PatientInternalIdentity,
    PatientNoteTags,
    PatientThreadAssignTicket,
    PatientThreadAssignee,
    PatientThreadFilteredTicketView,
    PatientThreadFolderTicketView,
    PatientThreadInitialUnreadItems,
    PatientThreadMatchTicketWithExternalIdentity,
    PatientThreadMatchTicketWithToken,
    PatientThreadSummaryDetails,
    PatientThreadTicket,
    PatientThreadTicketCommandResult,
    PatientThreadTicketFolder,
    PatientThreadTicketOrdering,
    PatientTicketMarkDone,
    PatientTicketNoteCreate,
    PatientTicketReOpenTicket,
    StartsWithType,
    TicketIdentity,
    WriteCommunicationToRecordUpdate,
    WriteToRecordUpdate,
} from "@accurx/api/ticket";
import { JsonResult, getApiEndpoint, httpClient } from "@accurx/shared";
import isNil from "lodash/isNil";

const ENDPOINTS = {
    folderTicketView: "/api/conversation/web/folderticketview",
    filteredTicketView: "/api/conversation/web/filteredticketview",
    initialSummaryDetails: "/api/conversation/web/initialsummarydetails",
    initialUnreadItems: "/api/conversation/web/initialunreaditems",
    ticket: "/api/conversation/web/ticket",
    markNoteRead: "/api/conversation/web/marknoteread",
    markTicketDone: "/api/conversation/web/markticketdone",
    reopenTicket: "/api/conversation/web/reopenticket",
    matchTicketToPatient: "/api/conversation/web/matchtickettopatient",
    matchTicketToPatientWithToken:
        "/api/conversation/web/matchtickettopatientwithtoken",
    unmatchTicketFromPatient: "/api/conversation/web/unmatchticketfrompatient",
    markTicketUrgent: "/api/conversation/web/markticketurgent",
    markTicketNonUrgent: "/api/conversation/web/markticketnonurgent",
    changeAssignee: "/api/conversation/web/changeassignee",
    patientTicketView: "/api/conversation/web/patienttickets",
    markNoteAsWrittenToRecord:
        "/api/conversation/marknotewrittentorecordcommand",
    markTicketLabelAsWrittenToRecord:
        "/api/conversation/markticketlabelwrittentorecord",
    markCommunicationAsWrittenToRecord:
        "/api/conversation/markcommunicationwrittentorecordcommand",
    saveFloreyPdfToRecord:
        "/api/conversation/web/savefloreypdf/:organisationId/:floreyNoteId",
    markTicketUnread: "/api/conversation/web/markticketunread",
    addTicketNote: "/api/conversation/addticketnote",
    deleteAttachment: "/api/conversation/patientupload/softdelete",
    delayedMessages:
        "/api/patientmessaging/practices/:workspaceId/DelayedMessages",
    addTicketLabel: "/api/conversation/web/addticketlabel",
} as const;

export class TicketFetchError extends Error {
    public readonly name = "TicketFetchError";

    constructor(
        public readonly statusCode: number | null,
        public readonly message: string,
    ) {
        super(message);
    }
}

/**
 * Returns a page of ticket highlights corresponding to the folder
 * details as supplied in the request body, for example the user
 * inbox or all unassigned patient triage messages.
 */
export async function fetchFolderView(
    workspaceId: number,
    folder: PatientThreadTicketFolder,
    continuationToken?: string,
): Promise<PatientThreadFolderTicketView> {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.folderTicketView,
        }),
        {
            organisationId: workspaceId,
            folder,
            continuationToken: continuationToken,
        },
    );

    if (!response.success || isNil(response.result)) {
        throw new TicketFetchError(
            response.statusCode,
            response.error ??
                `failed to fetch folder view for user in workspace ${workspaceId}`,
        );
    }

    return response.result;
}

/**
 * Returns a page of ticket highlights corresponding to the filter
 * details as supplied in the request body, for example all open
 * tickets currently assigned to Joe Bloggs.
 */
export async function fetchMatchingTickets({
    workspaceId,
    assignee,
    isDone,
    ordering,
    continuationToken,
    patientId,
    startsWithItem,
    containsItem,
}: {
    workspaceId: number;
    assignee?: PatientThreadAssignee;
    isDone?: boolean;
    ordering: PatientThreadTicketOrdering;
    continuationToken?: string;
    patientId?: PatientInternalIdentity;
    startsWithItem?: StartsWithType;
    containsItem?: ContainsType;
}): Promise<PatientThreadFilteredTicketView> {
    const request: GetFilteredTicketViewRequest = {
        organisationId: workspaceId,
        assignee,
        isDone,
        continuationToken,
        ordering,
        patientId,
        startsWithItem,
        containsItem,
    };
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.filteredTicketView,
        }),
        request,
    );

    if (!response.success || isNil(response.result)) {
        throw new TicketFetchError(
            response.statusCode,
            response.error ??
                `failed to fetch folder view for user in workspace ${workspaceId}`,
        );
    }

    return response.result;
}

/**
 * Returns a ticket with all history of items contained within it.
 */
export async function fetchTicket(
    workspaceId: number,
    ticketIdentity: TicketIdentity,
): Promise<PatientThreadTicket> {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.ticket,
        }),
        {
            organisationId: workspaceId,
            ticketIdentity: ticketIdentity,
        },
    );

    if (!response.success || isNil(response.result)) {
        throw new TicketFetchError(
            response.statusCode,
            response.error ??
                `Failed to fetch conversation for user in workspace ${workspaceId}`,
        );
    }

    return response.result;
}

/**
 * Returns users and teams that will be used to construct the navigation
 * to see tickets assigned to teams and colleagues
 */
export async function fetchInitialSummaryDetails(
    workspaceId: number,
): Promise<PatientThreadSummaryDetails> {
    const response = await httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.initialSummaryDetails,
            query: `?organisationId=${workspaceId}`,
        }),
    );

    if (!response.success || isNil(response.result)) {
        throw new TicketFetchError(
            response.statusCode,
            response.error ??
                `failed to load initial summary for workspace ${workspaceId}`,
        );
    }

    return response.result;
}

/**
 * Returns users and teams that will be used to construct the navigation
 * to see tickets assigned to teams and colleagues
 */
export async function fetchInitialUnreadItems(
    workspaceId: number,
): Promise<PatientThreadInitialUnreadItems> {
    const response = await httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.initialUnreadItems,
            query: `?organisationId=${workspaceId}`,
        }),
    );

    if (!response.success || isNil(response.result)) {
        throw new TicketFetchError(
            response.statusCode,
            response.error ??
                `failed to load initial unread items for workspace ${workspaceId}`,
        );
    }

    return response.result;
}

/**
 * Used specific to check valid PatientThreadTicketCommandResult response
 * @param response - response from httpClient
 * */
export const isSuccessfulTicketCommandResult = (
    response: JsonResult,
): boolean => {
    return (
        response.success &&
        !isNil(response.result) &&
        !!response.result.isSuccess
    );
};

/**
 * Mark a ticket as Done.
 *
 * Returns the result of the action, including whether it was successful and any relevant changes
 * to the ticket, equivalent to the set of updates that all clients would be pushed.
 *
 * If the action was not possible due to a conflict, success will be false and the set of changes
 * will represent the full current state of the ticket so that the client may repair itself.
 */
export async function markTicketDone(
    workspaceId: number,
    ticketIdentity: TicketIdentity,
    latestToken: string,
): Promise<PatientThreadTicketCommandResult> {
    const req: PatientTicketMarkDone = {
        organisationId: workspaceId,
        ticketIdentity,
        latestToken,
    };
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.markTicketDone,
        }),
        req,
    );

    if (!isSuccessfulTicketCommandResult(response)) {
        throw new TicketFetchError(
            response.statusCode,
            response.error ??
                `Error while attempting to mark as done ticket (${ticketIdentity.type}, ${ticketIdentity.id}) in workspace ${workspaceId}`,
        );
    }

    return response.result;
}

/**
 * Mark a ticket as unread.
 *
 * Returns the result of the action, including whether it was successful and any relevant changes
 * to the ticket, equivalent to the set of updates that all clients would be pushed.
 *
 * If the action was not possible due to a conflict, success will be false and the set of changes
 * will represent the full current state of the ticket so that the client may repair itself.
 */
export async function markTicketUnread(
    request: MarkTicketUnreadRequest,
): Promise<PatientThreadTicketCommandResult> {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.markTicketUnread,
        }),
        request,
    );

    if (!isSuccessfulTicketCommandResult(response)) {
        throw new TicketFetchError(
            response.statusCode,
            response.error ??
                `Error while attempting to mark as unread ticket (${request.ticketIdentity.type}, ${request.ticketIdentity.id}) in workspace ${request.organisationId}`,
        );
    }

    return response.result;
}

/**
 * Mark a ticket as Urgent.
 *
 * Returns the result of the action, including whether it was successful and any relevant changes
 * to the ticket, equivalent to the set of updates that all clients would be pushed.
 *
 * If the action was not possible due to a conflict, success will be false and the set of changes
 * will represent the full current state of the ticket so that the client may repair itself.
 */
export async function markTicketUrgent(
    workspaceId: number,
    ticketIdentity: TicketIdentity,
    latestToken: string,
): Promise<PatientThreadTicketCommandResult> {
    const req: PatientTicketMarkDone = {
        organisationId: workspaceId,
        ticketIdentity,
        latestToken,
    };
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.markTicketUrgent,
        }),
        req,
    );

    if (!isSuccessfulTicketCommandResult(response)) {
        throw new TicketFetchError(
            response.statusCode,
            response.error ??
                `Error while attempting to mark as urgent ticket (${ticketIdentity.type}, ${ticketIdentity.id}) in workspace ${workspaceId}`,
        );
    }

    return response.result;
}

/**
 * Mark a ticket as non-urgent.
 *
 * Returns the result of the action, including whether it was successful and any relevant changes
 * to the ticket, equivalent to the set of updates that all clients would be pushed.
 *
 * If the action was not possible due to a conflict, success will be false and the set of changes
 * will represent the full current state of the ticket so that the client may repair itself.
 */
export async function markTicketNonUrgent(
    workspaceId: number,
    ticketIdentity: TicketIdentity,
    latestToken: string,
): Promise<PatientThreadTicketCommandResult> {
    const req: PatientTicketMarkDone = {
        organisationId: workspaceId,
        ticketIdentity,
        latestToken,
    };
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.markTicketNonUrgent,
        }),
        req,
    );

    if (!isSuccessfulTicketCommandResult(response)) {
        throw new TicketFetchError(
            response.statusCode,
            response.error ??
                `Error while attempting to mark as non-urgent ticket (${ticketIdentity.type}, ${ticketIdentity.id}) in workspace ${workspaceId}`,
        );
    }

    return response.result;
}

/**
 * Re-open a ticket previously marked as Done.
 *
 * Returns the result of the action, including whether it was successful and any relevant changes
 * to the ticket, equivalent to the set of updates that all clients would be pushed.
 *
 * If the action was not possible due to a conflict, success will be false and the set of changes
 * will represent the full current state of the ticket so that the client may repair itself.
 */
export async function reOpenTicket(
    workspaceId: number,
    ticketIdentity: TicketIdentity,
    latestToken: string,
): Promise<PatientThreadTicketCommandResult> {
    const req: PatientTicketReOpenTicket = {
        organisationId: workspaceId,
        ticketIdentity,
        latestToken,
    };
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.reopenTicket,
        }),
        req,
    );

    if (!isSuccessfulTicketCommandResult(response)) {
        throw new TicketFetchError(
            response.statusCode,
            response.error ??
                `Error while attempting to reopen ticket (${ticketIdentity.type}, ${ticketIdentity.id}) in workspace ${workspaceId}`,
        );
    }

    return response.result;
}

export async function assign(
    workspaceId: number,
    ticketIdentity: TicketIdentity,
    latestToken: string,
    assignee: PatientThreadAssignee,
): Promise<PatientThreadTicketCommandResult> {
    const req: PatientThreadAssignTicket = {
        organisationId: workspaceId,
        ticketIdentity,
        latestToken,
        assignee,
    };
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.changeAssignee,
        }),
        req,
    );

    if (!isSuccessfulTicketCommandResult(response)) {
        throw new TicketFetchError(
            response.statusCode,
            response.error ??
                `Error while attempting to assign ticket (${ticketIdentity.type}, ${ticketIdentity.id}) in workspace ${workspaceId}`,
        );
    }

    return response.result;
}

export const markNoteRead = async (
    request: PatientNoteTags,
): Promise<PatientThreadTicketCommandResult> => {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.markNoteRead,
        }),
        request,
    );

    if (!isSuccessfulTicketCommandResult(response)) {
        throw new TicketFetchError(
            response.statusCode,
            response.error ??
                `Failed to mark note as read, with note ID ${request.patientThreadItemIds}`,
        );
    }

    return response.result;
};

export const matchTicketToPatient = async (
    request: PatientThreadMatchTicketWithExternalIdentity,
): Promise<PatientThreadTicketCommandResult> => {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.matchTicketToPatient,
        }),
        request,
    );

    if (!isSuccessfulTicketCommandResult(response)) {
        throw new TicketFetchError(
            response.statusCode,
            response.error ?? `Failed to match ticket to patient`,
        );
    }

    return response.result;
};

export const matchTicketToPatientWithToken = async (
    request: PatientThreadMatchTicketWithToken,
): Promise<PatientThreadTicketCommandResult> => {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.matchTicketToPatientWithToken,
        }),
        request,
    );

    if (!isSuccessfulTicketCommandResult(response)) {
        throw new TicketFetchError(
            response.statusCode,
            response.error ?? `Failed to match ticket to patient`,
        );
    }

    return response.result;
};

export const unmatchTicketFromPatient = async (
    request: PatientThreadMatchTicketWithExternalIdentity,
): Promise<PatientThreadTicketCommandResult> => {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.unmatchTicketFromPatient,
        }),
        request,
    );

    if (!isSuccessfulTicketCommandResult(response)) {
        throw new TicketFetchError(
            response.statusCode,
            response.error ?? `Failed to unmatch ticket from patient`,
        );
    }

    return response.result;
};

export const markNoteAsWrittenToRecord = async (
    request: WriteToRecordUpdate,
): Promise<PatientThreadTicketCommandResult> => {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.markNoteAsWrittenToRecord,
        }),
        request,
    );

    if (!isSuccessfulTicketCommandResult(response)) {
        throw new TicketFetchError(
            response.statusCode,
            response.error ??
                `Failed to mark note as written to record, with ID ${request.patientThreadItemId}`,
        );
    }

    return response.result;
};

export const markTicketLabelAsWrittenToRecord = async (
    request: WriteToRecordUpdate,
): Promise<PatientThreadTicketCommandResult> => {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.markTicketLabelAsWrittenToRecord,
        }),
        request,
    );

    if (!isSuccessfulTicketCommandResult(response)) {
        throw new TicketFetchError(
            response.statusCode,
            response.error ??
                `Failed to mark ticket label as written to record, with ID ${request.patientThreadItemId}`,
        );
    }

    return response.result;
};

export const markCommunicationAsWrittenToRecord = async (
    request: WriteCommunicationToRecordUpdate,
): Promise<PatientThreadTicketCommandResult> => {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.markCommunicationAsWrittenToRecord,
        }),
        request,
    );

    if (!isSuccessfulTicketCommandResult(response)) {
        throw new TicketFetchError(
            response.statusCode,
            response.error ??
                `Failed to mark communication as written to record, with IDs ${request.communicationItemServerIds.join(
                    ", ",
                )}`,
        );
    }

    return response.result;
};

/**
 * UHL integration
 *
 * Saves a PDF version of the florey response
 * to the user's medical record
 */
export const saveFloreyPdfToRecord = async (request: {
    organisationId: number;
    floreyNoteId: string;
}): Promise<PatientThreadTicketCommandResult> => {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.saveFloreyPdfToRecord,
            params: {
                organisationId: request.organisationId.toString(),
                floreyNoteId: request.floreyNoteId,
            },
        }),
        {},
    );

    if (!isSuccessfulTicketCommandResult(response)) {
        throw new TicketFetchError(
            response.statusCode,
            response.error ??
                `Failed to save Florey pdf to record, with IDs ${request.floreyNoteId}`,
        );
    }

    return response.result;
};

export const addTicketNote = async (
    request: PatientTicketNoteCreate,
): Promise<PatientThreadTicketCommandResult> => {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.addTicketNote,
        }),
        request,
    );

    if (!isSuccessfulTicketCommandResult(response)) {
        throw new TicketFetchError(
            response.statusCode,
            response.error ??
                `Error while attempting to create a new note (${request.ticketIdentity?.type}, ${request.ticketIdentity?.id}) in workspace ${request.organisationId}`,
        );
    }

    return response.result;
};

export const deleteAttachment = async (request: {
    organisationId: number;
    noteId: string;
    attachmentId: string;
    reasonForDeletion: string;
    patientId?: string;
}): Promise<void> => {
    const response = await httpClient.postJsonReturnSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.deleteAttachment,
        }),
        request,
    );

    if (!response.success) {
        throw new TicketFetchError(
            response.statusCode,
            response.error ??
                `Error while attempting to delete attachment ${request.attachmentId} from note ${request.noteId} in workspace ${request.organisationId}`,
        );
    }

    // No response body is returned from the delete attachment endpoint so all
    // we can do here is return without throwing an error if the deletion was
    // successful.
    return undefined;
};

export const cancelScheduledMessage = async (request: {
    workspaceId: number;
    messageId: string;
}): Promise<void> => {
    const response = await httpClient.postJsonReturnSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.delayedMessages,
            params: { workspaceId: `${request.workspaceId}` },
        }),
        {
            isCancelled: true,
            messageId: request.messageId,
        },
    );

    if (!response.success) {
        throw new TicketFetchError(
            response.statusCode,
            response.error ??
                `Error while attempting to cancel scheduled message ${request.messageId} in workspace ${request.workspaceId}`,
        );
    }

    // No response body is returned from the cancel scheduled message endpoint so all
    // we can do here is return without throwing an error if the cancellation was
    // successful.
    return undefined;
};

export const addLabelToTicket = async ({
    workspaceId,
    ticketIdentity,
    latestToken,
    labelCode,
    userId,
    otherDescription,
}: {
    workspaceId: number;
    ticketIdentity: TicketIdentity;
    latestToken: string;
    labelCode: string;
    userId: string;
    otherDescription?: string;
}): Promise<PatientThreadTicketCommandResult> => {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.addTicketLabel,
        }),
        {
            organisationId: workspaceId,
            ticketIdentity,
            latestToken,
            labelCode,
            userId,
            otherDescription,
        },
    );

    if (!response.success) {
        throw new TicketFetchError(
            response.statusCode,
            response.error ??
                `Error while attempting to add label with code ${labelCode} to ticket in workspace ${workspaceId}`,
        );
    }

    return response.result;
};
