import React, { useEffect } from "react";

import { FeatureName, Organisation } from "@accurx/auth";
import { Button, Card, Feedback, Icon, Spinner, Text } from "@accurx/design";
import { IconNameVariants } from "@accurx/design/dist/styles/types";
import { useAccurxWebTitle } from "@accurx/navigation";
import { isArchivedWorkspace } from "@accurx/workspace-management";
import { shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { FlemingAnalyticsTracker } from "app/analytics";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { Subheader } from "app/patientLists/Subheader";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { StackPanel } from "app/sharedComponents/StackPanel";
import { useIsTwoFactorAuthenticated } from "app/twoFactorAuth/TwoFactorReducer";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { ROUTES_BASE } from "shared/Routes";
import { getTwoFactorRouteWithNext } from "shared/RoutesHelper";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

import {
    StyledButton,
    StyledText,
    StyledTryNowDiv,
} from "./AllPatientLists.styles";
import CreateOrEditPatientListModalComponent from "./CreateOrEditPatientListModalComponent";
import PatientListSummary from "./PatientListSummary";
import { actionCreators } from "./PatientListsActions";

const {
    PatientListActionOrigin: { PatientListsCard },
} = FlemingAnalyticsTracker;

export const AllPatientLists = (): JSX.Element => {
    useAccurxWebTitle("My patient lists");

    const history = useHistory();
    const dispatch = useDispatch();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const allListSummariesLoading = useAppSelector(
        ({ patientLists }) => patientLists.allListSummariesLoading,
    );
    const allListSummaries = useAppSelector(
        ({ patientLists }) => patientLists.allListSummaries || [],
    );
    const allListsError = useAppSelector(
        ({ patientLists }) => patientLists.allListSummariesError,
    );
    const organisationId = useAppSelector(
        ({ account }) => account.selectedOrganisation,
    );
    const videoConsultEnabled = useIsFeatureEnabled(
        FeatureName.VideoConsultWeb,
    );
    const messagePatientEnabled = useIsFeatureEnabled(
        FeatureName.PatientThreadSMS,
    );
    const messageGPEnabled = useIsFeatureEnabled(
        FeatureName.CaregiverInitiated,
    );

    const is2FAed = useIsTwoFactorAuthenticated();

    const organisation = useAppSelector(
        ({ account }) => OrganisationHelper.getOrganisation(account),
        shallowEqual,
    );

    const isArchived = organisation
        ? isArchivedWorkspace(organisation as Organisation)
        : false;

    useEffect(() => {
        if (organisationId) {
            dispatch(
                actionCreators.getAllUserPatientListSummaries({
                    organisationId,
                }),
            );
        }
    }, [dispatch, organisationId]);

    const handleTwoFactor = (): void => {
        FlemingAnalyticsTracker.trackTwoFactorStarted(analyticsLoggedInProps);
        handleCreateNewListClick();
    };

    const refreshPatientListSummaries = (e: MouseEvent): void => {
        e.preventDefault();
        if (!organisationId) {
            window.location.reload();
        } else {
            dispatch(
                actionCreators.getAllUserPatientListSummaries({
                    organisationId,
                }),
            );
        }
    };

    const handleCreateNewListClick = (): void => {
        if (isArchived) return;

        if (is2FAed) {
            dispatch(
                actionCreators.initCreateOrEditList(
                    true,
                    false,
                    PatientListsCard,
                ),
            );
        } else {
            history.push(
                getTwoFactorRouteWithNext(ROUTES_BASE.patientsListsAll),
            );
        }
    };

    const renderTryNowListItem = (
        iconName: IconNameVariants,
        text: string,
    ): JSX.Element => {
        return (
            <StyledTryNowDiv>
                <Icon
                    name={iconName}
                    size={4}
                    halo={{
                        colour: "blue",
                        luminosity: "high",
                    }}
                />
                <StyledText>{text}</StyledText>
            </StyledTryNowDiv>
        );
    };

    const getVideoConsultantAndSmsText = () => {
        if (videoConsultEnabled && messagePatientEnabled) {
            return "Have Video Consult calls and send SMS to patients in your lists";
        }

        if (videoConsultEnabled) {
            return "Have Video Consult calls with patients in your lists";
        }

        return "Send SMS to patients in your lists";
    };

    return (
        <>
            <PageHeader
                type={PageHeaderType.ListPage}
                title="Patient lists"
                helperText={
                    <StackPanel gutter={2}>
                        <Text variant="label">
                            Save patients to lists so you can easily contact
                            them again and again.
                        </Text>
                        <Text variant="label">
                            You can also use lists to schedule events for the
                            future.
                        </Text>
                    </StackPanel>
                }
            />

            <>
                {allListSummariesLoading && <Spinner />}
                {!allListSummariesLoading && allListsError && (
                    <Feedback
                        colour="error"
                        title="We can’t load the patient lists right now"
                    >
                        <Text as="span">
                            Click{" "}
                            <Text
                                variant="link"
                                as="a"
                                colour="zinc"
                                props={{
                                    href: "#",
                                    onClick: refreshPatientListSummaries,
                                }}
                            >
                                refresh
                            </Text>{" "}
                            to try again...
                        </Text>
                    </Feedback>
                )}
                {!allListSummariesLoading && !allListsError && (
                    <>
                        <Subheader
                            icon="List"
                            text={`Lists you're involved in · ${allListSummaries.length}`}
                            button={
                                !isArchived ? (
                                    <StyledButton
                                        text="Create new list"
                                        icon={{ name: "Plus" }}
                                        onClick={handleCreateNewListClick}
                                    />
                                ) : undefined
                            }
                        />
                        {allListSummaries.length === 0 && !isArchived && (
                            <Card>
                                {renderTryNowListItem(
                                    "Team",
                                    "Create multiple lists and add any number of patients to them",
                                )}
                                {(videoConsultEnabled ||
                                    messagePatientEnabled) &&
                                    renderTryNowListItem(
                                        "Send",
                                        getVideoConsultantAndSmsText(),
                                    )}
                                {messageGPEnabled &&
                                    renderTryNowListItem(
                                        "Mention",
                                        "Directly message the GP practice of patients in your lists",
                                    )}
                                {videoConsultEnabled &&
                                    renderTryNowListItem(
                                        "Calendar",
                                        "Create lists for dates in the future to schedule upcoming communication",
                                    )}
                                <Button
                                    text="Try it now"
                                    onClick={handleTwoFactor}
                                    className="text-nowrap mt-4"
                                />
                            </Card>
                        )}
                        {allListSummaries.length > 0 && (
                            <StackPanel gutter={1}>
                                {allListSummaries.map((summary) => {
                                    return (
                                        <PatientListSummary
                                            summary={summary}
                                            key={summary.patientListId}
                                        />
                                    );
                                })}
                            </StackPanel>
                        )}
                    </>
                )}
            </>
            <CreateOrEditPatientListModalComponent />
        </>
    );
};

export default AllPatientLists;
