import { TemplateItem } from "domains/message-component/types";

import { TemplatesGroupedByOwner } from "../types/TemplateItem";
import { StyledList } from "./TemplateList.styles";
import { TemplateListSection } from "./TemplateListSection";

type TemplateListProps = {
    templates: TemplatesGroupedByOwner;
    onClickTemplate: (template: TemplateItem) => void;
    onClickPreview: (template: TemplateItem) => void;
    loadingTemplate?: TemplateItem;
};

export const TemplateList = ({
    templates,
    onClickTemplate,
    onClickPreview,
    loadingTemplate,
}: TemplateListProps) => {
    return (
        <StyledList>
            {!!templates.User.length && (
                <li key="User">
                    <TemplateListSection
                        owner="User"
                        categories={templates.User}
                        onClickPreview={onClickPreview}
                        onClickTemplate={onClickTemplate}
                        loadingTemplate={loadingTemplate}
                    />
                </li>
            )}
            {!!templates.Organisation.length && (
                <li key="Organisation">
                    <TemplateListSection
                        owner="Organisation"
                        categories={templates.Organisation}
                        onClickPreview={onClickPreview}
                        onClickTemplate={onClickTemplate}
                        loadingTemplate={loadingTemplate}
                    />
                </li>
            )}
            {!!templates.Accurx.length && (
                <li key="Accurx">
                    <TemplateListSection
                        owner="Accurx"
                        categories={templates.Accurx}
                        onClickPreview={onClickPreview}
                        onClickTemplate={onClickTemplate}
                        loadingTemplate={loadingTemplate}
                    />
                </li>
            )}
        </StyledList>
    );
};
