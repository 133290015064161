import { useState } from "react";

import { useAuth } from "@accurx/auth";
import {
    Icon,
    Popover,
    PopoverContent,
    PopoverItem,
    PopoverTrigger,
} from "@accurx/design";
import { SupportUrls } from "@accurx/shared";
import { cleanUpSession } from "appLifecycle";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useAnalytics } from "reduxQuarantine/useAnalytics";

import {
    IsCollapsed,
    StyledCollapsibleText,
} from "app/layout/navigationMenu/NavigationMenu.styles";
import {
    ActiveState,
    COLOURS,
    LinkVariant,
} from "app/layout/navigationMenu/navLink";
import { ROUTES_BASE, ROUTES_PRIMARY } from "shared/Routes";
import { getInitials } from "shared/formatters/UserName";
import { useIsMobile } from "shared/useIsMobile";
import { useSimplifiedNavigation } from "store/hooks";

import {
    StyledButton,
    StyledInitials,
    StyledText,
} from "./AccountPopover.styles";

type AccountPopoverProps = {
    onClick?: () => void;
    isCollapsed?: IsCollapsed;
};

const AccountPopover = ({
    onClick,
    isCollapsed,
}: AccountPopoverProps): JSX.Element => {
    const history = useHistory();
    const isMobile = useIsMobile();
    const track = useAnalytics();

    const [open, setOpen] = useState(false);
    const { user, logOut } = useAuth();
    const userName = user.isLoggedIn ? user.fullName : undefined;
    const userInitials = getInitials(userName);
    // We limit the options here to help and logout with the simplified navigation
    const simpleNav = useSimplifiedNavigation();
    const match = useRouteMatch({ path: ROUTES_PRIMARY.account });

    const activeState = !!match ? ActiveState.Active : ActiveState.Inactive;
    const textColour = COLOURS.Primary[activeState];

    const handleTriggerClick = () => {
        if (!open) {
            track("NavigationMenu Button Click", {
                origin: history.location.pathname,
                navigationOptionSelected: "Account",
                eventVersion: 2,
            });
        }
    };

    const handleLogout = (): void => {
        track("NavigationAccount MenuItem Click", {
            origin: history.location.pathname,
            optionSelected: "Logout",
        });
        onClick?.();
        setOpen(false);
        logOut({ cleanUpFn: cleanUpSession });
    };

    const goToAccount = (): void => {
        track("NavigationAccount MenuItem Click", {
            origin: history.location.pathname,
            optionSelected: "Account",
        });
        onClick?.();
        history.push(ROUTES_BASE.accountUserNameAndEmail);
        setOpen(false);
    };

    const handleHelpLinkClick = (): void => {
        track("NavigationAccount MenuItem Click", {
            origin: history.location.pathname,
            optionSelected: "Help",
        });
        onClick?.();
        window?.open?.(SupportUrls.Home, "_blank")?.focus();
        setOpen(false);
    };

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger>
                <StyledButton
                    onClick={handleTriggerClick}
                    variant={LinkVariant.Primary}
                    activestate={activeState}
                >
                    <StyledInitials
                        forwardedAs="span"
                        variant="label"
                        colour={textColour}
                    >
                        {userInitials}
                    </StyledInitials>

                    <StyledCollapsibleText
                        isCollapsed={isCollapsed}
                        forwardedAs="span"
                        variant="label"
                        colour={textColour}
                    >
                        Account
                    </StyledCollapsibleText>
                </StyledButton>
            </PopoverTrigger>
            <PopoverContent
                side={isMobile ? "top" : "right"}
                align={isMobile ? "start" : "end"}
            >
                <PopoverItem>
                    <StyledText forwardedAs="span" colour="night">
                        {userName}
                    </StyledText>
                </PopoverItem>
                <hr />
                {!simpleNav && (
                    <>
                        <PopoverItem onClick={goToAccount}>
                            <StyledText forwardedAs="span" colour="night">
                                <Icon name="Cog" colour="night" size={3} />{" "}
                                Account
                            </StyledText>
                        </PopoverItem>
                        <hr />
                    </>
                )}
                <PopoverItem onClick={handleHelpLinkClick}>
                    <StyledText colour="night">
                        Help
                        <Icon
                            props={{ className: "align-bottom" }}
                            name="OpenWindow"
                            size={3}
                            colour={"night"}
                        />
                    </StyledText>
                </PopoverItem>
                <PopoverItem onClick={handleLogout}>
                    <StyledText forwardedAs="span" colour="night">
                        Logout
                    </StyledText>
                </PopoverItem>
            </PopoverContent>
        </Popover>
    );
};

export { AccountPopover };
