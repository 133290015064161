import { api } from "domains/concierge/internal/api/clinicianMessaging";
import { useConciergeDispatch } from "domains/concierge/internal/context";
import { actions } from "domains/concierge/internal/store";
import {
    UseMutationOptions,
    useMutation,
} from "domains/concierge/internal/util/useMutation";
import { Conversation } from "domains/concierge/types";

type SendMessageVariables = {
    workspaceId: string;
    message: string;
    attachmentIds: string[];
    isPostPatientMessage: boolean;
    isPresetTemplate: boolean;
    templateGroup: string | null;
    templateName: string | null;
} & (
    | { workspaceConversationId: string }
    | {
          workspaceConversationId?: never;
          patientToken: string;
      }
);

/**
 * Clinician messaging - send message
 */
export const useSendMutation = (
    options?: UseMutationOptions<SendMessageVariables, Conversation, Error>,
) => {
    const dispatch = useConciergeDispatch();

    return useMutation<SendMessageVariables, Conversation, Error>(
        "SendMessageGpMutation",
        async (params) => {
            // The params used by both the /MessagePractice and the /send endpoints
            const sharedParams = {
                workspaceId: params.workspaceId,
                externalEmailAttachmentIds: params.attachmentIds,
                textContent: params.message,
                metadata: {
                    isPresetTemplate: params.isPresetTemplate,
                    isPostPatientMessage: params.isPostPatientMessage,
                    templateLevel: params.isPresetTemplate ? "Accurx" : null,
                    templateGroup: params.templateGroup,
                    templateName: params.templateName,
                },
            };

            if (params.workspaceConversationId !== undefined) {
                const { updates } = await api.send({
                    workspaceConversationId: params.workspaceConversationId,
                    ...sharedParams,
                });

                dispatch(
                    actions.processUpdates({
                        source: "Mutation:Send",
                        ...updates,
                    }),
                );

                return updates.conversations[0];
            } else {
                const { updates } = await api.messagePractice({
                    patientToken: params.patientToken,
                    ...sharedParams,
                });

                dispatch(
                    actions.processUpdates({
                        source: "Mutation:MessagePractice",
                        ...updates,
                    }),
                );

                return updates.conversations[0];
            }
        },
        {
            ...options,
            // Figured it better to allow the user to handle the connection errors themselves. React query default to online meaning it waits for a connection to come back before attempting to send the message. I think it's safer for the user to know the message hasn't been sent so they can check their PCs network connection rather then thinking it has been sent.
            networkMode: "always",
        },
    );
};
