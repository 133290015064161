import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";

import { registerUserflowGroup, useAuth } from "@accurx/auth";
import { useRouteMatch } from "react-router";

import { registerWorkspaceConnection } from "../api/WorkspaceApi";
import { getInitialSelectedOrgId } from "./getInitialSelectedOrgId";

type SelectedOrgContext = {
    selectedOrgId: number | null;
    setSelectedOrgId: (id: number) => void;
    clearSelectedOrgId: () => void;
};

export const SelectedOrganisationContext =
    createContext<SelectedOrgContext | null>(null);

/**
 * The job of this provider is to set the initial selected organisation
 * when a user logs in and provide the value to its children.
 * It also provides a function to update the selected organisation.
 *
 * The need for this is temporary. The end goal is to have the selected organisation
 * ID provided by the URL.
 */
export const SelectedOrganisationProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const { user } = useAuth();

    /**
     * Sync up the URL with the selected org
     * from redux, so that if we load the page on an url
     * that contains the :workspaceId, and we find that id in
     * the array of orgs, override the selected org id.
     *
     * Mainly needed for when a user **loads** the inbox, otherwise we risk
     * having a different org selected in the primary nav than we
     * have in the inbox.
     */

    const params = useRouteMatch<{ workspaceId?: string; orgId?: string }>({
        path: [
            "*/w/:workspaceId",
            "*/organisations/:orgId",
            "*/practices/:orgId",
        ],
        strict: false,
        sensitive: false,
    })?.params;

    const workspaceUrlId = params?.workspaceId ?? params?.orgId;

    const [orgId, setOrgId] = useState<number | null>(
        user.isLoggedIn && !workspaceUrlId
            ? getInitialSelectedOrgId(user)
            : null,
    );

    useEffect(() => {
        if (workspaceUrlId !== undefined && user.isLoggedIn) {
            const found = user.organisations.find(
                (o) => `${o.orgId}` === workspaceUrlId,
            );
            setOrgId(found ? found.orgId : getInitialSelectedOrgId(user));
        }
        // Only trigger effect when the workspace ID in the URL changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspaceUrlId]);

    const workspace = user.isLoggedIn
        ? user.organisations.find((org) => org.orgId === orgId)
        : undefined;

    /**
     * Tracks whenever the user changes accesses or changes workspaces
     */
    useEffect(() => {
        if (user.isLoggedIn && workspace?.orgId) {
            void registerWorkspaceConnection(workspace.orgId);
        }
    }, [user.isLoggedIn, workspace?.orgId]);

    useEffect(() => {
        if (user.isLoggedIn && workspace) {
            registerUserflowGroup({ user, workspace });
        }
    }, [workspace, user]);

    return (
        <SelectedOrganisationContext.Provider
            value={{
                selectedOrgId: orgId,
                setSelectedOrgId: (id: number) => setOrgId(id),
                clearSelectedOrgId: () => setOrgId(null),
            }}
        >
            {children}
        </SelectedOrganisationContext.Provider>
    );
};

export const useSelectedOrganisation = () => {
    const context = useContext(SelectedOrganisationContext);

    if (context === null) {
        throw new Error(
            "useSelectedOrgId must be used within a SelectedOrganisationProvider",
        );
    }

    return context;
};
