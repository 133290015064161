import { useEffect } from "react";

import { useCurrentUser } from "@accurx/auth";
import { Conversation, PatientSummary } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import {
    ComposeProvider,
    generatePatientGreeting,
    generateSignature,
    useMaxAttachmentCount,
} from "@accurx/message-component";
import { useComposeAreaVisibility } from "domains/inbox/components/ConversationView/ComposeAreaVisibilityContext/useComposeAreaVisibility";
import { useMarkAsOpenMutation } from "domains/inbox/hooks/mutations";
import { usePatientContactDetails } from "domains/inbox/hooks/util";
import { useCanUseQuestionnaires } from "domains/inbox/hooks/util/useCanUseQuestionnaires";
import { useConversationActionAnalytics } from "domains/inbox/hooks/util/useConversatonActionAnalytics";
import { toast } from "react-toastify";

import { useButtonClickAnalytics } from "../../useButtonClickAnalytics";
import { ReplyContents } from "../Contents/ReplyContents";
import { MessageActionButton } from "../MessageActionButton/MessageActionButton";

type ReplyProps = {
    patient: PatientSummary;
    conversation: Conversation;
    isExpanded: boolean;
    onExpandClick: () => void;
};

export const Reply = ({
    patient,
    conversation,
    isExpanded,
    onExpandClick,
}: ReplyProps) => {
    const { replyState, setReplyState } = useComposeAreaVisibility();
    const { user } = useCurrentUser();
    const canUseQuestionnaires = useCanUseQuestionnaires();
    const maxAttachmentCount = useMaxAttachmentCount("PatientMessaging");

    const patientGreeting = generatePatientGreeting(patient);
    const userSignature = generateSignature(user);
    const { status, data: patientContactDetails } = usePatientContactDetails({
        patient,
        conversation,
        // We only want to fetch patient details when the user has clicked the button to open the reply area
        enabled: replyState === "Opening" || replyState === "Open",
    });

    const { trackReplyButtonClick } = useButtonClickAnalytics(conversation);
    const analytics = useConversationActionAnalytics({
        conversation,
        productOrigin: "MessageActions",
    });

    const markAsOpen = useMarkAsOpenMutation({
        onSuccess: () =>
            analytics.trackConversationActionButtonResponse("Reopen", false),
        onError: () => {
            analytics.trackConversationActionButtonResponse("Reopen", true);
            toast(
                <UI.Feedback title="Action failed" colour="error">
                    <UI.Text as="span">
                        Sorry, we couldn't reopen this conversation
                    </UI.Text>
                </UI.Feedback>,
            );
        },
    });

    const markAsOpenMutationIsActive =
        markAsOpen.isLoading || markAsOpen.isSuccess;

    const replyButtonText =
        conversation.status === "Done" ||
        (replyState === "Opening" && markAsOpenMutationIsActive)
            ? "Reopen and reply"
            : "Reply to patient";

    const replyButtonIcon: UI.IconNameVariants | null =
        conversation.status === "Done" ||
        (replyState === "Opening" && markAsOpenMutationIsActive)
            ? null
            : "Reply";

    useEffect(() => {
        if (
            replyState === "Opening" &&
            status !== "loading" &&
            markAsOpen.status !== "loading"
        ) {
            setReplyState("Open");
        }
    }, [replyState, setReplyState, status, markAsOpen.status]);

    if (replyState === "Closed") {
        return (
            <MessageActionButton
                onClick={() => {
                    if (conversation.status === "Done") {
                        markAsOpen.mutate(conversation);
                    }

                    setReplyState("Opening");
                    trackReplyButtonClick();
                }}
                text={replyButtonText}
                theme="primary"
                icon={replyButtonIcon ?? undefined}
            />
        );
    }

    if (replyState === "Opening") {
        return (
            <MessageActionButton
                isLoading
                theme="primary"
                text={replyButtonText}
            />
        );
    }

    return (
        <ComposeProvider
            settings={{
                // In case there's an error when fetching demographics we start up with no default details
                contactDetails: patientContactDetails.default ?? undefined,
                greeting: patientGreeting,
                editableBody: "",
                editableSignature: userSignature,
                maxAttachmentCount,
                canUseQuestionnaires,
                conversationParticipant: "WithPatient",
                shouldTryToFetchConsent: false,
            }}
        >
            <ReplyContents
                patient={patient}
                conversation={conversation}
                contactDetails={patientContactDetails.contactDetails}
                isExpanded={isExpanded}
                onExpandClick={onExpandClick}
            />
        </ComposeProvider>
    );
};
