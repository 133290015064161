import { ArchivedWorkspaceHiddenItemWrapper } from "@accurx/workspace-management";
import { generatePath } from "react-router";

import { LinkVariant, NavLink } from "app/layout/navigationMenu/navLink";
import { ROUTES_BASE, ROUTES_WORKSPACE } from "shared/Routes";
import { useWorkspaceId } from "shared/concierge/conversations/hooks";

import {
    SecondaryNavigationContent,
    SecondaryNavigationHeader,
} from "./SecondaryNavigation";
import { StyledContainer, StyledLinkList } from "./SecondaryNavigation.styles";

export const BatchMessagesTrustNavigation = ({
    onClick,
}: {
    onClick: () => void;
}): JSX.Element => {
    const workspaceId = useWorkspaceId();

    return (
        <StyledContainer>
            <SecondaryNavigationHeader>Batch message</SecondaryNavigationHeader>
            {!!workspaceId && (
                <SecondaryNavigationContent>
                    <StyledLinkList>
                        <ArchivedWorkspaceHiddenItemWrapper>
                            <li>
                                <NavLink
                                    to={generatePath(
                                        ROUTES_BASE.batchMessageTrustSend,
                                        {
                                            workspaceId,
                                        },
                                    )}
                                    text="Send new batch"
                                    variant={LinkVariant.Secondary}
                                    onClick={onClick}
                                    userflowId="batch-message-trust-secondary-send-new"
                                />
                            </li>
                        </ArchivedWorkspaceHiddenItemWrapper>
                        <li>
                            <NavLink
                                to={generatePath(
                                    ROUTES_WORKSPACE.batchMessageTrustSentMessages,
                                    {
                                        workspaceId,
                                    },
                                )}
                                text="Sent messages"
                                variant={LinkVariant.Secondary}
                                onClick={onClick}
                            />
                        </li>
                    </StyledLinkList>
                </SecondaryNavigationContent>
            )}
        </StyledContainer>
    );
};
