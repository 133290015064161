import { useCurrentWorkspace } from "@accurx/auth";
import { useMedicalRecordConnection } from "@accurx/native";
import { PatientSearchResult } from "@accurx/navigation";
import { ClinicianPatientSearch } from "domains/compose/pages/search/components/ComposePatientSearch/ClinicianPatientSearch";
import { useHistory } from "react-router-dom";

import { DisconnectedEmrErrorPage } from "../components/DisconnectedEmrErrorPage/DisconnectedEmrErrorPage";
import { useAppOrigin } from "../compose/hooks/useAppOrigin";
import { useComposeVariant } from "../compose/hooks/useComposeVariant";
import { ComposePatientSearch } from "./components/ComposePatientSearch/ComposePatientSearch";
import { PatientSearchWrapper } from "./components/PatientSearchWrapper/PatientSearchWrapper";
import { handleSelectPatient } from "./handleSearch.utils";

const supportedSystems: ReturnType<
    typeof useMedicalRecordConnection
>["system"][] = ["Emis", "SystmOne", "Vision"];

export const ComposeSearchPage = () => {
    const history = useHistory();
    const workspace = useCurrentWorkspace();
    const workspaceId = workspace.orgId;
    const composeVariant = useComposeVariant();
    const appOrigin = useAppOrigin();
    const medicalRecordConnection = useMedicalRecordConnection();

    const onSelectPatient = (result: PatientSearchResult) => {
        handleSelectPatient({
            workspaceId,
            result,
            history,
            composeVariant,
            appOrigin: appOrigin ?? undefined,
        });
    };

    return medicalRecordConnection.status === "Disconnected" ||
        (medicalRecordConnection.status === "Connected" &&
            !supportedSystems.includes(medicalRecordConnection.system)) ? (
        <DisconnectedEmrErrorPage />
    ) : (
        <PatientSearchWrapper>
            {composeVariant === "Clinician" ? (
                <ClinicianPatientSearch onSelect={onSelectPatient} />
            ) : (
                <ComposePatientSearch onSelect={onSelectPatient} />
            )}
        </PatientSearchWrapper>
    );
};
