import { Ds, Tokens } from "@accurx/design";
import styled from "styled-components";

export const LinkWrapper = styled.span`
    margin-right: -${Tokens.SIZES[1]};
`;

/**
 * This is a hack and a deviation from the design system.
 * Semantically, we want these items to behave as radio buttons
 * but without the built-in, greyed-out appearance.
 */
export const StyledRadioGroupItem = styled(Ds.RadioGroup.Item)`
    background-color: ${Tokens.COLOURS.greyscale.white} !important;

    &[data-state="checked"] {
        border-color: ${Tokens.COLOURS.primary.blue[100]} !important;
        background-color: ${Tokens.COLOURS.primary.blue[10]} !important;

        * {
            color: ${Tokens.COLOURS.primary.blue[100]} !important;
        }
    }
`;
