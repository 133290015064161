import React from "react";

import {
    Accordion,
    Button,
    Card,
    Feedback,
    Text,
    TextareaAutosize,
    Tokens,
} from "@accurx/design";
import { i } from "images";

import { UpdatingStatusType } from "shared/LoadingStatus";

import { Settings } from "../../constants";
import { PatientTriageStatus } from "../../types";
import { UrgentHelpSection } from "../UrgentHelpSection/UrgentHelpSection";

export type ChangeWelcomePageFormPropsType = {
    status: PatientTriageStatus;
    triageSettingsUpdatingStatus: UpdatingStatusType;
    hasClickedEditWelcomePage: boolean;
    welcomePageMessage: string | null;
    defaultWelcomeText: string;
    handleUpdateCustomCopy: (
        event:
            | React.MouseEvent<HTMLButtonElement>
            | React.FormEvent<HTMLFormElement>,
        name: string,
        resetToDefault: boolean | null,
    ) => void;
    handleWelcomeMessageCancel: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleUpdateString: (event: any, name: string) => void;
    hasClickedEditButton: (
        event: React.MouseEvent<HTMLButtonElement>,
        name: string,
    ) => void;
    handleAccordionClick: (
        e: React.MouseEvent<HTMLButtonElement>,
        name: string,
    ) => void;
};

// Renders the welcome page edit form

export const ChangeWelcomePageForm = ({
    status,
    triageSettingsUpdatingStatus,
    hasClickedEditWelcomePage,
    welcomePageMessage,
    defaultWelcomeText,
    handleUpdateCustomCopy,
    handleWelcomeMessageCancel,
    handleUpdateString,
    hasClickedEditButton,
    handleAccordionClick,
}: ChangeWelcomePageFormPropsType): JSX.Element => {
    const disabled =
        status.isAdmin === false ||
        triageSettingsUpdatingStatus.updating ||
        hasClickedEditWelcomePage === false;

    const isDisabled =
        status.isAdmin === false || triageSettingsUpdatingStatus.updating;

    const { TEXT_WELCOME_PAGE } = Settings;

    return (
        <>
            <form
                onSubmit={(e) =>
                    handleUpdateCustomCopy(e, TEXT_WELCOME_PAGE, false)
                }
            >
                <Card
                    props={{ className: "mb-3" }}
                    header={
                        <Text as="h3" skinny variant="subtitle">
                            Edit welcome page message
                        </Text>
                    }
                    footer={
                        <div className="d-flex justify-content-end">
                            {hasClickedEditWelcomePage ? (
                                <>
                                    <Button
                                        dimension="medium"
                                        theme="primary"
                                        disabled={isDisabled}
                                        data-testid="welcomePageSave"
                                        type="submit"
                                        text="Save changes"
                                    />
                                    <Button
                                        className="ml-3"
                                        dimension="medium"
                                        theme="secondary"
                                        onClick={(e): void =>
                                            handleUpdateCustomCopy(
                                                e,
                                                TEXT_WELCOME_PAGE,
                                                true,
                                            )
                                        }
                                        disabled={isDisabled}
                                        data-testid="welcomePageReset"
                                        text="Reset to default"
                                        type="button"
                                    />
                                    <Button
                                        type="button"
                                        className="ml-3"
                                        dimension="medium"
                                        theme="transparent"
                                        onClick={handleWelcomeMessageCancel.bind(
                                            this,
                                        )}
                                        data-testid="welcomePageCancel"
                                        text="Cancel"
                                    />
                                </>
                            ) : (
                                <Button
                                    dimension="medium"
                                    theme="primary"
                                    icon={{ name: "Pencil" }}
                                    onClick={(e): void =>
                                        hasClickedEditButton(
                                            e,
                                            TEXT_WELCOME_PAGE,
                                        )
                                    }
                                    disabled={isDisabled}
                                    data-testid="welcomePageEdit"
                                    text="Edit"
                                    type="button"
                                />
                            )}
                        </div>
                    }
                >
                    <>
                        <Text>
                            This is the text at the top of the welcome page, in
                            the yellow box
                        </Text>

                        <Accordion
                            header="See where this is on the patient form"
                            icon={{
                                colour: "night",
                                name: "Photo",
                            }}
                            className="mb-3"
                            buttonProps={{
                                onClick: (e): void =>
                                    handleAccordionClick(e, TEXT_WELCOME_PAGE),
                            }}
                        >
                            <img
                                className="w-100"
                                src={i(
                                    "patientTriage/welcome-message-location.png",
                                )}
                                alt="Location of welcome message in patient triage flow"
                            />
                        </Accordion>

                        <div className="mb-3">
                            <Feedback
                                colour="secondary"
                                title="When editing the message, remember to:"
                                content={
                                    <ul className="pl-3 mb-0">
                                        <li>
                                            specify within how long you will
                                            respond to the request
                                        </li>
                                        <li>
                                            specify if you mean working days
                                        </li>
                                        <li>not include any website links</li>
                                    </ul>
                                }
                            />
                        </div>

                        <div
                            className="p-2"
                            style={{
                                backgroundColor: Tokens.COLOURS.greyscale.cloud,
                            }}
                        >
                            <Text variant="label" props={{ className: "mb-2" }}>
                                Your current message:
                            </Text>

                            <Feedback
                                colour="warning"
                                content={
                                    <>
                                        {hasClickedEditWelcomePage ? (
                                            <TextareaAutosize
                                                cols={80}
                                                className="w-100"
                                                name="welcomePageMessage"
                                                id="welcomePageMessage"
                                                data-testid="welcomePageMessageCopy"
                                                disabled={disabled}
                                                onChange={(e) =>
                                                    handleUpdateString(
                                                        e,
                                                        TEXT_WELCOME_PAGE,
                                                    )
                                                }
                                                value={
                                                    welcomePageMessage ??
                                                    status.welcomePageMessage ??
                                                    defaultWelcomeText
                                                }
                                            />
                                        ) : (
                                            <Text skinny>
                                                {welcomePageMessage ??
                                                    status.welcomePageMessage ??
                                                    defaultWelcomeText}
                                            </Text>
                                        )}
                                        <UrgentHelpSection
                                            region={status.region}
                                        />
                                    </>
                                }
                            />
                        </div>
                    </>
                </Card>
            </form>
        </>
    );
};
