import { ROUTES_COMPOSE } from "../pages/Routes.constants";
import { ComposeVariant } from "../pages/Routes.types";

export const getRouteFromComposeVariant = (variant: ComposeVariant) => {
    switch (variant) {
        case "Clinician": {
            return ROUTES_COMPOSE.clinicianCompose;
        }
        case "Patient": {
            return ROUTES_COMPOSE.compose;
        }
        default: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustive: never = variant;

            throw new Error("Cannot map compose variant to route.");
        }
    }
};
