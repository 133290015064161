import { Text, Tokens } from "@accurx/design";
import styled from "styled-components";

export const ListPageWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${Tokens.SIZES[3]};
    margin-top: ${Tokens.SIZES[3]};
`;

export const StyledHeaderText = styled(Text)<{ variant: "subtitle" | "body" }>`
    ${({ variant }) => Tokens.TYPOGRAPHY[variant].desktop};
    margin-top: 0;
`;
