import * as UI from "@accurx/design";
import { TextareaAutosize } from "@accurx/design";
import { doveGray } from "domains/inbox/theme/colors";
import styled from "styled-components";

const { SIZES, COLOURS, TYPOGRAPHY, BORDERS } = UI.Tokens;

export const StyledContainer = styled(UI.Flex)`
    padding: ${SIZES[1.5]};
    background: ${UI.Tokens.COLOURS.primary.orange[5]};
    flex: 1 1 0;
`;

export const StyledOuterTextarea = styled(UI.Item)<{
    $isHeightRestricted: boolean;
}>`
    border-radius: ${BORDERS.radius};
    border: 1px solid transparent;

    // Align inner textarea: 2px padding from user agent + 1px border of this component
    margin-left: -3px;
    margin-right: -3px;

    /* Flex basis of 0 when height is not restricted  prevents the component from 
     * growing outside of it's parent. When height is restricted, the min-content 
     * flex basis prevents the component being collapsed entirely */
    flex: 1 1
        ${({ $isHeightRestricted }) =>
            $isHeightRestricted ? "min-content" : "0"};

    overflow-y: auto;
    ${({ $isHeightRestricted }) =>
        $isHeightRestricted && "max-height: 40vh; min-height: 20vh;"};

    &:hover {
        ${UI.hoverBorderStyles}
    }
    &:focus-within {
        ${UI.focusBorderStyles}
    }
`;

export const StyledTextArea = styled(TextareaAutosize)`
    width: 100%;
    height: 100%;
    resize: none;
    line-height: ${SIZES[3]};
    background: ${UI.Tokens.COLOURS.primary.orange[5]};

    display: block;
    font-size: ${TYPOGRAPHY.preview.desktop.fontSize};

    border-radius: ${BORDERS.radius};
    padding: ${SIZES[1]} ${SIZES[1.5]};

    color: ${COLOURS.greyscale.night};

    border: none;

    &:hover {
        border: none;
    }

    &:focus {
        overflow-y: auto;
        box-shadow: none;
        border: none;
        outline: none;
    }

    &::placeholder {
        color: ${doveGray};
    }
`;

export const StyledBottomContainer = styled(UI.Flex).attrs({
    justifyContent: "space-between",
    alignItems: "center",
    gap: "1",
})`
    margin-top: ${SIZES[1.5]};
`;

export const StyledIconWrap = styled.div`
    flex-shrink: 0;
    svg {
        width: 20px;
        height: 20px;
        margin-right: ${UI.Tokens.SIZES[0.5]};
    }
`;

export const StyledFeedback = styled(UI.Feedback)`
    margin-bottom: ${UI.Tokens.SIZES[1]};
`;
