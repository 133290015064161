import { WorkspaceClassificationsResponse } from "@accurx/api/portal";
import {
    IWrappedResult,
    getApiEndpoint,
    httpClient,
    returnDataOrThrow,
} from "@accurx/shared";
import { useQuery } from "@tanstack/react-query";

const WORKSPACE_CLASSIFICATIONS_CACHE_KEY = "WorkspaceClassifications";

const fetchWorkspaceClassifications = async ({
    workspaceId,
}: {
    workspaceId: number;
}): Promise<IWrappedResult<WorkspaceClassificationsResponse>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: `/api/portal/workspaces/${workspaceId}/classifications`,
        }),
    );

export const useWorkspaceClassifications = ({
    workspaceId,
}: {
    workspaceId: number;
}) => {
    return useQuery<
        WorkspaceClassificationsResponse,
        Error,
        WorkspaceClassificationsResponse
    >({
        queryKey: [WORKSPACE_CLASSIFICATIONS_CACHE_KEY, { workspaceId }],
        queryFn: async () => {
            const response = await fetchWorkspaceClassifications({
                workspaceId,
            });
            return returnDataOrThrow(response);
        },
    });
};
