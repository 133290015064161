import { Button, Ds } from "@accurx/design";

import { AnalyticsMapper, ChainAnalyticsTracker } from "app/analytics";
import { PatientTriageSettingObjectType } from "app/analytics/ChainAnalytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { supportUrls } from "../../constants";
import { SetupPatientTriageArticleWrapper } from "./SetupPatientTriage.styles";

function SupportLink({ title, url }: { title: string; url: string }) {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const goToLinkAndTrack = (url: string) => {
        const optionSelected = url
            .substring(url.indexOf("-") + 1)
            .replace("patient-triage", "")
            .replace(/-/g, " ");

        ChainAnalyticsTracker.trackPatientTriageManagementResourceClick({
            ...analyticsLoggedInProps,
            objectName: AnalyticsMapper.mapToPascalCase(optionSelected),
            objectType: PatientTriageSettingObjectType.BUTTON,
        });

        window.open(url, "_blank");
    };

    return (
        // TODO: Replace button links for better accessibility
        <Button
            dimension="large"
            theme="secondary"
            text={title}
            icon={{ name: "Document" }}
            onClick={() => goToLinkAndTrack(url)}
        />
    );
}

export function SupportArticles() {
    return (
        <SetupPatientTriageArticleWrapper>
            <Ds.Text as="h2" size="large" weight="bold">
                Patient Triage support articles
            </Ds.Text>
            <SupportLink
                title="How to get started"
                url={supportUrls.GET_STARTED}
            />
            <SupportLink
                title="How to manage patient requests in the Accurx Desktop Inbox"
                url={supportUrls.PATIENT_REQUESTS_INBOX}
            />
            <SupportLink
                title="Who gets notified about new requests?"
                url={supportUrls.NEW_REQUESTS}
            />
            <SupportLink
                title="How does patient identification work?"
                url={supportUrls.PATIENT_INDENTIFICATION}
            />
            <SupportLink
                title="What information do patients submit?"
                url={supportUrls.PATIENT_DATA_SUBMIT}
            />
        </SetupPatientTriageArticleWrapper>
    );
}
