import { useState } from "react";

import { useNativeSubscription } from "@accurx/native";

export const useOnWindowReset = ({
    enabled,
    onReset,
}: {
    enabled: boolean;
    onReset: () => void;
}) => {
    const { data } = useNativeSubscription("SubscribeWindowOpenStatus");

    const newResetCount = data?.resetCount ?? 0;
    const [resetCount, setResetCount] = useState<number>(newResetCount);

    if (newResetCount !== resetCount) {
        if (enabled && newResetCount > resetCount) {
            onReset();
        }
        setResetCount(newResetCount);
    }
};
