import * as UI from "@accurx/design";
import { TextareaAutosize } from "@accurx/design";
import styled from "styled-components";

const { BORDERS, COLOURS, SIZES, TYPOGRAPHY } = UI.Tokens;

export const StyledContainer = styled(UI.Flex).attrs({
    gap: "1",
    flexDirection: "column",
})`
    padding: ${SIZES[1.5]};
`;

export const StyledStickyHeader = styled.li`
    position: sticky;
    top: 0;
    z-index: 2;

    background-color: ${UI.Tokens.COLOURS.greyscale.white};
`;

export const StyledPopoverContentHeader = styled.div`
    padding: ${UI.Tokens.SIZES[1]} ${UI.Tokens.SIZES[1]} ${UI.Tokens.SIZES[1]}
        ${UI.Tokens.SIZES[1.5]};
    margin: 0px;
    background-color: ${UI.Tokens.COLOURS.greyscale.cloud};
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom: 1px solid ${UI.Tokens.COLOURS.greyscale.silver};
    height: initial;
`;

export const StyledPopoverFooter = styled(UI.Flex)`
    padding: ${UI.Tokens.SIZES[1.5]};
    border-top: 1px solid ${COLOURS.greyscale.silver};
`;

export const StyledNoteContainer = styled(UI.Cell)`
    padding: ${SIZES[1.5]};
    background: ${UI.Tokens.COLOURS.primary.orange[5]};
`;

export const StyledHorizontalBorder = styled.hr`
    margin: 0px;
`;

export const StyledFeedback = styled(UI.Feedback)`
    padding: ${UI.Tokens.SIZES[1]};
`;

export const StyledHeaderText = styled(UI.Text)`
    padding: ${UI.Tokens.SIZES[1]} ${UI.Tokens.SIZES[1.5]};

    @media screen and (min-width: ${UI.Tokens.BREAKPOINTS.md}) {
        padding: ${UI.Tokens.SIZES[1.5]} ${UI.Tokens.SIZES[2]};
    }
`;

export const StyledOuterTextarea = styled.div`
    border-radius: ${BORDERS.radius};
    border: 1px solid ${COLOURS.greyscale.white};

    // Align inner textarea: 2px padding from user agent + 1px border of this component
    margin-left: -3px;
    margin-right: -3px;

    &:hover {
        ${UI.hoverBorderStyles}
    }
    &:focus-within {
        ${UI.focusBorderStyles}
    }
`;

export const StyledAssigneeNoteTextArea = styled(TextareaAutosize)`
    width: 100%;
    height: 70px;
    max-height: 70px;
    overflow-y: hidden;
    resize: none;
    background: ${UI.Tokens.COLOURS.primary.orange[5]};
    line-height: ${UI.Tokens.TYPOGRAPHY.preview.desktop.lineHeight};
    font-size: ${UI.Tokens.TYPOGRAPHY.preview.desktop.fontSize};

    display: block;
    ${TYPOGRAPHY.body.base};

    border-radius: ${BORDERS.radius};
    padding: ${SIZES[1]};

    color: ${COLOURS.greyscale.night};

    border: 1px solid ${UI.Tokens.COLOURS.greyscale.stone};

    &:hover {
        border: none;
    }

    &:focus {
        overflow-y: auto;
        box-shadow: none;
        border: none;
        outline: none;
    }

    @media screen and (min-width: ${UI.Tokens.BREAKPOINTS.md}) {
        height: 100px;
        max-height: 100px;
        padding: ${SIZES[1]} ${SIZES[1.5]};
    }
`;

export const StyledAssigneeLists = styled.li`
    overflow-y: auto;
    scrollbar-gutter: stable;
    flex: 1;

    @media screen and (min-width: ${UI.Tokens.BREAKPOINTS.md}) {
        max-height: 240px;
    }

    div {
        position: relative;
    }

    legend {
        background-color: rgba(255, 255, 255, 0.75);
        position: sticky;
        top: -1px;
        z-index: 1;
        border-bottom: 1px solid ${COLOURS.greyscale.cloud};
        backdrop-filter: blur(8px);

        @media screen and (min-width: ${UI.Tokens.BREAKPOINTS.md}) {
            top: 0;
        }
    }
`;

export const StyledAssigneeListWrapper = styled.div`
    label span {
        align-self: center;
    }

    legend {
        margin: 0;
        margin-bottom: ${SIZES[1]};
    }
`;

export const StyledIconWrapper = styled.div`
    svg {
        width: 20px;
        height: 20px;
        margin-right: ${UI.Tokens.SIZES[0.5]};
    }
`;
