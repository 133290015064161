import { Button, TextareaAutosize, Tokens } from "@accurx/design";
import styled from "styled-components";

export const Container = styled.div`
    padding-bottom: ${Tokens.SIZES[4]};
`;

export const StyledPageHeading = styled.div`
    display: flex;
    justify-content: center;
`;

export const StyledTextArea = styled(TextareaAutosize)`
    padding: 0 ${Tokens.SIZES[1.5]};
    border: 1px solid ${Tokens.COLOURS.greyscale.silver};
    border-radius: ${Tokens.BORDERS.radius};
    font-family: ${Tokens.TYPOGRAPHY.body.base.fontFamily};
    font-size: 1rem;
    resize: none !important;
    width: 100%;
    line-height: 2rem;
    display: block;
    margin: ${Tokens.SIZES[1]} 0;
`;

export const StyledGreyBox = styled.div`
    background-color: ${Tokens.COLOURS.greyscale.cloud};
    border-radius: ${Tokens.BORDERS.radius};
    padding: ${Tokens.SIZES[1]};
`;

export const StyledWrapper = styled.div<{ $zIndex?: number }>`
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    background-color: ${Tokens.COLOURS.greyscale.white};
    border-top: 2px solid ${Tokens.COLOURS.greyscale.silver};
    padding: ${Tokens.SIZES[1]};
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 200;
`;

export const StyledLayoutWithMaxSize = styled.div`
    padding-bottom: 10%;

    @media (max-width: ${Tokens.BREAKPOINTS.md}) {
        padding-bottom: 20%;
    }
`;

export const StyledAttachmentButton = styled(Button)`
    margin-top: ${Tokens.SIZES[2]};
`;
