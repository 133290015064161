import React, { useState } from "react";

import { FeatureName } from "@accurx/auth";
import { Ds, Feedback, TabProps, Tabs, Tokens } from "@accurx/design";
import { AutoFocusHeading, useAccurxWebTitle } from "@accurx/navigation";
import { Log } from "@accurx/shared";
import keyBy from "lodash/keyBy";
import { useHistory, useLocation } from "react-router-dom";

import { trackChangeTab } from "app/analytics/FlemingAnalytics";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import {
    useCurrentOrgId,
    useCurrentOrgName,
    useIsUserApproved,
} from "store/hooks";

import { useFeatureFlag } from "../../../reduxQuarantine/useFeatureFlag";
import { AccurxTemplatesPanel } from "./AccurxTemplatesPanel";
import {
    FEEDBACK_UNAPPROVED_USER_BODY_TEXT,
    FEEDBACK_UNAPPROVED_USER_TITLE_TEXT,
} from "./ManageTemplates.utils";
import { OrganisationFloreysPanel } from "./OrganisationFloreysPanel";
import { OrganisationTemplatesPanel } from "./OrganisationTemplatesPanel";
import { UserTemplatesPanel } from "./UserTemplatesPanel";

//These tabIds should be kept in sync with BuildManageTemplatesRoute and BuildManageAccuRxTemplatesRoute in AccuRxDotCom.cs in Shared
export const TabIds = {
    UserTemplates: "UserTemplates",
    OrganisationTemplates: "OrganisationTemplates",
    AccurxTemplates: "AccurxTemplates",
    OrganisationFloreys: "OrganisationFloreys",
} as const;

type TabPanelProps = {
    tabId: string;
    isDesktopPage: boolean;
};

export type TabId = (typeof TabIds)[keyof typeof TabIds];

const ManageTemplatesTabPanel = ({
    tabId,
    isDesktopPage,
}: TabPanelProps): JSX.Element => {
    useAccurxWebTitle("Manage message templates");
    const orgId = useCurrentOrgId();
    const orgName = useCurrentOrgName();

    if (orgId === null) {
        return (
            <Feedback
                colour="error"
                title="There was a problem loading this page"
                children="Please refresh the page."
            />
        );
    }
    switch (tabId) {
        case TabIds.UserTemplates:
            return (
                <UserTemplatesPanel
                    orgId={orgId}
                    orgName={orgName}
                    isDesktopPage={isDesktopPage}
                />
            );
        case TabIds.OrganisationTemplates:
            return (
                <OrganisationTemplatesPanel
                    orgId={orgId}
                    orgName={orgName}
                    isDesktopPage={isDesktopPage}
                />
            );
        case TabIds.AccurxTemplates:
            return <AccurxTemplatesPanel orgId={orgId} />;
        case TabIds.OrganisationFloreys:
            return <OrganisationFloreysPanel orgId={orgId?.toString() || ""} />;
        default:
            return <></>;
    }
};

export const ManageTemplatesOverview = ({
    isDesktopPage = true,
}): JSX.Element => {
    const flemingLoggedInProperties = useFlemingLoggedInAnalytics();
    const history = useHistory();
    const location = useLocation();
    const isUserApproved: boolean = useIsUserApproved();
    const searchParams = new URLSearchParams(history.location.search);
    const tab = searchParams.get("tab");
    const isUnifiedNavigation = useFeatureFlag(FeatureName.UnifiedNavigation);

    const tabs: TabProps<TabId>[] = [
        { tabId: TabIds.UserTemplates, text: "My Templates" },
        { tabId: TabIds.OrganisationTemplates, text: "Workspace Templates" },
        { tabId: TabIds.AccurxTemplates, text: "Accurx Templates" },
    ];
    const customQuestionnaireTab = {
        tabId: TabIds.OrganisationFloreys,
        text: "Workspace Questionnaires",
    };

    if (!isUnifiedNavigation) {
        tabs.push(customQuestionnaireTab);
    }
    const TabLookup = keyBy(tabs, "tabId");
    const initialTab =
        TabLookup[tab || TabIds.UserTemplates] ||
        TabLookup[TabIds.UserTemplates];
    const [currentTabId, setCurrentTabId] = useState<TabId>(initialTab.tabId);
    const handleTabChange = (tabId: TabId) => {
        switch (tabId.toLowerCase()) {
            case TabIds.UserTemplates.toLowerCase():
            case TabIds.OrganisationTemplates.toLowerCase():
            case TabIds.AccurxTemplates.toLowerCase():
            case TabIds.OrganisationFloreys.toLowerCase():
                const params = new URLSearchParams({ tab: tabId });
                history.replace({
                    pathname: location.pathname,
                    search: params.toString(),
                });

                trackChangeTab({
                    ...flemingLoggedInProperties,
                    currentTab: currentTabId,
                    newTab: tabId,
                });
                return setCurrentTabId(tabId);
            default:
                Log.error(
                    `[ManageTemplatesOverview] - tab id ${tabId} is not on list`,
                );
                return setCurrentTabId(TabIds.UserTemplates);
        }
    };

    return (
        <div>
            {isDesktopPage && <Breadcrumb title="Manage templates" />}
            <Ds.Flex flexDirection="column" gap="3">
                <AutoFocusHeading
                    size={isDesktopPage ? "xxlarge" : "large"}
                    mt={isDesktopPage ? "0" : Tokens.SIZES[3]}
                >
                    Manage templates
                </AutoFocusHeading>
                {!isUserApproved && (
                    <Feedback
                        colour="warning"
                        title={FEEDBACK_UNAPPROVED_USER_TITLE_TEXT}
                    >
                        {FEEDBACK_UNAPPROVED_USER_BODY_TEXT}
                    </Feedback>
                )}
                <Tabs
                    selected={currentTabId}
                    onTabChange={(tab) => handleTabChange(tab as TabId)}
                >
                    <Tabs.TabList tabs={tabs} />
                    {tabs.map(({ tabId }) => (
                        <Tabs.Panel key={tabId} tabId={tabId}>
                            <ManageTemplatesTabPanel
                                tabId={tabId}
                                isDesktopPage={isDesktopPage}
                            />
                        </Tabs.Panel>
                    ))}
                </Tabs>
            </Ds.Flex>
        </div>
    );
};
