import React from "react";

import { Ds, Flex, Icon, Link } from "@accurx/design";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { bmBatchSelfBookTriggerScrapeWork } from "api/BatchMessaging";
import { ChainAnalyticsTracker } from "app/analytics";
import {
    resetState,
    updateBatchType,
} from "app/batchMessage/gp/BatchMessage.actions";
import { BatchType } from "app/batchMessage/gp/BatchMessage.types";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import {
    StyledBlockNestedLink,
    StyledFauxBlockContainer,
    StyledFauxBlockOverlayLink,
    StyledHoveredIconWrapper,
    StyledTextBody,
    StyledUnhoveredIconWrapper,
} from "app/sharedComponents/clickableCard/clickableCard.styles";
import { useAppSelector } from "store/hooks";

export type secondaryLinkDetails = {
    url: string;
    description: string;
};
export type BatchMessageChooseCardProps = {
    title: BatchType;
    description: string;
    primaryLink: string;
    secondaryLinkDetails?: secondaryLinkDetails;
    badgeCopy?: string;
};
export const BatchMessageChooseCard = ({
    title,
    secondaryLinkDetails,
    primaryLink,
    description,
    badgeCopy,
}: BatchMessageChooseCardProps): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    // Store
    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );

    const trackClickedLink = () => {
        ChainAnalyticsTracker.trackBatchResourceClick({
            ...analyticsLoggedInProps,
            origin: history.location.pathname,
            batchResourceName: secondaryLinkDetails?.url || "",
        });
    };

    const selectBatchType = (batchMessageCategory: BatchType) => {
        dispatch(resetState()); // Clear out previous entry if the user clicked on back button
        dispatch(updateBatchType(batchMessageCategory));

        if (batchMessageCategory === BatchType.SELFBOOK) {
            bmBatchSelfBookTriggerScrapeWork(practiceId);
        }

        ChainAnalyticsTracker.trackBatchTypeSelectClick({
            ...analyticsLoggedInProps,
            batchType: batchMessageCategory,
        });
    };

    return (
        <StyledFauxBlockContainer>
            <Flex alignItems="center" justifyContent="space-between">
                <div>
                    <Ds.Text as="h2">
                        <StyledFauxBlockOverlayLink
                            to={primaryLink}
                            onClick={() => selectBatchType(title)}
                        >
                            <Ds.Text as="span" weight="bold" mt="0.5">
                                {title}
                            </Ds.Text>
                            {badgeCopy && (
                                <Ds.Badge
                                    color="purple"
                                    radius="round"
                                    className="ml-2"
                                >
                                    {badgeCopy}
                                </Ds.Badge>
                            )}
                        </StyledFauxBlockOverlayLink>
                    </Ds.Text>
                    <StyledTextBody>
                        {description}
                        {secondaryLinkDetails && (
                            <StyledBlockNestedLink
                                href={secondaryLinkDetails.url}
                                openInNewTab
                                theme="light"
                                onClick={trackClickedLink}
                            >
                                <Link.Text
                                    text={secondaryLinkDetails.description}
                                />
                                <Link.Icon />
                            </StyledBlockNestedLink>
                        )}
                    </StyledTextBody>
                </div>

                <div className="ml-3">
                    <StyledHoveredIconWrapper>
                        <Icon
                            name="ArrowTail"
                            size={4}
                            rotation="right"
                            halo={{
                                colour: "blue",
                                luminosity: "low",
                            }}
                            props={{ "aria-hidden": true, focusable: false }}
                        />
                    </StyledHoveredIconWrapper>
                    <StyledUnhoveredIconWrapper>
                        <Icon
                            name="ArrowTail"
                            size={4}
                            rotation="right"
                            halo={{
                                colour: "blue",
                                luminosity: "high",
                            }}
                            props={{ "aria-hidden": true, focusable: false }}
                        />
                    </StyledUnhoveredIconWrapper>
                </div>
            </Flex>
        </StyledFauxBlockContainer>
    );
};
