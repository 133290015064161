import { useEffect, useRef } from "react";

import { useRealtimeForSignalR } from "@accurx/realtime";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { ConciergeState } from "domains/concierge/internal/types/ConciergeState";

import {
    useConciergeDispatch,
    useConciergeGetState,
    useConciergeMeta,
} from "../context";

type Subscribe = ReturnType<typeof useRealtimeForSignalR>["subscribe"];
export type SubscriptionParams = {
    subscribe: Subscribe;
    dispatch: Dispatch<AnyAction>;
    getState: () => ConciergeState;
    workspaceId: number;
    currentUserId: string;
};

export type Subscription = (arg: SubscriptionParams) => () => void;

export const useSignalRSubscription = (subscription: Subscription) => {
    const { workspaceId, userId } = useConciergeMeta();
    const { subscribe, connectionState } = useRealtimeForSignalR();
    const dispatch = useConciergeDispatch();

    // To prevent unnecessary re-renders
    const getStateRef = useRef(useConciergeGetState());

    useEffect(() => {
        if (connectionState !== "Connected") return;

        const unsubscribe = subscription({
            subscribe,
            dispatch,
            getState: getStateRef.current,
            workspaceId,
            currentUserId: userId,
        });
        return () => unsubscribe();
    }, [
        connectionState,
        dispatch,
        subscribe,
        subscription,
        userId,
        workspaceId,
    ]);
};
