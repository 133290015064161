import * as UI from "@accurx/design";
import styled from "styled-components";

const List = styled.ul`
    display: grid;
    grid-template-rows: repeat(5, min-content);
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    grid-gap: ${UI.Tokens.SIZES[1]};

    list-style: none;

    margin: 0;
    padding: 0;
`;

const Wrapper = styled.div`
    border: 1px solid ${UI.Tokens.COLOURS.greyscale.silver};
    border-radius: ${UI.Tokens.SIZES[0.5]};

    background: ${UI.Tokens.COLOURS.greyscale.white};
`;

const Body = styled.div`
    padding: ${UI.Tokens.SIZES[1.5]};
`;

const Header = styled.div<{ $showFullBorder?: boolean }>`
    padding: ${UI.Tokens.SIZES[1]} ${UI.Tokens.SIZES[1.5]};

    ${({ $showFullBorder }) => {
        if ($showFullBorder) {
            return `
            border-radius: ${UI.Tokens.SIZES[0.5]};
            border: 1px solid ${UI.Tokens.COLOURS.greyscale.silver};
            `;
        } else {
            return `
            border-radius: ${UI.Tokens.SIZES[0.5]} ${UI.Tokens.SIZES[0.5]} 0 0;
            border-bottom: 1px solid ${UI.Tokens.COLOURS.greyscale.silver};
            `;
        }
    }}
`;

const Link = styled(UI.Link)`
    font-size: ${UI.Tokens.TYPOGRAPHY.note.desktop.fontSize};
`;

export const StyledOutcomeRecording = {
    Wrapper,
    Body,
    Header,
    List,
    Link,
};
