import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledTable = styled.table`
    border-collapse: separate;
    border-spacing: 0;

    thead > tr > td {
        border-bottom: ${Tokens.BORDERS.normal.silver};
        position: sticky;
        top: 0;
    }

    tr {
        border-bottom: ${Tokens.BORDERS.normal.silver};
    }

    td {
        background-color: ${Tokens.COLOURS.greyscale.white};
        padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[1.5]};
        white-space: nowrap;
        border-top: none;
        border-bottom: ${Tokens.BORDERS.normal.silver};
    }
`;

export const FixedWidthCell = styled.td`
    border-right: ${Tokens.BORDERS.normal.silver};
    left: 0;
    max-width: 250px;
    overflow: hidden;
    position: sticky;
    text-overflow: ellipsis;
    z-index: 1;
`;
