import { Ds } from "@accurx/design";
import styled from "styled-components";

import { AssigneeOnlineIndicator } from "../AssigneeOnlineIndicator/AssigneeOnlineIndicator";

export const StyledAssigneeAvatarWrapper = styled.span`
    position: relative;
`;

export const StyledAssigneeOnlineIndicator = styled(AssigneeOnlineIndicator)`
    position: absolute;
    right: -2px;
    bottom: -2px;
`;

export const StyledAssigneeText = styled(Ds.Text)`
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    hyphens: auto;
    float: left;
    text-align: left;
`;
