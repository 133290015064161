import { filters } from "domains/concierge/internal/util/conversationGroupFilters";
import { sortOptions } from "domains/concierge/internal/util/conversationGroupSortOptions";

import { useConversationCount } from "./useConversationCount";

export const useUserConversationCount = (userId: string) => {
    const status = "Open";
    const sort = "Newest";

    return useConversationCount(
        filters.user({ userId, status }),
        sortOptions.common({ status, sort }),
    );
};
