import * as UI from "@accurx/design";

import { StyledActionStack } from "../ActionStack.styles";

export const ResponseAssignee = ({
    assigneeLabel,
}: {
    assigneeLabel: JSX.Element;
}) => {
    return (
        <StyledActionStack.Row
            justifyContent={"space-between"}
            alignItems={"center"}
            flexWrap={{ xs: "wrap", sm: "nowrap" }}
            gap="1"
        >
            <UI.Ds.Flex.Item flex="1">
                <UI.Ds.Flex gap="1" alignItems="center">
                    <StyledActionStack.RowIcon
                        name="Assign"
                        appearance="solid"
                    />
                    <StyledActionStack.RowText $noWrap>
                        Assigned to
                    </StyledActionStack.RowText>
                </UI.Ds.Flex>
            </UI.Ds.Flex.Item>
            {assigneeLabel}
        </StyledActionStack.Row>
    );
};
