/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import { ConnectionState } from "@accurx/realtime/hubClient/ConnectionState";
import isEqual from "lodash/isEqual";
import { Observable, Subscribable, combineLatest, interval } from "rxjs";
import {
    concatMap,
    distinctUntilChanged,
    filter,
    map,
    scan,
    startWith,
} from "rxjs/operators";

import { EpochStatus } from "./types/component.types";

export const defaultEpochStatusSeed: EpochStatus = {
    status: "None",
    epochVersion: 0,
};

export const defaultEpochLoopStateSeed: EpochLoopState = {
    ...defaultEpochStatusSeed,
    tickOnConnectedIntervalLoop: 0,
};

export const createEpochFeed = (
    connectionStatusFeed: Subscribable<ConnectionState>,
    refreshIntervalMs: number,
): Observable<EpochStatus> => {
    // Configure a refresh interval feed.
    const intervalFeed = interval(refreshIntervalMs).pipe(
        startWith(-1),
        map((loopCount) => loopCount + 1),
    );

    return combineLatest([connectionStatusFeed, intervalFeed]).pipe(
        scan(
            (
                aggregate: EpochLoopState,
                [latestConnectionStatus, intervalCount],
            ) => updateEpoch(aggregate, latestConnectionStatus, intervalCount),
            defaultEpochLoopStateSeed,
        ),
        filter((epochState) => epochState.status !== "None"),
        map(
            (loopState): EpochStatus => ({
                status: loopState.status,
                epochVersion: loopState.epochVersion,
            }),
        ),
        distinctUntilChanged(isEqual),
    );
};

/**
 * Update the current Epoch state.
 * @param currentEpochLoopState The current epoch state.
 * @param connectionState The current connection state.
 * @param intervalCount the number of times that the interval has fired.
 * @returns the new epoch state.
 */
const updateEpoch = (
    currentEpochLoopState: EpochLoopState,
    connectionState: ConnectionState,
    intervalCount: number,
): EpochLoopState => {
    // Online -> Offline (i.e. we want to start the background refreshing process but not trigger anything immediately)
    if (
        currentEpochLoopState.status !== "Offline" &&
        connectionState.state === "Disconnected"
    ) {
        return {
            status: "Offline",
            epochVersion: currentEpochLoopState.epochVersion,
            tickOnConnectedIntervalLoop: undefined,
        };
    }

    // Online -> Online (i.e. no change, but want to push epoch version after we have gone back online)
    if (
        connectionState.state === "Connected" &&
        currentEpochLoopState.tickOnConnectedIntervalLoop !== undefined &&
        currentEpochLoopState.tickOnConnectedIntervalLoop <= intervalCount
    ) {
        return {
            status: "Online",
            epochVersion: currentEpochLoopState.epochVersion + 1,
            tickOnConnectedIntervalLoop: undefined,
        };
    }

    // Offline -> Online (i.e. we want to start the reconnection process)
    if (
        currentEpochLoopState.status !== "Online" &&
        connectionState.state === "Connected"
    ) {
        return {
            status: "Offline",
            epochVersion: currentEpochLoopState.epochVersion,
            tickOnConnectedIntervalLoop:
                currentEpochLoopState.tickOnConnectedIntervalLoop ??
                intervalCount + 1,
        };
    }

    // Offline -> Offline (i.e. no change, but we want to tick up the epoch value)
    if (
        currentEpochLoopState.status === "Offline" &&
        connectionState.state === "Disconnected"
    ) {
        return {
            ...currentEpochLoopState,
            epochVersion: currentEpochLoopState.epochVersion + 1,
        };
    }

    // The connection state is at Connecting or None, push us into an offline state
    if (
        connectionState.state === "Connecting" ||
        connectionState.state === "None"
    ) {
        return {
            status: "Offline",
            epochVersion: currentEpochLoopState.epochVersion,
            tickOnConnectedIntervalLoop: undefined,
        };
    }

    // Otherwise, no material change to announce
    return currentEpochLoopState;
};

type EpochLoopState = EpochStatus & {
    tickOnConnectedIntervalLoop: number | undefined;
};

export const filterUnlessEpochVersionChanges = () => {
    return distinctUntilChanged<EpochStatus>(
        (previousUpdate, currentUpdate) =>
            previousUpdate.epochVersion >= currentUpdate.epochVersion,
    );
};

/**
 * On epoch version changing, will run the action and emit the epoch update on success only.
 * The action will run for every update, and the observable will emit the value on completion.
 *
 * @param epochFeed The source epoch observable
 * @param action The async action to perform
 * @param onError Handle cases of the async action throwing here
 */
export const performOnEpochVersionUpdate = (
    epochFeed: Observable<EpochStatus>,
    action: () => Promise<unknown>,
    onError: (e: any) => void,
): Observable<EpochStatus> =>
    epochFeed.pipe(
        // Only emit distinct updates where the Epoch version has increased
        filterUnlessEpochVersionChanges(),
        // Run the action, and tick with successful results
        concatMap(async (update) => {
            try {
                await action();
                return { success: true, update };
            } catch (e) {
                onError(e);
                return { success: false, update };
            }
        }),
        filter((x) => x.success),
        map((x) => x.update),
    );
