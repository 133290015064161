import { ComponentPropsWithoutRef, ReactNode } from "react";

import { Ds } from "@accurx/design";
import { StyledPopoverItemWithSpaceBetween } from "@accurx/navigation";

export const PopoverSubMenu = ({
    onClick,
    children,
    ...props
}: {
    onClick: () => void;
    children: ReactNode;
} & ComponentPropsWithoutRef<"button">) => {
    return (
        <StyledPopoverItemWithSpaceBetween onClick={onClick} {...props}>
            <Ds.Flex gap="1">{children}</Ds.Flex>
            <Ds.Icon name="Arrow" size="small" color="stone" rotate="90" />
        </StyledPopoverItemWithSpaceBetween>
    );
};
