import { useEffect, useRef } from "react";

import { Ds } from "@accurx/design";

/**
 * A heading component that receives focus on mount. Can be used
 * to manage focus after a client-side navigation.
 */
export const AutoFocusHeading = (props: Ds.TextProps) => {
    const ref = useRef<HTMLHeadingElement | null>(null);

    useEffect(() => {
        ref.current?.focus();
    }, []);

    return (
        <Ds.Text
            ref={ref}
            as="h1"
            size="large"
            weight="bold"
            tabIndex={-1}
            style={{
                border: "none",
                outline: "none",
                textOverflow: "ellipsis",
                overflow: "hidden",
            }}
            {...props}
        >
            {props.children}
        </Ds.Text>
    );
};
