import * as React from "react";

import { Avatar, Icon, Text } from "@accurx/design";

import { trackPatientTriageDashboardPinClick } from "app/analytics/FlemingAnalytics/PatientTriageDashboardEvents";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { useAppSelector } from "store/hooks";

import { UserType } from "../shared/shared.constants";
import { StyledBadge, StyledIconWrapper } from "../shared/shared.styles";
import { AssignmentData } from "../shared/shared.types";
import { getItemId, storePinnedItems } from "../shared/shared.utils";
import { StyledTableCellButton } from "./OwnerCellContent.styles";

type OwnerCellContentProps = {
    row: AssignmentData;
    totalRows: number;
    onItemPin: React.Dispatch<React.SetStateAction<string[]>>;
    isItemPinned: (row: AssignmentData) => boolean;
};

export const OwnerCellContent = ({
    row,
    totalRows,
    onItemPin,
    isItemPinned,
}: OwnerCellContentProps): JSX.Element => {
    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const handlePinAnalytics = React.useCallback(
        (id: string, isItemToBePinned: boolean, itemIds: string[]) => {
            trackPatientTriageDashboardPinClick({
                ...analyticsLoggedInProps,
                sectionName: "Requests by assignee",
                dashboardName: "Patient Triage Dashboard",
                itemId: id,
                oldValue: !isItemToBePinned,
                newValue: isItemToBePinned,
                countPinnedRow: isItemToBePinned
                    ? itemIds.length + 1
                    : itemIds.length,
                countTotalRow: totalRows,
            });
        },
        [analyticsLoggedInProps, totalRows],
    );

    const handlePinToTop = React.useCallback(() => {
        onItemPin((itemIds) => {
            const id = getItemId(row, practiceId);
            const isItemToBePinned = !itemIds.includes(id);
            handlePinAnalytics(id, isItemToBePinned, itemIds);
            const pinnedItemIds = isItemToBePinned
                ? [...itemIds, id]
                : itemIds.filter((itemId) => itemId !== id);
            storePinnedItems(pinnedItemIds);
            return pinnedItemIds;
        });
    }, [onItemPin, row, practiceId, handlePinAnalytics]);

    return (
        <>
            <StyledTableCellButton onClick={handlePinToTop}>
                <StyledIconWrapper>
                    <Icon
                        name="Pin"
                        style={isItemPinned(row) ? "Fill" : "Line"}
                        colour={isItemPinned(row) ? "blue" : "currentColor"}
                        title="Click to pin to top"
                        size={3}
                    />
                </StyledIconWrapper>
                <div aria-hidden>
                    <Avatar
                        name={row.name}
                        size="small"
                        colour={row.type === UserType.Person ? "blue" : "pink"}
                    />
                </div>
                <Text as="span" skinny>
                    {row.name}
                </Text>
                {row.type === UserType.SharedTeam && (
                    <StyledBadge colour="yellow">
                        <Text skinny variant="note">
                            Shared Team
                        </Text>
                    </StyledBadge>
                )}
            </StyledTableCellButton>
        </>
    );
};
