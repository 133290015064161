import { calculateSMSFragments } from "@accurx/shared";

import {
    NHS_ADVICE_TEXT,
    PATIENT_RESPONSE_TEXT_MAX,
    QUESTIONNAIRE_TEXT_MAX,
    SELFBOOK_TEXT_MAX,
    VIEW_ATTACHMENT_TEXT_MAX_MULTIPLE,
    VIEW_ATTACHMENT_TEXT_MAX_SINGLE,
    VIEW_PATIENT_RESPONSE_OR_ATTACHMENT_TEXT_MAX,
} from "../components/Compose/Compose.constants";
import { NHSAdviceLink } from "../types";

export type CalculateCharacterCountParams = {
    /**
     * Formatted string composed of
     *
     * greeting
     * body
     */
    body: string;
    /**
     * Contains message signature
     */
    footer: string;
    workspaceName: string;
    patientResponseEnabled: boolean;
    attachmentsCount: number;
    hasQuestionnaire: boolean;
    nhsAdviceLink: NHSAdviceLink | null;
    hasSelfbookLink: boolean;
};

export const calculateCharacterCount = ({
    body,
    footer,
    workspaceName,
    patientResponseEnabled,
    attachmentsCount,
    hasQuestionnaire,
    nhsAdviceLink,
    hasSelfbookLink,
}: CalculateCharacterCountParams): {
    characterCount: number;
    fragmentCount: number;
    isUnicode: boolean;
} => {
    const hasAttachments = attachmentsCount > 0;
    const hasMultipleAttachments = attachmentsCount > 1;

    let formatted = `${body}\n${footer}\n${workspaceName}`;

    if (hasQuestionnaire) {
        formatted += QUESTIONNAIRE_TEXT_MAX;
    } else if (patientResponseEnabled && hasAttachments) {
        formatted += VIEW_PATIENT_RESPONSE_OR_ATTACHMENT_TEXT_MAX;
    } else if (patientResponseEnabled) {
        formatted += PATIENT_RESPONSE_TEXT_MAX;
    } else if (hasAttachments) {
        formatted += hasMultipleAttachments
            ? VIEW_ATTACHMENT_TEXT_MAX_MULTIPLE
            : VIEW_ATTACHMENT_TEXT_MAX_SINGLE;
    } else if (hasSelfbookLink) {
        formatted += SELFBOOK_TEXT_MAX;
    }

    if (nhsAdviceLink !== null) {
        formatted += `${NHS_ADVICE_TEXT}${nhsAdviceLink.name}\n${nhsAdviceLink.url}`;
    }

    const {
        smsCharacterCount: characterCount,
        fragmentCount,
        unicode,
    } = calculateSMSFragments(formatted);

    return { characterCount, fragmentCount, isUnicode: unicode };
};
