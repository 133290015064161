import React from "react";

import {
    Accordion,
    Button,
    Card,
    Feedback,
    Text,
    TextareaAutosize,
    Tokens,
} from "@accurx/design";
import { i } from "images";

import { UpdatingStatusType } from "shared/LoadingStatus";

import { Settings } from "../../constants";
import { PatientTriageStatus } from "../../types";
import {
    formatDateAsFriendlyDate,
    formatDateAsFriendlyTime,
} from "../../utils";

export type PTMessagePropsType = {
    status: PatientTriageStatus;
    triageSettingsUpdatingStatus: UpdatingStatusType;
    headingText: string;
    bodyText: string;
    suspendPTUntil: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    hasClickEditPTPage: boolean;
    hasClickedEditButton: (
        event: React.MouseEvent<HTMLButtonElement>,
        name: string,
    ) => void;
    handleUpdateCustomCopy: (
        event:
            | React.MouseEvent<HTMLButtonElement>
            | React.FormEvent<HTMLFormElement>,
        name: string,
        resetToDefault: boolean | null,
    ) => void;
    handlePTMessageCancel: () => void;
    handleAccordionClick: (
        e: React.MouseEvent<HTMLButtonElement>,
        name: string,
    ) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleUpdateString: (event: any, name: string) => void;
};

// Renders Edit suspended Patient Triage message
export const PTMessage = ({
    status,
    triageSettingsUpdatingStatus,
    headingText,
    bodyText,
    suspendPTUntil,
    hasClickEditPTPage,
    hasClickedEditButton,
    handleUpdateCustomCopy,
    handlePTMessageCancel,
    handleAccordionClick,
    handleUpdateString,
}: PTMessagePropsType): JSX.Element => {
    const isDisabled =
        status.isAdmin === false || triageSettingsUpdatingStatus.updating;

    const formatDisplayDate = (): string | undefined => {
        if (suspendPTUntil !== null) {
            const d = suspendPTUntil;

            return `${formatDateAsFriendlyDate(
                d,
            )} at ${formatDateAsFriendlyTime(d)}`;
        }

        return undefined;
    };

    return (
        <form
            onSubmit={(e): void =>
                handleUpdateCustomCopy(e, Settings.TEXT_PT_PAGE, false)
            }
        >
            <Card
                props={{ className: "mb-3" }}
                header={
                    <Text as="h3" skinny variant="subtitle">
                        Edit suspended Patient Triage message
                    </Text>
                }
                footer={
                    <div className="d-flex justify-content-end">
                        {hasClickEditPTPage ? (
                            <>
                                <Button
                                    theme="primary"
                                    data-testid="ptMessageSave"
                                    disabled={isDisabled}
                                    type="submit"
                                    text="Save Changes"
                                />
                                <Button
                                    className="ml-3"
                                    theme="secondary"
                                    onClick={(e): void =>
                                        handleUpdateCustomCopy(
                                            e,
                                            Settings.TEXT_PT_PAGE,
                                            true,
                                        )
                                    }
                                    data-testid="ptMessageReset"
                                    disabled={isDisabled}
                                    type="button"
                                    text="Reset to default"
                                />
                                <Button
                                    className="ml-3"
                                    dimension="medium"
                                    theme="transparent"
                                    onClick={handlePTMessageCancel}
                                    data-testid="ptPageMessageCancel"
                                    text="Cancel"
                                    type="button"
                                />
                            </>
                        ) : (
                            <Button
                                icon={{ name: "Pencil" }}
                                onClick={(e): void =>
                                    hasClickedEditButton(
                                        e,
                                        Settings.TEXT_PT_PAGE,
                                    )
                                }
                                disabled={isDisabled}
                                data-testid="ptPageMessageEdit"
                                type="button"
                                text="Edit"
                            />
                        )}{" "}
                    </div>
                }
            >
                <div>
                    <Text>
                        This is the message at the top of the welcome page.{" "}
                    </Text>

                    <Accordion
                        header="See where this is on the patient form"
                        icon={{
                            colour: "night",
                            name: "Photo",
                        }}
                        className="mb-3"
                        buttonProps={{
                            onClick: (e): void =>
                                handleAccordionClick(e, Settings.TEXT_PT_PAGE),
                        }}
                    >
                        <img
                            className="w-100"
                            src={i("patientTriage/suspend-pt.png")}
                            alt="Location of the suspend patient triage message in patient triage flow"
                        />
                    </Accordion>

                    <div
                        className="p-2"
                        style={{
                            backgroundColor: Tokens.COLOURS.greyscale.cloud,
                        }}
                    >
                        <Text variant="label" props={{ className: "mb-2" }}>
                            Your current message:
                        </Text>

                        <Feedback
                            colour="warning"
                            title={
                                <span className="mb-3">
                                    {hasClickEditPTPage ? (
                                        <TextareaAutosize
                                            cols={80}
                                            className="w-100"
                                            name="suspendPTHeading"
                                            id="suspendPTHeading"
                                            data-testid="suspendPTHeadingCopy"
                                            disabled={isDisabled}
                                            onChange={(e) =>
                                                handleUpdateString(
                                                    e,
                                                    Settings.TEXT_PT_HEADING,
                                                )
                                            }
                                            value={headingText}
                                        />
                                    ) : (
                                        <Text as="span" skinny>
                                            {headingText}
                                        </Text>
                                    )}
                                </span>
                            }
                            content={
                                <>
                                    <div>
                                        <Text>
                                            Online enquiries will be available
                                            again on {formatDisplayDate()}.{" "}
                                        </Text>
                                    </div>
                                    {hasClickEditPTPage ? (
                                        <TextareaAutosize
                                            cols={80}
                                            className="w-100"
                                            name="suspendPTBody"
                                            id="suspendPTBody"
                                            data-testid="suspendPTBodyCopy"
                                            disabled={isDisabled}
                                            onChange={(e) =>
                                                handleUpdateString(
                                                    e,
                                                    Settings.TEXT_PT_BODY,
                                                )
                                            }
                                            value={bodyText}
                                        />
                                    ) : (
                                        <Text skinny>{bodyText}</Text>
                                    )}
                                </>
                            }
                        />
                    </div>
                </div>
            </Card>
        </form>
    );
};
