import React, { ReactElement, useEffect, useRef, useState } from "react";

import { Tokens } from "@accurx/design";
import { NhsNumberHelpers } from "@accurx/shared";
import { ArchivedWorkspaceHiddenItemWrapper } from "@accurx/workspace-management";

import { FlemingAnalyticsTracker } from "app/analytics";
import { MessagePatientButton } from "app/patientProfile/MessagePatientButton";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { SkeletonBlock } from "app/sharedComponents/loadingSkeleton/SkeletonText";
import { useAppSelector } from "store/hooks";

import {
    PatientDetailsCard,
    PatientDetailsCardPatient,
} from "../../sharedComponents/patientDetailsCard/PatientDetailsCard";
import { useGetPatientDetailsActions } from "../hooks/useGetPatientDetailsActions";
import { ClinicianConversationSidePanelSection } from "../sidePanelSection/conversationSidePanelSection";
import { StyledButton, StyledButtonWrapper } from "./PatientDetails.styles";

type PDSSearchStatus = "LOADING" | "ERROR" | "SUCCESS";

type PatientDetailsProps = {
    organisationId: number | null;
    patient?: PatientDetailsCardPatient;
    displayPatientProfileButton?: boolean;
    displayMessagePatientButton?: boolean;
};

const isSamePatient = (prev: PatientDetailsProps, next: PatientDetailsProps) =>
    prev.patient?.nhsNumber === next.patient?.nhsNumber;

export const PatientDetails = ({
    patient,
    organisationId,
    displayPatientProfileButton = true,
    displayMessagePatientButton = true,
}: PatientDetailsProps): ReactElement => {
    const nhsNumber = patient?.nhsNumber;
    const dateOfBirth = patient?.dateOfBirth;
    const [pdsStatus, setPdsStatus] = useState<PDSSearchStatus>("LOADING");
    const hasSearchInvoked = useRef<boolean>(false);

    const {
        viewPatientProfile,
        viewMessagePatient,
        searchPatientAndSaveInStore,
    } = useGetPatientDetailsActions();
    const searchForPatient = useAppSelector(
        ({ searchForPatient }) => searchForPatient,
    );
    const isSearching = searchForPatient.isSearching;
    const lastSearchedNhsNumber =
        searchForPatient.lastResponse?.searchedResult?.patient?.nhsNumber;

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const handleViewPatientProfile = () => {
        FlemingAnalyticsTracker.trackPatientProfileButtonClick({
            ...analyticsLoggedInProps,
            origin: "clinician-conversation",
        });

        if (dateOfBirth && nhsNumber && organisationId) {
            viewPatientProfile({
                shouldInvokePdsSearch: false,
            });
        }
    };

    const handleViewMessagePatient = () => {
        const analyticsProps = {
            ...analyticsLoggedInProps,
            navigationLocation: "ClinicianConversation" as const,
            optionSelected: "MessagePatient" as const,
            productAvailableForPatient: true,
            searchPatientOrigin: "/clinician-conversation",
        };

        analyticsProps &&
            FlemingAnalyticsTracker.trackProductOptionButtonClick(
                analyticsProps,
            );
        if (dateOfBirth && nhsNumber && organisationId) {
            viewMessagePatient({
                organisationId,
                shouldInvokePdsSearch: false,
            });
        }
    };

    const hasValidNhsNumber =
        (nhsNumber && NhsNumberHelpers.validateNhsNumber(nhsNumber).success) ||
        false;

    useEffect(() => {
        if (
            dateOfBirth &&
            nhsNumber &&
            organisationId &&
            hasValidNhsNumber &&
            (displayPatientProfileButton || displayMessagePatientButton)
        ) {
            // if the last searched nhs number in store is same one
            if (lastSearchedNhsNumber && lastSearchedNhsNumber === nhsNumber) {
                setPdsStatus("SUCCESS");
                return;
            }
            const fetchAndSavePatientInStore = async () => {
                try {
                    const { error } = await searchPatientAndSaveInStore({
                        dateOfBirth,
                        nhsNumber,
                        organisationId,
                    });
                    setPdsStatus(error ? "ERROR" : "SUCCESS");
                } catch {
                    setPdsStatus("ERROR");
                }
            };
            if (!hasSearchInvoked.current) {
                fetchAndSavePatientInStore();
                hasSearchInvoked.current = true;
            }
        }
    }, [
        dateOfBirth,
        nhsNumber,
        organisationId,
        displayPatientProfileButton,
        displayMessagePatientButton,
        hasValidNhsNumber,
        lastSearchedNhsNumber,
        searchPatientAndSaveInStore,
    ]);

    const renderMessagePatientButton = () => {
        const isLoaded =
            displayMessagePatientButton &&
            hasValidNhsNumber &&
            pdsStatus === "SUCCESS";

        const isLoading =
            displayMessagePatientButton &&
            hasValidNhsNumber &&
            pdsStatus === "LOADING";

        const isError = displayMessagePatientButton && pdsStatus === "ERROR";

        if (isLoading) {
            return (
                <SkeletonBlock
                    height={Tokens.SIZES[5]}
                    width="100%"
                    className="mb-2"
                />
            );
        }

        if (isError) {
            return null;
        }

        if (isLoaded) {
            return (
                <MessagePatientButton
                    actionCallback={handleViewMessagePatient}
                />
            );
        }
    };

    const renderPatientProfileButton = () => {
        const isLoaded =
            displayPatientProfileButton &&
            hasValidNhsNumber &&
            pdsStatus === "SUCCESS";

        const isLoading =
            displayPatientProfileButton &&
            hasValidNhsNumber &&
            pdsStatus === "LOADING";

        const isError = displayPatientProfileButton && pdsStatus === "ERROR";

        if (isLoading) {
            return (
                <SkeletonBlock
                    height={Tokens.SIZES[5]}
                    width="100%"
                    className="mb-2"
                />
            );
        }

        if (isError) {
            return null;
        }

        if (isLoaded) {
            return (
                <StyledButton
                    text="View patient profile"
                    theme="secondary"
                    onClick={handleViewPatientProfile}
                    disabled={isSearching}
                    icon={{ name: "Person", colour: "blue" }}
                    dimension="large"
                />
            );
        }
    };

    return (
        <ClinicianConversationSidePanelSection
            headerText="Patient details"
            headerIconProps={{ name: "Person", style: "Fill" }}
        >
            <PatientDetailsCard patient={patient} noHeader>
                <StyledButtonWrapper>
                    <ArchivedWorkspaceHiddenItemWrapper>
                        {renderMessagePatientButton()}
                    </ArchivedWorkspaceHiddenItemWrapper>
                    {renderPatientProfileButton()}
                </StyledButtonWrapper>
            </PatientDetailsCard>
        </ClinicianConversationSidePanelSection>
    );
};

export default React.memo(PatientDetails, isSamePatient);
