import { Location } from "history";
import { PAGE_CONTENT_ID } from "layouts/constants";
import { useHistory } from "react-router-dom";

export type FullScreenRouterState =
    | {
          /** When the user closes the full screen page,
              this is the path they should be returend to */
          originLocation: Location;
      }
    | undefined;

/** Performs history.push, populating the current url as the originLocation
    in the location state. This is so that the user can return to this page
    when they close the full screen page */
export const useFullScreenNavigate = () => {
    const history = useHistory();

    return (...[location, state]: Parameters<(typeof history)["push"]>) => {
        const fullScreenRouterState: FullScreenRouterState = {
            originLocation: {
                ...history.location,
                state: {
                    originScrollTop:
                        document.getElementById(PAGE_CONTENT_ID)?.scrollTop,
                    ...(history.location.state ?? {}),
                },
            },
        };

        history.push(location, { ...(state ?? {}), ...fullScreenRouterState });
    };
};
