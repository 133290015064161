import { useFullScreen } from "layouts/UnifiedLayout/UnifiedLayoutContext";

import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { VaccineCancel } from "app/practices/vaccine/vaccineCancel/VaccineCancel.component";

export const CancelAppointmentPage = () => {
    useFullScreen();

    return (
        <StyledContainer>
            <PageHeader
                title="Cancel appointment"
                type={PageHeaderType.ListPage}
            />
            <VaccineCancel />
        </StyledContainer>
    );
};
