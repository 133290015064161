import { Ds, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledText = styled(Ds.Text)`
    padding: 0; // Required as the browser is adding unwanted padding
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledContainer = styled(Ds.Flex)`
    overflow: hidden;
    padding: 0;
`;

export const StyledContactDetail = styled(Ds.Flex)`
    padding: 0;

    :first-child {
        padding-left: ${Tokens.SIZES[0.5]};
    }
`;

export const StyledIconContainer = styled.div`
    padding: 0;
`;
