import { useMemo } from "react";

import { ConversationItem } from "@accurx/concierge/types";
import { Log } from "@accurx/shared";

import * as Summary from "./summaries";

export type PreviewSummaryProps = {
    conversationItem: ConversationItem;
};

export const PreviewSummary = ({ conversationItem }: PreviewSummaryProps) => {
    const ConversationPreviewSummary = useMemo(() => {
        switch (conversationItem.contentType) {
            case "Note":
                return <Summary.Note conversationItem={conversationItem} />;

            case "PatientSms":
                return (
                    <Summary.MessagePatientSms
                        conversationItem={conversationItem}
                    />
                );

            case "PatientEmail":
                return (
                    <Summary.MessagePatientEmail
                        conversationItem={conversationItem}
                    />
                );

            case "NhsAppMessage":
                return (
                    <Summary.MessageNhsApp
                        conversationItem={conversationItem}
                    />
                );
            case "FallbackFailedLink":
            case "FailedDeliveryReceiptLink":
                return <Summary.FailedMessage />;

            case "FloreyResponseNote":
                return (
                    <Summary.FloreyResponse
                        conversationItem={conversationItem}
                    />
                );

            case "PatientTriageRequestNote":
                return (
                    <Summary.PatientTriageRequest
                        conversationItem={conversationItem}
                    />
                );

            case "PatientAppointmentRequestNote":
                return (
                    <Summary.PatientAppointmentRequest
                        conversationItem={conversationItem}
                    />
                );

            case "GenericNote":
                return (
                    <Summary.GenericNote conversationItem={conversationItem} />
                );

            case "PatientSingleResponse":
                return (
                    <Summary.PatientResponse
                        conversationItem={conversationItem}
                    />
                );

            case "StateChange":
                return conversationItem.changeType === "Assignment" ? (
                    <Summary.StateChangeAssignment
                        conversationItem={conversationItem}
                    />
                ) : (
                    <Summary.StateChange conversationItem={conversationItem} />
                );

            case "VideoConsultLink":
                return <Summary.VideoConsult />;

            case "LabelTag":
                return <Summary.LabelTag conversationItem={conversationItem} />;

            case "ClinicianMessage":
                return (
                    <Summary.ClinicianMessage
                        conversationItem={conversationItem}
                    />
                );

            default:
                Log.error("Unknown Summary Type", {
                    tags: {
                        contentType: (conversationItem as ConversationItem)
                            .contentType,
                        conversationItemId: (
                            conversationItem as ConversationItem
                        ).id,
                    },
                });
                return null;
        }
    }, [conversationItem]);

    return <>{ConversationPreviewSummary}</>;
};
