import * as UI from "@accurx/design";
import styled from "styled-components";

const Outer = styled(UI.Ds.Flex).attrs({ justifyContent: "center" })`
    height: 100%;
    position: relative;
    background-color: ${UI.Tokens.COLOURS.greyscale.cloud};

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        content: "";
        /* Setting the height explicitly, as theres no content, to match the compose header */
        height: 98px;
        width: 100%;
        border-bottom: ${UI.Tokens.BORDERS.normal.silver};
        background-color: ${UI.Tokens.COLOURS.greyscale.white};
    }
`;

const Inner = styled.div`
    position: relative;

    width: 100%;
    max-width: ${UI.Tokens.BREAKPOINTS.md};
    overflow: auto;
    background-color: ${UI.Tokens.COLOURS.greyscale.white};
    padding: ${UI.Tokens.SIZES[2]};

    @media (min-width: ${UI.Tokens.BREAKPOINTS.sm}) {
        margin: ${UI.Tokens.SIZES[4]};
        border-radius: ${UI.Tokens.SIZES[0.5]};
        border: ${UI.Tokens.BORDERS.normal.silver};
        padding: ${UI.Tokens.SIZES[3]};
    }
`;

export const StyledSearchContainer = {
    Outer,
    Inner,
};
