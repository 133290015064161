import { Button, TextareaAutosize, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledTextarea = styled(TextareaAutosize)`
    width: 100%;

    border: ${Tokens.BORDERS.normal.silver};
    border-radius: ${Tokens.BORDERS.radius};
    box-sizing: border-box;
    box-shadow: inset 0px 3px 0px #e0e1ebff;
    resize: none;

    ${Tokens.TYPOGRAPHY.body.base}

    padding: ${Tokens.SIZES[1]};
`;

export const StyledButtonWithCenteredContent = styled(Button)`
    justify-content: center;
`;
