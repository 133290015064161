import { PracticeActivityResponse } from "@accurx/api/clinician-messaging";
import { getApiEndpoint, httpClient, returnDataOrThrow } from "@accurx/shared";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { differenceInCalendarDays, startOfDay } from "date-fns";

export type PracticeUserAvailability =
    | {
          activity: { lastOnlineDaysAgo: number; numberOfUsers: number } | null;
          isAvailable: true;
      }
    | {
          isAvailable: false;
      };

export type UsePracticeUserAvailablityQueryParams =
    | {
          organisationId: number;
          practiceCodeToQuery: string;
          workspaceIdToQuery?: undefined;
      }
    | {
          workspaceIdToQuery: number;
          organisationId: number;
          practiceCodeToQuery?: undefined;
      };

const hasEnoughData = (
    data: Partial<UsePracticeUserAvailablityQueryParams>,
): data is UsePracticeUserAvailablityQueryParams => {
    if (data.organisationId === undefined) {
        return false;
    }
    if (
        (data.workspaceIdToQuery === undefined ||
            // In the message GP test flow (legacy), the workspace id is 0
            data.workspaceIdToQuery === 0) &&
        data.practiceCodeToQuery === undefined
    ) {
        return false;
    }
    return true;
};

const DAYS_CUT_OFF = 3;

export const usePracticeUserAvailablityQuery = (
    params: Partial<UsePracticeUserAvailablityQueryParams>,
    options?: UseQueryOptions<PracticeUserAvailability, Error>,
) => {
    return useQuery({
        queryKey: ["PracticeAvailablity", params],
        queryFn: async (): Promise<PracticeUserAvailability> => {
            if (!hasEnoughData(params)) {
                throw new Error("Not enough data for query");
            }
            const result = await getAvailabilityFromServer(params);

            if (!result.isOpenToReplies) {
                return {
                    isAvailable: false,
                };
            }

            const days = result.practiceUserOnlineStatus
                .map(({ lastOnlineAt }) =>
                    differenceInCalendarDays(
                        startOfDay(new Date()),
                        startOfDay(new Date(lastOnlineAt)),
                    ),
                )
                .filter((daysAgo) => daysAgo <= DAYS_CUT_OFF);

            if (!days.length) {
                // No one was online in the last 3 days
                return { isAvailable: true, activity: null };
            }

            const mostRecentDay = Math.min(...days);
            return {
                isAvailable: true,
                activity: {
                    lastOnlineDaysAgo: mostRecentDay,
                    numberOfUsers: days.filter((day) => day === mostRecentDay)
                        .length,
                },
            };
        },
        ...options,
        enabled: (options?.enabled ?? true) && hasEnoughData(params),
    });
};

const getAvailablityByWorkspaceId = async ({
    workspaceIdToQuery,
    organisationId,
}: {
    workspaceIdToQuery: number;
    organisationId: number;
}) => {
    return await httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/clinicianmessaging/PracticeInformation/workspaces/:workspaceIdToQuery/activity",
            params: {
                workspaceIdToQuery: workspaceIdToQuery.toString(),
            },
            query: `?organisationId=${organisationId}`,
        }),
    );
};

const getAvailablityByPracticeCode = async ({
    practiceCodeToQuery,
    organisationId,
}: {
    practiceCodeToQuery: string;
    organisationId: number;
}) => {
    return await httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/clinicianmessaging/PracticeInformation/practices/:practiceCodeToQuery/activity",
            params: { practiceCodeToQuery },
            query: `?organisationId=${organisationId}`,
        }),
    );
};

const getAvailabilityFromServer = async (
    params: UsePracticeUserAvailablityQueryParams,
): Promise<PracticeActivityResponse> => {
    const response =
        params.workspaceIdToQuery !== undefined
            ? await getAvailablityByWorkspaceId(params)
            : await getAvailablityByPracticeCode(params);

    return returnDataOrThrow(response) as PracticeActivityResponse;
};
