import { useCurrentWorkspace } from "@accurx/auth";
import { Tokens } from "@accurx/design";
import { Switch, useLocation } from "react-router-dom";
import styled from "styled-components";

import { AppRoute, SimpleRoute } from "app/AppRoute";
import { SwitchingOrgPage } from "app/practices/questionnaires/switchingOrg/SwitchingOrgPage";
import { ROUTES_CHAIN, ROUTES_WORKSPACE } from "shared/Routes";

import { ImportToOrgPage } from "../questionnaires/importToOrg/ImportToOrgPage";
import { SharingPage } from "../questionnaires/sharing/SharingPage";
import { useHasAcceptedFloreyBuilderUsePolicy } from "./FloreyBuilder.hooks";
import { FloreyBuilderAUP } from "./FloreyBuilderAcceptableUsePolicy/FloreyBuilderAUP";
import { FloreyBuilderAssignAndSave } from "./FloreyBuilderAssignAndSave/FloreyBuilderAssignAndSave";
import { FloreyBuilderCreateConfirmationMessage } from "./FloreyBuilderCreateConfirmationMessage/FloreyBuilderCreateConfirmationMessage";
import { FloreyBuilderCreateInviteMessage } from "./FloreyBuilderCreateInviteMessage/FloreyBuilderCreateInviteMessage";
import { FloreyBuilderHome } from "./FloreyBuilderHome/FloreyBuilderHome";
import { FloreyBuilderExportAsJson } from "./FloreyBuilderImportExport/FloreyBuilderExportAsJson";
import { FloreyBuilderImportAsJson } from "./FloreyBuilderImportExport/FloreyBuilderImportAsJson";
import { FloreyBuilderNameAndQuestions } from "./FloreyBuilderNameAndQuestions/FloreyBuilderNameAndQuestions";
import { PATHS } from "./constants";
import {
    routeDesktopAcceptableUsePolicy,
    routeWebAcceptableUsePolicy,
} from "./constants/paths";

const StyledWebWrapper = styled.div<{ isDesktopPage: boolean }>`
    margin: ${(props) => (props.isDesktopPage ? "0" : `${Tokens.SIZES[2]}`)};

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        margin: ${(props) =>
            props.isDesktopPage ? "0" : `${Tokens.SIZES[4]}`};
    }
`;

export const FloreyBuilderContent = (): JSX.Element => {
    const location = useLocation();

    const isDesktopPage = location.pathname.includes("/practices");

    const { orgId } = useCurrentWorkspace();

    const NEXT_URL_QUERY_PARAM = "?next";

    const encodeQueryParams = (): string =>
        `${NEXT_URL_QUERY_PARAM}=${encodeURIComponent(
            location.pathname + location.search,
        )}`;
    const hasAcceptedFloreyBuilderUsePolicy =
        useHasAcceptedFloreyBuilderUsePolicy();

    return (
        <>
            <main>
                <Switch>
                    <SimpleRoute
                        path={
                            ROUTES_CHAIN.practicesFloreyBuilderBase +
                            PATHS.acceptableUse
                        }
                    >
                        <StyledWebWrapper isDesktopPage={isDesktopPage}>
                            <FloreyBuilderAUP />
                        </StyledWebWrapper>
                    </SimpleRoute>
                    <SimpleRoute
                        path={
                            ROUTES_WORKSPACE.workspaceQuestionnaireBuilder +
                            PATHS.acceptableUse
                        }
                    >
                        <FloreyBuilderAUP />
                    </SimpleRoute>
                    <AppRoute
                        exact
                        path={
                            isDesktopPage
                                ? ROUTES_CHAIN.practicesFloreyBuilderBase
                                : ROUTES_WORKSPACE.workspaceQuestionnaireBuilder
                        }
                        allowRoute={{
                            isAllowed: hasAcceptedFloreyBuilderUsePolicy,
                            redirectTo: `${
                                isDesktopPage
                                    ? routeDesktopAcceptableUsePolicy(
                                          orgId.toString(),
                                      )
                                    : routeWebAcceptableUsePolicy(orgId)
                            }${encodeQueryParams()}`,
                        }}
                    >
                        <StyledWebWrapper isDesktopPage={isDesktopPage}>
                            <FloreyBuilderHome isDesktopPage={isDesktopPage} />
                        </StyledWebWrapper>
                    </AppRoute>
                    <AppRoute
                        path={
                            (isDesktopPage
                                ? ROUTES_CHAIN.practicesFloreyBuilderBase
                                : ROUTES_WORKSPACE.workspaceQuestionnaireBuilder) +
                            PATHS.nameAndQuestionsWithId
                        }
                        allowRoute={{
                            isAllowed: hasAcceptedFloreyBuilderUsePolicy,
                            redirectTo: `${
                                isDesktopPage
                                    ? routeDesktopAcceptableUsePolicy(
                                          orgId.toString(),
                                      )
                                    : routeWebAcceptableUsePolicy(orgId)
                            }${encodeQueryParams()}`,
                        }}
                    >
                        <StyledWebWrapper isDesktopPage={isDesktopPage}>
                            <FloreyBuilderNameAndQuestions />
                        </StyledWebWrapper>
                    </AppRoute>
                    <AppRoute
                        path={
                            (isDesktopPage
                                ? ROUTES_CHAIN.practicesFloreyBuilderBase
                                : ROUTES_WORKSPACE.workspaceQuestionnaireBuilder) +
                            PATHS.nameAndQuestions
                        }
                        allowRoute={{
                            isAllowed: hasAcceptedFloreyBuilderUsePolicy,
                            redirectTo: `${
                                isDesktopPage
                                    ? routeDesktopAcceptableUsePolicy(
                                          orgId.toString(),
                                      )
                                    : routeWebAcceptableUsePolicy(orgId)
                            }${encodeQueryParams()}`,
                        }}
                    >
                        <StyledWebWrapper isDesktopPage={isDesktopPage}>
                            <FloreyBuilderNameAndQuestions />
                        </StyledWebWrapper>
                    </AppRoute>
                    <AppRoute
                        exact
                        path={
                            (isDesktopPage
                                ? ROUTES_CHAIN.practicesFloreyBuilderBase
                                : ROUTES_WORKSPACE.workspaceQuestionnaireBuilder) +
                            PATHS.invitationMessage
                        }
                        allowRoute={{
                            isAllowed: hasAcceptedFloreyBuilderUsePolicy,
                            redirectTo: `${
                                isDesktopPage
                                    ? routeDesktopAcceptableUsePolicy(
                                          orgId.toString(),
                                      )
                                    : routeWebAcceptableUsePolicy(orgId)
                            }${encodeQueryParams()}`,
                        }}
                    >
                        <StyledWebWrapper isDesktopPage={isDesktopPage}>
                            <FloreyBuilderCreateInviteMessage />
                        </StyledWebWrapper>
                    </AppRoute>
                    <AppRoute
                        exact
                        path={
                            (isDesktopPage
                                ? ROUTES_CHAIN.practicesFloreyBuilderBase
                                : ROUTES_WORKSPACE.workspaceQuestionnaireBuilder) +
                            PATHS.confirmationMessage
                        }
                        allowRoute={{
                            isAllowed: hasAcceptedFloreyBuilderUsePolicy,
                            redirectTo: `${
                                isDesktopPage
                                    ? routeDesktopAcceptableUsePolicy(
                                          orgId.toString(),
                                      )
                                    : routeWebAcceptableUsePolicy(orgId)
                            }${encodeQueryParams()}`,
                        }}
                    >
                        <StyledWebWrapper isDesktopPage={isDesktopPage}>
                            <FloreyBuilderCreateConfirmationMessage />
                        </StyledWebWrapper>
                    </AppRoute>
                    <AppRoute
                        exact
                        path={
                            (isDesktopPage
                                ? ROUTES_CHAIN.practicesFloreyBuilderBase
                                : ROUTES_WORKSPACE.workspaceQuestionnaireBuilder) +
                            PATHS.assignAndSave
                        }
                        allowRoute={{
                            isAllowed: hasAcceptedFloreyBuilderUsePolicy,
                            redirectTo: `${
                                isDesktopPage
                                    ? routeDesktopAcceptableUsePolicy(
                                          orgId.toString(),
                                      )
                                    : routeWebAcceptableUsePolicy(orgId)
                            }${encodeQueryParams()}`,
                        }}
                    >
                        <StyledWebWrapper isDesktopPage={isDesktopPage}>
                            <FloreyBuilderAssignAndSave />
                        </StyledWebWrapper>
                    </AppRoute>
                    <AppRoute
                        exact
                        path={
                            (isDesktopPage
                                ? ROUTES_CHAIN.practicesFloreyBuilderBase
                                : ROUTES_WORKSPACE.workspaceQuestionnaireBuilder) +
                            PATHS.exportJson
                        }
                    >
                        <StyledWebWrapper isDesktopPage={isDesktopPage}>
                            <FloreyBuilderExportAsJson />
                        </StyledWebWrapper>
                    </AppRoute>
                    <AppRoute
                        exact
                        path={
                            (isDesktopPage
                                ? ROUTES_CHAIN.practicesFloreyBuilderBase
                                : ROUTES_WORKSPACE.workspaceQuestionnaireBuilder) +
                            PATHS.importJson
                        }
                    >
                        <StyledWebWrapper isDesktopPage={isDesktopPage}>
                            <FloreyBuilderImportAsJson />
                        </StyledWebWrapper>
                    </AppRoute>
                    <AppRoute
                        exact
                        path={
                            (isDesktopPage
                                ? ROUTES_CHAIN.practicesFloreyBuilderBase
                                : ROUTES_WORKSPACE.workspaceQuestionnaireBuilder) +
                            PATHS.questionnaireShare
                        }
                    >
                        <StyledWebWrapper isDesktopPage={isDesktopPage}>
                            <SharingPage />
                        </StyledWebWrapper>
                    </AppRoute>
                    <AppRoute
                        exact
                        path={
                            (isDesktopPage
                                ? ROUTES_CHAIN.practicesFloreyBuilderBase
                                : ROUTES_WORKSPACE.workspaceQuestionnaireBuilder) +
                            PATHS.questionnaireSwitchingOrg
                        }
                    >
                        <StyledWebWrapper isDesktopPage={isDesktopPage}>
                            <SwitchingOrgPage />
                        </StyledWebWrapper>
                    </AppRoute>
                    <AppRoute
                        exact
                        path={
                            (isDesktopPage
                                ? ROUTES_CHAIN.practicesFloreyBuilderBase
                                : ROUTES_WORKSPACE.workspaceQuestionnaireBuilder) +
                            PATHS.import
                        }
                    >
                        <StyledWebWrapper isDesktopPage={isDesktopPage}>
                            <ImportToOrgPage />
                        </StyledWebWrapper>
                    </AppRoute>
                </Switch>
            </main>
        </>
    );
};
