import { useState } from "react";

import { AppointmentChangeDeadline } from "@accurx/api/appointment";
import { Card, CollapsibleInfo, Ds, Option, Select } from "@accurx/design";

type AppointmentChangesProps = {
    changeDeadline: AppointmentChangeDeadline;
    onDeadlineChange: (
        selectedChangeDeadline: AppointmentChangeDeadline,
    ) => void;
    is2WayOrg: boolean;
};

enum CancellationType {
    patientCanCancel = "patientCanCancel",
    patientCanRequestToCancel = "patientCanRequestToCancel",
}

export const AppointmentChanges = (props: AppointmentChangesProps) => {
    const { changeDeadline, onDeadlineChange, is2WayOrg = true } = props;

    const [cancellationType, setCancellationType] = useState<CancellationType>(
        CancellationType.patientCanCancel,
    );

    const [rebookRequest, setRebookRequest] = useState<boolean>(false);

    const onDeadlineChangeHandler = (option: Option) => {
        onDeadlineChange(option.value as AppointmentChangeDeadline);
    };

    const DeadlineOptions: Option[] = [
        {
            label: "1 hour before appointment",
            value: AppointmentChangeDeadline.OneHour,
        },
        {
            label: "1 working day before appointment",
            value: AppointmentChangeDeadline.OneWorkingDay,
        },
        {
            label: "3 working days before appointment",
            value: AppointmentChangeDeadline.ThreeWorkingDays,
        },
    ];

    const selectedDeadline =
        DeadlineOptions.find(
            (option) =>
                (option.value as AppointmentChangeDeadline) === changeDeadline,
        ) || DeadlineOptions[0];

    return (
        <Card
            spacing={3}
            header={
                <Ds.Text size="large" weight="bold" as="h2">
                    Appointment changes
                </Ds.Text>
            }
        >
            {is2WayOrg ? (
                <>
                    <Ds.Text weight="bold" mb="1">
                        Cancellation
                    </Ds.Text>
                    <Ds.RadioGroup
                        appearance="ghost"
                        defaultValue={cancellationType}
                        onValueChange={(item) =>
                            setCancellationType(item as CancellationType)
                        }
                    >
                        <Ds.Flex flexDirection="column" gap="1">
                            <Ds.RadioGroup.Item
                                value={CancellationType.patientCanCancel}
                            >
                                <Ds.RadioGroup.Indicator />
                                <Ds.RadioGroup.Label>
                                    Patients can cancel and their appointment
                                    will be removed automatically
                                </Ds.RadioGroup.Label>
                            </Ds.RadioGroup.Item>
                            <Ds.RadioGroup.Item
                                value={
                                    CancellationType.patientCanRequestToCancel
                                }
                            >
                                <Ds.RadioGroup.Indicator />
                                <Ds.RadioGroup.Label>
                                    Patients can request to cancel
                                </Ds.RadioGroup.Label>
                            </Ds.RadioGroup.Item>
                        </Ds.Flex>
                    </Ds.RadioGroup>

                    <Ds.Text weight="bold" mt="2" mb="1">
                        Rebooking
                    </Ds.Text>
                    <Ds.Checkbox
                        appearance="ghost"
                        defaultChecked={rebookRequest}
                        onCheckedChange={(canRebookRequest) =>
                            setRebookRequest(canRebookRequest)
                        }
                    >
                        <Ds.Checkbox.Indicator />
                        <Ds.Checkbox.Label>
                            Patients can request to rebook
                        </Ds.Checkbox.Label>
                    </Ds.Checkbox>

                    <CollapsibleInfo title="How patient requests work">
                        <Ds.Text>
                            Cancellation and rebooking requests need to be
                            reviewed manually. All appointment changes can be
                            found in the inbox.
                        </Ds.Text>
                    </CollapsibleInfo>
                </>
            ) : (
                <Ds.Text>
                    Patients can request to cancel or rebook appointments, which
                    will need to be reviewed manually. All appointment changes
                    can be found in the inbox.
                </Ds.Text>
            )}
            <Ds.Text weight="bold" mt="2" mb="1">
                Deadline
            </Ds.Text>
            <Select
                options={DeadlineOptions}
                id="appointmentChangeDeadline"
                onChangeHandler={(option) =>
                    onDeadlineChangeHandler(option as Option)
                }
                initSelectedValues={selectedDeadline}
            />
        </Card>
    );
};
