import { returnDataOrThrow } from "@accurx/api/QueryClient";
import { CreateTemplate, TemplateResponse } from "@accurx/api/content";
import {
    IWrappedResult,
    Log,
    getApiEndpoint,
    httpClient,
} from "@accurx/shared";
import { useMutation } from "@tanstack/react-query";

const createMessageTemplate = ({
    workspaceId,
    template,
}: {
    workspaceId: number;
    template: CreateTemplate;
}): Promise<IWrappedResult<TemplateResponse>> =>
    httpClient.postJsonReturnSafeAsync(
        getApiEndpoint({
            path: "/api/content/workspace/:organisationId/templates",
            params: {
                organisationId: workspaceId.toString(),
            },
        }),
        template,
    );

export const useCreateTemplateMutation = () => {
    return useMutation({
        mutationKey: ["Create Template"],
        mutationFn: async ({
            workspaceId,
            template,
        }: {
            workspaceId: number;
            template: CreateTemplate;
        }) => {
            if (!workspaceId) {
                Log.error("Could not create template: missing workspace id");
                throw new Error(
                    "Could not create template: missing workspace id",
                );
            }

            return createMessageTemplate({ workspaceId, template }).then(
                returnDataOrThrow,
            );
        },
    });
};
