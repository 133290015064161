import { Flex, TextareaAutosize, Tokens } from "@accurx/design";
import { setBoxShadow } from "@accurx/design/dist/styles/helpers";
import styled from "styled-components";

export const StyledTextAreaGroup = styled(Flex).attrs({
    flexDirection: "column",
})`
    border: ${Tokens.BORDERS.normal.silver};
    border-radius: ${Tokens.BORDERS.radius};
    box-shadow: ${setBoxShadow(Tokens.COLOURS.greyscale.silver)};
    background-color: ${Tokens.COLOURS.greyscale.white};
`;

export const StyledTextareaAutosize = styled(TextareaAutosize)`
    border: none;
    border-radius: ${Tokens.BORDERS.radius};
    padding: ${Tokens.SIZES[2]};
    background-color: transparent;
    width: 100%;
    color: ${(props) =>
        props.disabled ? Tokens.COLOURS.greyscale.stone : "inherit"};
`;
