import { createMutationHook } from "@accurx/api/QueryClient";
import {
    ViewPatientTokenUnprotectRequest,
    ViewPatientTokenUnprotectResponse,
} from "@accurx/api/portal";
import {
    IWrappedResult,
    getApiEndpoint,
    httpClient,
    returnDataOrThrow,
} from "@accurx/shared";

export const RedeemPatientTokenKey = "RedeemPatientToken";

const redeemPatientToken = async ({
    token,
}: ViewPatientTokenUnprotectRequest): Promise<
    IWrappedResult<ViewPatientTokenUnprotectResponse>
> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/portal/viewPatient/redeemToken",
        }),
        { token },
    );

export const useRedeemPatientToken = createMutationHook<
    ViewPatientTokenUnprotectRequest,
    ViewPatientTokenUnprotectResponse
>(() => ({
    mutationKey: [RedeemPatientTokenKey],
    mutationFn: async ({ token }) => {
        const response = await redeemPatientToken({ token });
        return returnDataOrThrow(response);
    },
}));
