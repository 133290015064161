import { useMemo } from "react";

import { Organisation, useCurrentUser } from "@accurx/auth";
import { Ds, Flex } from "@accurx/design";
import { isArchivedWorkspace } from "@accurx/workspace-management";

import { StyledSectionHeading } from "../WorkspaceAccessPage.styles";
import { sortWorkspaces } from "../helpers";
import { WorkspaceMemberCard } from "./WorkspaceMemberCard/WorkspaceMemberCard";

export const JoinedWorkspaceSection = () => {
    const {
        user: { organisations: allWorkspaces },
    } = useCurrentUser();

    const { archivedWorkspaces, activeWorkspaces } = useMemo(() => {
        const archived: Organisation[] = [];
        const active: Organisation[] = [];

        allWorkspaces.forEach((item) => {
            if (isArchivedWorkspace(item)) {
                archived.push(item);
                return;
            }

            active.push(item);
        });

        return {
            archivedWorkspaces: archived,
            activeWorkspaces: active,
        };
    }, [allWorkspaces]);

    const sortedActiveWorkspaces = sortWorkspaces(activeWorkspaces);

    const sortedArchivedWorkspaces = sortWorkspaces(archivedWorkspaces);

    return (
        <Ds.Flex flexDirection="column" gap="3">
            <div>
                <StyledSectionHeading skinny>
                    Current · {activeWorkspaces.length}
                </StyledSectionHeading>
                <Flex flexDirection="column" gap="1">
                    {sortedActiveWorkspaces.map((workspace) => {
                        return (
                            <WorkspaceMemberCard
                                workspace={workspace}
                                key={workspace.orgId}
                            />
                        );
                    })}
                </Flex>
            </div>
            {!!archivedWorkspaces.length && (
                <div>
                    <StyledSectionHeading skinny>
                        Archived by Accurx · {archivedWorkspaces.length}
                    </StyledSectionHeading>
                    <Flex flexDirection="column" gap="1">
                        {sortedArchivedWorkspaces.map((workspace) => {
                            return (
                                <WorkspaceMemberCard
                                    workspace={workspace}
                                    key={workspace.orgId}
                                />
                            );
                        })}
                    </Flex>
                </div>
            )}
        </Ds.Flex>
    );
};
