import { Ds, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledContactButton = styled(Ds.Button)`
    justify-content: space-between;

    padding: ${Tokens.SIZES[1.5]};
    // Need to override custom button styling
    &:has(svg:first-child) {
        padding-top: ${Tokens.SIZES[1.5]};
        padding-bottom: ${Tokens.SIZES[1.5]};
        padding-left: ${Tokens.SIZES[1.5]};
        padding-right: ${Tokens.SIZES[1.5]};
    }
`;

export const StyledTextContainer = styled.span`
    display: flex;
    flex-direction: column;
    align-items: start;
    overflow-wrap: anywhere;
    hyphens: auto;
`;

export const StyledFullWidthFlex = styled(Ds.Flex)`
    width: 100%;
    text-align: start;
`;
