import { Ds, Tokens } from "@accurx/design";
import styled from "styled-components";

import { SkeletonLoader } from "../../SkeletonLoader/SkeletonLoader";

export const PatientDetailsPanelSkeleton = () => (
    <>
        <Ds.Flex flexDirection="column" gap="2">
            <StyledSkeletonText width="300px" height="24px" />
            <Ds.Flex gap="1">
                <SkeletonLoader width="130px" />
                <SkeletonLoader width="130px" />
            </Ds.Flex>
            <Ds.Box mt="0.5">
                <Ds.Flex gap="0.25">
                    <StyledSkeletonButton width="120px" />
                    <StyledSkeletonButton width="140px" />
                    <StyledSkeletonButton width="35px" />
                </Ds.Flex>
            </Ds.Box>
        </Ds.Flex>
        <StyledDivider />
        <Ds.Flex flexDirection="column" gap="1.5">
            <StyledSkeletonText width="100px" height="24px" />
            <Ds.Flex gap="1" justifyContent="space-between" alignItems="center">
                <Ds.Flex flexDirection="column" gap="2">
                    <StyledSkeletonText width="90px" height="24px" />
                    <SkeletonLoader width="350px" height="24px" />
                    <StyledSkeletonText width="200px" />
                </Ds.Flex>
                <StyledSkeletonButton width="42px" />
            </Ds.Flex>
        </Ds.Flex>
    </>
);

const StyledSkeletonButton = styled(SkeletonLoader)`
    height: 42px;
    margin: 0 ${Tokens.SIZES[0.5]};
`;

const StyledSkeletonText = styled(SkeletonLoader)`
    margin: ${Tokens.SIZES[0.25]} 0;
`;

const StyledDivider = styled.hr`
    margin: 16px 0;
`;
