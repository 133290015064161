import React, { useEffect } from "react";

import { IframeDetector } from "@accurx/shared";
import Cookies from "js-cookie";
import { useLocation } from "react-router";

import { ROUTES } from "shared/Routes";

import { mapProductSearchParamToProductType } from "../Account.helper";

type UseSsoProps = React.PropsWithChildren<{
    nextRouteAfterLogin: { route: string; search: string };
    onLoading?: (isLoading: boolean) => void;
}>;

export const useSsoPopup = ({
    nextRouteAfterLogin,
    onLoading,
}: UseSsoProps) => {
    useEffect(() => {
        window.addEventListener("message", receiveMessage, false);

        // remove event listener on unmount
        return () => window.removeEventListener("message", receiveMessage);
    });

    let windowObjectReference: Window | null = null;
    let previousUrl: string | null = null;

    const newWindowUrl = `openidconnect/authenticate?returnUrl=${encodeURIComponent(
        ROUTES.postSsoRedirect,
    )}`;

    const receiveMessage = (event: MessageEvent) => {
        // Only accepts messages from the same base url as current window (eg web.accurx.com)
        if (event.origin !== window.location.origin) {
            return;
        }
        const { data } = event;
        // Redirect to clinician conversation if we receive a message that the user has logged in via the secondary window
        if (data.message === "SsoPopoutLoginSuccess") {
            onLoading?.(true);
            window.location.pathname = nextRouteAfterLogin.route;
            window.location.search = nextRouteAfterLogin.search;
        }
    };

    // If within an iframe, add a cookie so that we can detect the site is running from an iframe server-side.
    // For email login we use a HTTP Header, but we can't add headers loading URL for whole page
    if (IframeDetector.InIframe()) {
        Cookies.set("IFRAME", "true", { sameSite: "none", secure: true });
    }

    return () => {
        const strWindowFeatures =
            "toolbar=no, menubar=no, width=600, height=700, top=100, left=400";

        //  if the secondary window is not already open, open it
        if (windowObjectReference === null || windowObjectReference?.closed) {
            windowObjectReference = window.open(
                newWindowUrl,
                "Log in with NHSmail",
                strWindowFeatures,
            );
        }

        //   unlikely case: if the secondary window is already open, but not on the SSO login page, set the url to the SSO login page, and re-focus on the window
        else if (previousUrl !== newWindowUrl) {
            windowObjectReference = window.open(
                newWindowUrl,
                "Log in with NHSmail",
                strWindowFeatures,
            );
            // ensures the window reference exists before focusing
            if (windowObjectReference !== null) {
                windowObjectReference.focus();
            }
        }
        // if the window is already open and the user clicks the login button again, re-focuses on it
        else {
            windowObjectReference.focus();
        }

        // assign the previous URL
        previousUrl = newWindowUrl;
    };
};

export const useGetProductTypeFromUrl = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const productSearchParam = params.get("product");

    const productType = mapProductSearchParamToProductType(productSearchParam);

    return productType;
};
