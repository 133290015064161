import {
    IdType,
    PatientExternalIdentityDto,
} from "@accurx/api/patient-messaging";
import { ComposePatient } from "domains/message-component/schemas/ComposePatientSchema";

export const mapPatientExternalIdsToPatientExternalIdentityDto = (
    externalIds: ComposePatient["externalIds"],
): PatientExternalIdentityDto => {
    const mappedExternalIds = externalIds.map(({ type, value }) => ({
        value,
        type: IdType[type],
    }));
    return { patientExternalIds: mappedExternalIds };
};
