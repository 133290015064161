import { z } from "zod";

export const CONSENT_COMMUNICATION_CONTENT_TYPES = [
    "All",
    "TestResults",
    "Clinical",
    "NonClinical",
] as const;

export const ConsentCommunicationContentTypeSchema = z.enum(
    CONSENT_COMMUNICATION_CONTENT_TYPES,
);

export const ConsentCodeSchema = z.object({
    communicationContentType: ConsentCommunicationContentTypeSchema,
    hasConsented: z.boolean(),
    date: z.string(),
    term: z.string(),
});
export type ConsentCode = z.infer<typeof ConsentCodeSchema>;

const CommunicationConsentSchema = z.object({
    hasConsented: z.boolean().nullable(),
    codes: z.array(ConsentCodeSchema),
});
export type CommunicationConsent = z.infer<typeof CommunicationConsentSchema>;

export const QueryPatientCommunicationConsentResponseSchema = z.object({
    emailConsent: CommunicationConsentSchema,
    smsConsent: CommunicationConsentSchema,
});

export type QueryPatientCommunicationConsentResponse = z.infer<
    typeof QueryPatientCommunicationConsentResponseSchema
>;
