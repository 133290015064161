import { ContactDetail } from "@accurx/message-component";

export const mapMobileNumberToContactDetails = ({
    mobileNumber,
}: {
    mobileNumber: string | null;
}): ContactDetail[] =>
    mobileNumber
        ? [
              {
                  origin: "PDS",
                  method: "Mobile",
                  value: mobileNumber,
              },
          ]
        : [];
