import { useEffect } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useNavigationOrigin } from "@accurx/navigation";

import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import ApprovalRequests from "app/practices/vaccine/approvalRequests/ApprovalRequests";

export const AccubookDeliverySitesPage = () => {
    const track = useAnalytics();
    const navigationOrigin = useNavigationOrigin();

    useEffect(() => {
        track("AccuBookDeliverySite Page View", { navigationOrigin });
    }, [track, navigationOrigin]);

    return (
        <StyledContainer>
            <PageHeader title="Delivery sites" type={PageHeaderType.ListPage} />
            <ApprovalRequests noHeader />
        </StyledContainer>
    );
};
