import { SnomedCodeListContext } from "app/hooks/queries/useGetSnomedCodesQuery";

import { SnomedCode } from ".";
import { MissingSnomedCodeInformation } from "./MissingSnomedCodeInformation";
import { SnomedSelect } from "./SnomedSelect";
import { SnomedSummaryCard } from "./SnomedSummaryCard";

type SingleSnomedPickerProps = {
    onChange: (snomedCode: Optional<SnomedCode>) => void;
    value: Optional<SnomedCode>;
    disabled?: boolean;
    showMissingSnomedCodeInformation: boolean;
    id: string;
    context: SnomedCodeListContext;
};

export const SingleSnomedPicker = ({
    onChange,
    disabled = false,
    value,
    showMissingSnomedCodeInformation,
    id,
    context,
}: SingleSnomedPickerProps) =>
    value ? (
        <SnomedSummaryCard
            onRemove={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();

                onChange(undefined);
            }}
            value={value}
            disabled={disabled}
        />
    ) : (
        <>
            <SnomedSelect
                id={id}
                value={value}
                onChange={onChange}
                disabled={disabled}
                context={context}
            />
            {showMissingSnomedCodeInformation && (
                <MissingSnomedCodeInformation />
            )}
        </>
    );
