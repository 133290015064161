import { useState } from "react";

import { Button, Ds } from "@accurx/design";
import { useDeleteTemplateMutation } from "@accurx/message-templates";

import { FlemingAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

type DeleteTemplateButtonProps = {
    isWorkspaceTemplate: boolean;
    workspaceId: number;
    workspaceName: string;
    templateId: number;
    templateName: string;
    templateAttachmentCount: number;
    onSuccess?(): void;
};

export default function DeleteTemplateButton({
    isWorkspaceTemplate,
    workspaceId,
    workspaceName,
    templateId,
    templateName,
    templateAttachmentCount,
    onSuccess,
}: DeleteTemplateButtonProps) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { mutate: deleteTemplate } = useDeleteTemplateMutation();
    const flemingLoggedInProps = useFlemingLoggedInAnalytics();

    return (
        <>
            <Button
                text="Delete"
                theme="secondary"
                onClick={() => setIsModalOpen(true)}
            />
            <Ds.Modal open={isModalOpen} onOpenChange={setIsModalOpen}>
                <Ds.Modal.Section>
                    <Ds.Modal.Title>Delete Template</Ds.Modal.Title>
                </Ds.Modal.Section>
                <Ds.Modal.Divider />
                <Ds.Modal.Section>
                    <Ds.Text>
                        Are you sure you want to delete{" "}
                        <Ds.Text as="span" weight="bold">
                            "{templateName}"
                        </Ds.Text>
                        ?
                    </Ds.Text>
                    {isWorkspaceTemplate && (
                        <Ds.Text>
                            This will delete the template for everyone in{" "}
                            {workspaceName}.
                        </Ds.Text>
                    )}
                </Ds.Modal.Section>
                <Ds.Modal.Divider />
                <Ds.Modal.Section>
                    <Ds.Flex gap="2" justifyContent="flex-end">
                        <Button
                            theme="secondary"
                            text="Cancel"
                            onClick={() => {
                                setIsModalOpen(false);
                            }}
                        />
                        <Button
                            text="Delete"
                            theme="danger"
                            onClick={() => {
                                FlemingAnalyticsTracker.trackTemplateDeleteConfirmButtonClick(
                                    {
                                        ...flemingLoggedInProps,
                                        pageOrigin: "ManageTemplatePage",
                                        templateType: "sms",
                                        templateName,
                                        countAttachment:
                                            templateAttachmentCount,
                                    },
                                );
                                deleteTemplate(
                                    {
                                        workspaceId,
                                        templateId,
                                        templateName,
                                    },
                                    {
                                        onSuccess: () => {
                                            setIsModalOpen(false);
                                            onSuccess && onSuccess();
                                        },
                                    },
                                );
                            }}
                        />
                    </Ds.Flex>
                </Ds.Modal.Section>
            </Ds.Modal>
        </>
    );
}
