import { Ds } from "@accurx/design";
import { MAX_MESSAGE_LENGTH } from "domains/message-component/constants";
import { useCompose } from "domains/message-component/context";
import { useCharacterCount } from "domains/message-component/hooks/useCharacterCount";

import { CharacterCount } from "../../../Compose/components/CharacterCount/CharacterCount";
import { FragmentCount } from "../../../Compose/components/FragmentCount/FragmentCount";

export const MessageFooter = ({ children }: { children?: JSX.Element }) => {
    const { characterCount, fragmentCount } = useCharacterCount();
    const { state } = useCompose();

    return (
        <Ds.Flex gap="1" justifyContent="space-between">
            {children}
            {state.contactDetails.method !== "Email" && (
                <Ds.Flex
                    gap="0.5"
                    justifyContent="center"
                    alignItems="center"
                    flexWrap="wrap"
                >
                    <CharacterCount
                        charactersUsed={characterCount}
                        maxCharacters={MAX_MESSAGE_LENGTH}
                    />
                    <FragmentCount count={fragmentCount} />
                </Ds.Flex>
            )}
        </Ds.Flex>
    );
};
