import { ReactNode } from "react";

import { Ds, Tokens } from "@accurx/design";
import { i } from "images";
import styled from "styled-components";

import { PatientSearchResult } from "../../../types";
import { PatientDetailsPanelSkeleton } from "./PatientDetailsPanelSkeleton";

export type PatientDetailsPanelProps = {
    patient: PatientSearchResult | null;
    children?: ReactNode;
    isLoading?: boolean;
    isEmpty: boolean;
};

export const PatientDetailsPanel = ({
    patient,
    children,
    isLoading = false,
    isEmpty = false,
}: PatientDetailsPanelProps) => {
    if (isLoading) {
        return (
            <StyledPanel role="status">
                <PatientDetailsPanelSkeleton />
            </StyledPanel>
        );
    }

    if (isEmpty) {
        return <StyledPanel>{null}</StyledPanel>;
    }

    if (!patient) {
        return (
            <StyledPanel>
                <PatientDetailsEmptyState />
            </StyledPanel>
        );
    }

    return <StyledPanel>{children}</StyledPanel>;
};

const PatientDetailsEmptyState = () => {
    return (
        <Ds.Flex alignItems="center" style={{ flex: 1 }}>
            <Ds.Box width="190px" m="0 auto">
                <Ds.Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{ textAlign: "center" }}
                >
                    <img
                        src={i("illustrations/Group.png")}
                        width={80}
                        height={80}
                        alt=""
                    />
                    <Ds.Text color="metal" mt="2">
                        Search or select a patient to see their details here
                    </Ds.Text>
                </Ds.Flex>
            </Ds.Box>
        </Ds.Flex>
    );
};

const StyledPanel = styled(Ds.Flex)`
    flex-direction: column;

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        border-left: ${Tokens.BORDERS.normal.silver};
        padding: ${Tokens.SIZES[2]};
        width: 50%;
    }
`;
