import { useAllSentConversationGroupQuery } from "@accurx/concierge/hooks/queries/useAllSentConversationGroupQuery";
import { getMostRecentItem } from "@accurx/concierge/util/getMostRecentItem";
import { getMostRecentSentItem } from "@accurx/concierge/util/getMostRecentSentItem";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import {
    ConversationFeed,
    ConversationFeedOptionsWrapper,
    ConversationListHeader,
    ConversationTabItem,
    ConversationTabs,
} from "domains/inbox/components/ConversationList/components";
import { ConversationPreview } from "domains/inbox/components/ConversationPreview/ConversationPreview";
import { Dropdown } from "domains/inbox/components/Dropdown/Dropdown";
import { DropdownItem } from "domains/inbox/components/Dropdown/DropdownItem";
import { useInboxTitle } from "domains/inbox/hooks/util";
import { useConversationFilterAnalytics } from "domains/inbox/hooks/util/useConversationFilterAnalytics";
import { useConversationListAnalytics } from "domains/inbox/hooks/util/useConversationListAnalytics";
import { useInboxLink } from "domains/inbox/hooks/util/useInboxLink";

import {
    StyledAccessibleHeader,
    StyledConversationFeedContainer,
    StyledConversationList,
} from "../styles";

export const AllSent = () => {
    useInboxTitle(`All sent conversations`);

    const link = useInboxLink();
    const groupQuery = useAllSentConversationGroupQuery();

    const { trackTabClick } = useConversationListAnalytics();
    const { trackFilterButtonClick, trackFilterMenuItemClick } =
        useConversationFilterAnalytics();

    return (
        <>
            <StyledConversationList rows="auto 1fr">
                <UI.Cell>
                    <ConversationListHeader
                        heading={
                            <>
                                All sent & scheduled
                                <StyledAccessibleHeader>
                                    showing sent messages
                                </StyledAccessibleHeader>
                            </>
                        }
                    />

                    <ConversationTabs>
                        <ConversationTabItem
                            active={true}
                            link={link.to("AllSent")}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Scheduled",
                                    newTab: "Sent",
                                    conversationParticipant: "WithPatient",
                                })
                            }
                        >
                            Sent
                        </ConversationTabItem>

                        <ConversationTabItem
                            active={false}
                            link={link.to("AllScheduled")}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Sent",
                                    newTab: "Scheduled",
                                    conversationParticipant: "WithPatient",
                                })
                            }
                        >
                            Scheduled
                        </ConversationTabItem>
                    </ConversationTabs>
                </UI.Cell>

                <StyledConversationFeedContainer>
                    <ConversationFeedOptionsWrapper>
                        <UI.Ds.Flex.Item>
                            <Dropdown
                                icon={<Pill.Icon name="Tag" colour="blue" />}
                                dropDownDisplayName="Filter"
                                contentHeading="Filter"
                                onClick={(_, isOpen) => {
                                    if (!isOpen) {
                                        trackFilterButtonClick({
                                            filterName: "Filter",
                                            appOrigin: "ConversationList",
                                        });
                                    }
                                }}
                            >
                                <DropdownItem.Link
                                    link={link.to("AllSent")}
                                    selected={true}
                                    onClick={() =>
                                        trackFilterMenuItemClick({
                                            filterName: "Show all",
                                            appOrigin: "ConversationList",
                                        })
                                    }
                                >
                                    <DropdownItem.Icon name="Tag" />
                                    <DropdownItem.Text>
                                        Show all
                                    </DropdownItem.Text>
                                </DropdownItem.Link>

                                <DropdownItem.ListItem>
                                    <DropdownItem.Divider fullWidth />
                                </DropdownItem.ListItem>

                                <DropdownItem.Link
                                    link={link.to("AllFailed")}
                                    onClick={() =>
                                        trackFilterMenuItemClick({
                                            filterName: "Failed Messages",
                                            appOrigin: "ConversationList",
                                        })
                                    }
                                >
                                    <DropdownItem.Icon name="Tag" />
                                    <DropdownItem.Text>
                                        Failed Messages
                                    </DropdownItem.Text>
                                </DropdownItem.Link>
                            </Dropdown>
                        </UI.Ds.Flex.Item>
                    </ConversationFeedOptionsWrapper>

                    <ConversationFeed
                        key={groupQuery.data?.id}
                        isLoading={groupQuery.status === "loading"}
                        isError={groupQuery.status === "error"}
                        refetch={groupQuery.refetch}
                        fetchMore={groupQuery.fetchMore}
                        isFetchingMore={groupQuery.isFetchingMore}
                        isFullyLoaded={groupQuery.data?.isFullyLoaded}
                        members={groupQuery.data?.members}
                        filters={groupQuery.data?.filters}
                        renderItem={(conversation, state) => {
                            const item =
                                getMostRecentSentItem(conversation) ??
                                getMostRecentItem(conversation);
                            const lastUpdateTime = item.createdAt;

                            return (
                                <ConversationPreview
                                    conversation={conversation}
                                    item={item}
                                    state={state}
                                    displayAssigneeBadge
                                    lastUpdateTime={lastUpdateTime}
                                    hideConversationActions
                                />
                            );
                        }}
                    />
                </StyledConversationFeedContainer>
            </StyledConversationList>
        </>
    );
};
