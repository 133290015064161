import { DirectoryAddContact } from "domains/directory/components/DirectoryAddContact/DirectoryAddContact";
import { DirectorySearch } from "domains/directory/components/DirectorySearch/DirectorySearch";
import { useDirectory } from "domains/directory/context/DirectoryProvider";

export const DirectoryContent = () => {
    const { view } = useDirectory();

    return view === "Search" ? (
        <DirectorySearch />
    ) : view === "AddContact" ? (
        <DirectoryAddContact />
    ) : null;
};
