import { useFeatureFlag } from "@accurx/auth";
import { useTeam } from "@accurx/concierge/hooks/data/useTeam";
import { useTeamConversationGroupQuery } from "@accurx/concierge/hooks/queries/useTeamConversationGroupQuery";
import { getMostRecentItem } from "@accurx/concierge/util/getMostRecentItem";
import { Ds } from "@accurx/design";
import {
    ConversationFeed,
    ConversationFeedOptionsWrapper,
    ConversationListHeader,
    ConversationTabItem,
    ConversationTabs,
} from "domains/inbox/components/ConversationList/components";
import { ConversationPreview } from "domains/inbox/components/ConversationPreview/ConversationPreview";
import { NotificationsDropdown } from "domains/inbox/components/NotificationsDropdown/NotificationsDropdown";
import {
    useInboxLink,
    useInboxParams,
    useInboxTitle,
} from "domains/inbox/hooks/util";
import { useConversationListAnalytics } from "domains/inbox/hooks/util/useConversationListAnalytics";
import { userflowIds } from "domains/inbox/util";

import { ConversationSortOrder } from "../../components/ConversationSortOrder/ConversationSortOrder";
import { getEmptyStateVariant } from "../../components/EmptyState/EmptyState";
import {
    StyledConversationFeedContainer,
    StyledConversationList,
    StyledHeaderTitle,
    StyledHeaderWrap,
} from "../styles";

export const Teams = () => {
    const { teamId, status, sort } = useInboxParams([
        "teamId",
        "status",
        "sort",
    ]);
    const link = useInboxLink();
    const team = useTeam({ teamId });

    const groupQuery = useTeamConversationGroupQuery({
        teamId,
        status,
        sort,
    });

    const { trackTabClick } = useConversationListAnalytics();

    useInboxTitle(
        team
            ? `Assigned to ${team.displayName.toLowerCase()} ${status.toLowerCase()} conversations`
            : "",
    );
    const emptyStateVariant = getEmptyStateVariant(status);
    const canUseSorting = useFeatureFlag("UnifiedInboxSortingBuild");

    if (!team) return null;

    return (
        <StyledConversationList rows="auto 1fr">
            <StyledHeaderWrap>
                <ConversationListHeader
                    heading={
                        <StyledHeaderTitle>
                            {team.displayName}
                        </StyledHeaderTitle>
                    }
                    notifications={<NotificationsDropdown team={team} />}
                />

                <ConversationTabs>
                    <ConversationTabItem
                        active={status === "Open"}
                        link={link.update({
                            status: "Open",
                            sort: null,
                            conversationId: null,
                        })}
                        userflowId={userflowIds.conversationList.openTab}
                        onClick={() =>
                            trackTabClick({
                                currentTab: "Done",
                                newTab: "Open",
                                conversationParticipant: "WithPatient",
                            })
                        }
                    >
                        Open
                    </ConversationTabItem>

                    <ConversationTabItem
                        active={status === "Done"}
                        link={link.update({
                            status: "Done",
                            sort: null,
                            conversationId: null,
                        })}
                        userflowId={userflowIds.conversationList.doneTab}
                        onClick={() =>
                            trackTabClick({
                                currentTab: "Open",
                                newTab: "Done",
                                conversationParticipant: "WithPatient",
                            })
                        }
                    >
                        Done
                    </ConversationTabItem>
                </ConversationTabs>
            </StyledHeaderWrap>
            <StyledConversationFeedContainer>
                {canUseSorting && (
                    <ConversationFeedOptionsWrapper>
                        <Ds.Flex.Item>
                            <ConversationSortOrder />
                        </Ds.Flex.Item>
                    </ConversationFeedOptionsWrapper>
                )}
                <ConversationFeed
                    key={groupQuery.data?.id}
                    isLoading={groupQuery.status === "loading"}
                    isError={groupQuery.status === "error"}
                    refetch={groupQuery.refetch}
                    fetchMore={groupQuery.fetchMore}
                    isFetchingMore={groupQuery.isFetchingMore}
                    isFullyLoaded={groupQuery.data?.isFullyLoaded}
                    members={groupQuery.data?.members}
                    filters={groupQuery.data?.filters}
                    emptyStateVariant={emptyStateVariant}
                    renderItem={(conversation, state) => (
                        <ConversationPreview
                            conversation={conversation}
                            item={getMostRecentItem(conversation)}
                            state={state}
                        />
                    )}
                />
            </StyledConversationFeedContainer>
        </StyledConversationList>
    );
};
