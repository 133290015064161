import { Ds, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledCard = styled(Ds.Box)`
    border-radius: ${Tokens.BORDERS.radius};
    border: 1px solid ${Tokens.COLOURS.greyscale.silver};
`;

export const StyledList = styled.ul`
    // override default list styling
    list-style: none;
    padding: 0;
    margin-bottom: 0px;

    display: flex;
    flex-direction: column;
    gap: ${Tokens.SIZES[1]};
`;
