import { HubClient } from "@accurx/realtime/hubClient/HubClient";

import { PatientManager } from "shared/concierge/patients/types/PatientManager.types";
import { UsersAndTeamsManager } from "shared/concierge/usersAndTeams/types/usersAndTeams.types";

import { ConversationManager } from "./ConversationManager";
import type {
    ConversationActionsFactory,
    ConversationManager as ConversationManagerType,
    ConversationUpdatesSubscriptionFactory,
    GlobalConversationManager as GlobalConversationManagerType,
    PatientUpdatesSubscriptionFactory,
} from "./types/component.types";

/**
 * GlobalConversationManager
 *
 * We're not sure entirely what this class is responsible for just
 * yet. Currently it's acting as a factory for Conversation managers
 * but could expand to as a global data store for conversations across
 * all workspaces.
 */
class GlobalConversationManager implements GlobalConversationManagerType {
    constructor(
        private readonly userId: string,
        private readonly hubClient: HubClient,
    ) {
        // Call out to hub client to connect here. This returns a promise but we dont
        // handled the call back as the update subject is used later to monitor the
        // connection status.
        hubClient.connect();
    }

    get currentUserId(): string {
        return this.userId;
    }

    /**
     * Creates a conversation manager for a given workspace, encompassing the conversations for everything in that workspace.
     * @param workspaceId the id of the workspace.
     * @param conversationActionsFactory factory function for creating an instance of conversation actions.
     * @param liveConversationUpdatesFeedFactory
     * @param livePatientUpdatesFeedFactory
     * @param patientManager
     * @param usersAndTeamsManager
     * @returns instance of conversation manager.
     */
    forWorkspace(
        workspaceId: number,
        conversationActionsFactory: ConversationActionsFactory,
        liveConversationUpdatesFeedFactory: ConversationUpdatesSubscriptionFactory,
        livePatientUpdatesFeedFactory: PatientUpdatesSubscriptionFactory,
        patientManager: PatientManager,
        usersAndTeamsManager: UsersAndTeamsManager,
    ): ConversationManagerType {
        const conversationActions = conversationActionsFactory(workspaceId);

        const liveConversationUpdatesFeed = liveConversationUpdatesFeedFactory(
            this.hubClient,
            workspaceId,
        );

        const livePatientUpdatesFeed = livePatientUpdatesFeedFactory(
            this.hubClient,
            workspaceId,
        );

        const patientManagerSubscriptions = [
            patientManager.connectFeed(conversationActions.patientsFeed),
            patientManager.connectFeed(livePatientUpdatesFeed),
        ];

        const userManagerSubscriptionHandle = usersAndTeamsManager.connectFeed(
            conversationActions.usersAndTeamsFeed,
        );

        const handleUnsubscribe = () => {
            patientManagerSubscriptions.forEach((subscription) =>
                subscription.unsubscribe(),
            );
            userManagerSubscriptionHandle.unsubscribe();
        };

        return new ConversationManager(
            this.userId,
            workspaceId,
            handleUnsubscribe,
            conversationActions,
            liveConversationUpdatesFeed,
            this.hubClient.connectionStateSubscription,
            this.hubClient.connectionStateSubscriptionNew,
            usersAndTeamsManager.currentUserTeamMembershipFeed,
        );
    }
}

export const createGlobalConversationManager = (
    userId: string,
    hubClient: HubClient,
): GlobalConversationManagerType =>
    new GlobalConversationManager(userId, hubClient);
