import { ComponentProps } from "react";

import * as UI from "@accurx/design";
import { TemplateItem } from "domains/message-component/types";

import {
    Chevron,
    StyledButton,
    StyledListItem,
    StyledLoadingSpinner,
    StyledTitleText,
} from "./ListItem.styles";

type ListItemProps = ComponentProps<typeof StyledListItem> & {
    templateType: TemplateItem["type"];
    templateTitle: string;
    onClick: () => void;
    onClickPreview: () => void;
    isLoading?: boolean;
};

export const ListItem = ({
    templateType,
    templateTitle,
    onClick,
    onClickPreview,
    isLoading = false,
    ...listItemProps
}: ListItemProps) => {
    return (
        <StyledListItem {...listItemProps}>
            <StyledButton onClick={onClickPreview}>
                <UI.Flex gap={"1"} alignItems="center" as={"span"}>
                    {templateType === "MessageTemplate" && (
                        <UI.Icon
                            name="MessageWrite"
                            size={3}
                            props={{ style: { flexShrink: "0" } }}
                        />
                    )}
                    {templateType === "QuestionnaireTemplate" &&
                        (isLoading ? (
                            <StyledLoadingSpinner
                                dimension="extra-small"
                                colour="blue"
                            />
                        ) : (
                            <UI.Icon
                                name="Questionnaire"
                                size={3}
                                props={{ style: { flexShrink: "0" } }}
                            />
                        ))}
                    <StyledTitleText size="small">
                        {templateTitle}
                    </StyledTitleText>
                    <Chevron />
                </UI.Flex>
            </StyledButton>
        </StyledListItem>
    );
};
