import { Conversation } from "@accurx/concierge/types";
import { ContactDetail } from "@accurx/message-component";
import { EmailAddressHelper, MobileNumberHelper } from "@accurx/shared";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";

import { findValidNumbersFromTriageRequest } from "./findValidNumbersFromTriageRequest";

/**
 * Returns a list of **valid** contact details
 * found in the conversation, ordered by date most recent to oldest.
 *
 * Valid conversation items are:
 * - PatientSms
 * - PatientEmail
 * - PatientTriageRequestNote
 */
export const getUniqueValidContactDetailsFromConversation = (
    conversation: Conversation,
): ContactDetail[] => {
    const contactDetailsInConversation: ContactDetail[] = [];

    const itemsOrderedByCreatedAt = orderBy(
        conversation.items,
        "createdAt",
        "desc",
    );

    itemsOrderedByCreatedAt.forEach((item) => {
        switch (item.contentType) {
            case "PatientEmail":
                if (EmailAddressHelper.isValidEmailAddress(item.emailAddress)) {
                    contactDetailsInConversation.push({
                        method: "Email",
                        value: item.emailAddress,
                        origin: "Conversation",
                    });
                }
                break;
            case "PatientSms":
                if (MobileNumberHelper.isValidMobileNumber(item.mobileNumber)) {
                    contactDetailsInConversation.push({
                        method: "Mobile",
                        value: item.mobileNumber,
                        origin: "Conversation",
                    });
                }
                break;
            case "PatientTriageRequestNote": {
                const foundNumbers = findValidNumbersFromTriageRequest(item);

                foundNumbers.forEach(({ type, value }) => {
                    contactDetailsInConversation.push({
                        method: "Mobile",
                        value,
                        origin:
                            type === "proxy"
                                ? "SubmittedByPatientProxy"
                                : "SubmittedByPatient",
                    });
                });

                break;
            }
            default:
                break;
        }
    });

    return uniqBy(contactDetailsInConversation, "value");
};
