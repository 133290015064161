import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useAddNoteMutation } from "@accurx/concierge/hooks/mutations/useAddNoteMutation";
import { Conversation, PatientSummary } from "@accurx/concierge/types";
import { Ds, Feedback, Text, VisuallyHidden } from "@accurx/design";
import { useConversationActionTrackingFields } from "domains/inbox/hooks/util/useConversatonActionAnalytics";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import {
    StyledBottomContainer,
    StyledContainer,
    StyledFeedback,
    StyledIconWrap,
    StyledOuterTextarea,
    StyledTextArea,
} from "./ComposeNote.styles";
import {
    ComposeNoteErrors,
    composeNoteValidation,
} from "./ComposeNote.validation";
import { ExpandMinimiseBar } from "./components/ExpandMinimiseBar/ExpandMinimiseBar";

const ERROR_TITLE: Record<ComposeNoteErrors, string> = {
    NOTE_EMPTY: "Write a note before adding",
    NOTE_TOO_LONG: "Your note is too long",
};

type ComposeNoteProps = {
    noteText: string;
    setNoteText: (value: string) => void;
    conversation: Conversation;
    closeNotePanel: () => void;
    patient?: PatientSummary;
    setSentNoteStatus: (status: string | null) => void;
    onMinimiseClick?: () => void;
} & ExpandedInfo;

type ExpandedInfo =
    | {
          onExpandClick?: never;
          isHeightRestricted?: boolean;
      }
    | {
          onExpandClick: () => void;
          isHeightRestricted: boolean;
      };

export const ComposeNote = ({
    noteText,
    setNoteText,
    conversation,
    patient,
    closeNotePanel,
    setSentNoteStatus,
    onMinimiseClick,
    onExpandClick,
    isHeightRestricted = false,
}: ComposeNoteProps) => {
    const history = useHistory();
    const currentURL = history.location.pathname + history.location.search;

    const ref = useRef<HTMLTextAreaElement | null>(null);

    const track = useAnalytics();
    const addNoteButtonClickAnalytics =
        useConversationActionTrackingFields(conversation);

    const [noteErrors, setNoteErrors] = useState<ComposeNoteErrors | null>(
        null,
    );

    useEffect(() => {
        ref.current?.focus();
    }, []);

    const addNote = useAddNoteMutation({
        onSuccess: () => {
            setSentNoteStatus("Note was successfully saved");
        },
        onError: () => {
            toast(
                <Feedback
                    title={
                        <Ds.Flex
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            <Text variant="label">
                                An error occurred when trying to send your
                                message
                            </Text>
                            <Ds.Button
                                appearance="tertiary"
                                title="Close alert"
                            >
                                <Ds.Button.Icon
                                    name="Cross"
                                    aria-label="Close alert"
                                />
                            </Ds.Button>
                        </Ds.Flex>
                    }
                    colour="error"
                >
                    <Ds.Flex flexDirection="column">
                        <Text skinny>
                            Go back to your message and try saving your note
                            again. If this keeps happening, please get in touch
                            with our support team.
                        </Text>
                        <Link to={currentURL} style={{ width: "fit-content" }}>
                            <Text skinny colour="blue" as="span">
                                Go to message
                            </Text>
                        </Link>
                    </Ds.Flex>
                </Feedback>,
                { autoClose: false },
            );
        },
        onSettled: (_, error) => {
            closeNotePanel();

            track("ConversationAction Button Response", {
                ...addNoteButtonClickAnalytics({
                    type: "AddNote",
                    appOrigin: "ComposeNote",
                }),
                withNote: true,
                noteLength: noteText.length,
                hasError: !!error,
            });
        },
    });

    const onAddNoteButtonKeyDownHandler = (
        e: React.KeyboardEvent<HTMLButtonElement> | undefined,
    ) => {
        if (e && "key" in e && (e.key === "Enter" || e.key === "Space")) {
            e.preventDefault();
            onAddNoteButtonHandler(e);
        }
    };

    const onAddNoteButtonHandler = (
        e:
            | React.KeyboardEvent<HTMLButtonElement>
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | undefined,
    ) => {
        const errors = composeNoteValidation(noteText);

        if (errors) {
            setNoteErrors(errors);
            setSentNoteStatus(ERROR_TITLE[errors]);

            /**
             * There is an inconsistent behaviour for onClick vs Keydown events.
             * Voiceover doesn't consistently read out the aria-live statement when there is an error on keydown events, i believe this happens because we manually set the focus back to text area. This piece of code tries to mitigate that issue by setting the focus only after the error statement is read out to the user.
             */
            if (e?.type === "keydown") {
                setTimeout(() => {
                    ref.current?.focus();
                }, 1800);
            } else {
                ref.current?.focus();
            }

            return;
        }

        addNote.mutate({
            conversation,
            patientIds: patient?.externalIds,
            note: noteText,
        });

        track("ConversationAction Button Click", {
            ...addNoteButtonClickAnalytics({
                type: "AddNote",
                appOrigin: "ComposeNote",
            }),
            withNote: true,
            noteLength: noteText.length,
        });
    };

    const onNoteTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (noteErrors) {
            setNoteErrors(null);
        }
        setSentNoteStatus(null);
        setNoteText(e.target.value);
    };

    return (
        <StyledContainer flexDirection="column">
            {(onExpandClick || onMinimiseClick) && (
                <ExpandMinimiseBar
                    onExpandClick={onExpandClick}
                    onMinimiseClick={onMinimiseClick}
                    isHeightRestricted={isHeightRestricted}
                />
            )}
            {noteErrors && (
                <Ds.Flex.Item flex="0">
                    <StyledFeedback
                        title={ERROR_TITLE[noteErrors]}
                        iconName="Failed"
                        colour="error"
                    />
                </Ds.Flex.Item>
            )}

            <StyledOuterTextarea
                flex="1"
                $isHeightRestricted={isHeightRestricted}
            >
                <VisuallyHidden as="span">
                    <label htmlFor="compose-note">
                        Write your note here. It's only visible to colleagues in
                        your workspace
                    </label>
                </VisuallyHidden>
                <StyledTextArea
                    autoComplete="off"
                    ref={ref}
                    placeholder="Write your note here..."
                    value={noteText}
                    onChange={onNoteTextChange}
                    id="compose-note"
                    aria-label="Note content"
                    maxLength={4000}
                />
            </StyledOuterTextarea>
            <Ds.Flex.Item flex="0">
                <StyledBottomContainer>
                    <Ds.Flex alignItems="center">
                        <StyledIconWrap>
                            <Ds.Icon
                                name="LockLocked"
                                size="small"
                                color="metal"
                                appearance="solid"
                            />
                        </StyledIconWrap>
                        <Text skinny variant="preview" colour="metal">
                            Only visible to colleagues in your workspace
                        </Text>
                    </Ds.Flex>
                    <Ds.Button
                        onClick={onAddNoteButtonHandler}
                        loading={addNote.isLoading}
                        onKeyDown={onAddNoteButtonKeyDownHandler}
                        size="small"
                    >
                        Add note
                    </Ds.Button>
                </StyledBottomContainer>
            </Ds.Flex.Item>
        </StyledContainer>
    );
};
