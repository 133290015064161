import { useEffect } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useNavigationOrigin } from "@accurx/navigation";

import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { VaccineSiteSetup } from "app/practices/vaccine/siteSetup/SiteSetup";

export const AccubookManageClinicsPage = () => {
    const track = useAnalytics();
    const navigationOrigin = useNavigationOrigin();

    useEffect(() => {
        track("AccuBookManageClinic Page View", { navigationOrigin });
    }, [track, navigationOrigin]);

    return (
        <StyledContainer>
            <PageHeader title="Manage clinics" type={PageHeaderType.ListPage} />
            <VaccineSiteSetup noHeader />
        </StyledContainer>
    );
};
