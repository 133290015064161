import { Ds, Tokens } from "@accurx/design";
import styled from "styled-components";

const { COLOURS, SIZES, BORDERS } = Tokens;

export const StyledContainer = styled(Ds.Flex).attrs({
    alignItems: "center",
    justifyContent: "space-between",
})`
    background-color: ${COLOURS.primary.yellow[10]};
    box-shadow: 0 0 0 1px ${COLOURS.primary.yellow[75]};

    padding: ${SIZES[1]} ${SIZES[1.5]};
    border-radius: ${BORDERS.radius};

    flex-grow: 1;

    a {
        &:hover {
            background: none;
        }
    }
`;

export const StyledCancelButton = styled(Ds.Button)`
    min-width: ${SIZES[8]};
    justify-content: center;
`;
