import { FloreyResponseItem } from "@accurx/concierge/types";
import { Ds, Feedback } from "@accurx/design";
import {
    useIsSavingToRecord,
    useSaveFloreyPdfToRecordMutation,
} from "domains/inbox/hooks/mutations/useSaveToRecordMutation";
import { toast } from "react-toastify";

export const SaveFloreyPdfToRecordButton = ({
    item,
}: {
    item: FloreyResponseItem;
}) => {
    const isLoading = useIsSavingToRecord({ itemServerId: item.serverId });
    const mutation = useSaveFloreyPdfToRecordMutation({
        itemServerId: item.serverId,
    });

    const hasFailed =
        item.saveToRecordState.status === "Failed" ||
        mutation.status === "error";

    const handleClick = async (): Promise<void> => {
        try {
            await mutation.mutateAsync();
        } catch (e) {
            toast(
                <Feedback
                    colour="error"
                    title="Something went wrong while trying to save to CITO, please try again"
                />,
            );
        }
    };

    return (
        <Ds.Flex flexDirection={"column"} gap="0.25">
            <Ds.Flex.Item>
                <Ds.Button
                    appearance="secondary"
                    size="small"
                    onClick={() => void handleClick()}
                    loading={isLoading}
                    data-testid="save-cito-button"
                >
                    <Ds.Button.Icon name="Save" />
                    Save to CITO
                </Ds.Button>
            </Ds.Flex.Item>
            {hasFailed && !isLoading && (
                <Ds.Flex alignItems="center" gap="0.5">
                    <Ds.Icon
                        name="Error"
                        appearance="solid"
                        size="small"
                        color="red"
                    />
                    <Ds.Text color="zinc" size="small">
                        Failed to save, try again or download PDF
                    </Ds.Text>
                </Ds.Flex>
            )}
        </Ds.Flex>
    );
};
