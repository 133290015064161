import {
    FormFieldFeedback,
    StackPanel,
    TextareaAutosize,
    Thread,
    Tokens,
} from "@accurx/design";
import styled from "styled-components";

import { StyledBannerSection } from "./NextStepsBanner.styles";

// Temporary fix for dodgy attachment overflow
export const ButtonRow = styled(StackPanel)`
    > div {
        min-width: 0;
    }
`;

export const ThreadWrapper = styled.div`
    header {
        background-color: ${Tokens.COLOURS.greyscale.white};
    }

    header:nth-of-type(2) {
        border-radius: 0;
    }

    display: contents;

    ${StyledBannerSection} {
        border: 0;
    }
`;

export const StyledTextareaAutosize = styled(TextareaAutosize)`
    margin-top: ${Tokens.SIZES[1.5]};
`;

export const StyledFormFieldFeedback = styled(FormFieldFeedback)`
    padding-top: ${Tokens.SIZES[1]};
`;

export const StyledThreadFooterContainer = styled.div`
    width: 100%;
    height: max-content;
    background-color: ${Tokens.COLOURS.greyscale.white};
    border-radius: ${Tokens.BORDERS.radius};
`;

export const StyledOverFlowContent = styled.div`
    overflow-y: auto;
    // this should always match the parent element height
    max-height: 20rem;
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
`;

export const MessageContainer = styled.div`
    position: relative;
`;

type HighlightableAttachmentProps = {
    highlight: boolean;
} & React.ComponentProps<typeof Thread.Attachment>;

export const HighlightableAttachment = styled(
    Thread.Attachment,
)<HighlightableAttachmentProps>`
    ${({ highlight }) =>
        highlight
            ? `
        animation: flash 1700ms ease-in;
        animation-direction: normal, reverse;
        animation-delay: 1s;
    `
            : ""}
    @keyframes flash {
        18%,
        65% {
            background-color: ${Tokens.COLOURS.primary.blue[10]};
            border-color: ${Tokens.COLOURS.primary.blue[100]};
        }
    }
`;

export const RaisedAbsoluteContainer = styled.div`
    position: absolute;
    top: -30px;
`;

export const RelativeContainer = styled.div`
    position: relative;
`;
