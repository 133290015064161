import { ComposeRoutes, FullScreenPageWrapper } from "@accurx/compose";
import { useAccurxWebTitle } from "@accurx/navigation";
import { useExcludeFromGlobalStyles } from "GlobalStyleManager";

/**
 * Displays compose routes wrapped around unified navigation
 * creation layout.
 */
export const ComposeRoutesWithFullScreenPage = () => {
    useAccurxWebTitle("New message");

    useExcludeFromGlobalStyles();

    return (
        <FullScreenPageWrapper>
            <ComposeRoutes />
        </FullScreenPageWrapper>
    );
};
