import { Grid, Tokens } from "@accurx/design";
import styled from "styled-components";

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        flex-wrap: nowrap;
        flex-direction: row;
    }
`;

const StyledLayout = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

const StyledSidebar = styled.div`
    flex-shrink: 0;
    width: 100%;

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        width: auto;
    }
`;

const StyledMainContent = styled.div`
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100vw;
    display: flex;
    flex-direction: column;

    overflow-y: auto;
`;

const StyledMobileHeaderLayout = styled.div`
    align-self: flex-end;
    width: 100vw;
    height: max-content;
`;

export const QuickViewContainer = styled.div``;
export const PageContainer = styled.div``;

const StyledQuickViewWrapper = styled(Grid)<{ $quickViewIsOpen: boolean }>`
    display: flex;
    width: 100%;
    height: 100%;

    ${QuickViewContainer} {
        box-shadow: 0px 4px 16px rgba(68, 72, 126, 0.32);
        width: 100%;
        position: relative;
        @media (min-width: 1200px) {
            min-width: 414px;
            flex: 0.5;
        }
        &:empty {
            min-width: 0;
            flex: 0;
        }
    }
    ${PageContainer} {
        min-width: 0;
        flex: 1;
        overflow: auto;
        max-height: 100%;
        display: flex;
        flex-direction: column;
    }
`;

export {
    StyledMainContent,
    StyledSidebar,
    StyledWrapper,
    StyledMobileHeaderLayout,
    StyledQuickViewWrapper,
    StyledLayout,
};
