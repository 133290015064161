import { Icon, Text, Thread } from "@accurx/design";
import { Log } from "@accurx/shared";

import {
    FallbackFailedLinkItem,
    PatientNhsAppMessageConversationItem,
} from "shared/concierge/conversations/types/item.types";

import { BaseConversationThreadItem } from "../threadItem.types";

export type FallbackFailedThreadItemProps =
    BaseConversationThreadItem<FallbackFailedLinkItem> & {
        linkedItem: PatientNhsAppMessageConversationItem | null;
    };

export const FallbackFailedThreadItem = ({
    item,
    linkedItem,
    handleOnItemRead,
}: FallbackFailedThreadItemProps) => {
    // This is unlikely to happen, but if there is no
    // linkedItem, display a generic message.
    if (!linkedItem) {
        Log.warn(
            "FallbackFailedThreadItem - Cannot find linked conversation item for fallback failed item.",
            {
                tags: { linkedItemId: item.linkedItemId },
            },
        );

        return (
            <Thread.Message key={item.id} inboxType="information">
                <span>Could not deliver fallback message.</span>
            </Thread.Message>
        );
    }

    return (
        <Thread.Message
            key={item.id}
            inboxType="information"
            readActionProps={{ id: item.id, onMessageSeen: handleOnItemRead }}
        >
            <Text skinny>
                <Icon theme="Fill" name="Warning" colour="red" size={3} />
                <span>
                    Message delivered to NHS App but could not deliver SMS
                    fallback.
                </span>
            </Text>
        </Thread.Message>
    );
};

export const fallbackFailedThreadItemSummaryText = (
    item: FallbackFailedLinkItem,
) => "Fallback failed";
