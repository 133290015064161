import { useEffect, useState } from "react";

import * as UI from "@accurx/design";
import { Input, SelectItem, SingleSelect } from "@accurx/inbox-design-library";
import {
    availabilityPrimaryText,
    availabilityText,
} from "domains/self-book/utils/AppointmentAvailabilityUtils";
import {
    AppointmentAvailabilityPeriod,
    TimeFrameInput,
} from "domains/self-book/utils/types";

import {
    SpacingWrapper,
    StyledSearchSelect,
    SubTitlePositioning,
} from "../SelfbookConfigurationForm.styles";
import {
    StyledHeader,
    StyledInputContainer,
    StyledSelectContainer,
    StyledTextComponent,
    SubTitleText,
} from "./AppointmentAvailability.styles";

export const TIME_FRAME_OPTIONS = [
    { label: "weeks", value: "weeks" },
    { label: "days", value: "days" },
];

type AppointmentAvailabilityProps = {
    initialValue?: { timeFrame: TimeFrameInput };
    sendTime: string;
    onChangeValue: (value: AppointmentAvailabilityPeriod) => void;
    errors: string[] | undefined;
};

export const NUMBER_REGEXP = /^[0-9]*$/;

export const AppointmentAvailability = ({
    initialValue,
    sendTime,
    onChangeValue,
    errors,
}: AppointmentAvailabilityProps) => {
    const [timeFrame, setTimeFrame] = useState<TimeFrameInput>(
        initialValue?.timeFrame ?? {
            type: "weeks",
            value: "",
        },
    );
    const appointmentAvailabilityText = availabilityText(sendTime, timeFrame);
    const appointmentAvailabilityPrimaryText = availabilityPrimaryText(
        sendTime,
        timeFrame,
    );

    const onAppoinmentAvailabilityChanged = (event: TimeFrameInput) => {
        const { value: selectedValue, type: selectedType } = event;
        const isValidValue =
            selectedValue !== undefined && NUMBER_REGEXP.test(selectedValue);

        setTimeFrame((prev) => ({
            ...prev,
            value: isValidValue ? selectedValue : "",
            type: selectedType,
        }));

        onChangeValue({
            value: isValidValue ? Number(selectedValue) : 0,
            units: selectedType,
        });
    };
    useEffect(() => {
        setTimeFrame(
            initialValue?.timeFrame ?? {
                type: "weeks",
                value: "",
            },
        );
    }, [initialValue]);

    return (
        <SpacingWrapper>
            <StyledSearchSelect>
                <UI.FormFieldWrapper
                    label={
                        <StyledHeader>
                            <UI.Text variant="note" skinny>
                                {"Appointment availability"}
                            </UI.Text>
                        </StyledHeader>
                    }
                >
                    <>
                        <UI.Flex gap="1">
                            <StyledInputContainer>
                                <Input
                                    dimension="medium"
                                    name="time-frame-value"
                                    value={timeFrame.value}
                                    onChange={(event) =>
                                        onAppoinmentAvailabilityChanged({
                                            ...timeFrame,
                                            value: event.target.value,
                                        })
                                    }
                                    pattern="[0-9]*"
                                    placeholder="e.g. 6"
                                />
                            </StyledInputContainer>
                            <StyledSelectContainer>
                                <SingleSelect
                                    id="time-frame-type"
                                    data-testid="time-frame-type"
                                    onValueChange={(
                                        type: TimeFrameInput["type"],
                                    ) => {
                                        onAppoinmentAvailabilityChanged({
                                            ...timeFrame,
                                            type: type,
                                        });
                                    }}
                                    value={timeFrame.type}
                                    name="time-frame-type"
                                >
                                    {TIME_FRAME_OPTIONS.map((option) => (
                                        <SelectItem
                                            key={option.label}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </SelectItem>
                                    ))}
                                </SingleSelect>
                            </StyledSelectContainer>
                        </UI.Flex>
                        <StyledTextComponent>
                            {errors ? (
                                <UI.FormFieldFeedback
                                    text={
                                        <UI.Text
                                            as="span"
                                            skinny
                                            variant="preview"
                                        >
                                            {errors}
                                        </UI.Text>
                                    }
                                    variant="error"
                                />
                            ) : (
                                <UI.Flex alignItems="flex-start">
                                    <UI.Icon name={"Calendar"} size={3} />
                                    <SubTitlePositioning>
                                        <UI.Text variant="preview" skinny>
                                            {appointmentAvailabilityPrimaryText}
                                            <SubTitleText>
                                                <strong>
                                                    {
                                                        appointmentAvailabilityText
                                                    }
                                                </strong>
                                            </SubTitleText>
                                        </UI.Text>
                                    </SubTitlePositioning>
                                </UI.Flex>
                            )}
                        </StyledTextComponent>
                    </>
                </UI.FormFieldWrapper>
            </StyledSearchSelect>
        </SpacingWrapper>
    );
};
