import { useCallback } from "react";

import { api } from "domains/concierge/internal/api/ticket";
import { useConciergeMeta } from "domains/concierge/internal/context";
import {
    ConversationGroupFetcher,
    useConversationGroupQuery,
} from "domains/concierge/internal/hooks/useConversationGroupQuery";
import { ConversationGroupHook } from "domains/concierge/internal/types/ConversationGroupHook";
import { filters } from "domains/concierge/internal/util/conversationGroupFilters";
import { sortOptions } from "domains/concierge/internal/util/conversationGroupSortOptions";

export const usePatientConversationGroupQuery: ConversationGroupHook<{
    status: "Open" | "Done";
    sort: "Newest" | "Oldest";
    patientInternalId?: string;
}> = ({ patientInternalId = "", status, sort }) => {
    const { workspaceId } = useConciergeMeta();

    const fetcher = useCallback<ConversationGroupFetcher>(
        async (continuationToken) => {
            const { updates, response } = await api.getFilteredTicketView({
                workspaceId: workspaceId,
                patientId: patientInternalId,
                isDone: status === "Done",
                ordering: {
                    urgentFirst: status === "Open",
                    mostRecentlyUpdatedFirst: sort === "Newest",
                },
                continuationToken,
            });

            return {
                continuationToken: response.continuationToken ?? undefined,
                updates,
            };
        },
        [workspaceId, patientInternalId, status, sort],
    );

    return useConversationGroupQuery(
        {
            filters: filters.patient({ status, patientInternalId }),
            sortOptions: sortOptions.common({ status, sort }),
            loggingInfo: {
                name: "Patient conversations",
                tags: {
                    statusFilter: status,
                },
            },
            fetcher,
        },
        { enabled: !!patientInternalId },
    );
};
