import { Icon } from "@accurx/design";
import { useCustomIntercomLauncher } from "@accurx/navigation";

import { StyledCollapsibleText } from "../NavigationMenu.styles";
import { ActiveState, LinkVariant } from "../navLink";
import { StyledButton } from "../navLink/NavLink.styles";

export const ChatWithUsButton = ({
    isCollapsed,
}: {
    isCollapsed?: boolean;
}) => {
    useCustomIntercomLauncher();

    return (
        <StyledButton
            variant={LinkVariant.Primary}
            activestate={ActiveState.Inactive}
            onClick={() => window?.Intercom?.("show")}
        >
            <Icon size={4} name="Question" colour={"white"} />
            <StyledCollapsibleText
                isCollapsed={isCollapsed}
                forwardedAs="span"
                variant="label"
                colour="white"
            >
                Chat with us
            </StyledCollapsibleText>
        </StyledButton>
    );
};
