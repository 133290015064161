import { SignalRSubscriptionEvent } from "@accurx/realtime";
import { Event, HubClient } from "@accurx/realtime/hubClient/HubClient";
import { Log } from "@accurx/shared";
import isNil from "lodash/isNil";
import { filter, map } from "rxjs/operators";

import {
    ConversationUpdatesSubscriptionFactory,
    PatientUpdatesSubscriptionFactory,
} from "shared/concierge/conversations/types/component.types";
import { isInstance } from "shared/concierge/conversations/utils";

import { mapTicketItemUpdateToConversationUpdate } from "./mappers/ConversationUpdateMapper";
import { mapTicketPatientToPatientSummary } from "./mappers/PatientMapper";
import { PatientThreadItemUpdate } from "./types/dto.types";

const filterEvent = (
    event:
        | Event<SignalRSubscriptionEvent.OnPatientThreadItemChanged>
        | undefined,
    workspaceId: number,
) => {
    const eventPayload = event?.payload;
    if (isNil(eventPayload)) {
        Log.error(
            "Unable to process undefined/null event from SignalR for 'OnPatientThreadItemChanged'",
        );
        return false;
    }

    if (!isForWorkspace(eventPayload, workspaceId)) {
        return false;
    }

    return true;
};

const isForWorkspace = (
    newPatientThreadItemUpdate: PatientThreadItemUpdate,
    workspaceId: number,
) => {
    return newPatientThreadItemUpdate.organisationId === workspaceId;
};

export const subscribeToTicketUpdates: ConversationUpdatesSubscriptionFactory =
    (hubClient: HubClient, workspaceId: number) => {
        // This is doing some filtering and mapping on the existing subscription.
        // We subscribe to the updates from the hubClient, then we use pipes and filters
        // to first filter for the workspace we want (and undefined events) and then
        // pipe into a mapping function - which produces a whole new observable to subscribe to.
        // There might be some further efficiencies we can put in around storing the filtered observable
        // per workspace but thats something we can look at as a revision.
        return hubClient
            .getSubscription(
                SignalRSubscriptionEvent.OnPatientThreadItemChanged,
            )
            .pipe(
                filter((event) => filterEvent(event, workspaceId)),
                map((event) => {
                    return mapTicketItemUpdateToConversationUpdate(
                        event.payload,
                    );
                }),
                filter(isInstance),
                map((conversation) => [conversation]),
            );
    };

export const subscribeToPatientUpdates: PatientUpdatesSubscriptionFactory = (
    hubClient: HubClient,
    workspaceId: number,
) => {
    // This is doing some filtering and mapping on the existing subscription.
    // We subscribe to the updates from the hubClient, then we use pipes and filters
    // to first filter our for the workspace we want (and undefined events) and then
    // pipe into a mapping function - which produces a whole new observable to subscribe to.
    // There might be some further efficiencies we can put in around storing the filtered observable
    // per workspace but thats something we can look at as a revision.
    return hubClient
        .getSubscription(SignalRSubscriptionEvent.OnPatientThreadItemChanged)
        .pipe(
            filter((event) => filterEvent(event, workspaceId)),
            map((event) => {
                return event.payload.referencedPatients
                    ?.map(mapTicketPatientToPatientSummary)
                    .filter(isInstance);
            }),
            filter(isInstance),
        );
};

export const subscribeToTicketAndPatientUpdates = (
    hubClient: HubClient,
    workspaceId: number,
) => {
    return hubClient
        .getSubscription(SignalRSubscriptionEvent.OnPatientThreadItemChanged)
        .pipe(
            filter((event) => filterEvent(event, workspaceId)),
            map((event) => {
                const conversation = mapTicketItemUpdateToConversationUpdate(
                    event.payload,
                );

                const patients = (event.payload.referencedPatients ?? [])
                    .map(mapTicketPatientToPatientSummary)
                    .filter(isInstance);

                const conversations = conversation ? [conversation] : [];
                return { conversations, patients };
            }),
            filter(
                (event) =>
                    !!event.conversations.length || !!event.patients?.length,
            ),
        );
};
