import { UnreadTicketSummary } from "@accurx/api/ticket";
import { conversationIdMapper } from "domains/concierge/index";
import { mapTicketItemIdentityToConversationItemId } from "domains/concierge/internal/api/shared/mappers/mapTicketItemIdentityToConversationItemId";
import { mapPatientThreadAssigneeToAssigneeSummary } from "domains/concierge/internal/api/ticket/mappers/mapPatientThreadAssigneeToAssigneeSummary";
import { UnreadItemsSummary } from "domains/concierge/schemas/UnreadItemsSummarySchema";
import uniq from "lodash/uniq";

export function mapUnreadTicketSummariesToUnreadItemsSummaries(
    dto: UnreadTicketSummary[],
): UnreadItemsSummary[] {
    return dto.map<UnreadItemsSummary>((summary) => ({
        conversationId: conversationIdMapper.ticket.fromSource(
            summary.identity,
        ),
        patientId: summary.patientId ?? null,
        latestToken: summary.latestToken,
        assignee: mapPatientThreadAssigneeToAssigneeSummary(summary.assignee),
        status: "Open",
        system: "Ticket",
        itemIds: uniq(
            summary.items.map(mapTicketItemIdentityToConversationItemId),
        ).sort(),
    }));
}
