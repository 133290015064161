import { forwardRef } from "react";

import { useCurrentUser } from "@accurx/auth";
import { useAssignee } from "@accurx/concierge/hooks/data/useAssignee";

import { Assignee } from "./AssigneeSelector.types";
import {
    StyledPillButton,
    StyledPillText,
} from "./AssigneeSelectorButton.styles";

export const AssigneeSelectorButton = forwardRef<
    HTMLButtonElement,
    { assignee: Assignee; onClick?: () => void }
>(({ assignee, onClick }, ref) => {
    const assigneeDisplay = useAssignee(assignee);
    const { user: currentUser } = useCurrentUser();
    const isAssigneeCurrentUser =
        assignee.type === "User" && assignee.id === currentUser.accuRxUserId;

    const assigneeName = isAssigneeCurrentUser
        ? "You"
        : assigneeDisplay?.displayName ?? `Unknown ${assignee.type}`;

    return (
        <StyledPillButton
            ref={ref}
            radius="full"
            onClick={onClick}
            data-testid="assign-on-send-button"
            data-userflow-id={"assign-on-send-button"}
        >
            <StyledPillText>{assigneeName}</StyledPillText>
        </StyledPillButton>
    );
});
