import { useRouteMatch } from "react-router-dom";

import { HCP_ROUTES, PATIENT_ROUTES } from "../routes/routes";

const patientRoutesArray = Object.values(PATIENT_ROUTES);
const hcpRoutesArray = Object.values(HCP_ROUTES);

export const useConversationParticipant = ():
    | "WithPatient"
    | "WithHcp"
    | undefined => {
    const withPatientMatch = useRouteMatch(patientRoutesArray);
    const withHcpMatch = useRouteMatch(hcpRoutesArray);

    if (withPatientMatch) return "WithPatient";
    if (withHcpMatch) return "WithHcp";

    return undefined;
};
