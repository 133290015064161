import { useEffect } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useCurrentWorkspace } from "@accurx/auth";
import { Ds, Feedback, Text } from "@accurx/design";
import { useAccurxWebTitle, useNavigationOrigin } from "@accurx/navigation";
import { useHistory } from "react-router";

import { OrganisationHelper } from "../../../../shared/OrganisationHelper";
import { getCreatePagePath, getEditPagePath } from "../../Routes";
import { RemindersList } from "../../components/RemindersList/RemindersList";
import { CreateReminderButton } from "../OverviewPage/components/CreateReminderButton";
import { PageContainer } from "../OverviewPage/components/PageContainer.styles";

/**
 * This page is purposely designed for the new unified navigation
 */
export const ManageRemindersPage = () => {
    useAccurxWebTitle("Manage reminders");

    const track = useAnalytics();
    const navigationOrigin = useNavigationOrigin();
    const workspace = useCurrentWorkspace();
    const { orgId: workspaceId, organisationName } = workspace;
    const history = useHistory();
    const isAdminUser = OrganisationHelper.getIsAdminOrgUser(workspace);

    useEffect(() => {
        track("ReminderManage Page View", { navigationOrigin });
    }, [track, navigationOrigin]);

    return (
        <PageContainer>
            <Ds.Flex justifyContent="space-between">
                <Text as="h1" variant="subtitle" skinny>
                    Manage reminders
                </Text>

                {isAdminUser && (
                    <CreateReminderButton
                        onClick={() => {
                            history.push(getCreatePagePath(workspaceId));
                        }}
                    />
                )}
            </Ds.Flex>
            {!isAdminUser && (
                <Feedback
                    colour="information"
                    title="You can only view appointment reminders"
                >
                    <Text skinny>
                        To make changes, contact the workspace admins
                    </Text>
                </Feedback>
            )}
            <RemindersList
                onCreate={() => {
                    history.push(getCreatePagePath(workspaceId));
                }}
                onEdit={(reminder) => {
                    history.push(getEditPagePath(workspaceId, reminder.id));
                }}
                workspaceId={workspaceId}
                workspaceName={organisationName}
            />
        </PageContainer>
    );
};
