import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

import { CrossOrgBookableOrganisation } from "@accurx/api/appointment";
import * as UI from "@accurx/design";
import { Spinner } from "@accurx/design/dist/ds";
import { SupportUrls } from "@accurx/shared";
import { useDebounce } from "domains/self-book/hooks/useDebounce";
import { useSlotAggregateAvailability } from "domains/self-book/queries/useSlotAggregateAvailability";
import isEqual from "lodash/isEqual";

import {
    AppOrigin,
    useSelfBookConfigurationAnalytics,
} from "../../hooks/useSelfbookAnalytics";
import { useAppointmentsConfigurationQuery } from "../../queries";
import {
    createQueryParamsFromSelfbookData,
    getSelfbookConfigObject,
    transformToOrganisationOptions,
    validateAppointmentAvailabilityForErrors,
    validateChooseClinicianForErrors,
    validateOrganisationForErrors,
    validateSlotTypeForErrors,
} from "../../utils/SelfbookFormComponentUtils";
import {
    AppointmentAvailabilityPeriod,
    AppointmentTypeValue,
    ChooseClinicianValue,
    ClinicianAvailabilityOption,
    ClinicianConfig,
    OrganisationOption,
    SelfBookFormData,
    SelfbookConfigurationPayload,
    SlotAvailabilityOption,
} from "../../utils/types";
import { AppointmentAvailability } from "./AppointmentAvailability";
import { AppointmentType } from "./AppointmentType";
import { ChooseClinician } from "./ChooseClinician";
import { ChooseOrganisation } from "./ChooseOrganisation";
import {
    SpacingWrapper,
    SpinnerPositioning,
    StyledButton,
    StyledDisabledLoadingButton,
    StyledFooter,
    StyledInnerWrapper,
    StyledLinkedText,
    StyledMiddleWrapper,
    StyledOuterWrapper,
    StyledSelfbookForm,
    TelephoneDetailsWrapper,
} from "./SelfbookConfigurationForm.styles";
import { SlotType } from "./SlotType";
import { TelephoneBookingDetails } from "./TelephoneBookingDetails";

type TelephoneFlowProps =
    | { isTelephoneFlow?: never; telephoneFlowOnClickBack?: never }
    | { isTelephoneFlow: boolean; telephoneFlowOnClickBack: () => void };

export type SelfbookFormProps = {
    orgId: number;
    organisationName: string;
    showCrossOrg: boolean;
    showAppointmentAvailability: boolean;
    appOrigin: AppOrigin;
    isTelephoneFlow?: boolean;
    telephoneFlowOnClickBack?: () => void;
    marginBottom?: number | string;
    sendAt: string;
    onSupportLinkClick?: (supportLinkUri: string) => void;
    onSelfbookConfigComplete: (
        selfbookConfigData: SelfbookConfigurationPayload,
        defaultAvailabilityPeriod: AppointmentAvailabilityPeriod,
    ) => void;
    onSelfbookConfigChange?: (
        selfbookConfigData: SelfbookConfigurationPayload,
    ) => void;
} & TelephoneFlowProps;

function LoadingSpinner() {
    return (
        <SpinnerPositioning data-testid="self-book-configuration-form-loading">
            <UI.Spinner />
        </SpinnerPositioning>
    );
}

export const SelfbookConfigurationForm = (props: SelfbookFormProps) => {
    const {
        data: appointmentConfiguration,
        status: appointmentConfigurationStatus,
        error: appointmentConfigurationError,
    } = useAppointmentsConfigurationQuery();

    if (appointmentConfigurationStatus === "loading") {
        return <LoadingSpinner />;
    }

    if (
        !appointmentConfiguration?.selfBookCrossOrgBookableOrganisations &&
        appointmentConfigurationStatus === "error"
    ) {
        return (
            <UI.Feedback
                title="An error occurred while trying to get self book configuration"
                colour="error"
            >
                {appointmentConfigurationError.message}
            </UI.Feedback>
        );
    }

    return (
        <SelfbookConfigurationFormInternal
            {...props}
            selfBookMaxAppointmentAvailabilityWeeks={
                appointmentConfiguration.selfBookMaxAppointmentAvailabilityWeeks
            }
            selfBookCrossOrgBookableOrganisations={
                appointmentConfiguration.selfBookCrossOrgBookableOrganisations
            }
        />
    );
};

type SelfbookFormInternalProps = SelfbookFormProps & {
    selfBookMaxAppointmentAvailabilityWeeks: number;
    selfBookCrossOrgBookableOrganisations: CrossOrgBookableOrganisation[];
};

type LastUpdatedFormField = keyof SelfBookFormData;

const SelfbookConfigurationFormInternal = ({
    orgId,
    organisationName,
    showCrossOrg,
    showAppointmentAvailability,
    appOrigin,
    isTelephoneFlow,
    telephoneFlowOnClickBack,
    marginBottom,
    sendAt,
    onSupportLinkClick,
    onSelfbookConfigComplete,
    onSelfbookConfigChange,
    selfBookMaxAppointmentAvailabilityWeeks,
    selfBookCrossOrgBookableOrganisations,
}: SelfbookFormInternalProps) => {
    const defaultAppointmentAvailabilityPeriod: AppointmentAvailabilityPeriod =
        {
            value: selfBookMaxAppointmentAvailabilityWeeks,
            units: "weeks",
        };

    const analytics = useSelfBookConfigurationAnalytics({ appOrigin });

    const selfBookBookableOrganisations = useMemo(
        () =>
            transformToOrganisationOptions(
                orgId,
                organisationName,
                selfBookCrossOrgBookableOrganisations,
            ),
        [orgId, organisationName, selfBookCrossOrgBookableOrganisations],
    );

    const [selfBookData, setSelfbookData] = useState<SelfBookFormData>({
        appointmentType: "FaceToFace",
        clinicians: { clinicians: [], clinicianType: "AnyClinician" },
        organisation: {
            label: `${organisationName} (Default)`,
            value: `${orgId}`,
            isDefault: true,
        },
        appointmentAvailabilityPeriod: defaultAppointmentAvailabilityPeriod,
    });
    // widht of loading button and primary button should be same
    const buttonGroupRef = useRef<HTMLDivElement>(null);
    const buttonWidth = useRef<number>();

    const debouncedFormData = useDebounce(selfBookData, 250); // 0.25th of a second delay
    const [lastUpdatedField, setLastUpdatedField] =
        useState<LastUpdatedFormField>();

    const [hasButtonClicked, setHasButtonClicked] = useState<boolean>(false);

    const {
        data: slotAggregateAvailabilityData,
        status: slotAggregateAvailabilityStatus,
        error: slotAggregateAvailabilityError,
        isPreviousData,
    } = useSlotAggregateAvailability(
        orgId,
        createQueryParamsFromSelfbookData(debouncedFormData, sendAt),
    );

    const isLoadingAvailability =
        isPreviousData || slotAggregateAvailabilityStatus === "loading";

    // We want to set the width of the loading button to be the same as the actual button
    useLayoutEffect(() => {
        if (buttonGroupRef.current) {
            const { width } = buttonGroupRef.current.getBoundingClientRect();
            buttonWidth.current = width;
        }
    }, [isLoadingAvailability]);

    useEffect(() => {
        const getSlotTypeObj = (
            prevSlotType: SlotAvailabilityOption | undefined,
        ) => {
            return slotAggregateAvailabilityData?.slotTypeOptions.find(
                (item) => {
                    return item.slotName === prevSlotType?.slotName;
                },
            );
        };
        const getClinicianObj = (prevData: ClinicianConfig | undefined) => {
            const previousClinicianData = new Set(
                prevData?.clinicians.map((item) => item.value),
            );
            const updatedClinicianObject =
                slotAggregateAvailabilityData?.clinicianOptions.filter(
                    (options) => previousClinicianData.has(options.value),
                );

            return {
                clinicianType: prevData?.clinicianType ?? "AnyClinician",
                clinicians: updatedClinicianObject ?? [],
            };
        };

        setSelfbookData((prevState) => ({
            ...prevState,
            slotType: getSlotTypeObj(prevState.slotType),
            clinicians: getClinicianObj(prevState.clinicians),
        }));
    }, [slotAggregateAvailabilityData]);

    // Effect triggered on status changes and data changes
    useEffect(() => {
        const selfbookConfigObject: SelfbookConfigurationPayload =
            getSelfbookConfigObject(
                selfBookData,
                isLoadingAvailability,
                showCrossOrg,
                selfBookMaxAppointmentAvailabilityWeeks,
            );

        if (!isLoadingAvailability && onSelfbookConfigChange) {
            onSelfbookConfigChange(selfbookConfigObject);
        }
    }, [
        isLoadingAvailability,
        selfBookData,
        slotAggregateAvailabilityStatus,
        showCrossOrg,
        onSelfbookConfigChange,
        selfBookMaxAppointmentAvailabilityWeeks,
    ]);

    if (
        selfBookCrossOrgBookableOrganisations.length === 0 &&
        slotAggregateAvailabilityStatus === "error"
    ) {
        return (
            <UI.Feedback
                title="An error occurred while trying to get self book availability"
                colour="error"
            >
                {slotAggregateAvailabilityError.message}
            </UI.Feedback>
        );
    }

    const slotOptions = slotAggregateAvailabilityData?.slotTypeOptions ?? [];
    const clinicianOptions =
        slotAggregateAvailabilityData?.clinicianOptions ?? [];

    const onAppointmentTypeChanged = (selectedType: AppointmentTypeValue) => {
        analytics.trackAppointmentTypeOptionSelect({
            appointmentType: selectedType,
        });
        setHasButtonClicked(false);
        const updatedSelfbookData: SelfBookFormData = {
            ...selfBookData,
            appointmentType: selectedType,
            slotType: undefined,
            clinicians: {
                clinicianType: "AnyClinician",
                clinicians: [],
            },
            appointmentAvailabilityPeriod: defaultAppointmentAvailabilityPeriod,
        };
        setSelfbookData(updatedSelfbookData);
        setLastUpdatedField("appointmentType");
    };
    const onSlotTypeChanged = (selected: SlotAvailabilityOption) => {
        const slotTypeError = validateSlotTypeForErrors(selected, false);

        analytics.trackSlotTypeMenuItemClick({
            slotName: selected.slotName,
            error: slotTypeError,
        });

        const updatedSelfbookData: SelfBookFormData = {
            ...selfBookData,
            slotType: selected,
        };

        setSelfbookData(updatedSelfbookData);
        setLastUpdatedField("slotType");
    };

    const onAppointmentAvailabilityChanged = (
        selectedType: AppointmentAvailabilityPeriod,
    ) => {
        const updatedSelfbookData: SelfBookFormData = {
            ...selfBookData,
            appointmentAvailabilityPeriod: selectedType,
        };
        setSelfbookData(updatedSelfbookData);
        setLastUpdatedField("appointmentAvailabilityPeriod");
    };

    const onSelfbookFormConfigButtonClick = () => {
        // to trigger validation on button click
        setHasButtonClicked(true);

        const selfBookConfig = getSelfbookConfigObject(
            selfBookData,
            isLoadingAvailability,
            showCrossOrg,
            selfBookMaxAppointmentAvailabilityWeeks,
        );
        if (selfBookConfig.validationSuccess) {
            onSelfbookConfigComplete(
                selfBookConfig,
                defaultAppointmentAvailabilityPeriod,
            );
        }
    };

    const onOrganisationChange = (selected: OrganisationOption) => {
        const updatedSelfbookData: SelfBookFormData = {
            ...selfBookData,
            organisation: selected,
            slotType: undefined,
            clinicians: {
                clinicianType: "AnyClinician",
                clinicians: [],
            },
            appointmentAvailabilityPeriod: defaultAppointmentAvailabilityPeriod,
        };
        setHasButtonClicked(false);
        setSelfbookData(updatedSelfbookData);
        setLastUpdatedField("organisation");
    };

    const onClinicianChange = (
        selected: ClinicianAvailabilityOption[] | undefined,
        chooseClinicianType: ChooseClinicianValue,
    ) => {
        const clinicianObject = {
            clinicians: selected ?? [],
            clinicianType: chooseClinicianType,
        };
        const updatedSelfbookData: SelfBookFormData = {
            ...selfBookData,
            clinicians: clinicianObject,
        };
        setSelfbookData(updatedSelfbookData);
        if (!isEqual(selfBookData.clinicians?.clinicians, selected)) {
            setLastUpdatedField("clinicians");
        }
    };

    const showLoadingForField = (field: LastUpdatedFormField): boolean =>
        slotAggregateAvailabilityStatus === "loading" ||
        (isPreviousData && lastUpdatedField !== field);
    const renderConfigForm = (): JSX.Element => {
        const hasNoSlotAvailability = selfBookData.slotType?.count === 0;
        return (
            <>
                {showCrossOrg && (
                    <ChooseOrganisation
                        onChange={onOrganisationChange}
                        options={selfBookBookableOrganisations}
                        initialOption={selfBookData.organisation}
                        errors={validateOrganisationForErrors(
                            slotAggregateAvailabilityStatus,
                        )}
                    />
                )}
                <AppointmentType
                    selectedAppointmentType={selfBookData.appointmentType}
                    onChange={(event) =>
                        onAppointmentTypeChanged(
                            event.target.value as AppointmentTypeValue,
                        )
                    }
                />

                <SlotType
                    onChange={onSlotTypeChanged}
                    initialOption={selfBookData.slotType}
                    options={slotOptions}
                    errors={validateSlotTypeForErrors(
                        selfBookData.slotType,
                        hasButtonClicked,
                    )}
                    showLoading={showLoadingForField("slotType")}
                />
                {selfBookData.organisation.isDefault && (
                    <ChooseClinician
                        onChange={onClinicianChange}
                        initialOption={selfBookData.clinicians}
                        options={clinicianOptions}
                        errors={validateChooseClinicianForErrors(
                            selfBookData.clinicians,
                            hasButtonClicked,
                        )}
                        showLoading={showLoadingForField("clinicians")}
                    />
                )}

                {showAppointmentAvailability &&
                    selfBookData.organisation.isDefault && (
                        <AppointmentAvailability
                            initialValue={{
                                timeFrame: {
                                    type: selfBookData
                                        .appointmentAvailabilityPeriod.units,
                                    value: selfBookData.appointmentAvailabilityPeriod.value.toString(),
                                },
                            }}
                            onChangeValue={onAppointmentAvailabilityChanged}
                            sendTime={sendAt}
                            errors={validateAppointmentAvailabilityForErrors(
                                selfBookData.appointmentAvailabilityPeriod,
                                selfBookMaxAppointmentAvailabilityWeeks,
                                hasNoSlotAvailability &&
                                    lastUpdatedField ===
                                        "appointmentAvailabilityPeriod",
                            )}
                        />
                    )}
            </>
        );
    };

    const getPrimaryButtonText = (): string => {
        return selfBookData.appointmentType === "FaceToFace" || isTelephoneFlow
            ? "Add Self-Book invite"
            : "Save and next";
    };

    return (
        <StyledOuterWrapper>
            <StyledMiddleWrapper>
                <StyledInnerWrapper>
                    <StyledSelfbookForm>
                        {isTelephoneFlow ? (
                            <TelephoneDetailsWrapper>
                                <TelephoneBookingDetails />
                            </TelephoneDetailsWrapper>
                        ) : (
                            renderConfigForm()
                        )}
                        <SpacingWrapper>
                            <UI.Link
                                href={SupportUrls.BatchSelfBookProblem}
                                openInNewTab
                                onClick={() => {
                                    analytics.trackBookingResourceLinkClick({
                                        resourceLink:
                                            SupportUrls.BatchSelfBookProblem,
                                    });
                                    onSupportLinkClick &&
                                        onSupportLinkClick(
                                            SupportUrls.BatchSelfBookProblem,
                                        );
                                }}
                            >
                                <StyledLinkedText>
                                    <UI.Link.Text text="Having problems?" />
                                </StyledLinkedText>
                                <UI.Link.Icon />
                            </UI.Link>
                        </SpacingWrapper>
                    </StyledSelfbookForm>
                    <StyledFooter>
                        {isTelephoneFlow && (
                            <UI.Button
                                dimension="small"
                                onClick={telephoneFlowOnClickBack}
                                icon={{
                                    name: "ArrowTail",
                                    rotation: "left",
                                    style: "Line",
                                    title: "Close",
                                }}
                                theme="transparent"
                                text="Back"
                            />
                        )}

                        <span ref={buttonGroupRef}>
                            {isLoadingAvailability ? (
                                <StyledDisabledLoadingButton
                                    aria-label="SendorNext"
                                    width={buttonWidth.current}
                                    disabled={isLoadingAvailability}
                                >
                                    <Spinner size="xsmall" />
                                </StyledDisabledLoadingButton>
                            ) : (
                                <StyledButton
                                    theme="primary"
                                    dimension="medium"
                                    text={getPrimaryButtonText()}
                                    onClick={onSelfbookFormConfigButtonClick}
                                    marginBottom={marginBottom}
                                />
                            )}
                        </span>
                    </StyledFooter>
                </StyledInnerWrapper>
            </StyledMiddleWrapper>
        </StyledOuterWrapper>
    );
};
