import { ComponentPropsWithRef, forwardRef } from "react";

import { Ds } from "@accurx/design";
import { PopoverItemLink } from "@accurx/navigation";

export const PopoverExternalLink = forwardRef<
    HTMLAnchorElement,
    ComponentPropsWithRef<"a">
>(({ children, ...props }, forwardedRef) => {
    return (
        <PopoverItemLink
            ref={forwardedRef}
            to={{ pathname: props.href }}
            target="_blank"
            rel="noopener noreferrer"
            style={{ justifyContent: "space-between" }}
            {...props}
        >
            <Ds.Flex gap="1">{children}</Ds.Flex>
            <Ds.Icon name="OpenWindow" size="small" color="metal" />
        </PopoverItemLink>
    );
});
