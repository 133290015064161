import * as UI from "@accurx/design";
import { Link } from "domains/inbox/components/Link/Link";
import { ItemState } from "domains/inbox/hooks/util/useUpdatingFeed";
import styled, { css, keyframes } from "styled-components";

import {
    FEED_ANIMATION_FUNCTION,
    FEED_ANIMATION_SPEED,
} from "../ConversationList/components/ConversationFeed/ConversationFeed.styles";

export const CONVERSATION_PREVIEW_HEIGHT = 112;
export const EXTENDED_CONVERSATION_PREVIEW_HEIGHT = 126;
export const COLLAPSED_CONVERSATION_PREVIEW_HEIGHT = 44;

const entering = keyframes`
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
        transform: translateX(-20%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
`;

const leaving = keyframes`
    0% {
        opacity: 1;
        max-height: ${COLLAPSED_CONVERSATION_PREVIEW_HEIGHT}px;
    }
    100% {
        opacity: 0;
        max-height: 0px;
    }
`;

const suspended = keyframes`
    0% {
        max-height: ${CONVERSATION_PREVIEW_HEIGHT}px;
    }
    100% {
        max-height: ${COLLAPSED_CONVERSATION_PREVIEW_HEIGHT}px;
    }
`;

export const StyledActionsContainer = styled(UI.Item)<{
    $isOpen: boolean;
}>`
    opacity: ${({ $isOpen }) => ($isOpen ? "100%" : "0")};
    width: ${({ $isOpen }) => ($isOpen ? "24px" : "0")};
    height: 24px;

    // Always display on touch devices
    @media (hover: none) {
        opacity: 100%;
        width: 24px;
    }

    & [data-radix-popper-content-wrapper] {
        /* A hack to match the widths of overflowing tooltips to the conversation preview, plus a gutter */
        /* The "!important"s are required to override some radix styling */
        white-space: initial !important;
        min-width: initial !important;
    }
`;

export const StyledContainer = styled.div<{
    $state: ItemState;
    $isActive: boolean;
    $height: number;
}>`
    width: 100%;
    overflow: hidden;

    .conversation-item {
        position: relative;
        height: ${({ $height }) => $height}px;

        &:hover {
            border-radius: ${UI.Tokens.SIZES[0.5]};
            border: 1px solid ${UI.Tokens.COLOURS.primary.blue[100]};

            .text-item,
            .preview-summary * {
                color: ${UI.Tokens.COLOURS.primary.blue[100]};
            }
        }

        &:focus-within:not(:has(button:focus)) {
            outline: solid 3px ${UI.Tokens.COLOURS.primary.blue[50]};
            border-radius: ${UI.Tokens.SIZES[0.5]};
            border: 1px solid ${UI.Tokens.COLOURS.primary.blue[100]};
            background-color: ${UI.Tokens.COLOURS.primary.blue[10]};

            .conversation-preview-badge {
                background-color: ${UI.Tokens.COLOURS.primary.blue[25]};
            }
        }

        @supports not selector(:has(*)) {
            /* Fallback for when :has() is unsupported */

            &:focus-within {
                outline: solid 3px ${UI.Tokens.COLOURS.primary.blue[50]};
                border-radius: ${UI.Tokens.SIZES[0.5]};
                border: 1px solid ${UI.Tokens.COLOURS.primary.blue[100]};
                background-color: ${UI.Tokens.COLOURS.primary.blue[10]};

                .conversation-preview-badge {
                    background-color: ${UI.Tokens.COLOURS.primary.blue[25]};
                }
            }
        }

        &:focus:not(:hover),
        &:active {
            .text-item,
            .preview-summary * {
                color: ${UI.Tokens.COLOURS.primary.blue[130]};
            }
        }

        &:active {
            background-color: ${UI.Tokens.COLOURS.primary.blue[10]};

            .conversation-preview-badge {
                background-color: ${UI.Tokens.COLOURS.primary.blue[25]};
            }
        }

        ${({ $isActive }) =>
            $isActive &&
            `
            border: 1px solid ${UI.Tokens.COLOURS.primary.blue[100]};
            background-color: ${UI.Tokens.COLOURS.primary.blue[10]};


            .conversation-preview-badge {
                background-color: ${UI.Tokens.COLOURS.primary.blue[25]};
            }

            .text-item,
            .preview-summary *  {
                color: ${UI.Tokens.COLOURS.primary.blue[130]};
            }
        `}
    }

    @media (prefers-reduced-motion: no-preference) {
        ${({ $state }) =>
            $state === "entering" &&
            css`
                animation: ${entering} ${FEED_ANIMATION_SPEED}
                    ${FEED_ANIMATION_FUNCTION} forwards;
            `}

        ${({ $state }) =>
            $state === "leaving" &&
            css`
                animation: ${leaving} ${FEED_ANIMATION_SPEED}
                    ${FEED_ANIMATION_FUNCTION} forwards;
                overflow-y: hidden;
            `}

        ${({ $state }) =>
            $state === "suspended" &&
            css`
                animation: ${suspended} ${FEED_ANIMATION_SPEED}
                    ${FEED_ANIMATION_FUNCTION} forwards;
                overflow-y: hidden;

                .conversation-item {
                    border-color: ${UI.Tokens.COLOURS.greyscale.cloud};

                    &:hover {
                        border: 1px solid ${UI.Tokens.COLOURS.greyscale.cloud};
                    }
                }
            `}
    }

    @media (prefers-reduced-motion: reduce) {
        ${({ $state }) =>
            $state === "leaving" &&
            css`
                opacity: 0;
                max-height: 0px;
                overflow-y: hidden;
            `}
        ${({ $state }) =>
            $state === "suspended" &&
            css`
                opacity: 1;
                max-height: ${COLLAPSED_CONVERSATION_PREVIEW_HEIGHT}px;
                overflow-y: hidden;

                .conversation-item {
                    border-color: ${UI.Tokens.COLOURS.greyscale.cloud};

                    &:hover {
                        border: 1px solid ${UI.Tokens.COLOURS.greyscale.cloud};
                    }
                }
            `}
    }
`;

export const StyledLink = styled(Link)`
    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &:hover {
        text-decoration: none;
    }

    &:focus {
        outline: none;
    }

    &:hover
        ${StyledActionsContainer},
        &:focus-within
        ${StyledActionsContainer} {
        opacity: 100%;
        width: 24px;
    }
`;

export const StyledHeader = styled(UI.Flex)`
    white-space: nowrap;
`;

export const StyledFooter = styled(UI.Flex)`
    white-space: nowrap;

    margin-top: ${UI.Tokens.SIZES[1]};
`;

export const StyledBadgesGroup = styled(UI.Flex)`
    overflow: hidden;

    & [data-radix-popper-content-wrapper] {
        /* A hack to match the widths of overflowing tooltips to the conversation preview, plus a gutter */
        /* The "!important"s are required to override some radix styling */
        white-space: initial !important;
        min-width: initial !important;
    }
`;
