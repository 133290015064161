import React, { ChangeEvent, useEffect, useState } from "react";

import { FeatureName } from "@accurx/auth";
import { Card, Feedback, Link, Text, Tokens } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { SupportUrls, calculateFragments } from "@accurx/shared";
import { shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { ChainAnalyticsTracker } from "app/analytics";
import {
    fetchAccurxMessageTemplates,
    fetchOrgMessageTemplates,
    fetchUserMessageTemplates,
    updateMessage,
    updateSelectedBatchOptionToSaveToRecord,
    updateSelectedTemplateCanReply,
    updateSelectedTemplateName,
    updateSelectedTemplateOwner,
    updateSelectedTemplateSnomed,
    updateSelfBookSlot,
} from "app/batchMessage/gp/BatchMessage.actions";
import {
    StyledLayoutWithFooter,
    StyledTextareaAutosize,
} from "app/batchMessage/gp/BatchMessage.styles";
import {
    BatchSnomedCode,
    BatchType,
    MessageTemplate,
    MessageTemplateOwner,
} from "app/batchMessage/gp/BatchMessage.types";
import {
    BatchRoute,
    findBatchRoute,
    getSelfBookLinkExpiryDays,
} from "app/batchMessage/gp/BatchMessage.utils";
import { updateBatchDeclineSettings } from "app/batchMessage/gp/ComposeDeclineSettings.actions";
import { BatchMessageHeader } from "app/batchMessage/gp/components/BatchMessageHeader";
import { ComposeMsgInfo } from "app/batchMessage/gp/components/ComposeMsgInfo";
import {
    ComposeOptionToSaveToRecord,
    useSetSaveBatchToRecord,
} from "app/batchMessage/gp/components/ComposeOptionToSaveToRecord";
import {
    AVG_PATIENT_LENGTH,
    DECLINE_SETTINGS_SUPPORT_URL,
    DEFAULT_TEMPLATE_HEADER,
    FOOTER_SELFBOOK,
    FOOTER_SELFBOOK_ACTION_TYPE_REPLACE,
    FOOTER_SELFBOOK_LINK_EXPIRY_REPLACE,
    PLACEHOLDER_LINK_AUTO,
    PLACEHOLDER_LINK_PATIENT_PORTAL,
} from "app/batchMessage/gp/components/compose/BatchMessageCompose.constants";
import { Z_INDEX } from "app/batchMessage/gp/components/compose/BatchMessageCompose.styles";
import { MessageErrorType } from "app/batchMessage/gp/components/compose/BatchMessageCompose.types";
import { isValidMessage } from "app/batchMessage/gp/components/compose/BatchMessageCompose.utils";
import { ComposeTemplateDropdown } from "app/batchMessage/gp/components/compose/ComposeTemplateDropdown";
import { SnomedCodeSelect } from "app/batchMessage/gp/components/snomedCodes/SnomedCodeSelect";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { StepsFooter } from "app/sharedComponents/footer/StepsFooter";
import { UpdatingStatus } from "shared/LoadingStatus";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

import { ComposeSlotDropdown } from "../../../components/ComposeSlotDropdown";
import { MessageComposeWrapper } from "../components/MessageComposeWrapper";
import ComposeDeclineSettings, {
    DeclineSettingsWithErrors,
} from "./ComposeDeclineSettings";

const getMessageContent = ({
    linkExpiryDays,
    messageBody,
    declineSettings,
    practiceName,
}: {
    linkExpiryDays: number;
    messageBody: string;
    declineSettings: DeclineSettingsWithErrors;
    practiceName: string;
}) => {
    const footerContentWithReplaceTokens = FOOTER_SELFBOOK;

    const linkExpiryTime = `${linkExpiryDays} days`;
    const footerContentLinkExpiryReplaced =
        footerContentWithReplaceTokens.replace(
            FOOTER_SELFBOOK_LINK_EXPIRY_REPLACE,
            linkExpiryTime,
        );

    const actionType = declineSettings?.isToggled ? "respond" : "book";
    const footerContentActionTypeReplaced =
        footerContentLinkExpiryReplaced.replace(
            FOOTER_SELFBOOK_ACTION_TYPE_REPLACE,
            actionType,
        );

    const messageFooterContent =
        footerContentActionTypeReplaced + PLACEHOLDER_LINK_PATIENT_PORTAL;

    const newCount =
        messageBody.length +
        messageFooterContent.length +
        AVG_PATIENT_LENGTH +
        practiceName.length +
        1; // new line character before practice name

    return {
        footer: messageFooterContent,
        body: messageBody,
        totalLength: newCount,
        linkExpiryTime,
    };
};

export const ComposeSelfbook = (): JSX.Element => {
    useAccurxWebTitle("Write a batch self-book message");

    const dispatch = useDispatch();
    const history = useHistory();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    // Store
    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );
    const practiceName = useAppSelector(
        ({ practices }: ApplicationState) =>
            practices?.items.find(
                (x) => x.id.toString() === practices?.selectedPractice,
            )?.name ?? "",
    );
    const initialSelectedTemplateCanReply = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedTemplateCanReply,
    );
    const initialSelectedTemplateOwner = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedTemplateOwner,
        shallowEqual,
    );

    const {
        patientMessage,
        selectedTemplateName,
        selectedTemplateSnomedCode,
        selectedSlotType,
    } = useAppSelector(({ batchMessage }) => batchMessage);

    const {
        gettingUserTemplatesStatus,
        gettingOrgTemplatesStatus,
        gettingAccurxTemplatesStatus,
        snomedCodes,
    } = useAppSelector(({ batchMessage }) => batchMessage, shallowEqual);

    // States: to be saved to redux
    const [messageBody, setMessageBody] = useState(patientMessage);
    const [selectedTemplateCanReply, setSelectedTemplateCanReply] = useState(
        initialSelectedTemplateCanReply,
    );
    const [selectedTemplateDisplay, setSelectedTemplateDisplay] = useState(
        selectedTemplateName || DEFAULT_TEMPLATE_HEADER,
    );
    const [selectedTemplateOwner, setSelectedTemplateOwner] = useState(
        initialSelectedTemplateOwner,
    );
    const [
        selectedTemplateSnomedConceptId,
        setSelectedTemplateSnomedConceptId,
    ] = useState(selectedTemplateSnomedCode);
    const [selectedSlot, setSelectedSlot] = useState(selectedSlotType);

    // States: local only
    const [validMessageBody, setValidMessageBody] = useState(false);
    const [messageErrorMessage, setMessageErrorMessage] = useState("");
    const [messageErrorType, setMessageErrorType] = useState(
        MessageErrorType.None,
    );

    // Feature flag
    const isFragmentLimitBatchSelfBookEnabled = useIsFeatureEnabled(
        FeatureName.FragmentLimitBatchSelfBook,
    );
    // Decline Settings
    const [declineSettings, setDeclineSettings] =
        useState<DeclineSettingsWithErrors>({
            isToggled: false,
            confirmationText: "",
            snomedConceptId: "",
            errors: { confirmationText: false, snomedConceptId: false },
        });

    const isSelfBook7DaysExpiryLinks = useIsFeatureEnabled(
        FeatureName.SelfBook7DaysExpiryLinks,
    );
    const linkExpiryDays = getSelfBookLinkExpiryDays(
        isSelfBook7DaysExpiryLinks,
    );

    // Load 3 types of templates for the dropdown

    useEffect(() => {
        if (
            gettingAccurxTemplatesStatus === UpdatingStatus.Initial &&
            practiceId
        ) {
            dispatch(fetchAccurxMessageTemplates(practiceId));
        }
    }, [dispatch, practiceId, gettingAccurxTemplatesStatus]);

    useEffect(() => {
        if (
            gettingOrgTemplatesStatus === UpdatingStatus.Initial &&
            practiceId
        ) {
            dispatch(fetchOrgMessageTemplates(practiceId));
        }
    }, [dispatch, practiceId, gettingOrgTemplatesStatus]);

    useEffect(() => {
        if (
            gettingUserTemplatesStatus === UpdatingStatus.Initial &&
            practiceId
        ) {
            dispatch(fetchUserMessageTemplates(practiceId));
        }
    }, [dispatch, practiceId, gettingUserTemplatesStatus]);

    useEffect(() => {
        checkMessageTextAndSetState(messageBody);
    });

    useEffect(() => {
        ChainAnalyticsTracker.trackBatchPatientMessageReviewPageView({
            ...analyticsLoggedInProps,
            origin: history.location.pathname,
        });
    }, [analyticsLoggedInProps, history.location.pathname]);

    const updateMessageText = (e: ChangeEvent<HTMLTextAreaElement>) => {
        checkAndSetMessageText(e.target.value);
    };

    const checkAndSetMessageText = (messageText: string) => {
        checkMessageTextAndSetState(messageText);
        setMessageBody(messageText);
    };

    const checkMessageTextAndSetState = (textToCheck: string) => {
        const { totalLength } = getMessageContent({
            linkExpiryDays,
            messageBody: textToCheck,
            declineSettings,
            practiceName,
        });
        const validation = isValidMessage(textToCheck, totalLength, true);
        setValidMessageBody(validation.validMessageBody);
        setMessageErrorMessage(validation.messageErrorMessage);
        setMessageErrorType(validation.messageErrorType);
    };

    const checkAndSetSnomedCode = (codes: BatchSnomedCode[]) => {
        if (codes !== null && codes.length > 0) {
            const snomedCode = snomedCodes.find(
                (code) => code.conceptId === codes[0].conceptId,
            );
            if (snomedCode !== undefined) {
                setSelectedTemplateSnomedConceptId(snomedCode.conceptId);
                return;
            }
        }
        setSelectedTemplateSnomedConceptId("");
    };

    const { shouldSaveBatchToRecord, setShouldSaveBatchToRecord } =
        useSetSaveBatchToRecord();

    const selectTemplate = (
        template: MessageTemplate,
        templateOwner: MessageTemplateOwner,
    ) => {
        setSelectedTemplateDisplay(template.title);

        setSelectedTemplateOwner(templateOwner);
        setSelectedTemplateCanReply(template.allowReplyByDefault);
        checkAndSetMessageText(template.body);
        checkAndSetSnomedCode(template.snomedCodes);
    };

    const handleBack = () => {
        ChainAnalyticsTracker.trackBatchBackClick({
            ...analyticsLoggedInProps,
            origin: history.location.pathname,
            isTrustFlow: false,
        });
    };

    // N.B: We need to update Redux store with local state, because <SnomedCodeSelect> is coupled with the global state
    useEffect(() => {
        if (selectedSlot.type !== "") {
            dispatch(updateSelfBookSlot(selectedSlot));
        }
    }, [dispatch, selectedSlot]);

    const handleSave = () => {
        // TODO: This needs to be refactored into one action
        dispatch(updateMessage(messageBody));
        dispatch(updateSelectedTemplateCanReply(selectedTemplateCanReply));
        dispatch(
            updateSelectedTemplateName(
                selectedTemplateDisplay === DEFAULT_TEMPLATE_HEADER
                    ? ""
                    : selectedTemplateDisplay,
            ),
        );
        dispatch(updateSelectedTemplateOwner(selectedTemplateOwner));
        dispatch(updateSelectedTemplateSnomed(selectedTemplateSnomedConceptId));
        dispatch(
            updateSelectedBatchOptionToSaveToRecord(shouldSaveBatchToRecord),
        );

        const hasDeclineSettingsPayload =
            declineSettings?.confirmationText &&
            declineSettings?.snomedConceptId;

        let hasDeclineSettingsErrors = false;
        const invalidConfirmationText =
            declineSettings?.confirmationText === "";
        const invalidSnomed = declineSettings?.snomedConceptId === "";
        if (invalidConfirmationText || invalidSnomed) {
            hasDeclineSettingsErrors = true;
            setDeclineSettings({
                ...declineSettings,
                errors: {
                    snomedConceptId: invalidSnomed,
                    confirmationText: invalidConfirmationText,
                },
            });
        }

        if (hasDeclineSettingsPayload) {
            dispatch(
                updateBatchDeclineSettings({
                    confirmationText: declineSettings.confirmationText,
                    snomedConceptId: declineSettings.snomedConceptId,
                }),
            );
        }

        if (declineSettings.isToggled && !hasDeclineSettingsErrors) {
            history.push(routes.forward);
        }

        if (!declineSettings.isToggled) {
            history.push(routes.forward);
        }
    };

    const trackClickedLink = (resourceName: string) => {
        ChainAnalyticsTracker.trackBatchResourceClick({
            ...analyticsLoggedInProps,
            origin: history.location.pathname,
            batchResourceName: resourceName,
        });
    };

    const routes = findBatchRoute(BatchRoute.COMPOSE);

    const messageContent = getMessageContent({
        linkExpiryDays,
        messageBody,
        declineSettings,
        practiceName,
    });

    /**
     * calculate fragments by passing in the body of the
     * batch message as well as padding it to length we assume it is
     * accounting for things like practice name and message footer
     */
    const fragmentCount = calculateFragments(
        `${messageBody}${"a".repeat(
            Math.abs(messageContent.totalLength - messageBody.length),
        )}`,
    );

    return (
        <StyledLayoutWithFooter>
            <Breadcrumb title="Batch Messaging Self-book" />
            <div className="row mb-4">
                <div className="col-12 col-lg-8 offset-lg-2 text-center mb-4">
                    <BatchMessageHeader
                        title={"Write a Self-Book message"}
                        stepNumber={1}
                    />
                </div>
                <div className="col-12 col-lg-6 offset-lg-3">
                    <Card
                        isElevated={false}
                        spacing={1.5}
                        header={
                            <Text as="h2" variant="subtitle" skinny>
                                Invite details
                            </Text>
                        }
                    >
                        <ComposeSlotDropdown
                            selectedSlot={selectedSlot}
                            setSelectedSlot={setSelectedSlot}
                        />
                        <div>
                            <Feedback
                                data-testid="selfBookBatchSlotTypeFeedback"
                                colour="information"
                                title="If your slot type list is not up-to-date, refresh the page after about 30 minutes."
                            >
                                <Text skinny>
                                    Having problems? Make sure{" "}
                                    <Link
                                        href={SupportUrls.BatchSelfBookProblem}
                                        openInNewTab={true}
                                        text="you've checked a few things"
                                        onClick={() => {
                                            trackClickedLink(
                                                SupportUrls.BatchSelfBookProblem,
                                            );
                                        }}
                                    />
                                </Text>
                            </Feedback>
                        </div>
                        <div className="mt-4">
                            <ComposeTemplateDropdown
                                isSelfBook
                                selectTemplate={selectTemplate}
                                selectedTemplateCanReply={
                                    selectedTemplateCanReply
                                }
                                selectedTemplateDisplay={
                                    selectedTemplateDisplay
                                }
                            />
                        </div>
                        <Text
                            as="label"
                            variant="label"
                            props={{ htmlFor: "message-body" }}
                        >
                            Invite message
                        </Text>
                        <Text skinny>
                            To reduce the cost for your organisation, Self-Book
                            messages can only be sent in 2 fragments (below 307
                            characters).{" "}
                        </Text>
                        <Text>
                            <Link
                                href={SupportUrls.IntroToFragments}
                                openInNewTab
                                onClick={() =>
                                    ChainAnalyticsTracker.trackBatchResourceClick(
                                        {
                                            ...analyticsLoggedInProps,
                                            origin: history.location.pathname,
                                            batchResourceName:
                                                SupportUrls.IntroToFragments,
                                        },
                                    )
                                }
                            >
                                <Link.Text text="Learn about fragments" />
                                <Link.Icon />
                            </Link>
                        </Text>
                        <MessageComposeWrapper>
                            <StyledTextareaAutosize
                                id="message-body"
                                style={{
                                    outline: "none",
                                    color: Tokens.COLOURS.greyscale.zinc,
                                    marginTop: `${Tokens.SIZES[1]}`,
                                    marginBottom: `${Tokens.SIZES[1]}`,
                                }}
                                value={messageContent.body}
                                onChange={updateMessageText}
                                placeholder={"Please enter a message"}
                                data-testid="message-body"
                                disabled={false}
                                minRows={4}
                            />
                            {!!selectedSlot.availability && (
                                <Text
                                    skinny
                                    variant="body"
                                    props={{ "data-testid": "message-footer" }}
                                >
                                    {messageContent.footer.replace(
                                        PLACEHOLDER_LINK_PATIENT_PORTAL,
                                        PLACEHOLDER_LINK_AUTO,
                                    )}
                                </Text>
                            )}
                        </MessageComposeWrapper>
                        <ComposeMsgInfo
                            characterCount={messageContent.totalLength}
                            fragmentCount={fragmentCount}
                            messageErrorMessage={messageErrorMessage}
                            messageErrorType={messageErrorType}
                            isSelfBook={isFragmentLimitBatchSelfBookEnabled}
                        />
                        <div className="mb-4">
                            <ComposeOptionToSaveToRecord
                                shouldSaveBatchToRecord={
                                    shouldSaveBatchToRecord
                                }
                                setShouldSaveBatchToRecord={
                                    setShouldSaveBatchToRecord
                                }
                                batchType={BatchType.SELFBOOK}
                            />
                        </div>
                        <SnomedCodeSelect
                            id="snomed-dropdown"
                            batchSnomedSelect="Booking - Invite"
                            // TODO: (optional) should be displayed as non-bold copy similar to how it's done on SMS page
                            label="Invite SNOMED code (optional)"
                            selectedTemplateDisplay={selectedTemplateDisplay}
                            selectedSnomedCode={selectedTemplateSnomedConceptId}
                            setSelectedTemplateSnomedConceptId={
                                setSelectedTemplateSnomedConceptId
                            }
                        />
                    </Card>

                    <div data-testid="decline-settings" className="mt-5">
                        <Card
                            isElevated={false}
                            spacing={1.5}
                            header={
                                <>
                                    <Text as="h2" variant="subtitle" skinny>
                                        Decline settings
                                    </Text>
                                    <Text skinny>
                                        Allow patients to decline the
                                        appointment, see{" "}
                                        <Link
                                            onClick={() => {
                                                trackClickedLink(
                                                    DECLINE_SETTINGS_SUPPORT_URL,
                                                );
                                            }}
                                            openInNewTab={true}
                                            iconName="OpenWindow"
                                            text="how decline works"
                                            href={DECLINE_SETTINGS_SUPPORT_URL}
                                        />
                                    </Text>
                                </>
                            }
                        >
                            <ComposeDeclineSettings
                                declineSettings={declineSettings}
                                setDeclineSettings={setDeclineSettings}
                            />
                        </Card>
                    </div>
                    {/* Temp fix for <SnomedCodeSelect> while we fix the design library.
                    The <select> component should take in account screen collision and make sure there's enough space to display content in full */}
                    <div style={{ marginBottom: "16rem" }}></div>
                </div>

                <StepsFooter
                    backText={"Back"}
                    backLink={routes.back}
                    backClickFunction={handleBack}
                    forwardText={"Add a patient list"}
                    forwardClickFunction={handleSave}
                    disabled={!validMessageBody || !selectedSlot.availability}
                    zIndex={Z_INDEX.footer}
                />
            </div>
        </StyledLayoutWithFooter>
    );
};

export default ComposeSelfbook;
