import { ChangeEvent, MouseEvent, useRef } from "react";

import * as UI from "@accurx/design";
import { DropdownItem } from "domains/message-component/components/DropdownItem";
import { useCompose } from "domains/message-component/context";
import {
    Attachment,
    AttachmentSource,
} from "domains/message-component/reducer.types";
import { getMostSignificantAttachmentUploadErrorMessage } from "domains/message-component/utils/getMostSignificantAttachmentUploadError";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

import { getIsFileLimitReached } from "../../../getIsFileLimitReached";
import {
    MAX_ATTACHMENT_FILE_SIZE_MEGABYTES,
    SUPPORTED_FILE_EXTENSIONS,
} from "../../../upload.constants";
import { OnUpload, useUploadFromDesktop } from "../../../useUploadFromDesktop";
import { StyledInvisibleFileInput } from "./AttachFileFromDesktopButton.styles";

type AttachFileFromDesktopButtonProps = {
    /** Function to handle upload */
    onUpload: OnUpload;
    /** What to do when user selects a file or cancels */
    onChange: () => void;
    /** Whether the user is able to attach a file to compose
     * If nothing is passed, it will be disabled based on
     * max attachment limit.
     */
    isDisabled?: boolean;
    /**
     * What to do right after the user clicks on the button
     */
    onClick?: (e: MouseEvent<HTMLInputElement>) => void;
};

type AttachFileFromDesktopButtonBySourceProps = {
    /**
     * Determines which API to call
     */
    source: AttachmentSource;
} & Omit<AttachFileFromDesktopButtonProps, "onUpload">;

export const AttachFileFromDesktopButtonBySourceType = ({
    source,
    ...props
}: AttachFileFromDesktopButtonBySourceProps) => {
    const { onUpload } = useUploadFromDesktop(source);
    return <AttachFileFromDesktopButton onUpload={onUpload} {...props} />;
};

export const AttachFileFromDesktopButton = ({
    isDisabled,
    onChange,
    onClick,
    onUpload,
}: AttachFileFromDesktopButtonProps) => {
    const { state, dispatch } = useCompose();

    const fileInputRef = useRef<HTMLInputElement>(null);

    const hasReachedAttachmentLimit = getIsFileLimitReached(state);

    const uploadFileForTempAttachment = async ({
        tempAttachment,
        file,
    }: {
        tempAttachment: Extract<Attachment, { status: "loading" }>;
        file: File;
    }) => {
        const result = await onUpload(file, {
            maxFileSizeInMegabytes: MAX_ATTACHMENT_FILE_SIZE_MEGABYTES,
            allowedFileExtensions: SUPPORTED_FILE_EXTENSIONS,
        });

        if (result.status === "error") {
            dispatch({
                type: "UPDATE_ATTACHMENT",
                payload: {
                    attachmentId: tempAttachment.id,
                    attachmentOrigin: "Upload",
                    attachment: {
                        ...tempAttachment,
                        origin: "Upload",
                        status: "error",
                        error: getMostSignificantAttachmentUploadErrorMessage(
                            result.result.errors,
                        ),
                    },
                },
            });
        } else {
            dispatch({
                type: "UPDATE_ATTACHMENT",
                payload: {
                    attachmentId: tempAttachment.id,
                    attachmentOrigin: "Upload",
                    attachment: {
                        origin: "Upload",
                        status: "success",
                        name: file.name,
                        size: file.size,
                        ...result.result,
                    },
                },
            });
        }
    };

    const uploadFiles = (files: FileList) => {
        if (
            state.maxAttachmentCount > 1 &&
            files.length + state.attachments.length > state.maxAttachmentCount
        ) {
            toast(
                <UI.Feedback
                    title={`You can only attach up to ${state.maxAttachmentCount} files`}
                    colour="error"
                />,
            );
            return;
        }

        const tempAttachmentFileList: {
            attachment: Extract<Attachment, { status: "loading" }>;
            file: File;
        }[] = Array.from(files).map((file) => ({
            attachment: {
                name: file.name,
                origin: "Upload",
                status: "loading",
                id: uuid(),
                size: file.size,
            },
            file,
        }));

        dispatch({
            type: "ADD_ATTACHMENTS",
            payload: {
                attachments: tempAttachmentFileList.map(
                    ({ attachment }) => attachment,
                ),
            },
        });

        tempAttachmentFileList.forEach(
            ({ attachment, file }) =>
                void uploadFileForTempAttachment({
                    tempAttachment: attachment,
                    file,
                }),
        );
    };

    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        onChange();

        const files = e.target.files ? e.target.files : null;

        if (files) {
            uploadFiles(files);
        }
    };

    return (
        <UI.Ds.Flex flexDirection="column" gap="1">
            {state.maxAttachmentCount > 1 && (
                <UI.Ds.Text color="metal" size="small" ml="0.5">
                    {`Attach up to ${state.maxAttachmentCount} files`}
                </UI.Ds.Text>
            )}
            <UI.Ds.Flex alignItems="center" gap="1">
                <DropdownItem.Button
                    aria-label="Upload desktop file"
                    text="Desktop file"
                    onClick={() => fileInputRef.current?.click()}
                    disabled={isDisabled || hasReachedAttachmentLimit}
                    theme={"transparent"}
                    icon={{
                        name: "Paperclip",
                        colour: isDisabled ? "stone" : "zinc",
                    }}
                />
                <StyledInvisibleFileInput
                    multiple={state.maxAttachmentCount > 1}
                    ref={fileInputRef}
                    type="file"
                    accept={`.${SUPPORTED_FILE_EXTENSIONS.join(",.")}`}
                    id="fileUpload"
                    data-testid="fileUpload"
                    disabled={isDisabled}
                    onChange={handleFileUpload}
                    onClick={(event: MouseEvent<HTMLInputElement>) => {
                        // Need to reset this on click in case we want to upload the same file twice
                        event.currentTarget.value = "";
                        onClick?.(event);
                    }}
                />
            </UI.Ds.Flex>
        </UI.Ds.Flex>
    );
};
