import { Tokens, focusBorderStyles } from "@accurx/design";
import { css } from "styled-components";

export const mobileNavItemStyles = css`
    align-items: center;
    border: 1px solid transparent;
    border-radius: ${Tokens.BORDERS.radius};
    display: flex;
    padding: ${Tokens.SIZES[1]};
    transition: all 0.1s ease-in;

    &[data-active="true"],
    &[data-state="open"] {
        background-color: #026363;
        border: 1px solid #025454;
    }

    &:hover {
        background-color: #037070;
        text-decoration: none;
    }

    // Higher specificity to override GlobalStyle
    &:focus&:focus,
    &:focus-visible {
        ${focusBorderStyles};
    }
`;
