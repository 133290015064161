import { useAuth } from "@accurx/auth";
import { Link } from "@accurx/design";
import {
    WORKSPACE_MANAGEMENT_ROUTES,
    isArchivedWorkspace,
} from "@accurx/workspace-management";
import { useRouteMatch } from "react-router-dom";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { ONBOARDING_ROUTES } from "app/guardedRoutes/routes/OnboardingRoutesSection";

import {
    StyledArchivedWorkspaceBanner,
    StyledIcon,
} from "./GlobalArchivedWorkspaceBanner.styles";

const SUPPORT_ARTICLE_LINK =
    "https://support.accurx.com/en/articles/8586989-accurx-web-how-to-create-or-join-a-workspace";

export const useShowGlobalArchivedWorkspaceBanner = () => {
    const { user } = useAuth();
    const { selectedOrgId } = useSelectedOrganisation();

    const isBannerEnabledOnRoute = !useRouteMatch({
        path: [
            ...ONBOARDING_ROUTES,
            ...Object.values(WORKSPACE_MANAGEMENT_ROUTES),
        ],
    });

    const workspace = user?.isLoggedIn
        ? user.organisations.find(({ orgId }) => orgId === selectedOrgId)
        : null;

    const isCurrentWorkspaceArchived =
        !!workspace && isArchivedWorkspace(workspace);

    return isBannerEnabledOnRoute && isCurrentWorkspaceArchived;
};

export const GlobalArchivedWorkspaceBanner = () => {
    const showBanner = useShowGlobalArchivedWorkspaceBanner();

    if (!showBanner) {
        return null;
    }

    return (
        <StyledArchivedWorkspaceBanner>
            <StyledIcon name="Info" color="blue" />
            <div>
                Most actions aren't available because this workspace has been
                archived. Use another workspace for any new messages.{" "}
                <Link href={SUPPORT_ARTICLE_LINK} openInNewTab={true}>
                    Find out more.
                </Link>
            </div>
        </StyledArchivedWorkspaceBanner>
    );
};
