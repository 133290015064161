import { Feedback } from "@accurx/design";

export const MULTIPLE_CHOICE_MANY_ANSWERS_MAX_RECOMMENDED_OPTIONS = 10;

export const MultipleChoiceManyAnswersRecommendedLimit = (): JSX.Element => (
    <Feedback
        colour="information"
        title={`You have more than ${MULTIPLE_CHOICE_MANY_ANSWERS_MAX_RECOMMENDED_OPTIONS} options`}
    >
        We recommend keeping the number of options to a maximum of{" "}
        {MULTIPLE_CHOICE_MANY_ANSWERS_MAX_RECOMMENDED_OPTIONS}, so the question
        is easy to complete and the response is easy to read.
    </Feedback>
);

export const FreeTextInformation = (): JSX.Element => (
    <Feedback colour="information">
        Questionnaires with free text answers cannot be automatically saved to
        record in batch messaging. Patient responses can be individually saved
        to record after review.
    </Feedback>
);

export const BranchingInformation = (): JSX.Element => (
    <Feedback colour="information" title="Condition">
        When this condition is met, then the associated questions below will be
        shown to the patient.
    </Feedback>
);
