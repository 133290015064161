import * as UI from "@accurx/design";

import { StyledActionStack } from "../ActionStack.styles";

export const PatientResponse = () => {
    return (
        <StyledActionStack.Row>
            <UI.Flex gap="1" alignItems="center">
                <StyledActionStack.RowIcon name="Reply" />
                <StyledActionStack.RowText>
                    Allow reply: <strong>once within 7 days</strong>
                </StyledActionStack.RowText>
            </UI.Flex>
        </StyledActionStack.Row>
    );
};
