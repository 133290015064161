import { createMutationHook } from "@accurx/api/QueryClient";
import { ReplaySearchForPatientResponse } from "@accurx/api/portal";
import {
    IWrappedResult,
    Log,
    getApiEndpoint,
    httpClient,
} from "@accurx/shared";
import { SearchForPatientSuccessSchema } from "domains/navigation/schemas/SearchForPatientSchema";
import { PatientSearchResult } from "domains/navigation/types";
import { mapPatientSearchResponse } from "domains/navigation/util/format/mappers";

export const ReplayPatientSearchTokenKey = "ReplayPatientSearchToken";

type ReplayPatientSearchArgs = {
    token?: string;
    workspaceId?: number;
};

const replayPatientSearch = async ({
    token: patientToken,
    workspaceId: organisationId,
}: ReplayPatientSearchArgs): Promise<
    IWrappedResult<ReplaySearchForPatientResponse>
> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/portal/SearchForPatient/replay",
        }),
        { patientToken, organisationId },
    );

export const useReplayPatientSearch = createMutationHook<
    ReplayPatientSearchArgs,
    PatientSearchResult[]
>(() => ({
    mutationKey: [ReplayPatientSearchTokenKey],
    mutationFn: async ({ workspaceId, token }) => {
        const response = await replayPatientSearch({ token, workspaceId });
        const result = SearchForPatientSuccessSchema.safeParse({
            searched: true,
            searchedResult: response.result?.searchedResult,
            searchedNhsNumber: null,
        });

        if (!result.success) {
            throw new Error("Replay Search for patient via token failed");
        }

        if (!result.data.searchedResult.patient) {
            Log.warn("Replay search for patient failed to find a match");
        }

        return mapPatientSearchResponse(result.data);
    },
}));
