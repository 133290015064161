import { Ds } from "@accurx/design";

type DeleteConfirmModalProps = {
    title: string;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
};

export const DeleteConfirmModal = ({
    title,
    isOpen,
    onClose,
    onConfirm,
}: DeleteConfirmModalProps) => {
    return (
        <Ds.Modal open={isOpen} onOpenChange={onClose}>
            <Ds.Modal.Section>
                <Ds.Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>{title}</Ds.Modal.Title>
                    <Ds.Text>This cannot be undone.</Ds.Text>
                    <Ds.Flex justifyContent="flex-end" gap="2">
                        <Ds.Button
                            appearance="secondary"
                            type="button"
                            data-testid="secondary-button"
                            onClick={onClose}
                        >
                            Cancel
                        </Ds.Button>
                        <Ds.Button
                            appearance="primary"
                            type="button"
                            data-testid="primary-button"
                            onClick={onConfirm}
                        >
                            Confirm
                        </Ds.Button>
                    </Ds.Flex>
                </Ds.Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
