import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";

import { useCurrentUser, useCurrentWorkspace } from "@accurx/auth";
import { Button } from "@accurx/design";
import { useMedicalRecordConnection } from "@accurx/native";
import { useAnalytics } from "reduxQuarantine/useAnalytics";

import { PatientSearchResult } from "../../../types";
import { AdvancedSearch } from "./AdvancedSearch/AdvancedSearch";
import { BasicSearch } from "./BasicSearch/BasicSearch";
import { BasicTwoFactoredSearch } from "./BasicTwoFactoredSearch/BasicTwoFactoredSearch";
import { EmisSearch } from "./EmisSearch/EmisSearch";
import { EmrSearch } from "./EmrSearch/EmrSearch";
import {
    AdvancedSearchState,
    BasicSearchState,
    BasicTwoFactoredSearchState,
    EmisSearchState,
    EmrSearchState,
    INIT_STATE,
    SearchMethod,
    TEST_PATIENT_DATA,
    isBasicSearchState,
    isTestPatientInFormData,
    useFormReducer,
} from "./PatientSearchForm.helpers";
import { StyledSearchBox } from "./PatientSearchForm.styles";

export type PatientSearchProps = {
    searchMethods: SearchMethod[];
    onSearchResult: (searchResults: PatientSearchResult[] | null) => void;
    onSearchError: (error: Error | null) => void;
    onLoading?: (isLoading: boolean) => void;
    initialData?: BasicSearchState | BasicTwoFactoredSearchState;
    isFormDisabled?: boolean;
    productOrigin?:
        | "PatientSearch"
        | "Compose"
        | "ClinicianCompose"
        | "VideoInvite";
};

export type PatientSearchFormHandle = {
    useTestPatient: () => void;
};

export const PatientSearchForm = forwardRef<
    PatientSearchFormHandle,
    PatientSearchProps
>(
    (
        {
            searchMethods,
            onSearchError,
            onSearchResult,
            onLoading,
            initialData,
            isFormDisabled,
            productOrigin = "PatientSearch",
        },
        ref,
    ) => {
        const track = useAnalytics();
        const medicalRecordConnection = useMedicalRecordConnection();

        const isTwoFactoredAuth = useCurrentUser().user.is2FAed;
        const isApprovedUser = useCurrentWorkspace().settings.isApprovedUser;

        const isApprovedAnd2FAed = isTwoFactoredAuth && isApprovedUser;

        const initBasicState = isApprovedAnd2FAed
            ? INIT_STATE.BASIC
            : INIT_STATE.BASIC_TWO_FACTORED;
        const [basicFormData, basicFormAction] = useFormReducer<
            BasicSearchState | BasicTwoFactoredSearchState
        >(initialData ?? initBasicState);
        const [advancedFormData, advancedFormAction] =
            useFormReducer<AdvancedSearchState>(INIT_STATE.ADVANCED);
        const [emisFormData, emisFormAction] = useFormReducer<EmisSearchState>(
            INIT_STATE.EMIS,
        );
        // Emis is separated out from the other standard EMRs due to different interfaces
        const [emrFormData, emrFormAction] = useFormReducer<EmrSearchState>(
            INIT_STATE.EMR,
        );
        const [selectedSearchMethod, setSelectedSearchMethod] = useState(
            searchMethods[0],
        );
        const isMultipleForms = searchMethods.length > 1;

        const formRef = useRef<HTMLFormElement>(null);

        // Automatically trigger search if patient token is provided
        useEffect(() => {
            initialData?.nhsNumber && formRef.current?.requestSubmit();
        }, [initialData]);

        useImperativeHandle(ref, () => ({
            useTestPatient: () => {
                handleFormSwitch("BASIC");
                basicFormAction({
                    type: "UPDATE",
                    payload: TEST_PATIENT_DATA,
                });
                setTimeout(() => formRef.current?.requestSubmit());
            },
        }));

        const isEmisConnection = medicalRecordConnection.system === "Emis";
        const isSystmOneConnection =
            medicalRecordConnection.system === "SystmOne";
        const isVisionConnection = medicalRecordConnection.system === "Vision";
        const isEmrConnection = isSystmOneConnection || isVisionConnection;

        const patientSearchMethodType = useMemo(() => {
            if (isEmisConnection) {
                return "SearchByEmis";
            } else if (isSystmOneConnection) {
                return "SearchBySystmOne";
            } else if (isVisionConnection) {
                return "SearchByVision";
            } else if (selectedSearchMethod === "ADVANCED") {
                return "SearchByName";
            } else if (isApprovedAnd2FAed) {
                return "SearchByNHSNumberOnly";
            } else {
                return "SearchByNHSNumber";
            }
        }, [
            isEmisConnection,
            selectedSearchMethod,
            isApprovedAnd2FAed,
            isSystmOneConnection,
            isVisionConnection,
        ]);

        const onSearch = (data: PatientSearchResult[]) => {
            onSearchResult(data);
            track("PatientSearch Button Click", {
                navigationVersion: "Unified",
                hasError: false,
                isTestPatient: isTestPatientInFormData(
                    basicFormData,
                    advancedFormData,
                ),
                patientSearchSuccess: data.length > 0,
                patientSearchMethodType,
                productOrigin,
            });
        };

        const onError = (error: Error) => {
            onSearchError(error);
            track("PatientSearch Button Click", {
                navigationVersion: "Unified",
                hasError: true,
                isTestPatient: isTestPatientInFormData(
                    basicFormData,
                    advancedFormData,
                ),
                patientSearchSuccess: false,
                patientSearchMethodType,
                productOrigin,
            });
        };

        const onValidationError = (errorReason: string[]) => {
            track("PatientSearch Button Click", {
                navigationVersion: "Unified",
                hasError: true,
                errorReason,
                isTestPatient: isTestPatientInFormData(
                    basicFormData,
                    advancedFormData,
                ),
                patientSearchSuccess: false,
                patientSearchMethodType,
                productOrigin,
            });
        };

        const handleClear = () => {
            onSearchResult(null);
        };

        const getAnalyticsSearchMethod = (searchMethod: SearchMethod) => {
            if (isEmisConnection) {
                return "SearchByEmis";
            } else if (isSystmOneConnection) {
                return "SearchBySystmOne";
            } else if (isVisionConnection) {
                return "SearchByVision";
            } else if (searchMethod === "ADVANCED") {
                return "SearchByName";
            } else if (isApprovedAnd2FAed) {
                return "SearchByNHSNumberOnly";
            } else {
                return "SearchByNHSNumber";
            }
        };

        const handleFormSwitch = (searchMethod: SearchMethod) => {
            setSelectedSearchMethod(searchMethod);
            if (searchMethod === "BASIC") {
                advancedFormAction({
                    type: "UPDATE",
                    payload: INIT_STATE.ADVANCED,
                });
            } else {
                basicFormAction({
                    type: "UPDATE",
                    payload: INIT_STATE.BASIC,
                });
            }
            handleClear();
            track("PatientSearchMethod Button Click", {
                navigationVersion: "Unified",
                patientSearchMethodType: getAnalyticsSearchMethod(searchMethod),
                productOrigin,
            });
        };

        const BasicFormSwitchButton = () => (
            <Button
                icon={{
                    name: "ArrowTail",
                    rotation: "right",
                    placement: "end",
                }}
                type="button"
                aria-label="Switch to advanced search form"
                text="Advanced search"
                theme="transparent"
                onClick={() => handleFormSwitch("ADVANCED")}
            />
        );

        const SupportedSearchMethods = {
            BASIC: isApprovedAnd2FAed ? (
                <BasicTwoFactoredSearch
                    ref={formRef}
                    formData={basicFormData}
                    formAction={basicFormAction}
                    onSubmit={onSearch}
                    onError={onError}
                    onValidationError={onValidationError}
                    onClear={handleClear}
                    onLoading={onLoading}
                    isFormDisabled={isFormDisabled}
                >
                    {isMultipleForms ? <BasicFormSwitchButton /> : undefined}
                </BasicTwoFactoredSearch>
            ) : (
                <BasicSearch
                    ref={formRef}
                    formData={
                        isBasicSearchState(basicFormData)
                            ? basicFormData
                            : {
                                  ...INIT_STATE.BASIC,
                                  ...basicFormData,
                              }
                    }
                    formAction={basicFormAction}
                    onSubmit={onSearch}
                    onError={onError}
                    onValidationError={onValidationError}
                    onClear={handleClear}
                    onLoading={onLoading}
                    isFormDisabled={isFormDisabled}
                >
                    {isMultipleForms ? <BasicFormSwitchButton /> : undefined}
                </BasicSearch>
            ),
            ADVANCED: (
                <AdvancedSearch
                    formData={advancedFormData}
                    formAction={advancedFormAction}
                    onSubmit={onSearch}
                    onError={onError}
                    onValidationError={onValidationError}
                    onClear={handleClear}
                    onLoading={onLoading}
                    isFormDisabled={isFormDisabled}
                >
                    {isMultipleForms ? (
                        <Button
                            icon={{
                                name: "ArrowTail",
                                rotation: "left",
                                placement: "start",
                            }}
                            type="button"
                            aria-label="Switch to basic search form"
                            text="Back"
                            theme="transparent"
                            onClick={() => handleFormSwitch("BASIC")}
                        />
                    ) : undefined}
                </AdvancedSearch>
            ),
        };

        if (isEmisConnection) {
            return (
                <StyledSearchBox>
                    <EmisSearch
                        ref={formRef}
                        formData={emisFormData}
                        formAction={emisFormAction}
                        onSubmit={onSearch}
                        onError={onError}
                        onValidationError={onValidationError}
                        onClear={handleClear}
                        onLoading={onLoading}
                        isFormDisabled={isFormDisabled}
                    />
                </StyledSearchBox>
            );
        }

        if (isEmrConnection) {
            return (
                <StyledSearchBox>
                    <EmrSearch
                        ref={formRef}
                        formData={emrFormData}
                        formAction={emrFormAction}
                        onSubmit={onSearch}
                        onError={onError}
                        onValidationError={onValidationError}
                        onClear={handleClear}
                        onLoading={onLoading}
                        isFormDisabled={isFormDisabled}
                    />
                </StyledSearchBox>
            );
        }

        return (
            <StyledSearchBox>
                {SupportedSearchMethods[selectedSearchMethod]}
            </StyledSearchBox>
        );
    },
);
