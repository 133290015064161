import {
    CollapsibleInfo,
    Feedback,
    Flex,
    FormFieldWrapper,
    Select,
    Text,
} from "@accurx/design";
import { SupportUrls } from "@accurx/shared";

import {
    MultiSelectOption,
    SiteLocationAndSlotTypeData,
    SiteLocationAndSlotTypeDataForUpdate,
} from "../types";
import { formatSelectedInputObjects, formatToInputObject } from "../utils";
import { FilterWithSingleItem } from "./FilterWithSingleItem";

export interface SiteLocationAndSlotTypeProps {
    allSiteNames: string[];
    allSlotTypes: string[];
    siteLocationAndSlotTypeData: SiteLocationAndSlotTypeData;
    onUpdateSiteLocationOrSlotTypes: (
        data: SiteLocationAndSlotTypeDataForUpdate,
    ) => void;
    canSiteBeSelected: (
        siteName: string,
        selectedSlotType: string[],
    ) => boolean;

    slotTypeErrors: string[] | undefined;
    siteLocationErrors: string[] | undefined;
}

export const SiteLocationAndSlotType = ({
    allSiteNames,
    allSlotTypes,
    siteLocationAndSlotTypeData,
    onUpdateSiteLocationOrSlotTypes,
    canSiteBeSelected,
    slotTypeErrors,
    siteLocationErrors,
}: SiteLocationAndSlotTypeProps) => {
    const hasMultipleSites = allSiteNames.length > 1;
    const hasMultipleSlots = allSlotTypes.length > 1;

    const renderSiteSelection = (): JSX.Element => {
        const {
            siteNameMultiSelectOptions,
            disableSiteNameMultiSelect,
            selectedSiteNames,
        } = siteLocationAndSlotTypeData;

        const onSiteNameChangeHandler = (sites: MultiSelectOption[]) => {
            const selectedSites = sites.map((site: MultiSelectOption) => {
                return site.label;
            });

            if (selectedSites.length === 0) {
                onUpdateSiteLocationOrSlotTypes({
                    selectedSiteNames: selectedSites,
                    selectedSlotTypes: [],
                });
            } else {
                onUpdateSiteLocationOrSlotTypes({
                    selectedSiteNames: selectedSites,
                });
            }
        };

        const label = "Site locations";

        return (
            <div id="site_locations_view">
                {hasMultipleSites === false ? (
                    <FilterWithSingleItem
                        label={label}
                        sublabel="Create a reminder for your site"
                        value={allSiteNames[0]}
                    />
                ) : (
                    <FormFieldWrapper
                        label={label}
                        labelProps={{ htmlFor: "site_names" }}
                        subLabel="Select all site locations for this reminder"
                        errors={siteLocationErrors}
                    >
                        {siteNameMultiSelectOptions && (
                            <>
                                <Select
                                    options={siteNameMultiSelectOptions}
                                    id="site_names"
                                    onChangeHandler={(values) =>
                                        onSiteNameChangeHandler(
                                            values as MultiSelectOption[],
                                        )
                                    }
                                    defaultCopy={{
                                        placeholder: "Select site locations",
                                        valueSelectAll: `Select ${siteNameMultiSelectOptions.length} of my site locations`,
                                    }}
                                    disabled={disableSiteNameMultiSelect}
                                    zIndex={4}
                                    initSelectedValues={formatSelectedInputObjects(
                                        selectedSiteNames,
                                        allSiteNames,
                                    )}
                                    theme="MULTISELECTALL"
                                    error={
                                        siteLocationErrors &&
                                        siteLocationErrors.length > 0
                                    }
                                />
                            </>
                        )}
                    </FormFieldWrapper>
                )}

                {hasMultipleSites && (
                    <CollapsibleInfo title="Why are some locations not available?">
                        <div>
                            <Text>
                                If you can’t select a site location, it’s
                                because it has either been used with all slot
                                types or with one of your currently selected
                                slot types.
                            </Text>
                            <Text
                                variant="link"
                                as="a"
                                skinny
                                props={{
                                    href: SupportUrls.AppointmentReminderCreation,
                                    target: "_blank",
                                    rel: "noopener noreferrer",
                                }}
                            >
                                Read more.
                            </Text>
                        </div>
                    </CollapsibleInfo>
                )}
            </div>
        );
    };

    const renderSlotSelection = (): JSX.Element => {
        const { selectedSlotTypes, selectedSiteNames } =
            siteLocationAndSlotTypeData;

        const onSlotTypeChangeHandler = (slots: MultiSelectOption[]) => {
            const slotTypes = slots.map((slot: MultiSelectOption) => {
                return slot.label;
            });
            const formattedSiteNames = formatToInputObject(
                allSiteNames,
                slotTypes,
                canSiteBeSelected,
            );

            onUpdateSiteLocationOrSlotTypes({
                selectedSlotTypes: slotTypes,
                siteNameMultiSelectOptions: formattedSiteNames,
            });
        };

        const label = "Slot type";

        return (
            <div id="slot_types_view">
                {hasMultipleSlots === false ? (
                    <FilterWithSingleItem
                        label={label}
                        value={allSlotTypes[0]}
                    />
                ) : (
                    siteLocationAndSlotTypeData.slotTypeMultiSelectOptions && (
                        <FormFieldWrapper
                            label={label}
                            labelProps={{ htmlFor: "slot_types" }}
                            subLabel="Select all slot types for this reminder"
                            errors={slotTypeErrors}
                        >
                            <Select
                                key={selectedSlotTypes.toString()}
                                label={label}
                                options={
                                    siteLocationAndSlotTypeData.slotTypeMultiSelectOptions
                                }
                                id="slot_types"
                                onChangeHandler={(values) =>
                                    onSlotTypeChangeHandler(
                                        values as MultiSelectOption[],
                                    )
                                }
                                disabled={
                                    hasMultipleSites &&
                                    selectedSiteNames.length === 0
                                }
                                defaultCopy={{
                                    placeholder: "Select slot type",
                                    valueSelectAll: `Select ${siteLocationAndSlotTypeData.slotTypeMultiSelectOptions.length} of my slot types`,
                                }}
                                zIndex={3}
                                initSelectedValues={formatSelectedInputObjects(
                                    selectedSlotTypes,
                                    allSlotTypes,
                                )}
                                theme="MULTISELECTALL"
                                error={
                                    slotTypeErrors && slotTypeErrors.length > 0
                                }
                            />
                        </FormFieldWrapper>
                    )
                )}
                <CollapsibleInfo title="Why are some slot types not available?">
                    <div>
                        <Text>
                            If you can’t select a slot type, it’s because there
                            is already a reminder set up for it at one of your
                            selected site locations.
                        </Text>
                        <Text
                            variant="link"
                            as="a"
                            skinny
                            props={{
                                href: SupportUrls.AppointmentReminderCreation,
                                target: "_blank",
                                rel: "noopener noreferrer",
                            }}
                        >
                            Read more.
                        </Text>
                    </div>
                </CollapsibleInfo>
            </div>
        );
    };

    return (
        <Flex gap="2" flexDirection="column">
            {hasMultipleSites && (
                <div>
                    <Feedback
                        colour="information"
                        title={`${allSiteNames.length} site locations found in your appointment book`}
                    />
                </div>
            )}
            {renderSiteSelection()}
            {renderSlotSelection()}
        </Flex>
    );
};

export default SiteLocationAndSlotType;
