import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useCurrentUser } from "@accurx/auth";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { StyledPopoverContent } from "domains/inbox/components/Dropdown/Dropdown.styles";
import { DropdownItem } from "domains/inbox/components/Dropdown/DropdownItem";
import { WithTooltip } from "domains/inbox/components/Tooltip/WithTooltip";
import { useInboxLink, useInboxParams } from "domains/inbox/hooks/util";
import { useLocation } from "react-router-dom";

import { getSortOrderKey, saveSortOrder } from "./saveSortOrder";

export const ConversationSortOrder = ({ minimise }: { minimise?: boolean }) => {
    const link = useInboxLink();
    const { sort, status } = useInboxParams(["sort", "status"]);
    const { user } = useCurrentUser();
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    const track = useAnalytics();

    const sortOrderKey = getSortOrderKey({
        userId: user.accuRxUserId,
        pathname: location.pathname,
        status,
    });

    const onOrderChanged = (sort: "Newest" | "Oldest") => {
        setIsOpen(false);
        saveSortOrder({ key: sortOrderKey, sort });
        track("ConversationListSort Button Click", {
            eventVersion: 1,
            sortField: "LastUpdated",
            sortDirection: sort === "Newest" ? "Descending" : "Ascending",
        });
    };

    return (
        <UI.Popover open={isOpen} onOpenChange={setIsOpen}>
            <WithTooltip
                isEnabled={!!minimise}
                props={{
                    trigger: { asChild: true, tabIndex: -1 },
                }}
                content={
                    <UI.Ds.Text size="small" color="white" weight="bold">
                        Sort: {sort}
                    </UI.Ds.Text>
                }
            >
                <UI.PopoverTrigger asChild>
                    {minimise ? (
                        <Pill.Button radius="full">
                            <Pill.Icon name="Order" colour="blue" />
                            <UI.VisuallyHidden>
                                Order by {sort}
                            </UI.VisuallyHidden>
                        </Pill.Button>
                    ) : (
                        <Pill.Dropdown radius="full" aria-haspopup="menu">
                            <Pill.Icon name="Order" colour="blue" />
                            <UI.VisuallyHidden>Order by</UI.VisuallyHidden>
                            <Pill.Text>{sort}</Pill.Text>
                        </Pill.Dropdown>
                    )}
                </UI.PopoverTrigger>
            </WithTooltip>
            <StyledPopoverContent align="start">
                <DropdownItem.ListItem>
                    <DropdownItem.TitleBar $mb={1}>
                        <UI.Ds.Text weight="bold" size="medium" mt="0.5">
                            Sort
                        </UI.Ds.Text>
                    </DropdownItem.TitleBar>
                </DropdownItem.ListItem>
                <DropdownItem.ListItem $mb={1}>
                    <DropdownItem.Link
                        link={link.update({
                            sort: "Newest",
                            conversationId: null,
                        })}
                        selected={sort === "Newest"}
                        onClick={() => onOrderChanged("Newest")}
                    >
                        <DropdownItem.Icon name="Order" />
                        <DropdownItem.Text>Newest first</DropdownItem.Text>
                    </DropdownItem.Link>
                </DropdownItem.ListItem>
                <DropdownItem.ListItem>
                    <DropdownItem.Link
                        link={link.update({
                            sort: "Oldest",
                            conversationId: null,
                        })}
                        selected={sort === "Oldest"}
                        onClick={() => onOrderChanged("Oldest")}
                    >
                        <DropdownItem.Icon name="Order" />
                        <DropdownItem.Text>Oldest first</DropdownItem.Text>
                    </DropdownItem.Link>
                </DropdownItem.ListItem>
            </StyledPopoverContent>
        </UI.Popover>
    );
};
