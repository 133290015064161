import { Ds, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledIconButton = styled(Ds.Button).attrs({
    appearance: "secondary",
    size: "small",
})`
    height: ${Tokens.SIZES[4]};
    width: ${Tokens.SIZES[4]};

    svg {
        width: 20px;
        height: 20px;
    }
`;

export const StyledBarContainer = styled(Ds.Flex)`
    margin-bottom: ${Tokens.SIZES[1.5]};
`;
