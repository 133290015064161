import { i } from "images";

import {
    FullPageFlex,
    StyledCenteredText,
} from "./DisconnectedEmrErrorPage.styles";

export const DisconnectedEmrErrorPage = () => (
    <FullPageFlex
        alignItems="center"
        justifyContent="center"
        gap="2"
        flexDirection="column"
    >
        <img
            src={i("illustrations/error-icon-80px@2x.png")}
            srcSet={`${i("illustrations/error-icon-80px.png")}, ${i(
                "illustrations/error-icon-80px@2x.png",
            )} 2x, ${i("illustrations/error-icon-80px@3x.png")} 3x`}
            alt="Medical record disconnected"
            height="80"
        />
        <StyledCenteredText>
            Unable to connect to the medical system.
            <br /> <br />
            To start a new message, connect to the medical system.
        </StyledCenteredText>
    </FullPageFlex>
);
