import { z } from "zod";

export const PatientExternalIdSchema = z.object({
    type: z.enum(["NhsNumber", "Emis", "SystmOne", "Vision"]),
    value: z.string(),
});

export type PatientExternalId = z.infer<typeof PatientExternalIdSchema>;

export const PatientExternalIdentityDtoSchema = z.object({
    patientExternalIds: z.array(PatientExternalIdSchema),
});

export type PatientExternalIdentityDto = z.infer<
    typeof PatientExternalIdentityDtoSchema
>;
