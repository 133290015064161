import * as UI from "@accurx/design";
import styled from "styled-components";

const { SIZES, COLOURS, BORDERS } = UI.Tokens;

export const StyledDate = styled.span<{ isSticky: boolean }>`
    z-index: ${({ isSticky }) => (isSticky ? "1" : "0")};
    position: sticky;
    top: -1px;

    padding: ${SIZES[2]} 0;
    margin: 0 -${SIZES[3]}; /* Break out of container padding */
    display: flex;
    justify-content: center;
    align-items: center;

    ${({ isSticky }) =>
        !isSticky &&
        `
        ::before {
            margin-right: ${SIZES[2]};
            content: "";
            flex: 1;
            border-bottom: solid ${COLOURS.greyscale.silver} 1px;
        }
        ::after {
            margin-left: ${SIZES[2]};
            content: "";
            flex: 1;
            border-bottom: solid ${COLOURS.greyscale.silver} 1px;
        }
    `}
`;

export const StyledList = styled.ul`
    margin: 0;
    padding-inline-start: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
`;

export const StyledBadge = styled.span<{ isSticky: boolean }>`
    padding: 0 ${SIZES[1]};
    border-radius: ${BORDERS.radius};

    background-color: ${COLOURS.greyscale.silver};

    ${({ isSticky }) =>
        isSticky &&
        `
            transition: background-color 0.2s ease-in-out;
            background-color: ${COLOURS.greyscale.white};
            
            box-shadow: 0 0 0 1px ${COLOURS.greyscale.silver},
                0px 4px 8px 0px rgba(68, 72, 126, 0.15);
        `}
`;
