import { ReactNode, useMemo, useState } from "react";

import { useAuth } from "@accurx/auth";
import { Ds, Popover, PopoverTrigger } from "@accurx/design";
import {
    NavTooltip,
    StyledPopoverContent,
    StyledPopoverItem,
} from "@accurx/navigation";
import { SupportUrls } from "@accurx/shared";
import { userflowIds } from "layouts/constants";
import { useHistory } from "react-router-dom";
import { useAnalytics } from "reduxQuarantine/useAnalytics";

import { StyledTriggerButton } from "../../UnifiedLayout.styles";
import { getNavigationVersion } from "../../helpers";
import { PopoverExternalLink } from "./PopoverExternalLink";
import { WhatIsNextLink } from "./WhatIsNextLink";

export const HelpPopover = ({
    children,
    workspaceId,
}: {
    children?: ReactNode;
    workspaceId?: number;
}) => {
    const track = useAnalytics();
    const history = useHistory();
    const [popoverOpen, setPopoverOpen] = useState(false);

    const handleOpenChange = (open: boolean) => {
        setPopoverOpen(open);

        if (open) {
            track("NavigationMenu Button Click", {
                origin: history.location.pathname,
                navigationOptionSelected: "Help",
                navigationVersion: getNavigationVersion(),
                eventVersion: 2,
            });
        }
    };

    return (
        <Popover open={popoverOpen} onOpenChange={handleOpenChange}>
            <NavTooltip text="Help" align="end">
                <PopoverTrigger asChild>
                    {children || (
                        <StyledTriggerButton
                            data-userflow-id={
                                userflowIds.navigation.primary.help
                            }
                            aria-label="Help"
                        >
                            <Ds.Icon
                                name="Question"
                                size="small"
                                color="white"
                            />
                        </StyledTriggerButton>
                    )}
                </PopoverTrigger>
            </NavTooltip>
            <HelpPopoverContent
                onClose={() => setPopoverOpen(false)}
                workspaceId={workspaceId}
            />
        </Popover>
    );
};

export const HelpPopoverContent = ({
    onClose,
    workspaceId,
}: {
    onClose: () => void;
    workspaceId?: number;
}) => {
    const track = useAnalytics();
    const history = useHistory();
    const { user } = useAuth();
    const { ref, handleFocusRef } = Ds.useModalAutoFocus<HTMLAnchorElement>();

    const feedbackURL = useMemo(() => {
        if (!user.isLoggedIn) {
            return undefined;
        }

        const url = new URL("https://survey.survicate.com/3516d30de2fd6729/");
        url.searchParams.set("first_name", user.fullName);
        url.searchParams.set("email", user.email);
        url.searchParams.set("uid", user.accuRxUserId);
        return url.toString();
    }, [user]);

    const handleMenuItemClick = (
        optionSelected:
            | "HelpCentre"
            | "ContactSupport"
            | "SendFeedback"
            | "WhatIsNext",
    ) => {
        track("NavigationHelp MenuItem Click", {
            navigationVersion: getNavigationVersion(),
            origin: history.location.pathname,
            optionSelected,
        });
        onClose();
    };

    const handleContactSupport = () => {
        window?.Intercom?.("show");
        handleMenuItemClick("ContactSupport");
    };

    return (
        <StyledPopoverContent
            side="bottom"
            align="end"
            onOpenAutoFocus={(e) => setTimeout(() => handleFocusRef(e), 0)}
        >
            <PopoverExternalLink
                ref={ref}
                href={SupportUrls.Home}
                onClick={() => handleMenuItemClick("HelpCentre")}
                data-userflow-id={userflowIds.navigation.primary.helpCentre}
            >
                <Ds.Icon name="Browser" size="small" color="stone" />
                <Ds.Text weight="bold">Help centre</Ds.Text>
            </PopoverExternalLink>
            <StyledPopoverItem
                onClick={handleContactSupport}
                data-userflow-id={
                    userflowIds.navigation.primary.helpContactSupport
                }
            >
                <Ds.Icon name="Chat" size="small" color="stone" />
                <Ds.Text weight="bold">Contact support</Ds.Text>
            </StyledPopoverItem>
            {user.isLoggedIn && feedbackURL && (
                <PopoverExternalLink
                    href={feedbackURL}
                    onClick={() => handleMenuItemClick("SendFeedback")}
                    data-userflow-id={
                        userflowIds.navigation.primary.helpSendFeedback
                    }
                >
                    <Ds.Icon name="AccurxLogo" size="small" color="stone" />
                    <Ds.Text weight="bold">Send feedback</Ds.Text>
                </PopoverExternalLink>
            )}
            {workspaceId && (
                <WhatIsNextLink
                    onClick={() => handleMenuItemClick("WhatIsNext")}
                    workspaceId={workspaceId}
                />
            )}
        </StyledPopoverContent>
    );
};
