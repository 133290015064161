import { FeatureName, useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import { Ds, Tokens } from "@accurx/design";
import { useCustomIntercomLauncher } from "@accurx/navigation";
import { ArchivedWorkspaceHiddenItemWrapper } from "@accurx/workspace-management";
import { userflowIds } from "layouts/constants";
import { generatePath } from "react-router-dom";
import styled from "styled-components";

import { ROUTES, ROUTES_BASE, ROUTES_PRIMARY } from "shared/Routes";
import { useSimplifiedNavigation } from "store/hooks";

import { DirectoryButton } from "./Directory/DirectoryButton";
import { MobileNavButton, MobileNavLink } from "./MobileNavItem/MobileNavItem";
import { NewMessage } from "./NewMessage/NewMessage";
import { NewPatientSearch } from "./PatientSearch/NewPatientSearch";
import {
    StyledNavLinkList,
    StyledPrimaryNavigation,
} from "./UnifiedPrimaryNavigation.styles";
import { SimpleWorkspaceMenu } from "./WorkspaceMenu/SimpleWorkspaceMenu";
import { WorkspaceMenu } from "./WorkspaceMenu/WorkspaceMenu";

const StyledMobileNavLinkList = styled(StyledNavLinkList)`
    gap: ${Tokens.SIZES[2]};
`;

export const UnifiedMobilePrimaryNavigation = () => {
    useCustomIntercomLauncher();
    const isSimplifiedNavigation = useSimplifiedNavigation();

    return (
        <StyledPrimaryNavigation data-testid="mobile-primary-nav">
            {isSimplifiedNavigation ? (
                <MobileSimplePrimaryNavigation />
            ) : (
                <MobilePrimaryNavigation />
            )}
        </StyledPrimaryNavigation>
    );
};

const MobilePrimaryNavigation = () => {
    const { orgId: workspaceId, organisationName: workspaceName } =
        useCurrentWorkspace();

    const isIntegratedClinicsEnabled = useFeatureFlag(
        FeatureName.IntegratedClinicLists,
    );
    const isDirectoryRolloutOn = useFeatureFlag(
        FeatureName.NavigationWebDirectoryRollout,
    );

    const listsPath = isIntegratedClinicsEnabled
        ? generatePath(ROUTES_BASE.integratedClinicListsAll, {
              workspaceId,
          })
        : ROUTES_BASE.patientsListsAll;

    // TODO: Remove once GPs are familiar with 2FA and Unified Inbox
    const isManageOrgRedirect = Boolean(
        window.sessionStorage.getItem("isManageOrgRedirect"),
    );

    if (isManageOrgRedirect) {
        return (
            <Ds.Flex justifyContent="flex-end">
                <WorkspaceMenu isMobile>
                    <MobileNavButton
                        aria-label={`${workspaceName} workspace menu`}
                        data-userflow-id={
                            userflowIds.navigation.primary.workspaceMenu
                        }
                    >
                        <Ds.Icon name="Cog" color="white" size="small" />
                    </MobileNavButton>
                </WorkspaceMenu>
            </Ds.Flex>
        );
    }

    return (
        <StyledMobileNavLinkList>
            <ArchivedWorkspaceHiddenItemWrapper>
                <li>
                    <NewMessage hideText />
                </li>
            </ArchivedWorkspaceHiddenItemWrapper>
            <li>
                <NewPatientSearch>
                    <MobileNavButton
                        aria-label="Search patient"
                        data-userflow-id={
                            userflowIds.navigation.primary.searchPatient
                        }
                    >
                        <Ds.Icon
                            name="SearchPerson"
                            color="white"
                            size="small"
                            appearance="solid"
                        />
                    </MobileNavButton>
                </NewPatientSearch>
            </li>
            {isDirectoryRolloutOn && (
                <li>
                    <DirectoryButton>
                        <MobileNavButton
                            aria-label="Directory"
                            data-userflow-id={
                                userflowIds.navigation.primary.directory
                            }
                        >
                            <Ds.Icon
                                name="preview-Directory"
                                appearance="outline"
                                color="white"
                                size="small"
                            />
                        </MobileNavButton>
                    </DirectoryButton>
                </li>
            )}
            <li>
                <MobileNavLink
                    aria-label="Inbox"
                    activePaths={[ROUTES.inbox]}
                    to={generatePath(ROUTES.inbox, {
                        workspaceId,
                    })}
                    data-userflow-id={userflowIds.navigation.primary.inbox}
                    navigationOptionSelected="Inbox"
                >
                    <Ds.Icon name="Mailbox" color="white" size="small" />
                </MobileNavLink>
            </li>
            <li>
                <MobileNavLink
                    aria-label="Lists"
                    activePaths={[
                        ROUTES_PRIMARY.patients,
                        ROUTES_PRIMARY.batchMessageTrust,
                        ROUTES_PRIMARY.questionnaireScoreboard,
                        ROUTES_BASE.integratedClinicListsAll,
                    ]}
                    to={listsPath}
                    data-userflow-id={
                        userflowIds.navigation.primary.patientLists
                    }
                    navigationOptionSelected="PatientLists"
                >
                    <Ds.Icon
                        name="preview-PatientList"
                        color="white"
                        size="small"
                    />
                </MobileNavLink>
            </li>
            <li>
                <WorkspaceMenu isMobile>
                    <MobileNavButton
                        aria-label={`${workspaceName} workspace menu`}
                        data-userflow-id={
                            userflowIds.navigation.primary.workspaceMenu
                        }
                    >
                        <Ds.Icon name="Cog" color="white" size="small" />
                    </MobileNavButton>
                </WorkspaceMenu>
            </li>
        </StyledMobileNavLinkList>
    );
};

const MobileSimplePrimaryNavigation = () => {
    return (
        <Ds.Flex justifyContent="flex-end">
            <SimpleWorkspaceMenu isMobile>
                <MobileNavButton
                    aria-label="Workspace menu"
                    data-userflow-id={
                        userflowIds.navigation.primary.workspaceMenu
                    }
                >
                    <Ds.Icon name="Cog" color="white" size="small" />
                </MobileNavButton>
            </SimpleWorkspaceMenu>
        </Ds.Flex>
    );
};
