import { is2FAOnboarded, useAuth } from "@accurx/auth";
import { ROOT_ROUTES } from "@accurx/workspace-management";
import { useLocation } from "react-router";
import { matchPath } from "react-router-dom";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { OrganisationHelper } from "shared/OrganisationHelper";
import { ROUTES } from "shared/Routes";
import { NEXT_URL_QUERY_PARAM } from "shared/RoutesHelper";

import { AppRouteProps, BlockedRoute, SimpleRoute } from "../AppRoute";
import { GlobalNhsPharmacyModal } from "../workspace/components/GlobalNhsPharmacyModal/GlobalNhsPharmacyModal";
import { ONBOARDING_ROUTES } from "./routes/OnboardingRoutesSection";

// Not using `LoggedInWith2FA` anywhere yet, but this is here as it helped to set up the types
export type LoggedInStatus =
    | "LoggedIn"
    | "LoggedInWith2FA"
    | "LoggedInWithTrustedDevice";

type GuardedRouteProps = AppRouteProps & {
    isOrganisationRoute?: boolean;
    children: JSX.Element;
    requires:
        | // Requires a user to be logged in. May also require the user to have onboarded.
        {
              authenticationStatus: LoggedInStatus;
              onboarded: boolean;
          }
        // Either does not require a specific authentication status, or requires a user to be logged out. Cannot require the user to be onboarded.
        | {
              authenticationStatus?: "LoggedOut";
              onboarded?: false;
          };
};

/**
 * GuardedRoute will render a route if the current user meets the requirements provided in the `required` prop. Otherwise, it will redirect to a location depending on the requirement.
 * Will check requirements in the following priority: login status, onboarding status, then 2FA status.
 * Accepts route props from react-router, plus additional props.
 * @property {boolean} isOrganisationRoute:
 * if this prop is true and the user is logged out, we should redirect to the organisation login page
 * which is currently used for practices
 *
 * @property {Object} requires:
 * if your route requires the user to be logged in, you must pass both the required authentication status, and whether the user needs to have completed the onboarding flow: { authenticationStatus: "LoggedIn" | "LoggedInWith2FA"; onboarded: boolean; }
 * Otherwise: { authenticationStatus?: "LoggedOut"; onboarded?: never;}
 */
export const GuardedRoute = ({
    children,
    isOrganisationRoute,
    requires,
    ...routeProps
}: GuardedRouteProps) => {
    const location = useLocation();

    const { user } = useAuth();
    const { selectedOrgId } = useSelectedOrganisation();

    const organisations = user.isLoggedIn ? user.organisations : [];

    const selectedOrganisation = user.isLoggedIn
        ? user.organisations.find(({ orgId }) => orgId === selectedOrgId)
        : undefined;

    const hasAcceptedTermsService = user.isLoggedIn
        ? user.onboarding?.hasAcceptedTermsService
        : false;

    // Puts whatever the current path is as the next URL parameter so that when we redirect away from
    // a page we can tell that page to return to the current URL next
    const encodeQueryParams = (): string =>
        `${NEXT_URL_QUERY_PARAM}=${encodeURIComponent(
            location.pathname + location.search,
        )}`;

    const getRedirectUrl = (baseRoute: string) => {
        return `${baseRoute}?${encodeQueryParams()}`;
    };

    const RedirectFromBlockedRoute = ({
        redirectTo,
    }: {
        redirectTo?: string;
    }) => {
        return <BlockedRoute {...routeProps} redirectTo={redirectTo} />;
    };

    const requiredAuthStatus = requires.authenticationStatus ?? "";

    // Redirect to login page if logged out
    if (
        ["LoggedIn", "LoggedInWith2FA", "LoggedInWithTrustedDevice"].includes(
            requiredAuthStatus,
        ) &&
        !user.isLoggedIn
    ) {
        const redirectTo = getRedirectUrl(
            isOrganisationRoute ? ROUTES.loginOrganisations : ROUTES.login,
        );
        return <RedirectFromBlockedRoute redirectTo={redirectTo} />;
    }

    // Do not allow access to route if it requires the user to be logged out, but the user is logged in
    if (requiredAuthStatus === "LoggedOut" && user.isLoggedIn) {
        return <RedirectFromBlockedRoute />;
    }

    if (requires.onboarded && user.isLoggedIn) {
        if (!is2FAOnboarded(user)) {
            return (
                <RedirectFromBlockedRoute
                    redirectTo={getRedirectUrl(ROUTES.two_factor_auth)}
                />
            );
        }

        const isTrustOnboarding = Boolean(
            matchPath(location.pathname, [
                ROOT_ROUTES.workspaceCreate,
                ROOT_ROUTES.workspaceJoin,
            ]),
        );

        // Redirects to the select organisation page if the user is not in an organisation
        // and it's not an onboarding route
        if (!selectedOrganisation && !isTrustOnboarding) {
            return (
                <RedirectFromBlockedRoute
                    redirectTo={getRedirectUrl(ROUTES.joinOrganisation)}
                />
            );
        }

        // Redirects to terms and conditions page if the user has not accepted them
        if (!hasAcceptedTermsService) {
            return (
                <RedirectFromBlockedRoute
                    redirectTo={getRedirectUrl(ROUTES.terms_and_conditions)}
                />
            );
        }
    }

    const showJoinPharmacyModal =
        selectedOrganisation &&
        OrganisationHelper.isNhsPharmacy(selectedOrganisation) &&
        organisations &&
        organisations.filter(OrganisationHelper.isPharmacy).length === 0 &&
        !matchPath(location.pathname, ONBOARDING_ROUTES);

    if (showJoinPharmacyModal) {
        return (
            <>
                <GlobalNhsPharmacyModal />
                <SimpleRoute {...routeProps}>{children}</SimpleRoute>
            </>
        );
    }

    // Redirect to 2FA page if route requires user to be TrustedDevice and they are not
    if (
        requiredAuthStatus === "LoggedInWithTrustedDevice" &&
        user.isLoggedIn &&
        !user.isTrustedDevice
    ) {
        return (
            <RedirectFromBlockedRoute
                redirectTo={getRedirectUrl(ROUTES.two_factor_auth)}
            />
        );
    }

    // Redirect to 2FA page if route requires user to be 2FAed and they're  not 2FAed
    if (
        requiredAuthStatus === "LoggedInWith2FA" &&
        user.isLoggedIn &&
        !user.is2FAed
    ) {
        return (
            <RedirectFromBlockedRoute
                redirectTo={getRedirectUrl(ROUTES.two_factor_auth)}
            />
        );
    }

    return <SimpleRoute {...routeProps}>{children}</SimpleRoute>;
};
