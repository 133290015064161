import {
    ToastContainer as ReactToastifyContainer,
    ToastContainerProps,
} from "react-toastify";

export const ToastContainerIds = {
    patientSearch: "toast-container-patient-search",
};

export const ToastContainer = (props: ToastContainerProps) => (
    <ReactToastifyContainer
        position="top-center"
        hideProgressBar
        draggable
        closeButton={false}
        {...props}
    />
);
