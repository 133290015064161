import { filters } from "domains/concierge/internal/util/conversationGroupFilters";
import { sortOptions } from "domains/concierge/internal/util/conversationGroupSortOptions";

import { useConversationCount } from "./useConversationCount";

export const usePatientConversationCount = (patientInternalId: string) => {
    const status = "Open";
    const sort = "Newest";

    return useConversationCount(
        filters.patient({ status, patientInternalId }),
        sortOptions.common({ status, sort }),
    );
};
