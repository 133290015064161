import { getApiEndpoint, httpClient } from "@accurx/shared";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";

type ConfirmUploadRequest = {
    workspaceId: string;
    bulkAppointmentImportId: string;
};

export const useBulkAppointmentImportConfirmUploadMutation = (
    options: UseMutationOptions<void, Error, ConfirmUploadRequest> = {},
) => {
    return useMutation({
        mutationKey: ["bulkappointmentimports-confirm"],
        ...options,
        mutationFn: async (request: ConfirmUploadRequest) => {
            const response = await httpClient.postJsonReturnSafeAsync(
                getApiEndpoint({
                    path: "/api/appointment/:workspaceId/bulkappointmentimports/ConfirmUpload/:bulkAppointmentImportId",
                    params: {
                        workspaceId: request.workspaceId,

                        bulkAppointmentImportId:
                            request.bulkAppointmentImportId,
                    },
                }),
                {},
            );
            if (!response.success) {
                throw new Error(
                    response.error ?? "Could not trigger appointment import",
                );
            }
        },
    });
};
