import { useEffect, useState } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { Conversation } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { GenericErrorState } from "domains/inbox/components/GenericErrorState/GenericErrorState";
import { SkeletonLoader } from "domains/inbox/components/SkeletonLoader/SkeletonLoader";
import { useTicketLabelsQuery } from "domains/inbox/hooks/queries/useTicketLabelsQuery";
import { useScrollIntoView } from "domains/inbox/hooks/util/useScrollIntoView";

import { StyledOutcomeRecording } from "../OutcomeRecording.styles";
import { OutcomeLabelButton } from "./OutcomeLabelButton";
import { OutcomeLoadingContext } from "./OutcomeLoadingContext";

type OutcomeLabelsProps = {
    conversation: Conversation;
    onSelectOutcome?: () => void;
};

export const OutcomeLabels = ({
    conversation,
    onSelectOutcome,
}: OutcomeLabelsProps) => {
    const { orgId } = useCurrentWorkspace();
    const [isLoading, setIsLoading] = useState(false);

    const { data, status } = useTicketLabelsQuery({
        labelType: "Outcome",
    });

    const { ref, scrollIntoView } = useScrollIntoView();

    useEffect(() => {
        if (status === "success") {
            scrollIntoView();
        }
    }, [scrollIntoView, status]);

    // If fetching labels failed, or was successful but returned no results, display an error.
    // No results is unlikely, since the list of labels served by the API is hard coded.
    if (status === "error" || (status === "success" && data.length === 0)) {
        return (
            <GenericErrorState>
                <>
                    Sorry, something went wrong.
                    <br />
                    Refresh the page to try again.
                </>
            </GenericErrorState>
        );
    }

    return (
        <UI.Flex flexDirection="column" gap="1.5" ref={ref}>
            <UI.Text skinny variant="preview">
                This will record the outcome of this conversation. Outcome data
                can be accessed in the{" "}
                <StyledOutcomeRecording.Link
                    href={`practices/${orgId}/patient-triage-dashboards`}
                    openInNewTab
                >
                    Patient Triage Dashboard
                    <StyledOutcomeRecording.Link.Icon />
                </StyledOutcomeRecording.Link>
            </UI.Text>
            <StyledOutcomeRecording.List aria-label="Outcome recording options">
                {status === "loading" && <SkeletonLoader height="40px" />}
                {status === "success" && (
                    <OutcomeLoadingContext.Provider
                        value={{ isLoading, setIsLoading }}
                    >
                        {data.map((label) => {
                            return (
                                <li key={`${label.code}`}>
                                    <OutcomeLabelButton
                                        conversation={conversation}
                                        labelName={label.name}
                                        labelCode={label.code}
                                        onSuccess={onSelectOutcome}
                                    />
                                </li>
                            );
                        })}
                    </OutcomeLoadingContext.Provider>
                )}
            </StyledOutcomeRecording.List>
        </UI.Flex>
    );
};
