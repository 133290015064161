import { Ds, Feedback, Text } from "@accurx/design";
import { Link, useLocation } from "react-router-dom";

export const ReplyFailedFeedback = () => {
    const location = useLocation();
    const currentURL = location.pathname + location.search;
    return (
        <Feedback
            title={
                <Ds.Flex justifyContent="space-between" alignItems="flex-start">
                    <Text variant="label">
                        An error occurred when trying to send your message.
                    </Text>
                    <Ds.Button appearance="tertiary" title="Close alert">
                        <Ds.Button.Icon name="Cross" aria-label="Close alert" />
                    </Ds.Button>
                </Ds.Flex>
            }
            colour="error"
        >
            <Ds.Flex flexDirection="column">
                <Text skinny>
                    Go back to your message and try sending it again. If this
                    keeps happening, please get in touch with our support team.
                </Text>
                <Link to={currentURL} style={{ width: "fit-content" }}>
                    <Text skinny colour="blue" as="span">
                        Go to message
                    </Text>
                </Link>
            </Ds.Flex>
        </Feedback>
    );
};
