import { useState } from "react";

import { RegisterAccountProductType } from "@accurx/api/account";
import { User } from "@accurx/auth";
import { Ds, Flex } from "@accurx/design";
import { Redirect, useHistory, useLocation } from "react-router-dom";

import { formatSearch } from "app/account/Account.helper";
import { LoginPageLayout } from "app/layout/LoginPageLayout/LoginPageLayout";
import { ROUTES } from "shared/Routes";
import { useAppSelector } from "store/hooks";

import { ActiveDirectoryRedirectForm } from "../../ActiveDirectoryRedirectForm";
import {
    LOGIN_LAYOUT_CONSTANTS,
    SHOW_LOGIN_FORM_SEARCH_PARAM,
} from "../LogIn.constants";
import { LoginForm } from "../LoginForm";
import { InnerStackPanel, OuterStackPanel } from "../LoginStackPanel";
import { FullWidthFlex, LogInOptionsButton } from "./Login.styles";
import { NhsMailLoginButtonLink } from "./NhsMailLogin";

export const Login = () => {
    const history = useHistory();
    const location = useLocation<{ email?: string }>();

    // Set default product in case none was present
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("product", `${RegisterAccountProductType.Fleming}`);

    const [externalOrgId, setExternalOrgId] = useState("");

    const showActiveDirectoryRedirect = Boolean(externalOrgId);
    const showLoginFormSearchParam = searchParams.get(
        SHOW_LOGIN_FORM_SEARCH_PARAM,
    );
    const showLoginForm = showLoginFormSearchParam === "true";
    const defaultEmail = location.state?.email;

    const searchPreLogin = formatSearch({
        search: searchParams.toString(),
        paramsToRemove: [SHOW_LOGIN_FORM_SEARCH_PARAM],
    });

    const searchPostLogin = formatSearch({
        search: searchParams.toString(),
        paramsToRemove: [SHOW_LOGIN_FORM_SEARCH_PARAM, "product"],
    });

    const isLoggedIn = useAppSelector(({ account }) => account.isLoggedIn);

    if (isLoggedIn) {
        return (
            <Redirect
                to={{
                    ...location,
                    pathname: ROUTES.postlogin,
                    search: searchPostLogin,
                }}
            />
        );
    }

    const onLoginSuccess = ({ organisationExternalId }: User) => {
        if (organisationExternalId) {
            setExternalOrgId(organisationExternalId);
        } else {
            window.location.href = `${ROUTES.postlogin}${searchPostLogin}`;
        }
    };

    return (
        <LoginPageLayout {...LOGIN_LAYOUT_CONSTANTS.defaultTrust}>
            <OuterStackPanel>
                <Ds.Flex
                    flexDirection="column"
                    gap="3"
                    alignItems="center"
                    className="w-100"
                >
                    <Ds.Text size="xxlarge" as="h1">
                        Welcome
                    </Ds.Text>
                    <InnerStackPanel horizontalContentAlignment="stretch">
                        {showActiveDirectoryRedirect ? (
                            <ActiveDirectoryRedirectForm
                                externalOrgId={externalOrgId}
                                onBack={() => setExternalOrgId("")}
                            />
                        ) : showLoginForm ? (
                            <Flex flexDirection="column" gap="2">
                                <LogInOptionsButton
                                    theme="secondary"
                                    icon={{
                                        name: "ArrowTail",
                                        rotation: "left",
                                    }}
                                    text="Log in options"
                                    onClick={() => {
                                        history.push(
                                            `${ROUTES.login}${searchPreLogin}`,
                                        );
                                    }}
                                />
                                <LoginForm
                                    product={RegisterAccountProductType.Fleming}
                                    defaultEmail={defaultEmail}
                                    onLoginSuccess={onLoginSuccess}
                                />
                            </Flex>
                        ) : (
                            <Flex
                                flexDirection="column"
                                gap="1"
                                alignItems="center"
                            >
                                <FullWidthFlex alignItems="center">
                                    <NhsMailLoginButtonLink
                                        nextRouteAfterLogin={{
                                            // Always redirect to /postlogin. 'next' search param is passed along and handled.
                                            route: ROUTES.postlogin,
                                            search: searchPostLogin,
                                        }}
                                    />
                                    <Ds.Text>
                                        Log in or sign up instantly with your
                                        nhs.net account
                                    </Ds.Text>
                                </FullWidthFlex>
                                <Ds.Text weight="bold">or</Ds.Text>
                                <Ds.Button
                                    appearance="secondary"
                                    fullwidth
                                    onClick={() => {
                                        history.push(
                                            `${ROUTES.login}${searchPreLogin}&${SHOW_LOGIN_FORM_SEARCH_PARAM}=true`,
                                        );
                                    }}
                                >
                                    Log in with email (nhs.uk)
                                </Ds.Button>
                            </Flex>
                        )}
                    </InnerStackPanel>
                    <Ds.Text weight="bold">
                        New to Accurx?{" "}
                        <Ds.Link to={`${ROUTES.register}${searchPreLogin}`}>
                            Create an account
                        </Ds.Link>
                    </Ds.Text>
                </Ds.Flex>
            </OuterStackPanel>
        </LoginPageLayout>
    );
};
