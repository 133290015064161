import { ChangeEvent, useRef, useState } from "react";

import { TeamSummary, UserSummary } from "@accurx/concierge/types";
import { Cell, Ds, Grid, Text, VisuallyHidden } from "@accurx/design";
import { useFuzzyFilter } from "@accurx/hooks";
import { Input } from "@accurx/inbox-design-library";
import { userflowIds } from "domains/inbox/util";

import { AssigneeListButtons } from "./AssigneeListButtons";
import {
    ASSIGNEE_SELECTOR_FORM_ID,
    AssigneeListRadioGroupForm,
} from "./AssigneeListRadioGroupForm";
import {
    StyledAssigneeLists,
    StyledAssigneeNoteTextArea,
    StyledContainer,
    StyledFeedback,
    StyledHeaderText,
    StyledHorizontalBorder,
    StyledIconWrapper,
    StyledNoteContainer,
    StyledOuterTextarea,
    StyledPopoverContentHeader,
    StyledPopoverFooter,
    StyledStickyHeader,
} from "./AssigneeSelectorForm.styles";
import { ValidAssigneeSummary } from "./AssigneeSelectorForm.types";

export type AssigneeSelectorFormProps = {
    currentAssignee?: ValidAssigneeSummary;
    users: UserSummary[];
    teams?: TeamSummary[];
    unassignedTeam?: TeamSummary;
    onCancel: () => void;
    onSubmit: (args: {
        assignee: ValidAssigneeSummary;
        noteText?: string;
    }) => void;
    onSelectedAssigneeChanged: (args: {
        assignee: ValidAssigneeSummary;
        noteText?: string;
    }) => void;
    isLoading?: boolean;
    /**
     * When false, it only allows reassignment
     */
    canAddNote: boolean;
};

export const AssigneeSelectorForm = ({
    currentAssignee,
    users: allUsers,
    teams: allTeams = [],
    unassignedTeam,
    onCancel,
    onSubmit,
    onSelectedAssigneeChanged,
    isLoading,
    canAddNote,
}: AssigneeSelectorFormProps): JSX.Element => {
    const assigneeListRef = useRef<HTMLLIElement | null>(null);

    const [searchTerm, setSearchTerm] = useState("");
    const [selectedAssignee, setSelectedAssignee] = useState<
        ValidAssigneeSummary | undefined
    >(currentAssignee);
    const [shouldShowNoteSection, setShouldShowNoteSection] = useState(false);
    const [noteText, setNoteText] = useState("");
    const [showNoAssigneeSelectedError, setShowNoAssigneeSelectedError] =
        useState(false);

    const allUnassigned = unassignedTeam ? [unassignedTeam] : [];

    /**
     * Apply filters to the unassigned team (wrapped in an array where it is the only
     * element) as well so that the search behaviour is maintained for the to
     * assign team.
     */
    const filteredUnassigned = useFuzzyFilter(allUnassigned, searchTerm, {
        keys: ["displayName"],
        threshold: 0.2,
    });
    const filteredTeams = useFuzzyFilter(allTeams, searchTerm, {
        keys: ["displayName"],
        threshold: 0.2,
    });
    const filteredUsers = useFuzzyFilter(allUsers, searchTerm, {
        keys: ["displayName"],
        threshold: 0.2,
    });

    const handleCheckedChange = (
        assigneeId: string,
        assigneeType: "User" | "Team",
    ) => {
        const assignee = {
            type: assigneeType,
            id: assigneeId,
        };

        setSelectedAssignee(assignee);

        setShowNoAssigneeSelectedError(false);

        // Once the user selects a different assignee, the note section pops up - it'll only disappear when the selector is closed
        setShouldShowNoteSection(true);

        onSelectedAssigneeChanged({ assignee, noteText });
    };

    const onClickAssignButton = () => {
        if (!selectedAssignee) {
            setShowNoAssigneeSelectedError(true);
            return;
        }

        onSubmit({
            assignee: selectedAssignee,
            noteText: noteText,
        });
    };

    return (
        <>
            <StyledStickyHeader>
                <StyledPopoverContentHeader>
                    <Text variant="label" skinny>
                        Assign this conversation
                    </Text>
                </StyledPopoverContentHeader>
                <StyledContainer>
                    <VisuallyHidden as="span">
                        <label htmlFor="assignee-list">
                            Search for assignee
                        </label>
                    </VisuallyHidden>
                    {/* This is to let screen reader users know this is a live search */}
                    <VisuallyHidden id="assignee-search-description">
                        Results will update as you type.
                    </VisuallyHidden>
                    <Input
                        isSearchInput
                        aria-describedby="assignee-search-description"
                        value={searchTerm}
                        id="assignee-list"
                        name="assignee-list"
                        onChange={(e) => {
                            e.preventDefault();
                            setSearchTerm(e.target.value);

                            // reset scroll position
                            if (assigneeListRef.current) {
                                assigneeListRef.current.scrollTop = 0;
                            }
                        }}
                        placeholder="Search for a colleague or team"
                    />
                    {showNoAssigneeSelectedError && (
                        <StyledFeedback
                            props={{ id: "no-assignee-error" }}
                            title="Select an assignee to continue."
                            colour="error"
                        />
                    )}
                </StyledContainer>
                <StyledHorizontalBorder />
            </StyledStickyHeader>
            <StyledAssigneeLists ref={assigneeListRef}>
                {canAddNote ? (
                    <AssigneeListRadioGroupForm
                        onClickAssignButton={onClickAssignButton}
                        showNoAssigneeSelectedError={
                            showNoAssigneeSelectedError
                        }
                        onCheckedChange={handleCheckedChange}
                        unassignedTeams={filteredUnassigned}
                        teams={filteredTeams}
                        users={filteredUsers}
                        selectedAssignee={selectedAssignee}
                        currentAssignee={currentAssignee}
                    />
                ) : (
                    <AssigneeListButtons
                        unassignedTeams={filteredUnassigned}
                        users={filteredUsers}
                        teams={filteredTeams}
                        currentAssignee={currentAssignee}
                        onAssign={(assignee) => {
                            setSelectedAssignee(assignee);
                            onSelectedAssigneeChanged({ assignee });
                        }}
                    />
                )}

                {filteredUnassigned.length === 0 &&
                    filteredTeams.length === 0 &&
                    filteredUsers.length === 0 && (
                        <StyledHeaderText skinny variant="note" colour="metal">
                            No results for '{searchTerm}'
                        </StyledHeaderText>
                    )}

                <VisuallyHidden>
                    <div aria-live="assertive" aria-atomic="true">
                        {filteredUnassigned.length === 0 &&
                            filteredTeams.length === 0 &&
                            filteredUsers.length === 0 &&
                            `No users or teams match your search`}
                    </div>
                </VisuallyHidden>
            </StyledAssigneeLists>
            <Grid as="li">
                {canAddNote && shouldShowNoteSection && (
                    <>
                        <StyledHorizontalBorder />
                        <StyledNoteContainer>
                            <Ds.Flex flexDirection="column" gap="0.5">
                                <StyledOuterTextarea>
                                    <StyledAssigneeNoteTextArea
                                        form={ASSIGNEE_SELECTOR_FORM_ID}
                                        value={noteText}
                                        onChange={(
                                            e: ChangeEvent<HTMLTextAreaElement>,
                                        ) => {
                                            setNoteText(e.target.value);
                                        }}
                                        aria-label="Assignment note"
                                        maxLength={2000}
                                        placeholder="Write your note here..."
                                    />
                                </StyledOuterTextarea>
                                <Ds.Flex alignItems="center">
                                    <StyledIconWrapper>
                                        <Ds.Icon
                                            name="LockLocked"
                                            size="small"
                                            color="metal"
                                            appearance="solid"
                                        />
                                    </StyledIconWrapper>
                                    <Text
                                        skinny
                                        variant="preview"
                                        colour="metal"
                                    >
                                        Only visible to colleagues in your
                                        workspace
                                    </Text>
                                </Ds.Flex>
                            </Ds.Flex>
                        </StyledNoteContainer>
                    </>
                )}

                {canAddNote && (
                    <Cell>
                        <StyledPopoverFooter justifyContent="space-between">
                            <Ds.Flex.Item>
                                <Ds.Button
                                    appearance="secondary"
                                    onClick={onCancel}
                                    size="small"
                                >
                                    Cancel
                                </Ds.Button>
                            </Ds.Flex.Item>

                            <Ds.Flex.Item>
                                <Ds.Button
                                    loading={isLoading}
                                    data-userflow-id={
                                        userflowIds.conversationView
                                            .confirmAssignButton
                                    }
                                    size="small"
                                    type="submit"
                                    form={ASSIGNEE_SELECTOR_FORM_ID}
                                >
                                    <Ds.Button.Icon
                                        name="Assign"
                                        appearance="solid"
                                    />
                                    Assign
                                </Ds.Button>
                            </Ds.Flex.Item>
                        </StyledPopoverFooter>
                    </Cell>
                )}
            </Grid>
        </>
    );
};
