import { MessagePatientRequestBase } from "@accurx/api/patient-messaging";
import { NhsNumberHelpers } from "@accurx/shared";
import { SendMessageMutationVariables } from "domains/message-component/hooks/mutations/useSendMessageMutation";

import { mapAssigneeToAsigneeDto } from "./mapAssigneeToAssigneeDto";
import { mapSelfBookToSelfBookDto } from "./mapSelfBookToSelfBookDto";
import { mapTemplateToMessageTemplateDto } from "./mapTemplateToMessageTemplateDto";

export const mapSendMessageVariablesToBaseSendMessageRequest = ({
    composedMessage,
    options,
}: SendMessageMutationVariables): Required<MessagePatientRequestBase> => {
    return {
        // Patient details
        nhsNumber:
            options.patientExternalIds.find(
                (id) =>
                    id.type === "NhsNumber" &&
                    NhsNumberHelpers.validateNhsNumber(id.value).success ===
                        true,
            )?.value ?? null,

        // Patient contacts
        mobileNumber:
            composedMessage.contactDetails.method === "Mobile"
                ? composedMessage.contactDetails.value
                : null,
        emailAddress:
            composedMessage.contactDetails.method === "Email"
                ? composedMessage.contactDetails.value
                : null,

        // Clinician info
        organisationId: options.workspaceId,
        assignee: mapAssigneeToAsigneeDto(composedMessage.assignee),

        // Send info
        ticketIdentity: options.ticketIdentity,
        trySendViaNhsApp: options.trySendViaNhsApp,
        sendTime:
            composedMessage.sendAt &&
            composedMessage.sendAt.sendAtDateTime !== ""
                ? composedMessage.sendAt.sendAtDateTime
                : null,

        // Message content
        messageBody: composedMessage.messageBody,
        attachedDocumentIds: composedMessage.attachments
            .filter(
                (att) => att.status === "success" && att.origin === "Upload",
            )
            .map((att) => att.id),
        messageFooter: composedMessage.messageSignature,
        messageTemplate: mapTemplateToMessageTemplateDto({
            template: composedMessage.template,
            attachments: composedMessage.attachments,
        }),
        conditionId:
            composedMessage.template.type === "QuestionnaireTemplate"
                ? composedMessage.template.value.id
                : null,
        conditionName:
            composedMessage.template.type === "QuestionnaireTemplate"
                ? composedMessage.template.value.title
                : null,
        enablePatientResponse: composedMessage.isPatientResponseEnabled,
        selfBook: mapSelfBookToSelfBookDto(composedMessage.selfBookLink),
        nhsChoicesLink: composedMessage.nhsAdviceLink,

        // Ignored
        isVideoConsult: false,
        videoConsultTime: null,
        clientMessageId: null,
        patientListId: null,
        patientListEntryId: null,
    };
};
