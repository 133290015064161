import { useCallback } from "react";

import { api } from "domains/concierge/internal/api/ticket";
import { useConciergeMeta } from "domains/concierge/internal/context";
import {
    ConversationGroupFetcher,
    useConversationGroupQuery,
} from "domains/concierge/internal/hooks/useConversationGroupQuery";
import { ConversationGroupHook } from "domains/concierge/internal/types/ConversationGroupHook";
import { filters } from "domains/concierge/internal/util/conversationGroupFilters";
import { sortOptions } from "domains/concierge/internal/util/conversationGroupSortOptions";

export const useAllFailedConversationGroupQuery: ConversationGroupHook<
    void
> = () => {
    const { workspaceId } = useConciergeMeta();

    const fetcher = useCallback<ConversationGroupFetcher>(
        async (continuationToken) => {
            const { updates, response } = await api.getFolderTicketView({
                workspaceId: workspaceId,
                folder: { viewType: "Failed" },
                continuationToken,
            });

            return {
                continuationToken: response.continuationToken ?? undefined,
                updates,
            };
        },
        [workspaceId],
    );

    return useConversationGroupQuery({
        filters: filters.allFailed(),
        sortOptions: sortOptions.allFailed(),
        loggingInfo: {
            name: "All failed conversations",
            tags: {},
        },
        fetcher,
    });
};
