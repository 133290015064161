import { useRef } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useCurrentUser, useCurrentWorkspace } from "@accurx/auth";
import { useUser } from "@accurx/concierge/hooks/data/useUser";
import {
    Attachment,
    ClinicianMessageItem,
    Conversation,
    Participant,
    WorkspaceParticipant,
} from "@accurx/concierge/types";
import {
    Ds,
    Text,
    Tokens,
    VisuallyHidden,
    focusBorderStyles,
} from "@accurx/design";
import { Log } from "@accurx/shared";
import {
    ConversationItemBadgeContainer,
    ConversationItemContainer,
    ConversationItemContent,
    ConversationItemFooter,
    ConversationItemHeader,
    ConversationThreadListItem,
} from "domains/inbox/components/ConversationItem/ConversationItem.styles";
import { CreatedAtTime } from "domains/inbox/components/ConversationItem/components/CreatedAtTime/CreatedAtTime";
import { FreeText } from "domains/inbox/components/ConversationItem/components/FreeText/FreeText";
import {
    AttachmentsContainerWithLabel,
    ItemAttachment,
    ItemAttachmentsList,
} from "domains/inbox/components/ConversationItem/components/ItemAttachments";
import { MessageNameTag } from "domains/inbox/components/ConversationItem/components/MessageNameTag/MessageNameTag";
import { CopyToClipboardButton } from "domains/inbox/components/ConversationItem/components/SaveItemAction/CopyToClipboardButton";
import { ConversationItemComponent } from "domains/inbox/components/ConversationItem/types";
import { getItemAlignment } from "domains/inbox/components/ConversationView/components/ConversationThread/conversationThread.utils";
import { useItemAttachmentAnalytics } from "domains/inbox/hooks/util/useItemAttachmentAnalytics";
import { useWorkspaceActivityAnalytics } from "domains/inbox/hooks/util/useWorkspaceActivityAnalytics";
import { formatUserDisplayName } from "domains/inbox/util/format/names";
import { getConversationParticipantForAnalytics } from "domains/inbox/util/getConversationParticipantForAnalytics";
import { getConversationServerId } from "domains/inbox/util/getConversationServerId";
import styled from "styled-components";

import {
    ActivityIndicator,
    TextOverflowWrapper,
    WorkspaceActivityTooltipProvider,
} from "../../../ConversationView/components/WorkspaceActivityTooltipProvider/WorkspaceActivityTooltipProvider";

const { SIZES, BORDERS, COLOURS } = Tokens;

type UserNameTagProps = {
    createdBy: Extract<
        ClinicianMessageItem["createdBy"],
        { type: "WorkspaceUser" }
    >;
};

const StethascopeBadge = styled(Ds.Badge).attrs({ color: "greyscale" })`
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;

    svg {
        height: 24px;
        width: 24px;
    }
`;

const FooterRightContainer = styled(Ds.Flex).attrs({ alignItems: "center" })`
    gap: 4px;
`;

const Summary = styled.summary`
    display: inline-flex;
    align-items: center;
    list-style: none;
    border-radius: ${BORDERS.radius};
    border: 1px solid transparent;
    outline: none;
    padding: ${SIZES[0.5]} ${SIZES[1]} ${SIZES[0.5]} 0px;
    color: ${COLOURS.greyscale.metal};

    &:hover {
        color: ${COLOURS.greyscale.night};
    }
    &:focus,
    &:active {
        ${focusBorderStyles}
    }

    &::-webkit-details-marker {
        display: none;
    }
`;

const Details = styled.details`
    ${Summary} svg {
        transform: rotate(-90deg);
    }

    &[open] ${Summary} svg {
        transform: rotate(0deg);
    }
`;

const TrimmedContent = styled(FreeText)`
    border-left: 4px solid ${COLOURS.greyscale.silver};
    padding-left: ${SIZES[2]};
    margin-left: ${SIZES[1]};
    margin-top: ${SIZES[1]};
    color: ${COLOURS.greyscale.metal};
`;

const Badge = styled(Ds.Badge)`
    width: calc(100%);
`;

const BadgeWrapper = styled.div`
    position: relative;
    overflow: hidden;
    padding: 4px;
`;

const UserNameTag = ({ createdBy }: UserNameTagProps) => {
    const workspaceId = useCurrentWorkspace().orgId;
    const user = useUser({ userId: createdBy.userId });

    const displayName = formatUserDisplayName({
        user,
        considerCurrentUser: false,
    });

    return (
        <MessageNameTag
            avatar={
                createdBy.workspaceId === workspaceId.toString() ? (
                    <Ds.Avatar
                        name={displayName}
                        size="small"
                        color="greyscale"
                    />
                ) : (
                    <StethascopeBadge>
                        <Ds.Icon name="Doctor" size="xsmall" />
                    </StethascopeBadge>
                )
            }
            name={displayName}
        />
    );
};

type ContactNameTagProps = {
    createdBy: Extract<ClinicianMessageItem["createdBy"], { type: "Contact" }>;
};
const ContactNameTag = ({ createdBy }: ContactNameTagProps) => {
    return (
        <>
            <MessageNameTag
                avatar={
                    <Ds.Avatar
                        name={createdBy.displayName}
                        size="small"
                        color="greyscale"
                    />
                }
                name={createdBy.displayName}
            />
        </>
    );
};

const isWorkspaceParticipant = (
    participant: Participant,
): participant is WorkspaceParticipant => {
    return participant.type === "Workspace";
};

type SenderBadgeProps = {
    createdBy: ClinicianMessageItem["createdBy"];
    conversation: Conversation;
};
const SenderBadge = ({
    createdBy,
    conversation,
}: SenderBadgeProps): JSX.Element => {
    const workspaceId = useCurrentWorkspace().orgId;
    const { user } = useCurrentUser();
    const nameRef = useRef<HTMLDivElement>(null);
    const { trackWorkspaceStaffAvailabilityHover } =
        useWorkspaceActivityAnalytics({ location: "message" });

    switch (createdBy.type) {
        case "WorkspaceUser":
            if (createdBy.workspaceId === workspaceId.toString()) {
                // If it's from someone in the users workspace the badge reads "Staff"
                return (
                    <ConversationItemBadgeContainer>
                        <Ds.Badge color="greyscale">Staff</Ds.Badge>
                    </ConversationItemBadgeContainer>
                );
            } else {
                const workspaceParticipant = conversation.participants
                    .filter(isWorkspaceParticipant)
                    .find(
                        (participant) =>
                            participant.workspaceId.toString() ===
                            createdBy.workspaceId,
                    );

                // If it's from someone in a different workspace the badge says the workspace name
                return (
                    <ConversationItemBadgeContainer>
                        <WorkspaceActivityTooltipProvider
                            nameRef={nameRef}
                            participant={workspaceParticipant ?? null}
                            onTooltipOpen={({ workspaceStatus }) => {
                                trackWorkspaceStaffAvailabilityHover({
                                    workspaceStatus,
                                });
                            }}
                        >
                            {({ workspaceStatus }) => {
                                const isActive =
                                    !!workspaceStatus?.activity &&
                                    workspaceStatus.activity.daysSinceOnline <
                                        1;
                                return (
                                    <BadgeWrapper>
                                        <Badge color="greyscale">
                                            <TextOverflowWrapper ref={nameRef}>
                                                <Text
                                                    variant="note"
                                                    skinny
                                                    as="span"
                                                >
                                                    {workspaceParticipant?.displayName ??
                                                        "Unknown workspace"}
                                                </Text>
                                                {isActive && (
                                                    <Text
                                                        variant="preview"
                                                        skinny
                                                        as="span"
                                                    >
                                                        {" "}
                                                        (online)
                                                    </Text>
                                                )}
                                            </TextOverflowWrapper>
                                        </Badge>
                                        <ActivityIndicator
                                            isActive={isActive}
                                        />
                                    </BadgeWrapper>
                                );
                            }}
                        </WorkspaceActivityTooltipProvider>
                    </ConversationItemBadgeContainer>
                );
            }

        case "Contact":
            if (user.email === createdBy.emailAddress) {
                // If it's from an email contact
                return (
                    <ConversationItemBadgeContainer>
                        <Ds.Badge color="greyscale">Staff</Ds.Badge>
                    </ConversationItemBadgeContainer>
                );
            }

            return <></>;
    }
};

const getAttachmentUrl = ({
    workspaceId,
    conversation,
    attachment,
}: {
    workspaceId: number;
    conversation: Conversation;
    attachment: Attachment;
}) => {
    const conversationId = getConversationServerId(conversation);
    const attachmentName = attachment.displayName ?? "";
    const unencodedUrl = `/api/clinicianmessaging/workspaces/${workspaceId}/conversation/${conversationId}/attachment/${attachment.id}/${attachmentName}`;

    try {
        return encodeURI(unencodedUrl);
    } catch (e) {
        Log.error(e as Error, { tags: { product: "Inbox" } });
        return "";
    }
};

export const ClinicianMessage = ({
    item,
    conversation,
}: ConversationItemComponent<ClinicianMessageItem>) => {
    const workspaceId = useCurrentWorkspace().orgId;
    const { user } = useCurrentUser();
    const track = useAnalytics();
    const {
        trackConversationAttachmentPreviewLinkClick,
        trackConversationAttachmentRemoveButtonClick,
        trackConversationAttachmentRemoveMenuItemClick,
    } = useItemAttachmentAnalytics({ conversation });

    const isCurrentUserMessage =
        item.createdBy.type === "WorkspaceUser" &&
        item.createdBy.userId === user.accuRxUserId &&
        item.createdBy.workspaceId === workspaceId.toString();

    const handleCopyToClipboard = () => {
        track("MessageCopy Button Click", {
            eventVersion: 2,
            conversationParticipant:
                getConversationParticipantForAnalytics(conversation),
            productOrigin: "Inbox",
            accessType: "Browser",
            isModal: false,
            isCurrentUserMessage,
        });

        return item.body;
    };

    return (
        <ConversationThreadListItem
            $align={getItemAlignment({
                item,
                currentWorkspaceId: workspaceId,
                currentUser: user,
            })}
        >
            <ConversationItemContainer
                data-testid={`conversation-item-${item.id}`}
                $color={"white"}
            >
                <ConversationItemHeader>
                    {item.createdBy.type === "WorkspaceUser" && (
                        <UserNameTag createdBy={item.createdBy} />
                    )}
                    {item.createdBy.type === "Contact" && (
                        <ContactNameTag createdBy={item.createdBy} />
                    )}
                    <SenderBadge
                        createdBy={item.createdBy}
                        conversation={conversation}
                    />
                </ConversationItemHeader>
                <ConversationItemContent>
                    <Ds.Flex flexDirection="column" gap="3">
                        <Ds.Flex.Item>
                            <FreeText>{item.body}</FreeText>
                        </Ds.Flex.Item>
                        {!!item.attachments.length && (
                            <Ds.Flex.Item>
                                <AttachmentsContainerWithLabel>
                                    <ItemAttachmentsList>
                                        {item.attachments.map(
                                            (attachment, index) => (
                                                <ItemAttachment
                                                    key={attachment.id}
                                                    allowDelete={false}
                                                    fileName={
                                                        attachment.displayName ??
                                                        `Attachment ${
                                                            index + 1
                                                        }`
                                                    }
                                                    href={getAttachmentUrl({
                                                        attachment,
                                                        workspaceId,
                                                        conversation,
                                                    })}
                                                    onPreview={
                                                        trackConversationAttachmentPreviewLinkClick
                                                    }
                                                    onDeleteClick={
                                                        trackConversationAttachmentRemoveButtonClick
                                                    }
                                                    onDeleteConfirm={({
                                                        reason,
                                                    }) => {
                                                        trackConversationAttachmentRemoveMenuItemClick(
                                                            reason,
                                                        );
                                                    }}
                                                />
                                            ),
                                        )}
                                    </ItemAttachmentsList>
                                </AttachmentsContainerWithLabel>
                            </Ds.Flex.Item>
                        )}
                        <Ds.Flex flexDirection="column" gap="1">
                            <Ds.Flex.Item>
                                <CopyToClipboardButton
                                    getText={handleCopyToClipboard}
                                />
                            </Ds.Flex.Item>
                            {!!item.trimmedContent && (
                                <Details aria-label="Show trimmed content">
                                    <Summary>
                                        <Ds.Icon name="Arrow" size="small" />
                                        Show trimmed content
                                    </Summary>
                                    <TrimmedContent>
                                        {item.trimmedContent}
                                    </TrimmedContent>
                                </Details>
                            )}
                        </Ds.Flex>
                    </Ds.Flex>
                </ConversationItemContent>
                <ConversationItemFooter>
                    <div />
                    <FooterRightContainer>
                        {item.readStatus === "Delivered" && (
                            <Ds.Flex.Item>
                                <Ds.Icon
                                    size="small"
                                    name="TickDouble"
                                    color="stone"
                                />
                                <VisuallyHidden>
                                    Message delivered
                                </VisuallyHidden>
                            </Ds.Flex.Item>
                        )}
                        {item.readStatus === "Read" && (
                            <Ds.Flex.Item>
                                <Ds.Icon
                                    size="small"
                                    name="TickDouble"
                                    color="green"
                                />
                                <VisuallyHidden>Message read</VisuallyHidden>
                            </Ds.Flex.Item>
                        )}
                        <Ds.Flex.Item>
                            <CreatedAtTime time={item.createdAt} />
                        </Ds.Flex.Item>
                    </FooterRightContainer>
                </ConversationItemFooter>
            </ConversationItemContainer>
        </ConversationThreadListItem>
    );
};
