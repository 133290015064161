import {
    EventUserType,
    LoggedInCustomProperties,
    SharedRudderStackAnalyticProps,
} from "@accurx/shared";

export type AnalyticsSearchPatientOrigin = {
    searchPatientOrigin: string; // route path of origin
};

export type AnalyticsPatientId = {
    patientId: string | null;
};

export type FlemingAnalyticsProductType =
    | "sms"
    | "video-consult"
    | "record-access"
    | "message-practice"
    | "none"
    | "unknown";

export type ProductSelection = {
    Product: FlemingAnalyticsProductType;
};

export type FlemingAnalyticsPatientListsEnabled = {
    isFeaturePatientListEnabled: boolean;
};

export type FlemingWorkspaceSpecialties = {
    workspaceSpecialtyList: string[] | null;
};

export type IsTestPatientProps = {
    isTestPatient: boolean;
};

export type OriginProps = {
    origin: string;
};

export type HasErrorProps = {
    hasError: boolean;
};

export type FlemingCustomProperties = FlemingWorkspaceSpecialties;

export type FlemingLoggedInCustomProperties = LoggedInCustomProperties &
    FlemingCustomProperties;

export type SharedLoggedInRudderStackAnalyticProps =
    FlemingLoggedInCustomProperties & SharedRudderStackAnalyticProps;

export function AddRudderStackAnalyticProps<T>(
    props: T & FlemingLoggedInCustomProperties,
    sharedRudderstackProps: Partial<SharedRudderStackAnalyticProps> = {},
): T & SharedLoggedInRudderStackAnalyticProps {
    const {
        eventVersion = 1,
        eventUserType = EventUserType.HealthcareProfessional,
        userIsLoggedIn = true,
    } = sharedRudderstackProps;
    return {
        ...props,
        eventVersion,
        eventUserType,
        userIsLoggedIn,
    };
}

export function AddSuccess<T>(
    props: T & SharedLoggedInRudderStackAnalyticProps,
): T & SharedLoggedInRudderStackAnalyticProps & HasErrorProps {
    return {
        ...props,
        hasError: false,
    };
}

export function AddError<T>(
    props: T & SharedLoggedInRudderStackAnalyticProps,
): T & SharedLoggedInRudderStackAnalyticProps & HasErrorProps {
    return {
        ...props,
        hasError: true,
    };
}
