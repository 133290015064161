import React, { useEffect, useMemo, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import {
    FeatureName,
    Organisation,
    isFeatureEnabledForWorkspace,
    useCurrentUser,
    useCurrentWorkspace,
} from "@accurx/auth";
import { Button, Feedback, Input, Text } from "@accurx/design";
import { useFuzzyFilter } from "@accurx/hooks";
import { AutoFocusHeading, useAccurxWebTitle } from "@accurx/navigation";
import { isUserCreatedWorkspace } from "@accurx/workspace-management";
import { Link } from "react-router-dom";

import { IAllowedNhsOrganisationResponse } from "api/FlemingDtos";
import { useGetAllowedNhsOrganisationsQuery } from "app/hooks/queries/useGetAllowedNhsOrganisationsQuery";
import { SkeletonList } from "app/sharedComponents/loadingSkeleton/SkeletonList";
import {
    StyledCardItem,
    StyledCardList,
    StyledMain,
    StyledPageWrapper,
    StyledWorkspaceDetails,
} from "app/workspace/pages/shared/Layout/Layout.styles";
import { ROUTES } from "shared/Routes";

import { AllowedOrganisationCard } from "./AllowedOrganisationCard";
import { JoinedOrganisationCard } from "./JoinedOrganisationCard";

const REDIRECT_TO_ADD_ORGANISATION = {
    pathname: ROUTES.addOrganisation,
    state: { showBackButton: true },
};

const PAGE_TITLE = "Select an organisation to view workspaces in";

export const SelectOrganisationPage = () => {
    useAccurxWebTitle(PAGE_TITLE);

    const { user } = useCurrentUser();
    const { nationalCode } = useCurrentWorkspace();
    const track = useAnalytics();

    const [searchTerm, setSearchTerm] = useState("");

    const {
        data: allAllowedOrganisations = [],
        isLoading: isLoadingAllowedOrganisations,
    } = useGetAllowedNhsOrganisationsQuery();

    useEffect(() => {
        if (!isLoadingAllowedOrganisations) {
            track("AccountOrganisation Page View", {
                onboarding: false,
                hasPreselectedOrg: false,
                pageOrigin: "OrganisationWorkspaceFlow",
            });
        }
    }, [isLoadingAllowedOrganisations, track]);

    const joinedOrganisations = useMemo(() => {
        const currentDefaultWorkspace = user.organisations.find(
            (org) =>
                !isUserCreatedWorkspace(org) &&
                org.nationalCode === nationalCode,
        );
        return [
            // Order current default organisation first
            ...(currentDefaultWorkspace ? [currentDefaultWorkspace] : []),
            ...user.organisations.filter(
                (org) =>
                    !isUserCreatedWorkspace(org) &&
                    org.nationalCode !== nationalCode,
            ),
        ];
    }, [user, nationalCode]);

    const filteredJoinedOrganisations = useFuzzyFilter<Organisation>(
        joinedOrganisations,
        searchTerm,
        {
            threshold: 0.5,
            keys: ["organisationName"],
        },
    );

    const existingNationalCodes = joinedOrganisations.map(
        (org) => org.nationalCode,
    );

    const allowedOrganisations = allAllowedOrganisations.filter(
        (org) => !existingNationalCodes.includes(org.nationalCode),
    );

    const filteredAllowedOrganisations =
        useFuzzyFilter<IAllowedNhsOrganisationResponse>(
            allowedOrganisations,
            searchTerm,
            {
                threshold: 0.5,
                keys: ["organisationName"],
            },
        );

    const hasNoSearchResults = filteredAllowedOrganisations.length === 0;

    const hasFlexibleWorkspacesEnabled = (organisation: Organisation) =>
        isFeatureEnabledForWorkspace(
            FeatureName.FlexibleWorkspace,
            organisation,
        );

    const trackAddOrgButtonClick = (e: React.MouseEvent) => {
        track("AccountOrganisationSelect Button Click", {
            onboarding: false,
            pageOrigin: "OrganisationWorkspaceFlow",
            organisationActionSelected: "Add",
        });
    };

    return (
        <StyledPageWrapper flexDirection="column">
            <StyledMain gap="3" flexDirection="column">
                <AutoFocusHeading>{PAGE_TITLE}</AutoFocusHeading>
                <Input
                    isSearchInput
                    placeholder="Search organisations"
                    aria-label="Search organisations"
                    disabled={isLoadingAllowedOrganisations}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                {isLoadingAllowedOrganisations ? (
                    <SkeletonList quantity={3} />
                ) : (
                    <>
                        {hasNoSearchResults && (
                            <Feedback colour="information">
                                No search results, please change your criteria
                                or add an organisation.
                            </Feedback>
                        )}
                        <StyledCardList>
                            {filteredJoinedOrganisations.map((org) => (
                                <JoinedOrganisationCard
                                    key={org.orgId}
                                    orgId={org.orgId}
                                    name={org.organisationName}
                                    hasFlexibleWorkspacesEnabled={hasFlexibleWorkspacesEnabled(
                                        org,
                                    )}
                                    nationalCode={org.nationalCode}
                                />
                            ))}
                            {filteredAllowedOrganisations.map((org) => (
                                <AllowedOrganisationCard
                                    key={org.nationalCode}
                                    allowedOrganisation={org}
                                />
                            ))}
                            <StyledCardItem as="li">
                                <Text variant="label" skinny>
                                    Organisation not listed
                                </Text>
                                <StyledWorkspaceDetails>
                                    <Link
                                        to={REDIRECT_TO_ADD_ORGANISATION}
                                        onClick={trackAddOrgButtonClick}
                                    >
                                        <Button
                                            as="span"
                                            theme="secondary"
                                            text="Add an organisation"
                                            icon={{
                                                name: "Plus",
                                                colour: "blue",
                                            }}
                                        />
                                    </Link>
                                </StyledWorkspaceDetails>
                            </StyledCardItem>
                        </StyledCardList>
                    </>
                )}
            </StyledMain>
        </StyledPageWrapper>
    );
};
