import React, { useEffect, useState } from "react";

import { Feedback, Spinner, Text } from "@accurx/design";
import { DateHelpers } from "@accurx/shared";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { LoadingStatus } from "shared/LoadingStatus";

import { SessionSummary } from "../../models/VaccineRecordingDTO";
import { getAllVaccineSessions } from "../VaccineRecording.actions";
import { VaccineAllSessionsSubMenu } from "../vaccineSubMenus/VaccineAllSessionsSubMenu";
import { VaccineRecordingSessionCard } from "./VaccineRecordingSessionCard.component";

export const VaccineRecordingAllSessions = ({
    noHeader = false,
}: {
    noHeader?: boolean;
}): JSX.Element => {
    const dispatch = useDispatch();
    const { selectedOrgId: orgId } = useSelectedOrganisation();

    const allStoreSessions = useSelector(
        ({ vaccineRecording }: ApplicationState) =>
            vaccineRecording.allSessions || null,
    );
    const allStoreSessionsStatus = useSelector(
        ({ vaccineRecording }: ApplicationState) =>
            vaccineRecording.allSessionsStatus || LoadingStatus.Initial,
        shallowEqual,
    );

    const [showPastSessions, setShowPastSessions] = useState(false);

    const [sessionsList, setSessionsList] = useState<SessionSummary[]>([]);
    const [sessionsLoading, setSessionsLoading] = useState<boolean>(true);

    useEffect(
        function initialSessionsListFetch() {
            if (orgId && allStoreSessionsStatus === LoadingStatus.Initial) {
                dispatch(getAllVaccineSessions({ orgId: String(orgId) }));
            }
        },
        [dispatch, orgId, allStoreSessionsStatus],
    );

    useEffect(
        function setLocalStateSessionsList() {
            allStoreSessions && setSessionsList(allStoreSessions);
        },
        [allStoreSessions],
    );

    useEffect(
        function setLocalStateSessionsLoadingList() {
            setSessionsLoading(
                allStoreSessionsStatus === LoadingStatus.Loading,
            );
        },
        [allStoreSessionsStatus],
    );

    const renderSessionCard = (session: SessionSummary): JSX.Element | null => {
        const isPast = DateHelpers.isBeforeToday(session.startTime);

        if (isPast && !showPastSessions) {
            return null;
        }

        return (
            <VaccineRecordingSessionCard
                key={session.sessionId}
                session={session}
            />
        );
    };

    const renderSessionsLoaded = (): JSX.Element => {
        // No content
        if (sessionsList.length === 0) {
            return (
                <Text as="h2" variant="subtitle">
                    No sessions configured
                </Text>
            );
        }

        return (
            <>
                <div
                    style={{
                        width: "100%",
                        margin: "auto",
                        position: "relative",
                        display: "flex",
                        flexFlow: "row wrap",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div style={{ flexGrow: 1 }}>
                        <Text variant="label" props={{ className: "m-2" }}>
                            Filters
                        </Text>
                        <div className="d-flex align-items-center m-2">
                            <input
                                type="checkbox"
                                id="showPastSessionsCheckbox"
                                data-testid="showPastSessionsCheckbox"
                                onChange={() =>
                                    setShowPastSessions(
                                        (prevState) => !prevState,
                                    )
                                }
                                checked={showPastSessions}
                            />
                            <Text
                                as="label"
                                props={{
                                    htmlFor: "showPastSessionsCheckbox",
                                    className: "ml-2",
                                }}
                                skinny
                            >
                                Show past clinics
                            </Text>
                        </div>
                    </div>
                </div>
                {sessionsList.map((session) => renderSessionCard(session))}
            </>
        );
    };

    const renderViewStates = (): JSX.Element => {
        // TODO: remove this once all actions are connected to the store
        if (sessionsLoading) {
            return <Spinner />;
        }

        switch (allStoreSessionsStatus) {
            case LoadingStatus.Loaded:
                return renderSessionsLoaded();

            case LoadingStatus.Failed:
                return (
                    <Feedback
                        title="Sessions failed to load"
                        content="Please refresh the page to try again"
                    />
                );

            case LoadingStatus.Initial:
            case LoadingStatus.Loading:
            default:
                return <Spinner />;
        }
    };

    return (
        <div className="row" data-testid="all-sessions">
            {!noHeader && <VaccineAllSessionsSubMenu />}
            <div className="col-md-8">{renderViewStates()}</div>
        </div>
    );
};

export default VaccineRecordingAllSessions;
