import { MessageTemplate, Template } from "@accurx/message-templates";
import { TemplateItem } from "domains/message-component/types";
import isEqual from "lodash/isEqual";

import { TemplateGroup } from "../types/TemplateItem";
import { ListItem } from "./ListItem";
import {
    StyledHyphenatedText,
    StyledTemplateListSection,
} from "./TemplateListSection.styles";

type TemplateListSectionProps = {
    categories: TemplateGroup[];
    owner: Template["owner"];
    onClickTemplate: (template: TemplateItem) => void;
    onClickPreview: (template: TemplateItem) => void;
    loadingTemplate?: TemplateItem;
};

export const TemplateListSection = ({
    categories,
    owner,
    onClickTemplate,
    onClickPreview,
    loadingTemplate,
}: TemplateListSectionProps) => {
    const sectionHeading = getDisplaySectionHeading({
        owner: owner,
    });

    const sectionHeadingId = `${owner}-templates-heading`;

    return (
        <>
            <StyledTemplateListSection.Heading>
                <StyledHyphenatedText
                    size="small"
                    color="zinc"
                    weight="bold"
                    forwardedAs="h3"
                    id={sectionHeadingId}
                >
                    {sectionHeading}
                </StyledHyphenatedText>
            </StyledTemplateListSection.Heading>
            <StyledTemplateListSection.List aria-labelledby={sectionHeadingId}>
                {categories.map(({ title, items }, index) => {
                    const headingId = `${title
                        .split(" ")
                        .join("-")}-templates-heading`;

                    return (
                        <li key={`${title}-${index}`}>
                            {title && (
                                <StyledTemplateListSection.SubHeading>
                                    <StyledHyphenatedText
                                        size="small"
                                        color="zinc"
                                        weight="bold"
                                        forwardedAs="h4"
                                        id={headingId}
                                    >
                                        {title}
                                    </StyledHyphenatedText>
                                </StyledTemplateListSection.SubHeading>
                            )}
                            <StyledTemplateListSection.List
                                aria-labelledby={headingId}
                            >
                                {items.map((item, i) => (
                                    <ListItem
                                        onClick={() => onClickTemplate(item)}
                                        templateType={item.type}
                                        templateTitle={item.value.title}
                                        key={`${item.value.id}-${i}`}
                                        onClickPreview={() =>
                                            onClickPreview(item)
                                        }
                                        isLoading={isEqual(
                                            loadingTemplate,
                                            item,
                                        )}
                                    />
                                ))}
                            </StyledTemplateListSection.List>
                        </li>
                    );
                })}
            </StyledTemplateListSection.List>
        </>
    );
};

const getDisplaySectionHeading = ({
    owner,
}: {
    owner: MessageTemplate["owner"];
}): string => {
    switch (owner) {
        case "User":
            return "Created by you";
        case "Organisation":
            return "Created by your workspace";
        case "Accurx":
            return "Created by Accurx";
    }
};
