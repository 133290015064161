import { PatientExternalId } from "@accurx/concierge/types";
import { Ds } from "@accurx/design";
import { Feedback } from "@accurx/inbox-design-library";
import { useCurrentContactDetailSelection } from "@accurx/message-component";
import { useSmsDeliveryPredictionQuery } from "domains/compose/pages/compose/hooks/queries/useSmsDeliveryPredictionQuery";

export const SmsPredictionFeedback = ({
    patientExternalIds,
}: {
    patientExternalIds: PatientExternalId[];
}) => {
    const contactDetailSelected = useCurrentContactDetailSelection();

    const queryResult = useSmsDeliveryPredictionQuery({
        patientExternalIds,
        mobileNumber: contactDetailSelected.value,
        enabled:
            contactDetailSelected.method === "Mobile" &&
            contactDetailSelected.origin === "EMR",
    });

    if (
        queryResult.status === "success" &&
        queryResult.data.isMessageLikelyToFail === true
    ) {
        return (
            <Feedback colour="warning">
                <Ds.Text size="small" weight="regular" as="span" color="zinc">
                    Messages have previously failed to send to this number.
                    Consider using a different contact method.
                </Ds.Text>
            </Feedback>
        );
    } else {
        return null;
    }
};
