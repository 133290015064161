import { forwardRef } from "react";

import { StyledBlockListItem } from "@accurx/design/dist/components/Popover/Popover.styles";
import { Link, LinkProps } from "react-router-dom";

import { StyledPopoverItemLink } from "../../styles/Popover.styles";

/**
 * Custom Popover item component with React Router Link support
 */
export const PopoverItemLink = forwardRef<HTMLAnchorElement, LinkProps>(
    (props, forwardedRef) => {
        return (
            <StyledBlockListItem>
                <StyledPopoverItemLink
                    ref={forwardedRef}
                    as={Link}
                    {...props}
                />
            </StyledBlockListItem>
        );
    },
);
