import { ReactNode } from "react";

import { useAuth } from "@accurx/auth";
import { NativeTransportProvider } from "@accurx/native";
import { useOptionalHubClient } from "domains/realtime/hooks/useOptionalHubClient";
import { useSignalRConnectionState } from "domains/realtime/hooks/useSignalRConnectionState";

import { useResubscribeToNativeSubscriptions } from "../hooks/useResubscribeToNativeSubscriptions";
import { NativeSubscriptionsProvider } from "./NativeSubscriptionProvider";
import { RealtimeContext } from "./RealtimeContext";

const RealtimeContextProvider = ({ children }: { children: ReactNode }) => {
    const { user } = useAuth();

    const hubClient = useOptionalHubClient({
        currentUserId: user.isLoggedIn ? user.accuRxUserId : null,
    });
    const connectionState = useSignalRConnectionState(hubClient);

    useResubscribeToNativeSubscriptions(connectionState);

    return (
        <RealtimeContext.Provider value={{ connectionState, hubClient }}>
            {children}
        </RealtimeContext.Provider>
    );
};

export const RealtimeProvider = ({
    workspaceId,
    children,
}: {
    workspaceId: number;
    children: ReactNode;
}) => {
    return (
        <NativeTransportProvider>
            <NativeSubscriptionsProvider workspaceId={workspaceId}>
                <RealtimeContextProvider>{children}</RealtimeContextProvider>
            </NativeSubscriptionsProvider>
        </NativeTransportProvider>
    );
};
