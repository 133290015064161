import { useCurrentWorkspace } from "@accurx/auth";
import { ButtonLink, Ds } from "@accurx/design";
import { useParams } from "react-router";

import { useGetQuestionnaireQuery } from "app/hooks/queries";

export const FloreyBuilderExportAsJson = () => {
    const { orgId } = useCurrentWorkspace();

    const { questionnaireId } = useParams<{ questionnaireId: string }>();

    const { data, status } = useGetQuestionnaireQuery({
        workspaceId: orgId,
        questionnaireId,
    });

    const blob = new Blob([JSON.stringify(data)], { type: "text/json" });
    const downloadUrl = window.URL.createObjectURL(blob);

    return (
        <>
            {status === "loading" && <Ds.Spinner />}
            {status === "success" && (
                <ButtonLink
                    href={downloadUrl}
                    download={`${data.name}.json`}
                    text="Click to download"
                />
            )}
            {status === "error" && (
                <Ds.Text>Failed to load questionnaire</Ds.Text>
            )}
        </>
    );
};
