export enum SignalRSendEvent {
    ConnectedStatus = "ConnectedStatus",
    OnConnectedInfo = "OnConnectedInfo",
    RegisterForReconnectPush = "RegisterForReconnectPush",
    SendThreadActive = "SendThreadActive",
    SendTyping = "SendTyping",
}

export enum SignalRSubscriptionEvent {
    OnInviteStatusChanged = "OnInviteStatusChanged",
    OnLatestHubConnectInbox = "OnLatestHubConnectInbox",
    OnMachineChangeStatus = "OnMachineChangeStatus",
    OnPatientThreadItemChanged = "OnPatientThreadItemChanged",
    OnPatientThreadNoteTyping = "OnPatientThreadNoteTyping",
    OnThreadActive = "OnThreadActive",
    OnUserGroupChanged = "OnUserGroupChanged",
    OnVideoConsultationStatusChanged = "OnVideoConsultationStatusChanged",
    OrganisationEntityChanged = "OrganisationEntityChanged",
    UserAdded = "UserAdded",
    UserApprovalChanged = "UserApprovalChanged",
    UserRoleChanged = "UserRoleChanged",
}
