import { useCallback, useEffect, useRef } from "react";

import { SignalRSendEvent, useRealtimeForSignalR } from "@accurx/realtime";
import { Log } from "@accurx/shared";
import { useConciergeMeta } from "domains/concierge/internal/context";

/**
 * When SignalR reconnects, we want to register the client to receive
 * a SignalR push of all unread item IDs added in the past 12 hours
 * to calculate if there have been any updates that has been missed.
 */
export const useUnreadItemsOnReconnect = () => {
    const { workspaceId } = useConciergeMeta();
    const { send, connectionState } = useRealtimeForSignalR();
    const previousConnection = useRef(connectionState);

    const registerForUnreadItemsOnReconnect = useCallback((): void => {
        void send({
            type: SignalRSendEvent.RegisterForReconnectPush,
            payload: {
                workspaceId,
            },
        });

        Log.info("SignalR reconnect: registered to receive unread items");
    }, [send, workspaceId]);

    useEffect(() => {
        if (previousConnection.current === connectionState) return;

        if (
            previousConnection.current === "Disconnected" &&
            connectionState === "Connected"
        ) {
            registerForUnreadItemsOnReconnect();
        }

        // update previous connection ref
        previousConnection.current = connectionState;
    }, [connectionState, registerForUnreadItemsOnReconnect]);
};
