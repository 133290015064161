import React, { useEffect, useRef } from "react";

import { Ds } from "@accurx/design";
import { Drawer } from "domains/inbox/components/Drawer/Drawer";
import { useLayout } from "domains/inbox/components/Layout/context";
import { useViewportSize } from "domains/inbox/hooks/util";
import { useLocation } from "react-router";

import {
    StyledCloseButtonWrapper,
    StyledLeftPaneContainer,
} from "./LeftPane.styles";

export type LeftPaneProps = { children: React.ReactNode };

export const LeftPane = ({ children }: LeftPaneProps) => {
    const { leftPane } = useLayout();
    const leftPaneRef = useRef(leftPane);

    const size = useViewportSize();
    const { pathname } = useLocation();

    useEffect(() => {
        leftPaneRef.current = leftPane;
    }, [leftPane]);

    /**
     * Close the left pane drawer when the route changes
     */
    useEffect(() => {
        leftPaneRef.current.setIsOpen(false);
    }, [pathname]);

    if (size !== "lg" && size !== "xl") {
        // For smaller and medium size screens, we want the sidebar to slide(in/out) from the left side of the screen, we use Drawer a component that uses React.Portal to load the sidebar outside the App element.
        return (
            <Drawer placement="left" {...leftPane}>
                <StyledLeftPaneContainer>
                    {leftPane.isOpen && (
                        <StyledCloseButtonWrapper justifyContent="flex-end">
                            <Ds.Button
                                appearance="secondary"
                                title="Close sidebar"
                                onClick={leftPane.toggle}
                            >
                                <Ds.Button.Icon
                                    name="Cross"
                                    id="close-sidebar"
                                    aria-label="Close sidebar"
                                />
                            </Ds.Button>
                        </StyledCloseButtonWrapper>
                    )}
                    {children}
                </StyledLeftPaneContainer>
            </Drawer>
        );
    } else {
        // On desktop the sidebar sits nicely in the grid and is shown at all times
        return <StyledLeftPaneContainer>{children}</StyledLeftPaneContainer>;
    }
};
