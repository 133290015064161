import { useRealtimeForSignalR } from "@accurx/realtime";
import { Log } from "@accurx/shared";
import { mapConversationServerIdToConversationId } from "domains/concierge/internal/api/clinicianMessaging/mappers/mapConversationServerIdToConversationId";
import { fetchConversationUpdates } from "domains/concierge/internal/api/shared/pollers/useConversationPoller";
import { mapTicketIdentityToConversationId } from "domains/concierge/internal/api/ticket/mappers/mapTicketIdentityToConversationId";
import {
    useConciergeDispatch,
    useConciergeMeta,
} from "domains/concierge/internal/context";
import { actions } from "domains/concierge/internal/store";
import { Conversation } from "domains/concierge/types";

/**
 * Can be used to manually refetch the conversation.
 * i.e. when there is a conversation action error or
 * when signalR is not connected
 */
export const useForceConversationRefreshQuery = () => {
    const { workspaceId } = useConciergeMeta();
    const dispatch = useConciergeDispatch();
    const { connectionState } = useRealtimeForSignalR();

    const refreshConversation = async ({
        conversationSource,
    }: {
        conversationSource: Conversation["source"];
    }) => {
        const conversationId =
            conversationSource.system === "Ticket"
                ? mapTicketIdentityToConversationId(
                      conversationSource.ticketIdentity,
                  )
                : mapConversationServerIdToConversationId(
                      conversationSource.serverId,
                  );

        Log.debug("Refreshing conversation", {
            tags: {
                product: "Inbox",
                conversationId,
                connectionState,
            },
        });

        const updates = await fetchConversationUpdates(
            conversationSource,
            workspaceId,
        );
        dispatch(
            actions.processUpdates({
                source: "ForcedRefetch:Conversation",
                ...updates,
            }),
        );
    };

    return refreshConversation;
};
