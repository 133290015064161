import { Button, SingleSelect, Table } from "@accurx/design";
import styled from "styled-components";

export const StyledSelect = styled(SingleSelect)`
    width: 30rem;
`;

// Standard Visibility Hidden styles
// TODO: to move out to accurx-design as shared styling.
export const StyledVisibilityHidden = styled.span`
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
`;

export const StyledTableBody = styled(Table.Body)`
    vertical-align: top;
`;

export const StyledButton = styled(Button)`
    max-width: fit-content;
`;
