import { HTMLAttributes, useMemo, useRef } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useMatchTicketToPatientMutation } from "@accurx/concierge/hooks/mutations/useMatchTicketToPatientMutation";
import { Conversation } from "@accurx/concierge/types";
import { Feedback } from "@accurx/design";
import { PatientDemographics } from "@accurx/native";
import { mapPatientDemographicsToPatientSearchResult } from "domains/inbox/components/PatientSearch/utils";
import { useConversationAnalyticsFields } from "domains/inbox/hooks/util/useConversationAnalyticsFields";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

import { SuggestedPatientMatchResultCard } from "./SuggestedPatientMatchResultCard";
import { StyledList } from "./SuggestedPatientMatches.styles";

type PatientExternalIds =
    PatientDemographics["patientExternalIdentityDto"]["patientExternalIds"];
type Props = {
    conversation: Conversation;
    onMatchConfirmSuccess?: () => void;
    patients: PatientDemographics[];
    props?: HTMLAttributes<HTMLUListElement>;
};

export const SuggestedMatchedPatientList = ({
    conversation,
    onMatchConfirmSuccess,
    patients,
    props: listProps = {},
}: Props) => {
    const isLoadingId = useRef<string>();
    const track = useAnalytics();
    const trackingFields = useConversationAnalyticsFields({
        conversation,
    });

    const { mutate, status } = useMatchTicketToPatientMutation({
        onSuccess: onMatchConfirmSuccess,
        onSettled: (_, error) => {
            track("ConversationPatientMatchSelect Button Response", {
                ...trackingFields,
                matchType: "Suggested",
                hasError: Boolean(error),
            });

            isLoadingId.current = undefined;
        },
    });

    const mappedPatients = useMemo(
        () =>
            patients.map((patient) => ({
                ...mapPatientDemographicsToPatientSearchResult(patient),
                id: uuid(),
            })),
        [patients],
    );

    const matchTicketToPatient = (
        id: string,
        patientExternalIds?: PatientExternalIds,
    ): void => {
        isLoadingId.current = id;

        mutate(
            {
                conversation,
                patientExternalIds,
            },
            {
                onError: () =>
                    toast(
                        <Feedback
                            title="Couldn't select patient. Please try again."
                            colour="error"
                        />,
                    ),
            },
        );
    };

    if (mappedPatients.length === 0) return null;
    return (
        <StyledList {...listProps}>
            {mappedPatients.map(({ id, ...patient }) => (
                <li key={id}>
                    <SuggestedPatientMatchResultCard
                        patient={patient}
                        buttonProps={{
                            onClick: () => {
                                track(
                                    "ConversationPatientMatchSelect Button Click",
                                    {
                                        ...trackingFields,
                                        matchType: "Suggested",
                                        eventVersion: 2,
                                    },
                                );

                                matchTicketToPatient(id, patient.externalIds);
                            },
                            isLoading:
                                status === "loading" &&
                                isLoadingId.current === id,
                            disabled: status === "loading",
                        }}
                    />
                </li>
            ))}
        </StyledList>
    );
};
