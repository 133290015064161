import {
    CreateTemplate,
    Owner,
    SendAvailability,
    UpdateTemplate,
} from "@accurx/api/content";
import { TemplateCategory } from "@accurx/api/patient-messaging";
import { AttachmentStatus, Option } from "@accurx/design";
import compact from "lodash/compact";
import sortBy from "lodash/sortBy";

import { MessageTemplateOwner } from "api/FlemingDtos";

import { Template } from "../manageTemplates/ManageTemplates.types";
import {
    FormState,
    FormTemplateAttachment,
} from "./ManageTemplatesFormPage.types";

export const MAX_TEMPLATE_NAME_LENGTH = 50;
export const MAX_BODY_LENGTH = 450;
export const MAX_MESSAGE_FRAGMENT_LENGTH = 612;
export const MESSAGE_LENGTH_COUNT_START =
    MAX_MESSAGE_FRAGMENT_LENGTH - MAX_BODY_LENGTH;
export const MESSAGE_INTRO = "Dear [Patient Name],";
export const MESSAGE_OUTRO = "Thanks,";

export const ALLOWED_FILE_EXTENSIONS = [
    "pdf",
    "docx",
    "doc",
    "rtf",
    "jpeg",
    "jpg",
    "png",
    "tiff",
    "tiff2",
    "tif",
];

export const ALLOWED_FILE_SIZE = { size: 3145728, humanReadable: "3MB" };
export const ALLOWED_NUMBER_OF_FILES = 1;

export const toRequestBody = (
    values: FormState,
): CreateTemplate | UpdateTemplate => {
    const sendAvailability = [];

    if (values.allowAsSms) {
        sendAvailability.push(SendAvailability.Individual);
    }

    if (values.allowAsBatch) {
        sendAvailability.push(SendAvailability.Batch);
    }

    return {
        title: values.templateName,
        body: values.body,
        owner:
            values.owner === MessageTemplateOwner.Organisation
                ? Owner.Workspace
                : Owner.User,
        category:
            values.categorySelect === CATEGORIES_EMPTY_OPTION
                ? ""
                : (values.categorySelect.label as string),
        allowReplyByDefault: values.allowReplyByDefault,
        sendAvailability,
        snomedCodes: values.snomedCode ? [values.snomedCode] : [],
        attachments: compact(
            values.attachments.map(({ id, fileName, fileSize, status }) => {
                if (status === AttachmentStatus.Success) {
                    return {
                        id: id as number,
                        fileName: fileName as string,
                        fileSize,
                    };
                }

                return null;
            }),
        ),
    };
};

export const generateCategoryForDropdown = (category: string): Option => {
    if (category === "") {
        return CATEGORIES_EMPTY_OPTION;
    }
    const parts = category
        .split("/")
        .map((name) => name.trim()) // trim each sub-category
        .filter((name) => !!name); // don't allow empty sub-categories

    return { label: parts.join(" > "), value: parts.join("/") };
};

export const generateCategoryFromTemplatePath = (
    templatePath: Template["path"] | undefined,
): string => {
    if (!templatePath) {
        return "";
    }
    return templatePath?.folders
        .map((folder) => (folder.name ? folder.name.trim() : ""))
        .join("/");
};

export const generateCategoryDropdownFromTemplatePath = (
    templatePath: Template["path"],
): Option => {
    return generateCategoryForDropdown(
        generateCategoryFromTemplatePath(templatePath),
    );
};

export const toOption = (category: TemplateCategory): Option => ({
    label: category.heading ?? undefined,
    value: category.path?.folders
        ? category.path.folders.map((part) => part.name || "").join("/")
        : "",
});

export const toOptions = (
    categories: TemplateCategory[] | undefined,
): Option[] => [...sortBy(categories, "heading").map(toOption)];

export const CATEGORIES_RESERVED_VALUE = "No category";
export const CATEGORIES_EMPTY_OPTION = {
    label: "No category",
    value: CATEGORIES_RESERVED_VALUE,
} as const;

export const createNewOption = (inputValue: string): Option => {
    const sanitisedValue = inputValue.trim().replace(/(\s*\/\s*)+/g, "/");
    return {
        value: sanitisedValue,
        label: sanitisedValue.replace(/\//g, " > "),
    };
};

export const hasDuplicateInList = (
    options: Option[],
    item: string,
): boolean => {
    // including case insensitve duplicates with extra spacing
    const arrayOfSanitisedValues = options.map((option) =>
        option?.value.trim().toLowerCase(),
    );
    return arrayOfSanitisedValues.includes(item.trim().toLowerCase());
};

type MapToAttachmentProps = {
    file: File;
    attachmentId: string;
    dataUrl?: string;
    errors: string[];
    status: AttachmentStatus;
};
export const mapDataToAttachment = (
    props: MapToAttachmentProps,
): FormTemplateAttachment => {
    return {
        id: props.attachmentId ? parseInt(props.attachmentId, 10) : undefined,
        fileName: props.file.name,
        fileSize: props.file.size,
        fileType: props.file.type,
        previewUrl: props.dataUrl,
        errors: props.errors,
        status: props.status,
    };
};

// Used as a clientside Attachment Id for handling removing items from the list
// Type is required to be same as that of the serverside attachment id
// An integer, but in string format
export const generateClientsideAttachmentId = (): string => {
    return Date.now().toString();
};
