import { useRef, useState } from "react";

import { useFeatureFlag } from "@accurx/auth";
import * as UI from "@accurx/design";
import { QuestionnaireTemplate } from "@accurx/message-templates";
import {
    useMedicalRecordConnection,
    useUploadPatientRecordAttachmentMutation,
} from "@accurx/native";
import { QuickViewPortal } from "@accurx/quick-view";
import { SelfbookConfigurationQuickView } from "@accurx/self-book";
import { DateHelpers } from "@accurx/shared";
import { PatientRecordAttachmentSelector } from "domains/message-component/components/Compose/components/Attach/components/PatientRecordAttachmentSelector/PatientRecordAttachmentSelector";
import { useCompose } from "domains/message-component/context";
import { useCharacterCount } from "domains/message-component/hooks/useCharacterCount";
import { Attachment } from "domains/message-component/reducer.types";
import { NHSAdviceLink } from "domains/message-component/types";
import { getNativeUploadErrorMessage } from "domains/message-component/utils";
import { formatPatientName } from "domains/message-component/utils/format/formatPatientName";
import { mapSelfBookFormDataToSelfBookLink } from "domains/message-component/utils/mapSelfBookFormDataToSelfBookLink";

import {
    StyledContainer,
    StyledFlexGrowContainer,
    StyledScrollableFixedHeightContainer,
} from "./Compose.styles";
import { ComposeActionStack } from "./ComposeActionStack";
import { formatPatientRecordAttachmentName } from "./components/Attach/components/PatientRecordAttachmentSelector/formatPatientRecordAttachmentName";
import { ComposeTextArea } from "./components/ComposeTextArea/ComposeTextArea";
import { ContactDetailSelector } from "./components/ContactDetailSelector/ContactDetailSelector";
import { ExpandMinimiseBar } from "./components/ExpandMinimiseBar/ExpandMinimiseBar";
import { MessageActionsControlBar } from "./components/MessageActionsControlBar/MessageActionsControlBar";
import { Templates } from "./components/MessageTemplates/Templates";
import { NHSAdviceSelector } from "./components/NhsAdviceSelector/NhsAdviceSelector";
import { RecipientSelector } from "./components/RecipientSelector/RecipientSelector";
import { ScheduledSendInfo } from "./components/ScheduledSendInfo/ScheduledSendInfo";
import { SendMessage } from "./components/SendMessage/SendMessage";
import { StillLoadingErrors } from "./components/StillLoadingErrors/StillLoadingErrors";
import type { ComposeProps } from "./types";

export const Compose = ({
    contactDetails = [],
    assigneeLabel = "You",
    assigneeSelector,
    patient,
    patientMatchesCurrentMedicalRecordPatient,
    onMessageSend,
    isMessageSending,
    onUserTyping,
    onMinimiseClick,
    isHeightRestricted,
    onExpandClick,
    setAssignee,
}: ComposeProps) => {
    const connection = useMedicalRecordConnection();
    const attachSelectorButtonRef = useRef<HTMLButtonElement>(null);

    const isRecipientSelectorEnabled = useFeatureFlag(
        "ComposeRecipientSelector",
    );

    const canSaveToRecord =
        connection.status === "Connected" &&
        connection.capabilities.saveToRecord === true;

    const { state, dispatch } = useCompose();
    const uploadPatientRecordAttachment =
        useUploadPatientRecordAttachmentMutation();

    const [showPatientRecordAttachments, setShowPatientRecordAttachments] =
        useState(false);
    const [showSelfbookConfiguration, setShowSelfbookConfiguration] =
        useState(false);
    const [showNhsAdviceSelector, setShowNhsAdviceSelector] = useState(false);

    const selfBookLinkSelected = state.selfBookLink !== null;

    const { characterCount, fragmentCount, isUnicode } = useCharacterCount();

    const { size: viewPort } = UI.Hooks.useViewportSize();
    const isMobileSize = viewPort === "xs" || viewPort === "sm";

    const onSelectNHSAdvice = (nhsAdviceLink: NHSAdviceLink) => {
        dispatch({ type: "ADD_NHS_ADVICE_LINK", payload: { nhsAdviceLink } });
        setShowNhsAdviceSelector(false);
    };

    const onQuestionnaireSelected = (questionnaire: QuestionnaireTemplate) => {
        if (questionnaire.defaultUserGroupId) {
            setAssignee?.({
                type: "Team",
                id: questionnaire.defaultUserGroupId.toString(),
            });
        } else {
            setAssignee?.("DEFAULT");
        }
    };

    return (
        <StyledContainer>
            <ExpandMinimiseBar
                onMinimiseClick={onMinimiseClick}
                onExpandClick={onExpandClick}
                isHeightRestricted={isHeightRestricted ?? false}
            >
                {isRecipientSelectorEnabled ? (
                    <RecipientSelector
                        patientExternalIds={patient.externalIds}
                        contactDetails={contactDetails}
                        shouldDisableEmail={
                            showSelfbookConfiguration || selfBookLinkSelected
                        }
                        forceOpenSide={isHeightRestricted ? "top" : "bottom"}
                    />
                ) : (
                    <ContactDetailSelector
                        contactDetails={contactDetails}
                        shouldDisableEmail={
                            showSelfbookConfiguration || selfBookLinkSelected
                        }
                    />
                )}
            </ExpandMinimiseBar>
            <StyledFlexGrowContainer>
                <UI.Grid rows="2">
                    <UI.Item>
                        <Templates
                            disabled={
                                showSelfbookConfiguration || state.isScheduling
                            }
                            patientExternalIds={patient.externalIds}
                            onQuestionnaireSelected={onQuestionnaireSelected}
                            onQuestionnaireRemoved={() => setAssignee?.(null)}
                        />
                    </UI.Item>
                </UI.Grid>
                <StyledScrollableFixedHeightContainer
                    $isHeightRestricted={isHeightRestricted || false}
                >
                    <ComposeTextArea onUserTyping={onUserTyping} />

                    <ComposeActionStack
                        assigneeLabel={
                            assigneeSelector ?? (
                                <UI.Ds.Badge
                                    radius="round"
                                    color="greyscale"
                                    data-testid="assignee-label"
                                >
                                    <strong>{assigneeLabel}</strong>
                                </UI.Ds.Badge>
                            )
                        }
                        canSaveToRecord={canSaveToRecord}
                    />
                </StyledScrollableFixedHeightContainer>
                <StillLoadingErrors />
                <UI.Flex flexDirection="column" gap="1.5">
                    <ScheduledSendInfo />
                    <UI.Flex
                        justifyContent="space-between"
                        flexWrap="wrap"
                        alignItems={isMobileSize ? "flex-end" : "flex-start"}
                        gap="1"
                    >
                        {/* The 100 flex grow here was the only way we could figure out how to get the save to record checkbox to sit nicely up against the send button. */}
                        <UI.Item flex="100">
                            <MessageActionsControlBar
                                showSelfbookConfiguration={
                                    showSelfbookConfiguration
                                }
                                setAssignee={setAssignee}
                                attachSelectorButtonRef={
                                    attachSelectorButtonRef
                                }
                                setShowPatientRecordAttachments={
                                    setShowPatientRecordAttachments
                                }
                                patient={patient}
                                setShowNhsAdviceSelector={
                                    setShowNhsAdviceSelector
                                }
                                setShowSelfbookConfiguration={
                                    setShowSelfbookConfiguration
                                }
                                canSaveToRecord={canSaveToRecord}
                            />
                        </UI.Item>

                        <UI.Item flex="1">
                            <SendMessage
                                patientMatchesCurrentMedicalRecordPatient={
                                    patientMatchesCurrentMedicalRecordPatient
                                }
                                patientExternalIds={patient.externalIds}
                                characterCount={characterCount}
                                fragmentCount={fragmentCount}
                                isUnicode={isUnicode}
                                onMessageSend={onMessageSend}
                                isLoading={isMessageSending}
                            />
                        </UI.Item>
                    </UI.Flex>
                </UI.Flex>
                <QuickViewPortal
                    isOpen={showPatientRecordAttachments}
                    onClose={() => setShowPatientRecordAttachments(false)}
                >
                    <PatientRecordAttachmentSelector
                        patientExternalIds={patient.externalIds}
                        patientName={formatPatientName({
                            firstName: patient.firstName,
                            familyName: patient.familyName,
                            prefixName: patient.prefixName,
                        })}
                        onSelect={(attachment) => {
                            setShowPatientRecordAttachments(false);

                            const attachmentName =
                                formatPatientRecordAttachmentName(attachment);
                            const tempAttachment: Attachment = {
                                id: attachment.documentId,
                                name: attachmentName,
                                origin: "Upload",
                                status: "loading",
                            };

                            dispatch({
                                type: "ADD_ATTACHMENTS",
                                payload: {
                                    attachments: [tempAttachment],
                                },
                            });

                            uploadPatientRecordAttachment.mutate(
                                {
                                    documentId: attachment.documentId,
                                    patientExternalIds: patient.externalIds,
                                },
                                {
                                    onError: (error) =>
                                        dispatch({
                                            type: "UPDATE_ATTACHMENT",
                                            payload: {
                                                attachmentId: tempAttachment.id,
                                                attachmentOrigin: "Upload",
                                                attachment: {
                                                    ...tempAttachment,
                                                    origin: "Upload",
                                                    status: "error",
                                                    error: getNativeUploadErrorMessage(
                                                        error,
                                                    ),
                                                },
                                            },
                                        }),
                                    onSuccess: (data) => {
                                        dispatch({
                                            type: "UPDATE_ATTACHMENT",
                                            payload: {
                                                attachmentId: tempAttachment.id,
                                                attachmentOrigin: "Upload",
                                                attachment: {
                                                    ...tempAttachment,
                                                    status: "success",
                                                    id: data.serverId,
                                                    previewUrl: data.previewUrl,
                                                },
                                            },
                                        });
                                    },
                                },
                            );
                        }}
                    />
                </QuickViewPortal>
                <SelfbookConfigurationQuickView
                    showSelfbookConfiguration={showSelfbookConfiguration}
                    sendAt={
                        state.sendAt?.sendAtDateTime ??
                        DateHelpers.getCurrentTimeStamp()
                    }
                    setShowSelfbookConfiguration={(showConfig: boolean) =>
                        setShowSelfbookConfiguration(showConfig)
                    }
                    onSelfbookConfigComplete={(selfbookConfigData) => {
                        const selfBookLink =
                            mapSelfBookFormDataToSelfBookLink(
                                selfbookConfigData,
                            );

                        dispatch({
                            type: "ADD_SELF_BOOK_LINK",
                            payload: {
                                selfBookLink,
                            },
                        });
                    }}
                />
                <QuickViewPortal
                    onClose={() => setShowNhsAdviceSelector(false)}
                    isOpen={showNhsAdviceSelector}
                    returnFocusRef={attachSelectorButtonRef}
                    autoFocus={false}
                >
                    <NHSAdviceSelector onSelectNHSAdvice={onSelectNHSAdvice} />
                </QuickViewPortal>
            </StyledFlexGrowContainer>
        </StyledContainer>
    );
};
