import {
    PatientThreadSummaryDetails,
    PatientThreadUser,
    PatientThreadUserGroupUserMembership,
    UserGroupType,
} from "@accurx/api/ticket";
import { mapUserGroupToTeamSummary } from "domains/concierge/internal/api/shared/mappers/mapUserGroupToTeamSummary";
import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";
import { isInstance } from "domains/concierge/internal/util/isInstance";
import { TeamSummary, UserSummary } from "domains/concierge/types";
import isNil from "lodash/isNil";

import * as TicketApiClient from "../TicketApiClient";

const mapUser = (
    dto: PatientThreadUser,
    status: UserSummary["status"],
): UserSummary | undefined => {
    if (isNil(dto.accuRxId) || dto.accuRxId.length === 0) {
        return undefined;
    }
    return {
        id: dto.accuRxId,
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        displayName: dto.name || dto.email || dto.accuRxId,
        canBeAssignedTo: status === "Approved",
        status,
    };
};

const mapTeam = (
    dto: PatientThreadUserGroupUserMembership,
): TeamSummary | undefined => {
    // teams of type PatientSingleResponse and FailedDeliveryReceipts cannot be
    // assigned to
    const canBeAssignedTo =
        dto.groupType !== UserGroupType.PatientSingleResponse &&
        dto.groupType !== UserGroupType.FailedDeliveryReceipts;

    return mapUserGroupToTeamSummary({
        id: dto.id,
        name: dto.name,
        isMember: dto.currentUserIsMember,
        type: dto.groupType,
        canBeAssignedTo,
    });
};

export const getInitialSummaries: ConciergeFetcher<
    { workspaceId: number },
    PatientThreadSummaryDetails
> = async (arg) => {
    const response = await TicketApiClient.fetchInitialSummaryDetails(
        arg.workspaceId,
    );

    const activeUsers = response.activeUsers ?? [];
    const pendingUsers = response.pendingUsers ?? [];
    const mappedUsers = [
        ...activeUsers.map((user) => mapUser(user, "Approved")),
        ...pendingUsers.map((user) => mapUser(user, "Unapproved")),
    ];

    const users = mappedUsers.filter(isInstance);

    const teams = (response.activeUserGroups ?? [])
        .map(mapTeam)
        .filter(isInstance);

    return {
        updates: {
            conversations: [],
            patients: [],
            users,
            teams,
        },
        response,
    };
};
