import React from "react";

import { FeatureName, useCurrentWorkspace } from "@accurx/auth";
import { Ds } from "@accurx/design";
import {
    isArchivedWorkspace,
    isPharmacy,
    isUserCreatedWorkspace,
    useWorkspaceUsersQuery,
} from "@accurx/workspace-management";
import { Redirect } from "react-router";
import { generatePath, matchPath, useLocation } from "react-router-dom";

import { LinkVariant, NavLink } from "app/layout/navigationMenu/navLink";
import { ROUTES, ROUTES_WORKSPACE } from "shared/Routes";
import { useIsFeatureEnabled } from "store/hooks";

import {
    SecondaryNavigationContent,
    SecondaryNavigationHeader,
} from "./SecondaryNavigation";
import { StyledContainer, StyledLinkList } from "./SecondaryNavigation.styles";

const WorkspaceNavigation = ({
    onClick,
}: {
    onClick: () => void;
}): JSX.Element => {
    const location = useLocation();
    const currentWorkspace = useCurrentWorkspace();
    const { orgId: workspaceId, settings } = currentWorkspace;

    const wayfinderFeaturesEnabled = useIsFeatureEnabled(FeatureName.Wayfinder);
    const isRecordViewFeatureEnabled = useIsFeatureEnabled(
        FeatureName.RecordAccessBeta,
    );
    const isAppointmentRemindersAlphaEnabled = useIsFeatureEnabled(
        FeatureName.AppointmentRemindersAlpha,
    );
    const isAppointmentRemindersEnabled = useIsFeatureEnabled(
        FeatureName.AppointmentRemindersUhl,
    );
    const isIntegratedClinicsEnabled = useIsFeatureEnabled(
        FeatureName.IntegratedClinicLists,
    );
    const isFlexibleWorkspaceEnabled = useIsFeatureEnabled(
        FeatureName.FlexibleWorkspace,
    );

    const isPendingRequestsBadgeEnabled =
        settings.isAdminUser &&
        (isPharmacy(currentWorkspace) ||
            isUserCreatedWorkspace(currentWorkspace));

    const { data: { unapprovedUsers = [] } = {} } = useWorkspaceUsersQuery(
        { workspaceId: Number(workspaceId) },
        { enabled: isPendingRequestsBadgeEnabled },
    );

    const showPendingRequestsBadge =
        isPendingRequestsBadgeEnabled && unapprovedUsers.length > 0;

    if (!workspaceId) {
        return <Redirect to={ROUTES.home} />;
    }

    const isPathActive = (path: string) =>
        Boolean(matchPath(location.pathname, path));

    const renderApprovalRequestsBadge = () =>
        showPendingRequestsBadge ? (
            <Ds.Badge
                radius="round"
                color="greyscale"
                luminosity={
                    isPathActive(ROUTES_WORKSPACE.workspaceUserManagement)
                        ? "high"
                        : "low"
                }
                data-testid="approval-requests-badge"
            >
                {unapprovedUsers.length}
            </Ds.Badge>
        ) : null;

    return (
        <StyledContainer>
            <SecondaryNavigationHeader>
                {isFlexibleWorkspaceEnabled ? "Workspace" : "Organisation"}{" "}
                settings
            </SecondaryNavigationHeader>
            <SecondaryNavigationContent>
                <StyledLinkList>
                    <li>
                        <NavLink
                            to={generatePath(
                                ROUTES_WORKSPACE.workspaceGeneralSettings,
                                { workspaceId },
                            )}
                            text="General"
                            variant={LinkVariant.Secondary}
                            onClick={onClick}
                            icon="Cog"
                        />
                    </li>
                    {currentWorkspace.settings.isApprovedUser && (
                        <li>
                            <NavLink
                                to={generatePath(
                                    ROUTES_WORKSPACE.workspaceUserManagement,
                                    { workspaceId },
                                )}
                                text="Users"
                                variant={LinkVariant.Secondary}
                                onClick={onClick}
                                icon="Person"
                                renderBadge={renderApprovalRequestsBadge}
                                userflowId="workspace-navigation-users"
                            />
                        </li>
                    )}
                    <li>
                        <NavLink
                            to={generatePath(
                                ROUTES_WORKSPACE.workspacePersonalTemplates,
                                { workspaceId },
                            )}
                            text="My templates"
                            variant={LinkVariant.Secondary}
                            onClick={onClick}
                            icon="Message"
                            userflowId="workspace-navigation-my-templates"
                        />
                    </li>
                    <li>
                        <NavLink
                            to={generatePath(
                                ROUTES_WORKSPACE.workspaceSharedTemplates,
                                { workspaceId },
                            )}
                            text="Shared templates"
                            variant={LinkVariant.Secondary}
                            onClick={onClick}
                            icon="Messages"
                            userflowId="workspace-navigation-shared-templates"
                        />
                    </li>
                    {isAppointmentRemindersAlphaEnabled &&
                        isAppointmentRemindersEnabled &&
                        isIntegratedClinicsEnabled && (
                            <li>
                                <NavLink
                                    to={generatePath(
                                        ROUTES_WORKSPACE.workspaceAppointmentReminders,
                                        { workspaceId },
                                    )}
                                    text="Appointments"
                                    variant={LinkVariant.Secondary}
                                    onClick={onClick}
                                    icon="Bell"
                                />
                            </li>
                        )}
                    {isRecordViewFeatureEnabled &&
                        !isArchivedWorkspace(currentWorkspace) && (
                            <li>
                                <NavLink
                                    to={generatePath(
                                        ROUTES_WORKSPACE.workspaceMedicalRecords,
                                        { workspaceId },
                                    )}
                                    text="Medical records"
                                    variant={LinkVariant.Secondary}
                                    onClick={onClick}
                                    icon="Record"
                                />
                            </li>
                        )}
                    <li>
                        <NavLink
                            to={generatePath(
                                ROUTES_WORKSPACE.workspaceDeliveryReceipts,
                                { workspaceId },
                            )}
                            text="Delivery receipts"
                            variant={LinkVariant.Secondary}
                            onClick={onClick}
                            icon="TickDouble"
                        />
                    </li>
                    {wayfinderFeaturesEnabled && (
                        <li>
                            <NavLink
                                to={generatePath(
                                    ROUTES_WORKSPACE.workspaceResources,
                                    { workspaceId },
                                )}
                                text="Resources"
                                variant={LinkVariant.Secondary}
                                onClick={onClick}
                                icon="Photo"
                            />
                        </li>
                    )}
                </StyledLinkList>
            </SecondaryNavigationContent>
        </StyledContainer>
    );
};

export { WorkspaceNavigation };
