import React from "react";

import {
    PatientListAppointment,
    PatientListPatientDetails,
} from "api/FlemingDtos";
import { StackPanel } from "app/sharedComponents/StackPanel";

import { useRestoreScrollPositionAfterCompose } from "../ComposeRoutesWithFullScreenPage/useRestoreScrollPositionAfterCompose";
import { getPatientFromPatientId } from "./PatientList.helper";
import { PatientEntryCard } from "./patientCard/PatientEntryCard";

type PatientListAppointmentsProps = {
    appointments: PatientListAppointment[];
    patientDetails: PatientListPatientDetails;
    isReadonly: boolean;
};

export const PatientListAppointments = ({
    appointments,
    patientDetails,
    isReadonly,
}: PatientListAppointmentsProps): JSX.Element => {
    useRestoreScrollPositionAfterCompose();

    return (
        <StackPanel gutter={1} data-testid="patientList">
            {appointments.map((apt) => {
                const patient = getPatientFromPatientId(
                    patientDetails,
                    apt.patientId,
                );
                if (!patient) return null;
                return (
                    <PatientEntryCard
                        patient={patient}
                        appointment={apt}
                        isReadonly={isReadonly}
                        key={apt.id}
                    />
                );
            })}
        </StackPanel>
    );
};
