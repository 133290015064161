import { useCurrentWorkspace } from "@accurx/auth";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";

import { getStringQuery } from "shared/RoutesHelper";

export const useImportToOrgPage: () => {
    returnOrg: string | null;
    shareId: string;
    orgId: string;
    orgName: string;
} = () => {
    const location = useLocation();
    const returnOrg = getStringQuery(location.search, "returnOrg");
    const { orgId, organisationName } = useCurrentWorkspace();
    const { shareId } = useParams<{ shareId: string }>();
    return {
        returnOrg,
        shareId,
        orgId: orgId.toString(),
        orgName: organisationName,
    };
};
