import React, { useEffect } from "react";

import { Link, StackPanel, Text } from "@accurx/design";

import { routeSummary } from "app/practices/floreyBuilder/constants/paths";

import { trackManageTemplatePageView } from "../../analytics/FlemingAnalytics";
import { useFlemingLoggedInAnalytics } from "../../sessionAnalytics/useFlemingLoggedInAnalytics";
import { TabIds } from "./ManageTemplatesOverview";

type OrganisationFloreysPanelProps = {
    orgId: string;
};
export const OrganisationFloreysPanel = ({
    orgId,
}: OrganisationFloreysPanelProps): JSX.Element => {
    const flemingLoggedInProperties = useFlemingLoggedInAnalytics();
    useEffect(() => {
        trackManageTemplatePageView({
            ...flemingLoggedInProperties,
            countTemplateCategories: 0, //there are no custom florey categories
            currentTab: TabIds.OrganisationFloreys,
            productOrigin: "PatientMessage",
        });
    }, [flemingLoggedInProperties]);
    return (
        <StackPanel gutter={3} orientation="vertical">
            <Text skinny>
                Questionnaires for your workspace that can be sent to patients.
            </Text>
            <Link
                href={routeSummary(orgId)}
                text="Go to Custom Questionnaires"
            />
        </StackPanel>
    );
};
