import { FeatureName, useFeatureFlag } from "@accurx/auth";
import { useAllUsers } from "@accurx/concierge/hooks/data/useAllUsers";
import { useFuzzyFilter } from "@accurx/hooks";
import { FocusRingSpacing } from "@accurx/navigation";
import { FlexList } from "domains/inbox/components/Sidebar/components/FlexList";
import { FolderLink } from "domains/inbox/components/Sidebar/components/FolderLink";
import { NoResults } from "domains/inbox/components/Sidebar/components/NoResults";
import { useInboxLink } from "domains/inbox/hooks/util";
import { useSidebarFolderClickAnalytics } from "domains/inbox/hooks/util/useSidebarFolderClickAnalytics";
import { ROUTES_INBOX } from "domains/inbox/routes";
import filter from "lodash/filter";
import sortBy from "lodash/sortBy";
import { useRouteMatch } from "react-router-dom";

import { TotalCountProvider } from "../ConversationCount/TotalCountProvider";
import { ConversationCountTooltip } from "../ConversationCountTooltip";

type ColleaguesListProps = {
    searchTerm?: string;
};

const useActiveColleagueId = () => {
    const match = useRouteMatch<{ colleagueId: string }>(
        ROUTES_INBOX.Colleagues,
    );
    return match?.params.colleagueId;
};

export const ColleaguesList = (props: ColleaguesListProps) => {
    const link = useInboxLink();
    const colleagues = useAllUsers({ excludeCurrentUser: true });
    const activeColleagueId = useActiveColleagueId();
    const trackFolderClick = useSidebarFolderClickAnalytics();

    const showPendingUsers = useFeatureFlag(
        FeatureName.UnifiedInboxPendingUsers,
    );

    const allowedStatuses = showPendingUsers
        ? ["Approved", "Unapproved"]
        : ["Approved"];

    const searchResults = useFuzzyFilter(
        sortBy(
            filter(colleagues, (c) => allowedStatuses.includes(c.status ?? "")),
            (t) => t.displayName.toLowerCase(),
        ),
        props.searchTerm ?? "",
        {
            keys: ["displayName"],
            threshold: 0.4,
        },
    );

    return (
        <div>
            {!!searchResults.length && (
                <FocusRingSpacing>
                    <FlexList gap="0.5" aria-label="Colleagues">
                        {searchResults.map((colleague) => (
                            <li
                                key={colleague.id}
                                data-autoscroll={
                                    colleague.id === activeColleagueId
                                        ? "true"
                                        : undefined
                                }
                            >
                                <TotalCountProvider.Colleague
                                    userId={colleague.id}
                                >
                                    {(total) => (
                                        <ConversationCountTooltip total={total}>
                                            <FolderLink
                                                text={colleague.displayName}
                                                to={link.to("Colleagues", {
                                                    colleagueId: colleague.id,
                                                })}
                                                isActive={
                                                    colleague.id ===
                                                    activeColleagueId
                                                }
                                                onClick={() =>
                                                    trackFolderClick({
                                                        folderName:
                                                            "Colleagues",
                                                        ownerUserId:
                                                            colleague.id,
                                                        conversationParticipant:
                                                            "WithPatient",
                                                    })
                                                }
                                            />
                                        </ConversationCountTooltip>
                                    )}
                                </TotalCountProvider.Colleague>
                            </li>
                        ))}
                    </FlexList>
                </FocusRingSpacing>
            )}
            {!searchResults.length && (
                <FocusRingSpacing>
                    <NoResults aria-live="assertive">
                        No results found
                    </NoResults>
                </FocusRingSpacing>
            )}
        </div>
    );
};
