import React, { useEffect, useState } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { Button, Card, Feedback, Icon, StackPanel, Text } from "@accurx/design";
import { Log, getApiEndpoint, httpClient } from "@accurx/shared";
import nexmoClient, { Application, Configuration, NXMCall } from "nexmo-client";
import { useHistory } from "react-router-dom";

import { MuteButton } from "./MuteButton";
import { NumPad } from "./NumPad";

type VonageTokenResponse = {
    jwt: string;
    mobileNumber: string;
    voipToken: string;
    url: string;
    nexmoApiUrl: string;
    ipsUrl: string;
    turnServers: TurnServer[];
};

type TurnServer = {
    uris: string[];
    username: string;
    credential: string;
};

type MutedState = "Muted" | "Unmuted" | "Changing";

const ENDPOINTS = {
    voipEndcall: "/api/patientmessaging/voip/:orgId/endcall",
    voipInitialise: "/api/patientmessaging/voip/:orgId/initialise",
} as const;

export const Voip = (): JSX.Element => {
    const history = useHistory();
    const { orgId } = useCurrentWorkspace();

    const [callState, setCallState] = useState("loading");
    const [call, setCall] = useState<NXMCall | null>(null);
    const [number, setNumber] = useState<string | null>(null);
    const [voipToken, setVoipToken] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [application, setApplication] = useState<Application | null>(null);
    const [mutedState, setMutedState] = useState<MutedState>("Unmuted");

    const queryParams = new URLSearchParams(history.location.search);
    const obsNumber = queryParams.get("number") || "";

    const cleanup = () => {
        if (call !== null) {
            const headers = {
                type: "application/json",
            };
            const data = { callId: call.id };
            const blob = new Blob([JSON.stringify(data)], headers);
            navigator.sendBeacon(
                getApiEndpoint({
                    path: ENDPOINTS.voipEndcall,
                    params: { orgId: String(orgId) },
                }).urlFinal,
                blob,
            );
        }
    };

    const placeCall = async (
        app: Application,
        mobNumber: string,
        token: string,
    ) => {
        try {
            setError(null);
            setCallState("waiting");
            await app.callServer(mobNumber, "phone", { voipToken: token });
        } catch (e) {
            if (
                e instanceof Error &&
                e.message &&
                e.message === "Requested device not found"
            ) {
                setError(
                    "It looks like you don't have a microphone plugged in",
                );
            } else {
                setError(
                    "Error starting call, this is likely a local IT issue",
                );
            }
            setCallState("failed");
            Log.error("Failed to place a call", {
                originalException: e,
            });
            return;
        }
    };

    const hangUpCall = async () => {
        try {
            if (call !== null) {
                await call.hangUp();
                setCall(null);
            }
        } catch (e) {
            Log.error("Failed to hang up", {
                originalException: e,
            });
        }
    };

    const toggleMute = async () => {
        if (mutedState === "Muted") {
            setMutedState("Changing");
            try {
                await call?.conversation.me.mute(false);
                setMutedState("Unmuted");
            } catch (e) {
                setMutedState("Muted"); // Need to set back - didn't work
                Log.error("Failed to unmute", {
                    originalException: e,
                });
            }
        }

        if (mutedState === "Unmuted") {
            setMutedState("Changing");
            try {
                await call?.conversation.me.mute(true);
                setMutedState("Muted");
            } catch (e) {
                setMutedState("Unmuted"); // Need to set back - didn't work
                Log.error("Failed to mute", {
                    originalException: e,
                });
            }
        }
    };

    const handleNumpadInput = async (value: string) => {
        try {
            await call?.conversation.media.sendDTMF(value);
        } catch (e) {
            Log.error("Failed to handle Numpad", {
                originalException: e,
            });
        }
    };

    useEffect(() => {
        async function initialiseCall() {
            const callData = await httpClient.getReturnJsonSafeAsync(
                getApiEndpoint({
                    path: ENDPOINTS.voipInitialise,
                    params: { orgId: String(orgId) },
                    query: `?obsNumber=${obsNumber}`,
                }),
            );
            if (callData.error !== null) {
                setError(callData.error);
                setCallState("failed");
                return;
            }

            const response = callData.result as VonageTokenResponse;
            if (response === null) {
                setError("Error - please refresh the page");
                setCallState("failed");
                return;
            }

            const mobileNumber = response.mobileNumber;
            const voipToken = response.voipToken;
            if (mobileNumber === null || voipToken === null) {
                setError("Error - please refresh the page");
                setCallState("failed");
                return;
            }

            setNumber(mobileNumber);
            setVoipToken(voipToken);

            try {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const iceServers: any = [];
                if (
                    response.turnServers !== undefined &&
                    response.turnServers !== null &&
                    response.turnServers.length !== 0
                ) {
                    response.turnServers.forEach((t) => {
                        iceServers.push({
                            urls: t.uris,
                            username: t.username,
                            credential: t.credential,
                        });
                    });
                }
                const config: Configuration = {
                    debug: true,
                    iceServers: iceServers,
                };
                if (response.url) {
                    config.url = response.url;
                }
                if (response.nexmoApiUrl) {
                    config.nexmo_api_url = response.nexmoApiUrl;
                }
                if (response.ipsUrl) {
                    config.ips_url = response.ipsUrl;
                }
                const vonageClient = new nexmoClient(config);

                const app = await vonageClient.createSession(response.jwt);
                setApplication(app);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                app.on("member:call", (_: any, call: NXMCall) => setCall(call));
                app.on("call:status:changed", (call: NXMCall) =>
                    setCallState(call.status),
                );
                await placeCall(app, mobileNumber, voipToken);
            } catch (e) {
                setError("Error starting voip call - please refresh the page");
                setCallState("failed");
                Log.error("Failed to start voip call", {
                    originalException: e,
                });
                return;
            }
        }

        if (orgId && obsNumber) {
            initialiseCall();
        } else {
            // Missing a query params "?number=xxxx"
            setCallState("failed");
            setError(
                "Error - You are missing some data. Open Voip from your toolbar again.",
            );
        }
    }, [orgId, obsNumber]);

    useEffect(() => {
        //This should end any in progress call when we close the tab or window
        window.addEventListener("pagehide", cleanup);

        return () => {
            window.removeEventListener("pagehide", cleanup);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [call]);

    const _IsInCall = (status: string): boolean => {
        switch (status) {
            case "loading":
            case "cancelled":
            case "busy":
            case "timeout":
            case "rejected":
            case "failed":
            case "completed":
            case "unanswered":
                return false;
            case "waiting":
            case "started":
            case "ringing":
            case "answered":
                return true;
            default:
                return false;
        }
    };

    const _GetCallStatusBanner = (): JSX.Element => {
        switch (callState) {
            case "loading":
                return <Feedback colour="information" title="Loading" />;
            case "waiting":
                return <Feedback colour="information" title="Starting call" />;
            case "started":
                return <Feedback colour="information" title="Started call" />;
            case "ringing":
                return (
                    <Feedback
                        colour="information"
                        title="Calling patient phone"
                    />
                );
            case "cancelled":
                return (
                    <Feedback colour="warning" title="The call was cancelled" />
                );
            case "busy":
                return (
                    <Feedback
                        colour="warning"
                        title="The patient's phone is busy"
                    />
                );
            case "timeout":
                return (
                    <Feedback colour="warning" title="The call has timed out" />
                );
            case "rejected":
                return (
                    <Feedback colour="warning" title="The call was rejected" />
                );
            case "answered":
                return (
                    <Feedback colour="success" title="Calling in progress" />
                );
            case "failed":
                return (
                    <Feedback
                        colour="error"
                        title={error ?? "Calling failed"}
                    />
                );
            case "completed":
                return <Feedback colour="information" title="Call completed" />;
            case "unanswered":
                return (
                    <Feedback colour="information" title="Call not answered" />
                );
            default:
                return <Feedback />;
        }
    };

    return (
        <div className="d-flex justify-content-center">
            <div className="d-flex flex-column narrowContent">
                <Card
                    props={{ className: "mb-3" }}
                    header={
                        <div className="d-flex align-items-center">
                            <Icon name="Bell" theme="Line" size={4} />
                            <div className="ml-1">
                                <Text skinny variant="subtitle">
                                    Patient Call
                                </Text>
                            </div>
                        </div>
                    }
                >
                    <div>
                        <div className="m-2">{_GetCallStatusBanner()}</div>
                        {number === null ? null : (
                            <div className="d-flex align-items-center mb-2">
                                <Icon name="Person" theme="Line" size={4} />
                                <div className="ml-1">
                                    <Text skinny variant="label">
                                        Patient number: {number}
                                    </Text>
                                </div>
                            </div>
                        )}
                        {application === null ? null : (
                            <StackPanel
                                horizontalContentAlignment="center"
                                gutter={2}
                            >
                                <StackPanel
                                    orientation="horizontal"
                                    horizontalContentAlignment="center"
                                    gutter={1}
                                >
                                    {!_IsInCall(callState) ? (
                                        <Button
                                            type="button"
                                            text="Call"
                                            theme="primary"
                                            icon={{
                                                name: "Phone",
                                                colour: "white",
                                            }}
                                            onClick={async () =>
                                                await placeCall(
                                                    application,
                                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                    number!,
                                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                    voipToken!,
                                                )
                                            }
                                        />
                                    ) : (
                                        <>
                                            <Button
                                                type="button"
                                                theme="danger"
                                                disabled={
                                                    callState === "waiting" ||
                                                    call === null
                                                } //Can't hang up before we've even made call!
                                                text="Hang Up"
                                                icon={{
                                                    name: "Phone",
                                                    colour: "white",
                                                    rotation: "left",
                                                }}
                                                onClick={hangUpCall}
                                            />
                                            <MuteButton
                                                onClick={toggleMute}
                                                isMuted={mutedState === "Muted"}
                                                disabled={
                                                    mutedState === "Changing" ||
                                                    callState === "waiting" ||
                                                    call === null
                                                }
                                            />
                                        </>
                                    )}
                                </StackPanel>

                                {callState === "answered" && (
                                    <NumPad onInput={handleNumpadInput} />
                                )}
                            </StackPanel>
                        )}
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default Voip;
