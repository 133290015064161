import { ReactNode } from "react";

import { Ds } from "@accurx/design";
import { InlineError } from "domains/message-component/components/InlineError/InlineError";
import { useCompose } from "domains/message-component/context";
import { ComposeErrorType } from "domains/message-component/reducer.types";

import { StyledOuterTextArea, StyledTextArea } from "./TextAreaWrapper.styles";

const getMessageLengthError = (
    error: Required<ComposeErrorType>["messageLength"],
) => {
    switch (error) {
        case "EMPTY_MESSAGE":
            return "Write a message to send";
        case "MESSAGE_TOO_LONG":
            return "Message too long to send via SMS";
        default: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars -- This is to make sure we handle all cases. If we add a new error type, this will throw a compilation error
            const exhaustive: never = error;
            return "";
        }
    }
};

export const TextAreaWrapper = ({ children }: { children: ReactNode }) => {
    const { state } = useCompose();
    return (
        <div>
            <StyledOuterTextArea
                $error={
                    !!state.errors.messageLength ||
                    !!state.errors.placeholderRemains
                }
            >
                <StyledTextArea>{children}</StyledTextArea>
            </StyledOuterTextArea>
            <Ds.Flex flexDirection="column" gap="0.5">
                {state.errors.messageLength && (
                    <InlineError>
                        {getMessageLengthError(state.errors.messageLength)}
                    </InlineError>
                )}
                {state.errors.placeholderRemains && (
                    <InlineError>
                        Complete template placeholders to send
                    </InlineError>
                )}
            </Ds.Flex>
        </div>
    );
};
