import { useState } from "react";

import { Card, Ds, Feedback } from "@accurx/design";

import { useTeamUsageQuery } from "app/hooks/queries";
import {
    FEEDBACK_UNAPPROVED_USER_BODY_TEXT,
    FEEDBACK_UNAPPROVED_USER_TITLE_TEXT,
} from "app/organisations/usageDashboard/UsageDashboardPage.consts";
import { useCurrentOrgId, useIsUserApproved } from "store/hooks";

import { StyledContainer } from "./TeamUsage.styles";
import { FilterTimeTeamUsage } from "./TeamUsage.types";
import { TeamUsageTable } from "./TeamUsageTable";

export const TeamUsage = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const workspaceId = useCurrentOrgId()!;
    const [filter, setFilter] = useState(FilterTimeTeamUsage.ALL_TIME);
    const isApprovedUser = useIsUserApproved();

    const {
        data: teamUsage,
        isLoading,
        isError,
        isSuccess,
        error,
    } = useTeamUsageQuery({ workspaceId }, { enabled: isApprovedUser });

    const handleFilter = (nextFilter: string): void => {
        setFilter(nextFilter as FilterTimeTeamUsage);
    };

    const renderFilters = (): JSX.Element => (
        <div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-5 col-xl-4">
                <Ds.RadioGroup
                    name="time-filter"
                    appearance="ghost"
                    onValueChange={handleFilter}
                    value={filter}
                >
                    <Ds.Flex
                        gap="1 2"
                        flexDirection={{ xs: "column", md: "row" }}
                    >
                        <Ds.RadioGroup.Item
                            value={FilterTimeTeamUsage.ALL_TIME}
                        >
                            <Ds.RadioGroup.Indicator />
                            <Ds.RadioGroup.Label>All time</Ds.RadioGroup.Label>
                        </Ds.RadioGroup.Item>
                        <Ds.RadioGroup.Item
                            value={FilterTimeTeamUsage.LAST_7_DAYS}
                        >
                            <Ds.RadioGroup.Indicator />
                            <Ds.RadioGroup.Label>
                                Last 7 days
                            </Ds.RadioGroup.Label>
                        </Ds.RadioGroup.Item>
                    </Ds.Flex>
                </Ds.RadioGroup>
            </div>
        </div>
    );

    return (
        <StyledContainer leftAligned>
            <Ds.Grid gap="2">
                {!isApprovedUser && (
                    <Feedback
                        title={FEEDBACK_UNAPPROVED_USER_TITLE_TEXT}
                        colour="warning"
                    >
                        {FEEDBACK_UNAPPROVED_USER_BODY_TEXT}
                    </Feedback>
                )}
                {isApprovedUser && (
                    <>
                        {isError && (
                            <Feedback
                                title={
                                    error.message ??
                                    "Something went wrong loading your team information. Please refresh the page."
                                }
                                colour="error"
                            />
                        )}

                        {isLoading && <Ds.Spinner />}

                        {isSuccess && teamUsage.firstInstall && (
                            <Ds.Grid gap={"2"}>
                                <Card
                                    header={
                                        <Ds.Text
                                            size="large"
                                            as="h2"
                                            weight="bold"
                                        >
                                            Team usage overview
                                        </Ds.Text>
                                    }
                                >
                                    <Ds.Text weight="bold" mt="0.5" mb="0.5">
                                        Number of users
                                    </Ds.Text>
                                    <Ds.Text
                                        data-testid="nb-users"
                                        mt="0.5"
                                        mb="1.5"
                                    >
                                        {teamUsage.numberOfUsers}
                                    </Ds.Text>
                                    <Ds.Text weight="bold" mt="0.5">
                                        Number of messages sent since first
                                        install{" "}
                                        <Ds.Text
                                            as="span"
                                            data-testid="date-first-install"
                                        >
                                            ({teamUsage.firstInstall})
                                        </Ds.Text>
                                    </Ds.Text>
                                    <Ds.Text
                                        data-testid="nb-first-install"
                                        mt="0.5"
                                        mb="1.5"
                                    >
                                        {teamUsage.messagesSinceFirstInstall}
                                    </Ds.Text>
                                </Card>

                                <Card
                                    header={
                                        <Ds.Text
                                            size="large"
                                            as="h2"
                                            weight="bold"
                                        >
                                            Usage breakdown
                                        </Ds.Text>
                                    }
                                >
                                    <Ds.Text mt="0.5" mb="1.5">
                                        You can see how many texts have been
                                        sent by your practice and each team
                                        member.
                                    </Ds.Text>
                                    {renderFilters()}
                                    <TeamUsageTable
                                        userUsage={
                                            filter ===
                                            FilterTimeTeamUsage.ALL_TIME
                                                ? teamUsage.userUsageSinceInstall
                                                : teamUsage.userUsageLast7Days
                                        }
                                    />
                                </Card>
                            </Ds.Grid>
                        )}
                    </>
                )}
            </Ds.Grid>
        </StyledContainer>
    );
};
