import { Tokens, defaultInputStyles, focusBorderStyles } from "@accurx/design";
import styled from "styled-components";

export const ReadOnlyInput = styled.input.attrs(() => ({
    autoComplete: "off",
    readOnly: true,
}))`
    background-color: ${Tokens.COLOURS.greyscale.dishwater};
    padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[1.5]};
    width: 100%;

    ${defaultInputStyles};
    border-color: transparent;

    // Higher specificity to override GlobalStyle
    &:focus&:focus,
    &:focus-visible {
        ${focusBorderStyles};
    }
`;
