import { useEffect } from "react";

import { useAnalytics } from "@accurx/analytics";
import { Ds, Tokens } from "@accurx/design";
import { AutoFocusHeading, useNavigationOrigin } from "@accurx/navigation";

import { StyledContainer } from "app/layout/Container";
import { SetupPatientTriage } from "app/practices/patientTriage/components";

export const SetupPatientTriagePage = () => {
    const track = useAnalytics();
    const navigationOrigin = useNavigationOrigin();

    useEffect(() => {
        track("PatientTriageSetup Page View", { navigationOrigin });
    }, [track, navigationOrigin]);

    return (
        <StyledContainer>
            <Ds.Flex flexDirection="column" gap="3">
                <AutoFocusHeading size="large" mt={Tokens.SIZES[3]}>
                    Set up Patient Triage
                </AutoFocusHeading>
                <SetupPatientTriage />
            </Ds.Flex>
        </StyledContainer>
    );
};
