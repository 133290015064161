import { useCompose } from "../context";

export const useHasQuestionnaire = () => {
    const {
        state: { template },
    } = useCompose();
    return (
        template.type === "PendingQuestionnaireTemplate" ||
        template.type === "QuestionnaireTemplate"
    );
};
