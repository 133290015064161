import { useEffect, useState } from "react";

import { Ds, Text } from "@accurx/design";
import {
    useAcknowledgeMedicalRecordUserChangeMutation,
    useMedicalRecordConnection,
} from "@accurx/native";

export const LogoutMedicalRecordModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    const { system, loggedInUsersDetails, hasMedicalRecordUserChanged } =
        useMedicalRecordConnection();

    const { mutate: acknowledgeMedicalRecordUserChange } =
        useAcknowledgeMedicalRecordUserChangeMutation();

    useEffect(() => {
        setIsOpen(!!hasMedicalRecordUserChanged);
    }, [hasMedicalRecordUserChanged]);

    const onChangeLogin = () => {
        acknowledgeMedicalRecordUserChange({ logout: true });
        setIsOpen(false);
    };

    const onContinue = () => {
        acknowledgeMedicalRecordUserChange({ logout: false });
        setIsOpen(false);
    };

    if (!system) {
        return null;
    }

    const loggedInUserName = loggedInUsersDetails.accuRxUserName;
    return (
        <Ds.Modal open={isOpen} onOpenChange={setIsOpen}>
            <Ds.Modal.Section>
                <Ds.Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>
                        {`Continue as '${loggedInUserName}'?`}
                    </Ds.Modal.Title>
                    <Text>
                        {`The account logged into ${system} has changed. You are logged into Accurx as '${loggedInUserName}'. Do you want to continue as '${loggedInUserName}'?`}
                    </Text>
                    <Ds.Flex justifyContent="flex-end" gap="2">
                        <Ds.Button
                            appearance="secondary"
                            type="button"
                            data-testid="secondary-button"
                            onClick={onChangeLogin}
                        >
                            No, change login
                        </Ds.Button>
                        <Ds.Button
                            appearance="primary"
                            type="button"
                            data-testid="primary-button"
                            onClick={onContinue}
                        >
                            Yes, continue
                        </Ds.Button>
                    </Ds.Flex>
                </Ds.Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
