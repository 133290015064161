import { User } from "../types/User";

/**
 * As long as either `is2FAed` or `isSetupFor2Fa` are true, we consider a user to be 2FA "onboarded"
 * - SSO users are always 2FA verified despite not being 2FA setup
 * - Non-SSO users must have set up a secondary email address or mobile for 2FA
 * - [TEMPORARY] Skip 2FA for toolbar users being redirected from Manage Org
 */
export const is2FAOnboarded = (user: User) => {
    if (sessionStorage.getItem("isManageOrgRedirect")) {
        return true;
    }

    return user.is2FAed || user.isSetupFor2Fa;
};
