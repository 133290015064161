import { UtmParameters } from "@accurx/api/account";
import { useLocation } from "react-router";

const UTM_MAPPING = [
    {
        apiParams: "utmSource",
        urlParams: "utm_source",
    },
    {
        apiParams: "utmMedium",
        urlParams: "utm_medium",
    },
    {
        apiParams: "utmCampaign",
        urlParams: "utm_campaign",
    },
    {
        apiParams: "utmTerm",
        urlParams: "utm_term",
    },
    {
        apiParams: "utmContent",
        urlParams: "utm_content",
    },
    {
        apiParams: "next",
        urlParams: "next",
    },
    {
        apiParams: "userSource",
        urlParams: "user-source",
    },
] as const;

/**
 * Create an object with relevant utm params to send it to a backend api.
 * Used to forward utm params in emails to not lose marketing tracking.
 * This is needed because we are not allowed to do any
 * tracking "prelogin/user consent" because of PECR (compliance)
 */
export const useGetUtmParams = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const utmParams: UtmParameters = {};

    UTM_MAPPING.forEach(({ apiParams, urlParams }) => {
        const searchValue = params.get(urlParams);
        if (searchValue) {
            utmParams[apiParams] = searchValue;
        }
    });

    return Object.keys(utmParams).length ? utmParams : undefined;
};
