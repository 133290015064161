import { api } from "domains/concierge/internal/api/ticket";
import {
    useConciergeDispatch,
    useConciergeMeta,
} from "domains/concierge/internal/context";
import { actions } from "domains/concierge/internal/store";
import {
    UseMutationOptions,
    useMutation,
} from "domains/concierge/internal/util/useMutation";
import { Conversation } from "domains/concierge/types";

import { useForceConversationRefreshQuery } from "../queries/useConversationRefreshQuery";

type Variables = Pick<Conversation, "source" | "latestToken">;

export const useMarkAsNonUrgentMutation = (
    options?: UseMutationOptions<Variables>,
) => {
    const dispatch = useConciergeDispatch();
    const { workspaceId } = useConciergeMeta();
    const forceConversationRefresh = useForceConversationRefreshQuery();

    return useMutation<Variables>(
        "MarkAsNonUrgent",
        async (conversation) => {
            if (conversation.source.system !== "Ticket") {
                throw new Error(
                    `MarkAsNonUrgentMutation does not support ${conversation.source.system} conversations`,
                );
            }

            const { updates } = await api.markAsNonUrgent({
                workspaceId,
                ticketIdentity: conversation.source.ticketIdentity,
                latestToken: conversation.latestToken,
            });

            dispatch(
                actions.processUpdates({
                    source: "Mutation:MarkAsNonUrgent",
                    ...updates,
                }),
            );
        },
        options,
        {
            refreshOnError: true,
            refreshOnSignalRNotConnected: true,
            refreshFn: (vars) =>
                forceConversationRefresh({ conversationSource: vars.source }),
        },
    );
};
