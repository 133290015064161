import { useCallback } from "react";

import { useConversation } from "domains/concierge/hooks/data/useConversation";
import { api as clinicianMessagingApi } from "domains/concierge/internal/api/clinicianMessaging";
import { mapConversationIdToConversationSource } from "domains/concierge/internal/api/shared/mappers/mapConversationIdToConversationSource";
import { api as ticketApi } from "domains/concierge/internal/api/ticket";
import {
    useConciergeDispatch,
    useConciergeMeta,
} from "domains/concierge/internal/context";
import { useConversationSource } from "domains/concierge/internal/hooks/useConversationSource";
import { useIsConversationFresh } from "domains/concierge/internal/hooks/useIsConversationFresh";
import { usePoller } from "domains/concierge/internal/hooks/usePoller";
import { actions } from "domains/concierge/internal/store";
import { ConciergeUpdates } from "domains/concierge/testing";
import { Conversation } from "domains/concierge/types";

export const fetchConversationUpdates = async (
    source: Conversation["source"],
    workspaceId: number,
): Promise<ConciergeUpdates> => {
    switch (source.system) {
        case "Ticket": {
            const response = await ticketApi.getConversation({
                ticketIdentity: source.ticketIdentity,
                workspaceId,
            });
            return response.updates;
        }
        case "ClinicianMessaging": {
            const response = await clinicianMessagingApi.getConversation({
                serverId: source.serverId,
                workspaceId,
            });
            return response.updates;
        }
    }
};

const getRefreshRate = (system?: Conversation["source"]["system"]): number => {
    switch (system) {
        case "Ticket":
            return 30_000; // Poll ticket every 30 seconds
        case "ClinicianMessaging":
            return 10_000; // Poll clinician messaging every 10 seconds
        case undefined:
            // The poller will never fetch if there's no system so it doesn't
            // matter what we return here
            return Number.MAX_SAFE_INTEGER;
    }
};

export const useConversationPoller = ({
    conversationId,
}: {
    conversationId: string;
}) => {
    const dispatch = useConciergeDispatch();
    const { workspaceId } = useConciergeMeta();
    const conversation = useConversation({ conversationId });
    const isFresh = useIsConversationFresh({ conversationId });
    const conversationSource = useConversationSource({ conversationId });

    const fetchFn = useCallback(async () => {
        const source = mapConversationIdToConversationSource(conversationId);

        if (!source) {
            throw new Error("Invalid conversation ID");
        }

        const updates = await fetchConversationUpdates(source, workspaceId);

        dispatch(
            actions.processUpdates({
                source: "Poller:Conversation",
                ...updates,
            }),
        );
    }, [dispatch, conversationId, workspaceId]);

    return usePoller({
        name: "ConversationPoller",
        onlyPollWhenOffline: conversationSource?.system === "Ticket",
        refreshRate: getRefreshRate(conversationSource?.system),
        fetchFn,
        skipInitialFetch:
            !!conversation?.isFullyLoaded &&
            conversationSource?.system === "Ticket" &&
            isFresh,
    });
};
