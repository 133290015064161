import React from "react";

import { i } from "images";
import { useHistory } from "react-router-dom";

import { ROUTES, ROUTES_CHAIN } from "shared/Routes";
import { isChainPathname } from "shared/RoutesHelper";

import { IsCollapsed } from "../NavigationMenu.styles";
import { NavigationToggle } from "../navigationToggle";
import {
    StyledFullAccurxLogo,
    StyledLink,
    StyledMobileHeader,
    StyledSmallAccurxLogo,
} from "./NavigationHeader.styles";

export const PRIMARY_NAV_TOGGLE_ID = "Primary-navigation-toggle";

const NavigationHeader = ({
    showToggle,
    toggleOnClick,
    simpleNav,
    isCollapsed,
}: {
    showToggle?: boolean;
    toggleOnClick?: () => void;
    simpleNav: boolean;
    isCollapsed: IsCollapsed;
}): JSX.Element => {
    const history = useHistory();
    const getHomePage = (): string => {
        const isChain = isChainPathname(history.location.pathname);
        if (isChain) {
            return ROUTES_CHAIN.practices;
        } else {
            return ROUTES.home;
        }
    };

    const fullAccurxLogo = (shouldDisplay = true) => (
        <StyledFullAccurxLogo
            data-testid="accurx-logo-full"
            src={i("accurx-logo-full.svg")}
            alt="Accurx home"
            width="120px"
            height="16px"
            isCollapsed={!shouldDisplay}
            aria-hidden={!shouldDisplay}
        />
    );

    const smallAccurxLogo = (shouldDisplay = true) => (
        <StyledSmallAccurxLogo
            data-testid="accurx-logo-small"
            src={i("accurx-logo-small.svg")}
            alt="Accurx home"
            width="34px"
            height="20px"
            isCollapsed={!shouldDisplay}
            aria-hidden={!shouldDisplay}
        />
    );

    if (simpleNav && isCollapsed) {
        return smallAccurxLogo();
    }

    if (showToggle) {
        return (
            <StyledMobileHeader>
                <NavigationToggle
                    onClick={() => toggleOnClick?.()}
                    id={PRIMARY_NAV_TOGGLE_ID}
                    action="close"
                />
            </StyledMobileHeader>
        );
    }

    return (
        <StyledLink to={getHomePage()} type="button" title="Accurx home">
            {fullAccurxLogo(!isCollapsed)}
            {smallAccurxLogo(isCollapsed)}
        </StyledLink>
    );
};

export { NavigationHeader };
