import { useFullScreen } from "layouts/UnifiedLayout/UnifiedLayoutContext";

import { StyledContainer } from "app/layout/Container";
import { VaccineDeliveryComposeFirst } from "app/practices/vaccine/vaccineInvitesUploadAndCompose/VaccineDeliveryComposeFirst";

export const InvitePatientsComposeFirstPage = () => {
    useFullScreen();

    return (
        <StyledContainer>
            <VaccineDeliveryComposeFirst />
        </StyledContainer>
    );
};
