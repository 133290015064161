import { mapConversationIdToTicketIdentity } from "domains/concierge/internal/api/shared/mappers/mapConversationIdToTicketIdentity";
import { mapConversationItemIdToTicketItemIdentity } from "domains/concierge/internal/api/shared/mappers/mapConversationItemIdToTicketItemIdentity";
import {
    useConciergeMeta,
    useConciergeSelector,
} from "domains/concierge/internal/context";
import { TeamMembershipRecord } from "domains/concierge/internal/types/TeamMembershipRecord";
import { AssigneeSummary } from "domains/concierge/types";

type Unreads = {
    ticketId: string;
    itemIds: string[];
};

export const useWithPatientsUnreadItems = (): Unreads[] => {
    const { userId } = useConciergeMeta();

    return useConciergeSelector((state) =>
        Object.values(state.conversations.unreadItems)
            .filter((conversation) => {
                const isAssigned = isAssignedToUserDirectlyOrViaTeam({
                    userId,
                    assignee: conversation.assignee,
                    teamMembership: state.conversations.teamMembership,
                });
                return (
                    conversation.system === "Ticket" &&
                    conversation.status === "Open" &&
                    isAssigned
                );
            })
            .map((conversation) => ({
                ticketId:
                    mapConversationIdToTicketIdentity(
                        conversation.conversationId,
                    )?.id ?? "",
                itemIds: conversation.itemIds.map(
                    (itemId) =>
                        mapConversationItemIdToTicketItemIdentity(itemId)?.id ??
                        "",
                ),
            })),
    );
};
function isAssignedToUserDirectlyOrViaTeam({
    userId,
    assignee,
    teamMembership,
}: {
    userId: string;
    assignee: AssigneeSummary;
    teamMembership: TeamMembershipRecord;
}) {
    if (assignee.type === "User" && assignee.id === userId) {
        return true;
    }

    if (assignee.type === "Team" && teamMembership[assignee.id]) {
        return true;
    }

    return false;
}
