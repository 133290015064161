import {
    AppointmentReminder,
    AppointmentTypeFilterOption,
    GetAppointmentRemindersResponse,
} from "@accurx/api/appointment";
import { Option } from "@accurx/design";

export function getExistingCombinations(
    existingReminders: AppointmentReminder[],
) {
    const existingCombinations = new Set<string>();
    existingReminders.forEach((reminder) => {
        reminder.slotTypes.forEach((clinicCode) => {
            if (reminder.appointmentTypeFilters.length) {
                existingCombinations.add(
                    `${clinicCode}-${reminder.appointmentTypeFilters[0]}`,
                );
            }
        });
    });

    return existingCombinations;
}

export function getClinicCodeOptions(
    clinicCodes: GetAppointmentRemindersResponse["slotTypes"],
    appointmentTypes: AppointmentTypeFilterOption[],
    existingCombinations: Set<string>,
    selectedAppointmentType: string,
): Option[] {
    return clinicCodes.map((code) => {
        const hasReminderForAllAppointmentTypes = appointmentTypes.every(
            (type) => existingCombinations.has(`${code}-${type.value}`),
        );

        const hasReminderForSelectedAppointmentType = existingCombinations.has(
            `${code}-${selectedAppointmentType}`,
        );

        const isDisabled =
            hasReminderForAllAppointmentTypes ||
            hasReminderForSelectedAppointmentType;

        return {
            label: code,
            value: code,
            disabled: isDisabled,
        };
    });
}

export function getAppointmentTypeOptions(
    clinicCodes: GetAppointmentRemindersResponse["slotTypes"],
    appointmentTypes: AppointmentTypeFilterOption[],
    existingCombinations: Set<string>,
    selectedClinics: Option[],
): Option[] {
    return appointmentTypes.map((type) => {
        const hasReminderForAllClinics = clinicCodes.every((code) =>
            existingCombinations.has(`${code}-${type.value}`),
        );

        const hasReminderForSelectedClinics = selectedClinics.some((clinic) =>
            existingCombinations.has(`${clinic.value}-${type.value}`),
        );

        const isDisabled =
            hasReminderForAllClinics || hasReminderForSelectedClinics;

        return {
            label: type.description,
            value: type.value,
            disabled: isDisabled,
        };
    });
}

export const getExistingSiteNamesAndLocationTypes = (
    existingReminders: AppointmentReminder[],
) => {
    const existingCombinations = new Set<string>();
    existingReminders.forEach((reminder) => {
        reminder.siteNames.forEach((siteName) => {
            reminder.slotTypes.forEach((slotType) => {
                existingCombinations.add(`${siteName}-${slotType}`);
            });
        });
    });

    return existingCombinations;
};

export const getSiteLocationAndSlotTypeData = ({
    selectedSiteNames,
    selectedSlotTypes,
    siteNames,
    slotTypes,
    existingSiteNamesAndLocationTypes,
}: {
    selectedSiteNames: string[];
    selectedSlotTypes: Option[];
    slotTypes: string[];
    siteNames: string[];
    existingSiteNamesAndLocationTypes: Set<string>;
}) => {
    return {
        selectedSiteNames: selectedSiteNames,
        selectedSlotTypes: selectedSlotTypes.map(({ value }) => value),
        siteNameMultiSelectOptions: siteNames.map((siteName) => ({
            value: siteName,
            label: siteName,
            disabled:
                slotTypes.every((slotType) =>
                    existingSiteNamesAndLocationTypes.has(
                        `${siteName}-${slotType}`,
                    ),
                ) ||
                selectedSlotTypes.some((slotType) =>
                    existingSiteNamesAndLocationTypes.has(
                        `${siteName}-${slotType.value}`,
                    ),
                ),
        })),
        slotTypeMultiSelectOptions: slotTypes.map((slotType) => ({
            value: slotType,
            label: slotType,
            disabled: selectedSiteNames.some((siteName) =>
                existingSiteNamesAndLocationTypes.has(
                    `${siteName}-${slotType}`,
                ),
            ),
        })),
        disableSiteNameMultiSelect: false,
    };
};
