import { useEffect, useRef } from "react";

import * as UI from "@accurx/design";
import { MessageTemplate } from "@accurx/message-templates";
import { ActionStack } from "domains/message-component/components/Compose/components/ActionStack/ActionStack";
import { MessageContentPlaceholder } from "domains/message-component/components/Compose/components/MessageContentPlaceholder/MessageContentPlaceholder";

import { StyledHyphenatedText, StyledWrapper } from "./TemplatePreview.styles";

type TemplatePreviewProps = {
    greeting: string;
    signature: string;
} & Pick<
    MessageTemplate,
    "snomedCodes" | "attachments" | "title" | "body" | "allowReplyByDefault"
>;

export const TemplatePreview = ({
    title,
    body,
    greeting,
    signature,
    snomedCodes = [],
    attachments,
    allowReplyByDefault,
}: TemplatePreviewProps) => {
    /**
     * Screenreader lose focus and revert back to the top of window when navigating to preview.
     * This ref moves focus back to the component and reads out the title.
     */
    const sectionRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (sectionRef.current) {
            sectionRef.current.focus();
        }
    }, []);

    return (
        <section aria-label={`${title} preview`} tabIndex={-1} ref={sectionRef}>
            <StyledWrapper>
                <StyledHyphenatedText
                    size="medium"
                    weight="bold"
                    forwardedAs="h2"
                >
                    {title}
                </StyledHyphenatedText>
                <UI.Ds.Flex flexDirection="column" gap="3">
                    <div>
                        <StyledHyphenatedText size="small">
                            {greeting}
                        </StyledHyphenatedText>
                        <StyledHyphenatedText size="small">
                            {body}
                        </StyledHyphenatedText>
                    </div>
                    <MessageContentPlaceholder
                        isPatientResponseEnabled={allowReplyByDefault === true}
                        attachmentsCount={attachments?.length ?? 0}
                        isQuestionnaire={false}
                        nhsAdviceLink={null}
                        hasSelfBookLink={false}
                    />
                    <StyledHyphenatedText size="small">
                        {signature}
                    </StyledHyphenatedText>
                </UI.Ds.Flex>
                <ActionStack
                    isPatientResponseEnabled={allowReplyByDefault === true}
                    attachments={
                        attachments?.map((att) => ({
                            id: att.id,
                            name: att.name ?? "file name not found",
                            origin: "Template",
                            previewUrl: att.previewUrl,
                            status: "success",
                        })) ?? []
                    }
                    snomedCodes={snomedCodes}
                    nhsAdviceLink={null}
                    selfBookLink={null}
                    questionnaire={null}
                />
            </StyledWrapper>
        </section>
    );
};
