import { useCurrentUser } from "@accurx/auth";
import { useAssignee } from "@accurx/concierge/hooks/data/useAssignee";
import { Conversation } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { StyledAssigneeLabel } from "domains/inbox/components/AssigneeSelector/components/AssigneeButton/AssigneeButton.styles";

import { StyledPillText } from "./ReadOnlyAssigneeBadge.styles";

export type ReadOnlyAssigneeBadgeProps = {
    assignee: Conversation["assignee"];
};

export const ReadOnlyAssigneeBadge = ({
    assignee,
}: ReadOnlyAssigneeBadgeProps) => {
    const { type } = assignee;

    const fullAssignee = useAssignee(assignee);
    const { user } = useCurrentUser();

    const isUnassigned =
        !fullAssignee ||
        (type === "Team" && fullAssignee.displayName === "Unassigned");

    const isCurrentUser =
        type === "User" && fullAssignee?.id === user.accuRxUserId;

    const assigneeName = isCurrentUser
        ? "You"
        : isUnassigned
        ? "Unassigned"
        : fullAssignee.displayName;

    return (
        <Pill.Base
            color={UI.Tokens.COLOURS.greyscale.cloud}
            radius="full"
            border={false}
        >
            <Pill.Icon
                name="Assign"
                colour="zinc"
                theme="Fill"
                props={{
                    "aria-hidden": true,
                }}
            />

            <StyledPillText>
                {isUnassigned ? (
                    <>Unassigned</>
                ) : (
                    <>
                        <UI.VisuallyHidden>
                            Assigned: {assigneeName}
                        </UI.VisuallyHidden>
                        <span aria-hidden>
                            <StyledAssigneeLabel>
                                Assigned:{" "}
                            </StyledAssigneeLabel>
                            <em>{assigneeName}</em>
                        </span>
                    </>
                )}
            </StyledPillText>
        </Pill.Base>
    );
};
