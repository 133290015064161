import { useEffect, useRef } from "react";

import { TeamSummary, UserSummary } from "@accurx/concierge/types";
import { Ds } from "@accurx/design";

import {
    StyledAssigneeAvatarWrapper,
    StyledAssigneeOnlineIndicator,
    StyledAssigneeText,
} from "./AssigneeLabel.styles";

type AssigneeLabelProps = {
    assignee: TeamSummary | UserSummary;
    assigneeType: "Team" | "User";
    isCurrentAssignee: boolean;
    isPending?: boolean;
};

export const AssigneeLabel = ({
    assignee,
    assigneeType,
    isCurrentAssignee,
    isPending = false,
}: AssigneeLabelProps) => {
    const scrollToRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        scrollToRef.current?.scrollIntoView({
            block: "center",
        });
    }, []);

    return (
        <Ds.Flex
            gap="1"
            ref={isCurrentAssignee ? scrollToRef : undefined}
            alignItems="center"
        >
            {!isPending && (
                <StyledAssigneeAvatarWrapper>
                    <span aria-hidden={true}>
                        <Ds.Avatar
                            size="small"
                            name={assignee.displayName}
                            color="white"
                        />
                    </span>

                    {assigneeType === "User" && (
                        <StyledAssigneeOnlineIndicator
                            userId={assignee.id}
                            displayName={assignee.displayName}
                        />
                    )}
                </StyledAssigneeAvatarWrapper>
            )}

            <Ds.Box width="auto">
                <StyledAssigneeText forwardedAs="span" size="small">
                    {"isCurrentUser" in assignee
                        ? `${assignee.displayName} (You)`
                        : assignee.displayName}
                </StyledAssigneeText>
            </Ds.Box>
        </Ds.Flex>
    );
};
