import {
    ConsentCode,
    QueryPatientCommunicationConsentResponse,
    useMedicalRecordConnection,
} from "@accurx/native";

import { ConsentAggregate } from "../types";
import { filterLatestByCommunicationContentType } from "./filterLatestByCommunicationContentType";
import { getConsentAggregate } from "./getConsentAggregate";

export type MappedCommunicationConsentResponse = Record<
    "emailConsent" | "smsConsent",
    {
        consentAggregate: ConsentAggregate;
        codes: ConsentCode[];
        hasConsented: boolean | null;
    }
>;

export const mapConsentQueryResponseToCommunicationConsent = (
    { emailConsent, smsConsent }: QueryPatientCommunicationConsentResponse,
    medicalRecordSystem: ReturnType<
        typeof useMedicalRecordConnection
    >["system"],
): MappedCommunicationConsentResponse => {
    const latestEmailCodesByContentType =
        filterLatestByCommunicationContentType(emailConsent.codes);
    const latestSmsCodesByContentType = filterLatestByCommunicationContentType(
        smsConsent.codes,
    );

    return {
        emailConsent: {
            consentAggregate: getConsentAggregate({
                consent: emailConsent,
                contactMethod: "Email",
                medicalSystem: medicalRecordSystem,
            }),
            codes: latestEmailCodesByContentType,
            hasConsented: emailConsent.hasConsented,
        },
        smsConsent: {
            consentAggregate: getConsentAggregate({
                consent: smsConsent,
                contactMethod: "Mobile",
                medicalSystem: medicalRecordSystem,
            }),
            codes: latestSmsCodesByContentType,
            hasConsented: smsConsent.hasConsented,
        },
    };
};
