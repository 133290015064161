import { Log } from "@accurx/shared";
import { z } from "zod";

import { storageAvailable } from "../../../../../../shared/storage/featureDetection";

export const CONVERSATION_GROUP_SORT_ORDER_STORAGE_KEY = "inbox__sort_order";

const sortOrderSchema = z.record(z.enum(["Newest", "Oldest"]));
type SavedSortOrders = z.infer<typeof sortOrderSchema>;

export const getSortOrderKey = ({
    userId,
    pathname,
    status,
}: {
    userId: string;
    pathname: string;
    status: string;
}) => `u:${userId}-p:${pathname}-s:${status}`;

export const saveSortOrder = ({
    key,
    sort,
}: {
    key: string;
    sort: "Newest" | "Oldest";
}) => {
    const sortOrders = getAllSortOrders();
    sortOrders[key] = sort;
    if (!storageAvailable("localStorage")) {
        Log.info("Local storage is unavailable", {
            tags: {
                product: "Inbox",
                action: "Save Conversation Group Sort",
            },
        });
    }
    localStorage.setItem(
        CONVERSATION_GROUP_SORT_ORDER_STORAGE_KEY,
        JSON.stringify(sortOrders),
    );
};

export const getSortOrder = ({
    key,
}: {
    key: string;
}): "Newest" | "Oldest" | undefined => getAllSortOrders()[key];

export const getAllSortOrders = (): SavedSortOrders => {
    if (!storageAvailable("localStorage")) {
        Log.info("Local storage is unavailable", {
            tags: {
                product: "Inbox",
                action: "Retrieve Conversation Group Sort",
            },
        });
    }
    const serializedItems = localStorage.getItem(
        CONVERSATION_GROUP_SORT_ORDER_STORAGE_KEY,
    );
    try {
        return sortOrderSchema.parse(JSON.parse(serializedItems ?? "{}"));
    } catch (error) {
        let message: string | null = null;
        if (typeof error === "string") {
            message = error;
        } else if (error instanceof Error) {
            message = error.message;
        }
        Log.error("Could not parse stored conversation group sort orders", {
            tags: {
                product: "Inbox",
                action: "Retrieve Conversation Group Sort",
                error: message ?? "Unknown error",
            },
        });
        return {};
    }
};
