import { z } from "zod";

export const PatientSchema = z.object({
    displayName: z.string().nullable(),
    firstName: z.string().nullable(),
    familyName: z.string().nullable(),
    prefixName: z.string().nullable(),
    gender: z.string().nullable(),
    nhsNumber: z.string().nullable(),
    dateOfBirth: z.string().nullable(),
    ageDisplay: z.string().nullable(),
    deceased: z.boolean(),
    ageYear: z.number(),
    mobileNumber: z.string().nullable(),
    practiceCode: z.string().nullable(),
    practiceName: z.string().nullable(),
    practicePhoneNumber: z.string().nullable(),
    practiceAddress: z.string().nullable(),
    featuresAvailable: z
        .object({
            name: z.string().nullable(),
            isEnabled: z.boolean().nullable(),
        })
        .array(),
    recordViewSupported: z.enum([
        "None",
        "Success",
        "FailureNoValidMobile",
        "FailureNoOrganisation",
        "FailureNoActiveUsers",
        "FailureGpNotOptedIn",
        "FailureNotGP",
        "FailureNoRecentConnection",
        "FailureNotInUserOrganisation",
        "FailureNoApprovedUsersWithFeatureEnabled",
        "FailureUnsupportedSystem",
    ]),
});

const SearchForPatientSchema = z.object({
    searched: z.boolean(),
    searchedResult: z
        .object({
            matchFound: z.boolean(),
            patientToken: z.string().nullable(),
            patient: PatientSchema.nullable(),
        })
        .nullable(),
    searchedNhsNumber: z.string().nullable(),
});

export const SearchForPatientSuccessSchema = SearchForPatientSchema.merge(
    z.object({
        searched: z.literal(true),
        searchedResult: z.object({
            matchFound: z.boolean(),
            patientToken: z.string().nullable(),
            patient: PatientSchema.nullable(),
        }),
    }),
);

export type SearchForPatientPatient = z.infer<typeof PatientSchema>;
export type SearchForPatientSuccess = z.infer<
    typeof SearchForPatientSuccessSchema
>;
