import { Organisation, User, isFeatureEnabledForWorkspace } from "@accurx/auth";
import { Ds } from "@accurx/design";
import { isMyWorkspace } from "@accurx/workspace-management";

export const WorkspaceMenuHeader = ({
    workspace,
    user,
}: {
    workspace?: Organisation;
    user: User;
}) => {
    const isFlexibleWorkspacesEnabled = workspace
        ? isFeatureEnabledForWorkspace("FlexibleWorkspace", workspace)
        : false;

    return (
        <>
            {workspace && (
                <>
                    <Ds.Text as="h2" weight="bold" color="metal">
                        {isMyWorkspace(workspace)
                            ? "My Workspace"
                            : workspace.organisationName}
                    </Ds.Text>
                    {isFlexibleWorkspacesEnabled && (
                        <Ds.Text weight="bold" color="metal">
                            {workspace.organisationNodeName}
                        </Ds.Text>
                    )}
                </>
            )}
            <Ds.Text color="metal">{user.fullName || user.email}</Ds.Text>
        </>
    );
};
