import { z } from "zod";

export const SubscribeWindowOpenStatusSchema = z.object({
    open: z.boolean(),
});

export type SubscribeWindowOpenStatus = z.infer<
    typeof SubscribeWindowOpenStatusSchema
>;

export type SubscribeWindowOpenStatusWithResetCount =
    SubscribeWindowOpenStatus & { resetCount: number };
