import { ConsentCode } from "@accurx/native";
import compact from "lodash/compact";
import groupBy from "lodash/groupBy";
import maxBy from "lodash/maxBy";
import uniqBy from "lodash/uniqBy";

/** Filters a list of consent codes to give only the most recent
 *  code per communication content type.
 */
export const filterLatestByCommunicationContentType = (
    allConsentCodes: ConsentCode[],
): ConsentCode[] => {
    const groupedByContentType = groupBy(
        allConsentCodes,
        "communicationContentType",
    );

    const latestCodesPerContentType = Object.values(groupedByContentType)
        .map((group) => {
            // Find the latest date
            const codeWithLatestDate = maxBy(group, "date");

            // If there is no latest date, return all codes in precaution.
            // This could happen if group is an empty array,
            // or if the date strings are invalid for any reason.
            if (!codeWithLatestDate) {
                return group;
            }

            // Get all codes with the latest date
            const allCodesWithLatestDate = group.filter(
                (entry) => entry.date === codeWithLatestDate.date,
            );

            // Return a maximum of one "consent given" and one "consent declined" code
            return uniqBy(allCodesWithLatestDate, "hasConsented");
        })
        .flat();

    return compact(latestCodesPerContentType);
};
