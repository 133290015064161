import { useCurrentWorkspace } from "@accurx/auth";
import { QueryKey, UseQueryOptions, useQuery } from "@tanstack/react-query";
import { NativeFetchError, NativeTransportError } from "domains/native/errors";
import { useNativeTransport } from "domains/native/hooks/useNativeTransport";
import { PatientExternalId } from "domains/native/schemas/PatientExternalIdSchema";

import { patientCommunicationConsentQuery } from "../api/endpoints/queryPatientCommunicationConsent";
import { QueryPatientCommunicationConsentResponse } from "../schemas/QueryPatientCommunicationConsentSchema";

export type UsePatientCommunicationConsentQueryArgs = {
    patientExternalIds: PatientExternalId[];
};

export type PatientCommunicationQueryOptions<T> = Partial<
    UseQueryOptions<
        QueryPatientCommunicationConsentResponse,
        NativeFetchError,
        T,
        QueryKey
    >
>;

export const usePatientCommunicationConsentQuery = <
    T = QueryPatientCommunicationConsentResponse,
>(
    args: UsePatientCommunicationConsentQueryArgs,
    options?: PatientCommunicationQueryOptions<T>,
) => {
    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;

    return useQuery({
        queryKey: [
            "Native:PatientCommunicationConsentQuery",
            {
                workspaceId,
                patientExternalIds: args.patientExternalIds,
            },
        ],
        queryFn: () => {
            if (!transport) {
                throw new NativeTransportError(
                    "PatientCommunicationConsentQuery: Called with no native transport",
                );
            }

            return patientCommunicationConsentQuery(
                { transport, workspaceId },
                {
                    patientExternalIdentityDto: {
                        patientExternalIds: args.patientExternalIds,
                    },
                },
            );
        },
        ...options,
    });
};
