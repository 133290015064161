import { useFullScreen } from "layouts/UnifiedLayout/UnifiedLayoutContext";

import { StyledContainer } from "app/layout/Container";
import { VaccineDeliverySinglePatient } from "app/practices/vaccine/vaccineInviteSinglePatient/VaccineDeliverySinglePatient";

export const InviteIndividualPatientPage = () => {
    useFullScreen();

    return (
        <StyledContainer>
            <VaccineDeliverySinglePatient />
        </StyledContainer>
    );
};
