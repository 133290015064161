import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { InstalledComputers } from "app/practices/computers/InstalledComputers";

export const InstalledComputersPage = () => (
    <StyledContainer>
        <PageHeader
            title="Installed computers"
            type={PageHeaderType.ListPage}
        />
        <InstalledComputers noHeader />
    </StyledContainer>
);
