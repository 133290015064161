import { useCurrentUser } from "@accurx/auth";
import { useIsConversationNotifiableForUser } from "@accurx/concierge/hooks/data/useIsConversationNotifiableForUser";
import { Conversation } from "@accurx/concierge/schemas/ConversationSchema";
import {
    AssigneeSummary,
    TeamSummary,
    UserSummary,
} from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { AssigneeSelector } from "domains/inbox/components/AssigneeSelector/AssigneeSelector";

import { ReadOnlyAssigneeBadge } from "../ReadOnlyAssigneeBadge/ReadOnlyAssigneeBadge";
import {
    StyledContainer as Container,
    Item,
} from "./ConversationActions.styles";
import { MarkAsUnreadActionButton } from "./MarkAsUnreadActionButton";
import { Participants, PatientParticipant } from "./Participants";
import { ToggleStatusActionButton } from "./ToggleStatusActionButton";
import { ToggleUrgentActionButton as ToggleUrgent } from "./ToggleUrgentActionButton";

type ConversationActionsProps = {
    conversation: Conversation;
};

const Assign = ({
    conversation,
    users,
    teams,
    toAssignTeam,
    onAssignedSuccess,
}: ConversationActionsProps & {
    users: UserSummary[];
    teams?: TeamSummary[];
    toAssignTeam?: TeamSummary;
    onAssignedSuccess?: (payload: {
        newAssignee: AssigneeSummary;
        previousAssignee: AssigneeSummary;
    }) => void;
}) => {
    if (conversation.status === "Done") {
        return <ReadOnlyAssigneeBadge assignee={conversation.assignee} />;
    }

    return (
        <AssigneeSelector
            conversation={conversation}
            users={users}
            teams={teams}
            unassignedTeam={toAssignTeam}
            onAssignedSuccess={onAssignedSuccess}
        />
    );
};

const ToggleStatus = ({ conversation }: ConversationActionsProps) => {
    return (
        <ToggleStatusActionButton
            conversation={conversation}
            productOriginAnalytics="ConversationActions"
        />
    );
};

const MarkAsUnread = ({
    conversation,
    onMarkAsUnreadSuccess,
}: ConversationActionsProps & {
    onMarkAsUnreadSuccess: (() => void) | undefined;
}) => {
    const { user } = useCurrentUser();
    const isConversationNotifiableForUser = useIsConversationNotifiableForUser({
        conversationId: conversation.id,
        userId: user.accuRxUserId,
    });

    return conversation.status === "Open" ? (
        <>
            {isConversationNotifiableForUser && (
                <MarkAsUnreadActionButton
                    conversation={conversation}
                    onMarkAsUnreadSuccess={onMarkAsUnreadSuccess}
                />
            )}
        </>
    ) : null;
};

export const LoadingState = () => (
    <Container>
        <UI.Flex justifyContent="space-between" gap={"1"}>
            <Pill.Loading radius="full" width="120px" />
            <UI.Flex gap="1">
                <Pill.Loading radius="full" width="120px" />
                <Pill.Loading radius="full" width="110px" />
            </UI.Flex>
        </UI.Flex>
    </Container>
);

export const ConversationActions = {
    Container,
    Item,
    Assign,
    ToggleStatus,
    Participants,
    PatientParticipant,
    ToggleUrgent,
    MarkAsUnread,
    LoadingState,
};
