import { Organisation, useCurrentUser } from "@accurx/auth";
import {
    IWrappedResult,
    getApiEndpoint,
    httpClient,
    returnDataOrThrow,
} from "@accurx/shared";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

const joinWorkspace = (
    workspaceId: number,
): Promise<IWrappedResult<Organisation>> =>
    httpClient.putJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: `api/account/workspace/${workspaceId}/Join`,
        }),
        {},
    );

export const useJoinWorkspaceMutation = (
    options: UseMutationOptions<
        Organisation,
        Error,
        { workspaceId: number }
    > = {},
) => {
    const dispatch = useDispatch();
    const { update, user } = useCurrentUser();

    return useMutation({
        mutationKey: ["JoinWorkspace"],
        ...options,
        mutationFn: async ({ workspaceId }) => {
            const response = await joinWorkspace(workspaceId);
            return returnDataOrThrow(response);
        },
        onSuccess: (...args) => {
            const workspace = args[0];
            const organisations = [...user.organisations, workspace];
            update({ ...user, organisations });
            // Whilst we should avoid Redux dependencies in the domain
            // architecture, this is an exception because a lot of components
            // still rely on the list of workspaces in Redux being up-to-date
            dispatch({ type: "ORGANISATIONS_UPDATE", organisations });
            // Workaround for dealing with `onSuccess` callback in `mutate` not
            // firing due to premature unmounting in E2E tests
            // https://tkdodo.eu/blog/mastering-mutations-in-react-query#some-callbacks-might-not-fire
            options.onSuccess && options.onSuccess(...args);
        },
    });
};
