import { useAllFailedConversationGroupQuery } from "@accurx/concierge/hooks/queries/useAllFailedConversationGroupQuery";
import { getMostRecentFailedItem } from "@accurx/concierge/util/getMostRecentFailedItem";
import { getMostRecentItem } from "@accurx/concierge/util/getMostRecentItem";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import {
    ConversationFeed,
    ConversationFeedOptionsWrapper,
    ConversationListHeader,
    ConversationTabItem,
    ConversationTabs,
} from "domains/inbox/components/ConversationList/components";
import { ConversationPreview } from "domains/inbox/components/ConversationPreview/ConversationPreview";
import { Dropdown } from "domains/inbox/components/Dropdown/Dropdown";
import { DropdownItem } from "domains/inbox/components/Dropdown/DropdownItem";
import { useInboxTitle } from "domains/inbox/hooks/util";
import { useConversationFilterAnalytics } from "domains/inbox/hooks/util/useConversationFilterAnalytics";
import { useConversationListAnalytics } from "domains/inbox/hooks/util/useConversationListAnalytics";
import { useInboxLink } from "domains/inbox/hooks/util/useInboxLink";

import {
    StyledAccessibleHeader,
    StyledConversationFeedContainer,
    StyledConversationList,
} from "../styles";

export const AllFailed = () => {
    useInboxTitle(`All failed sent conversations`);
    const link = useInboxLink();
    const groupQuery = useAllFailedConversationGroupQuery();

    const { trackTabClick } = useConversationListAnalytics();
    const { trackFilterButtonClick, trackFilterMenuItemClick } =
        useConversationFilterAnalytics();

    return (
        <>
            <StyledConversationList rows="auto 1fr">
                <UI.Cell>
                    <ConversationListHeader
                        heading={
                            <>
                                All sent & scheduled
                                <StyledAccessibleHeader>
                                    showing sent messages with failed filter
                                    applied
                                </StyledAccessibleHeader>
                            </>
                        }
                    />

                    <ConversationTabs>
                        <ConversationTabItem
                            active={true}
                            link={link.to("AllSent")}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Scheduled",
                                    newTab: "Sent",
                                    conversationParticipant: "WithPatient",
                                })
                            }
                        >
                            Sent
                        </ConversationTabItem>

                        <ConversationTabItem
                            active={false}
                            link={link.to("AllScheduled")}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Sent",
                                    newTab: "Scheduled",
                                    conversationParticipant: "WithPatient",
                                })
                            }
                        >
                            Scheduled
                        </ConversationTabItem>
                    </ConversationTabs>
                </UI.Cell>

                <StyledConversationFeedContainer>
                    <ConversationFeedOptionsWrapper>
                        <UI.Ds.Flex.Item>
                            <Dropdown
                                icon={<Pill.Icon name="Tag" colour="blue" />}
                                dropDownDisplayName="Failed Messages"
                                contentHeading="Filter"
                                onClick={(_, isOpen) => {
                                    if (!isOpen) {
                                        trackFilterButtonClick({
                                            filterName: "Failed Messages",
                                            appOrigin: "ConversationList",
                                        });
                                    }
                                }}
                            >
                                <DropdownItem.ListItem>
                                    <DropdownItem.Link
                                        link={link.to("AllSent")}
                                        onClick={() =>
                                            trackFilterMenuItemClick({
                                                filterName: "Show all",
                                                appOrigin: "ConversationList",
                                            })
                                        }
                                    >
                                        <DropdownItem.Icon name="Tag" />
                                        <DropdownItem.Text>
                                            Show all
                                        </DropdownItem.Text>
                                    </DropdownItem.Link>
                                </DropdownItem.ListItem>

                                <DropdownItem.ListItem>
                                    <DropdownItem.Divider fullWidth />
                                </DropdownItem.ListItem>

                                <DropdownItem.ListItem>
                                    <DropdownItem.Link
                                        link={link.to("AllFailed")}
                                        selected={true}
                                        onClick={() =>
                                            trackFilterMenuItemClick({
                                                filterName: "Failed Messages",
                                                appOrigin: "ConversationList",
                                            })
                                        }
                                    >
                                        <DropdownItem.Icon name="Tag" />
                                        <DropdownItem.Text>
                                            Failed Messages
                                        </DropdownItem.Text>
                                    </DropdownItem.Link>
                                </DropdownItem.ListItem>
                            </Dropdown>
                        </UI.Ds.Flex.Item>
                    </ConversationFeedOptionsWrapper>

                    <ConversationFeed
                        key={groupQuery.data?.id}
                        isLoading={groupQuery.status === "loading"}
                        isError={groupQuery.status === "error"}
                        refetch={groupQuery.refetch}
                        fetchMore={groupQuery.fetchMore}
                        isFetchingMore={groupQuery.isFetchingMore}
                        isFullyLoaded={groupQuery.data?.isFullyLoaded}
                        members={groupQuery.data?.members}
                        filters={groupQuery.data?.filters}
                        renderItem={(conversation, state) => (
                            <ConversationPreview
                                conversation={conversation}
                                item={
                                    getMostRecentFailedItem(conversation) ??
                                    getMostRecentItem(conversation)
                                }
                                state={state}
                                displayAssigneeBadge
                            />
                        )}
                    />
                </StyledConversationFeedContainer>
            </StyledConversationList>
        </>
    );
};
