import React, { useEffect } from "react";

import { Owner } from "@accurx/api/content";
import { useCurrentWorkspace } from "@accurx/auth";
import {
    Button,
    Card,
    Checkbox,
    Ds,
    ErrorSummary,
    Feedback,
    Flex,
    FormFieldWrapper,
    Input,
    Item,
    Link,
    StackPanel,
    Text,
    Tokens,
} from "@accurx/design";
import { Log, SharedUrls, calculateSMSFragments } from "@accurx/shared";
import { useParams } from "react-router";

import {
    trackCopyToOrgTemplatePageView,
    trackCreateTemplatePageView,
    trackEditTemplatePageView,
} from "app/analytics/FlemingAnalytics";
import { useCurrentUser } from "app/organisations/hooks";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { LoadingStatus } from "shared/LoadingStatus";

import { SingleSnomedPicker } from "../../sharedComponents/SnomedPicker";
import { StyledGreyBox } from "../floreyBuilder/components/PageContainer.styles";
import * as helpers from "./ManageTemplatesFormPage.helpers";
import {
    useIsOrgTemplate,
    useManageTemplatesForm,
    useMessageTemplate,
} from "./ManageTemplatesFormPage.hooks";
import {
    Container,
    StyledLayoutWithMaxSize,
    StyledPageHeading,
    StyledTextArea,
    StyledWrapper,
} from "./ManageTemplatesFormPage.styles";
import { Params } from "./ManageTemplatesFormPage.types";
import { Attachments } from "./components/Attachments/Attachments";
import { CategoryModal } from "./components/CategoryModal";
import { CategorySelect } from "./components/CategorySelect";

const FormHeader = ({
    isDesktopPage,
    title,
    subheading,
}: FormHeaderProps): JSX.Element => {
    if (isDesktopPage) {
        return (
            <>
                <StyledPageHeading>
                    <Ds.Text as="h1" weight="bold" size={"xxlarge"}>
                        {title}
                    </Ds.Text>
                </StyledPageHeading>
                {subheading && (
                    <StyledPageHeading>
                        <Ds.Text color={"night"}>{subheading}</Ds.Text>
                    </StyledPageHeading>
                )}
            </>
        );
    }
    return (
        <>
            <Ds.Text as="h1" weight="bold" size={"large"} mt={Tokens.SIZES[3]}>
                {title}
            </Ds.Text>
            {subheading && <Ds.Text color={"night"}>{subheading}</Ds.Text>}
        </>
    );
};

type FormHeaderProps = {
    isDesktopPage: boolean;
    title: string;
    subheading: string;
};

export const ManageTemplatesFormPage = ({
    isDesktopPage = true,
}): JSX.Element => {
    const flemingLoggedInProps = useFlemingLoggedInAnalytics();
    const user = useCurrentUser();
    const org = useCurrentWorkspace();
    const { loadingStatus, template } = useMessageTemplate();
    const form = useManageTemplatesForm(template, isDesktopPage);
    const isOrgTemplate = useIsOrgTemplate(template?.owner);
    const { action, templateId } = useParams<Params>();

    const mode = (action as Params["action"]) || "create";

    const onAllowAsSmsChange = (): void => {
        if (form.values.allowAsSms && form.values.allowReplyByDefault) {
            form.onChange.disallowAsSms(
                form.values.allowAsSms,
                form.values.allowReplyByDefault,
            );
        } else {
            form.onChange.allowAsSms(form.values.allowAsSms);
        }
    };
    const onAllowAsBatchChange = (): void => {
        form.onChange.allowAsBatch(form.values.allowAsBatch);
    };

    const onAllowPatientResponseChange = (): void => {
        form.onChange.allowReplyByDefault(form.values.allowReplyByDefault);
    };

    useEffect(() => {
        if (template === null || template === undefined) {
            return;
        }
        switch (mode) {
            case "create":
                trackCreateTemplatePageView({
                    ...flemingLoggedInProps,
                    templateLevel: template.owner,
                    productOrigin: "PatientMessage",
                });
                break;
            case "edit":
                trackEditTemplatePageView({
                    ...flemingLoggedInProps,
                    templateLevel: template.owner,
                    templateId: template.id,
                    productOrigin: "PatientMessage",
                });
                break;
            case "copy":
                trackCopyToOrgTemplatePageView({
                    ...flemingLoggedInProps,
                    templateId: template.id,
                    productOrigin: "PatientMessage",
                });
                break;
        }
    }, [flemingLoggedInProps, mode, template]);

    if (
        loadingStatus === LoadingStatus.Loading ||
        loadingStatus === LoadingStatus.Initial
    ) {
        return <Ds.Spinner />;
    }

    if (loadingStatus === LoadingStatus.Failed) {
        return (
            <Feedback
                colour="error"
                title="There was an error loading this page"
            >
                <Ds.Text>Please refresh the page and try again</Ds.Text>
            </Feedback>
        );
    }

    const title: string = (() => {
        switch (mode) {
            case "create":
                return "Create a new template";
            case "edit":
                return `Edit template: "${template?.title}"`;
            case "copy":
                return `Copy template: "${template?.title}"`;
        }
    })();

    const subheading: string = (() => {
        switch (mode) {
            case "create":
                return `This will be available for ${
                    isOrgTemplate
                        ? `everyone in ${org.organisationName}`
                        : `you`
                } to use`;
            case "edit":
                return `These changes will update ${
                    isOrgTemplate
                        ? `the template for everyone in ${org.organisationName}`
                        : `your template`
                }`;
            case "copy":
                return `From my templates for everyone in ${org.organisationName} to use`;
        }
    })();

    const FormContainer = isDesktopPage
        ? StyledLayoutWithMaxSize
        : React.Fragment;

    if (mode === "edit" && !template) {
        Log.error(new Error("Unable to load template for editing"), {
            tags: {
                templateId,
                templateOwner: isOrgTemplate ? Owner.Workspace : Owner.User,
            },
        });

        return (
            <Ds.Flex flexDirection="column" gap="3">
                <FormHeader
                    isDesktopPage={isDesktopPage}
                    title="Edit template"
                    subheading=""
                />
                <FormContainer>
                    <Feedback
                        colour="error"
                        title="There was an error loading your template"
                    >
                        <Ds.Text>Please refresh the page and try again</Ds.Text>
                    </Feedback>
                </FormContainer>
            </Ds.Flex>
        );
    }

    const submitText: string = (() => {
        switch (mode) {
            case "create":
            case "copy":
                return form.submitting ? "Saving" : "Save template";
            case "edit":
                return form.submitting ? "Updating" : "Update template";
        }
    })();

    function mapErrorsByKey(key: string) {
        if (form.errorsByKey[key] === undefined) {
            return [];
        }
        return [form.errorsByKey[key].body as JSX.Element];
    }

    const disableAllowResponseByDefault = !form.values.allowAsSms;

    const handleSelectCategory = (selectedCategory: string) => {
        if (selectedCategory === "") {
            return;
        }
        // filter by value
        const selectedOptions = form.categories.filter(
            (option) => option.value === selectedCategory,
        );
        const selectedOption = selectedOptions[0];
        form.onChange.categorySelect({
            label: selectedOption.label,
            value: selectedOption.value,
        });
    };

    /**
     * calculate fragments by passing in the body of the
     * template as well as padding it to length we assume it is
     * accounting for things like practice name and message footer
     */
    const fragments = calculateSMSFragments(
        `${form.values.body}${"a".repeat(helpers.MESSAGE_LENGTH_COUNT_START)}`,
    );

    const displaySnomedCodes = org.latestIntegratedSystem !== undefined;

    return (
        <Container>
            <CategoryModal
                isModalOpen={form.addCategoryIsModalOpen}
                handleCloseModal={form.onAddCategoryClose}
                handleAddCategory={form.onAddCategorySubmit}
                formValues={form.addCategoryFormValues}
                onChange={form.addCategoryFormOnChange}
                errors={form.addCategoryFormErrors}
            />
            {isDesktopPage && <Breadcrumb title={title} />}
            <Ds.Flex flexDirection="column" gap="3">
                <FormHeader
                    isDesktopPage={isDesktopPage}
                    title={title}
                    subheading={subheading}
                />
                <FormContainer>
                    <form onSubmit={form.onSubmit}>
                        <Ds.Flex gap="4" flexDirection="column">
                            <ErrorSummary
                                isShown={form.errorsCount > 0}
                                title="Review the errors in the form"
                            />
                            <Card
                                header={
                                    <Text skinny variant="subtitle">
                                        Template details
                                    </Text>
                                }
                            >
                                <StackPanel gutter={2}>
                                    <FormFieldWrapper
                                        label="Template name"
                                        labelProps={{
                                            htmlFor: "templateName",
                                        }}
                                        errors={mapErrorsByKey("templateName")}
                                        metaInfo={
                                            <Text
                                                variant="note"
                                                as="span"
                                                colour={
                                                    form.characterCounts
                                                        .templateName >
                                                    helpers.MAX_TEMPLATE_NAME_LENGTH
                                                        ? "red"
                                                        : "zinc"
                                                }
                                            >
                                                {
                                                    form.characterCounts
                                                        .templateName
                                                }{" "}
                                                /{" "}
                                                {
                                                    helpers.MAX_TEMPLATE_NAME_LENGTH
                                                }
                                            </Text>
                                        }
                                    >
                                        <Input
                                            id="templateName"
                                            value={form.values.templateName}
                                            onChange={
                                                form.onChange.templateName
                                            }
                                            placeholder="e.g. Opening hours"
                                        />
                                    </FormFieldWrapper>
                                    <>
                                        <Flex
                                            justifyContent="space-between"
                                            alignItems="start"
                                        >
                                            <Item>
                                                <Text
                                                    skinny
                                                    as="label"
                                                    variant="label"
                                                    props={{
                                                        htmlFor:
                                                            "category-select",
                                                    }}
                                                >
                                                    Category (optional)
                                                </Text>
                                                <Text skinny>
                                                    This will organise templates
                                                    in the dropdown.
                                                </Text>
                                            </Item>
                                            <Item>
                                                <Button
                                                    onClick={
                                                        form.onAddCategoryOpen
                                                    }
                                                    type="button"
                                                    theme="secondary"
                                                    text="Add category"
                                                    icon={{ name: "Plus" }}
                                                />
                                            </Item>
                                        </Flex>
                                        {form.categoriesError && (
                                            <Feedback
                                                colour="warning"
                                                title="Unexpected error"
                                                children={
                                                    "We couldn't load the Categories. Please refresh."
                                                }
                                            />
                                        )}
                                        <CategorySelect
                                            categoriesError={
                                                form.categoriesError
                                            }
                                            categoriesStatus={
                                                form.categoriesStatus
                                            }
                                            categories={form.categories}
                                            addCategorySuccessStatus={
                                                form.addCategorySuccessStatus
                                            }
                                            formValues={form.values}
                                            onChange={handleSelectCategory}
                                        />
                                    </>
                                </StackPanel>
                            </Card>

                            <Card
                                header={
                                    <Text skinny variant="subtitle">
                                        Template content
                                    </Text>
                                }
                            >
                                <Ds.Flex flexDirection="column" gap={"2"}>
                                    <FormFieldWrapper
                                        label="Message"
                                        labelProps={{ htmlFor: "message" }}
                                        errors={mapErrorsByKey("message")}
                                        metaInfo={
                                            <Text
                                                as="span"
                                                variant="note"
                                                colour={
                                                    form.characterCounts.body >
                                                    helpers.MAX_BODY_LENGTH
                                                        ? "red"
                                                        : "zinc"
                                                }
                                            >
                                                {form.characterCounts.body +
                                                    helpers.MESSAGE_LENGTH_COUNT_START}{" "}
                                                /{" "}
                                                {
                                                    helpers.MAX_MESSAGE_FRAGMENT_LENGTH
                                                }{" "}
                                                ({fragments.fragmentCount}{" "}
                                                {fragments.fragmentCount === 1
                                                    ? "fragment"
                                                    : "fragments"}
                                                )
                                            </Text>
                                        }
                                    >
                                        <StyledGreyBox>
                                            {helpers.MESSAGE_INTRO}
                                            <StyledTextArea
                                                id="message"
                                                value={form.values.body}
                                                onChange={form.onChange.body}
                                                minRows={3}
                                                placeholder="Add the content of your template by typing here..."
                                            />
                                            <span>
                                                {helpers.MESSAGE_OUTRO}{" "}
                                                {user?.settings?.fullName || ""}
                                            </span>
                                            <br />
                                            <span>{org.organisationName}</span>
                                        </StyledGreyBox>
                                    </FormFieldWrapper>

                                    {form.shouldShowAttachmentsSection && (
                                        <Attachments
                                            attachments={
                                                form.values.attachments
                                            }
                                            onAddAttachment={
                                                form.onAddAttachment
                                            }
                                            onRemoveAttachment={
                                                form.onRemoveAttachment
                                            }
                                        />
                                    )}
                                    {displaySnomedCodes && (
                                        <FormFieldWrapper
                                            label="SNOMED code (optional)"
                                            subLabel={
                                                !!form.values.snomedCode
                                                    ? undefined
                                                    : "Type the description or concept ID to search."
                                            }
                                            labelProps={{
                                                htmlFor: "snomedCode",
                                            }}
                                        >
                                            <SingleSnomedPicker
                                                id="snomedCode"
                                                onChange={
                                                    form.onChange.snomedCode
                                                }
                                                value={form.values.snomedCode}
                                                showMissingSnomedCodeInformation
                                                context="patientMessaging"
                                            />
                                        </FormFieldWrapper>
                                    )}
                                </Ds.Flex>
                            </Card>

                            <Card
                                header={
                                    <Text skinny variant="subtitle">
                                        Template settings
                                    </Text>
                                }
                            >
                                <StackPanel gutter={2}>
                                    <FormFieldWrapper label="Template available">
                                        <Flex
                                            gap={"2"}
                                            flexDirection={{
                                                xs: "column",
                                                sm: "row",
                                            }}
                                        >
                                            <Item flex={"1"}>
                                                <Checkbox
                                                    id="allowAsSms"
                                                    text="Individual"
                                                    theme="bordered"
                                                    checked={
                                                        form.values.allowAsSms
                                                    }
                                                    onCheckChange={
                                                        onAllowAsSmsChange
                                                    }
                                                />
                                            </Item>
                                            <Item flex={"1"}>
                                                <Checkbox
                                                    id="allowAsBatch"
                                                    text="Batch"
                                                    theme="bordered"
                                                    checked={
                                                        form.values.allowAsBatch
                                                    }
                                                    onCheckChange={
                                                        onAllowAsBatchChange
                                                    }
                                                />
                                            </Item>
                                        </Flex>
                                    </FormFieldWrapper>
                                    <FormFieldWrapper
                                        label="Default reply setting"
                                        labelProps={{
                                            htmlFor: "allowReplyByDefault",
                                        }}
                                        subLabel={
                                            "The patients will be able to respond once, with a photo or a message. Not applicable if sending in batch."
                                        }
                                    >
                                        <Checkbox
                                            id="allowReplyByDefault"
                                            text="Allow patients to respond"
                                            theme="bordered"
                                            checked={
                                                form.values.allowReplyByDefault
                                            }
                                            onCheckChange={
                                                onAllowPatientResponseChange
                                            }
                                            disabled={
                                                disableAllowResponseByDefault
                                            }
                                        />
                                    </FormFieldWrapper>
                                    {form.values.allowAsBatch &&
                                        !disableAllowResponseByDefault && (
                                            <Feedback
                                                colour={"secondary"}
                                                title={
                                                    "Patients can't respond when you send in batch"
                                                }
                                            >
                                                You can use questionnaires to
                                                record patients' responses.{" "}
                                                <Link
                                                    href={SharedUrls.Floreys}
                                                    openInNewTab
                                                    text="Questionnaires"
                                                />
                                            </Feedback>
                                        )}
                                </StackPanel>
                            </Card>
                            {isDesktopPage ? (
                                <StyledWrapper>
                                    <Ds.Flex
                                        flexDirection="row"
                                        gap="2"
                                        justifyContent="center"
                                    >
                                        <Button
                                            onClick={form.onCancel}
                                            text="Cancel"
                                            disabled={form.submitting}
                                            theme="secondary"
                                        />
                                        <Button
                                            type="submit"
                                            text={submitText}
                                            disabled={form.submitting}
                                            theme="primary"
                                        />
                                    </Ds.Flex>
                                </StyledWrapper>
                            ) : (
                                <Ds.Flex
                                    flexDirection="row"
                                    gap="2"
                                    justifyContent="end"
                                >
                                    <Button
                                        onClick={form.onCancel}
                                        text="Cancel"
                                        disabled={form.submitting}
                                        theme="secondary"
                                    />
                                    <Button
                                        type="submit"
                                        text={submitText}
                                        disabled={form.submitting}
                                        theme="primary"
                                    />
                                </Ds.Flex>
                            )}
                        </Ds.Flex>
                    </form>
                </FormContainer>
            </Ds.Flex>
        </Container>
    );
};
