import { Switch } from "react-router-dom";

import { SimpleRoute } from "app/AppRoute";
import { StyledContainer } from "app/layout/Container";
import { useLoadPractices } from "app/organisations/hooks";
import { VaccineRecordingList } from "app/practices/vaccine/vaccineRecording/VaccineRecordingList.component";
import { ROUTES_WORKSPACE } from "shared/Routes";

import { AccubookBookedPatientsPage } from "./pages/AccubookBookedPatientsPage";
import { AccubookDashboardPage } from "./pages/AccubookDashboardPage";
import { AccubookDeliverySitesPage } from "./pages/AccubookDeliverySitesPage";
import { AccubookManageClinicsPage } from "./pages/AccubookManageClinicsPage";
import { CancelAppointmentPage } from "./pages/CancelAppointmentPage";
import { InviteIndividualPatientPage } from "./pages/InviteIndividualPatientPage";
import { InvitePatientsComposeFirstPage } from "./pages/InvitePatientsComposeFirstPage";
import { InvitePatientsComposeSecondPage } from "./pages/InvitePatientsComposeSecondPage";
import { InvitePatientsReviewPage } from "./pages/InvitePatientsReviewPage";
import { InvitePatientsSelectPage } from "./pages/InvitePatientsSelectPage";
import { InvitePatientsUploadPage } from "./pages/InvitePatientsUploadPage";
import { InvitePatientsUploadSecondPage } from "./pages/InvitePatientsUploadSecondPage";
import { ManagePatientsPage } from "./pages/ManagePatientsPage";
import { SendReminderPage } from "./pages/SendReminderPage";

export const AccubookRoutesSection = () => {
    useLoadPractices();

    return (
        <Switch>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookManagePatients} exact>
                <ManagePatientsPage />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookInvitesUpload}>
                <InvitePatientsUploadPage />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookInvitesUploadSecond}>
                <InvitePatientsUploadSecondPage />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookInvitesSelect}>
                <InvitePatientsSelectPage />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookInvitesComposeFirst}>
                <InvitePatientsComposeFirstPage />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookInvitesComposeSecond}>
                <InvitePatientsComposeSecondPage />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookInvitesReview}>
                <InvitePatientsReviewPage />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookInvitesSingle}>
                <InviteIndividualPatientPage />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookSendReminder}>
                <SendReminderPage />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookCancelAppointment}>
                <CancelAppointmentPage />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookManageClinics}>
                <AccubookManageClinicsPage />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookBookedPatients} exact>
                <AccubookBookedPatientsPage />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookBookedPatientsSession}>
                <StyledContainer>
                    <VaccineRecordingList />
                </StyledContainer>
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookDeliverySites}>
                <AccubookDeliverySitesPage />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookDashboard}>
                <AccubookDashboardPage />
            </SimpleRoute>
        </Switch>
    );
};
