import { BasicUserStatus } from "@accurx/api/account";
import { EventPayload, SignalRSubscriptionEvent } from "@accurx/realtime";
import { Subscription } from "domains/concierge/internal/hooks/useSignalRSubscription";
import { actions } from "domains/concierge/internal/store";
import { UserSummary } from "domains/concierge/types";

type Payload = EventPayload<SignalRSubscriptionEvent.UserApprovalChanged>;

const mapUserStatus = (
    userStatus: Payload["userStatus"],
): UserSummary["status"] => {
    switch (userStatus) {
        case BasicUserStatus.Approved:
            return "Approved";
        case BasicUserStatus.Unapproved:
            return "Unapproved";
        case BasicUserStatus.Archived:
            return "Archived";
        case BasicUserStatus.Deleted:
            return "Deleted";
        default:
            return;
    }
};

const mapToUserSummary = (
    payload: Payload,
    existingUser: UserSummary,
): UserSummary => {
    const status = mapUserStatus(payload.userStatus);

    return {
        ...existingUser,
        status,
        // Only approved users can be assigned to.
        canBeAssignedTo: status === "Approved",
    };
};

export const UserApprovalChanged: Subscription = ({
    subscribe,
    dispatch,
    getState,
    workspaceId,
}) => {
    const subscription = subscribe({
        type: SignalRSubscriptionEvent.UserApprovalChanged,
        eventFilter: (payload) => payload.organisationId === workspaceId,
        onEvent: (payload) => {
            const existingUser = getState().users.items[payload.userId];
            // Since the payload does not have all the information needed (e.g. display name) for a UserSummary,
            // we won't be able to add them to the state.
            if (!existingUser) return;

            const userUpdate = mapToUserSummary(payload, existingUser);
            if (!userUpdate) return;

            dispatch(
                actions.processUpdates({
                    source: "SignalR:UserApprovalChanged",
                    teams: [],
                    users: [userUpdate],
                    patients: [],
                    conversations: [],
                }),
            );
        },
    });

    return subscription.unsubscribe.bind(subscription);
};
