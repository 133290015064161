import { TemplateItem } from "domains/message-component/types";

import { ipPathfinderTemplates } from "./templates.IpPathfinder";
import { ambulanceTemplates } from "./templates.ambulance";
import { pharmacyTemplates } from "./templates.pharmacy";

type ClinicianMessageTemplate = {
    title: string;
    body: string;
    name: string;
};

type ClinicianMessageTemplateGroup = {
    heading: string;
    templates: ClinicianMessageTemplate[];
};

const mapTemplates = ({
    templates,
    heading,
}: ClinicianMessageTemplateGroup): TemplateItem[] =>
    templates.map((template) => {
        return {
            type: "MessageTemplate",
            value: {
                id: template.name,
                title: template.title,
                body: template.body + "\n", // Add a new line so there's space between the body and signature
                owner: "Accurx",
                heading: heading,
                allowReplyByDefault: false,
            },
        };
    });

const clinicianMessageTemplatesGroups: ClinicianMessageTemplateGroup[] = [
    {
        heading: "Community visit",
        templates: [
            {
                name: "review-request",
                title: "Patient requiring review",
                body: "I have just visited this patient at home\nSituation:\nBackground:\nAssessment:\nRecommendation:",
            },
        ],
    },
    {
        heading: "GP",
        templates: [
            {
                name: "add-to-record",
                title: "Add to record",
                body: "I have just consulted with this patient. Consultation notes as follows:\n\nConsultation summary:\n\nActions required:",
            },
            {
                name: "appointment",
                title: "Appointment required",
                body: "This patient requires a *****APPOINTMENT TYPE***** appointment, *****URGENCY/TIMEFRAME*****. Please contact them directly to arrange.",
            },
            {
                name: "bp",
                title: "Blood pressure and atrial fibrillation",
                body: "Blood pressure reading:\n\nAtrial Fibrillation:\n\n1064971000000100 |Making Every Contact Count (regime/therapy)|",
            },
            {
                name: "referral-required",
                title: "Referral required",
                body: "This patient requires a *****REFERRAL TYPE***** referral, *****URGENCY/TIMEFRAME*****. Please submit this, attaching summary of problems, medication and investigations.\n\nReason for referral:\n\nHistory and presentation:\n\nOther relevant information:",
            },
            {
                name: "prescription",
                title: "Prescription required",
                body: "This patient requires a prescription for the following medication, *****URGENCY/TIMEFRAME*****\n\n*****MEDICATION, DOSE, DURATION*****\n\nI have given advice on taking this medication, and informed them that the prescription will be sent to their nominated pharmacy.",
            },
        ],
    },
    {
        heading: "Hospital",
        templates: [
            {
                name: "medication-change",
                title: "Medication change/request",
                body: "I have just seen this patient in my clinic. The clinic letter will follow, but in the meantime they require a prescription of:\n\n*****MEDICATION, DOSE, DURATION*****",
            },
            {
                name: "admission",
                title: "Notification of admission",
                body: "I'm contacting you to inform you that this patient of yours has just been admitted to *****SPECIALTY AND HOSPITAL*****. We have access to a summary of their GP record, however please inform us if there is a care plan in place or other information that we should know.",
            },
            {
                name: "discharge",
                title: "Send discharge summary",
                body: "Please find attached a discharge summary for the patient above who was recently seen in hospital. Please contact me by replying to this message if you have any questions.",
            },
        ],
    },
    {
        heading: "Pharmacy",
        templates: [
            {
                name: "bp-check",
                title: "Blood Pressure Check Service",
                body: pharmacyTemplates.bpCheck,
            },
            {
                name: "out-of-stock",
                title: "Out of Stock",
                body: pharmacyTemplates.outOfStock,
            },
            {
                name: "dosette-box-query",
                title: "Dosette box query",
                body: pharmacyTemplates.dosetteBoxQuery,
            },
            {
                name: "controlled-drug-query",
                title: "Controlled drug query",
                body: pharmacyTemplates.controlledDrugQuery,
            },
            {
                name: "unlicensed-medication-dose-query",
                title: "Unlicensed medication/dose query",
                body: pharmacyTemplates.unlicensedMedicationDoseQuery,
            },
            {
                name: "medication-dose-query",
                title: "Medication/dose query",
                body: pharmacyTemplates.medicationDoseQuery,
            },
            {
                name: "incorrect-quantity",
                title: "Incorrect quantity",
                body: pharmacyTemplates.incorrectQuantity,
            },
            {
                name: "drug-interaction",
                title: "Drug interaction",
                body: pharmacyTemplates.drugInteraction,
            },
            {
                name: "medication-query-follow-up",
                title: "Medication query follow up",
                body: pharmacyTemplates.medicationQueryFollowUp,
            },
            {
                name: "ip-extended-minor-illness",
                title: "IP Pathfinder - Extended Minor Illness",
                body: ipPathfinderTemplates.extendedMinorIllness,
            },
            {
                name: "ip-hypertension",
                title: "IP Pathfinder - Hypertension Management",
                body: ipPathfinderTemplates.hyptertension,
            },
            {
                name: "ip-contraception",
                title: "IP Pathfinder - Contraception",
                body: ipPathfinderTemplates.contraception,
            },
        ],
    },
    {
        heading: "Ambulance Services",
        templates: [
            {
                name: "paramedic-review",
                title: "Paramedic Review",
                body: ambulanceTemplates.paramedicReview,
            },
        ],
    },
];

export const clinicianMessageTemplates = clinicianMessageTemplatesGroups
    .map(mapTemplates)
    .flat();
