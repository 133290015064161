import { ReactNode } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { isArchivedWorkspace } from "domains/workspace-management/util/workspaceHelpers";

export const ArchivedWorkspaceHiddenItemWrapper = ({
    children,
}: {
    children: ReactNode;
}) => {
    const currentWorkspace = useCurrentWorkspace();

    if (isArchivedWorkspace(currentWorkspace)) {
        return null;
    }

    return <>{children}</>;
};
