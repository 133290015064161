import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { TeamNotifications } from "app/practices/teamNotifications/TeamNotifications";

export const TeamNotificationsPage = () => {
    return (
        <StyledContainer>
            <PageHeader
                title="Team notifications"
                type={PageHeaderType.ListPage}
            />
            <TeamNotifications noHeader />
        </StyledContainer>
    );
};
