import { useEffect } from "react";

export const useCustomIntercomLauncher = () => {
    // For logged in users (those who can see this button),
    // we hide the default intercom launcher and replace it with this.
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/no-unsafe-call
        window?.Intercom?.("update", { hide_default_launcher: true });

        return () => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/no-unsafe-call
            window?.Intercom?.("update", { hide_default_launcher: false });
        };
    }, []);
};
