import { ReactNode } from "react";

import { useRouteMatch } from "react-router-dom";

import { usePrimaryNavAnalytics } from "../hooks/usePrimaryNavAnalytics";
import { StyledNavLink } from "./NavLink.styles";

export const PrimaryNavLink = ({
    activePaths,
    to,
    children,
    navigationOptionSelected,
    ...props
}: {
    activePaths: string[];
    to: string;
    children: ReactNode;
    navigationOptionSelected: string;
}) => {
    const { track } = usePrimaryNavAnalytics({ navigationOptionSelected });
    const routeMatch = useRouteMatch(activePaths);
    const isActiveRoute = Boolean(routeMatch);

    return (
        <li>
            <StyledNavLink
                to={to}
                data-active={isActiveRoute}
                onClick={track}
                {...props}
            >
                {children}
            </StyledNavLink>
        </li>
    );
};
