import { MessagePatientResponse } from "@accurx/api/patient-messaging";
import { Conversation } from "domains/concierge/types";

import { mapMessagePatientResponseItemsToConciergeUpdateConversationItems } from "../../internal/api/patientMessaging/mappers/mapMessagePatientResponseToConciergeUpdateConversationItems";
import { useConciergeDispatch } from "../../internal/context";
import { actions } from "../../internal/store";

export const useAddPatientMessagesToConversation = ({
    conversation,
}: {
    conversation: Conversation;
}) => {
    const dispatch = useConciergeDispatch();

    const addPatientMessagesToConversation = (
        messagePatientResponse: MessagePatientResponse,
    ) => {
        const conversationItems =
            mapMessagePatientResponseItemsToConciergeUpdateConversationItems(
                messagePatientResponse,
            );

        dispatch(
            actions.processUpdates({
                source: "Mutation:SendMessage",
                conversations: [
                    {
                        ...conversation,
                        items: conversationItems ?? [],
                    },
                ],
                patients: [],
                users: [],
                teams: [],
            }),
        );

        return conversationItems;
    };

    return addPatientMessagesToConversation;
};
