import { useEffect, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useAuth } from "@accurx/auth";
import { Button, Ds } from "@accurx/design";
import {
    WORKSPACE_MANAGEMENT_ROUTES,
    isArchivedWorkspace,
    supportsMultipleWorkspaces,
} from "@accurx/workspace-management";
import { Link, generatePath, useRouteMatch } from "react-router-dom";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { ONBOARDING_ROUTES } from "app/guardedRoutes/routes/OnboardingRoutesSection";
import { ROUTES, ROUTES_ORGS } from "shared/Routes";

export const GlobalArchivedWorkspaceModal = () => {
    const { user } = useAuth();
    const { selectedOrgId } = useSelectedOrganisation();

    const track = useAnalytics();

    const [showModal, setShowModal] = useState(false);

    const isArchivedWorkspaceModalEnabledOnRoute = !useRouteMatch({
        path: [
            ...ONBOARDING_ROUTES,
            ...Object.values(ROUTES_ORGS),
            ...Object.values(WORKSPACE_MANAGEMENT_ROUTES),
        ],
    });

    const workspace = user?.isLoggedIn
        ? user.organisations.find(({ orgId }) => orgId === selectedOrgId)
        : null;

    const isUserInSingleWorkspace = user?.isLoggedIn
        ? user.organisations?.length === 1
        : false;

    const isArchived = workspace ? isArchivedWorkspace(workspace) : false;

    useEffect(() => {
        if (
            isArchived &&
            isArchivedWorkspaceModalEnabledOnRoute &&
            isUserInSingleWorkspace
        ) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [
        isArchived,
        isArchivedWorkspaceModalEnabledOnRoute,
        isUserInSingleWorkspace,
    ]);

    useEffect(() => {
        if (showModal) {
            track("WorkspaceArchive Component View", {
                modalView: "workspaceIsArchived",
            });
        }
    }, [showModal, track]);

    if (!selectedOrgId || !workspace) {
        return null;
    }

    const handleContinue = () => {
        track("WorkspaceArchiveContinue Button Click", {
            modalView: "workspaceIsArchived",
        });
        setShowModal(false);
    };

    return (
        <Ds.Modal
            open={showModal}
            onOpenChange={() => setShowModal(!showModal)}
        >
            <Ds.Modal.Section>
                <Ds.Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>
                        This workspace has been archived
                    </Ds.Modal.Title>
                    <Ds.Text>
                        You can only view or download information for your
                        records. To send messages, you'll need to use another
                        workspace.
                    </Ds.Text>
                    <Ds.Flex justifyContent="flex-end" gap="2">
                        <Link
                            to={{
                                pathname: supportsMultipleWorkspaces(workspace)
                                    ? generatePath(
                                          WORKSPACE_MANAGEMENT_ROUTES.selectOrganisation,
                                          { workspaceId: selectedOrgId },
                                      )
                                    : ROUTES.joinOrganisation,
                            }}
                            onClick={() =>
                                track("WorkspaceJoinStart Button Click", {
                                    appOrigin: "ArchivedWorkspaceModal",
                                })
                            }
                        >
                            <Button
                                as="span"
                                text="Join another workspace"
                                theme="secondary"
                            />
                        </Link>
                        <Button text="Continue" onClick={handleContinue} />
                    </Ds.Flex>
                </Ds.Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
