import React, { FormEvent, useState } from "react";

import { RegisterAccountProductType } from "@accurx/api/account";
import { User, useAuth } from "@accurx/auth";
import {
    Ds,
    Feedback,
    FormFieldWrapper,
    Input,
    StackPanel,
    Text,
} from "@accurx/design";
import { useLocation } from "react-router-dom";

import { formatSearch } from "app/account/Account.helper";
import { getEmailError } from "shared/Form.helper";
import { ROUTES } from "shared/Routes";

import { SHOW_LOGIN_FORM_SEARCH_PARAM } from "../LogIn.constants";

export const LoginForm = ({
    product,
    defaultEmail,
    onLoginSuccess,
}: {
    product: RegisterAccountProductType;
    defaultEmail?: string | null;
    onLoginSuccess?: (data: User) => void;
}) => {
    const location = useLocation();
    const searchPreLogin = formatSearch({
        search: location.search,
        paramsToRemove: [SHOW_LOGIN_FORM_SEARCH_PARAM, "Email"],
    });

    const { logIn, isLoading, error } = useAuth();

    const [userName, setUserName] = useState(defaultEmail || "");
    const [userNameError, setUserNameError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        const userNameErr = getEmailError(userName);
        setUserNameError(userNameErr);

        const passwordErr = !password ? "The password field is required." : "";
        setPasswordError(passwordErr);

        const isOrganisationsLogin =
            product === RegisterAccountProductType.Chain;

        if (userNameErr || passwordErr) {
            return;
        }

        logIn(
            {
                username: userName,
                password,
                isLoginOrg: isOrganisationsLogin,
            },
            { onSuccess: onLoginSuccess },
        );
    };

    return (
        <form noValidate onSubmit={handleSubmit}>
            <StackPanel orientation="vertical" gutter={2}>
                <FormFieldWrapper
                    label="Email address"
                    labelProps={{ htmlFor: "user-email" }}
                    errors={[userNameError]}
                >
                    <Input
                        id="user-email"
                        value={userName}
                        type="email"
                        onChange={(e): void => setUserName(e.target.value)}
                        autoComplete="email"
                        autoFocus={!defaultEmail}
                        hasErrors={!!userNameError}
                    />
                </FormFieldWrapper>
                <FormFieldWrapper
                    label="Password"
                    labelProps={{ htmlFor: "user-password" }}
                    errors={[passwordError]}
                >
                    <Input
                        id="user-password"
                        value={password}
                        type="password"
                        onChange={(e): void => setPassword(e.target.value)}
                        autoComplete="current-password"
                        hasErrors={!!passwordError}
                        autoFocus={!!defaultEmail}
                    />
                </FormFieldWrapper>
                <Text skinny>
                    <Ds.Link to={`${ROUTES.forgotPassword}${searchPreLogin}`}>
                        Forgot password?
                    </Ds.Link>
                </Text>
                {!!error && <Feedback colour="error">{error}</Feedback>}
                <Ds.Button
                    type="submit"
                    fullwidth
                    appearance="primary"
                    loading={isLoading}
                    loadingMessage="Logging in..."
                >
                    Log in
                </Ds.Button>
            </StackPanel>
        </form>
    );
};
