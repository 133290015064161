import * as Accordion from "@radix-ui/react-accordion";
import { customScrollbar } from "domains/navigation/styles/scrollbar";
import styled, { css } from "styled-components";

export const Root = styled(Accordion.Root).attrs({ type: "single" })<{
    $scrollable?: boolean;
}>`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    ${({ $scrollable }) =>
        $scrollable &&
        css`
            overflow: auto;
            ${customScrollbar}
        `}
`;
