import React, { useEffect, useState } from "react";

import {
    Card,
    Ds,
    Feedback,
    Icon,
    Input,
    Spinner,
    Text,
    Tokens,
} from "@accurx/design";
import debounce from "lodash/debounce";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import ChainApiClient from "api/VaccineApiHelper";
import { PracticeUrlParams } from "app/practices/Practices.types";
import { LoadingStatus } from "shared/LoadingStatus";

import {
    BookedPatient,
    Slot,
    UpdateArrivalRequest,
} from "../models/VaccineRecordingDTO";
import {
    getVaccineSession,
    resetVaccineSession,
    updateVaccineSessionArrival,
} from "./VaccineRecording.actions";
import { BookedPatientStatusBadges } from "./vaccineClinicDay/BookedPatientStatusBadges.component";
import { VaccineListSubMenu } from "./vaccineSubMenus/VaccineListSubMenu";

interface ArriveStatusProps {
    organisationId: string;
    slotBookingId: number;
    hasArrived: boolean;
    setHasArrived: (hasArrived: boolean) => void;
}

const debounceSearch = debounce((callback: () => void) => callback(), 500);

const filterSlots = (term: string, slots: Slot[]): Slot[] => {
    if (!term || !slots.length) {
        return slots;
    }

    const terms = term.split(" ");

    return slots.reduce<Slot[]>((reducedSlots, currentSlot) => {
        // Filter patient that contains searchTerm as a displayName
        const foundPatients = currentSlot.bookedPatients?.filter((booked) => {
            return terms.every(
                (t) =>
                    booked.patient.displayName
                        .toLocaleLowerCase()
                        .indexOf(t.toLocaleLowerCase()) !== -1,
            );
        });

        if (foundPatients.length > 0) {
            // Add a new field "bookedPatients" to the currentSlot with patient matching the search
            return [
                ...reducedSlots,
                {
                    ...currentSlot,
                    bookedPatients: foundPatients,
                },
            ];
        }

        return reducedSlots;
    }, []);
};

const ArrivedStatus = (props: ArriveStatusProps): JSX.Element => {
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.Loaded);
    const id = `arrived${props.slotBookingId}`;

    const handleChangeArrivedStatus = (
        e: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        const value = e.target.checked;
        const request: UpdateArrivalRequest = {
            recallSlotBookingId: props.slotBookingId,
            hasArrived: value,
        };
        setLoadingStatus(LoadingStatus.Loading);
        ChainApiClient.updateArrival({ orgId: props.organisationId, request })
            .then((response) => {
                if (response.success) {
                    props.setHasArrived(value);
                    setLoadingStatus(LoadingStatus.Loaded);
                    return;
                }
                setLoadingStatus(LoadingStatus.Failed);
            })
            .catch(() => setLoadingStatus(LoadingStatus.Failed));
    };

    if (loadingStatus === LoadingStatus.Loading) {
        return <Spinner />;
    }

    return (
        <>
            <input
                id={id}
                type="checkbox"
                style={{ margin: "0" }}
                checked={props.hasArrived}
                onChange={handleChangeArrivedStatus}
                data-testid={id}
            />
            <label
                htmlFor={id}
                style={{
                    fontSize: "1rem",
                    fontWeight: "normal",
                    margin: "0 0 0 0.5rem",
                    verticalAlign: "middle",
                }}
            >
                Arrived
            </label>
            {loadingStatus === LoadingStatus.Failed && (
                <Icon name="Warning" theme="Fill" colour="red" size={3} />
            )}
        </>
    );
};

interface SearchBoxProps {
    onSearchTermUpdated: (term: string) => void;
}

const SearchBox = ({ onSearchTermUpdated }: SearchBoxProps): JSX.Element => {
    const [searchTerm, setSearchTerm] = useState("");

    const updateSearchTerm = (
        event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        const searchInput = event.target.value;
        setSearchTerm(searchInput);

        // Update parent component state only on debounce for performance
        debounceSearch(() => onSearchTermUpdated(searchInput));
    };

    return (
        <Input
            className={"my-2"}
            placeholder={"Search patients by name"}
            value={searchTerm}
            onChange={updateSearchTerm}
            dimension="large"
            isSearchInput
            autoFocus
        />
    );
};

export const VaccineRecordingList = (): JSX.Element => {
    const dispatch = useDispatch();
    const { selectedOrgId: orgId } = useSelectedOrganisation();
    const { sessionId }: PracticeUrlParams = useParams();

    const sessionStatus = useSelector(
        ({ vaccineRecording }: ApplicationState) =>
            vaccineRecording.sessionStatus,
        shallowEqual,
    );
    const storeSession = useSelector(
        ({ vaccineRecording }: ApplicationState) =>
            vaccineRecording.session || null,
        shallowEqual,
    );

    const [filtered, setFiltered] = useState<Slot[] | null>(null);

    useEffect(() => {
        if (orgId && sessionId && sessionStatus === LoadingStatus.Initial) {
            dispatch(getVaccineSession({ orgId: String(orgId), sessionId }));
        }
    }, [dispatch, orgId, sessionId, sessionStatus]);

    useEffect(
        function initFilteredListOnDataLoad() {
            if (!filtered && storeSession) {
                setFiltered(storeSession.slots);
            }
        },
        [storeSession, filtered],
    );

    useEffect(() => {
        return function resetStoreSession(): void {
            dispatch(resetVaccineSession());
        };
    }, [dispatch]);

    // NOTE: while we're now keeping local state in sync from changes, we will ultimately need to
    // handle push notifications to update this list live (arrival change; booking added/removed)
    const setHasArrived = (
        slot: Slot,
        slotBooking: BookedPatient,
        hasArrived: boolean,
    ): void => {
        dispatch(
            updateVaccineSessionArrival({
                slotStartTime: slot.startTime,
                recallSlotBookingId: slotBooking.recallSlotBookingId,
                hasArrived,
            }),
        );

        const markArrived = (slots: Slot[], arrived: boolean): Slot[] => {
            return slots?.map((s) => {
                // If the startTime matches the slot...
                if (s.startTime === slot.startTime) {
                    return {
                        ...s,
                        bookedPatients: s.bookedPatients.map((p) => {
                            // Try finding the patient with the right booking id and update the arrival status
                            if (p === slotBooking) {
                                return {
                                    ...p,
                                    hasArrived: arrived,
                                };
                            }
                            // Otherwise just leave it as is
                            return p;
                        }),
                    };
                }
                // ...Otherwise just leave it as is
                return s;
            });
        };

        // modify within filtered slot state so that updates are preserved when search is used
        setFiltered((previous) => {
            if (!previous) return previous;
            return markArrived(previous, hasArrived);
        });
    };

    const handleFiltering = (searchTerm: string): void => {
        if (!storeSession) return;

        const filteredSlots = filterSlots(searchTerm, storeSession.slots);
        setFiltered(filteredSlots);
    };

    const renderPatientRow = (
        slot: Slot,
        slotBooking: BookedPatient,
        isLast: boolean,
    ): JSX.Element => {
        const invitedByPractice = slotBooking.invitedBy;
        const registeredPractice = slotBooking.registeredOrganisation;
        const patient = slotBooking.patient;

        const wideColClassName = "col-sm-8";
        const shortColClassName = "col-sm-4";

        return (
            <div key={patient.nhsNumber} data-testid="patientCard">
                <div className="row">
                    <div className={wideColClassName}>
                        <Text variant="label" skinny>
                            {patient.displayName}
                        </Text>
                        <BookedPatientStatusBadges
                            vaccineProduct={slotBooking.sessionVaccineProduct}
                        />
                        <Text
                            variant="preview"
                            colour="zinc"
                            props={{
                                className: "d-flex flex-wrap",
                                style: { marginTop: "0.25rem" },
                            }}
                            skinny
                        >
                            <span
                                className="mr-3"
                                style={{ marginTop: "0.25rem" }}
                            >
                                NHS: {patient.nhsNumber}
                            </span>
                            <span
                                className="mr-3"
                                style={{ marginTop: "0.25rem" }}
                            >
                                Born: {patient.dateOfBirthDisplay} (
                                {patient.ageDisplay})
                            </span>
                            <span
                                className="mr-3"
                                style={{ marginTop: "0.25rem" }}
                            >
                                Gender: {patient.gender || "Unknown"}
                            </span>
                            {patient.contactNumber && (
                                <span
                                    className="mr-3"
                                    style={{ marginTop: "0.25rem" }}
                                >
                                    Contact: {patient.contactNumber}
                                </span>
                            )}
                            {registeredPractice && (
                                <span
                                    className="mr-3 text-truncate"
                                    style={{ marginTop: "0.25rem" }}
                                >
                                    Registered at: {registeredPractice.name} (
                                    {registeredPractice.nationalCode})
                                </span>
                            )}
                            {/* Drop through to invited if no registeredPractice */}
                            {!registeredPractice && invitedByPractice && (
                                <span
                                    className="mr-3 text-truncate"
                                    style={{ marginTop: "0.25rem" }}
                                >
                                    Invited by: {invitedByPractice.name} (
                                    {invitedByPractice.nationalCode})
                                </span>
                            )}
                        </Text>
                    </div>
                    <div
                        className={`${shortColClassName} d-flex align-items-start justify-content-end flex-wrap flex-sm-nowrap`}
                    >
                        <span
                            style={{
                                padding: "0.5rem",
                                border: `solid 1px ${Tokens.COLOURS.greyscale.silver}`,
                                borderRadius: "0.25rem",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                            className="mt-1 mt-md-0"
                        >
                            {orgId && (
                                <ArrivedStatus
                                    hasArrived={slotBooking.hasArrived}
                                    slotBookingId={
                                        slotBooking.recallSlotBookingId
                                    }
                                    organisationId={String(orgId)}
                                    setHasArrived={(hasArrived: boolean) =>
                                        setHasArrived(
                                            slot,
                                            slotBooking,
                                            hasArrived,
                                        )
                                    }
                                />
                            )}
                        </span>
                    </div>
                    {/*{patient.deceased && <Text variant="body" props={{ className: 'text-danger mb-0' }}>This patient has died</Text>}*/}
                </div>
                {!isLast && <hr className="my-2" />}
            </div>
        );
    };

    const renderTimeSlot = (slot: Slot): JSX.Element | null => {
        if (!slot?.bookedPatients) return null;

        const timeDisplay = `${slot.startTime} - ${slot.endTime}`;
        return (
            <Card
                key={timeDisplay}
                spacing={2}
                header={
                    <div className="d-flex align-items-center">
                        <span className="d-flex align-items-center mr-1">
                            <Icon name="Clock" size={3} />
                        </span>
                        <Text
                            variant="label"
                            colour="zinc"
                            as="span"
                            skinny
                            props={{ style: { marginRight: "0.75rem" } }}
                        >
                            {timeDisplay}
                        </Text>
                        <Text as="span" colour="zinc" skinny>
                            Patients · {slot?.bookedPatients?.length}
                        </Text>
                        {storeSession && !storeSession.isPublished && (
                            <Ds.Badge color="greyscale" className="ml-2">
                                Not published
                            </Ds.Badge>
                        )}
                    </div>
                }
                props={{
                    className: "mb-1",
                    "data-testid": "session-slot-card",
                }}
            >
                <>
                    {slot?.bookedPatients?.map((bookedPatient, i) =>
                        renderPatientRow(
                            slot,
                            bookedPatient,
                            i === slot?.bookedPatients?.length - 1,
                        ),
                    )}
                </>
            </Card>
        );
    };

    if (sessionStatus === LoadingStatus.Failed) {
        return (
            <Feedback
                colour="error"
                title="Something went wrong"
                content="Please refresh the page to try again"
            />
        );
    }

    if (sessionStatus === LoadingStatus.Loaded) {
        return (
            <div className="mb-8 row" data-testid="vaccine-recording-list">
                <VaccineListSubMenu />
                <div className="col-lg-8">
                    <SearchBox onSearchTermUpdated={handleFiltering} />
                    {filtered?.length ? (
                        filtered?.map(renderTimeSlot)
                    ) : (
                        <Feedback
                            colour="information"
                            title="No patient bookings found"
                        />
                    )}
                </div>
            </div>
        );
    }

    if (sessionStatus === LoadingStatus.Loading) {
        return <Spinner />;
    }

    return <></>;
};

export default VaccineRecordingList;
