import React, { CSSProperties, useEffect, useRef, useState } from "react";

import { Card, Feedback, Icon, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { Log, MobileNumberHelper } from "@accurx/shared";
import omit from "lodash/omit";
import { shallowEqual, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { useSafeAsync } from "api/Api.utils";
import { RecordViewRequestResult } from "api/FlemingDtos";
import {
    authenticateRecordView,
    getLatestPatientResult,
    requestRecordView,
} from "api/RecordViewApi";
import { AnalyticsMapper, FlemingAnalyticsTracker } from "app/analytics";
import { useNavigateToWorkspaceRoute } from "app/workspace/hooks";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { PatientHelper } from "shared/PatientHelper";
import { ROUTES, ROUTES_EXTENSION } from "shared/Routes";
import { findBaseRoute } from "shared/RoutesHelper";
import { useAppSelector } from "store/hooks";

import { NavSubMenuComponent } from "../navbar/NavSubMenuComponent";
import PatientInfoSubMenu from "../navbar/PatientInfoSubMenuComponent";
import { generateGreeting } from "../sendMessage/sendMessageForm/SendMessageFormComponent";
import { ConfirmCodeCard } from "../twoFactorAuth/shared/ConfirmCodeCard";
import { SendCodeCard } from "../twoFactorAuth/shared/SendCodeCard";
import {
    canUseNoAuthRecordViewGp,
    canUseNoAuthRecordViewInternal,
} from "./RecordView.helper";
import { actionCreators } from "./RecordViewActions";
import { CardSpacer } from "./medicalRecord/CardSpacer";
import ConfirmCodeProblemFeedback from "./requestMedicalRecord/ConfirmCodeProblemFeedback";
import { LatestPatientRecordRequest } from "./requestMedicalRecord/LatestPatientRecordRequest";
import { RequestDemoPatient } from "./requestMedicalRecord/RequestDemoPatient";
import { RequestRecordGpFlow } from "./requestMedicalRecord/RequestRecordGpFlow";
import {
    CheckUserWithPatientCard,
    ConfirmCodeContent,
    ConfirmCodeDirectContent,
    RequestMedicalRecordWrapper,
    RequestPermissionContent,
    UnableToUseRecordViewCard,
    confirmCodeTitle,
    requestPermissionStepTitle,
} from "./requestMedicalRecord/RequestSteps";

enum RequestFlowSteps {
    RequestPermission = "RequestPermission",
    ConfirmCode = "ConfirmCode",
    CheckUserWithPatient = "CheckUserWithPatient",
    UnableToUse = "UnableToUse",
}

export const RequestMedicalRecord = (): JSX.Element | null => {
    useAccurxWebTitle("Request patients medical record");

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation<
        { requestId: string; referredFrom?: string } | undefined
    >();
    const safeAsync = useSafeAsync();
    const { navigateToWorkspaceRoute } = useNavigateToWorkspaceRoute();

    const isInEmbeddedMode = useAppSelector(({ app }) => app.isEmbedded);

    const analyticsProps = useAppSelector(({ account, selectProduct }) => {
        const baseProps =
            AnalyticsMapper.getRecordViewBaseAnalyticsProps(account);
        return baseProps
            ? {
                  ...baseProps,
                  searchPatientOrigin: selectProduct.searchPatientOrigin,
              }
            : undefined;
    }, shallowEqual);

    const getReferrer = () =>
        location.state?.referredFrom || window.opener?.location.pathname || "";

    const viewMedicalRecordOrigin = useAppSelector(
        ({ recordView }) => recordView.viewMedicalRecordOrigin,
    );

    useEffect(() => {
        analyticsProps &&
            FlemingAnalyticsTracker.trackRecordViewRequestPageView({
                ...analyticsProps,
                referrer: getReferrer(),
                recordViewOrigin: viewMedicalRecordOrigin,
                isTestPatient: isDemoPatient,
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hasValidUser = useAppSelector(({ account }) => account.user !== null);
    const organisationId = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationId(account),
    );

    const searchedPatient = useAppSelector(
        ({ searchForPatient }) =>
            PatientHelper.getPatient(searchForPatient.lastResponse),
        shallowEqual,
    );

    const patientFirstName = searchedPatient?.firstName ?? null;

    const obfuscatedPatientMobileNumber = useAppSelector(
        ({ searchForPatient }) => {
            const patientSearchResult = searchForPatient.lastResponse;
            const patientMobile =
                PatientHelper.getPatient(patientSearchResult)?.mobileNumber;
            if (typeof patientMobile === "string" && patientMobile !== "") {
                // This should always be obfuscated already, but we apply client side obfuscation for extra security
                return MobileNumberHelper.obfuscate(patientMobile) as string;
            }
            return MobileNumberHelper.unknownNumber;
        },
    );
    const patientToken = useAppSelector(({ searchForPatient }) =>
        PatientHelper.getPatientToken(searchForPatient.lastResponse),
    );
    const isDemoPatient = useAppSelector(({ searchForPatient }) =>
        PatientHelper.isDemoPatient(
            PatientHelper.getPatient(searchForPatient.lastResponse)
                ?.nhsNumber ?? "",
        ),
    );
    const canSkipPatientAuthInternal = useAppSelector(
        ({ account, searchForPatient }) =>
            canUseNoAuthRecordViewInternal(
                account,
                searchForPatient.lastResponse,
            ),
    );
    const canSkipPatientAuthGp = useAppSelector(
        ({ account, searchForPatient }) =>
            canUseNoAuthRecordViewGp(account, searchForPatient.lastResponse),
    );

    const messageGreeting = generateGreeting(searchedPatient);
    const userName = useAppSelector(
        ({ account }) => account.user?.fullName || "",
    );
    const practiceName = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationName(account),
    );

    // nhsNumber is null if searched patient is not found or if it is the demo patient
    let nhsNumber: string | null;

    if (searchedPatient !== null) {
        nhsNumber = isDemoPatient
            ? null
            : (searchedPatient.nhsNumber as string);
    } else {
        nhsNumber = null;
    }

    const [latestRecord, setLatestRecord] =
        useState<RecordViewRequestResult | null>(null);

    useEffect(() => {
        const initLoadRequest = async (): Promise<void> => {
            if (nhsNumber !== null) {
                // Loading and error states don't matter. If we don't get a result we just don't do anything
                const { result } = await safeAsync(
                    getLatestPatientResult({
                        organisationId,
                        nhsNumber,
                    }),
                );

                setLatestRecord(result?.record || null);
            }
        };

        initLoadRequest();
    }, [safeAsync, nhsNumber, organisationId]);

    const [isConfirmDirect, setIsConfirmDirect] = useState(false);
    const [requestFlowStep, setRequestFlowStep] = useState(
        RequestFlowSteps.CheckUserWithPatient,
    );
    const [requestId, setRequestId] = useState<string | null>(null);
    const [requestLoading, setRequestLoading] = useState(false);
    const [stepError, setStepError] = useState("");
    const [latestRecordCardHeight, setLatestRecordCardHeight] = useState(0);

    const latestRecordCardRef = useRef<HTMLDivElement | null>(null);

    // If we are trying just to add the access code for
    // a certain record view request that was previously made
    // skip the first step and go straight to input code step
    useEffect(() => {
        const requestIdFromLocationState: string | null =
            location.state?.requestId || null;

        if (requestIdFromLocationState === null) {
            return;
        }

        setIsConfirmDirect(true);
        setRequestId(requestIdFromLocationState);

        // Remove requestId from state so we don't have issues on reload or after page navigation
        history.replace({
            ...history.location,
            state: omit(location.state, "requestId"),
        });
    }, [history, location.state]);

    useEffect(() => {
        dispatch(
            actionCreators.setViewMedicalRecordOrigin(
                isConfirmDirect
                    ? FlemingAnalyticsTracker.MedicalRecordViewOrigin
                          .MedicalRecordsTable
                    : FlemingAnalyticsTracker.MedicalRecordViewOrigin
                          .PatientRequestFlow,
            ),
        );
    }, [dispatch, isConfirmDirect]);

    useEffect(() => {
        if (!hasValidUser) {
            Log.error("RequestMedicalRecord expects non-null user prop.");
        }
    }, [hasValidUser]);

    useEffect(() => {
        if (latestRecordCardRef.current === null) return;

        const height = Array.from(latestRecordCardRef.current.children)
            .map((node) => node.clientHeight)
            .reduce((a, b) => a + b, 0);

        setLatestRecordCardHeight(height);
    }, [latestRecordCardRef, setLatestRecordCardHeight]);

    if (!hasValidUser) {
        return <Redirect to={ROUTES.home} />;
    }

    if (canSkipPatientAuthInternal) {
        return (
            <Redirect
                to={`${findBaseRoute(history.location.pathname)}${
                    ROUTES_EXTENSION.remoteRecordViewMedicalRecord
                }`}
            />
        );
    }

    const handleAuthenticateRecordView = async (
        accessCode: string,
    ): Promise<void> => {
        setStepError("");
        setRequestLoading(true);

        const success = await safeAsync(
            authenticateRecordView({
                AccessCode: accessCode,
                RequestId: requestId,
                OrganisationId: organisationId,
            }),
        );

        setRequestLoading(false);

        if (success) {
            analyticsProps &&
                FlemingAnalyticsTracker.trackRecordViewSubmitPatientAuthCodeSuccess(
                    analyticsProps,
                );

            history.replace(
                `${findBaseRoute(history.location.pathname)}${
                    ROUTES_EXTENSION.remoteRecordViewMedicalRecord
                }?request=${requestId}`,
            );
        } else {
            analyticsProps &&
                FlemingAnalyticsTracker.trackRecordViewSubmitPatientAuthCodeFailure(
                    analyticsProps,
                );

            setStepError("Invalid two-factor code");
        }

        analyticsProps &&
            FlemingAnalyticsTracker.trackRecordViewConfirmCode({
                ...analyticsProps,
                requestId: requestId,
                hasError: !success,
            });
    };

    const handleSendPatientCode = async (): Promise<void> => {
        analyticsProps &&
            FlemingAnalyticsTracker.trackRecordViewSendPatientAuth(
                analyticsProps,
            );

        setStepError("");
        setRequestLoading(true);
        const { success, requestId, error } = await safeAsync(
            requestRecordView({
                patientToken,
                organisationId,
                isGpOwnPatient: false,
            }),
        );

        setRequestLoading(false);
        setRequestId(requestId);

        if (success) {
            toast(
                <Feedback
                    colour="success"
                    title="A 6 digit code has been sent to the patient"
                    content={obfuscatedPatientMobileNumber}
                />,
            );
            setRequestFlowStep(RequestFlowSteps.ConfirmCode);
        } else {
            setStepError(error ?? "An error occurred. Please try again.");
        }
    };

    const exitRequestRecordView = () => {
        // location.key is a unique string representing the location. On the first page load, it is undefined and so there is no previous location to go to.
        const canGoBack = !!history.location.key;

        if (canGoBack) {
            history.goBack();
        }

        navigateToWorkspaceRoute(
            `${findBaseRoute(history.location.pathname)}${
                ROUTES_EXTENSION.patientProfile
            }`,
        );
    };

    const goToRequestPermissionStep = () => {
        setStepError("");
        setRequestFlowStep(RequestFlowSteps.RequestPermission);
    };

    const goToCheckUserWithPatientStep = () =>
        setRequestFlowStep(RequestFlowSteps.CheckUserWithPatient);

    const getSubMenuBackFunction = () => {
        switch (requestFlowStep) {
            case RequestFlowSteps.CheckUserWithPatient:
                return exitRequestRecordView;

            case RequestFlowSteps.RequestPermission:
            case RequestFlowSteps.UnableToUse:
                return goToCheckUserWithPatientStep;

            case RequestFlowSteps.ConfirmCode:
                return goToRequestPermissionStep;
        }
    };

    const renderSteps = (): JSX.Element => {
        const confirmCodeTitleText = (
            <Text variant="subtitle" as="h2" colour="night" skinny>
                Confirm code to view record
            </Text>
        );
        const confirmCodeSubmitText = "Confirm code";

        // Since we are coming in directly from the records table
        // for a record who's permission has already been requested
        if (isConfirmDirect) {
            return (
                <ConfirmCodeCard
                    onSubmit={handleAuthenticateRecordView}
                    isLoading={requestLoading}
                    error={stepError}
                    text={{
                        title: confirmCodeTitleText,
                        mainText: <ConfirmCodeDirectContent />,
                        submitButtonText: confirmCodeSubmitText,
                    }}
                />
            );
        }

        switch (requestFlowStep) {
            case RequestFlowSteps.CheckUserWithPatient:
                const testPatientLinkProps = isInEmbeddedMode
                    ? {
                          href: `${findBaseRoute(history.location.pathname)}${
                              ROUTES_EXTENSION.remoteRecordViewMedicalRecordTestPatient
                          }`,
                          target: "_blank",
                          rel: "noopener noreferrer",
                      }
                    : {
                          onClick: (): void =>
                              history.replace(
                                  `${findBaseRoute(history.location.pathname)}${
                                      ROUTES_EXTENSION.remoteRecordViewMedicalRecordTestPatient
                                  }`,
                              ),
                      };

                return (
                    <>
                        <CheckUserWithPatientCard
                            onConfirm={() => {
                                analyticsProps &&
                                    FlemingAnalyticsTracker.trackRecordRequestWithPatient(
                                        analyticsProps,
                                    );

                                setRequestFlowStep(
                                    RequestFlowSteps.RequestPermission,
                                );
                            }}
                            onDecline={() => {
                                analyticsProps &&
                                    FlemingAnalyticsTracker.trackRecordRequestWithoutPatient(
                                        analyticsProps,
                                    );

                                setRequestFlowStep(
                                    RequestFlowSteps.UnableToUse,
                                );
                            }}
                            patientFirstName={patientFirstName}
                        />
                        <CardSpacer spacing="medium" />
                        <Card spacing={2}>
                            <Text
                                className="font-weight-bold pb-2"
                                colour="night"
                                skinny
                            >
                                {"About Record View"}
                            </Text>
                            <div className="d-flex">
                                <div className="mr-1">
                                    <Icon
                                        name="LockLocked"
                                        size={3}
                                        theme="Fill"
                                    />
                                </div>
                                <Text className="mt-0">
                                    Meets the security standards of National
                                    Bodies (NHS Digital, NHSX, and National Data
                                    Guardian). Learn more in the{" "}
                                    <Text
                                        as="a"
                                        variant="link"
                                        props={{
                                            href: "https://support.accurx.com/en/articles/5665448-accurx-web-record-view-for-secondary-and-community-care#h_ea976f586c",
                                            target: "_blank",
                                            rel: "noopener noreferrer",
                                        }}
                                    >
                                        Record View FAQs
                                    </Text>
                                </Text>
                            </div>
                            <div className="d-flex">
                                <Icon
                                    name="Record"
                                    size={3}
                                    props={{ className: "mr-1" }}
                                />
                                <Text className="mt-0">
                                    Want to see what Record View looks like?
                                    Look at a{" "}
                                    <Text
                                        as="a"
                                        variant="link"
                                        props={testPatientLinkProps}
                                    >
                                        test patient record
                                    </Text>
                                </Text>
                            </div>
                        </Card>
                    </>
                );

            case RequestFlowSteps.RequestPermission:
                analyticsProps &&
                    FlemingAnalyticsTracker.trackRecordRequestCodePageView(
                        analyticsProps,
                    );
                return (
                    <SendCodeCard
                        onSubmit={handleSendPatientCode}
                        isLoading={requestLoading}
                        error={stepError}
                        text={{
                            title: requestPermissionStepTitle,
                            mainText: (
                                <RequestPermissionContent
                                    obfuscatedPatientMobileNumber={
                                        obfuscatedPatientMobileNumber
                                    }
                                    greeting={messageGreeting}
                                    practiceName={practiceName}
                                    userName={userName}
                                    patientFirstName={patientFirstName}
                                />
                            ),
                            submitButtonText: "Send code via SMS",
                        }}
                    />
                );

            case RequestFlowSteps.ConfirmCode:
                analyticsProps &&
                    FlemingAnalyticsTracker.trackRecordRequestConfirmCodePageView(
                        analyticsProps,
                    );
                return (
                    <>
                        <ConfirmCodeCard
                            onSubmit={handleAuthenticateRecordView}
                            isLoading={requestLoading}
                            error={stepError}
                            text={{
                                title: confirmCodeTitle,
                                mainText: (
                                    <ConfirmCodeContent
                                        obfuscatedPatientMobileNumber={
                                            obfuscatedPatientMobileNumber
                                        }
                                        patientFirstName={patientFirstName}
                                    />
                                ),
                                submitButtonText: confirmCodeSubmitText,
                            }}
                        />
                        <CardSpacer spacing="small" />
                        <ConfirmCodeProblemFeedback />
                        <CardSpacer spacing="small" />
                    </>
                );

            case RequestFlowSteps.UnableToUse:
                return (
                    <UnableToUseRecordViewCard
                        handleGoBack={() => {
                            analyticsProps &&
                                FlemingAnalyticsTracker.RecordRequestWithoutPatientBack(
                                    analyticsProps,
                                );

                            exitRequestRecordView();
                        }}
                        handleContinue={() => {
                            analyticsProps &&
                                FlemingAnalyticsTracker.RecordRequestWithoutPatientContinue(
                                    analyticsProps,
                                );

                            goToRequestPermissionStep();
                        }}
                        patientFirstName={patientFirstName}
                        hideGoBackButton={isInEmbeddedMode}
                    />
                );
        }
    };

    const patientInfoHeader = (
        <NavSubMenuComponent
            backCallback={
                isInEmbeddedMode ? undefined : getSubMenuBackFunction()
            }
        >
            <PatientInfoSubMenu patient={searchedPatient} />
        </NavSubMenuComponent>
    );

    if (isDemoPatient) {
        return (
            <RequestMedicalRecordWrapper>
                {patientInfoHeader}
                <RequestDemoPatient />
            </RequestMedicalRecordWrapper>
        );
    }

    if (canSkipPatientAuthGp) {
        return (
            <RequestMedicalRecordWrapper>
                {patientInfoHeader}
                <RequestRecordGpFlow
                    patientToken={patientToken}
                    organisationId={organisationId}
                />
            </RequestMedicalRecordWrapper>
        );
    }

    /*
        transitions
        ---
        styles for sequencing the translation of the main request card and the
        entrance of the latest record card
    */
    const latestPatientCardTransition: CSSProperties = {
        visibility: "hidden",
        height: 0,
        opacity: 0,
        transform: "scale(0.9)",
        transition: "transform .3s ease-out, opacity .3s ease-out",
        transitionDelay: "1s",
    };

    const latestPatientCardStyles = latestRecord
        ? Object.assign(latestPatientCardTransition, {
              visibility: "visible",
              transform: "scale(1)",
              opacity: 1,
          })
        : latestPatientCardTransition;

    const requestStepTransition: CSSProperties = {
        transform: "translateY(0)",
        transition: "transform .3s ease-out",
        transitionDelay: ".7s",
    };

    const requestStepStyles = latestRecord
        ? Object.assign(requestStepTransition, {
              transform: `translateY(${latestRecordCardHeight}px)`,
          })
        : requestStepTransition;
    /* end transitions */

    return (
        <RequestMedicalRecordWrapper>
            {patientInfoHeader}
            <div
                style={latestPatientCardStyles}
                ref={latestRecordCardRef}
                aria-live="polite"
            >
                <LatestPatientRecordRequest latestRecord={latestRecord} />
            </div>
            <div style={requestStepStyles}>{renderSteps()}</div>
        </RequestMedicalRecordWrapper>
    );
};

export default RequestMedicalRecord;
