import {
    CommunicationConsent,
    useMedicalRecordConnection,
} from "@accurx/native";
import { ContactDetail } from "domains/message-component/reducer.types";

import { ConsentAggregate } from "../types";
import { filterLatestByCommunicationContentType } from "./filterLatestByCommunicationContentType";

export const getConsentAggregate = ({
    consent,
    contactMethod,
    medicalSystem,
}: {
    consent: CommunicationConsent;
    contactMethod: ContactDetail["method"];
    medicalSystem: ReturnType<typeof useMedicalRecordConnection>["system"];
}): ConsentAggregate => {
    // We do not fetch email consent codes from SystmOne, so we return an
    // "Ambiguous" consent aggregate and display a prompt to the user to check
    // the EMR for consent.
    if (contactMethod === "Email" && medicalSystem === "SystmOne") {
        return "Ambiguous";
    }
    // When a code is entered into the EMR without a date, it comes through
    // with a date of "1899-30-12T00:00:0". We're unable to correctly order
    // the codes without a date, so we return an "Ambiguous" consent aggregate
    // and display a prompt to the user to check the EMR for consent.
    //
    // This is only a known issue with EMIS.
    if (consent.codes.some((code) => code.date < "1900-01-01T00:00:0")) {
        return "Ambiguous";
    }

    // Only consider the latest code per consent type when calculating
    // the aggregate.
    const latestCodesByContentType = filterLatestByCommunicationContentType(
        consent.codes,
    );

    if (latestCodesByContentType.length > 0) {
        const includesConsentForAll = latestCodesByContentType.some(
            ({ communicationContentType }) =>
                communicationContentType === "All",
        );

        if (!includesConsentForAll) {
            return "Mixed";
        }

        if (latestCodesByContentType.every((code) => code.hasConsented)) {
            return "AllConsented";
        }

        if (latestCodesByContentType.every((code) => !code.hasConsented)) {
            return "AllDeclined";
        }

        return "Mixed";
    }

    if (consent.hasConsented === null) {
        return "Empty";
    }

    // For some EMRs, we do not fetch SNOMED codes for communication consent.
    // If we have no codes, consent has been fetched from the EMR by other means
    // and we use the top-level hasConsented value
    return consent.hasConsented ? "AllConsented" : "AllDeclined";
};
