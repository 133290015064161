import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useCurrentUser } from "@accurx/auth";
import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import { useUser } from "@accurx/concierge/hooks/data/useUser";
import { Conversation, ConversationItem } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import {
    ContentTypesWithSaveToRecordSupport,
    useBrowserEnvironment,
    useMedicalRecordConnection,
} from "@accurx/native";
import { format } from "date-fns";
import {
    useIsSavingToRecord,
    useSaveToRecordMutation,
} from "domains/inbox/hooks/mutations/useSaveToRecordMutation";
import { getConversationParticipantForAnalytics } from "domains/inbox/util/getConversationParticipantForAnalytics";

import { CopyToClipboardButton } from "./CopyToClipboardButton";
import { StyledSavedToRecordSuccess } from "./SaveItemAction.styles";
import { SaveToRecordButton } from "./SaveToRecordButton";
import { TranslatedLanguageWarningModal } from "./TranslatedLanguageWarningModal";

type SaveItemActionProps = {
    item: Extract<
        ConversationItem,
        { contentType: ContentTypesWithSaveToRecordSupport }
    >;
    conversation: Conversation;
    hasAttachments?: boolean;
    getTextToCopy: () => string;
};

export const SaveItemAction = ({
    item,
    conversation,
    hasAttachments,
    getTextToCopy,
}: SaveItemActionProps) => {
    const track = useAnalytics();
    const env = useBrowserEnvironment();
    const { user } = useCurrentUser();

    const [showModal, setShowModal] = useState(false);
    const [includeAttachments, setIncludeAttachments] = useState(true);

    const patient = usePatient({ patientId: conversation.regardingPatientId });
    const medicalRecordConnection = useMedicalRecordConnection();
    const saveToRecordMutation = useSaveToRecordMutation({
        itemServerId: item.serverId,
    });
    const isSavingToRecord = useIsSavingToRecord({
        itemServerId: item.serverId,
    });

    const successfulSaveEvent = item.saveToRecordState.saveEvents.find(
        (saveEvent) => saveEvent.saveSuccess === true,
    );
    const savedByUser = useUser({
        userId: successfulSaveEvent?.savedByUserId ?? "",
    });

    const isCurrentUserMessage =
        item.createdBy.type === "User" &&
        item.createdBy.id === user.accuRxUserId;

    const accessType = env === "WebView" ? "DesktopApp" : "Browser";

    const handleCopyToClipboard = () => {
        track("MessageCopy Button Click", {
            version: "2",
            conversationParticipant:
                getConversationParticipantForAnalytics(conversation),
            accessType,
            productOrigin: "Inbox",
            isCurrentUserMessage,
            isModal: false,
        });

        return getTextToCopy();
    };

    if (item.saveToRecordState.status === "Saved") {
        if (successfulSaveEvent) {
            const savedAt = format(
                new Date(successfulSaveEvent.savedAt),
                "h:mmaaa, EEE dd MMM",
            );

            return (
                <StyledSavedToRecordSuccess>
                    {savedByUser ? (
                        <UI.Text skinny variant="preview" colour="metal">
                            {savedByUser.displayName} ({savedAt})
                        </UI.Text>
                    ) : (
                        <UI.Text skinny variant="preview" colour="metal">
                            Unknown user ({savedAt})
                        </UI.Text>
                    )}
                </StyledSavedToRecordSuccess>
            );
        }

        return <StyledSavedToRecordSuccess />;
    }

    if (patient && medicalRecordConnection.capabilities?.saveToRecord) {
        const canIncludeAttachments = Boolean(
            hasAttachments &&
                medicalRecordConnection.capabilities.saveAttachmentsToRecord,
        );

        const saveToRecord = async () => {
            let hasError = false;

            try {
                await saveToRecordMutation.mutateAsync({
                    conversationSource: conversation.source,
                    contentType: item.contentType,
                    createAttachments:
                        includeAttachments && canIncludeAttachments,
                    patientId: patient.patientId,
                    patientExternalIds: patient.externalIds,
                });
            } catch (e) {
                hasError = true;
            }

            track("MessageSave Button Response", {
                accessType,
                hasError,
                withAttachment: includeAttachments && canIncludeAttachments,
                isCurrentUserMessage,
                medicalRecordSystem: medicalRecordConnection.system,
                userMedicalRecordRole:
                    medicalRecordConnection.userMedicalRecordRole,
            });
        };

        const onClickSaveToRecord = async () => {
            track("MessageSave Button Click", {
                accessType,
                isCurrentUserMessage,
                withAttachment: includeAttachments && canIncludeAttachments,
                medicalRecordSystem: medicalRecordConnection.system,
                userMedicalRecordRole:
                    medicalRecordConnection.userMedicalRecordRole,
            });

            if (
                item.contentType === "PatientTriageRequestNote" &&
                Boolean(item.translationLanguage)
            ) {
                setShowModal(true);
            } else {
                await saveToRecord();
            }
        };

        const onClickConfirm = async () => {
            setShowModal(false);
            await saveToRecord();
        };

        const onClickCancel = () => {
            setShowModal(false);
        };

        const onToggleAttachments = (value: boolean) => {
            setIncludeAttachments(value === true);
        };

        const attachmentProps = canIncludeAttachments
            ? { enabled: includeAttachments, onToggle: onToggleAttachments }
            : undefined;

        return (
            <div>
                <SaveToRecordButton
                    attachments={attachmentProps}
                    isLoading={isSavingToRecord}
                    hasFailed={
                        item.saveToRecordState.status === "Failed" ||
                        saveToRecordMutation.status === "error"
                    }
                    onClick={() => {
                        void (async () => onClickSaveToRecord())();
                    }}
                />

                <TranslatedLanguageWarningModal
                    onOpenChange={(open) => setShowModal(open)}
                    onConfirm={() => {
                        void (async () => onClickConfirm())();
                    }}
                    onCancel={() => onClickCancel()}
                    open={showModal}
                />
            </div>
        );
    }

    return (
        <div>
            <CopyToClipboardButton getText={handleCopyToClipboard} />
        </div>
    );
};
