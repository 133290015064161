import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";

import { GlobalStyle } from "@accurx/design";

type GlobalStyleContextType = {
    useGlobalStyles: boolean;
    setUseGlobalStyles: (useGlobalStyles: boolean) => void;
};

const GlobalStyleContext = createContext<GlobalStyleContextType | null>(null);

const ContextAwareGlobalStyles = () => {
    const context = useContext(GlobalStyleContext);

    return context?.useGlobalStyles ? <GlobalStyle /> : null;
};

export const GlobalStyleManager = ({ children }: { children: ReactNode }) => {
    const [useGlobalStyles, setUseGlobalStyles] = useState(true);
    return (
        <GlobalStyleContext.Provider
            value={{ useGlobalStyles, setUseGlobalStyles }}
        >
            <ContextAwareGlobalStyles />
            {children}
        </GlobalStyleContext.Provider>
    );
};

export const useExcludeFromGlobalStyles = () => {
    const context = useContext(GlobalStyleContext);

    if (!context) {
        throw new Error(
            "Can't use useExcludeFromGlobalStyles outside of a GlobalStyleContext provider",
        );
    }

    const { setUseGlobalStyles } = context;

    useEffect(() => {
        setUseGlobalStyles(false);
        return () => setUseGlobalStyles(true);
    }, [setUseGlobalStyles]);
};
