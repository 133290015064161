import React, { useEffect, useRef, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { CollaborativeWorkspaceResponse } from "@accurx/api/account";
import { Ds, Feedback, Input, Text, usePagination } from "@accurx/design";
import { LinkIcon } from "@accurx/design/dist/components/Link/LinkIcon";
import { LinkText } from "@accurx/design/dist/components/Link/LinkText";
import { useFuzzyFilter } from "@accurx/hooks";
import {
    PaginationControls,
    PaginationLabel,
    SUPPORT_ARTICLE_WORKSPACE_GUIDANCE,
    useGetWorkspacesAllowedQuery,
    useGetWorkspacesLowTrustQuery,
} from "@accurx/workspace-management";
import { useParams } from "react-router-dom";

import { registerWorkspaceConnection } from "api/WorkspaceApi";
import { StyledTwoThirdsContainer } from "app/layout/TwoThirdsContainer/TwoThirdsContainer.styles";
import { SkeletonList } from "app/sharedComponents/loadingSkeleton/SkeletonList";

import { JoinWorkspaceSucceed } from "./JoinWorkspaceSucceed/JoinWorkspaceSucceed";
import {
    StyledCardList,
    StyledLink,
    StyledMain,
    StyledPageWrapper,
} from "./Layout.styles";
import { ExistingWorkspaceItem } from "./WorkspaceItem/ExistingWorkspaceItem";

export const JoinWorkspaceDesktopPage = () => {
    const { nationalCode } = useParams<{
        nationalCode: string;
    }>();

    const track = useAnalytics();

    const pageWrapperRef = useRef<HTMLElement>(null);

    const [searchTerm, setSearchTerm] = useState("");
    const [joinSucceed, setJoinSucceed] = useState(false);

    const {
        data,
        isError: isWorkspacesAllowedError,
        isFetching: isWorkspacesAllowedFetching,
    } = useGetWorkspacesAllowedQuery(
        { nationalCode, enabled: true },
        { refetchOnMount: true },
    );

    const supportsMultipleWorkspaces = Boolean(data?.organisationName);

    const {
        data: existingWorkspaces,
        isError: isWorkspacesError,
        isFetching: isWorkspacesFetching,
    } = useGetWorkspacesLowTrustQuery(
        {
            nationalCode,
            enabled: supportsMultipleWorkspaces,
        },
        { refetchOnMount: true },
    );

    const isLoading = isWorkspacesAllowedFetching || isWorkspacesFetching;
    const isError = isWorkspacesAllowedError || isWorkspacesError;

    const filteredWorkspaces = useFuzzyFilter<CollaborativeWorkspaceResponse>(
        existingWorkspaces?.workspaces ?? [],
        searchTerm,
        {
            threshold: 0.3,
            keys: ["name", "specialties", "description"],
        },
    );

    const { currentPage, totalPages, onNext, onPrevious, goToPage, paginate } =
        usePagination({
            defaultPage: 1,
            defaultPageSize: 20,
            total: filteredWorkspaces.length,
        });

    const paginatedWorkspaces = paginate(filteredWorkspaces);

    useEffect(() => {
        if (!isLoading && !isError) {
            track("WorkspaceJoin Page View", {});
        }
    }, [isLoading, isError, track]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        goToPage(1);
        setSearchTerm(e.target.value);
    };

    const showPreviousResults = () => {
        onPrevious();
        pageWrapperRef.current?.scrollTo(0, 0);
    };

    const showNextResults = () => {
        onNext();
        pageWrapperRef.current?.scrollTo(0, 0);
    };

    if (isLoading) {
        return (
            <StyledPageWrapper>
                <StyledMain gap="3" flexDirection="column">
                    <SkeletonList quantity={3} />
                </StyledMain>
            </StyledPageWrapper>
        );
    }

    if (isError || !supportsMultipleWorkspaces) {
        return (
            <StyledPageWrapper>
                <StyledMain gap="3" flexDirection="column">
                    <Feedback colour="error" title="Something went wrong." />
                </StyledMain>
            </StyledPageWrapper>
        );
    }

    if (joinSucceed) {
        return <JoinWorkspaceSucceed />;
    }

    return (
        <StyledPageWrapper ref={pageWrapperRef} flexDirection="column">
            <StyledMain gap="3" flexDirection="column">
                <Text as="h1" variant="subtitle" skinny>
                    Join a workspace at {data?.organisationName}
                </Text>

                <Ds.Flex justifyContent="space-between">
                    <StyledTwoThirdsContainer>
                        <Text skinny>
                            A workspace is a group of people at your
                            organisation who would like to collaborate in Accurx
                            e.g. your specialty.{" "}
                            <StyledLink
                                href={SUPPORT_ARTICLE_WORKSPACE_GUIDANCE}
                                openInNewTab
                            >
                                <LinkText text="Find out more about workspaces" />
                                <LinkIcon />
                            </StyledLink>
                        </Text>
                    </StyledTwoThirdsContainer>
                </Ds.Flex>

                <Input
                    isSearchInput
                    placeholder={`Search workspaces`}
                    onChange={handleSearch}
                    disabled={isLoading}
                    aria-label={`Search workspaces`}
                />

                <PaginationLabel
                    totalPages={totalPages}
                    currentPage={currentPage}
                    totalCount={filteredWorkspaces.length}
                    appliedLimit={20}
                />
                <StyledCardList key={`${searchTerm}-${currentPage}`}>
                    {paginatedWorkspaces.map((workspace) => {
                        return (
                            <ExistingWorkspaceItem
                                id={workspace.id}
                                key={workspace.id}
                                name={workspace.name}
                                description={workspace.description}
                                users={workspace.users}
                                specialty={workspace.specialties[0]}
                                isRecommended={workspace.isRecommended}
                                defaultOrgName={data?.organisationName}
                                onJoinSuccess={(workspaceId) => {
                                    setJoinSucceed(true);
                                    void registerWorkspaceConnection(
                                        workspaceId,
                                    );
                                }}
                                showSwitchButton={true}
                            />
                        );
                    })}
                </StyledCardList>
                {filteredWorkspaces.length > 0 && (
                    <PaginationControls
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPrevious={showPreviousResults}
                        onNext={showNextResults}
                    />
                )}
            </StyledMain>
        </StyledPageWrapper>
    );
};
