import React, { useEffect, useState } from "react";

import { Button, Feedback, Text } from "@accurx/design";
import { useAccurxWebTitle, useIsManageOrg } from "@accurx/navigation";
import { generatePath, useHistory } from "react-router-dom";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import {
    getInstalledComputers,
    postInstalledComputers,
} from "api/PracticesApi";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { ROUTES_CHAIN } from "shared/Routes";

import {
    EditRowComputer,
    FeedbackMsg,
    InstalledComputer,
} from "./InstalledComputers.types";
import { InstalledComputersTable } from "./InstalledComputersTable";

export const InstalledComputers = ({
    noHeader,
}: { noHeader?: boolean } = {}): JSX.Element => {
    useAccurxWebTitle("View desktop installations");

    const history = useHistory();
    const { selectedOrgId } = useSelectedOrganisation();
    const isManageOrg = useIsManageOrg();

    const [updating, setUpdating] = useState<boolean>(false);
    const [computersRows, setComputersRows] = useState<InstalledComputer[]>([]);
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [feedbackMsg, setFeedbackMsg] = useState<FeedbackMsg>({
        colour: null,
        msg: "",
    });

    useEffect(() => {
        if (selectedOrgId) {
            (async function loadData(): Promise<void> {
                const res = await getInstalledComputers(String(selectedOrgId));
                if (!res.success || !res.result) {
                    setFeedbackMsg({
                        colour: "error",
                        msg:
                            res.error ||
                            "Something went wrong loading your information. Please refresh the page.",
                    });
                    return;
                }
                setComputersRows(res.result.computers);
                setCanEdit(res.result.userCanEdit);
            })();
        }
    }, [selectedOrgId]);

    const handleEditRow = ({ index, fieldKey, newValue }: EditRowComputer) => {
        const editedComputers = [...computersRows];
        editedComputers[index][fieldKey] = newValue;
        setComputersRows(editedComputers);
    };

    const handleSave = async () => {
        setUpdating(true);
        const res = await postInstalledComputers(String(selectedOrgId), {
            computers: computersRows,
        });
        setUpdating(false);
        if (!res.success) {
            setFeedbackMsg({
                colour: "error",
                msg:
                    res.error ||
                    "Something went wrong saving. Please try again.",
            });
            return;
        }
        setFeedbackMsg({
            colour: "success",
            msg: "Your list has been updated.",
        });
    };

    const handleCancel = () => {
        history.push(
            generatePath(ROUTES_CHAIN.practicesDetails, {
                orgId: String(selectedOrgId),
            }),
        );
    };

    return (
        <div className="mb-4">
            {/*
                This page is also rendered inside the new manage organisation
                layout, which has its own header and breadcrumbs, so we don't
                want to include them here.
            */}
            {!noHeader && (
                <>
                    <Breadcrumb title="Installed Computers" />
                    <Text variant="title" as="h1">
                        Installed Computers
                    </Text>
                </>
            )}
            <Text skinny>
                Here you can see how many computers have Accurx Desktop
                installed.
            </Text>

            {!!computersRows.length && (
                <>
                    <Text props={{ className: "mb-4" }}>
                        You have installed for {computersRows.length} computers.
                    </Text>
                    <div className="mb-4">
                        <InstalledComputersTable
                            canEdit={canEdit}
                            computers={computersRows}
                            onEditRow={handleEditRow}
                        />
                    </div>
                </>
            )}

            {feedbackMsg.msg && feedbackMsg.colour && (
                <Feedback
                    title={feedbackMsg.msg}
                    colour={feedbackMsg.colour}
                    props={{ className: "mb-3" }}
                />
            )}

            {!!computersRows.length && canEdit && (
                <div className="d-flex">
                    <Button
                        type="button"
                        theme="primary"
                        className="mr-2"
                        onClick={handleSave}
                        text={"Update"}
                        disabled={updating}
                    />
                    {isManageOrg && (
                        <Button
                            type="button"
                            theme="secondary"
                            onClick={handleCancel}
                            text={"Cancel"}
                            disabled={updating}
                        />
                    )}
                </div>
            )}
        </div>
    );
};
