import { BulkAppointmentUploadPatientFileResponse } from "@accurx/api/appointment";
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";

type UseAppointmentFileUploadMutationArgs = {
    workspaceId: string;
    file: File;
};

export const useAppointmentFileUploadMutation = (
    options: UseMutationOptions<
        BulkAppointmentUploadPatientFileResponse,
        Error,
        UseAppointmentFileUploadMutationArgs
    > = {},
) =>
    useMutation({
        mutationKey: ["appointment-file-upload"],
        ...options,
        mutationFn: async ({ file, workspaceId }) => {
            const formData = new FormData();
            // This must match the file variable name in form data
            formData.append("file", file);
            const response: IWrappedResult<BulkAppointmentUploadPatientFileResponse> =
                await httpClient.postFileSafeAsync(
                    getApiEndpoint({
                        path: "/api/appointment/:workspaceId/bulkappointmentimports/UploadAppointments",
                        params: { workspaceId },
                    }),
                    formData,
                );

            if (response.success && response.result !== null) {
                return response.result;
            }
            throw new Error(response.error ?? "Failed to upload file");
        },
    });
