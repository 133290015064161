import { ReactNode } from "react";

import { Ds, Tokens } from "@accurx/design";
import styled from "styled-components";

export const CardFooterWrapper = ({ children }: { children: ReactNode }) => (
    <Ds.Flex justifyContent={"flex-end"}>{children}</Ds.Flex>
);

export const ErrorList = styled.ul`
    margin: 0;
    padding-left: ${Tokens.SIZES[3]};
`;

export const ErrorContainer = styled.div`
    margin-bottom: ${Tokens.SIZES[3]};
`;

export const MainContentsText = styled.div`
    padding-bottom: 1.25rem;
`;

export const MainContentsContainer = styled.main`
    padding: ${Tokens.SIZES[3]} ${Tokens.SIZES[2]};
    overflow-y: auto;
    flex: 1;
`;

export const FooterContainer = styled.footer`
    justify-content: flex-end;
    width: 100%;
    bottom: 0;
    height: ${Tokens.SIZES[8]};
    padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[2]};
    background-color: ${Tokens.COLOURS.greyscale.white};
    border-top: 1px solid ${Tokens.COLOURS.greyscale.silver};
    position: absolute;
`;

export const PageContainer = styled.div`
    height: 100%;
    position: relative;
    padding-bottom: ${Tokens.SIZES[8]};
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
`;
