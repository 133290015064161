import { filters } from "domains/concierge/internal/util/conversationGroupFilters";
import { sortOptions } from "domains/concierge/internal/util/conversationGroupSortOptions";

import { useConversationCount } from "./useConversationCount";

export const useClinicianAllConversationsConversationCount = () => {
    return useConversationCount(
        filters.clinicianAll({ status: "Open" }),
        sortOptions.clinicianCommon({ sort: "Newest" }),
    );
};
