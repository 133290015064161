import { useCurrentUser } from "@accurx/auth";
import { useUserSentConversationGroupQuery } from "@accurx/concierge/hooks/queries/useUserSentConversationGroupQuery";
import { getMostRecentItem } from "@accurx/concierge/util/getMostRecentItem";
import { getMostRecentSentItem } from "@accurx/concierge/util/getMostRecentSentItem";
import * as UI from "@accurx/design";
import {
    ConversationFeed,
    ConversationListHeader,
    ConversationTabItem,
    ConversationTabs,
} from "domains/inbox/components/ConversationList/components";
import { ConversationPreview } from "domains/inbox/components/ConversationPreview/ConversationPreview";
import { useInboxTitle } from "domains/inbox/hooks/util";
import { useConversationListAnalytics } from "domains/inbox/hooks/util/useConversationListAnalytics";
import { useInboxLink } from "domains/inbox/hooks/util/useInboxLink";

import {
    StyledAccessibleHeader,
    StyledConversationFeedContainer,
    StyledConversationList,
} from "../styles";

export const MyInboxSent = () => {
    useInboxTitle("My inbox sent conversations");

    const { user } = useCurrentUser();
    const link = useInboxLink();

    const groupQuery = useUserSentConversationGroupQuery();

    const { trackTabClick } = useConversationListAnalytics();

    return (
        <>
            <StyledConversationList rows="auto 1fr">
                <UI.Cell>
                    <ConversationListHeader
                        heading={
                            <>
                                Sent & scheduled
                                <StyledAccessibleHeader>
                                    showing sent messages
                                </StyledAccessibleHeader>
                            </>
                        }
                    />

                    <ConversationTabs>
                        <ConversationTabItem
                            active={true}
                            link={link.to("MyInboxSent")}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Scheduled",
                                    newTab: "Sent",
                                    conversationParticipant: "WithPatient",
                                })
                            }
                        >
                            Sent
                        </ConversationTabItem>

                        <ConversationTabItem
                            active={false}
                            link={link.to("MyInboxScheduled")}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Sent",
                                    newTab: "Scheduled",
                                    conversationParticipant: "WithPatient",
                                })
                            }
                        >
                            Scheduled
                        </ConversationTabItem>
                    </ConversationTabs>
                </UI.Cell>

                <StyledConversationFeedContainer>
                    <ConversationFeed
                        key={groupQuery.data?.id}
                        isLoading={groupQuery.status === "loading"}
                        isError={groupQuery.status === "error"}
                        refetch={groupQuery.refetch}
                        fetchMore={groupQuery.fetchMore}
                        isFetchingMore={groupQuery.isFetchingMore}
                        isFullyLoaded={groupQuery.data?.isFullyLoaded}
                        members={groupQuery.data?.members}
                        filters={groupQuery.data?.filters}
                        renderItem={(conversation, state) => {
                            const item =
                                getMostRecentSentItem(
                                    conversation,
                                    user.accuRxUserId,
                                ) ?? getMostRecentItem(conversation);
                            const lastUpdateTime = item.createdAt;

                            return (
                                <ConversationPreview
                                    conversation={conversation}
                                    item={item}
                                    state={state}
                                    displayAssigneeBadge
                                    lastUpdateTime={lastUpdateTime}
                                    hideConversationActions
                                />
                            );
                        }}
                    />
                </StyledConversationFeedContainer>
            </StyledConversationList>
        </>
    );
};
