import { Ds } from "@accurx/design";

import { StyledContactButton } from "./ContactButton.styles";

export const NewContactOptionButton = ({
    onClick,
}: {
    onClick: () => void;
}) => {
    return (
        <StyledContactButton
            appearance={"tertiary"}
            fullwidth
            onClick={onClick}
        >
            <Ds.Text color="zinc" size="small">
                Add new mobile or email
            </Ds.Text>
            <Ds.Icon name="ArrowTail" rotate="90" color="zinc" size="small" />
        </StyledContactButton>
    );
};
