import { useRef, useState } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { Ds, Feedback } from "@accurx/design";
import { useBrowserEnvironment } from "@accurx/native";
import {
    PatientResults,
    PatientSearchForm,
    PatientSearchFormHandle,
    PatientSearchResult,
    useLatestPatientSearchQuery,
    usePatientSearchViewAnalytics,
} from "@accurx/navigation";
import { toast } from "react-toastify";
import { useAnalytics } from "reduxQuarantine/useAnalytics";

import { StyledComposePatientSearchContainer } from "./ComposePatientSearch.styles";

export const ComposePatientSearch = ({
    onSelect,
}: {
    onSelect: (searchResult: PatientSearchResult) => void;
}) => {
    const track = useAnalytics();
    const { orgId } = useCurrentWorkspace();
    const { data: latestPatientSearch } = useLatestPatientSearchQuery(
        { organisationId: orgId, search: "" },
        { enabled: false },
    );
    const formRef = useRef<PatientSearchFormHandle>(null);

    const [searchResults, setSearchResults] = useState<
        PatientSearchResult[] | null
    >(null);
    const [isSearching, setIsSearching] = useState(false);
    const [isFormDisabled, setIsFormDisabled] = useState(false);

    const isFirstExperience = latestPatientSearch?.patients.length === 0;

    const browserEnv = useBrowserEnvironment();

    usePatientSearchViewAnalytics({
        isDetailPrePopulated: false,
        searchPatientOrigin: "Compose",
    });

    const showErrorToast = () => {
        toast(
            <Feedback
                colour="error"
                title="Couldn't load patient details. Please try again."
            />,
        );
    };

    const handleSelectPatient = (patient: PatientSearchResult | null) => {
        if (patient) {
            onSelect(patient);
        } else {
            showErrorToast();
        }
    };

    const handleTestPatient = () => {
        formRef.current?.useTestPatient();
        track("PatientSearchTestPatient Button Click", {
            productOrigin: "Compose",
        });
    };

    return (
        <StyledComposePatientSearchContainer>
            <Ds.Flex justifyContent="space-between" alignItems="center">
                <Ds.Text as="h1" size="large" weight="bold">
                    Search for patient to message
                </Ds.Text>
                {!isFirstExperience &&
                    !isFormDisabled &&
                    browserEnv !== "WebView" && (
                        <Ds.Button
                            onClick={handleTestPatient}
                            appearance="tertiary"
                        >
                            Use test patient
                        </Ds.Button>
                    )}
            </Ds.Flex>
            <PatientSearchForm
                ref={formRef}
                searchMethods={["BASIC", "ADVANCED"]}
                onSearchResult={setSearchResults}
                onSearchError={showErrorToast}
                onLoading={setIsSearching}
                isFormDisabled={isFormDisabled}
                productOrigin="Compose"
            />
            <PatientResults
                onSelect={handleSelectPatient}
                onClickTestPatient={handleTestPatient}
                onToggleFilter={setIsFormDisabled}
                results={searchResults}
                isLoading={isSearching}
                productOrigin="Compose"
            />
        </StyledComposePatientSearchContainer>
    );
};
