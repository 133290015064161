import { ReactNode, forwardRef } from "react";

import { Ds } from "@accurx/design";
import { FocusRingSpacing, NavLink } from "@accurx/navigation";
import styled from "styled-components";

const StyledButton = styled.button`
    background-color: inherit;
    text-align: left;
    width: 100%;
`;

type SecondaryNavToggleProps = {
    icon?: Pick<Ds.IconProps, "name" | "appearance" | "rotate">;
    isOpen?: boolean;
    onClick?: () => void;
    userflowId?: string;
    children: ReactNode;
};

export const SecondaryNavToggle = forwardRef<
    HTMLButtonElement,
    SecondaryNavToggleProps
>(
    (
        {
            icon,
            isOpen = false,
            onClick,
            children,
            userflowId,
        }: SecondaryNavToggleProps,
        forwardedRef,
    ) => {
        return (
            <FocusRingSpacing>
                <NavLink asChild>
                    <StyledButton
                        ref={forwardedRef}
                        onClick={onClick}
                        data-userflow-id={userflowId ?? undefined}
                    >
                        {icon && <NavLink.Icon {...icon} />}
                        <NavLink.Text>
                            <span>{children}</span>
                            <Ds.Icon
                                name="Arrow"
                                rotate={isOpen ? "0" : "90"}
                                size="xsmall"
                                color="white"
                                style={{ height: "16px", width: "16px" }}
                            />
                        </NavLink.Text>
                    </StyledButton>
                </NavLink>
            </FocusRingSpacing>
        );
    },
);
