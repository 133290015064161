import { ChangeEvent, TextareaHTMLAttributes, forwardRef } from "react";

import { useCompose } from "../../../../context";
import { ComposeActionsTypes } from "../../../../reducer.types";
import { StyledInnerTextarea } from "../InnerTextarea.styles";

type MessageSignatureProps = Pick<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    "onKeyDown" | "onChange" | "onKeyUp"
>;

export const MessageSignature = forwardRef<
    HTMLTextAreaElement,
    MessageSignatureProps
>(function Signature({ onChange, ...props }, ref) {
    const { state, dispatch } = useCompose();
    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        dispatch({
            type: ComposeActionsTypes.UpdateMessageSignature,
            payload: {
                text: e.currentTarget.value,
            },
        });

        onChange?.(e);
    };
    return (
        <StyledInnerTextarea
            ref={ref}
            minRows={1}
            aria-label="Message signature"
            name="compose-message-signature"
            onChange={handleChange}
            value={state.messageSignature}
            {...props}
        />
    );
});
