import { useFeatureFlag } from "@accurx/auth";
import { useUser } from "@accurx/concierge/hooks/data/useUser";
import { useUserConversationGroupQuery } from "@accurx/concierge/hooks/queries/useUserConversationGroupQuery";
import { getMostRecentItem } from "@accurx/concierge/util/getMostRecentItem";
import { Ds } from "@accurx/design";
import {
    ConversationFeed,
    ConversationFeedOptionsWrapper,
    ConversationListHeader,
    ConversationTabItem,
    ConversationTabs,
} from "domains/inbox/components/ConversationList/components";
import { ConversationPreview } from "domains/inbox/components/ConversationPreview/ConversationPreview";
import {
    useInboxLink,
    useInboxParams,
    useInboxTitle,
} from "domains/inbox/hooks/util";
import { useConversationListAnalytics } from "domains/inbox/hooks/util/useConversationListAnalytics";
import { userflowIds } from "domains/inbox/util";

import { ConversationSortOrder } from "../../components/ConversationSortOrder/ConversationSortOrder";
import { getEmptyStateVariant } from "../../components/EmptyState/EmptyState";
import {
    StyledAccessibleHeader,
    StyledConversationFeedContainer,
    StyledConversationList,
    StyledHeaderTitle,
    StyledHeaderWrap,
} from "../styles";

export const Colleagues = () => {
    const { colleagueId, status, sort } = useInboxParams([
        "colleagueId",
        "status",
        "sort",
    ]);
    const link = useInboxLink();
    const colleague = useUser({ userId: colleagueId });

    const groupQuery = useUserConversationGroupQuery({
        userId: colleagueId,
        status,
        sort,
    });

    const { trackTabClick } = useConversationListAnalytics();

    useInboxTitle(
        colleague
            ? `${colleague.displayName}'s ${status.toLowerCase()} conversations`
            : "",
    );
    const emptyStateVariant = getEmptyStateVariant(status);
    const canUseSorting = useFeatureFlag("UnifiedInboxSortingBuild");

    return colleague ? (
        <>
            <StyledConversationList rows="auto 1fr">
                <StyledHeaderWrap>
                    <ConversationListHeader
                        heading={
                            <>
                                <StyledHeaderTitle>
                                    {colleague.displayName}
                                    <StyledAccessibleHeader>
                                        showing {status.toLowerCase()} messages
                                    </StyledAccessibleHeader>
                                </StyledHeaderTitle>
                                {colleague.status === "Unapproved" && (
                                    <Ds.Badge color="greyscale">
                                        Pending approval
                                    </Ds.Badge>
                                )}
                            </>
                        }
                    />

                    <ConversationTabs>
                        <ConversationTabItem
                            active={status === "Open"}
                            link={link.update({
                                status: "Open",
                                sort: null,
                                conversationId: null,
                            })}
                            userflowId={userflowIds.conversationList.openTab}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Done",
                                    newTab: "Open",
                                    conversationParticipant: "WithPatient",
                                })
                            }
                        >
                            Open
                        </ConversationTabItem>

                        <ConversationTabItem
                            active={status === "Done"}
                            link={link.update({
                                status: "Done",
                                sort: null,
                                conversationId: null,
                            })}
                            userflowId={userflowIds.conversationList.doneTab}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Open",
                                    newTab: "Done",
                                    conversationParticipant: "WithPatient",
                                })
                            }
                        >
                            Done
                        </ConversationTabItem>
                    </ConversationTabs>
                </StyledHeaderWrap>
                <StyledConversationFeedContainer>
                    {canUseSorting && (
                        <ConversationFeedOptionsWrapper>
                            <Ds.Flex.Item>
                                <ConversationSortOrder />
                            </Ds.Flex.Item>
                        </ConversationFeedOptionsWrapper>
                    )}
                    <ConversationFeed
                        key={groupQuery.data?.id}
                        isLoading={groupQuery.status === "loading"}
                        isError={groupQuery.status === "error"}
                        refetch={groupQuery.refetch}
                        fetchMore={groupQuery.fetchMore}
                        isFetchingMore={groupQuery.isFetchingMore}
                        isFullyLoaded={groupQuery.data?.isFullyLoaded}
                        members={groupQuery.data?.members}
                        filters={groupQuery.data?.filters}
                        emptyStateVariant={emptyStateVariant}
                        renderItem={(conversation, state) => (
                            <ConversationPreview
                                conversation={conversation}
                                item={getMostRecentItem(conversation)}
                                state={state}
                            />
                        )}
                    />
                </StyledConversationFeedContainer>
            </StyledConversationList>
        </>
    ) : null;
};
