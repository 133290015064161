import React from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { CollapsibleInfo, Ds, FormFieldWrapper, Input } from "@accurx/design";
import { AutoFocusHeading, useAccurxWebTitle } from "@accurx/navigation";

import {
    StyledCardList,
    StyledMain,
    StyledPageWrapper,
} from "app/workspace/pages/shared/Layout/Layout.styles";

const PAGE_TITLE = "Select an organisation to continue";

export const MapWorkspacePage = () => {
    useAccurxWebTitle(PAGE_TITLE);

    const { organisationName } = useCurrentWorkspace();

    const suggestedOrganisations = [
        {
            name: "Suggested Organisation 1",
        },
        {
            name: "Suggested Organisation 2",
        },
        {
            name: "Suggested Organisation 3",
        },
    ];

    return (
        <StyledPageWrapper flexDirection="column">
            <StyledMain gap="3" flexDirection="column">
                <AutoFocusHeading size="xlarge" as="h1">
                    {PAGE_TITLE}
                </AutoFocusHeading>

                <Ds.Text size="large" weight="bold">
                    {organisationName} - where you and your colleagues currently
                    work together in Accurx - needs to be linked to an
                    organisation.
                </Ds.Text>
                <Ds.Text>
                    For an exact result you can search by ODS (Organisation Data
                    Service) code.
                    <Ds.Link
                        href="https://odsportal.digital.nhs.uk/Organisation/Search"
                        target="_blank"
                    >
                        You can find your code on the ODS Portal.
                    </Ds.Link>
                </Ds.Text>

                <CollapsibleInfo title="Help with choosing an organisation">
                    <Ds.Text>You should select:</Ds.Text>
                    <ul>
                        <li>the NHS Trust if the workspace belongs to one.</li>
                        <li>
                            the GP practice or pharmacy if it belongs to one,
                            and not search for a GP federation or Integrated
                            Care Board (ICB)
                        </li>
                        <li>
                            the independent healthcare organisation if it
                            belongs to one, and not the NHS organisation it
                            serves.
                        </li>
                    </ul>

                    <Ds.Text>
                        If you can't find the organisation in the list you can
                        fill in the details instead.
                    </Ds.Text>
                </CollapsibleInfo>

                <form>
                    <FormFieldWrapper>
                        <Ds.Flex flexDirection="row" gap="2">
                            <Input
                                id="search-input"
                                isSearchInput
                                placeholder="Search organisations"
                                aria-label="Search organisations"
                            />
                            <Ds.Button>
                                <Ds.Button.Icon name="Search" />
                                Search
                            </Ds.Button>
                        </Ds.Flex>
                    </FormFieldWrapper>
                </form>

                <Ds.Text weight="bold">Suggested organisations</Ds.Text>
                <StyledCardList>
                    {suggestedOrganisations.map((org, index) => (
                        <li key={index}>{org.name}</li>
                    ))}
                </StyledCardList>
            </StyledMain>
        </StyledPageWrapper>
    );
};
