import React, { ChangeEvent, useEffect, useState } from "react";

import {
    CollapsibleInfo,
    FormFieldFeedback,
    Switch,
    Text,
    TextareaAutosize,
    Tokens,
} from "@accurx/design";
import { setBoxShadow } from "@accurx/design/dist/styles/helpers";
import { i } from "images";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { DECLINE_SETTINGS_DEFAULT_CONFIRMATION_TEXT } from "app/batchMessage/gp/BatchMessage.constant";
import type { BatchDeclineSettings } from "app/batchMessage/gp/BatchMessage.types";
import { updateBatchDeclineSettings } from "app/batchMessage/gp/ComposeDeclineSettings.actions";
import { SnomedCodeSelect } from "app/batchMessage/gp/components/snomedCodes/SnomedCodeSelect";

const StyledTextAreaGroup = styled.div`
    border: ${Tokens.BORDERS.normal.silver};
    border-radius: ${Tokens.BORDERS.radius};
    box-shadow: ${setBoxShadow(Tokens.COLOURS.greyscale.silver)};
    background-color: ${Tokens.COLOURS.greyscale.white};
`;

const StyledTextareaAutosize = styled(TextareaAutosize)<{
    $noBottomRadius?: boolean;
    $noTopRadius?: boolean;
    $noBottomPadding?: boolean;
    disabled: boolean;
}>`
    border: none;
    border-radius: ${(props) =>
            props.$noTopRadius ? "0" : Tokens.BORDERS.radius}
        ${(props) => (props.$noBottomRadius ? "0" : Tokens.BORDERS.radius)};
    padding: ${Tokens.SIZES[2]};
    padding-bottom: ${(props) =>
        props.$noBottomPadding ? 0 : Tokens.SIZES[2]};
    background-color: transparent;
    width: 100%;
    color: ${(props) =>
        props.disabled ? Tokens.COLOURS.greyscale.stone : "inherit"};
`;

export type DeclineSettingsWithErrors = BatchDeclineSettings & {
    isToggled: boolean;
    errors: {
        confirmationText: boolean;
        snomedConceptId: boolean;
    };
};

type ComposeDeclineSettingsProps = {
    declineSettings: DeclineSettingsWithErrors;
    setDeclineSettings: React.Dispatch<
        React.SetStateAction<DeclineSettingsWithErrors>
    >;
};

const ComposeDeclineSettings = ({
    setDeclineSettings,
    declineSettings,
}: ComposeDeclineSettingsProps): JSX.Element => {
    const [isDeclineSettingsVisible, setIsDeclineSettingsVisible] =
        useState<boolean>(false);

    const dispatch = useDispatch();

    useEffect(() => {
        setDeclineSettings({
            isToggled: false,
            snomedConceptId: "",
            confirmationText: DECLINE_SETTINGS_DEFAULT_CONFIRMATION_TEXT,
            errors: {
                snomedConceptId: false,
                confirmationText: false,
            },
        });
        dispatch(
            updateBatchDeclineSettings({
                confirmationText: "",
                snomedConceptId: "",
            }),
        );
        // N.B: "declineSettings" state from the component above needs to be updated with default value on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // 1.
        // Reset "declineSettings" to empty state, so it doesn't get stored in Redux and collide with Review Page
        if (
            isDeclineSettingsVisible === false &&
            declineSettings.confirmationText
        ) {
            setDeclineSettings({
                ...declineSettings,
                confirmationText: "",
                snomedConceptId: "",
            });
        }

        // 2.
        // Set "confirmationText" to default value so it's reflected correctly after toggling Allow Decline
        if (isDeclineSettingsVisible && !declineSettings.confirmationText) {
            setDeclineSettings({
                ...declineSettings,
                confirmationText: DECLINE_SETTINGS_DEFAULT_CONFIRMATION_TEXT,
            });
        }

        //
        // N.B: "declineSettings" needs to be omitted to prevent infinite loop when "setDeclineSettings" is being called
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeclineSettingsVisible, setDeclineSettings]);

    return (
        <>
            <Switch
                colour="green"
                labelText="Allow decline"
                onChange={() => {
                    setIsDeclineSettingsVisible(!isDeclineSettingsVisible);
                    setDeclineSettings({
                        ...declineSettings,
                        isToggled: !declineSettings.isToggled,
                    });
                }}
            />
            {isDeclineSettingsVisible && (
                <>
                    <div className="mt-2"></div>
                    <Text
                        as="label"
                        variant="label"
                        props={{ htmlFor: "decline-message-body" }}
                    >
                        Decline message
                    </Text>
                    <Text skinny>
                        This message will appear before patients confirm that
                        they want to decline the invite.
                    </Text>
                    <div className="mt-1"></div>
                    <CollapsibleInfo title="Where will patients see this message?">
                        <img
                            className="w-100"
                            src={i("batchMessaging/decline-settings-demo.jpg")}
                            alt="Highlighted paragraph area underneath the title ‘Are you sure you don’t want this’. 2 buttons after the paragraph area, ‘I don’t want this’ and ‘Back to booking’."
                        />
                    </CollapsibleInfo>
                    <StyledTextAreaGroup>
                        <StyledTextareaAutosize
                            disabled={false}
                            id="decline-message-body"
                            value={declineSettings.confirmationText}
                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                                const text = e.target.value;

                                setDeclineSettings({
                                    ...declineSettings,
                                    confirmationText: text,
                                });
                            }}
                        />
                    </StyledTextAreaGroup>
                    {declineSettings.errors.confirmationText && (
                        /* N.B: Negative margin is applied to negate the <SnomedCodeSelect> margin bottom */
                        <div className="mt-1">
                            <FormFieldFeedback
                                text="Tell patients what will happen if they decline the invite. This will appear before patients confirm that they want to decline."
                                variant="error"
                            />
                        </div>
                    )}

                    <div className="mt-4"></div>

                    <SnomedCodeSelect
                        id="decline-settings-snomed-dropdown"
                        batchSnomedSelect="Booking - Decline"
                        label="Decline SNOMED code"
                        sublabel="This will be saved to record if the appointment invite is declined."
                        selectedTemplateDisplay={""}
                        selectedSnomedCode={""}
                        setSelectedTemplateSnomedConceptId={(snomedCode) => {
                            setDeclineSettings({
                                ...declineSettings,
                                errors: {
                                    snomedConceptId: false,
                                    confirmationText: false,
                                },
                                snomedConceptId: snomedCode,
                            });
                        }}
                    />
                    {declineSettings.errors.snomedConceptId && (
                        /* N.B: Negative margin is applied to negate the <SnomedCodeSelect> margin bottom */
                        <div className="mt-n3">
                            <FormFieldFeedback
                                text="Select a SNOMED code to save to record when invite is declined."
                                variant="error"
                            />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default ComposeDeclineSettings;
