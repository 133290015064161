import React, { useEffect, useMemo } from "react";

import { Owner, Recipient } from "@accurx/api/content";
import {
    Ds,
    Feedback,
    Flex,
    Item,
    Table,
    Text,
    useTable,
} from "@accurx/design";
import {
    useEditMessageTemplateMutation,
    useMessageTemplates,
} from "@accurx/message-templates";
import { ArchivedWorkspaceHiddenItemWrapper } from "@accurx/workspace-management";

import Linkify from "app/sharedComponents/linkify/Linkify";

import { trackManageTemplatePageView } from "../../analytics/FlemingAnalytics";
import { useFlemingLoggedInAnalytics } from "../../sessionAnalytics/useFlemingLoggedInAnalytics";
import { CategoriesFilter } from "./CategoriesFilter";
import { StyledTableBody } from "./ManageTemplates.styles";
import {
    FEEDBACK_SEARCH_TEXT,
    shouldDisplayCategoryHeader,
} from "./ManageTemplates.utils";
import { TabIds } from "./ManageTemplatesOverview";
import { SendTypeSwitches } from "./SendTypeSwitches";
import SnomedTag from "./SnomedTag";
import { getDistinctCategories } from "./utils";

type AccurxTemplatesPanelProps = {
    orgId: number;
};

export const AccurxTemplatesPanel = ({
    orgId,
}: AccurxTemplatesPanelProps): JSX.Element => {
    const { data, status } = useMessageTemplates(orgId, {
        owner: Owner.Accurx,
        recipient: Recipient.Patient,
    });

    const { mutate: updateSendType, isError } =
        useEditMessageTemplateMutation();

    const flemingLoggedInProperties = useFlemingLoggedInAnalytics();

    const sortedTemplates = data?.templates
        .sort((a, b) => a.title.localeCompare(b.title))
        .sort((a, b) => a.category.localeCompare(b.category));

    const table = useTable({
        data: sortedTemplates ?? [],
        columnDefs: [
            {
                header: "Template name",
                id: "title",
            },
            {
                header: "Details",
                id: "body",
            },
            {
                header: "Category",
                id: "category",
            },
            {
                header: "Availability",
                id: "availability",
            },
        ],
    });

    const distinctCategories = useMemo(() => {
        return getDistinctCategories(table.rows);
    }, [table.rows]);
    const countTemplateCategories = distinctCategories.length;

    useEffect(() => {
        trackManageTemplatePageView({
            ...flemingLoggedInProperties,
            countTemplateCategories,
            currentTab: TabIds.AccurxTemplates,
            productOrigin: "PatientMessage",
        });
    }, [flemingLoggedInProperties, countTemplateCategories]);

    return (
        <Ds.Flex gap="3" flexDirection="column">
            <Text skinny>
                These templates are provided by Accurx. They cannot be deleted,
                but they can be "hidden" so that they do not appear for your
                team. If you would like to edit a template, you can copy the
                text, create your own, and then hide the Accurx template.
            </Text>
            {isError && (
                <Feedback
                    colour="error"
                    title="There was a problem switching template availability"
                >
                    <Ds.Text>Try again later</Ds.Text>
                </Feedback>
            )}

            {status === "success" && (
                <Table>
                    <Table.Head>
                        <Table.Row>
                            <Table.Header colSpan={4}>
                                <Ds.Grid
                                    columns={{ xs: "1fr", sm: "1fr 1fr" }}
                                    gap="1"
                                >
                                    <div>
                                        <Text variant="subtitle">
                                            Accurx templates
                                        </Text>
                                        {table.rows.length !== 0 && (
                                            <Text>{FEEDBACK_SEARCH_TEXT}</Text>
                                        )}
                                    </div>
                                    {table.rows.length && (
                                        <CategoriesFilter
                                            distinctCategories={
                                                distinctCategories
                                            }
                                            onFilterSelect={(filterTemplates) =>
                                                table.actions.filter((row) =>
                                                    filterTemplates(
                                                        row.category,
                                                    ),
                                                )
                                            }
                                            currentTable={
                                                TabIds.AccurxTemplates
                                            }
                                        />
                                    )}
                                </Ds.Grid>
                            </Table.Header>
                        </Table.Row>
                        <Table.Row>
                            {table.columns.map((column) => (
                                <Table.Header
                                    scope={"col"}
                                    key={column.id}
                                    colour="greyscale"
                                >
                                    {column.header}
                                </Table.Header>
                            ))}
                        </Table.Row>
                    </Table.Head>
                    <StyledTableBody>
                        {table.rows.map((row, index) => (
                            <React.Fragment key={row.id}>
                                {shouldDisplayCategoryHeader({
                                    currentRowCategoryHeading: row.category,
                                    previousRowCategoryHeading:
                                        table.rows[index - 1]?.category,
                                    currentIndex: index,
                                }) && (
                                    <Table.Row>
                                        <Table.Header
                                            colSpan={5}
                                            scope="colgroup"
                                            colour="greyscale"
                                        >
                                            <Text
                                                skinny
                                                variant="subtitle"
                                                as="span"
                                            >
                                                {row.category}
                                            </Text>
                                        </Table.Header>
                                    </Table.Row>
                                )}
                                <Table.Row>
                                    <Table.Header scope="row">
                                        {row.title}
                                    </Table.Header>
                                    <Table.DataCell>
                                        <div>
                                            <Linkify>{row.body}</Linkify>
                                            {row.snomedCodes.map(
                                                (snomedCode, index) => (
                                                    <SnomedTag
                                                        key={`${snomedCode.conceptId}-${index}`}
                                                        text={snomedCode.term}
                                                        code={
                                                            snomedCode.conceptId
                                                        }
                                                    />
                                                ),
                                            )}
                                        </div>
                                    </Table.DataCell>
                                    <Table.DataCell>
                                        {row.category}
                                    </Table.DataCell>
                                    <Table.DataCell>
                                        <ArchivedWorkspaceHiddenItemWrapper>
                                            <SendTypeSwitches
                                                orgId={orgId}
                                                template={row}
                                                updateSendType={updateSendType}
                                            />
                                        </ArchivedWorkspaceHiddenItemWrapper>
                                    </Table.DataCell>
                                </Table.Row>
                            </React.Fragment>
                        ))}
                    </StyledTableBody>
                    {table.rows.length > 0 && (
                        <Table.Foot>
                            <Table.Row>
                                <Table.DataCell colSpan={4}>
                                    <Flex justifyContent={"center"}>
                                        <Item>Showing {table.rows.length}</Item>
                                    </Flex>
                                </Table.DataCell>
                            </Table.Row>
                        </Table.Foot>
                    )}
                </Table>
            )}

            {status === "error" && (
                <Feedback
                    colour="error"
                    title="There was a problem loading Accurx templates"
                >
                    <Ds.Text>Reload this page to try again</Ds.Text>
                </Feedback>
            )}

            {status === "loading" && (
                <Ds.Flex justifyContent="center">
                    <Ds.Spinner />
                </Ds.Flex>
            )}
        </Ds.Flex>
    );
};
