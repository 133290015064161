import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useCurrentWorkspace } from "@accurx/auth";
import { Ds } from "@accurx/design";
import { AppOrigin } from "@accurx/message-component";
import { NavTooltip, SplitButtonGroup } from "@accurx/navigation";
import { StyledMessageButton } from "layouts/UnifiedLayout/UnifiedLayout.styles";
import { userflowIds } from "layouts/constants";
import { useLocation } from "react-router";
import { Link, useHistory } from "react-router-dom";

import { VideoConsultModal } from "../PatientSearch/VideoConsultModal";
import { NewMessageDropdown } from "./NewMessageDropdown";

export const NewMessage = ({ hideText = false }: { hideText?: boolean }) => {
    const originLocation = useLocation();
    const { orgId } = useCurrentWorkspace();
    const [videoConsultModalOpen, setvideoConsultModalOpen] = useState(false);

    const track = useAnalytics();
    const history = useHistory();

    const handleMessagePatientClick = () => {
        track("NavigationMenu Button Click", {
            origin: history.location.pathname,
            navigationOptionSelected: "MessagePatient",
            navigationVersion: "Unified",
            eventVersion: 2,
        });
    };

    const messagePatientAppOrigin = AppOrigin.NavigationNewMessage;

    return (
        <>
            <SplitButtonGroup>
                <NewMessageDropdown
                    openVideoConsultModal={() => setvideoConsultModalOpen(true)}
                    hideText={hideText}
                />
                <NavTooltip text="Message patient">
                    <StyledMessageButton
                        as={Link}
                        onClick={handleMessagePatientClick}
                        to={{
                            pathname: `/w/${orgId}/compose/search`,
                            state: {
                                originLocation,
                                appOrigin: messagePatientAppOrigin,
                            },
                        }}
                        data-userflow-id={
                            userflowIds.navigation.primary.messagePatient
                        }
                        aria-label="Message patient"
                    >
                        <Ds.Icon name="preview-Message" size="small" />
                    </StyledMessageButton>
                </NavTooltip>
            </SplitButtonGroup>
            {videoConsultModalOpen && (
                <VideoConsultModal
                    modalOpen={true}
                    setModalOpen={setvideoConsultModalOpen}
                />
            )}
        </>
    );
};
