import styled from "styled-components";

export const StyledTooltipTriggerWrapper = styled.span`
    > span {
        /* 
           This is to fix the issue of adding tabIndex to tooltip trigger 
           when ellipsis is active. If we don't override, the ellipsis will
           be broken
        */
        display: inline;
    }

    // Do not show focus state of the tooltip
    &:focus-within {
        span[tabindex="-1"]:focus,
        span[tabindex="-1"]:focus-visible {
            box-shadow: none;
        }
    }
`;
