import React from "react";

import { Organisation } from "@accurx/auth";
import { Text } from "@accurx/design";
import { AutoFocusHeading } from "@accurx/navigation";
import { isArchivedWorkspace } from "@accurx/workspace-management";
import { shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";

import { NavSubMenuComponent } from "app/navbar/NavSubMenuComponent";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { PatientHelper } from "shared/PatientHelper";
import { useAppSelector } from "store/hooks";

import PatientInfoSubMenu from "../navbar/PatientInfoSubMenuComponent";
import MoreInformationTooltipLink from "../sharedComponents/moreInformationTooltipLink/MoreInformationTooltipLinkComponent";
import { ListPageWrapper, StyledHeaderText } from "./PageHeader.styles";

export enum PageHeaderType {
    Legacy,
    ListPage,
    PatientPage,
}

type LayoutProps = {
    title: string;
    type: PageHeaderType;
    helperText?: string | JSX.Element;
    displayPatientInfo?: boolean;
};

export const PageHeader = ({
    title,
    type,
    helperText,
    displayPatientInfo = true,
}: LayoutProps) => {
    const history = useHistory();

    const patient = useAppSelector(
        ({ searchForPatient }) =>
            type === PageHeaderType.PatientPage
                ? PatientHelper.getPatient(searchForPatient.lastResponse)
                : undefined,
        shallowEqual,
    );

    const organisation = useAppSelector(
        ({ account }) => OrganisationHelper.getOrganisation(account),
        shallowEqual,
    );

    const isArchived = organisation
        ? isArchivedWorkspace(organisation as Organisation)
        : false;

    const handleGoBack = (): void => {
        history.goBack();
    };

    switch (type) {
        case PageHeaderType.ListPage:
            return (
                <ListPageWrapper>
                    <AutoFocusHeading>{title}</AutoFocusHeading>
                    {helperText && !isArchived && (
                        <MoreInformationTooltipLink
                            text="What can I do here?"
                            id="tooltip-header"
                        >
                            <Text as="span" variant="label">
                                {helperText}
                            </Text>
                        </MoreInformationTooltipLink>
                    )}
                </ListPageWrapper>
            );
        case PageHeaderType.PatientPage:
            return (
                <ListPageWrapper>
                    {patient && (
                        <NavSubMenuComponent backCallback={handleGoBack}>
                            {displayPatientInfo && (
                                <PatientInfoSubMenu patient={patient} />
                            )}
                        </NavSubMenuComponent>
                    )}
                    <StyledHeaderText variant="subtitle" forwardedAs="h1">
                        {title}
                    </StyledHeaderText>
                </ListPageWrapper>
            );
        case PageHeaderType.Legacy:
        default:
            return (
                <NavSubMenuComponent>
                    <Text variant="title" as="h1">
                        {title}
                    </Text>
                </NavSubMenuComponent>
            );
    }
};
