import { ReactNode } from "react";

import { useAnalytics } from "@accurx/analytics";
import { Ds } from "@accurx/design";
import { FocusRingSpacing, NavLink } from "@accurx/navigation";
import { Link } from "react-router-dom";

import { pascalCase } from "shared/formatters/String";
import { useRouteMatchOrTwoFactorPrompt } from "shared/useRouteMatchOrTwoFactorPrompt";

import { getNavigationVersion } from "../helpers";

export const SecondaryNavLink = ({
    to,
    icon,
    isExact,
    analyticsId,
    userflowId,
    children,
}: {
    to: string;
    icon?: Pick<Ds.IconProps, "name" | "appearance" | "rotate">;
    isExact?: boolean;
    analyticsId?: string;
    userflowId?: string;
    children: ReactNode;
}) => {
    const track = useAnalytics();
    const match = useRouteMatchOrTwoFactorPrompt({
        path: to,
    });
    const isActive = Boolean(isExact ? match?.isExact : match);

    const handleClick = () => {
        track("NavigationSubMenu Button Click", {
            navigationOptionSelected:
                analyticsId || pascalCase(String(children)),
            navigationVersion: getNavigationVersion(),
            buttonType: "Button",
            eventVersion: 3,
        });
    };

    return (
        <FocusRingSpacing>
            <NavLink isActive={isActive} asChild>
                <Link
                    to={{
                        pathname: to,
                        state: {
                            navigationOrigin: "Web",
                        },
                    }}
                    onClick={handleClick}
                    data-userflow-id={userflowId ?? undefined}
                >
                    {icon && <NavLink.Icon {...icon} />}
                    <NavLink.Text>{children}</NavLink.Text>
                </Link>
            </NavLink>
        </FocusRingSpacing>
    );
};
