import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledPageWrapper = styled.main`
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: ${Tokens.COLOURS.greyscale.cloud};
`;
export const StyledPatientHeaderWrapper = styled.div`
    background-color: ${Tokens.COLOURS.greyscale.white};
    border-bottom: ${Tokens.BORDERS.normal.silver};
    width: 100%;
    padding: ${Tokens.SIZES[2]};
`;

export const StyledMainContentWrapper = styled.div`
    min-width: 0;
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
`;
export const StyledQuickviewWrapper = styled.div`
    border-left: 1px solid ${Tokens.COLOURS.greyscale.silver};
    width: 100%;
    position: relative;
    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        min-width: 280px;
        flex: 0.5;
    }
    &:empty {
        min-width: 0;
        flex: 0;
    }
`;
