import { configureStore } from "@reduxjs/toolkit";
import { ConciergeState } from "domains/concierge/internal/types/ConciergeState";
import { ConnectionState } from "domains/concierge/internal/types/ConnectionState";

import * as middleware from "./middleware";
import * as slices from "./slices";
import { ConciergeMeta } from "./types/ConciergeMeta";
import { ConciergeUpdates } from "./types/ConciergeUpdates";

export const createStore = (
    meta: ConciergeMeta,
    options?: {
        preloadedState?: Partial<ConciergeState>;
    },
) =>
    configureStore({
        reducer: {
            conversations: slices.conversations.reducer,
            patients: slices.patients.reducer,
            users: slices.users.reducer,
            teams: slices.teams.reducer,
            queries: slices.queries.reducer,
        },
        devTools:
            process.env.NODE_ENV !== "production"
                ? { name: `Concierge Layer [${meta.workspaceId}]` }
                : false,
        middleware: (getDefaultMiddleware) => {
            return getDefaultMiddleware({
                serializableCheck: process.env.NODE_ENV === "development",
                immutableCheck: process.env.NODE_ENV === "development",
            })
                .concat(middleware.meta(meta))
                .concat(middleware.timings)
                .concat(middleware.debug(meta));
        },
        preloadedState: options?.preloadedState,
    });

export const actions = {
    conversations: slices.conversations.actions,
    patients: slices.patients.actions,
    queries: slices.queries.actions,
    processUpdates: (updates: ConciergeUpdates & { source: string }) => ({
        type: "processUpdates",
        payload: updates,
    }),
    connectionStateChange: (state: ConnectionState) => ({
        type: "connectionStateChange",
        payload: state,
    }),
};
