import { FeatureName, useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import { useWorkspaceUnreadCountsQuery } from "@accurx/concierge";
import { Ds } from "@accurx/design";
import { NavTooltip, useCustomIntercomLauncher } from "@accurx/navigation";
import { ArchivedWorkspaceHiddenItemWrapper } from "@accurx/workspace-management";
import { i } from "images";
import { userflowIds } from "layouts/constants";
import { generatePath } from "react-router-dom";

import { ROUTES, ROUTES_BASE, ROUTES_PRIMARY } from "shared/Routes";

import { DirectoryButton } from "./Directory/DirectoryButton";
import { HelpPopover } from "./HelpPopover/HelpPopover";
import { PrimaryNavLink } from "./NavLink/PrimaryNavLink";
import { NewMessage } from "./NewMessage/NewMessage";
import { NewPatientSearch } from "./PatientSearch/NewPatientSearch";
import {
    StyledColOne,
    StyledColThree,
    StyledColTwo,
    StyledNavLinkList,
    StyledPrimaryNavigation,
    StyledSplitWorkspaceButton,
    StyledThreeColumnGrid,
} from "./UnifiedPrimaryNavigation.styles";
import { WorkspaceMenu } from "./WorkspaceMenu/WorkspaceMenu";
import { WorkspaceSwitcher } from "./WorkspaceSwitcher/WorkspaceSwitcher";

export const UnifiedPrimaryNavigation = () => {
    useCustomIntercomLauncher();
    const { orgId: workspaceId } = useCurrentWorkspace();

    const { unreadCount } = useWorkspaceUnreadCountsQuery({
        hasClinicianMessagingInbox: useFeatureFlag(
            "UnifiedInboxAccumailSandboxBuild",
        ),
    });

    const isIntegratedClinicsEnabled = useFeatureFlag(
        FeatureName.IntegratedClinicLists,
    );
    const isDirectoryRolloutOn = useFeatureFlag(
        FeatureName.NavigationWebDirectoryRollout,
    );

    const listsPath = isIntegratedClinicsEnabled
        ? generatePath(ROUTES_BASE.integratedClinicListsAll, {
              workspaceId,
          })
        : ROUTES_BASE.patientsListsAll;

    // TODO: Remove once GPs are familiar with 2FA and Unified Inbox
    const isManageOrgRedirect = Boolean(
        window.sessionStorage.getItem("isManageOrgRedirect"),
    );

    return (
        <StyledPrimaryNavigation data-testid="primary-nav">
            <StyledThreeColumnGrid>
                <StyledColOne>
                    {isManageOrgRedirect ? (
                        <Ds.Box ml="1">
                            <img
                                src={i("accurx-logo-small.svg")}
                                width={24}
                                height={14}
                                alt=""
                            />
                        </Ds.Box>
                    ) : (
                        <>
                            <ArchivedWorkspaceHiddenItemWrapper>
                                <NewMessage />
                            </ArchivedWorkspaceHiddenItemWrapper>
                            <NewPatientSearch />
                            {isDirectoryRolloutOn && <DirectoryButton />}
                        </>
                    )}
                </StyledColOne>
                <StyledColTwo>
                    {!isManageOrgRedirect && (
                        <StyledNavLinkList>
                            <NavTooltip
                                text={
                                    "Inbox" +
                                    (unreadCount > 0
                                        ? ` • ${unreadCount} unread`
                                        : "")
                                }
                            >
                                <PrimaryNavLink
                                    activePaths={[ROUTES.inbox]}
                                    to={generatePath(ROUTES.inbox, {
                                        workspaceId,
                                    })}
                                    data-userflow-id={
                                        userflowIds.navigation.primary.inbox
                                    }
                                    navigationOptionSelected="Inbox"
                                >
                                    <Ds.Icon
                                        name="Mailbox"
                                        color="white"
                                        size="small"
                                    />
                                    <Ds.Text
                                        as="span"
                                        weight="bold"
                                        color="white"
                                    >
                                        Inbox
                                    </Ds.Text>
                                    {unreadCount > 0 && (
                                        <Ds.Badge radius="round">
                                            {unreadCount}
                                        </Ds.Badge>
                                    )}
                                </PrimaryNavLink>
                            </NavTooltip>
                            <NavTooltip text="Patient lists">
                                <PrimaryNavLink
                                    activePaths={[
                                        ROUTES_PRIMARY.patientsLists,
                                        ROUTES_PRIMARY.batchMessageTrust,
                                        ROUTES_PRIMARY.questionnaireScoreboard,
                                        ROUTES_BASE.integratedClinicListsAll,
                                    ]}
                                    to={listsPath}
                                    data-userflow-id={
                                        userflowIds.navigation.primary
                                            .patientLists
                                    }
                                    navigationOptionSelected="PatientLists"
                                >
                                    <Ds.Icon
                                        name="preview-PatientList"
                                        color="white"
                                        size="small"
                                    />
                                    <Ds.Text
                                        as="span"
                                        weight="bold"
                                        color="white"
                                    >
                                        Lists
                                    </Ds.Text>
                                </PrimaryNavLink>
                            </NavTooltip>
                        </StyledNavLinkList>
                    )}
                </StyledColTwo>
                <StyledColThree>
                    <StyledSplitWorkspaceButton className="TESTING">
                        <WorkspaceMenu />
                        <WorkspaceSwitcher />
                    </StyledSplitWorkspaceButton>
                    <HelpPopover workspaceId={workspaceId} />
                </StyledColThree>
            </StyledThreeColumnGrid>
        </StyledPrimaryNavigation>
    );
};
