import { useEffect } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useCurrentWorkspace } from "@accurx/auth";
import { Ds, Feedback } from "@accurx/design";
import { useNavigationOrigin } from "@accurx/navigation";

import { usePatientTriageStatusQuery } from "app/hooks/queries/usePatientTriageStatusQuery";
import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { WhatIsPatientTriage } from "app/practices/patientTriage/components";

export const WhatIsPatientTriagePage = () => {
    const track = useAnalytics();
    const navigationOrigin = useNavigationOrigin();

    const { orgId } = useCurrentWorkspace();
    const { status, data } = usePatientTriageStatusQuery({
        workspaceId: orgId.toString(),
    });

    useEffect(() => {
        track("PatientTriageIntro Page View", { navigationOrigin });
    }, [track, navigationOrigin]);

    return (
        <StyledContainer>
            <PageHeader
                title="What is Patient Triage?"
                type={PageHeaderType.ListPage}
            />
            {status === "loading" ? (
                <Ds.Flex justifyContent="center">
                    <Ds.Spinner />
                </Ds.Flex>
            ) : status === "error" ? (
                <Feedback
                    title="Unable to receive Patient Triage status, please try again"
                    colour="error"
                />
            ) : (
                <WhatIsPatientTriage practiceLink={data.url} />
            )}
        </StyledContainer>
    );
};
