import { StyledActionStack } from "../../ActionStack.styles";

export const AttachmentSize = ({ size }: { size: number }) => {
    const sizeInMegabytes = size / (1024 * 1024);

    const rounded = Math.ceil(sizeInMegabytes * 10) / 10;

    return (
        <StyledActionStack.RowText colour="metal">{`${rounded}MB`}</StyledActionStack.RowText>
    );
};
