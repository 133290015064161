import { NativeApi } from "@accurx/native";
import { createEndpoint } from "domains/navigation/native/createEndpoint";
import {
    MaterialisedPatientDemographics,
    MaterialisedPatientDemographicsSchema,
} from "domains/navigation/schemas/MaterialisedPatientDemographicsSchema";
import { PatientExternalIdentityDto } from "domains/navigation/schemas/PatientExternalIdentityDtoSchema";

export type PatientDemographicsQueryPayload = {
    patientExternalIdentityDto: PatientExternalIdentityDto;
};

/**
 * Clients should load demographics from the medical record
 * when a patient is selected from a list search results.
 *
 * We avoid calling more often as the clinical system audit logs can fill up with these queries.
 * SystmOne practices have complained about this in the past. For example, we should not be calling this when loading
 * lists of search results, instead we using a lighter search API.
 *
 * A side effect of this request is that demographics may get pushed to our servers
 * @param {PatientThreadPatientDetails.demographicsLastRefreshed} - see implementation behind this for more details
 */
export const searchPatientQuery = createEndpoint<
    MaterialisedPatientDemographics,
    PatientDemographicsQueryPayload
>("QueryPatientDemographics", async (context, payload) => {
    const result = await NativeApi.request({
        transport: context.transport,
        request: {
            type: "QueryPatientDemographics",
            workspaceId: context.workspaceId,
            payload: payload,
        },
    });

    return MaterialisedPatientDemographicsSchema.parse(result);
});
