import * as UI from "@accurx/design";
import { Ds } from "@accurx/design";
import styled from "styled-components";

import { TrackedDimensionContainer } from "../TrackedDimensionContainer/TrackedDimensionContainer";

export const StyledContainer = styled(TrackedDimensionContainer)`
    display: flex;
    gap: ${UI.Tokens.SIZES[1.5]};
    padding: ${UI.Tokens.SIZES[1.5]};
    flex-direction: column;
    flex: 1;
    position: relative;
`;

export const StyledScrollableFixedHeightContainer = styled(Ds.Flex).attrs({
    flexDirection: "column",
    gap: "1",
})<{ $isHeightRestricted: boolean }>`
    /* Flex basis of 0 when height is not restricted  prevents the component from 
     * growing outside of it's parent. When height is restricted, the min-content 
     * flex basis prevents the component being collapsed entirely */
    flex: 1 1
        ${({ $isHeightRestricted }) =>
            $isHeightRestricted ? "min-content" : "0"};

    overflow-y: auto;
    overflow-x: hidden;
    ${({ $isHeightRestricted }) => $isHeightRestricted && "max-height: 40vh"};

    /* Prevent clipping of the text areas outline while maintaining 
     * it's alignment */
    padding: ${UI.Tokens.SIZES[1]};
    margin: -${UI.Tokens.SIZES[1]};
`;

export const StyledFlexGrowContainer = styled(UI.Flex).attrs({
    gap: "2",
    flexDirection: "column",
})`
    flex: 1;
`;
