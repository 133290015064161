import {
    PatientThreadContentType,
    TicketIdentityType,
} from "@accurx/api/patient-messaging";
import { Log } from "@accurx/shared";

export const mapSentItemToTicketIdentityType = ({
    type,
    id,
}: {
    type: PatientThreadContentType;
    id?: string | null;
}): TicketIdentityType | null => {
    switch (type) {
        case PatientThreadContentType.NhsAppMessage:
            return TicketIdentityType.NhsAppMessage;
        case PatientThreadContentType.SMS:
            return TicketIdentityType.SMS;
        case PatientThreadContentType.PatientEmail:
            return TicketIdentityType.PatientEmail;
        default:
            Log.error(
                "Cannot map message to a valid TicketIdentityType to be saved to record",
                {
                    tags: {
                        itemType: type,
                        itemId: id,
                        product: "Compose",
                    },
                },
            );
            return null;
    }
};
