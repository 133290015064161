import * as UI from "@accurx/design";

export const PatientNotFound = () => {
    return (
        <>
            <UI.VisuallyHidden as="span">Patient not found, </UI.VisuallyHidden>
            <UI.Ds.Badge color="yellow" aria-hidden="true">
                Patient not found
            </UI.Ds.Badge>
        </>
    );
};
