import { useState } from "react";

import {
    Button,
    Ds,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Text,
} from "@accurx/design";
import { useRealtimeForSignalR } from "@accurx/realtime";

const STORAGE_KEY = "ACCURX_DISABLE_SIGNALR";

const getIsDisabled = (): boolean =>
    sessionStorage.getItem(STORAGE_KEY) === "true";

export const SignalRToggle = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [checked, setChecked] = useState(false || getIsDisabled());

    const { connectionState: hubClientConnection } = useRealtimeForSignalR();

    const onCheckedChange = (c: boolean): void => {
        if (c) {
            sessionStorage.setItem(STORAGE_KEY, "true");
        } else {
            sessionStorage.removeItem(STORAGE_KEY);
        }
        setChecked(c);

        window.location.reload();
    };

    return (
        <Popover open={isOpen} onOpenChange={setIsOpen}>
            <PopoverTrigger asChild>
                <Button
                    theme="secondary"
                    icon={{ name: "Messages" }}
                    text="SignalR"
                    dimension="small"
                />
            </PopoverTrigger>
            <PopoverContent side="top" align="center" width="max-content">
                <Text>
                    <b>Connection: </b>
                    {hubClientConnection}
                </Text>
                <Ds.Switch checked={checked} onCheckedChange={onCheckedChange}>
                    <Ds.Switch.Label>Disable SignalR</Ds.Switch.Label>
                    <Ds.Switch.Toggle />
                </Ds.Switch>
                <Text skinny variant="preview">
                    (Toggling 👆 will reload the page)
                </Text>
            </PopoverContent>
        </Popover>
    );
};
