import { Ds } from "@accurx/design";
import styled from "styled-components";

import { borderCompensation } from "../Textarea/components/TextAreaWrapper/TextAreaWrapper.styles";

export const StyledErrorContainer = styled(Ds.Flex).attrs({
    gap: "0.25",
    alignItems: "center",
})`
    ${borderCompensation}
`;
