import { ContactDetail } from "../components/Compose/types";

export const getContactDetailLabel = (origin: ContactDetail["origin"]) => {
    switch (origin) {
        case "PDS": {
            return "From PDS";
        }
        case "EMR": {
            return "From medical record";
        }
        case "Conversation": {
            return "From this conversation";
        }
        case "SubmittedByPatient":
        case "SubmittedByPatientProxy":
            return "Submitted by patient";
    }
};
