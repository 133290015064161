import { BulkAppointmentUploadItem } from "@accurx/api/appointment";
import { useCurrentWorkspace } from "@accurx/auth";
import { Ds, Feedback, Table } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import {
    getUploadPagePath,
    getUploadedPagePath,
} from "domains/appointment-reminders/Routes";
import { useBulkAppointmentImportConfirmUploadMutation } from "domains/appointment-reminders/hooks";
import { Redirect, useLocation, useParams } from "react-router";
import { useHistory } from "react-router-dom";

import { NavSubMenuComponent } from "../../../../app/navbar/NavSubMenuComponent";
import {
    FooterContainer,
    MainContentsContainer,
    MainContentsText,
    PageContainer,
    StyledReviewUploadAppointmentsText,
    StyledTextWithHalo,
    StyledTruncatedTableData,
} from "./ReviewAppointmentsPage.styles";

interface LocationState {
    data: BulkAppointmentUploadItem[];
}

export const ReviewAppointmentsPage = (): JSX.Element => {
    const { state: locationState } = useLocation<LocationState | undefined>();
    const { orgId: workspaceId } = useCurrentWorkspace();
    const { uploadId } = useParams<{ uploadId: string }>();

    if (locationState === undefined) {
        return <Redirect to={getUploadPagePath(workspaceId)} />;
    }
    return (
        <ReviewAppointmentsContainer
            workspaceId={workspaceId}
            uploadItems={locationState.data}
            uploadId={uploadId}
        />
    );
};

type ReviewAppointmentsContainerProps = {
    uploadId: string;
    uploadItems: BulkAppointmentUploadItem[];
    workspaceId: number;
};

const ReviewAppointmentsContainer = ({
    uploadItems,
    workspaceId,
    uploadId,
}: ReviewAppointmentsContainerProps): JSX.Element => {
    const history = useHistory();

    const {
        mutate: confirmUpload,
        status: confirmUploadStatus,
        error: confirmUploadError,
    } = useBulkAppointmentImportConfirmUploadMutation();

    const handleContinueClick = () => {
        confirmUpload(
            {
                workspaceId: workspaceId.toString(),
                bulkAppointmentImportId: uploadId,
            },
            {
                onSuccess: () => {
                    history.push(getUploadedPagePath(workspaceId));
                },
            },
        );
    };

    return (
        <PageContainer>
            <NavSubMenuComponent backCallback={() => history.goBack()} />
            <MainContentsContainer>
                <MainContentsText>
                    <Ds.Text as="h1" weight="bold" size="large">
                        Review appointments
                    </Ds.Text>
                </MainContentsText>
                {confirmUploadStatus === "error" && (
                    <Feedback
                        colour="error"
                        title="We could not process the appointments."
                    >
                        {confirmUploadError.message}
                    </Feedback>
                )}
                <Ds.Accordion defaultValue="item-1">
                    <Ds.Accordion.Item value="item-1">
                        <Ds.Accordion.Trigger>
                            <StyledTextWithHalo>
                                <Ds.Text as="span" weight="bold">
                                    {uploadItems.length}
                                </Ds.Text>
                            </StyledTextWithHalo>
                            <StyledReviewUploadAppointmentsText>
                                Appointments
                            </StyledReviewUploadAppointmentsText>
                        </Ds.Accordion.Trigger>

                        <Ds.Accordion.Content>
                            <Table>
                                <Table.Head>
                                    <Table.Row>
                                        <Table.Header scope="column">
                                            Appointment id
                                        </Table.Header>
                                        <Table.Header scope="column">
                                            Clinic code
                                        </Table.Header>
                                        <Table.Header scope="column">
                                            Appointment start time
                                        </Table.Header>
                                        <Table.Header scope="column">
                                            Appointment category
                                        </Table.Header>
                                        <Table.Header scope="column">
                                            Location
                                        </Table.Header>
                                        <Table.Header scope="column">
                                            Healthcare professional
                                        </Table.Header>
                                        <Table.Header scope="column">
                                            Patient NHS number
                                        </Table.Header>
                                        <Table.Header scope="column">
                                            Patient dob
                                        </Table.Header>
                                    </Table.Row>
                                </Table.Head>
                                <Table.Body>
                                    {uploadItems.map(
                                        (
                                            appointment: BulkAppointmentUploadItem,
                                            index,
                                        ) => (
                                            <Table.Row
                                                key={`${appointment.patientNhsNumber}-${index}`}
                                            >
                                                <Table.DataCell>
                                                    <StyledTruncatedTableData>
                                                        {appointment.appointmentId ===
                                                            null ||
                                                        appointment.appointmentId ===
                                                            undefined
                                                            ? " "
                                                            : appointment.appointmentId}
                                                    </StyledTruncatedTableData>
                                                </Table.DataCell>
                                                <Table.DataCell>
                                                    <StyledTruncatedTableData>
                                                        {
                                                            appointment.appointmentClinicCode
                                                        }
                                                    </StyledTruncatedTableData>
                                                </Table.DataCell>
                                                <Table.DataCell>
                                                    <StyledTruncatedTableData>
                                                        {DateHelpers.formatDate(
                                                            appointment.appointmentStartDateTime,
                                                            DateFormatOptions.DATE_TIME_SHORT_WITH_SLASH,
                                                        )}
                                                    </StyledTruncatedTableData>
                                                </Table.DataCell>
                                                <Table.DataCell>
                                                    <StyledTruncatedTableData>
                                                        {
                                                            appointment.appointmentType
                                                        }
                                                    </StyledTruncatedTableData>
                                                </Table.DataCell>
                                                <Table.DataCell>
                                                    <StyledTruncatedTableData>
                                                        {appointment.location}
                                                    </StyledTruncatedTableData>
                                                </Table.DataCell>
                                                <Table.DataCell>
                                                    <StyledTruncatedTableData>
                                                        {
                                                            appointment.practitioner
                                                        }
                                                    </StyledTruncatedTableData>
                                                </Table.DataCell>
                                                <Table.DataCell>
                                                    <StyledTruncatedTableData>
                                                        {
                                                            appointment.patientNhsNumber
                                                        }
                                                    </StyledTruncatedTableData>
                                                </Table.DataCell>
                                                <Table.DataCell>
                                                    <StyledTruncatedTableData>
                                                        {DateHelpers.formatDate(
                                                            appointment.dateOfBirth,
                                                            DateFormatOptions.DATE_SHORT_NUMERIC_WITH_HYPHENS_REVERSE,
                                                        )}
                                                    </StyledTruncatedTableData>
                                                </Table.DataCell>
                                            </Table.Row>
                                        ),
                                    )}
                                </Table.Body>
                            </Table>
                        </Ds.Accordion.Content>
                    </Ds.Accordion.Item>
                </Ds.Accordion>
            </MainContentsContainer>
            <FooterContainer>
                <Ds.Button
                    onClick={handleContinueClick}
                    loading={confirmUploadStatus === "loading"}
                >
                    Continue
                </Ds.Button>
            </FooterContainer>
        </PageContainer>
    );
};
