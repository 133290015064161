import { ButtonHTMLAttributes, useEffect, useRef, useState } from "react";

import {
    Button,
    ButtonProps,
    Card,
    Icon,
    Text,
    TextareaAutosize,
} from "@accurx/design";
import { Tooltip } from "reactstrap";

import { FlemingAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

export interface CopyTextButtonProps {
    text: string;
    analyticsContext?: FlemingAnalyticsTracker.CopyButtonAnalyticsContext;
    isButtonOnly?: boolean;
    buttonProps?: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>;
}

const CopyTextButton = ({
    text,
    analyticsContext,
    isButtonOnly = false,
    buttonProps,
}: CopyTextButtonProps) => {
    const [copySuccess, setCopySuccess] = useState(false);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const copyTimeout = useRef<undefined | number>(undefined);

    useEffect(() => {
        return (): void => {
            clearCopyTimeout();
        };
    }, []);

    const clearCopyTimeout = (): void => {
        if (copyTimeout?.current) {
            clearTimeout(copyTimeout.current);
        }
    };

    const copyToClipboard = (): void => {
        textAreaRef?.current?.select();
        document.execCommand("copy");
        setCopySuccess(true);

        clearCopyTimeout();
        copyTimeout.current = window.setTimeout(() => {
            setCopySuccess(false);
        }, 2000);

        if (analyticsContext) {
            FlemingAnalyticsTracker.trackCopyMessage({
                ...analyticsLoggedInProps,
                context: analyticsContext,
            });
        }
    };

    if (!document.queryCommandSupported("copy")) return null;

    const renderButton = (): JSX.Element => {
        return (
            <div>
                <TextareaAutosize
                    data-testid="copyButtonTextArea"
                    ref={textAreaRef}
                    aria-hidden="true"
                    value={text}
                    style={{
                        opacity: 0,
                        position: "fixed",
                        top: 0,
                        left: 0,
                        zIndex: -9999,
                        pointerEvents: "none",
                    }}
                />
                <div id="CopyButton">
                    {/* Can override any of the props above buttonProps spread, onClick is the only prop that may not be overridden */}
                    <Button
                        text="Copy"
                        theme="secondary"
                        icon={{ name: "Copy" }}
                        className="mt-2 mt-sm-0"
                        {...buttonProps}
                        onClick={copyToClipboard}
                    />
                </div>
                {copySuccess && (
                    <Tooltip
                        hideArrow
                        placement="top"
                        target="CopyButton"
                        isOpen={copySuccess}
                        trigger="click"
                    >
                        Copied
                    </Tooltip>
                )}
            </div>
        );
    };

    if (isButtonOnly) {
        return renderButton();
    }

    return (
        <Card spacing={2} props={{ className: "mb-4" }}>
            <div className="d-block d-sm-flex align-items-center justify-content-between">
                <Text
                    variant="label"
                    props={{
                        className:
                            "d-flex align-items-center my-0 mr-0 mr-sm-4",
                    }}
                    colour="night"
                >
                    <span className="d-inline-block mr-2">
                        <Icon name="Save" size={3} colour="night" />
                    </span>{" "}
                    Get a copy of the sent message to save it to the record
                </Text>
                {renderButton()}
            </div>
        </Card>
    );
};

export default CopyTextButton;
