import { generatePath } from "react-router";

import { ROUTES, ROUTES_CHAIN, ROUTES_WORKSPACE } from "shared/Routes";

export const PATHS = {
    acceptableUse: "/acceptable-use-policy",
    nameAndQuestions: "/name-and-questions/",
    nameAndQuestionsWithId: "/name-and-questions/:questionnaireId",
    invitationMessage: "/invitation-message/:questionnaireId",
    confirmationMessage: "/confirmation-message/:questionnaireId",
    assignAndSave: "/assign-and-save/:questionnaireId",
    questionnaireShare: "/questionnaires/share/:shareId",
    questionnaireSwitchingOrg: "/questionnaires/import/:shareId/switching-org",
    import: "/import/:shareId",
    exportJson: "/export/json/:questionnaireId",
    importJson: "/import/json",
} as const;

export const routeHome = (orgId: string) =>
    ROUTES_CHAIN.practicesFloreyBuilderBase.replace(":orgId", orgId);

export const routeNameAndQuestionsBase = (orgId: string): string =>
    ROUTES_CHAIN.practicesFloreyBuilderBase.replace(":orgId", orgId) +
    PATHS.nameAndQuestions;

export const routeDesktopAcceptableUsePolicy = (orgId: string): string =>
    ROUTES_CHAIN.practicesFloreyBuilderBase.replace(":orgId", orgId || "") +
    PATHS.acceptableUse;

export const routeNameAndQuestions = (
    orgId: string,
    questionnaireId: string,
): string =>
    ROUTES_CHAIN.practicesFloreyBuilderBase.replace(":orgId", orgId) +
    PATHS.nameAndQuestionsWithId.replace(":questionnaireId", questionnaireId);

export const routeInvitationMessage = (
    orgId: string,
    questionnaireId: string,
): string =>
    ROUTES_CHAIN.practicesFloreyBuilderBase.replace(":orgId", orgId) +
    PATHS.invitationMessage.replace(":questionnaireId", questionnaireId);

export const routeConfirmationMessage = (
    orgId: string,
    questionnaireId: string,
): string =>
    ROUTES_CHAIN.practicesFloreyBuilderBase.replace(":orgId", orgId) +
    PATHS.confirmationMessage.replace(":questionnaireId", questionnaireId);

export const routeAssignAndSave = (
    orgId: string,
    questionnaireId: string,
): string =>
    ROUTES_CHAIN.practicesFloreyBuilderBase.replace(":orgId", orgId) +
    PATHS.assignAndSave.replace(":questionnaireId", questionnaireId);

export const routeSummary = (orgId: string): string =>
    ROUTES_CHAIN.practicesFloreyBuilderBase.replace(":orgId", orgId);

export const routeShare = (orgId: string, shareId: string): string =>
    ROUTES_CHAIN.practicesFloreyBuilderBase.replace(":orgId", orgId) +
    PATHS.questionnaireShare.replace(":shareId", shareId);

export const routeSwitchingOrg = ({
    orgId,
    shareId,
    switchingOrgId,
}: {
    orgId: string;
    shareId: string;
    switchingOrgId: string;
}): string =>
    generatePath(
        ROUTES_CHAIN.practicesFloreyBuilderBase +
            PATHS.questionnaireSwitchingOrg +
            "?switchingOrgId=:switchingOrgId",
        { orgId, shareId, switchingOrgId },
    );

export const importToOrg = (orgId: string, shareId: string): string =>
    ROUTES_CHAIN.practicesFloreyBuilderBase.replace(":orgId", orgId) +
    PATHS.import.replace(":shareId", shareId);

export const routeImport = (shareId: string): string =>
    ROUTES.importChooseOrg.replace(":shareId", shareId);

export const routeExportAsJson = (
    orgId: string,
    questionnaireId: string,
): string =>
    ROUTES_CHAIN.practicesFloreyBuilderBase.replace(":orgId", orgId) +
    PATHS.exportJson.replace(":questionnaireId", questionnaireId);

export const routeWebHome = (orgId: string) =>
    generatePath(ROUTES_WORKSPACE.workspaceQuestionnaireBuilder, {
        workspaceId: orgId,
    });

export const routeWebNameAndQuestionsBase = (orgId: string) =>
    generatePath(
        ROUTES_WORKSPACE.workspaceQuestionnaireBuilder + PATHS.nameAndQuestions,
        {
            workspaceId: orgId,
        },
    );

export const routeWebAcceptableUsePolicy = (orgId: number): string =>
    generatePath(
        ROUTES_WORKSPACE.workspaceQuestionnaireBuilder + PATHS.acceptableUse,
        {
            workspaceId: orgId || "",
        },
    );

export const routeWebNameAndQuestions = (
    orgId: string,
    questionnaireId: string,
    isImported = false,
): string => {
    let path = generatePath(
        ROUTES_WORKSPACE.workspaceQuestionnaireBuilder +
            PATHS.nameAndQuestionsWithId,
        {
            workspaceId: orgId,
            questionnaireId,
        },
    );

    if (isImported) {
        path += "?imported=true";
    }

    return path;
};

export const routeWebInvitationMessage = (
    orgId: string,
    questionnaireId: string,
): string =>
    generatePath(
        ROUTES_WORKSPACE.workspaceQuestionnaireBuilder +
            PATHS.invitationMessage,
        {
            workspaceId: orgId,
            questionnaireId,
        },
    );

export const routeWebConfirmationMessage = (
    orgId: string,
    questionnaireId: string,
): string =>
    generatePath(
        ROUTES_WORKSPACE.workspaceQuestionnaireBuilder +
            PATHS.confirmationMessage,
        {
            workspaceId: orgId,
            questionnaireId,
        },
    );

export const routeWebAssignAndSave = (
    orgId: string,
    questionnaireId: string,
): string =>
    generatePath(
        ROUTES_WORKSPACE.workspaceQuestionnaireBuilder + PATHS.assignAndSave,
        {
            workspaceId: orgId,
            questionnaireId,
        },
    );

export const routeWebSummary = (orgId: string): string =>
    generatePath(ROUTES_WORKSPACE.workspaceQuestionnaireBuilder, {
        workspaceId: orgId,
    });

export const routeWebShare = (orgId: string, shareId: string): string =>
    generatePath(
        ROUTES_WORKSPACE.workspaceQuestionnaireBuilder +
            PATHS.questionnaireShare,
        {
            workspaceId: orgId,
            shareId,
        },
    );

export const routeWebSwitchingOrg = ({
    orgId,
    shareId,
    switchingOrgId,
}: {
    orgId: string;
    shareId: string;
    switchingOrgId: string;
}): string =>
    generatePath(
        ROUTES_WORKSPACE.workspaceQuestionnaireBuilder +
            PATHS.questionnaireSwitchingOrg +
            "?switchingOrgId=:switchingOrgId",
        { workspaceId: orgId, shareId, switchingOrgId },
    );

export const importWebToOrg = (orgId: string, shareId: string): string =>
    generatePath(
        ROUTES_WORKSPACE.workspaceQuestionnaireBuilder + PATHS.import,
        {
            workspaceId: orgId,
            shareId,
        },
    );

export const routeWebImport = (shareId: string): string =>
    generatePath(ROUTES.importChooseOrg, { shareId });

export const routeWebExportAsJson = (
    orgId: string,
    questionnaireId: string,
): string =>
    generatePath(
        ROUTES_WORKSPACE.workspaceQuestionnaireBuilder + PATHS.exportJson,
        {
            workspaceId: orgId,
            questionnaireId,
        },
    );
