import { useCallback, useState } from "react";

export type FullScreenToggle = {
    toggle: () => void;
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
    isBordered: boolean;
    setIsBordered: (bordered: boolean) => void;
};

type UseFullScreenToggleProps = { isOpen?: boolean; isBordered?: boolean };

export const useFullScreenToggle = (
    props: UseFullScreenToggleProps = {},
): FullScreenToggle => {
    const [isOpen, setIsOpen] = useState(!!props.isOpen);
    const [isBordered, setIsBordered] = useState(!!props.isBordered);

    const toggle = useCallback(() => {
        setIsOpen((isOpen) => !isOpen);
    }, []);

    return {
        toggle,
        isOpen,
        setIsOpen,
        isBordered,
        setIsBordered,
    };
};
