import React from "react";

import { BatchMessageItemSummary } from "@accurx/api/patient-messaging";
import { FeatureName } from "@accurx/auth";
import { Card, Icon, Text } from "@accurx/design";
import { Log } from "@accurx/shared";
import { shallowEqual } from "react-redux";

import { BatchType } from "app/batchMessage/gp/BatchMessage.types";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

import {
    FullWidthOnMobile,
    GrowOnMobile,
    HeadingPadding,
    StyledFlexRow,
    StyledTableData,
    StyledTableHeader,
    StyledTableRow,
    StyledText,
    StyledWrapper,
} from "./BatchMessageReview.styles";
import { formatSendDate } from "./BatchMessageReview.utils";
import { BatchSnomedCode } from "./BatchMessageReviewSnomed";

type BatchMessageReviewTableRowProps = {
    heading: string | JSX.Element;
    hasBorderTop?: boolean;
    children: React.ReactNode;
};

const BatchMessageReviewTableRow = ({
    heading,
    hasBorderTop,
    children,
}: BatchMessageReviewTableRowProps): JSX.Element => (
    <StyledTableRow $topBorder={hasBorderTop}>
        <StyledTableHeader>
            <Text as="span" variant="label">
                {heading}
            </Text>
        </StyledTableHeader>
        <StyledTableData>{children}</StyledTableData>
    </StyledTableRow>
);

export const BatchMessageReviewTable = () => {
    // Feature flags
    const waitingListValidationEnabled = useIsFeatureEnabled(
        FeatureName.WebWaitingListValidation,
    );

    const {
        patientMessage,
        selectedSlotType,
        uploadedFileName,
        sendAt,
        staggerOverDays,
        selectedFloreyName,
        selectedBeastSeries,
        uploadedFileLink,
        declineSettings,
    } = useAppSelector(({ batchMessage }) => batchMessage, shallowEqual);

    const isDeclineSettingsAvailable = Boolean(
        declineSettings?.confirmationText && declineSettings?.snomedConceptId,
    );

    const { batchType, snomedCodes: smsSnomedCodes } = useAppSelector(
        ({ batchMessage }) => batchMessage,
        shallowEqual,
    );

    const shouldSaveBatchToRecord = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedBatchOptionSaveToRecord,
    );

    const patientList = useAppSelector(
        ({ batchMessage }) => batchMessage.batchMessageDetails.items,
        shallowEqual,
    );
    const selectedAssigneeUserGroupName = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedAssigneeUserGroupName,
    );

    const getSelectedAssigneeUserGroupName = (): string | null => {
        if (selectedAssigneeUserGroupName !== "") {
            return selectedAssigneeUserGroupName;
        }
        return null;
    };

    const calculateMessagesPerDay = () => {
        if (staggerOverDays) {
            return Math.floor(numOfTextablePatients / staggerOverDays);
        } else {
            Log.error(
                "Error calculating number of patients a days to stagger send",
                {
                    tags: {
                        staggerOverDays: staggerOverDays,
                        totalListItems: patientList.length,
                    },
                },
            );
        }
    };

    const numOfTextablePatients = patientList.filter(
        (item: BatchMessageItemSummary) => item.isTextable,
    ).length;

    const selectedGroup = getSelectedAssigneeUserGroupName();

    const declineSnomedTerm = smsSnomedCodes.find(
        (code) => code.conceptId === declineSettings?.snomedConceptId,
    )?.term;

    const conditionalHeadingsCount = [
        selectedSlotType?.type,
        sendAt,
        staggerOverDays,
    ].filter(Boolean).length;

    const applyGridLayout = conditionalHeadingsCount >= 2;
    const applyTwoHeadingsLayout = conditionalHeadingsCount === 0;

    const layoutClass = applyGridLayout
        ? "grid-layout"
        : applyTwoHeadingsLayout
        ? "two-headings-layout"
        : "";

    return (
        <Card spacing={0}>
            <StyledWrapper>
                <div data-testid={"message-details"}>
                    <HeadingPadding>
                        <StyledFlexRow className={layoutClass}>
                            <GrowOnMobile>
                                <BatchMessageReviewTableRow heading="Batch type">
                                    <Text as="span">
                                        {batchType}
                                        {batchType === BatchType.FLOREY && (
                                            <> - {selectedFloreyName}</>
                                        )}
                                    </Text>
                                </BatchMessageReviewTableRow>
                            </GrowOnMobile>
                            <GrowOnMobile>
                                <BatchMessageReviewTableRow heading="Sending to">
                                    <Text as="span">
                                        {numOfTextablePatients} patients
                                    </Text>
                                </BatchMessageReviewTableRow>
                            </GrowOnMobile>
                            {selectedSlotType?.type && (
                                <FullWidthOnMobile>
                                    <BatchMessageReviewTableRow heading="Slot type">
                                        <Text as="span">
                                            {selectedSlotType?.type}
                                        </Text>
                                    </BatchMessageReviewTableRow>
                                </FullWidthOnMobile>
                            )}
                            {sendAt && (
                                <BatchMessageReviewTableRow heading="Send time">
                                    {formatSendDate(sendAt)}
                                </BatchMessageReviewTableRow>
                            )}

                            {staggerOverDays && (
                                <BatchMessageReviewTableRow heading="Stagger over">
                                    <>
                                        {staggerOverDays} days
                                        <Text
                                            variant="body"
                                            colour="metal"
                                            skinny
                                        >
                                            (about {calculateMessagesPerDay()}{" "}
                                            patients a day)
                                        </Text>
                                    </>
                                </BatchMessageReviewTableRow>
                            )}
                        </StyledFlexRow>
                    </HeadingPadding>
                </div>

                <BatchMessageReviewTableRow
                    heading={
                        batchType === BatchType.MESSAGE
                            ? "Message"
                            : "Invite message"
                    }
                >
                    <Text as="span">{patientMessage}</Text>
                </BatchMessageReviewTableRow>

                {selectedGroup && (
                    <BatchMessageReviewTableRow heading="Assign responses to">
                        <Text as="span">{selectedGroup}</Text>
                    </BatchMessageReviewTableRow>
                )}

                {!selectedBeastSeries &&
                    waitingListValidationEnabled === false && (
                        <BatchMessageReviewTableRow
                            heading={
                                <>
                                    Invite SNOMED code
                                    {batchType !== BatchType.FLOREY}
                                </>
                            }
                        >
                            <BatchSnomedCode />
                        </BatchMessageReviewTableRow>
                    )}
                {!selectedBeastSeries && (
                    <BatchMessageReviewTableRow heading="Save to record">
                        <Text as="span">
                            {shouldSaveBatchToRecord ? "On" : "Off"}
                        </Text>
                    </BatchMessageReviewTableRow>
                )}
                {!!uploadedFileName && !!uploadedFileLink && (
                    <BatchMessageReviewTableRow
                        heading={
                            <>
                                <Icon name="Paperclip" size={3} theme="Line" />
                                Attachment
                            </>
                        }
                    >
                        <Text as="span" variant="body">
                            <Text
                                skinny
                                as="a"
                                props={{
                                    "data-testid": "batch-info-url",
                                    target: "_blank",
                                    rel: "noreferrer noopener",
                                    href: uploadedFileLink,
                                    style: {
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                        wordWrap: "anywhere",
                                    },
                                }}
                            >
                                {uploadedFileName}
                            </Text>
                        </Text>
                    </BatchMessageReviewTableRow>
                )}
                {batchType === BatchType.SELFBOOK && (
                    <>
                        <BatchMessageReviewTableRow
                            hasBorderTop
                            heading="Decline"
                        >
                            {isDeclineSettingsAvailable ? (
                                <Text as="span">Allowed</Text>
                            ) : (
                                <StyledText>
                                    <Text as="span">Not allowed</Text>
                                </StyledText>
                            )}
                        </BatchMessageReviewTableRow>

                        {isDeclineSettingsAvailable && (
                            <>
                                <BatchMessageReviewTableRow heading="Decline message">
                                    <Text as="span">
                                        {declineSettings?.confirmationText}
                                    </Text>
                                </BatchMessageReviewTableRow>
                                <BatchMessageReviewTableRow heading="Decline SNOMED code">
                                    <StyledText>
                                        <Text as="span">
                                            {declineSnomedTerm}
                                        </Text>
                                    </StyledText>
                                    <Text colour={"metal"}>
                                        (This will be saved to record if the
                                        appointment invite is declined)
                                    </Text>
                                </BatchMessageReviewTableRow>
                            </>
                        )}
                    </>
                )}
            </StyledWrapper>
        </Card>
    );
};
