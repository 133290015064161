import { ChangeEvent, MouseEvent, useState } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import {
    Button,
    Flex,
    FormFieldFeedback,
    FormFieldWrapper,
    Icon,
    Link,
} from "@accurx/design";
import { validateFile } from "@accurx/design/dist/components/Attachment/Attachment.hooks";
import { Log } from "@accurx/shared";

import { documentUpload } from "api/PatientMessagingServer/PatientMessagingServerApi";
import { StyledTextareaAutosize } from "app/sharedComponents/textAreaAutosize/TextAreaAutosize.styles";

import { useFloreyBuilder } from "../../FloreyBuilder.context";
import { Actions } from "../../constants";
import { ClientSideQuestion, ContextProps } from "../../types/context.types";
import {
    BranchPosition,
    ErrorIds,
    Errors,
    QuestionLevel,
} from "../../types/data.types";
import { StyledThumbnailIcon } from "./QuestionImageUploader.styles";

const ALLOWED_IMAGE_EXTENSIONS = ["jpeg", "jpg", "png", "tiff", "tiff2", "tif"];

export const ALLOWED_IMAGE_SIZE = { size: 3145728, humanReadable: "3MB" };
export const ALLOWED_NUMBER_OF_IMAGES = 1;

type QuestionImageUploaderProps = {
    question: ClientSideQuestion;
    questionIndex: number;
    errors: Errors;
    questionLevel: QuestionLevel;
    branchPosition?: BranchPosition;
};
export const QuestionImageUploader = ({
    question,
    questionIndex,
    questionLevel,
    branchPosition,
}: QuestionImageUploaderProps): JSX.Element => {
    const { dispatch } = useFloreyBuilder() as ContextProps;
    const { orgId } = useCurrentWorkspace();

    const [fileUploadSuccessful, setFileUploadSuccessful] =
        useState<boolean>(false);
    const [fileUploadErrorMessage, setFileUploadErrorMessage] =
        useState<string>("");
    const handleRemoveImage = () => {
        dispatch({
            type: Actions.UPDATE_QUESTION_FIELD,
            payload: {
                questionIndex,
                fieldKey: "imageDocumentUrl",
                fieldValue: null,
                questionLevel,
                branchPosition,
            },
        });
        setFileUploadSuccessful(false);
    };
    const onAddImage = async (
        e: ChangeEvent<HTMLInputElement>,
    ): Promise<void> => {
        e.preventDefault();
        e.stopPropagation();
        setFileUploadSuccessful(false);
        if (e.target.files === null) return;

        // Only allow uploading a single file at once
        const file = e.target.files[0];
        const { isValid, errors } = validateFile({
            file,
            allowedFileExtensions: ALLOWED_IMAGE_EXTENSIONS,
            maxAllowedFileSize: ALLOWED_IMAGE_SIZE,
        });
        setFileUploadErrorMessage(errors.join(" "));

        // when there are clientside errors, do not upload file
        if (!isValid) {
            return;
        }

        try {
            const { result, error } = await documentUpload({
                file,
                organisationId: orgId,
                origin: "FloreyCreateEditQuestion",
            });
            if (error) {
                // handle serverside error
                Log.warn(
                    "[FloreyBuilder]: Unable to upload user file in question creation",
                    {
                        originalException: error,
                        tags: {
                            logger: "FloreyBuilder",
                            fileType: file.type,
                        },
                    },
                );
                setFileUploadErrorMessage("Unable to upload file");
            }
            if (result?.id && result.link) {
                // if uploading file is successful, add to question
                dispatch({
                    type: Actions.UPDATE_QUESTION_FIELD,
                    payload: {
                        questionIndex,
                        fieldKey: "imageDocumentUrl",
                        fieldValue: result.link,
                        questionLevel,
                        branchPosition,
                    },
                });
                setFileUploadSuccessful(true);
            }
        } catch (error) {
            // if not successful, show error & log
            setFileUploadErrorMessage("Unable to upload file");
            Log.warn(
                "[FloreyBuilder]: Unable to upload user file in question creation",
                {
                    originalException: error,
                    tags: { logger: "FloreyBuilder", fileType: file.type },
                },
            );
        }
    };
    const [imageDescription, setImageDescription] = useState(
        question.imageAltText ?? "",
    );

    const hasImageAttachment = !!question.imageDocumentUrl;
    return (
        <>
            <Flex flexDirection="column" alignItems="flex-start" gap="2">
                {!hasImageAttachment && (
                    <>
                        <Button
                            type="button"
                            icon={{ name: "Paperclip" }}
                            text="Add image"
                            theme="secondary"
                            onClick={() => {
                                document.getElementById("fileUpload")?.click();
                            }}
                            disabled={false}
                        />
                        <input
                            id="fileUpload"
                            type="file"
                            accept={`.${ALLOWED_IMAGE_EXTENSIONS.join(",.")}`}
                            style={{ display: "none" }}
                            // Need to reset this on click in case we want to upload the same file twice
                            onClick={(event: MouseEvent<HTMLInputElement>) => {
                                event.currentTarget.value = "";
                            }}
                            onChange={onAddImage}
                            data-testid="fileUpload"
                        />
                    </>
                )}
                {fileUploadSuccessful && (
                    <FormFieldFeedback
                        variant="success"
                        text="Successfully uploaded image"
                    />
                )}
                {fileUploadErrorMessage && (
                    <FormFieldFeedback
                        variant="error"
                        text={fileUploadErrorMessage}
                    />
                )}
                {hasImageAttachment && (
                    <QuestionImagePreview question={question}>
                        <Button
                            theme="secondary"
                            onClick={handleRemoveImage}
                            icon={{
                                name: "Cross",
                                colour: "red",
                                title: "Remove image",
                            }}
                        />
                    </QuestionImagePreview>
                )}
            </Flex>
            {question.imageDocumentUrl && (
                <FormFieldWrapper
                    label="Descriptive text for image"
                    labelProps={{
                        htmlFor: ErrorIds.questionImageDescriptionError,
                    }}
                >
                    <StyledTextareaAutosize
                        id={ErrorIds.questionImageDescriptionError}
                        disabled={false}
                        value={imageDescription}
                        onChange={(e) => {
                            setImageDescription(e.currentTarget.value);
                            dispatch({
                                type: Actions.UPDATE_QUESTION_FIELD,
                                payload: {
                                    questionIndex,
                                    fieldKey: "imageAltText",
                                    fieldValue: e.currentTarget.value,
                                    questionLevel,
                                    branchPosition,
                                },
                            });
                        }}
                    />
                </FormFieldWrapper>
            )}
        </>
    );
};

export const QuestionImagePreview = ({
    question,
    children,
}: {
    question: ClientSideQuestion;
    children?: React.ReactNode;
}) => {
    if (!question.imageDocumentUrl) {
        return null;
    }
    return (
        <Flex flexDirection="row" gap="1" alignItems="center">
            <StyledThumbnailIcon>
                <Icon name="Photo" />
            </StyledThumbnailIcon>
            <Link href={question.imageDocumentUrl} openInNewTab={true}>
                Preview of uploaded image
                <Link.Icon />
            </Link>
            {children}
        </Flex>
    );
};
