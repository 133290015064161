import { useCurrentUser, useFeatureFlag } from "@accurx/auth";
import { useUserClinicianConversationGroupQuery } from "@accurx/concierge/hooks/queries/useUserClinicianConversationGroupQuery";
import { getMostRecentItem } from "@accurx/concierge/util/getMostRecentItem";
import * as UI from "@accurx/design";
import {
    ConversationFeed,
    ConversationFeedOptionsWrapper,
    ConversationListHeader,
    ConversationTabItem,
    ConversationTabs,
} from "domains/inbox/components/ConversationList/components";
import { ClinicianConversationPreview } from "domains/inbox/components/ConversationPreview/ConversationPreview";
import { EXTENDED_CONVERSATION_PREVIEW_HEIGHT } from "domains/inbox/components/ConversationPreview/ConversationPreview.styles";
import {
    useInboxLink,
    useInboxParams,
    useInboxTitle,
} from "domains/inbox/hooks/util";
import { useConversationListAnalytics } from "domains/inbox/hooks/util/useConversationListAnalytics";
import { userflowIds } from "domains/inbox/util";

import { ConversationSortOrder } from "../../components/ConversationSortOrder/ConversationSortOrder";
import { getEmptyStateVariant } from "../../components/EmptyState/EmptyState";
import {
    StyledAccessibleHeader,
    StyledConversationFeedContainer,
    StyledConversationList,
} from "../styles";

export const ClinicianMyInbox = () => {
    const { status, sort } = useInboxParams(["status", "sort"]);
    const link = useInboxLink();
    const { user } = useCurrentUser();
    const groupQuery = useUserClinicianConversationGroupQuery({
        userId: user.accuRxUserId,
        status,
        sort,
    });

    const { trackTabClick } = useConversationListAnalytics();

    useInboxTitle(
        `My ${status.toLowerCase()} conversations with healthcare providers`,
    );
    const emptyStateVariant = getEmptyStateVariant(status);
    const canUseSorting = useFeatureFlag("UnifiedInboxSortingBuild");

    return (
        <>
            <StyledConversationList rows="auto 1fr">
                <UI.Cell>
                    <ConversationListHeader
                        heading={
                            <>
                                My inbox
                                <StyledAccessibleHeader>
                                    showing {status.toLowerCase()} messages
                                </StyledAccessibleHeader>
                            </>
                        }
                    />
                    <ConversationTabs>
                        <ConversationTabItem
                            active={status === "Open"}
                            link={link.update({
                                status: "Open",
                                sort: null,
                                conversationId: null,
                            })}
                            userflowId={userflowIds.conversationList.openTab}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Done",
                                    newTab: "Open",
                                    conversationParticipant: "WithHcp",
                                })
                            }
                        >
                            Open
                        </ConversationTabItem>

                        <ConversationTabItem
                            active={status === "Done"}
                            link={link.update({
                                status: "Done",
                                sort: null,
                                conversationId: null,
                            })}
                            userflowId={userflowIds.conversationList.doneTab}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Open",
                                    newTab: "Done",
                                    conversationParticipant: "WithHcp",
                                })
                            }
                        >
                            Done
                        </ConversationTabItem>
                    </ConversationTabs>
                </UI.Cell>

                <StyledConversationFeedContainer>
                    {canUseSorting && (
                        <ConversationFeedOptionsWrapper>
                            <UI.Ds.Flex.Item>
                                <ConversationSortOrder />
                            </UI.Ds.Flex.Item>
                        </ConversationFeedOptionsWrapper>
                    )}
                    <ConversationFeed
                        key={groupQuery.data?.id}
                        isLoading={groupQuery.status === "loading"}
                        isError={groupQuery.status === "error"}
                        refetch={groupQuery.refetch}
                        fetchMore={groupQuery.fetchMore}
                        isFetchingMore={groupQuery.isFetchingMore}
                        isFullyLoaded={groupQuery.data?.isFullyLoaded}
                        members={groupQuery.data?.members}
                        filters={groupQuery.data?.filters}
                        emptyStateVariant={emptyStateVariant}
                        renderItem={(conversation, state) => (
                            <ClinicianConversationPreview
                                conversation={conversation}
                                item={getMostRecentItem(conversation)}
                                state={state}
                            />
                        )}
                        itemHeight={EXTENDED_CONVERSATION_PREVIEW_HEIGHT}
                    />
                </StyledConversationFeedContainer>
            </StyledConversationList>
        </>
    );
};
