import {
    FeatureName,
    Organisation,
    isFeatureEnabledForWorkspace,
} from "@accurx/auth";
import { PopoverItem } from "@accurx/design";
import {
    isArchivedWorkspace,
    isMyWorkspace,
    supportsMultipleWorkspaces,
} from "@accurx/workspace-management";

import {
    StyledArchivedBadge,
    StyledText,
} from "./SelectOrganisationPopover.styles";

export type WorkspacePopoverItemProps = {
    workspace: Organisation;
    isCurrentWorkspace: boolean;
    defaultOrgName: string;
    onSelectWorkspace: (organisation: Organisation) => void;
};

export const WorkspacePopoverItem = ({
    workspace,
    isCurrentWorkspace,
    defaultOrgName,
    onSelectWorkspace,
}: WorkspacePopoverItemProps) => {
    const isFlexibleWorkspaceEnabled = supportsMultipleWorkspaces(workspace);

    const isNhsPharmacy = workspace?.nationalCode === "X31";

    const organisationName = isMyWorkspace(workspace)
        ? "My Workspace"
        : workspace.organisationName;

    const showArchivedBadge =
        isNhsPharmacy ||
        (isMyWorkspace(workspace) &&
            isFeatureEnabledForWorkspace(
                FeatureName.DissuadeFromUsingMyWorkspace,
                workspace,
            )) ||
        isArchivedWorkspace(workspace);

    return (
        <PopoverItem
            onClick={() => onSelectWorkspace(workspace)}
            active={isCurrentWorkspace}
            data-testid={`${isCurrentWorkspace ? "active" : "inactive"}-${
                workspace.orgId
            }`}
        >
            <div>
                <StyledText
                    variant={isCurrentWorkspace ? "label" : "body"}
                    colour="night"
                >
                    {organisationName}
                </StyledText>
                {isFlexibleWorkspaceEnabled && (
                    <StyledText
                        variant={isCurrentWorkspace ? "note" : "preview"}
                        colour="night"
                    >
                        {defaultOrgName}
                    </StyledText>
                )}
                {showArchivedBadge && (
                    <StyledArchivedBadge>Archived</StyledArchivedBadge>
                )}
            </div>
        </PopoverItem>
    );
};
