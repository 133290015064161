import * as UI from "@accurx/design";
import { ConsentCode } from "@accurx/native";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";

type ConsentCodeDisplayProps = { consentCode: ConsentCode };

const getConsentCodeDisplayTerm = ({
    contentType,
    hasConsented,
}: {
    contentType: ConsentCode["communicationContentType"];
    hasConsented: boolean;
}) => {
    switch (contentType) {
        case "All":
            return hasConsented ? "Consent given" : "Declined consent";
        case "Clinical":
            return hasConsented
                ? "Consent given to receive clinical information"
                : "Declined consent to receive clinical information";
        case "NonClinical":
            return hasConsented
                ? "Consent given to receive non-clinical information"
                : "Declined consent to receive non-clinical information";
        case "TestResults":
            return hasConsented
                ? "Consent given to receive test results"
                : "Declined consent to receive test results";
    }
};

export const ConsentCodeDisplay = ({
    consentCode,
}: ConsentCodeDisplayProps) => {
    const dateDisplay = DateHelpers.formatDate(
        consentCode.date,
        DateFormatOptions.DATE_SHORT_WITH_SPACES,
    );

    const displayTerm = getConsentCodeDisplayTerm({
        contentType: consentCode.communicationContentType,
        hasConsented: consentCode.hasConsented,
    });

    return (
        <UI.Ds.Text size="small">
            {displayTerm} ({dateDisplay})
        </UI.Ds.Text>
    );
};
