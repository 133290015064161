import { Ds, Text, Tokens } from "@accurx/design";
import { SIDENAV_WIDTH } from "@accurx/navigation";
import { PADDING_SIZE as FEED_PADDING_SIZE } from "domains/inbox/components/ConversationList/components/ConversationFeed/ConversationFeed.styles";
import styled from "styled-components";

const PREVIEW_PADDING_SIZE = Tokens.SIZES[3];

export const StyledTriggerButton = styled(Ds.Button)`
    // Reduce the size of the button due to limited space
    width: 26px;
    height: 26px;

    // Increase the button's clickable area
    &:before {
        content: "";
        width: 32px;
        height: 32px;
        position: absolute;
        top: -4px;
        left: -4px;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: ${Tokens.COLOURS.primary.blue[5]};
    }

    &:hover {
        box-shadow: 0px 0px 0px 1px ${Tokens.COLOURS.primary.blue[100]};
    }

    &:focus,
    &:focus:not(:focus-visible),
    &:active {
        box-shadow: 0px 0px 0px 1px ${Tokens.COLOURS.greyscale.zinc};

        outline-style: solid;
        outline-width: 3px;
        outline-color: ${Tokens.COLOURS.primary.blue[50]};
    }
`;

export const StyledTooltipContent = styled(Text)`
    word-break: break-all;
    overflow-wrap: break-word;
    display: inline-block;

    /* A hack to match the widths of overflowing tooltips to the conversation preview, plus a gutter */
    max-width: calc(
        var(--radix-popper-available-width) - ${FEED_PADDING_SIZE}px -
            ${PREVIEW_PADDING_SIZE}
    );

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        max-width: calc(
            var(--radix-popper-available-width) - ${SIDENAV_WIDTH} -
                ${FEED_PADDING_SIZE}px - ${PREVIEW_PADDING_SIZE}
        );
    }
`;
