import React from "react";

import {
    Button,
    Card,
    Feedback,
    Text,
    TextareaAutosize,
    Tokens,
} from "@accurx/design";

import { UpdatingStatusType } from "shared/LoadingStatus";

import { Settings } from "../../constants";
import { PatientTriageStatus } from "../../types";

export type ChangeSubmissionSmsFormPropsType = {
    status: PatientTriageStatus;
    triageSettingsUpdatingStatus: UpdatingStatusType;
    submissionPageSms: string;
    handleUpdateCustomCopy: (
        event:
            | React.MouseEvent<HTMLButtonElement>
            | React.FormEvent<HTMLFormElement>,
        name: string,
        resetToDefault: boolean | null,
    ) => void;
    handleUpdateString: (
        event: React.ChangeEvent<HTMLTextAreaElement>,
        name: string,
    ) => void;
    hasClickedEditButton: (
        event: React.MouseEvent<HTMLButtonElement>,
        name: string,
    ) => void;
    hasClickedEditSubmissionSms: boolean;
    handleSubmissionSmsMessageCancel: () => void;
    practiceName: string;
};

export const ChangeSubmissionSmsForm = ({
    status,
    triageSettingsUpdatingStatus,
    submissionPageSms,
    hasClickedEditSubmissionSms,
    practiceName,
    hasClickedEditButton,
    handleUpdateCustomCopy,
    handleSubmissionSmsMessageCancel,
    handleUpdateString,
}: ChangeSubmissionSmsFormPropsType): JSX.Element => {
    const isDisabled =
        status.isAdmin === false || triageSettingsUpdatingStatus.updating;

    const messageStarterWithProxy = `A request has been made for <Patient Name> at ${practiceName}. Please contact the practice if you weren't expecting this.`;
    return (
        <form
            onSubmit={(e): void =>
                handleUpdateCustomCopy(e, Settings.TEXT_SUBMISSION_SMS, false)
            }
        >
            <Card
                props={{ className: "mb-3" }}
                header={
                    <Text as="h3" skinny variant="subtitle">
                        Edit SMS confirmation message
                    </Text>
                }
                footer={
                    <div className="d-flex justify-content-end">
                        {hasClickedEditSubmissionSms ? (
                            <>
                                <Button
                                    dimension="medium"
                                    theme="primary"
                                    data-testid="submissionSmsSave"
                                    disabled={isDisabled}
                                    type="submit"
                                    text="Save changes"
                                />
                                <Button
                                    className="ml-3"
                                    dimension="medium"
                                    theme="secondary"
                                    onClick={(e): void =>
                                        handleUpdateCustomCopy(
                                            e,
                                            Settings.TEXT_SUBMISSION_SMS,
                                            true,
                                        )
                                    }
                                    data-testid="submissionSmsReset"
                                    disabled={isDisabled}
                                    text="Reset to default"
                                />
                                <Button
                                    className="ml-3"
                                    dimension="medium"
                                    theme="transparent"
                                    onClick={handleSubmissionSmsMessageCancel}
                                    data-testid="submissionMessageCancel"
                                    text="Cancel"
                                    type="button"
                                />
                            </>
                        ) : (
                            <Button
                                dimension="medium"
                                theme="primary"
                                icon={{ name: "Pencil" }}
                                onClick={(e): void =>
                                    hasClickedEditButton(
                                        e,
                                        Settings.TEXT_SUBMISSION_SMS,
                                    )
                                }
                                disabled={isDisabled}
                                data-testid="submissionSmsEdit"
                                text="Edit"
                                type="button"
                            />
                        )}
                    </div>
                }
            >
                <>
                    <div>
                        <Text>
                            This is the SMS message patients receive when a
                            proxy has submitted a request on their behalf
                        </Text>

                        <div className="mb-3">
                            <Feedback
                                colour="secondary"
                                title="When editing the message, remember to:"
                                content={
                                    <ul className="pl-3 mb-0">
                                        <li>
                                            acknowledge that you have received
                                            the request
                                        </li>
                                        <li>
                                            specify within how long you will
                                            respond to the request
                                        </li>
                                        <li>
                                            specify what to do if the patients
                                            have not heard from you within the
                                            expected timeframe
                                        </li>
                                        <li>
                                            remind the patients to keep their
                                            phone to hand for your response
                                        </li>
                                    </ul>
                                }
                            />
                        </div>

                        <div
                            className="p-2"
                            style={{
                                backgroundColor: Tokens.COLOURS.greyscale.cloud,
                            }}
                        >
                            <Text variant="label" props={{ className: "mb-2" }}>
                                Your current message:
                            </Text>

                            <div>
                                <Text>{messageStarterWithProxy}</Text>

                                {hasClickedEditSubmissionSms ? (
                                    <TextareaAutosize
                                        cols={80}
                                        name="submissionPageSms"
                                        id="submissionPageSms"
                                        data-testid="submissionPageSmsCopy"
                                        disabled={isDisabled}
                                        onChange={(e): void =>
                                            handleUpdateString(
                                                e,
                                                Settings.TEXT_SUBMISSION_SMS,
                                            )
                                        }
                                        value={submissionPageSms}
                                    />
                                ) : (
                                    <Text>{submissionPageSms}</Text>
                                )}

                                <Text skinny>Thanks, {practiceName}</Text>
                            </div>
                        </div>
                    </div>
                </>
            </Card>
        </form>
    );
};
