import { useContext } from "react";

import { RealtimeContext } from "../components/RealtimeContext";

/**
 * @deprecated Please migrate away from using HubClient directly and use `useRealtimeForSignalR` if you can.
 *
 * We need to provide this in case access to HubClient is needed
 * when there is no current user or workspace ID.
 * */
export const useRealtimeContext = () => {
    const { hubClient, connectionState } = useContext(RealtimeContext);

    return { hubClient, connectionState };
};
