import { useFeatureFlag } from "@accurx/auth";
import { useTeam } from "@accurx/concierge/hooks/data/useTeam";
import { useTeamConversationGroupQuery } from "@accurx/concierge/hooks/queries/useTeamConversationGroupQuery";
import { getMostRecentItem } from "@accurx/concierge/util/getMostRecentItem";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import {
    ConversationFeed,
    ConversationFeedOptionsWrapper,
    ConversationListHeader,
    ConversationTabItem,
    ConversationTabs,
} from "domains/inbox/components/ConversationList/components";
import { ConversationPreview } from "domains/inbox/components/ConversationPreview/ConversationPreview";
import { Dropdown } from "domains/inbox/components/Dropdown/Dropdown";
import { DropdownItem } from "domains/inbox/components/Dropdown/DropdownItem";
import { NotificationsDropdown } from "domains/inbox/components/NotificationsDropdown/NotificationsDropdown";
import {
    useInboxLink,
    useInboxParams,
    useInboxTitle,
} from "domains/inbox/hooks/util";
import { useConversationFilterAnalytics } from "domains/inbox/hooks/util/useConversationFilterAnalytics";
import { useConversationListAnalytics } from "domains/inbox/hooks/util/useConversationListAnalytics";
import { userflowIds } from "domains/inbox/util";

import { ConversationSortOrder } from "../../components/ConversationSortOrder/ConversationSortOrder";
import { getEmptyStateVariant } from "../../components/EmptyState/EmptyState";
import {
    StyledAccessibleHeader,
    StyledConversationFeedContainer,
    StyledConversationList,
} from "../styles";

/**
 * Notes on existing desktop behaviour:
 *
 * Currently the top level ToAssign view is equivalent to all open conversations,
 * assigned to the hard-coded "To Assign" team which acts as a catch-all, rather
 * than have a true lack of assignee. It only differs from the team based view in
 * that we don't show a done variation.
 *
 * There are more specific sub-views (appointments, admin, medical) which are
 * additional filters on the open conversations assigned to the To Assign team,
 * which therefore have their own component and distinct group filters. This differs
 * from Florey behaviour, which uses different teams for grouping based on
 * assignment.
 */
export const Unassigned = () => {
    const { teamId, status, sort } = useInboxParams([
        "teamId",
        "status",
        "sort",
    ]);
    const team = useTeam({ teamId });
    const link = useInboxLink();
    const groupQuery = useTeamConversationGroupQuery({ teamId, status, sort });

    const { trackTabClick } = useConversationListAnalytics();
    const { trackFilterButtonClick, trackFilterMenuItemClick } =
        useConversationFilterAnalytics();

    useInboxTitle(`Unassigned ${status.toLowerCase()} conversations`);
    const emptyStateVariant = getEmptyStateVariant(status);

    const isSelfReferralEnabled = useFeatureFlag("PatientInitiatedSussexMsk");
    const canUseSorting = useFeatureFlag("UnifiedInboxSortingBuild");

    return team ? (
        <>
            <StyledConversationList rows="auto 1fr">
                <UI.Cell>
                    <ConversationListHeader
                        heading={
                            <>
                                Unassigned
                                <StyledAccessibleHeader>
                                    showing {status.toLowerCase()} messages
                                </StyledAccessibleHeader>
                            </>
                        }
                        notifications={<NotificationsDropdown team={team} />}
                    />

                    <ConversationTabs>
                        <ConversationTabItem
                            active={status === "Open"}
                            link={link.update({
                                status: "Open",
                                sort: null,
                                conversationId: null,
                            })}
                            userflowId={userflowIds.conversationList.openTab}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Done",
                                    newTab: "Open",
                                    conversationParticipant: "WithPatient",
                                })
                            }
                        >
                            Open
                        </ConversationTabItem>

                        <ConversationTabItem
                            active={status === "Done"}
                            link={link.update({
                                status: "Done",
                                sort: null,
                                conversationId: null,
                            })}
                            userflowId={userflowIds.conversationList.doneTab}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Open",
                                    newTab: "Done",
                                    conversationParticipant: "WithPatient",
                                })
                            }
                        >
                            Done
                        </ConversationTabItem>
                    </ConversationTabs>
                </UI.Cell>

                <StyledConversationFeedContainer>
                    <ConversationFeedOptionsWrapper>
                        <UI.Ds.Flex.Item>
                            <Dropdown
                                icon={<Pill.Icon name="Tag" colour="blue" />}
                                dropDownDisplayName="Filter"
                                contentHeading="Filter"
                                onClick={(_, isOpen) => {
                                    if (!isOpen) {
                                        trackFilterButtonClick({
                                            filterName: "Filter",
                                            appOrigin: "ConversationList",
                                        });
                                    }
                                }}
                            >
                                <DropdownItem.ListItem>
                                    <DropdownItem.Link
                                        link={link.to("Unassigned", { teamId })}
                                        selected={true}
                                        onClick={() =>
                                            trackFilterMenuItemClick({
                                                filterName: "Show all",
                                                appOrigin: "ConversationList",
                                            })
                                        }
                                    >
                                        <DropdownItem.Icon name="Tag" />
                                        <DropdownItem.Text>
                                            Show all
                                        </DropdownItem.Text>
                                    </DropdownItem.Link>
                                </DropdownItem.ListItem>

                                <DropdownItem.ListItem>
                                    <DropdownItem.Divider fullWidth />
                                </DropdownItem.ListItem>

                                <DropdownItem.ListItem $mb={1}>
                                    <DropdownItem.Link
                                        link={link.to("UnassignedAdmin", {
                                            teamId,
                                        })}
                                        onClick={() =>
                                            trackFilterMenuItemClick({
                                                filterName: "Admin",
                                                appOrigin: "ConversationList",
                                            })
                                        }
                                    >
                                        <DropdownItem.Icon name="Tag" />
                                        <DropdownItem.Text>
                                            Admin
                                        </DropdownItem.Text>
                                    </DropdownItem.Link>
                                </DropdownItem.ListItem>

                                <DropdownItem.ListItem
                                    $mb={isSelfReferralEnabled ? 1 : undefined}
                                >
                                    <DropdownItem.Link
                                        link={link.to("UnassignedMedical", {
                                            teamId,
                                        })}
                                        onClick={() =>
                                            trackFilterMenuItemClick({
                                                filterName: "Medical",
                                                appOrigin: "ConversationList",
                                            })
                                        }
                                    >
                                        <DropdownItem.Icon name="Tag" />
                                        <DropdownItem.Text>
                                            Medical
                                        </DropdownItem.Text>
                                    </DropdownItem.Link>
                                </DropdownItem.ListItem>

                                {isSelfReferralEnabled && (
                                    <DropdownItem.ListItem>
                                        <DropdownItem.Link
                                            link={link.to(
                                                "UnassignedSelfReferral",
                                                {
                                                    teamId,
                                                    status,
                                                },
                                            )}
                                            onClick={() =>
                                                trackFilterMenuItemClick({
                                                    filterName: "Self referral",
                                                    appOrigin:
                                                        "ConversationList",
                                                })
                                            }
                                        >
                                            <DropdownItem.Icon name="Tag" />
                                            <DropdownItem.Text>
                                                Self referral
                                            </DropdownItem.Text>
                                        </DropdownItem.Link>
                                    </DropdownItem.ListItem>
                                )}
                            </Dropdown>
                        </UI.Ds.Flex.Item>
                        {canUseSorting && (
                            <UI.Ds.Flex.Item>
                                <ConversationSortOrder minimise />
                            </UI.Ds.Flex.Item>
                        )}
                    </ConversationFeedOptionsWrapper>

                    <ConversationFeed
                        key={groupQuery.data?.id}
                        isLoading={groupQuery.status === "loading"}
                        isError={groupQuery.status === "error"}
                        refetch={groupQuery.refetch}
                        fetchMore={groupQuery.fetchMore}
                        isFetchingMore={groupQuery.isFetchingMore}
                        isFullyLoaded={groupQuery.data?.isFullyLoaded}
                        members={groupQuery.data?.members}
                        filters={groupQuery.data?.filters}
                        emptyStateVariant={emptyStateVariant}
                        renderItem={(conversation, state) => (
                            <ConversationPreview
                                conversation={conversation}
                                item={getMostRecentItem(conversation)}
                                state={state}
                            />
                        )}
                    />
                </StyledConversationFeedContainer>
            </StyledConversationList>
        </>
    ) : null;
};
