import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledAdditionalDetails = styled(UI.Ds.Flex)`
    gap: ${UI.Tokens.SIZES[0.5]} 0;
    flex-wrap: wrap;
    flex-direction: row;

    @media (min-width: ${UI.Tokens.BREAKPOINTS.sm}) {
        flex-wrap: nowrap;
    }

    & > *:not(:last-child)::after {
        content: " • ";
        white-space: pre;
    }
`;

export const StyledPatientActions = styled(UI.Ds.Flex).attrs({
    gap: "1",
    alignItems: "flex-start",
})``;

export const StyledPatientDetails = styled(UI.Ds.Flex).attrs({
    flexDirection: "column",
    gap: "1",
})``;

export const StyledPatientHeader = styled(UI.Ds.Flex).attrs({
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "1",
})``;
