import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { useLoadPractices } from "app/organisations/hooks";
import { ModuleAccess } from "app/practices/moduleAccess";

export const AvailableFeaturesPage = () => {
    useLoadPractices();

    return (
        <StyledContainer>
            <PageHeader
                title="Available features"
                type={PageHeaderType.ListPage}
            />
            <ModuleAccess noHeader />
        </StyledContainer>
    );
};
