import React, { useEffect, useState } from "react";

import {
    Card,
    Feedback,
    Icon,
    Link,
    Spinner,
    StackPanel,
    Text,
} from "@accurx/design";
import styled from "styled-components";

import { getSharedQuestionnaire } from "api/FloreyBuilder/FloreyBuilderApi";
import { trackImportOrgSelectPage } from "app/analytics/FlemingAnalytics";
import { Navbar } from "app/organisations/shared";
import { importToOrg } from "app/practices/floreyBuilder/constants/paths";
import { StyledStackPanelListWrapper } from "app/practices/questionnaires/sharing/SharingPage.styles";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import {
    StyledCardLinkBackgroundDiv,
    StyledFauxBlockContainer,
    StyledFauxBlockOverlayAnchor,
    StyledHoveredIconWrapper,
    StyledUnhoveredIconWrapper,
} from "app/sharedComponents/clickableCard/clickableCard.styles";
import { LoadingStatus } from "shared/LoadingStatus";
import { ROUTES_CHAIN } from "shared/Routes";

import { useChooseOrgPage } from "./ChooseOrgPage.hooks";

const StyledLayoutLink = styled.a`
    text-decoration: underline;
`;
const StyledStackPanel = styled(StackPanel)`
    list-style-type: none;
    padding: 0;
`;
const StackPanelGutterSize = 2;

export const ChooseOrgPage = (): JSX.Element => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const [questionnaireInfo, setQuestionnaireInfo] = useState({ name: "" });
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(
        LoadingStatus.Initial,
    );
    const { shareId, orgsWithFeatureFlagEnabled, orgsWithFeatureFlagDisabled } =
        useChooseOrgPage();

    const getAndSetQuestionnaireName = async () => {
        setLoadingStatus(LoadingStatus.Loading);

        const response = await getSharedQuestionnaire(shareId);
        if (!response.result || !response.result.questionnaireName) {
            setLoadingStatus(LoadingStatus.Failed);
            return;
        }
        setQuestionnaireInfo({
            name: response.result.questionnaireName,
        });
        setLoadingStatus(LoadingStatus.Loaded);
    };

    useEffect(() => {
        getAndSetQuestionnaireName();
        trackImportOrgSelectPage({
            ...analyticsLoggedInProps,
            shareId: shareId,
            countActivatedOrgs: orgsWithFeatureFlagEnabled.length,
            countUnactivatedOrgs: orgsWithFeatureFlagDisabled.length,
        });
        // getAndSetQuestionnaireName changes on every render and causes the useEffect to fire each time.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shareId]);

    const noActivatedOrgs = orgsWithFeatureFlagEnabled.length === 0;
    const noUnactivatedOrgs = orgsWithFeatureFlagDisabled.length === 0;

    return (
        <>
            <StackPanel gutter={3}>
                <Navbar />
                <StackPanel horizontalContentAlignment={"center"}>
                    {loadingStatus === LoadingStatus.Loading && <Spinner />}
                    {loadingStatus === LoadingStatus.Failed && (
                        <StackPanel gutter={StackPanelGutterSize}>
                            <Feedback
                                colour={"error"}
                                title={
                                    "There was a problem with that sharing link"
                                }
                                content={
                                    "Check that there are no mistakes in your link, and try refreshing the page. If the problem persists please contact support"
                                }
                            />
                            <Link
                                href={ROUTES_CHAIN.practices}
                                text={"Take me back to my practices"}
                            />
                        </StackPanel>
                    )}
                    {loadingStatus === LoadingStatus.Loaded && (
                        <Card
                            header={
                                <Text variant="title" as="h1" skinny>
                                    {`Choose a workspace to copy ${questionnaireInfo.name} to:`}
                                </Text>
                            }
                        >
                            <StackPanel gutter={3}>
                                {noActivatedOrgs && (
                                    <Feedback
                                        colour={"warning"}
                                        title={
                                            "You can't import this questionnaire to any of your workspaces"
                                        }
                                        content={
                                            "A workspace needs to have Florey Plus module activated to access this feature."
                                        }
                                    />
                                )}
                                {!noActivatedOrgs && (
                                    <StackPanel gutter={StackPanelGutterSize}>
                                        <Text variant="label" skinny>
                                            Import questionnaire to one of your
                                            workspaces
                                        </Text>
                                        <StyledStackPanel
                                            as="ul"
                                            gutter={StackPanelGutterSize}
                                        >
                                            {orgsWithFeatureFlagEnabled.map(
                                                (org) => (
                                                    <StyledFauxBlockContainer
                                                        as="li"
                                                        key={org.id}
                                                    >
                                                        {/*we're using <StyledFauxBlockOverlayAnchor> here because the refresh page behaviour is DESIRED - we're changing org so clearing the state avoids mismatch*/}
                                                        <StyledFauxBlockOverlayAnchor
                                                            href={importToOrg(
                                                                org.id.toString(),
                                                                shareId,
                                                            )}
                                                        >
                                                            <StackPanel
                                                                orientation="horizontal"
                                                                horizontalContentAlignment="space-between"
                                                            >
                                                                <Text
                                                                    skinny
                                                                    variant="label"
                                                                >
                                                                    {org.name}
                                                                </Text>
                                                                {/* TODO: refactor existing StyledFauxBlockOverlayLink approach to use CSS for SVG colour switching instead of hiding/showing SVG elements */}
                                                                <StyledHoveredIconWrapper>
                                                                    <Icon
                                                                        name="ArrowTail"
                                                                        size={4}
                                                                        rotation="right"
                                                                        halo={{
                                                                            colour: "blue",
                                                                            luminosity:
                                                                                "low",
                                                                        }}
                                                                        props={{
                                                                            "aria-hidden":
                                                                                true,
                                                                            focusable:
                                                                                false,
                                                                        }}
                                                                    />
                                                                </StyledHoveredIconWrapper>
                                                                <StyledUnhoveredIconWrapper>
                                                                    <Icon
                                                                        name="ArrowTail"
                                                                        size={4}
                                                                        rotation="right"
                                                                        halo={{
                                                                            colour: "blue",
                                                                            luminosity:
                                                                                "high",
                                                                        }}
                                                                        props={{
                                                                            "aria-hidden":
                                                                                true,
                                                                            focusable:
                                                                                false,
                                                                        }}
                                                                    />
                                                                </StyledUnhoveredIconWrapper>
                                                            </StackPanel>
                                                        </StyledFauxBlockOverlayAnchor>
                                                    </StyledFauxBlockContainer>
                                                ),
                                            )}
                                        </StyledStackPanel>
                                    </StackPanel>
                                )}
                                {!noUnactivatedOrgs && (
                                    <StackPanel gutter={StackPanelGutterSize}>
                                        <>
                                            <Text variant="label">
                                                Workspace without Florey Plus
                                                activated
                                            </Text>
                                            <StackPanel>
                                                <Text skinny>
                                                    {
                                                        "If you would like to generate a quote to purchase Florey plus, please follow this link."
                                                    }
                                                </Text>
                                                <StyledLayoutLink
                                                    href={
                                                        "https://link.accurx.com/accurxPlusQuoteOrder"
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {" "}
                                                    Generate quote
                                                    <Icon
                                                        name={"OpenWindow"}
                                                        colour={"blue"}
                                                        size={3}
                                                    />
                                                </StyledLayoutLink>
                                            </StackPanel>
                                        </>

                                        <div>
                                            <StyledStackPanelListWrapper
                                                as="ul"
                                                gutter={StackPanelGutterSize}
                                            >
                                                {orgsWithFeatureFlagDisabled.map(
                                                    (org) => (
                                                        <StyledCardLinkBackgroundDiv
                                                            as="li"
                                                            key={org.id}
                                                        >
                                                            <Text
                                                                skinny
                                                                variant="label"
                                                            >
                                                                {org.name}
                                                            </Text>
                                                            <Text skinny>
                                                                This workspace
                                                                does not have
                                                                access to custom
                                                                questionnaires.
                                                            </Text>
                                                        </StyledCardLinkBackgroundDiv>
                                                    ),
                                                )}
                                            </StyledStackPanelListWrapper>
                                        </div>
                                    </StackPanel>
                                )}
                            </StackPanel>
                        </Card>
                    )}
                </StackPanel>
            </StackPanel>
        </>
    );
};
