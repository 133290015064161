import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useMatchTicketToPatientMutation } from "@accurx/concierge/hooks/mutations/useMatchTicketToPatientMutation";
import { useUnunmatchTicketFromPatientMutation } from "@accurx/concierge/hooks/mutations/useUnmatchTicketFromPatientMutation";
import { Conversation, PatientExternalId } from "@accurx/concierge/types";
import { Ds, Feedback } from "@accurx/design";
import { WithTooltip } from "domains/inbox/components/Tooltip/WithTooltip";
import { useConversationAnalyticsFields } from "domains/inbox/hooks/util/useConversationAnalyticsFields";
import { PatientMatchState } from "domains/inbox/util/getPatientMatchState";
import { toast } from "react-toastify";

import { UnmatchPatientModal } from "./UnmatchPatientModal";

export type SearchForPatientButtonProps = Partial<{
    disabled: boolean;
    onClick: () => void;
}>;

export type PatientMatchingActionsProps = {
    patientMatchState: NonNullable<PatientMatchState>;
    conversation: Conversation;
    searchForPatientButtonProps?: SearchForPatientButtonProps;
};

export const PatientMatchingActions = ({
    patientMatchState,
    conversation,
    searchForPatientButtonProps,
}: PatientMatchingActionsProps) => {
    const track = useAnalytics();
    const [isUnmatchPatientModalOpen, setIsUnmatchPatientModalOpen] =
        useState(false);
    const matchTicketToPatientMutation = useMatchTicketToPatientMutation({
        onError: () =>
            toast(
                <Feedback
                    title="Failed to match conversation to patient. Please try again."
                    colour="error"
                />,
            ),
        onSettled: (_data, error) =>
            track("ConversationPatientMatchConfirm Button Response", {
                ...trackingFields,
                hasError: Boolean(error),
                matchType: "Suggested",
                eventVersion: 2,
            }),
    });
    const unmatchTicketFromPatientMutation =
        useUnunmatchTicketFromPatientMutation({
            onError: () =>
                toast(
                    <Feedback
                        title="Failed to unmatch conversation from patient. Please try again."
                        colour="error"
                    />,
                ),
            onSuccess: () => setIsUnmatchPatientModalOpen(false),
            onSettled: (_data, error) =>
                track("ConversationPatientUnmatchConfirm Button Response", {
                    ...trackingFields,
                    hasError: Boolean(error),
                }),
        });

    const trackingFields = useConversationAnalyticsFields({
        conversation,
        appOrigin: "ConversationActions",
    });

    const handleMatchTicketToPatient = (
        patientExternalIds: PatientExternalId[],
    ) => {
        matchTicketToPatientMutation.mutate({
            conversation,
            patientExternalIds,
        });
    };

    const handleUnmatchTicketFromPatient = () => {
        track("ConversationPatientUnmatchConfirm Button Click", trackingFields);

        unmatchTicketFromPatientMutation.mutate({ conversation });
    };

    return (
        <>
            {patientMatchState.type === "NoMatch" && (
                <Ds.Button
                    size="small"
                    onClick={() => {
                        track(
                            "ConversationPatientMatchSearchStart Button Click",
                            trackingFields,
                        );

                        searchForPatientButtonProps?.onClick?.();
                    }}
                    disabled={!!searchForPatientButtonProps?.disabled}
                >
                    Search for patient
                </Ds.Button>
            )}
            {patientMatchState.type === "Match" && (
                <Ds.Button
                    appearance="secondary"
                    loading={unmatchTicketFromPatientMutation.isLoading}
                    aria-label="Unmatch patient from conversation"
                    size="small"
                    onClick={() => {
                        track(
                            "ConversationPatientUnmatchSelect Button Click",
                            trackingFields,
                        );

                        setIsUnmatchPatientModalOpen(true);
                    }}
                >
                    Unmatch patient
                </Ds.Button>
            )}
            {patientMatchState.type === "SuggestedMatch" && (
                <Ds.Flex gap="1">
                    <Ds.Button
                        loading={matchTicketToPatientMutation.isLoading}
                        size="small"
                        aria-label="Confirm patient and match to conversation"
                        onClick={() => {
                            track(
                                "ConversationPatientMatchConfirm Button Click",
                                {
                                    ...trackingFields,
                                    matchType: "Suggested",
                                    eventVersion: 2,
                                },
                            );
                            handleMatchTicketToPatient(
                                patientMatchState.suggestedMatch.externalIds,
                            );
                        }}
                    >
                        Confirm patient
                    </Ds.Button>
                    <WithTooltip
                        content={
                            <Ds.Text size="small" weight="bold" color="white">
                                Match to another patient
                            </Ds.Text>
                        }
                    >
                        <Ds.Button
                            appearance="secondary"
                            size="small"
                            aria-label="Match to another patient"
                            onClick={() => {
                                track(
                                    "ConversationSuggestedPatientChangeSelect Button Click",
                                    trackingFields,
                                );

                                searchForPatientButtonProps?.onClick?.();
                            }}
                        >
                            Change
                        </Ds.Button>
                    </WithTooltip>
                </Ds.Flex>
            )}
            <UnmatchPatientModal
                isOpen={isUnmatchPatientModalOpen}
                onClose={() => setIsUnmatchPatientModalOpen(false)}
                onConfirmUnmatch={handleUnmatchTicketFromPatient}
                isLoading={unmatchTicketFromPatientMutation.isLoading}
            />
        </>
    );
};
