import { ConversationGroupSortOptions } from "domains/concierge/internal/types/ConversationGroupSortOptions";

type CommonParams = {
    status: "Open" | "Done";
    sort: "Newest" | "Oldest";
};

type ClinicianCommonParams = {
    sort: "Newest" | "Oldest";
};

const common = ({
    status,
    sort,
}: CommonParams): ConversationGroupSortOptions => {
    const lastUpdatedDirection = sort === "Newest" ? "desc" : "asc";
    return status === "Open"
        ? [
              { key: "IsUrgent", direction: "desc" },
              { key: "LastUpdated", direction: lastUpdatedDirection },
              { key: "Id", direction: "desc" },
          ]
        : [
              { key: "LastUpdated", direction: lastUpdatedDirection },
              { key: "Id", direction: "desc" },
          ];
};

const allFailed = (): ConversationGroupSortOptions => {
    return [
        { key: "MostRecentFailedItem", direction: "desc" },
        { key: "Id", direction: "desc" },
    ];
};

const clinicianCommon = ({
    sort,
}: ClinicianCommonParams): ConversationGroupSortOptions => {
    return [
        { key: "LastUpdated", direction: sort === "Newest" ? "desc" : "asc" },
        { key: "Id", direction: "desc" },
    ];
};

export const sortOptions = {
    common,
    allFailed,
    clinicianCommon,
};
