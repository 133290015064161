import { ClinicianConversationWithWorkspace } from "@accurx/api/clinician-messaging";
import { mapAssignee } from "domains/concierge/internal/api/clinicianMessaging/mappers/mapAssignee";
import { isInstance } from "domains/concierge/internal/util/isInstance";
import { Conversation } from "domains/concierge/types";

import { mapClinicianMessageItemToConversationItem } from "./mapClinicianMessageItemToConversationItem";
import { mapConversationServerIdToConversationId } from "./mapConversationServerIdToConversationId";
import { mapContactMemberToConversationParticipant } from "./mapIndividualParticipantToConversationParticipant";
import { mapMemberWorkspaceToConversationParticipant } from "./mapMemberWorkspaceToConversationParticipant";

export const mapClinicianConversationWithWorkspaceToConversation = (
    dto: ClinicianConversationWithWorkspace,
): Conversation | undefined => {
    const items = dto.items
        .map(mapClinicianMessageItemToConversationItem)
        .filter(isInstance);

    const participants = [
        ...dto.participantWorkspaces.map(
            mapMemberWorkspaceToConversationParticipant,
        ),
        ...dto.individualParticipants.map(
            mapContactMemberToConversationParticipant,
        ),
    ].filter(isInstance);

    return {
        id: mapConversationServerIdToConversationId(
            dto.workspaceConversationId,
        ),
        source: {
            system: "ClinicianMessaging",
            serverId: dto.workspaceConversationId,
            rowVersion: dto.rowVersion,
        },
        assignee: mapAssignee(dto.assignee),
        status: dto.isArchived ? "Done" : "Open",
        isUrgent: false,
        regardingPatientId: dto.patient?.patientId ?? null,
        lastUpdated: dto.lastUpdated,
        latestToken: dto.lastUpdated,
        isFullyLoaded: true,
        items,
        participants,
    };
};
