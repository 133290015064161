import * as UI from "@accurx/design";
import styled, { css } from "styled-components";

const { COLOURS, BORDERS } = UI.Tokens;

export const StyledTextArea = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

// Align inner textarea: 2px padding from user agent + 1px border of this component
export const borderCompensation = css`
    margin-left: -3px;
    margin-right: -3px;
`;

export const StyledOuterTextArea = styled.div<{ $error: boolean }>`
    border-radius: ${BORDERS.radius};
    border: 1px solid ${COLOURS.greyscale.white};

    ${borderCompensation}

    padding: ${UI.Tokens.SIZES[0.5]};

    border: 1px solid
        ${({ $error }) =>
            $error ? COLOURS.primary.red[100] : COLOURS.greyscale.white};

    &:hover {
        ${UI.hoverBorderStyles}
    }
    &:focus-within {
        ${UI.focusBorderStyles}
    }
`;
