import { useCallback } from "react";

import { getApiEndpoint, httpClient, returnDataOrThrow } from "@accurx/shared";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import omit from "lodash/omit";

import {
    ClinicianAvailabilityOption,
    ClinicianObject,
    SlotAvailabilityOption,
} from "../utils/types";

export const GET_SLOT_AGGREGATE_AVAILABILITY = "AggregateSlotAvailability";

export type SlotAggregateObject = {
    id: number;
    name: string;
    count: number;
};

export type ClinicianAggregateObject = {
    id: number;
    clinicians: ClinicianObject[];
    count: number;
};

export interface SlotAggregateAvailabilityResponse {
    slotType: SlotAggregateObject[];
    clinicianGroup: ClinicianAggregateObject[];
}

export type QueryParam = {
    slotType?: string[];
    clinicianGroupId?: number[];
    appointmentType?: number[];
    from?: string;
    to?: string;
    targetOrganisationOdsCode?: string;
};

// Function to build the query string
function buildQueryParams(params: QueryParam | undefined): string | undefined {
    if (!params) return;
    const searchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (value === undefined) {
            return;
        }
        if (Array.isArray(value)) {
            // If the value is an array, join it into a single string separated by commas
            value.forEach((item) => {
                const value = typeof item === "string" ? item : item.toString();
                searchParams.append(key, value);
            });
        } else {
            searchParams.append(key, value);
        }
    });
    return searchParams.toString();
}

export interface SlotAggregateAvailabilityParsedResponse {
    slotTypeOptions: SlotAvailabilityOption[];
    clinicianOptions: ClinicianAvailabilityOption[];
}

export const transformToSlotTypesOptions = (
    slotType: SlotAggregateObject[],
): SlotAvailabilityOption[] => {
    // Transforming the slot object into an array of objects with label, value and count properties
    return slotType.map((item) => ({
        label: `${item.name}${item.count === 0 ? " (0 slots)" : ""}`,
        value: `${item.id}`,
        count: item.count,
        slotName: `${item.name}`,
    }));
};

export const transformToClinicianOptions = (
    clinicianGroup: ClinicianAggregateObject[],
): ClinicianAvailabilityOption[] => {
    // Transforming the clinician Group object into an array of objects with label, value and count properties
    return clinicianGroup.map((group) => ({
        value: `${group.id}`,
        count: group.count,
        clinicians: group.clinicians,
        label: `${group.clinicians.map((obj) => obj.displayName).join(", ")} ${
            group.count === 0 ? "(no availability)" : ""
        }`,
    }));
};

const slotAggregateAvailabilityDataTransformer = (
    response: SlotAggregateAvailabilityResponse,
): SlotAggregateAvailabilityParsedResponse => {
    const slotTypeOptions: SlotAvailabilityOption[] =
        transformToSlotTypesOptions(response.slotType);

    const clinicianOptions: ClinicianAvailabilityOption[] =
        transformToClinicianOptions(response.clinicianGroup);

    return {
        slotTypeOptions,
        clinicianOptions,
    };
};

export const useSlotAggregateAvailability = (
    orgId: number,
    queryParams: QueryParam,
): UseQueryResult<SlotAggregateAvailabilityParsedResponse, Error> => {
    // Generate the query string
    const queryString = buildQueryParams(queryParams);
    const queryParamWithoutFrom = omit(queryParams, ["from"]);
    const queryParamsObject = `${Object.values(queryParamWithoutFrom).join(
        "-",
    )}`;

    return useQuery({
        queryKey: [
            GET_SLOT_AGGREGATE_AVAILABILITY,
            `${
                queryParams.targetOrganisationOdsCode ?? orgId
            }- ${queryParamsObject}`,
        ],
        queryFn: async () =>
            await httpClient
                .getReturnJsonSafeAsync(
                    getApiEndpoint({
                        path: `/api/appointments/organisation/${orgId}/slots/aggregateAvailability`,
                        query: queryString ? `?${queryString}` : undefined,
                    }),
                )
                .then((response) => {
                    return returnDataOrThrow(
                        response,
                    ) as SlotAggregateAvailabilityResponse;
                }),
        // memoise to not run on every render
        select: useCallback(
            (data: SlotAggregateAvailabilityResponse) =>
                slotAggregateAvailabilityDataTransformer(data),
            [],
        ),
        refetchOnMount: true,
        keepPreviousData: true,
    });
};
