import { Ds } from "@accurx/design";

type DownloadAsPDFButtonProps = {
    downloadUrl: string;
};

export const DownloadAsPDFButton = ({
    downloadUrl,
}: DownloadAsPDFButtonProps) => {
    return (
        <Ds.Button
            appearance="secondary"
            size="small"
            onClick={() => window.open(downloadUrl)}
            data-testid="download-pdf-button"
        >
            <Ds.Button.Icon name="Save" />
            Download as PDF
        </Ds.Button>
    );
};
