import { TextArea } from "domains/message-component/components/Textarea";
import { NHSAdviceLink } from "domains/message-component/types";

import {
    NHS_ADVICE_TEXT,
    PATIENT_RESPONSE_TEXT,
    QUESTIONNAIRE_TEXT,
    SELFBOOK_TEXT,
    VIEW_ATTACHMENT_TEXT_MULTIPLE,
    VIEW_ATTACHMENT_TEXT_SINGLE,
    VIEW_PATIENT_RESPONSE_OR_ATTACHMENT_TEXT,
} from "../../Compose.constants";
import { StyledMessageContentPlaceholderWrapper } from "./MessageContentPlaceholder.styles";

const LINK_WILL_APPEAR_HERE_PLACEHOLDER = "[link will appear here]";

const PATIENT_RESPONSE_PLACEHOLDER = `${PATIENT_RESPONSE_TEXT.trim()} ${LINK_WILL_APPEAR_HERE_PLACEHOLDER}`;
const VIEW_ATTACHMENTS_PLACEHOLDER_SINGLE = `${VIEW_ATTACHMENT_TEXT_SINGLE.trim()} ${LINK_WILL_APPEAR_HERE_PLACEHOLDER}`;
const VIEW_ATTACHMENTS_PLACEHOLDER_MULTIPLE = `${VIEW_ATTACHMENT_TEXT_MULTIPLE.trim()} ${LINK_WILL_APPEAR_HERE_PLACEHOLDER}`;
const VIEW_PATIENT_RESPONSE_OR_ATTACHMENT_PLACEHOLDER = `${VIEW_PATIENT_RESPONSE_OR_ATTACHMENT_TEXT.trim()} ${LINK_WILL_APPEAR_HERE_PLACEHOLDER}`;
const NHS_ADVICE_PLACEHOLDER = NHS_ADVICE_TEXT.trim();
const QUESTIONNAIRE_PLACEHOLDER = `${QUESTIONNAIRE_TEXT.trim()} ${LINK_WILL_APPEAR_HERE_PLACEHOLDER}`;

const generateSelfBookPlaceholder = (timeoutDays: number) => {
    const puralText = timeoutDays === 1 ? "" : "s";
    return `${SELFBOOK_TEXT.prepend.trim()} ${LINK_WILL_APPEAR_HERE_PLACEHOLDER} ${SELFBOOK_TEXT.append
        .replace("X", timeoutDays.toString())
        .replace("S", puralText)
        .trim()}`;
};

const getPatientResponseAndAttachmentText = ({
    isPatientResponseEnabled,
    attachmentsCount,
}: {
    isPatientResponseEnabled: boolean;
    attachmentsCount: number;
}) => {
    const hasAttachments = attachmentsCount > 0;
    const hasMultipleAttachments = attachmentsCount > 1;

    if (isPatientResponseEnabled) {
        return hasAttachments
            ? VIEW_PATIENT_RESPONSE_OR_ATTACHMENT_PLACEHOLDER
            : PATIENT_RESPONSE_PLACEHOLDER;
    }

    if (hasAttachments) {
        return hasMultipleAttachments
            ? VIEW_ATTACHMENTS_PLACEHOLDER_MULTIPLE
            : VIEW_ATTACHMENTS_PLACEHOLDER_SINGLE;
    }

    return null;
};

export const MessageContentPlaceholder = ({
    nhsAdviceLink,
    isPatientResponseEnabled,
    attachmentsCount,
    isQuestionnaire,
    hasSelfBookLink,
    selfBookTimeoutDays,
}: {
    nhsAdviceLink: NHSAdviceLink | null;
    isPatientResponseEnabled: boolean;
    attachmentsCount: number;
    isQuestionnaire: boolean;
    hasSelfBookLink: boolean;
    selfBookTimeoutDays?: number;
}) => {
    const hasAttachments = attachmentsCount > 0;

    if (
        !nhsAdviceLink &&
        !isPatientResponseEnabled &&
        !hasAttachments &&
        !isQuestionnaire &&
        !hasSelfBookLink
    ) {
        return null;
    }

    const patientResponseAndAttachmentsText =
        getPatientResponseAndAttachmentText({
            isPatientResponseEnabled,
            attachmentsCount,
        });

    return (
        <StyledMessageContentPlaceholderWrapper>
            {nhsAdviceLink !== null && (
                <TextArea.UneditableText>
                    {NHS_ADVICE_PLACEHOLDER} {nhsAdviceLink.name} <br />
                    {nhsAdviceLink.url}
                </TextArea.UneditableText>
            )}
            {patientResponseAndAttachmentsText !== null && (
                <TextArea.UneditableText>
                    {patientResponseAndAttachmentsText}
                </TextArea.UneditableText>
            )}
            {isQuestionnaire && (
                <TextArea.UneditableText>
                    {QUESTIONNAIRE_PLACEHOLDER}
                </TextArea.UneditableText>
            )}
            {hasSelfBookLink && selfBookTimeoutDays && (
                <TextArea.UneditableText>
                    {generateSelfBookPlaceholder(selfBookTimeoutDays)}
                </TextArea.UneditableText>
            )}
        </StyledMessageContentPlaceholderWrapper>
    );
};
