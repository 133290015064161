import { useCallback, useState } from "react";

import { Pagination, Text } from "@accurx/design";

import {
    trackPatientTriageDashboardPageChangeClick,
    trackPatientTriageDashboardPageSizeButtonClick,
    trackPatientTriageDashboardPageSizeMenuItemClick,
} from "app/analytics/FlemingAnalytics/PatientTriageDashboardEvents";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { PAGE_SIZES } from "./TablePagination.constants";
import { TablePaginationProps } from "./TablePagination.types";

export const TablePagination = ({
    currentPage,
    currentPageSize,
    total,
    totalPages,
    onPageSizeChange,
    onNext,
    onPrevious,
}: TablePaginationProps): JSX.Element => {
    const [isSelectOpen, setIsSelectOpen] = useState(false);

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const filteredPageSizes = PAGE_SIZES.filter((pageSize) => pageSize < total);

    const handleOpenChange = useCallback(
        (isOpen: boolean) => {
            setIsSelectOpen(isOpen);
            if (isOpen) {
                trackPatientTriageDashboardPageSizeButtonClick({
                    ...analyticsLoggedInProps,
                    sectionName: "Requests by assignee",
                    dashboardName: "Patient Triage Dashboard",
                });
            }
        },
        [analyticsLoggedInProps],
    );

    const handlePageSizeChange = useCallback(
        (pageSize: number) => {
            onPageSizeChange(pageSize);
            trackPatientTriageDashboardPageSizeMenuItemClick({
                ...analyticsLoggedInProps,
                sectionName: "Requests by assignee",
                dashboardName: "Patient Triage Dashboard",
                pageSize,
            });
        },
        [analyticsLoggedInProps, onPageSizeChange],
    );

    const handleNext = useCallback(() => {
        onNext();
        trackPatientTriageDashboardPageChangeClick({
            ...analyticsLoggedInProps,
            sectionName: "Requests by assignee",
            dashboardName: "Patient Triage Dashboard",
            currentPageNumber: currentPage,
            newPageNumber: currentPage === totalPages ? 1 : currentPage + 1,
        });
    }, [onNext, currentPage, totalPages, analyticsLoggedInProps]);

    const handlePrevious = useCallback(() => {
        onPrevious();
        trackPatientTriageDashboardPageChangeClick({
            ...analyticsLoggedInProps,
            sectionName: "Requests by assignee",
            dashboardName: "Patient Triage Dashboard",
            currentPageNumber: currentPage,
            newPageNumber: currentPage === 1 ? totalPages : currentPage - 1,
        });
    }, [onPrevious, currentPage, totalPages, analyticsLoggedInProps]);

    return (
        <Pagination>
            <Text skinny as="div">
                Showing
                {filteredPageSizes.length === 0 &&
                total !== 0 &&
                currentPageSize > total ? (
                    <> all </>
                ) : total !== 0 ? (
                    <Pagination.SizeSelect
                        id="test-pagination-size"
                        open={isSelectOpen}
                        pageSize={
                            filteredPageSizes.includes(currentPageSize)
                                ? currentPageSize
                                : PAGE_SIZES[0]
                        }
                        onPageSizeChange={handlePageSizeChange}
                        onOpenChange={handleOpenChange}
                    >
                        {filteredPageSizes.map((pageSize) => (
                            <Pagination.SizeOption
                                key={pageSize}
                                value={pageSize}
                            />
                        ))}
                        {!!total && (
                            <Pagination.SizeOption value={total}>
                                All
                            </Pagination.SizeOption>
                        )}
                    </Pagination.SizeSelect>
                ) : (
                    <> 0</>
                )}
                {!!total && <>of {total}</>} entries
            </Text>
            <Pagination.Navigation
                currentPage={currentPage}
                totalPages={totalPages}
            >
                <Pagination.PreviousButton onPrevious={handlePrevious} />
                <Pagination.NextButton onNext={handleNext} />
            </Pagination.Navigation>
        </Pagination>
    );
};
