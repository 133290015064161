import { useMemo, useRef } from "react";

import { useCurrentUser } from "@accurx/auth";
import { useAssignee } from "@accurx/concierge/hooks/data/useAssignee";
import { useConversation } from "@accurx/concierge/hooks/data/useConversation";
import {
    Conversation,
    PatientTriageRequestItem,
    TeamSummary,
    UserSummary,
} from "@accurx/concierge/types";
import { ROUTES_INBOX } from "domains/inbox/routes";

import { LocationObject, useInboxLink } from "./useInboxLink";

/**
 * Gets the location of the conversation, based on the conversation
 * and the assignee of the conversation
 */

export const useConversationLocation = ({
    conversationId,
}: {
    conversationId: string;
}): LocationObject | undefined => {
    const link = useInboxLink();
    const linkToRef = useRef(link.to);
    const conversation = useConversation({ conversationId });
    const assignee = useAssignee(conversation?.assignee ?? { type: "None" });

    const currentUser = useCurrentUser();
    const userId = currentUser.user.accuRxUserId;

    return useMemo(() => {
        if (!conversation) return;

        const conversationGroupRoute = getConversationGroupRoute({
            conversation,
            assignee,
            userId,
        });
        const status = conversation.status === "Done" ? "Done" : undefined;

        switch (conversationGroupRoute) {
            case "Teams":
            case "Questionnaires":
            case "Unassigned":
            case "UnassignedAdmin":
            case "UnassignedMedical":
            case "UnassignedSelfReferral":
            case "Shared":
                if (!assignee) break;

                return linkToRef.current(conversationGroupRoute, {
                    conversationId: conversation.id,
                    teamId: assignee.id,
                });

            case "Colleagues":
                if (!assignee) break;
                return linkToRef.current(conversationGroupRoute, {
                    conversationId: conversation.id,
                    colleagueId: assignee.id,
                    status,
                });
            default:
                return linkToRef.current(conversationGroupRoute, {
                    conversationId: conversation.id,
                    status,
                });
        }
    }, [assignee, conversation, userId]);
};

const getConversationGroupRoute = ({
    conversation,
    assignee,
    userId,
}: {
    conversation: Conversation;
    assignee: TeamSummary | UserSummary | undefined;
    userId: string;
}): keyof typeof ROUTES_INBOX => {
    if (assignee && "isCurrentUser" in assignee && assignee.isCurrentUser) {
        const hasScheduledItemByUser = conversation.items.some(
            (item) =>
                item.createdBy.type === "User" &&
                item.createdBy.id === userId &&
                "deliveryStatus" in item &&
                item.deliveryStatus === "Queued",
        );
        const hasSentItemByUser = conversation.items.some(
            (item) =>
                item.createdBy.type === "User" &&
                item.createdBy.id === userId &&
                "deliveryStatus" in item,
        );

        if (hasScheduledItemByUser) {
            return "MyInboxScheduled";
        } else if (hasSentItemByUser) {
            return "MyInboxSent";
        }

        return "MyInbox";
    }

    if (assignee && "type" in assignee) {
        switch (assignee.type) {
            case "ToAssignPatientMessaging": {
                const patientTriageItem = conversation.items.find(
                    (item, index) =>
                        index === 0 &&
                        item.contentType === "PatientTriageRequestNote",
                ) as PatientTriageRequestItem | undefined;

                if (patientTriageItem?.requestType === "Admin") {
                    return "UnassignedAdmin";
                } else if (patientTriageItem?.requestType === "Medical") {
                    return "UnassignedMedical";
                } else if (patientTriageItem?.requestType === "SelfReferral") {
                    return "UnassignedSelfReferral";
                }

                return "Unassigned";
            }
            case "Default":
                return "Teams";
            case "Florey":
                return "Questionnaires";
            case "Hub":
                return "Shared";
        }
    }

    const hasPatientTriageItem = conversation.items.some(
        (item, index) =>
            index === 0 && item.contentType === "PatientTriageRequestNote",
    );

    const hasPatientResponseItem = conversation.items.some(
        (item) => item.contentType === "PatientSingleResponse",
    );

    const hasFailedItem = conversation.items.some(
        (item) => item.contentType === "FailedDeliveryReceiptLink",
    );

    const hasScheduledItem = conversation.items.some(
        (item) => "deliveryStatus" in item && item.deliveryStatus === "Queued",
    );

    const hasSentItem = conversation.items.some(
        (item) => "deliveryStatus" in item,
    );

    if (hasPatientTriageItem) return "AllPatientTriages";
    if (hasPatientResponseItem) return "AllPatientResponses";
    if (hasFailedItem) return "AllFailed";
    if (hasScheduledItem) return "AllScheduled";
    if (hasSentItem) return "AllSent";
    if (assignee && "isCurrentUser" in assignee && !assignee.isCurrentUser)
        return "Colleagues";

    return "All";
};
