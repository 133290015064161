import { useEffect, useState } from "react";

import { FeatureName, useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import { useIsGpBatchEnabled } from "@accurx/batch-messaging";
import { Accordion, Sidenav } from "@accurx/navigation";
import { generatePath, matchPath, useLocation } from "react-router";

import { ROUTES_BASE, ROUTES_PRIMARY } from "shared/Routes";

import { SecondaryNavLink } from "../SecondaryNavLink";
import { PatientLists } from "./PatientLists";

export const CohortsNavigation = () => {
    const { orgId: workspaceId } = useCurrentWorkspace();
    const location = useLocation();

    const [openAccordionItem, setOpenAccordionItem] = useState<
        string | undefined
    >(undefined);

    const isIntegratedClinicsEnabled = useFeatureFlag(
        FeatureName.IntegratedClinicLists,
    );
    const isWebBatchMessagingEnabled = useFeatureFlag(
        FeatureName.WebBatchMessaging,
    );
    const isQuestionnaireScoreboardEnabled = useFeatureFlag(
        FeatureName.PerioperativeCarePilot,
    );
    const isGpBatchEnabled = useIsGpBatchEnabled();

    const isBatchEnabled = isWebBatchMessagingEnabled || isGpBatchEnabled;

    useEffect(() => {
        const isMatch = matchPath(location.pathname, {
            path: "*/patients/lists",
            exact: false,
        });

        if (isMatch) {
            setOpenAccordionItem("patient-lists");
        }
    }, [location.pathname]);

    return (
        <Sidenav.Content>
            <Sidenav.Title>Patient lists</Sidenav.Title>
            <Accordion.Root
                value={openAccordionItem}
                onValueChange={setOpenAccordionItem}
                collapsible
            >
                {isIntegratedClinicsEnabled && (
                    <SecondaryNavLink
                        to={generatePath(ROUTES_BASE.integratedClinicListsAll, {
                            workspaceId,
                        })}
                        icon={{ name: "Calendar" }}
                    >
                        Clinics
                    </SecondaryNavLink>
                )}
                <PatientLists isOpen={openAccordionItem === "patient-lists"} />
                {isBatchEnabled && (
                    <SecondaryNavLink
                        to={generatePath(
                            `${ROUTES_PRIMARY.batchMessageTrust}/sent`,
                            {
                                workspaceId,
                            },
                        )}
                        icon={{ name: "Messages" }}
                    >
                        Batch messages
                    </SecondaryNavLink>
                )}
                {isQuestionnaireScoreboardEnabled && (
                    <SecondaryNavLink
                        to={generatePath(
                            ROUTES_PRIMARY.questionnaireScoreboard,
                            {
                                workspaceId,
                            },
                        )}
                        icon={{ name: "Questionnaire" }}
                    >
                        Questionnaire responses
                    </SecondaryNavLink>
                )}
            </Accordion.Root>
        </Sidenav.Content>
    );
};
