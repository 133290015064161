import { useState } from "react";

import { Pill } from "@accurx/inbox-design-library";
import { useCompose } from "domains/message-component/context";
import { hasError } from "domains/message-component/utils/hasError";
import { validateClinicianMessage } from "domains/message-component/utils/validateClinicianMessage";

import { OnClinicianMessageSendFn } from "../../types/Compose.types";
import { FailedAttachmentWarningModal } from "./FailedAttachmentWarningModal";

type ClinicianSendMessageProps = {
    onMessageSend: OnClinicianMessageSendFn;
    isLoading: boolean;
};

export const ClinicianSendMessage = ({
    onMessageSend,
    isLoading,
}: ClinicianSendMessageProps) => {
    const { state, dispatch } = useCompose();
    const [isAttachmentFailedModalOpen, setIsAttachmentFailedModalOpen] =
        useState(false);

    const handleValidatedMessageSendClick = (args: {
        /**
         * Whether the button to send has been clicked
         * from a warning modal
         */
        isModal: boolean;
        warnings: string[];
        errors: string[];
    }) => {
        onMessageSend({
            data: {
                attachments: state.attachments.filter(
                    ({ status }) => status === "success",
                ),
                messageBody: state.messageBody,
                messageSignature: state.messageSignature,
                template: state.template,
            },
            errors: args.errors,
            warnings: args.warnings,
            isModal: args.isModal,
        });
    };

    const handleMessageValidation = () => {
        const errors = validateClinicianMessage({
            originalGreeting: state.originalGreeting,
            messageBody: state.messageBody,
            messageSignature: state.messageSignature,
            originalSignature: state.originalSignature,
            attachments: state.attachments,
            withTemplate: !!state.template,
        });

        const hasErrors = hasError(errors);
        const analyticsErrors: string[] = hasErrors
            ? (Object.values(errors).filter(
                  (error) => typeof error === "string",
              ) as string[])
            : [];
        // Either add new or clear the errors
        dispatch({
            type: "SET_ERRORS",
            payload: errors,
        });

        if (hasErrors) {
            handleValidatedMessageSendClick({
                errors: analyticsErrors,
                warnings: [],
                isModal: false,
            });
            return;
        }

        if (state.attachments.some(({ status }) => status === "error")) {
            setIsAttachmentFailedModalOpen(true);
            handleValidatedMessageSendClick({
                errors: analyticsErrors,
                warnings: ["FAILED_ATTACHMENT"],
                isModal: false,
            });
            return;
        }

        handleValidatedMessageSendClick({
            isModal: false,
            warnings: [],
            errors: [],
        });
    };

    return (
        <>
            <Pill.PrimaryButton
                onClick={handleMessageValidation}
                disabled={isLoading}
                dimension="small"
            >
                <Pill.Icon
                    name="Send"
                    colour="white"
                    size={3}
                    theme="Fill"
                    isLoading={isLoading}
                />
                <Pill.Text>Send</Pill.Text>
            </Pill.PrimaryButton>
            <FailedAttachmentWarningModal
                isOpen={isAttachmentFailedModalOpen}
                onClose={() => setIsAttachmentFailedModalOpen(false)}
                onClickSendMessageWithoutAttachment={() => {
                    setIsAttachmentFailedModalOpen(false);
                    handleValidatedMessageSendClick({
                        isModal: true,
                        warnings: [],
                        errors: [],
                    });
                }}
            />
        </>
    );
};
