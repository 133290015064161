import { useEffect } from "react";

import { useCurrentUser, useCurrentWorkspace } from "@accurx/auth";
import { useLocation, useParams } from "react-router";

import {
    FlemingLoggedInCustomProperties,
    trackSwitchingOrgPage,
} from "app/analytics/FlemingAnalytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { getStringQuery } from "shared/RoutesHelper";

export const useSwitchingOrgPage: () => {
    orgId: string | undefined;
    shareId: string | undefined;
    switchingOrgId: string | null;
    organisationName: string;
    analyticsLoggedInProps: FlemingLoggedInCustomProperties;
} = () => {
    const { orgId } = useCurrentWorkspace();
    const { shareId }: { shareId?: string } = useParams();
    const location = useLocation();
    const switchingOrgId = getStringQuery(location.search, "switchingOrgId");
    const { user } = useCurrentUser();

    const organisationName = OrganisationHelper.getOrganisationNameForId(
        user.organisations,
        switchingOrgId ?? "",
    );
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    useEffect(() => {
        trackSwitchingOrgPage({
            ...analyticsLoggedInProps,
            shareId: shareId ?? "",
            newOrganisationId: switchingOrgId ?? "",
        });
    }, [analyticsLoggedInProps, shareId, switchingOrgId]);

    return {
        orgId: orgId.toString(),
        shareId,
        switchingOrgId,
        organisationName,
        analyticsLoggedInProps,
    };
};
