import { SelfBookLink } from "domains/message-component/reducer.types";

const humanReadableUnit: Record<
    SelfBookLink["appointmentAvailabilityPeriod"]["units"],
    string
> = {
    days: "day",
    weeks: "week",
};

export const getAppointmentAvailabilityPeriodDisplay = (
    availabilityPeriod: SelfBookLink["appointmentAvailabilityPeriod"],
) => {
    let humanReadableUnits = humanReadableUnit[availabilityPeriod.units];
    if (availabilityPeriod.value > 1) {
        humanReadableUnits += "s";
    }
    return `within ${availabilityPeriod.value} ${humanReadableUnits} of message send`;
};
