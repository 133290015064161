import { useEffect } from "react";

import { useAnalytics } from "@accurx/analytics";
import { Ds } from "@accurx/design";
import { useNavigationOrigin } from "@accurx/navigation";

import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { RecordViewOptIn } from "app/practices/recordView/RecordViewOptIn";

export const RecordViewOptInPage = () => {
    const track = useAnalytics();
    const navigationOrigin = useNavigationOrigin();

    useEffect(() => {
        track("RecordViewOptIn Page View", { navigationOrigin });
    }, [track, navigationOrigin]);

    return (
        <StyledContainer>
            <PageHeader
                title="Get started with Record View"
                type={PageHeaderType.ListPage}
            />
            <Ds.Text mb="2">
                Learn more about Record View and its benefits to your practice
                and your patients
            </Ds.Text>
            <RecordViewOptIn hideHeader />
        </StyledContainer>
    );
};
