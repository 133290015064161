import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ConnectionState, useRealtimeForSignalR } from "@accurx/realtime";
import { Log, httpClient } from "@accurx/shared";
import isBefore from "date-fns/isBefore";
import { v4 as uuidv4 } from "uuid";

import { FetchError, PollerOptions, Start, Teardown } from "./poller.types";

export const useOfflinePoller = (
    options: PollerOptions,
): { start: Start; end: Teardown } => {
    const { connectionState } = useRealtimeForSignalR();

    const [pollerId] = useState(() => uuidv4());
    const [isInitialFetch, setIsInitialFetch] = useState<boolean>(false);
    const isInitialized = useRef<boolean>(false);
    const isPolling = useRef<boolean>(false);
    const isLive = useRef<boolean>(true);
    const isUnauthorized = useRef<boolean>(false);
    const intervalHandle = useRef<ReturnType<typeof setTimeout> | null>(null);

    /**
     * This value is set to the last time SignalR connected,
     * or if it is disconnected, null. This allows us to know,
     * when our fetchFn completes, whether we have had a consistent
     * SignalR connection for the whole duration of the request.
     */
    const connectedSince = useRef<Date | null>(null);

    const getMeta = useCallback(
        () => ({
            initialized: isInitialized.current,
            polling: isPolling.current,
            connected: !!connectedSince.current,
            refreshRate: options.refreshRate,
            online: navigator.onLine,
            unauthorized: isUnauthorized.current,
        }),
        [options.refreshRate],
    );

    const syncPollingState = useCallback(
        (state: ConnectionState) => {
            connectedSince.current = state === "Connected" ? new Date() : null;

            // Enable polling if we just entered a disconnected state
            if (state !== "Connected") {
                isPolling.current = true;
                const meta = getMeta();

                Log.debug(`${options.name}: enabling polling`, {
                    tags: { product: "Inbox", ...meta },
                });
                options.onPollingStateChange &&
                    options.onPollingStateChange(meta);
            }
        },
        [getMeta, options],
    );

    const performFetch = useCallback(async () => {
        if (!isLive.current) return;

        const meta = getMeta();
        if (!isInitialized.current && options.skipInitialFetch) {
            Log.debug(`${options.name}: skipping initial fetch`, {
                tags: { product: "Inbox", ...meta },
            });
            isInitialized.current = true;
            return;
        }

        // Track the time the fetch started at. There's a small chance
        // SignalR could disconnect and then reconnect while the fetch is
        // happening (especially with large requests like initialunreaditems).
        // So we need to know not just that SignalR is connected when the fetch
        // completes, but that it has been connected for the whole lifetime
        // of the request.
        //
        // All of this unfortunately doesn't and can't take into account lag
        // on the backend between things happening and SignalR events being pushed.
        const fetchStartedAt = new Date();

        const disablePolling = () => {
            Log.debug(`${options.name}: disabling polling`, {
                tags: { product: "Inbox", ...meta },
            });
            isPolling.current = false;
            options.onPollingStateChange &&
                options.onPollingStateChange({
                    ...meta,
                    polling: isPolling.current,
                });
        };

        // Try to fetch data:
        // - If the call is successful and we are now back in a connected state then disable polling.
        // - If the call is successful and we're still disconnected then keep polling enabled.
        // - If the call was unsuccessful then keep polling enabled
        try {
            Log.debug(`${options.name}: fetch start`, {
                tags: { product: "Inbox", ...meta },
            });
            options.onFetchStart && options.onFetchStart(meta);

            await options.fetchFn({
                isInitialFetch: !isInitialized.current,
                pollerId,
            });

            Log.debug(`${options.name}: fetch success`, {
                tags: { product: "Inbox", ...meta },
            });
            options.onFetchSuccess && options.onFetchSuccess(meta);

            // If we are back in a connected state,
            // and have been since before the fetchFn began,
            // then we can switch off polling.
            if (
                connectedSince.current &&
                isBefore(connectedSince.current, fetchStartedAt)
            ) {
                disablePolling();
            }
        } catch (error: unknown) {
            const e = error as FetchError;

            switch (e.statusCode) {
                case 403:
                case 404:
                    Log.debug(`${options.name}: ${e.statusCode} client error`, {
                        tags: {
                            product: "Inbox",
                            statusCode: e.statusCode,
                            ...meta,
                        },
                        originalException: e,
                    });
                    disablePolling();
                    break;
                case 401:
                    Log.debug(`${options.name}: 401 unauthorized received`, {
                        tags: {
                            product: "Inbox",
                            statusCode: e.statusCode,
                            ...meta,
                        },
                        originalException: e,
                    });
                    isPolling.current = true;
                    break;
                default:
                    Log.error(`${options.name}: fetch failed`, {
                        tags: {
                            product: "Inbox",
                            statusCode: e.statusCode,
                            ...meta,
                        },
                        originalException: e,
                    });
                    isPolling.current = true;
            }
            options.onFetchError && options.onFetchError(e, meta);
        } finally {
            isInitialized.current = true;
        }
    }, [getMeta, options, pollerId]);

    const setupNextRefreshTick = useCallback(() => {
        if (!isLive.current) return;

        const onRefreshTick = async () => {
            const meta = getMeta();

            Log.debug(`${options.name}: refresh interval`, {
                tags: { product: "Inbox", ...meta },
            });
            options.onRefreshInterval && options.onRefreshInterval(meta);

            if (isPolling.current) {
                await performFetch();
            }
            setupNextRefreshTick();
        };

        intervalHandle.current = setTimeout(
            () => void onRefreshTick(),
            options.refreshRate,
        );
    }, [getMeta, options, performFetch]);

    /**
     * Subscribe to connection status changes and synchronise internal state
     * so that we can keep track of whether we should be polling on the next
     * refresh interval.
     */
    useEffect(() => {
        if (connectionState !== "Initialising") {
            syncPollingState(connectionState);
        }
    }, [connectionState, syncPollingState]);

    useEffect(() => {
        const fetch = async () => {
            await performFetch();
            setupNextRefreshTick();
        };

        if (isInitialFetch && connectionState !== "Initialising") {
            void fetch();
            setIsInitialFetch(false);
        }
    }, [isInitialFetch, connectionState, performFetch, setupNextRefreshTick]);

    const unauthorizedHandle = httpClient.unauthorizedObservable.subscribe(
        () => {
            isUnauthorized.current = true;
        },
    );
    // Store this in a ref so that we don't need to re-trigger the useMemo dependency array
    const unauthorizedHandleRef = useRef(unauthorizedHandle);
    unauthorizedHandleRef.current = unauthorizedHandle;

    return useMemo(
        () => ({
            start: () => {
                isLive.current = true;
                setIsInitialFetch(true);
            },
            end: () => {
                isLive.current = false;
                if (intervalHandle.current) {
                    clearTimeout(intervalHandle.current);
                }
                unauthorizedHandleRef.current.unsubscribe();
            },
        }),
        [],
    );
};
