import { useEffect } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useCurrentWorkspace } from "@accurx/auth";
import { Text } from "@accurx/design";
import { useNavigationOrigin } from "@accurx/navigation";

import { DeliveryReceipts } from "../../components/DeliveryReceipts";
import { PageContainer } from "../OverviewPage/components/PageContainer.styles";

/**
 * This page is purposely designed for the new unified navigation
 */
export const SentRemindersPage = () => {
    const track = useAnalytics();
    const navigationOrigin = useNavigationOrigin();
    const { orgId: workspaceId } = useCurrentWorkspace();

    useEffect(() => {
        track("ReminderSent Page View", { navigationOrigin });
    }, [track, navigationOrigin]);

    return (
        <PageContainer>
            <Text as="h1" variant="subtitle" skinny>
                Sent reminders
            </Text>
            <DeliveryReceipts workspaceId={String(workspaceId)} />
        </PageContainer>
    );
};
