import { HubClient } from "@accurx/realtime/hubClient/HubClient";
import { Log } from "@accurx/shared";
import { QueryClient } from "@tanstack/react-query";
import isEqual from "lodash/isEqual";
import keyBy from "lodash/keyBy";
import without from "lodash/without";
import { BehaviorSubject, Observable, Unsubscribable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

import { fromReactQuery } from "api/QueryClient/rxjs/fromReactQuery";
import { SubscriptionResult } from "shared/concierge/types/subscription.types";

import { fetchInitialSummaryDetails } from "./UsersAndTeamsApiClient";
import {
    subscribeToTeamUpdatesFromTicketUpdates,
    subscribeToUserTeamChangesFromTicketUpdates,
} from "./UsersAndTeamsSignalRClient";
import { mapInitialSummaryDetailsToUsersAndTeams } from "./mappers/ApiResponseMapper";
import { mapQueryObserverResultToSubscriptionResult } from "./mappers/ObserverResultMapper";
import {
    TeamId,
    TeamMembershipFeed,
    TeamSummary,
    TeamUpdate,
    UserSummary,
    UsersAndTeamsManagerConstructorArgs,
    UsersAndTeamsManager as UsersAndTeamsManagerType,
    UsersAndTeamsSummaries,
    UsersAndTeamsWithMembershipSummaries,
} from "./types/usersAndTeams.types";

/**
 * # UsersAndTeamsManager
 *
 * This class is responsible for managing realtime updates to Users and Teams.
 *
 * ## Cache management
 * `UsersAndTeamsManager` uses ReactQuery for cache management. Clients should
 * not request users and teams data directly from this manager. Instead they can
 * get data from ReactQuery using the cache key exposed from
 * `UsersAndTeamsManager`.
 *
 * ## Outbound updates feed
 * `UsersAndTeamsManager` exposes an RxJS observable that emits updates when the
 * list of teams, of which the current user is a member, changes.
 *
 * @example
 * Using the manager to create a ReactQuery hook.
 * ```
 * const manager = new UsersAndTeamsManager({
 *   workspaceId: 3,
 *   currentUserId: "56",
 *   queryClient: getOrCreateQueryClient()
 * });
 *
 * const useUsersAndTeams = () => {
 *     return useQuery(
 *         manager.cacheKey,
 *         () => manager.fetchUserTeamSummaries(),
 *     );
 * };
 * ```
 */
export class UsersAndTeamsManager implements UsersAndTeamsManagerType {
    public readonly workspaceId: number;

    private readonly hubClient: HubClient;

    private readonly queryClient: QueryClient;

    private readonly currentUserId: string;

    /**
     * The outbound feed emits events when the list of teams the current user is
     * a member of changes. It's an RxJS BehaviourSubject internally so that we
     * can push events into it. Publically it's exposed as a readonly
     * observable.
     *
     * Accessed via: manager.currentUserTeamMembershipFeed
     */
    private readonly outboundCurrentUserTeamMembershipFeed =
        new BehaviorSubject<SubscriptionResult<TeamId[]>>({
            status: "LOADING",
            errorMessage: null,
            data: null,
        });

    /**
     * It's important we unsubscribe from RxJS handles when the manager
     * is torn down. We store them all here so that we can unsubscribe
     * from them later.
     */
    private readonly subscriptionHandles: Unsubscribable[] = [];

    constructor({
        workspaceId,
        currentUserId,
        hubClient,
        queryClient,
    }: UsersAndTeamsManagerConstructorArgs) {
        this.currentUserId = currentUserId;
        this.workspaceId = workspaceId;
        this.hubClient = hubClient;
        this.queryClient = queryClient;

        this.processUsersAndTeamsUpdates =
            this.processUsersAndTeamsUpdates.bind(this);
        this.processTeamMembershipUpdates =
            this.processTeamMembershipUpdates.bind(this);

        this.fetchDataAndSubscribeToUpdates();
        this.subscribeToTicketUpdates();
        this.subscribeToUserGroupUpdates();
    }

    /**
     * Emits events when the list of teams the current user is a member of
     * changes.
     */
    public get currentUserTeamMembershipFeed(): TeamMembershipFeed {
        return this.outboundCurrentUserTeamMembershipFeed;
    }

    /**
     * The cache key where ReactQuery stores all data for users and teams.
     */
    public get cacheKey(): string[] {
        return [
            "inboxActiveUserAndTeamSummaries",
            `workspace-${this.workspaceId}`,
        ];
    }

    public teardown(): void {
        this.subscriptionHandles.forEach((handle) => handle.unsubscribe());
    }

    public fetchUserTeamSummaries(): Promise<UsersAndTeamsWithMembershipSummaries> {
        return fetchInitialSummaryDetails(this.workspaceId).then(
            mapInitialSummaryDetailsToUsersAndTeams,
        );
    }

    /**
     * Allows you to connect a feed of user/team updates from an external source.
     * We, for example, want to feed in updates from API responses that contain
     * referenced user or team objects.
     */
    public connectFeed(
        feed: Observable<UsersAndTeamsSummaries>,
    ): Unsubscribable {
        const handle = feed.subscribe(this.processUsersAndTeamsUpdates);

        this.subscriptionHandles.push(handle);

        return handle;
    }

    private fetchDataAndSubscribeToUpdates() {
        const callback = fromReactQuery(this.queryClient, {
            queryKey: this.cacheKey,
            queryFn: () => this.fetchUserTeamSummaries(),
        });

        const handle = callback
            .pipe(
                map(mapQueryObserverResultToSubscriptionResult),
                map(getCurrentUserTeamMembershipResult),
                distinctUntilChanged(isEqual),
            )
            .subscribe(this.outboundCurrentUserTeamMembershipFeed);

        this.subscriptionHandles.push(handle);
    }

    private subscribeToTicketUpdates() {
        const feed = subscribeToUserTeamChangesFromTicketUpdates(
            this.hubClient,
            this.workspaceId,
        );
        this.connectFeed(feed);
    }

    private subscribeToUserGroupUpdates() {
        const observable = subscribeToTeamUpdatesFromTicketUpdates(
            this.hubClient,
            this.workspaceId,
            this.currentUserId,
        );

        const handle = observable.subscribe(this.processTeamMembershipUpdates);

        this.subscriptionHandles.push(handle);
    }

    private processUsersAndTeamsUpdates(update: UsersAndTeamsSummaries) {
        const cache = this.queryClient.getQueryData<
            UsersAndTeamsWithMembershipSummaries | undefined
        >(this.cacheKey);
        const queryState = this.queryClient.getQueryState(this.cacheKey);

        if (!cache) {
            if (queryState?.status === "loading") {
                this.logError(
                    "An update to users and teams was lost because it was received before the cache was hydrated",
                );
            }
            return;
        }

        const usersById = keyBy(cache.users, "id");
        const teamsById = keyBy(cache.teams, "id");

        const userUpdates = new Map<string, UserSummary>();
        const teamUpdates = new Map<string, TeamSummary>();

        update.users?.forEach((user) => {
            if (!isEqual(user, usersById[user.id])) {
                userUpdates.set(user.id, user);
            }
        });

        update.teams?.forEach((team) => {
            if (!isEqual(team, teamsById[team.id])) {
                teamUpdates.set(team.id, team);
            }
        });

        // Here we do some performance enhancements i.e.
        // do a deep equality check on users and teams and only update the cache
        // if any values have actually changed. This prevents wasted rerenders.
        if (userUpdates.size === 0 && teamUpdates.size === 0) {
            return;
        }

        let users = cache.users;
        let teams = cache.teams;

        if (userUpdates.size) {
            users = replaceItemsById(users, userUpdates);
        }

        if (teamUpdates.size) {
            teams = replaceItemsById(teams, teamUpdates);
        }

        this.queryClient.setQueryData<UsersAndTeamsWithMembershipSummaries>(
            this.cacheKey,
            { ...cache, users, teams },
        );
    }

    private processTeamMembershipUpdates(update: TeamUpdate) {
        const cache = this.queryClient.getQueryData<
            UsersAndTeamsWithMembershipSummaries | undefined
        >(this.cacheKey);
        const queryState = this.queryClient.getQueryState(this.cacheKey);

        if (!cache) {
            if (queryState?.status === "loading") {
                this.logError(
                    "An update to team memberships was lost because it was received before the cache was hydrated",
                );
            }
            return;
        }

        const team = update.team;
        const existingTeam = cache.teams.find((t) => t.id === team.id);
        const newCurrentUserTeamIds = updateCurrentUserTeamIds(
            cache.currentUserTeamIds,
            update,
        );

        if (
            isEqual(existingTeam, team) &&
            isEqual(cache.currentUserTeamIds, newCurrentUserTeamIds)
        ) {
            return;
        }

        const newTeams = replaceItemsById(
            cache.teams,
            new Map<TeamId, TeamSummary>([[team.id, team]]),
        );

        this.queryClient.setQueryData<UsersAndTeamsWithMembershipSummaries>(
            this.cacheKey,
            {
                users: cache.users,
                teams: newTeams,
                currentUserTeamIds: newCurrentUserTeamIds,
            },
        );
    }

    private logError(message: string): void {
        Log.error(message, {
            tags: {
                product: "WebInbox 2.0",
                domain: "Users and Teams Manager",
            },
        });
    }
}

export const createUsersAndTeamsManager = (
    args: UsersAndTeamsManagerConstructorArgs,
): UsersAndTeamsManagerType => {
    return new UsersAndTeamsManager(args);
};

/**
 * Accepts two lists A & B and overwrites items in list A
 * with any item in list B with a matching ID.
 *
 * This allows us to combine two lists but maintain the
 * order of existing items in the original list.
 *
 * Any items in B that aren't matched with an existing item
 * in A are appended at the end of the returned list.
 *
 * @param currentList
 * @param newItems
 * @returns a merged list
 */
const replaceItemsById = <T extends { id: string }>(
    currentList: T[],
    newItems: Map<string, T>,
): T[] => {
    const replacedList = currentList.map((item) => {
        const newItem = newItems.get(item.id);
        if (newItem) {
            newItems.delete(item.id);
            return newItem;
        }
        return item;
    });

    return [...replacedList, ...Array.from(newItems.values())];
};

/**
 * To calculate unread counts in the Inbox we need to know which teams the
 * current user is a member of. This takes a subscription result that contains
 * all updates to users and teams and condenses it down to a set of team IDs
 * that the current user is a member of.
 */
const getCurrentUserTeamMembershipResult = (
    update: SubscriptionResult<UsersAndTeamsWithMembershipSummaries>,
): SubscriptionResult<TeamId[]> => {
    if (update.status !== "SUCCESS") {
        return update;
    }

    return {
        ...update,
        data: update.data.currentUserTeamIds,
    };
};

const updateCurrentUserTeamIds = (
    currentList: TeamId[],
    update: TeamUpdate,
): TeamId[] => {
    if (update.currentUserIsMember && !currentList.includes(update.team.id)) {
        return [...currentList, update.team.id];
    }

    if (!update.currentUserIsMember && currentList.includes(update.team.id)) {
        return without(currentList, update.team.id);
    }

    return currentList;
};
