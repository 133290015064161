import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useCurrentUser } from "@accurx/auth";
import { useAllAssignees } from "@accurx/concierge/hooks/data/useAllAssignees";
import { useAllUsers } from "@accurx/concierge/hooks/data/useAllUsers";
import { Popover, PopoverTrigger } from "@accurx/design";
import {
    AssigneeSelectorForm,
    OnlineUsersContextProvider,
} from "@accurx/inbox";
import { useHasQuestionnaire } from "@accurx/message-component";
import { useNativeTrackingFields } from "@accurx/native";

import { StyledPopoverContent } from "./AssigneeSelector.styles";
import { Assignee } from "./AssigneeSelector.types";
import { AssigneeSelectorButton } from "./AssigneeSelectorButton";
import { getAssigneeTrackingFields } from "./getAssigneeTrackingFields";

export const AssigneeSelector = ({
    currentAssignee,
    onAssigneeSelected,
}: {
    currentAssignee: Assignee;
    onAssigneeSelected: (assignee: Assignee) => void;
}) => {
    const { user } = useCurrentUser();
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();

    const [isOpen, setIsOpen] = useState(false);
    const onlyShowTeams = useHasQuestionnaire();

    const onSubmit = (assignee: Assignee) => {
        onAssigneeSelected(assignee);
        setIsOpen(false);
    };

    const allUsers = useAllUsers();
    const allAssignees = useAllAssignees();
    const teams = allAssignees.teams
        .filter((team) => team.type !== "ToAssignClinicianMessaging")
        .filter((t) => t.canBeAssignedTo);

    return (
        <OnlineUsersContextProvider>
            <Popover open={isOpen} onOpenChange={setIsOpen}>
                <PopoverTrigger asChild={true}>
                    <AssigneeSelectorButton
                        assignee={currentAssignee}
                        onClick={() =>
                            track("ConversationAssignSelect Button Click", {
                                ...nativeTrackingFields,
                                origin: "/compose",
                            })
                        }
                    />
                </PopoverTrigger>
                <StyledPopoverContent
                    side="top"
                    align="end"
                    collisionPadding={4}
                >
                    <AssigneeSelectorForm
                        users={onlyShowTeams ? [] : allUsers}
                        teams={teams}
                        currentAssignee={currentAssignee}
                        canAddNote={false}
                        onCancel={() => setIsOpen(false)}
                        onSubmit={({ assignee }) => {
                            onSubmit(assignee);
                        }}
                        onSelectedAssigneeChanged={({ assignee }) => {
                            onSubmit(assignee);

                            const assigneeTrackingFields =
                                getAssigneeTrackingFields({
                                    userId: user.accuRxUserId,
                                    assignee,
                                    teams,
                                });

                            track("ConversationAssignSelect MenuItem Click", {
                                ...nativeTrackingFields,
                                ...assigneeTrackingFields,
                                origin: "/compose",
                            });
                        }}
                    />
                </StyledPopoverContent>
            </Popover>
        </OnlineUsersContextProvider>
    );
};
