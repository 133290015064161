import { useEffect, useRef } from "react";

import { EventPayload, SignalRSubscriptionEvent } from "@accurx/realtime";
import { Log } from "@accurx/shared";
import { mapConversationItemIdToTicketItemIdentity } from "domains/concierge/internal/api/shared/mappers/mapConversationItemIdToTicketItemIdentity";
import { useConciergeSelector } from "domains/concierge/internal/context";
import { useFetchTicketUnreadItems } from "domains/concierge/internal/hooks/useFetchTicketUnreadItems";
import { useSignalRSubscription } from "domains/concierge/internal/hooks/useSignalRSubscription";
import { useUnreadItemsOnReconnect } from "domains/concierge/internal/hooks/useUnreadItemsOnReconnect";
import { ConciergeState } from "domains/concierge/internal/types/ConciergeState";
import { UnreadItemsSummary } from "domains/concierge/schemas/UnreadItemsSummarySchema";

export const REFRESH_RATE = 1_800_000; // 30 minutes

const areUnreadItemsStale = (
    payload: EventPayload<SignalRSubscriptionEvent.OnLatestHubConnectInbox>,
    unreadItemsState: ConciergeState["conversations"]["unreadItems"],
    currentUserId: string,
): boolean => {
    const isValidUnreadItem = ({
        system,
        status,
        assignee,
    }: UnreadItemsSummary): boolean => {
        if (system !== "Ticket") {
            return false;
        }
        if (status !== "Open") {
            return false;
        }
        if (assignee.type === "User" && assignee.id !== currentUserId) {
            return false;
        }

        return true;
    };

    // Get a set of all the unread item IDs the client has
    const clientUnreadItemIds = new Set<string>([]);
    for (const id in unreadItemsState) {
        const summary = unreadItemsState[id];

        if (isValidUnreadItem(summary)) {
            summary.itemIds.forEach((itemId) => {
                // Item IDs come as ti-X-Y but the IDs from the event come as just Y
                const ticketItem =
                    mapConversationItemIdToTicketItemIdentity(itemId);

                if (ticketItem) {
                    clientUnreadItemIds.add(ticketItem.id);
                }
            });
        }
    }

    const serverUnreadItemIds = payload.unreadPatientThreadNoteIds ?? [];

    for (const itemId of serverUnreadItemIds) {
        // The client does not have an unread item from the server
        if (!clientUnreadItemIds.has(itemId)) {
            return true;
        }

        clientUnreadItemIds.delete(itemId);
    }

    // The client has some unread items that should not be unread
    if (clientUnreadItemIds.size > 0) {
        return true;
    }

    return false;
};

export const useTicketUnreadItemsPoller = () => {
    const fetchTicketUnreadItems = useFetchTicketUnreadItems();
    const conciergeUnreadCountsQueryStatus = useConciergeSelector(
        (state) => state.queries.ticketUnreads.status,
    );

    // If this is true then we need to refresh the unread items
    const shouldRefreshUnreadItems = useRef(false);
    const unreadCountsqueryStatus = useRef(conciergeUnreadCountsQueryStatus);
    unreadCountsqueryStatus.current = conciergeUnreadCountsQueryStatus;

    // When reconnected to SignalR, register for a reconnection push
    useUnreadItemsOnReconnect();

    useSignalRSubscription(
        ({ subscribe, workspaceId, getState, currentUserId }) => {
            const subscription = subscribe({
                type: SignalRSubscriptionEvent.OnLatestHubConnectInbox,
                eventFilter: (payload) =>
                    payload.organisationId === workspaceId,
                onEvent: (payload) => {
                    const currentUnreadItems =
                        getState().conversations.unreadItems;
                    const isStale = areUnreadItemsStale(
                        payload,
                        currentUnreadItems,
                        currentUserId,
                    );
                    /**
                     * If the client and server have gone out of sync
                     * ensure we refresh on the next interval
                     */
                    if (isStale) {
                        shouldRefreshUnreadItems.current = true;
                    }
                    Log.info("SignalR reconnect: received unread items", {
                        tags: { shouldRefeshUnreads: isStale },
                    });
                },
            });

            return subscription.unsubscribe;
        },
    );

    useEffect(() => {
        // Fetch unread items on initial mount
        void fetchTicketUnreadItems({ isInitialFetch: true });

        // Check every 30 minutes to see if we need to refetch unread items
        const fetchInterval = setInterval((): void => {
            /**
             * Only refetch if fetching unread counts previously failed
             * or if unread items are out of sync
             */
            if (
                unreadCountsqueryStatus.current === "error" ||
                shouldRefreshUnreadItems.current
            ) {
                Log.debug(`TicketUnreadItemsPoller: refetching unread items`, {
                    tags: {
                        product: "Inbox",
                        refreshRate: REFRESH_RATE,
                        fetchPreviouslyFailed:
                            unreadCountsqueryStatus.current === "error",
                        wasOutOfSync: shouldRefreshUnreadItems.current,
                    },
                });

                void fetchTicketUnreadItems({
                    isInitialFetch: false,
                    options: {
                        onSuccess: () => {
                            shouldRefreshUnreadItems.current = false;
                        },
                    },
                });
            }
        }, REFRESH_RATE);

        return () => {
            clearInterval(fetchInterval);
        };
    }, [fetchTicketUnreadItems]);
};
