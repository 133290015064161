import { NativeApi } from "@accurx/native";
import {
    PatientsSearchResult,
    PatientsSearchResultSchema,
} from "domains/navigation/schemas/PatientSearchResultSchema";

import { createEndpoint } from "../createEndpoint";

export type SearchPatientsQueryPayload = {
    searchTerm?: string;
    nhsNumber?: string;
    firstName?: string;
    lastName?: string;
    dateOfBirth?: string;
};

export const searchPatientsQuery = createEndpoint<
    PatientsSearchResult,
    SearchPatientsQueryPayload
>("QuerySearchPatients", async (context, payload) => {
    const result = await NativeApi.request({
        transport: context.transport,
        request: {
            type: "QuerySearchPatients",
            workspaceId: context.workspaceId,
            payload: payload,
        },
    });
    return PatientsSearchResultSchema.parse(result);
});
