import { PrincipalSystemDto, WorkspaceStatus } from "@accurx/api/account";
import {
    FeatureName,
    Organisation,
    User,
    isFeatureEnabledForWorkspace,
} from "@accurx/auth";
import { DateHelpers } from "@accurx/shared";

const PHARMACY_ROLE_CODE = "RO182";
const NHS_PHARMACY_NATIONAL_CODE = "X31";

const SupportedIntegratedSystems = [
    PrincipalSystemDto.Emis,
    PrincipalSystemDto.SystmOne,
    PrincipalSystemDto.Vision,
];

export const isPharmacy = (organisation: Organisation) =>
    organisation.roleCode === PHARMACY_ROLE_CODE;

export const isNhsPharmacy = (workspace: Organisation) =>
    workspace.nationalCode === NHS_PHARMACY_NATIONAL_CODE;

export const supportsMultipleWorkspaces = (organisation: Organisation) =>
    isFeatureEnabledForWorkspace(FeatureName.FlexibleWorkspace, organisation);

export const isMyWorkspace = (organisation: Organisation) =>
    organisation.status === WorkspaceStatus.Deprecated &&
    supportsMultipleWorkspaces(organisation);

export const findFirstPharmacy = (organisations: Organisation[]) =>
    organisations.find(
        (workspace) => isPharmacy(workspace) && !isNhsPharmacy(workspace),
    );

export const findNhsPharmacy = (organisations: Organisation[]) =>
    organisations.find((workspace) => isNhsPharmacy(workspace));

export const isActiveWorkspace = (workspace: Organisation) =>
    workspace.status === WorkspaceStatus.Active;

export const isUserCreatedWorkspace = (workspace: Organisation) =>
    isActiveWorkspace(workspace) && supportsMultipleWorkspaces(workspace);

export const isArchivedWorkspace = (workspace: Organisation) =>
    workspace.status === WorkspaceStatus.Archived;

export const isUserOnlyInDefaultWorkspaceWithinOrg = ({
    user,
    organisation,
}: {
    user: User;
    organisation: Organisation;
}): boolean => {
    if (isUserCreatedWorkspace(organisation)) return false;

    return !user.organisations.some((item) => {
        const isWithinTheSameOrg =
            item.nationalCode === organisation.nationalCode;

        return isWithinTheSameOrg && isUserCreatedWorkspace(item);
    });
};

export const hasAccurxInstalled = (workspace: Organisation) => {
    return (
        workspace.latestIntegratedSystem &&
        SupportedIntegratedSystems.includes(workspace.latestIntegratedSystem)
    );
};

export const isArchivedMoreThanMonthAgo = (workspace: Organisation) => {
    const { statusLastUpdatedAt, status } = workspace;

    const archivingTime = statusLastUpdatedAt
        ? new Date(statusLastUpdatedAt).toISOString()
        : null;

    const today = new Date().toISOString();

    const daysAfterArchiving =
        archivingTime && status === WorkspaceStatus.Archived
            ? DateHelpers.getNumberOfDaysBetweenTwoDates(archivingTime, today)
            : 0;
    return daysAfterArchiving > 30;
};
