import { useAnalytics } from "@accurx/analytics";
import { useTeam } from "@accurx/concierge/hooks/data/useTeam";
import { useUser } from "@accurx/concierge/hooks/data/useUser";
import { useUpdateAssigneeMutation } from "@accurx/concierge/hooks/mutations/useUpdateAssigneeMutation";
import { AssigneeSummary, Conversation } from "@accurx/concierge/types";
import { Ds, Feedback } from "@accurx/design";
import { useNativeTrackingFields } from "@accurx/native";
import { Link } from "domains/inbox/components/Link/Link";
import { useConversationParticipant } from "domains/inbox/hooks/useConversationParticipant";
import { useConversationLocation } from "domains/inbox/hooks/util/useConversationLocation";
import { useConversationActionTrackingFields } from "domains/inbox/hooks/util/useConversatonActionAnalytics";
import {
    LocationObject,
    useInboxLink,
} from "domains/inbox/hooks/util/useInboxLink";
import { i } from "images";
import noop from "lodash/noop";
import { toast } from "react-toastify";

import { StyledFlex, StyledText } from "./ConversationAssignedOverlay.styles";

type ConversationAssignedOverlayProps = {
    conversation: Conversation;
    newAssignee: AssigneeSummary;
    onUndoSuccess?: () => void;
    previousAssignee: AssigneeSummary;
};

export const ConversationAssignedOverlay = ({
    conversation,
    newAssignee,
    onUndoSuccess = noop,
    previousAssignee,
}: ConversationAssignedOverlayProps) => {
    const newAssigneeId = newAssignee.type === "None" ? "" : newAssignee.id;
    const user = useUser({ userId: newAssigneeId });
    const team = useTeam({ teamId: newAssigneeId });
    const { mutate: updateAssignee, status } = useUpdateAssigneeMutation();
    const conversationParticipant = useConversationParticipant();
    const link = useInboxLink();

    const track = useAnalytics();
    const getTrackingFields = useConversationActionTrackingFields(conversation);
    const nativeTrackingFields = useNativeTrackingFields();

    const conversationLocation = useConversationLocation({
        conversationId: conversation.id,
    });

    const getAssignActionText = (): string | undefined => {
        switch (newAssignee.type) {
            case "User": {
                if (user?.isCurrentUser) return "Conversation assigned to you.";

                if (user?.displayName) {
                    return `Conversation assigned to ${user.displayName}.`;
                }

                return "Conversation assigned.";
            }
            case "Team": {
                if (team?.displayName) {
                    return team.displayName === "Unassigned"
                        ? "Conversation unassigned."
                        : `Conversation assigned to ${team.displayName}.`;
                }

                return "Conversation assigned.";
            }
            case "None":
                return;
        }
    };

    const handleClickUndo = () => {
        track("ConversationAction Button Click", {
            ...getTrackingFields({
                appOrigin: "ConversationAssigned",
                type: "Assign",
            }),
        });

        updateAssignee(
            { conversation, assignee: previousAssignee },
            {
                onSuccess: onUndoSuccess,
                onError: () => {
                    toast(
                        <Feedback
                            title="Couldn't undo, please try again"
                            colour="error"
                        />,
                    );
                },
                onSettled: (_, error) => {
                    track("ConversationAction Button Response", {
                        ...getTrackingFields({
                            appOrigin: "ConversationAssigned",
                            type: "Assign",
                        }),
                        hasError: Boolean(error),
                    });
                },
            },
        );
    };

    const getLinkText = (): string => {
        if (newAssignee.type === "User") {
            if (conversationParticipant === "WithPatient") {
                return user?.isCurrentUser ? "your inbox" : "Colleagues";
            }
            if (conversationParticipant === "WithHcp") {
                return user?.isCurrentUser ? "your inbox" : "All conversations";
            }
        }

        switch (team?.type) {
            case "Florey":
                return "Questionnaires";
            case "Hub":
                return "Shared";
            default:
                return team?.displayName === "Unassigned"
                    ? "Unassigned"
                    : "Teams";
        }
    };

    const getLinkLocation = (): LocationObject | undefined => {
        if (conversationParticipant === "WithPatient") {
            return getLinkLocationForPatients();
        }

        if (conversationParticipant === "WithHcp") {
            return getLinkLocationForHcps();
        }
    };

    const getLinkLocationForPatients = (): LocationObject | undefined => {
        // If the assignee is a user, navigate to the user's inbox that has just been
        // assigned to, rather than the all conversations filter folder
        if (newAssignee.type !== "User") {
            return conversationLocation;
        }

        if (user?.isCurrentUser) {
            return link.to("MyInbox", {
                conversationId: conversation.id,
                status: conversation.status === "Done" ? "Done" : undefined,
            });
        }

        return link.to("Colleagues", {
            conversationId: conversation.id,
            colleagueId: newAssigneeId,
            status: conversation.status === "Done" ? "Done" : undefined,
        });
    };

    const getLinkLocationForHcps = (): LocationObject | undefined => {
        // If the assignee is a user, navigate to the user's inbox that has just been
        // assigned to, rather than the all conversations filter folder
        if (newAssignee.type !== "User") {
            return conversationLocation;
        }

        if (user?.isCurrentUser) {
            return link.to("CareProvidersMyInbox", {
                conversationId: conversation.id,
                status: conversation.status === "Done" ? "Done" : undefined,
            });
        }

        return link.to("CareProvidersAll", {
            conversationId: conversation.id,
            colleagueId: newAssigneeId,
            status: conversation.status === "Done" ? "Done" : undefined,
        });
    };

    const assignActionText = getAssignActionText();
    const linkLocation = getLinkLocation();
    const linkText = getLinkText();
    const canUndo = previousAssignee.type !== "None";

    const handleClickLink = () => {
        track("ConversationFolder Link Click", {
            ...nativeTrackingFields,
            folderName: getLinkText(),
            teamId: team?.id,
            teamName: team?.displayName,
            conversationParticipant: conversationParticipant ?? "WithPatient",
        });
    };

    return (
        <Ds.Box p="1.5" height="100%">
            <StyledFlex
                flexDirection="column"
                gap="1.5"
                alignItems="center"
                justifyContent="center"
            >
                <img
                    src={i("illustrations/assign-to-colleague-80px.png")}
                    srcSet={`${i(
                        "illustrations/assign-to-colleague-80px.png",
                    )}, ${i(
                        "illustrations/assign-to-colleague-80px@2x.png",
                    )} 2x, ${i(
                        "illustrations/assign-to-colleague-80px@3x.png",
                    )} 3x`}
                    alt="Conversation assigned"
                    height="80"
                    aria-hidden="true"
                />
                {assignActionText && (
                    <StyledText color="metal">
                        {assignActionText}{" "}
                        {linkLocation && (
                            <>
                                View in{" "}
                                <Link
                                    to={linkLocation}
                                    onClick={handleClickLink}
                                >
                                    {linkText}
                                </Link>
                                .
                            </>
                        )}
                    </StyledText>
                )}
                {canUndo && (
                    <Ds.Button
                        appearance="secondary"
                        size="small"
                        aria-label="Undo assigning this conversation"
                        loading={status === "loading"}
                        onClick={handleClickUndo}
                    >
                        Undo
                    </Ds.Button>
                )}
            </StyledFlex>
        </Ds.Box>
    );
};
