import React, { useCallback, useEffect, useState } from "react";

import {
    SearchForPatientByDemographicsRequest,
    SearchForPatientByNhsNumberAndDobRequest,
} from "@accurx/api/portal";
import { Feedback } from "@accurx/design";
import { Log } from "@accurx/shared";
import { useDispatch } from "react-redux";
import { generatePath, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { useSafeAsync } from "api/Api.utils";
import FlemingApi from "api/FlemingApiClient";
import { actionCreators as accountActions } from "app/account/AccountActions";
import { FlemingAnalyticsTracker } from "app/analytics";
import {
    clearTourMode,
    getTourMode,
    isTourEnded,
} from "app/onboarding/Onboarding.helper";
import { actionCreators as searchForPatientActions } from "app/searchForPatient/SearchForPatientActions";
import { SearchForPatientForm } from "app/searchForPatient/searchForPatientForm/SearchForPatientForm";
import { SearchFormErrors } from "app/searchForPatient/searchForPatientForm/SearchFormConstants";
import { actionCreators as selectProductActions } from "app/selectProduct/SelectProductActions";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { useNavigateToWorkspaceRoute } from "app/workspace/hooks";
import { PatientHelper } from "shared/PatientHelper";
import { ROUTES, ROUTES_EXTENSION, ROUTES_PRIMARY } from "shared/Routes";
import { findBaseRoute, getStringQuery } from "shared/RoutesHelper";
import { useAppSelector, useCurrentOrgId, useCurrentUserId } from "store/hooks";

export type PatientSearchCardProps = {
    // This will be stored in the redux state and used for navigational purposes
    patientSearchOrigin: string;
};

export const PatientSearchCard = ({
    patientSearchOrigin,
}: PatientSearchCardProps): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();
    const safeAsync = useSafeAsync();
    const { navigateToWorkspaceRoute } = useNavigateToWorkspaceRoute();

    const [searchServerError, setSearchServerError] = useState("");
    const [initialNhsNumber, setInitialNhsNumber] = useState("");

    const accuRxUserId = useCurrentUserId() || "";
    const selectedOrganisation = useCurrentOrgId();
    const userOrganisationIds = useAppSelector(
        ({ account }) => account.user?.organisations?.map((o) => o.orgId) ?? [],
    );
    const { setSelectedOrgId } = useSelectedOrganisation();

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const searchForPatientByNhsNumber = useCallback(
        async (patientSearchInfo: SearchForPatientByNhsNumberAndDobRequest) => {
            if (!patientSearchInfo.organisationId) {
                Log.error(
                    "Trying to search for patient by NHS number without an organisationId",
                );
                return;
            }

            dispatch(searchForPatientActions.searchForPatientStarted());

            const res = await FlemingApi.searchForPatientByNhsNumber(
                patientSearchInfo.organisationId,
                patientSearchInfo.nhsNumber,
                patientSearchInfo.dateOfBirthYear,
                patientSearchInfo.dateOfBirthMonth,
                patientSearchInfo.dateOfBirthDay,
            );

            // Save to recent search in the background, but don't wait for call to complete
            if (
                PatientHelper.getPatient(res) &&
                !PatientHelper.isDemoPatient(patientSearchInfo.nhsNumber)
            ) {
                FlemingApi.savePatientToRecentSearches({
                    organisationId: patientSearchInfo.organisationId as number,
                    patientToken: res.searchedResult?.patientToken || "",
                }).catch((e) => console.log(e));
            }

            dispatch(searchForPatientActions.searchForPatientFinished(res));

            if (!PatientHelper.getPatient(res)) {
                if (res.searchedResult?.matchFound === false) {
                    setSearchServerError(SearchFormErrors.NoMatchFound);
                } else {
                    setSearchServerError(SearchFormErrors.Other);
                }
            } else {
                const workspaceRouteBase = generatePath(
                    ROUTES_PRIMARY.workspaceBase,
                    {
                        workspaceId: patientSearchInfo.organisationId,
                    },
                );

                history.push(
                    workspaceRouteBase +
                        `${findBaseRoute(history.location.pathname)}${
                            ROUTES_EXTENSION.patientProfile
                        }`,
                );
            }

            FlemingAnalyticsTracker.trackSearchForPatientFormSubmit({
                ...analyticsLoggedInProps,
                patientSearchMethodType: "SearchByNHSNumber",
                hasError: !res.searched,
                patientSearchSuccess: !!res.searchedResult?.matchFound,
                isTestPatient: PatientHelper.isDemoPatient(
                    patientSearchInfo.nhsNumber,
                ),
            });
        },
        [analyticsLoggedInProps, dispatch, history],
    );

    const searchForPatientByDemographics = useCallback(
        async (patientSearchInfo: SearchForPatientByDemographicsRequest) => {
            dispatch(searchForPatientActions.searchForPatientStarted());

            const { result, success } =
                await FlemingApi.searchForPatientByDemographics(
                    patientSearchInfo,
                );

            // Save to recent search in the background, but don't wait for call to complete
            if (PatientHelper.getPatient(result)) {
                FlemingApi.savePatientToRecentSearches({
                    organisationId: patientSearchInfo.organisationId as number,
                    patientToken: result?.searchedResult?.patientToken || "",
                }).catch((e) => console.log(e));
            }

            result &&
                dispatch(
                    searchForPatientActions.searchForPatientFinished(result),
                );

            if (!PatientHelper.getPatient(result)) {
                if (success && result?.searchedResult?.matchFound === false) {
                    setSearchServerError(SearchFormErrors.NoMatchFound);
                } else {
                    setSearchServerError(SearchFormErrors.Other);
                }
            } else {
                navigateToWorkspaceRoute(
                    `${findBaseRoute(history.location.pathname)}${
                        ROUTES_EXTENSION.patientProfile
                    }`,
                );
            }

            FlemingAnalyticsTracker.trackSearchForPatientFormSubmit({
                ...analyticsLoggedInProps,
                patientSearchMethodType: "SearchByName",
                hasError: !result?.searched,
                patientSearchSuccess: !!result?.searchedResult?.matchFound,
                // Users can't use test patient with search by name form
                isTestPatient: false,
            });
        },
        [
            analyticsLoggedInProps,
            dispatch,
            history.location.pathname,
            navigateToWorkspaceRoute,
        ],
    );

    const getNhsNumberAndSet = useCallback(
        async (token: string) => {
            const { success, result } = await safeAsync(
                FlemingApi.postPatientToken({
                    token,
                }),
            );
            if (!success || !result) {
                return;
            }

            const tokenOrganisationId = result.organisationId;
            if (userOrganisationIds.indexOf(tokenOrganisationId) === -1) {
                //  Redirect to join organisation if not a member
                toast(
                    Feedback({
                        colour: "warning",
                        content: "Join your organisation to view the patient",
                    }),
                );
                history.push(ROUTES.joinOrganisation, { showBackButton: true });
                return;
            } else if (tokenOrganisationId !== selectedOrganisation) {
                // switch organisation if necessary before setting nhsNumber
                dispatch(
                    accountActions.selectOrganisation(
                        accuRxUserId,
                        tokenOrganisationId,
                    ),
                );
                setSelectedOrgId(tokenOrganisationId);

                toast(
                    Feedback({
                        colour: "information",
                        content:
                            "You have switched to a different organisation",
                    }),
                );
            }

            if (result.nhsNumber) {
                setInitialNhsNumber(result.nhsNumber);
                dispatch(
                    searchForPatientActions.setEntryPatientNhsNumber(
                        result.nhsNumber,
                    ),
                );
            }

            // Some patientTokens have all the required info so we can submit the form and redirect to "select-product" directly (eg: EPR flow)
            if (
                result.nhsNumber &&
                result.dateOfBirthDay &&
                result.dateOfBirthMonth &&
                result.dateOfBirthYear &&
                result.organisationId
            ) {
                searchForPatientByNhsNumber({
                    nhsNumber: result.nhsNumber,
                    dateOfBirthDay: result.dateOfBirthDay,
                    dateOfBirthMonth: result.dateOfBirthMonth,
                    dateOfBirthYear: result.dateOfBirthYear,
                    organisationId: tokenOrganisationId,
                });
            }
        },
        [
            safeAsync,
            userOrganisationIds,
            selectedOrganisation,
            history,
            dispatch,
            accuRxUserId,
            setSelectedOrgId,
            searchForPatientByNhsNumber,
        ],
    );

    // Get the patient token from the URL if present and pre-fill it into the search
    useEffect(() => {
        const patientToken = getStringQuery(
            history.location.search,
            "patientToken",
        );

        if (patientToken !== null) {
            FlemingAnalyticsTracker.trackSearchForPatientEmailLink(
                analyticsLoggedInProps,
            );
            getNhsNumberAndSet(patientToken);
            const locationWithoutQueryParams = {
                ...history.location,
                search: "",
            };
            history.replace(locationWithoutQueryParams); // Token doesn't remain in browser history
        }
    }, [dispatch, getNhsNumberAndSet, history, analyticsLoggedInProps]);

    // Set the origin of the patient selection to search for patient route
    useEffect(() => {
        dispatch(
            selectProductActions.setPatientSearchOrigin(patientSearchOrigin),
        );
    }, [dispatch, patientSearchOrigin]);

    // This is to handle when a user clicks on the back button - we want to allow this behaviour, therefore in order to avoid the redirect on the render, we reset the product + patient
    useEffect(() => {
        dispatch(selectProductActions.resetProductType());
    }, [dispatch]);

    // Clearing the tour when we return on this page
    useEffect(() => {
        if (isTourEnded(accuRxUserId)) {
            clearTourMode(accuRxUserId);
        }
    }, [accuRxUserId]);

    const resetServerErrors = (): void => {
        setSearchServerError("");
    };

    const tourMode = getTourMode(accuRxUserId);

    return (
        <SearchForPatientForm
            onSubmitNhsNumberForm={searchForPatientByNhsNumber}
            onSubmitNameForm={searchForPatientByDemographics}
            initialNhsNumber={initialNhsNumber}
            serverError={searchServerError}
            resetServerError={resetServerErrors}
            tourMode={tourMode}
        />
    );
};
