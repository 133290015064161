import React from "react";

import { Link } from "@accurx/api/patient-initiated";
import { Ds, Feedback, Tokens } from "@accurx/design";
import { toast } from "react-toastify";
import styled from "styled-components";

import {
    PatientTriageSettingObjectType,
    trackPatientTriageSetupCopyLinkButtonClick,
} from "app/analytics/ChainAnalytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { CopyableLink } from "./CopyableLink";

const Grid = styled.div`
    > :nth-child(even) {
        margin-bottom: ${Tokens.SIZES[2]};
    }

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        > :nth-child(even) {
            margin-bottom: 0;
        }

        display: grid;
        grid-template-columns: 15% 1fr;
        gap: ${Tokens.SIZES[2]} ${Tokens.SIZES[3]};
        align-items: center;
    }

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        padding: ${Tokens.SIZES[1]};
    }
`;

function DirectLink({ link }: { link: Link }) {
    const id = link.name.replace(/\s/g, "-");
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    return (
        <>
            <label htmlFor={id} style={{ margin: 0 }}>
                <Ds.Text weight="bold">{link.name}</Ds.Text>
            </label>
            <CopyableLink
                id={id}
                url={link.url}
                ariaLabel={`Copy ${link.name} link`}
                onCopyClick={() => {
                    trackPatientTriageSetupCopyLinkButtonClick({
                        ...analyticsLoggedInProps,
                        objectName: link.name,
                        objectType: PatientTriageSettingObjectType.BUTTON,
                    });

                    navigator.clipboard
                        .writeText(link.url)
                        .then(() => {
                            toast(
                                <Feedback
                                    colour="success"
                                    title={`${link.name} link copied!`}
                                >
                                    <Ds.Text>
                                        Share this link with your patients or
                                        your IT team
                                    </Ds.Text>
                                </Feedback>,
                            );
                        })
                        .catch(() => {
                            toast(
                                <Feedback
                                    colour="error"
                                    title="There was a problem copying your link"
                                >
                                    <Ds.Text>
                                        Try again or copy the link manually
                                    </Ds.Text>
                                </Feedback>,
                            );
                        });
                }}
            />
        </>
    );
}

export function DirectLinks({ links }: { links: Link[] }) {
    return (
        <section>
            <Ds.Flex gap="1" flexDirection="column">
                <Ds.Flex gap="1" alignItems="center">
                    <Ds.Text as="h2" size="large" weight="bold">
                        Direct request links
                    </Ds.Text>
                    <Ds.Badge luminosity="low" color="purple" radius="round">
                        New
                    </Ds.Badge>
                </Ds.Flex>
                <Ds.Accordion>
                    <Ds.Accordion.Item value="direct-links">
                        <Ds.Accordion.Trigger>
                            <Ds.Flex gap="2">
                                <Ds.Flex.Item>
                                    <Ds.Icon name="Link" size="xlarge" />
                                </Ds.Flex.Item>
                                <Ds.Flex.Item>
                                    <Ds.Text weight="bold">
                                        These are direct links to specific
                                        request types within Patient Triage
                                    </Ds.Text>
                                    <Ds.Text>
                                        You’ll need to give it to your IT team
                                        to implement on your website.
                                    </Ds.Text>
                                </Ds.Flex.Item>
                            </Ds.Flex>
                        </Ds.Accordion.Trigger>
                        <Ds.Accordion.Content>
                            <Grid>
                                {links.map((link) => (
                                    <DirectLink key={link.url} link={link} />
                                ))}
                            </Grid>
                        </Ds.Accordion.Content>
                    </Ds.Accordion.Item>
                </Ds.Accordion>
            </Ds.Flex>
        </section>
    );
}
