import { useFeatureFlag } from "@accurx/auth";
import { Feedback } from "@accurx/inbox-design-library";
import { ContactDetail } from "@accurx/message-component";

import { getIsContactSubmittedByPatientDifferentFromEmr } from "./getIsContactSubmittedByPatientDifferentFromEmr";

export const EmrPatientSubmittedMismatchFeedback = ({
    contactDetails,
}: {
    contactDetails: ContactDetail[];
}) => {
    const isRecipientSelectorEnabled = useFeatureFlag(
        "ComposeRecipientSelector",
    );
    const isSubmittedByPatientDifferentFromEmr =
        getIsContactSubmittedByPatientDifferentFromEmr(contactDetails);

    if (isRecipientSelectorEnabled && isSubmittedByPatientDifferentFromEmr) {
        return (
            <Feedback
                title="Mobile number in the triage request does not match the patient's medical record."
                colour="information"
            />
        );
    }

    return null;
};
