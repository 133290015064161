import { useAnalytics } from "@accurx/analytics";
import { Conversation } from "@accurx/concierge/types";
import { useNativeTrackingFields } from "@accurx/native";
import { getConversationDescription } from "domains/inbox/util/getConversationDescription";
import { getConversationParticipantForAnalytics } from "domains/inbox/util/getConversationParticipantForAnalytics";
import { getConversationRequestType } from "domains/inbox/util/getConversationRequestType";
import { getConversationServerId } from "domains/inbox/util/getConversationServerId";
import { getConversationType } from "domains/inbox/util/getConversationType";
import { getCountConversationItems } from "domains/inbox/util/getCountConversationItems";

const getConversationTrackingFields = (conversation: Conversation) => {
    return {
        conversationDescription: getConversationDescription(conversation),
        conversationId: getConversationServerId(conversation),
        conversationStartTimestampUtc: conversation.items[0]?.createdAt,
        conversationType: getConversationType(conversation),
        conversationRequestType: getConversationRequestType(conversation),
        conversationStatus: conversation.status,
        conversationParticipant:
            getConversationParticipantForAnalytics(conversation),
    };
};

export const useButtonClickAnalytics = (conversation: Conversation) => {
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();

    const conversationTrackingFields =
        getConversationTrackingFields(conversation);
    const { countPatientMessageInbound, countPatientMessageOutbound } =
        getCountConversationItems(conversation.items);

    return {
        trackReplyButtonClick: () =>
            track("ConversationReply Button Click", {
                ...nativeTrackingFields,
                ...conversationTrackingFields,
                version: "2",
            }),
        trackNoteButtonClick: () =>
            track("ConversationAddNoteSelect Button Click", {
                ...nativeTrackingFields,
                ...conversationTrackingFields,
                countItemsInConversation:
                    countPatientMessageInbound + countPatientMessageOutbound,
                countPatientMessageInbound,
                countPatientMessageOutbound,
                conversationRequestType:
                    getConversationRequestType(conversation),
            }),
    };
};
