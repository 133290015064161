import React, { useState } from "react";

import { OrganisationSetupUserViewModel, UserStatus } from "@accurx/api/portal";
import { useCurrentWorkspace } from "@accurx/auth";
import { Button, Feedback } from "@accurx/design";
import {
    isArchivedWorkspace,
    useUpdateUserStatusMutation,
    useWorkspaceUsersQuery,
} from "@accurx/workspace-management";
import { toast } from "react-toastify";

import { trackUserManagementAction } from "app/analytics/FlemingAnalytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { ConfirmationModal } from "../ConfirmationModal/ConfirmationModal";
import { UserCard } from "../UserCard/UserCard";
import { isStatusChangeAllowed } from "../utils/permissions";

export const ArchivedUserCard = ({
    user,
}: {
    user: OrganisationSetupUserViewModel;
}) => {
    const { userId, name } = user;
    const email = user.email || "";

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const workspace = useCurrentWorkspace();
    const { orgId: workspaceId } = workspace;
    const { mutate } = useUpdateUserStatusMutation();
    const { data: { hasApprovalRights = false } = {}, refetch } =
        useWorkspaceUsersQuery({ workspaceId });

    const isUnarchiveAllowed =
        hasApprovalRights &&
        isStatusChangeAllowed(user, UserStatus.Approved) &&
        !isArchivedWorkspace(workspace);

    const [showModal, setShowModal] = useState(false);

    const handleUnarchiveUser = () => {
        setShowModal(false);
        mutate(
            { workspaceId, userId, status: UserStatus.Approved },
            {
                onSuccess: async () => {
                    toast(
                        Feedback({
                            colour: "success",
                            title: `${name || email} is now a member`,
                        }),
                    );
                    trackUserManagementAction({
                        ...analyticsLoggedInProps,
                        targetUserId: userId,
                        actionSelected: "Unarchive",
                    });
                    await refetch();
                },
                onError: () => {
                    toast(
                        Feedback({
                            colour: "error",
                            title: "Could not update status",
                        }),
                    );
                },
            },
        );
    };

    return (
        <>
            <UserCard name={name} email={email}>
                {isUnarchiveAllowed && (
                    <Button
                        theme="secondary"
                        text="Unarchive"
                        aria-label={`Unarchive ${name || email}`}
                        onClick={() => setShowModal(true)}
                    />
                )}
            </UserCard>
            <ConfirmationModal
                open={showModal}
                onOpenChange={setShowModal}
                onConfirm={handleUnarchiveUser}
                headerText="Unarchive user"
                confirmText="Unarchive user"
            >
                This will restore {name || email} as a member of the workspace.
            </ConfirmationModal>
        </>
    );
};
