import { TicketIdentity } from "@accurx/api/ticket";
import { SentItem } from "@accurx/message-component";

import { mapSentItemToTicketIdentityType } from "./mapSentItemToTicketIdentityType";
import {
    SentItemContentType,
    mapSentItemTypeToContentType,
} from "./mapSentItemTypeToContentType";

export const mapSendMessageResultToSaveToRecordData = (
    sentItem: SentItem,
): {
    patientId: string | null;
    ticketIdentity: TicketIdentity;
    contentType: SentItemContentType;
} | null => {
    const sentItemTicketIdentityType =
        mapSentItemToTicketIdentityType(sentItem);
    const sentItemContentType = mapSentItemTypeToContentType(sentItem);

    if (sentItemTicketIdentityType && sentItemContentType) {
        const patientId: string | null = sentItem.patientAccuRxId ?? null;

        const ticketIdentity: TicketIdentity = {
            id: sentItem.id,
            type: sentItemTicketIdentityType,
        };

        return {
            patientId,
            ticketIdentity,
            contentType: sentItemContentType,
        };
    }
    return null;
};
