import {
    AnalyticsPageType,
    initialiseAppInsightService,
    initialiseRudderStackService,
    isAppInsightsInitialized,
    isRudderStackInitialized,
} from "@accurx/shared";
import { History } from "history";

import type { AccountState } from "app/account/AccountState.types";
import * as FlemingAnalyticsTracker from "app/analytics/FlemingAnalytics";

import AnalyticsMapper from "./AnalyticsMapper";

export const firePageLoadAnalyticAndResetReferredFrom = (
    history: History,
    accountState: AccountState,
    pageName: AnalyticsPageType,
): void => {
    const referrer = history.location.state?.referredFrom;
    const analyticsLoggedInProps =
        AnalyticsMapper.getAnalyticsLoggedInProps(accountState);
    analyticsLoggedInProps &&
        referrer &&
        FlemingAnalyticsTracker.trackPageLoadFleming({
            ...analyticsLoggedInProps,
            referrer: referrer,
            page: pageName,
        });
    clearReferredFromState(history);
};

export const clearReferredFromState = (history: History): void => {
    const state = { ...history.location.state, referredFrom: null };
    history.replace(history.location.pathname + history.location.search, state);
};

/**
 * Helper function to load all analytics
 * Currently we are loading Rudderstack and AppInsight
 */
export const loadAnalytics = () => {
    // Load Rudderstack analytics
    if (!isRudderStackInitialized()) {
        initialiseRudderStackService();
    }

    // Load AppInsight analytics
    if (!isAppInsightsInitialized()) {
        initialiseAppInsightService();
    }
};
