import { useEffect } from "react";

import { useFeatureFlag } from "@accurx/auth";
import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import { Conversation } from "@accurx/concierge/types";
import { usePatientRecordAttachmentsQuery } from "@accurx/native";
import { ArchivedWorkspaceHiddenItemWrapper } from "@accurx/workspace-management";
import { useViewportSize } from "domains/inbox/hooks/util";
import { useShouldPrefetchPatientRecordAttachments } from "domains/inbox/hooks/util/useShouldPrefetchPatientRecordAttachments";

import { useComposeAreaVisibility } from "../../ComposeAreaVisibilityContext/useComposeAreaVisibility";
import {
    StyledExpandableContainer,
    StyledReplyAndNote,
} from "./MessageActions.styles";
import { Note } from "./components/Actions/Note";
import { Reply } from "./components/Actions/Reply";
import { MessageActionButton } from "./components/MessageActionButton/MessageActionButton";

export const MessageActions = ({
    conversation,
    onExpandClick,
    isExpanded,
    setSentNoteStatus,
    onClickAddOutcome,
}: {
    conversation: Conversation;
    onExpandClick: () => void;
    isExpanded: boolean;
    setSentNoteStatus: (state: string | null) => void;
    onClickAddOutcome?: () => void;
}) => {
    const { noteState, replyState, setReplyState } = useComposeAreaVisibility();
    const patient = usePatient({ patientId: conversation.regardingPatientId });
    const isPatientMessagingDisabled = useFeatureFlag(
        "DisableSingleSendSmsAndVideoInvites",
    );

    const showNote = replyState !== "Open";
    const showReply =
        noteState !== "Open" && !!patient && !isPatientMessagingDisabled;
    const showAddOutcome =
        noteState !== "Open" && replyState !== "Open" && onClickAddOutcome;

    const viewPort = useViewportSize();

    // When the conversation opens we want to prefetch patient record
    // attachments if the medical record supports it because that request can
    // potentially take a looooong time so doing it early increases perceived
    // performance.
    const shouldPrefetchPatientRecordAttachments =
        useShouldPrefetchPatientRecordAttachments(patient?.externalIds ?? []);
    usePatientRecordAttachmentsQuery(
        {
            patientExternalIds: patient?.externalIds ?? [],
        },
        {
            enabled: !!patient && shouldPrefetchPatientRecordAttachments,
            refetchOnMount: true,
        },
    );

    useEffect(() => {
        if (conversation.status === "Done") {
            setReplyState("Closed");
        }
    }, [conversation.status, setReplyState]);

    return (
        <StyledExpandableContainer
            $isExpanded={isExpanded}
            $isFloating={replyState !== "Open" && noteState !== "Open"}
            $isMobile={viewPort === "xs" || viewPort === "sm"}
            justifyContent={"space-between"}
        >
            <StyledReplyAndNote gap="1">
                {showReply && (
                    <ArchivedWorkspaceHiddenItemWrapper>
                        <Reply
                            patient={patient}
                            conversation={conversation}
                            isExpanded={isExpanded}
                            onExpandClick={onExpandClick}
                        />
                    </ArchivedWorkspaceHiddenItemWrapper>
                )}
                {showNote && (
                    <Note
                        patient={patient}
                        conversation={conversation}
                        setSentNoteStatus={setSentNoteStatus}
                        isExpanded={isExpanded}
                        onExpandClick={onExpandClick}
                    />
                )}
            </StyledReplyAndNote>
            {showAddOutcome && (
                <MessageActionButton
                    dataTestId="add-outcome-button"
                    onClick={onClickAddOutcome}
                    theme="secondary"
                    text="Add outcome"
                />
            )}
        </StyledExpandableContainer>
    );
};
