import * as UI from "@accurx/design";
import {
    FEED_ANIMATION_FUNCTION,
    FEED_ANIMATION_SPEED,
    GUTTER_SIZE,
} from "domains/inbox/components/ConversationList/components/ConversationFeed/ConversationFeed.styles";
import styled, { keyframes } from "styled-components";

import {
    COLLAPSED_CONVERSATION_PREVIEW_HEIGHT,
    CONVERSATION_PREVIEW_HEIGHT,
} from "../../ConversationPreview.styles";

const markAsDone = keyframes`
  0% {
    opacity: 0;
    max-height: ${CONVERSATION_PREVIEW_HEIGHT + GUTTER_SIZE}px;
  }
  100% {
    opacity: 1;
    max-height: ${COLLAPSED_CONVERSATION_PREVIEW_HEIGHT}px;
    background: ${UI.Tokens.COLOURS.greyscale.dishwater};
  }
`;

export const StyledContainer = styled.div`
    position: absolute;
    z-index: 1;

    // spread out by 1px to account for the 1px outer border of the container
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
`;

export const StyledOverlay = styled(UI.Flex)`
    height: 100%;
    border: 1px solid ${UI.Tokens.COLOURS.greyscale.dishwater};
    border-radius: ${UI.Tokens.BORDERS.radius};
    background: ${UI.Tokens.COLOURS.greyscale.dishwater};
    will-change: opacity, max-height;

    @media (prefers-reduced-motion: no-preference) {
        animation: ${markAsDone} ${FEED_ANIMATION_SPEED}
            ${FEED_ANIMATION_FUNCTION} forwards;
    }

    @media (prefers-reduced-motion: reduce) {
        opacity: 1;
        max-height: ${COLLAPSED_CONVERSATION_PREVIEW_HEIGHT}px;
    }
`;
