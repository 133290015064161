import { ComponentProps } from "react";

import { Tooltip } from "@accurx/inbox-design-library";

import { StyledTooltipTriggerWrapper } from "./WithTooltip.styles";

type TooltipProps = {
    root: Omit<ComponentProps<typeof Tooltip.Root>, "children">;
    trigger: ComponentProps<typeof Tooltip.Trigger>;
    // Omitting children because in WithTooltip component it is called `content`
    content: Omit<ComponentProps<typeof Tooltip.Content>, "children">;
};
type WithTooltipProps = {
    isEnabled?: boolean;
    content: React.ReactNode;
    props?: Partial<TooltipProps>;
    children: React.ReactNode;
};

export const WithTooltip = ({
    children,
    content,
    props,
    isEnabled = true,
}: WithTooltipProps) => {
    // If tooltip should not be shown, just don't render
    // anything tooltip related
    if (!isEnabled) {
        return <>{children}</>;
    }

    return (
        <Tooltip.Root {...props?.root}>
            <StyledTooltipTriggerWrapper>
                <Tooltip.Trigger tabIndex={-1} {...props?.trigger} asChild>
                    {children}
                </Tooltip.Trigger>
            </StyledTooltipTriggerWrapper>
            <Tooltip.Content {...props?.content}>{content}</Tooltip.Content>
        </Tooltip.Root>
    );
};
