import { useEffect } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useFeatureFlag } from "@accurx/auth";
import { usePatientConversationGroupQuery } from "@accurx/concierge/hooks/queries/usePatientConversationGroupQuery";
import { getMostRecentItem } from "@accurx/concierge/util/getMostRecentItem";
import * as UI from "@accurx/design";
import { useNativeTrackingFields } from "@accurx/native";
import { formatPatientDisplayInfo } from "@accurx/navigation";
import {
    ConversationFeed,
    ConversationFeedOptionsWrapper,
    ConversationListHeader,
    ConversationTabItem,
    ConversationTabs,
} from "domains/inbox/components/ConversationList/components";
import { ConversationPreview } from "domains/inbox/components/ConversationPreview/ConversationPreview";
import { usePatientIdentityQuery } from "domains/inbox/hooks/queries/usePatientIdentityQuery";
import { useInboxParams } from "domains/inbox/hooks/util";
import { useConversationListAnalytics } from "domains/inbox/hooks/util/useConversationListAnalytics";
import {
    InboxLocationState,
    useInboxLink,
} from "domains/inbox/hooks/util/useInboxLink";
import { usePatientSummaryBackgroundFixer } from "domains/inbox/hooks/util/usePatientSummaryBackgroundFixer";
import { userflowIds } from "domains/inbox/util";
import { useHistory } from "react-router";

import { ConversationSortOrder } from "../../components/ConversationSortOrder/ConversationSortOrder";
import { getEmptyStateVariant } from "../../components/EmptyState/EmptyState";
import {
    StyledConversationFeedContainer,
    StyledConversationList,
} from "../styles";

export const Patient = () => {
    const { status, sort } = useInboxParams(["status", "sort"]);
    const link = useInboxLink();
    const history = useHistory<InboxLocationState | undefined>();

    const track = useAnalytics();
    const { accessType, medicalRecordSystem } = useNativeTrackingFields();
    const { trackTabClick } = useConversationListAnalytics();

    const patient = history.location.state?.patient ?? undefined;

    const { patientExternalIds = [] } =
        patient?.patientExternalIdentityDto ?? {};

    const { status: patientIdentityStatus, data: patientIdentity } =
        usePatientIdentityQuery(
            patientExternalIds,
            !!patient?.patientExternalIdentityDto,
        );

    const groupQuery = usePatientConversationGroupQuery({
        patientInternalId: patientIdentity?.accuRxId,
        status,
        sort,
    });

    const { isPatientSummaryFixing } = usePatientSummaryBackgroundFixer(
        patientIdentity?.accuRxId,
    );

    const emptyStateVariant = getEmptyStateVariant(status);

    useEffect(() => {
        if (groupQuery.status === "success") {
            track("PatientCard Page Load", {
                accessType,
                medicalRecordSystem,
            });
        }
        // this useEffect should only run when patient conversation query result is received
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupQuery.status]);
    const canUseSorting = useFeatureFlag("UnifiedInboxSortingBuild");

    if (
        !patient ||
        !patient.patientExternalIdentityDto.patientExternalIds.length
    ) {
        // that's a temporary placeholder, designs are not implemented yet
        return <div style={{ margin: "16px" }}>no patient selected</div>;
    }

    return (
        <>
            <StyledConversationList rows="auto 1fr">
                <UI.Cell>
                    <ConversationListHeader
                        heading={
                            <>
                                {formatPatientDisplayInfo({
                                    ...patient,
                                    nameComponents: patient.patientName.usual,
                                })}
                            </>
                        }
                        paddingBottom={2}
                    />
                    <ConversationTabs>
                        <ConversationTabItem
                            active={status === "Open"}
                            link={link.update({
                                status: "Open",
                                sort: null,
                                conversationId: null,
                            })}
                            userflowId={userflowIds.conversationList.openTab}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Done",
                                    newTab: "Open",
                                    conversationParticipant: "WithPatient",
                                })
                            }
                        >
                            Open
                        </ConversationTabItem>

                        <ConversationTabItem
                            active={status === "Done"}
                            link={link.update({
                                status: "Done",
                                sort: null,
                                conversationId: null,
                            })}
                            userflowId={userflowIds.conversationList.doneTab}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Open",
                                    newTab: "Done",
                                    conversationParticipant: "WithPatient",
                                })
                            }
                        >
                            Done
                        </ConversationTabItem>
                    </ConversationTabs>
                </UI.Cell>
                <StyledConversationFeedContainer>
                    {canUseSorting && (
                        <ConversationFeedOptionsWrapper>
                            <UI.Ds.Flex.Item>
                                <ConversationSortOrder />
                            </UI.Ds.Flex.Item>
                        </ConversationFeedOptionsWrapper>
                    )}
                    <ConversationFeed
                        key={groupQuery.data?.id}
                        isLoading={
                            groupQuery.status === "loading" ||
                            patientIdentityStatus === "loading" ||
                            isPatientSummaryFixing
                        }
                        isError={groupQuery.status === "error"}
                        refetch={groupQuery.refetch}
                        fetchMore={groupQuery.fetchMore}
                        isFetchingMore={groupQuery.isFetchingMore}
                        isFullyLoaded={groupQuery.data?.isFullyLoaded}
                        members={groupQuery.data?.members}
                        filters={groupQuery.data?.filters}
                        emptyStateVariant={emptyStateVariant}
                        renderItem={(conversation, state) => (
                            <ConversationPreview
                                conversation={conversation}
                                item={getMostRecentItem(conversation)}
                                state={state}
                                displayAssigneeBadge
                            />
                        )}
                    />
                </StyledConversationFeedContainer>
            </StyledConversationList>
        </>
    );
};
