import { useCurrentWorkspace } from "@accurx/auth";
import { useMarkItemAsWrittenToRecordMutation } from "@accurx/concierge/hooks/mutations/useMarkItemAsWrittenToRecordMutation";
import { useSaveFloreyPdfToRecordMutation as useSaveFloreyPdfToRecordMutationConcierge } from "@accurx/concierge/hooks/mutations/useSaveFloreyPdfToRecordMutation";
import { Conversation, PatientExternalId } from "@accurx/concierge/types";
import {
    ContentTypesWithSaveToRecordSupport,
    useSaveConversationItemToRecordMutation as useNativeSaveConversationItemToRecordMutation,
} from "@accurx/native";
import { Log } from "@accurx/shared";
import {
    UseMutationResult,
    useIsMutating,
    useMutation,
} from "@tanstack/react-query";

const getMutationKey = (workspaceId: number, itemServerId: string) => [
    "SaveConversationItemToRecord",
    { workspaceId, itemServerId },
];

export type UseSaveToRecordMutationVariables = {
    conversationSource: Conversation["source"];
    patientId: string;
    patientExternalIds: PatientExternalId[];
    contentType: ContentTypesWithSaveToRecordSupport;
    createAttachments: boolean;
    snomedCodes?: string[];
};

/**
 * Mutation state is local state in React Query. So if two components call the
 * same mutation, the `isLoading` and `status` fields are not shared between the
 * two components. Usually that's ok because the component which calls the
 * mutation is usually the same component that renders the loading state caused
 * by the mutation but in the case of save to record it can get called in
 * multiple places. This hook makes use of the global mutation state so that the
 * loading state of save to record is global.
 */
export const useIsSavingToRecord = ({
    itemServerId,
}: {
    itemServerId: string;
}) => {
    const workspaceId = useCurrentWorkspace().orgId;
    const mutationKey = getMutationKey(workspaceId, itemServerId);
    return useIsMutating({ mutationKey }) > 0;
};

export const useSaveToRecordMutation = ({
    itemServerId,
}: {
    itemServerId: string;
}): UseMutationResult<unknown, Error, UseSaveToRecordMutationVariables> => {
    const workspaceId = useCurrentWorkspace().orgId;

    const { mutateAsync: saveConversationItemToRecord } =
        useNativeSaveConversationItemToRecordMutation();
    const { mutateAsync: markItemAsWrittenToRecord } =
        useMarkItemAsWrittenToRecordMutation();

    return useMutation(
        async (variables) => {
            let error: Error | null = null;

            if (variables.conversationSource.system !== "Ticket") {
                throw new Error(
                    `SaveToRecordMutation does not support ${variables.conversationSource.system} conversations`,
                );
            }

            const { ticketIdentity } = variables.conversationSource;

            try {
                await saveConversationItemToRecord({
                    itemId: itemServerId,
                    ticketIdentity: {
                        id: ticketIdentity.id,
                        type: ticketIdentity.type.toString(),
                    },
                    patientId: variables.patientId,
                    contentType: variables.contentType,
                    createAttachments: variables.createAttachments,
                    snomedCodes: variables.snomedCodes,
                });
            } catch (err) {
                error = err as Error;
            }

            try {
                await markItemAsWrittenToRecord({
                    contentType: variables.contentType,
                    patientExternalIds: variables.patientExternalIds,
                    writeToRecordSuccess: !error,
                    itemServerId: itemServerId,
                    conversationSource: variables.conversationSource,
                });
            } catch (err) {
                // If the message was successfully written to record but we
                // failed to record that it's a bit of an issue so log an error
                if (!error) {
                    Log.error(
                        "Item was saved to record but we failed to mark it as written",
                        {
                            originalException: err,
                            tags: {
                                itemId: itemServerId,
                                contentType: variables.contentType,
                                createAttachments: variables.createAttachments,
                                ticketType: ticketIdentity.type.toString(),
                                ticketId: ticketIdentity.id,
                            },
                        },
                    );
                }
                error = err as Error;
            }

            if (error) {
                throw error;
            }
        },
        { mutationKey: getMutationKey(workspaceId, itemServerId) },
    );
};

export const useSaveFloreyPdfToRecordMutation = ({
    itemServerId,
}: {
    itemServerId: string;
}) => {
    const workspaceId = useCurrentWorkspace().orgId;
    const saveFloreyPdfToRecord = useSaveFloreyPdfToRecordMutationConcierge();

    return useMutation(
        () =>
            saveFloreyPdfToRecord.mutateAsync({
                itemServerId,
            }),
        {
            mutationKey: getMutationKey(workspaceId, itemServerId),
        },
    );
};
