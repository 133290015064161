import { useMutateCloseWindow } from "@accurx/native";

import { useActiveCloseAttempt } from "./useActiveCloseAttempt";

/**
 * In some cases we don't want to incercept window closes, for example
 * on the patient search and conversation pages. Using this hook will
 * mean that the page will close immediately if ever requested via
 * the native bridge when enabled. */
export const useAllowWindowClose = ({
    enabled,
    onClose,
}: {
    enabled: boolean;
    onClose: () => void;
}) => {
    const { mutate: closeWindow } = useMutateCloseWindow();

    const { activeCloseAttempt, onCloseAttemptActioned } =
        useActiveCloseAttempt();

    if (activeCloseAttempt) {
        if (enabled) {
            closeWindow();
            onClose();
        }
        onCloseAttemptActioned();
    }
};
