import React from "react";

import { Ds } from "@accurx/design";
import { i } from "images";

import {
    StyledContainer,
    StyledErrorMessage,
} from "./GenericErrorState.styles";

export const GenericErrorState = ({
    children,
}: {
    children?: React.ReactNode;
}) => {
    return (
        <StyledContainer
            flexDirection="column"
            gap="1.5"
            alignItems="center"
            justifyContent="center"
        >
            <img
                src={i("illustrations/error-icon-80px@2x.png")}
                srcSet={`${i("illustrations/error-icon-80px.png")}, ${i(
                    "illustrations/error-icon-80px@2x.png",
                )} 2x, ${i("illustrations/error-icon-80px@3x.png")} 3x`}
                alt="Conversation loading error"
                height="80"
            />

            {children ? (
                <StyledErrorMessage>{children}</StyledErrorMessage>
            ) : (
                <StyledErrorMessage>
                    <Ds.Flex
                        flexDirection="column"
                        gap="1.5"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Ds.Text as="span" color="metal">
                            Sorry, something went wrong.
                            <br />
                            Refresh the page to try again.
                        </Ds.Text>
                        <Ds.Button
                            appearance="secondary"
                            onClick={() => window.location.reload()}
                            size="small"
                        >
                            <Ds.Button.Icon name="Refresh" />
                            Refresh
                        </Ds.Button>
                    </Ds.Flex>
                </StyledErrorMessage>
            )}
        </StyledContainer>
    );
};
