import {
    AppointmentChangeDeadline,
    AppointmentSupportedChanges,
} from "@accurx/api/appointment";
import { Card, Checkbox, Flex, Icon, Text, Tokens } from "@accurx/design";
import styled from "styled-components";

import { getSupportedAmendMessage } from "../utils";

const ReadOnlyCheckboxWrapper = styled(Flex).attrs({
    gap: "0.5",
    alignItems: "center",
})`
    padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[1.5]};
    padding-left: ${Tokens.SIZES[2]};

    border: 1px solid ${Tokens.COLOURS.primary.blue[100]};
    border-radius: ${Tokens.BORDERS.radius};
    background-color: ${Tokens.COLOURS.primary.blue[10]};
`;

const LimitedFlexContainer = styled(Flex)`
    max-width: 100%;

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        // To align this on the page with other component that using grid and spacing: 2
        max-width: calc(50% - ${Tokens.SIZES["2"]} / 2);
    }
`;

interface SendTimeInputProps {
    oneWeekReminderEnabled: boolean;
    toggleOneWeekReminderEnabled: () => void;
    threeWorkingDayReminderEnabled: boolean;
    toggleThreeDaysReminderEnabled: () => void;
    changeDeadline: AppointmentChangeDeadline;
    supportedChanges: AppointmentSupportedChanges;
    limitOptionsWidth?: boolean;
    spacing?: 2 | 3;
}

export const SendTimeInput = ({
    oneWeekReminderEnabled,
    toggleOneWeekReminderEnabled,
    threeWorkingDayReminderEnabled,
    toggleThreeDaysReminderEnabled,
    changeDeadline,
    supportedChanges,
    limitOptionsWidth,
    spacing,
}: SendTimeInputProps) => {
    const WidthLimitWrapper = limitOptionsWidth ? LimitedFlexContainer : Flex;

    return (
        <Card
            spacing={spacing || 2}
            header={
                <Text skinny variant="subtitle" as="h2">
                    Scheduling
                </Text>
            }
        >
            <Flex flexDirection="column" gap="1.5">
                <Text skinny>
                    This automated appointment reminder{" "}
                    <b>will be sent to patients.</b>{" "}
                    {getSupportedAmendMessage(supportedChanges, changeDeadline)}
                </Text>
                <WidthLimitWrapper flexDirection="column" gap="1.5">
                    <ReadOnlyCheckboxWrapper>
                        <Icon name="Tick" size={3} colour="zinc" />
                        <Text variant="body" skinny>
                            1 working day before appointment
                        </Text>
                    </ReadOnlyCheckboxWrapper>
                    <Checkbox
                        checked={threeWorkingDayReminderEnabled}
                        id="three-days-reminder-checkbox"
                        onCheckChange={toggleThreeDaysReminderEnabled}
                        text="3 working days before appointment"
                        theme="bordered"
                    />
                    <Checkbox
                        checked={oneWeekReminderEnabled}
                        id="one-week-reminder-checkbox"
                        onCheckChange={toggleOneWeekReminderEnabled}
                        text="1 week before appointment"
                        theme="bordered"
                    />
                </WidthLimitWrapper>
            </Flex>
        </Card>
    );
};
