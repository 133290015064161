import {
    ConversationGroupSortOption,
    ConversationGroupSortOptions,
    SortDirection,
} from "domains/concierge/internal/types/ConversationGroupSortOptions";
import { Conversation } from "domains/concierge/schemas/ConversationSchema";
import { getMostRecentItemOfTypes } from "domains/concierge/util/getMostRecentItemOfTypes";
import { getMostRecentSentItem } from "domains/concierge/util/getMostRecentSentItem";
import { getNextScheduledItem } from "domains/concierge/util/getNextScheduledItem";

export type ComparatorFn = (
    conversation: Conversation,
) => string | number | boolean | null;

export type Ordering = [ComparatorFn[], ("asc" | "desc")[]];

const getComparator = (
    sortOption: ConversationGroupSortOption,
): ComparatorFn => {
    switch (sortOption.key) {
        case "Id":
            return (c) => c.id;

        case "IsUrgent":
            return (c) => c.isUrgent;

        case "LastUpdated":
            return (c) => c.lastUpdated;

        case "MostRecentSentItem":
            return (c) => {
                const mostRecentSentItem = getMostRecentSentItem(
                    c,
                    sortOption.senderId,
                );
                return (
                    mostRecentSentItem?.deliveryStatusUpdatedAt ??
                    mostRecentSentItem?.createdAt ??
                    ""
                );
            };

        case "MostRecentSentItemId":
            return (c) =>
                getMostRecentSentItem(c, sortOption.senderId)?.serverId ?? "";

        case "NextScheduledItem":
            return (c) =>
                getNextScheduledItem(c, sortOption.senderId)
                    ?.deliveryScheduledAt ?? "";

        // Currently we're ignoring the sender ID on failed messages which means
        // that the ordering for "My failed" messages is incorrect. Rather than
        // being ordered by the latest delivery receipt that links to a message
        // sent by the user, they are ordered by the latest delivery receipt of
        // any kind.
        //
        // Currently there's a limitation on the API and we cannot guarentee
        // that we have the linked item for every failed delivery receipt,
        // therefor we cannot check who the sender was for each failed item.
        case "MostRecentFailedItem":
            return (c) =>
                getMostRecentItemOfTypes(c, [
                    "FailedDeliveryReceiptLink",
                    "FallbackFailedLink",
                ])?.createdAt ?? "";
    }
};

export const conversationOrdering = (
    sortOptions: ConversationGroupSortOptions,
): Ordering => {
    const comparators: ComparatorFn[] = [];
    const directions: SortDirection[] = [];

    sortOptions.forEach((sortOption) => {
        comparators.push(getComparator(sortOption));
        directions.push(sortOption.direction);
    });

    return [comparators, directions];
};
