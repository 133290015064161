import { useState } from "react";

import { Conversation, PatientSummary } from "@accurx/concierge/types";
import { useComposeAreaVisibility } from "domains/inbox/components/ConversationView/ComposeAreaVisibilityContext/useComposeAreaVisibility";
import { useViewportSize } from "domains/inbox/hooks/util";

import { ComposeNote } from "../../../ComposeNote/ComposeNote";
import { useButtonClickAnalytics } from "../../useButtonClickAnalytics";
import { MessageActionButton } from "../MessageActionButton/MessageActionButton";
import { StyledInnerContainer, StyledOuterContainer } from "./Contents.styles";

type NoteContentsProps = {
    conversation: Conversation;
    patient?: PatientSummary;
    isExpanded: boolean;
    onExpandClick: () => void;
    setSentNoteStatus: (status: string | null) => void;
};

export const NoteContents = ({
    patient,
    conversation,
    isExpanded,
    onExpandClick,
    setSentNoteStatus,
}: NoteContentsProps) => {
    const { noteState, setNoteState } = useComposeAreaVisibility();
    const [noteText, setNoteText] = useState<string>("");
    const { trackNoteButtonClick } = useButtonClickAnalytics(conversation);

    const viewPort = useViewportSize();
    const isMobileSize = viewPort === "xs" || viewPort === "sm";

    const isComposingNote = noteText.length > 0;

    if (noteState === "Minimised") {
        return (
            <MessageActionButton
                onClick={() => {
                    setNoteState("Open");
                    trackNoteButtonClick();
                }}
                text={isComposingNote ? "Continue note" : "Add note"}
                theme="secondary"
                icon="Document"
            />
        );
    }

    return (
        <StyledOuterContainer>
            <StyledInnerContainer>
                <ComposeNote
                    noteText={noteText}
                    setNoteText={setNoteText}
                    conversation={conversation}
                    patient={patient}
                    closeNotePanel={() => setNoteState("Closed")}
                    setSentNoteStatus={setSentNoteStatus}
                    isHeightRestricted={!isExpanded}
                    onMinimiseClick={() => setNoteState("Minimised")}
                    //We now automatically expand the compose note component on mobile.
                    //passing undefined down to ExpandMinimiseBar will not render the expand button
                    onExpandClick={isMobileSize ? undefined : onExpandClick}
                />
            </StyledInnerContainer>
        </StyledOuterContainer>
    );
};
