import { useEffect } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useNavigationOrigin } from "@accurx/navigation";

import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { VaccineRecordingAllSessions } from "app/practices/vaccine/vaccineRecording/vaccineAllSessions/VaccineRecordingAllSessions.component";

export const AccubookBookedPatientsPage = () => {
    const track = useAnalytics();
    const navigationOrigin = useNavigationOrigin();

    useEffect(() => {
        track("AccuBookBookedPatient Page View", { navigationOrigin });
    }, [track, navigationOrigin]);

    return (
        <StyledContainer>
            <PageHeader
                title="Booked patients"
                type={PageHeaderType.ListPage}
            />
            <VaccineRecordingAllSessions noHeader />
        </StyledContainer>
    );
};
