import { useCurrentWorkspace } from "@accurx/auth";
import { useMessageTemplatesQuery } from "@accurx/message-templates";
import {
    OrganisationEntityChangedType,
    SignalRSubscriptionEvent,
    useSubscribeSignalREvent,
} from "@accurx/realtime";
import { Log } from "@accurx/shared";

/** Listens for SignalR events that say templates have been updated and refetches data */
export const useMessageTemplatesSubscription = () => {
    const workspace = useCurrentWorkspace();
    const { refetch } = useMessageTemplatesQuery(
        { workspaceId: workspace.orgId },
        { enabled: false },
    );

    useSubscribeSignalREvent({
        type: SignalRSubscriptionEvent.OrganisationEntityChanged,
        eventFilter: (payload) => payload.organisationId === workspace.orgId,
        onEvent: (payload) => {
            const isTemplateChange =
                payload.type === OrganisationEntityChangedType.Templates;

            if (isTemplateChange) {
                Log.info("Refetching templates after update");
                void refetch();
            }
        },
    });
};
