import { TextareaAutosize, Tokens } from "@accurx/design";
import styled from "styled-components";

const StyledTextareaBody = styled(TextareaAutosize)`
    border: none;
    border-radius: ${Tokens.BORDERS.radius};

    padding: ${Tokens.SIZES[1.5]};
    width: 100%;

    color: ${Tokens.COLOURS.greyscale.night};

    border-bottom: none;

    ${Tokens.TYPOGRAPHY.body.base}
    resize: none;
    line-height: ${Tokens.SIZES[3]};
    display: block;

    &:focus {
        box-shadow: none !important;
    }
`;

const StyledTextareaFooter = styled.textarea`
    border: none;
    padding: 0 ${Tokens.SIZES[1.5]};
    width: 100%;

    color: ${Tokens.COLOURS.greyscale.stone};
    background-color: transparent;

    border-top: none;

    font-family: ${Tokens.TYPOGRAPHY.body.base.fontFamily};
    resize: none;
    line-height: ${Tokens.SIZES[3]};
    flex: 1;
    display: block;

    &:focus {
        box-shadow: none !important;
        outline: none;
    }
`;

export const StyledTextareaHeader = styled.textarea`
    border: none;
    padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[1.5]} 0;
    width: 100%;

    color: ${Tokens.COLOURS.greyscale.stone};
    background-color: transparent;

    border-bottom: none;

    font-family: ${Tokens.TYPOGRAPHY.body.base.fontFamily};
    resize: none;
    display: block;

    &:focus {
        box-shadow: none !important;
        outline: none;
    }
`;

const StyledTextareaWrapper = styled.div`
    border: ${Tokens.BORDERS.normal.silver};
    border-radius: ${Tokens.BORDERS.radius};
    background-color: ${Tokens.COLOURS.greyscale.white};
    padding-bottom: ${Tokens.SIZES[1.5]};
    display: flex;
    flex-direction: column;
    flex: 1;

    &:focus-within {
        box-shadow: 0 0 0 ${Tokens.SIZES[0.5]}
            ${Tokens.COLOURS.primary.blue[50]};
    }
`;

export const StyledTextarea = {
    Wrapper: StyledTextareaWrapper,
    Footer: StyledTextareaFooter,
    Body: StyledTextareaBody,
    Header: StyledTextareaHeader,
};
