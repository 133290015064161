import { useEffect } from "react";

import { useHistory } from "react-router";

import { useConversationLocation } from "./useConversationLocation";
import { useSearchParams } from "./useSearchParams";

/**
 * Navigates to a conversation's conversation group.
 * This is only done when "source=native" is a query param.
 */
export const useNavigateToConversationGroup = () => {
    const { conversationId: id, source } = useSearchParams();
    const history = useHistory();

    const nextLocation = useConversationLocation({
        conversationId: decodeURIComponent(id ?? ""),
    });

    useEffect(() => {
        if (source !== "native" || !nextLocation) return;
        history.push(nextLocation);
    }, [source, nextLocation, history]);
};
