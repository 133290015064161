import { useEffect, useMemo, useRef, useState } from "react";

import { PatientSearchResultBase } from "@accurx/api/portal";
import { useCurrentWorkspace } from "@accurx/auth";
import { Button, Ds, Feedback, Input, Tokens } from "@accurx/design";
import { DateHelpers } from "@accurx/shared";
import { useBasicPatientSearch } from "domains/navigation/hooks/mutations/useBasicPatientSearch";
import { useLatestPatientSearchQuery } from "domains/navigation/hooks/queries/useLatestPatientSearchQuery";
import {
    PatientSearchProductOrigin,
    getDisabledMessage,
} from "domains/navigation/util/patient.helpers";
import { i } from "images";
import { userflowIds } from "layouts/constants";
import _debounce from "lodash/debounce";
import { useAnalytics } from "reduxQuarantine/useAnalytics";
import styled from "styled-components";

import { PatientSearchResult } from "../../../types";
import { SkeletonLoader } from "../../SkeletonLoader/SkeletonLoader";
import { PatientCard } from "../PatientCard/PatientCard";
import { PatientCardSkeleton } from "../PatientCard/PatientCardSkeleton";
import {
    StyledClearButton,
    StyledFormFieldWrapper,
} from "../PatientSearchForm/PatientSearchForm.styles";

export const errorMessage = {
    api: {
        content: "Couldn’t load recent patients. Try a new patient search.",
    },
    notFound: {
        title: "No results in recent patients",
        content: "Try starting a new patient search",
    },
};

export type RecentPatientsProps = {
    onSelect: (searchResult: PatientSearchResult | null, error?: Error) => void;
    onToggleFilter?: (state: boolean) => void;
    onClickTestPatient?: () => void;
    cardRole?: "button" | "radio";
    productOrigin?: PatientSearchProductOrigin;
};

export const RecentPatients = ({
    onSelect,
    onToggleFilter,
    onClickTestPatient,
    cardRole = "button",
    productOrigin = "PatientSearch",
}: RecentPatientsProps) => {
    const track = useAnalytics();
    const { orgId } = useCurrentWorkspace();

    const searchButtonRef = useRef<HTMLButtonElement>(null);

    const [searchInput, setSearchInput] = useState("");
    const [searchInputDebounced, setSearchInputDebounced] =
        useState(searchInput);
    const [showFilter, setShowFilter] = useState(false);

    const {
        data: latestPatientSearch,
        isLoading,
        isError,
    } = useLatestPatientSearchQuery({
        organisationId: orgId,
        search: searchInputDebounced,
    });

    useEffect(() => {
        if (latestPatientSearch && searchInputDebounced) {
            track("PatientSearchRecentFilter TextBox Input", {
                countPatientsTotal: latestPatientSearch.totalCount,
                countPatientsInFilter: latestPatientSearch.patients.length,
                searchStringLength: searchInputDebounced.length,
                navigationVersion: "Unified",
                productOrigin,
            });
        }
    }, [track, latestPatientSearch, searchInputDebounced, productOrigin]);

    const isFirstExperience =
        !isLoading &&
        !searchInput &&
        latestPatientSearch?.patients.length === 0;

    const debounceSearch = useMemo(
        () => _debounce(setSearchInputDebounced, 500),
        [],
    );

    const updateSearch = (value: string) => {
        setSearchInput(value);
        setSearchInputDebounced(value);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value);
        debounceSearch(e.target.value);
    };

    const handleToggleSearch = () => {
        updateSearch("");
        setShowFilter(() => !showFilter);
        onToggleFilter?.(!showFilter);
        setTimeout(() => searchButtonRef.current?.focus(), 0);
    };

    if (isLoading && !searchInput) {
        return (
            <Ds.Flex flexDirection="column" gap="2" role="status">
                <Ds.Box m="0.5">
                    <SkeletonLoader width="60px" />
                </Ds.Box>
                <PatientCardSkeleton />
            </Ds.Flex>
        );
    }

    if (isFirstExperience && !showFilter) {
        return <FirstExperience onClickTestPatient={onClickTestPatient} />;
    }

    return (
        <StyledRecentPatients>
            {!showFilter ? (
                <StyledRecentPatientsHeader>
                    <Ds.Flex justifyContent="space-between" alignItems="center">
                        <Ds.Text weight="bold" color="zinc">
                            Recent patients
                        </Ds.Text>
                        <Button
                            ref={searchButtonRef}
                            text="Search recents"
                            theme="transparent"
                            icon={{ name: "Search" }}
                            onClick={handleToggleSearch}
                            data-userflow-id={
                                userflowIds.navigation.patientSearch
                                    .searchRecents
                            }
                        />
                    </Ds.Flex>
                </StyledRecentPatientsHeader>
            ) : (
                <RecentPatientsSearch
                    search={searchInput}
                    onSearchChange={handleSearchChange}
                    onToggleSearch={handleToggleSearch}
                    onClear={() => updateSearch("")}
                />
            )}
            {isError ? (
                <StyledSoftError>{errorMessage.api.content}</StyledSoftError>
            ) : isLoading ? (
                <PatientCardSkeleton role="status" />
            ) : latestPatientSearch.patients.length === 0 ? (
                <Feedback title={errorMessage.notFound.title} colour="warning">
                    {errorMessage.notFound.content}
                </Feedback>
            ) : (
                <StyledPatientList
                    as={cardRole === "radio" ? Ds.RadioGroup : "div"}
                >
                    {latestPatientSearch.patients.map((patient) => (
                        <PatientLoader
                            key={patient.nhsNumber}
                            patient={patient}
                            onSelect={onSelect}
                            cardRole={cardRole}
                            productOrigin={productOrigin}
                        />
                    ))}
                </StyledPatientList>
            )}
        </StyledRecentPatients>
    );
};

const PatientLoader = ({
    cardRole,
    patient,
    onSelect,
    productOrigin = "PatientSearch",
}: {
    cardRole: "button" | "radio";
    patient: PatientSearchResultBase;
    productOrigin: PatientSearchProductOrigin;
    onSelect: (searchResult: PatientSearchResult | null, error?: Error) => void;
}) => {
    const [disabledMessage, setDisabledMessage] = useState<string>();

    const { mutate, isLoading } = useBasicPatientSearch({
        mutationKey: ["SearchForLatestPatient"],
        onError: (error) => onSelect(null, error),
        onSuccess: (data) => {
            if (!data.length) {
                onSelect(null);
                return;
            }
            const disabledMessage = getDisabledMessage({
                productOrigin,
                patient: data[0],
            });
            disabledMessage
                ? setDisabledMessage(disabledMessage)
                : onSelect(data[0]);
        },
    });

    const handleClick = () => {
        const dateOfBirth = DateHelpers.getDateParts(patient.dateOfBirth ?? "");
        if (patient.nhsNumber && dateOfBirth) {
            mutate({
                nhsNumber: patient.nhsNumber,
                dateOfBirth: {
                    day: dateOfBirth.d.toString(),
                    month: dateOfBirth.m.toString(),
                    year: dateOfBirth.y.toString(),
                },
            });
        }
    };

    return (
        <PatientCard
            key={patient.nhsNumber}
            patient={patient}
            onClick={handleClick}
            isLoading={isLoading}
            as={cardRole}
            disabledMessage={disabledMessage}
        />
    );
};

type FilterHeaderProps = {
    search: string;
    onToggleSearch: () => void;
    onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClear: () => void;
};

const RecentPatientsSearch = ({
    search,
    onToggleSearch,
    onSearchChange,
    onClear,
}: FilterHeaderProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        inputRef.current?.focus();
    }, [inputRef]);

    return (
        <StyledRecentPatientsHeader>
            <Ds.Flex justifyContent="space-between" alignItems="center">
                <Ds.Text weight="bold">Recent patient's name</Ds.Text>
                <Button
                    text="Close"
                    theme="transparent"
                    icon={{ name: "Cross" }}
                    onClick={onToggleSearch}
                />
            </Ds.Flex>
            <StyledFormFieldWrapper
                labelProps={{
                    htmlFor: "RecentPatientsFilter",
                }}
            >
                <Input
                    ref={inputRef}
                    id="RecentPatientsFilter"
                    aria-label="Search recent patients"
                    value={search}
                    type="text"
                    onChange={onSearchChange}
                />
                {search && (
                    <StyledClearButton onClick={onClear}>
                        <Ds.Text weight="bold">Clear</Ds.Text>
                    </StyledClearButton>
                )}
            </StyledFormFieldWrapper>
        </StyledRecentPatientsHeader>
    );
};

type FirstExperienceProps = {
    onClickTestPatient?: () => void;
};

const FirstExperience = ({ onClickTestPatient }: FirstExperienceProps) => (
    <StyledFirstExperience>
        <Ds.Box width="180px">
            <Ds.Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap="2"
                style={{ textAlign: "center" }}
            >
                <img
                    src={i("illustrations/Group.png")}
                    width={80}
                    height={80}
                    alt=""
                />
                <Ds.Text color="metal">
                    Search for a patient to see their details here
                </Ds.Text>
            </Ds.Flex>
        </Ds.Box>
        {onClickTestPatient && (
            <Button
                text="Try out with a test patient"
                theme="secondary"
                onClick={onClickTestPatient}
            />
        )}
    </StyledFirstExperience>
);

const StyledRecentPatients = styled(Ds.Flex)`
    flex-direction: column;
    overflow: hidden;
`;

const StyledSoftError = styled.div`
    display: flex;
    align-self: center;
    max-width: 250px;
    text-align: center;
    margin-top: ${Tokens.SIZES[1]};
`;

const StyledRecentPatientsHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 5px; // Additional margin for focus ring
    gap: ${Tokens.SIZES[1]};
`;

const StyledPatientList = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${Tokens.SIZES[1]};
    padding: 5px;
    overflow: auto;
`;

const StyledFirstExperience = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${Tokens.SIZES[2]};
    margin: auto 0;
    padding-bottom: ${Tokens.SIZES[5]};
`;
