import * as UI from "@accurx/design";
import {
    PatientName,
    calculateAge,
    formatPatientName,
} from "@accurx/navigation";

import {
    StyledPatientCard,
    StyledPatientInfo,
    StyledPatientTitle,
} from "./PatientCard.styles";

export type PatientCardProps = {
    nhsNumber: string | null;
    dateOfBirth: string | null;
    gender: string;
    firstName: string;
    familyName: string;
};

const PATIENT_INFO_PLACEHOLDER = "Unspecified";

export const PatientCard = ({
    nhsNumber,
    dateOfBirth,
    gender,
    firstName,
    familyName,
}: PatientCardProps) => {
    const patientName = formatPatientName({
        firstName,
        familyName,
    });

    const ageYear = dateOfBirth ? calculateAge(dateOfBirth) : 0;
    return (
        <StyledPatientCard>
            <StyledPatientTitle>
                <PatientName
                    name={patientName}
                    ageYear={ageYear}
                    gender={gender}
                />
            </StyledPatientTitle>
            <StyledPatientInfo>
                <UI.Text skinny variant="preview">
                    NHS: {nhsNumber ?? PATIENT_INFO_PLACEHOLDER} • DOB:{" "}
                    {dateOfBirth ?? PATIENT_INFO_PLACEHOLDER}
                </UI.Text>
            </StyledPatientInfo>
        </StyledPatientCard>
    );
};
