import * as UI from "@accurx/design";
import { PatientExternalId } from "domains/message-component/types";

import { ContactDetail } from "../../types";
import { useCommunicationConsent } from "./CommunicationConsent/ConsentAggregate/useCommunicationConsent";
import { ConsentAggregateIcon } from "./CommunicationConsent/ConsentAggregateIcon";
import { StyledSpinnerContainer } from "./RecipientSelectorButtonConsentIcon.styles";
import { VisuallyHiddenConsentText } from "./VisuallyHiddenConsentText";

export const RecipientSelectorButtonConsentIcon = ({
    patientExternalIds,
    contactMethod,
}: {
    patientExternalIds: PatientExternalId[];
    contactMethod: ContactDetail["method"];
}) => {
    const communicationConsentQuery = useCommunicationConsent({
        patientExternalIds,
    });

    if (communicationConsentQuery === null) {
        return null;
    }

    const { status, data } = communicationConsentQuery;
    const isMobile = contactMethod === "Mobile";

    if (status === "loading") {
        return (
            <div>
                <StyledSpinnerContainer>
                    <UI.Ds.Spinner
                        size="xsmall"
                        aria-labelledby="consent-loading-description"
                    />
                </StyledSpinnerContainer>
                <UI.VisuallyHidden id="consent-loading-description">
                    Communication consent loading
                </UI.VisuallyHidden>
            </div>
        );
    }

    if (status === "error") {
        return (
            <div>
                <ConsentAggregateIcon
                    consentAggregate="Mixed"
                    aria-labelledby="consent-description"
                />
                <UI.VisuallyHidden id="consent-description">
                    Error loading communication consent
                </UI.VisuallyHidden>
            </div>
        );
    }

    const consentAggregate = isMobile
        ? data.smsConsent.consentAggregate
        : data.emailConsent.consentAggregate;

    return (
        <div>
            <ConsentAggregateIcon
                consentAggregate={consentAggregate}
                aria-labelledby="consent-description"
            />
            <VisuallyHiddenConsentText
                id="consent-description"
                consentAggregate={consentAggregate}
                contactMethod={isMobile ? "Mobile" : "Email"}
            />
        </div>
    );
};
