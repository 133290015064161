import { useEffect } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useCurrentWorkspace } from "@accurx/auth";
import { Ds, TabProps, Tabs } from "@accurx/design";
import { useNavigationOrigin } from "@accurx/navigation";
import { useParams } from "react-router";
import { generatePath, useHistory } from "react-router-dom";

import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { DelayedMessages } from "app/practices/delayedMessages/DelayedMessages";
import { DeliveryReceiptsContainer } from "app/practices/deliveryReceipts/DeliveryReceipts.container";
import { StyledTabListWrapper } from "app/workspaceSettings/pages/UserManagement/UserManagementPage.styles";
import { ROUTES_WORKSPACE } from "shared/Routes";

export const SmsStatusPage = () => {
    const history = useHistory();
    const { tab } = useParams<{ tab?: string }>();
    const { orgId: workspaceId, settings } = useCurrentWorkspace();
    const track = useAnalytics();
    const navigationOrigin = useNavigationOrigin();

    const tabs: TabProps[] = [
        { tabId: "sent", text: "Sent" },
        ...(settings.isApprovedUser
            ? [{ tabId: "scheduled", text: "Scheduled" }]
            : []),
    ];

    useEffect(() => {
        track("SmsStatus Page View", { navigationOrigin });
    }, [track, navigationOrigin]);

    const handleTabChange = (tab: string) => {
        history.push(
            generatePath(ROUTES_WORKSPACE.workspaceDeliveryReceipts, {
                workspaceId,
                tab,
            }),
        );
    };

    return (
        <StyledContainer>
            <PageHeader title="SMS status" type={PageHeaderType.ListPage} />
            <Ds.Flex flexDirection="column" gap="3">
                <Tabs selected={tab || "sent"} onTabChange={handleTabChange}>
                    <StyledTabListWrapper>
                        <Tabs.TabList tabs={tabs} />
                    </StyledTabListWrapper>
                    <Tabs.Panel tabId="sent">
                        <DeliveryReceiptsContainer noHeader />
                    </Tabs.Panel>
                    {settings.isApprovedUser && (
                        <Tabs.Panel tabId="scheduled">
                            <DelayedMessages noHeader />
                        </Tabs.Panel>
                    )}
                </Tabs>
            </Ds.Flex>
        </StyledContainer>
    );
};
