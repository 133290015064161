import { RouterState } from "../pages/Routes.types";
import { CurrentPatient } from "../pages/compose/context/CurrentPatientProvider.types";
import { mapMobileNumberToContactDetails } from "./mapMobileNumberToContactDetails";

export const mapRouterStateToCurrentPatient = (
    state: RouterState | null,
): CurrentPatient | null =>
    state?.searchedPatient
        ? {
              patient: state.searchedPatient,
              contactDetails: mapMobileNumberToContactDetails(
                  state.searchedPatient,
              ),
          }
        : null;
