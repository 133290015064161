import { useFullScreen } from "layouts/UnifiedLayout/UnifiedLayoutContext";

import { StyledContainer } from "app/layout/Container";
import { VaccineSmsPreview } from "app/practices/vaccine/vaccineSmsPreview/VaccineSmsPreview.component";

export const SendReminderPage = () => {
    useFullScreen();

    return (
        <StyledContainer>
            <VaccineSmsPreview />
        </StyledContainer>
    );
};
