import {
    EventAction,
    EventObjectType,
    EventUserType,
    GenericTrackEvent,
    LoggedInCustomProperties,
    SharedRudderStackAnalyticProps,
} from "@accurx/shared";

import { TrackManagePracticeEvent } from "./ChainAnalyticsTracker";

//#region INTERFACES

/**
 * - isEnabled: is patient triage enabled for the practice
 * */
type PatientTriagePageLoadedBaseProps = {
    isEnabled: string;
};

/**
 * - redirectUrl: the url to redirect to
 * */
type PatientTriageRedirectEnabledBaseProps = {
    redirectUrl: string;
};

/**
 * - disableUntil: the time patient triage is disabled until
 * - reason: the reason to disabled
 * - comment: user comment
 * */
type PatientTriageDisableBaseProps = {
    disableUntil: string;
    reason: string;
    comment: string;
};

/**
 * - enabled: boolean for either on or off
 * */
type PatientTriageOutOfHoursToggleBaseProps = {
    enabled: string;
};

/**
 * - isAdmin: is the user an admin
 * */
type ManageTeamsPageLoadedBaseProps = {
    isAdmin: string;
};

/**
 * - teamId: the id of the team the user is editing
 * - teamName: the name of the team the user is editing
 * */
type EditTeamBaseProps = {
    teamId: string;
    teamName: string;
};

type OrganisationManagementItemBaseProps = {
    objectName: string;
};

type PatientTriageOptionBaseProps = {
    optionSelected: string;
};

/**
 * Corresponds to the type of component that was clicked
 * */
export enum PatientTriageSettingObjectType {
    BUTTON = "Button",
    LINK = "Link",
    TAB = "Tab",
}

/**
 * Corresponds to the buttons a user can click when interacting with a Patient Triage Setting
 * */
export enum PatientTriageSettingObjectName {
    DOWNLOAD = "Download",
    EDIT = "Edit",
    CANCEL = "Cancel",
    CREATE_REQUEST = "CreateRequest",
    PRACTICE_LINK = "PracticeLink",
    RESET = "Reset",
    SAVE = "Save",
}

type PatientTriageManagementSettingBaseProps = {
    objectName: PatientTriageSettingObjectName | string;
    objectType: PatientTriageSettingObjectType;
};

type PatientTriageSettingSaveSuspendBaseProps =
    PatientTriageManagementSettingBaseProps & {
        isEnabled: boolean;
        resumeDate: string | null;
        isSuspendMedicalRequestOnlyEnabled: boolean | null;
        reason: string | null;
    };

type PatientTriageSettingSaveOutOfHoursBaseProps =
    PatientTriageManagementSettingBaseProps & {
        isEnabled: boolean;
        fromHour: number | null;
        toHour: number | null;
        isSuspendAccessEnabled: boolean | null;
        isSuspendMedicalRequestOnlyEnabled: boolean | null;
    };

type PatientTriageSettingSavePrescriptionsBaseProps =
    PatientTriageManagementSettingBaseProps & {
        isRepeatPrescriptionEnabled: boolean;
        isRedirectEnabled: boolean;
        isPrescriptionFormEnabled: boolean;
    };

type PatientTriageSettingSelfReferralBaseProps =
    PatientTriageManagementSettingBaseProps & {
        isEnabled: boolean;
        hasError: boolean;
        countWord: number;
    };

type BookingDemoClickedBaseProps = {
    homepageItem: string;
};

//#endregion INTERFACES

export type PatientTriagePageLoadedProps = LoggedInCustomProperties &
    PatientTriagePageLoadedBaseProps;
export type PatientTriageRedirectEnabledProps = LoggedInCustomProperties &
    PatientTriageRedirectEnabledBaseProps;
export type PatientTriageDisableProps = LoggedInCustomProperties &
    PatientTriageDisableBaseProps;
export type PatientTriageOutOfHoursToggleProps = LoggedInCustomProperties &
    PatientTriageOutOfHoursToggleBaseProps;
export type ManageTeamsPageLoadedProps = LoggedInCustomProperties &
    ManageTeamsPageLoadedBaseProps;
export type EditTeamProps = LoggedInCustomProperties & EditTeamBaseProps;
export type OrganisationManagementItemProps = LoggedInCustomProperties &
    OrganisationManagementItemBaseProps;

export type PatientTriageManagementItemProps = LoggedInCustomProperties &
    PatientTriageOptionBaseProps;

export type PatientTriageManagementSettingProps = LoggedInCustomProperties &
    PatientTriageManagementSettingBaseProps;
export type PatientTriageManagementSettingSaveSuspendProps =
    LoggedInCustomProperties & PatientTriageSettingSaveSuspendBaseProps;
export type PatientTriageManagementSettingSaveOutOfHoursProps =
    LoggedInCustomProperties & PatientTriageSettingSaveOutOfHoursBaseProps;
export type PatientTriageManagementSettingSavePrescriptionProps =
    LoggedInCustomProperties & PatientTriageSettingSavePrescriptionsBaseProps;
export type PatientTriageManagementSettingSelfReferralProps =
    LoggedInCustomProperties & PatientTriageSettingSelfReferralBaseProps;

//#region MANAGE_PRACTICE

/**
 * User views the Manage Practice page
 * */
export const trackManagePracticePageView = (
    props: LoggedInCustomProperties,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "OrganisationManagement",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

/**
 * Loads "Patient Triage"
 *
 * Event type: Page load
 * */
export const trackPatientTriagePageLoaded = (
    props: PatientTriagePageLoadedProps,
): void => {
    TrackManagePracticeEvent("Opened Patient Triage Setting", props);

    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageManagement",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

/**
 * User turns on "Patient Triage"
 *
 * Event type: Button click
 * */
export const trackPatientTriageEnabledFeature = (
    props: LoggedInCustomProperties,
): void => {
    TrackManagePracticeEvent("Enabled Patient Triage", props);
};

/**
 * User turns on prescription redirect
 *
 * Event type: Button click
 * */
export const trackPatientTriagePrescriptionRedirectEnabled = (
    props: PatientTriageRedirectEnabledProps,
): void => {
    TrackManagePracticeEvent("Patient Triage Redirect Enabled", props);
};

/**
 * User disables patient triage
 *
 * Event type: Button click
 * */
export const trackPatientTriageTemporarilyDisabled = (
    props: PatientTriageDisableProps,
): void => {
    TrackManagePracticeEvent("Patient Triage Temporarily Disabled", props);
};

/**
 * User toggles patient triage
 *
 * Event type: Button click
 * */
export const trackPatientTriageToggledOutOfHoursPage = (
    props: PatientTriageOutOfHoursToggleProps,
): void => {
    TrackManagePracticeEvent(
        "Turned on Patient Triage Out of Hours Page",
        props,
    );
};

/**
 * Loads "Patient Triage Usage"
 *
 * Event type: Page load
 * */
export const trackPatientTriageUsagePageLoaded = (
    props: LoggedInCustomProperties,
): void => {
    TrackManagePracticeEvent("Opened Patient Triage Usage", props);
};

/**
 * User downloads patient triage usage csv
 *
 * Event type: Button click
 * */
export const trackPatientTriageUsageDownloaded = (
    props: PatientTriageManagementSettingProps,
): void => {
    TrackManagePracticeEvent("Downloaded Patient Triage Usage", props);

    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageManagementUsage",
        objectType: EventObjectType.Page,
        action: EventAction.Action,
        properties: fullProps,
    });
};

export const trackPatientTriageManagementMenuItemClicked = (
    props: PatientTriageManagementItemProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageManagement",
        objectType: EventObjectType.MenuItem,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackPatientTriageCreateRequestButtonClick = (
    props: PatientTriageManagementSettingProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageManagementIntro",
        objectType: EventObjectType.Page,
        action: EventAction.Action,
        properties: fullProps,
    });
};

export const trackPatientTriageSetupCopyLinkButtonClick = (
    props: PatientTriageManagementSettingProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageManagementSetUpPracticeLink",
        objectType: EventObjectType.Page,
        action: EventAction.Action,
        properties: fullProps,
    });
};

export const trackPatientTriageManagementResourceClick = (
    props: PatientTriageManagementSettingProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageManagementSetUpResource",
        objectType: EventObjectType.Page,
        action: EventAction.Action,
        properties: fullProps,
    });
};

export const trackPatientTriageManagementSettingWelcomeNote = (
    props: PatientTriageManagementSettingProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageManagementSettingWelcomeNote",
        objectType: EventObjectType.Page,
        action: EventAction.Action,
        properties: fullProps,
    });
};

export const trackPatientTriageManagementSettingConfirmationNote = (
    props: PatientTriageManagementSettingProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageManagementSettingSubmissionConfirmationNote",
        objectType: EventObjectType.Page,
        action: EventAction.Action,
        properties: fullProps,
    });
};

export const trackPatientTriageManagementSettingConfirmationMessage = (
    props: PatientTriageManagementSettingProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageManagementSettingConfirmationMessage",
        objectType: EventObjectType.Page,
        action: EventAction.Action,
        properties: fullProps,
    });
};

export const trackPatientTriageManagementSettingSuspend = (
    props:
        | PatientTriageManagementSettingProps
        | PatientTriageManagementSettingSaveSuspendProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageManagementSettingSuspend",
        objectType: EventObjectType.Page,
        action: EventAction.Action,
        properties: fullProps,
    });
};

export const trackPatientTriageManagementSettingSuspendNote = (
    props: PatientTriageManagementSettingProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageManagementSettingSuspendNote",
        objectType: EventObjectType.Page,
        action: EventAction.Action,
        properties: fullProps,
    });
};

export const trackPatientTriageManagementSettingOutOfHour = (
    props:
        | PatientTriageManagementSettingProps
        | PatientTriageManagementSettingSaveOutOfHoursProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageManagementSettingOutOfHour",
        objectType: EventObjectType.Page,
        action: EventAction.Action,
        properties: fullProps,
    });
};

export const trackPatientTriageManagementSettingOutOfHourNote = (
    props: PatientTriageManagementSettingProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 2,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageManagementSettingOutOfHourNote",
        objectType: EventObjectType.Page,
        action: EventAction.Action,
        properties: fullProps,
    });
};

export const trackPatientTriageManagementSettingPrescription = (
    props:
        | PatientTriageManagementSettingProps
        | PatientTriageManagementSettingSavePrescriptionProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageManagementSettingPrescription",
        objectType: EventObjectType.Page,
        action: EventAction.Action,
        properties: fullProps,
    });
};

export const trackPatientTriageManagementSettingSelfReferral = (
    props: PatientTriageManagementSettingSelfReferralProps,
): void => {
    const fullProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageManagementSettingSelfReferral",
        objectType: EventObjectType.Page,
        action: EventAction.Action,
        properties: fullProps,
    });
};

export const trackPatientTriageManagementSettingWorkingHours = (
    props: PatientTriageManagementSettingBaseProps,
): void => {
    const fullProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageSettingWorkingHour",
        objectType: EventObjectType.Page,
        action: EventAction.Action,
        properties: fullProps,
    });
};

/**
 * Loads "Manage Teams"
 *
 * Event type: Page load
 * */
export const trackManageTeamsPageLoaded = (
    props: ManageTeamsPageLoadedProps,
): void => {
    TrackManagePracticeEvent("Opened Manage Teams page", props);
};

/**
 * User adds another user to a team
 *
 * Event type: Button click
 * */
export const trackUserAddedToTeam = (props: EditTeamProps): void => {
    TrackManagePracticeEvent("User added to team", props);
};

/**
 * User removes another user from a team
 *
 * Event type: Button click
 * */
export const trackUserRemovedFromTeam = (props: EditTeamProps): void => {
    TrackManagePracticeEvent("User removed from team", props);
};

/**
 * User clicks link on Organisation Management page
 *
 * Event type: Link click
 * */
export const trackOrganisationManagementItemLinkClick = (
    props: OrganisationManagementItemProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "OrganisationManagementItem",
        objectType: EventObjectType.Link,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * User clicks on View Demo link in Messaging from the Manage Practice page
 * */
export const trackMessagingSelfBookViewDemoClick = (
    props: BookingDemoClickedBaseProps & LoggedInCustomProperties,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "HomepageItem",
        objectType: EventObjectType.ListItem,
        action: EventAction.Click,
        properties: fullProps,
    });
};

//#endregion MANAGE_PRACTICE
