import { createMutationHook } from "@accurx/api/QueryClient";
import { getApiEndpoint, httpClient, returnDataOrThrow } from "@accurx/shared";

import { IJoinOrganisationFormRequest } from "api/FlemingDtos";

export const useSubmitJoinOrganisationRequestMutation = createMutationHook<
    IJoinOrganisationFormRequest,
    undefined
>(() => ({
    mutationKey: ["submitJoinOrganisationRequest"],
    mutationFn: async ({
        organisationName,
        organisationType,
        organisationPostCode,
        reasonForAccess,
    }) => {
        const response = await httpClient.postJsonReturnSafeAsync(
            getApiEndpoint({
                path: "/api/account/OrganisationRequest",
            }),
            {
                organisationName,
                organisationType,
                organisationPostCode,
                reasonForAccess,
            },
        );

        return returnDataOrThrow(response);
    },
}));
