import {
    BatchPatientDataRequirements,
    BatchSettings,
    PrincipalSystem,
} from "@accurx/api/patient-messaging";
import { Text } from "@accurx/design";
import { IdentifiersMap } from "domains/batch-messaging/constants";

import { TickList, TickListItem } from "./TickList";

const ContactDetailsRequirement = ({
    header,
    description,
    isOptional = false,
}: {
    isOptional?: boolean;
    header: string;
    description: string;
}) => {
    return (
        <TickListItem haloColour={isOptional ? "yellow" : "green"}>
            <Text skinny>
                <Text as="span" variant="label" skinny>
                    {isOptional ? "(Optional) " : ""}
                    {header}
                </Text>{" "}
                {description}
            </Text>
        </TickListItem>
    );
};

interface RequiredColumnsListProps {
    requirements: BatchPatientDataRequirements;
    integratedSystem: BatchSettings["integratedSystem"];
}

export const RequiredColumnsList = ({
    requirements,
    integratedSystem,
}: RequiredColumnsListProps) => {
    const integratedSystemUsed =
        integratedSystem && integratedSystem !== PrincipalSystem.None
            ? integratedSystem
            : "NHS Spine";

    return (
        <>
            <Text skinny>
                To send a batch message, first upload a list of patients which
                should include:
            </Text>

            <TickList aria-label="required columns">
                <TickListItem>
                    <Text skinny>
                        <Text as="span" variant="label" skinny>
                            {IdentifiersMap[requirements.identifier]}
                        </Text>{" "}
                        to find the patient in the system.
                    </Text>
                </TickListItem>
                {requirements.dateOfBirthRequired && (
                    <TickListItem>
                        <Text skinny>
                            <Text as="span" variant="label" skinny>
                                Date of birth
                            </Text>{" "}
                            to verify the patient.
                        </Text>
                    </TickListItem>
                )}
                {
                    {
                        None: null,
                        MobileRequired: (
                            <ContactDetailsRequirement
                                header="UK mobile number"
                                description="to contact the patient."
                            />
                        ),
                        MobileOptional: (
                            <ContactDetailsRequirement
                                header="UK mobile number"
                                description={`to contact the patient instead of using details from ${integratedSystemUsed}.`}
                                isOptional
                            />
                        ),
                        EmailOptional: (
                            <ContactDetailsRequirement
                                header="Email"
                                description={`to contact the patient instead of using SMS details from ${integratedSystemUsed}. If an email is provided, we will only communicate using email.`}
                                isOptional
                            />
                        ),
                        MobileOrEmailRequired: (
                            <ContactDetailsRequirement
                                header="Email or UK mobile number"
                                description="to contact the patient."
                            />
                        ),
                        MobileOrEmailOptional: (
                            <ContactDetailsRequirement
                                header="Email or UK mobile number"
                                description={`to contact the patient instead of using details from ${integratedSystemUsed}. If an email is provided, we will only communicate using email.`}
                                isOptional
                            />
                        ),
                    }[requirements.contactDetailsRequirement]
                }
            </TickList>
        </>
    );
};
