import {
    MessagePatientRequestWithExternalIdentity,
    MessagePatientRequestWithToken,
    MessagePatientResponse,
} from "@accurx/api/patient-messaging";
import { getApiEndpoint, httpClient } from "@accurx/shared";
import isNil from "lodash/isNil";

const ENDPOINTS = {
    messagePatientWithId: "/api/patientmessaging/messagepatientwithid",
    messagePatientWithToken: "/api/patientmessaging/messagepatientwithtoken",
} as const;

/**
 * Send a message as part of an existing conversation
 * or create a new conversation
 * when we have a patient token as a result of a patient search.
 *
 * User must have previously searched for the patient in order to be able to use this endpoint
 */
export const messagePatientWithToken = async (
    request: Required<MessagePatientRequestWithToken>,
): Promise<MessagePatientResponse> => {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.messagePatientWithToken,
        }),
        request,
    );

    if (!response.success || isNil(response.result)) {
        throw new Error(response.error ?? "Network connection error!");
    }

    return response.result as MessagePatientResponse;
};

/**
 * Send a message as part of an existing conversation
 * or create a new conversation
 * when we don't have a patient token as a result of a patient search.
 *
 * User must be on trusted device, in order to be able to successfully use this endpoint
 */
export const messagePatientWithExternalIdentity = async (
    request: Required<MessagePatientRequestWithExternalIdentity>,
): Promise<MessagePatientResponse> => {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.messagePatientWithId,
        }),
        request,
    );

    if (!response.success || isNil(response.result)) {
        throw new Error(response.error ?? "Network connection error!");
    }

    return response.result as MessagePatientResponse;
};
