import { useState } from "react";

import { Ds, FormFieldWrapper, SelectItem } from "@accurx/design";

import {
    trackCategoriesFilterClear,
    trackCategoriesFilterClick,
    trackCategoriesFilterSelect,
} from "../../analytics/FlemingAnalytics";
import { useFlemingLoggedInAnalytics } from "../../sessionAnalytics/useFlemingLoggedInAnalytics";
import { StyledSelect } from "./ManageTemplates.styles";

const noCategoryString = "Templates with no category";
const allTemplatesString = "All templates";

export const CategoriesFilter = ({
    distinctCategories,
    onFilterSelect,
    currentTable,
}: {
    distinctCategories: string[];
    onFilterSelect: (filter: (templateCategory: string) => boolean) => void;
    currentTable: string;
}): JSX.Element => {
    const [selected, setSelected] = useState<string>(allTemplatesString);
    const flemingLoggedInProps = useFlemingLoggedInAnalytics();
    const [open, setOpen] = useState(false);

    const handleFilter = (selectedCategory: string) => {
        trackCategoriesFilterSelect({
            ...flemingLoggedInProps,
            filterName: "Category",
            currentTable,
            optionSelected: selectedCategory,
        });
        setSelected(selectedCategory);

        if (selectedCategory === allTemplatesString) {
            onFilterSelect(() => true);
        } else {
            const categoryFilter =
                selectedCategory === noCategoryString ? "" : selectedCategory;
            onFilterSelect(
                (templateCategory) => templateCategory === categoryFilter,
            );
        }
    };
    const handleClearFilter = () => {
        trackCategoriesFilterClear({ ...flemingLoggedInProps, currentTable });
        onFilterSelect(() => true);
        setSelected(allTemplatesString);
    };
    const handleOpen = (isOpen: boolean) => {
        if (isOpen) {
            trackCategoriesFilterClick({
                ...flemingLoggedInProps,
                filterName: "Category",
                currentTable,
            });
        }
        setOpen(isOpen);
    };

    return (
        <FormFieldWrapper label="Filter by category">
            <Ds.Grid
                columns={{
                    sm: "1fr",
                    lg: "4fr 1fr",
                }}
                gap="1"
            >
                <StyledSelect
                    name={"Select category"}
                    dimension="medium"
                    placeholder={"Select category"}
                    onValueChange={handleFilter}
                    value={selected} //handling this manually so we can clear from the outside
                    onOpenChange={handleOpen}
                    open={open}
                >
                    <SelectItem value={allTemplatesString}>
                        {allTemplatesString}
                    </SelectItem>

                    {distinctCategories.map((category) => {
                        const categoryValue =
                            category === "" ? noCategoryString : category;

                        return (
                            <SelectItem key={category} value={categoryValue}>
                                {categoryValue}
                            </SelectItem>
                        );
                    })}
                </StyledSelect>
                <div>
                    <Ds.Button
                        onClick={handleClearFilter}
                        appearance={"secondary"}
                    >
                        <Ds.Button.Icon name="Refresh" />
                        Clear
                    </Ds.Button>
                </div>
            </Ds.Grid>
        </FormFieldWrapper>
    );
};
