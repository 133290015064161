import { useCallback } from "react";

import { EventPayload } from "../types";
import { SignalRSendEvent } from "../types/signalR/events";
import { useRealtimeForSignalR } from "./useRealtimeForSignalR";

export const useSendSignalREvent = <T extends SignalRSendEvent>(
    eventName: T,
) => {
    const { send, connectionState } = useRealtimeForSignalR();

    return useCallback(
        (payload: EventPayload<T>) => {
            if (connectionState !== "Connected") return;
            send({ type: eventName, payload });
        },
        [connectionState, send, eventName],
    );
};
