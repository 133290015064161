import { filters } from "domains/concierge/internal/util/conversationGroupFilters";
import { sortOptions } from "domains/concierge/internal/util/conversationGroupSortOptions";

import { useConversationCount } from "./useConversationCount";

export const useAllConversationsPatientResponsesConversationCount = () => {
    const status = "Open";
    const sort = "Newest";
    return useConversationCount(
        filters.allPatientResponses({ status }),
        sortOptions.common({ status, sort }),
    );
};
