import { useAnalytics } from "@accurx/analytics";
import { Ds } from "@accurx/design";
import { useNativeTrackingFields } from "@accurx/native";

import {
    StyledBarContainer,
    StyledIconButton,
} from "./ExpandMinimiseBar.styles";

export const ExpandMinimiseBar = ({
    onExpandClick,
    isHeightRestricted,
    onMinimiseClick,
}: {
    onExpandClick?: () => void;
    onMinimiseClick?: () => void;
    isHeightRestricted: boolean;
}) => {
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();

    return (
        <StyledBarContainer
            justifyContent="flex-end"
            alignItems="center"
            gap="1"
        >
            {onExpandClick && (
                <StyledIconButton
                    title={isHeightRestricted ? "Expand" : "Collapse"}
                    onClick={() => {
                        track("Maximise Button Click", {
                            ...nativeTrackingFields,
                            conversationParticipant: "WithPatient",
                            isExpanded: !isHeightRestricted,
                        });
                        onExpandClick();
                    }}
                >
                    <Ds.Button.Icon
                        name={isHeightRestricted ? "Expand" : "Collapse"}
                        aria-label={isHeightRestricted ? "Expand" : "Collapse"}
                    />
                </StyledIconButton>
            )}
            {onMinimiseClick && (
                <StyledIconButton
                    title="Minimise"
                    onClick={() => {
                        track("Minimise Button Click", {
                            ...nativeTrackingFields,
                            conversationParticipant: "WithPatient",
                            isExpanded: !isHeightRestricted,
                        });
                        onMinimiseClick();
                    }}
                >
                    <Ds.Button.Icon name="Minimise" aria-label="Minimise" />
                </StyledIconButton>
            )}
        </StyledBarContainer>
    );
};
