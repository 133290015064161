import { useEffect } from "react";

import { FeatureName } from "@accurx/auth";
import { Ds, Icon, Text } from "@accurx/design";
import {
    AutoFocusHeading,
    useAccurxWebTitle,
    useIsManageOrg,
} from "@accurx/navigation";
import { SupportUrls } from "@accurx/shared";
import { generatePath, useHistory } from "react-router-dom";

import { ChainAnalyticsTracker } from "app/analytics";
import {
    StyledBatchTypeList,
    StyledLayoutWithFooter,
} from "app/batchMessage/gp/BatchMessage.styles";
import { BatchType } from "app/batchMessage/gp/BatchMessage.types";
import { BatchMessageChooseCard } from "app/batchMessage/gp/components/BatchMessageChooseCard";
import { usePractices } from "app/organisations/hooks";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { StepsFooter } from "app/sharedComponents/footer/StepsFooter";
import { ROUTES_ORGS, ROUTES_WORKSPACE, SLUGS_GP_BATCH } from "shared/Routes";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

import { NhsAppAnouncementCard } from "../components/NhsAppAnnouncementCard";
import { BorderTopContainer } from "./BatchMessageChoose.styles";

export const BatchMessageChoose = (): JSX.Element => {
    useAccurxWebTitle("Choose batch message type");

    const practices = usePractices();
    const history = useHistory<{ from?: string }>();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const isManageOrg = useIsManageOrg();

    // Feature flags
    const batchSelfBookEnabled = useIsFeatureEnabled(FeatureName.BatchSelfBook);
    const batchInhalerEnabled = useIsFeatureEnabled(
        FeatureName.BehaviourChangeInhalerRct,
    );
    const batchSmsAndFloreyEnabled = useIsFeatureEnabled(
        FeatureName.BatchMessagingFlorey,
    ); // Checks whether practice is a paid Accurx plus user i.e automatically has both SMS and Florey
    const batchGskFloreyEnabled = useIsFeatureEnabled(
        FeatureName.BatchGskFlorey,
    );
    const payingBatchUsers = batchSmsAndFloreyEnabled;
    const nonPayingBatchUsers =
        batchGskFloreyEnabled && !batchSmsAndFloreyEnabled;
    const nhsAppMessagesEnabled = useIsFeatureEnabled(
        FeatureName.BatchNhsAppMessagingNationalRollout,
    );
    const nhsAppSelfBookEnabled = useIsFeatureEnabled(
        FeatureName.NhsAppMessagingBatchSelfBook,
    );

    // Store
    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );

    useEffect(() => {
        ChainAnalyticsTracker.trackBatchTypeSelectPageView(
            analyticsLoggedInProps,
        );
    }, [analyticsLoggedInProps]);

    if (!practices.selectedPractice) return <></>;

    const handleBack = () => {
        ChainAnalyticsTracker.trackBatchBackClick({
            ...analyticsLoggedInProps,
            origin: history.location.pathname,
            isTrustFlow: false,
        });
    };

    const previousRoute = history?.location?.state?.from;
    const manageOrgBatchRoute = generatePath(ROUTES_ORGS.allMessages, {
        orgId: practiceId,
        tab: "batch-messages",
    });
    const accurxWebBatchRoute = generatePath(
        ROUTES_WORKSPACE.batchMessageTrustSentMessages,
        { workspaceId: practiceId },
    );

    const defaultBackRoute = isManageOrg
        ? manageOrgBatchRoute
        : accurxWebBatchRoute;

    const backRoute = previousRoute ?? defaultBackRoute;

    return (
        <StyledLayoutWithFooter>
            <Breadcrumb title="Batch Messaging" />
            <Ds.Flex justifyContent="center">
                <Ds.Flex.Item flex={{ sm: "1", lg: "0.5" }}>
                    <Ds.Box px="2">
                        <AutoFocusHeading
                            size="xxlarge"
                            mt="2"
                            mb="1.5"
                            className="text-center"
                        >
                            Choose a batch type
                        </AutoFocusHeading>

                        <StyledBatchTypeList>
                            {batchSelfBookEnabled && (
                                <BatchMessageChooseCard
                                    title={BatchType.SELFBOOK}
                                    primaryLink={SLUGS_GP_BATCH.composeSelfbook}
                                    description={
                                        nhsAppSelfBookEnabled
                                            ? `Invite patients to book face-to-face appointments in your clinical system.
                                If patients have the NHS App with the notification turned
                                    on, they will receive self book links via the app.`
                                            : `Invite patients to book face-to-face appointments in your clinical system.`
                                    }
                                    secondaryLinkDetails={{
                                        url: SupportUrls.SelfBookFaceToFace,
                                        description: "Learn more",
                                    }}
                                />
                            )}

                            {payingBatchUsers && (
                                <>
                                    <BatchMessageChooseCard
                                        title={BatchType.MESSAGE}
                                        primaryLink={SLUGS_GP_BATCH.composeSms}
                                        description="Send a message and choose to attach a file via email, SMS or the NHS App."
                                    />
                                    <BatchMessageChooseCard
                                        title={BatchType.FLOREY}
                                        primaryLink={
                                            SLUGS_GP_BATCH.composeFlorey
                                        }
                                        description="Send a Florey questionnaire to gather patient’s info via email, SMS or the NHS App."
                                    />
                                    {batchInhalerEnabled && (
                                        <BatchMessageChooseCard
                                            title={BatchType.INHALER}
                                            primaryLink={
                                                SLUGS_GP_BATCH.composeInhaler
                                            }
                                            description="Send an invite to patients to sign up to the Accurx Inhaler Adherence Study."
                                        />
                                    )}
                                    {nhsAppMessagesEnabled && (
                                        <NhsAppAnouncementCard
                                            title="Batch Florey and Message are available in the NHS App now!"
                                            messageCopy="batch Floreys and batch Messages"
                                        />
                                    )}
                                </>
                            )}
                            {nonPayingBatchUsers && (
                                <>
                                    <BatchMessageChooseCard
                                        title={BatchType.COPD}
                                        primaryLink={SLUGS_GP_BATCH.composeCopd}
                                        description="This is the only batch questionnaire available for your practice."
                                    />
                                    {batchInhalerEnabled && (
                                        <BatchMessageChooseCard
                                            title={BatchType.INHALER}
                                            primaryLink={
                                                SLUGS_GP_BATCH.composeInhaler
                                            }
                                            description="Send an invite to patients to sign up to the Accurx Inhaler Adherence Study."
                                        />
                                    )}
                                    {nhsAppMessagesEnabled && (
                                        <NhsAppAnouncementCard
                                            title="COPD questionnaire is available in the NHS App now!"
                                            messageCopy="this questionnaire"
                                        />
                                    )}
                                </>
                            )}

                            {nonPayingBatchUsers && (
                                <BorderTopContainer>
                                    <Text variant="subtitle" skinny>
                                        How can I have more types of Florey
                                        questionnaires?
                                    </Text>
                                    <Text className="mt-3">
                                        Your practice will need to purchase
                                        Accurx Plus to send other types of
                                        Florey questionnaires and SMS.
                                    </Text>
                                    <Text as="span">Learn more about </Text>
                                    <Text
                                        as="a"
                                        variant="link"
                                        props={{
                                            href: SupportUrls.BatchAccessGuide,
                                            target: "_blank",
                                            rel: "noopener noreferrer",
                                            onClick: () => {
                                                ChainAnalyticsTracker.trackBatchResourceClick(
                                                    {
                                                        ...analyticsLoggedInProps,
                                                        origin: history.location
                                                            .pathname,
                                                        batchResourceName:
                                                            SupportUrls.BatchAccessGuide,
                                                    },
                                                );
                                            },
                                        }}
                                    >
                                        full access to Batch Messaging
                                        <Icon
                                            name="OpenWindow"
                                            size={3}
                                            colour="blue"
                                        />
                                    </Text>
                                </BorderTopContainer>
                            )}
                        </StyledBatchTypeList>
                    </Ds.Box>
                </Ds.Flex.Item>
            </Ds.Flex>
            <StepsFooter
                backText="Back"
                backLink={backRoute}
                backClickFunction={handleBack}
                disabled={false}
                zIndex={2}
            />
        </StyledLayoutWithFooter>
    );
};

export default BatchMessageChoose;
