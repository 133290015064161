import { Log } from "@accurx/shared";
import { AnyAction } from "@reduxjs/toolkit";
import { ConciergeMeta } from "domains/concierge/internal/types/ConciergeMeta";
import { ConciergeState } from "domains/concierge/internal/types/ConciergeState";
import { ConciergeUpdates } from "domains/concierge/testing";
import difference from "lodash/difference";
import { ThunkMiddleware } from "redux-thunk";

const getWorkspaceUnreadCount = (state: ConciergeState): number => {
    const unreadCounts = state.conversations.unreadCounts.ticket;

    let workspaceUnreadCount = unreadCounts.user;

    for (const teamId in state.conversations.teamMembership) {
        if (state.teams.items[teamId].isMember) {
            workspaceUnreadCount += unreadCounts.teams[teamId] ?? 0;
        }
    }

    return workspaceUnreadCount;
};

const extractTagsFromAction = (action: AnyAction) => {
    if (action.type !== "processUpdates") {
        return {};
    }

    const payload = action.payload as ConciergeUpdates & { source: string };

    const conversationIds = payload.conversations.map((c) => c.id).join(",");
    const teamIds = payload.teams.map((t) => t.id).join(",");
    const patientIds = payload.patients.map((p) => p.patientId).join(",");
    const userIds = payload.users.map((u) => u.id).join(",");

    return {
        actionSource: payload.source,
        conversationIds,
        teamIds,
        patientIds,
        userIds,
    };
};

const getTeamMembershipChangeTags = (
    before: ConciergeState,
    after: ConciergeState,
) => {
    const beforeMemberTeams = Object.keys(before.conversations.teamMembership);
    const afterMemberTeams = Object.keys(after.conversations.teamMembership);
    const memberTeamsAdded = difference(afterMemberTeams, beforeMemberTeams);
    const memberTeamsRemoved = difference(beforeMemberTeams, afterMemberTeams);

    return {
        beforeMemberTeamsCount: beforeMemberTeams.length,
        afterMemberTeamsCount: afterMemberTeams.length,
        memberTeamsCountDiff:
            afterMemberTeams.length - beforeMemberTeams.length,
        memberTeamsAdded: memberTeamsAdded.join(","),
        memberTeamsRemoved: memberTeamsRemoved.join(","),
    };
};

export const debug = (
    meta: ConciergeMeta,
): ThunkMiddleware<ConciergeState, AnyAction> => {
    return (store) => (next) => (action: AnyAction) => {
        const beforeState = store.getState();
        const beforeUnreadCount = getWorkspaceUnreadCount(beforeState);

        const result = next(action);

        const afterState = store.getState();
        const afterUnreadCount = getWorkspaceUnreadCount(afterState);

        const unreadCountDiff = afterUnreadCount - beforeUnreadCount;

        if (unreadCountDiff < -1) {
            Log.info("High workspace unread count drop detected", {
                tags: {
                    workspaceId: meta.workspaceId,
                    userId: meta.userId,
                    conciergeSessionId: meta.sessionId,
                    conciergeInstanceId: meta.instanceId,
                    actionType: action.type,
                    beforeUnreadCount,
                    afterUnreadCount,
                    unreadCountDiff: afterUnreadCount - beforeUnreadCount,
                    ...extractTagsFromAction(action),
                    ...getTeamMembershipChangeTags(beforeState, afterState),
                },
            });
        }

        return result;
    };
};
