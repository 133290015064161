import { TriageType } from "domains/concierge/internal/types/ConversationGroupFilters";
import { filters } from "domains/concierge/internal/util/conversationGroupFilters";
import { sortOptions } from "domains/concierge/internal/util/conversationGroupSortOptions";

import { useConversationCount } from "./useConversationCount";

export type Variables = {
    triageType: TriageType;
    teamId: string;
    status: "Open" | "Done";
};

export const useUnassignedTriageConversationCount = ({
    teamId,
    status,
    triageType,
}: Variables) => {
    return useConversationCount(
        filters.unassignedTriage({ teamId, status, triageType }),
        sortOptions.common({ status, sort: "Newest" }),
    );
};
