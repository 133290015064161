import { Ds } from "@accurx/design";

export type TranslatedLanguageWarningModalProps = Pick<
    Ds.ModalProps,
    "open" | "onOpenChange"
> & {
    onConfirm: () => void;
    onCancel: () => void;
};

export const TranslatedLanguageWarningModal = ({
    onOpenChange,
    onConfirm,
    onCancel,
    open,
}: TranslatedLanguageWarningModalProps) => {
    return (
        <Ds.Modal open={open} onOpenChange={onOpenChange}>
            <Ds.Modal.Section>
                <Ds.Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>
                        Warning - please review before saving
                    </Ds.Modal.Title>
                    <Ds.Text>
                        This text may not be in English and may contain
                        characters that may not display accurately in the
                        medical record.
                    </Ds.Text>
                    <Ds.Flex justifyContent="space-between" gap="2">
                        <Ds.Button appearance="secondary" onClick={onCancel}>
                            Cancel
                        </Ds.Button>
                        <Ds.Button appearance="primary" onClick={onConfirm}>
                            Save to record
                        </Ds.Button>
                    </Ds.Flex>
                </Ds.Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
