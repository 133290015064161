import React, { ReactNode } from "react";

import { StyledPillButton } from "./RecipientSelectorButton.styles";

type RecipientSelectorButtonProps = {
    children: ReactNode;
    onClick: () => void;
    isError: boolean;
};

export const RecipientSelectorButton = React.forwardRef<
    HTMLButtonElement,
    RecipientSelectorButtonProps
>(({ children, isError, onClick, ...props }, ref) => {
    return (
        <StyledPillButton
            data-testid="recipient-selector-button"
            radius="full"
            onClick={onClick}
            isError={isError}
            ref={ref}
            {...props}
            aria-label="Edit contact details"
        >
            {children}
        </StyledPillButton>
    );
});
