import { send } from "domains/concierge/internal/api/clinicianMessaging/mutations/send";
import { getConversation } from "domains/concierge/internal/api/clinicianMessaging/queries/getConversation";
import { getConversations } from "domains/concierge/internal/api/clinicianMessaging/queries/getConversations";
import { getTeams } from "domains/concierge/internal/api/clinicianMessaging/queries/getTeams";
import { getUnreadSummaries } from "domains/concierge/internal/api/clinicianMessaging/queries/getUnreadSummaries";

import { markAsDone } from "./mutations/markAsDone";
import { markAsOpen } from "./mutations/markAsOpen";
import { markItemsAsRead } from "./mutations/markItemsAsRead";
import { messagePractice } from "./mutations/messagePractice";
import { updateAssigneeWithNote } from "./mutations/updateAssigneeWithNote";

export const api = {
    getConversations,
    getConversation,
    getUnreadSummaries,
    getTeams,
    send,
    messagePractice,
    markAsDone,
    markAsOpen,
    updateAssigneeWithNote,
    markItemsAsRead,
};
