import { AssigneeSummary, TeamSummary } from "@accurx/concierge/types";

export const getAssigneeTrackingFields = ({
    userId,
    assignee,
    teams,
}: {
    userId: string;
    assignee: Exclude<AssigneeSummary, { type: "None" }>;
    teams: TeamSummary[];
}) => {
    const { type, id } = assignee;

    if (type === "Team") {
        const teamInfo = teams.find((item) => item.id === id);

        return {
            assigneeType: type,
            assigneeTeamId: id,
            isAssignedToSelf: false,
            assigneeTeamName: teamInfo?.displayName,
            assigneeTeamType: teamInfo?.type,
            eventVersion: 2,
        };
    }

    return {
        assigneeType: type,
        isAssignedToSelf: id === userId,
        assigneeUserId: id,
        eventVersion: 2,
    };
};
