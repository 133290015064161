import {
    NoteLinkType,
    PatientThreadContentLinkNote,
    PatientThreadContentType,
    PatientThreadEmailLinkNote,
    PatientThreadNhsAppMessageLinkNote,
    PatientThreadSmsLinkNote,
} from "@accurx/api/ticket";
import { NO_ITEM_CONTENT_ERROR } from "domains/concierge/internal/api/shared/mappers/conversationItemMappers/conversationItem.constants";
import { mapBaseConversationItem } from "domains/concierge/internal/api/shared/mappers/conversationItemMappers/mapBaseConversationItem";
import { mapTicketItemIdentityToConversationItemId } from "domains/concierge/internal/api/shared/mappers/mapTicketItemIdentityToConversationItemId";
import {
    FailedDeliveryReceiptLinkItem,
    FallbackFailedLinkItem,
    VideoConsultLinkItem,
} from "domains/concierge/types";

export function mapSmsLinkItem(
    ticketLinkItem: PatientThreadSmsLinkNote,
):
    | FailedDeliveryReceiptLinkItem
    | VideoConsultLinkItem
    | FallbackFailedLinkItem
    | undefined {
    if (!ticketLinkItem.message) {
        throw new Error(NO_ITEM_CONTENT_ERROR);
    }

    if (!ticketLinkItem.message.linkedSmsId) {
        throw new Error(
            "Cannot map SMS Link Note item as it does not contain a linked item ID",
        );
    }

    const message = mapLinkItemMessage(ticketLinkItem.message);
    const baseConversationItem = mapBaseConversationItem(ticketLinkItem);
    const linkedItemId = mapTicketItemIdentityToConversationItemId({
        type: PatientThreadContentType.SMS,
        id: ticketLinkItem.message.linkedSmsId,
    });

    return {
        ...baseConversationItem,
        ...message,
        linkedItemId,
    };
}

export function mapEmailLinkItem(
    ticketLinkItem: PatientThreadEmailLinkNote,
):
    | FailedDeliveryReceiptLinkItem
    | VideoConsultLinkItem
    | FallbackFailedLinkItem
    | undefined {
    if (!ticketLinkItem.message) {
        throw new Error(NO_ITEM_CONTENT_ERROR);
    }

    if (!ticketLinkItem.message.linkedEmailId) {
        throw new Error(
            "Cannot map Email Link Note item as it does not contain a linked item ID",
        );
    }

    const message = mapLinkItemMessage(ticketLinkItem.message);
    const baseConversationItem = mapBaseConversationItem(ticketLinkItem);
    const linkedItemId = mapTicketItemIdentityToConversationItemId({
        type: PatientThreadContentType.PatientEmail,
        id: ticketLinkItem.message.linkedEmailId,
    });

    return {
        ...baseConversationItem,
        ...message,
        linkedItemId,
    };
}

export function mapNhsAppMessageLinkItem(
    ticketLinkItem: PatientThreadNhsAppMessageLinkNote,
):
    | FailedDeliveryReceiptLinkItem
    | VideoConsultLinkItem
    | FallbackFailedLinkItem
    | undefined {
    if (!ticketLinkItem.message) {
        throw new Error(NO_ITEM_CONTENT_ERROR);
    }

    if (!ticketLinkItem.message.linkedNhsAppMessageId) {
        throw new Error(
            "Cannot map NHS App Message Link Note item as it does not contain a linked item ID",
        );
    }

    const message = mapLinkItemMessage(ticketLinkItem.message);
    const baseConversationItem = mapBaseConversationItem(ticketLinkItem);
    const linkedItemId = mapTicketItemIdentityToConversationItemId({
        type: PatientThreadContentType.NhsAppMessage,
        id: ticketLinkItem.message.linkedNhsAppMessageId,
    });

    return {
        ...baseConversationItem,
        ...message,
        linkedItemId,
    };
}

function mapLinkItemMessage(
    message: PatientThreadContentLinkNote,
):
    | Pick<FailedDeliveryReceiptLinkItem, "contentType" | "createdBy">
    | Pick<FallbackFailedLinkItem, "contentType" | "createdBy">
    | Pick<VideoConsultLinkItem, "contentType" | "createdBy" | "clinicianUrl"> {
    switch (message.linkType) {
        case NoteLinkType.FailedDeliveryReceipt:
            return {
                contentType: "FailedDeliveryReceiptLink",
                createdBy: { type: "System" },
            };

        case NoteLinkType.FallbackFailed:
            return {
                contentType: "FallbackFailedLink",
                createdBy: { type: "System" },
            };

        case NoteLinkType.VideoConsult:
            if (!message.clinicianUrl) {
                throw new Error(
                    "Cannot map ticket item as link item is of type Video Consult, but does not contain a valid clinician url",
                );
            }

            return {
                contentType: "VideoConsultLink",
                createdBy: { type: "System" },
                clinicianUrl: message.clinicianUrl,
            };

        case NoteLinkType.None:
        default:
            throw new Error(
                "Cannot map ticket item as the link type is unknown",
            );
    }
}
