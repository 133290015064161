import { createMutationHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import { TemplateResponse, UpdateTemplate } from "@accurx/api/content";
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";

export type EditTemplateMutationProps = {
    workspaceId: number;
    templateId: string;
    template: UpdateTemplate;
};

const editMessageTemplate = ({
    workspaceId,
    templateId,
    request,
}: {
    workspaceId: string;
    templateId: string;
    request: UpdateTemplate;
}): Promise<IWrappedResult<TemplateResponse>> => {
    return httpClient.putJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/content/workspace/:workspaceId/templates/:templateId",
            params: {
                workspaceId,
                templateId: templateId,
            },
        }),
        request,
    );
};

export const useEditMessageTemplateMutation = createMutationHook<
    EditTemplateMutationProps,
    TemplateResponse
>(() => ({
    mutationKey: ["EditMessageTemplate"],
    mutationFn: async (templateMutation) => {
        const response = await editMessageTemplate({
            workspaceId: templateMutation.workspaceId.toString(),
            templateId: templateMutation.templateId,
            request: templateMutation.template,
        });
        return returnDataOrThrow(response);
    },
}));
