import { NativeApi } from "domains/native/api/NativeApi";
import { createEndpoint } from "domains/native/api/api.utils";
import { PatientExternalIdentityDto } from "domains/native/schemas/PatientExternalIdentityDtoSchema";
import {
    QueryPatientCommunicationConsentResponse,
    QueryPatientCommunicationConsentResponseSchema,
} from "domains/native/schemas/QueryPatientCommunicationConsentSchema";

export type PatientCommunicationConsentQueryPayload = {
    patientExternalIdentityDto: PatientExternalIdentityDto;
};

export const patientCommunicationConsentQuery = createEndpoint<
    QueryPatientCommunicationConsentResponse,
    PatientCommunicationConsentQueryPayload
>("QueryPatientCommunicationConsent", async (context, payload) => {
    const result = await NativeApi.request({
        transport: context.transport,
        request: {
            type: "QueryPatientCommunicationConsent",
            workspaceId: context.workspaceId,
            payload: payload,
        },
    });

    return QueryPatientCommunicationConsentResponseSchema.parse(result);
});
