import { Button, Feedback, Text } from "@accurx/design";
import styled from "styled-components";

import { isFilteredByInviteId } from "./VaccineAllPatientsInvited.helper";

const StyledFeedback = styled(Feedback)`
    width: 100%;

    // This should fix the wrapper added by Feedback component to take all the space and position it's content properly
    & > div:last-child {
        width: 100%;
    }
`;

export interface VaccineAllPatientsInvitedFilterFeedbackProps {
    searchTerm: string;
    serverLimitedResults: boolean;
    onClearFilter: () => void;
}

export const VaccineAllPatientsInvitedFilterFeedback = ({
    searchTerm,
    serverLimitedResults,
    onClearFilter,
}: VaccineAllPatientsInvitedFilterFeedbackProps): JSX.Element | null => {
    if (isFilteredByInviteId(searchTerm)) {
        return (
            <StyledFeedback colour="information">
                <Text skinny>
                    The active filter may hide some patients in your list. Clear
                    the filter and then search for a patient to see their
                    current status.
                </Text>
                <Button
                    theme="secondary"
                    text="Clear filter"
                    icon={{ name: "Cross", colour: "blue" }}
                    className="mt-2 float-right"
                    onClick={onClearFilter}
                />
            </StyledFeedback>
        );
    }

    if (serverLimitedResults) {
        return (
            <Feedback
                colour="information"
                content={
                    <>
                        The active filter may only have returned some patient
                        matches for your search terms. Either clear the filter
                        to view the full list, or try to narrow down your search
                        further.
                        <Button
                            theme="secondary"
                            text="Clear filter"
                            icon={{ name: "Cross", colour: "blue" }}
                            className="mt-2 float-right"
                            onClick={onClearFilter}
                        />
                    </>
                }
                props={{ className: "mb-2 w-100" }}
            />
        );
    }

    return null;
};
