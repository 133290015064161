import { ReactNode } from "react";

import { useCurrentUser } from "@accurx/auth";
import {
    useInboxLink,
    useInboxParams,
    useSearchParams,
} from "domains/inbox/hooks/util";
import "domains/inbox/hooks/util/useInboxParams";
import { useHistory, useLocation } from "react-router-dom";

import {
    getSortOrder,
    getSortOrderKey,
} from "../ConversationList/components/ConversationSortOrder/saveSortOrder";

export const ConversationSortWrapper = ({
    children,
}: {
    children: ReactNode;
}) => {
    const { user } = useCurrentUser();
    const { pathname } = useLocation();
    /**
     * Both sort and status are retrieved from the sort params. However, we
     * retrieve sort directly from `useSearchParams` so that we can tell if
     * there is no sort specified in the url. `useInboxParams` gives a default
     * value if the param is unspecified which makes it unsuited for retrieving
     * the sort here.
     */
    const { sort } = useSearchParams();
    const { status } = useInboxParams(["status"]);

    const history = useHistory();
    const link = useInboxLink();

    const key = getSortOrderKey({
        userId: user.accuRxUserId,
        pathname,
        status,
    });
    const storedSort = getSortOrder({ key });

    if (!sort && storedSort) {
        const href = link.update({ sort: storedSort });
        history.replace(href);
        return null;
    }
    return <>{children}</>;
};
