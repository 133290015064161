import { Ds, Hooks, Tokens } from "@accurx/design";
import { DirectoryContent } from "domains/directory/components/DirectoryContent/DirectoryContent";
import {
    DirectoryMobileModalHeader,
    DirectoryModalHeader,
} from "domains/directory/components/DirectoryModal/DirectoryModalHeader";
import { DirectoryProvider } from "domains/directory/context/DirectoryProvider";
import styled from "styled-components";

export const DirectoryModal = ({ closeModal }: { closeModal: () => void }) => {
    const { size } = Hooks.useViewportSize();

    const isMobileSize = ["xs", "sm"].includes(size);

    return (
        <StyledModal
            size="large"
            open={true}
            onOpenChange={closeModal}
            fullscreen="lg"
        >
            <DirectoryProvider>
                {isMobileSize ? (
                    <DirectoryMobileModalHeader />
                ) : (
                    <DirectoryModalHeader />
                )}
                <Ds.Modal.Divider />
                <DirectoryContent />
            </DirectoryProvider>
        </StyledModal>
    );
};

const StyledModal = styled(Ds.Modal)`
    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        display: flex;
        flex-direction: column;
        min-height: 700px;
        max-height: 90%;
        overflow: hidden;
        width: 900px;
    }
`;
