import { ReactNode, useMemo } from "react";

import {
    ConciergeContext,
    ConciergeMetaProvider,
} from "domains/concierge/internal/context";
import { createStore } from "domains/concierge/internal/store";
import { Provider as ReduxProvider } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { ConciergeMeta } from "./types/ConciergeMeta";

/**
 * Create a unique ID for the entire web session. This may be useful in logging
 * to connect events together coming from a single session.
 */
const sessionId = uuidv4();

export const ConciergeReduxProvider = ({
    workspaceId,
    userId,
    isTrustedDevice,
    children,
}: Pick<ConciergeMeta, "workspaceId" | "userId" | "isTrustedDevice"> & {
    children: ReactNode;
}) => {
    const { store, instanceId } = useMemo(() => {
        /**
         * Create a unique ID for the store instance. This may be useful in
         * logging to ses when the user changes org and a new Concierge instance
         * gets spun up.
         */
        const instanceId = uuidv4();

        const store = createStore({
            workspaceId,
            userId,
            isTrustedDevice: true,
            sessionId,
            instanceId,
        });

        return { store, instanceId };
    }, [workspaceId, userId]);

    return (
        <ConciergeMetaProvider
            value={{
                workspaceId,
                userId,
                isTrustedDevice,
                sessionId,
                instanceId,
            }}
        >
            <ReduxProvider context={ConciergeContext} store={store}>
                {children}
            </ReduxProvider>
        </ConciergeMetaProvider>
    );
};
