import { validateNhsNumber } from "@accurx/shared/dist/NhsNumberHelper";
import { ZodError, z } from "zod";

import { FIELDS_ID } from "./PatientSearchForm.helpers";

export type AdvancedSearchError = {
    firstName?: string;
    lastName?: string;
    postcode?: string;
    gender?: string;
    dateOfBirth?: string;
};

export type BasicSearchError = {
    nhsNumber?: string;
    dateOfBirth?: string;
};

export type BasicTwoFactoredSearchError = {
    nhsNumber?: string;
};

export type EmisSearchError = {
    searchTerm?: string;
};

export type EmrSearchError = {
    firstName?: string;
    lastName?: string;
    nhsNumber?: string;
};

export type FormFieldErrors =
    | BasicTwoFactoredSearchError
    | BasicSearchError
    | AdvancedSearchError
    | EmisSearchError;

export const formatErrors = <T extends FormFieldErrors>(error: ZodError) => {
    return Object.entries(error.flatten().fieldErrors).reduce(
        (acc, [field, errorMessage]) => ({
            ...acc,
            [field]: errorMessage?.[0],
        }),
        {} as T,
    );
};

// Custom errors
export const nhsNumberLengthErrorMessage = "NHS number must be 10 digits";
export const nhsNumberInvalidErrorMessage = "Please enter a valid NHS number";
export const postcodeLengthErrorMessage = "Postcode must be 5 digits or more";
export const dobErrorMessage = "Date of birth must be DD, MM, YYYY";
export const dobInvalidDateMessage = "Date of birth must be valid";
export const dobFutureMessage = "Date of birth cannot be in the future";
export const defaultRequiredMessage = "Required";
const zodRequiredString = (message = defaultRequiredMessage) =>
    z.string({ required_error: message }).min(1, { message });

// Fields
export const FirstNameField = { [FIELDS_ID.FIRST_NAME]: zodRequiredString() };
export const LastNameField = { [FIELDS_ID.LAST_NAME]: zodRequiredString() };
export const PostcodeField = {
    [FIELDS_ID.POSTCODE]: zodRequiredString().min(5, {
        message: postcodeLengthErrorMessage,
    }),
};
export const GenderField = { [FIELDS_ID.GENDER]: zodRequiredString() };
export const NhsNumberField = {
    [FIELDS_ID.NHS_NUMBER]: z
        .string()
        .length(10, { message: nhsNumberLengthErrorMessage })
        .refine((str) => validateNhsNumber(str).success, {
            message: nhsNumberInvalidErrorMessage,
        }),
};
export const DateOfBirthField = {
    [FIELDS_ID.DATE_OF_BIRTH]: z
        .object({
            day: z.string().length(2, { message: dobErrorMessage }),
            month: z.string().length(2, { message: dobErrorMessage }),
            year: z.string().length(4, { message: dobErrorMessage }),
        })
        .required()
        .transform(({ day, month, year }) => `${year}-${month}-${day}`)
        .pipe(z.string().date(dobInvalidDateMessage))
        .pipe(z.coerce.date().max(new Date(), dobFutureMessage)),
};
export const SearchTermField = { [FIELDS_ID.SEARCH_TERM]: zodRequiredString() };

// Schemas
export const BasicTwoFactoredSearchSchema = z.object(NhsNumberField);
export const BasicSearchSchema = z.object({
    ...NhsNumberField,
    ...DateOfBirthField,
});
export const AdvancedSearchSchema = z.object({
    ...FirstNameField,
    ...LastNameField,
    ...PostcodeField,
    ...GenderField,
    ...DateOfBirthField,
});
export const EmisSearchSchema = z.object(SearchTermField);
export const EmrSearchSchema = z
    .object({ ...FirstNameField, ...LastNameField })
    .or(z.object(NhsNumberField));
