import { MessagePatientRequestBase } from "@accurx/api/patient-messaging";
import {
    Attachment,
    TemplateState,
} from "domains/message-component/reducer.types";

export const mapTemplateToMessageTemplateDto = ({
    template,
    attachments,
}: {
    template: TemplateState;
    attachments: Attachment[];
}): Exclude<MessagePatientRequestBase["messageTemplate"], undefined> => {
    return template.type === "MessageTemplate"
        ? {
              id: parseInt(template.value.id, 10),
              attachmentIds: attachments
                  .filter(
                      (att) =>
                          att.status === "success" && att.origin === "Template",
                  )
                  .map((att) => parseInt(att.id, 10)),
              isPreset: template.value.owner === "Accurx",
              name: template.value.title,
              group: template.value.heading ?? null,
              level: template.value.owner,
          }
        : null;
};
