import { conversationIdMapper } from "@accurx/concierge";
import { ROUTES_INBOX } from "@accurx/inbox";
import { Redirect, Switch, generatePath } from "react-router";

import { SimpleRoute } from "app/AppRoute";
import { ROUTES } from "shared/Routes";

/**
 * We're migrating everyone over to the new Care Providers part of the Unified
 * Inbox. This component sets up specific redirects for various parts of the
 * clinician conversation inbox:
 * - Redirect the "My conversations" view to "My inbox"
 * - Redirect the "All conversations" view to "All convesations"
 * - Redirect the single conversation view to the single conversation view
 * - Redirect the "New message" flow to patient search
 * - Add a catch all redirect to "My inbox"
 */
export const RedirectToUnifiedInbox = ({
    workspaceId,
}: {
    workspaceId: number;
}): JSX.Element => {
    return (
        <Switch>
            {/* Redirect My Inbox and All Conversations */}
            <SimpleRoute
                path={
                    "/w/:workspaceId/clinician-conversations/:view(me|workspace)"
                }
                render={(routeProps) => {
                    switch (routeProps.match.params.view) {
                        case "workspace":
                            return (
                                <Redirect
                                    to={generatePath(
                                        ROUTES_INBOX.CareProvidersAll,
                                        { workspaceId },
                                    )}
                                />
                            );
                        default:
                            return (
                                <Redirect
                                    to={generatePath(
                                        ROUTES_INBOX.CareProvidersMyInbox,
                                        { workspaceId },
                                    )}
                                />
                            );
                    }
                }}
            />

            {/* Redirect compose URLs back to patient search */}
            <Redirect
                from={"/w/:workspaceId/clinician-conversations/new"}
                to={`${ROUTES.home}?patientSearch=true`}
            />
            <Redirect
                from={"/w/:workspaceId/clinician-conversations/compose"}
                to={`${ROUTES.home}?patientSearch=true`}
            />
            <Redirect
                from={"/w/:workspaceId/clinician-conversations/compose-test"}
                to={`${ROUTES.home}?patientSearch=true`}
            />

            {/* Redirect the single conversation view */}
            <SimpleRoute
                path={
                    "/w/:workspaceId/clinician-conversations/:workspaceConversationId"
                }
                render={(routeProps) => {
                    const conversationId =
                        conversationIdMapper.clinicianMessaging.fromSource(
                            routeProps.match.params.workspaceConversationId ??
                                "",
                        );

                    const path = generatePath(
                        ROUTES_INBOX.CareProvidersMyInbox,
                        { workspaceId },
                    );

                    return (
                        <Redirect
                            to={`${path}?conversationId=${conversationId}`}
                        />
                    );
                }}
            />

            {/* Add catch all redirect to My Inbox just in case */}
            <Redirect to={ROUTES_INBOX.CareProvidersMyInbox} />
        </Switch>
    );
};
