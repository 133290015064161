import { StyledSearchContainer } from "./PatientSearchWrapper.styles";

export const PatientSearchWrapper = ({
    children,
}: {
    children: JSX.Element;
}) => {
    return (
        <StyledSearchContainer.Outer>
            <StyledSearchContainer.Inner>
                {children}
            </StyledSearchContainer.Inner>
        </StyledSearchContainer.Outer>
    );
};
