import { Ds, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledReplyFeedbacksContainer = styled(Ds.Flex).attrs({
    gap: "1.5",
    flexDirection: "column",
})`
    padding: ${Tokens.SIZES[1.5]};
    padding-bottom: 0;

    &:empty {
        display: none;
    }
`;
