import React, { useState } from "react";

import { FeatureName } from "@accurx/auth";
import { Card, Ds, Feedback, Icon, Text } from "@accurx/design";
import { useIsManageOrg } from "@accurx/navigation";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { generatePath, useHistory } from "react-router-dom";

import { PracticeDetails } from "app/practices/Practices.types";
import { StepsFooter } from "app/sharedComponents/footer/StepsFooter";
import { IsFeatureEnabled } from "shared/FeatureNameHelper";
import { ROUTES_CHAIN, ROUTES_WORKSPACE } from "shared/Routes";
import { useAppSelector } from "store/hooks";

import {
    FirstVaccinationFilterChoice,
    VaccineCourse,
    VaccineDeliveryItem,
    VaccineExistingPatientState,
    vaccineTypeStrings,
} from "../models/VaccineDeliveryDTO";
import { VaccineDeliveryHeader } from "../shared/VaccineDeliveryComponents";
import {
    AugmentedFilterChoice,
    timeSinceLastAndNoneVaccination,
    timeSinceLastVaccination,
    timeSincePrimaryVaccinationWeekChoicesFull,
    timeSincePrimaryVaccinationWeekChoicesReduced,
} from "../vaccineAllPatientsInvited/VaccineAllPatientsInvited.helper";
import { setPatientInvitesForInviteToSecond } from "../vaccineInvitesOldPage/vaccineDelivery.actions";
import { initialState as VaccineAllInvitesInitialState } from "../vaccineInvitesOldPage/vaccineDelivery.reducer";
import {
    getAllPatientWhoNeedBoosterAndHaveBeenPreviouslyUploaded,
    getAllPatientWhoNeedBoosterAndHaveNotBeenPreviouslyUploaded,
    getAllPatientWhoNeedSeasonalBoosterAndHaveBeenPreviouslyUploaded,
    getAllPatientWhoNeedSeasonalBoosterAndHaveNotBeenPreviouslyUploaded,
    getAllPatientWithNoVaccinationData,
    getAllPatientsWhoNeedSecondJabAndHaveBeenPreviouslyUploaded,
    getAllPatientsWhoNeedSecondJabAndHaveNotBeenPreviouslyUploaded,
    getTotalSteps,
    mapVaccineTypeToBadgeColour,
    timeSinceFirstDoseVaccinationWeekChoices,
} from "./VaccineDeliveryUploadAndComposeHelpers";

/* This page is used for selecting patients for their second vaccines, or for their booster vaccines */
export const VaccineDeliverySelectSecond = (): JSX.Element => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isManageOrg = useIsManageOrg();

    const selectedPractice = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );

    const practiceFeatures = useAppSelector(
        ({ practices }) =>
            practices.items.find(
                (x: PracticeDetails) =>
                    x.id.toString() === practices.selectedPractice,
            )?.features ?? [],
    );

    const strictBoosterDoseSeparation: boolean = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.VaccinePracticeStrictBoosterDoseSeparation,
    );

    const reducedBoosterDoseSeparation: boolean = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.VaccinePracticeReducedBoosterDoseSeparation,
    );

    const extendedBoosterTimeRangeFilter: boolean = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.VaccinePracticeExtendedBoosterTimeRangeFilter,
    );

    const [selectedPatientIds, setSelectedPatientIds] = useState(
        new Set<string>(),
    );

    const patientList = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.vaccineDeliveryDetails.items ||
            VaccineAllInvitesInitialState.vaccineDeliveryDetails.items,
        shallowEqual,
    );

    const vaccineCourse = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.vaccineCourse ||
            VaccineAllInvitesInitialState.vaccineCourse,
        shallowEqual,
    );

    const secondJabNotAlreadyUploadedPatients =
        getAllPatientsWhoNeedSecondJabAndHaveNotBeenPreviouslyUploaded(
            patientList,
        );
    const boosterJabNotAlreadyUploadedPatients =
        getAllPatientWhoNeedBoosterAndHaveNotBeenPreviouslyUploaded(
            patientList,
        );

    const seasonalBoosterJabNotAlreadyUploadedPatients =
        getAllPatientWhoNeedSeasonalBoosterAndHaveNotBeenPreviouslyUploaded(
            patientList,
        );

    const secondJabAlreadyUploadedPatients =
        getAllPatientsWhoNeedSecondJabAndHaveBeenPreviouslyUploaded(
            patientList,
        );
    const boosterJabAlreadyUploadedPatients =
        getAllPatientWhoNeedBoosterAndHaveBeenPreviouslyUploaded(patientList);

    const seasonalBoosterJabAlreadyUploadedPatients =
        getAllPatientWhoNeedSeasonalBoosterAndHaveBeenPreviouslyUploaded(
            patientList,
        );

    const patientsNoVaccinationDataAvailable =
        getAllPatientWithNoVaccinationData(patientList);

    const isBooster = vaccineCourse !== VaccineCourse.Primary;
    const isSeasonalBooster = vaccineCourse !== VaccineCourse.Booster;

    const getFilteredPrimaryList = (
        vaccineType: string,
        min: number | undefined,
        max: number | undefined,
        list: VaccineDeliveryItem[],
    ): VaccineDeliveryItem[] => {
        return list.filter(
            ({ daysSinceFirstVaccination, vaccineProduct }) =>
                daysSinceFirstVaccination !== null && //N.B. expect this to not be null for all second jab patients
                (max === undefined || daysSinceFirstVaccination < max) &&
                (min === undefined || daysSinceFirstVaccination >= min) &&
                vaccineProduct === vaccineType,
        );
    };

    const getFilteredBoosterList = (
        vaccineType: string,
        minInclusive: number | undefined,
        maxExclusive: number | undefined,
        list: VaccineDeliveryItem[],
    ): VaccineDeliveryItem[] => {
        return list.filter(
            ({ timeSinceLastVaccination, vaccineProduct }) =>
                timeSinceLastVaccination !== null && //N.B. expect this to not be null for all booster jab patients
                (maxExclusive === undefined ||
                    timeSinceLastVaccination?.days < maxExclusive) &&
                (minInclusive === undefined ||
                    timeSinceLastVaccination?.days >= minInclusive) &&
                vaccineProduct === vaccineType,
        );
    };

    const getFilteredSeasonalBoosterList = (
        minInclusive: number | undefined,
        maxExclusive: number | undefined,
        list: VaccineDeliveryItem[],
    ): VaccineDeliveryItem[] => {
        return list.filter(
            ({ timeSinceLastVaccination, jabState }) =>
                jabState !== VaccineExistingPatientState.Unknown &&
                (maxExclusive === undefined && minInclusive === undefined
                    ? jabState === VaccineExistingPatientState.NoJabs
                    : timeSinceLastVaccination !== null &&
                      timeSinceLastVaccination.days !== null &&
                      (maxExclusive === undefined ||
                          timeSinceLastVaccination?.days < maxExclusive) &&
                      (minInclusive === undefined ||
                          timeSinceLastVaccination?.days >= minInclusive)),
        );
    };

    const handleContinueButton = (): void => {
        const filteredList = isBooster
            ? isSeasonalBooster
                ? seasonalBoosterJabNotAlreadyUploadedPatients
                : boosterJabNotAlreadyUploadedPatients
            : secondJabNotAlreadyUploadedPatients;

        const selectedPatients: VaccineDeliveryItem[] = filteredList.filter(
            (x) => selectedPatientIds.has(x.inviteId),
        );

        dispatch(setPatientInvitesForInviteToSecond(selectedPatients));
        history.push(
            isManageOrg
                ? generatePath(
                      ROUTES_CHAIN.practicesVaccineInvitesComposeSecond,
                      { orgId: selectedPractice },
                  )
                : generatePath(ROUTES_WORKSPACE.accubookInvitesComposeSecond, {
                      workspaceId: selectedPractice,
                  }),
        );
    };

    const handleAddToSelectedPatients = (
        add: boolean,
        vaccineType: string,
        min: number | undefined,
        max: number | undefined,
        filteredList: VaccineDeliveryItem[],
    ): void => {
        const newPatientIds = filteredList.map((patient) => patient.inviteId);

        if (add) {
            const currentPatientIds = Array.from(selectedPatientIds);
            const newSet = new Set([...currentPatientIds, ...newPatientIds]);
            setSelectedPatientIds(newSet);
        } else {
            const newSet = new Set(selectedPatientIds);
            newPatientIds.forEach((id) => {
                newSet.delete(id);
            });
            setSelectedPatientIds(newSet);
        }
    };

    const renderCheckboxRow = (
        numOfPatients: number,
        label: string,
        vaccineType = "NoVaccineSpecified",
        min: number | undefined,
        max: number | undefined,
        filteredList: VaccineDeliveryItem[],
    ): JSX.Element | null => {
        if (numOfPatients === 0) return null;

        return (
            <React.Fragment key={`checkBoxRow-${min}-${max}-${vaccineType}`}>
                <Card spacing={2} props={{ className: "mb-2" }}>
                    <div
                        className="w-100 d-flex justify-content-between"
                        data-testid={`${min}-${max}-${vaccineType}`}
                    >
                        <span>
                            <input
                                type="checkbox"
                                data-testid={`selectPatients-${min}-${max}-${vaccineType}`}
                                id={`selectPatients-${min}-${max}-${vaccineType}`}
                                onChange={(e): void =>
                                    handleAddToSelectedPatients(
                                        e.target.checked,
                                        vaccineType,
                                        min,
                                        max,
                                        filteredList,
                                    )
                                }
                                className="mr-2"
                            />
                            <Text
                                as="label"
                                skinny
                                props={{
                                    htmlFor: `selectPatients-${min}-${max}-${vaccineType}`,
                                }}
                            >
                                {label}
                            </Text>
                        </span>
                        <span className="d-flex align-items-center">
                            {vaccineType !== "NoVaccineSpecified" && (
                                <Ds.Badge
                                    color={mapVaccineTypeToBadgeColour(
                                        vaccineType,
                                    )}
                                    className="mr-1"
                                >
                                    {vaccineType}
                                </Ds.Badge>
                            )}
                            <Ds.Badge color="greyscale">
                                <Icon name="Team" />
                                {`${numOfPatients} patients`}
                            </Ds.Badge>
                        </span>
                    </div>
                </Card>
            </React.Fragment>
        );
    };

    const renderCheckboxes = (
        choices: FirstVaccinationFilterChoice[] | AugmentedFilterChoice[],
        patientList: VaccineDeliveryItem[],
    ): JSX.Element[] => {
        const checkBoxGroups: JSX.Element[] = [];

        choices.forEach(
            (choice: FirstVaccinationFilterChoice | AugmentedFilterChoice) => {
                let min: number | undefined,
                    max: number | undefined,
                    label: string,
                    filterFunction = getFilteredPrimaryList;

                if ("minDays" in choice) {
                    // This means it's a FirstVaccFilterChoice
                    min = choice.minDays;
                    max = choice.maxDays;
                    label = `${choice.displayText} since 1st vaccination`;
                } else {
                    // This means it's a SecondVaccFilterChoice
                    min = choice.minDaysInclusive;
                    max = choice.maxDaysExclusive;
                    label =
                        vaccineCourse === VaccineCourse.Booster
                            ? `${choice.displayText} since primary course`
                            : `${choice.displayText} since last vaccine`;
                    filterFunction = getFilteredBoosterList;
                }

                if (
                    vaccineCourse === VaccineCourse.BoosterAutumn2024 ||
                    vaccineCourse === VaccineCourse.BoosterSpring2024 ||
                    vaccineCourse === VaccineCourse.BoosterAutumn2023
                ) {
                    const list = getFilteredSeasonalBoosterList(
                        min,
                        max,
                        patientList,
                    );
                    label =
                        min === undefined && max === undefined
                            ? choice.displayText
                            : label;

                    const checkBoxRow = renderCheckboxRow(
                        list.length,
                        label,
                        "NoVaccineSpecified",
                        min,
                        max,
                        list,
                    );

                    checkBoxRow !== null && checkBoxGroups.push(checkBoxRow);
                } else {
                    Object.keys(vaccineTypeStrings).forEach((vaccineType) => {
                        const list = filterFunction(
                            vaccineType,
                            min,
                            max,
                            patientList,
                        );

                        const checkBoxRow = renderCheckboxRow(
                            list.length,
                            label,
                            vaccineType,
                            min,
                            max,
                            list,
                        );

                        checkBoxRow !== null &&
                            checkBoxGroups.push(checkBoxRow);
                    });
                }
            },
        );

        return checkBoxGroups;
    };

    const renderMain = (
        notAlreadyUploadedList: VaccineDeliveryItem[],
        alreadyUploadedList: VaccineDeliveryItem[],
        feedBackTitle: string,
        listSuffix: string,
        listDescription: string,
        choices: FirstVaccinationFilterChoice[] | AugmentedFilterChoice[],
        warningMessage?: string,
    ): JSX.Element => {
        return (
            <>
                {notAlreadyUploadedList.length === 0 ? (
                    <Feedback
                        colour="information"
                        title={feedBackTitle}
                        content={
                            alreadyUploadedList.length > 0
                                ? `Your upload contains ${alreadyUploadedList.length} patients who have already been uploaded`
                                : "Skip to view the patient list"
                        }
                    />
                ) : (
                    <Card
                        spacing={2}
                        header={
                            <>
                                <Text variant="label" skinny>
                                    {notAlreadyUploadedList.length} {listSuffix}
                                </Text>
                            </>
                        }
                    >
                        <>
                            {alreadyUploadedList.length > 0 && (
                                <Feedback
                                    colour="information"
                                    content={`${alreadyUploadedList.length} patients were already uploaded`}
                                />
                            )}

                            {patientsNoVaccinationDataAvailable.length > 0 && (
                                <Feedback colour="warning">
                                    {`We were unable to retrieve previous vaccination data for ${patientsNoVaccinationDataAvailable.length} patients”, if we fail to fetch vaccination data from Imms History API for these patients.`}
                                </Feedback>
                            )}
                            <Text>{listDescription}</Text>
                            {warningMessage && (
                                <Text colour="red">{warningMessage}</Text>
                            )}
                            {renderCheckboxes(choices, notAlreadyUploadedList)}
                        </>
                    </Card>
                )}
            </>
        );
    };

    const renderPrimaryView = (): JSX.Element => {
        return renderMain(
            secondJabNotAlreadyUploadedPatients,
            secondJabAlreadyUploadedPatients,
            "You haven't uploaded any patients who need to be invited for second.",
            "patients eligible for 2nd vaccination",
            "These patients received their 1st vaccinations at different times. Select the patients to invite today, you'll be able to manually invite the others later",
            timeSinceFirstDoseVaccinationWeekChoices,
        );
    };
    const hasSpring2024Tab = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.VaccinePracticeSpring2024BoosterTab,
    );
    const hasAutumn2024Tab = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.VaccinePracticeAutumn2024BoosterTab,
    );

    const renderBoosterView = (): JSX.Element => {
        const gap = reducedBoosterDoseSeparation ? "13 weeks" : "26 weeks";

        const vaccineCourseTitle = hasAutumn2024Tab
            ? "Autumn 2024 booster"
            : hasSpring2024Tab
            ? "Spring 2024 booster"
            : "Autumn 2023 booster";

        return renderMain(
            vaccineCourse === VaccineCourse.Booster
                ? boosterJabNotAlreadyUploadedPatients
                : seasonalBoosterJabNotAlreadyUploadedPatients,
            vaccineCourse === VaccineCourse.Booster
                ? boosterJabAlreadyUploadedPatients
                : seasonalBoosterJabAlreadyUploadedPatients,
            `You haven’t uploaded any new patients for the ${vaccineCourseTitle}.`,
            `patients with vaccination data who can be invited`,
            "Select patients to invite today. Unselected patients will be added to your 'to invite' list, and can be invited later, when ready.",
            vaccineCourse === VaccineCourse.Booster
                ? reducedBoosterDoseSeparation &&
                  !extendedBoosterTimeRangeFilter
                    ? timeSincePrimaryVaccinationWeekChoicesReduced
                    : timeSincePrimaryVaccinationWeekChoicesFull
                : vaccineCourse === VaccineCourse.BoosterAutumn2024 ||
                  vaccineCourse === VaccineCourse.BoosterSpring2024 ||
                  vaccineCourse === VaccineCourse.BoosterAutumn2023
                ? timeSinceLastAndNoneVaccination
                : timeSinceLastVaccination,
            strictBoosterDoseSeparation
                ? `Any patients invited today will not be able to see appointments that are less than ${gap} since they completed their ${
                      vaccineCourse === VaccineCourse.Booster
                          ? "primary course"
                          : "last vaccine"
                  }. If patients need to be booked in earlier you must do this on their behalf.`
                : `Any patients invited today will be able to book appointments straight away, even if they are less than ${gap} post their ${
                      vaccineCourse === VaccineCourse.Booster
                          ? "primary course"
                          : "last vaccine"
                  }.`,
        );
    };

    return (
        <div>
            <div className="text-center">
                <VaccineDeliveryHeader
                    stepNumber={vaccineCourse !== VaccineCourse.Primary ? 2 : 3}
                    totalSteps={getTotalSteps(vaccineCourse)}
                    title={
                        isBooster
                            ? "Review patient list"
                            : "Review awaiting 2nd vaccination"
                    }
                />
            </div>
            <div className="row mb-5">
                <div className="col-8 offset-md-2">
                    {isBooster ? renderBoosterView() : renderPrimaryView()}
                </div>
            </div>

            <StepsFooter
                backText="Skip"
                backLink={
                    isManageOrg
                        ? generatePath(
                              ROUTES_CHAIN.practicesVaccineInvitesReviewInvites,
                              { orgId: selectedPractice },
                          )
                        : generatePath(ROUTES_WORKSPACE.accubookInvitesReview, {
                              workspaceId: selectedPractice,
                          })
                }
                forwardText="Continue"
                forwardClickFunction={handleContinueButton}
                disabled={selectedPatientIds.size === 0}
            />
        </div>
    );
};

export default VaccineDeliverySelectSecond;
