import { ContactDetail } from "domains/message-component/reducer.types";

export const mapMethodToPluralLowerCase = (method: ContactDetail["method"]) => {
    switch (method) {
        case "Email":
            return "emails";
        case "Mobile":
            return "text messages";
        default: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustive: never = method;
            return "messages";
        }
    }
};
