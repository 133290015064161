import { WorkspaceSelectionAllowedResponse } from "@accurx/api/account";
import {
    IWrappedResult,
    getApiEndpoint,
    httpClient,
    returnDataOrThrow,
} from "@accurx/shared";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";

export const getWorkspacesAllowed = (
    nationalCode: string,
): Promise<IWrappedResult<WorkspaceSelectionAllowedResponse>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: `/api/account/lowtrust/nhsorganisations/${nationalCode}/workspaceSelectionAllowed`,
        }),
    );

const GET_WORKSPACES_CACHE_KEY = "GetWorkspacesAllowed";

type GetWorkspacesAllowedQueryVariables = {
    nationalCode: string;
    enabled?: boolean;
};

type GetWorkspacesAllowedQueryOptions = Pick<
    UseQueryOptions<WorkspaceSelectionAllowedResponse, Error>,
    "refetchOnMount"
>;

export const useGetWorkspacesAllowedQuery = (
    { nationalCode, enabled }: GetWorkspacesAllowedQueryVariables,
    { refetchOnMount }: GetWorkspacesAllowedQueryOptions = {
        refetchOnMount: false,
    },
) => {
    return useQuery<WorkspaceSelectionAllowedResponse, Error>({
        queryKey: [GET_WORKSPACES_CACHE_KEY, { nationalCode }],
        queryFn: async () => {
            const response = await getWorkspacesAllowed(nationalCode);
            return returnDataOrThrow(response);
        },
        refetchOnMount,
        enabled,
    });
};
