import { NHSAdviceLink } from "domains/message-component/types";
import groupBy from "lodash/groupBy";

import { GroupedNHSAdvice } from "./NHSAdviceSelector.types";

/** Groups an array of NHSAdviceLink[] by the first letter of the name property */
export const groupNHSAdviceByFirstLetter = (
    nhsAdviceLinks: NHSAdviceLink[],
): GroupedNHSAdvice => {
    return groupBy(nhsAdviceLinks, (link) => {
        const firstChar = link.name[0];
        if (isNaN(Number(firstChar))) {
            return firstChar.toUpperCase();
        } else {
            return "#";
        }
    });
};
