import { useAccurxWebTitle } from "@accurx/navigation";

import { AccountPageLayout } from "../AccountPageLayout";
import { JoinedWorkspaceSection } from "./JoinedWorkspaceSection/JoinedWorkspaceSection";

const WorkspaceAccessPage = () => {
    useAccurxWebTitle("Manage your workspaces");

    return (
        <AccountPageLayout title="Your workspaces">
            <JoinedWorkspaceSection />
        </AccountPageLayout>
    );
};

export { WorkspaceAccessPage };
