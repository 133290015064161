import { FeatureName, useFeatureFlag } from "@accurx/auth";
import { Ds, TabProps, Tabs } from "@accurx/design";

import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";

import MedicalRecords from "../MedicalRecordsComponent";
import { OrganisationRecordRequestsPage } from "./OrganisationRecordRequestsPage";
import { RecordViewOptInPage } from "./RecordViewOptInPage";

const TabIds = {
    OrganisationRequests: "organisation-record-requests",
    UserRequests: "user-record-requests",
};

const tabs: TabProps[] = [
    {
        tabId: TabIds.OrganisationRequests,
        text: "Requests to your organisation",
    },
    {
        tabId: TabIds.UserRequests,
        text: "Active requests made by you",
    },
];

export const RecordRequestsPage = () => {
    const isGpRecordViewEnabled = useFeatureFlag(FeatureName.RecordViewGpOptIn);

    return (
        <StyledContainer>
            <PageHeader
                title="Record requests"
                type={PageHeaderType.ListPage}
            />
            <Tabs defaultSelected={TabIds.OrganisationRequests}>
                <Tabs.TabList tabs={tabs} />
                <Ds.Box mt="3">
                    <Tabs.Panel tabId={TabIds.OrganisationRequests}>
                        {isGpRecordViewEnabled ? (
                            <OrganisationRecordRequestsPage />
                        ) : (
                            <RecordViewOptInPage />
                        )}
                    </Tabs.Panel>
                    <Tabs.Panel tabId={TabIds.UserRequests}>
                        <MedicalRecords noHeader />
                    </Tabs.Panel>
                </Ds.Box>
            </Tabs>
        </StyledContainer>
    );
};
