import { useAnalytics } from "@accurx/analytics";
import { useCurrentWorkspace } from "@accurx/auth";
import * as UI from "@accurx/design";
import {
    Accordion,
    AccordionOnValueChangeHandler,
    FocusRingSpacing,
    Sidenav,
} from "@accurx/navigation";
import { usePendingApproval } from "@accurx/workspace-management";
import { WithPatientsSidebarSection } from "domains/inbox/components/Sidebar/WithPatientsSidebarSection";
import { CareSettingSectionHeader } from "domains/inbox/components/Sidebar/components/CareSettingSectionHeader/CareSettingSectionHeader";
import { UnreadCountProvider } from "domains/inbox/components/Sidebar/components/ConversationCount/UnreadCountProvider";
import { FolderLink } from "domains/inbox/components/Sidebar/components/FolderLink";
import {
    useAccordionBehaviour,
    useIsCurrentPath,
} from "domains/inbox/components/Sidebar/utils";
import { useInboxLink } from "domains/inbox/hooks/util";
import { useIsCollaborativeInboxEnabled } from "domains/inbox/hooks/util/useIsCollaborativeInboxEnabled";
import { useSidebarFolderClickAnalytics } from "domains/inbox/hooks/util/useSidebarFolderClickAnalytics";
import { userflowIds } from "domains/inbox/util";

import { TotalCountProvider } from "./components/ConversationCount/TotalCountProvider";
import { ConversationCountTooltip } from "./components/ConversationCountTooltip";

export const CrossWorkspaceSidebar = () => {
    const link = useInboxLink();
    const accordion = useAccordionBehaviour();
    const isCurrentPath = useIsCurrentPath();
    const trackFolderClick = useSidebarFolderClickAnalytics();
    const track = useAnalytics();
    const canCollaborate = useIsCollaborativeInboxEnabled();
    const currentWorkspace = useCurrentWorkspace();
    const { isPendingApproval } = usePendingApproval(currentWorkspace.orgId);

    const changeTopLevelPath: AccordionOnValueChangeHandler = (topLevel) => {
        switch (topLevel) {
            case "Patients":
                accordion.setPath({
                    topLevel: "Patients",
                    patients: "MyInbox",
                });
                break;
            case "CareProviders":
                accordion.setPath({ topLevel: "CareProviders" });
                break;
        }
    };

    return (
        <Sidenav id="CrossWorkspaceSidebar">
            <Accordion.Root
                value={accordion.path.topLevel}
                onValueChange={changeTopLevelPath}
            >
                <Accordion.Item.Level1 value="Patients">
                    <Accordion.Header>
                        <UnreadCountProvider.WithPatients>
                            {(unreadCount) => (
                                <FocusRingSpacing>
                                    <Accordion.Trigger asChild>
                                        <CareSettingSectionHeader
                                            to={link.to("MyInbox")}
                                            title="Patients"
                                            aria-label="Conversations with patients"
                                            unreadCount={unreadCount}
                                            isOpen={
                                                accordion.path.topLevel ===
                                                "Patients"
                                            }
                                            data-userflow-id={
                                                userflowIds.sidebar.withPatients
                                            }
                                            onClick={() => {
                                                track(
                                                    "NavigationSubMenu Button Click",
                                                    {
                                                        navigationOptionSelected:
                                                            "ConversationsPatients",
                                                        notificationCount:
                                                            unreadCount
                                                                ? `${unreadCount}`
                                                                : "",
                                                        eventVersion: 2,
                                                    },
                                                );
                                            }}
                                        />
                                    </Accordion.Trigger>
                                </FocusRingSpacing>
                            )}
                        </UnreadCountProvider.WithPatients>
                    </Accordion.Header>
                    <Accordion.Content>
                        <WithPatientsSidebarSection
                            path={accordion.path.patients}
                            onPathChange={(value) => {
                                accordion.setPath({
                                    topLevel: "Patients",
                                    patients: value,
                                });
                            }}
                        />
                    </Accordion.Content>
                </Accordion.Item.Level1>
                <FocusRingSpacing>
                    <Sidenav.Divider />
                </FocusRingSpacing>
                <Accordion.Item.Level1 value="CareProviders">
                    <Accordion.Header>
                        <UnreadCountProvider.WithCareProviders>
                            {(unreadCount) => (
                                <FocusRingSpacing>
                                    <Accordion.Trigger asChild>
                                        <CareSettingSectionHeader
                                            to={link.to("CareProvidersMyInbox")}
                                            title="Care providers"
                                            aria-label="Conversations with care providers"
                                            unreadCount={unreadCount}
                                            isOpen={
                                                accordion.path.topLevel ===
                                                "CareProviders"
                                            }
                                            data-userflow-id={
                                                userflowIds.sidebar
                                                    .withCareProviders
                                            }
                                            onClick={() => {
                                                track(
                                                    "NavigationSubMenu Button Click",
                                                    {
                                                        navigationOptionSelected:
                                                            "ConversationsCareProviders",
                                                        notificationCount:
                                                            unreadCount
                                                                ? `${unreadCount}`
                                                                : "",
                                                        eventVersion: 2,
                                                    },
                                                );
                                            }}
                                        />
                                    </Accordion.Trigger>
                                </FocusRingSpacing>
                            )}
                        </UnreadCountProvider.WithCareProviders>
                    </Accordion.Header>
                    <Accordion.Content>
                        <FocusRingSpacing>
                            <UI.Flex flexDirection="column" gap="1">
                                <TotalCountProvider.WithCareProvidersMyInbox>
                                    {(total) => (
                                        <UnreadCountProvider.WithCareProvidersMyInbox>
                                            {(unreadCount) => (
                                                <ConversationCountTooltip
                                                    total={total}
                                                    unread={unreadCount}
                                                >
                                                    <FolderLink
                                                        text="My inbox"
                                                        to={link.to(
                                                            "CareProvidersMyInbox",
                                                        )}
                                                        icon={{
                                                            name: "Inbox",
                                                            style: "Fill",
                                                        }}
                                                        size="large"
                                                        unreadCount={
                                                            unreadCount
                                                        }
                                                        isActive={isCurrentPath(
                                                            "CareProvidersMyInbox",
                                                        )}
                                                        onClick={() => {
                                                            trackFolderClick({
                                                                folderName:
                                                                    "My inbox",
                                                                unreadCount,
                                                                isUserSubscribedToGroupNotifications:
                                                                    true,
                                                                conversationParticipant:
                                                                    "WithHcp",
                                                            });
                                                        }}
                                                        data-userflow-id={
                                                            userflowIds.sidebar
                                                                .withCareProvidersMyInbox
                                                        }
                                                    />
                                                </ConversationCountTooltip>
                                            )}
                                        </UnreadCountProvider.WithCareProvidersMyInbox>
                                    )}
                                </TotalCountProvider.WithCareProvidersMyInbox>
                                {canCollaborate && !isPendingApproval && (
                                    <>
                                        <TotalCountProvider.WithCareProvidersUnassigned>
                                            {(total) => (
                                                <UnreadCountProvider.WithCareProvidersUnassigned>
                                                    {(unreadCount) => (
                                                        <ConversationCountTooltip
                                                            total={total}
                                                            unread={unreadCount}
                                                        >
                                                            <FolderLink
                                                                text="Unassigned"
                                                                to={link.to(
                                                                    "CareProvidersUnassigned",
                                                                )}
                                                                icon={{
                                                                    name: "Assign",
                                                                    style: "Fill",
                                                                }}
                                                                size="large"
                                                                unreadCount={
                                                                    unreadCount
                                                                }
                                                                isActive={isCurrentPath(
                                                                    "CareProvidersUnassigned",
                                                                )}
                                                                onClick={() => {
                                                                    trackFolderClick(
                                                                        {
                                                                            folderName:
                                                                                "Unassigned",
                                                                            conversationParticipant:
                                                                                "WithHcp",
                                                                        },
                                                                    );
                                                                }}
                                                                data-userflow-id={
                                                                    userflowIds
                                                                        .sidebar
                                                                        .withCareProvidersUnassigned
                                                                }
                                                            />
                                                        </ConversationCountTooltip>
                                                    )}
                                                </UnreadCountProvider.WithCareProvidersUnassigned>
                                            )}
                                        </TotalCountProvider.WithCareProvidersUnassigned>
                                        <TotalCountProvider.WithCareProvidersAllConversations>
                                            {(total) => (
                                                <ConversationCountTooltip
                                                    total={total}
                                                >
                                                    <FolderLink
                                                        text="All conversations"
                                                        to={link.to(
                                                            "CareProvidersAll",
                                                        )}
                                                        icon={{
                                                            name: "Messages",
                                                        }}
                                                        size="large"
                                                        isActive={isCurrentPath(
                                                            "CareProvidersAll",
                                                        )}
                                                        onClick={() => {
                                                            trackFolderClick({
                                                                folderName:
                                                                    "All conversations",
                                                                conversationParticipant:
                                                                    "WithHcp",
                                                            });
                                                        }}
                                                        data-userflow-id={
                                                            userflowIds.sidebar
                                                                .withCareProvidersAllConversations
                                                        }
                                                    />
                                                </ConversationCountTooltip>
                                            )}
                                        </TotalCountProvider.WithCareProvidersAllConversations>
                                    </>
                                )}
                            </UI.Flex>
                        </FocusRingSpacing>
                    </Accordion.Content>
                </Accordion.Item.Level1>
            </Accordion.Root>
        </Sidenav>
    );
};
