import { EnrolmentResultReason } from "@accurx/api/patient-messaging";
import { useCurrentWorkspace } from "@accurx/auth";
import * as UI from "@accurx/design";
import { format } from "date-fns";
import { useCompose } from "domains/message-component/context";
import {
    EnrolmentStatus,
    useQuestionnaireEnrolmentStatusQuery,
} from "domains/message-component/hooks/useQuestionnaireEnrolmentStatusQuery";
import { PatientExternalId } from "domains/message-component/types";
import { toast } from "react-toastify";

const formatScheduleDate = (date: string): string => {
    return format(new Date(date), "d MMM yyyy");
};

const buildWarning = (questionnaireEnrolment: EnrolmentStatus) => {
    if (questionnaireEnrolment.schedule) {
        return `This questionnaire was already sent to this patient on ${formatScheduleDate(
            questionnaireEnrolment.schedule.startDate,
        )}. They have until ${formatScheduleDate(
            questionnaireEnrolment.schedule.endDate,
        )} to respond.`;
    }

    return null;
};

const getEnrolmentReasonError = (reason: string): string => {
    switch (Number(reason) as EnrolmentResultReason) {
        case EnrolmentResultReason.FailedMustBeTestPatient:
            return "This questionnaire can only be sent to test patients";
        default:
            return "Sorry, something went wrong. Please try again.";
    }
};

export const useValidatePendingQuestionnaire = (
    patientExternalIds: PatientExternalId[],
) => {
    const { state, dispatch } = useCompose();
    const workspace = useCurrentWorkspace();

    const questionnaireToCheck = state.template.pendingValue;
    useQuestionnaireEnrolmentStatusQuery(
        {
            workspaceId: workspace.orgId,
            questionnaireId: questionnaireToCheck?.id,
            patientExternalIds,
        },
        {
            enabled: !!questionnaireToCheck,
            onSuccess: (questionnaireEnrolment) => {
                if (questionnaireToCheck) {
                    const warning = buildWarning(questionnaireEnrolment);
                    dispatch({
                        type: "ADD_QUESTIONNAIRE_TEMPLATE",
                        payload: {
                            template: questionnaireToCheck,
                            warning: warning ?? undefined,
                        },
                    });
                }
            },
            onError: (payload) => {
                toast(
                    <UI.Feedback
                        title={getEnrolmentReasonError(payload.message)}
                        colour="error"
                    />,
                );

                dispatch({
                    type: "REMOVE_TEMPLATE",
                });
            },
        },
    );
};
