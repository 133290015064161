import { useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import { useCountOfTeamsByType } from "@accurx/concierge/hooks/data/useCountOfTeamsByType";
import { useUnassignedTeamPatient } from "@accurx/concierge/hooks/data/useUnassignedTeam";
import { Ds, Tokens } from "@accurx/design";
import {
    Accordion,
    ExpandingScrollableArea,
    FocusRingSpacing,
} from "@accurx/navigation";
import { usePendingApproval } from "@accurx/workspace-management";
import { ColleaguesList } from "domains/inbox/components/Sidebar/components/ColleaguesList/ColleaguesList";
import { TotalCountProvider } from "domains/inbox/components/Sidebar/components/ConversationCount/TotalCountProvider";
import { UnreadCountProvider } from "domains/inbox/components/Sidebar/components/ConversationCount/UnreadCountProvider";
import { ConversationCountTooltip } from "domains/inbox/components/Sidebar/components/ConversationCountTooltip";
import { FolderLink } from "domains/inbox/components/Sidebar/components/FolderLink";
import { FolderLinkWithToggle } from "domains/inbox/components/Sidebar/components/FolderLinkWithToggle";
import { FolderListLabel } from "domains/inbox/components/Sidebar/components/FolderListLabel";
import { FolderToggle } from "domains/inbox/components/Sidebar/components/FolderToggle";
import { LockedInboxFeaturesSection } from "domains/inbox/components/Sidebar/components/LockedInboxFeature/LockedInboxFeaturesSection";
import { QuestionnaireTeamsList } from "domains/inbox/components/Sidebar/components/QuestionnaireTeamsList/QuestionnaireTeamsList";
import { SearchableAccordionSection } from "domains/inbox/components/Sidebar/components/SearchableAccordionSection/SearchableAccordionSection";
import { SharedTeamsList } from "domains/inbox/components/Sidebar/components/SharedTeamsList/SharedTeamsList";
import { TeamsList } from "domains/inbox/components/Sidebar/components/TeamsList/TeamsList";
import { useIsCurrentPath } from "domains/inbox/components/Sidebar/utils";
import { useInboxLink } from "domains/inbox/hooks/util";
import { useConversationFilterAnalytics } from "domains/inbox/hooks/util/useConversationFilterAnalytics";
import { useIsCollaborativeInboxEnabled } from "domains/inbox/hooks/util/useIsCollaborativeInboxEnabled";
import { useSidebarFolderClickAnalytics } from "domains/inbox/hooks/util/useSidebarFolderClickAnalytics";
import { useSidebarFolderSearchClickAnalytics } from "domains/inbox/hooks/util/useSidebarFolderSearchClickAnalytics";
import { userflowIds } from "domains/inbox/util";
import styled from "styled-components";

const SmallVerticalSpacer = styled.div`
    height: ${Tokens.SIZES[1]};
    min-height: ${Tokens.SIZES[1]};
`;

type WithPatientsSidebarSectionProps = {
    path: string | undefined;
    onPathChange: (path: string) => void;
};

export const WithPatientsSidebarSection = ({
    path,
    onPathChange,
}: WithPatientsSidebarSectionProps) => {
    const link = useInboxLink();
    const isCurrentPath = useIsCurrentPath();
    const trackFolderClick = useSidebarFolderClickAnalytics();
    const trackSearchButtonClick = useSidebarFolderSearchClickAnalytics();
    const { trackFilterMenuItemClick } = useConversationFilterAnalytics();
    const unassignedTeam = useUnassignedTeamPatient();
    const countOfHubTeams = useCountOfTeamsByType({ type: "Hub" });
    const isSelfReferralEnabled = useFeatureFlag("PatientInitiatedSussexMsk");
    const canCollaborate = useIsCollaborativeInboxEnabled();
    const currentWorkspace = useCurrentWorkspace();
    const { isPendingApproval } = usePendingApproval(currentWorkspace.orgId);

    return (
        <Accordion.Root value={path} onValueChange={onPathChange} collapsible>
            <Accordion.Item.Level2 value="MyInbox">
                <Accordion.Header>
                    <FocusRingSpacing>
                        <TotalCountProvider.MyInbox>
                            {(total) => (
                                <UnreadCountProvider.MyInbox>
                                    {(unreadCount) => (
                                        <ConversationCountTooltip
                                            unread={unreadCount}
                                            total={total}
                                        >
                                            <FolderLinkWithToggle
                                                text="My inbox"
                                                to={link.to("MyInbox")}
                                                isOpen={path === "MyInbox"}
                                                isActive={isCurrentPath(
                                                    "MyInbox",
                                                )}
                                                icon={{
                                                    name: "Inbox",
                                                    style: "Fill",
                                                }}
                                                unreadCount={unreadCount}
                                                onClick={() =>
                                                    trackFolderClick({
                                                        folderName: "MyInbox",
                                                        unreadCount,
                                                        conversationParticipant:
                                                            "WithPatient",
                                                    })
                                                }
                                                data-userflow-id={
                                                    userflowIds.sidebar.myInbox
                                                }
                                                toggleProps={{
                                                    "aria-label":
                                                        "My inbox subfolders and filters options",
                                                }}
                                            />
                                        </ConversationCountTooltip>
                                    )}
                                </UnreadCountProvider.MyInbox>
                            )}
                        </TotalCountProvider.MyInbox>
                    </FocusRingSpacing>
                </Accordion.Header>
                <Accordion.Content>
                    <ExpandingScrollableArea>
                        <Accordion.Indented>
                            <FocusRingSpacing>
                                <FolderLink
                                    text="Sent & scheduled"
                                    to={link.to("MyInboxSent")}
                                    icon={{ name: "Send" }}
                                    isActive={isCurrentPath([
                                        "MyInboxSent",
                                        "MyInboxScheduled",
                                    ])}
                                    onClick={() =>
                                        trackFolderClick({
                                            folderName: "MySentAndScheduled",
                                            conversationParticipant:
                                                "WithPatient",
                                        })
                                    }
                                    data-userflow-id={
                                        userflowIds.sidebar.sentAndScheduled
                                    }
                                />
                            </FocusRingSpacing>
                        </Accordion.Indented>
                    </ExpandingScrollableArea>
                </Accordion.Content>
            </Accordion.Item.Level2>

            {canCollaborate && !isPendingApproval && (
                <>
                    {!!unassignedTeam && (
                        <Accordion.Item.Level2 value="Unassigned">
                            <Accordion.Header>
                                <FocusRingSpacing>
                                    <TotalCountProvider.Team
                                        teamId={unassignedTeam.id}
                                    >
                                        {(total) => (
                                            <UnreadCountProvider.Team
                                                teamId={unassignedTeam.id}
                                            >
                                                {(unreadCount) => (
                                                    <ConversationCountTooltip
                                                        total={total}
                                                        unread={unreadCount}
                                                    >
                                                        <FolderLinkWithToggle
                                                            text="Unassigned"
                                                            to={link.to(
                                                                "Unassigned",
                                                                {
                                                                    teamId: unassignedTeam.id,
                                                                },
                                                            )}
                                                            isOpen={
                                                                path ===
                                                                "Unassigned"
                                                            }
                                                            isActive={isCurrentPath(
                                                                "Unassigned",
                                                            )}
                                                            icon={{
                                                                name: "Assign",
                                                                style: "Fill",
                                                            }}
                                                            unreadCount={
                                                                unreadCount
                                                            }
                                                            onClick={() =>
                                                                trackFolderClick(
                                                                    {
                                                                        folderName:
                                                                            "Unassigned",
                                                                        unreadCount,
                                                                        conversationParticipant:
                                                                            "WithPatient",
                                                                    },
                                                                )
                                                            }
                                                            data-userflow-id={
                                                                userflowIds
                                                                    .sidebar
                                                                    .unassigned
                                                            }
                                                            toggleProps={{
                                                                "aria-label":
                                                                    "Unassigned subfolders and filters options",
                                                            }}
                                                        />
                                                    </ConversationCountTooltip>
                                                )}
                                            </UnreadCountProvider.Team>
                                        )}
                                    </TotalCountProvider.Team>
                                </FocusRingSpacing>
                            </Accordion.Header>
                            <Accordion.Content>
                                <ExpandingScrollableArea>
                                    <Accordion.Indented>
                                        <FocusRingSpacing>
                                            <FolderListLabel>
                                                Filters
                                            </FolderListLabel>
                                            <Ds.Flex
                                                flexDirection="column"
                                                gap="0.5"
                                            >
                                                <TotalCountProvider.UnassignedAdmin>
                                                    {(total) => (
                                                        <ConversationCountTooltip
                                                            total={total}
                                                        >
                                                            <FolderLink
                                                                text="Admin"
                                                                to={link.to(
                                                                    "UnassignedAdmin",
                                                                    {
                                                                        teamId: unassignedTeam.id,
                                                                    },
                                                                )}
                                                                aria-label={
                                                                    "Unassigned conversations Admin filter"
                                                                }
                                                                icon={{
                                                                    name: "Tag",
                                                                }}
                                                                isActive={isCurrentPath(
                                                                    "UnassignedAdmin",
                                                                )}
                                                                onClick={() =>
                                                                    trackFilterMenuItemClick(
                                                                        {
                                                                            filterName:
                                                                                "UnassignedAdmin",
                                                                            appOrigin:
                                                                                "Sidebar",
                                                                        },
                                                                    )
                                                                }
                                                            />
                                                        </ConversationCountTooltip>
                                                    )}
                                                </TotalCountProvider.UnassignedAdmin>
                                                <TotalCountProvider.UnassignedMedical>
                                                    {(total) => (
                                                        <ConversationCountTooltip
                                                            total={total}
                                                        >
                                                            <FolderLink
                                                                text="Medical"
                                                                to={link.to(
                                                                    "UnassignedMedical",
                                                                    {
                                                                        teamId: unassignedTeam.id,
                                                                    },
                                                                )}
                                                                aria-label={
                                                                    "Unassigned conversations Medical filter"
                                                                }
                                                                icon={{
                                                                    name: "Tag",
                                                                }}
                                                                isActive={isCurrentPath(
                                                                    "UnassignedMedical",
                                                                )}
                                                                onClick={() =>
                                                                    trackFilterMenuItemClick(
                                                                        {
                                                                            filterName:
                                                                                "UnassignedMedical",
                                                                            appOrigin:
                                                                                "Sidebar",
                                                                        },
                                                                    )
                                                                }
                                                            />
                                                        </ConversationCountTooltip>
                                                    )}
                                                </TotalCountProvider.UnassignedMedical>
                                                {isSelfReferralEnabled && (
                                                    <FolderLink
                                                        text="Self referral"
                                                        to={link.to(
                                                            "UnassignedSelfReferral",
                                                            {
                                                                teamId: unassignedTeam.id,
                                                            },
                                                        )}
                                                        aria-label={
                                                            "Unassigned conversations Self referral filter"
                                                        }
                                                        icon={{
                                                            name: "Tag",
                                                        }}
                                                        isActive={isCurrentPath(
                                                            "UnassignedSelfReferral",
                                                        )}
                                                        onClick={() =>
                                                            trackFilterMenuItemClick(
                                                                {
                                                                    filterName:
                                                                        "UnassignedSelfReferral",
                                                                    appOrigin:
                                                                        "Sidebar",
                                                                },
                                                            )
                                                        }
                                                    />
                                                )}
                                            </Ds.Flex>
                                        </FocusRingSpacing>
                                    </Accordion.Indented>
                                </ExpandingScrollableArea>
                            </Accordion.Content>
                        </Accordion.Item.Level2>
                    )}
                    <Accordion.Item.Level2 value="AllConversations">
                        <Accordion.Header>
                            <FocusRingSpacing>
                                <TotalCountProvider.AllConversations>
                                    {(total) => (
                                        <ConversationCountTooltip total={total}>
                                            <FolderLinkWithToggle
                                                text="All conversations"
                                                to={link.to("All")}
                                                isOpen={
                                                    path === "AllConversations"
                                                }
                                                isActive={isCurrentPath("All")}
                                                icon={{ name: "Messages" }}
                                                onClick={() =>
                                                    trackFolderClick({
                                                        folderName:
                                                            "AllConversations",
                                                        conversationParticipant:
                                                            "WithPatient",
                                                    })
                                                }
                                                data-userflow-id={
                                                    userflowIds.sidebar
                                                        .allConversations
                                                }
                                                toggleProps={{
                                                    "aria-label":
                                                        "All conversations subfolders and filters options",
                                                }}
                                            />
                                        </ConversationCountTooltip>
                                    )}
                                </TotalCountProvider.AllConversations>
                            </FocusRingSpacing>
                        </Accordion.Header>
                        <Accordion.Content>
                            <ExpandingScrollableArea>
                                <Accordion.Indented>
                                    <FocusRingSpacing>
                                        <FolderLink
                                            text="Sent & scheduled"
                                            to={link.to("AllSent")}
                                            icon={{ name: "Send" }}
                                            isActive={isCurrentPath("AllSent")}
                                            onClick={() =>
                                                trackFolderClick({
                                                    folderName:
                                                        "SentAndScheduled",
                                                    conversationParticipant:
                                                        "WithPatient",
                                                })
                                            }
                                        />
                                        <FolderListLabel>
                                            Filters
                                        </FolderListLabel>
                                        <Ds.Flex
                                            flexDirection="column"
                                            gap="0.5"
                                        >
                                            <TotalCountProvider.AllConversationsPatientResponses>
                                                {(total) => (
                                                    <ConversationCountTooltip
                                                        total={total}
                                                    >
                                                        <FolderLink
                                                            text="Patient responses"
                                                            to={link.to(
                                                                "AllPatientResponses",
                                                            )}
                                                            icon={{
                                                                name: "Tag",
                                                            }}
                                                            isActive={isCurrentPath(
                                                                "AllPatientResponses",
                                                            )}
                                                            onClick={() =>
                                                                trackFilterMenuItemClick(
                                                                    {
                                                                        filterName:
                                                                            "PatientResponses",
                                                                        appOrigin:
                                                                            "Sidebar",
                                                                    },
                                                                )
                                                            }
                                                        />
                                                    </ConversationCountTooltip>
                                                )}
                                            </TotalCountProvider.AllConversationsPatientResponses>
                                            <TotalCountProvider.AllConversationsPatientTriages>
                                                {(total) => (
                                                    <ConversationCountTooltip
                                                        total={total}
                                                    >
                                                        <FolderLink
                                                            text="Patient triages"
                                                            to={link.to(
                                                                "AllPatientTriages",
                                                            )}
                                                            icon={{
                                                                name: "Tag",
                                                            }}
                                                            isActive={isCurrentPath(
                                                                "AllPatientTriages",
                                                            )}
                                                            onClick={() =>
                                                                trackFilterMenuItemClick(
                                                                    {
                                                                        filterName:
                                                                            "PatientTriages",
                                                                        appOrigin:
                                                                            "Sidebar",
                                                                    },
                                                                )
                                                            }
                                                        />
                                                    </ConversationCountTooltip>
                                                )}
                                            </TotalCountProvider.AllConversationsPatientTriages>
                                            <TotalCountProvider.AllConversationsFailedMessages>
                                                {(total) => (
                                                    <ConversationCountTooltip
                                                        total={total}
                                                    >
                                                        <FolderLink
                                                            text="Failed messages"
                                                            to={link.to(
                                                                "AllFailed",
                                                            )}
                                                            icon={{
                                                                name: "Tag",
                                                            }}
                                                            isActive={isCurrentPath(
                                                                "AllFailed",
                                                            )}
                                                            onClick={() =>
                                                                trackFilterMenuItemClick(
                                                                    {
                                                                        filterName:
                                                                            "Failed",
                                                                        appOrigin:
                                                                            "Sidebar",
                                                                    },
                                                                )
                                                            }
                                                        />
                                                    </ConversationCountTooltip>
                                                )}
                                            </TotalCountProvider.AllConversationsFailedMessages>
                                        </Ds.Flex>
                                    </FocusRingSpacing>
                                </Accordion.Indented>
                            </ExpandingScrollableArea>
                        </Accordion.Content>
                    </Accordion.Item.Level2>
                    <SmallVerticalSpacer />
                    <Accordion.Item.Level2 value="Teams">
                        <UnreadCountProvider.TeamType type="Default">
                            {(unreadCount) => (
                                <SearchableAccordionSection
                                    isOpen={path === "Teams"}
                                    renderHeader={() => (
                                        <FocusRingSpacing>
                                            <FolderToggle
                                                text="Teams"
                                                isOpen={path === "Teams"}
                                                unreadCount={unreadCount}
                                                data-userflow-id={
                                                    userflowIds.sidebar.teams
                                                }
                                            />
                                        </FocusRingSpacing>
                                    )}
                                    renderContent={({ searchTerm }) => (
                                        <TeamsList searchTerm={searchTerm} />
                                    )}
                                    onSearchButtonclick={() =>
                                        trackSearchButtonClick({
                                            folderName: "Teams",
                                            unreadCount,
                                        })
                                    }
                                />
                            )}
                        </UnreadCountProvider.TeamType>
                    </Accordion.Item.Level2>
                    <Accordion.Item.Level2 value="Questionnaires">
                        <UnreadCountProvider.TeamType type="Florey">
                            {(unreadCount) => (
                                <SearchableAccordionSection
                                    isOpen={path === "Questionnaires"}
                                    renderHeader={() => (
                                        <FocusRingSpacing>
                                            <FolderToggle
                                                text="Questionnaires"
                                                isOpen={
                                                    path === "Questionnaires"
                                                }
                                                unreadCount={unreadCount}
                                                data-userflow-id={
                                                    userflowIds.sidebar
                                                        .questionnaires
                                                }
                                            />
                                        </FocusRingSpacing>
                                    )}
                                    renderContent={({ searchTerm }) => (
                                        <QuestionnaireTeamsList
                                            searchTerm={searchTerm}
                                        />
                                    )}
                                    onSearchButtonclick={() =>
                                        trackSearchButtonClick({
                                            folderName: "Questionnaires",
                                            unreadCount,
                                        })
                                    }
                                />
                            )}
                        </UnreadCountProvider.TeamType>
                    </Accordion.Item.Level2>
                    {countOfHubTeams > 0 && (
                        <Accordion.Item.Level2 value="Shared">
                            <SearchableAccordionSection
                                isOpen={path === "Shared"}
                                renderHeader={() => (
                                    <FocusRingSpacing>
                                        <FolderToggle
                                            text="Shared"
                                            isOpen={path === "Shared"}
                                            data-userflow-id={
                                                userflowIds.sidebar.shared
                                            }
                                        />
                                    </FocusRingSpacing>
                                )}
                                renderContent={({ searchTerm }) => (
                                    <SharedTeamsList searchTerm={searchTerm} />
                                )}
                                onSearchButtonclick={() =>
                                    trackSearchButtonClick({
                                        folderName: "Shared",
                                    })
                                }
                            />
                        </Accordion.Item.Level2>
                    )}
                    <Accordion.Item.Level2 value="Colleagues">
                        <SearchableAccordionSection
                            isOpen={path === "Colleagues"}
                            renderHeader={() => (
                                <FocusRingSpacing>
                                    <FolderToggle
                                        text="Colleagues"
                                        isOpen={path === "Colleagues"}
                                        data-userflow-id={
                                            userflowIds.sidebar.colleagues
                                        }
                                    />
                                </FocusRingSpacing>
                            )}
                            renderContent={({ searchTerm }) => (
                                <ColleaguesList searchTerm={searchTerm} />
                            )}
                            onSearchButtonclick={() =>
                                trackSearchButtonClick({
                                    folderName: "Colleagues",
                                })
                            }
                        />
                    </Accordion.Item.Level2>
                </>
            )}
            {isPendingApproval && (
                <FocusRingSpacing>
                    <LockedInboxFeaturesSection />
                </FocusRingSpacing>
            )}
        </Accordion.Root>
    );
};
