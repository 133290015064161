import { Ds, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledModalHeader = styled(Ds.Modal.Section)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[2]};
`;

export const StyledModalHeaderDivider = styled.div`
    height: ${Tokens.SIZES[3]};
    background-color: ${Tokens.COLOURS.greyscale.silver};
    margin: 0 ${Tokens.SIZES[1]};
    width: 1px;
`;

export const StyledModalCloseButton = styled(Ds.Modal.CloseButton)`
    align-self: center;
    height: 42px;
    width: 42px;
    display: flex;
    justify-content: center;
`;
