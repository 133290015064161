import { ConversationGroupSortOptions } from "domains/concierge/internal/types/ConversationGroupSortOptions";
import { conversationGroupId } from "domains/concierge/internal/util/conversationGroupId";
import { ConversationGroupFilters } from "domains/concierge/types";

import { useConversationGroup } from "../useConversationGroup";

export const useConversationCount = (
    filters: ConversationGroupFilters,
    sortOptions: ConversationGroupSortOptions,
) => {
    const groupId = conversationGroupId(filters, sortOptions);
    const group = useConversationGroup({ id: groupId });

    if (!group) {
        return;
    } else if (group.members.length === 0 && !group.isFullyLoaded) {
        return;
    } else {
        return {
            count: group.members.length,
            isFullyLoaded: group.isFullyLoaded,
        };
    }
};
