import React, { useState } from "react";

import {
    Ds,
    Popover,
    PopoverContent,
    PopoverItemButton,
    PopoverTrigger,
} from "@accurx/design";
import {
    StyledModalCloseButton,
    StyledModalHeader,
    StyledModalHeaderDivider,
} from "@accurx/navigation";
import { useDirectory } from "domains/directory/context/DirectoryProvider";

export const DirectoryModalHeader = () => {
    const { view, setView } = useDirectory();

    const isAddContactDisabled = view === "AddContact";

    const handleClickAddContact = () => {
        setView("AddContact");
    };

    return (
        <StyledModalHeader>
            <Ds.Modal.Title>Directory</Ds.Modal.Title>
            <Ds.Flex justifyContent="flex-end" alignItems="center" gap="0.5">
                <Ds.Button
                    appearance="tertiary"
                    onClick={handleClickAddContact}
                    disabled={isAddContactDisabled}
                >
                    <Ds.Button.Icon name="Plus" />
                    Add new contact
                </Ds.Button>
                <StyledModalHeaderDivider />
                <StyledModalCloseButton />
            </Ds.Flex>
        </StyledModalHeader>
    );
};

export const DirectoryMobileModalHeader = () => {
    const { view, setView } = useDirectory();

    const [open, setOpen] = useState(false);

    const isAddContactDisabled = view === "AddContact";

    const handleClickAddContact = () => {
        setOpen(false);
        setView("AddContact");
    };

    return (
        <Ds.Box p="1 2">
            <Ds.Grid columns="1fr 1fr 1fr" alignItems="center">
                <StyledModalCloseButton />
                <Ds.Flex justifyContent="center">
                    <Ds.Modal.Title>Directory</Ds.Modal.Title>
                </Ds.Flex>
                {!isAddContactDisabled && (
                    <Ds.Flex justifyContent="flex-end">
                        <Popover open={open} onOpenChange={setOpen} modal>
                            <PopoverTrigger>
                                <Ds.Button
                                    appearance="tertiary"
                                    aria-label="More"
                                >
                                    <Ds.Button.Icon name="More" />
                                </Ds.Button>
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverItemButton
                                    onClick={handleClickAddContact}
                                    disabled={isAddContactDisabled}
                                >
                                    <Ds.Icon name="Plus" size="small" />
                                    <Ds.Text weight="bold">
                                        Add new contact
                                    </Ds.Text>
                                </PopoverItemButton>
                            </PopoverContent>
                        </Popover>
                    </Ds.Flex>
                )}
            </Ds.Grid>
        </Ds.Box>
    );
};
