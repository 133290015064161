import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const UploadButtonWrapper = styled.div`
    @media screen and (max-width: ${Tokens.BREAKPOINTS.md}) {
        width: max-content;
        align-self: end;
        margin-top: ${Tokens.SIZES[2]};
    }
`;
