import { NhsNumberHelpers } from "@accurx/shared";
import { PatientExternalId } from "domains/message-component/types";

export const getValidPatientNhsNumber = (
    patientExternalIds: PatientExternalId[],
): string | undefined => {
    return patientExternalIds.find(
        (id) =>
            id.type === "NhsNumber" &&
            NhsNumberHelpers.validateNhsNumber(id.value).success === true,
    )?.value;
};
