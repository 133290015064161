export const ROUTES_COMPOSE = {
    clinicianCompose: "/w/:workspaceId/compose/clinician-compose",
    compose: "/w/:workspaceId/compose",
    /*
     * When navigating to compose within the inbox we need slightly different
     * behaviour to the other compose experiences, namely:
     * - We need to wrap the page in the modal UI.
     * - We need to display only the patient in the router state, not subscribing
     *   to the medical record patient.
     */
    inboxCompose: "/w/:workspaceId/compose/inbox-compose",
    conversation: "/w/:workspaceId/compose/conversation/:conversationId",
    search: "/w/:workspaceId/compose/search",
    // Used by the Switch app
    backgroundSearch: "/w/:workspaceId/compose/background-search",
} as const;

export const DEPRECATED_COMPOSE_ROUTE = "/compose/w/:workspaceId";
export const DEPRECATED_CLINICIAN_COMPOSE_ROUTE =
    "/compose/w/:workspaceId/clinician-compose";
export const DEPRECATED_CONVERSATION_ROUTE =
    "/compose/w/:workspaceId/conversation/:conversationId";

// Used by the Switch app
export const DEPRECATED_BACKGROUND_SEARCH =
    "/compose/w/:workspaceId/background-search";
