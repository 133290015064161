import * as UI from "@accurx/design";
import styled from "styled-components";

const { Ds } = UI;

export const StyledBadge = styled(Ds.Badge)`
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const StyledBadgeText = styled.span`
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const StyledTooltipContent = styled(UI.Text)`
    word-break: break-all;
    overflow-wrap: break-word;
    display: inline-block;

    /* A hack to match the widths of overflowing tooltips to the conversation preview, plus a gutter */
    max-width: calc(var(--radix-popper-anchor-width) + ${UI.Tokens.SIZES[2]});
`;
