import { useCallback } from "react";

import {
    ConversationSortDirection,
    ConversationSortField,
} from "@accurx/api/clinician-messaging";
import { api } from "domains/concierge/internal/api/clinicianMessaging";
import { useConciergeMeta } from "domains/concierge/internal/context";
import {
    ConversationGroupFetcher,
    useConversationGroupQuery,
} from "domains/concierge/internal/hooks/useConversationGroupQuery";
import { ConversationGroupHook } from "domains/concierge/internal/types/ConversationGroupHook";
import { sortOptions } from "domains/concierge/internal/util/conversationGroupSortOptions";

export const useAllClinicianConversationGroupQuery: ConversationGroupHook<{
    status: "Open" | "Done";
    sort: "Newest" | "Oldest";
}> = ({ status, sort }) => {
    const { workspaceId } = useConciergeMeta();

    const fetcher = useCallback<ConversationGroupFetcher>(
        async (continuationToken) => {
            const { updates, response } = await api.getConversations({
                workspaceId,
                isArchived: status === "Done",
                updatedBeforeDate: continuationToken,
                limit: 20,
                sort: {
                    field: ConversationSortField.LastUpdated,
                    direction:
                        sort === "Newest"
                            ? ConversationSortDirection.Descending
                            : ConversationSortDirection.Ascending,
                },
            });

            return {
                continuationToken: response.nextCursor ?? undefined,
                updates,
            };
        },
        [workspaceId, status, sort],
    );

    return useConversationGroupQuery({
        useCache: false,
        filters: [
            { type: "Source", value: "ClinicianMessaging" },
            { type: "Status", value: status },
        ],
        sortOptions: sortOptions.clinicianCommon({ sort }),
        loggingInfo: {
            name: "All clinician conversations",
            tags: {
                statusFilter: status,
            },
        },
        fetcher,
    });
};
