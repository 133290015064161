import { useCurrentWorkspace } from "@accurx/auth";
import { useQuestionnaireTemplatesQuery } from "@accurx/message-templates";
import {
    OrganisationEntityChangedType,
    SignalRSubscriptionEvent,
    useSubscribeSignalREvent,
} from "@accurx/realtime";
import { Log } from "@accurx/shared";
import { useCompose } from "domains/message-component/context";

/** Listens for SignalR events that say questionnaires have been updated and refetches data */
export const useQuestionnairesSubscription = () => {
    const { state } = useCompose();
    const canUseQuestionnaires = state.canUseQuestionnaires;
    const workspace = useCurrentWorkspace();
    const { refetch } = useQuestionnaireTemplatesQuery(
        { workspaceId: workspace.orgId },
        { enabled: false },
    );

    useSubscribeSignalREvent({
        type: SignalRSubscriptionEvent.OrganisationEntityChanged,
        eventFilter: (payload) => payload.organisationId === workspace.orgId,
        onEvent: (payload) => {
            const isFloreyChange =
                payload.type === OrganisationEntityChangedType.FloreyTemplate;

            if (isFloreyChange) {
                Log.info("Refetching questionnaires after update");
                void refetch();
            }
        },
        enabled: canUseQuestionnaires,
    });
};
