import { useEffect } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { Button, Ds, Flex, Text } from "@accurx/design";
import { useAnalytics } from "reduxQuarantine/useAnalytics";

export const MyWorkspaceWarningModal = ({
    handleClose,
    handleSelectMyWorkspace,
}: {
    handleClose: () => void;
    handleSelectMyWorkspace: () => void;
}) => {
    const currentWorkspace = useCurrentWorkspace();
    const track = useAnalytics();

    useEffect(() => {
        track("WorkspaceDefault Component View", {
            modalView: "workspaceWillBeArchived",
        });
        // Only track event on page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleContinueToMyWorkspace = () => {
        track("WorkspaceDefaultContinue Button Click", {});
        handleSelectMyWorkspace();
        handleClose();
    };

    const handleStayInCurrentWorkspace = () => {
        track("WorkspaceRemain Button Click", {
            workspaceStatus: "Deprecated",
            eventVersion: 2,
        });
        handleClose();
    };

    return (
        <Ds.Modal open={true} onOpenChange={handleClose} dismissible={false}>
            <Ds.Modal.Section>
                <Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>
                        This workspace will soon be archived
                    </Ds.Modal.Title>
                    <Text skinny>
                        This workspace is currently available for you to save
                        information to your records and finish any open
                        conversations.
                    </Text>
                    <Text skinny>
                        Please use your specialty or custom workspace for any
                        new communication.
                    </Text>
                    <Flex justifyContent="flex-end" gap="2" flexWrap="wrap">
                        <Button
                            theme="secondary"
                            text={`Stay in ${currentWorkspace.organisationName}`}
                            onClick={handleStayInCurrentWorkspace}
                        />
                        <Button
                            text="Continue to My Workspace"
                            onClick={handleContinueToMyWorkspace}
                        />
                    </Flex>
                </Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
