import React, { ChangeEvent, ClipboardEvent } from "react";

import { FormFieldWrapper, Input, Tokens } from "@accurx/design";
import { isValid, parse } from "date-fns";
import styled from "styled-components";

export type DateInputValue = {
    day: string;
    month: string;
    year: string;
};

const maxLengths = {
    day: 2,
    month: 2,
    year: 4,
};

const dateOfBirthFormats = [
    "d M yyyy",
    "d.M.yyyy",
    "d-M-yyyy",
    "d/M/yyyy",
    "d-MMM-yyyy",
    "d-MMMM-yyyy",
    "d MMM yyyy",
    "d MMMM yyyy",
];

const parseDate = (text: string): Date | null => {
    for (const format of dateOfBirthFormats) {
        const date = parse(text, format, new Date());

        if (isValid(date)) {
            return date;
        }
    }

    return null;
};

export type DateInputProps = {
    onChange: (
        e: ChangeEvent<HTMLInputElement> | ClipboardEvent<HTMLInputElement>,
        value: DateInputValue,
    ) => void;
    value: DateInputValue;
    label?: string;
    subLabel?: string;
    error?: string;
    disabled?: boolean;
    required?: boolean;
};

export const DateInput = React.forwardRef(
    (
        props: DateInputProps,
        forwardedRef: React.Ref<HTMLInputElement>,
    ): JSX.Element => {
        const onPaste = (e: ClipboardEvent<HTMLInputElement>) => {
            const text = e.clipboardData.getData("text");
            const validDate = parseDate(text);

            if (!validDate) {
                return;
            }

            // Prevent full date being pasted in to current input
            // Careful! This behaviour cannot be tested until we upgrade to @testing-library/user-event v14
            e.preventDefault();

            props.onChange(e, {
                day: `0${validDate.getDate()}`.slice(-2),
                month: `0${validDate.getMonth() + 1}`.slice(-2),
                year: validDate.getFullYear().toString(),
            });
        };

        const handleInput =
            (name: keyof DateInputValue) =>
            (e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length <= maxLengths[name]) {
                    props.onChange(e, {
                        ...props.value,
                        [name]: e.target.value,
                    });
                }
            };

        const handleBlur =
            (name: keyof DateInputValue) =>
            (e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length === 1) {
                    props.onChange(e, {
                        ...props.value,
                        [name]: `0${e.target.value}`,
                    });
                }
            };

        const a11yLabel = props.label ?? "Date";

        return (
            <StyledFormFieldWrapper
                label={props.label}
                subLabel={props.subLabel}
                grouped
                errors={props.error ? [props.error] : undefined}
                labelProps={{
                    htmlFor: `${props.label ?? "Date"}-date-input`,
                    skinny: true,
                }}
            >
                <Input
                    ref={forwardedRef}
                    id={`${props.label ?? "Date"}-date-input`}
                    value={props.value.day}
                    type="number"
                    pattern="[0-9]*"
                    min={1}
                    max={31}
                    onChange={handleInput("day")}
                    onBlur={handleBlur("day")}
                    onPaste={onPaste}
                    aria-label={`${a11yLabel} day`}
                    disabled={props.disabled}
                    required={props.required}
                    hasErrors={Boolean(props.error)}
                />
                <Input
                    value={props.value.month}
                    type="number"
                    pattern="[0-9]*"
                    min={1}
                    max={12}
                    onBlur={handleBlur("month")}
                    onChange={handleInput("month")}
                    aria-label={`${a11yLabel} month`}
                    disabled={props.disabled}
                    required={props.required}
                    hasErrors={Boolean(props.error)}
                />
                <Input
                    value={props.value.year}
                    type="number"
                    pattern="[0-9]*"
                    min={1900}
                    max={new Date().getFullYear()}
                    onChange={handleInput("year")}
                    aria-label={`${a11yLabel} year`}
                    disabled={props.disabled}
                    required={props.required}
                    hasErrors={Boolean(props.error)}
                />
            </StyledFormFieldWrapper>
        );
    },
);

const StyledFormFieldWrapper = styled(FormFieldWrapper)`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: ${Tokens.SIZES[0.5]};

    & > div > div:not(:first-child) {
        // Collapsing broders of consecutive inputs
        margin-left: -1px;
    }

    & > div > div:focus-within::before {
        // Making focus outline fully visible and not covered by neighour elements
        z-index: 1;
    }
`;

export { StyledFormFieldWrapper };
