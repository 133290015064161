import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledHeader = styled.div`
    display: flex;
`;

export const StyledBadgePositioner = styled.span`
    margin-left: ${Tokens.SIZES[0.5]};
    margin-top: -4px;
`;
