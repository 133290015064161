import { useCurrentWorkspace } from "@accurx/auth";
import { Spinner, StackPanel, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { isArchivedWorkspace } from "@accurx/workspace-management";
import { Redirect, useHistory, useLocation } from "react-router";

import { FileUploadState } from "app/fileUpload/FileUploadReducer";
import { NavSubMenuComponent } from "app/navbar/NavSubMenuComponent";
import { ROUTES, ROUTES_BASE } from "shared/Routes";

import { ViewportStackPanel } from "./ClinicianConversation.styles";
import { ConversationError } from "./ConversationError";
import { RecipientSection } from "./RecipientSection/RecipientSection";
import { ClinicianConversationCompose } from "./conversation/ClinicianConversationCompose";
import { ClinicianConversationComposeButton } from "./conversation/ClinicianConversationComposeButton";
import { ConversationLayout } from "./conversation/ConversationLayout";
import { MessageGroupByDate } from "./conversation/Messages";
import { NextStepsBanner } from "./conversation/NextStepsBanner";
import { useAttachmentIdToHighlight } from "./hooks/useAttachmentIdToHighlight";
import { useClinicianConversation } from "./hooks/useClinicianConversation";
import { useGetPatientDetailsActions } from "./hooks/useGetPatientDetailsActions";
import ParticipantsPanel from "./participantsPanel/ParticipantsPanel";
import { PatientDetails } from "./patientDetails/PatientDetails";
import { Template } from "./types";

export const ClinicianConversation = (): JSX.Element => {
    useAccurxWebTitle("Send message to GP practice");

    const history = useHistory();
    const location = useLocation<{ from?: string }>();

    const conversationState = useClinicianConversation();
    const { viewPatientProfile } = useGetPatientDetailsActions();
    const attachmentIdToHighlight = useAttachmentIdToHighlight();

    const { state, isNewConversation } = conversationState;

    const workspace = useCurrentWorkspace();

    if (state === "loading") {
        return (
            <div className="p-4">
                <Spinner />
            </div>
        );
    }

    if (state === "error") {
        if (isNewConversation) {
            return <Redirect to={ROUTES_BASE.patientsSearch} />;
        }
        return <ConversationError error={conversationState.errorText} />;
    }

    if (state === "redirect") {
        return <Redirect to={conversationState.redirectTo} />;
    }

    const {
        onClickSend,
        onClickRetry,
        onCopyMessage,
        userEmailAddress,
        patient,
        participants,
        messages,
        subject,
        hasNewMessage,
    } = conversationState.conversation;

    const { showComposeArea, setShowComposeArea, hasPolled, workspaceId } =
        conversationState;

    const dateOfBirth = patient?.dateOfBirth;
    const nhsNumber = patient?.nhsNumber;

    const displayRecipientSection =
        (isNewConversation && Boolean(patient?.practiceName)) ||
        Boolean(patient?.practiceCode);

    const Header = (
        <Text as="h1" variant="label" skinny>
            {subject}
        </Text>
    );

    const Messages = messages.map((dateGroup) => (
        <MessageGroupByDate
            onCopyMessage={onCopyMessage}
            dateGroup={dateGroup}
            userEmailAddress={userEmailAddress}
            key={`messageGroupByDate-${dateGroup.date}`}
            onClickRetry={onClickRetry}
            attachmentIdToHighlight={attachmentIdToHighlight}
            hasPolled={hasPolled}
        />
    ));

    const isFromLogin =
        location.state !== undefined &&
        "from" in location.state &&
        location.state.from === ROUTES.postlogin;

    const backToUrl =
        location.state !== undefined && "backTo" in location.state
            ? location.state.backTo
            : undefined;

    const canGoBack =
        !["default", undefined].includes(location.key) && !isFromLogin; // Checking for either default or undefined keys, to support react router V5 and V6 for future
    const canViewPatientProfile = !!(dateOfBirth && nhsNumber);

    const displayBackButton = canGoBack || canViewPatientProfile || backToUrl;

    const onClickBackButton = () => {
        conversationState.trackBackButtonClick();
        if (backToUrl) {
            history.push(backToUrl);
            return;
        }
        if (canGoBack) {
            history.goBack();
            return;
        }
        if (canViewPatientProfile) {
            viewPatientProfile({
                shouldInvokePdsSearch: false,
            });
        }
    };

    const handleOnWriteAnotherMessageClick = () => {
        setShowComposeArea(true);
    };
    const handleOnSendMessageClick = (
        messageBody: string,
        fileUploadState: FileUploadState,
        usedKeyboardShortcut: boolean,
        isPostPatientMessage: boolean,
        template: Template | undefined,
    ) => {
        return onClickSend(
            messageBody,
            fileUploadState,
            usedKeyboardShortcut,
            isPostPatientMessage,
            template,
        );
    };

    return (
        <>
            {displayBackButton ? (
                <NavSubMenuComponent backCallback={onClickBackButton} />
            ) : null}

            <ViewportStackPanel
                orientation="horizontal"
                horizontalContentAlignment="space-between"
                verticalContentAlignment="top"
            >
                <div className="col-12 col-md-8 h-100">
                    <ConversationLayout
                        header={Header}
                        messages={Messages}
                        secondaryHeader={
                            displayRecipientSection &&
                            patient && <RecipientSection patient={patient} />
                        }
                        compose={
                            isArchivedWorkspace(workspace) ? null : (
                                <ClinicianConversationComposeButton
                                    onWriteAnotherMessageClick={
                                        handleOnWriteAnotherMessageClick
                                    }
                                    showMessageCompose={showComposeArea}
                                >
                                    <ClinicianConversationCompose
                                        onClickSend={handleOnSendMessageClick}
                                        organisationId={workspaceId}
                                        patient={patient}
                                        isFirstMessage={isNewConversation}
                                        userAgent={window.navigator.userAgent}
                                    />
                                </ClinicianConversationComposeButton>
                            )
                        }
                        banner={!showComposeArea && <NextStepsBanner />}
                        scrollToLatestMessage={
                            (hasPolled || !attachmentIdToHighlight) &&
                            hasNewMessage
                        }
                    />
                </div>
                <StackPanel gutter={2} className="col-4 d-none d-md-flex h-100">
                    {patient && (
                        <PatientDetails
                            patient={patient}
                            organisationId={workspaceId}
                            displayPatientProfileButton
                            displayMessagePatientButton
                        />
                    )}

                    {participants &&
                        (participants.individualParticipants.length > 0 ||
                            participants.participantWorkspaces.length > 0) && (
                            <ParticipantsPanel participants={participants} />
                        )}
                </StackPanel>
            </ViewportStackPanel>
        </>
    );
};
