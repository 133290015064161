import { NhsOrganisationWorkspaces } from "@accurx/api/account";
import {
    IWrappedResult,
    getApiEndpoint,
    httpClient,
    returnDataOrThrow,
} from "@accurx/shared";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";

export const getWorkspacesLowTrust = (
    nationalCode: string,
): Promise<IWrappedResult<NhsOrganisationWorkspaces>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: `/api/account/lowtrust/nhsorganisations/${nationalCode}/workspaces`,
        }),
    );

const GET_WORKSPACES_CACHE_KEY = "GetWorkspacesLowTrust";

type GetWorkspacesQueryVariables = {
    nationalCode: string;
    enabled?: boolean;
};

type GetWorkspacesQueryOptions = Pick<
    UseQueryOptions<NhsOrganisationWorkspaces, Error>,
    "refetchOnMount"
>;

export const useGetWorkspacesLowTrustQuery = (
    { nationalCode, enabled }: GetWorkspacesQueryVariables,
    { refetchOnMount }: GetWorkspacesQueryOptions = { refetchOnMount: false },
) => {
    return useQuery<NhsOrganisationWorkspaces, Error>({
        queryKey: [GET_WORKSPACES_CACHE_KEY, { nationalCode }],
        queryFn: async () => {
            const response = await getWorkspacesLowTrust(nationalCode);
            return returnDataOrThrow(response);
        },
        refetchOnMount,
        enabled,
    });
};
