import { useCurrentWorkspace } from "@accurx/auth";
import { Ds, TabProps, Tabs, Text } from "@accurx/design";
import { generatePath, useHistory, useParams } from "react-router-dom";

import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { ManageAccurxTemplates } from "app/practices/accumail/manageTemplates/tabs/ManageAccurxTemplates";
import { ManageUserTemplates } from "app/practices/accumail/manageTemplates/tabs/ManageUserTemplates";
import { ManageWorkspaceTemplates } from "app/practices/accumail/manageTemplates/tabs/ManageWorkspaceTemplates";
import { StyledTabListWrapper } from "app/workspaceSettings/pages/UserManagement/UserManagementPage.styles";
import { ROUTES_WORKSPACE } from "shared/Routes";

export const AccumailTemplatesPage = () => {
    const history = useHistory();
    const { orgId: workspaceId, organisationName } = useCurrentWorkspace();
    const { tab } = useParams<{ tab?: string }>();

    const tabs: TabProps[] = [
        { tabId: "my-templates", text: "My templates" },
        { tabId: "workspace-templates", text: `${organisationName} templates` },
        { tabId: "accurx-templates", text: "Accurx templates" },
    ];

    const handleTabChange = (tab: string) => {
        history.push(
            generatePath(ROUTES_WORKSPACE.accumailTemplatesManage, {
                workspaceId,
                tab,
            }),
        );
    };

    return (
        <StyledContainer>
            <PageHeader
                title="Manage Accumail templates"
                type={PageHeaderType.ListPage}
            />
            <Ds.Flex flexDirection="column" gap="3">
                <Text skinny>
                    Create and manage templates to communicate with a service or
                    healthcare professional.
                </Text>
                <Tabs
                    selected={tab || "my-templates"}
                    onTabChange={handleTabChange}
                >
                    <StyledTabListWrapper>
                        <Tabs.TabList tabs={tabs} />
                    </StyledTabListWrapper>
                    <Tabs.Panel tabId="my-templates">
                        <ManageUserTemplates />
                    </Tabs.Panel>
                    <Tabs.Panel tabId="workspace-templates">
                        <ManageWorkspaceTemplates
                            workspaceId={workspaceId}
                            orgName={organisationName}
                        />
                    </Tabs.Panel>
                    <Tabs.Panel tabId="accurx-templates">
                        <ManageAccurxTemplates />
                    </Tabs.Panel>
                </Tabs>
            </Ds.Flex>
        </StyledContainer>
    );
};
