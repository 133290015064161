import { useState } from "react";

import { Conversation } from "@accurx/concierge/types";
import { PatientDemographics, useBrowserEnvironment } from "@accurx/native";
import { QuickViewPortal, useQuickViewContext } from "@accurx/quick-view";
import { FormPdsResult } from "domains/inbox/components/PatientSearch/components/forms/types";
import {
    mapPatientDemographicsToPatientSearchResult,
    mapPdsResultToPatientSearchResult,
} from "domains/inbox/components/PatientSearch/utils";
import { v4 as uuid } from "uuid";

import { PatientMatchEmr } from "./PatientMatchEmr";
import { PatientMatchPds } from "./PatientMatchPds";
import {
    PatientMatchResultForRadio,
    PatientMatchResultsForm,
} from "./PatientMatchResultsForm";

type PatientMatchProps = {
    conversation: Conversation;
};

export const PatientMatch = ({ conversation }: PatientMatchProps) => {
    const env = useBrowserEnvironment();
    const { onClose } = useQuickViewContext();

    const [results, setResults] = useState<PatientMatchResultForRadio[] | null>(
        null,
    );

    const onResultsFoundEmr = (data: PatientDemographics[]) => {
        const mappedResults = data.map((patient) => {
            const patientId = uuid();
            const mapped = mapPatientDemographicsToPatientSearchResult(patient);
            return {
                ...mapped,
                uniqueId: patientId,
            };
        });

        setResults(mappedResults);
    };

    const onResultsFoundPds = ({ patient, patientToken }: FormPdsResult) => {
        // For a PDS search, we only ever get one patient.
        const id = uuid();

        const mapped = mapPdsResultToPatientSearchResult({
            patient,
            patientToken,
        });

        setResults([{ ...mapped, uniqueId: id }]);
    };

    if (results && results.length > 0) {
        return (
            <>
                <QuickViewPortal.Header text="Confirm patient match" />
                <PatientMatchResultsForm
                    conversation={conversation}
                    onMatchConfirmSuccess={onClose}
                    results={results}
                    onClickBack={() => setResults(null)}
                />
            </>
        );
    } else if (env === "WebView") {
        return (
            <PatientMatchEmr
                conversation={conversation}
                onMatchConfirmSuccess={onClose}
                onResultsFound={onResultsFoundEmr}
            />
        );
    } else {
        return (
            <PatientMatchPds
                onResultsFound={onResultsFoundPds}
                conversation={conversation}
            />
        );
    }
};
