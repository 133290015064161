import { GPRequestResult } from "@accurx/api/portal";
import { FeatureName, useCurrentWorkspace } from "@accurx/auth";
import { isArchivedWorkspace } from "@accurx/workspace-management";
import { useDispatch } from "react-redux";

import { SearchForPatientResponseSuccess } from "app/hooks/mutations/useSearchForPatientByNhsNumber";
import { actionCreators } from "app/selectProduct/SelectProductActions";
import { useIsFeatureEnabled } from "store/hooks";

import { ContactOption } from "./ContactOption";
import useSavePatientSearchToStore from "./hooks/useSavePatientSearchToStore";

/**
 * Will render only if {FeatureName.RecordAccessBeta} is enabled for userOrg
 *
 * If the feature is enabled for the user org, if will show as
 * disabled based on the patient permissions returned from the API.
 */
export const RecordViewOption = ({
    onClick,
    searchResult,
}: {
    onClick: () => void;
    searchResult: SearchForPatientResponseSuccess;
}) => {
    const dispatch = useDispatch();
    const saveToStore = useSavePatientSearchToStore();

    const workspace = useCurrentWorkspace();

    const isFeatureEnabled = useIsFeatureEnabled(FeatureName.RecordAccessBeta);

    if (!isFeatureEnabled || isArchivedWorkspace(workspace)) {
        return null;
    }

    const enabledForPatient =
        searchResult.searchedResult.patient.recordViewSupported ===
        GPRequestResult.Success;

    return (
        <ContactOption
            disabled={!enabledForPatient}
            iconName="Record"
            text="Request GP record"
            onClick={() => {
                saveToStore(searchResult);
                dispatch(actionCreators.selectRecordViewType());
                onClick();
            }}
        />
    );
};
