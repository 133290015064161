import { is2FAOnboarded, useAuth } from "@accurx/auth";
// eslint-disable-next-line no-restricted-imports
import { ROUTES_INBOX } from "@accurx/inbox/routes";
import { Log } from "@accurx/shared";
import { useLocation } from "react-router";
import { Redirect, generatePath } from "react-router-dom";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";
import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

import { ROUTES, ROUTES_PRIMARY, ROUTES_WORKSPACE } from "shared/Routes";

export const Home = () => {
    const { user } = useAuth();
    const { selectedOrgId } = useSelectedOrganisation();

    const location = useLocation();

    const isUnifiedNavigationEnabled = useFeatureFlag("UnifiedNavigation");

    const isManageOrgRedirect = Boolean(
        window.sessionStorage.getItem("isManageOrgRedirect"),
    );

    if (user.isLoggedIn) {
        const mustAssociateWithOrg = !user.organisations.length;
        const mustAcceptTerms =
            user.organisations.length > 0 &&
            user.onboarding?.hasAcceptedTermsService !== true;
        const canUseProduct =
            selectedOrgId &&
            user?.onboarding?.hasAcceptedTermsService &&
            is2FAOnboarded(user);

        if (canUseProduct) {
            if (isManageOrgRedirect) {
                return (
                    <Redirect
                        to={{
                            ...location,
                            pathname: generatePath(
                                ROUTES_WORKSPACE.workspaceTemplatesOverview,
                                { workspaceId: selectedOrgId },
                            ),
                        }}
                    />
                );
            } else if (isUnifiedNavigationEnabled) {
                return (
                    <Redirect
                        to={{
                            ...location,
                            pathname: generatePath(ROUTES_INBOX.MyInbox, {
                                workspaceId: selectedOrgId,
                            }),
                        }}
                    />
                );
            }

            return (
                <Redirect
                    to={{ ...location, pathname: ROUTES_PRIMARY.patients }}
                />
            );
        } else if (mustAcceptTerms) {
            return (
                <Redirect
                    to={{ ...location, pathname: ROUTES.terms_and_conditions }}
                />
            );
        } else if (!is2FAOnboarded(user)) {
            return (
                <Redirect
                    to={{ ...location, pathname: ROUTES.two_factor_auth }}
                />
            );
        } else if (mustAssociateWithOrg) {
            return (
                <Redirect
                    to={{ ...location, pathname: ROUTES.joinOrganisation }}
                />
            );
        }
        Log.error(
            "[Home] Expected to redirect somewhere, but returned null instead",
        );
        return null;
    } else {
        return <Redirect to={{ ...location, pathname: ROUTES.login }} />;
    }
};
