import { MAX_MESSAGE_LENGTH } from "../constants";
import {
    Attachment,
    ComposeErrorType,
    PendingQuestionnaire,
} from "../reducer.types";
import { NHSAdviceLink, TemplateItem } from "../types";
import { calculateCharacterCount } from "./calculateCharacterCount";

export type ValidateMessageProps = {
    messageBody: string;
    messageSignature: string;
    attachments: Attachment[];
    mobileNumber: string | null;
    emailAddress: string | null;
    workspaceName: string;
    originalGreeting: string;
    originalSignature: string;
    template: TemplateItem | PendingQuestionnaire | null;
    nhsAdviceLink: NHSAdviceLink | null;
    withSelfBookLink: boolean;
    withPatientResponseEnabled: boolean;
};

const placeholderRegex = /\*{5}(\w|\s)*\*{5}/;

export const validateMessage = (
    arg: ValidateMessageProps,
): ComposeErrorType => {
    const errors: ComposeErrorType = {};
    const { characterCount } = calculateCharacterCount({
        body: arg.messageBody,
        footer: arg.messageSignature,
        workspaceName: arg.workspaceName,
        patientResponseEnabled: !!arg.withPatientResponseEnabled,
        attachmentsCount: arg.attachments.length,
        hasQuestionnaire: arg.template?.type === "QuestionnaireTemplate",
        nhsAdviceLink: arg.nhsAdviceLink,
        hasSelfbookLink: arg.withSelfBookLink,
    });

    // Do not allow sending if there are attachments in progress
    if (arg.attachments.find((a) => a.status === "loading")) {
        errors.attachmentLoading = "ATTACHMENT_STILL_LOADING";
    }

    // Do not allow sending if there are questionnaires being checked still
    if (arg.template?.type === "PendingQuestionnaireTemplate") {
        errors.questionnaireLoading = "QUESTIONNAIRE_STILL_LOADING";
    }

    const isBodyEmpty = arg.messageBody.trim() === "";
    const isMessageEdited =
        `${arg.messageBody.trim()}${arg.messageSignature.trim()}` !==
        `${arg.originalGreeting}${arg.originalSignature}`;

    const hasBeenEdited =
        isMessageEdited ||
        arg.withPatientResponseEnabled ||
        !!arg.nhsAdviceLink ||
        arg.attachments.length > 0;

    if (arg.mobileNumber && characterCount > MAX_MESSAGE_LENGTH) {
        errors.messageLength = "MESSAGE_TOO_LONG";
    } else if (isBodyEmpty || !hasBeenEdited) {
        errors.messageLength = "EMPTY_MESSAGE";
    }

    if (placeholderRegex.test(arg.messageBody) && !!arg.template) {
        errors.placeholderRemains = "TEMPLATE_PLACEHOLDER_REMAINS";
    }

    if (!arg.emailAddress && !arg.mobileNumber) {
        errors.noContactDetails = "NO_CONTACT_DETAILS";
    }

    return errors;
};
