import { ReactNode } from "react";

import { Ds } from "@accurx/design";
import { QuickViewPortal } from "@accurx/quick-view";

import { SearchButton, SearchButtonProps } from "./SearchButton";

type PatientMatchLayoutComponentProps = {
    showFooter?: boolean;
    children?: ReactNode;
} & SearchButtonProps;

export const PatientMatchLayout = ({
    buttonProps,
    showFooter = true,
    children,
}: PatientMatchLayoutComponentProps): JSX.Element => {
    return (
        <>
            <QuickViewPortal.Header text="Match patient to conversation" />
            <QuickViewPortal.Body>{children}</QuickViewPortal.Body>
            {showFooter && (
                <QuickViewPortal.Footer>
                    <Ds.Flex justifyContent="flex-end">
                        <SearchButton buttonProps={buttonProps} />
                    </Ds.Flex>
                </QuickViewPortal.Footer>
            )}
        </>
    );
};
