import { useEffect } from "react";

import { SubscribeOptions } from "../types";
import { SignalRSubscriptionEvent } from "../types/signalR/events";
import { useRealtimeForSignalR } from "./useRealtimeForSignalR";

type Options<E extends SignalRSubscriptionEvent> = SubscribeOptions<E> & {
    enabled?: boolean;
};

export const useSubscribeSignalREvent = <E extends SignalRSubscriptionEvent>({
    type,
    onEvent,
    eventFilter = () => true,
    enabled = true,
}: Options<E>) => {
    const { subscribe, connectionState } = useRealtimeForSignalR();

    useEffect(() => {
        if (!enabled) return;
        if (connectionState !== "Connected") return;

        const unsubscribe = subscribe({
            type,
            eventFilter,
            onEvent,
        }).unsubscribe;

        return () => unsubscribe();
    }, [subscribe, connectionState, type, eventFilter, onEvent, enabled]);
};
