import { Attachment, ComposeErrorType } from "../reducer.types";

export type ValidateClinicianMessageProps = {
    messageBody: string;
    messageSignature: string;
    attachments: Attachment[];
    originalGreeting: string;
    originalSignature: string;
    withTemplate: boolean;
};

const placeholderRegex = /\*{5}(\w|\s)*\*{5}/;
export const validateClinicianMessage = (
    arg: ValidateClinicianMessageProps,
): ComposeErrorType => {
    const errors: ComposeErrorType = {};

    // Do not allow sending if there are attachments in progress
    if (arg.attachments.find((a) => a.status === "loading")) {
        errors.attachmentLoading = "ATTACHMENT_STILL_LOADING";
    }

    if (placeholderRegex.test(arg.messageBody) && arg.withTemplate) {
        errors.placeholderRemains = "TEMPLATE_PLACEHOLDER_REMAINS";
    }

    const isBodyEmpty = arg.messageBody.trim() === "";
    const isMessageEdited =
        `${arg.messageBody.trim()}${arg.messageSignature.trim()}` !==
        `${arg.originalGreeting}${arg.originalSignature}`;
    /**
     * A message is considered edited if the user
     * - somehow changed the body area to be different that the plain greeting
     * - attached a document
     */
    const hasBeenEdited = isMessageEdited || arg.attachments.length > 0;
    if (isBodyEmpty || !hasBeenEdited) {
        errors.messageLength = "EMPTY_MESSAGE";
    }

    return errors;
};
