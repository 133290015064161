import { FeatureName, useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import { Card, Ds, Feedback } from "@accurx/design";
import { toast } from "react-toastify";

import {
    PatientTriageSettingObjectName,
    PatientTriageSettingObjectType,
    trackPatientTriageSetupCopyLinkButtonClick,
} from "app/analytics/ChainAnalytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { CopyableLink } from "./CopyableLink";
import { DirectLinks } from "./DirectLinks";
import { SupportArticles } from "./SupportArticles";
import { usePatientInitiatedLinksQuery } from "./usePatientInitiatedLinksQuery";

export const SetupPatientTriage = () => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const { nationalCode } = useCurrentWorkspace();
    const { status, data } = usePatientInitiatedLinksQuery({
        nationalCode,
    });
    const areDirectLinksEnabled = useFeatureFlag(
        FeatureName.PatientInitiatedDirectLinks,
    );

    if (status === "loading") {
        return <Ds.Spinner />;
    }

    if (status === "error") {
        return (
            <Feedback
                title="Unable to receive Patient Triage status, please try again"
                colour="error"
            />
        );
    }

    const practiceLink = data.patientInitiatedLandingPageLink;

    const copyHandler = () => {
        trackPatientTriageSetupCopyLinkButtonClick({
            ...analyticsLoggedInProps,
            objectName: PatientTriageSettingObjectName.PRACTICE_LINK,
            objectType: PatientTriageSettingObjectType.BUTTON,
        });
        navigator.clipboard
            .writeText(practiceLink)
            .then(() => {
                toast(
                    <Feedback
                        colour="success"
                        title="Patient Triage link copied!"
                    >
                        <Ds.Text>
                            Share this with your patients to start using Patient
                            Triage
                        </Ds.Text>
                    </Feedback>,
                );
            })
            .catch(() => {
                toast(
                    <Feedback
                        colour="error"
                        title="There was a problem copying your link"
                    >
                        <Ds.Text>Try again or copy the link manually</Ds.Text>
                    </Feedback>,
                );
            });
    };

    return (
        <Ds.Flex flexDirection="column" gap="4">
            <Card
                isElevated={false}
                spacing={3}
                variant="regular"
                header={
                    <Ds.Text weight="bold" as="h3" size="large">
                        Your practice and patients can start benefiting from
                        Patient Triage as soon as you add it to your website
                    </Ds.Text>
                }
            >
                <Ds.Flex flexDirection="column" gap="3" alignItems="stretch">
                    <Ds.Flex gap="2">
                        <Ds.Flex.Item>
                            <Ds.Icon name="Link" size="xlarge" />
                        </Ds.Flex.Item>
                        <Ds.Flex flexDirection="column" gap="2">
                            <Ds.Flex.Item>
                                <label
                                    htmlFor="practice-link"
                                    style={{ margin: 0 }}
                                >
                                    <Ds.Text as="span" weight="bold">
                                        This is your unique practice link
                                    </Ds.Text>
                                </label>
                                <Ds.Text>
                                    You'll need to give it to your IT team to
                                    implement on your website.
                                </Ds.Text>
                            </Ds.Flex.Item>
                            <Ds.Text>
                                You could promote Patient Triage on your website
                                as a button, or in a banner or even by simply
                                adding a link. But remember, the more obvious
                                you make this, the more your patients will be
                                likely to find it and use it.
                            </Ds.Text>
                        </Ds.Flex>
                    </Ds.Flex>
                    <CopyableLink
                        id="practice-link"
                        onCopyClick={copyHandler}
                        url={practiceLink}
                    />
                </Ds.Flex>
            </Card>

            {areDirectLinksEnabled && <DirectLinks links={data.links} />}

            <SupportArticles />
        </Ds.Flex>
    );
};
