import { Ds, Text } from "@accurx/design";
import { ErrorImage } from "domains/inbox/components/ErrorImage/ErrorImage";

import { StyledErrorStateContainer } from "./ConversationFeed.styles";

export type ConversationFeedErrorStateProps = {
    onClick: () => void;
};

export const ConversationFeedErrorState = ({
    onClick,
}: ConversationFeedErrorStateProps) => {
    return (
        <StyledErrorStateContainer
            flexDirection="column"
            alignItems="center"
            gap="1.5"
        >
            <ErrorImage alt="Conversation feed loading error" />
            <Text colour="metal" skinny>
                Sorry, something went wrong. Refresh the page to try again.
            </Text>
            <Ds.Button appearance="secondary" size="small" onClick={onClick}>
                <Ds.Button.Icon name="Refresh" />
                Refresh
            </Ds.Button>
        </StyledErrorStateContainer>
    );
};
