import { Flex, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledCardContainer = styled(Flex)`
    display: flex;
    flex-direction: column;
    gap: ${Tokens.SIZES[1]};
    justify-content: space-between;

    @media (min-width: ${Tokens.BREAKPOINTS.sm}) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
`;

export const StyledChildrenContentFlex = styled(Flex)`
    gap: ${Tokens.SIZES[3]};
    align-items: center;

    @media (max-width: ${Tokens.BREAKPOINTS.sm}) {
        flex-direction: column;
        gap: ${Tokens.SIZES[0.5]};
    }
`;
