import { ReactNode } from "react";

import { Popover, PopoverTrigger } from "@accurx/design";
import * as UI from "@accurx/design";
import { Tooltip } from "@accurx/inbox-design-library";
import { ErrorBoundary } from "@accurx/shared";
import { GenericErrorState } from "domains/message-component/components/Compose/components/GenericErrorState/GenericErrorState";

import {
    StyledErrorContainer,
    StyledPopoverContent,
    StyledPopoverTitle,
    StyledPopoverTitleText,
} from "./ScheduleSend.styles";
import { ScheduleSendPopoverContent } from "./components/ScheduleSendPopoverContent/ScheduleSendPopoverContent";
import { ScheduleSendAt } from "./types/ScheduleSend.types";

type ScheduleSendProps = {
    isOpen: boolean;
    onPopoverOpenChange: () => void;
    onConfirmSheduleSend: (sendAt: ScheduleSendAt) => void;
    handleOnQuickviewOpenChange: () => void;
    children: ReactNode;
};

// Active Item should have a different style
// that is captured in https://linear.app/accurx/issue/BECKS-1389/[scheduled]-add-ui-for-popover
export const ScheduleSend = ({
    isOpen,
    onPopoverOpenChange,
    onConfirmSheduleSend,
    handleOnQuickviewOpenChange,
    children,
}: ScheduleSendProps) => {
    return (
        <Tooltip.Root>
            {!isOpen && (
                <Tooltip.Content>
                    <UI.Text colour="white" variant="note" skinny>
                        Schedule message
                    </UI.Text>
                </Tooltip.Content>
            )}

            <Popover open={isOpen} onOpenChange={onPopoverOpenChange}>
                <Tooltip.Trigger tabIndex={-1} asChild>
                    <PopoverTrigger asChild>{children}</PopoverTrigger>
                </Tooltip.Trigger>

                <StyledPopoverContent align="end">
                    <StyledPopoverTitle>
                        <StyledPopoverTitleText>
                            Schedule message
                        </StyledPopoverTitleText>
                    </StyledPopoverTitle>
                    {isOpen && (
                        <ErrorBoundary
                            fallback={
                                <StyledErrorContainer>
                                    <GenericErrorState>
                                        <UI.Text skinny>
                                            Sorry, something went wrong. Refresh
                                            the page to try again.
                                        </UI.Text>
                                    </GenericErrorState>
                                </StyledErrorContainer>
                            }
                        >
                            <ScheduleSendPopoverContent
                                onConfirmScheduleSend={onConfirmSheduleSend}
                                onClickCustomDate={handleOnQuickviewOpenChange}
                            />
                        </ErrorBoundary>
                    )}
                </StyledPopoverContent>
            </Popover>
        </Tooltip.Root>
    );
};
