import { Button } from "@accurx/design";
import styled from "styled-components";

const StyledButton = styled(Button)`
    height: 40px;
`;

const Wrapper = styled.div`
    position: relative;
`;

const FileInput = styled.input`
    opacity: 0;
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

const Label = styled.label`
    cursor: pointer;
    // Remove default margin of label elements
    margin-bottom: 0;
    // So button text doesn't go on two lines
    white-space: nowrap;
`;

export const StyledFileInputButton = {
    Wrapper,
    FileInput,
    Label,
    StyledButton,
};
