import { StyledTriggerButton } from "layouts/UnifiedLayout/UnifiedLayout.styles";
import styled from "styled-components";

export const StyledWorkspaceMenuButton = styled(StyledTriggerButton)`
    max-width: 100%;

    > span {
        max-width: 25ch;
    }
`;

export const StyledTooltipTriggerWrapper = styled.span`
    display: inline-block;
    min-width: 0;
    border-radius: 0;

    // Style the tooltip trigger extra span added in design library component
    > span {
        min-width: 0;
        max-width: 100%;
        border-radius: 0;
    }
`;
