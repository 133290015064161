import {
    ConversationGroupFilters,
    TriageType,
} from "domains/concierge/internal/types/ConversationGroupFilters";
import { Conversation } from "domains/concierge/types";

type UserParams = {
    userId: string;
    status: "Open" | "Done";
};

const user = ({ userId, status }: UserParams): ConversationGroupFilters => [
    { type: "Source", value: "Ticket" },
    { type: "AssignedTo", value: { type: "User", id: userId } },
    { type: "Status", value: status },
];

type TeamParams = {
    teamId: string;
    status: "Open" | "Done";
};

const team = ({ teamId, status }: TeamParams): ConversationGroupFilters => [
    { type: "Source", value: "Ticket" },
    { type: "AssignedTo", value: { type: "Team", id: teamId } },
    { type: "Status", value: status },
];

type AllParams = {
    status: "Open" | "Done";
};

const all = ({ status }: AllParams): ConversationGroupFilters => [
    { type: "Source", value: "Ticket" },
    { type: "Status", value: status },
];

type AllPatientResponsesParams = {
    status: "Open" | "Done";
};
const allPatientResponses = ({
    status,
}: AllPatientResponsesParams): ConversationGroupFilters => [
    { type: "Source", value: "Ticket" },
    { type: "Status", value: status },
    {
        type: "ContainsItem",
        value: {
            filters: [{ type: "ItemType", value: ["PatientSingleResponse"] }],
        },
    },
];

type AllPatientTriagesParams = {
    status: "Open" | "Done";
};

const allPatientTriages = ({
    status,
}: AllPatientTriagesParams): ConversationGroupFilters => [
    { type: "Source", value: "Ticket" },
    { type: "Status", value: status },
    {
        type: "StartsWithItem",
        value: {
            filters: [
                { type: "ItemType", value: ["PatientTriageRequestNote"] },
            ],
        },
    },
];

const allFailed = (): ConversationGroupFilters => [
    { type: "Source", value: "Ticket" },
    {
        type: "ContainsItem",
        value: {
            filters: [
                {
                    type: "ItemType",
                    value: ["FailedDeliveryReceiptLink", "FallbackFailedLink"],
                },
            ],
        },
    },
];

type UnassignedTriageParams = {
    teamId: string;
    triageType: TriageType;
    status: "Open" | "Done";
};

const unassignedTriage = ({
    teamId,
    status,
    triageType,
}: UnassignedTriageParams): ConversationGroupFilters => [
    { type: "Source", value: "Ticket" },
    { type: "AssignedTo", value: { type: "Team", id: teamId } },
    { type: "Status", value: status },
    {
        type: "StartsWithItem",
        value: {
            filters: [
                {
                    type: "ItemType",
                    value: ["PatientTriageRequestNote"],
                },
                {
                    type: "PatientTriageRequestType",
                    value: triageType,
                },
            ],
        },
    },
];

type ClinicianUserParams = {
    userId: string;
    status: "Open" | "Done";
};

const clinicianUser = ({
    userId,
    status,
}: ClinicianUserParams): ConversationGroupFilters => [
    { type: "Source", value: "ClinicianMessaging" },
    { type: "AssignedTo", value: { type: "User", id: userId } },
    { type: "Status", value: status },
];

type ClinicianTeamParams = {
    teamId: string;
    status: "Open" | "Done";
};

const clinicianTeam = ({
    teamId,
    status,
}: ClinicianTeamParams): ConversationGroupFilters => [
    { type: "Source", value: "ClinicianMessaging" },
    { type: "AssignedTo", value: { type: "Team", id: teamId } },
    { type: "Status", value: status },
];

type ClinicianAllParams = {
    status: "Open" | "Done";
};

const clinicianAll = ({
    status,
}: ClinicianAllParams): ConversationGroupFilters => [
    { type: "Source", value: "ClinicianMessaging" },
    { type: "Status", value: status },
];

type PatientParams = {
    status: "Open" | "Done";
    patientInternalId: string;
};

/**
 * Definition of a Pseudo ticket:
 * A Pseudo ticket is defined as a conversation that only includes 1 sent message.
 *
 * In other folders, we typically display pseudo-tickets in the ‘sent’ folder view.
 * However, since the patient view lacks a ‘sent’ view, we display pseudo-tickets in the ‘done’ view.
 */
const filterDoneAndPseudoTickets: Conversation["status"][] = ["Done", "None"];

const patient = ({
    status,
    patientInternalId,
}: PatientParams): ConversationGroupFilters => [
    { type: "Source", value: "Ticket" },
    {
        type: "Patient",
        value: {
            // we will not subscribe to a group if it's disabled (that's disbaled when there is no accurxId)
            // so it's ok to pass possible undefined value here
            patientInternalId,
        },
    },
    {
        type: "Status",
        value: status === "Open" ? "Open" : filterDoneAndPseudoTickets,
    },
];

export const filters = {
    user,
    team,
    unassignedTriage,
    allPatientResponses,
    allPatientTriages,
    allFailed,
    all,
    clinicianUser,
    clinicianTeam,
    clinicianAll,
    patient,
};
