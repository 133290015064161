import { ROUTES_INBOX } from "domains/inbox/routes";
import { matchPath } from "react-router";

/**
 * Returns `true` if the given pathname matches a path that the Conversation Assigned Overlay
 * should be disabled for (e.g. All Conversations, My Inbox Sent/Scheduled).
 */
export const isDisabledForPath = (pathname: string): boolean => {
    const isMatch = matchPath(pathname, {
        path: [
            ROUTES_INBOX.All,
            ROUTES_INBOX.MyInboxSent,
            ROUTES_INBOX.MyInboxScheduled,
            ROUTES_INBOX.CareProvidersAll,
        ],
        strict: true,
    });
    return Boolean(isMatch);
};
