import { SendRequestWithPatientToken } from "@accurx/api/clinician-messaging";
import { ConciergeUpdates } from "domains/concierge/internal/types/ConciergeUpdates";

import * as ClinicianMessagingApiClient from "../ClinicianMessagingApiClient";
import { WorkspaceIdParam } from "../ClinicianMessagingApiClient";
import { mapConversationSummaryToConversation } from "../mappers/mapConversationSummaryToConversation";

export const messagePractice = async (
    request: SendRequestWithPatientToken & WorkspaceIdParam,
): Promise<{ updates: ConciergeUpdates }> => {
    const { summary } = await ClinicianMessagingApiClient.messagePractice(
        request,
    );

    const conversation = mapConversationSummaryToConversation(summary);

    return {
        updates: {
            conversations: conversation ? [conversation] : [],
            teams: [],
            users: [],
            patients: [],
        },
    };
};
