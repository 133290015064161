import * as Accordion from "@radix-ui/react-accordion";
import {
    Level1AccordionHeaderHeight,
    Level2AccordionHeaderHeight,
} from "domains/navigation/styles/accordionHeight";
import styled, { css } from "styled-components";

const Level1 = styled(Accordion.Item)`
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: ${Level1AccordionHeaderHeight};
`;

const Level2 = styled(Accordion.Item)<{ $scrollable?: boolean }>`
    position: relative;

    ${({ $scrollable }) =>
        $scrollable &&
        css`
            min-height: ${Level2AccordionHeaderHeight};
            overflow: hidden;
        `}
`;

Level2.defaultProps = {
    $scrollable: true,
};

export const Item = {
    Level1,
    Level2,
};
