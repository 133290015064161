import { WorkspaceParticipant } from "@accurx/concierge/types";

import { StyledFeedback } from "./ConversationActionsFeedback.styles";

const getClinicianMessagingUnavailableWarningMessage = (
    workspaces: WorkspaceParticipant[],
) => {
    const names = workspaces.map((w) => w.displayName);

    if (names.length === 1) {
        return `${names[0]} is not using Accurx at this time. Please contact them via email or phone call instead.`;
    }

    if (names.length === 2) {
        return `${names[0]} and ${names[1]} are not using Accurx at this time. Please contact them via email or phone call instead.`;
    }

    if (names.length > 2) {
        const first = names.slice(0, names.length - 1).join(", ");
        const last = names[names.length - 1];
        return `${first} and ${last} are not using Accurx at this time. Please contact them via email or phone call instead.`;
    }
};

export const ClinicianConversationActionsFeedback = ({
    workspaces,
}: {
    workspaces: WorkspaceParticipant[];
}) => {
    const message = getClinicianMessagingUnavailableWarningMessage(workspaces);

    return (
        <StyledFeedback colour="warning">
            <b>{message}</b>
        </StyledFeedback>
    );
};
