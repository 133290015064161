import { useCallback, useEffect, useRef } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { getEmbedMode } from "@accurx/native";
import {
    SignalRSendEvent,
    StatusType,
    useRealtimeForSignalR,
    useSendSignalREvent,
} from "@accurx/realtime";

export const ACTIVITY_INTERVAL = 1000 * 60 * 10; // 10 minutes

export const useSendUserConnectedStatus = (): void => {
    const { orgId: workspaceId } = useCurrentWorkspace();
    const sendUserStatusUpdate = useSendSignalREvent(
        SignalRSendEvent.ConnectedStatus,
    );
    const realtime = useRealtimeForSignalR();
    const { embedMode } = getEmbedMode();
    const lastActiveTimestamp = useRef<number>(Date.now());

    // Send an update whether the user is active based on whether
    // the page is visible or their last active timestamp is within the
    // time threshold for being considered active
    const handleUserActivity = useCallback((): void => {
        const isUserActive =
            document.visibilityState === "visible" ||
            Date.now() - lastActiveTimestamp.current < ACTIVITY_INTERVAL;

        const payload = {
            organisationId: workspaceId,
            machineIdentifier: null,
            notifySingleUserId: null,
            status: isUserActive
                ? StatusType.StatusUpdateActive
                : StatusType.StatusUpdateIdle,
        };

        sendUserStatusUpdate(payload);
    }, [sendUserStatusUpdate, workspaceId]);

    useEffect(() => {
        if (
            embedMode === "Desktop" ||
            realtime.connectionState !== "Connected"
        ) {
            return;
        }

        // Send an update the user is active and update the last active timestamp
        // when the visibility changes to 'visible'
        const handleVisibilityChange = (): void => {
            if (document.visibilityState !== "visible") return;

            const payload = {
                organisationId: workspaceId,
                machineIdentifier: null,
                notifySingleUserId: null,
                status: StatusType.StatusUpdateActive,
            };

            sendUserStatusUpdate(payload);
            lastActiveTimestamp.current = Date.now();
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        // Update whether the user has been active or idle at regular intervals
        const userActivityInterval = setInterval(() => {
            handleUserActivity();
        }, ACTIVITY_INTERVAL);

        return () => {
            clearInterval(userActivityInterval);
            document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange,
            );
        };
    }, [
        embedMode,
        sendUserStatusUpdate,
        handleUserActivity,
        realtime.connectionState,
        workspaceId,
    ]);
};
