import { useCallback } from "react";

import {
    ConversationSortDirection,
    ConversationSortField,
} from "@accurx/api/clinician-messaging";
import { api } from "domains/concierge/internal/api/clinicianMessaging";
import { useConciergeMeta } from "domains/concierge/internal/context";
import {
    ConversationGroupFetcher,
    useConversationGroupQuery,
} from "domains/concierge/internal/hooks/useConversationGroupQuery";
import { ConversationGroupHook } from "domains/concierge/internal/types/ConversationGroupHook";
import { filters } from "domains/concierge/internal/util/conversationGroupFilters";
import { sortOptions } from "domains/concierge/internal/util/conversationGroupSortOptions";

export const useTeamClinicianConversationGroupQuery: ConversationGroupHook<{
    teamId: string;
    status: "Open" | "Done";
    sort: "Newest" | "Oldest";
}> = ({ teamId, status, sort }) => {
    const { workspaceId } = useConciergeMeta();

    const fetcher = useCallback<ConversationGroupFetcher>(
        async (continuationToken) => {
            const { updates, response } = await api.getConversations({
                workspaceId,
                assigneeGroupId: teamId,
                isArchived: status === "Done",
                updatedBeforeDate: continuationToken,
                limit: 20,
                sort: {
                    field: ConversationSortField.LastUpdated,
                    direction:
                        sort === "Newest"
                            ? ConversationSortDirection.Descending
                            : ConversationSortDirection.Ascending,
                },
            });

            return {
                continuationToken: response.nextCursor ?? undefined,
                updates,
            };
        },
        [workspaceId, status, teamId, sort],
    );

    return useConversationGroupQuery({
        useCache: false,
        filters: filters.clinicianTeam({ teamId, status }),
        sortOptions: sortOptions.clinicianCommon({ sort }),
        loggingInfo: {
            name: "Team clinician conversations",
            tags: {
                statusFilter: status,
                teamIdFilter: teamId,
            },
        },
        fetcher,
    });
};
