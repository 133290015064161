import { MeaningfulActionProps } from "@accurx/analytics";
import {
    AnalyticsProperties,
    EventAction,
    EventObjectType,
    EventUserType,
    GenericTrackEvent,
    SharedRudderStackAnalyticProps,
} from "@accurx/shared";

import {
    AddError,
    AddRudderStackAnalyticProps,
    AddSuccess,
    AnalyticsSearchPatientOrigin,
    FlemingAnalyticsPatientListsEnabled,
    FlemingLoggedInCustomProperties,
    IsTestPatientProps,
    OriginProps,
} from "./FlemingAnalyticsConstants";
import {
    TrackFlemingEvent,
    reduceToFlemingLoggedInCustomProperties,
} from "./FlemingEventTracker";
import { PatientConversationSharedProps } from "./SharedInboxEvents";

function ToLower(big: string): string {
    return big.charAt(0).toUpperCase() + big.slice(1);
}

/**
 * Prepends `Send Message - ` in front of all of the exported analytics functions
 * */
const TrackSendMessageEvent = (
    eventName: string,
    props: AnalyticsProperties,
): void => {
    TrackFlemingEvent(`Send Message - ${eventName}`, props);
};

export type SendMessageSamePatientProps = FlemingLoggedInCustomProperties;

/**
 * User Successfully sent message to the same patient as entry link
 *
 * Event type: API response
 * */
export const trackSendMessageSamePatient = (
    props: SendMessageSamePatientProps,
): void => {
    TrackSendMessageEvent("Same Patient As Received Email Link", props);
};

type SharedSentMessageProps = {
    AttachmentUsed: boolean;
    TemplateUsed: boolean;
};

type SentMessageConversationProps = Pick<
    PatientConversationSharedProps,
    | "conversationId"
    | "conversationInitiatedBy"
    | "isPatientInitiatedFollowUpUser"
> & {
    /** Whether the message body contains the PIFU link */
    withPatientInitiatedFollowUpLink: boolean;
};

// The original AppInsights SendMessage properties
export type SentPatientMessageAnalyticsProps = FlemingLoggedInCustomProperties &
    SharedSentMessageProps &
    AnalyticsSearchPatientOrigin &
    FlemingAnalyticsPatientListsEnabled &
    IsTestPatientProps &
    SentMessageConversationProps & {
        patientResponseEnabled: boolean;
        MessageType: string;
        inviteType: string;
        messageLength: number;
        templateName: string | null;
        templateGroup: string | null;
        isPresetTemplate: boolean;
        isMobileNumberOverridden: boolean;
    };

// The new Rudderstack SendMessage properties
export type SentPatientMessageRudderStackAnalyticsProps =
    FlemingLoggedInCustomProperties &
        IsTestPatientProps &
        OriginProps &
        MeaningfulActionProps &
        SentMessageConversationProps & {
            messageType: string;
            inviteType: string;
            messageLength: number;
            withTemplate: boolean;
            withAttachment: boolean;
            countAttachmentFromTemplate: number;
            withPatientResponse: boolean;
            templateName: string | null;
            templateGroup: string | null;
            isPresetTemplate: boolean;
            isMobileNumberOverridden: boolean;
            isReply: boolean;
            withFlorey: boolean;
            floreyName: string | null;
            appOrigin?: string;
            messagePracticeStatus?:
                | "AvailableForUserAndPatient"
                | "NotAvailableForUserNorPatient"
                | `NotAvailableFor${"Patient" | "User"}`;
        };

const mapToSendMessageLegacyProps = (
    props: SentPatientMessageRudderStackAnalyticsProps,
): SentPatientMessageAnalyticsProps => {
    return {
        ...reduceToFlemingLoggedInCustomProperties(props),
        isTestPatient: props.isTestPatient,
        searchPatientOrigin: props.origin,
        TemplateUsed: props.withTemplate,
        AttachmentUsed: props.withAttachment,
        patientResponseEnabled: props.withPatientResponse,
        templateName: props.templateName,
        templateGroup: props.templateGroup,
        isPresetTemplate: props.isPresetTemplate,
        conversationId: props.conversationId,
        conversationInitiatedBy: props.conversationInitiatedBy,
        isFeaturePatientListEnabled: true,
        withPatientInitiatedFollowUpLink:
            props.withPatientInitiatedFollowUpLink,
        isPatientInitiatedFollowUpUser: props.isPatientInitiatedFollowUpUser,
        isMobileNumberOverridden: props.isMobileNumberOverridden,
        MessageType: ToLower(props.messageType),
        messageLength: props.messageLength,
        inviteType: ToLower(props.inviteType),
    };
};

// Not ideal but temporary while unified compose is not fully rolled out.
// This is so we don't have to update every event that uses SentPatientMessageRudderStackAnalyticsProps
// We only want to update PatientMessageSend Button Click and PatientMessageSend Button Response
type AdditionalSendMessageProps = {
    withQuestionnaire?: boolean;
    withTemplate?: boolean;
    withBookingLink?: boolean;
};

/**
 * User clicks the button to send a patient message
 *
 * Event type: Button click
 * */
export const trackPatientMessageSendButtonClickSuccess = (
    props: SentPatientMessageRudderStackAnalyticsProps &
        AdditionalSendMessageProps,
): void => {
    GenericTrackEvent({
        object: "PatientMessageSend",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddSuccess(
            AddRudderStackAnalyticProps(props, { eventVersion: 3 }),
        ),
    });
};

/**
 * User clicks the button to send a patient message
 *
 * Event type: Button click
 * */
export const trackPatientMessageSendButtonClickError = (
    props: SentPatientMessageRudderStackAnalyticsProps &
        AdditionalSendMessageProps,
): void => {
    GenericTrackEvent({
        object: "PatientMessageSend",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddError(
            AddRudderStackAnalyticProps(props, { eventVersion: 2 }),
        ),
    });
};

/**
 * User successfully sent message
 *
 * Event type: API response
 * */
export const trackSentPatientMessageSuccess = (
    props: SentPatientMessageRudderStackAnalyticsProps &
        AdditionalSendMessageProps,
): void => {
    TrackFlemingEvent(
        "Sent Patient Message",
        mapToSendMessageLegacyProps(props),
    );

    // this event should be used in dashboards in future
    GenericTrackEvent({
        object: "PatientMessageSend",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: AddSuccess(
            AddRudderStackAnalyticProps(props, { eventVersion: 2 }),
        ),
    });
};

/**
 * User tried to send Message Patient message,
 * but the request failed
 *
 * Event type: API response
 * */
export const trackSentPatientMessageFailure = (
    props: SentPatientMessageRudderStackAnalyticsProps &
        AdditionalSendMessageProps,
): void => {
    TrackFlemingEvent(
        "Failed to Send Patient Message",
        mapToSendMessageLegacyProps(props),
    );
    GenericTrackEvent({
        object: "PatientMessageSend",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: AddError(
            AddRudderStackAnalyticProps(props, { eventVersion: 2 }),
        ),
    });
};

type SentVideoConsultInviteRudderStackProps = FlemingLoggedInCustomProperties &
    IsTestPatientProps &
    MeaningfulActionProps &
    OriginProps & {
        messageType: string;
        inviteType: string;
        withTemplate: boolean;
        isMobileNumberOverridden: boolean;
    };

type SentVideoConsultInviteRudderStackAnalyticsProps =
    SentVideoConsultInviteRudderStackProps & SharedRudderStackAnalyticProps;

/**
 *
 * Reduce message to video invite type
 */
const VideoOnly = (
    props: SentPatientMessageRudderStackAnalyticsProps,
): SentVideoConsultInviteRudderStackAnalyticsProps => {
    return {
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
        ...reduceToFlemingLoggedInCustomProperties(props),
        isTestPatient: props.isTestPatient,
        origin: props.origin,
        messageType: props.messageType,
        withTemplate: props.withTemplate,
        isMobileNumberOverridden: props.isMobileNumberOverridden,
        inviteType: props.inviteType,
        organisationNationalCode: props.organisationNationalCode,
        isMultiFactorAuthActive: props.isMultiFactorAuthActive,
        isMultiFactorAuthEnabled: props.isMultiFactorAuthEnabled,
    };
};

/**
 * Utility for getting message practice status for use in analytics
 * @param param0
 * @returns
 */
export const getMessagePracticeStatus = ({
    isMessageGpEnabledForCurrentUser = false,
    isMessageGpEnabledForPatient = false,
}): SentPatientMessageRudderStackAnalyticsProps["messagePracticeStatus"] => {
    if (isMessageGpEnabledForCurrentUser && isMessageGpEnabledForPatient) {
        return "AvailableForUserAndPatient";
    }
    if (isMessageGpEnabledForCurrentUser && !isMessageGpEnabledForPatient) {
        return "NotAvailableForPatient";
    }
    if (!isMessageGpEnabledForCurrentUser && isMessageGpEnabledForPatient) {
        return "NotAvailableForUser";
    }
    return "NotAvailableForUserNorPatient";
};

/**
 * User clicked video consult button with no errors
 *
 * Event type: Button click
 * */
export const trackPatientVideoInviteSendButtonClickSuccess = (
    props: SentPatientMessageRudderStackAnalyticsProps,
): void => {
    GenericTrackEvent({
        object: "PatientVideoInviteSend",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddSuccess(VideoOnly(AddRudderStackAnalyticProps(props))),
    });
};

/**
 * User clicked video consult button with error
 *
 * Event type: Button click
 * */
export const trackPatientVideoInviteSendButtonClickError = (
    props: SentPatientMessageRudderStackAnalyticsProps,
): void => {
    GenericTrackEvent({
        object: "PatientVideoInviteSend",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddError(VideoOnly(AddRudderStackAnalyticProps(props))),
    });
};

/**
 * User Successfully sent Video Consult message
 *
 * Event type: API response
 * */
export const trackSentVideoConsultInviteSuccess = (
    props: SentPatientMessageRudderStackAnalyticsProps,
): void => {
    TrackFlemingEvent(
        "Sent Patient Video Invitation",
        mapToSendMessageLegacyProps(props),
    );

    // this event should be used in dashboards in future
    GenericTrackEvent({
        object: "PatientVideoInviteSend",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: AddSuccess(VideoOnly(AddRudderStackAnalyticProps(props))),
    });
};

/**
 * User tried to send Video Consult message,
 * but the request failed
 *
 * Event type: API response
 * */
export const trackSentVideoConsultInviteFailure = (
    props: SentPatientMessageRudderStackAnalyticsProps,
): void => {
    TrackFlemingEvent(
        "Failed to Send Patient Video Invitation",
        mapToSendMessageLegacyProps(props),
    );
    GenericTrackEvent({
        object: "PatientVideoInviteSend",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: AddError(VideoOnly(AddRudderStackAnalyticProps(props))),
    });
};

export type SentPracticeMessageAnalyticsProps =
    FlemingLoggedInCustomProperties &
        SharedSentMessageProps &
        FlemingAnalyticsPatientListsEnabled &
        AnalyticsSearchPatientOrigin & {
            template: string;
            attachments: number;
            fileTypes: string;
            practiceCodeSentTo: string;
            enteredWithUseCase: string | null;
        };

/**
 * User Successfully sent an accuMail
 *
 * Event type: API response
 * */
export const trackSentPracticeMessageSuccess = (
    props: SentPracticeMessageAnalyticsProps,
): void => {
    TrackFlemingEvent("Sent Practice Message", props);
};

/**
 * User tried to send accuMail,
 * but the request failed
 *
 * Event type: API response
 * */
export const trackSentPracticeMessageFailure = (
    props: SentPracticeMessageAnalyticsProps,
): void => {
    TrackFlemingEvent("Failed to Send Practice Message", props);
};
