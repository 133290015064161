import { useCallback } from "react";

import { api } from "domains/concierge/internal/api/ticket";
import { useConciergeMeta } from "domains/concierge/internal/context";
import {
    ConversationGroupFetcher,
    useConversationGroupQuery,
} from "domains/concierge/internal/hooks/useConversationGroupQuery";
import { ConversationGroupHook } from "domains/concierge/internal/types/ConversationGroupHook";
import { filters } from "domains/concierge/internal/util/conversationGroupFilters";
import { sortOptions } from "domains/concierge/internal/util/conversationGroupSortOptions";

export const useUserConversationGroupQuery: ConversationGroupHook<{
    userId: string;
    status: "Open" | "Done";
    sort: "Newest" | "Oldest";
}> = ({ userId, status, sort }) => {
    const { workspaceId } = useConciergeMeta();

    const fetcher = useCallback<ConversationGroupFetcher>(
        async (continuationToken) => {
            const { updates, response } = await api.getFilteredTicketView({
                workspaceId: workspaceId,
                assignee: {
                    type: "User",
                    id: userId,
                },
                isDone: status === "Done",
                ordering: {
                    urgentFirst: status === "Open",
                    mostRecentlyUpdatedFirst: sort === "Newest",
                },
                continuationToken,
            });

            return {
                continuationToken: response.continuationToken ?? undefined,
                updates,
            };
        },
        [workspaceId, userId, status, sort],
    );

    return useConversationGroupQuery({
        filters: filters.user({ userId, status }),
        sortOptions: sortOptions.common({ status, sort }),
        loggingInfo: {
            name: "User conversations",
            tags: {
                statusFilter: status,
                userIdFilter: userId,
            },
        },
        fetcher,
    });
};
