import { ReactNode } from "react";

import { QUICK_VIEW_CONTAINER_ID } from "@accurx/quick-view";

import {
    StyledMainContentWrapper,
    StyledPageWrapper,
    StyledQuickviewWrapper,
} from "./Page.styles";

type PageProps = {
    children: ReactNode;
    withQuickViewContainer?: boolean;
};

export const Page = ({
    children,
    withQuickViewContainer = false,
}: PageProps) => {
    return (
        <StyledPageWrapper>
            <StyledMainContentWrapper>{children}</StyledMainContentWrapper>
            {withQuickViewContainer && (
                <StyledQuickviewWrapper id={QUICK_VIEW_CONTAINER_ID} />
            )}
        </StyledPageWrapper>
    );
};
