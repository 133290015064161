import React, { useEffect, useState } from "react";

import {
    Button,
    Card,
    Ds,
    Feedback,
    Icon,
    Link,
    Spinner,
    StackPanel,
    Text,
    Tokens,
} from "@accurx/design";
import { useHistory } from "react-router-dom";

import {
    getSharedQuestionnaire,
    importSharedQuestionnaire,
} from "api/FloreyBuilder/FloreyBuilderApi";
import {
    trackImportToOrg,
    trackImportToOrgPage,
} from "app/analytics/FlemingAnalytics";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import {
    routeImport,
    routeNameAndQuestions,
    routeShare,
    routeWebImport,
    routeWebNameAndQuestions,
    routeWebShare,
} from "app/practices/floreyBuilder/constants/paths";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { LoadingStatus } from "shared/LoadingStatus";
import { ROUTES_CHAIN } from "shared/Routes";

import { StyledLi, StyledUl } from "../sharing/SharingPage.styles";
import { useImportToOrgPage } from "./ImportToOrgPage.hooks";

export const ImportToOrgPage = (): JSX.Element => {
    const history = useHistory();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const isDesktopPage = history.location.pathname.includes("/practices");

    const [questionnaireInfo, setQuestionnaireInfo] = useState({
        name: "",
        sharingOrgName: "",
    });
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.Initial);
    const [actionLoadingStatus, setActionLoadingStatus] = useState(
        LoadingStatus.Initial,
    );
    const [showError, setShowError] = useState(false);

    const { returnOrg, shareId, orgId, orgName } = useImportToOrgPage();

    const getAndSetQuestionnaireName = async () => {
        setLoadingStatus(LoadingStatus.Loading);
        const response = await getSharedQuestionnaire(shareId);
        if (!response.result || !response.result.questionnaireName) {
            setLoadingStatus(LoadingStatus.Failed);
            return;
        }
        setQuestionnaireInfo({
            name: response.result.questionnaireName,
            sharingOrgName: response.result.sharingOrgName,
        });
        setLoadingStatus(LoadingStatus.Loaded);
    };
    useEffect(() => {
        trackImportToOrgPage({ ...analyticsLoggedInProps, shareId });
        getAndSetQuestionnaireName();
        // getAndSetQuestionnaireName changes on every render and causes the useEffect to fire each time.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shareId]);

    const handleImport = async () => {
        setActionLoadingStatus(LoadingStatus.Loading);
        const response = await importSharedQuestionnaire(
            parseInt(orgId),
            shareId,
            {
                organisationId: parseInt(orgId),
                shareId,
            },
        );
        if (
            !response.success ||
            response.result === null ||
            response.result.importQuestionnaireId === undefined
        ) {
            setShowError(true);
            setActionLoadingStatus(LoadingStatus.Failed);
            return;
        }
        setShowError(false);
        setActionLoadingStatus(LoadingStatus.Loaded);
        const importedQuestionnaireId = response.result.importQuestionnaireId;
        trackImportToOrg({
            ...analyticsLoggedInProps,
            shareId,
            questionnaireId: importedQuestionnaireId,
        });
        history.push(
            isDesktopPage
                ? routeNameAndQuestions(
                      orgId,
                      importedQuestionnaireId.toString() + "?imported=true",
                  )
                : routeWebNameAndQuestions(
                      orgId,
                      importedQuestionnaireId.toString(),
                      true,
                  ),
        );
    };

    //TODO update to getSharedQuestionnaire to return creating practice

    const backLink =
        returnOrg !== null
            ? isDesktopPage
                ? routeShare(returnOrg, shareId ?? "")
                : routeWebShare(returnOrg, shareId ?? "")
            : isDesktopPage
            ? routeImport(shareId ?? "")
            : routeWebImport(shareId ?? "");

    return (
        <>
            {loadingStatus === LoadingStatus.Loading && <Spinner />}
            {loadingStatus === LoadingStatus.Failed && (
                <StackPanel gutter={2}>
                    <Feedback
                        colour={"error"}
                        title={"There was a problem with that sharing link"}
                        content={
                            "Check that there are no mistakes in your link, and try refreshing the page. If the problem persists please contact support"
                        }
                    />
                    <Link
                        href={ROUTES_CHAIN.practices}
                        text={"Take me back to my workspaces"}
                    />
                </StackPanel>
            )}
            {loadingStatus === LoadingStatus.Loaded && (
                <>
                    {isDesktopPage && (
                        <Breadcrumb title="Custom Questionnaires" />
                    )}
                    <StackPanel gutter={2}>
                        {showError && (
                            <Feedback
                                colour="error"
                                title="Something went wrong"
                                content="We couldn't import this questionnaire. Please try again and if the error persists contact support."
                            />
                        )}
                        <Card
                            header={
                                <StackPanel>
                                    <Ds.Text
                                        weight="bold"
                                        as="h1"
                                        size={
                                            isDesktopPage ? "xxlarge" : "large"
                                        }
                                        mt={
                                            isDesktopPage
                                                ? "0"
                                                : Tokens.SIZES[3]
                                        }
                                    >
                                        Import {questionnaireInfo.name}
                                    </Ds.Text>
                                    <Text variant="label" skinny>
                                        To {orgName ?? "your workspace"}
                                    </Text>
                                </StackPanel>
                            }
                            footer={
                                <StackPanel
                                    gutter={2}
                                    orientation="horizontal"
                                    verticalContentAlignment="center"
                                    horizontalContentAlignment="right"
                                >
                                    <Link text="Back" href={backLink} />
                                    <Button
                                        text={
                                            actionLoadingStatus ===
                                            LoadingStatus.Loading
                                                ? "Importing..."
                                                : "Import Questionnaire"
                                        }
                                        disabled={
                                            actionLoadingStatus ===
                                            LoadingStatus.Loading
                                        }
                                        onClick={handleImport}
                                    />
                                </StackPanel>
                            }
                        >
                            <StackPanel gutter={2}>
                                <Text skinny>
                                    This will import a new questionnaire into
                                    your custom questionnaires page. It won't be
                                    linked with the original questionnaire
                                    created by{" "}
                                    {questionnaireInfo.sharingOrgName}, so any
                                    changes they make to the original version
                                    won't be made for your workspace
                                </Text>
                                <StackPanel orientation="horizontal" gutter={1}>
                                    <div>
                                        <Icon
                                            name="Done"
                                            halo={{
                                                colour: "blue",
                                                luminosity: "high",
                                            }}
                                            size={3}
                                        />
                                    </div>
                                    <div>
                                        <Text variant="label" skinny>
                                            What will be imported?
                                        </Text>
                                        <StyledUl>
                                            <StyledLi>
                                                Name of the questionnaire
                                            </StyledLi>
                                            <StyledLi>
                                                Questions & answers
                                            </StyledLi>
                                            <StyledLi>
                                                Invitation message
                                            </StyledLi>
                                            <StyledLi>
                                                Confirmation page
                                            </StyledLi>
                                        </StyledUl>
                                    </div>
                                </StackPanel>
                                <StackPanel orientation="horizontal" gutter={1}>
                                    <div>
                                        <Icon
                                            name="Done"
                                            halo={{
                                                colour: "blue",
                                                luminosity: "high",
                                            }}
                                            size={3}
                                        />
                                    </div>
                                    <div>
                                        <Text variant="label" skinny>
                                            What will happen next?
                                        </Text>
                                        <Text>
                                            You will be able to review and edit
                                            the questionnaire, and you'll need
                                            to choose the default folder you'd
                                            like the responses to come into.
                                        </Text>
                                    </div>
                                </StackPanel>
                            </StackPanel>
                        </Card>
                    </StackPanel>
                </>
            )}
        </>
    );
};

export default ImportToOrgPage;
