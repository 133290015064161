import { ReactNode, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { Ds, Hooks, Popover, PopoverTrigger } from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { useNativeTrackingFields } from "@accurx/native";
import { QuickViewPortal } from "@accurx/quick-view";
import { InlineError } from "domains/message-component/components/InlineError/InlineError";
import { useCompose } from "domains/message-component/context";

import { ContactDetailButton } from "./ContactDetailButton";
import {
    StyledContactDetailsForm,
    StyledFixedHeightContainer,
    StyledPopoverContent,
} from "./ContactDetailSelector.styles";
import { ContactDetailSelectorProps } from "./ContactDetailSelector.types";
import {
    ContactDetailSelectorForm,
    ContactDetailSelectorFormProps,
} from "./ContactDetailSelectorForm";

export const ContactDetailSelector = ({
    contactDetails,
    shouldDisableEmail = false,
}: ContactDetailSelectorProps) => {
    const { state, dispatch } = useCompose();

    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();

    const [isOpen, setIsOpen] = useState(false);
    const [emailInputValue, setEmailInputValue] = useState(
        state.contactDetails.method === "Email" &&
            state.contactDetails.origin === "UserInput"
            ? state.contactDetails.value
            : "",
    );
    const [mobileInputValue, setMobileInputValue] = useState(
        state.contactDetails.method === "Mobile" &&
            state.contactDetails.origin === "UserInput"
            ? state.contactDetails.value
            : "",
    );

    const onSubmit: ContactDetailSelectorFormProps["onSubmit"] = (
        selectedContactDetail,
    ) => {
        dispatch({
            type: "UPDATE_CONTACT_DETAILS",
            payload: { contactDetails: selectedContactDetail },
        });

        track("PatientMessageContactOptionConfirm Button Click", {
            ...nativeTrackingFields,
            contactType: selectedContactDetail.method,
            contactOrigin: selectedContactDetail.origin,
            // there is no such event yet, so no need to rise a version
        });

        setIsOpen(false);
    };

    const contactDetailButtonText =
        state.contactDetails.value === ""
            ? "Enter patient details"
            : `To: ${state.contactDetails.value}`;

    const isError = !!state.errors.noContactDetails;

    return (
        <SelectorLayout
            isOpen={isOpen}
            toggleOpen={() => setIsOpen((prev) => !prev)}
            button={
                <ContactDetailButton
                    text={contactDetailButtonText}
                    toggle={() => setIsOpen((prev) => !prev)}
                    isError={isError}
                />
            }
            error={
                isError && (
                    <InlineError>Enter patient's contact details</InlineError>
                )
            }
            form={
                <ContactDetailSelectorForm
                    contactDetails={contactDetails}
                    onSubmit={onSubmit}
                    onCancel={() => setIsOpen(false)}
                    emailValue={emailInputValue}
                    emailOnChange={setEmailInputValue}
                    mobileValue={mobileInputValue}
                    mobileOnChange={setMobileInputValue}
                    shouldDisableEmail={shouldDisableEmail}
                />
            }
            formFooter={
                <>
                    <Pill.SecondaryButton
                        dimension="small"
                        onClick={() => setIsOpen(false)}
                        type="button"
                    >
                        <Pill.Text>Cancel</Pill.Text>
                    </Pill.SecondaryButton>
                    <Pill.PrimaryButton
                        form="contact-details"
                        type="submit"
                        dimension="small"
                    >
                        <Pill.Text>Confirm</Pill.Text>
                    </Pill.PrimaryButton>
                </>
            }
        />
    );
};

const SelectorLayout = ({
    isOpen,
    toggleOpen,
    button,
    form,
    formFooter,
    error,
}: {
    isOpen: boolean;
    toggleOpen: () => void;
    button: ReactNode;
    form: ReactNode;
    formFooter: ReactNode;
    error: ReactNode;
}) => {
    const { size } = Hooks.useViewportSize();
    const isMobile = size === "xs";

    return (
        <>
            {isMobile && (
                <>
                    <Ds.Flex flexDirection={"column"} gap="0.25">
                        {button}
                        {error}
                    </Ds.Flex>
                    <QuickViewPortal
                        isOpen={isOpen}
                        onClose={toggleOpen}
                        autoFocus={false}
                    >
                        <QuickViewPortal.Header />
                        <QuickViewPortal.Body>{form}</QuickViewPortal.Body>
                        <QuickViewPortal.Footer>
                            <Ds.Flex justifyContent="space-between">
                                {formFooter}
                            </Ds.Flex>
                        </QuickViewPortal.Footer>
                    </QuickViewPortal>
                </>
            )}
            {!isMobile && (
                <Popover open={isOpen} onOpenChange={toggleOpen}>
                    <Ds.Flex flexDirection={"column"} gap="0.25">
                        <PopoverTrigger asChild={true}>{button}</PopoverTrigger>
                        {error}
                    </Ds.Flex>
                    <StyledPopoverContent align="start">
                        <StyledFixedHeightContainer>
                            {form}
                            <StyledContactDetailsForm.HorizontalBorder />
                        </StyledFixedHeightContainer>
                        <StyledContactDetailsForm.ButtonsWrapper
                            justifyContent={"space-between"}
                        >
                            {formFooter}
                        </StyledContactDetailsForm.ButtonsWrapper>
                    </StyledPopoverContent>
                </Popover>
            )}
        </>
    );
};
