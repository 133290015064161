import { Ds } from "@accurx/design";
import { PatientDemographics } from "@accurx/native";
import { mapPatientDemographicsToPatientSearchResult } from "domains/inbox/components/PatientSearch/utils";

import { PatientCard } from "../PatientCard";
import { StyledCard } from "./SuggestedPatientCard.styles";

/**
 * Used as a means to bypass searching for patients and directly
 * go to the patients page
 */
export const SuggestedPatientCard = ({
    patient,
    onSelectClick,
}: {
    onSelectClick: (patient: PatientDemographics) => void;
    patient: PatientDemographics;
}) => {
    const mapped = mapPatientDemographicsToPatientSearchResult(patient);
    return (
        <StyledCard>
            <PatientCard {...mapped} />
            <Ds.Button
                size="small"
                onClick={() => {
                    onSelectClick(patient);
                }}
            >
                Select patient
            </Ds.Button>
        </StyledCard>
    );
};
