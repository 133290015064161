import { ReactNode, useEffect, useState } from "react";

import { FullScreenPage, FullScreenRouterState } from "@accurx/navigation";
import { useHistory, useLocation } from "react-router-dom";

/**
 * Displays children wrapped around unified navigation
 * full screen modal layout
 *
 * This is the place where we keep track of different entry points
 * to then go "back" to, regardless of which page we're on within the flow.
 */
export const FullScreenPageWrapper = ({
    children,
}: {
    children: ReactNode;
}) => {
    const history = useHistory();

    const { state, pathname, search } = useLocation<FullScreenRouterState>();

    // Retain the initial state when the full screen page was created
    const [memoState] = useState<FullScreenRouterState>(state);

    // Overrides the exit location with the original entry point if a user moves between pages within the full screen page
    useEffect(() => {
        if (memoState?.originLocation) {
            history.replace(
                { pathname, search },
                { ...state, originLocation: memoState.originLocation },
            );
        }
        // Only run when path changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return (
        <FullScreenPage
            header="New message"
            onClose={() => history.push(memoState?.originLocation ?? "/")}
        >
            {children}
        </FullScreenPage>
    );
};
