import { useEffect } from "react";

import { useQueryStatus } from "@accurx/concierge/hooks/data/useQueryStatus";
import { useWithPatientsUnreadItems } from "@accurx/concierge/hooks/data/useWithPatientsUnreadItems";
import { useDeepMemo } from "@accurx/hooks";
import { useForwardUnreadItemsMutation } from "@accurx/native";
import { useRealtimeForSignalR } from "@accurx/realtime";

/**
 * This component takes the current unread patient converations and sends them
 * to the toolbar via the native bridge when the unread conversations or their
 * items change. This is to enable the toolbar log the unread items if there is
 * any discrepancy between what the unified inbox considers unread and what the
 * toolbar considers unread. In the future, we would like to unify these systems
 * and one way to do it might be to have the inbox pass the unread count up to
 * the native toolbar app, but that is a concern for the future.
 */
export const UnreadsForwarder = () => {
    const { status } = useQueryStatus("ticketUnreads");
    const tickets = useDeepMemo(useWithPatientsUnreadItems());
    const realtime = useRealtimeForSignalR();
    const { mutate } = useForwardUnreadItemsMutation();
    useEffect(() => {
        if (status === "loading") return;
        mutate({
            isConnectedToSignalR: realtime.connectionState === "Connected",
            tickets,
        });
    }, [tickets, realtime.connectionState, status, mutate]);
    return null;
};
