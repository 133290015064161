import React, { ReactNode } from "react";

import { Button, Ds, Tokens } from "@accurx/design";
import { useQuickViewContext } from "domains/quick-view/context";
import styled, { css } from "styled-components";

const StyledHeader = styled.header<{ isLegacy: boolean }>`
    align-items: center;
    padding: ${Tokens.SIZES[1]};
    display: flex;

    ${(props) =>
        props.isLegacy &&
        css`
            justify-content: space-between;
            flex-direction: row-reverse;
        `}
`;

export const StyledDivider = styled.div`
    height: 24px;
    background-color: ${Tokens.COLOURS.greyscale.silver};
    margin: 0 ${Tokens.SIZES[2]} 0 ${Tokens.SIZES[1]};
    width: 1px;
`;

export const Header = ({
    text,
    children,
}: {
    text?: string;
    /**
     * @deprecated use text prop for new QuickView design
     */
    children?: ReactNode;
}) => {
    const { onClose } = useQuickViewContext();

    return (
        <StyledHeader isLegacy={Boolean(children)}>
            {children || (
                <>
                    <Button
                        onClick={onClose}
                        icon={{
                            name: "Cross",
                            style: "Line",
                            title: "Close",
                        }}
                        theme="transparent"
                    />
                    {text && (
                        <>
                            <StyledDivider />
                            <Ds.Text
                                as="h2"
                                size="medium"
                                weight="bold"
                                color="metal"
                                mb="0"
                            >
                                {text}
                            </Ds.Text>
                        </>
                    )}
                </>
            )}
        </StyledHeader>
    );
};
