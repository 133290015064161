import { useFullScreen } from "layouts/UnifiedLayout/UnifiedLayoutContext";

import { StyledContainer } from "app/layout/Container";
import { VaccineDeliveryUploadSecond } from "app/practices/vaccine/vaccineInvitesUploadAndCompose/VaccineDeliveryUploadSecond";

export const InvitePatientsUploadSecondPage = () => {
    useFullScreen();

    return (
        <StyledContainer>
            <VaccineDeliveryUploadSecond />
        </StyledContainer>
    );
};
