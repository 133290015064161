import {
    EventFields,
    useAnalytics,
    useMeaningfulActionAnalyticsProps,
} from "@accurx/analytics";
import { useNativeTrackingFields } from "@accurx/native";

import {
    AppOrigin,
    OnMessageSendFnComposeData,
} from "../components/Compose/types/Compose.types";

type ComposeStateParts = Omit<
    OnMessageSendFnComposeData,
    "isSaveToRecordEnabled"
>;

export type PatientMessageBaseAnalyticsData = {
    characterCount: number;
    fragmentCount: number;
    isUnicode: boolean;
    shouldSaveToRecord: boolean;
    isReply: boolean;
    origin: string;
    conversationId: string | null;
    hasError: boolean;
    isTestPatient: boolean;
    appOrigin?: keyof typeof AppOrigin | null;
} & ComposeStateParts;

export type PatientMessageSendClickAnalyticsData = Omit<
    PatientMessageBaseAnalyticsData,
    "hasError"
> & {
    warnings: string[];
    errors: string[];
    isModal: boolean;
};

// List of the custom dimensions for analytics provider
type PatientMessageSendCustomDimensions = EventFields<
    "PatientMessageSend Button Click" | "PatientMessageSend Button Response"
>;

const getTemplateTrackingFields = (
    args: Pick<ComposeStateParts, "attachments" | "template">,
): Pick<
    PatientMessageSendCustomDimensions,
    | "templateGroup"
    | "withTemplate"
    | "templateName"
    | "isPresetTemplate"
    | "countAttachmentFromTemplate"
> => {
    return args.template.type === "MessageTemplate"
        ? {
              withTemplate: true,
              isPresetTemplate: args.template.value.owner === "Accurx",
              templateName: args.template.value.title,
              templateGroup: args.template.value.heading,
              countAttachmentFromTemplate: args.attachments.filter(
                  (att) => att.origin === "Template",
              ).length,
          }
        : {
              withTemplate: false,
              countAttachmentFromTemplate: 0,
          };
};
const getQuestionnaireTrackingFields = (
    args: Pick<ComposeStateParts, "template">,
): Pick<
    PatientMessageSendCustomDimensions,
    "withQuestionnaire" | "isPresetTemplate" | "questionnaireName"
> => {
    return args.template.type === "QuestionnaireTemplate"
        ? {
              withQuestionnaire: true,
              isPresetTemplate: args.template.value.owner === "Accurx",
              questionnaireName: args.template.value.title,
          }
        : { withQuestionnaire: false };
};

const getIsMobileOverridden = (
    args: Pick<ComposeStateParts, "contactDetails">,
): PatientMessageSendCustomDimensions["isMobileNumberOverridden"] => {
    return (
        args.contactDetails.origin === "UserInput" &&
        args.contactDetails.method === "Mobile"
    );
};

const getMessageType = (
    args: Pick<ComposeStateParts, "contactDetails">,
): PatientMessageSendCustomDimensions["messageType"] => {
    return args.contactDetails.method === "Mobile" ? "Sms" : "Email";
};

export const usePatientMessageSendAnalytics = () => {
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();

    const { isMultiFactorAuthActive, isMultiFactorAuthEnabled } =
        useMeaningfulActionAnalyticsProps();

    const trackPatientMessageSendResponse = (
        args: PatientMessageBaseAnalyticsData,
    ) => {
        const templateTrackingFields = getTemplateTrackingFields(args);

        const questionnaireTrackingFields =
            getQuestionnaireTrackingFields(args);

        const validAttachments = args.attachments.filter(
            (att) => att.status === "success",
        );

        track("PatientMessageSend Button Response", {
            ...nativeTrackingFields,
            isMultiFactorAuthActive,
            isMultiFactorAuthEnabled,
            isReply: args.isReply,
            origin: args.origin,
            conversationId: args.conversationId,
            isMobileNumberOverridden: getIsMobileOverridden(args),
            isTestPatient: args.isTestPatient,
            messageLength: args.characterCount,
            countFragment: args.fragmentCount,
            messageType: getMessageType(args),
            withAttachment: !!validAttachments.length,
            countAttachment: validAttachments.length,
            withPatientResponse: args.isPatientResponseEnabled,
            hasError: !!args.hasError,
            enabledSaveToMedicalRecord: args.shouldSaveToRecord,
            withUnicodeCharacter: !!args.isUnicode,
            withSchedule: !!args.sendAt?.sendAtDateTime,
            withNHSAdviceLink: !!args.nhsAdviceLink,
            withBookingLink: !!args.selfBookLink,
            appOrigin: args.appOrigin,
            ...questionnaireTrackingFields,
            ...templateTrackingFields,
            eventVersion: 11,
        });
    };

    const trackPatientMessageSendClick = (
        args: PatientMessageSendClickAnalyticsData,
    ) => {
        const templateTrackingFields = getTemplateTrackingFields(args);

        const questionnaireTrackingFields =
            getQuestionnaireTrackingFields(args);

        const validAttachments = args.attachments.filter(
            (att) => att.status === "success",
        );

        track("PatientMessageSend Button Click", {
            ...nativeTrackingFields,
            isMultiFactorAuthActive,
            isMultiFactorAuthEnabled,
            eventVersion: 11,
            isReply: args.isReply,
            origin: args.origin,
            conversationId: args.conversationId,
            isMobileNumberOverridden: getIsMobileOverridden(args),
            isTestPatient: args.isTestPatient,
            messageLength: args.characterCount,
            countFragment: args.fragmentCount,
            messageType: getMessageType(args),
            withAttachment: !!validAttachments.length,
            countAttachment: validAttachments.length,
            withPatientResponse: args.isPatientResponseEnabled,
            enabledSaveToMedicalRecord: args.shouldSaveToRecord,
            withUnicodeCharacter: !!args.isUnicode,
            withSchedule: !!args.sendAt?.sendAtDateTime,
            withNHSAdviceLink: !!args.nhsAdviceLink,
            withBookingLink: !!args.selfBookLink,
            hasWarning: args.warnings.length > 0,
            warningReason: args.warnings,
            hasError: args.errors.length > 0,
            errorReason: args.errors,
            isModal: args.isModal,
            appOrigin: args.appOrigin,
            ...questionnaireTrackingFields,
            ...templateTrackingFields,
        });
    };

    return {
        trackPatientMessageSendResponse,
        trackPatientMessageSendClick,
    };
};
