import { useEffect } from "react";

import { Redirect, Switch, generatePath, useLocation } from "react-router-dom";

import {
    ROUTES_BASE,
    ROUTES_CHAIN,
    ROUTES_ORGS,
    ROUTES_WORKSPACE,
} from "shared/Routes";

export const ManageOrgRedirects = () => {
    const { search } = useLocation();

    useEffect(() => {
        window.sessionStorage.setItem("isManageOrgRedirect", "true");
    }, []);

    return (
        <Switch>
            {/* All messages */}
            <Redirect
                exact
                from="/organisations/:orgId/all-messages"
                to={generatePath(ROUTES_WORKSPACE.workspaceDeliveryReceipts, {
                    workspaceId: ":orgId",
                    tab: "sent",
                })}
            />
            <Redirect
                from="/organisations/:orgId/all-messages/delivery-receipts"
                to={generatePath(ROUTES_WORKSPACE.workspaceDeliveryReceipts, {
                    workspaceId: ":orgId",
                    tab: "sent",
                })}
            />
            <Redirect
                from="/organisations/:orgId/all-messages/delayed-messages"
                to={generatePath(ROUTES_WORKSPACE.workspaceDeliveryReceipts, {
                    workspaceId: ":orgId",
                    tab: "scheduled",
                })}
            />
            <Redirect
                from="/organisations/:orgId/all-messages/batch-messages"
                to={generatePath(ROUTES_WORKSPACE.workspaceBatchMessages, {
                    workspaceId: ":orgId",
                })}
            />

            {/* Appointment reminders */}
            <Redirect
                exact
                from="/organisations/:orgId/appointment-reminders"
                to={generatePath(
                    ROUTES_WORKSPACE.workspaceAppointmentRemindersManage,
                    { workspaceId: ":orgId" },
                )}
            />
            <Redirect
                from={`${ROUTES_ORGS.appointmentReminders}/manage`}
                to={generatePath(
                    ROUTES_WORKSPACE.workspaceAppointmentRemindersManage,
                    { workspaceId: ":orgId" },
                )}
            />
            <Redirect
                from={`${ROUTES_ORGS.appointmentReminders}/sent`}
                to={generatePath(
                    ROUTES_WORKSPACE.workspaceAppointmentRemindersSent,
                    { workspaceId: ":orgId" },
                )}
            />
            <Redirect
                exact
                from={`${ROUTES_ORGS.appointmentReminders}/cancelled`}
                to={generatePath(
                    ROUTES_WORKSPACE.workspaceAppointmentRemindersChanges,
                    { workspaceId: ":orgId" },
                )}
            />
            <Redirect
                exact
                from={ROUTES_CHAIN.practicesAppointmentReminderCompose}
                to={generatePath(
                    `${ROUTES_WORKSPACE.workspaceAppointmentReminders}/create`,
                    { workspaceId: ":orgId" },
                )}
            />

            <Redirect
                from="/practices/:orgId/batch-messaging"
                to={generatePath(
                    ROUTES_WORKSPACE.batchMessageTrustSentMessages,
                    { workspaceId: ":orgId" },
                )}
            />
            <Redirect
                from="/practices/:orgId/batch-messaging-*"
                to="/w/:orgId/batch-messages/batch-messaging-*"
            />

            {/* AccuBook */}
            <Redirect
                exact
                from="/organisations/:orgId/accubook"
                to={generatePath(ROUTES_WORKSPACE.accubookDashboard, {
                    workspaceId: ":orgId",
                })}
            />
            <Redirect
                exact
                from={ROUTES_ORGS.accubook}
                to={generatePath(ROUTES_WORKSPACE.accubook, {
                    workspaceId: ":orgId",
                    tab: ":tab",
                })}
            />
            <Redirect
                exact
                from={ROUTES_CHAIN.practicesVaccineInvitesSingle}
                to={generatePath(ROUTES_WORKSPACE.accubookInvitesSingle, {
                    workspaceId: ":orgId",
                })}
            />
            <Redirect
                exact
                from="/practices/:orgId/vaccine-invites-*"
                to={generatePath(ROUTES_WORKSPACE.accubookInvitesUpload, {
                    workspaceId: ":orgId",
                })}
            />
            <Redirect
                exact
                from={ROUTES_CHAIN.practicesVaccineSmsPreview}
                to={generatePath(ROUTES_WORKSPACE.accubookSendReminder, {
                    workspaceId: ":orgId",
                })}
            />
            <Redirect
                exact
                from={ROUTES_CHAIN.practicesVaccineBookedClinicsId}
                to={generatePath(
                    ROUTES_WORKSPACE.accubookBookedPatientsSession,
                    { workspaceId: ":orgId", sessionId: ":sessionId" },
                )}
            />

            {/* Accumail */}
            <Redirect
                exact
                from="/organisations/:orgId/accumail-manage-templates/"
                to={generatePath(ROUTES_WORKSPACE.accumailTemplatesManage, {
                    workspaceId: ":orgId",
                    tab: "my-templates",
                })}
            />
            <Redirect
                exact
                from="/organisations/:orgId/accumail-manage-templates/my-templates"
                to={generatePath(ROUTES_WORKSPACE.accumailTemplatesManage, {
                    workspaceId: ":orgId",
                    tab: "my-templates",
                })}
            />
            <Redirect
                exact
                from="/organisations/:orgId/accumail-manage-templates/workspace-templates"
                to={generatePath(ROUTES_WORKSPACE.accumailTemplatesManage, {
                    workspaceId: ":orgId",
                    tab: "workspace-templates",
                })}
            />
            <Redirect
                exact
                from="/organisations/:orgId/accumail-manage-templates/accurx-templates"
                to={generatePath(ROUTES_WORKSPACE.accumailTemplatesManage, {
                    workspaceId: ":orgId",
                    tab: "accurx-templates",
                })}
            />
            <Redirect
                exact
                from="/organisations/:orgId/accumail-manage-templates/create/user"
                to={generatePath(ROUTES_WORKSPACE.accumailTemplatesCreate, {
                    workspaceId: ":orgId",
                    type: "user",
                })}
            />
            <Redirect
                exact
                from="/organisations/:orgId/accumail-manage-templates/create/workspace"
                to={generatePath(ROUTES_WORKSPACE.accumailTemplatesCreate, {
                    workspaceId: ":orgId",
                    type: "workspace",
                })}
            />
            <Redirect
                exact
                from={ROUTES_ORGS.accumailManageTemplatesCopy}
                to={generatePath(ROUTES_WORKSPACE.accumailTemplatesCopy, {
                    workspaceId: ":orgId",
                    templateId: ":templateId",
                })}
            />
            <Redirect
                exact
                from={ROUTES_ORGS.accumailManageTemplatesEdit}
                to={generatePath(ROUTES_WORKSPACE.accumailTemplatesEdit, {
                    workspaceId: ":orgId",
                    templateId: ":templateId",
                })}
            />

            {/* Patient triage */}
            <Redirect
                exact
                from={ROUTES_CHAIN.practicesPatientTriage}
                to={generatePath(
                    ROUTES_WORKSPACE.workspacePatientTriageSettings,
                    { workspaceId: ":orgId" },
                )}
            />
            <Redirect
                exact
                from={ROUTES_CHAIN.practicesPatientTriageSettings}
                to={generatePath(
                    ROUTES_WORKSPACE.workspacePatientTriageSettings,
                    { workspaceId: ":orgId" },
                )}
            />
            <Redirect
                exact
                from={ROUTES_CHAIN.practicesPatientTriageSetup}
                to={generatePath(ROUTES_WORKSPACE.workspacePatientTriageSetup, {
                    workspaceId: ":orgId",
                })}
            />
            <Redirect
                exact
                from={ROUTES_CHAIN.practicesPatientTriageDashboards}
                to={generatePath(ROUTES_WORKSPACE.reporting, {
                    workspaceId: ":orgId",
                })}
            />

            {/* Questionnaires */}
            <Redirect
                exact
                from="/practices/:orgId/questionnaire-builder*"
                to="/w/:orgId/settings/questionnaire-builder*"
            />

            {/* Workspace settings */}
            <Redirect
                exact
                from="/organisations/:orgId/settings"
                to={generatePath(ROUTES_WORKSPACE.workspaceUserManagement, {
                    workspaceId: ":orgId",
                })}
            />
            <Redirect
                exact
                from="/organisations/:orgId/settings/users"
                to={generatePath(ROUTES_WORKSPACE.workspaceUserManagement, {
                    workspaceId: ":orgId",
                })}
            />
            <Redirect
                exact
                from="/practices/:orgId/practice-users"
                to={generatePath(ROUTES_WORKSPACE.workspaceUserManagement, {
                    workspaceId: ":orgId",
                })}
            />
            <Redirect
                exact
                from="/organisations/:orgId/settings/available-features"
                to={generatePath(ROUTES_WORKSPACE.workspaceAvailableFeatures, {
                    workspaceId: ":orgId",
                })}
            />
            <Redirect
                exact
                from="/organisations/:orgId/settings/installed-computers"
                to={generatePath(ROUTES_WORKSPACE.workspaceInstalledComputers, {
                    workspaceId: ":orgId",
                })}
            />
            <Redirect
                exact
                from="/organisations/:orgId/settings/team-notifications"
                to={generatePath(ROUTES_WORKSPACE.workspaceTeamNotifications, {
                    workspaceId: ":orgId",
                })}
            />
            <Redirect
                exact
                from="/organisations/:orgId/settings/sms-settings"
                to={generatePath(ROUTES_WORKSPACE.workspaceSmsSettings, {
                    workspaceId: ":orgId",
                })}
            />
            <Redirect
                exact
                from="/organisations/:orgId/settings/patient-triage"
                to={generatePath(
                    ROUTES_WORKSPACE.workspacePatientTriageSettings,
                    { workspaceId: ":orgId" },
                )}
            />

            {/* Record view */}
            <Redirect
                exact
                from="/practices/:orgId/record-view-*"
                to={generatePath(ROUTES_WORKSPACE.workspaceMedicalRecords, {
                    workspaceId: ":orgId",
                })}
            />

            {/* Account settings */}
            <Redirect
                exact
                from="/organisations/:orgId/settings/profile"
                to={ROUTES_BASE.accountUserNameAndEmail}
            />
            <Redirect
                exact
                from="/organisations/:orgId/settings/password"
                to={ROUTES_BASE.accountUserPassword}
            />
            <Redirect
                exact
                from="/organisations/:orgId/settings/manage-signature"
                to={ROUTES_BASE.accountUserManageSignature}
            />
            <Redirect
                exact
                from="/organisations/:orgId/settings/cookies"
                to={ROUTES_BASE.accountUserCookies}
            />
            <Redirect
                exact
                from="/organisations/:orgId/settings/security"
                to={ROUTES_BASE.accountUserSecurity}
            />
            <Redirect
                exact
                from="/organisations/:orgId/settings/terms-and-conditions"
                to={ROUTES_BASE.accountUserTermsAndConditions}
            />
            <Redirect
                exact
                from="/practices/settings/profile"
                to={ROUTES_BASE.accountUserNameAndEmail}
            />

            {/* Templates */}
            <Redirect
                exact
                from={ROUTES_CHAIN.practicesManageTemplatesForm}
                to={`/w/:orgId/settings/template/:templateId/:action?` + search}
            />
            <Redirect
                exact
                from="/practices/:orgId/manage-templates*"
                to={`/w/:orgId/settings/templates*` + search}
            />

            {/* VOIP */}
            <Redirect
                exact
                from="/practices/:orgId/voip"
                to={"/w/:orgId/voip" + search}
            />

            {/* Reporting */}
            <Redirect
                exact
                from={ROUTES_ORGS.reporting}
                to={generatePath(ROUTES_WORKSPACE.reporting, {
                    workspaceId: ":orgId",
                })}
            />
            <Redirect
                exact
                from="/organisations/:orgId/reporting/patient-triage"
                to={generatePath(ROUTES_WORKSPACE.reportingPatientTriage, {
                    workspaceId: ":orgId",
                })}
            />
            <Redirect
                exact
                from={ROUTES_ORGS.reportPatientTriage}
                to={generatePath(ROUTES_WORKSPACE.reportingPatientTriage, {
                    workspaceId: ":orgId",
                    tab: ":tab",
                })}
            />
            <Redirect
                exact
                from="/organisations/:orgId/reporting/response-rates"
                to={generatePath(ROUTES_WORKSPACE.reportingResponseRates, {
                    workspaceId: ":orgId",
                })}
            />
            <Redirect
                exact
                from={ROUTES_ORGS.reportResponseRates}
                to={generatePath(ROUTES_WORKSPACE.reportingResponseRates, {
                    workspaceId: ":orgId",
                    tab: ":tab",
                })}
            />
            <Redirect
                exact
                from="/organisations/:orgId/reporting/fragments"
                to={generatePath(ROUTES_WORKSPACE.reportingFragments, {
                    workspaceId: ":orgId",
                })}
            />
            <Redirect
                exact
                from={ROUTES_ORGS.reportFragments}
                to={generatePath(ROUTES_WORKSPACE.reportingFragments, {
                    workspaceId: ":orgId",
                    tab: ":tab",
                })}
            />
            <Redirect
                exact
                from="/organisations/:orgId/usage-dashboard"
                to={generatePath(ROUTES_WORKSPACE.reportingTeamUsage, {
                    workspaceId: ":orgId",
                })}
            />

            {/* Homepage */}
            <Redirect
                from="/practices/:orgId"
                to={generatePath(ROUTES_WORKSPACE.workspaceTemplatesOverview, {
                    workspaceId: ":orgId",
                })}
            />
            <Redirect
                from="/organisations/:orgId"
                to={generatePath(ROUTES_WORKSPACE.workspaceTemplatesOverview, {
                    workspaceId: ":orgId",
                })}
            />
        </Switch>
    );
};
