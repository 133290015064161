import {
    BulkAppointmentImport,
    BulkAppointmentImportStatus,
} from "@accurx/api/appointment";
import { Pagination, Table, usePagination, useTable } from "@accurx/design";
import { format, parseISO } from "date-fns";
import { StyledPaginationContainer } from "domains/appointment-reminders/components/AppointmentReminder.styles";

interface UploadedAppointmentsTableProps {
    imports: BulkAppointmentImport[];
}

export const PAGE_SIZE = 50;

export const UploadedAppointmentsTable = ({
    imports,
}: UploadedAppointmentsTableProps) => {
    const table = useTable({
        data: imports,
        columnDefs: [
            {
                id: "fileName",
                header: "File name",
            },
            {
                id: "uploadAt",
                header: "Upload date",
                sort: {
                    direction: "desc",
                    selector: (row) => row.uploadAt,
                },
            },
            {
                id: "createdByUserName",
                header: "User",
            },
            {
                id: "appointmentsCount",
                header: "Appointments count",
            },
            {
                id: "status",
                header: "Status",
            },
        ],
        defaultOptions: {
            sortColumnId: "uploadAt",
            sortDirection: "desc",
        },
    });

    const { currentPage, totalPages, onNext, onPrevious, paginate } =
        usePagination({
            defaultPage: 1,
            defaultPageSize: PAGE_SIZE,
            total: table.rows.length,
        });

    return (
        <>
            <Table>
                <Table.Head>
                    <Table.Row>
                        {table.columns.map((column) => (
                            <Table.Header key={column.id} scope="col">
                                {column.header}
                            </Table.Header>
                        ))}
                    </Table.Row>
                </Table.Head>
                <Table.Body>
                    {paginate(table.rows).map((row) => (
                        <Table.Row key={row.id}>
                            <Table.DataCell>{row.fileName}</Table.DataCell>
                            <Table.DataCell>
                                {format(
                                    parseISO(row.uploadAt),
                                    "HH:mm - dd MMM yyyy",
                                )}
                            </Table.DataCell>
                            <Table.DataCell>
                                {row.createdByUserName}
                            </Table.DataCell>
                            <Table.DataCell>
                                {row.appointmentsCount}
                            </Table.DataCell>
                            <Table.DataCell>
                                {BulkAppointmentImportStatus[row.status]}
                            </Table.DataCell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <StyledPaginationContainer>
                <Pagination>
                    <Pagination.Navigation
                        currentPage={currentPage}
                        totalPages={totalPages}
                    >
                        {currentPage > 1 && (
                            <>
                                <Pagination.PreviousButton
                                    onPrevious={onPrevious}
                                />
                            </>
                        )}
                        {currentPage < totalPages && (
                            <>
                                <Pagination.NextButton onNext={onNext} />
                            </>
                        )}
                    </Pagination.Navigation>
                </Pagination>
            </StyledPaginationContainer>
        </>
    );
};
