import { GPRequestResult, SearchForPatientResponse } from "@accurx/api/portal";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import { differenceInYears } from "date-fns";
import { PatientDemographics } from "domains/navigation/schemas/PatientDemographicSchema";
import { PatientsSearchResult } from "domains/navigation/schemas/PatientSearchResultSchema";
import { SearchForPatientSuccess } from "domains/navigation/schemas/SearchForPatientSchema";

import {
    FormPdsResult,
    PatientExternalId,
    PatientSearchResult,
} from "../../types";
import { mapPatientsNames } from "./patientDisplayInfo";

const formatDateShortWithHyphens = (date: string) => {
    return DateHelpers.formatDate(
        date,
        DateFormatOptions.DATE_SHORT_WITH_HYPHENS,
    );
};

const calculateAge = (dob: string) =>
    differenceInYears(new Date(), new Date(dob));

const formatDateToAgeDisplay = (dob: string) => `(${calculateAge(dob)}y)`;

const formatDateToAgeYear = (dob: string) => calculateAge(dob);

const getNhsNumberFromExternalId = (patientExternalIds: PatientExternalId[]) =>
    patientExternalIds.find((id) => id.type === "NhsNumber")?.value;

export const mapPdsResultToPatientSearchResult = ({
    patient,
    patientToken,
}: FormPdsResult): PatientSearchResult => ({
    nhsNumber: patient.nhsNumber,
    dateOfBirth: patient.dateOfBirth
        ? formatDateShortWithHyphens(patient.dateOfBirth)
        : null,
    ageDisplay: patient.ageDisplay,
    ageYear: patient.ageYear,
    gender: patient.gender ?? "Unknown",
    prefixName: patient.prefixName ?? "",
    firstName: patient.firstName ?? "",
    familyName: patient.familyName ?? "",
    displayName: patient.displayName ?? "",
    nickname: null,
    mobileNumber: patient.mobileNumber,
    deceased: patient.deceased,
    patientToken: patientToken ?? undefined,
    practiceCode: patient.practiceCode,
    practiceName: patient.practiceName,
    practicePhoneNumber: patient.practicePhoneNumber,
    practiceAddress: patient.practiceAddress,
    featuresAvailable: patient.featuresAvailable,
    recordViewSupported: patient.recordViewSupported,
    externalIds: patient.nhsNumber
        ? [{ type: "NhsNumber", value: patient.nhsNumber }]
        : undefined,
});

export const mapPatientSearchResponse = (
    searchResponse: SearchForPatientSuccess,
) =>
    searchResponse.searchedResult.patient
        ? [
              mapPdsResultToPatientSearchResult({
                  patient: searchResponse.searchedResult.patient,
                  patientToken: searchResponse.searchedResult.patientToken,
              }),
          ]
        : [];

export const mapPatientDemographicsToPatientSearchResult = (
    data: PatientDemographics,
): PatientSearchResult => {
    const name = mapPatientsNames(data.patientName.usual);
    const nickname = mapPatientsNames(data.patientName.nickname);

    return {
        nhsNumber:
            getNhsNumberFromExternalId(
                data.patientExternalIdentityDto.patientExternalIds,
            ) ?? null,
        dateOfBirth: data.dateOfBirth
            ? formatDateShortWithHyphens(data.dateOfBirth)
            : null,
        ageDisplay: data.dateOfBirth
            ? formatDateToAgeDisplay(data.dateOfBirth)
            : null,
        ageYear: data.dateOfBirth
            ? formatDateToAgeYear(data.dateOfBirth)
            : null,
        gender: data.gender,
        prefixName: name.prefixName,
        firstName: name.firstName,
        familyName: name.familyName,
        nickname: nickname.firstName,
        mobileNumber: data.contact.mobileTelephone,
        homeNumber: data.contact.homeTelephone,
        workNumber: data.contact.workTelephone,
        address: data.contact.address,
        email: data.contact.email,
        externalIds: data.patientExternalIdentityDto.patientExternalIds,
    };
};

export const mapNativePatientSearchResponse = (
    searchResponse: PatientsSearchResult,
) =>
    searchResponse.results.map((result) =>
        mapPatientDemographicsToPatientSearchResult(result),
    );

// Note: This is a temporary solution for PatientSearch to support Patient Profile via Redux
// Once we can refactor Patient Profile to accept router data / it fetches its own data, then this mapper is no longer required
export const mapPatientSearchResultToPdsResult = (
    patient: PatientSearchResult,
): SearchForPatientResponse => ({
    searched: true,
    searchedNhsNumber: patient.nhsNumber,
    searchedResult: {
        matchFound: true,
        patientToken: patient.patientToken,
        patient: {
            dateOfBirth: patient.dateOfBirth,
            nhsNumber: patient.nhsNumber,
            prefixName: patient.prefixName,
            firstName: patient.firstName,
            familyName: patient.familyName,
            displayName: patient.displayName,
            deceased: patient.deceased ?? false,
            ageYear: patient.ageYear as number,
            ageDisplay: patient.ageDisplay,
            gender: patient.gender,
            mobileNumber: patient.mobileNumber,
            practiceCode: patient.practiceCode,
            practiceName: patient.practiceName,
            practicePhoneNumber: patient.practicePhoneNumber,
            practiceAddress: patient.practiceAddress,
            featuresAvailable: patient.featuresAvailable ?? [],
            recordViewSupported: patient.recordViewSupported as GPRequestResult,
        },
    },
});
