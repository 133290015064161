import { ContactDetail } from "@accurx/message-component";

export const getIsContactSubmittedByPatientDifferentFromEmr = (
    contactDetails: ContactDetail[],
) => {
    const emrContact = contactDetails.find(
        ({ origin, method }) => origin === "EMR" && method === "Mobile",
    );

    if (!emrContact) return false;

    return contactDetails.some(
        ({ origin, value }) =>
            origin === "SubmittedByPatient" && emrContact.value !== value,
    );
};
