import { MouseEvent, ReactNode } from "react";

import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { LinkProps } from "domains/inbox/components/Link/Link";
import { UnreadCount } from "domains/inbox/components/UnreadCount/UnreadCount";

import {
    StyledCurrentPatient,
    StyledCurrentPatientActionButton,
    StyledCurrentPatientTop,
    StyledName,
} from "../CurrentPatient.styles";

type CurrentPatientWithPatientProps = {
    patientCardAriaLabel: string;
    displayName: string;
    to: LinkProps["to"];
    unreadCount?: number;

    shouldShowTriageButton: boolean;
    isTriageButtonLoading: boolean;
    onTriageButtonClick: () => void;

    shouldShowComposeButton: boolean;
    isComposeButtonLoading: boolean;
    onComposeButtonClick: () => void;

    isActive: boolean;
    onClick: () => void;
    children: ReactNode;
};

export const CurrentPatientWithPatient = ({
    patientCardAriaLabel,
    displayName,
    to,
    unreadCount,
    isActive,
    onClick,
    isTriageButtonLoading,
    onTriageButtonClick,
    shouldShowTriageButton,
    shouldShowComposeButton,
    onComposeButtonClick,
    isComposeButtonLoading,
    children: patientCardLabel,
}: CurrentPatientWithPatientProps) => {
    const handleTriageButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();
        onTriageButtonClick();
    };
    const handleComposeButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();
        onComposeButtonClick();
    };

    return (
        <StyledCurrentPatient
            to={to}
            $isActive={isActive}
            onClick={(e) => {
                e.stopPropagation();
                onClick();
            }}
            aria-current={isActive ? "page" : undefined}
            aria-label={`${patientCardAriaLabel} ${displayName}`}
        >
            <UI.Flex flexDirection="column" gap="2">
                <div>
                    <StyledCurrentPatientTop>
                        {patientCardLabel}
                        {!!unreadCount && <UnreadCount count={unreadCount} />}
                    </StyledCurrentPatientTop>
                    <StyledName>{displayName}</StyledName>
                </div>
                {(shouldShowTriageButton || shouldShowComposeButton) && (
                    <UI.Flex gap="1">
                        {shouldShowTriageButton && (
                            <StyledCurrentPatientActionButton
                                onClick={handleTriageButtonClick}
                                disabled={isTriageButtonLoading}
                                $stretch
                            >
                                {isTriageButtonLoading ? (
                                    <UI.Spinner
                                        dimension="extra-small"
                                        colour="white"
                                    />
                                ) : (
                                    <UI.Icon name="Plus" colour="white" />
                                )}
                                <UI.Text variant="note" as="span" skinny>
                                    New triage
                                </UI.Text>
                            </StyledCurrentPatientActionButton>
                        )}
                        {/* 
                        - If triage button doesn't show, display 
                          the compose button with full visible text and full width, 
                        - If triage button shows, show icon only 
                    */}
                        {shouldShowComposeButton && (
                            <StyledCurrentPatientActionButton
                                onClick={handleComposeButtonClick}
                                disabled={isComposeButtonLoading}
                                $stretch={!shouldShowTriageButton}
                            >
                                {isComposeButtonLoading ? (
                                    <Pill.Icon
                                        isLoading
                                        spinnerColour="white"
                                    />
                                ) : (
                                    <UI.Icon
                                        name="PenToSquare"
                                        colour="white"
                                    />
                                )}
                                {!shouldShowTriageButton ? (
                                    <UI.Text variant="note" as="span" skinny>
                                        New message
                                    </UI.Text>
                                ) : (
                                    <UI.VisuallyHidden>
                                        New message
                                    </UI.VisuallyHidden>
                                )}
                            </StyledCurrentPatientActionButton>
                        )}
                    </UI.Flex>
                )}
            </UI.Flex>
        </StyledCurrentPatient>
    );
};
