import { useCurrentWorkspace } from "@accurx/auth";
import { Card, Flex, Text } from "@accurx/design";
import {
    isArchivedWorkspace,
    isUserCreatedWorkspace,
} from "@accurx/workspace-management";
import { generatePath } from "react-router-dom";

import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { ReactRouterLink } from "app/sharedComponents/reactRouterLink/ReactRouterLink";
import { ROUTES_WORKSPACE } from "shared/Routes";

const CardItem = ({ title, value }: { title: string; value: string }) => {
    return (
        <div>
            <Text variant="label" as="h2">
                {title}
            </Text>
            <Text variant="body">{value || <br />}</Text>
        </div>
    );
};

export const GeneralWorkspaceSettings = () => {
    const workspace = useCurrentWorkspace();

    const {
        organisationName: workspaceName,
        description,
        orgId: workspaceId,
        settings: { isAdminUser },
        workspaceSpecialties,
    } = workspace;

    // Only display option to edit the details if the current user is an admin of the workspace, it has flexible workspace FF & it is not a default workspace
    const canEditWorkspaceDetails =
        isAdminUser &&
        isUserCreatedWorkspace(workspace) &&
        !isArchivedWorkspace(workspace);

    return (
        <StyledContainer>
            <PageHeader title="Workspace name" type={PageHeaderType.ListPage} />
            <Card>
                <Flex justifyContent="space-between" alignItems="flex-start">
                    <Flex flexDirection="column">
                        <CardItem title="Name" value={workspaceName} />
                        {isUserCreatedWorkspace(workspace) && (
                            <CardItem
                                title="Description (optional)"
                                value={description || ""}
                            />
                        )}
                        {isUserCreatedWorkspace(workspace) && (
                            <CardItem
                                title="Specialty"
                                value={workspaceSpecialties[0] || "None"}
                            />
                        )}
                    </Flex>
                    {canEditWorkspaceDetails ? (
                        <ReactRouterLink
                            to={generatePath(ROUTES_WORKSPACE.workspaceEdit, {
                                workspaceId,
                            })}
                        >
                            Edit
                        </ReactRouterLink>
                    ) : null}
                </Flex>
            </Card>
        </StyledContainer>
    );
};
