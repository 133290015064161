import {
    StyledPatientActions,
    StyledPatientDetails,
    StyledPatientHeader,
} from "./PatientHeader.styles";
import { AdditionalDetails } from "./components/AdditionalDetails";
import { LoadingState } from "./components/LoadingState";
import { PatientName } from "./components/PatientName";

const PatientHeader = Object.assign(StyledPatientHeader, {
    Details: Object.assign(StyledPatientDetails, {
        Name: PatientName,
        Additional: AdditionalDetails,
    }),
    Actions: StyledPatientActions,
});

export { LoadingState as PatientHeaderLoading, PatientHeader };
