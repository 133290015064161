import { Ds } from "@accurx/design";
import { Feedback } from "@accurx/inbox-design-library";
import { useCompose } from "domains/message-component/context";

export const StillLoadingErrors = () => {
    const { state } = useCompose();

    if (!state.errors.attachmentLoading && !state.errors.questionnaireLoading) {
        return null;
    }

    return (
        <Ds.Flex flexDirection="column" gap="0.5">
            {!!state.errors.attachmentLoading && (
                <Feedback
                    title="Attachment uploading. Wait for upload to complete to send."
                    iconName="Failed"
                    colour="error"
                />
            )}

            {!!state.errors.questionnaireLoading && (
                <Feedback
                    title="Questionnaire loading. Wait for loading to complete to send."
                    iconName="Failed"
                    colour="error"
                />
            )}
        </Ds.Flex>
    );
};
