import { useEffect } from "react";

import { useNativeSubscription } from "@accurx/native";
import { Log } from "@accurx/shared";
import { Subject } from "rxjs";

import { SubscriptionSubjects } from "../types";
import { SignalREventPayload } from "../types/signalR";
import { SignalRSubscriptionEvent } from "../types/signalR/events";

const EVENT_SUBJECTS: SubscriptionSubjects<SignalRSubscriptionEvent> = {};

export const useOnNativeSignalRSubscriptionEvent = () => {
    const event = useNativeSubscription("SubscribeSignalRInvocations");

    useEffect(() => {
        if (event.status === "loading" || event.status === "idle") return;
        if (event.status === "error") {
            Log.error(
                "useOnNativeSignalRSubscriptionEvent: Unsuccessful subscription event",
                {
                    tags: {
                        errorMessage: event.error.message,
                    },
                },
            );
            return;
        }

        if (!isValidMethodName(event.data.methodName)) {
            Log.error(
                "useOnNativeSignalRSubscriptionEvent: Invalid subscription event methodName",
                {
                    tags: {
                        methodName: event.data.methodName,
                    },
                },
            );
            return;
        }

        const subject = EVENT_SUBJECTS[event.data.methodName];
        if (!subject) {
            Log.warn(
                "useOnNativeSignalRSubscriptionEvent: Subject not found for subscription event",
                {
                    tags: { methodName: event.data.methodName },
                },
            );

            return;
        }

        subject.next(
            event.data
                .parameter as SignalREventPayload[typeof event.data.methodName],
        );
    }, [event]);
};

export const getEventSubscription = <
    E extends SignalRSubscriptionEvent,
    P = Subject<SignalREventPayload[E]>,
>({
    type,
    onNewSubscription,
}: {
    type: E;
    onNewSubscription?: () => void;
}): P => {
    const createSubject = () => {
        EVENT_SUBJECTS[type] = new Subject();

        // A callback that can be called only when registering a subscription when it
        // has not already been registered (ie the first time). This will not be
        // called when re-registering a subscription
        onNewSubscription?.();
        return EVENT_SUBJECTS[type];
    };

    const subject = EVENT_SUBJECTS[type] ?? createSubject();
    return subject as P;
};

export function clearAllSubscriptions(): void {
    Object.keys(EVENT_SUBJECTS).forEach((key) => {
        delete EVENT_SUBJECTS[key as SignalRSubscriptionEvent];
    });
}

function isValidMethodName(name: string): name is SignalRSubscriptionEvent {
    return Object.keys(SignalRSubscriptionEvent).includes(name);
}

export function getSubscriptionNames(): SignalRSubscriptionEvent[] {
    return Object.keys(EVENT_SUBJECTS).filter(isValidMethodName);
}
