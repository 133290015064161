import { ReactNode } from "react";

import { Ds, Hooks } from "@accurx/design";
import { useFullScreen } from "layouts/UnifiedLayout/UnifiedLayoutContext";
import {
    PORTAL_ID_APP_HEADER,
    PORTAL_ID_APP_SUBHEADER,
} from "layouts/constants";
import { createPortal } from "react-dom";
import { useHistory } from "react-router-dom";

import {
    StyledBody,
    StyledContainer,
    StyledDivider,
    StyledFooter,
    StyledHeader,
    StyledPage,
} from "./FullScreenPage.styles";

export const FullScreenPage = ({
    header,
    onClose,
    children,
}: {
    header?: string;
    onClose?: () => void;
    children: ReactNode;
}) => {
    useFullScreen({ isBordered: true });

    const history = useHistory();
    const { size } = Hooks.useViewportSize();
    const isDesktop = ["lg", "xl"].includes(size);

    const handleClose = () => {
        onClose ? onClose() : history.goBack();
    };

    const contentHeaderRoot = document.getElementById(PORTAL_ID_APP_HEADER);
    const pageHeaderRoot = document.getElementById(PORTAL_ID_APP_SUBHEADER);

    // Render the header on a different node so that QuickView takes up the entire view on mobile/tablet,
    // whilst on desktop it appears underneath the app header.
    const portalRoot = isDesktop ? contentHeaderRoot : pageHeaderRoot;

    return (
        <StyledPage>
            {portalRoot &&
                createPortal(
                    <StyledHeader>
                        <Ds.Button
                            appearance="tertiary"
                            onClick={handleClose}
                            aria-label="Close"
                        >
                            <Ds.Button.Icon name="Cross" />
                        </Ds.Button>
                        {header && (
                            <>
                                <StyledDivider />
                                <Ds.Text as="h2" weight="bold" color="metal">
                                    {header}
                                </Ds.Text>
                            </>
                        )}
                    </StyledHeader>,
                    portalRoot,
                )}
            {children}
        </StyledPage>
    );
};

FullScreenPage.Body = StyledBody;
FullScreenPage.Container = StyledContainer;
FullScreenPage.Footer = StyledFooter;
