import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useAddLabelToTicketMutation } from "@accurx/concierge/hooks/mutations/useAddLabelToTicketMutation";
import { Conversation } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { useConversationAnalyticsFields } from "domains/inbox/hooks/util/useConversationAnalyticsFields";
import { getLastConversationLabelTagItem } from "domains/inbox/util/getLastConversationLabelTag";
import { toast } from "react-toastify";

import { OtherLabelModal } from "./OtherLabelModal";
import { useOutcomeLoadingContext } from "./OutcomeLoadingContext";

type OutcomeLabelButtonProps = {
    conversation: Conversation;
    labelName: string;
    labelCode: string;
    onSuccess?: () => void;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const OutcomeLabelButton = ({
    conversation,
    labelName,
    labelCode,
    onSuccess,
    autoFocus,
}: OutcomeLabelButtonProps) => {
    // This tells us if a request to add an outcome label to this conversation
    // is already taking place from another button.
    const { isLoading: globalIsLoading, setIsLoading: setGlobalIsLoading } =
        useOutcomeLoadingContext();
    const analyticsFields = useConversationAnalyticsFields({ conversation });
    const track = useAnalytics();
    const existingLabelTag = getLastConversationLabelTagItem(conversation);
    const [isOtherLabelModalOpen, setIsOtherLabelModalOpen] = useState(false);

    const addLabelToTicketMutation = useAddLabelToTicketMutation({
        // TODO: Agree error state with design
        onError: () =>
            toast(
                <UI.Feedback
                    colour="error"
                    title="Sorry, something went wrong. Please try again."
                />,
            ),
        onSuccess,
    });

    const handleAddLabel = (labelDescription?: string) => {
        if (existingLabelTag) {
            track("ConversationOutcomeEdit Button Click", {
                ...analyticsFields,
                outcome: labelName,
                otherOutcomeDescription: labelDescription,
                previousOutcome: existingLabelTag.label,
            });
        } else {
            track("ConversationOutcomeConfirm Button Click", {
                ...analyticsFields,
                outcome: labelName,
                otherOutcomeDescription: labelDescription,
            });
        }

        setGlobalIsLoading(true);
        addLabelToTicketMutation.mutate(
            {
                latestToken: conversation.latestToken,
                conversationSource: conversation.source,
                labelCode,
                otherDescription: labelDescription,
            },
            {
                onSettled: (_data, error) => {
                    if (existingLabelTag) {
                        track("ConversationOutcomeEdit Button Response", {
                            ...analyticsFields,
                            outcome: labelName,
                            otherOutcomeDescription: labelDescription,
                            previousOutcome: existingLabelTag.label,
                            hasError: !!error,
                        });
                    } else {
                        track("ConversationOutcomeConfirm Button Response", {
                            ...analyticsFields,
                            outcome: labelName,
                            otherOutcomeDescription: labelDescription,
                            hasError: !!error,
                        });
                    }

                    setGlobalIsLoading(false);
                },
            },
        );
    };

    const ariaLabel =
        labelCode === "other"
            ? "Enter a different outcome for this conversation"
            : `Record the outcome of this conversation as ${labelName}`;

    return (
        <>
            <UI.Ds.Button
                autoFocus={autoFocus}
                fullwidth
                appearance="secondary"
                aria-label={ariaLabel}
                onClick={() => {
                    if (labelCode === "other") {
                        setIsOtherLabelModalOpen(true);
                        return;
                    }

                    handleAddLabel();
                }}
                disabled={globalIsLoading || addLabelToTicketMutation.isLoading}
            >
                <UI.Ds.Flex gap="1" alignItems="center">
                    {addLabelToTicketMutation.isLoading && (
                        <UI.Ds.Spinner size="xsmall" />
                    )}
                    <UI.Ds.Text size="small" weight="bold">
                        {addLabelToTicketMutation.isLoading
                            ? "Saving outcome"
                            : labelName}
                    </UI.Ds.Text>
                </UI.Ds.Flex>
            </UI.Ds.Button>
            <OtherLabelModal
                isOpen={isOtherLabelModalOpen}
                onClose={() => setIsOtherLabelModalOpen(false)}
                onConfirm={(labelDescription) => {
                    setIsOtherLabelModalOpen(false);
                    handleAddLabel(labelDescription);
                }}
            />
        </>
    );
};
