import { filters } from "domains/concierge/internal/util/conversationGroupFilters";
import { sortOptions } from "domains/concierge/internal/util/conversationGroupSortOptions";

import { useConversationCount } from "./useConversationCount";

export const useClinicianTeamConversationCount = (teamId: string) => {
    return useConversationCount(
        filters.clinicianTeam({ teamId, status: "Open" }),
        sortOptions.clinicianCommon({ sort: "Newest" }),
    );
};
