import { ErrorSummaryProvider } from "@accurx/design";
import { Redirect, generatePath, useParams } from "react-router";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { Breadcrumb, Header } from "app/organisations/shared";
import { ROUTES_ORGS } from "shared/Routes";

import {
    CreateTemplatePageParam,
    createParamToTemplateType,
} from "../manageTemplates/ManageAccumailTemplates.tabs";
import { SaveMode } from "../templates.types";
import { CopyAccumailTemplate } from "./CopyTemplate";
import { CreateAccumailTemplate } from "./CreateTemplate";
import { EditAccumailTemplate } from "./EditTemplate";
import { StyledBreadcrumbs } from "./EditTemplatePage.styles";

type EditParams =
    | {
          templateId: string;
          type?: undefined;
      }
    | {
          templateId?: undefined;
          type: CreateTemplatePageParam;
      };

function isValidCreateTab(tab?: string): tab is CreateTemplatePageParam {
    if (!tab) return false;
    return ["user", "workspace"].includes(tab);
}

type AccumailEditTemplatePageProps = { saveMode: SaveMode; noHeader?: boolean };

export const AccumailEditTemplatePage = ({
    saveMode,
    noHeader = false,
}: AccumailEditTemplatePageProps) => {
    const params = useParams<EditParams>();
    const { selectedOrgId: workspaceId } = useSelectedOrganisation();

    const paths = {
        copy: ROUTES_ORGS.accumailManageTemplatesCopy,
        create: ROUTES_ORGS.accumailManageTemplatesCreate,
        edit: ROUTES_ORGS.accumailManageTemplatesEdit,
    };
    const breadcrumbs = {
        copy: "Copy a template",
        create: "Create a new template",
        edit: "Edit a template",
    };

    if (!workspaceId) {
        return null;
    }

    return (
        <>
            {!noHeader && (
                <Header>
                    <StyledBreadcrumbs>
                        <Breadcrumb
                            path={ROUTES_ORGS.accumailManageTemplates}
                            text="Manage Accumail templates"
                        />
                        <Breadcrumb
                            path={paths[saveMode]}
                            text={breadcrumbs[saveMode]}
                        />
                    </StyledBreadcrumbs>
                </Header>
            )}
            <ErrorSummaryProvider>
                {saveMode === "edit" && !!params.templateId && (
                    <EditAccumailTemplate templateId={params.templateId} />
                )}
                {saveMode === "copy" && !!params.templateId && (
                    <CopyAccumailTemplate
                        workspaceId={workspaceId}
                        templateId={params.templateId}
                    />
                )}
                {saveMode === "create" && isValidCreateTab(params.type) && (
                    <CreateAccumailTemplate
                        type={createParamToTemplateType(params.type)}
                    />
                )}
            </ErrorSummaryProvider>
            {saveMode === "create" && !isValidCreateTab(params.type) && (
                <Redirect
                    to={generatePath(ROUTES_ORGS.accumailManageTemplates, {
                        orgId: workspaceId,
                    })}
                />
            )}
        </>
    );
};
