import { useState } from "react";

import { useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import { QuickViewPortal } from "@accurx/quick-view";
import { useSelfBookConfigurationAnalytics } from "domains/self-book/hooks/useSelfbookAnalytics";
import { SelfbookConfigurationPayload } from "domains/self-book/utils/types";

import { SelfbookConfigurationForm } from "../SelfbookConfigurationForm";

type SelfbookConfiguratioQuickViewProps = {
    showSelfbookConfiguration: boolean;
    sendAt: string;
    setShowSelfbookConfiguration: (showConfig: boolean) => void;
    onSelfbookConfigComplete: (
        selfbookConfigData: SelfbookConfigurationPayload,
    ) => void;
};

export const SelfbookConfigurationQuickView = ({
    showSelfbookConfiguration,
    sendAt,
    setShowSelfbookConfiguration,
    onSelfbookConfigComplete,
}: SelfbookConfiguratioQuickViewProps) => {
    const appOrigin = "UnifiedInbox";

    const analytics = useSelfBookConfigurationAnalytics({
        appOrigin: appOrigin,
    });

    const workspace = useCurrentWorkspace();

    const isCrossOrgEnabled = useFeatureFlag("HasCrossOrgBooking");
    const isAppointmentAvailabilityEnabled = useFeatureFlag(
        "SelfBookAppointmentAvailability",
    );

    const [isTelephoneFlow, setIsTelephoneFlow] = useState<boolean>(false);

    const resetFormState = () => {
        setIsTelephoneFlow(false);
        setShowSelfbookConfiguration(false);
    };

    return (
        <QuickViewPortal
            isOpen={showSelfbookConfiguration}
            onClose={resetFormState}
        >
            <QuickViewPortal.Header text="Add a Self-Book link" />
            <SelfbookConfigurationForm
                orgId={workspace.orgId}
                organisationName={workspace.organisationName}
                showCrossOrg={isCrossOrgEnabled}
                showAppointmentAvailability={isAppointmentAvailabilityEnabled}
                isTelephoneFlow={isTelephoneFlow}
                telephoneFlowOnClickBack={() => setIsTelephoneFlow(false)}
                appOrigin={appOrigin}
                sendAt={sendAt}
                onSelfbookConfigComplete={(
                    selfBookConfig,
                    defaultAppointmentAvailabilityPeriod,
                ) => {
                    if (
                        selfBookConfig.appointmentType === "FaceToFace" ||
                        isTelephoneFlow
                    ) {
                        analytics.trackBookingAddConfirmButtonClick({
                            selfBookConfig,
                            defaultAppointmentAvailabilityPeriod,
                        });
                        onSelfbookConfigComplete(selfBookConfig);
                        resetFormState();
                    } else {
                        setIsTelephoneFlow(true);
                    }
                }}
            />
        </QuickViewPortal>
    );
};
