import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import { LatestSearchListDto } from "@accurx/api/patient-messaging";
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";

type LatestPatientsSearchQueryVariables = {
    organisationId: number;
    search: string;
};

export const LatestPatientSearchQueryKey = "LatestPatientSearchQuery";

const fetchRecentPatients = async ({
    organisationId,
    search,
}: LatestPatientsSearchQueryVariables): Promise<
    IWrappedResult<LatestSearchListDto>
> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/patientmessaging/latestpatientsearch/query",
        }),
        {
            organisationId,
            search,
        },
    );

export const useLatestPatientSearchQuery = createQueryHook<
    LatestPatientsSearchQueryVariables,
    LatestSearchListDto
>(({ organisationId, search }) => ({
    queryKey: [LatestPatientSearchQueryKey, { organisationId, search }],
    queryFn: async () => {
        const response = await fetchRecentPatients({ organisationId, search });
        return returnDataOrThrow(response);
    },
    refetchOnMount: true,
}));
