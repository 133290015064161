import { Ds } from "@accurx/design";
import styled from "styled-components";

export const StyledFlex = styled(Ds.Flex)`
    height: 100%;
`;

export const StyledText = styled(Ds.Text)`
    text-align: center;
`;
