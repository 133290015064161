import { Ds, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledComposeFeedbacksContainer = styled(Ds.Flex).attrs({
    flexDirection: "column",
    gap: "1",
})`
    padding: ${Tokens.SIZES[1.5]};
    padding-bottom: 0;

    &:empty {
        display: none;
    }
`;

export const StyledComposeNewMessageFlex = styled(Ds.Flex).attrs({
    flexDirection: "column",
})`
    height: 100%;
`;
