import React from "react";

import { Button, Ds } from "@accurx/design";
import { useHistory } from "react-router-dom";

import { StyledNewNavFooter, StyledWrapper } from "./StepsFooter.styles";

export type StepsFooterProps = FooterProps & { zIndex?: number };

export type FooterProps = {
    backText?: string;
    backLink?: string;
    backClickFunction?: () => void;
    forwardText?: string;
    forwardLink?: string;
    forwardClickFunction?: () => void;
    forwardButtonIsCancel?: boolean;
    disabled: boolean;
};

const UnstyledFooter = ({
    backText,
    backLink,
    backClickFunction,
    forwardText,
    forwardLink,
    forwardClickFunction,
    forwardButtonIsCancel,
    disabled,
}: StepsFooterProps): JSX.Element => {
    const history = useHistory();

    const handleBackClick = () => {
        backClickFunction && backClickFunction();
        backLink && history.push(backLink);
    };

    const handleForwardClick = () => {
        forwardClickFunction && forwardClickFunction();
        forwardLink && history.push(forwardLink);
    };

    return (
        <>
            {(backLink || backClickFunction) && backText && (
                <Button
                    theme="secondary"
                    type="button"
                    data-testid="back-button"
                    onClick={handleBackClick}
                    text={backText}
                />
            )}
            {(forwardLink || forwardClickFunction) && forwardText && (
                <Button
                    theme={forwardButtonIsCancel ? "danger" : "primary"}
                    type="button"
                    data-testid="forward-button"
                    onClick={handleForwardClick}
                    disabled={disabled}
                    text={forwardText}
                />
            )}
        </>
    );
};

export const StepsFooter = ({
    zIndex,
    ...props
}: StepsFooterProps): JSX.Element => {
    return (
        <StyledWrapper $zIndex={zIndex}>
            <Ds.Flex flexDirection="row" gap="2">
                <UnstyledFooter {...props} />
            </Ds.Flex>
        </StyledWrapper>
    );
};

export const NewNavFooter = (props: FooterProps): JSX.Element => {
    return (
        <StyledNewNavFooter flexDirection="row" gap="2" justifyContent="end">
            <UnstyledFooter {...props} />
        </StyledNewNavFooter>
    );
};
