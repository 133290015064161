import { useFullScreen } from "layouts/UnifiedLayout/UnifiedLayoutContext";

import { StyledContainer } from "app/layout/Container";
import { VaccineDeliveryReviewInvites } from "app/practices/vaccine/vaccineInvitesUploadAndCompose/VaccineDeliveryReviewInvites";

export const InvitePatientsReviewPage = () => {
    useFullScreen();

    return (
        <StyledContainer>
            <VaccineDeliveryReviewInvites />
        </StyledContainer>
    );
};
