import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import * as UI from "@accurx/design";
import { Feedback } from "@accurx/inbox-design-library";
import { useNativeTrackingFields } from "@accurx/native";
import { InlineError } from "domains/message-component/components/InlineError/InlineError";
import { useCompose } from "domains/message-component/context";
import { ContactDetail } from "domains/message-component/reducer.types";
import { PatientExternalId } from "domains/message-component/types";

import { useCommunicationConsent } from "./CommunicationConsent/ConsentAggregate/useCommunicationConsent";
import { PatientCommunicationConsentSummary } from "./CommunicationConsent/PatientCommunicationConsentSummary";
import { ContactButton } from "./ContactButton";
import { CustomContactDetailForm } from "./CustomContactDetailForm";
import { NewContactOptionButton } from "./NewContactOptionButton";
import {
    StyledContactDetailsList,
    StyledCustomContactDetailForm,
    StyledDivider,
    StyledMinWidthFlex,
} from "./RecipientSelector.styles";
import { RecipientSelectorButton } from "./RecipientSelectorButton";
import { RecipientSelectorButtonContent } from "./RecipientSelectorButtonContent";

type RecipientSelectorProps = {
    patientExternalIds: PatientExternalId[];
    shouldDisableEmail: boolean;
    contactDetails: ContactDetail[];
    forceOpenSide?: "top" | "bottom";
};

export const RecipientSelector = ({
    patientExternalIds,
    contactDetails,
    shouldDisableEmail,
    forceOpenSide,
}: RecipientSelectorProps) => {
    const { state, dispatch } = useCompose();
    const [isOpen, setIsOpen] = useState(false);
    const [showCustomContactDetailsForm, setShowCustomContactDetailsForm] =
        useState(false);
    const [customInputValue, setCustomInputValue] = useState(
        state.contactDetails.origin === "UserInput"
            ? state.contactDetails.value
            : "",
    );
    const communicationConsentQuery = useCommunicationConsent({
        patientExternalIds,
    });

    const hasError = !!state.errors.noContactDetails;
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();

    return (
        <UI.Popover
            open={isOpen}
            onOpenChange={(open) => {
                setShowCustomContactDetailsForm(
                    state.contactDetails.origin === "UserInput",
                );
                setIsOpen(open);
            }}
        >
            <StyledMinWidthFlex
                gap="0.5"
                alignItems={hasError ? "baseline" : "center"}
            >
                <UI.Ds.Text size="small" color="zinc">
                    To:
                </UI.Ds.Text>
                <StyledMinWidthFlex
                    flexDirection={"column"}
                    gap="0.25"
                    alignItems={"start"}
                >
                    <UI.PopoverTrigger asChild={true}>
                        <RecipientSelectorButton
                            onClick={() => null}
                            isError={!!state.errors.noContactDetails}
                        >
                            <RecipientSelectorButtonContent
                                patientExternalIds={patientExternalIds}
                            />
                        </RecipientSelectorButton>
                    </UI.PopoverTrigger>
                    {hasError && (
                        <InlineError>
                            Enter patient's contact details to send message
                        </InlineError>
                    )}
                </StyledMinWidthFlex>
            </StyledMinWidthFlex>
            <UI.PopoverContent
                align="start"
                aria-labelledby="recipient-selector"
                side={forceOpenSide}
                width="300px"
            >
                <li>
                    <UI.VisuallyHidden>
                        <UI.Ds.Text id="recipient-selector">
                            Select contact method
                        </UI.Ds.Text>
                    </UI.VisuallyHidden>
                    {communicationConsentQuery?.status === "error" && (
                        <Feedback
                            title="An error occurred when loading patient communication consent"
                            colour="warning"
                        >
                            <UI.Ds.Text as="span" color="night" size="small">
                                Check the medical record for details
                            </UI.Ds.Text>
                        </Feedback>
                    )}
                    {contactDetails.length > 0 &&
                    !showCustomContactDetailsForm ? (
                        <UI.Ds.Flex gap="0.5" flexDirection={"column"}>
                            <StyledContactDetailsList>
                                {contactDetails.map((contactOption, i) => {
                                    return (
                                        <li
                                            key={`${contactOption.origin}-${contactOption.method}-${i}`}
                                            data-testid="contact-option"
                                        >
                                            <ContactButton
                                                disabled={
                                                    shouldDisableEmail &&
                                                    contactOption.method ===
                                                        "Email"
                                                }
                                                patientExternalIds={
                                                    patientExternalIds
                                                }
                                                contactDetail={contactOption}
                                                onClick={() => {
                                                    dispatch({
                                                        type: "UPDATE_CONTACT_DETAILS",
                                                        payload: {
                                                            contactDetails:
                                                                contactOption,
                                                        },
                                                    });
                                                    track(
                                                        "PatientMessageContactOptionConfirm Button Click",
                                                        {
                                                            ...nativeTrackingFields,
                                                            contactType:
                                                                contactOption.method,
                                                            contactOrigin:
                                                                contactOption.origin,
                                                        },
                                                    );
                                                    setIsOpen(false);
                                                }}
                                            />
                                        </li>
                                    );
                                })}
                            </StyledContactDetailsList>
                            <PatientCommunicationConsentSummary
                                patientExternalIds={patientExternalIds}
                            />
                            <StyledDivider />
                            <NewContactOptionButton
                                onClick={() => {
                                    setShowCustomContactDetailsForm(true);
                                }}
                            />
                        </UI.Ds.Flex>
                    ) : (
                        <StyledCustomContactDetailForm
                            flexDirection={"column"}
                            gap="2"
                        >
                            <UI.Ds.Flex gap="1" alignItems={"center"}>
                                {contactDetails.length > 0 && (
                                    <UI.Ds.Button
                                        appearance="tertiary"
                                        size="small"
                                        onClick={() => {
                                            setShowCustomContactDetailsForm(
                                                false,
                                            );
                                        }}
                                        aria-label="Go back"
                                    >
                                        <UI.Ds.Icon
                                            name="ArrowTail"
                                            rotate="270"
                                            size="small"
                                        />
                                    </UI.Ds.Button>
                                )}
                                <UI.Ds.Text
                                    as="h2"
                                    size="medium"
                                    id="custom-contact-detail-form"
                                    weight={"bold"}
                                >
                                    Add new mobile or email
                                </UI.Ds.Text>
                            </UI.Ds.Flex>
                            <CustomContactDetailForm
                                patientExternalIds={patientExternalIds}
                                formLabelledById="custom-contact-detail-form"
                                onContactDetailAdded={(contactDetail) => {
                                    setIsOpen(false);
                                    setShowCustomContactDetailsForm(false);
                                    track(
                                        "PatientMessageContactOptionConfirm Button Click",
                                        {
                                            ...nativeTrackingFields,
                                            contactType: contactDetail.method,
                                            contactOrigin: contactDetail.origin,
                                        },
                                    );
                                }}
                                inputValue={customInputValue}
                                setInputValue={setCustomInputValue}
                            />
                        </StyledCustomContactDetailForm>
                    )}
                </li>
            </UI.PopoverContent>
        </UI.Popover>
    );
};
