import { useCurrentUser, useFeatureFlag } from "@accurx/auth";
import { useUser } from "@accurx/concierge/hooks/data/useUser";
import { useUserConversationGroupQuery } from "@accurx/concierge/hooks/queries/useUserConversationGroupQuery";
import { getMostRecentItem } from "@accurx/concierge/util/getMostRecentItem";
import * as UI from "@accurx/design";
import { Ds } from "@accurx/design";
import {
    ConversationFeed,
    ConversationFeedOptionsWrapper,
    ConversationListHeader,
    ConversationTabItem,
    ConversationTabs,
} from "domains/inbox/components/ConversationList/components";
import { ConversationPreview } from "domains/inbox/components/ConversationPreview/ConversationPreview";
import {
    useInboxLink,
    useInboxParams,
    useInboxTitle,
} from "domains/inbox/hooks/util";
import { useConversationListAnalytics } from "domains/inbox/hooks/util/useConversationListAnalytics";
import { userflowIds } from "domains/inbox/util";

import { ConversationSortOrder } from "../../components/ConversationSortOrder/ConversationSortOrder";
import { getEmptyStateVariant } from "../../components/EmptyState/EmptyState";
import {
    StyledAccessibleHeader,
    StyledConversationFeedContainer,
    StyledConversationList,
    StyledHeaderTitle,
} from "../styles";

export const MyInbox = () => {
    const { status, sort } = useInboxParams(["status", "sort"]);
    const link = useInboxLink();
    const { user } = useCurrentUser();
    const userSummary = useUser({ userId: user.accuRxUserId });
    const groupQuery = useUserConversationGroupQuery({
        userId: user.accuRxUserId,
        status,
        sort,
    });

    const { trackTabClick } = useConversationListAnalytics();

    useInboxTitle(`My inbox ${status.toLowerCase()} conversations`);
    const emptyStateVariant = getEmptyStateVariant(status);
    const canUseSorting = useFeatureFlag("UnifiedInboxSortingBuild");

    return (
        <>
            <StyledConversationList rows="auto 1fr">
                <UI.Cell>
                    <ConversationListHeader
                        heading={
                            <>
                                <StyledHeaderTitle>
                                    My inbox
                                    <StyledAccessibleHeader>
                                        showing {status.toLowerCase()} messages
                                    </StyledAccessibleHeader>
                                </StyledHeaderTitle>
                                {userSummary?.status === "Unapproved" && (
                                    <Ds.Badge color="greyscale">
                                        Pending approval
                                    </Ds.Badge>
                                )}
                            </>
                        }
                    />
                    <ConversationTabs>
                        <ConversationTabItem
                            active={status === "Open"}
                            link={link.update({
                                status: "Open",
                                sort: null,
                                conversationId: null,
                            })}
                            userflowId={userflowIds.conversationList.openTab}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Done",
                                    newTab: "Open",
                                    conversationParticipant: "WithPatient",
                                })
                            }
                        >
                            Open
                        </ConversationTabItem>

                        <ConversationTabItem
                            active={status === "Done"}
                            link={link.update({
                                status: "Done",
                                sort: null,
                                conversationId: null,
                            })}
                            userflowId={userflowIds.conversationList.doneTab}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Open",
                                    newTab: "Done",
                                    conversationParticipant: "WithPatient",
                                })
                            }
                        >
                            Done
                        </ConversationTabItem>
                    </ConversationTabs>
                </UI.Cell>
                <StyledConversationFeedContainer>
                    {canUseSorting && (
                        <ConversationFeedOptionsWrapper>
                            <Ds.Flex.Item>
                                <ConversationSortOrder />
                            </Ds.Flex.Item>
                        </ConversationFeedOptionsWrapper>
                    )}
                    <ConversationFeed
                        key={groupQuery.data?.id}
                        isLoading={groupQuery.status === "loading"}
                        isError={groupQuery.status === "error"}
                        refetch={groupQuery.refetch}
                        fetchMore={groupQuery.fetchMore}
                        isFetchingMore={groupQuery.isFetchingMore}
                        isFullyLoaded={groupQuery.data?.isFullyLoaded}
                        members={groupQuery.data?.members}
                        filters={groupQuery.data?.filters}
                        emptyStateVariant={emptyStateVariant}
                        renderItem={(conversation, state) => (
                            <ConversationPreview
                                conversation={conversation}
                                item={getMostRecentItem(conversation)}
                                state={state}
                            />
                        )}
                    />
                </StyledConversationFeedContainer>
            </StyledConversationList>
        </>
    );
};
