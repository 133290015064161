import { ReactNode, createContext, useContext, useState } from "react";

type DirectoryView = "Search" | "AddContact" | "EditContact";

export const DirectoryContext = createContext<{
    view: DirectoryView;
    setView: (view: DirectoryView) => void;
} | null>(null);

export const DirectoryProvider = ({ children }: { children: ReactNode }) => {
    const [view, setView] = useState<DirectoryView>("Search");

    return (
        <DirectoryContext.Provider value={{ view, setView }}>
            {children}
        </DirectoryContext.Provider>
    );
};

export const useDirectory = () => {
    const context = useContext(DirectoryContext);

    if (context === null) {
        throw new Error(
            "This component is a directory consumer, it must be used within a DirectoryProvider",
        );
    }

    return context;
};
