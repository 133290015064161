import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledFormSpacer = styled(UI.Ds.Flex).attrs({
    flexDirection: "column",
    gap: "2",
})``;

export const StyledDateOfBirthWrapper = styled.div`
    // This is required as the design library DateOfBirthInput applies unwanted margin to the legend
    * > legend {
        margin-bottom: ${UI.Tokens.SIZES[0.5]};
    }

    * {
        font-size: ${UI.Tokens.TYPOGRAPHY.note.desktop.fontSize};
    }

    input {
        height: ${UI.Tokens.SIZES[4]};
        font-size: ${UI.Tokens.TYPOGRAPHY.note.desktop.fontSize};
        line-height: ${UI.Tokens.TYPOGRAPHY.note.desktop.lineHeight};
    }
`;

export const StyledSearchFormSubmitButtonContainer = styled(UI.Ds.Flex)`
    justify-content: flex-end;
`;
