import React, { Fragment, useEffect, useMemo, useState } from "react";

import { Button, Spinner, Text } from "@accurx/design";
import { shallowEqual, useDispatch } from "react-redux";
import { Dropdown, DropdownItem, DropdownMenu, Modal } from "reactstrap";

import {
    MessageTemplate,
    MessageTemplateOwner,
    MessageTemplateType,
} from "api/FlemingDtos";
import { AnalyticsMapper, FlemingAnalyticsTracker } from "app/analytics";
import {
    getNewMessageTemplate,
    mapPresetTemplateToMessageTemplate,
} from "app/messageTemplates/MessageTemplatesHelper";
import {
    ManageMessageTemplate,
    ManageTemplateActionType,
} from "app/messageTemplates/messageTemplatesEditCreate/ManageMessageTemplate";
import { MESSAGE_TEMPLATE_FORM_TITLE_ID } from "app/messageTemplates/messageTemplatesEditCreate/ManageMessageTemplateConstants";
import { OrganisationHelper } from "shared/OrganisationHelper";
import {
    MessageTemplateOption,
    PresetTemplate,
    PresetTemplateSection,
    Templates,
} from "shared/Templates";
import { truncateLongWord } from "shared/formatters/String";
import { useAppSelector } from "store/hooks";

import { actionCreators as fileUploadActionCreators } from "../../fileUpload/FileUploadActions";
import { actionCreators as messageTemplateActionCreators } from "../../messageTemplates/MessageTemplatesActions";
import { ProductSelection } from "../../selectProduct/ProductSelection";
import { StyledDropdownToggle } from "./TemplatesInput.styles";

export interface TemplateInputProps {
    handleTemplateModalToggle: () => void;
    /** Open state of the templates modal */
    isTemplateModalOpen: boolean;
    /** Flag that indicates whether the send message form is in a modal or not */
    isInFormModal: boolean;
    templateType: MessageTemplateType;
    userDefaultTemplate: MessageTemplate | null;
    /** The list of "Accurx" templates - aka not custom user or org templates */
    presetTemplatesList: PresetTemplateSection[];
    /** The list of user + org wide custom templates */
    customTemplatesList: MessageTemplate[];
    selectedTemplate: MessageTemplate;
    onTemplateSelect: (template: MessageTemplateOption) => void;
}

const dropdownSectionColorStyle = "rgb(3,124,124)";

export const TemplatesInput = ({
    handleTemplateModalToggle,
    isTemplateModalOpen,
    isInFormModal,
    templateType,
    userDefaultTemplate,
    customTemplatesList,
    presetTemplatesList,
    selectedTemplate,
    onTemplateSelect,
}: TemplateInputProps): JSX.Element | null => {
    const dispatch = useDispatch();
    const accountState = useAppSelector((state) => state.account, shallowEqual);

    const orgId = useAppSelector(
        (state) => state.account?.selectedOrganisation,
    );
    const isMessageTemplatesLoading = useAppSelector(
        ({ messageTemplates }) => messageTemplates.isMessageTemplatesLoading,
    );

    const productSelection = useAppSelector(
        ({ selectProduct }) => selectProduct.productSelection,
    );

    const isVideoConsult = productSelection === ProductSelection.Video;
    const lastMessageTemplateActionLocation = useAppSelector(
        ({ messageTemplates }) =>
            messageTemplates.lastMessageTemplateActionLocation,
    );
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [dropDownHeight, setDropdownHeight] = useState("20rem");

    // Make sure that we clear all the uploads on component unmount
    useEffect(() => {
        return (): void => {
            dispatch(fileUploadActionCreators.resetFileUpload());
        };
    }, [dispatch]);

    // Load message templates on mount
    useEffect(() => {
        if (!!orgId) {
            dispatch(
                messageTemplateActionCreators.getUserMessageTemplates(
                    {
                        organisationId: orgId,
                        isApprovedUser:
                            OrganisationHelper.getIsApprovedOrgUser(
                                accountState,
                            ),
                    },
                    true,
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId, dispatch]);

    // UI hack: adjust the height of the dropdown if you can
    useEffect(() => {
        if (
            (userDefaultTemplate !== null && customTemplatesList.length < 3) ||
            customTemplatesList.length < 6
        ) {
            setDropdownHeight("initial");
        }
    }, [userDefaultTemplate, customTemplatesList]);

    /**
     * Set the select template dropdown either open or closed
     */
    const handleDropdownToggle = (): void => {
        if (!isDropdownOpen) {
            const props = AnalyticsMapper.getMessageTemplateProps(
                selectedTemplate,
                accountState,
                lastMessageTemplateActionLocation,
                productSelection,
            );
            FlemingAnalyticsTracker.trackSelectATemplateClicked({
                ...props,
                currentTemplate: selectedTemplate.title,
            });
        }
        setIsDropdownOpen((prevState) => !prevState);
    };

    const handleCustomTemplateSelected = (template: MessageTemplate): void =>
        handleTemplateSelected({
            ...template,
            isPresetTemplate: false,
            group: "Custom",
        });

    const handlePresetTemplateSelected = (
        presetTemplate: PresetTemplate,
        templateGroup: string,
        productSelection: ProductSelection,
    ): void =>
        handleTemplateSelected(
            mapPresetTemplateToMessageTemplate(
                presetTemplate,
                productSelection,
                templateGroup,
            ),
        );

    const handleNoTemplateSelected = (): void =>
        handleTemplateSelected(Templates.noTemplateSelected);

    const handleTemplateSelected = (template: MessageTemplateOption): void => {
        const templateProps = AnalyticsMapper.getMessageTemplateProps(
            template,
            accountState,
            lastMessageTemplateActionLocation,
            productSelection,
        );
        FlemingAnalyticsTracker.trackTemplateSelected({
            ...templateProps,
            templateSelected: template.title,
        });
        onTemplateSelect(template);
    };

    const renderDropdownSectionTitle = (title: string): JSX.Element => {
        return (
            <DropdownItem
                style={{ color: dropdownSectionColorStyle }}
                className="font-weight-bold"
                disabled={true}
            >
                {title}
            </DropdownItem>
        );
    };

    const renderDropdownItem = (
        title: string,
        handleClick: () => void,
    ): JSX.Element => {
        return (
            <DropdownItem
                className="d-flex align-items-center"
                onClick={handleClick}
            >
                <span
                    className="d-inline-block overflow-hidden"
                    style={{ width: "100%" }}
                >
                    {title}
                </span>
            </DropdownItem>
        );
    };

    const renderPresetTemplates = (): JSX.Element[] => {
        return presetTemplatesList.map((section) => (
            <Fragment key={section.sectionHeading}>
                {renderDropdownSectionTitle(section.sectionHeading)}
                {section.templateList.map((presetTemplate) => {
                    return (
                        <Fragment
                            key={`preset-template-${presetTemplate.title}`}
                        >
                            {renderDropdownItem(presetTemplate.title, () =>
                                handlePresetTemplateSelected(
                                    presetTemplate,
                                    section.sectionHeading,
                                    productSelection,
                                ),
                            )}
                        </Fragment>
                    );
                })}
            </Fragment>
        ));
    };

    const template = useMemo(
        () =>
            getNewMessageTemplate({
                type: templateType,
                owner: MessageTemplateOwner.User,
            }),
        [templateType],
    );

    return (
        <div className="form-group font-weight-bold">
            <Text
                variant="label"
                as="label"
                props={{ className: "col-form-label" }}
            >
                Message templates
            </Text>
            {isMessageTemplatesLoading && (
                <Spinner placement="inline" dimension="small" />
            )}
            {!isMessageTemplatesLoading && (
                <div className="d-flex align-items-center">
                    <Dropdown
                        className="flex-grow-1 md-3"
                        isOpen={isDropdownOpen}
                        toggle={handleDropdownToggle}
                    >
                        <StyledDropdownToggle color="light" caret>
                            <span className="d-inline-block d-sm-none">
                                {truncateLongWord(selectedTemplate.title, 15)}
                            </span>
                            {isInFormModal && (
                                <span className="d-none d-sm-inline-block">
                                    {truncateLongWord(
                                        selectedTemplate.title,
                                        33,
                                    )}
                                </span>
                            )}
                            {!isInFormModal && (
                                <>
                                    <span className="d-none d-sm-inline-block d-md-none">
                                        {truncateLongWord(
                                            selectedTemplate.title,
                                            33,
                                        )}
                                    </span>
                                    <span className="d-none d-md-inline-block">
                                        {selectedTemplate.title}
                                    </span>
                                </>
                            )}
                        </StyledDropdownToggle>
                        <DropdownMenu
                            className="w-100"
                            style={{
                                overflowY: "auto",
                                height: dropDownHeight,
                            }}
                        >
                            {/* no template, compose your own message */}
                            {renderDropdownItem("No template", () =>
                                handleNoTemplateSelected(),
                            )}

                            {/* current user-defined default template */}
                            {userDefaultTemplate !== null && (
                                <>
                                    {renderDropdownSectionTitle(
                                        "Current default",
                                    )}
                                    {renderDropdownItem(
                                        userDefaultTemplate.title,
                                        (): void =>
                                            handleCustomTemplateSelected(
                                                userDefaultTemplate,
                                            ),
                                    )}
                                </>
                            )}

                            {/* all other user-generated (non-default) templates */}
                            {customTemplatesList.length > 0 && (
                                <>
                                    {renderDropdownSectionTitle(
                                        "Custom templates",
                                    )}
                                    {customTemplatesList.map((template) => {
                                        if (template.id === null) return null;
                                        return (
                                            <Fragment key={template.id}>
                                                {renderDropdownItem(
                                                    template.title,
                                                    () =>
                                                        handleCustomTemplateSelected(
                                                            template,
                                                        ),
                                                )}
                                            </Fragment>
                                        );
                                    })}
                                </>
                            )}

                            {productSelection !== undefined &&
                                renderPresetTemplates()}
                        </DropdownMenu>
                    </Dropdown>
                    <Button
                        theme="secondary"
                        type="button"
                        className="d-flex d-sm-none"
                        onClick={handleTemplateModalToggle}
                        text="New"
                        icon={{ name: "Document", style: "Line" }}
                    />
                    {!isVideoConsult && (
                        <Button
                            theme="secondary"
                            type="button"
                            className="d-none d-sm-flex text-nowrap ml-2"
                            onClick={handleTemplateModalToggle}
                            text="New template"
                            icon={{ name: "Document", style: "Line" }}
                        />
                    )}
                    <Modal
                        isOpen={isTemplateModalOpen}
                        toggle={handleTemplateModalToggle}
                        unmountOnClose
                        aria-labelledby={MESSAGE_TEMPLATE_FORM_TITLE_ID}
                    >
                        <ManageMessageTemplate
                            actionType={ManageTemplateActionType.Create}
                            template={template}
                            onCancelAction={handleTemplateModalToggle}
                            onSuccessAction={handleTemplateModalToggle}
                        />
                    </Modal>
                </div>
            )}
        </div>
    );
};
