import React, { useMemo } from "react";

import { FeatureName, useCurrentWorkspace } from "@accurx/auth";
import { Feedback, Icon, Link, StackPanel, Text } from "@accurx/design";
import { SharedUrls } from "@accurx/shared";
import { useLocation, useParams } from "react-router";

import {
    routeSwitchingOrg,
    routeWebSwitchingOrg,
} from "app/practices/floreyBuilder/constants/paths";
import {
    StyledCardLinkBackgroundDiv,
    StyledFauxBlockContainer,
    StyledFauxBlockOverlayAnchor,
    StyledHoveredIconWrapper,
    StyledUnhoveredIconWrapper,
} from "app/sharedComponents/clickableCard/clickableCard.styles";
import { useIsFeatureEnabledForUserOrgs } from "store/hooks";

import { StyledStackPanelListWrapper } from "./SharingPage.styles";

export const SharingPageOrgsList = (): JSX.Element => {
    const { shareId } = useParams<{
        shareId: string;
    }>();

    const { orgId } = useCurrentWorkspace();
    const currentOrgId = orgId.toString();

    const { orgsWithFeatureFlagEnabled, orgsWithFeatureFlagDisabled } =
        useIsFeatureEnabledForUserOrgs(FeatureName.FloreyBuilder);
    const otherEnabledUserOrgs = useMemo(
        () =>
            orgsWithFeatureFlagEnabled.filter(
                (org) => org.id.toString() !== currentOrgId,
            ),
        [orgsWithFeatureFlagEnabled, currentOrgId],
    );
    const location = useLocation();

    return (
        <>
            {orgsWithFeatureFlagEnabled.length === 0 && (
                <Feedback
                    colour="warning"
                    title="You can't import this questionnaire to another of your workspaces"
                >
                    Workspaces need to have the Florey Plus module to access
                    this feature.
                </Feedback>
            )}
            {orgsWithFeatureFlagEnabled.length > 0 && (
                <StackPanel gutter={2}>
                    <Text skinny variant="label">
                        Import this questionnaire to another of your workspaces
                    </Text>
                    <div>
                        <StyledStackPanelListWrapper as="ul" gutter={2}>
                            {otherEnabledUserOrgs.map((org) => (
                                <StyledFauxBlockContainer as="li" key={org.id}>
                                    {/*we're using <StyledFauxBlockOverlayAnchor> here because the refresh page behaviour is DESIRED - we're changing org so clearing the state avoids mismatch*/}
                                    <StyledFauxBlockOverlayAnchor
                                        href={
                                            location.pathname.includes(
                                                "/settings",
                                            )
                                                ? routeWebSwitchingOrg({
                                                      orgId: currentOrgId,
                                                      shareId: shareId,
                                                      switchingOrgId:
                                                          org.id.toString(),
                                                  })
                                                : routeSwitchingOrg({
                                                      orgId: currentOrgId,
                                                      shareId: shareId,
                                                      switchingOrgId:
                                                          org.id.toString(),
                                                  })
                                        }
                                    >
                                        <StackPanel
                                            orientation="horizontal"
                                            horizontalContentAlignment="space-between"
                                        >
                                            <Text skinny variant="label">
                                                {org.name}
                                            </Text>
                                            {/* TODO: refactor existing StyledFauxBlockOverlayLink approach to use CSS for SVG colour switching instead of hiding/showing SVG elements */}
                                            <StyledHoveredIconWrapper>
                                                <Icon
                                                    name="ArrowTail"
                                                    size={4}
                                                    rotation="right"
                                                    halo={{
                                                        colour: "blue",
                                                        luminosity: "low",
                                                    }}
                                                    props={{
                                                        "aria-hidden": true,
                                                        focusable: false,
                                                    }}
                                                />
                                            </StyledHoveredIconWrapper>
                                            <StyledUnhoveredIconWrapper>
                                                <Icon
                                                    name="ArrowTail"
                                                    size={4}
                                                    rotation="right"
                                                    halo={{
                                                        colour: "blue",
                                                        luminosity: "high",
                                                    }}
                                                    props={{
                                                        "aria-hidden": true,
                                                        focusable: false,
                                                    }}
                                                />
                                            </StyledUnhoveredIconWrapper>
                                        </StackPanel>
                                    </StyledFauxBlockOverlayAnchor>
                                </StyledFauxBlockContainer>
                            ))}
                        </StyledStackPanelListWrapper>
                    </div>
                </StackPanel>
            )}
            {orgsWithFeatureFlagDisabled.length > 0 && (
                <StackPanel gutter={2}>
                    <Text skinny variant="label">
                        Workspaces without Florey Plus activated
                    </Text>
                    <Text skinny>
                        To use custom questionnaires, the workspace will need to
                        purchase our Florey Plus module.{" "}
                        <Link
                            href={SharedUrls.Floreys}
                            openInNewTab={true}
                            text="Sign up for a free trial"
                        />
                    </Text>
                    <div>
                        <StyledStackPanelListWrapper as="ul" gutter={2}>
                            {orgsWithFeatureFlagDisabled.map((org) => (
                                <StyledCardLinkBackgroundDiv
                                    as="li"
                                    key={org.id}
                                >
                                    <Text skinny variant="label">
                                        {org.name}
                                    </Text>
                                    <Text skinny>
                                        This workspace does not have access to
                                        custom questionnaires.
                                    </Text>
                                </StyledCardLinkBackgroundDiv>
                            ))}
                        </StyledStackPanelListWrapper>
                    </div>
                </StackPanel>
            )}
        </>
    );
};
