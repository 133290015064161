import { FeatureName, useFeatureFlag } from "@accurx/auth";
import { TeamSummary, UserSummary } from "@accurx/concierge/types";
import { VisuallyHidden } from "@accurx/design";

import { AssigneeLabel } from "./AssigneeLabel";
import {
    StyledHeaderText,
    StyledList,
    StyledListItem,
    StyledListItemButton,
    StyledPendingUsersStickyHeader,
} from "./AssigneeListButtons.styles";
import { PendingUsersLegend } from "./AssigneeListRadioGroupForm";
import { StyledStickyHeader } from "./AssigneeSelectorForm.styles";
import { ValidAssigneeSummary } from "./AssigneeSelectorForm.types";

const AssigneeListButton = ({
    currentAssignee,
    assignee,
    assigneeType,
    onClick,
    isPending,
}: {
    currentAssignee: ValidAssigneeSummary | undefined;
    assignee: TeamSummary | UserSummary;
    assigneeType: "User" | "Team";
    onClick: (assignee: ValidAssigneeSummary) => void;
    isPending?: boolean;
}) => {
    const isCurrentAssignee =
        currentAssignee?.type === assigneeType &&
        currentAssignee.id === assignee.id;
    return (
        <StyledListItem>
            <StyledListItemButton
                data-selected={isCurrentAssignee || undefined}
                onClick={() => {
                    onClick({ type: assigneeType, id: assignee.id });
                }}
                disabled={isPending}
            >
                {isCurrentAssignee && (
                    <VisuallyHidden>
                        Currently selected assignee:{" "}
                    </VisuallyHidden>
                )}
                <AssigneeLabel
                    assignee={assignee}
                    assigneeType={assigneeType}
                    isCurrentAssignee={isCurrentAssignee}
                    isPending={isPending}
                />
            </StyledListItemButton>
        </StyledListItem>
    );
};
export const AssigneeListButtons = ({
    users,
    teams,
    unassignedTeams,
    currentAssignee,
    onAssign,
}: {
    users: UserSummary[];
    teams: TeamSummary[];
    unassignedTeams: TeamSummary[];
    currentAssignee: ValidAssigneeSummary | undefined;
    onAssign: (assignee: ValidAssigneeSummary) => void;
}) => {
    const showPendingUsers = useFeatureFlag(
        FeatureName.UnifiedInboxPendingUsers,
    );

    const approvedUsers = users.filter((u) => u.status === "Approved");
    const pendingUsers = users.filter((u) => u.status === "Unapproved");

    return (
        <StyledList>
            {unassignedTeams.map((team) => (
                <AssigneeListButton
                    key={team.id}
                    assignee={team}
                    currentAssignee={currentAssignee}
                    assigneeType="Team"
                    onClick={onAssign}
                />
            ))}
            {teams.length > 0 && (
                <>
                    <StyledStickyHeader>
                        <StyledHeaderText
                            size="small"
                            color="metal"
                            weight="bold"
                        >
                            Teams
                        </StyledHeaderText>
                    </StyledStickyHeader>

                    {teams.map((team) => (
                        <AssigneeListButton
                            key={team.id}
                            assignee={team}
                            currentAssignee={currentAssignee}
                            assigneeType="Team"
                            onClick={onAssign}
                        />
                    ))}
                </>
            )}

            {users.length > 0 && (
                <>
                    <StyledStickyHeader>
                        <StyledHeaderText
                            size="small"
                            color="metal"
                            weight="bold"
                        >
                            Colleagues
                        </StyledHeaderText>
                    </StyledStickyHeader>
                    {approvedUsers.map((user) => (
                        <AssigneeListButton
                            key={user.id}
                            assignee={user}
                            currentAssignee={currentAssignee}
                            assigneeType="User"
                            onClick={onAssign}
                        />
                    ))}
                    {showPendingUsers && pendingUsers.length > 0 && (
                        <>
                            <StyledPendingUsersStickyHeader>
                                <PendingUsersLegend />
                            </StyledPendingUsersStickyHeader>
                            {pendingUsers.map((user) => (
                                <AssigneeListButton
                                    key={user.id}
                                    assignee={user}
                                    currentAssignee={currentAssignee}
                                    assigneeType="User"
                                    isPending
                                    onClick={onAssign}
                                />
                            ))}
                        </>
                    )}
                </>
            )}
        </StyledList>
    );
};
