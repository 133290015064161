import { TeamSummary, UserSummary } from "@accurx/concierge/types";

export const formatAssigneeDisplayName = (
    assignee: UserSummary | TeamSummary | undefined,
): string => {
    if (!assignee) return "Unknown assignee";

    if ("isCurrentUser" in assignee) {
        return assignee.isCurrentUser ? "You" : assignee.displayName;
    }

    return assignee.displayName;
};
