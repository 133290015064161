import * as UI from "@accurx/design";
import { useBrowserEnvironment } from "@accurx/native";
import { useToggle } from "@accurx/navigation";
import {
    QUICK_VIEW_CONTAINER_ID,
    useQuickViewContext,
} from "@accurx/quick-view";
import {
    CentrePane,
    LeftPane,
    RightPane,
} from "domains/inbox/components/Layout/components";
// eslint-disable-next-line no-restricted-imports
import {
    GlobalArchivedWorkspaceBanner,
    useShowGlobalArchivedWorkspaceBanner,
} from "layouts/Global/GlobalArchivedWorkspaceBanner/GlobalArchivedWorkspaceBanner";
import styled from "styled-components";

import { StyledGrid, StyledQuickViewContainer } from "./Layout.styles";
import { LayoutContext } from "./context/context";

export const PATIENT_SEARCH_PANEL_ROOT_ID = "patient-search-modal-panel";

export type LayoutProps = {
    navigationMenuComponent?: React.ReactNode;
    leftPane: React.ReactNode;
    rightPane: React.ReactNode;
    centrePane: React.ReactNode;
};

const StyledLayout = styled(UI.Flex).attrs({
    flexDirection: "column",
})`
    height: 100dvh;
`;

const StyledWrapper = styled.div`
    display: flex;
    flex: 1;
    overflow: hidden;
`;

export const InboxLayout = (props: LayoutProps) => {
    const leftPane = useToggle();
    const rightPane = useToggle();
    const quickView = useQuickViewContext();

    const showGlobalArchivedWorkspaceBanner =
        useShowGlobalArchivedWorkspaceBanner();

    const env = useBrowserEnvironment();

    return (
        <StyledLayout>
            {env !== "WebView" && showGlobalArchivedWorkspaceBanner && (
                <GlobalArchivedWorkspaceBanner />
            )}
            <StyledWrapper>
                {props.navigationMenuComponent}
                <LayoutContext.Provider value={{ leftPane, rightPane }}>
                    <StyledGrid
                        $rightPaneIsOpen={rightPane.isOpen}
                        $quickViewIsOpen={quickView.isOpen}
                    >
                        <LeftPane>{props.leftPane}</LeftPane>
                        <CentrePane>{props.centrePane}</CentrePane>
                        <RightPane>{props.rightPane}</RightPane>
                        <StyledQuickViewContainer
                            id={QUICK_VIEW_CONTAINER_ID}
                        />
                    </StyledGrid>
                </LayoutContext.Provider>
                <div id={PATIENT_SEARCH_PANEL_ROOT_ID} />
            </StyledWrapper>
        </StyledLayout>
    );
};
