import { MutableRefObject, useState } from "react";

import { Ds, Text } from "@accurx/design";
import { PatientDemographics } from "@accurx/native";
import { v4 as uuid } from "uuid";

import { mapPatientDemographicsToPatientSearchResult } from "../utils";
import { PatientSearchEmr } from "./PatientSearchEmr";
import {
    StyledPanel,
    StyledPatientSearchFormOverrides,
} from "./PatientSearchPanel.styles";
import {
    PatientSearchConfirmForm,
    PatientSearchResultsForRadio,
} from "./SearchFromSidebar/PatientSearchConfirmForm";
import { SuggestedPatientCard } from "./SearchFromSidebar/SuggestedPatientCard";

export const PATIENT_SEARCH_PANEL_ID = "patient-search-panel";

export type PatientSearchPanelProps = {
    onClose: () => void;
    onSearchConfirm: (
        patient: PatientDemographics,
        shouldOpenInSystem: boolean,
    ) => void;
    medicalRecordSystemDisplay: string;
    suggestedMedicalRecordPatient: PatientDemographics | null;
    onSelectMedicalRecordPatient: (patient: PatientDemographics) => void;
    /** Pass this on to an input element to autofocus instead of the panel */
    autoFocusInputRef: MutableRefObject<HTMLInputElement | null>;
};
export const PatientSearchPanel = ({
    onClose,
    onSearchConfirm,
    medicalRecordSystemDisplay,
    suggestedMedicalRecordPatient,
    onSelectMedicalRecordPatient,
    autoFocusInputRef,
}: PatientSearchPanelProps) => {
    const [results, setResults] = useState<PatientSearchResultsForRadio | null>(
        null,
    );

    const onResultsFound = (data: PatientDemographics[]) => {
        const mappedResults = data.map((patient) => {
            const patientId = uuid();
            const mapped = mapPatientDemographicsToPatientSearchResult(patient);
            return {
                ...mapped,
                demographics: patient,
                uniqueId: patientId,
            };
        });

        setResults(mappedResults);
    };

    if (results !== null) {
        return (
            <StyledPanel.Container
                as="section"
                aria-label="Patient search panel"
            >
                <StyledPanel.Header as="header">
                    <Text variant="subtitle" as="h1" skinny>
                        Patient search results
                    </Text>
                    <Ds.Button
                        appearance="secondary"
                        size="small"
                        onClick={onClose}
                    >
                        <Ds.Button.Icon name="Cross" />
                        Close
                    </Ds.Button>
                </StyledPanel.Header>
                <PatientSearchConfirmForm
                    results={results}
                    onCancel={() => setResults(null)}
                    onSelectPatient={onSearchConfirm}
                />
            </StyledPanel.Container>
        );
    }

    return (
        <StyledPanel.Container
            as="section"
            aria-label="Patient search panel"
            id={PATIENT_SEARCH_PANEL_ID}
            tabIndex={-1}
        >
            <StyledPanel.Header as="header">
                <Text variant="subtitle" as="h1" skinny>
                    Search for a patient
                </Text>
                <Ds.Button
                    appearance="secondary"
                    size="small"
                    onClick={onClose}
                >
                    <Ds.Button.Icon name="Cross" />
                    Close
                </Ds.Button>
            </StyledPanel.Header>
            <StyledPanel.Body>
                <Ds.Flex flexDirection={"column"} gap="4">
                    <StyledPatientSearchFormOverrides>
                        <PatientSearchEmr
                            onResultsFound={onResultsFound}
                            autoFocusInputRef={autoFocusInputRef}
                        />
                    </StyledPatientSearchFormOverrides>
                    {!!suggestedMedicalRecordPatient && (
                        <Ds.Flex flexDirection={"column"} gap="1">
                            <Text skinny variant="label" as="h2">
                                Patient open in {medicalRecordSystemDisplay}
                            </Text>
                            <SuggestedPatientCard
                                patient={suggestedMedicalRecordPatient}
                                onSelectClick={onSelectMedicalRecordPatient}
                            />
                        </Ds.Flex>
                    )}
                </Ds.Flex>
            </StyledPanel.Body>
        </StyledPanel.Container>
    );
};
