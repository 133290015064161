import { ComponentProps, ReactNode } from "react";

import {
    Ds,
    Hooks,
    Tooltip,
    TooltipContent,
    TooltipPortal,
    TooltipTrigger,
} from "@accurx/design";

type NavTooltipProps = {
    align?: ComponentProps<typeof TooltipContent>["align"];
    children: ReactNode;
    text: string;
};

export const NavTooltip = ({
    align = "center",
    text,
    children,
}: NavTooltipProps) => {
    const { size } = Hooks.useViewportSize();
    const isMobile = ["xs", "sm"].includes(size);

    if (isMobile) {
        return <>{children}</>;
    }

    return (
        <Tooltip>
            <TooltipTrigger tabIndex={-1} asChild>
                {children}
            </TooltipTrigger>
            <TooltipPortal>
                <TooltipContent side="bottom" align={align}>
                    <Ds.Text weight="bold">{text}</Ds.Text>
                </TooltipContent>
            </TooltipPortal>
        </Tooltip>
    );
};
