import React, { useState } from "react";

import { Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";

import { useIsClinicianConversationOnboarding } from "../hooks";
import {
    OnboardingContainer,
    OnboardingPageWrapper,
} from "../layout-components";
import { AddOrganisationForm } from "./AddOrganisationForm";
import { RequestAddOrgSuccess } from "./RequestAddOrgSuccess";

export const AddOrganisationPage = () => {
    useAccurxWebTitle("Add your organisation");

    const isBlurryChatLayout = useIsClinicianConversationOnboarding();

    const [submitted, setSubmitted] = useState(false);

    return (
        <OnboardingPageWrapper>
            {!isBlurryChatLayout && (
                <Text variant="title" as="h1" skinny>
                    Join a different organisation
                </Text>
            )}
            <OnboardingContainer>
                {submitted ? (
                    <RequestAddOrgSuccess />
                ) : (
                    <AddOrganisationForm
                        onAddSuccess={() => setSubmitted(true)}
                    />
                )}
            </OnboardingContainer>
        </OnboardingPageWrapper>
    );
};
