import { Ds, Feedback } from "@accurx/design";
import { copyToClipboard } from "domains/inbox/util/copyToClipboard";
import { toast } from "react-toastify";

type CopyToClipboardButtonProps = {
    getText: () => string;
};

export const CopyToClipboardButton = ({
    getText,
}: CopyToClipboardButtonProps) => {
    const onClickCopy = async () => {
        const { success } = await copyToClipboard(getText());
        if (success) {
            toast(
                <Feedback title="Copied to the clipboard" colour="success" />,
            );
        } else {
            toast(
                <Feedback
                    title="Couldn't copy to the clipboard"
                    colour="error"
                />,
            );
        }
    };

    return (
        <Ds.Button
            appearance="secondary"
            size="small"
            onClick={() => {
                void (async () => {
                    await onClickCopy();
                })();
            }}
            data-testid="copy-button"
        >
            <Ds.Button.Icon name="Copy" />
            Get a copy
        </Ds.Button>
    );
};
