import { Switch } from "react-router-dom";

import { SimpleRoute } from "app/AppRoute";
import { AccumailEditTemplatePage } from "app/practices/accumail/editTemplate/EditTemplatePage";
import { ROUTES_WORKSPACE } from "shared/Routes";

import { AccumailTemplatesPage } from "./AccumailTemplatesPage";

export const AccumailTemplatesRoutesSection = () => {
    return (
        <Switch>
            <SimpleRoute path={ROUTES_WORKSPACE.accumailTemplatesManage} exact>
                <AccumailTemplatesPage />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accumailTemplatesCreate} exact>
                <AccumailEditTemplatePage saveMode="create" noHeader />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accumailTemplatesEdit} exact>
                <AccumailEditTemplatePage saveMode="edit" noHeader />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accumailTemplatesCopy} exact>
                <AccumailEditTemplatePage saveMode="copy" noHeader />
            </SimpleRoute>
        </Switch>
    );
};
