import { useEffect } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import {
    SignalRSendEvent,
    useRealtimeForSignalR,
    useSendSignalREvent,
} from "@accurx/realtime";

export const useSendUserOnline = (): void => {
    const { orgId: workspaceId } = useCurrentWorkspace();
    const realtime = useRealtimeForSignalR();
    const sendUserIsOnline = useSendSignalREvent(
        SignalRSendEvent.OnConnectedInfo,
    );

    /**
     * For other clients to know its own online status,
     * it needs to send this event to the server when it first connects.
     *
     * The desktop toolbar already sends this event once its loaded but
     * the responses from other users will be sent back to the toolbar
     * instead of this client. This means that when embeded in Webview,
     * we technically need to send this event twice (which is OK).
     */
    useEffect(() => {
        sendUserIsOnline({
            organisationId: workspaceId,
            machineIdentifier: null,
        });
    }, [sendUserIsOnline, realtime.connectionState, workspaceId]);
};
