import { PatientThreadContentType } from "@accurx/api/patient-messaging";
import { ContentTypesWithSaveToRecordSupport } from "@accurx/native";
import { Log } from "@accurx/shared";

export type SentItemContentType = Extract<
    ContentTypesWithSaveToRecordSupport,
    "PatientSms" | "PatientEmail" | "NhsAppMessage"
>;

export const mapSentItemTypeToContentType = ({
    type,
    id,
}: {
    type: PatientThreadContentType;
    id?: string | null;
}): SentItemContentType | null => {
    switch (type) {
        case PatientThreadContentType.NhsAppMessage:
            return "NhsAppMessage";
        case PatientThreadContentType.SMS:
            return "PatientSms";
        case PatientThreadContentType.PatientEmail:
            return "PatientEmail";
        default:
            Log.error(
                "Cannot map message to a valid ContentType to be saved to record",
                {
                    tags: {
                        itemType: type,
                        itemId: id,
                        product: "Compose",
                    },
                },
            );
            return null;
    }
};
