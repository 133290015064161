import {
    AppointmentChangeDeadline,
    AppointmentChangeSupport,
    AppointmentSupportedChanges,
} from "@accurx/api/appointment";

import { isSupported } from "./isSupported";

const appointmentChangeDeadlineTextMapping: Record<
    AppointmentChangeDeadline,
    string
> = {
    OneHour: "1 hour",
    OneWorkingDay: "1 working day",
    ThreeWorkingDays: "3 working days",
};

export const getSupportedAmendMessage = (
    supportedChanges: AppointmentSupportedChanges,
    changeDeadline: AppointmentChangeDeadline,
) => {
    if (
        supportedChanges.cancellation ===
            AppointmentChangeSupport.NotSupported &&
        supportedChanges.rebooking === AppointmentChangeSupport.NotSupported
    ) {
        return "";
    }
    const amendDeadline = appointmentChangeDeadlineTextMapping[changeDeadline];
    if (
        isSupported(supportedChanges.cancellation) &&
        supportedChanges.rebooking === AppointmentChangeSupport.NotSupported
    ) {
        return `Patients will be able to cancel the appointment up to ${amendDeadline} before the appointment is due.`;
    }

    if (
        isSupported(supportedChanges.rebooking) &&
        supportedChanges.cancellation === AppointmentChangeSupport.NotSupported
    ) {
        return `Patients will be able to rebook the appointment up to ${amendDeadline} before the appointment is due.`;
    }

    return `Patients will be able to amend the appointment up to ${amendDeadline} before the appointment is due.`;
};
