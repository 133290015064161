import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { Ds } from "@accurx/design";
import { DirectoryModal } from "@accurx/directory";
import { NavTooltip } from "@accurx/navigation";
import { Slot } from "@radix-ui/react-slot";
import { StyledTriggerButton } from "layouts/UnifiedLayout/UnifiedLayout.styles";
import { userflowIds } from "layouts/constants";
import { useHistory } from "react-router-dom";

export const DirectoryButton = ({
    children,
}: {
    children?: React.ReactNode;
}) => {
    const history = useHistory();
    const track = useAnalytics();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleDirectoryOpen = () => {
        setIsModalOpen(true);
        track("NavigationMenu Button Click", {
            origin: history.location.pathname,
            navigationOptionSelected: "Directory",
            navigationVersion: "Unified",
            eventVersion: 2,
        });
    };

    const handleDirectoryClose = () => setIsModalOpen(false);

    return (
        <>
            <NavTooltip text="Directory">
                <Slot onClick={handleDirectoryOpen}>
                    {children || (
                        <StyledTriggerButton
                            data-userflow-id={
                                userflowIds.navigation.primary.directory
                            }
                            aria-label="Directory"
                        >
                            <Ds.Icon
                                name="preview-Directory"
                                appearance="outline"
                                size="small"
                                color="white"
                            />
                        </StyledTriggerButton>
                    )}
                </Slot>
            </NavTooltip>

            {isModalOpen && (
                <DirectoryModal closeModal={handleDirectoryClose} />
            )}
        </>
    );
};
