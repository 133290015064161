import * as UI from "@accurx/design";

import { CommunicationConsent } from "./ConsentAggregate/types";
import { ConsentAggregateIcon } from "./ConsentAggregateIcon";
import { ConsentCodeDisplay } from "./ConsentCodeDisplay";

type CommunicationConsentDisplayProps = {
    title: string;
    communicationConsent: CommunicationConsent;
};

export const CommunicationConsentDisplay = ({
    title,
    communicationConsent,
}: CommunicationConsentDisplayProps) => {
    const { codes, consentAggregate } = communicationConsent;

    if (consentAggregate === "Ambiguous") {
        return null;
    }

    return (
        <UI.Ds.Flex flexDirection={"column"} gap="0.5">
            <UI.Ds.Flex gap="1">
                <div>
                    <ConsentAggregateIcon consentAggregate={consentAggregate} />
                </div>
                <UI.Ds.Text weight="bold" color="metal" size="small">
                    {title}
                </UI.Ds.Text>
            </UI.Ds.Flex>
            {consentAggregate === "Empty" && (
                <UI.Ds.Text size="small">No recorded objection</UI.Ds.Text>
            )}
            {codes.length > 0 && (
                <UI.Ds.Flex flexDirection={"column"} gap="0.25">
                    {codes.map((consentCode, i) => (
                        <ConsentCodeDisplay
                            consentCode={consentCode}
                            key={`${i}-${consentCode.term}`}
                        />
                    ))}
                </UI.Ds.Flex>
            )}
        </UI.Ds.Flex>
    );
};
