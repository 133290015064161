import { Ds } from "@accurx/design";

export type SearchButtonProps = {
    buttonProps?: Omit<Ds.ButtonProps, "children">;
};

export const SearchButton = ({ buttonProps }: SearchButtonProps) => (
    <Ds.Button
        {...buttonProps}
        type="submit"
        size="small"
        loadingMessage="Search"
    >
        <Ds.Button.Icon name="Search" />
        Search
    </Ds.Button>
);
