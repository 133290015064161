import { ComponentProps, useState } from "react";

import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";

/**
 * TODO: Remove import from ILLEGAL_IMPORTS_DO_NOT_USE
 */
import { DropdownItem } from "domains/message-component/ILLEGAL_IMPORTS_DO_NOT_USE";

export type TemplatesFilter = "All" | "Templates" | "Questionnaires";

type TemplateFilterSelectorProps = {
    value: TemplatesFilter;
    onChange?: (value: TemplatesFilter) => void;
    onClick?: ComponentProps<typeof Pill.Dropdown>["onClick"];
};

const getPillLabel = (value: TemplatesFilter): string => {
    switch (value) {
        case "All":
            return "Filter";
        case "Questionnaires":
            return "Questionnaires";
        case "Templates":
            return "Templates";
    }
};

const getPillIcon = (value: TemplatesFilter): UI.IconNameVariants => {
    switch (value) {
        case "All":
            return "Filter";
        case "Questionnaires":
            return "Questionnaire";
        case "Templates":
            return "MessageWrite";
    }
};

export const TemplateFilterSelector = ({
    value,
    onChange,
    onClick,
}: TemplateFilterSelectorProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleChange = (value: TemplatesFilter) => {
        onChange?.(value);
        setIsOpen(false);
    };

    return (
        <UI.Popover open={isOpen} onOpenChange={() => setIsOpen((s) => !s)}>
            <UI.PopoverTrigger asChild={true}>
                <Pill.Dropdown
                    radius="full"
                    aria-label={"Filter templates and questionnaires"}
                    onClick={onClick}
                >
                    <Pill.Icon
                        name={getPillIcon(value)}
                        colour="blue"
                        theme="Line"
                        props={{
                            "aria-hidden": true,
                        }}
                    />
                    <Pill.Text>{getPillLabel(value)}</Pill.Text>
                </Pill.Dropdown>
            </UI.PopoverTrigger>
            <UI.PopoverContent align="start">
                <DropdownItem.ListItem>
                    <DropdownItem.TitleBar $mb={1}>
                        <UI.Text variant="label">Filter</UI.Text>
                    </DropdownItem.TitleBar>
                </DropdownItem.ListItem>
                <DropdownItem.ListItem>
                    <DropdownItem.Button
                        selected={value === "All"}
                        onClick={() => handleChange("All")}
                    >
                        <DropdownItem.Icon name="Filter" />
                        <DropdownItem.Text>Show all</DropdownItem.Text>
                    </DropdownItem.Button>
                </DropdownItem.ListItem>

                <DropdownItem.ListItem>
                    <DropdownItem.Divider fullWidth />
                </DropdownItem.ListItem>

                <DropdownItem.ListItem $mb={1}>
                    <DropdownItem.Button
                        selected={value === "Templates"}
                        onClick={() => handleChange("Templates")}
                    >
                        <DropdownItem.Icon name="MessageWrite" />
                        <DropdownItem.Text>Templates</DropdownItem.Text>
                    </DropdownItem.Button>
                </DropdownItem.ListItem>
                <DropdownItem.ListItem>
                    <DropdownItem.Button
                        selected={value === "Questionnaires"}
                        onClick={() => handleChange("Questionnaires")}
                    >
                        <DropdownItem.Icon name="Questionnaire" />
                        <DropdownItem.Text>Questionnaires</DropdownItem.Text>
                    </DropdownItem.Button>
                </DropdownItem.ListItem>
            </UI.PopoverContent>
        </UI.Popover>
    );
};
