import { addLabelToTicket } from "domains/concierge/internal/api/ticket/mutations/addLabelToTicket";
import {
    useConciergeDispatch,
    useConciergeMeta,
} from "domains/concierge/internal/context";
import { actions } from "domains/concierge/internal/store";
import {
    UseMutationOptions,
    useMutation,
} from "domains/concierge/internal/util/useMutation";
import { Conversation } from "domains/concierge/types";

import { useForceConversationRefreshQuery } from "../queries/useConversationRefreshQuery";

type Variables = {
    latestToken: Conversation["latestToken"];
    conversationSource: Conversation["source"];
    labelCode: string;
    otherDescription?: string;
};

export const useAddLabelToTicketMutation = (
    options?: UseMutationOptions<Variables>,
) => {
    const dispatch = useConciergeDispatch();
    const { workspaceId, userId } = useConciergeMeta();
    const forceConversationRefresh = useForceConversationRefreshQuery();

    return useMutation(
        "AddLabelToTicket",
        async ({
            latestToken,
            labelCode,
            otherDescription,
            conversationSource,
        }: Variables) => {
            if (conversationSource.system !== "Ticket") {
                throw new Error(
                    `AddLabelToTicketMutation does not support ${conversationSource.system} conversations`,
                );
            }

            const { updates } = await addLabelToTicket({
                workspaceId,
                ticketIdentity: conversationSource.ticketIdentity,
                latestToken,
                labelCode,
                userId,
                otherDescription,
            });

            dispatch(
                actions.processUpdates({
                    source: "Mutation:AddLabelToTicket",
                    ...updates,
                }),
            );
        },
        options,
        {
            refreshOnError: true,
            refreshOnSignalRNotConnected: true,
            refreshFn: (vars) =>
                forceConversationRefresh({
                    conversationSource: vars.conversationSource,
                }),
        },
    );
};
