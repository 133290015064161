import * as UI from "@accurx/design";
import { SkeletonLoader } from "domains/message-component/ILLEGAL_IMPORTS_DO_NOT_USE";
import { useCompose } from "domains/message-component/context";
import { PatientExternalId } from "domains/message-component/types";

import { CommunicationConsentDisplay } from "./CommunicationConsentDisplay";
import { useCommunicationConsent } from "./ConsentAggregate/useCommunicationConsent";
import { StyledCollapsibleInfoContainer } from "./PatientCommunicationConsentSummary.styles";

export const PatientCommunicationConsentSummary = ({
    patientExternalIds,
}: {
    patientExternalIds: PatientExternalId[];
}) => {
    const { shouldTryToFetchConsent } = useCompose();
    const communicationConsentQuery = useCommunicationConsent({
        patientExternalIds,
    });

    if (
        !shouldTryToFetchConsent ||
        communicationConsentQuery === null ||
        communicationConsentQuery.status === "error"
    ) {
        return null;
    }

    if (communicationConsentQuery.status === "loading") {
        return (
            <StyledCollapsibleInfoContainer>
                <UI.CollapsibleInfo title="Consent details">
                    <UI.Ds.Flex flexDirection={"column"} gap="1">
                        <SkeletonLoader width="172px" height="12px" />
                        <SkeletonLoader width="272px" height="12px" />
                    </UI.Ds.Flex>
                </UI.CollapsibleInfo>
            </StyledCollapsibleInfoContainer>
        );
    }

    return (
        <StyledCollapsibleInfoContainer>
            <UI.CollapsibleInfo title="Consent details">
                <UI.Ds.Flex flexDirection={"column"} gap="2">
                    <CommunicationConsentDisplay
                        title="Text message communications"
                        communicationConsent={
                            communicationConsentQuery.data.smsConsent
                        }
                    />
                    <CommunicationConsentDisplay
                        title="Email communications"
                        communicationConsent={
                            communicationConsentQuery.data.emailConsent
                        }
                    />
                </UI.Ds.Flex>
            </UI.CollapsibleInfo>
        </StyledCollapsibleInfoContainer>
    );
};
