import { KeyboardEvent, useState } from "react";

import { PatientListType } from "@accurx/api/patient-messaging";
import { Organisation } from "@accurx/auth";
import { Button, Feedback } from "@accurx/design";
import { isArchivedWorkspace } from "@accurx/workspace-management";
import { useQueryClient } from "@tanstack/react-query";
import { shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";

import FlemingApi from "api/FlemingApiClient";
import {
    PatientListAppointment,
    PatientListSummary as Summary,
} from "api/FlemingDtos";
import { FlemingAnalyticsTracker } from "app/analytics";
import { PATIENT_LIST_SUMMARY_KEY } from "app/hooks/queries/usePatientListSummariesQuery";
import PatientListSummaryCard from "app/patientLists/PatientListSummaryCard";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { useIsTwoFactorAuthenticated } from "app/twoFactorAuth/TwoFactorReducer";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { getSinglePatientListRoute } from "shared/RoutesHelper";
import { useAppSelector } from "store/hooks";

import { ConfirmDeleteAppointments } from "./ConfirmDelete/ConfirmDeleteAppointmentsModal";
import { getPatientListEntries } from "./PatientList.helper";
import { actionCreators } from "./PatientListsActions";

const {
    PatientListActionOrigin: {
        PatientListSummary: AnalyticsPatientListSummary,
    },
} = FlemingAnalyticsTracker;

const isScheduled = (appointment: PatientListAppointment): boolean => {
    return appointment.dateTimeStart !== null;
};

const hasVideoConsult = (appointment: PatientListAppointment): boolean => {
    return (
        appointment.hasSentVideoConsultInvite === true &&
        appointment.videoConsultUrl !== ""
    );
};

export interface PatientListSummaryProps {
    summary: Summary;
}

type PatientListDetailsRequiredForDeleting = {
    name: string;
    isCurrentUserListOwner: boolean;
    dates: string[];
    scheduledVideoConsults: PatientListAppointment[];
    listType: PatientListType;
};

const PatientListSummary = ({
    summary,
}: PatientListSummaryProps): JSX.Element => {
    const history = useHistory();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const is2FAed = useIsTwoFactorAuthenticated();
    const shouldActionButtonsBeDisabled = useAppSelector(
        ({ patientLists }) => patientLists.deletingListLoading,
    );
    const organisationId = useAppSelector(
        ({ account }) => account.selectedOrganisation,
    );

    const organisation = useAppSelector(
        ({ account }) => OrganisationHelper.getOrganisation(account),
        shallowEqual,
    );

    const isArchived = organisation
        ? isArchivedWorkspace(organisation as Organisation)
        : false;

    const [isMoreOpen, setIsMoreOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const [patientListDetails, setPatientListDetails] =
        useState<PatientListDetailsRequiredForDeleting | null>(null);

    const handleViewListClick = (): void => {
        FlemingAnalyticsTracker.trackPatientListView({
            ...analyticsLoggedInProps,
            listSize: summary.patientCount,
        });

        dispatch(
            actionCreators.setPatientListSearchedAction(summary.patientListId),
        );

        history.push(
            getSinglePatientListRoute(summary.patientListId.toString()),
        );
    };

    const handleDeleteClick = async (): Promise<void> => {
        if (isArchived) {
            return;
        }
        // fetch the list
        const { success, error, result } = await FlemingApi.getUserPatientList({
            organisationId,
            patientListId: summary.patientListId,
        });

        if (success === true && error === null && result !== null) {
            // get all the appt day dates from it
            const selectedListDates = result.appointmentDays.map(
                (day) => day.date,
            );
            // get all the scheduled video consults from it
            const selectedListScheduledVideoConsults = getPatientListEntries(
                result.appointmentDays,
            ).filter((appt) => isScheduled(appt) && hasVideoConsult(appt));

            setPatientListDetails({
                name: result.name,
                isCurrentUserListOwner: result.isCurrentUserListOwner,
                dates: selectedListDates,
                scheduledVideoConsults: selectedListScheduledVideoConsults,
                listType: result.listType,
            });

            handleToggleDeleteModal();
        } else {
            toast(
                Feedback({
                    colour: "error",
                    title: "Sorry, something went wrong",
                    content:
                        "We were unable to delete this list. Please refresh the page and try again.",
                }),
            );
        }

        if (isMoreOpen) {
            handleToggleMore();
        }
    };

    const handleConfirmDeleteList = () => {
        FlemingAnalyticsTracker.trackPatientListDeleteButtonClick({
            ...analyticsLoggedInProps,
            origin: history.location.pathname,
            pageOrigin:
                FlemingAnalyticsTracker.PatientListActionOrigin
                    .PatientListSummary,
        });

        dispatch(
            actionCreators.deletePatientList(
                {
                    organisationId,
                    patientListId: summary.patientListId,
                },
                AnalyticsPatientListSummary,
                () => {
                    queryClient.invalidateQueries([
                        PATIENT_LIST_SUMMARY_KEY,
                        organisationId,
                    ]);
                },
            ),
        );
    };

    const handleCancelDeleteList = () => {
        setIsDeleteModalOpen(false);
    };

    const handleToggleDeleteModal = (): void => {
        setIsDeleteModalOpen((prevState) => !prevState);
    };
    const handleDeleteKeyUp = (e: KeyboardEvent<HTMLButtonElement>): void => {
        if (e.key === "Enter") {
            handleDeleteClick();
        }
    };
    const handleToggleMore = (): void => {
        setIsMoreOpen((prevState) => !prevState);
    };
    const handleMoreKeyUp = (e: KeyboardEvent<HTMLButtonElement>): void => {
        if (e.key === "Enter") {
            handleToggleMore();
        }
    };

    const showMoreButton =
        is2FAed &&
        !summary.isReadonly &&
        // If workspace list, only show dropdown for list owners
        ((summary.listType === PatientListType.WorkspaceManaged &&
            summary.isCurrentUserListOwner) ||
            // If user list, always show dropdown with delete vs remove for list owners
            summary.listType === PatientListType.UserManaged);

    const userCanDelete =
        summary.isCurrentUserListOwner ||
        summary.listType === PatientListType.WorkspaceManaged;

    const actionButtons = (
        <>
            {showMoreButton && (
                <>
                    {patientListDetails && isDeleteModalOpen && (
                        <ConfirmDeleteAppointments
                            listDetails={{
                                listName: patientListDetails.name,
                                isCurrentUserListOwner:
                                    patientListDetails.isCurrentUserListOwner,
                                listType: patientListDetails.listType,
                            }}
                            affectedDates={patientListDetails.dates}
                            appointmentsWithVideoConsultsToDelete={
                                patientListDetails.scheduledVideoConsults
                            }
                            handleConfirm={handleConfirmDeleteList}
                            handleCancel={handleCancelDeleteList}
                        />
                    )}
                    {!isArchived && (
                        <Dropdown
                            isOpen={isMoreOpen}
                            toggle={handleToggleMore}
                            data-testid="patientlist-summary-more"
                        >
                            <DropdownToggle
                                tag="div"
                                data-toggle="dropdown"
                                aria-expanded={isMoreOpen}
                            >
                                <Button
                                    aria-label="Patient list options"
                                    theme="secondary"
                                    icon={{
                                        name: "More",
                                        style: "Fill",
                                        title: "Patient list options",
                                        id: "toggle-dropdown-btn",
                                    }}
                                    disabled={shouldActionButtonsBeDisabled}
                                    onKeyUp={handleMoreKeyUp}
                                />
                            </DropdownToggle>
                            <DropdownMenu right={true}>
                                <Button
                                    theme="transparent"
                                    icon={{ name: "Bin", colour: "red" }}
                                    text={
                                        userCanDelete
                                            ? "Delete list"
                                            : "Remove me from list"
                                    }
                                    disabled={shouldActionButtonsBeDisabled}
                                    className="text-nowrap w-100"
                                    onClick={handleDeleteClick}
                                    onKeyUp={handleDeleteKeyUp}
                                />
                            </DropdownMenu>
                        </Dropdown>
                    )}
                </>
            )}
            <Button
                theme="secondary"
                text="View list"
                icon={{ name: "List", colour: "blue" }}
                onClick={handleViewListClick}
                className="text-nowrap"
                disabled={shouldActionButtonsBeDisabled}
            />
        </>
    );

    return (
        <PatientListSummaryCard
            summary={summary}
            actionButtons={actionButtons}
        />
    );
};

export default PatientListSummary;
