import { FeatureName, useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";

export const useAvailableFilters = () => {
    const { latestIntegratedSystem } = useCurrentWorkspace();

    const isUnifiedNavigationEnabled = useFeatureFlag(
        FeatureName.UnifiedNavigation,
    );

    return latestIntegratedSystem && isUnifiedNavigationEnabled
        ? "site-name-slot-type"
        : "clinic-appointment-type";
};
