import { FeatureName } from "@accurx/auth";
import { Ds } from "@accurx/design";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

import { CardErrorRefType } from "app/sharedComponents/saveableCard/SaveableCard";

import { ClientSideQuestion } from "../../types/context.types";
import { CardsByKey, QuestionLevel } from "../../types/data.types";
import { HandleCancelMeasurementTypeSelectorCard } from "../MeasurementTypeSelector.types";
import { Question, QuestionPropsFromHook } from "../Question/Question";
import {
    HandleCloseQuestionSelectCard,
    HandleSaveMeasurmentType,
    HandleSelectQuestionType,
} from "../QuestionTypeSelector.types";
import { Selectors } from "../Selectors/Selectors";

type QuestionsProps = QuestionPropsFromHook & {
    cardsByKey: CardsByKey;
    questions: ClientSideQuestion[];
    handleSelectQuestionType: ({
        branchId,
        branchPosition,
        questionType,
        questionLevel,
    }: HandleSelectQuestionType) => void;
    handleCloseQuestionSelectCard: ({
        branchPosition,
        questionLevel,
    }: HandleCloseQuestionSelectCard) => void;
    handleCancelMeasurementTypeSelectorCard: ({
        questionLevel,
    }: HandleCancelMeasurementTypeSelectorCard) => void;
    handleSaveMeasurementTypeSelectorCard: ({
        branchId,
        branchPosition,
        event,
        questionLevel,
    }: HandleSaveMeasurmentType) => Promise<boolean>;
    handleCancelPhotoUploadSelectorCard: ({
        questionLevel,
    }: HandleCancelMeasurementTypeSelectorCard) => void;
    handleSavePhotoUploadSelectorCard: ({
        branchId,
        branchPosition,
        questionLevel,
    }: Omit<HandleSaveMeasurmentType, "event">) => Promise<boolean>;
    activeCardErrorRef: CardErrorRefType;
    questionLevel: QuestionLevel;
    cardsCanBeReordered: boolean;
};
export const Questions = ({
    activeCardErrorRef,
    handleCancelMeasurementTypeSelectorCard,
    handleCancelPhotoUploadSelectorCard,
    handleCancelQuestion,
    handleCloseQuestionSelectCard,
    handleQuestionEditChanges,
    handleRemoveQuestionModal,
    handleSaveMeasurementTypeSelectorCard,
    handleSavePhotoUploadSelectorCard,
    handleSaveQuestion,
    handleSelectQuestionType,
    questionnaireErrors,
    questionnaireId,
    questions,
    validationErrorsByKey,
    cardsByKey,
    questionLevel,
    branchId,
    branchPosition,
    cardsCanBeReordered,
}: QuestionsProps): JSX.Element | null => {
    const isGlobalCustomFloreyEnabled = useFeatureFlag(
        FeatureName.CreateGlobalCustomFloreys,
    );
    const isBranch = questionLevel === "Branch";
    const disableReordering =
        !cardsCanBeReordered || isBranch || !isGlobalCustomFloreyEnabled;

    const droppableKey = isBranch
        ? "branch-questions-dropzone"
        : "questionnaire-questions-dropzone";
    return (
        <>
            <Droppable
                droppableId={droppableKey}
                isDropDisabled={disableReordering}
                key={droppableKey}
            >
                {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        <Ds.Flex gap={"2"} flexDirection={"column"}>
                            {questions.map((question, questionIndex) => {
                                return (
                                    <Draggable
                                        key={`${question.clientId}`}
                                        draggableId={question.clientId}
                                        index={questionIndex}
                                        isDragDisabled={disableReordering}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                id={`${question.clientId}-draggable`}
                                            >
                                                <Question
                                                    branchId={branchId}
                                                    branchPosition={
                                                        branchPosition
                                                    }
                                                    cardsByKey={cardsByKey}
                                                    activeCardErrorRef={
                                                        activeCardErrorRef
                                                    }
                                                    handleCancelMeasurementTypeSelectorCard={
                                                        handleCancelMeasurementTypeSelectorCard
                                                    }
                                                    handleCancelPhotoUploadSelectorCard={
                                                        handleCancelPhotoUploadSelectorCard
                                                    }
                                                    handleCancelQuestion={
                                                        handleCancelQuestion
                                                    }
                                                    handleCloseQuestionSelectCard={
                                                        handleCloseQuestionSelectCard
                                                    }
                                                    handleQuestionEditChanges={
                                                        handleQuestionEditChanges
                                                    }
                                                    handleRemoveQuestionModal={
                                                        handleRemoveQuestionModal
                                                    }
                                                    handleSaveMeasurementTypeSelectorCard={
                                                        handleSaveMeasurementTypeSelectorCard
                                                    }
                                                    handleSavePhotoUploadSelectorCard={
                                                        handleSavePhotoUploadSelectorCard
                                                    }
                                                    handleSaveQuestion={
                                                        handleSaveQuestion
                                                    }
                                                    handleSelectQuestionType={
                                                        handleSelectQuestionType
                                                    }
                                                    question={question}
                                                    questionIndex={
                                                        questionIndex
                                                    }
                                                    questionLevel={
                                                        questionLevel
                                                    }
                                                    questionnaireErrors={
                                                        questionnaireErrors
                                                    }
                                                    questionnaireId={
                                                        questionnaireId
                                                    }
                                                    validationErrorsByKey={
                                                        validationErrorsByKey
                                                    }
                                                    key={questionIndex}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                        </Ds.Flex>
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            <Selectors
                activeCardErrorRef={activeCardErrorRef}
                cardsByKey={cardsByKey}
                handleCancelMeasurementTypeSelectorCard={
                    handleCancelMeasurementTypeSelectorCard
                }
                handleCancelPhotoUploadSelectorCard={
                    handleCancelPhotoUploadSelectorCard
                }
                handleCloseQuestionSelectCard={handleCloseQuestionSelectCard}
                handleSaveMeasurementTypeSelectorCard={
                    handleSaveMeasurementTypeSelectorCard
                }
                handleSavePhotoUploadSelectorCard={
                    handleSavePhotoUploadSelectorCard
                }
                handleSelectQuestionType={handleSelectQuestionType}
                questionLevel={questionLevel}
                questionnaireErrors={questionnaireErrors}
                questionnaireId={questionnaireId}
                questions={questions}
            />
        </>
    );
};
