import { useState } from "react";

import { FeatureName, useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import { useIsGpBatchEnabled } from "@accurx/batch-messaging";
import { Ds, Popover, PopoverTrigger, Text } from "@accurx/design";
import { AppOrigin } from "@accurx/message-component";
import {
    NavTooltip,
    PopoverItemLink,
    StyledPopoverContent,
    StyledPopoverItem,
} from "@accurx/navigation";
import { ArchivedWorkspaceHiddenItemWrapper } from "@accurx/workspace-management";
import { StyledMessageButton } from "layouts/UnifiedLayout/UnifiedLayout.styles";
import { userflowIds } from "layouts/constants";
import { generatePath, useHistory } from "react-router-dom";
import { useAnalytics } from "reduxQuarantine/useAnalytics";

import { ROUTES_WORKSPACE } from "shared/Routes";

type NewMessageDropdownProps = {
    openVideoConsultModal: () => void;
    hideText?: boolean;
};

export const NewMessageDropdown = ({
    openVideoConsultModal,
    hideText,
}: NewMessageDropdownProps) => {
    const { orgId: workspaceId, latestIntegratedSystem } =
        useCurrentWorkspace();
    const history = useHistory();
    const track = useAnalytics();

    const canMessageCareProviders = useFeatureFlag(
        FeatureName.CaregiverInitiated,
    );
    const isTrustBatchEnabled = useFeatureFlag(FeatureName.WebBatchMessaging);
    const isGpBatchEnabled = useIsGpBatchEnabled();

    const showBatchButton = latestIntegratedSystem
        ? isGpBatchEnabled
        : isTrustBatchEnabled;

    const isVideoConsultEnabled = useFeatureFlag(FeatureName.VideoConsultWeb);
    const [popoverOpen, setPopoverOpen] = useState(false);

    const batchPath = generatePath(
        latestIntegratedSystem
            ? ROUTES_WORKSPACE.batchMessageGpChoose
            : ROUTES_WORKSPACE.batchMessageTrustUpload,
        { workspaceId },
    );

    const handlePopoverOpenChange = (open: boolean) => {
        setPopoverOpen(open);
        if (open) {
            track("NavigationMenu Button Click", {
                origin: history.location.pathname,
                navigationOptionSelected: "NewMessage",
                navigationVersion: "Unified",
                eventVersion: 2,
            });
        }
    };

    const handleVideoConsultOpen = () => {
        handleMenuItemClick("VideoInvite");

        openVideoConsultModal();
    };

    const handleMenuItemClick = (
        optionSelected:
            | "MessagePatient"
            | "MessagePractice"
            | "VideoInvite"
            | "BatchMessage",
    ) => {
        track("NavigationMessage MenuItem Click", {
            navigationVersion: "Unified",
            origin: history.location.pathname,
            optionSelected,
        });
        setPopoverOpen(false);
    };

    const composeAppOrigin = AppOrigin.NavigationNewMessageDropdown;

    return (
        <Popover open={popoverOpen} onOpenChange={handlePopoverOpenChange}>
            <NavTooltip align="start" text="New message options">
                <PopoverTrigger asChild>
                    <StyledMessageButton
                        icon={{
                            name: "Plus",
                            title: "New message options",
                            colour: "green",
                        }}
                        text={hideText ? undefined : "New"}
                        data-userflow-id={
                            userflowIds.navigation.primary.newMessage
                        }
                    />
                </PopoverTrigger>
            </NavTooltip>
            <StyledPopoverContent side="bottom" align="start">
                <PopoverItemLink
                    to={{
                        pathname: `/w/${workspaceId}/compose/search`,
                        state: {
                            originLocation: history.location,
                            appOrigin: composeAppOrigin,
                        },
                    }}
                    onClick={() => handleMenuItemClick("MessagePatient")}
                    data-userflow-id={
                        userflowIds.navigation.primary.newMessagePatient
                    }
                >
                    <Ds.Icon
                        name="preview-Message"
                        size="small"
                        color="stone"
                    />
                    <Text skinny variant="label">
                        Message patient
                    </Text>
                </PopoverItemLink>
                <ArchivedWorkspaceHiddenItemWrapper>
                    {canMessageCareProviders && (
                        <PopoverItemLink
                            to={{
                                pathname: `/w/${workspaceId}/compose/search`,
                                search: "?composeVariant=Clinician",
                                state: {
                                    originLocation: history.location,
                                    appOrigin: composeAppOrigin,
                                },
                            }}
                            onClick={() =>
                                handleMenuItemClick("MessagePractice")
                            }
                            data-userflow-id={
                                userflowIds.navigation.primary.newMessageGp
                            }
                        >
                            <Ds.Icon
                                name="preview-MessageHealthProfessional"
                                size="small"
                                color="stone"
                            />
                            <Text skinny variant="label">
                                Message GP
                            </Text>
                        </PopoverItemLink>
                    )}
                </ArchivedWorkspaceHiddenItemWrapper>
                {isVideoConsultEnabled && (
                    <StyledPopoverItem
                        onClick={handleVideoConsultOpen}
                        data-userflow-id={
                            userflowIds.navigation.primary
                                .newMessageVideoConsult
                        }
                    >
                        <Ds.Icon name="Video" size="small" color="stone" />
                        <Ds.Text weight="bold">Video consult</Ds.Text>
                    </StyledPopoverItem>
                )}
                {showBatchButton && (
                    <ArchivedWorkspaceHiddenItemWrapper>
                        <PopoverItemLink
                            to={{
                                pathname: batchPath,
                                state: { from: history.location },
                            }}
                            onClick={() => handleMenuItemClick("BatchMessage")}
                            data-userflow-id={
                                userflowIds.navigation.primary.newMessageBatch
                            }
                        >
                            <Ds.Icon
                                name="Messages"
                                size="small"
                                color="stone"
                            />
                            <Text skinny variant="label">
                                Batch message
                            </Text>
                        </PopoverItemLink>
                    </ArchivedWorkspaceHiddenItemWrapper>
                )}
            </StyledPopoverContent>
        </Popover>
    );
};
