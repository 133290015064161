import React from "react";

import { isConversationItemUnread } from "app/workspaceConversations/utils/conversation.utils";
import {
    ConversationItem,
    PatientMatchState,
    PatientNhsAppMessageConversationItem,
} from "shared/concierge/conversations/types/item.types";
import { useSingleUserQuery } from "shared/concierge/usersAndTeams/hooks";

import {
    AppointmentRequestConversationThreadItem,
    FailedDeliveryReceiptThreadItem,
    FloreyResponseNoteThreadItem,
    GenericNoteItem,
    LabelTagItem,
    NoteThreadItem,
    PatientEmailThreadItem,
    PatientTriageRequestNoteThreadItem,
    StateChangeThreadItem,
    VideoConsultLinkThreadItem,
} from ".";
import { LinkedItem } from "./FailedDeliveryReceiptThreadItem/FailedDeliveryReceiptThreadItem";
import { FallbackFailedThreadItem } from "./FallbackFailedThreadItem/FallbackFailedThreadItem";
import { MessageConversationThreadItem } from "./MessageConversationItem/MessageConversationItem";
import { PatientSingleResponseThreadItem } from "./PatientSingleResponseThreadItem/PatientSingleResponseThreadItem";

export const ConversationThreadItem = ({
    item,
    currentUserId,
    allItems,
    patientId,
    patientFullName,
    organisationId,
    markItemAsRead,
    matchType,
}: {
    item: ConversationItem;
    currentUserId: string;
    allItems: ConversationItem[];
    patientId: string | null | undefined;
    patientFullName: string | null;
    organisationId: number;
    markItemAsRead: (itemId: string) => void;
    matchType: PatientMatchState | null;
}) => {
    const isUnread = isConversationItemUnread(item, currentUserId);

    const handleOnItemRead = async (itemId: string) => {
        if (isUnread) {
            await markItemAsRead(itemId);
        }
    };

    const createdById =
        item.createdBy.type === "User" ? item.createdBy.id : undefined;

    const itemCreatedByUser = useSingleUserQuery(createdById);

    const isFirstMessageByUser =
        allItems.length === 1 && createdById === currentUserId;

    switch (item.contentType) {
        case "PatientSms":
            return (
                <MessageConversationThreadItem
                    item={item}
                    deliveryMethod="SMS"
                    patientFullName={patientFullName}
                    handleOnItemRead={handleOnItemRead}
                    createdByDisplayName={itemCreatedByUser.data?.displayName}
                    isFirstMessageByUser={isFirstMessageByUser}
                    patientInfo={
                        patientFullName ? [...patientFullName.split(",")] : null
                    }
                />
            );
        case "PatientEmail":
            return (
                <PatientEmailThreadItem
                    item={item}
                    patientFullName={patientFullName}
                    handleOnItemRead={handleOnItemRead}
                    createdByDisplayName={itemCreatedByUser.data?.displayName}
                />
            );
        case "NhsAppMessage":
            return (
                <MessageConversationThreadItem
                    item={item}
                    patientFullName={patientFullName}
                    isFirstMessageByUser={isFirstMessageByUser}
                    deliveryMethod="NHS App"
                    handleOnItemRead={handleOnItemRead}
                    createdByDisplayName={itemCreatedByUser.data?.displayName}
                    patientInfo={
                        patientFullName ? [...patientFullName.split(",")] : null
                    }
                />
            );
        case "StateChange":
            return (
                <StateChangeThreadItem
                    item={item}
                    handleOnItemRead={handleOnItemRead}
                    createdByDisplayName={itemCreatedByUser.data?.displayName}
                />
            );
        case "FloreyResponseNote":
            return (
                <FloreyResponseNoteThreadItem
                    item={item}
                    patientId={patientId}
                    createdByDisplayName={patientFullName}
                    organisationId={organisationId}
                    handleOnItemRead={handleOnItemRead}
                />
            );
        case "PatientSingleResponse":
            return (
                <PatientSingleResponseThreadItem
                    item={item}
                    handleOnItemRead={handleOnItemRead}
                    patientId={patientId}
                    createdByDisplayName={patientFullName}
                    organisationId={organisationId}
                />
            );
        case "FailedDeliveryReceiptLink":
            // Linked items will always be either PatientSmsConversationItem or PatientEmailConversationItem
            const linkedItem =
                allItems.find(
                    (conversationItem): conversationItem is LinkedItem =>
                        conversationItem.id === item.linkedItemId,
                ) || null;

            return (
                <FailedDeliveryReceiptThreadItem
                    item={item}
                    linkedItem={linkedItem}
                    handleOnItemRead={handleOnItemRead}
                    createdByDisplayName={itemCreatedByUser.data?.displayName}
                />
            );
        case "FallbackFailedLink":
            // Linked items will always be PatientNhsAppMessageConversationItem
            const fallbackFailedLinkedItem =
                allItems.find(
                    (
                        conversationItem,
                    ): conversationItem is PatientNhsAppMessageConversationItem =>
                        conversationItem.id === item.linkedItemId,
                ) || null;

            return (
                <FallbackFailedThreadItem
                    item={item}
                    linkedItem={fallbackFailedLinkedItem}
                    handleOnItemRead={handleOnItemRead}
                    createdByDisplayName={itemCreatedByUser.data?.displayName}
                />
            );
        case "PatientTriageRequestNote":
            return (
                <PatientTriageRequestNoteThreadItem
                    item={item}
                    patientId={patientId}
                    organisationId={organisationId}
                    handleOnItemRead={handleOnItemRead}
                    createdByDisplayName={patientFullName}
                    matchType={matchType}
                />
            );
        case "VideoConsultLink": {
            return (
                <VideoConsultLinkThreadItem
                    item={item}
                    handleOnItemRead={handleOnItemRead}
                />
            );
        }
        case "PatientAppointmentRequestNote":
            return (
                <AppointmentRequestConversationThreadItem
                    item={item}
                    patientId={patientId}
                    organisationId={organisationId}
                    handleOnItemRead={handleOnItemRead}
                />
            );
        case "Note":
            return (
                <NoteThreadItem
                    item={item}
                    handleOnItemRead={handleOnItemRead}
                    createdByDisplayName={itemCreatedByUser.data?.displayName}
                />
            );
        case "GenericNote":
            return (
                <GenericNoteItem
                    item={item}
                    handleOnItemRead={handleOnItemRead}
                />
            );
        case "LabelTag":
            return (
                <LabelTagItem
                    item={item}
                    handleOnItemRead={handleOnItemRead}
                    createdByDisplayName={itemCreatedByUser.data?.displayName}
                />
            );
        default:
            return (
                <div>
                    {`Conversation item of type ${item.contentType} not yet supported.`}
                </div>
            );
    }
};
