import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { getAppointmentAvailabilityPeriodDisplay } from "domains/message-component/components/Compose/components/ActionStack/components/SelfBookLink/selfBookLinkGetAppointmentAvailabilityPeriodDisplay";
import { SelfBookLink as SelfBookLinkType } from "domains/message-component/reducer.types";

import { StyledActionStack } from "../../ActionStack.styles";
import { getAppointmentTypeDisplay } from "./selfBookLinkGetAppointmentTypeDisplay";
import { getClinicianNamesDisplay } from "./selfBookLinkGetClinicianNamesDisplay";

const capitalise = (str: string) => {
    if (!str) {
        return str;
    }
    return str.charAt(0).toUpperCase() + str.substring(1);
};

export const SelfBookLink = ({
    selfBookLink,
    onRemoveClick,
}: {
    selfBookLink: SelfBookLinkType;
    onRemoveClick?: () => void;
}) => {
    const clinicianNames = selfBookLink.specificClinician
        ? selfBookLink.specificClinician.flatMap(
              ({ clinicianNames }) => clinicianNames,
          )
        : [];

    const clinicianNamesDisplay = getClinicianNamesDisplay(clinicianNames);
    const appointmentTypeDisplay = getAppointmentTypeDisplay(
        selfBookLink.appointmentType,
    );
    const appointmentAvailabilityPeriodDisplay =
        getAppointmentAvailabilityPeriodDisplay(
            selfBookLink.appointmentAvailabilityPeriod,
        );

    return (
        <StyledActionStack.Row
            justifyContent={"space-between"}
            alignItems={"center"}
            gap="1"
        >
            <UI.Flex gap="1" alignItems="center">
                <StyledActionStack.RowIcon name="Calendar" />
                <StyledActionStack.RowText>
                    Self-Book:{" "}
                    {selfBookLink.crossOrgBooking ? (
                        <>
                            {selfBookLink.crossOrgBooking.targetOdsName} for{" "}
                            {selfBookLink.slotType} ({appointmentTypeDisplay})
                        </>
                    ) : (
                        <>
                            {selfBookLink.slotType} ({appointmentTypeDisplay})
                        </>
                    )}
                    . {capitalise(clinicianNamesDisplay)},{" "}
                    {appointmentAvailabilityPeriodDisplay}.
                </StyledActionStack.RowText>
            </UI.Flex>
            {onRemoveClick !== undefined && (
                <StyledActionStack.SquareIconButton
                    onClick={onRemoveClick}
                    title={"Remove Self-Book link"}
                >
                    <Pill.Icon name="Cross" colour="red" size={3} />
                </StyledActionStack.SquareIconButton>
            )}
        </StyledActionStack.Row>
    );
};
