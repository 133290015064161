import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { Attachment as AttachmentType } from "domains/message-component/reducer.types";

import { StyledActionStack } from "../../ActionStack.styles";
import { AttachmentSize } from "./AttachmentSize";

const RowIcon = ({ status }: { status: "success" | "error" }) =>
    status === "success" ? (
        <StyledActionStack.RowIcon name="Paperclip" appearance="outline" />
    ) : (
        <StyledActionStack.RowIcon
            name="Warning"
            appearance="solid"
            color="red"
        />
    );

export const Attachment = ({
    attachment,
    onRemoveClick,
}: {
    attachment: AttachmentType;
    onRemoveClick?: (attachment: AttachmentType) => void;
}) => {
    switch (attachment.status) {
        case "loading":
            return (
                <StyledActionStack.Row alignItems={"center"} gap="1">
                    <StyledActionStack.RowSpinner />
                    <StyledActionStack.RowText>
                        {attachment.name} uploading
                    </StyledActionStack.RowText>
                </StyledActionStack.Row>
            );
        case "success":
        case "error":
            return (
                <StyledActionStack.Row
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    gap="1"
                >
                    <UI.Flex gap="1" alignItems="center">
                        <RowIcon status={attachment.status} />
                        <UI.Flex flexDirection="column">
                            {attachment.status === "success" &&
                            attachment.previewUrl ? (
                                <StyledActionStack.RowLink
                                    href={attachment.previewUrl}
                                    openInNewTab
                                >
                                    <UI.Link.Text text={attachment.name} />
                                    <UI.Link.Icon />
                                </StyledActionStack.RowLink>
                            ) : (
                                <StyledActionStack.RowText>
                                    {attachment.name}
                                </StyledActionStack.RowText>
                            )}
                            {attachment.status === "error" && (
                                <StyledActionStack.RowText colour="red">
                                    {attachment.error}
                                </StyledActionStack.RowText>
                            )}
                        </UI.Flex>
                    </UI.Flex>
                    <UI.Flex gap="1" alignItems="center">
                        {attachment.size && (
                            <AttachmentSize size={attachment.size} />
                        )}
                        {onRemoveClick !== undefined && (
                            <StyledActionStack.SquareIconButton
                                onClick={() => onRemoveClick(attachment)}
                                title={`Remove ${attachment.name}`}
                            >
                                <Pill.Icon name="Cross" colour="red" size={3} />
                            </StyledActionStack.SquareIconButton>
                        )}
                    </UI.Flex>
                </StyledActionStack.Row>
            );
        default: {
            // Make sure build breaks if we don't render one of the attachment statuses
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustive: never = attachment;
            return null;
        }
    }
};
