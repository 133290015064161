import {
    PatientCommunicationQueryOptions,
    useMedicalRecordConnection,
    usePatientCommunicationConsentQuery,
} from "@accurx/native";
import { useCompose } from "domains/message-component/context";
import { PatientExternalId } from "domains/message-component/types";

import {
    MappedCommunicationConsentResponse,
    mapConsentQueryResponseToCommunicationConsent,
} from "./utils/mapConsentQueryResponseToCommunicationConsent";

export const useCommunicationConsent = (
    args: { patientExternalIds: PatientExternalId[] },
    options?: Omit<
        PatientCommunicationQueryOptions<MappedCommunicationConsentResponse>,
        "enabled"
    >,
) => {
    const medicalRecordConnection = useMedicalRecordConnection();
    const { shouldTryToFetchConsent } = useCompose();

    const query = usePatientCommunicationConsentQuery(args, {
        select: (data) =>
            mapConsentQueryResponseToCommunicationConsent(
                data,
                medicalRecordConnection.system,
            ),
        ...options,
        enabled: shouldTryToFetchConsent,
    });

    if (!shouldTryToFetchConsent) {
        return null;
    }
    return query;
};
