import { useCurrentWorkspace } from "@accurx/auth";
import { useUploadFileMutation } from "domains/message-component/hooks/useUploadFileMutation";
import { AttachmentSource } from "domains/message-component/reducer.types";

import { SUPPORTED_FILE_EXTENSIONS } from "./upload.constants";
import { AtLeastOneAttachmentError, UploadFilesOptions } from "./upload.types";
import { validateFile } from "./validateFile";

export type OnUpload = (
    file: File,
    options: UploadFilesOptions,
) => Promise<
    | {
          status: "success";
          result: {
              id: string;
              previewUrl?: string;
          };
      }
    | {
          status: "error";
          result: { errors: AtLeastOneAttachmentError };
      }
>;
export const useUploadFromDesktop = (source: AttachmentSource) => {
    const uploadFileMutation = useUploadFileMutation(source);
    const orgId = useCurrentWorkspace().orgId;
    const workspaceId = orgId.toString();

    const onUpload: OnUpload = async (file, options) => {
        const validated = validateFile({
            file,
            maxAllowedFileSize: {
                size: options.maxFileSizeInMegabytes * 1024 * 1024,
                humanReadable: `${options.maxFileSizeInMegabytes} MB`,
            },
            allowedFileExtensions: SUPPORTED_FILE_EXTENSIONS,
        });
        if (!validated.isValid) {
            return {
                status: "error",
                result: {
                    errors: validated.errors,
                },
            };
        }
        try {
            const result = await uploadFileMutation.mutateAsync({
                file,
                workspaceId,
            });

            return {
                status: "success",
                result,
            };
        } catch (error) {
            let errorMessage = "Failed to upload";
            if (error instanceof Error && error.message) {
                errorMessage = error.message;
            }
            return {
                status: "error",
                result: {
                    errors: [{ type: "uploadFailed", message: errorMessage }],
                },
            };
        }
    };

    return {
        onUpload,
    };
};
