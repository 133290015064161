import { useCallback } from "react";

import { Log } from "@accurx/shared";
import { api } from "domains/concierge/internal/api/ticket";
import { mapUnreadTicketSummariesToUnreadItemsSummaries } from "domains/concierge/internal/api/ticket/mappers/mapUnreadTicketSummariesToUnreadItemsSummaries";
import {
    useConciergeDispatch,
    useConciergeMeta,
} from "domains/concierge/internal/context";
import { actions } from "domains/concierge/internal/store";

export const useFetchTicketUnreadItems = () => {
    const dispatch = useConciergeDispatch();
    const { workspaceId } = useConciergeMeta();

    const fetchTicketUnreadItems = useCallback(
        async ({
            isInitialFetch,
            options,
        }: {
            isInitialFetch: boolean;
            options?: {
                onSuccess?: () => void;
                onError?: () => void;
            };
        }): Promise<void> => {
            try {
                const { unreadSummaries } = await api.getUnreadTickets(
                    workspaceId,
                );
                dispatch(
                    actions.conversations.populateTicketUnreadItems({
                        unreadItemsSummaries:
                            mapUnreadTicketSummariesToUnreadItemsSummaries(
                                unreadSummaries,
                            ),
                    }),
                );
                dispatch(
                    actions.queries.setQueryStatus({
                        name: "ticketUnreads",
                        value: { status: "success" },
                    }),
                );

                options?.onSuccess && options.onSuccess();
            } catch (err) {
                const error = err as Error;
                if (isInitialFetch) {
                    dispatch(
                        actions.queries.setQueryStatus({
                            name: "ticketUnreads",
                            value: { status: "error", error },
                        }),
                    );
                }
                Log.error("Fetching ticket unread items failed", {
                    tags: {
                        product: "Inbox",
                        workspaceId,
                        isInitialFetch,
                    },
                    originalException: error,
                });

                options?.onError && options.onError();
            }
        },
        [dispatch, workspaceId],
    );

    return fetchTicketUnreadItems;
};
