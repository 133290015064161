export const formatPatientName = ({
    firstName,
    familyName,
    prefixName,
}: {
    firstName?: string | null;
    familyName?: string | null;
    prefixName?: string | null;
}): string => {
    // Possible formatting of first and family names:
    // - LASTNAME
    // - Firstname
    // - LASTNAME, Firstname
    let name = [familyName?.toUpperCase(), firstName]
        .filter(Boolean)
        .join(", ");

    if (name === "") {
        return "Patient name missing";
    }

    // Any Prefix is appended:
    // - LASTNAME, Firstname (Ms)
    if (prefixName) {
        name += ` (${prefixName})`;
    }

    return name;
};
