import { useContext } from "react";

import { RealtimeContext } from "domains/realtime/components/RealtimeContext";
import { useHubClientTransport } from "domains/realtime/hooks/useHubClientTransport";
import { useNativeSignalRTransport } from "domains/realtime/hooks/useNativeSignalRTransport";
import { useCanUseSignalRViaNativeBridge } from "domains/realtime/hooks/utils/useCanUseSignalRViaNativeBridge";
import { Transport } from "domains/realtime/types";

export const useTransport = (): Transport => {
    const { hubClient } = useContext(RealtimeContext);

    const nativeTransport = useNativeSignalRTransport();
    const hubClientTransport = useHubClientTransport(hubClient);

    const canUseSignalRViaNativeBridge = useCanUseSignalRViaNativeBridge();

    return canUseSignalRViaNativeBridge ? nativeTransport : hubClientTransport;
};
