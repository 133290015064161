import { TwoFactorTokenResponse } from "@accurx/api/account";
import {
    IWrappedResult,
    getApiEndpoint,
    httpClient,
    returnDataOrThrow,
} from "@accurx/shared";
import { useQuery } from "@tanstack/react-query";

const GENERATE_TWO_FACTOR_TOKEN = "GenerateTwoFactorToken";

const generateTwoFactorToken = async ({
    email,
}: {
    email: string;
}): Promise<IWrappedResult<TwoFactorTokenResponse>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: `/api/account/generateTwoFactorToken?email=${email}`,
        }),
    );

export const useGenerateTwoFactorToken = ({ email }: { email: string }) => {
    return useQuery<TwoFactorTokenResponse, Error, TwoFactorTokenResponse>({
        queryKey: [GENERATE_TWO_FACTOR_TOKEN, email],
        queryFn: async () => {
            const response = await generateTwoFactorToken({
                email,
            });
            return returnDataOrThrow(response);
        },
    });
};
