import React, { KeyboardEvent, useRef } from "react";

import { Button, Card, Ds, Icon, Text } from "@accurx/design";
import { useIsManageOrg } from "@accurx/navigation";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import { useDispatch } from "react-redux";
import { generatePath, useHistory } from "react-router-dom";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { ChainAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ROUTES_CHAIN, ROUTES_WORKSPACE } from "shared/Routes";

import { getVaccinesSessionCsvDownloadUrl } from "../../Vaccine.helper";
import { SessionSummary } from "../../models/VaccineRecordingDTO";
import {
    setStatusFilters,
    updateMyPracticeFilter,
    updateSearchTerm,
} from "../../vaccineInvitesOldPage/vaccineDelivery.actions";

export interface VaccineRecordingSessionCardProps {
    session: SessionSummary;
}

export const VaccineRecordingSessionCard = ({
    session,
}: VaccineRecordingSessionCardProps): JSX.Element => {
    const { selectedOrgId } = useSelectedOrganisation();
    const history = useHistory();
    const dispatch = useDispatch();
    const downloadCsvRef = useRef<HTMLAnchorElement>(null);
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const isManageOrg = useIsManageOrg();

    const handleDownloadCsvKeyUp = (
        e: KeyboardEvent<HTMLAnchorElement>,
    ): void => {
        if (e.key === "Enter") {
            if (!!downloadCsvRef?.current) {
                downloadCsvRef.current.click();
            }
        }
    };

    const handleGoToSessionPage = (): void => {
        history.push(
            isManageOrg
                ? generatePath(ROUTES_CHAIN.practicesVaccineBookedClinicsId, {
                      orgId: `${selectedOrgId}`,
                      sessionId: session.sessionId,
                  })
                : generatePath(ROUTES_WORKSPACE.accubookBookedPatientsSession, {
                      workspaceId: `${selectedOrgId}`,
                      sessionId: session.sessionId,
                  }),
        );
    };

    const handleGoToFilteredInviteList = (
        event: React.MouseEvent<HTMLButtonElement>,
    ): void => {
        event.preventDefault();
        ChainAnalyticsTracker.trackVaccineManageBooking(analyticsLoggedInProps);
        dispatch(updateSearchTerm(`clinicId:${session.sessionId}`));
        dispatch(setStatusFilters([]));
        dispatch(updateMyPracticeFilter(false));
        history.push(
            isManageOrg
                ? generatePath(
                      ROUTES_CHAIN.practicesVaccineAllPatientsInvited,
                      { orgId: `${selectedOrgId}` },
                  )
                : generatePath(ROUTES_WORKSPACE.accubookManagePatients, {
                      workspaceId: `${selectedOrgId}`,
                  }),
        );
    };

    const renderSessionInfo = (session: SessionSummary): JSX.Element => {
        return (
            <>
                <div className="d-flex align-items-center">
                    <Text variant="label" props={{ className: "mr-1" }} skinny>
                        {DateHelpers.formatTime(
                            session.startTime,
                            DateFormatOptions.TIME,
                        )}{" "}
                        -{" "}
                        {DateHelpers.formatTime(
                            session.endTime,
                            DateFormatOptions.TIME,
                        )}
                    </Text>
                    <Ds.Badge color="greyscale" className="mr-1">
                        {session.type}
                    </Ds.Badge>
                    <Ds.Badge color="greyscale" className="mr-1">
                        {session.doseType}
                    </Ds.Badge>
                    {!session.isPublished && (
                        <Ds.Badge className="mr-1">Not published</Ds.Badge>
                    )}
                </div>
                <div className="d-flex align-items-center justify-content-between mt-1">
                    <div className="d-flex">
                        <Text props={{ className: "mr-2" }} skinny>
                            Empty slots:{" "}
                            {session.totalCapacity - session.totalBooked}
                        </Text>
                        <Text props={{ className: "mr-2" }} skinny>
                            Patients booked: {session.totalBooked}
                        </Text>
                    </div>
                    <Text skinny>Patients arrived: {session.totalArrived}</Text>
                </div>
            </>
        );
    };

    const renderSessionCardHeader = (session: SessionSummary): JSX.Element => {
        return (
            <Text
                variant="label"
                as="h2"
                props={{
                    className: "d-inline-flex align-items-center text-truncate",
                }}
                skinny
            >
                <span className="d-flex align-items-center mr-1">
                    <Icon name="Calendar" size={3} />
                </span>
                <span className="text-truncate">
                    {DateHelpers.formatDate(
                        session.startTime,
                        DateFormatOptions.DATE_LONG,
                    )}
                    <Text as="span" skinny>
                        {" "}
                        · {session.deliverySite}
                    </Text>
                </span>
            </Text>
        );
    };

    const renderSessionCardFooter = (session: SessionSummary): JSX.Element => {
        return (
            <div className="d-flex justify-content-between align-items-center">
                <Button
                    onClick={handleGoToFilteredInviteList}
                    theme="transparent"
                    style={{ textDecoration: "underline" }}
                    text={"Manage bookings"}
                />
                <span className="d-flex">
                    <a
                        href={getVaccinesSessionCsvDownloadUrl(
                            `${selectedOrgId}`,
                            `${session.sessionId}`,
                        )}
                        download
                        target="_blank"
                        rel="noreferrer"
                        ref={downloadCsvRef}
                        onKeyUp={handleDownloadCsvKeyUp}
                        className="mr-2"
                    >
                        <Button
                            icon={{ name: "Save", colour: "blue" }}
                            text="Export CSV"
                            theme="secondary"
                            className="text-nowrap w-100"
                        />
                    </a>
                    <Button
                        theme="secondary"
                        text="View clinic"
                        icon={{
                            name: "Person",
                            colour: "blue",
                            style: "Line",
                        }}
                        onClick={handleGoToSessionPage}
                    />
                </span>
            </div>
        );
    };

    return (
        <Card
            spacing={2}
            props={{
                className: "mb-2",
                "data-testid": "vaccine-session-card",
            }}
            header={renderSessionCardHeader(session)}
            footer={renderSessionCardFooter(session)}
        >
            {renderSessionInfo(session)}
        </Card>
    );
};
