import { ChangeEvent, useEffect, useState } from "react";

import { WorkspaceStatus } from "@accurx/api/account";
import { useCurrentUser, useCurrentWorkspace } from "@accurx/auth";
import {
    BadgeProps,
    CollapsibleInfo,
    Ds,
    Feedback,
    Flex,
    Input,
    TabProps,
    Tabs,
    Text,
} from "@accurx/design";
import { useFuzzyFilter } from "@accurx/hooks";
import { useAccurxWebTitle } from "@accurx/navigation";
import {
    WORKSPACE_MANAGEMENT_ROUTES,
    isArchivedWorkspace,
    supportsMultipleWorkspaces,
    useWorkspaceInvitesQuery,
    useWorkspaceUsersQuery,
} from "@accurx/workspace-management";
import { useHistory } from "react-router";
import { generatePath, useLocation } from "react-router-dom";

import { FlemingAnalyticsTracker } from "app/analytics";
import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { sortUsers } from "../helpers";
import { ApprovalRequestCard } from "./ApprovalRequestCard/ApprovalRequestCard";
import { ApprovedUserCard } from "./ApprovedUserCard/ApprovedUserCard";
import { ArchivedUserCard } from "./ArchivedUserCard/ArchivedUserCard";
import { OutstandingInviteList } from "./OutstandingInviteList";
import { UserList } from "./UserList";
import {
    StyledErrorCardContainer,
    StyledSearchInputContainer,
    StyledTabListWrapper,
} from "./UserManagementPage.styles";

const getBadgeProps = (count: number): Partial<BadgeProps> => ({
    text: String(count),
    colour: "red",
    radius: "full",
    luminosity: "low",
});

export const UserManagementPage = (): JSX.Element => {
    useAccurxWebTitle("Manage workspace users");

    const history = useHistory();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const workspace = useCurrentWorkspace();

    const {
        orgId: workspaceId,
        organisationNodeName,
        organisationName,
        settings: { isAdminUser },
    } = workspace;

    const { user } = useCurrentUser();

    useEffect(
        () =>
            FlemingAnalyticsTracker.trackUserManagementPageView(
                analyticsLoggedInProps,
            ),
        [analyticsLoggedInProps],
    );

    const showDefaultOrgName = supportsMultipleWorkspaces(workspace);

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);

    const activeTab = queryParams.get("tab");

    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        setSearchTerm("");
    }, [activeTab]);

    const inviteColleaguesButtonOnClick = () => {
        FlemingAnalyticsTracker.trackWorkspaceInviteStart({
            ...analyticsLoggedInProps,
            appOrigin: "UserManagementPage",
        });

        history.push(
            generatePath(WORKSPACE_MANAGEMENT_ROUTES.inviteUsers, {
                workspaceId,
            }),
        );
    };

    const {
        data: {
            approvedUsers = [],
            archivedUsers = [],
            unapprovedUsers = [],
        } = {},
        status: usersQueryStatus,
    } = useWorkspaceUsersQuery({ workspaceId }, { refetchOnMount: true });

    const orderedApprovedUsers = sortUsers({
        users: approvedUsers,
        currentUserId: user.accuRxUserId,
    });

    const filteredApprovedUsers = useFuzzyFilter(
        orderedApprovedUsers,
        searchTerm,
        {
            threshold: 0.3,
            keys: ["name", "email"],
        },
    );

    const { data: invites = [], status: invitesQueryStatus } =
        useWorkspaceInvitesQuery({ workspaceId });

    const showUnapprovedBadge =
        isAdminUser &&
        unapprovedUsers.length > 0 &&
        !isArchivedWorkspace(workspace);

    const tabs: TabProps[] = [
        {
            tabId: "approved",
            text: `Approved members · ${approvedUsers.length}`,
        },
        {
            tabId: "pending",
            text: `Approval requests · ${
                showUnapprovedBadge ? "" : unapprovedUsers.length
            }`,
            ...(showUnapprovedBadge && {
                badge: getBadgeProps(unapprovedUsers.length),
            }),
        },
        {
            tabId: "invited",
            text: `Invited · ${invites.length}`,
        },
        {
            tabId: "archived",
            text: `Archived · ${archivedUsers.length}`,
        },
    ];

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target?.value || "");
    };

    if (usersQueryStatus === "error") {
        return (
            <Feedback
                colour="error"
                title={"There was an error loading this page"}
            >
                <Text skinny>Please refresh the page and try again</Text>
            </Feedback>
        );
    }
    return (
        <StyledContainer>
            <PageHeader title={"Users"} type={PageHeaderType.ListPage} />
            <Flex flexDirection="column" gap="3">
                <Text skinny>
                    This area allows you to see all Accurx users from{" "}
                    <Text variant="label" as="span" skinny>
                        {organisationName}
                        {showDefaultOrgName ? " " : "."}
                    </Text>
                    {showDefaultOrgName && (
                        <Text as="span" skinny>
                            at {organisationNodeName}.
                        </Text>
                    )}
                </Text>
                <CollapsibleInfo title="Information about admin members">
                    <Text skinny>
                        Admin members can archive users from the workspace and
                        give the admin role to other users. There must be at
                        least 1 admin member in the workspace.
                    </Text>
                </CollapsibleInfo>
                {workspace.status === WorkspaceStatus.Active && (
                    <div>
                        <Ds.Button
                            onClick={inviteColleaguesButtonOnClick}
                            data-userflow-id="user-management-invite-button"
                        >
                            <Ds.Button.Icon name="Plus" />
                            Invite colleagues
                        </Ds.Button>
                    </div>
                )}
                <Tabs
                    selected={queryParams.get("tab") || "approved"}
                    onTabChange={(tab) =>
                        history.replace({ search: `?tab=${tab}` })
                    }
                >
                    <StyledTabListWrapper>
                        <Tabs.TabList tabs={tabs} />
                    </StyledTabListWrapper>

                    <Tabs.Panel tabId="approved">
                        <StyledSearchInputContainer>
                            <Input
                                isSearchInput
                                onChange={handleSearch}
                                placeholder="Search members"
                                aria-label="Search members"
                                disabled={usersQueryStatus === "loading"}
                            />
                        </StyledSearchInputContainer>
                        <UserList
                            emptyMessage={
                                approvedUsers.length
                                    ? "No results found"
                                    : "No users are currently approved"
                            }
                            isLoadingUsers={usersQueryStatus === "loading"}
                        >
                            {filteredApprovedUsers.map((user) => (
                                <ApprovedUserCard
                                    key={user.userId}
                                    user={user}
                                />
                            ))}
                        </UserList>
                    </Tabs.Panel>

                    <Tabs.Panel tabId="pending">
                        <UserList
                            emptyMessage="No users are currently pending"
                            isLoadingUsers={usersQueryStatus === "loading"}
                        >
                            {unapprovedUsers.map((user) => (
                                <ApprovalRequestCard
                                    key={user.userId}
                                    user={user}
                                />
                            ))}
                        </UserList>
                    </Tabs.Panel>

                    <Tabs.Panel tabId="invited">
                        {invitesQueryStatus === "error" ? (
                            <StyledErrorCardContainer>
                                <Feedback
                                    colour="error"
                                    title="We can't load your outstanding invites at the moment"
                                />
                            </StyledErrorCardContainer>
                        ) : (
                            <OutstandingInviteList
                                outstandingInvites={invites}
                                isLoadingInvites={
                                    invitesQueryStatus === "loading"
                                }
                            />
                        )}
                    </Tabs.Panel>

                    <Tabs.Panel tabId="archived">
                        <UserList
                            emptyMessage="No users are currently archived"
                            isLoadingUsers={usersQueryStatus === "loading"}
                        >
                            {archivedUsers.map((user) => (
                                <ArchivedUserCard
                                    key={user.userId}
                                    user={user}
                                />
                            ))}
                        </UserList>
                    </Tabs.Panel>
                </Tabs>
            </Flex>
        </StyledContainer>
    );
};
