import { Ds } from "@accurx/design";

import { StyledErrorContainer } from "./InlineError.styles";

export const InlineError = ({ children }: { children: string }) => (
    <StyledErrorContainer>
        <Ds.Icon name="Error" appearance="solid" color="red" size="small" />
        <Ds.Text size="small" color="zinc">
            {children}
        </Ds.Text>
    </StyledErrorContainer>
);
