import React from "react";

import { FeatureName, useAuth } from "@accurx/auth";
import { StackPanel } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { isArchivedWorkspace } from "@accurx/workspace-management";

import { useCurrentOrgId, useIsFeatureEnabled } from "store/hooks";

import { GpCard } from "../GpCard";
import { PreviousClinicianConversationsCard } from "../PreviousClinicianConversationsCard/PreviousClinicianConversationsCard";
import { RecordViewCard } from "../RecordViewCard";
import {
    StyledContainer,
    StyledOverviewColumnOne,
    StyledOverviewColumnTwo,
} from "./OverviewPanel.styles";

const OverviewPanel = (): JSX.Element => {
    useAccurxWebTitle("View patient profile");

    const isRecordViewFeatureEnabled = useIsFeatureEnabled(
        FeatureName.RecordAccessBeta,
    );

    const orgId = useCurrentOrgId();

    const { user } = useAuth();

    const workspace = user.isLoggedIn
        ? user.organisations.find((w) => w.orgId === orgId)
        : null;

    const isArchived = workspace && isArchivedWorkspace(workspace);

    return (
        <StyledContainer>
            <StyledOverviewColumnOne>
                <StackPanel gutter={2}>
                    <GpCard />
                    <PreviousClinicianConversationsCard />
                </StackPanel>
            </StyledOverviewColumnOne>
            <StyledOverviewColumnTwo>
                {isRecordViewFeatureEnabled && !isArchived && (
                    <RecordViewCard />
                )}
            </StyledOverviewColumnTwo>
        </StyledContainer>
    );
};

export { OverviewPanel };
