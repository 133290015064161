import {
    AnalyticsProperties,
    AnalyticsTracker,
    EventClientPrefixes,
    SharedAppInsightsCustomProperties,
    SharedCustomProperties,
} from "@accurx/shared";

import { FlemingLoggedInCustomProperties } from "./FlemingAnalyticsConstants";

/**
 * Do not use this function
 * @deprecated Use GenericTrackEvent instead
 */
export const TrackFlemingEvent = (
    eventName: string,
    props: AnalyticsProperties,
): void => {
    AnalyticsTracker.LegacyTrackEvent(
        EventClientPrefixes.FLEMING,
        eventName,
        props,
    );
};

/**
 * NOTE: this is only needed to be used for previously existing events that need to be migrated
 * to rudderstack. Newly introduced events do not need this.
 *
 * Takes any object that inherits from FlemingLoggedInCustomProperties and creates a new object
 * that contains only the properties from FlemingLoggedInCustomProperties, allowing us to take
 * a larger object and reduce it down to just a FlemingLoggedInCustomProperties object
 * */
export const reduceToFlemingLoggedInCustomProperties = (
    props: FlemingLoggedInCustomProperties,
): FlemingLoggedInCustomProperties => {
    return {
        [SharedCustomProperties.UserId]: props[SharedCustomProperties.UserId],
        [SharedAppInsightsCustomProperties.CurrentOrganisationId]:
            props[SharedAppInsightsCustomProperties.CurrentOrganisationId],
        [SharedAppInsightsCustomProperties.CurrentOrganisationName]:
            props[SharedAppInsightsCustomProperties.CurrentOrganisationName],
        [SharedCustomProperties.UserHealthcareRole]:
            props[SharedCustomProperties.UserHealthcareRole],
        [SharedCustomProperties.IsUserApproved]: props.isUserApproved,
        [SharedCustomProperties.UserOrganisationRole]:
            props.userOrganisationRole,
        workspaceSpecialtyList: props.workspaceSpecialtyList,
    };
};
