import { createContext } from "react";

import { HubClient } from "domains/realtime/hubClient/HubClient";
import { ConnectionState } from "domains/realtime/types";

export type RealtimeContextState = {
    connectionState: ConnectionState;
    // This is an initial tradeoff for us to migrate all usages of
    // hubClient over to transport to utilise the optional hubClient
    // provider logic for shared/concierge and the trust inbox ahead
    // of future deprecation
    hubClient: HubClient | null;
};

export const RealtimeContext = createContext<RealtimeContextState>({
    connectionState: "Initialising",
    hubClient: null,
});
