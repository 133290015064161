import { useFeatureFlag } from "@accurx/auth";
import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import { Conversation } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { PatientName, formatPatientName } from "@accurx/navigation";
import { formatNhsNumber } from "@accurx/shared/dist/NhsNumberHelper";
import { ArchivedWorkspaceHiddenItemWrapper } from "@accurx/workspace-management";
import { formatDateShortWithHyphens } from "domains/inbox/util/format/date";
import { getPatientMatchState } from "domains/inbox/util/getPatientMatchState";

import {
    PatientMatchingActions,
    SearchForPatientButtonProps,
} from "../ConversationViewHeader/PatientMatchingActions";
import {
    StyledFeedback,
    StyledFeedbackContent,
    StyledPatientDetails,
} from "./ConversationActionsFeedback.styles";

export const PatientConversationActionsFeedback = ({
    conversation,
    searchForPatientButtonProps,
}: {
    conversation: Conversation;
    searchForPatientButtonProps?: SearchForPatientButtonProps;
}) => {
    const patient = usePatient({ patientId: conversation.regardingPatientId });
    const patientMatchState = getPatientMatchState(conversation);
    const isPatientMessagingDisabled = useFeatureFlag(
        "DisableSingleSendSmsAndVideoInvites",
    );

    if (
        conversation.status === "Done" &&
        (patientMatchState?.type === "NoMatch" ||
            patientMatchState?.type === "SuggestedMatch")
    ) {
        return (
            <ArchivedWorkspaceHiddenItemWrapper>
                <StyledFeedback
                    colour="information"
                    title={"Reopen the conversation to match it to a patient"}
                />
            </ArchivedWorkspaceHiddenItemWrapper>
        );
    }

    if (!patient && patientMatchState?.type === "NoMatch") {
        return (
            <StyledFeedback
                colour="warning"
                title={
                    "For more message actions, search for a patient to match to this conversation"
                }
            >
                <StyledFeedbackContent>
                    <PatientMatchingActions
                        patientMatchState={patientMatchState}
                        conversation={conversation}
                        searchForPatientButtonProps={
                            searchForPatientButtonProps
                        }
                    />
                </StyledFeedbackContent>
            </StyledFeedback>
        );
    }

    if (!patient && patientMatchState?.type === "SuggestedMatch") {
        const suggestedPatient = patientMatchState.suggestedMatch;

        const patientName = formatPatientName({
            firstName: suggestedPatient.firstName,
            familyName: suggestedPatient.familyName,
        });

        const nhsNumber = suggestedPatient.externalIds.find(
            (externalId) => externalId.type === "NhsNumber",
        )?.value;

        const dateOfBirth = suggestedPatient.dateOfBirth;

        return (
            <StyledFeedback
                colour="warning"
                title={
                    <span>
                        For more conversation actions, please check and confirm
                        the suggested patient match.{" "}
                        <UI.Link
                            href="https://support.accurx.com/en/articles/4247693-patient-triage-how-does-patient-identification-work"
                            openInNewTab
                        >
                            <UI.Link.Text text="How we suggest patients" />
                            <UI.Link.Icon />
                        </UI.Link>
                    </span>
                }
            >
                <StyledPatientDetails>
                    <PatientName
                        name={patientName}
                        ageYear={suggestedPatient.ageYear}
                        gender={suggestedPatient.gender}
                    />
                    <UI.Flex gap="0.5">
                        {nhsNumber && (
                            <>
                                <UI.Text skinny colour="night">
                                    {`NHS: ${formatNhsNumber(nhsNumber)}`}
                                </UI.Text>
                                &middot;
                            </>
                        )}
                        {!!dateOfBirth && (
                            <UI.Text skinny colour="night">
                                {`DOB: ${formatDateShortWithHyphens(
                                    dateOfBirth,
                                )}`}
                            </UI.Text>
                        )}
                    </UI.Flex>
                </StyledPatientDetails>
                <StyledFeedbackContent>
                    <PatientMatchingActions
                        patientMatchState={patientMatchState}
                        conversation={conversation}
                        searchForPatientButtonProps={
                            searchForPatientButtonProps
                        }
                    />
                </StyledFeedbackContent>
            </StyledFeedback>
        );
    }

    if (isPatientMessagingDisabled) {
        return (
            <StyledFeedback
                colour="information"
                title={
                    <span>
                        Patient messaging isn’t available as your organisation
                        has exceeded its monthly limit. Limits reset on the
                        first day of each month.{" "}
                        <UI.Link
                            href="https://support.accurx.com/en/articles/9929962-accurx-lite-limits-for-community-and-mental-health-trusts"
                            openInNewTab
                        >
                            <UI.Link.Text text="Learn more" />
                            <UI.Link.Icon />
                        </UI.Link>
                    </span>
                }
            />
        );
    }

    // Conversation is either:
    // - Open and matched to a patient
    // - Done and not a triage request
    //
    // so we show nothing here.
    return null;
};
