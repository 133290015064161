import { Ds } from "@accurx/design";
import { ContactDetail } from "domains/message-component/reducer.types";

import { mapMethodToPluralLowerCase } from "./mapMethodToPluralLowerCase";

type DeclinedConsentModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSendMessage: () => void;
    method: ContactDetail["method"];
};

export const DeclinedConsentModal = ({
    isOpen,
    onClose,
    onSendMessage,
    method,
}: DeclinedConsentModalProps) => {
    return (
        <Ds.Modal open={isOpen} onOpenChange={onClose}>
            <Ds.Modal.Section>
                <Ds.Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>
                        Patient has declined consent. Send anyway?
                    </Ds.Modal.Title>
                    <Ds.Text>
                        {`This patient has declined consent to receive ${mapMethodToPluralLowerCase(
                            method,
                        )}. Consider using a different contact method.`}
                    </Ds.Text>
                    <Ds.Flex justifyContent="flex-end" gap="2">
                        <Ds.Button
                            onClick={onClose}
                            type="button"
                            appearance={"secondary"}
                        >
                            Cancel
                        </Ds.Button>
                        <Ds.Button
                            onClick={onSendMessage}
                            type="button"
                            appearance="primary"
                        >
                            Send
                        </Ds.Button>
                    </Ds.Flex>
                </Ds.Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
