import { ReactNode } from "react";

import { FeatureName, useFeatureFlag } from "@accurx/auth";
import * as UI from "@accurx/design";
import { WithTooltip } from "domains/inbox/components/Tooltip/WithTooltip";

export type ConversationCountData =
    | { status: "Loading" }
    | { status: "Success"; count: number; isFullyLoaded: boolean };

type ConversationCountTooltipProps = {
    total?: ConversationCountData;
    unread?: number;
    children: ReactNode;
    onOpenChange?: (isOpen: boolean) => void;
};

export const ConversationCountTooltip = ({
    total,
    unread,
    children,
    onOpenChange,
}: ConversationCountTooltipProps) => {
    const isNewTooltipEnabled = useFeatureFlag(
        FeatureName.UnifiedInboxTotalConversationCountTooltip,
    );

    /**
     * TODO: Remove as part of INB-452
     * https://linear.app/accurx/issue/INB-452/remove-feature-flag
     */
    if (!isNewTooltipEnabled) {
        if (total) {
            const props = { total, onOpenChange, children };
            return <ConversationCountTooltipLegacy {...props} />;
        } else {
            return <>{children}</>;
        }
    }

    const totalItems = total?.status === "Success" ? [getTotalText(total)] : [];
    const unreadItems = unread !== undefined ? [`${unread} unread`] : [];
    const tooltipText = [...totalItems, ...unreadItems].join(", ");
    const enabled = total?.status === "Success" || unread !== undefined;

    return (
        <WithTooltip
            isEnabled={enabled}
            content={
                <UI.Ds.Text size="small" color="white" weight="bold">
                    {tooltipText}
                </UI.Ds.Text>
            }
            props={{ root: { onOpenChange } }}
        >
            {children}
        </WithTooltip>
    );
};

/**
 * @deprecated
 */
type ConversationCountTooltipLegacyProps = {
    total: ConversationCountData;
    children: ReactNode;
    onOpenChange?: (isOpen: boolean) => void;
};

/**
 * @deprecated
 */
export const ConversationCountTooltipLegacy = ({
    total,
    children,
    onOpenChange,
}: ConversationCountTooltipLegacyProps) => {
    return (
        <WithTooltip
            isEnabled={total.status === "Success"}
            content={
                <UI.Text variant="note" colour="white" skinny>
                    {getTotalText(total)}
                </UI.Text>
            }
            props={{ root: { onOpenChange } }}
        >
            {children}
        </WithTooltip>
    );
};

const getTotalText = (total: ConversationCountData): string => {
    if (total.status === "Success" && total.isFullyLoaded) {
        return `${total.count} total`;
    } else if (total.status === "Success" && !total.isFullyLoaded) {
        return `${total.count}+ total`;
    } else {
        return "";
    }
};
