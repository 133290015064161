import {
    BulkAppointmentImport,
    BulkAppointmentImportStatus,
} from "@accurx/api/appointment";
import { getApiEndpoint, httpClient, returnDataOrThrow } from "@accurx/shared";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

type BulkAppointmentImportsFilters = {
    statuses?: BulkAppointmentImportStatus[];
};

export const useBulkAppointmentImportsForWorkspaceQuery = (
    workspaceId: string,
    filters: BulkAppointmentImportsFilters,
): UseQueryResult<BulkAppointmentImport[], Error> => {
    return useQuery({
        queryKey: ["AppointmentUpload", { workspaceId, filters }],
        queryFn: async () => {
            const response = await httpClient.getReturnJsonSafeAsync(
                getApiEndpoint({
                    path: "/api/appointment/:workspaceId/bulkappointmentimports/previousimports",
                    params: { workspaceId },
                }),
            );
            return returnDataOrThrow<BulkAppointmentImport[]>(response);
        },
        refetchOnMount: true,
        //Client side filtering
        select: (data) => {
            return data.filter((x) => filters.statuses?.includes(x.status));
        },
    });
};
