import { createContext, useContext, useEffect } from "react";

import { FullScreenToggle, Toggle } from "@accurx/navigation";

const initialState = {
    secondaryNav: { isOpen: false, toggle: () => null, setIsOpen: () => null },
    fullScreen: {
        isOpen: false,
        isBordered: false,
        toggle: () => null,
        setIsOpen: () => null,
        setIsBordered: () => null,
    },
};

export const UnifiedLayoutContext = createContext<{
    secondaryNav: Toggle;
    fullScreen: FullScreenToggle;
}>(initialState);

export const useUnifiedLayout = () => useContext(UnifiedLayoutContext);

/**
 * This hook will automatically hide secondary navigation layout when component is rendered for the first time,
 * and it will display it back on component cleanup
 */
export const useFullScreen = ({
    isBordered,
}: { isBordered?: boolean } = {}) => {
    const { fullScreen } = useUnifiedLayout();

    useEffect(() => {
        fullScreen.setIsOpen(true);
        isBordered && fullScreen.setIsBordered(true);

        return () => {
            fullScreen.setIsOpen(false);
            fullScreen && fullScreen.setIsBordered(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
