import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledContainer = styled.header<{ pt?: number; pb: number }>`
    padding: ${({ pt }) => UI.Tokens.SIZES[pt || 2]} ${UI.Tokens.SIZES[2]}
        ${({ pb }) => (pb === 0 ? 0 : UI.Tokens.SIZES[pb])}
        ${UI.Tokens.SIZES[2]};

    ${({ pb }) =>
        pb > 0
            ? `
        border-bottom:1px solid ${UI.Tokens.COLOURS.greyscale.silver};
    `
            : null}
`;

export const StyledActions = styled.header`
    padding-top: ${UI.Tokens.SIZES[2]};
    padding-bottom: ${UI.Tokens.SIZES[2]};
`;

export const StyledTitledContainer = styled.div`
    width: 100%;

    @media screen and (max-width: ${UI.Tokens.BREAKPOINTS.lg}) {
        overflow: hidden;
    }
`;

export const StyledHeadingWrap = styled(UI.Item)`
    width: 100%;
    overflow: hidden;
`;

export const StyledTitledContainerInner = styled(UI.Flex)`
    max-width: 100%;
    min-width: 0;
    justify-content: space-between;
    align-items: center;
`;
