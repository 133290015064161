import { useCurrentWorkspace } from "@accurx/auth";
import { useSendMutation } from "@accurx/concierge/hooks/mutations/useSendMutation";
import { Conversation } from "@accurx/concierge/types";
import { patientNhsNumber } from "@accurx/concierge/util/patientNhsNumber";
import {
    ClinicianCompose,
    ComposePatient,
    useClinicianMessageSendAnalytics,
    useIsComposing,
} from "@accurx/message-component";
import { ReplyState } from "domains/inbox/components/ConversationView/ConversationView.types";
import { ReplyFailedFeedback } from "domains/inbox/components/ConversationView/components/MessageActions/ReplyFailedFeedback";
import { isTestPatient } from "domains/inbox/util";
import { getMainParticipant } from "domains/inbox/util/getMainParticipant";
import { getParticipantName } from "domains/inbox/util/getParticipantName";
import { toast } from "react-toastify";

import { useButtonClickAnalytics } from "../../useButtonClickAnalytics";
import { MessageActionButton } from "../MessageActionButton/MessageActionButton";
import { StyledInnerContainer, StyledOuterContainer } from "./Contents.styles";

type ReplyContentsProps = {
    conversation: Conversation;
    patient?: ComposePatient;
    replyState: Omit<ReplyState, "Closed">; // ReplyContents is only be displayed when Reply is open
    setReplyState: (state: ReplyState) => void;
    isExpanded: boolean;
    onExpandClick: () => void;
};

export const ClinicianReplyContents = ({
    patient,
    conversation,
    replyState,
    setReplyState,
    isExpanded,
    onExpandClick,
}: ReplyContentsProps) => {
    const isComposing = useIsComposing();
    const sendMessage = useSendMutation();
    const { trackClinicianMessageSendClick } =
        useClinicianMessageSendAnalytics();

    const { orgId } = useCurrentWorkspace();
    const { trackReplyButtonClick } = useButtonClickAnalytics(conversation);

    if (replyState === "Minimised") {
        return (
            <MessageActionButton
                onClick={() => {
                    setReplyState("Open");
                    trackReplyButtonClick();
                }}
                text={isComposing ? "Continue reply" : "Reply"}
                theme="primary"
                icon="Reply"
            />
        );
    }

    const participant = getMainParticipant(conversation, orgId);
    const recipientName = getParticipantName(participant);

    const recipientWorkspaceId =
        participant?.type === "Workspace" ? participant.workspaceId : undefined;

    return (
        <StyledOuterContainer>
            <StyledInnerContainer>
                <ClinicianCompose
                    isMessageSending={sendMessage.isLoading}
                    onMessageSend={({ errors, warnings, data }) => {
                        if (
                            // Just for type safety
                            conversation.source.system === "ClinicianMessaging"
                        ) {
                            // Always track the click
                            trackClinicianMessageSendClick({
                                isReply: true,
                                origin: "/inbox",
                                conversationId: conversation.id,
                                isTestPatient: patient
                                    ? isTestPatient(
                                          patientNhsNumber(patient.externalIds),
                                      )
                                    : false,
                                countParticipant:
                                    conversation.participants.length,
                                hasError: errors.length > 0,
                                ...data,
                            });

                            const isValid =
                                errors.length === 0 && warnings.length === 0;

                            // If it's valid, send the message
                            if (isValid) {
                                sendMessage.mutate(
                                    {
                                        workspaceId: orgId.toString(),
                                        workspaceConversationId:
                                            conversation.source.serverId,
                                        message: `${data.messageBody}${
                                            data.messageSignature
                                                ? `\n${data.messageSignature}`
                                                : ""
                                        }`,
                                        attachmentIds: data.attachments.map(
                                            (att) => att.id,
                                        ),
                                        isPresetTemplate: !!data.template.value,
                                        isPostPatientMessage: false,
                                        templateGroup:
                                            data.template.value?.heading ??
                                            null,
                                        templateName:
                                            data.template.value?.title ?? null,
                                    },
                                    {
                                        onSuccess: () => {
                                            setReplyState("Closed");
                                        },
                                        onError: () => {
                                            toast(<ReplyFailedFeedback />, {
                                                autoClose: false,
                                            });
                                        },
                                    },
                                );
                            }
                        }
                    }}
                    recipient={{
                        name: recipientName,
                        workspaceId: recipientWorkspaceId,
                    }}
                    onMinimiseClick={() => setReplyState("Minimised")}
                    onExpandClick={onExpandClick}
                    isHeightRestricted={!isExpanded}
                />
            </StyledInnerContainer>
        </StyledOuterContainer>
    );
};
