import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledCollapsibleInfoContainer = styled.div`
    * {
        font-size: 0.875rem;
    }
    svg {
        width: 20px;
        height: 20px;
    }

    button[data-state] {
        align-items: center;
        padding: ${Tokens.SIZES[0.5]} ${Tokens.SIZES[0.5]} ${Tokens.SIZES[0.5]}
            0;
    }
`;
