import { TeamSummary } from "@accurx/concierge/types";

import { ValidAssigneeSummary } from "./AssigneeSelectorForm.types";

export const getAssigneeTrackingFields = (
    userId: string,
    assignee: ValidAssigneeSummary,
    teams: TeamSummary[],
    toAssignTeam?: TeamSummary,
) => {
    const { type, id } = assignee;

    if (type === "Team") {
        const teamInfo =
            id === toAssignTeam?.id
                ? toAssignTeam
                : teams.find((item) => item.id === id);

        return {
            assigneeType: type,
            assigneeTeamId: id,
            isAssignedToSelf: false,
            assigneeTeamName: teamInfo?.displayName,
            assigneeTeamType: teamInfo?.type,
            eventVersion: 2,
        };
    }

    return {
        assigneeType: type,
        isAssignedToSelf: id === userId,
        assigneeUserId: id,
        eventVersion: 2,
    };
};
