import React, { PropsWithChildren } from "react";

import { Ds, Icon, IconNameVariants, StackPanel, Text } from "@accurx/design";
import { i } from "images";

import { useIsMobile, useIsSmallMobile } from "shared/useIsMobile";

import { StyledMobileHeaderLayout } from "../Layout.styles";
import { PrNumberPopover } from "../accurxPrNumber";
import { MobileHeader } from "../navigationMenu/MobileNavigationMenu";
import {
    StyledBorderedBox,
    StyledBranding,
    StyledHeading,
    StyledIconBox,
    StyledImg,
    StyledList,
    StyledListItem,
    StyledSidebar,
    StyledWrapper,
} from "./LoginPageLayout.styles";

type FeatureItem = {
    heading: string;
    icon: { name: IconNameVariants; style: "Fill" | "Line" };
    badge?: string;
    text?: string;
};

export type FeaturesListProps = {
    heading?: string;
    text?: string;
    listItems: FeatureItem[];
};

const FeaturesList = ({ heading, text, listItems }: FeaturesListProps) => {
    return (
        <>
            <StyledHeading
                colour="white"
                variant="subtitle"
                forwardedAs="h2"
                skinny
            >
                {heading ?? "For what?"}
            </StyledHeading>
            {text && (
                <Text colour="white" className="mb-3" skinny>
                    {text}
                </Text>
            )}
            <StyledList>
                {listItems.map(({ icon, heading, badge, text }) => (
                    <StyledListItem key={heading}>
                        <StackPanel
                            orientation="horizontal"
                            gutter={2}
                            verticalContentAlignment={text ? "top" : "center"}
                        >
                            <StyledIconBox
                                verticalContentAlignment="center"
                                horizontalContentAlignment="center"
                            >
                                <Icon
                                    name={icon.name}
                                    colour="white"
                                    style={icon.style}
                                    size={3}
                                />
                            </StyledIconBox>
                            <div>
                                <Text
                                    colour="white"
                                    variant={text ? "label" : "body"}
                                    skinny
                                >
                                    {heading}{" "}
                                    {badge && (
                                        <Ds.Badge color="purple" radius="round">
                                            {badge}
                                        </Ds.Badge>
                                    )}
                                </Text>
                                {text && (
                                    <Text colour="white" skinny>
                                        {text}
                                    </Text>
                                )}
                            </div>
                        </StackPanel>
                    </StyledListItem>
                ))}
            </StyledList>
        </>
    );
};

export type LoginPageLayoutProps = {
    featuresList?: FeaturesListProps[];
    footer?: {
        link: string;
        text: string;
    };
};

export const LoginPageLayout = ({
    featuresList,
    footer,
    children,
}: PropsWithChildren<LoginPageLayoutProps>): JSX.Element => {
    const isMobile = useIsMobile();
    const isSmallMobile = useIsSmallMobile();

    return (
        <StyledWrapper>
            <StyledSidebar>
                <StyledBranding>
                    <StyledImg
                        src={i("accurx-logo-full.svg")}
                        alt="Accurx"
                        width="180px"
                        height="24px"
                    />
                </StyledBranding>
                {featuresList && (
                    <>
                        {featuresList.map((featureList, index) => (
                            <FeaturesList
                                {...featureList}
                                key={`${featureList.heading}-${index}`}
                            />
                        ))}
                        {footer && (
                            <StyledBorderedBox
                                orientation="vertical"
                                verticalContentAlignment="center"
                                gutter={1}
                            >
                                <Text
                                    colour="white"
                                    variant={isSmallMobile ? "body" : "label"}
                                    skinny
                                >
                                    {footer.text}
                                </Text>
                                <Ds.Link
                                    href={footer.link}
                                    target="_blank"
                                    color="white"
                                >
                                    Find out more
                                </Ds.Link>
                            </StyledBorderedBox>
                        )}
                    </>
                )}
                <br />
                <PrNumberPopover />
            </StyledSidebar>
            {children}
            {isMobile && (
                <StyledMobileHeaderLayout>
                    <MobileHeader />
                </StyledMobileHeaderLayout>
            )}
        </StyledWrapper>
    );
};
