import React from "react";

import { Card, Ds, StackPanel, Text } from "@accurx/design";

// TODO: Migrate to local style file once UserManagementCard is deprecated
import {
    StyledUserDetails,
    StyledUserManagementCardContainer,
    StyledUserManagementCardContent,
    StyledUserNameAndEmail,
} from "../UserManagementPage.styles";

interface UserCardProps {
    name?: Optional<string>;
    email: string;
    children?: React.ReactNode;
}

export const UserCard = ({ name, email, children }: UserCardProps) => {
    return (
        <StyledUserManagementCardContainer>
            <Card spacing={2}>
                <StackPanel
                    orientation="horizontal"
                    verticalContentAlignment="center"
                    gutter={3}
                >
                    <Ds.Avatar color="blue" name={name} size="xlarge" />
                    <StyledUserManagementCardContent>
                        <StyledUserDetails>
                            <StyledUserNameAndEmail>
                                {name && (
                                    <Text variant="label" skinny>
                                        {name}
                                    </Text>
                                )}
                                <Text variant="preview">{email}</Text>
                            </StyledUserNameAndEmail>
                        </StyledUserDetails>
                        {children}
                    </StyledUserManagementCardContent>
                </StackPanel>
            </Card>
        </StyledUserManagementCardContainer>
    );
};
