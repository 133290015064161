import * as UI from "@accurx/design";
import { useMedicalRecordConnection } from "@accurx/native";
import { useCompose } from "domains/message-component/context";
import { PatientExternalId } from "domains/message-component/types";

import { RecipientSelectorButtonConsentIcon } from "./RecipientSelectorButtonConsentIcon";
import {
    StyledContactDetail,
    StyledContainer,
    StyledIconContainer,
    StyledText,
} from "./RecipientSelectorButtonContent.styles";

export const RecipientSelectorButtonContent = ({
    patientExternalIds,
}: {
    patientExternalIds: PatientExternalId[];
}) => {
    const { state } = useCompose();
    const medicalRecordConnection = useMedicalRecordConnection();

    return (
        <StyledContainer gap="0.5" alignItems={"center"}>
            {state.contactDetails.value === "" ? (
                <UI.Ds.Text size="small" weight="bold" color="zinc" as="span">
                    Enter contact details
                </UI.Ds.Text>
            ) : (
                <StyledContainer gap="0.5" alignItems={"center"}>
                    {medicalRecordConnection.status === "Connected" && (
                        <RecipientSelectorButtonConsentIcon
                            patientExternalIds={patientExternalIds}
                            contactMethod={state.contactDetails.method}
                        />
                    )}
                    <StyledContactDetail gap="1" alignItems={"center"}>
                        <UI.Ds.Text
                            size="small"
                            weight="bold"
                            color="zinc"
                            as="span"
                        >
                            {state.contactDetails.origin ===
                            "SubmittedByPatientProxy"
                                ? "Patient proxy"
                                : "Patient"}
                        </UI.Ds.Text>
                        <StyledText
                            size="small"
                            color="zinc"
                            forwardedAs="span"
                        >
                            {state.contactDetails.value}
                        </StyledText>
                    </StyledContactDetail>
                </StyledContainer>
            )}
            <StyledIconContainer>
                <UI.Ds.Icon name="Arrow" size={"small"} />
            </StyledIconContainer>
        </StyledContainer>
    );
};
