import React, { useState } from "react";

import { useMarkAsUnreadMutation } from "@accurx/concierge/hooks/mutations/useMarkAsUnreadMutation";
import { Conversation } from "@accurx/concierge/types";
import {
    Ds,
    Feedback,
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@accurx/design";
import { WithTooltip } from "domains/inbox/components/Tooltip/WithTooltip";
import {
    useMarkAsDoneMutation,
    useMarkAsNonUrgentMutation,
    useMarkAsOpenMutation,
    useMarkAsUrgentMutation,
} from "domains/inbox/hooks/mutations";
import {
    useInboxLink,
    useIsActiveConversation,
} from "domains/inbox/hooks/util";
import { useConversationActionAnalytics } from "domains/inbox/hooks/util/useConversatonActionAnalytics";
import { ItemStateWithReason } from "domains/inbox/hooks/util/useUpdatingFeed";
import { userflowIds } from "domains/inbox/util";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { StyledActionsContainer } from "../../ConversationPreview.styles";
import { MarkAsUnread } from "./MarkAsUnread";
import {
    StyledTooltipContent,
    StyledTriggerButton,
} from "./PreviewActions.styles";
import { ToggleStatus } from "./ToggleStatus";
import { ToggleUrgency } from "./ToggleUrgency";

const ConversationPreviewActionsContext = React.createContext<{
    markAsUnread: () => void;
    markAsUrgent: () => void;
    markAsNonUrgent: () => void;
    markAsDone: () => void;
    markAsOpen: () => void;
    onActionCompleted: () => void;
    conversation: Conversation;
    analytics: ReturnType<typeof useConversationActionAnalytics>;
    isActionLoading: boolean;
} | null>(null);

export const useConversationPreviewActionsContext = () => {
    const context = React.useContext(ConversationPreviewActionsContext);
    if (!context) {
        throw new Error(
            "ConversationPreviewActionsContext cannot be used outside of a provider",
        );
    }
    return context;
};

const Layout = ({
    state,
    tooltipText,
    isOpen,
    setIsOpen,
    children,
}: {
    state: ItemStateWithReason;
    tooltipText: string;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    children: React.ReactNode;
}) => {
    const { conversation, isActionLoading } =
        useConversationPreviewActionsContext();
    const isActive = useIsActiveConversation(conversation.id);
    const disabled = state.current === "leaving" || isActionLoading;
    return (
        <StyledActionsContainer $isOpen={isOpen || isActive}>
            <Popover open={isOpen && !disabled} onOpenChange={setIsOpen}>
                <WithTooltip
                    content={
                        <StyledTooltipContent
                            colour="white"
                            variant="note"
                            skinny
                        >
                            {tooltipText}
                        </StyledTooltipContent>
                    }
                    props={{
                        content: {
                            align: "end",
                            side: "bottom",
                        },
                    }}
                >
                    <PopoverTrigger asChild>
                        <StyledTriggerButton
                            data-userflow-id={
                                userflowIds.conversationList.previewActions
                            }
                            appearance="secondary"
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setIsOpen(!isOpen);
                            }}
                            disabled={disabled}
                        >
                            <Ds.Button.Icon
                                name="More"
                                appearance="solid"
                                id="conversation-quick-actions-menu"
                                aria-label="Available conversation actions"
                            />
                        </StyledTriggerButton>
                    </PopoverTrigger>
                    <PopoverContent align="end" side="top" width="max-content">
                        {children}
                    </PopoverContent>
                </WithTooltip>
            </Popover>
        </StyledActionsContainer>
    );
};

const Provider = ({
    conversation,
    state,
    tooltipText,
    children,
}: {
    conversation: Conversation;
    state: ItemStateWithReason;
    tooltipText: string;
    children: React.ReactNode;
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const analytics = useConversationActionAnalytics({
        conversation,
        productOrigin: "ConversationListPreview",
    });
    const isActiveConversation = useIsActiveConversation(conversation.id);
    const history = useHistory();
    const link = useInboxLink();
    const markAsDone = useMarkAsDoneMutation({
        onSuccess: () => {
            analytics.trackConversationActionButtonResponse("Done", false);
        },
        onError: () => {
            analytics.trackConversationActionButtonResponse("Done", true);
        },
    });
    const markAsOpen = useMarkAsOpenMutation({
        onSuccess: () => {
            analytics.trackConversationActionButtonResponse("Reopen", false);
        },
        onError: () => {
            analytics.trackConversationActionButtonResponse("Reopen", true);
        },
    });

    const markAsUrgent = useMarkAsUrgentMutation({
        onSuccess: () => {
            analytics.trackConversationActionButtonResponse("Urgent", false);
        },
        onError: () => {
            analytics.trackConversationActionButtonResponse("Urgent", true);
        },
    });
    const markAsNonUrgent = useMarkAsNonUrgentMutation({
        onSuccess: () => {
            analytics.trackConversationActionButtonResponse("NonUrgent", false);
        },
        onError: () => {
            analytics.trackConversationActionButtonResponse("NonUrgent", true);
        },
    });

    const markAsUnread = useMarkAsUnreadMutation(
        {
            patientId: conversation.regardingPatientId,
        },
        {
            onSuccess: () => {
                analytics.trackConversationActionButtonResponse(
                    "Unread",
                    false,
                );
                toast(
                    <Feedback
                        colour="success"
                        title="Conversation marked unread for you"
                    />,
                );
                // We only navigate away if the currently active conversation is the
                // one being marked as unread.
                if (isActiveConversation) {
                    history.push(link.update({ conversationId: null }));
                }
            },
            onError: () => {
                analytics.trackConversationActionButtonResponse("Unread", true);
                toast(
                    <Feedback
                        title="Sorry, we were unable to mark this conversation
            as unread. Please try again."
                        colour="error"
                    />,
                );
            },
        },
    );

    const isActionLoading =
        markAsDone.status === "loading" ||
        markAsOpen.status === "loading" ||
        markAsNonUrgent.status === "loading" ||
        markAsUrgent.status === "loading" ||
        markAsUnread.status === "loading";

    return (
        <ConversationPreviewActionsContext.Provider
            value={{
                conversation,
                analytics,
                isActionLoading,
                markAsDone: () => markAsDone.mutate(conversation),
                markAsOpen: () => markAsOpen.mutate(conversation),
                markAsUrgent: () => markAsUrgent.mutate(conversation),
                markAsNonUrgent: () => markAsNonUrgent.mutate(conversation),
                markAsUnread: () => markAsUnread.mutate({ conversation }),
                onActionCompleted: () => setIsOpen(false),
            }}
        >
            <Layout
                state={state}
                tooltipText={tooltipText}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            >
                {children}
            </Layout>
        </ConversationPreviewActionsContext.Provider>
    );
};

export const ConversationPreviewActions = {
    Provider,
    Layout,
    ToggleStatus,
    ToggleUrgency,
    MarkAsUnread,
};
