import {
    ChangeEvent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import * as UI from "@accurx/design";
import { TemplateItem } from "domains/message-component/types";
import { useCombobox } from "downshift";

import { useTemplatesAnalytics } from "../../useTemplatesAnalytics";
import {
    StyledChevron,
    StyledComboboxDropdown,
    StyledEllipsisText,
    StyledHeadingLevelOne,
    StyledHeadingLevelTwo,
    StyledInput,
    StyledList,
    StyledNoResultsMessage,
    StyledOption,
    StyledPreviewOption,
    StyledTemplateSearchWrapper,
} from "./TemplatesCombobox.styles";
import { addHeadings } from "./addHeadings";
import { useTemplateSearchDebounced } from "./useTemplateSearchDebounced";
import { useTemplateSelectorDropdown } from "./useTemplateSelectorDropdown";

type PreviewOption = { type: "PreviewOption"; value: null; index: number };

type ComboboxItem = TemplateItem | PreviewOption;

export const OUTER_CLASSNAME = "combobox-input";

export const TemplatesCombobox = ({
    templates,
    onItemSelected,
    onPreviewOptionSelected,
    labelPlaceholder,
}: {
    templates: TemplateItem[];
    onItemSelected: (template: TemplateItem) => void;
    onPreviewOptionSelected: () => void;
    labelPlaceholder: string;
}) => {
    /**
     * We keep a cache of templates because they get live updates,
     * so we only want to update them in the background
     * when the user is not using or searching through templates.
     *
     * The cache gets updated
     * - when the user closes the dropdown, if the search is
     *   empty (see `onIsOpenChange`)
     * - in the background, if the dropdown is closed and
     *   the search is empty (see `onTemplatesUpdated`)
     */
    const [templatesCache, setTemplatesCache] = useState(templates);

    const events = useTemplatesAnalytics();
    const comboboxRef = useRef<HTMLDivElement | null>(null);
    const { openSide, calculatedDropdownHeight } = useTemplateSelectorDropdown({
        comboboxRef,
        templatesCount: templatesCache.length,
    });

    const [comboboxItems, setComboboxItems] = useState(templatesCache);

    // Search is debounced, so do not show not found message
    // unless we really don't have results after debounce
    // otherwise screen reader might say that there's no results
    // when there could be some
    const [showNoResultsMessage, setShowNoResultsMessage] = useState(false);

    const searchCallback = useCallback(
        (results: TemplateItem[]) => {
            setComboboxItems(results);
            setShowNoResultsMessage(results.length === 0);
        },
        [setComboboxItems],
    );

    const [searchTerm, setSearchTerm] = useState("");
    const searchDebounced = useTemplateSearchDebounced(
        templatesCache,
        searchCallback,
    );

    /** We are going to append the preview option
     * to the combobox `items`, so we know that the index
     * is the length of that array
     */
    const previewOption: PreviewOption = {
        type: "PreviewOption",
        value: null,
        index: comboboxItems.length,
    };

    const handleItemSelected = (template: TemplateItem) => {
        events.trackTemplateSelectButtonClick({
            template,
            appOrigin: "TemplatesCombobox",
        });
        onItemSelected(template);
    };
    const handlePreviewOptionSelected = () => {
        events.trackTemplateBrowsePreviewOptionClick();
        onPreviewOptionSelected();
    };

    const {
        isOpen,
        getLabelProps,
        getMenuProps,
        getInputProps,
        highlightedIndex,
        getItemProps,
        selectItem,
    } = useCombobox<ComboboxItem | null>({
        inputValue: searchTerm,
        // Filtering
        onInputValueChange: ({ inputValue, selectedItem }) => {
            setShowNoResultsMessage(false);

            // When user selects preview option, we do not want to
            // - populate the combobox input
            // - reset the search results
            if (selectedItem?.type === "PreviewOption") {
                setSearchTerm("");
                setComboboxItems(templatesCache);
                return;
            }

            setSearchTerm(inputValue);
            // Filter when input value is valid/unfilter
            // when input value is empty string
            if (inputValue === "") {
                setComboboxItems(templatesCache);
                // Cancel any pending invocations to prevent overriding resultss
                searchDebounced.cancel();
            } else {
                searchDebounced(inputValue);
            }
        },
        onSelectedItemChange: (changes) => {
            if (!changes.selectedItem) {
                return;
            }
            if (changes.selectedItem.type === "PreviewOption") {
                selectItem(null);
                handlePreviewOptionSelected();
            } else {
                handleItemSelected(changes.selectedItem);
            }
        },
        onIsOpenChange: ({ inputValue }) => {
            if (!inputValue) {
                setTemplatesCache(templates);
                setComboboxItems(templates);
            }
        },

        // Items
        // Have preview templates be an option so it's reachable
        // by keyboard strokes
        items: [...comboboxItems, previewOption],
        itemToString(item) {
            if (item?.type === "PreviewOption") {
                return "Preview templates";
            }
            return item ? item.value.title : "";
        },
    });

    useEffect(
        function onTemplatesUpdated() {
            // If the dropdown stays closed and templates update
            // in the background, refresh the cache
            if (!isOpen && !searchTerm) {
                setTemplatesCache(templates);
            }
        },
        [templates, isOpen, searchTerm],
    );

    const itemsWithHeadings = useMemo(() => {
        return addHeadings(comboboxItems);
    }, [comboboxItems]);

    return (
        <StyledTemplateSearchWrapper
            ref={comboboxRef}
            data-userflow-id="compose-templates-search"
        >
            <UI.VisuallyHidden>
                <label {...getLabelProps()}>{labelPlaceholder}</label>
                <div id="search-combobox-description">
                    Results will update as you type.
                </div>
            </UI.VisuallyHidden>
            <StyledInput
                {...getInputProps({
                    "aria-describedby": "search-combobox-description",
                    placeholder: labelPlaceholder,
                    onChange: (e: ChangeEvent<HTMLInputElement>) => {
                        events.trackTemplateSearchDebounced({
                            term: e.target.value,
                            appOrigin: "TemplatesCombobox",
                        });
                    },
                })}
                isSearchInput
                outerClassName={OUTER_CLASSNAME}
            />
            <StyledComboboxDropdown
                {...getMenuProps()}
                $isOpen={isOpen}
                $dropdownHeight={calculatedDropdownHeight}
                $openSide={openSide}
            >
                {isOpen && (
                    <>
                        <div
                            // aria-live=assertive is recommended here so screen reader users
                            // do not continue typing when we've found no results.
                            aria-live="assertive"
                            role="presentation"
                        >
                            {/* There are templates, but filter result is empty */}
                            {showNoResultsMessage && (
                                <StyledNoResultsMessage>
                                    <UI.Text skinny variant="preview">
                                        No results for '{searchTerm}'.
                                    </UI.Text>
                                </StyledNoResultsMessage>
                            )}
                            {/* There are no templates */}
                            {templatesCache.length === 0 && (
                                <StyledNoResultsMessage>
                                    <UI.Text skinny variant="preview">
                                        No templates found.
                                    </UI.Text>
                                </StyledNoResultsMessage>
                            )}
                        </div>
                        {itemsWithHeadings.length > 0 && (
                            <StyledList>
                                {itemsWithHeadings.map((item, i) => {
                                    if (item.type === "HeadingLevelOne") {
                                        return (
                                            <StyledHeadingLevelOne
                                                role="presentation"
                                                key={`heading-${i}`}
                                            >
                                                {item.value}
                                            </StyledHeadingLevelOne>
                                        );
                                    }
                                    if (item.type === "HeadingLevelTwo") {
                                        if (item.value === "") {
                                            return null;
                                        }
                                        return (
                                            <StyledHeadingLevelTwo
                                                role="presentation"
                                                key={`heading-${i}`}
                                            >
                                                {item.value}
                                            </StyledHeadingLevelTwo>
                                        );
                                    }

                                    return (
                                        <StyledOption
                                            $isHighlighted={
                                                highlightedIndex === item.index
                                            }
                                            key={`${item.value.id}-${i}`}
                                            {...getItemProps({
                                                item,
                                                index: item.index,
                                                "aria-label": `${
                                                    item.type ===
                                                    "MessageTemplate"
                                                        ? "Template"
                                                        : "Questionnaire"
                                                } - ${item.value.title}`,
                                            })}
                                            forwardedAs={"li"}
                                        >
                                            <UI.Ds.Flex alignItems={"center"}>
                                                <UI.Ds.Icon
                                                    size="xsmall"
                                                    name={
                                                        item.type ===
                                                        "MessageTemplate"
                                                            ? "MessageWrite"
                                                            : "Questionnaire"
                                                    }
                                                    color="night"
                                                />
                                            </UI.Ds.Flex>
                                            <StyledEllipsisText>
                                                {item.value.title}
                                            </StyledEllipsisText>
                                        </StyledOption>
                                    );
                                })}
                            </StyledList>
                        )}
                        <StyledPreviewOption
                            $isHighlighted={
                                highlightedIndex === previewOption.index
                            }
                            {...getItemProps({
                                item: previewOption,
                                index: previewOption.index,
                            })}
                        >
                            Preview templates
                            <StyledChevron />
                        </StyledPreviewOption>
                    </>
                )}
            </StyledComboboxDropdown>
        </StyledTemplateSearchWrapper>
    );
};
