import { useEffect } from "react";

import { useAnalytics } from "@accurx/analytics";

type PatientSearchOrigin =
    | "PrimaryNav"
    | "Compose"
    | "VideoInvite"
    | "Email"
    | "MessageGp";

/**
 * Tracking when user lands on any of the patient search forms
 * - isDetailPrePopulated - true when patient details passed through patientToken, false otherwise
 * - searchPatientOrigin - origin of the form: primary navigation, compose flow, email link, video consult invite or message GP flow
 */
export const usePatientSearchViewAnalytics = ({
    isDetailPrePopulated,
    searchPatientOrigin,
}: {
    isDetailPrePopulated: boolean;
    searchPatientOrigin: PatientSearchOrigin;
}) => {
    const track = useAnalytics();

    useEffect(() => {
        track("PatientSearch Form View", {
            isDetailPrePopulated,
            searchPatientOrigin,
        });
        // We want to track this event only once on component load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
