import { PatientThreadUserGroupChanged } from "@accurx/realtime";
import isNil from "lodash/isNil";

import { PatientThreadItemUpdate } from "shared/concierge/conversations/tickets/types/dto.types";
import { isInstance } from "shared/concierge/conversations/utils";
import {
    TeamUpdate,
    UserId,
    UsersAndTeamsSummaries,
} from "shared/concierge/usersAndTeams/types/usersAndTeams.types";

import {
    mapPatientThreadUserGroupToTeamSummary,
    mapPatientThreadUserToUserSummary,
} from "./ApiResponseMapper";

export const mapTicketItemUpdateToUserAndTeamSummaries = (
    update: PatientThreadItemUpdate,
): UsersAndTeamsSummaries | undefined => {
    const users =
        update.users
            ?.map(mapPatientThreadUserToUserSummary)
            .filter(isInstance) ?? [];
    const teams =
        update.userGroups
            ?.map(mapPatientThreadUserGroupToTeamSummary)
            .filter(isInstance) ?? [];

    if (!users.length && !teams.length) {
        return;
    }

    return { users, teams };
};

export const mapUserGroupUpdateToTeamMembershipChanges = (
    update: PatientThreadUserGroupChanged,
    currentUserId: UserId,
): TeamUpdate | undefined => {
    const userGroupId = update.userGroup?.id;
    const userGroupDisplayName = update.userGroup?.name;

    if (isNil(update.organisationId)) {
        return undefined;
    }

    if (isNil(userGroupId) || userGroupId.length === 0) {
        return undefined;
    }

    if (isNil(userGroupDisplayName)) {
        return undefined;
    }

    if (isNil(update.userMembership)) {
        return undefined;
    }

    const currentUserIsMember = !!update.userMembership.find(
        (membership) => membership.userId === currentUserId,
    )?.userHasConfirmedMemberShip;

    return {
        team: {
            id: userGroupId,
            displayName: userGroupDisplayName,
        },
        currentUserIsMember,
    };
};
