import { useAuth } from "@accurx/auth";

import { isActiveWorkspace } from "../../util/workspaceHelpers";

/**
 * This hook determines whether to show certain UI elements that reflect the
 * user's pending approval status within the current workspace. Currently, we
 * only want to show this to limited markets.
 */
export const usePendingApproval = (
    workspaceId?: number | null,
): { isPendingApproval: boolean } => {
    const { user } = useAuth();

    if (!user.isLoggedIn) {
        return { isPendingApproval: false };
    }

    const currentWorkspace = user.organisations.find(
        (o) => o.orgId === workspaceId,
    );

    if (!currentWorkspace) {
        return { isPendingApproval: false };
    }

    // Only show pending features to unapproved users of active worksapces
    return {
        isPendingApproval:
            !currentWorkspace.settings.isApprovedUser &&
            isActiveWorkspace(currentWorkspace),
    };
};
