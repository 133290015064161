import { TextareaAutosize, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledTextArea = styled(TextareaAutosize)`
    resize: none;
    outline: none;
    line-height: 2rem;
    background-color: ${Tokens.COLOURS.greyscale.white};
    border-color: ${Tokens.COLOURS.greyscale.silver};
    border-style: solid;
    display: block;
`;
