import { ComponentProps, ReactNode } from "react";

import {
    Ds,
    Tooltip,
    TooltipContent,
    TooltipPortal,
    TooltipTrigger,
} from "@accurx/design";

interface PatientSearchTooltiptProps {
    children: ReactNode;
    text: string;

    align?: ComponentProps<typeof TooltipContent>["align"];
}

export const PatientSearchTooltip = ({
    children,
    text,
    align = "center",
}: PatientSearchTooltiptProps) => {
    return (
        <Tooltip>
            <TooltipTrigger tabIndex={-1} asChild>
                {children}
            </TooltipTrigger>
            <TooltipPortal>
                {/* zIndex required to use this tooltip within a modal */}
                <TooltipContent
                    side="bottom"
                    align={align}
                    style={{ zIndex: 1000 }}
                >
                    <Ds.Text weight="bold">{text}</Ds.Text>
                </TooltipContent>
            </TooltipPortal>
        </Tooltip>
    );
};
