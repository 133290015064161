import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";

import { StyledActionStack } from "../ActionStack.styles";

export const NhsAdvice = ({
    name,
    url,
    onRemoveClick,
}: {
    name: string;
    url: string;
    onRemoveClick?: () => void;
}) => {
    return (
        <StyledActionStack.Row
            justifyContent={"space-between"}
            alignItems={"center"}
            gap="1"
        >
            <UI.Flex gap="1" alignItems="center">
                <StyledActionStack.RowIcon name="Link" />
                <StyledActionStack.RowText>
                    NHS.UK Advice:{" "}
                    <StyledActionStack.RowLink
                        href={`https://${url}`}
                        openInNewTab
                    >
                        <UI.Link.Text text={name} />
                        <UI.Link.Icon />
                    </StyledActionStack.RowLink>
                </StyledActionStack.RowText>
            </UI.Flex>
            {onRemoveClick !== undefined && (
                <StyledActionStack.SquareIconButton
                    onClick={onRemoveClick}
                    title="Remove NHS advice"
                >
                    <Pill.Icon name="Cross" colour="red" size={3} />
                </StyledActionStack.SquareIconButton>
            )}
        </StyledActionStack.Row>
    );
};
