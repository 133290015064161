/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import {
    ConnectionState,
    ConnectionStateNew,
} from "@accurx/realtime/hubClient/ConnectionState";
import { Log } from "@accurx/shared";
import { sha1 } from "object-hash";
import {
    BehaviorSubject,
    Observable,
    Subject,
    Subscribable,
    Unsubscribable,
    merge,
} from "rxjs";
import { filter, tap } from "rxjs/operators";

import {
    UnreadItemsFetcher,
    createUnreadItemsFetcher,
} from "shared/concierge/conversations/UnreadItemsFetcher";
import { createConversationGroupSummarySubscription } from "shared/concierge/conversations/subscriptions/ConversationGroupSummarySubscription";
import {
    loadingSubscriptionResult,
    successfulSubscriptionResult,
} from "shared/concierge/subscription.utils";
import type {
    PaginatedSubscription,
    Subscription,
    SubscriptionResult,
} from "shared/concierge/types/subscription.types";
import { TeamMembershipFeed } from "shared/concierge/usersAndTeams/types/usersAndTeams.types";
import { BehaviorSubscribable } from "shared/types/rxjs.types";
import { generateRefreshIntervalWithJitterMs } from "utils/jitterGenerator";

import {
    createEpochFeed,
    defaultEpochStatusSeed,
    performOnEpochVersionUpdate,
} from "./EpochStatusFeedFactory";
import { createUnifiedConversationFeed } from "./UnifiedConversationFeedFactory";
import { createConversationGroupSubscription } from "./subscriptions/ConversationGroupSubscription";
import { createConversationSubscription } from "./subscriptions/ConversationSubscription";
import {
    ConversationActions,
    ConversationManager as ConversationManagerType,
    ConversationSubscriptionRequest,
    ConversationUserActions,
    EpochStatus,
    EpochStatusFeedDetails,
} from "./types/component.types";
import {
    Conversation,
    ConversationDisplaySummary,
    ConversationUpdate,
} from "./types/conversation.types";
import {
    ConversationGroupPaginationOptions,
    ConversationGroupRuleset,
    ConversationGroupSummary,
} from "./types/conversationGroup.types";
import { InitialSummary } from "./types/initialSummary.types";
import { noop } from "./utils";

type SubscriptionsStore = {
    conversationGroups: Map<
        string,
        PaginatedSubscription<ConversationDisplaySummary>
    >;
    conversationGroupSummaries: Map<
        string,
        Subscription<ConversationGroupSummary>
    >;
};

/**
 * ConversationManager
 *
 * This class is responsible for coordinating conversations for the
 * Web inbox. An instance of ConversationManager is scoped to a
 * specific workspace. UI requests conversation data by registering
 * a subscription. The conversation manager is responsible for:
 * - Fetching data and returning it to the subscription
 * - Receiving realtime updates from the server
 * - Broadcasting updates to the subscriptions
 * - Handling user actions that result in data updates (e.g. mark as done, assign to)
 */
export class ConversationManager implements ConversationManagerType {
    private readonly trackedConversations = new Map<string, Conversation>();

    private readonly subscriptions: SubscriptionsStore = {
        conversationGroups: new Map(),
        conversationGroupSummaries: new Map(),
    };

    private readonly subscriptionHandles: Unsubscribable[] = [];

    /**
     * Represents a feed of conversation updates. These will be individual updates to conversations and
     * sets of conversations. It is _not_ a "complete state" of all conversations.
     */
    private readonly conversationsUpdateFeed: Subject<Conversation[]>;

    private readonly epochStatusFeedDetails: EpochStatusFeedDetails;

    private readonly defaultBackgroundRefreshRate = 1800000; // Default background refresh rate - 30 minutes in milliseconds

    private readonly unreadItemsFetcher: UnreadItemsFetcher;

    private lazyInitialSummaryFeed:
        | Observable<SubscriptionResult<InitialSummary>>
        | undefined;

    constructor(
        public readonly currentUserId: string,
        public readonly workspaceId: number,
        private onUnsubscribe: () => void,
        private readonly conversationActions: ConversationActions,
        private readonly liveConversationUpdatesFeed: Observable<
            ConversationUpdate[]
        >,
        /**
         * We're currently migrating the behaviour of the connection status feed
         * so we temporarily have two feeds. The old feed will be deleted soon
         * in favour of the new feed.
         *
         * The new connection feed differs from the old connection feed in the
         * following ways:
         * 1. It has a 10 second timeout on initial connection
         * 2. It treats "connecting" status as "disconnected"
         *
         * TODO: cleanup with GPWIN-409
         */
        liveConnectionStatusFeed: Subscribable<ConnectionState>,
        private liveConnectionStatusFeedNew: BehaviorSubscribable<ConnectionStateNew>,
        // TODO: FOU-164 recalculate unread counts based on team membership changes
        private readonly currentUserTeamMembershipFeed: TeamMembershipFeed,
    ) {
        this.epochStatusFeedDetails = this.initializeEpochStatusFeed(
            liveConnectionStatusFeed,
        );
        this.conversationsUpdateFeed = this.initializeConversationsFeed();
        this.unreadItemsFetcher = createUnreadItemsFetcher({
            refreshRate: 1_800_000, // 30 minutes
            connectionStateFeed: liveConnectionStatusFeedNew,
            conversationActions: conversationActions,
            onFetchSuccess: (updates) =>
                this.markAllOtherConversationsAsStale(updates),
        });
        this.unreadItemsFetcher.start();
    }

    get actions(): ConversationUserActions {
        return this.conversationActions;
    }

    unsubscribe(): void {
        this.onUnsubscribe();
        Object.values(this.subscriptions).forEach(
            this.unsubscribeAndClearTrackedSubscriptions,
        );
        this.subscriptionHandles.forEach((handle) => handle.unsubscribe());
        this.conversationActions.teardown();
        this.unreadItemsFetcher.teardown();
    }

    // TODO: `Subscription` type here is overkill, as with the group summary views.
    // Once the SubscriptionResult types are harmonized with react-query ones, use a lighter type
    getInitialSummary(): Subscription<InitialSummary> {
        const feed = this.initializeInitialSummarySubscription();
        return {
            feed: feed,
            start: async () => {},
            teardown: () => {},
        };
    }

    /**
     * it's possible to pass `cacheOnly` to this method,
     * and if so, it will return a conversation only if it is already
     * in the conversation manager's cache. (This will be the case if,
     * for example, a conversation group containing the conversation
     * has been viewed.). NB. - if the conversation is _not_ already in the
     * cache, but this option is set to true, the query will stay in
     * the `loading` state indefinitely. so use with care!
     */
    getConversation(
        request: ConversationSubscriptionRequest,
    ): Subscription<Conversation> {
        const subscription = createConversationSubscription({
            conversationIdentity: request.conversationIdentity,
            initialState: this.trackedConversations.get(
                request.conversationIdentity.id,
            ),
            fetchData: request.cacheOnly
                ? noop
                : async () => {
                      await this.conversationActions.getConversation(
                          request.conversationIdentity,
                      );
                  },
            conversationsFeed: this.conversationsUpdateFeed,
            connectionStateFeed: this.liveConnectionStatusFeedNew,
        });

        subscription.start();

        return subscription;
    }

    getConversationGroup(
        ruleset: ConversationGroupRuleset,
        paginationOptions: ConversationGroupPaginationOptions,
    ): PaginatedSubscription<ConversationDisplaySummary> {
        // We hash the pagination options as well, so if two calls to getConversationGroup to the same
        // group with different options we end up with different subscriptions. Other than page size,
        // which is hard-coded, they're currently derived from the ruleset but this may change in
        // future.
        const uniqueHash = sha1({ ruleset, paginationOptions });

        // If a group subscription with the same parameters has already been created
        // we can reuse it rather than creating a new subscription instance.
        // This lets us reopen the same group without re-fetching its conversations
        const existingSubscription =
            this.subscriptions.conversationGroups.get(uniqueHash);
        if (existingSubscription) {
            return existingSubscription;
        }

        const subscription = createConversationGroupSubscription(
            ruleset,
            () => this.subscriptions.conversationGroups.delete(uniqueHash),
            (continuationToken) =>
                this.conversationActions.getConversationGroup(
                    ruleset,
                    {
                        sortOrder: paginationOptions.sortOrder,
                        sortBy: paginationOptions.sortBy,
                    },
                    continuationToken,
                ),
            this.conversationsUpdateFeed,
            paginationOptions,
        );

        this.subscriptions.conversationGroups.set(uniqueHash, subscription);

        // Async start the subscription, but we dont want to wait for the result here. We are, instead,
        // letting the subscription return to the caller and when they start listening, it will all resolve.
        subscription.start();

        return subscription;
    }

    getConversationGroupSummary(
        ruleset: ConversationGroupRuleset,
    ): Subscription<ConversationGroupSummary> {
        const uniqueHash = sha1({ ruleset });

        const existingSubscription =
            this.subscriptions.conversationGroupSummaries.get(uniqueHash);

        if (existingSubscription) {
            return existingSubscription;
        }

        const subscription = createConversationGroupSummarySubscription(
            ruleset,
            () =>
                this.subscriptions.conversationGroupSummaries.delete(
                    uniqueHash,
                ),
            Array.from(this.trackedConversations.values()),
            this.conversationsUpdateFeed,
        );

        this.subscriptions.conversationGroupSummaries.set(
            uniqueHash,
            subscription,
        );

        subscription.start();

        return subscription;
    }

    /**
     * When the SignalR connection drops we fetch all unread items as a way to
     * refresh the client state. Once we've refreshed all unread conversations
     * in our store we can trust that any unread conversation is up-to-date.
     * However, we cannot guarentee that for all other conversations. We mark
     * these as stale so that:
     * 1. We know to ignore these when calculating unread counts
     * 2. We know to remove them from conversation groups after that group has
     *    been refreshed (Still Todo as part of https://linear.app/accurx/issue/GPWIN-406/poll-for-current-conversation-group-when-disconnected-from-signalr)
     */
    private markAllOtherConversationsAsStale(
        conversations: ConversationUpdate[],
    ) {
        const unreadConversationIds = new Set<string>(
            conversations.map((conversation) => conversation.id),
        );

        const updates: Conversation[] = [];

        this.trackedConversations.forEach((conversation) => {
            if (!unreadConversationIds.has(conversation.id)) {
                const update: Conversation = {
                    ...conversation,
                    isStale: true,
                };

                this.trackedConversations.set(conversation.id, conversation);
                updates.push(update);
            }
        });

        this.conversationsUpdateFeed.next(updates);
    }

    private unsubscribeAndClearTrackedSubscriptions(
        subsMap: Map<string, Subscription<unknown>>,
    ) {
        const toUnsubscribe = Array.from(subsMap.values());
        subsMap.clear();
        toUnsubscribe.forEach((sub) => sub.teardown());
    }

    /**
     * Listen to the Connection Status feed.
     * @param liveConnectionStatusFeed the feed to listen to.
     */
    private initializeEpochStatusFeed(
        liveConnectionStatusFeed: Subscribable<ConnectionState>,
    ): EpochStatusFeedDetails {
        const refreshInterval = generateRefreshIntervalWithJitterMs(
            this.defaultBackgroundRefreshRate,
        );
        const epochFeed = new BehaviorSubject<EpochStatus>(
            defaultEpochStatusSeed,
        );

        const handle = createEpochFeed(
            liveConnectionStatusFeed,
            refreshInterval,
        )
            .pipe(measureEpochStatus(this.currentUserId))
            .subscribe(epochFeed);

        this.subscriptionHandles.push(handle);
        return {
            feed: epochFeed.pipe(
                filter((epochStatus) => epochStatus.status !== "None"),
            ),
            interval: refreshInterval,
        };
    }

    private initializeConversationsFeed(): Subject<Conversation[]> {
        // What we are doing here is merging 2 feeds - the feed from conversation actions, and the feed from the live updates
        // and then piping those updates into the merging code. This results in a unified feed of all conversations coming into
        // this manager - the conversationsFeed.
        const mergedConversationFeeds = merge(
            this.conversationActions.conversationsFeed,
            this.liveConversationUpdatesFeed,
        );

        const conversationsUpdateFeed = new Subject<Conversation[]>();
        const handle = createUnifiedConversationFeed(
            this.trackedConversations,
            this.currentUserId,
            mergedConversationFeeds,
            this.epochStatusFeedDetails.feed,
        )
            .pipe(
                measureUpdatedConversations(
                    this.workspaceId,
                    this.currentUserId,
                    this.trackedConversations,
                ),
            )
            .subscribe(conversationsUpdateFeed);

        this.subscriptionHandles.push(handle);

        return conversationsUpdateFeed;
    }

    /**
     * Sets up subscription to initial summary data.
     * @returns an observable of subscription results that provides update events to what is and isn't unread.
     */
    private initializeInitialSummarySubscription(): Observable<
        SubscriptionResult<InitialSummary>
    > {
        if (!this.lazyInitialSummaryFeed) {
            const feed: Subject<SubscriptionResult<InitialSummary>> =
                new BehaviorSubject(loadingSubscriptionResult());

            // TODO add an extra trigger on team membership update events
            const handle = performOnEpochVersionUpdate(
                this.epochStatusFeedDetails.feed,
                async () => {
                    const summary =
                        await this.conversationActions.getInitialSummary();
                    feed.next(successfulSubscriptionResult(summary));
                },
                () => {
                    // Log an error but don't push an explicit error state to the UI, let it come around again
                    Log.error("Unable to fetch initial summary");
                },
            ).subscribe();

            this.subscriptionHandles.push(handle);
            this.lazyInitialSummaryFeed = feed;
        }

        return this.lazyInitialSummaryFeed;
    }
}

const measureUpdatedConversations = (
    workspaceId: number,
    userId: string,
    trackedConversations: Map<string, Conversation>,
) => {
    return tap<Conversation[]>((items) => {
        Log.info("Conversation manager conversations updated", {
            tags: {
                workspaceId,
                userId,
                updatedConversationCount: items.length,
                allTrackedConversationCount: trackedConversations.size,
            },
        });
    });
};

const measureEpochStatus = (userId: string) => {
    return tap<EpochStatus>((item) => {
        Log.info("Epoch status", {
            tags: {
                userId,
                status: item.status,
                epoch: item.epochVersion,
            },
        });
    });
};
