import { createMutationHook } from "@accurx/api/QueryClient";
import { SaveRecentSearchRequest } from "@accurx/api/patient-messaging";
import {
    IWrappedResult,
    getApiEndpoint,
    httpClient,
    returnDataOrThrow,
} from "@accurx/shared";

export const LatestPatientSearchKey = "LatestPatientSearch";

const addPatientToRecentSearches = async ({
    organisationId,
    patientToken,
}: SaveRecentSearchRequest): Promise<IWrappedResult<null>> =>
    httpClient.postJsonReturnSafeAsync(
        getApiEndpoint({
            path: "/api/patientmessaging/latestpatientsearch",
        }),
        {
            organisationId,
            patientToken,
        },
    );

export const useAddPatientToRecentSearches =
    createMutationHook<SaveRecentSearchRequest>(() => ({
        mutationKey: [LatestPatientSearchKey],
        mutationFn: async ({ organisationId, patientToken }) => {
            const response = await addPatientToRecentSearches({
                organisationId,
                patientToken,
            });
            return returnDataOrThrow(response);
        },
    }));
