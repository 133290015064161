import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledUserReadCountLabel = styled(UI.Text)`
    text-decoration: underline;
`;

export const StyledUserList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

export const StyledUserListItem = styled.li`
    padding: 2px 0;
`;
