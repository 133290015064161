import { Attachment } from "domains/message-component/reducer.types";

export const getIsFileLimitReached = ({
    attachments,
    maxAttachmentCount,
}: {
    attachments: Attachment[];
    maxAttachmentCount: number;
}): boolean => {
    // If the max attachment limit is 1, do not count failed attachments
    // towards to total as we will automatically try to replace it.
    const filterErrorAttachments = (att: Attachment) =>
        maxAttachmentCount === 1 ? att.status !== "error" : true;

    /* Failed attachments can be replaced by a new one.
     * Pending attachments count towards the total as they will
     * likely transition to successful attachments */
    return (
        attachments.filter(filterErrorAttachments).length >= maxAttachmentCount
    );
};
