import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledQuickViewContainer = styled.div`
    background-color: ${Tokens.COLOURS.greyscale.white};

    display: flex;
    flex-direction: column;

    height: 100%;
`;

export const StyledQuickViewColumn = styled.section`
    height: 100%;
    width: 100%;

    position: relative;
`;
