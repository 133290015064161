import { useContext, useMemo } from "react";

import { RealtimeContext } from "domains/realtime/components/RealtimeContext";
import { ConnectionState, Realtime } from "domains/realtime/types";
import {
    SignalRSendEvent,
    SignalRSubscriptionEvent,
} from "domains/realtime/types/signalR/events";

import { useTransport } from "./utils/useTransport";

type RealtimeForSignalR = Realtime<
    SignalRSendEvent,
    SignalRSubscriptionEvent
> & {
    connectionState: ConnectionState;
};

export const useRealtimeForSignalR = (): RealtimeForSignalR => {
    const { connectionState } = useContext(RealtimeContext);

    const transport = useTransport();

    return useMemo(
        () => ({ ...transport, connectionState }),
        [connectionState, transport],
    );
};
