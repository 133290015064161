import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import styled from "styled-components";

export const StyledPillButton = styled(Pill.Dropdown)`
    min-width: 0;
    padding-left: ${UI.Tokens.SIZES[1.5]};
`;

export const StyledPillText = styled(Pill.Text)`
    text-overflow: ellipsis;
    overflow: hidden;
`;
