/* eslint-disable */
/* tslint:disable */
/*
 * -------------------- /!\ WARNING /!\ --------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API  ##
 * ## DO NOT EDIT MANUALLY                                ##
 * ## YOUR CHANGES WILL BE OVERWRITTEN                    ##
 * -------------------- /!\ WARNING /!\ --------------------
 */

export interface GetTemplatesResponse {
    templates: TemplateResponse[];
}

export type TemplateResponse = BaseTemplate & {
    /** @minLength 1 */
    id: string;
    isVideo: boolean;
    recipient: Recipient;
};

export enum Recipient {
    Patient = "Patient",
    HealthcareProfessional = "HealthcareProfessional",
}

export interface BaseTemplate {
    /**
     * @minLength 1
     * @maxLength 50
     */
    title: string;
    /**
     * @minLength 1
     * @maxLength 450
     */
    body: string;
    snomedCodes: SnomedCodeFull[];
    owner: Owner;
    category: string;
    allowReplyByDefault: boolean;
    sendAvailability: SendAvailability[];
    /** @maxItems 5 */
    attachments: Attachment[];
}

export type SnomedCodeFull = SnomedCode & {
    term?: string | null;
    descriptionId?: string | null;
};

export interface SnomedCode {
    conceptId?: string | null;
}

export enum Owner {
    User = "User",
    Workspace = "Workspace",
    Accurx = "Accurx",
}

export enum SendAvailability {
    Individual = "Individual",
    Batch = "Batch",
}

export interface Attachment {
    /** @format int32 */
    id: number;
    fileName: string;
    /** @format int64 */
    fileSize: number;
}

export type CreateTemplate = BaseTemplate & object;

export type UpdateTemplate = BaseTemplate & object;
