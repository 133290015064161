import {
    Ds,
    PopoverContent,
    PopoverItem,
    PopoverItemButton,
    Tokens,
} from "@accurx/design";
import styled from "styled-components";

export const StyledListItem = styled.li`
    padding: ${Tokens.SIZES[1]};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: ${Tokens.SIZES[1]};
    border: ${Tokens.BORDERS.normal.silver};
    border-radius: ${Tokens.BORDERS.radius};
`;

export const StyledDeleteButton = styled(Ds.Button)`
    color: ${Tokens.COLOURS.primary.red[100]};
`;

export const StyledPopoverContent = styled(PopoverContent)`
    padding: 0px;
    font-size: 14px;
`;

export const StyledPopoverItemButton = styled(PopoverItemButton)`
    text-align: left;
    margin: 0;
`;

export const StyledPopoverHeader = styled(PopoverItem)`
    background-color: ${Tokens.COLOURS.greyscale.cloud};
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    margin: 0px;
`;
