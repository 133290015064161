import { IconNameVariants } from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";

import { StyledPillButton, Theme } from "./MessageActionButton.styles";

type MessageActionButtonProps = {
    text?: string;
    theme: Theme;
    dataTestId?: string;
} & (
    | {
          onClick?: () => void;
          isLoading?: false;
          icon?: IconNameVariants;
      }
    | {
          onClick?: undefined;
          isLoading: true;
          icon?: undefined;
      }
);

export const MessageActionButton = ({
    text,
    icon,
    onClick,
    theme,
    dataTestId,
    isLoading = false,
}: MessageActionButtonProps) => {
    return (
        <StyledPillButton
            $theme={theme}
            radius="full"
            onClick={() => onClick?.()}
            disabled={isLoading}
            aria-label={text}
            isLoading={isLoading}
            data-testid={dataTestId}
        >
            {icon && !isLoading && (
                <Pill.Icon
                    name={icon}
                    colour={theme === "primary" ? "white" : "zinc"}
                />
            )}
            <Pill.Text
                $colour={
                    isLoading || theme === "secondary" ? undefined : "white"
                }
            >
                {text}
            </Pill.Text>
        </StyledPillButton>
    );
};
