import React from "react";

import {
    ButtonLink,
    Card,
    Ds,
    Link,
    StackPanel,
    Text,
    Tokens,
} from "@accurx/design";
import { useLocation } from "react-router-dom";

import {
    importToOrg,
    importWebToOrg,
    routeShare,
    routeWebShare,
} from "../../floreyBuilder/constants/paths";
import { useSwitchingOrgPage } from "./SwitchingOrgPage.hooks";

export const SwitchingOrgPage = (): JSX.Element => {
    const { orgId, shareId, switchingOrgId, organisationName } =
        useSwitchingOrgPage();

    const location = useLocation();

    const isDesktopPage = location.pathname.includes("/practices");

    const importFn = isDesktopPage ? importToOrg : importWebToOrg;
    const continuePath =
        importFn(switchingOrgId ?? "", shareId ?? "") + `?returnOrg=${orgId}`;

    return (
        <Card
            header={
                <Ds.Text
                    weight="bold"
                    as="h1"
                    size={isDesktopPage ? "xxlarge" : "large"}
                    mt={isDesktopPage ? "0" : Tokens.SIZES[3]}
                >
                    Switching workspace
                </Ds.Text>
            }
            footer={
                <StackPanel
                    gutter={2}
                    orientation="horizontal"
                    verticalContentAlignment={"center"}
                    horizontalContentAlignment={"right"}
                >
                    <Link
                        text={"Back"}
                        href={
                            isDesktopPage
                                ? routeShare(orgId ?? "", shareId ?? "")
                                : routeWebShare(orgId ?? "", shareId ?? "")
                        }
                    />
                    <ButtonLink text={"Continue"} href={continuePath} />
                </StackPanel>
            }
        >
            <Text>
                To finish importing this questionnaire, we will log you into
                this workspace:{" "}
                <Text as="span" variant="label" skinny>
                    {organisationName}
                </Text>
                .
            </Text>
            <Text>
                If you want to change your workspace after importing this
                questionnaire, use the dropdown at the top right of the screen
                to select the workspace you want to log into.
            </Text>
        </Card>
    );
};

export default SwitchingOrgPage;
