import * as UI from "@accurx/design";
import { useInboxParams } from "domains/inbox/hooks/util";
import { i } from "images";

import { StyledContainer } from "./EmptyState.styles";

export type EmptyStateVariant = "inbox" | "smile";

export const getEmptyStateVariant = (
    status: "Open" | "Done",
): EmptyStateVariant => {
    return status === "Open" ? "smile" : "inbox";
};

const imageByVariant: Record<
    EmptyStateVariant,
    "empty-inbox" | "fast-support"
> = {
    inbox: "empty-inbox",
    smile: "fast-support",
};

export type EmptyStateProps = { fadeIn?: boolean; variant?: EmptyStateVariant };

export const EmptyState = ({
    fadeIn = false,
    variant = "inbox",
}: EmptyStateProps) => {
    const { status } = useInboxParams(["status"]);

    const text =
        status === "Open"
            ? "There are no open conversations in this folder"
            : "There are no conversations in this folder";

    const image = imageByVariant[variant];

    return (
        <StyledContainer
            flexDirection="column"
            gap="1.5"
            alignItems="center"
            aria-label={text}
            $fadeIn={fadeIn}
        >
            <img
                src={i(`illustrations/${image}-80px@2x.png`)}
                srcSet={`${i(`illustrations/${image}-80px.png`)}, ${i(
                    `illustrations/${image}-80px@2x.png`,
                )} 2x, ${i(`illustrations/${image}-80px@3x.png`)} 3x`}
                alt="Empty inbox"
                height="80"
            />
            <UI.Text colour="metal">{text}</UI.Text>
        </StyledContainer>
    );
};
