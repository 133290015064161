import {
    Conversation,
    ConversationItem,
    FailedDeliveryReceiptLinkItem,
    FallbackFailedLinkItem,
    UserSummary,
} from "domains/concierge/types";
import findLast from "lodash/findLast";
import keyBy from "lodash/keyBy";

export const getMostRecentFailedItem = (
    conversation: Conversation,
    senderId?: UserSummary["id"],
): ConversationItem | undefined => {
    const itemMap = keyBy(conversation.items, "id");

    const deliveryReceipt = findLast(
        conversation.items,
        (item): item is FailedDeliveryReceiptLinkItem => {
            if (item.contentType !== "FailedDeliveryReceiptLink") {
                return false;
            }

            if (senderId !== undefined) {
                const linkedItem = itemMap[item.linkedItemId];
                return (
                    linkedItem &&
                    linkedItem.createdBy.type === "User" &&
                    linkedItem.createdBy.id === senderId
                );
            }

            return true;
        },
    );

    const fallbackFailed = findLast(
        conversation.items,
        (item): item is FallbackFailedLinkItem => {
            if (item.contentType !== "FallbackFailedLink") {
                return false;
            }

            if (senderId !== undefined) {
                const linkedItem = itemMap[item.linkedItemId];
                return (
                    linkedItem &&
                    linkedItem.createdBy.type === "User" &&
                    linkedItem.createdBy.id === senderId
                );
            }

            return true;
        },
    );

    // Get the actual failed item of the failedDeliveryReceiptItem
    const failedDeliveryReceiptItem = deliveryReceipt
        ? itemMap[deliveryReceipt.linkedItemId] ?? deliveryReceipt
        : undefined;

    // If we have both return the item associated with the most recent failed item
    if (deliveryReceipt && fallbackFailed) {
        return deliveryReceipt.createdAt > fallbackFailed.createdAt
            ? failedDeliveryReceiptItem
            : fallbackFailed;
    }

    return failedDeliveryReceiptItem || fallbackFailed;
};
